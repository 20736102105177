import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { IImageDetailPageData, IMessageDefinitions } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";

@Component({
    selector: "media-details",
    templateUrl: "media-details.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaDetailsDialog {

    public mediaType = cat.MediaType;
    public excludes: string[] = [
        "thumbfileid",
        "thumbmimetype",
        "thumbfilehash",
        "accountrefid",
        "apprefid",
        "reportrefid",
        "receivermediatype"
    ];
    public definition: IMessageDefinitions = this.common.getMessageDefinitions(messageDefinitions.MediaFileMsg);
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) public data: IImageDetailPageData
    ) {
        this._logger.debug("Data: ", data);
    }

    public async gotoMessage(messageId: string) {
        try {
            // Store message id temporarily.
            localStorage.setItem("mediaMessageId", messageId);

            await this.common.dismissAllOverlays();
        } catch (error) {
            this._logger.error(error);
        }
    }
}
