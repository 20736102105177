import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { IState } from "@shared/app-models";
import { LoggerService } from "@services/logger/logger.service";
import { CommonService } from "@services/common/common.service";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { userGetPasswordPolicy, userSetCurrentUserPassword } from "@assets/proto/services";
import { WsService } from "@services/ws/ws.service";
import { Observable } from "rxjs";
import { AuthService } from "@services/auth/auth.service";
import { Store } from "@ngxs/store";

@Component({
  selector: "change-password",
  templateUrl: "./change-password.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordDialog implements OnInit {

    isLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userSetCurrentUserPassword.isLoading);

    // Form properties.
    public fieldData = protosui.formFieldData[userSetCurrentUserPassword.methodName];
    public changePasswordForm: FormGroup;
    public submitted = false;
    public hidePassword = true;
    public uitext = protosui.messages.uitext;

    constructor(
        public dialogRef: MatDialogRef<ChangePasswordDialog>,
        public common: CommonService,
        private _auth: AuthService,
        private _logger: LoggerService,
        private _ws: WsService,
        private _store: Store,
        private _cdr: ChangeDetectorRef
    ) {}

    /**
     * Attach the data, if applicable.
     *
     * @memberof ChangePasswordDialog
     */
    async ngOnInit() {
        // Retrieve password policy
        await this._ws.sendRequest(userGetPasswordPolicy);
        const policy = this._store.selectSnapshot((state: IState) => state.cat.userGetPasswordPolicy.msg);

        // Determine password policy validators
        const passwordValidators = this.common.getPasswordValidators(policy);

        this.fieldData["newpassword"].validatorList = passwordValidators;
        this.fieldData["confirmnewpassword"].validatorList = passwordValidators;

        // Construct the change password form.
        this.changePasswordForm = new FormGroup({
            oldpassword: new FormControl("", [Validators.required]),
            newpassword: new FormControl("", passwordValidators.map((validator) => validator.validator)),
            confirmnewpassword: new FormControl("", passwordValidators.map((validator) => validator.validator)),
        });
        this.common.detectChange(this._cdr);
    }

    /**
     * Change the current user's password.
     *
     * @returns
     * @memberof ChangePasswordDialog
     */
    public async changePassword() {
        // Change form hints to errors.
        this.submitted = true;
        this._logger.debug("submit", this.changePasswordForm);

        // Check if the form is valid.
        if (this.changePasswordForm.valid) {

            const oldpassword: string = this.changePasswordForm.value.oldpassword;
            const newpassword: string = this.changePasswordForm.value.newpassword;
            const newpasswordconfirm: string = this.changePasswordForm.value.confirmnewpassword;

            if (oldpassword === newpassword) {
                this.common.createSnackbar(protosui.messages.uitext.samepasswords, "bottom", 3000);
                return;
            } else if (newpassword !== newpasswordconfirm) {
                this.common.createSnackbar(protosui.messages.uitext.differentpasswords, "bottom", 3000);
                return;
            }

            try {
                // Actually try to change the password.
                const payload: cat.UserMsg = cat.UserMsg.create({
                    password: await this._auth.createHash(oldpassword),
                    // Do not hash new password
                    newpassword: newpassword
                });
                await this._ws.sendRequest(userSetCurrentUserPassword, payload);
                this.common.createSnackbar(protosui.messages.uitext.changedpassword);
                // Close the dialog.
                this.closeDialog();

            } catch (error) {
                this.common.createSnackbar(error);
                this._logger.error(new Error(error));
            } finally {
                this.submitted = false;
            }
        } else {
            this._logger.error("Form is not valid.");
        }
    }

    /**
     * Close the dialog.
     *
     * @memberof ChangePasswordDialog
     */
    public async closeDialog() {
        this.dialogRef.close();
    }
}