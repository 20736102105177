<mat-form-field color="white" appearance="fill" class="cat-full-width cat-margin-bottom-lg">

    <mat-label>
        {{ data.label | translate | replaceTerm }}
    </mat-label>

    <mat-select [formControl]="control" [multiple]="multiple" color="primary">
        <mat-option *ngFor="let option of options" [value]="option.value" color="primary">
          {{ option.label }}
        </mat-option>
    </mat-select>

    <mat-error *ngIf="control.invalid && submitted">
        {{ control.errors | getValidatorMsg : data.validatorList }}
    </mat-error>

    <mat-hint *ngIf="!submitted && control.touched" class="cat-opacity-4">
        {{ control.errors | getValidatorMsg : data.validatorList }}
    </mat-hint>

    <mat-icon (click)="common.showDescription(data); $event.stopPropagation()" matSuffix matTooltip="help" matTooltipPosition="right" color="primary" class="cat-cursor-pointer">
        help
    </mat-icon>

</mat-form-field>

