import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { LoggerService } from "@services/logger/logger.service";
import { Store } from "@ngxs/store";
import { IState } from "@shared/app-models";

/**
 * Human readable seconds
 */
@Pipe({
  name: "humanizenotification",
  pure: true
})
export class HumanizeNotificationsPipe implements PipeTransform {

    constructor(
        private _store: Store,
        private _logger: LoggerService
    ) {}

    public notificationTypeEnum = cat.NotificationType;

    transform(value: cat.NotificationMsg): string {
        if (value) {
            let result: string;

            switch (value.type) {
                case cat.NotificationType.NOTIFICATION_MESSAGE: {
                    result = protosui.def.NotificationType[cat.NotificationType[value.type]].label;

                    // e.g.: {2: "13", 3: "6", 4: "21", 5: "17", 6: "70", 7: "591", 18: "TELEGRAM"}
                    // REFERENCE_PROFILE_ID 13
                    // REFERENCE_DEVICE_ID 6
                    // REFERENCE_APP_ID 21
                    // REFERENCE_ACCOUNT_ID 17
                    // REFERENCE_CONVERSATION_ID 70
                    // REFERENCE_MESSAGE_ID 591
                    // REFERENCE_APPTYPE_ID TELEGRAM

                    if (value.references && Object.keys(value.references).length) {

                        const profileId: string = value.references[cat.ReferenceType.REFERENCE_PROFILE_ID];

                        if (profileId && this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserProfiles).list.has(profileId)) {
                            const profile = this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserProfiles).list.get(profileId);
                            result = `${profile.fullname} has a new message on ${value.references[cat.ReferenceType.REFERENCE_APPTYPE_ID]}`;
                        }
                    }

                    break;
                }
                case cat.NotificationType.NOTIFICATION_CAPTURE_APP: {
                    result = value.value;
                    break;
                }
                default: {
                    result = `Notification`;
                    break;
                }
            }
            return result;
        }
    }
}

