import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";

/**
 * Check if an attachment has a file.
 *
 * @export
 * @class HasFilePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: "hasFile",
  pure: true
})


export class HasFilePipe implements PipeTransform {
    transform(item: cat.AttachmentMsg | cat.IAttachmentMsg): boolean {
        if (item?.files?.[0]?.fileid && item?.files?.[0]?.mimetype) {
            return true;
        } else {
            return false;
        }
    }
}
