<div class="cat-row cat-padding-bottom-lg">
    <div class="cat-col cat-width-50-percent">

        <h6 class="cat-no-padding cat-no-margin">
            {{ "System-wide search via {themes}" | translate | replaceTerm }}
        </h6>

        <p class="cat-block cat-margin-y-lg cat-opacity-4 cat-lineheight-md">
            {{ "View all {profiles} and {accounts} of a user via a {theme}." | translate | replaceTerm }}
            {{ "A list of users is only shown when available and a {theme} is selected." | translate | replaceTerm }}
            {{ "Only users who 'own' a {profile} and {accounts} as listed." | translate | replaceTerm }}
        </p>

        <!-- Toolbar with selects -->
        <mat-toolbar class="cat-height-sm cat-no-padding cat-no-margin" color="none">
            <mat-form-field appearance="fill" subscriptSizing="dynamic" class="cat-margin-right-md">
                <mat-label>{{ "Select {theme}" | translate | replaceTerm }}</mat-label>
                <mat-select (ngModel)="selectedTheme" (selectionChange)="onChangeTheme($event)">
                    <mat-option *ngFor="let theme of (themes$|async)" [value]="theme">
                        {{ theme.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="(users$|async) as users">
                <mat-form-field *ngIf="users.length" appearance="fill" subscriptSizing="dynamic">
                    <mat-label>{{ "Select user" | translate }}</mat-label>
                    <mat-select (ngModel)="selectedUser" (selectionChange)="onChangeUser($event)">
                        <mat-option *ngFor="let user of users" [value]="user">
                            {{ user.fullname}} ({{ user.username }}, id: {{ user.id }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </mat-toolbar>

        <ng-container *ngIf="(profiles$|async) as profiles">
            <ng-container *ngFor="let profile of profiles trackBy: common.trackById">
                <mat-card class="mat-elevation-z0 cat-margin-y-md" color="light-shade">
                    <mat-card-content class="cat-margin-md cat-no-max-width">
                        <div class="cat-margin-bottom-md">
                            <b>{{ uitext.profile | translate | replaceTerm }}:</b> {{ profile.fullname }}
                        </div>
                        <ng-container *ngFor="let account of profile.accounts trackBy: common.trackById">
                            <span *ngIf="account.apptype.id" class="cat-avatar cat-xs cat-float-start cat-margin-right-sm cat-no-border-radius cat-no-background">
                                <img [src]="account.apptype.iconfileid | iconmedia">
                            </span>
                            <div class="cat-block cat-margin-bottom-sm">
                                {{ account.username }} (id: {{ account.externid }})
                            </div>
                        </ng-container>
                        <ng-container *ngIf="profile.accounts.length === 0">
                            {{ "No {accounts}" | translate | replaceTerm }}
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-container>

            <ng-container *ngIf="profiles.length === 0">
                <div class="cat-item cat-margin-y-lg cat-no-background">
                    <span class="cat-opacity-6">
                        {{ "No results" | translate }}
                    </span>
                </div>
            </ng-container>
        </ng-container>

        <div class="cat-divider cat-margin-y-xl"></div>
    </div>
</div>