// Copyright 2018-2024, Volto Labs BV
// All rights reserved.


export class FileStreamerService {
    private offset = 0;
    private readonly defaultChunkSize = 9 * 1024 * 1024; // bytes

    constructor(
        private file: File) {
        this.rewind();
    }

    /**
     * Reset the offset
     */
    public rewind() {
        this.offset = 0;
    }

    /**
     * Check if the end of a file is reached
     */
    public isEndOfFile(): boolean {
      return (this.offset >= this.file.size);
    }

   /**
     * Read blob as an arraybuffer
     * @param {number} length The length of the chunksize
     * @returns {Promise<ArrayBuffer>} Promise with an ArrayBuffer
     */
    public readAsArrayBuffer(length: number = this.defaultChunkSize): Promise<ArrayBuffer> {
        const fileReader: FileReader = new FileReader();
        const blob: Blob = this.file.slice(this.offset, this.offset + length);

        return new Promise<ArrayBuffer>((resolve, reject) => {
            fileReader.onloadend = (event: ProgressEvent) => {
                const target: FileReader = (event.target) as FileReader;
                if (target.error === null && typeof(target.result) !== "string") {
                    const result: ArrayBuffer = target.result;
                    this.offset += result.byteLength;
                    resolve(result);
                } else {
                    reject(target.error);
                }
            };
            fileReader.readAsArrayBuffer(blob);
        });
    }

    /**
     * Read blob as a text
     * @param {number} length The length of the chunksize
     * @returns {Promise<string>} Promise with a string
     */
    public readAsText(length: number = this.defaultChunkSize): Promise<string> {
        const fileReader: FileReader = new FileReader();
        const blob: Blob = this.file.slice(this.offset, this.offset + length);

        return new Promise<string>((resolve, reject) => {
            fileReader.onloadend = (event: ProgressEvent) => {
                const target: FileReader = (event.target) as FileReader;
                if (target.error === null && typeof(target.result) === "string") {
                    const result: string = target.result;
                    this.offset += result.length;
                    resolve(result);
                } else {
                    reject(target.error);
                }
            };
            fileReader.readAsText(blob);
        });
    }
}
