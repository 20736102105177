
<section class="cat-tile-content cat-overflow-y cat-background-light-shade cat-height-400" tabindex="0">

    <ng-container *ngIf="(users$|async) as users">

        <mat-table class="cat-no-shadow cat-margin-bottom-xxl" [dataSource]="users" matSort matSortActive="created" matSortDirection="desc">

            <ng-container matColumnDef="fullname">
                <mat-header-cell *matHeaderCellDef>
                    {{ uitext.fullname | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    {{ user.fullname }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="roles">
                <mat-header-cell *matHeaderCellDef>
                    {{ uitext.roles | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <ng-container *ngFor="let role of user.roles last as isLast trackBy: common.trackById">
                        {{ role.nametranslations | getRoleName }}<ng-container *ngIf="!isLast">,</ng-container>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="owner">
                <mat-header-cell *matHeaderCellDef>
                    {{ uitext.owner | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <mat-slide-toggle
                        [checked]="profile.user?.id === user.id"
                        color="primary"
                        (click)="toggleOwner(profile.user?.id === user.id, user)">
                    </mat-slide-toggle>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

            <tr class="mat-row" *matNoDataRow>
                <td [attr.colspan]="displayedColumns.length" class="cat-padding-lg">
                    {{ uitext.nodatamatch | translate }}
                </td>
            </tr>

        </mat-table>
    </ng-container>

</section>


