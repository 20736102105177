import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IState } from "@shared/app-models";
import { LoggerService } from "@services/logger/logger.service";
import { CommonService } from "@services/common/common.service";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { userGetPasswordPolicy, userResetUserPassword } from "@assets/proto/services";
import { WsService } from "@services/ws/ws.service";
import { Observable } from "rxjs";
import { Store } from "@ngxs/store";
import { AuthService } from "@services/auth/auth.service";

@Component({
  selector: "admin-reset-password",
  templateUrl: "./admin-reset-password.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminResetPasswordDialog implements OnInit {

    isLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userSetCurrentUserPassword.isLoading);

    // Form properties.
    public fieldData = protosui.formFieldData[userResetUserPassword.methodName];
    public resetPasswordForm: FormGroup;
    public submitted = false;
    public hidePassword = true;
    public uitext = protosui.messages.uitext;

    constructor(
        public dialogRef: MatDialogRef<AdminResetPasswordDialog>,
        public common: CommonService,
        private _auth: AuthService,
        private _logger: LoggerService,
        private _ws: WsService,
        private _store: Store,
        private _cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private data: cat.UserMsg
    ) {}

    /**
     * Attach the data, if applicable.
     *
     * @memberof ChangePasswordDialog
     */
    async ngOnInit() {
        // Retrieve password policy
        await this._ws.sendRequest(userGetPasswordPolicy);
        const policy = this._store.selectSnapshot((state: IState) => state.cat.userGetPasswordPolicy.msg);

        // Determine password policy validators
        const passwordValidators = this.common.getPasswordValidators(policy);

        this.fieldData["temppassword"].validatorList = passwordValidators;

        // Construct the change password form.
        this.resetPasswordForm = new FormGroup({
            temppassword: new FormControl("", passwordValidators.map((validator) => validator.validator))
        });
        this.common.detectChange(this._cdr);
    }

    /**
     * Change the current user's password.
     *
     * @returns
     * @memberof ChangePasswordDialog
     */
    public async resetPassword() {
        try {
            // Change form hints to errors.
            this.submitted = true;

            // Check if the form is valid.
            if (this.resetPasswordForm.valid && this.data?.id) {

                // Reset the password with the temp password for the target user.
                const payload = cat.UserMsg.create({
                    id: this.data.id,
                    // Do not hash new password
                    newpassword: this.resetPasswordForm.value.temppassword,
                    passwordreset: true
                });
                await this._ws.sendRequest(userResetUserPassword, payload);
                this.common.createSnackbar(protosui.messages.uitext.passwordreset);

                this.closeDialog();

            } else {
                this._logger.error("Form is not valid.");
                this.common.createSnackbar(protosui.messages.uitext.invalidform);
            }
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        } finally {
            this.submitted = false;
        }
    }

    /**
     * Close the dialog.
     *
     * @memberof ChangePasswordDialog
     */
    public async closeDialog() {
        this.dialogRef.close();
    }
}