// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { Injectable } from "@angular/core";
import { TranslateService, TranslateLoader, LangChangeEvent } from "@ngx-translate/core";
import { LoggerService } from "@services/logger/logger.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import * as gettext from "gettext-parser";

@Injectable({
    providedIn: "root"
})
export class TlService {

    private defaultLanguage: string = protosui.def.SupportedLanguages[cat.SupportedLanguages[cat.SupportedLanguages.LANGUAGE_ENGLISH]].value;

    public locale: string;

    constructor(
        private translate: TranslateService,
        private logger: LoggerService
    ) {
        this.locale = translate.currentLang;
        this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            this.logger.debug("Language changed to: ", langChangeEvent);
            this.locale = langChangeEvent.lang;
        })
    }

    /**
     * Set a default language (code) for the app, from the app config.
     */
    setDefault() {
        try {
            this.translate.setDefaultLang(this.defaultLanguage);
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Add supported languages (codes) to the app, from the app config.
     */
    addSupportedLanguages() {
        try {
            const languages: string[] = [];
            for (const lang of Object.keys(cat.SupportedLanguages)) {
                if (protosui.def.SupportedLanguages[cat.SupportedLanguages[cat.SupportedLanguages[lang]]]?.value) {
                    languages.push(protosui.def.SupportedLanguages[cat.SupportedLanguages[cat.SupportedLanguages[lang]]].value);
                }
            }
            this.translate.addLangs(languages);
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Tells the app to use a specific language (code).
     * @param {string} language The language (name).
     * @returns {Observable<any>} An observable.
     */
    useLanguage(language: cat.SupportedLanguages): Observable<any> {
        try {
            let result: string = this.defaultLanguage;
            if (protosui.def.SupportedLanguages[cat.SupportedLanguages[language]]?.value) {
                result = protosui.def.SupportedLanguages[cat.SupportedLanguages[language]].value.toLowerCase();
            }
            return this.translate.use(result);
        } catch (error) {
            throw new Error(error);
        }
    }
}

export class TranslatePoHttpLoader implements TranslateLoader {

    /** Translation domain */
    public domain = "";

    /** Default constructor */
    constructor(
        protected _http: HttpClient,
        protected _prefix: string = "i18n",
        protected _suffix: string = ".po"
    ) {}

    /**
     * Gets the translations from file.
     * @param {string} lang The language.
     * @returns {Observable<any>} An observable.
     */
    public getTranslation(lang: string): Observable<any> {
        return this._http
            .get(`${this._prefix}/${lang}${this._suffix}`, { responseType: "text" })
            .pipe(map((contents: string) => this.parse(contents)));
    }

    /**
     * Parse a po file and return the translations.
     * @param {string} contents The content of the PO file.
     * @returns {{ [key: string]: string }} The translations.
     */
    public parse(contents: string): { [key: string]: string } {
        const translations: { [key: string]: string } = {};
        const po = gettext.po.parse(contents, "utf-8");
        // eslint-disable-next-line no-prototype-builtins
        if (!po.translations.hasOwnProperty(this.domain)) {
            return translations;
        }

        Object.keys(po.translations[this.domain])
            .forEach(key => {
                const translation: string = po.translations[this.domain][key].msgstr.pop();
                if (key.length > 0 && translation.length > 0) {
                    translations[key] = translation;
                }
            });

        return translations;
    }
}
