
<div class="cat-grid">

    <div *ngIf="(permissionEnum.PERMISSION_SET_ANNOTATIONS | hasPermission)" class="cat-row">

        <div class="cat-col cat-flex-grow cat-no-min-width cat-nooverflow-ellipsis">
            <form [formGroup]="annotationForm" (ngSubmit)="addAnnotation()" class="cat-full-width cat-no-margin cat-padding-top-md" ngNativeValidate>
                <textarea-field
                    [data]="fieldData.annotation"
                    [focus]="true"
                    [submitted]="submitAttempt"
                    [control]="annotationForm.get('annotation')">
                </textarea-field>
            </form>
        </div>
    </div>
    <div class="cat-row cat-align-center cat-margin-bottom-xxl">

        <div class="cat-col cat-flex-grow">
            <button
                type="button"
                [disabled]="(isLoading$ | async) || (annotationLoading$ | async)"
                (click)="addAnnotation()"
                class="cat-width-150"
                mat-flat-button extended color="primary">
                <mat-icon>add</mat-icon>
                {{ uitext.annotation | translate }}
            </button>
        </div>

        <div class="cat-col">
            <input (change)="handleFileInput($event.target.files)" type="file"/>
        </div>
    </div>


    <ng-container *ngIf="annotations$|async as annotations">
        <div
            class="cat-row cat-margin-bottom-xl cat-background-light-shade cat-padding-sm cat-border-radius"
            *ngFor="let annotation of annotations let index = index trackBy: common.trackById">

            <div class="cat-col cat-no-margin cat-margin-right-sm">
                <mat-icon class="cat-animation-fade-down cat-align-self-center" color="primary" align-self-center>chatbubble</mat-icon>
            </div>

            <div class="cat-col cat-flex-grow">
                <div class="cat-grid cat-padding-x-lg">
                    <div *ngIf="annotation" class="cat-nowrap cat-row">
                        <div class="cat-col cat-flex-grow">
                            <p class="cat-no-padding cat-no-margin cat-preline">
                                {{ annotation.annotation || '&nbsp;' }}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="annotation.created" class="cat-nowrap cat-row cat-margin-top-sm">
                        <div class="cat-col">
                            <span color="dark-tint">{{ annotation.created | date: 'd MMMM y, HH:mm' : undefined : tl.locale }} - {{ uitext.by | translate }} {{ annotation.user.fullname }}</span>
                        </div>
                    </div>
                    <div *ngIf="(annotation.attachment | hasFile)" class="cat-nowrap cat-row cat-margin-top-sm">
                        {{ uitext.download | translate }}
                        <a
                            color="primary"
                            class="cat-padding-left-sm"
                            download
                            [href]="annotation.attachment.files[0] | catsrc : 'download' : mediaType.MEDIA_ANNOTATIONS : _accountId">
                            {{ annotation.attachment.files[0].filename }}
                        </a>
                    </div>
                </div>
            </div>


            <div class="cat-col cat-push-right cat-no-margin">
                <button mat-icon-button (click)="promptAlert(annotation); $event.stopPropagation();">
                    <mat-icon name="trash-outline" color="danger">delete</mat-icon>
                </button>
            </div>

            <hr>
        </div>
    </ng-container>
</div>