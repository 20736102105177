<gallery-filter
    class="cat-margin-top-xl"
    (newQueryEvent)="updateQuery($event)"
    [countpath]="countpath"
    [path]="path">
</gallery-filter>

<main (scroll)="onScroll()" #content class="cat-padding-md cat-overflow-y">
    <div class="cat-grid-wrapper cat-margin-bottom-xl">
        @if ((mediafiles$|async); as mediafiles) {
            @for (mediafile of mediafiles; track mediafile) {
                <gallery-item
                    [refId]="account?.id"
                    [accountId]="account?.id"
                    [conversationId]="conversation?.id"
                    [mediatype]="mediaTypeChat"
                    [mediafile]="mediafile">
                </gallery-item>
            } @empty {
                @if (!(isLoading$ | async)) {
                    <div class="cat-width-250 cat-padding-xl">
                        {{ uitext.notfound | translate }}
                    </div>
                }
            }
        }
    </div>
</main>