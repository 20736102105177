import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { CommonService } from "@services/common/common.service";
import { TlService } from "@services/tl/tl.service";


/** Decorator that marks a class as an Angular component */
@Component({
    selector: "reaction",
    templateUrl: "./reaction.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

/** Header component class */
export class ReactionComponent {

    /** Decorator that marks a class field as an input property */
    @Input() reaction: cat.ReactionMsg = cat.ReactionMsg.create();
    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    /** Decorator that marks a class field as an input property */
    @Input() loading = false;

    public mediaType = cat.MediaType;

    /** Constructor */
    constructor(
        /** Public common service */
        public common: CommonService,
        public tl: TlService) {}
}
