// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { AfterViewInit, Directive, Input } from "@angular/core";

@Directive({
    selector: "[gridResize]",
    host: {
        '(window:resize)': 'onResize()',
    }
})
export class GridResizeDirective implements AfterViewInit {

    @Input() set resizeNow(resize: boolean) {
        if (resize) {
            this.onResize();
        }
    }

    /**
     * Call resize on after Angular initializes the component's views and child views, or the view that contains the directive.
     *
     * @memberof GridResizeDirective
     */
    ngAfterViewInit(): void {
        this.onResize();
    }

    /**
     * Go over all grid items and resize them.
     *
     * @private
     * @memberof GridResizeDirective
     */
    private onResize() {
        const allItems = (document.getElementsByClassName("grid-item") as HTMLCollectionOf<HTMLElement>);
        for(let x = 0; x < allItems.length; x++){
            this.resizeGridItem(allItems[x]);
        }
    }

    /**
     * Resize all items correctly.
     *
     * @private
     * @param {HTMLElement} item The grid item.
     * @memberof GridResizeDirective
     */
    private resizeGridItem(item: HTMLElement){
        const grid = document.getElementsByClassName("cat-masonry-grid")[0];
        const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
        const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
        const rowSpan = Math.ceil((item.querySelector('.item-container').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
        item.style.gridRowEnd = "span " + (rowSpan - 1);
    }
}

