<div class="cat-nooverflow-ellipsis">

    <div class="cat-padding-xl">

        <h4 class="cat-no-margin">
            {{ "{Accounts} found on this device" | translate | replaceTerm }}
        </h4>

        <div class="cat-overflow-y cat-margin-top-xl cat-height-250">
            <div
                *ngFor="let account of accounts let i = index"
                (click)="dismiss()"
                class="cat-margin-bottom-xl">

                <div class="cat-margin-bottom-sm">
                    {{ account | accountHandle }}
                </div>

                <div color="medium">
                    ({{ account.externid }})
                </div>

            </div>
        </div>

    </div>

</div>
