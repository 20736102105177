import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";

/**
 * Check if a given attachment has a thumb.
 *
 * @export
 * @class HasThumbPipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: "hasThumb",
    pure: true
})


export class HasThumbPipe implements PipeTransform {
    transform(item: cat.AttachmentMsg | cat.IAttachmentMsg): boolean {
        if ((item?.files?.[0]?.thumbnailstatus === cat.ThumbnailStatus.THUMBNAILSTATUS_COMPLETE) && item?.files?.[0]?.thumbfileid && item?.files?.[0]?.thumbmimetype) {
            return true;
        } else {
            return false;
        }
    }
}
