import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";

@Pipe({
    name: "conversationstatus",
    pure: true
})
/**
 * Check if an id exists in an array of items
 *
 * @export
 * @class HasIdPipe
 * @implements {PipeTransform}
 */
export class ConversationStatusPipe implements PipeTransform {

    constructor(
        private _logger: LoggerService
    ) {}

    transform(conversation: cat.ConversationMsg): string {

        let result = "";

        if (conversation) {
            // Check if the conversation is a group
            if ((conversation.type === cat.ConversationType.CONVERSATION_GROUP) ||
                (conversation.type === cat.ConversationType.CONVERSATION_VOICE) ||
                (conversation.type === cat.ConversationType.CONVERSATION_THREAD) ||
                (conversation.type === cat.ConversationType.CONVERSATION_SUPERGROUP) ||
                (conversation.type === cat.ConversationType.CONVERSATION_CHANNEL) ||
                (conversation.type === cat.ConversationType.CONVERSATION_PUBLIC)) {
                    // Return the member count if it is a group and the count is greater than 2
                    if (conversation.membercount?.count > 2) {
                        result = `${conversation.membercount.count} ${protosui.messages.uitext.members}`;
                    } else {
                        result = `${protosui.messages.uitext.nomembercount}`;
                    }

            } else if (conversation.type === cat.ConversationType.CONVERSATION_FEED) {
                result = protosui.messages.uitext.feed;
            }
        }
        return result.trim();
    }
}
