import { Component, ChangeDetectionStrategy, OnInit, Input, OnDestroy } from "@angular/core";
import { CommonService } from "@services/common/common.service";
import { FormControl } from "@angular/forms";
import { IFormFieldData } from "@shared/app-models";

@Component({
    selector: "input-field",
    templateUrl: "./input-field.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class InputFieldComponent implements OnInit, OnDestroy {
    // Inputs
    @Input() data: IFormFieldData;
    @Input() submitted: boolean;
    @Input() focus: boolean = false;
    @Input() control: FormControl;

    constructor(
        public common: CommonService) {}

    ngOnInit() {
        // this._logger.debug("INIT INPUT FIELD");
    }

    ngOnDestroy(): void {
        // this._logger.debug("DESTROY INPUT FIELD");
    }
}