<div class="cat-nooverflow-ellipsis">

    <div class="cat-padding-xl">

        <h4 class="cat-no-margin">
            {{ uitext.select | translate }}
        </h4>


        <div class="cat-overflow-y cat-margin-top-xl cat-height-250">
            <div class="cat-item cat-margin-bottom-sm cat-no-background">
                <button mat-stroked-button (click)="selectItem('clear')" color="primary">
                    {{ uitext.clear | translate }}
                </button>
            </div>

            <ng-container *ngFor="let item of list trackBy: common.trackById">
                <div class="cat-item cat-margin-bottom-sm cat-no-background">
                    <button mat-stroked-button (click)="selectItem(item)" [disabled]="currentItem?.profile?.id === item.id" color="primary">
                        {{ item.fullname }}
                    </button>
                </div>
            </ng-container>

            <div *ngIf="([permissionEnum.PERMISSION_MANAGE_MY_PROFILES] | hasPermission)" class="cat-item cat-no-background cat-margin-top-lg">

                <mat-form-field subscriptSizing="dynamic" color="white" appearance="fill">
                    <mat-label>{{ uitext.add | translate }}</mat-label>
                    <input matInput
                        (keyup.enter)="userAddProfile()"
                        placeholder="{{ 'Add new {profile}' | translate | replaceTerm }}"
                        clearInput
                        [(ngModel)]="newProfileName"
                        autocorrect="off"
                        autocomplete="off"
                        inputmode="text">
                </mat-form-field>

            </div>
        </div>
    </div>
</div>