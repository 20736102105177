<button (click)="openDetails()" mat-menu-item extended color="primary">
    <mat-icon color="primary">info</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <span color="primary"><b>{{ uitext.info | translate }}</b></span>
        <br>
        <span color="medium">{{ "About this conversation" | translate }}</span>
    </div>
</button>

<button (click)="hideConversation()" mat-menu-item extended color="primary">
    <mat-icon color="primary">visibility_off</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <ng-container *ngIf="chat.visibility === visibilityType.VISIBILITY_VISIBLE">
            <span color="primary"><b>{{ uitext.hideconversation | translate }}</b></span>
        </ng-container>

        <ng-container *ngIf="chat.visibility === visibilityType.VISIBILITY_HIDDEN">
            <span color="primary"><b>{{ uitext.unhideconversation | translate }}</b></span>
        </ng-container>
        <br>
        <span color="medium">{{ "Set conversation visibility" | translate }}</span>
    </div>
</button>

<button *ngIf="(permissionEnum.PERMISSION_CREATE_PROFILE_REPORTS | hasPermission)" (click)="createReport()" mat-menu-item extended color="primary">
    <mat-icon color="primary">{{ reportIcon.matname }}</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <span color="primary"><b>{{ "Create conversation report" | translate }}</b></span>
        <br>
        <span color="medium">{{ "Go to the report wizard" | translate }}</span>
    </div>
</button>

