import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef, Inject, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { appRouteNames } from "@app/app.routes.names";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { cat } from "@assets/proto/msgs";
import { userAddContactAnnotation, userSetContactLabel, userGetContactAnnotations, userGetContactLabels, userGetContactReport, userGetContactMerges, userSetContactAlias, userGetProfileContacts, userGetContactConversations, IServiceDescriptor, userRemoveContactAnnotation, userUnsetContactLabel, userUnmergeContact, userMergeContacts, userGetProfileContact } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { FormDialog } from "@modals/form-dialog/form-dialog.modal";
import { IState } from "@shared/app-models";
import { Store } from "@ngxs/store";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { ReportWizardService } from "@services/report-wizard/report-wizard.service";
import { TlService } from "@services/tl/tl.service";
import { WsService } from "@services/ws/ws.service";
import { clearList, clearStoredMessage } from "@store/actions";
import { modifyInfiteList } from "@store/actions";
import { cloneDeep } from "lodash-es";
import { Observable } from "rxjs";
import { MediaDetailsDialog } from "@modals/detail-pages/media-details/media-details.dialog";
import { getList } from "@store/store";
import * as models from "@shared/app-models";
import { AuthService } from "@services/auth/auth.service";
import { SubscriptionManager } from "@shared/subscription-manager";
@Component({
    selector: "contact-details",
    templateUrl: "./contact-details.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactDetailsDialog extends SubscriptionManager implements OnInit, OnDestroy {

    apptypes$: Observable<Map<string, cat.AppTypeMsg>> = inject(Store).select((state: IState) => state.cat.userGetAppTypes.list);
    isLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetContactConversations.isLoading);
    isContactMergesLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetContactMerges.isLoading);
    annotationLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userAddAnnotationAttachment.isLoading);
    contact$: Observable<cat.AccountMsg> = inject(Store).select((state: IState) => state.cat.userGetProfileContact.msg);
    conversations$: Observable<cat.ConversationMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetContactConversations));
    mergedcontacts$: Observable<cat.AccountMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetContactMerges));

    // Enums
    public mediaType = cat.MediaType;
    public contactActionEnum = models.ContactAction;
    public permissionEnum = cat.Permission;

    // Template component calls
    public getAnnotations: IServiceDescriptor = userGetContactAnnotations;
    public addAnnotations: IServiceDescriptor = userAddContactAnnotation;
    public removeAnnotations: IServiceDescriptor = userRemoveContactAnnotation;
    public getLabels: IServiceDescriptor = userGetContactLabels;
    public setLabel: IServiceDescriptor = userSetContactLabel;
    public unsetLabel: IServiceDescriptor = userUnsetContactLabel;

    // Nav params
    public profileId = "";
    public accountId = "";
    public contactId = "";

    // Info
    public icon: models.IIconDefinition = protosui.msgIcons.AccountMsg;
    public messageDefinitions = messageDefinitions;
    public definition: models.IMessageDefinitions;
    public uitext = protosui.messages.uitext;

    // Header
    public buttons: models.IHeaderButton[] = [
        { text: this.uitext.contactreport, icon: { matname: "article" }, permissions: [cat.Permission.PERMISSION_CREATE_PROFILE_REPORTS]  }
    ]

    // Conversation table
    public conversationColumns = ["apptype", "name", "type"];
    public conversationType = cat.ConversationType;
    public conversationTypeEnum = protosui.def.ConversationType;

    // Merged contacts table
    public contactColumns = ["avatar", "app", "externid", "username", "name", "alias", "servernicknames"];

    // History
    public refreshTimeline: number = 0;

    constructor(
        public tl: TlService,
        public common: CommonService,
        private _auth: AuthService,
        private _store: Store,
        private _ws: WsService,
        private _dialogRef: MatDialog,
        private _dialog: MatDialog,
        private _router: Router,
        private _reportService: ReportWizardService,
        private _cdr: ChangeDetectorRef,
        private _logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) data: models.IContactDetailData) {
            super();
            // Assign properties.
            this.definition = this.common.getMessageDefinitions(messageDefinitions.AccountMsg);
            this.accountId = data.accountId
            this.profileId = data.profileId;
            this.contactId = data.contact?.id;
    }

    /**
     * Request the initial calls.
     *
     * @memberof ContactDetailsDialog
     */
    async ngOnInit() {
        await this.userGetProfileContact(this.contactId, this.profileId);
    }

    /**
     * Clear the store from annotations, details and labels.
     *
     * @memberof ContactDetailsDialog
     */
    ngOnDestroy(): void {
        this._logger.debug("Destroy");
        this._store.dispatch(new clearStoredMessage(userGetProfileContact.methodName, messageDefinitions.AccountMsg));
        this._store.dispatch(new clearList(userGetContactConversations.methodName));
        this._store.dispatch(new clearList(userGetContactAnnotations.methodName));
        this._store.dispatch(new clearList(userGetContactMerges.methodName));
        // Ensure all subscriptions are removed (synchronous and not using callbacks)
        this.unsubscribeAll();
    }

    /**
     * Actions emitted from the modal header.
     *
     * @param {models.IHeaderButton} event
     * @memberof ContactDetailsDialog
     */
    public async headerAction(event: models.IHeaderButton) {
        switch (event.text) {
            case this.uitext.unmergecontact: {
                this.userUnmergeContact();
                break;
            }
            case this.uitext.contactreport: {
                const contact = this._store.selectSnapshot((state: IState) => state.cat.userGetProfileContact.msg);
                await this.createReport(contact);
                break;
            }
        }
    }

    /**
     * Get the contact details.
     *
     * @private
     * @param {string} contactId
     * @param {string} profileId
     * @memberof ContactDetailsDialog
     */
    private async userGetProfileContact(contactId: string, profileId: string) {
        try {

            if (!contactId || !profileId) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            const payload = cat.AccountMsg.create({
                id: contactId,
                profile: cat.ProfileMsg.create({
                    id: profileId
                })
            })
            await this._ws.sendRequest(userGetProfileContact, payload);
            const contact = this._store.selectSnapshot((sate: IState) => sate.cat.userGetProfileContact.msg);

            if (contact.contacts.length) {
                this.buttons = [...this.buttons, { text: this.uitext.unmergecontact, icon: { matname: "link_off" }, permissions: []  }];
                this.common.detectChange(this._cdr);
            }
            this.refreshTimeline = Date.now();

        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
     * Handler for tab change events.
     *
     * @param {MatTabChangeEvent} event
     * @memberof ContactDetailsDialog
     */
    public async tabChange(event: MatTabChangeEvent) {

        if (!event || !event.tab?.ariaLabel) {
            this._logger.error("No label text / event found.");
        }

        const label = event.tab.ariaLabel.toLowerCase();

        if (label === this.uitext.conversations.toLowerCase()) {
            await this.userGetContactConversations();
        }
        if (label === this.uitext.mergedcontacts.toLowerCase()) {
            await this.userGetContactMerges();
        }
        if (label === this.uitext.history.toLowerCase()) {
            await this.userGetProfileContact(this.contactId, this.profileId);
        }
    }

    /**
     * Go to a specific conversation.
     *
     * @param {cat.ConversationMsg} chat The selected conversation.
     * @memberof ContactDetailsDialog
     */
    public async gotoConversation(chat: cat.ConversationMsg) {
        if (chat.id && chat.account.id) {
            await this._router.navigate([`${appRouteNames.DASHBOARD}/profile/${this.profileId}/account/${chat.account.id}/chat/${chat.id}`], { queryParams: { chatid: chat.id, serverid: chat.server?.id }});
            await this.common.dismissAllOverlays();
        } else {
            this._logger.error("No account or chat found to navigate to.");
        }
    }

     /**
     * Go to a specific merged contact.
     *
     * @param {cat.AccountMsg} contact The selected contact.
     * @memberof ContactDetailsDialog
     */
    public async gotoMergedContact(contact: cat.AccountMsg) {
        await this.common.dismissAllOverlays();
        await this.userGetContactMerges();
        const dialogData: models.IContactDetailData = {
            accountId: this.accountId,
            contact: contact,
            contactAction: this.contactActionEnum.View,
            profileId: this.profileId
        };
        this._logger.debug("Dialog Data: ", dialogData);
        this._dialog.open(ContactDetailsDialog, {
            width: "95vw",
            height: "95vh",
            data: dialogData
        });
    }

    /**
     * Create a report from a contact.
     *
     * @memberof ContactDetailsDialog
     */
    public async createReport(contact: cat.AccountMsg) {
        try {
            if (!this.profileId) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            // Construct the payload to retrieve the report definition.
            const payload: cat.AccountMsg = cat.AccountMsg.create({
                id: contact.id
            });

            // Allow the user to choose between a single or merged contact.
            if (contact.contacts?.length) {

                // Show a dialog with a yes / no choice.
                const choiceDialog = this.common.createDialogReference({
                    title: protosui.messages.uitext.pleaseselect,
                    content: protosui.messages.uitext.mergedreportdesc,
                    buttons: [
                        {
                            title: protosui.messages.uitext.yes,
                            color: "primary",
                            action: "merged"
                        },
                        {
                            title: protosui.messages.uitext.no,
                            color: "primary",
                            action: "proceed"
                        }
                    ]
                });

                choiceDialog.afterClosed().subscribe(async (result: models.IDialogButton["action"]) => {

                    const query = cat.QueryMsg.create();

                    if (result === "proceed") {
                        // Unmerged contact report, add exclude filter.
                        query.filters.push(
                            cat.FilterMsg.create({
                                collection: "contacts",
                                operator: cat.FilterOperator.FILTER_EXCLUDE,
                                field: "contacts",
                                value: ""
                            })
                        )
                    }
                    if (result === "merged") {
                        // Merged contact report, add include filter.
                        query.filters.push(
                            cat.FilterMsg.create({
                                collection: "contacts",
                                operator: cat.FilterOperator.FILTER_INCLUDE,
                                field: "contacts",
                                value: ""
                            })
                        )
                    }

                    // Fetch the contact report definition, and store it in the report service.
                    await this._ws.sendRequest(userGetContactReport, payload, undefined, undefined, query);
                    const report = this._store.selectSnapshot((state: IState) => state.cat.userGetContactReport)?.msg;
                    this._reportService.startDuplicateReport(report);

                    // Make sure the reporter navigates back here.
                    this._auth.setBackPath(this._router.url);

                    await this._router.navigate([`${appRouteNames.DASHBOARD}/profile/${this.profileId}/${appRouteNames.MANAGE_PROFILE_RERORTS}/${appRouteNames.REPORT_TEMPLATE}`]);
                    await this.common.dismissAllOverlays();
                });
            } else {
                // Fetch the contact report definition, and store it in the report service.
                await this._ws.sendRequest(userGetContactReport, payload);
                const report = this._store.selectSnapshot((state: IState) => state.cat.userGetContactReport)?.msg;
                this._reportService.startDuplicateReport(report);

                // Make sure the reporter navigates back here.
                this._auth.setBackPath(this._router.url);

                await this._router.navigate([`${appRouteNames.DASHBOARD}/profile/${this.profileId}/${appRouteNames.MANAGE_PROFILE_RERORTS}/${appRouteNames.REPORT_TEMPLATE}`]);
                await this.common.dismissAllOverlays();
            }

        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(error);
        }
    }

    /**
    * Unmerge the contact
    */
    public async userUnmergeContact() {
        try {
            const payload: cat.AccountMsg = cat.AccountMsg.create({ id: this.contactId });
            await this._ws.sendRequest(userUnmergeContact, payload);
            this.common.createSnackbar(protosui.messages.uitext.unmerged);

            const newContact: cat.AccountMsg = this._store.selectSnapshot((state: IState) => state.cat.userGetProfileContacts).list.get(payload.id);
            const messageType: string = userMergeContacts.requestType;

            // Update store and header buttons.
            if (newContact) {
                newContact.contacts = [];
                this._store.dispatch(new modifyInfiteList(newContact, messageType, userGetProfileContacts.methodName));
                this.buttons = this.buttons.filter((item: models.IHeaderButton) => item.text !== this.uitext.unmergecontact);
            }
            this.common.detectChange(this._cdr);
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
     * Get all conversations linked to a contact.
     *
     * @memberof ContactDetailsDialog
     */
    public async userGetContactConversations() {
        try {
            if (!this.profileId) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }
            const payload: cat.AccountMsg = cat.AccountMsg.create({
                id: this.contactId
            });
            await this._ws.sendRequest(userGetContactConversations, payload);
        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(error);
        }
    }

    /**
     * Get all merged contacts for a contact.
     *
     * @memberof ContactDetailsDialog
     */
    public async userGetContactMerges() {
        try {
            if (!this.profileId) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }
            const payload: cat.AccountMsg = cat.AccountMsg.create({
                id: this.contactId
            });
            await this._ws.sendRequest(userGetContactMerges, payload);
        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(error);
        }
    }

    /**
     * Save the alias to the contact details.
     *
     * @memberof ContactDetailsDialog
     */
    public async saveAlias(alias: string, contact: cat.AccountMsg) {
        try {
            if (!this.profileId) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }
            const payload: cat.AccountMsg = cat.AccountMsg.create({
                id: this.contactId,
                alias: alias
            });
            await this._ws.sendRequest(userSetContactAlias, payload);
            this.common.createSnackbar(protosui.messages.uitext.successfullyedited);
            await this.userGetProfileContact(this.contactId, this.profileId);
        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(error);
        }
    }

    /**
     * Open the form dialog to edit a field.
     *
     * @param {string} name
     * @memberof ContactDetailsDialog
     */
    openFormDialog(name: string, contact: cat.AccountMsg) {
        const msg = cloneDeep(contact);
        const data: models.IFormDialogData = {
            msg: msg,
            definition: this.definition,
            name: name
        }
        const dialogRef = this._dialogRef.open(FormDialog, {
            data: data
        });
        dialogRef.afterClosed().subscribe((result: models.IFormDialogReturnData) => {
            this._logger.debug(`Dialog result: `, result);
            if (result?.data?.msg) {
                this.saveAlias(result.data.msg?.alias, msg);
            }
        });
    }

    /**
    * Enlarge the contact avatar.
    * @param {cat.AccountMsg} account The current contact, with avatar.
    */
    async openMedia(account: cat.AccountMsg) {
        try {

            const refId = (this.accountId) ? this.accountId : account?.accountrefid;

            if (!account?.id || !account?.avatar || !refId) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            const attachment: cat.AttachmentMsg = cat.AttachmentMsg.create(account.avatar);

            const dialogData: models.IImageDetailPageData = {
                attachment: attachment,
                refId: refId,
                accountId: refId,
                mediatype: cat.MediaType.MEDIA_ACCOUNTAVATARS,
                asProfile: true,
                profileId: this.profileId
            };
            this._logger.debug("Dialog Data: ", dialogData);
            this._dialog.open(MediaDetailsDialog, {
                width: "95vw",
                height: "95vh",
                data: dialogData
            });

        } catch (error) {
            this._logger.error(error);
        }
    }
}


