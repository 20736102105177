// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { catAuthGuard } from "@services/auth/auth.service";
import { Store } from '@ngxs/store';
import { RouterModule, Route } from "@angular/router";
import { NgModule } from "@angular/core";
import { appRouteNames } from "@app/app.routes.names";
import { protosui } from "@definitions/definitions";
import { cat } from "@assets/proto/msgs";
import { IState, RouterData } from "@shared/app-models";
import { BehaviorSubject } from "rxjs";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { cloneDeep } from "lodash-es";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import * as calls from "@assets/proto/services";

/** The order of the routes are important, also the type should be Route[] instead of
 * Routes, to prevent errors in both documentation and production
 */
export const APP_ROUTES: Route[] = [
    {
        path: appRouteNames.DISCONNECTED,
        loadChildren: () => import("src/pages/disconnected/disconnected.module").then(mod => mod.DisconnectedPageModule),
        data: RouterData.create({
            permissions: [],
            icon: protosui.msgIcons.disconnected
        })
    },
    {
        path: appRouteNames.PREREQUISITES,
        loadChildren: () => import("@pages/prerequisites/prerequisites.module").then(mod => mod.PrerequisitesPageModule),
        data: RouterData.create({
            permissions: [],
            icon: protosui.msgIcons.prerequisites
        })
    },
    {
        path: appRouteNames.LOGIN,
        loadChildren: () => import("src/pages/login/login.module").then(mod => mod.LoginPageModule),
        data: RouterData.create({
            permissions: [],
            icon: protosui.msgIcons.login,
            post: {
                call: calls.userSignIn,
                permissions: [cat.Permission.PERMISSION_ESSENTIAL]
            },
            filter: {
                includes: ["username", "password"]
            }
        })
    },
    {
        path: `${appRouteNames.USER_SETTINGS}/:userid`,
        loadChildren: () => import("@pages/user-settings/user-settings.module").then(mod => mod.UserProfilePageModule),
        data: RouterData.create({
            permissions: [],
            icon: protosui.msgIcons.usersettings
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: `${appRouteNames.NOTIFICATIONS}`,
        loadChildren: () => import("@pages/notifications/notifications.module").then(mod => mod.NotificationsPageModule),
        data: RouterData.create({
            permissions: [],
            icon: protosui.msgIcons.NotificationMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.DASHBOARD,
        loadChildren: () => import("src/pages/dashboard/dashboard.module").then(mod => mod.DashboardPageModule),
        data: RouterData.create({
            title: protosui.messages.uitext.dashboard,
            permissions: [],
            icon: protosui.msgIcons.dashboard
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.USER_PROFILES,
        loadChildren: () => import("src/pages/profiles/user-profiles/user-profiles.module").then(mod => mod.UserProfilesPageModule),
        data: RouterData.create({
            permissions: [
                cat.Permission.PERMISSION_MANAGE_MY_PROFILES,
                cat.Permission.PERMISSION_VIEW_ASSIGNED_PROFILES,
                cat.Permission.PERMISSION_MANAGE_ASSIGNED_PROFILES
            ],
            icon: protosui.msgIcons.ProfileMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_PROFILES,
        loadChildren: () => import("src/pages/profiles/manage-profiles/manage-profiles.module").then(mod => mod.ManageProfilesPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_PROFILES],
            icon: protosui.msgIcons.manageprofile
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_CASES,
        loadChildren: () => import("@pages/cases/manage-cases/manage-cases.module").then(mod => mod.ManageCasesPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_CASES],
            icon: protosui.msgIcons.CaseMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.USER_CASES,
        loadChildren: () => import("@pages/cases/user-cases/user-cases.module").then(mod => mod.UserCasesPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MY_CASES],
            icon: protosui.msgIcons.CaseMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_USERS,
        loadChildren: () => import("@pages/users/manage-users/manage-users.module").then(mod => mod.ManageUsersPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_USERS],
            icon: protosui.msgIcons.UserMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_ROLES,
        loadChildren: () => import("@pages/roles/manage-roles/manage-roles.module").then(mod => mod.ManageRolesPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_ROLES],
            icon: protosui.msgIcons.RoleMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_DEVICES,
        loadChildren: () => import("src/pages/devices/manage-devices/manage-devices.module").then(mod => mod.ManageDevicesPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_DEVICES],
            icon: protosui.msgIcons.DeviceMsg
        }),

        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_TERMINOLOGY,
        loadChildren: () => import("src/pages/manage-terminology/manage-terminology.module").then(mod => mod.ManageTerminologyPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_TERMINOLOGY],
            icon: protosui.msgIcons.TerminologyMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_SYSTEM_SETTINGS,
        loadChildren: () => import("src/pages/manage-system-settings/manage-system-settings.module").then(mod => mod.ManageSystemSettingsPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_SYSTEM_SETTINGS],
            icon: protosui.msgIcons.settings
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_DOMAINS,
        loadChildren: () => import("@pages/domains/manage-domains/manage-domains.module").then(mod => mod.ManageDomainsPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_DOMAINS],
            icon: protosui.msgIcons.DomainMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_THEMES,
        loadChildren: () => import("@pages/themes/manage-themes/manage-themes.module").then(mod => mod.ManageThemesPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_THEMES],
            icon: protosui.msgIcons.ThemeMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_REPORTTEMPLATES,
        loadChildren: () => import("@pages/reporting/manage-report-templates/manage-report-templates.module").then(mod => mod.ManageReportTemplatesPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_REPORT_TEMPLATES],
            icon: protosui.msgIcons.ReportTemplateMsg,
            get: {
                call: calls.userGetReportTemplates,
                permissions: [cat.Permission.PERMISSION_MANAGE_REPORT_TEMPLATES],
            }
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_GLOBALLABELS,
        loadChildren: () => import("@pages/labels/manage-labels/manage-labels.module").then(mod => mod.ManageLabelsPagePageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_GLOBAL_LABELS],
            icon: protosui.msgIcons.LabelMsg,
            select: {
                msg: messageDefinitions.LabelMsg
            },
            get: {
                call: calls.userGetGlobalLabels,
                permissions: [cat.Permission.PERMISSION_MANAGE_GLOBAL_LABELS],
            },
            post: {
                call: calls.userAddGlobalLabel,
                permissions: [cat.Permission.PERMISSION_MANAGE_GLOBAL_LABELS],
            },
            remove: {
                call: calls.userRemoveGlobalLabel,
                permissions: [cat.Permission.PERMISSION_MANAGE_GLOBAL_LABELS],
            },
            filter: {
                bind: ["name", "value"]
            }
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_LOGS,
        loadChildren: () => import("src/pages/manage-logs/manage-logs.module").then(mod => mod.ManageLogsPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_LOGS],
            icon: protosui.msgIcons.ServiceStatusMsg,
            readonly: true,
            select: {
                msg: messageDefinitions.ServiceStatusMsg
            },
            get: {
                call: calls.userGetServiceStatuses,
                permissions: [cat.Permission.PERMISSION_MANAGE_LOGS],
            }
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_LICENSES,
        loadChildren: () => import("@pages/licenses/manage-licenses.module").then(mod => mod.ManageLicensesPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_LICENSES],
            icon: protosui.msgIcons.LicenseMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_USER_SUPERVISION,
        loadChildren: () => import("src/pages/manage-user-supervision/manage-user-supervision.module").then(mod => mod.ManageUserSupervisionPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_USER_SUPERVISION],
            icon: protosui.msgIcons.manageusersupervision,
            readonly: true
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.VIEW_DEVICES,
        loadChildren: () => import("@pages/devices/view-devices/view-devices.module").then(mod => mod.ViewDevicesPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_VIEW_DEVICES],
            icon: protosui.msgIcons.DeviceMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.HELP,
        loadChildren: () => import("src/pages/help/help.module").then(mod => mod.HelpPageModule),
        data: RouterData.create({
            permissions: [],
            icon: protosui.msgIcons.help,
            title: protosui.messages.uitext.help,
            name: "help",
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.MANAGE_APK,
        loadChildren: () => import("@pages/manage-apks/apk.module").then(mod => mod.ApkPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_MANAGE_APKS],
            icon: protosui.msgIcons.APKMsg,
            name: "help",
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.AUDIT,
        loadChildren: () => import("@pages/audit-trail/audit-table/audit-table.module").then(mod => mod.AuditTablePageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_VIEW_AUDIT_TRAIL],
            icon: protosui.msgIcons.AuditLogMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.VIEW_SYSTEMEVENTS,
        loadChildren: () => import("@pages/view-systemevents/view-systemevents.module").then(mod => mod.ViewSystemeventsPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_VIEW_SYSTEM_EVENTS],
            icon: protosui.msgIcons.SystemeventMsg
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.SYSTEMWIDE_SEARCH,
        loadChildren: () => import("@pages/systemwide-search/systemwide-search.module").then(mod => mod.SystemwideSearchPageModule),
        data: RouterData.create({
            permissions: [cat.Permission.PERMISSION_SEARCH_ACCOUNTS_SYSTEM_WIDE],
            icon: protosui.msgIcons.systemwidesearch
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: appRouteNames.LOGOUT,
        loadChildren: () => import("src/pages/disconnected/disconnected.module").then(mod => mod.DisconnectedPageModule),
        data: RouterData.create({
            permissions: [],
            icon: {
                matname: "logout"
            },
            name: "logout",
            title: "Logout"
        }),
        canActivate: [catAuthGuard]
    },
    {
        path: "**",
        redirectTo: appRouteNames.DASHBOARD,
        pathMatch: "full"
    }
];

/** Module that imports app routes and exports routermodule */
@NgModule({
    imports: [
        RouterModule.forRoot(APP_ROUTES, {
            bindToComponentInputs: true,
            useHash: true,
            enableTracing: false,
            scrollPositionRestoration: "enabled"
        })
    ],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
    exports: [ RouterModule ]
})
export class AppRoutingModule {
    /** Route update observable */
    public routeUpdate = new BehaviorSubject<boolean>(false);
    private shareRoutes: Array<Route> = [];
    public get: any = (p: any, o: any) => p.reduce((xs: any, x: any) => (xs && xs[x]) ? xs[x] : null, o);

    constructor(private _store: Store) {}

    /**
     * Update the routes after login, based on permissions
     */
    updateRoutes() {
        const baseRoutes: Route[] = this.getRoutes(true);
        const authRoutes: Route[] = [];

        // Get the authRoutes that match route component
        Array.from(this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserPermissions)?.list?.values())
            .sort((p1: cat.PermissionMsg, p2: cat.PermissionMsg) => (protosui.def.Permission[p1.id]?.orderid - protosui.def.Permission[p2.id]?.orderid))
            .map((permission: cat.PermissionMsg) => APP_ROUTES.map((proute: Route) => {
                if (permission.id && proute.data?.permissions?.includes(cat.Permission[permission.id])) {

                    // Avoid read-only object assignment.
                    let route = cloneDeep(proute);

                    // Assign the name defined in the permission enum.
                    const name = protosui.def.Permission[permission.id]?.label;
                    route.data.title = name;

                    // Check for duplicates
                    if (!authRoutes.some((authRoute: Route) => authRoute.path === route.path)) {
                        authRoutes.push(route);
                    }
                }
            }));

        // Insert the authRoutes between the baseroutes
        baseRoutes.splice(1, 0, ...authRoutes);
        this.shareRoutes = baseRoutes;
        this.routeUpdate.next(true);
    }

    /**
     * Clear all the routes on logout for instance
     */
    clearRoutes() {
        this.getRoutes(true);
        this.routeUpdate.next(true);
    }

    /**
     * Return the shared routes
     * @param {boolean} init Whether or not it should initialize the routes
     */
    getRoutes(init: boolean): Route[] {
        if (init) {
            this.shareRoutes = (APP_ROUTES.filter(p => p.path === appRouteNames.DASHBOARD || p.path === appRouteNames.LOGOUT || p.path === appRouteNames.HELP));
        }
        return this.shareRoutes;
    }
}