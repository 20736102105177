<mat-form-field *ngIf="!noMaterial" appearance="fill" class="cat-full-width">

    <!-- We need this input to prevent error: mat-form-field must contain a MatFormFieldControl -->
    <input hidden=true matInput>

    <mat-checkbox [formControl]="control" color="primary">
        {{ data.label | translate | replaceTerm }}
    </mat-checkbox>

    <mat-error *ngIf="control.invalid && submitted">
        {{ control.errors | getValidatorMsg : data.validatorList }}
    </mat-error>

    <mat-hint *ngIf="!submitted && control.touched" class="cat-opacity-4">
        {{ control.errors | getValidatorMsg : data.validatorList }}
    </mat-hint>

    <mat-icon *ngIf="data.description" (click)="common.showDescription(data)" matSuffix matTooltip="help" matTooltipPosition="right" color="primary" class="cat-cursor-pointer">
        help
    </mat-icon>

</mat-form-field>


<div *ngIf="noMaterial">
    <input [formControl]="control" type="checkbox" class="cat-margin-right-md cat-cursor-pointer">
    {{ data.label | translate | replaceTerm }}
    <mat-icon *ngIf="data.description" (click)="common.showDescription(data)" matSuffix matTooltip="help" matTooltipPosition="right" color="primary" class="cat-cursor-pointer cat-sm cat-font-md cat-no-margin cat-no-padding">
        help
    </mat-icon>
</div>

