import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";

@Pipe({
    name: "includesMimetype",
    pure: true
})
/**
 * Check if a string starts with an array of mimetypes.
 *
 * @export
 * @class IncludesMimetypePipe
 * @implements {PipeTransform}
 */
export class IncludesMimetypePipe implements PipeTransform {

    constructor(
        private _logger: LoggerService
    ) {}

    transform(value: string, mimetypes: string[]): boolean {
        let result = false;
        // this._logger.debug(`Value: ${value} and mimetype: ${mimetypes}`);
        if (value && mimetypes?.length) {
            result = mimetypes.some(mimetype => value.startsWith(mimetype));
        }
        return result;
    }
}
