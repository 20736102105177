// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { IServiceDescriptor } from "@assets/proto/services";
import { FormGroup, ValidatorFn } from "@angular/forms";
import { cat } from "@assets/proto/msgs";
import { IAppState } from "@assets/proto/store-model";

/**
* Callback types
*/
/**
 * export type TFileHandlerCallback = (isStream: boolean, filename: string, isEnd: boolean, data: Buffer, mimetype: string) => IFileHandler;
 */
export type TFileHandlerCallback = (filehandler: IFileHandler) => Promise<void>;

export type HistoryItem = cat.ConversationMsg | cat.MessageMsg | cat.AccountMsg | cat.ServerMsg | cat.TopicMsg;


export enum EnvName {
    LOCAL = "local",
    HMR = "hmr",
    SERVE = "ngserve",
    PROD  = "production"
}

/**
* Enums for internal use
*/
export enum HeaderType {
    Unknown = 0,
    Conversation = 1,
    Profile = 2,
    Message = 3,
    Account = 4,
    App = 5
}

/**
 *  The MessageAction enum
*/
export enum MessageAction {
    Unknown = 0,
    Annotate = 1,
    Label = 2,
    Attachments = 3,
    Sender = 4,
    Links = 5,
    Info = 6,
    Copy = 8,
    Reactions = 9,
    History = 10
}

/** The AccountAction enum */
export enum AccountAction {
    Unknown = 0,
    View = 1,
    History = 2
}

/** The ContactAction enum */
export enum ContactAction {
    Unknown = 0,
    Annotate = 1,
    Label = 2,
    View = 3,
    Settings = 4,
    Conversations = 5,
    MergedContacts = 6,
    History = 7
}

/** The ContactContext enum */
export enum ContactContext {
    Unknown = 0,
    Profile = 1,
    Case = 2
}

/** The ChatHeaderActions enum */
export enum ChatHeaderActions {
    Unknown = 0,
    OpenInfo = 1,
    OpenSearch = 2,
    CreateReport = 3,
    OpenDetails = 4
}

/** The ConversationAction enum */
export enum ConversationAction {
    Unknown = 0,
    Annotate = 1,
    Label = 2,
    Contact = 3,
    View = 4,
    History = 5,
    Gallery = 6
}

/** The PermissionType enum */
export enum PermissionType {
    Unknown = 0,
    User = 1,
    Case = 2,
    Profile = 3,
    ManagePermissions = 4
}

/**
 * The MessageMode enum
 * */
export enum MessageMode {
    Unknown = 0,
    Conversation = 1,
    Report = 2,
    MsgLink = 3
}

/**
 * The AddGroupMemberMode enum
 * */
export enum AddGroupMemberMode {
    Unknown = 0,
    ToNewGroup = 1,
    ToExistingGroup = 2
}

/**
 * The SelectMemberMode enum
 * */
export enum ChatMemberMode {
    Unknown = 0,
    View = 1,
    Select = 2
}

/** Call action types */
export enum CallAction {
    Unknown = 0,
    Get = "get",
    Post = "post",
    Remove = "remove",
    Upload = "upload"
}

/** The IAlertButtonRoles interface */
export enum AlertButtonRole {
    Unknown = 0,
    Cancel = "cancel",
    Confirm = "confirm"
}

/**
 * The ReportHeader enum
 * */
export enum ReportHeaderTabs {
    Unknown = 0,
    Data = 1,
    Details = 2,
    Text = 3,
    Build = 4
}

/**
 * The ReportingTabs enum
 * */
export enum ReportForm {
    Unknown = 0,
    Apps = 1,
    Labels = 2,
    Chats = 3,
    GlobalRange = 4,
    ChatRange = 5,
    GlobalLowerRange = 6,
    GlobalUpperRange = 7,
    ChatLowerRange = 8,
    ChatUpperRange = 9,
    SearchApps = 10,
    SearchLabels = 11,
    SearchChats = 12,
    SearchMembers = 13,
    SearchSelectedChats = 14,
    SelectAllApps = 15,
    SelectAllLabels = 16,
    SelectAllChats = 17,
    SelectAllMembers = 18,
    ChatRanges = 19,
    Template = 20,
    Members = 21,
    ExcludeMessages = 22
}

/**
 * The AccountStatusAvailability enum
 * */
export enum AccountStatusAvailability {
    Unknown = 0,
    Failed = 1,
    Available = 2,
    Idle = 3,
    Registering = 4
}

/**
 * Store interface
 *
 * @export
 * @interface IState
 */
export interface IState {
    cat: IAppState;
}

/** The IAppVersion action */
export interface IAppVersion {
    /** Version */
    version: string;
    /** Timestamp */
    timestamp: number;
}

/** The IKeycloakSettings interface */
export interface IKeycloakSettings {
    /** URL */
    "url": string;
    /** Realm */
    "realm": string;
    /** clientId */
    "clientId": string;
    /** Javascript adapter */
    "adapter": string;
}

/** The IdDictionary interface */
export interface IdDictionary {
    /** Generic object */
    [id: string]: any;
}

/** The IKeycloak interface */
export interface IKeycloak {
    token: string,
    /** Generic object */
    [id: string]: any;
}

/** The deferred Promise interface */
export interface IDeferredPromise {
    promise?: Promise<unknown>,
    resolve?: any,
    reject?: any
}

/**
 * Id Reference object for outgoing calls
 *
 * @export
 * @interface IIdRef
 */
export interface IIdRef {
    // eslint-disable-next-line @typescript-eslint/ban-types
    cb: IDeferredPromise;
    call: IServiceDescriptor;
    request: any;
    responseStream: boolean;
    query?: cat.IQueryMsg;
}

 /**
 * The language object with language code and name
 */
export interface IEnvironment {
    /** Production/development mode */
    production: boolean;
}

/**
 * Interface for message menu
 *
 * @export
 * @interface IMsgMenuData
 */
 export interface IMsgMenuData {
    action: MessageAction;
    msg: cat.MessageMsg;
}

/**
 * Interface for emitting selecting members
 *
 * @export
 * @interface ISelectMember
 */
 export interface ISelectMember {
    member: cat.AccountMsg;
    isSelected: boolean;
}

 /**
 * A new day msg between chat messages
 */
  export interface INewDayMsg {
    /** Id */
    id: string;
    /** isNewDay flag */
    isNewDay: boolean;
    /** Received timestamp */
    received: number;
}

/**
 * Interface for sign out subscription
 *
 * @export
 * @interface ISignOut
 */
export interface ISignOut {
    debugMessage: string;
    showMessage?: boolean;
    noCall?: boolean;
    message?: string;
}

/**
 * Interface for key pipe output
 *
 * @export
 * @interface IKeyPipe
 */
 export interface IKeyPipe {
    key: string;
    value: any;
    timestamp: number;
}

/**
 * Interface for custom alert button
 *
 * @export
 * @interface IAlertButton
 */
export interface IAlertButton {
    text: string;
    role: string;
    cssClass: string;
}

export interface IFormDialogData {
    msg: any;
    definition: IMessageDefinitions;
    name: string;
    readonly?: boolean;
    list?: { id: string, name: string }[];
    fieldData?: IFormFields;
}


export interface ILinkDeviceModal {
    domainId: string;
}

export interface IReportPreviewData {
    profileId: string;
    report: cat.ReportMsg;
}

export interface IFormDialogReturnData {
    data: any;
    files: Map<string, FileList>;
}

export interface IDialogData {
    title: string;
    content: string;
    buttons?: IDialogButton[];
}

export interface IDialogDeviceStats {
    profileId: string;
    packageName: string;
}

export interface IDialogEncryption {
    setting: cat.SettingMsg;
}

export interface IDialogButton {
    title: string;
    color: "primary" | "warn" | "accent";
    action?: "cancel" | "delete" | "unlock" | "retry" | "proceed" | "merged";
}

export interface statisticInterval {
    value: string;
    viewValue: string;
}

/**
 * Interface for add conversation modal
 *
 * @export
 * @interface IAddConversationData
 */
export interface IAddConversationData {
    type: cat.ConversationType;
    chatId: string;
}

/**
 * Interface for chat menu
 *
 * @export
 * @interface IChatMenuData
 */
export interface IChatMenuData {
    profile: cat.ProfileMsg;
    account: cat.AccountMsg;
    conversation: cat.ConversationMsg;
    path: string;
}

/**
 * Interface for reviewers popup
 *
 * @export
 * @interface IReviewersData
 */
 export interface IReviewersData {
    owner: cat.UserMsg;
    reviewers: cat.UserMsg[];
}

/**
 * Interface for JumpToDatePopoverComponent.
 *
 * @export
 * @interface IJumpToDateData
 */
export interface IJumpToDateData {
    startTimestamp: number;
    endTimestamp: number;
}

/**
 * Interface for device accounts popup
 *
 * @export
 * @interface IDeviceAccounts
 */
 export interface IDeviceAccounts {
    accounts: cat.AccountMsg[];
}

/**
 * Interface for device properties popup
 *
 * @export
 * @interface IDeviceProperties
 */
 export interface IDeviceProperties {
    properties: cat.DevicePropertiesMsg;
}

/**
 * Interface for profile menu popover
 *
 * @export
 * @interface IProfileMenuData
 */
 export interface IProfileMenuData {
    profile: cat.ProfileMsg;
}

/**
 * Interface for reactions popup
 *
 * @export
 * @interface IReactionsData
 */
 export interface IReactionsData {
    reaction: cat.ReactionMsg;
    message: cat.MessageMsg;
    account: cat.AccountMsg;
}

export interface IChatFilterDialogData {
    countCursor: string;
    title: string;
    removeEnabled: boolean;
    mode: string;
}
export interface IContactDetailData {
    contactAction: ContactAction;
    contact: cat.AccountMsg;
    accountId: string;
    profileId: string;
}

export interface IAccountDetailData {
    account: cat.AccountMsg;
    profile: cat.ProfileMsg;
}

export interface IConversationDetailData {
    conversationAction: ConversationAction;
    conversation: cat.ConversationMsg;
    profile: cat.ProfileMsg;
    account: cat.AccountMsg;
}

export interface IServerDetailData {
    server: cat.ServerMsg;
    profile: cat.ProfileMsg;
    account: cat.AccountMsg;
}

export interface ITopicDetailData {
    topic: cat.TopicMsg;
    profile: cat.ProfileMsg;
    account: cat.AccountMsg;
}

export interface IMessageDetailData {
    msgAction: MessageAction;
    message: cat.MessageMsg;
    profile: cat.ProfileMsg;
    account: cat.AccountMsg;
    conversationId: string;
}

export interface IHeaderButton {
    text: string;
    icon: IIconDefinition;
    permissions: number[]
}

 /**
 * The language object with language code and name
 */
export interface ILanguage {
    /** The id to track by */
    id: string;
    /** Language code */
    code: string;
    /** Name */
    name: string;
}

/** The IMessageDefinitions interface */
export interface ITimelineData {
    minTs: number;
    minKey: string;
    maxTs: number;
    maxKey: string;
    previousTs: number;
    previousKey: string;
    nextTs: number;
    nextKey: string;
}

/** The IMessageDefinitions interface */
export interface IMessageDefinitions {
    /** Message */
    msg?: IMessageDefinition;
    /** List */
    list?: IDefData;
    /** Optional icon for a message */
    icon?: IIconDefinition;
}

/** The IMediaLibData interface */
export interface IGalleryData {
    /** Profile id. */
    profileId: string;
}

/** The IMediaLibData interface */
export interface IMediaLibData {
    /** Account */
    account: cat.AccountMsg;
}

/** The IImageDetailPageData interface */
export interface IImageDetailPageData {
    /** Media type */
    mediatype: cat.MediaType;
    /** Attachment */
    attachment: cat.AttachmentMsg;
    /** Reference Id */
    refId: string;
    /** as Profile flag, for header alternation. */
    asProfile: boolean;
    /** Optional account id. */
    accountId?: string;
    /** Optional app id. */
    appId?: string;
    /** Optional profile id. */
    profileId?: string;
    /** Direct link to message. */
    messageLink?: boolean;
    /** Optional conversation id. */
    conversationId?: string;
    /** Optional message id. */
    messageId?: string;
    /** Optional contact id. */
    contactId?: string;
    /** Optional server id. */
    serverId?: string;
    /** Optional topic id. */
    topicId?: string;
}

/** The IFile interface */
export interface IFile {
    /** Mimetype of the file */
    mimetype: string;
    /** Name of the file */
    filename: string;
    /** The actual data of the file */
    data: Uint8Array;
}

/** The IFileHandler interface */
export interface IFileHandler {
    /** File name */
    filename: string;
    /** Is end */
    isEnd: boolean;
    /** Data */
    data: Uint8Array;
    /** Mimetype */
    mimetype: string;
}

/** The IFileHandler interface */
export interface IFilterPipeOptions {
    /** Filter type */
    type: "mimetype" | "id" | "ids" | "string" | "enumerator" | "extension" | "read" | "unread" | "true" | "false" | "path";
    /** Value*/
    value?: any;
    /** Key */
    key?: any;
    /** Selector */
    sel?: string;
    /** Data */
    path?: Array<string>;
}

/** The IStoreListMsg interface */
export interface IStoreListMsg {
    /** Free form */
    readonly [id: string]: Map<string, any>; // Could be improved with cat.UserMsg | cat.EulaMsg etc.
}

/** The IIconDefinition interface */
export interface IIconDefinition {
     /** Material name of the icon */
    matname: string;
    /** Color of the icon */
    color?: string;
}

/** The IMessageDefinition interface */
export interface IMessageDefinition {
    /** Free form */
    [msg: string]: IDefData;
}

/** The IStatusUpdate interface */
export interface IStatusUpdate {
    /** Typing */
    typing?: boolean;
    /** Online */
    online: boolean;
}

/** The IPasswordOptions interface */
export interface IPasswordOptions {
    /** Shown */
    shown: boolean;
    /** Type */
    type: "password" | "text";
    /** Color */
    color: string;
}

/** The IFormData interface */
export interface IFormData {
    /** Message */
    msg: string;
    /** Fields */
    fields: Array<IFormField>;
    /** Form group */
    formGroup: FormGroup;
    /** Button text */
    buttonText?: string;
}

/** The IPopoverFilterData interface */
export interface IPopoverFilterData {
    /** Path */
    path: string;
    /** removeEnabled */
    removeEnabled?: boolean;
    /** mode */
    mode?: "topic" | "server" | "serverchat" | "chat";
    /** Title */
    title: string;
    /** Count cursor for observable. */
    countCursor: string;
}

/** The IGroupbyData interface */
export interface IGroupbyData {
    /** Key */
    key: string;
    /** Value */
    value: any[];
}

/** The INotificationIds interface */
export interface INotificationIds {
    /** App Id */
    appId?: string;
    /** Profile Id */
    profileId?: string;
    /** Account Id */
    accountId?: string;
    /** Device Id */
    deviceId?: string;
    /** Conversation Id */
    conversationId?: string;
}

/** The IFormField interface */
export interface IFormField {
    /** Field */
    field: IField;
    /** Definition */
    def: IDefData;
}

/** The IFormTexts interface */
export interface IFormTexts {
    /** callname */
    [callname: string]: IFormFields;
}

export interface IFormFields {
    /** fieldname */
    [fieldname: string]: IFormFieldData;
}

export interface IFormFieldData {
    /** label */
    label: string;
    /** description */
    description: string;
    /** Validator list new */
    validatorList?: IValidatorListItem[]
}

export interface IValidatorListItem {
    /** validator */
    validator: ValidatorFn;
    /** type */
    type: AngularValidators;
    /** value */
    value?: number | string;
}

export enum AngularValidators {
    Required = "required",
    RequiredTrue = "requiredTrue",
    MinLength = "minLength",
    MaxLength = "maxLength",
    Pattern = "pattern",
    Email = "email",
    Min = "min",
    Max = "max",
    /** Custom pattern validator types. */
    patternLowerCase = "patternLowerCase",
    patternUpperCase = "patternUpperCase",
    patternDigits = "patternDigits",
    patternSymbols = "patternSymbols"
}

/** The ISortBy interface */
export interface ISortBy {
    /** sort by string */
    field: string;
    /** sort order */
    order: "asc" | "desc";
}

/** The ICall interface */
export interface ICall {
    /** Call */
    call: IServiceDescriptor;
    /** Required permissions to perform call */
    permissions: number[];
    /** Payload */
    payload?: any;
    /** Load more with offset */
    loadMore?: boolean;
    /** Optional query message */
    query?: cat.QueryMsg;
}

export interface IQueryMsg {
    offset: number;
    count: number;
    sortdirection: cat.SortDirection;
    sortfields: string[];
    search: string;
    filters: cat.FilterMsg[];
}

/** The IAvailableFilters interface */
export interface IAvailableFilters {
    /** Database collection name */
    collection: string;
    /** Database field name */
    field: string;
    /** Values */
    values: string[];
    /** Optional cursor for definitions */
    defCursor?: string;
    /** Include or Exclude */
    operator: cat.FilterOperator;
}

/** The IField interface */
export interface IField {
    /** The name of the form field */
    key: string;
    /** Whether or not the form item should be hidden */
    hidden: boolean;
    /** TODO: remove the value, and get it from the form */
    value: any;
    /** The current validity state */
    validity: "valid" | "invalid" | "hint";
    /** The parent field */
    parent: string;
    /** Whether or not to hide the label */
    hiddenLabel: boolean;
}
/** The IDefData interface */
export interface IDefData {
    /** Label */
    label: string;
    /** Type */
    type: "input" | "password" | "date" | "enumerator" | "textarea" | "select" | "boolean" | "map" |
        "birthday" | "list" | "repeatedstring" | "repeated_input" | "repeated_list" | "repeated_message" |
        "checkbox" | "checkbox_list" | "upload" | "proto" | "filesize";
    /** Value */
    value?: string;
    /** Group name */
    group?: string;
    /** Enumerator */
    enumerator?: string;
    /** Desc */
    desc?: string;
    /** Selector */
    sel?: string;
    /** Icon */
    icon?: IIconDefinition;
    /** Repeated */
    repeated?: boolean;
    /** Multiselect */
    multiselect?: boolean;
    /** Read only */
    readonly?: boolean;
    /** Mime types */
    mimeTypes?: string[];
    /** Get call */
    get?: ICall;
    /** Add call */
    add?: ICall;
    /** original singular value */
    original_singular?: string,
    /** original plural value */
    original_plural?: string,
    /** singular term enclosed by brackets  */
    singular?: string,
    /** plural term enclosed by brackets  */
    plural?: string,
    /** Optional order id for routes. */
    orderid?: number
}

export interface IChildSelector {
    /** ID of the child item */
    id?: string;
    /** ID of the child item */
    selector: string;
    /** Whether or not the field is repeated */
    repeated: boolean;
    /** The type of child message */
    msg: string;
}

export interface IReportStep {
    title: string;
    step: "choose" | "template" | "range" | "conversations" | "details" | "generate";
    stepno: number;
}

/**
* Selectors class includes title, add, store and external
*/
export class Selectors {
    constructor(
        /** Add form */
        public addForm?: boolean,
        /** Add form message */
        public addFormMsg?: string,
        /** Child msg */
        public child?: IChildSelector,
        /** Add to */
        public addTo?: string,
        /** External */
        public external?: Array<string>,
        /** Message */
        public msg?: string
    ) {}
}

/**
* Password show/hide text
*/
export class PasswordOptionsClass {
    constructor(
        /** Shown */
        public shown: boolean,
        /** Type */
        public type: "password" | "text",
        /** Color */
        public color: string
    ) {}
}

/**
* The data Filters class
*/
export class Filters {
    constructor(
        /** Includes */
        public includes?: Array<string>,
        /** Group by */
        public groupby?: Array<string>,
        /** Group list */
        public grouplist?: Array<string>,
        /** Excludes */
        public excludes?: Array<string>,
        /** Bind */
        public bind?: Array<string>,
        /** Nested */
        public nested?: any
    ) {}
}

/** The QRData class */
export class QRData {
    constructor(
        /** Websocket URL */
        public url: string,
        /** Username of current user */
        public username: string,
        /** Version of backend */
        public version: string,
        /** First token */
        public token?: string
    ) {}
}

/**
* Form field validators
*/
export class Validator {
    constructor(
        /** Name */
        public name: "min" | "max" | "required" | "requiredTrue" | "email" | "minLength" | "maxLength" | "pattern" | string,
        /** Message */
        public msg?: string,
        /** Value */
        public value?: string | number
    ) {}
}

/**
* Container for all route data
*/
export class RouteData {
    constructor(
        /** Item requires at least on of the provided permissions */
        public permissions: number[],
        /** Icon */
        public icon: IIconDefinition,
        /** Title */
        public title?: string,
        /** Name */
        public name?: string,
        /** Redirect link after action */
        public redirectTo?: string,
        /** Select */
        public select?: Selectors,
        /** Get */
        public get?: ICall,
        /** Post */
        public post?: ICall,
        /** Remove */
        public remove?: ICall,
        /** Upload */
        public upload?: ICall,
        /** Filter */
        public filter?: Filters,
        /** Hidden */
        public hidden?: boolean,
        /** Nested, show back arrow navigation button in header */
        public nested?: boolean,
        /** Read only */
        public readonly?: boolean
    ) {}
}

/**
* Container for all protobuf message definition data
*/
export class DefData implements IDefData {
    constructor(
        /** Label */
        public label: string,
        /** Type */
        public type: "input" | "password" | "date" | "enumerator" | "textarea" | "select" | "boolean" | "map" |
            "birthday" | "list" | "repeatedstring" | "repeated_input" | "repeated_list" | "repeated_message" |
            "checkbox" | "checkbox_list" | "upload" | "proto" | "filesize",
        /** Value */
        public value?: string,
        /** Group name */
        public group?: string,
        /** Enumerator */
        public enumerator?: string,
        /** Description */
        public desc?: string,
        /** Icon */
        public icon?: IIconDefinition,
        /** Repeated */
        public repeated?: boolean,
        /** Multiselect */
        public multiselect?: boolean,
        /** Read only */
        public readonly?: boolean,
        /** Mime types */
        public mimeTypes?: string[],
        /** Get call */
        public get?: ICall,
        /** singular value */
        public original_singular?: string,
        /** plural value  */
        public original_plural?: string,
        /** singular term enclosed by brackets  */
        public singular?: string,
        /** plural term emclosed by brackets  */
        public plural?: string,
        /** Optional order id (for permission routes). */
        public orderid?: number
    ) {}
}

/** The filterDefaults function */
function filterDefaults(filters: Filters) {
    const filterDefault: Filters = { bind: [], includes: [], excludes: [], groupby: [], grouplist: [] };
    return Object.assign(filterDefault, filters);
}

/** Create a routerdata object with default values */
export class RouterData {
    /** Create function */
    static create(event: RouteData) {
        return new RouteData(
            event.permissions || [],
            event.icon,
            event.title,
            event.name || "",
            event.redirectTo || "",
            event.select,
            event.get,
            event.post,
            event.remove,
            event.upload,
            filterDefaults(event.filter),
            event.hidden || false,
            event.nested || false,
            event.readonly || false
        );
    }
}

/** Create a MessageDefinition object with default values */
export class IconDefinition {
    static create(event: IIconDefinition) {
        return event;
    }
}

/** Create a MessageDefinition object with default values */
export class MessageDefinition {
    static create(event: IMessageDefinition) {
        return event;
    }
}

/** The Definition class */
export class Definition {
    /** Create function */
    static create(event: DefData) {
        return new DefData(
            event.label, // Label exposed to end-users
            event.type,
            event.value,
            event.group,
            event.enumerator,
            event.desc,
            event.icon,
            event.repeated,
            event.multiselect,
            event.readonly,
            event.mimeTypes,
            event.get,
            event.original_singular,
            event.original_plural,
            event.singular,
            event.plural,
            event.orderid
        );
    }
}

/** The QRMessage class */
export class QRMessage {
    /** Create function */
    static create(event: QRData) {
        return new QRData(
            event.url,
            event.username,
            event.version,
            event.token
        );
    }
}
