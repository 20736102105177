import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";

/**
 * Get progress (number) of report wizard.
 */
@Pipe({
    name: "getProgress",
    pure: true
})
export class GetReportProgressPipe implements PipeTransform {

    transform(report: cat.ReportMsg, detailValidity: boolean): number {

        let result = 10;

        if (report.template?.id) {
            result += 20;
        }

        if (report.flags?.length) {
            result += 10;
        }

        if (report.start) {
            result += 10;
        }

        if (report.end) {
            result += 10;
        }

        if (report.conversations?.length) {
            result += 10;
        }

        if (report.name) {
            result += 10;
        }

        if (detailValidity) {
            result += 20;
        }

        return result;
    }
}
