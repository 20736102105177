<div class="cat-flex-container cat-flex-column">

    <modal-header
        [accountId]="account?.id"
        [title]="uitext.accountdetails"
        class="cat-margin-bottom-lg">
    </modal-header>

    <div class="cat-flex-grow cat-nooverflow-ellipsis">

        <ng-container *ngIf="(account$|async) as account">
            <div class="cat-flex-container cat-flex-column">

                <div class="cat-flex-grow cat-nooverflow-ellipsis">

                    <mat-tab-group class="cat-full-height cat-overflow-y" animationDuration="0" [selectedIndex]="selectedTabIndex" (selectedTabChange)="tabChange($event)">
                        <mat-tab label="{{ uitext.info | translate }}" [aria-label]="uitext.info">
                            <ng-template matTabContent>

                                <div class="cat-padding-xl cat-margin-bottom-xl">

                                    <div class="grid-container">

                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item (click)="openFormDialog('password')" class="cat-cursor-pointer cat-hover-white">
                                                <mat-icon matListItemIcon color="primary">edit</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.password | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ account?.password ? '***' : '' | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.id | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ account?.id | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text cat-full-height">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.externid | translate }}
                                                </div>
                                                <div matListItemLine class="cat-wrap cat-break-word">
                                                    {{ account?.externid | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.fullname | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ account?.fullname | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text cat-full-height">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.phonenumber | translate }}
                                                </div>
                                                <div matListItemLine class="cat-wrap cat-break-word">
                                                    {{ account?.phonenumber | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.firstname | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ account?.firstname | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.lastname | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ account?.lastname | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.birthday | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    <ng-container *ngIf="account?.birthday?.day else noBirthday">
                                                        {{ common.objectToDate(account.birthday) | date : 'd MMMM y' : undefined : tl.locale }}
                                                    </ng-container>
                                                    <ng-template #noBirthday>
                                                        {{ "" | emptyValue }}
                                                    </ng-template>
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text cat-full-height">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.bio | translate }}
                                                </div>
                                                <div matListItemLine class="cat-wrap cat-break-word">
                                                    {{ account?.bio | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.website | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ account?.website | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.email | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ account?.email | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.location | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ account?.location | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.status | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ (messageDefinitions.StatusType | getEnumLabel : account?.status) | emptyValue | translate }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.username | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ account?.username | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>

                                    </div>

                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="{{ uitext.history | translate }}" [aria-label]="uitext.history" class="cat-full-height cat-nooverflow-ellipsis">
                            <ng-template matTabContent class="cat-nooverflow-ellipsis">
                                <mat-tab-body class="cat-nooverflow-ellipsis cat-background-white">

                                    <history-timeline
                                        [profile]="profile"
                                        [account]="account"
                                        [mode]="'account'"
                                        [refresh]="refreshTimeline">
                                    </history-timeline>

                                </mat-tab-body>
                            </ng-template>
                        </mat-tab>

                    </mat-tab-group>
                </div>
            </div>
        </ng-container>
    </div>

</div>

<ng-template #noAvatar>
    <img matTooltip="{{ uitext.noavatarfound | translate }}" matTooltipPosition="above"  src="assets/img/cat-avatar.svg">
</ng-template>