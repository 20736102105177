import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "@app/env";
import { cat } from "@assets/proto/msgs";
import { EnvName } from "@shared/app-models";

@Pipe({
    name: "catsrc",
    pure: true
})
/**
 * Get the source URL from the server.
 *
 * @export
 * @class CatSrcPipe
 * @implements {PipeTransform}
 */
export class CatSrcPipe implements PipeTransform {

    constructor() {}

    transform(file: cat.MediaFileMsg | { fileid: string, thumbfileid: string }, prefix: string, type: cat.MediaType, refid?: string): string {

        // Change the enum to meet the gateway standard (e.g. ICONS).
        const typeString = cat.MediaType[type].split("_").pop().toLowerCase();

        if (!["thumb", "media", "download"].includes(prefix)) {
            throw new Error("Unsupported src type");
        }

        let fileId = file.fileid;
        if (prefix === "thumb") {
            fileId = file?.thumbfileid;
        }

        let url: string;

        if (refid) {
            url = `${prefix}/${typeString}/${refid}/${fileId}`;
        } else {
            url = `${prefix}/${typeString}/${fileId}`;
        }

        // Determine URL components.
        const cnxType: string = (window.location.protocol !== "http:") ? "https:" : "http:";
        const hostname: string = window.location.hostname;
        const port: string = window.location.port;

        // Construct correct URL
        if (environment.envName === EnvName.SERVE || environment.envName === EnvName.HMR) {
            url = `${cnxType}//${hostname}:8080/${url}`;
        } else {
            if (port?.length) {
                // Include non-standard port
                url = `${cnxType}//${hostname}:${port}/${url}`;
            } else {
                // Standard port is used (port is empty string)
                url = `${cnxType}//${hostname}/${url}`;
            }
        }

        return url;
    }
}
