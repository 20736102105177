import { Location } from "@angular/common";
import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { ReportWizardService } from "@services/report-wizard/report-wizard.service";

@Component({
    selector: "report-bottom-navigation",
    templateUrl: "./report-bottom-navigation.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportBottomNavigationComponent implements OnInit {

    @Input() report: cat.ReportMsg = cat.ReportMsg.create();
    @Input() blinkNext: boolean = false;
    @Input() nextAllowed: boolean = false;
    @Input() back: boolean = false;
    @Input() profileId: string = "";
    @Output() emitGenerateReport: any = new EventEmitter();

    public reportIcon = protosui.msgIcons.ReportMsg;
    public mediaType = cat.MediaType;
    public permissionEnum = cat.Permission;
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        private _reportService: ReportWizardService,
        private _location: Location,
        private _router: Router,
        private _logger: LoggerService) {}


    get currentStep$() {
        return this._reportService.step$;
    }

    ngOnInit(): void {
        const currentStep = this._reportService.stepSubject.value;
        this._reportService.setCurrentStep(currentStep);
    }

    public async closePage() {
        this._location.back();
    }

    public async goBack() {
        try {

            // Get the URL from the service.
            const previousStep = this._reportService.getPreviousStep(this._reportService.stepSubject.value.stepNumber);

            // Get the current route URL and split it into segments.
            const urlSegments = this._router.url.split('/');

            // Replace the segment.
            urlSegments.pop();
            urlSegments.push(previousStep.route);

            // Navigate to the updated URL
            await this._router.navigateByUrl(urlSegments.join("/"));

            // Set the new step.
            this._reportService.setCurrentStep(previousStep);

        } catch (error) {
            this._logger.error(error);
        }
    }

    public async goForward() {
        try {

            // Get the URL from the service.
            const nextStep = this._reportService.getNextStep(this._reportService.stepSubject.value.stepNumber);
            this._logger.debug("Next step: ", nextStep);
            // Get the current route URL and split it into segments.
            const urlSegments = this._router.url.split('/');

            // Replace the segment.
            urlSegments.pop();
            urlSegments.push(nextStep.route);

            // Navigate to the updated URL
            await this._router.navigateByUrl(urlSegments.join("/"));

            // Set the new step.
            this._reportService.setCurrentStep(nextStep);

        } catch (error) {
            this._logger.error(error);
        }
    }

}
