import { Pipe, PipeTransform } from "@angular/core";
import { protosui } from "src/definitions/definitions";
import { TranslateService } from "@ngx-translate/core";

/**
 * Human readable seconds
 */
@Pipe({
  name: "humanizeseconds",
  pure: true
})
export class HumanizeSecondsPipe implements PipeTransform {

    constructor(
        private _translate: TranslateService,
    ) {}

    transform(value: number): string {
        return humanizeSeconds(value, this._translate);
    }
}

export const humanizeSeconds = (value: any, translate: TranslateService): string => {
    let result = "";
    if (typeof value === "number") {
        switch (true) {
            case (value < 1):
                result = `Off`;
                break;
            case (value < 2):
                result = `${parseInt(value.toString(), 10)} ${translate.instant(protosui.messages.uitext.second)}`;
                break;
            case (value < 60):
                result = `${parseInt(value.toString(), 10)} ${translate.instant(protosui.messages.uitext.seconds)}`;
                break;
            case (value === 60):
                value = (value / 60);
                result = `${parseInt(value.toString(), 10)} ${translate.instant(protosui.messages.uitext.minute)}`;
                break;
            case (value < 3600):
                value = (value / 60);
                result = `${parseInt(value.toString(), 10)} ${translate.instant(protosui.messages.uitext.minutes)}`;
                break;
            case (value === 3600):
                value = (value / 3600);
                result = `${parseInt(value.toString(), 10)} ${translate.instant(protosui.messages.uitext.hour)}`;
                break;
            case (value < 86400):
                value = (value / 3600);
                result = `${parseInt(value.toString(), 10)} ${translate.instant(protosui.messages.uitext.hours)}`;
                break;
            case (value === 86400):
                value = (value / 86400);
                result = `${parseInt(value.toString(), 10)} ${translate.instant(protosui.messages.uitext.day)}`;
                break;
            case (value < 604800):
                value = (value / 86400);
                result = `${parseInt(value.toString(), 10)} ${translate.instant(protosui.messages.uitext.days)}`;
                break;
            case (value === 604800):
                value = (value / 604800);
                result = `${parseInt(value.toString(), 10)} ${translate.instant(protosui.messages.uitext.week)}`;
                break;
            default:
                value = (value / 86400);
                result = `${parseInt(value.toString(), 10)} ${translate.instant(protosui.messages.uitext.days)}`;
                break;
        }
    }
    return result;
}
