<div class="cat-list cat-no-padding cat-margin-y-sm">

    <h6 class="cat-padding-md cat-no-margin" color="medium-shade">
        {{ "Jump to ..." | translate }}
    </h6>

    <mat-list>

        <button (click)="jumpTo('today')" mat-menu-item extended color="primary">
            <mat-icon color="primary">today</mat-icon>
            <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
                <span color="primary"><b>{{ "(Start of) last known date" | translate }}</b></span>
                <br>
                <span color="medium">{{ "Beginning of the day" | translate | replaceTerm }}</span>
            </div>
        </button>

        <button (click)="picker.open()" mat-menu-item extended color="primary">
            <mat-icon color="primary">event</mat-icon>
            <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
                <span color="primary"><b>{{ "Specific date" | translate }}</b></span>
                <br>
                <span color="medium">{{ "Select a date" | translate | replaceTerm }}</span>
            </div>
        </button>

        <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" (dateChange)="dateChange($event)" class="cat-no-width-height cat-invisible">
        <mat-datepicker #picker></mat-datepicker>

    </mat-list>

</div>
