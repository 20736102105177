import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { cat } from "@assets/proto/msgs";
import { userSetServerVisibility } from "@assets/proto/services";
import { ServerDetailsDialog } from "@modals/detail-pages/server-details/server-details.dialog";
import { IServerDetailData } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { WsService } from "@services/ws/ws.service";
import { protosui } from "@definitions/definitions";


/** Decorator that marks a class as an Angular component */
@Component({
    selector: "server-item",
    templateUrl: "./server-item.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServerItemComponent {

    // I/O
    @Input() server: cat.ServerMsg = cat.ServerMsg.create();
    @Input() profile: cat.ProfileMsg = cat.ProfileMsg.create();
    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    @Input() unreadCount = 0;
    @Input() selected = false;
    @Input() hideCount = false;
    @Output() emitServerVisibility = new EventEmitter();

    // Properties
    public mediaType = cat.MediaType;
    public visibilityType = cat.VisibilityType;
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        private _ws: WsService,
        private _logger: LoggerService,
        private _dialog: MatDialog,
    ) {}

    /**
    * Open the server detail modal
    */
    public async openDetails() {
        try {
            const dialogData: IServerDetailData = {
                server: this.server,
                profile: this.profile,
                account: this.account
            };
            this._logger.debug("Dialog Data: ", dialogData);

            this._dialog.open(ServerDetailsDialog, {
                width: "95vw",
                height: "95vh",
                data: dialogData,
                panelClass: "cat-dialog"
            });
        } catch (error) {
            this._logger.error(error);
        }
    }

    public async hideServer() {
        try {
            // Determine visibility.
            const visibility = this.server.visibility === cat.VisibilityType.VISIBILITY_HIDDEN
                ? cat.VisibilityType.VISIBILITY_VISIBLE
                : cat.VisibilityType.VISIBILITY_HIDDEN;

            const payload: cat.ServerMsg = cat.ServerMsg.create({
                id: this.server.id,
                visibility: visibility
            });
            await this._ws.sendRequest(userSetServerVisibility, payload);
            this.emitServerVisibility.emit();

        } catch (error) {
            this._logger.error(error);
        }
    }
}
