import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";

/** Decorator that marks a class as an Angular component */
@Component({
    selector: "apk-download-menu",
    templateUrl: "./apk-download-menu.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApkDownloadMenuComponent {

    @Input() apk: cat.APKMsg = cat.APKMsg.create();

    public mediaType = cat.MediaType;
    public permissionEnum = cat.Permission;

    constructor(
        public common: CommonService,
        private _logger: LoggerService) {}

}
