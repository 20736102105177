<h4 mat-dialog-title class="cat-no-margin" color="dark">
    <b>{{ uitext?.assigndevices | translate }}</b>
</h4>

<div mat-dialog-content>

    <div class="cat-padding-left-md">

        <div class="col-xs-12 col-sm-6 col-md-7 col-lg-8 col-xl-10 cat-nooverflow-ellipsis">
            <div class="item-container cat-margin-bottom-xl">

                <header class="header">
                    <h5 class="cat-margin-md cat-padding-sm">
                        <b>{{ uitext.devices | translate }}</b> ({{ devices?.length }})
                    </h5>
                </header>

                <div class="cat-detail-header cat-justify-start cat-padding-sm cat-padding-top-lg">

                    <div class="cat-padding-x-md cat-push-right">
                        <button
                            [disabled]="!selectionChanged"
                            (click)="reset(); $event.stopPropagation()"
                            mat-stroked-button color="primary">
                            <mat-icon>history</mat-icon>
                            {{ uitext?.undo | translate }}
                        </button>
                    </div>
                </div>

                <div class="content">
                    <mat-table [dataSource]="devices">

                        <ng-container matColumnDef="select">
                            <mat-header-cell *matHeaderCellDef class="cat-width-60 cat-no-padding">
                                <mat-checkbox
                                    color="primary"
                                    (click)="toggleAll(); $event.stopPropagation()">
                                </mat-checkbox>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" class="cat-width-60 cat-no-padding">
                                <mat-checkbox
                                    color="primary"
                                    (click)="selectionChanged = true; $event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row.id) : null"
                                    [checked]="selection.isSelected(row.id)">
                                </mat-checkbox>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef>
                                {{ uitext.name | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let device">
                                {{ device.name | emptyValue }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="domain">
                            <mat-header-cell *matHeaderCellDef>
                                {{ uitext.domain | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let device">
                                {{ device.domain?.name | emptyValue }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="physicalid">
                            <mat-header-cell *matHeaderCellDef>
                                {{ uitext.physicalid | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let device">
                                <span matTooltip="{{ device.physicalid }}" matTooltipPosition="above">
                                    {{ device.physicalid | emptyValue }}
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef>
                                {{ uitext.status | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let device">
                                {{ (messageDefinitions.StatusType | getEnumLabel : device.status) | emptyValue | translate }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef>
                                {{ uitext.type | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let device">
                                {{ (messageDefinitions.DeviceType | getEnumLabel : device.type) | emptyValue | translate }}
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                    </mat-table>

                    <div *ngIf="devices?.length === 0" class="cat-no-records">
                        {{ uitext.notfound | translate }}
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>

<div mat-dialog-actions align="end" class="cat-padding-bottom-lg cat-padding-x-lg">
    <button [disabled]="!selectionChanged" mat-button cdkFocusInitial class="cat-padding-lg" color="primary" (click)="save()">
        {{ uitext.save | translate }}
    </button>
    <button mat-stroked-button mat-dialog-close cdkFocusInitial class="cat-padding-lg" (click)="closeDialog()">
        {{ uitext.close | translate }}
    </button>
</div>
