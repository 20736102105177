import { Component, ChangeDetectionStrategy, Input, OnDestroy } from "@angular/core";
import { AnimationItem } from "lottie-web";
import { LoggerService } from "@services/logger/logger.service";
import { cat } from "@assets/proto/msgs";

@Component({
    selector: "lottie-container",
    templateUrl: "./lottie-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class LottieContainerComponent implements OnDestroy {

    @Input() file: cat.MediaFileMsg = cat.MediaFileMsg.create();
    @Input() mediatype = cat.MediaType;
    @Input() fullwidth = false;
    @Input() autoplay = false;
    @Input() large = false;
    @Input() refId = "";

    private _animationItems: Map<string, AnimationItem> = new Map();

    constructor(private _logger: LoggerService) {}

    /**
     * Clear the animation items.
     *
     * @memberof LottieContainerComponent
     */
    ngOnDestroy() {
        this._animationItems.clear();
    }

    /**
     * On hover tgs, fetch the right animation and play.
     *
     * @param {string} id
     * @memberof LottieContainerComponent
     */
    mouseEnter(id: string) {
        if (this._animationItems.has(id)) {
            this._animationItems.get(id).play();
        } else {
            this._logger.debug("Animation not found, can not stop it.");
        }
    }

    /**
     * On mouse leave tgs, fetch the right animation and stop it.
     *
     * @param {string} id
     * @memberof LottieContainerComponent
     */
    mouseLeave(id: string) {
        if (this._animationItems.has(id)) {
            this._animationItems.get(id).stop();
        } else {
            this._logger.debug("Animation not found, can not stop it.");
        }
    }

    /**
     * Initiate the animation and add it to a map.
     *
     * @param {AnimationItem} animationItem
     * @param {string} id
     * @memberof LottieContainerComponent
     */
    animationCreated(animationItem: AnimationItem, id: string): void {
        this._animationItems.set(id, animationItem);
    }
}
