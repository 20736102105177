<div class="mat-calendar-header">
    <div class="mat-calendar-controls">
    <button mat-button type="button" class="mat-calendar-period-button mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
            (click)="currentPeriodClicked()" [attr.aria-label]="periodButtonLabel"
            cdkAriaLive="polite">
        {{periodButtonText}}
        <div class="mat-calendar-arrow"
            [class.mat-calendar-invert]="calendar.currentView != 'month'"></div>
    </button>

    <div class="mat-calendar-spacer"></div>

        <ng-content></ng-content>

        <button mat-icon-button type="button" class="mat-calendar-previous-button mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
            [disabled]="!previousEnabled()" (click)="customPrev()"
            [attr.aria-label]="prevButtonLabel">
        </button>

        <button mat-icon-button type="button" class="mat-calendar-next-button mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
            [disabled]="!nextEnabled()" (click)="customNext()"
            [attr.aria-label]="nextButtonLabel">
        </button>
    </div>
</div>