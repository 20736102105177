import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "@app/env";
import { EnvName } from "@shared/app-models";

@Pipe({
    name: "docmedia",
    pure: true
})
/**
 * Construct icon URL.
 *
 * @export
 * @class DocMediaPipe
 * @implements {PipeTransform}
 */
export class DocMediaPipe implements PipeTransform {

    constructor() {}

    transform(type: string): string {
        let url = `docs/${type}`;

        // Determine URL components.
        const cnxType: string = (window.location.protocol !== "http:") ? "https:" : "http:";
        const hostname: string = window.location.hostname;
        const port: string = window.location.port;

        // Construct URL.
        if (environment.envName === EnvName.SERVE || environment.envName === EnvName.HMR) {
            url = `${cnxType}//${hostname}:8080/${url}`;
        } else {
            if (port?.length) {
                // Include non-standard port
                url = `${cnxType}//${hostname}:${port}/${url}`;
            } else {
                // Standard port is used (port is empty string)
                url = `${cnxType}//${hostname}/${url}`;
            }
        }

        return url;
    }
}
