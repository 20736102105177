<div class="cat-chat-item cat-padding-x-lg cat-cursor-pointer" [class.cat-selected]="selected">

    <div class="cat-avatar cat-md">
        <img
            *ngIf="(server.avatar | hasThumb) else noAvatar"
            [src]="server.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
        <ng-template #noAvatar>
            <img matTooltip="{{ uitext.noavatarfound | translate }}" matTooltipPosition="left"  src="assets/img/cat-avatar.svg">
        </ng-template>
    </div>

    <div class="cat-flex-fill-remaining cat-margin-x-lg cat-nowrap cat-nooverflow-ellipsis">
        <span class="cat-no-margin cat-nooverflow-ellipsis" color="grey-dark">
            <b>{{ server.name }}</b>
        </span>
        <p *ngIf="server.description" class="cat-no-margin cat-margin-top-sm cat-nooverflow-ellipsis" color="dark"
            matTooltip="{{ server.description }}"
            matTooltipShowDelay="850"
            matTooltipPosition="right">
            {{ server.description }}
        </p>
    </div>

    <div class="cat-nowrap cat-nooverflow-ellipsis cat-end cat-max-width-sm cat-flex-column">
        @if (!hideCount) {
            <div
                class="cat-no-margin cat-nooverflow-ellipsis cat-button-wrapper"
                matTooltip="{{ 'Expand' | translate }} {{ server.conversationcount }} {{ 'server conversation(s)' | translate }}" matTooltipPosition="above">
                <span color="primary" class="cat-margin-right-sm">
                    <b>{{ server.conversationcount }}</b>
                </span>
                <mat-icon color="primary">forum</mat-icon>
            </div>
        } @else {
            <div
                class="cat-no-margin cat-nooverflow-ellipsis cat-button-wrapper"
                matTooltip="{{ 'Expand' | translate }} {{ 'server conversation(s)' | translate }}" matTooltipPosition="above">
                <mat-icon color="primary">forum</mat-icon>
            </div>
        }
    </div>

    <mat-icon
        *ngIf="server.visibility === visibilityType.VISIBILITY_HIDDEN"
        matTooltip="{{ 'Hidden' | translate }}" matTooltipPosition="above" matTooltipShowDelay="500"
        class="cat-font-lg cat-margin-left-md"
        color="warn">
        visibility_off
    </mat-icon>

    <button
        (click)="$event.stopPropagation()"
        mat-icon-button class="cat-margin-left-md"
        [matMenuTriggerFor]="serverMenu">
        <mat-icon color="primary">more_vert</mat-icon>
    </button>
</div>

<mat-menu #serverMenu="matMenu">
    <button (click)="openDetails()" mat-menu-item extended color="primary">
        <mat-icon color="primary">info</mat-icon>
        <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
            <span color="primary"><b>{{ uitext.info | translate }}</b></span>
            <br>
            <span color="medium">{{ "About this server" | translate }}</span>
        </div>
    </button>

    <button (click)="hideServer(); $event.stopPropagation()" mat-menu-item extended color="primary">
        <mat-icon color="primary">visibility_off</mat-icon>
        <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
            <span color="primary" *ngIf="server.visibility === visibilityType.VISIBILITY_VISIBLE">
                <b>{{ "Hide server" | translate }}</b>
            </span>
            <span color="primary" *ngIf="server.visibility === visibilityType.VISIBILITY_HIDDEN">
                <b>{{ "Unhide server" | translate }}</b>
            </span>
            <br>
            <span color="medium">{{ "Set server visibility" | translate }}</span>
        </div>
    </button>
</mat-menu>