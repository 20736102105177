import { Store } from "@ngxs/store";
import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { LoggerService } from "@services/logger/logger.service";
import { IState } from "@shared/app-models";

/**
 * Get role name from language map
 */
@Pipe({
    name: "getRoleName",
    pure: true
})
export class GetRoleNamePipe implements PipeTransform {

    constructor(
        private _logger: LoggerService,
        private _store: Store
    ) {}

    transform(_rolenames?: { [key: string]: string }, _roleId?: string, ): string {
        let result = "";

        const me = this._store.selectSnapshot((state: IState) => state.cat.Me.msg);
        const role = me.roles.find((role: cat.RoleMsg) => role.id === _roleId);
        const language = cat.SupportedLanguages[me.language];

        // If rolenames not provided, look em up.
        if ((!_rolenames || !Object.keys(_rolenames)?.length) && role) {
            _rolenames = role.nametranslations
        }

        if (_rolenames && _rolenames[language] !== undefined) {
            result = _rolenames[language];
        }

        if (!result && role) {
            result = role.name
        }

        return result;
    }
}
