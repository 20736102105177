import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { MessageAction } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";

@Component({
    selector: "app-msg-menu-popover",
    templateUrl: "./msg-menu-popover.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MsgMenuPopoverComponent {

    @Input() msg: cat.MessageMsg = cat.MessageMsg.create();
    @Output() emitOpenDetails: EventEmitter<MessageAction> = new EventEmitter();

    public messageAction = MessageAction;
    public uitext = protosui.messages.uitext;

    constructor(public common: CommonService) {}

    /**
     * Copy the message body to the clipboard
     *
     * @memberof MsgMenuPopoverComponent
     */
    public copyMsg() {
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = this.msg.body;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
        this.common.createSnackbar(protosui.messages.uitext.copiedmessage);
    }
}
