import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, OnInit, Input, ChangeDetectorRef, inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { userGetContactAnnotations, IServiceDescriptor, userAddAnnotationAttachment, userAddContactAnnotation  } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { RouterData, IFileHandler, CallAction, IState, IDialogButton } from "@shared/app-models";
import { TranslateService } from "@ngx-translate/core";
import { ReplaceTermPipe } from "@pipes/replaceterm/replaceterm.pipe";
import { AuthService } from "@services/auth/auth.service";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";
import { WsService } from "@services/ws/ws.service";
import { updateContactAnnotations } from "@store/actions";
import { Observable } from "rxjs";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { cloneDeep } from "lodash-es";
import { FormGroup } from "@angular/forms";
import { getList } from "@store/store";

@Component({
    selector: "app-annotations",
    templateUrl: "./annotations.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotationsComponent implements OnInit {

    // Form properties.
    public annotationForm: FormGroup;
    public fieldData = protosui.formFieldData[userAddContactAnnotation.methodName];
    public uitext = protosui.messages.uitext;

    public permissionEnum = cat.Permission;
    public mediaType = cat.MediaType;
    public files: FileList;
    public submitAttempt = false;

    @Input() _refId = "";
    @Input() _get: IServiceDescriptor;
    @Input() _add: IServiceDescriptor;
    @Input() _remove: IServiceDescriptor;
    @Input() _accountId = "";

    annotationLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userAddAnnotationAttachment?.isLoading);

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _store: Store,
        private _cdr: ChangeDetectorRef,
        private _translate: TranslateService,
        private _logger: LoggerService,
        private _auth: AuthService,
        private _ws: WsService
    ) {}

    get annotations$() {
        return this._store.select((state: IState) => getList(state.cat[this._get.methodName]));
    }

    get isLoading$() {
        return this._store.select((state: IState) => state.cat[this._get.methodName].isLoading);
    }

    /**
     * Create form data and fetch the annotations
     */
    async ngOnInit() {
        this.annotationForm = this.common.createFormGroup(this.fieldData);
        await this.getAnnotations();
    }

    /**
     * Get the annotations of the contact
     */
    public async getAnnotations() {
        try {
            const payload = cat[this._get.requestType].create({ id: this._refId });
            await this._ws.sendRequest(this._get, payload);
        } catch (error) {
            await this.common.createSnackbar(error);
            this._logger.error(error);
        }
    }


    /**
    * Upload the attachment and return a promise when finished
    * @returns {cat.AttachmentMsg} Attachment with filesize, filename and id
    */
    private async uploadAttachment(annotationId: string): Promise<cat.AttachmentMsg> {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<cat.AttachmentMsg>(async (resolve, reject) => {
            try {

                // Generate callId for potential stream uploads
                const callId: string = await this._auth.generateId();
                const mimetypes: Array<string> = [];

                // Use common function to handle file input
                await this.common.handleFileInput(this.files, mimetypes, async (handler: IFileHandler) => {
                    // Create a Uint8Array and construct a media message
                    const media: cat.MediaMsg = cat.MediaMsg.create({
                        type: cat.MediaType.MEDIA_ANNOTATIONS,
                        refid: this._accountId,
                        data: handler.data
                    });

                    const request = cat.MediaFileMsg.create({
                        filename: handler.filename,
                        media,
                        externid: annotationId
                    })

                    await this._ws.sendRequest(userAddAnnotationAttachment, request, callId, handler.isEnd);
                });

                await this.common.createSnackbar(protosui.messages.uitext.successfullyuploaded);
                resolve(this._store.selectSnapshot((state: IState) => state.cat.userAddAnnotationAttachment.msg));
            } catch (error) {
                this._logger.error(error);
                reject();
            }
        });
    }

    /**
    * Add an annotation
    */
    public async addAnnotation() {
        try {

            this.submitAttempt = true;

            if (this.annotationForm.valid) {

                // Construct the correct request message.
                const routerData = RouterData.create({
                    permissions: [ cat.Permission.PERMISSION_SET_ANNOTATIONS ],
                    icon: protosui.msgIcons.AnnotationMsg,
                    post: { call: this._add, payload: { id: "id" }, permissions: [] },
                    select: { child: { msg: messageDefinitions.AnnotationMsg, selector: "annotations", repeated: true }, addForm: true },
                    filter: { includes: ["annotation"] }
                });

                const message: cat.AccountMsg = this.common.dataToProtoMsg(routerData, CallAction.Post, this._refId);

                // Fill the message with the annotation value from the form.
                for (const key of Object.keys(this.annotationForm.value)) {

                    const value: any = this.annotationForm.value[key];

                    // Assign value to the right field
                    if (routerData.select.child) {
                        if (routerData.select.child.repeated) {
                            message[routerData.select.child.selector][0][key] = value;
                        } else {
                            message[routerData.select.child.selector][key] = value;
                        }

                    } else {
                        message[key] = value;
                    }
                }

                // First add the annotation.
                await this._ws.sendRequest(this._add, message);

                // If there is an attachment, upload it.
                if (this.files?.length) {
                    const annotation = cloneDeep(this._store.selectSnapshot((state: IState) => state.cat[this._add.methodName]).msg);
                    const attachment: cat.AttachmentMsg = await this.uploadAttachment(annotation.id);
                    annotation.attachment = attachment;
                }

                if (this._get.methodName === "userGetContactAnnotations") {
                    const annotations: cat.AnnotationMsg[] = [
                        this._store.selectSnapshot((state: IState) => state.cat.userAddContactAnnotation.msg)
                    ];
                    this._store.dispatch(new updateContactAnnotations(annotations, this._refId));
                }

                // Update the view with the new annotation.
                this.getAnnotations();
                this.annotationForm.reset();
            } else {
                await this.common.createSnackbar(protosui.messages.uitext.invalidform);
            }

        } catch (error) {
            this._logger.error(error);
            await this.common.createSnackbar(error);
        } finally {
            this.clearAttachment();
            this.submitAttempt = false;
        }
    }

    /**
    * Attach the uploaded file to the property
    * @param {FileList} files The selected file(s)
    */
    async handleFileInput(files: FileList) {
        try {
            this.files = files;
            this.common.detectChange(this._cdr);
        } catch (error) {
            await this.common.createSnackbar(error);
        }
    }

    /**
    * Remove the file(s) from the property
    */
    public clearAttachment() {
        this.files = <FileList>{};
        this.common.detectChange(this._cdr);
    }

    /**
    * Show a confirmation alert before deleting
    * @param {cat.AnnotationMsg} annotation The selected annotation
    */
    public async promptAlert(annotation: cat.AnnotationMsg) {

        // Also replace terms in instantly translated strings
        let msg = `${this._translate.instant(protosui.messages.uitext.abouttodelete)}<b>${annotation.annotation}</b>`;
        msg = new ReplaceTermPipe(this._store).transform(msg);

        // Create dialog with warning before deletion.
        const warningDialog = this.common.createDialogReference({
            title: protosui.messages.uitext.areyousure,
            content: msg,
            buttons: [
                {
                    title: protosui.messages.uitext.delete,
                    color: "warn",
                    action: "delete"
                },
                {
                    title: protosui.messages.uitext.cancel,
                    color: "primary",
                    action: "cancel"
                }
            ]
        });

        warningDialog.afterClosed().subscribe(async (result: IDialogButton["action"]) => {
            if (result === "delete") {
                this.removeAnnotation(annotation);
            }
        });
    }

    /**
    * Remove an annotation and update the store
    * @param {cat.AnnotationMsg} annotation The annotation to remove
    */
    private async removeAnnotation(annotation: cat.AnnotationMsg) {
        try {

            const payload: cat.AccountMsg = cat.AccountMsg.create({
                id: this._refId,
                accountrefid: this._accountId,
                annotations: [annotation]
            });

            await this._ws.sendRequest(this._remove, payload);

            if (this._get.methodName === userGetContactAnnotations.methodName) {
                if (this._store.selectSnapshot((state: IState) => state.cat[this._get.methodName].list.size)) {
                    this._store.selectSnapshot((state: IState) => state.cat[this._get.methodName].list).delete(annotation.id)
                }
                const annotations: cat.AnnotationMsg[] = [];
                this._store.selectSnapshot((state: IState) => state.cat[this._get.methodName].list).forEach((storedAnnotation: cat.AnnotationMsg) => {
                    annotations.push(storedAnnotation);
                });
                this._store.dispatch(new updateContactAnnotations(annotations, this._refId));
            }

            // Update the view with the new annotation
            this.getAnnotations();

        } catch (error) {
            await this.common.createSnackbar(error);
        }
    }

}
