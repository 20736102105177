import { Component, ChangeDetectionStrategy, Input, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { IState } from "@shared/app-models";
import { Store } from "@ngxs/store";
import { CommonService } from "@services/common/common.service";
import { Observable } from "rxjs";

@Component({
    selector: "account-item",
    templateUrl: "./account-item.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountItemComponent {

    // I/O
    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    @Input() selected = false;
    @Input() count = 0;
    @Input() disabled = false;

    apptypes$: Observable<Map<string, cat.AppTypeMsg>> = inject(Store).select((state: IState) => state.cat.userGetAppTypes?.list);

    // Properties
    public mediaType = cat.MediaType;
    public messageType = cat.MessageType;
    public directionType = cat.DirectionType;
    public conversationType = cat.ConversationType;
    public conversationState = cat.ConversationState;
    public conversationVisibility = cat.VisibilityType;
    public conversationTypeEnum = protosui.def.ConversationType;
    public uitext = protosui.messages.uitext;
    public profileId = "";

    constructor(
        public common: CommonService,
        private _activeRoute: ActivatedRoute
    ) {
        this.profileId = this._activeRoute.snapshot.paramMap.get("profileid");
    }
}
