import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, OnInit, inject, OnDestroy } from "@angular/core";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { cat } from "@assets/proto/msgs";
import { IServiceDescriptor, userGetConversationMediaCount } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngxs/store";
import { HumanizeEnumPipe } from "@pipes/humanizeEnum/humanizeEnum.pipe";
import { ReplaceTermPipe } from "@pipes/replaceterm/replaceterm.pipe";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { IDialogData, IState } from "@shared/app-models";
import { clearStoreItem } from "@store/actions";
import { Observable } from "rxjs";

@Component({
    selector: "gallery-filter",
    templateUrl: "./gallery-filter.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryFilterComponent implements OnInit, OnDestroy {

    @Input() path: IServiceDescriptor;
    @Input() countpath: IServiceDescriptor;
    @Input() includeLinkStatus: boolean;
    @Input() includeLinkType: boolean;
    @Output() newQueryEvent: EventEmitter<cat.QueryMsg> = new EventEmitter();

    // Generic properties
    public uitext = protosui.messages.uitext;
    public count$: Observable<cat.QueryMsg>;

    // Filter bar properties
    public linkTypes: { value: number, label: string }[] = [];
    public linkTypeDefinition = protosui.def.ReceiverMediaType;
    public mimeTypes: { value: number, label: string }[] = [];
    public mimeTypeDefinition = protosui.def.MimeType;
    public selectedMimeTypes = [];
    public sortFields: { direction: "desc" | "asc", field: "created" | "filesize", text: string }[] = [
        { direction: "desc", field: "created", text: protosui.messages.uitext.newtoold },
        { direction: "asc", field: "created", text: protosui.messages.uitext.oldtonew },
        { direction: "desc", field: "filesize", text: protosui.messages.uitext.largetosmall },
        { direction: "asc", field: "filesize", text: protosui.messages.uitext.smalltolarge }
    ]
    public selectedSort = this.sortFields[0];
    public selectedLinkStatus: "all" | "linked" | "unlinked" = "all";
    public selectedLinkType = cat.ReceiverMediaType[cat.ReceiverMediaType.RECEIVERMEDIA_LINKABLE];

    private _count = 256;

    constructor(
        private _common: CommonService,
        private _logger: LoggerService,
        private _translate: TranslateService,
        private _store: Store) {
        this.mimeTypes = new HumanizeEnumPipe(this._logger).transform(messageDefinitions.MimeType);
        this.linkTypes = new HumanizeEnumPipe(this._logger).transform(messageDefinitions.ReceiverMediaType);
    }

    async ngOnInit() {
        if (this.countpath?.methodName) {
            this.count$ = this._store.select((state: IState) => state.cat[this.countpath.methodName].msg);
        }

        // Always return the query upon init
        await this.onFilterChange();
    }

    ngOnDestroy() {
        this._store.dispatch(new clearStoreItem(this.countpath));
    }

    /**
     * Request receiver media based on filter change.
     *
     * @memberof GalleryFilterComponent
     */
    public async onFilterChange() {
        this._logger.debug("onFilterChange");
        this._store.dispatch(new clearStoreItem(this.path));
        const query = this.getFilterQuery();
        this._logger.debug("Emite query: ", query);
        this.newQueryEvent.emit(query);
    }

    /**
     * Construct a query.
     *
     * @private
     * @returns {cat.QueryMsg}
     * @memberof GalleryFilterComponent
     */
    private getFilterQuery(): cat.QueryMsg {
        this._logger.debug("getFilterQuery");
        try {
            const query: cat.QueryMsg = cat.QueryMsg.create({
                offset: this._store.selectSnapshot((state: IState) => state.cat[this.path.methodName]).infiniteArray?.length || 0,
                count: this._count,
                filters: []
            });

            // Filter on specific mimetype status.
            if (this.selectedMimeTypes.length) {
                this._logger.debug("Selected mime types: ", this.selectedMimeTypes);
                query.filters.push(cat.FilterMsg.create({
                    field: "mimetype",
                    collection: "receivermedia",
                    values: this.selectedMimeTypes,
                    operator: cat.FilterOperator.FILTER_INCLUDE
                }));
            }

            if (this.includeLinkType && this.selectedLinkType) {
                this._logger.debug("Selected link type: ", this.selectedLinkType);
                query.filters.push(cat.FilterMsg.create({
                    field: "receivermediatype",
                    collection: "receivermedia",
                    value: this.selectedLinkType,
                    operator: cat.FilterOperator.FILTER_INCLUDE
                }));
            }

            if (this.includeLinkType && this.selectedLinkStatus) {
                this._logger.debug("Selected link status: ", this.selectedLinkStatus);
                switch(this.selectedLinkStatus) {
                    case "all": {
                        break;
                    }
                    case "linked": {
                        query.filters.push(cat.FilterMsg.create({
                            field: "linkstatus",
                            collection: "receivermedia",
                            value: cat.ReceiverMediaLinkStatus[cat.ReceiverMediaLinkStatus.RECEIVERMEDIALINKSTATUS_LINKED],
                            operator: cat.FilterOperator.FILTER_INCLUDE
                        }));
                        break;
                    }
                    case "unlinked": {
                        query.filters.push(cat.FilterMsg.create({
                            field: "linkstatus",
                            collection: "receivermedia",
                            value: cat.ReceiverMediaLinkStatus[cat.ReceiverMediaLinkStatus.RECEIVERMEDIALINKSTATUS_UNLINKED],
                            operator: cat.FilterOperator.FILTER_INCLUDE
                        }));
                        break;
                    }
                }
            }

            // Add sorting
            if (this.selectedSort) {
                query.sortdirection = (this.selectedSort.direction === "asc") ? cat.SortDirection.SORT_ASCENDING : cat.SortDirection.SORT_DESCENDING;
                query.sortfields = [this.selectedSort.field]
            }

            return query;
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Open help dialog for the filter bar.
     *
     * @memberof GalleryFilterComponent
     */
    public openHelp() {
        try {

            const title1 = new ReplaceTermPipe(this._store).transform(this._translate.instant(protosui.messages.uitext.typefiltertitle));
            const content1 = new ReplaceTermPipe(this._store).transform(this._translate.instant(protosui.messages.uitext.typefiltercontent));

            // Optional help texts.
            let title2: string, content2: string, title3: string, content3: string;

            if (this.includeLinkStatus) {
                title2 = new ReplaceTermPipe(this._store).transform(this._translate.instant(protosui.messages.uitext.linkstatusfiltertitle));
                content2 = new ReplaceTermPipe(this._store).transform(this._translate.instant(protosui.messages.uitext.linkstatusfiltercontent));
            }

            if (this.includeLinkType) {
                title3 = new ReplaceTermPipe(this._store).transform(this._translate.instant(protosui.messages.uitext.linktypefiltertitle));
                content3 = new ReplaceTermPipe(this._store).transform(this._translate.instant(protosui.messages.uitext.linktypefiltercontent));
            }

            const title4 = new ReplaceTermPipe(this._store).transform(this._translate.instant(protosui.messages.uitext.orderfiltertitle));
            const content4 = new ReplaceTermPipe(this._store).transform(this._translate.instant(protosui.messages.uitext.orderfiltercontent));

            let content = "<ol class='cat-padding-md'>";
            content += `<li class="cat-margin-bottom-xl"><h6 class="cat-no-margin"><b>${title1}</b></h6>${content1}</li>`

            if (this.includeLinkStatus) {
                content += `<li class="cat-margin-bottom-xl"><h6 class="cat-no-margin"><b>${title2}</b></h6>${content2}</li>`
            }
            if (this.includeLinkType) {
                content += `<li class="cat-margin-bottom-xl"><h6 class="cat-no-margin"><b>${title3}</b></h6>${content3}</li>`
            }
            content += `<li><h6 class="cat-no-margin"><b>${title4}</b></h6>${content4}</li>`
            content += "</ol>";
            const data: IDialogData = {
                title: protosui.messages.uitext.receivermediafilterbartitle,
                content: content
            }
            this._common.createDialog(data);
        } catch (error) {
            this._logger.error(error);
        }
    }
}