import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";

@Pipe({
  name: "getApptypeByPackagename",
  pure: true
})
/**
* Get the definition for a specific message
* @param {string} msgtype The message type.
* @returns {IMessageDefinitions} The best available contact handle
*/
export class GetApptypeByPackagename implements PipeTransform {
    transform(apptypes: cat.AppTypeMsg[], packagename: string): cat.AppTypeMsg {
        let result: cat.AppTypeMsg = cat.AppTypeMsg.create();
        result = apptypes
            .filter((apptype: cat.AppTypeMsg) => apptype.packagename)
            .find((apptype: cat.AppTypeMsg) => apptype.packagename === packagename);
        return result;
    }
}
