import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { appRouteNames } from "@app/app.routes.names";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { ReportWizardService, Steps, steps } from "@services/report-wizard/report-wizard.service";
import { TlService } from "@services/tl/tl.service";

@Component({
    selector: "report-progress-bar",
    templateUrl: "./report-progress-bar.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportProgressBarComponent {

    @Input() report: cat.ReportMsg = cat.ReportMsg.create();

    public reportIcon = protosui.msgIcons.ReportMsg;
    public mediaType = cat.MediaType;
    public permissionEnum = cat.Permission;
    public steps: Steps[] = [];

    private _profileId: string = "";

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _reportService: ReportWizardService,
        private _logger: LoggerService) {
            this.steps = steps;
            this._profileId = this._activatedRoute.snapshot.params.profileid;
        }

    get reportStatus$() {
        return this._reportService.report$;
    }

    get currentStep$() {
        return this._reportService.step$;
    }

    get detailValidity$() {
        return this._reportService.detailsValid$;
    }

    /**
     * Navigate to a step directly.
     *
     * @param {Steps} wizardStep
     * @param {boolean} isAllowed
     * @returns
     * @memberof ReportProgressBarComponent
     */
    public async goto(wizardStep: Steps, isAllowed: boolean) {
        try {

            if (!isAllowed) {
                return;
            }

            // Update the step, if clicked.
            const step = this._reportService.getStepDefinition(wizardStep.stepNumber);

            // Set conversation view mode
            if (step.stepName === "conversations") {
                const reportState = this._reportService.reportSubject.value;
                if (reportState.conversations.length) {
                    this._reportService.setView("selection");
                } else {
                    this._reportService.setView("choose");
                }
            }

            this._reportService.setCurrentStep(step);

            // Construct the 'absolute' URL.
            const url = `/${appRouteNames.DASHBOARD}/profile/${this._profileId}/${appRouteNames.MANAGE_PROFILE_RERORTS}/${wizardStep.route}`;
            await this._router.navigateByUrl(url);

        } catch (error) {
            this._logger.error(error);
        }
    }


}
