// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { Subject } from "rxjs";
import { LoggerService } from "@services/logger/logger.service";
import { cat } from "@assets/proto/msgs";
import { Injectable } from "@angular/core";
import { IAvailableFilters, IIdRef } from "@shared/app-models";
import * as calls from "@assets/proto/services";

@Injectable({
    providedIn: "root"
})

export class QueryService {

    // Use (volatile) subjects for change detection
    public filterCalls: Map<string, Subject<boolean>> = new Map<string, Subject<boolean>>();

    public defaultQueries: Map<string, cat.QueryMsg> = new Map([
        [
            calls.userGetConversationTopics.methodName, cat.QueryMsg.create({
                filters: [
                    {
                        collection: "topics",
                        field: "state",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.TopicState[cat.TopicState.TOPICSTATE_READY]
                        ]
                    }
                ]
            })
        ],
        [
            calls.userGetAccountServers.methodName, cat.QueryMsg.create({
                count: 0,
                offset: 0,
                search: "",
                sortdirection: cat.SortDirection.SORT_NONE,
                sortfields: [],
                filters: [
                    {
                        collection: "servers",
                        field: "state",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.ServerState[cat.ServerState.SERVERSTATE_READY]
                        ]
                    },
                    {
                        collection: "servers",
                        field: "visibility",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.VisibilityType[cat.VisibilityType.VISIBILITY_VISIBLE],
                        ]
                    }
                ]
            })
        ],
        [
            calls.userGetServerConversations.methodName, cat.QueryMsg.create({
                count: 0,
                offset: 0,
                search: "",
                sortdirection: cat.SortDirection.SORT_NONE,
                sortfields: [],
                filters: [
                    {
                        collection: "conversations",
                        field: "state",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_READY],
                            cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_PENDING]
                        ]
                    },
                    {
                        collection: "conversations",
                        field: "lastmessageid",
                        operator: cat.FilterOperator.FILTER_EXCLUDE,
                        value: "",
                        values: [""],
                    },
                    {
                        collection: "conversations",
                        field: "type",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.ConversationType[cat.ConversationType.CONVERSATION_CHANNEL],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_PRIVATE],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_SECRET],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_GROUP],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_SUPERGROUP],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_STORY],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_PUBLIC],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_SELF]
                        ]
                    },
                    {
                        collection: "conversations",
                        field: "visibility",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.VisibilityType[cat.VisibilityType.VISIBILITY_VISIBLE],
                        ]
                    },

                ]
            })
        ],
        [
            calls.userGetAccountConversations.methodName, cat.QueryMsg.create({
                count: 0,
                offset: 0,
                search: "",
                sortdirection: cat.SortDirection.SORT_NONE,
                sortfields: [],
                filters: [
                    {
                        collection: "conversations",
                        field: "state",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_READY],
                            cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_PENDING]
                        ]
                    },
                    {
                        collection: "conversations",
                        field: "lastmessageid",
                        operator: cat.FilterOperator.FILTER_EXCLUDE,
                        value: "",
                        values: [""],
                    },
                    {
                        collection: "conversations",
                        field: "type",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.ConversationType[cat.ConversationType.CONVERSATION_CHANNEL],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_PRIVATE],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_SECRET],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_GROUP],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_SUPERGROUP],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_STORY],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_PUBLIC],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_SELF]
                        ]
                    },
                    {
                        collection: "conversations",
                        field: "visibility",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.VisibilityType[cat.VisibilityType.VISIBILITY_VISIBLE],
                        ]
                    }
                ]
            })
        ],
        [
            calls.userGetProfileConversations.methodName, cat.QueryMsg.create({
                count: 0,
                offset: 0,
                search: "",
                sortdirection: cat.SortDirection.SORT_NONE,
                sortfields: [],
                filters: [
                    {
                        collection: "conversations",
                        field: "state",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_READY],
                            cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_PENDING]
                        ]
                    },
                    {
                        collection: "conversations",
                        field: "type",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.ConversationType[cat.ConversationType.CONVERSATION_CHANNEL],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_PRIVATE],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_SECRET],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_GROUP],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_SUPERGROUP],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_STORY],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_PUBLIC],
                            cat.ConversationType[cat.ConversationType.CONVERSATION_SELF]
                        ]
                    },
                    {
                        collection: "conversations",
                        field: "lastmessageid",
                        operator: cat.FilterOperator.FILTER_EXCLUDE,
                        value: "",
                        values: [],
                    },
                    {
                        collection: "conversations",
                        field: "visibility",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        value: "",
                        values: [
                            cat.VisibilityType[cat.VisibilityType.VISIBILITY_VISIBLE],
                        ]
                    }
                ]
            })
        ],
        [ calls.userGetProfileContacts.methodName, cat.QueryMsg.create({
            count: 0,
                offset: 0,
                search: "",
                sortdirection: cat.SortDirection.SORT_DESCENDING,
                sortfields: ["lastmodified"],
                filters: []
            })
        ],
        [ calls.userGetDeviceBackups.methodName, cat.QueryMsg.create({
            count: 0,
                offset: 0,
                search: "",
                sortdirection: cat.SortDirection.SORT_DESCENDING,
                sortfields: ["backuptimestamp"],
                filters: []
            })
        ],
        [ calls.userGetCurrentUserProfileReports.methodName, cat.QueryMsg.create({
            count: 0,
                offset: 0,
                search: "",
                sortdirection: cat.SortDirection.SORT_DESCENDING,
                sortfields: ["lastmodified"],
                filters: []
            })
        ],
        [ calls.userSearchTopicMessages.methodName, cat.QueryMsg.create({
            count: 0,
                offset: 0,
                search: "",
                filters: []
            })
        ],
        [ calls.userSearchConversationMessages.methodName, cat.QueryMsg.create({
            count: 0,
                offset: 0,
                search: "",
                filters: []
            })
        ]
    ]);


    // All available filters, to show all filters
    public availableFilters: Map<string, cat.FilterMsg[]> = new Map([
        [ calls.userGetConversationTopics.methodName, [
            cat.FilterMsg.create({
                collection: "topics",
                field: "state",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.TopicState[cat.TopicState.TOPICSTATE_READY],
                    cat.TopicState[cat.TopicState.TOPICSTATE_LOCKED],
                    cat.TopicState[cat.TopicState.TOPICSTATE_CLOSED]
                ]
            })
        ]],
        [ calls.userGetServerConversations.methodName, [
            cat.FilterMsg.create({
                collection: "conversations",
                field: "lastmessageid",
                operator: cat.FilterOperator.FILTER_EXCLUDE,
                value: "",
                values: [""]
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "state",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_READY],
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_PENDING],
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_CLOSED],
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_LEFT]
                ]
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "type",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.ConversationType[cat.ConversationType.CONVERSATION_CHANNEL],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_PRIVATE],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_SECRET],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_GROUP],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_SUPERGROUP],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_STORY],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_THREAD],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_VOICE],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_FEED],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_PUBLIC],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_SELF]
                ]
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "visibility",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.VisibilityType[cat.VisibilityType.VISIBILITY_VISIBLE],
                    cat.VisibilityType[cat.VisibilityType.VISIBILITY_HIDDEN],
                ]
            }),
        ]],
        [ calls.userGetAccountServers.methodName, [
            cat.FilterMsg.create({
                collection: "servers",
                field: "state",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.ServerState[cat.ServerState.SERVERSTATE_READY],
                    cat.ServerState[cat.ServerState.SERVERSTATE_CLOSED],
                ]
            }),
            cat.FilterMsg.create({
                collection: "servers",
                field: "visibility",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.VisibilityType[cat.VisibilityType.VISIBILITY_VISIBLE],
                    cat.VisibilityType[cat.VisibilityType.VISIBILITY_HIDDEN],
                ]
            })
        ]],
        [ calls.userGetAccountConversations.methodName, [
            cat.FilterMsg.create({
                collection: "conversations",
                field: "lastmessageid",
                operator: cat.FilterOperator.FILTER_EXCLUDE,
                value: "",
                values: [""]
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "state",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_READY],
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_PENDING],
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_CLOSED],
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_LEFT]
                ]
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "type",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.ConversationType[cat.ConversationType.CONVERSATION_CHANNEL],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_PRIVATE],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_SECRET],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_GROUP],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_SUPERGROUP],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_STORY],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_THREAD],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_VOICE],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_FEED],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_PUBLIC],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_SELF]
                ]
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "visibility",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.VisibilityType[cat.VisibilityType.VISIBILITY_VISIBLE],
                    cat.VisibilityType[cat.VisibilityType.VISIBILITY_HIDDEN],
                ]
            })
        ]],
        [ calls.userGetProfileConversations.methodName, [
            cat.FilterMsg.create({
                collection: "conversations",
                field: "lastmessageid",
                operator: cat.FilterOperator.FILTER_EXCLUDE,
                values: [""]
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "state",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_READY],
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_PENDING],
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_CLOSED],
                    cat.ConversationState[cat.ConversationState.CONVERSATIONSTATE_LEFT]
                ]
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "type",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                values: [
                    cat.ConversationType[cat.ConversationType.CONVERSATION_CHANNEL],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_PRIVATE],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_SECRET],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_GROUP],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_SUPERGROUP],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_STORY],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_THREAD],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_VOICE],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_FEED],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_PUBLIC],
                    cat.ConversationType[cat.ConversationType.CONVERSATION_SELF]
                ]
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "visibility",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: [
                    cat.VisibilityType[cat.VisibilityType.VISIBILITY_VISIBLE],
                    cat.VisibilityType[cat.VisibilityType.VISIBILITY_HIDDEN],
                ]
            }),
            cat.FilterMsg.create({
                collection: "accounts",
                field: "id",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: []
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "start",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: []
            }),
            cat.FilterMsg.create({
                collection: "conversations",
                field: "end",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                value: "",
                values: []
            })
        ]],
        [ calls.userGetProfileContacts.methodName, [
            cat.FilterMsg.create({
                collection: "contacts",
                field: "apps",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                values: [""]
            })
        ]],
        [ calls.userGetDeviceBackups.methodName, [
            cat.FilterMsg.create({
                collection: "backups",
                field: "appname",
                operator: cat.FilterOperator.FILTER_INCLUDE,
                values: [""]
            })
        ]]
    ]);

    constructor(
        private _logger: LoggerService
    ) {
        // Initialize map for change detection
        this.filterCalls
            .set(calls.userGetConversationTopics.methodName, new Subject())
            .set(calls.userGetServerConversations.methodName, new Subject())
            .set(calls.userGetAccountServers.methodName, new Subject())
            .set(calls.userGetAccountConversations.methodName, new Subject())
            .set(calls.userGetProfileConversations.methodName, new Subject())
            .set(calls.userGetProfileAccounts.methodName, new Subject())
            .set(calls.userGetCases.methodName, new Subject())
            .set(calls.userGetCurrentUserCases.methodName, new Subject())
            .set(calls.userGetCurrentUserProfiles.methodName, new Subject())
            .set(calls.userSearchAccountsAndContactsSystemWide.methodName, new Subject())
            .set(calls.userSearchConversationsSystemWide.methodName, new Subject())
            .set(calls.userGetProfileContacts.methodName, new Subject())
            .set(calls.userGetDeviceBackups.methodName, new Subject())
            .set(calls.userSearchConversationMessages.methodName, new Subject())
            .set(calls.userSearchTopicMessages.methodName, new Subject())
            .set(calls.userGetAccountContacts.methodName, new Subject())
            .set(calls.userGetCurrentUserProfileReports.methodName, new Subject())
            .set(calls.userGetConversationMembers.methodName, new Subject())
            .set(calls.userGetAvailableCaseProfiles.methodName, new Subject())
            .set(calls.userGetSupervisorUsers.methodName, new Subject()
        );

        // Initialize default values
        this.initialize();
    }

    /**
     * Initialize
     *
     * @memberof QueryService
     */
    public initialize() {
        try {

            for (const callName of this.defaultQueries.keys()) {
                // Try to get the stored query.
                const storedQuery = this.getStoredQuery(callName);
                // If nothing stored, add the default.
                if (!storedQuery) {
                    const defaultQuery = this.defaultQueries.get(callName);
                    this.storeQueryMsg(callName, defaultQuery);
                }
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Store a query in persistent storage (for keekping state).
     *
     * @param {string} path The cursor / path (callname in most cases).
     * @param {cat.QueryMsg} query The query to store.
     * @param {string} [searchvalue] An optional search value.
     * @memberof QueryService
     */
    public storeQueryMsg(path: string, query: cat.QueryMsg, searchvalue?: string) {
        try {

            if (path) {

                let storeQuery: cat.QueryMsg = query;

                if (!storeQuery) {
                    storeQuery = cat.QueryMsg.create();
                }

                // // Attach searchvalue, if provided, to the updated query.
                if (searchvalue) {
                    storeQuery.search = searchvalue;
                }

                const queryObj = cat.QueryMsg.toObject(storeQuery, {
                    defaults: true, // includes default values
                    arrays: true, // populates empty arrays (repeated fields) even if defaults=false
                    objects: true // populates empty objects (map fields) even if defaults=false
                });
                this._logger.debug(`Store the query...`, queryObj);

                // Store the merged query
                localStorage.setItem(path, JSON.stringify(queryObj));
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Get a query from persistant storage.
     *
     * @param {string} path
     * @returns {<cat.QueryMsg>}
     * @memberof QueryService
     */
    public getStoredQuery(path: string): cat.QueryMsg {
        try {

            let result: cat.QueryMsg | undefined;

            if (path && this.filterCalls.has(path)) {
                // Attach the query, if found, from persistent storage.
                const storedQuery = localStorage.getItem(path);
                if (!storedQuery) {
                    this._logger.debug("No stored query found...");
                } else {
                    result = cat.QueryMsg.create(JSON.parse(storedQuery));
                }
            } else {
                this._logger.warn("No path / filter call found: ", path);
            }

            return result;
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Reset filters to default values.
     *
     * @param {string} path Name of the call (filter).
     * @memberof QueryService
     */
      public resetQuery(path: string) {
        try {
            if (path && this.defaultQueries.has(path)) {
                this._logger.debug(`Reset ${path} query to default`);
                this.storeQueryMsg(path, this.defaultQueries.get(path));
            } else {
                this._logger.debug("No path provided / filter found, can not reset filter...", path);
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Get the current filter values for a specific path.
     *
     * @param {string} path The path / callname.
     * @returns {<{ [key: string]: string[] }>} The filters.
     * @memberof QueryService
     */
    public getCurrentFilters(path: string): { [key: string]: string[] } {
        try {
            const result: { [key: string]: string[] } = {};

            if (path && this.filterCalls.has(path)) {

                const storedQuery: cat.QueryMsg = this.getStoredQuery(path);
                const filters: IAvailableFilters[] = this.availableFilters.get(path);
                // Use default filters, if no stored query is found
                if (!storedQuery?.filters?.length) {
                    this._logger.warn("Get default filters", this.defaultQueries.get(path));
                } else {
                    // Add the current filters.
                    storedQuery.filters.map(storedFilter => {
                        const availableFilter = filters.find(availableFilter => availableFilter.field === storedFilter.field);
                        if (availableFilter) {
                            result[availableFilter.field] = storedFilter.values;
                        }
                    })
                }
            }
            return result;
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Add available filter to a query.
     *
     * @param {string} path
     * @param {string} field
     * @memberof QueryService
     */
    public addAvailableFilter(path: string, field: string) {
        try {
            if (path && field) {

                // Get the stored query.
                const storedQuery: cat.QueryMsg = this.getStoredQuery(path);

                if (storedQuery) {
                    // Determine the available filter with the same field.
                    const availableFilter = this.availableFilters.get(path).find(filter => filter.field === field);
                    if (availableFilter) {
                        // Remove the existing filter, if found.
                        const fieldFilterIndex = storedQuery.filters.findIndex(storedFilter => storedFilter.field === field);
                        if (fieldFilterIndex > -1) {
                            storedQuery.filters.splice(fieldFilterIndex, 1);
                        }
                        // Add the 'default' filter and store it.
                        storedQuery.filters.push(availableFilter);
                        this.storeQueryMsg(path, storedQuery);
                    }
                } else {
                    this._logger.debug("No stored query found...");
                }
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Clear the search value of a specific query
     *
     * @param {string} path
     * @memberof QueryService
     */
    public clearSearchvalue(path: string) {
        try {
            if (path && path.length && this.filterCalls.has(path)) {

                // Attach the query, if found, from persistent storage
                const storedQuery: cat.QueryMsg = this.getStoredQuery(path);

                if (storedQuery) {
                    // Clear the search value
                    storedQuery.search = "";
                    // Store the new query
                    this._logger.debug(`Store a new query without search value: `, storedQuery);
                    this.storeQueryMsg(path, storedQuery);
                } else {
                    this._logger.debug(`No query found: `, storedQuery);
                }

            } else {
                this._logger.warn("No path / filter call found: ", path);
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Set / replace a filter value, or add the filter with value if not found.
     *
     * @param {string} path
     * @param {string} field
     * @param {string} value
     * @memberof QueryService
     */
    public setSingleFilterValue(path: string, collection: string, field: string, value: string) {
        try {
            if (path && field && value && collection) {

                // Attach the query, if found, from persistent storage
                let storedQuery: cat.QueryMsg = this.getStoredQuery(path);
                this._logger.debug("Stored Q", storedQuery);
                if (!storedQuery) {
                    // If nothing stored, add the default.
                    const defaultQuery = this.defaultQueries.get(path);
                    this.storeQueryMsg(path, defaultQuery);
                    storedQuery = this.getStoredQuery(path);
                }
                const fieldFilter = storedQuery.filters.find(storedFilter => storedFilter.field === field);
                if (fieldFilter) {
                    fieldFilter.collection = collection;
                    fieldFilter.value = value;
                    this.storeQueryMsg(path, storedQuery);
                } else {
                    this._logger.debug("Filter not yet found in stored query.");
                    // Add the filter, if available.
                    const filter = this.availableFilters.get(path).find(filter => filter.field === field);
                    if (filter) {
                        filter.collection = collection;
                        filter.value = value;
                        storedQuery.filters.push(filter);
                        this.storeQueryMsg(path, storedQuery);
                    }
                }
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Add a value to a filter values array.
     * This is NOT adding a single value.
     *
     * @param {string} path
     * @param {string} field
     * @param {string} value
     * @memberof QueryService
     */
    public addFilterValue(path: string, field: string, value: string) {
        try {
            if (path && field && value) {

                // Attach the query, if found, from persistent storage
                let storedQuery: cat.QueryMsg = this.getStoredQuery(path);
                this._logger.debug("Stored Q", storedQuery);
                if (!storedQuery) {
                    // If nothing stored, add the default.
                    const defaultQuery = this.defaultQueries.get(path);
                    this.storeQueryMsg(path, defaultQuery);
                    storedQuery = this.getStoredQuery(path);
                }
                const fieldFilter = storedQuery.filters.find(storedFilter => storedFilter.field === field);
                if (fieldFilter) {
                    fieldFilter.values.push(value);
                    this.storeQueryMsg(path, storedQuery);
                } else {
                    this._logger.debug("Filter not yet found in stored query.");
                    // Add the filter, if available.
                    const filter = this.availableFilters.get(path).find(filter => filter.field === field);
                    if (filter) {
                        filter.values.push(value);
                        storedQuery.filters.push(filter);
                        this.storeQueryMsg(path, storedQuery);
                    }
                }
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Remove a value from a filter values array.
     * This is NOT adding a single value.
     *
     * @param {string} path
     * @param {string} field
     * @param {string} value
     * @memberof QueryService
     */
    public removeFilterValue(path: string, field: string, value: string) {
        try {
            if (path && field && value) {

                // Attach the query, if found, from persistent storage
                const storedQuery: cat.QueryMsg = this.getStoredQuery(path);
                if (storedQuery) {
                    const fieldFilter = storedQuery.filters.find(storedFilter => storedFilter.field === field);
                    if (fieldFilter) {
                        const index = fieldFilter.values.indexOf(value);
                        fieldFilter.values.splice(index, 1);
                        this.storeQueryMsg(path, storedQuery);
                    }
                }
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Replace a whole list of filter values at once.
     *
     * @param {string} path The cursur.
     * @param {string} field The specific field.
     * @param {string[]} values The new values.
     * @memberof QueryService
     */
    public replaceFilterValues(path: string, field: string, values: string[]) {
        try {
            if (path && field && values.length) {

                // Attach the query, if found, from persistent storage
                let storedQuery: cat.QueryMsg = this.getStoredQuery(path);
                if (!storedQuery) {
                    // If nothing stored, add the default.
                    const defaultQuery = this.defaultQueries.get(path);
                    this.storeQueryMsg(path, defaultQuery);
                    storedQuery = this.getStoredQuery(path);
                }

                // Find the specific filter.
                const fieldFilter = storedQuery?.filters.find(storedFilter => storedFilter.field === field);

                // Add the values.
                if (fieldFilter) {
                    fieldFilter.values = values;
                } else {
                    const filter = this.availableFilters.get(path).find(filter => filter.field === field);
                    if (filter) {
                        filter.values = values;
                        storedQuery.filters.push(filter);
                    }
                }
                // Store the query.
                this.storeQueryMsg(path, storedQuery);
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Remove a filter from a stored query, and store it again.
     *
     * @param {string} path The cursor / path.
     * @param {string} field The field to be removed.
     * @memberof QueryService
     */
    public removeFilter(path: string, field: string) {
        try {
            if (path && field) {
                // Attach the query, if found, from persistent storage.
                const storedQuery: cat.QueryMsg = this.getStoredQuery(path);
                if (storedQuery) {
                    const fieldFilterIndex = storedQuery.filters.findIndex(storedFilter => storedFilter.field === field);
                    if (fieldFilterIndex > -1) {
                        storedQuery.filters.splice(fieldFilterIndex, 1);
                        this.storeQueryMsg(path, storedQuery);
                    }
                }
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Check if a call had id filtering turned on,
     * to update instead of replace a list for instance
     *
     * @private
     * @param {IIdRef} idref
     * @returns {boolean}
     * @memberof WsService
     */
    public hasIdFilter(idref: IIdRef): boolean {
        try {
            let result = false;
            // Check if a filter is sent with id as field.
            if (idref?.query?.filters?.length) {
                result = idref.query.filters
                    .some((sentFilter: cat.FilterMsg) => (sentFilter.field === "id" && (sentFilter.value && sentFilter.value.length > 0)));
            }
            return result;
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Remove a specific query completely.
     *
     * @private
     * @param {string} path
     * @memberof QueryService
     */
    public removeQuery(path: string) {
        try {
            if (path && path.length && this.filterCalls.has(path)) {
                localStorage.removeItem(path);
            } else {
                this._logger.warn("No path / filter call found: ", path);
            }
        } catch (error) {
            this._logger.error(error);
        }
    }
}
