<a
    *ngIf="apk.apkfile"
    download [href]="apk.apkfile | catsrc : 'download' : mediaType.MEDIA_APK : undefined"
    mat-menu-item extended color="primary">
    <mat-icon color="dark">description</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
        <span color="primary"><b>{{ "Download APK" | translate }}</b></span>
        <br>
        <span color="medium-shade">{{ apk.apkfile.filesize | filesize }}</span>
    </div>
</a>

<a
    *ngIf="apk.patchedfile"
    download [href]="apk.patchedfile | catsrc : 'download' : mediaType.MEDIA_APK : undefined"
    mat-menu-item extended color="primary">
    <mat-icon color="dark">note_add</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
        <span color="primary"><b>{{ "Download patched APK" | translate }}</b></span>
        <br>
        <span color="medium-shade">{{ apk.patchedfile.filesize | filesize }}</span>
    </div>
</a>

<a
    *ngIf="apk.logfile"
    download [href]="apk.logfile | catsrc : 'download' : mediaType.MEDIA_APK : undefined"
    mat-menu-item extended color="primary">
    <mat-icon color="dark">text_snippet</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
        <span color="primary"><b>{{ "Download patch log" | translate }}</b></span>
        <br>
        <span color="medium-shade">{{ apk.logfile.filesize | filesize }}</span>
    </div>
</a>