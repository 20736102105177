import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { cloneDeep } from "lodash-es";

/**
 * Group messages, and keep the array ordered as delivered
 *
 * @export
 * @class GroupMessagesPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: "groupMessages",
  pure: true
})

export class GroupMessagesPipe implements PipeTransform {

    transform(msgArray: cat.MessageMsg[]) {

        if (!msgArray?.length) {
            return [];
        }

        // Store the last known group id to compare with the current group
        let lastKnownGroupId = undefined;

        const result = msgArray.reduce((accumulator: cat.MessageMsg[], currentMsg: cat.MessageMsg) => {

            // Check if the currently processed message is part of a group
            if (currentMsg?.group) {

                // Determine overall bodies for the current message group.
                let bodyCount = 0;
                msgArray.filter(msg => msg.group === currentMsg.group).forEach(msg => {
                    if (msg.body) bodyCount++
                });

                if (lastKnownGroupId != currentMsg.group) {

                    // New group detected
                    lastKnownGroupId = currentMsg.group;

                    const lastKnownGroupMessage: cat.MessageMsg = cloneDeep(currentMsg);

                    // If multiple messages have a body, then set the body as a caption
                    if (bodyCount > 1) {
                        if (lastKnownGroupMessage.attachments?.length) {
                            lastKnownGroupMessage.attachments[0].content = { "caption": currentMsg.body }
                        }
                        lastKnownGroupMessage.body = "";
                    }

                    // Push the parent group message to the array
                    accumulator.push(lastKnownGroupMessage);

                } else {

                    // Current message is part of the same group, the parent
                    // group message should be the last item in the accumulator
                    // combine msg.body and msg.attachments

                    // Look up parent group message, last message in accumulator with group.
                    // Deep copy to prevent errors.
                    const lastGroupIndex = accumulator.findIndex(msg => msg.group === lastKnownGroupId);
                    const lastGroupItem = accumulator.splice(lastGroupIndex, 1);
                    if (!lastGroupItem.length) {
                        throw new Error("Group message not found.");
                    }
                    const lastKnownGroupMessage: cat.MessageMsg = cloneDeep(lastGroupItem[0]);

                    // Determine the body / captions
                    // Check if we need to set a body or a caption (on multiple attachments for TG)
                    // Multiple bodies means only captions below the attachments and no message body
                    if (bodyCount < 2) {
                        if (currentMsg.body) {
                            lastKnownGroupMessage.body = currentMsg.body;
                        }
                    } else {
                        lastKnownGroupMessage.body = "";
                    }

                    // Combine the attachments
                    currentMsg.attachments.map((attachment: cat.AttachmentMsg) => {
                        if (!lastKnownGroupMessage.attachments.some((att: cat.AttachmentMsg) => att.id === attachment.id)) {
                            if (bodyCount > 1) {
                                lastKnownGroupMessage?.attachments.push({
                                    ...attachment,
                                    content: { "caption": currentMsg.body }
                                });
                            } else {
                                lastKnownGroupMessage?.attachments.push(attachment);
                            }
                        }
                    });

                    // Combine hashtags, mentions and urls
                    lastKnownGroupMessage.hashtags = lastKnownGroupMessage.hashtags.concat(currentMsg.hashtags);
                    lastKnownGroupMessage.mentions = lastKnownGroupMessage.mentions.concat(currentMsg.mentions);
                    lastKnownGroupMessage.urls = lastKnownGroupMessage.urls.concat(currentMsg.urls);

                    // Combine last edited
                    if (lastKnownGroupMessage.lastedited < currentMsg.lastedited) {
                        lastKnownGroupMessage.lastedited = currentMsg.lastedited;
                    }

                    // Combine historytimestamp
                    if (lastKnownGroupMessage.historytimestamp < currentMsg.historytimestamp) {
                        lastKnownGroupMessage.historytimestamp = currentMsg.historytimestamp;
                    }

                    // Combine history markers
                    currentMsg.historymarkers.forEach((marker) => {
                        if (marker != cat.HistoryMarker.HISTORYMARKER_GROUP && !lastKnownGroupMessage.historymarkers.includes(marker)) {
                            lastKnownGroupMessage.historymarkers.push(marker);
                        }
                    });
                    lastKnownGroupMessage.historymarkers =
                        lastKnownGroupMessage.historymarkers.filter((marker) => marker != cat.HistoryMarker.HISTORYMARKER_GROUP);

                    // Add the message back to the accumulator
                    accumulator.push(lastKnownGroupMessage);
                }
            } else {
                // If not part of a group, remove the last known group id and push to the accumulator
                if (!currentMsg["isNewDay"]) {
                    lastKnownGroupId = undefined;
                }
                accumulator.push(currentMsg);
            }
            return accumulator;
        }, []);
        return result;
    }
}
