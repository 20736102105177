import { Store } from "@ngxs/store";
import { Component, OnInit, ChangeDetectionStrategy, inject } from "@angular/core";
import { Router } from "@angular/router";
import { appRouteNames } from "@app/app.routes.names";
import { cat } from "@assets/proto/msgs";
import { userGetCurrentLicense } from "@assets/proto/services";
import { TlService } from "@services/tl/tl.service";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { WsService } from "@services/ws/ws.service";
import { Observable } from "rxjs";
import { protosui } from "@definitions/definitions";
import { IState } from "@shared/app-models";
@Component({
    selector: "widget-license",
    templateUrl: "./widget-license.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetLicenseComponent implements OnInit {

    license$: Observable<cat.LicenseMsg> = inject(Store).select((state: IState) => state.cat.userGetCurrentLicense.msg);

    public permissionEnum = cat.Permission;
    public permissionDef = protosui.def.Permission;
    public receiverModeEnum = cat.ReceiverMode;
    public receiverModeDef = protosui.def.ReceiverMode;

    constructor(
        private _common: CommonService,
        public tl: TlService,
        private _logger: LoggerService,
        private _router: Router,
        private _ws: WsService
    ) {
    }

    /**
     * Create form data and fetch the annotations
     */
    ngOnInit() {
        // Fetch initial license info
        this.userGetCurrentLicense();
    }

    /**
     * Get the global license
     *
     * @memberof WidgetLicenseComponent
     */
    async userGetCurrentLicense() {
        try {
            await this._ws.sendRequest(userGetCurrentLicense);
        } catch (error) {
            this._logger.error(error);
            this._common.createSnackbar(error);
        }
    }

    /**
     * Navigate to manage licenses
     *
     * @memberof WidgetLicenseComponent
     */
    gotoLicense() {
        this._router.navigate([`/${appRouteNames.MANAGE_LICENSES}`]);
    }
}
