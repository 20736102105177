import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from "@angular/core";
import { ReportForm } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { QueryService } from "@services/query/query.service";
import { Subject, debounceTime, distinctUntilChanged } from "rxjs";
import { SubscriptionManager } from "@shared/subscription-manager";
import { protosui } from "@definitions/definitions";
@Component({
    selector: "cat-searchbar",
    templateUrl: "./searchbar.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchbarComponent extends SubscriptionManager implements OnInit, OnDestroy {

    @ViewChild("searchField1") searchField1: ElementRef;

    // Component inputs.
    @Input() _refId = "";
    @Input() dense = false;
    @Input() multiSearch = false;
    @Input() searchCount = 0;
    @Input() searchLabel = "";
    @Input() noBackground = false;
    @Input() searchTypes: { name: string, value: string }[] = [];

    @Input() set setDisabled(disabled: boolean) {
        if (disabled) {
            this.disabled = true;
        } else {
            this.disabled = false;
        }
    }

    @Input() set clearSearch(clear: { refresh: boolean }) {
        this._logger.debug("CLEAR SEARCH", clear);
        if (clear?.refresh === true) {
            this._logger.debug("CLEAR SEARCH 2", clear);
            this.clearSearchvalue();
        }
    }

    /** Path to store filter in (e.g. userGetCurrentUserProfiles) */
    @Input() set filterPath(path: string) {
        if (path?.length) {
            this._path = path;
        }
    }

    /** Set search value programatically. */
    @Input() set setValue(value: string) {
        if (value?.length) {
            this._setValue = value;
        }
    }

    /** Set search value programatically. */
    @Input() set setType(value: { name: string, value: string }) {
        if (value?.name?.length && value?.value?.length) {
            this._setType = value;
        }
    }

    // Component outputs
    @Output() emitSelectedType = new EventEmitter<{ name: string, value: string }>();
    @Output() emitSearchChanged = new EventEmitter<string>();

    // Component properties
    public reportFormEnum = ReportForm;
    public searchValue = "";
    public searchValueModel = "";
    public disabled = false;
    public focus = false;
    public selectedType: { name: string, value: string } = { name: "", value: "" };
    public uitext = protosui.messages.uitext;
    // Private properties
    private _path = "";
    private inputChanged = new Subject<string>();
    private _setValue = "";
    private _setType: { name: string, value: string };
    private _init = 0;

    constructor(
        public common: CommonService,
        private _cdr: ChangeDetectorRef,
        private _queryService: QueryService,
        private _logger: LoggerService
    ) { super(); }

    ngAfterViewChecked() {
        if (this._init < 2 && this.searchField1 && this._setValue && this._setValue !== this.searchField1.nativeElement.value) {
            this.searchValue = this._setValue;
            const inputElement = this.searchField1.nativeElement;
            inputElement.value = this._setValue;

            // Trigger the input event
            const event = new Event('input', { bubbles: true });
            inputElement.dispatchEvent(event);
            this._init++;
        }
    }

    ngOnInit(): void {
        this._logger.debug("Init searchbar", this._path);

        if (this.searchTypes?.length) {
            this.selectedType = (this._setType) ? this._setType : this.searchTypes[0];
        }

        this.addSubscriptions([
            this.inputChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
                // Handle the debounced input value change here
                this._logger.debug(`Search with value ${value} and path ${this._path}`);
                // Perform the search
                this.searchValue = value;
                if (this._path) {
                    if (value.length) {
                        const storedQuery = this._queryService.getStoredQuery(this._path);
                        this._queryService.storeQueryMsg(this._path, storedQuery, value);
                    } else {
                        this._queryService.clearSearchvalue(this._path);
                    }
                    this.performSearch();
                }
                this.emitSearchChanged.emit(value);
                this.common.detectChange(this._cdr);
            })
        ]);

        // Restore search.
        const query = this._queryService.getStoredQuery(this._path);
        if (query?.search) {
            this.onInputChange(query.search);
        }
    }

    /**
     * Remove search value on destroy
     *
     * @memberof SearchbarComponent
     */
    async ngOnDestroy() {
        if (this._path) {
            this._logger.debug(`Clear search query, leave anything else`);
            this._queryService.clearSearchvalue(this._path);
        }
        // Ensure all subscriptions are removed.
        this.unsubscribeAll();
    }

    onInputChange(newValue: string) {
        this.inputChanged.next(newValue);
    }

    /**
     * Clear the value from the input and trigger a new search.
     *
     * @memberof SearchbarComponent
     */
    public clearSearchvalue() {
        // Clear the text inside the input.
        this.onInputChange("");
    }

    selectType(type: { name: string, value: string }) {
        this.emitSelectedType.emit(type);
        this.selectedType = type;
        this._queryService.filterCalls.get(this._path).next(true);
    }

    /**
     * Perform a new search by emitting a new event for the current call name
     *
     * @memberof SearchbarComponent
     */
    public performSearch() {
        if (this._path?.length && this._queryService.filterCalls.has(this._path)) {
            this._queryService.filterCalls.get(this._path).next(true);
        } else {
            this._logger.debug("No path provided or this call is not added to the filter bar service yet: ", this._path);
        }
    }
}
