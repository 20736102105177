<ng-container *ngIf="template?.inputfields?.length">
    <div [formGroup]="templateForm" class="cat-full-width cat-margin-top-xl">

        <h4>{{ "Report template textfields" | translate }}</h4>

        <div class="cat-margin-top-md">
            <div class="cat-button-row">
                <ng-container *ngFor="let item of template.inputfields">
                    <div *ngIf="[textType.TEXTFIELD_REPORT_TITLE_INPUT, textType.TEXTFIELD_REPORT_SUBTITLE_INPUT, textType.TEXTFIELD_HEADING_INPUT, textType.TEXTFIELD_INPUT].includes(item.type)" class="cat-button-row-item">
                        <mat-form-field appearance="fill" class="cat-align-top" floatLabel="always">
                            <mat-label>{{ item.name }} ({{ item.component }})</mat-label>
                            <input matInput [formControlName]="item.name">
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="cat-margin-top-md">
            <div class="cat-button-row">
                <ng-container *ngFor="let item of template.inputfields">
                    <div *ngIf="item.type === textType.TEXTFIELD_MULTILINE_INPUT" class="cat-button-row-item">
                        <mat-form-field appearance="fill" floatLabel="always">
                            <mat-label>{{ item.name }} ({{ item.component }})</mat-label>
                            <span matTextPrefix class="cat-opacity-4">{{ item.label }}</span>
                            <textarea matInput [formControlName]="item.name" autoGrow="true" rows="7" autocapitalize="off"></textarea>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </div>

    </div>
</ng-container>