import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
@Component({
  selector: "register-capture-modal",
  templateUrl: "./register-capture.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterCaptureModal implements OnInit {

    public device: cat.DeviceMsg = cat.DeviceMsg.create();
    public uitext = protosui.messages.uitext;
    public mediaType = cat.MediaType;
    public showManual = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RegisterCaptureModal>
    ) {}

    /**
    * Fetch the params everytime the popup opens
    */
    async ngOnInit() {
        this.device = this.data?.device;
    }


    /**
     * Close dialig by button.
     *
     * @memberof RegisterCaptureModal
     */
    closeDialog() {
        this.dialogRef.close();
    }
}