// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { PipesModule } from "@pipes/pipes.module";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AnnotationsComponent } from "./annotations/annotations.component";
import { AssignUserProfileComponent } from "./assign-user-profile/assign-user-profile.component";
import { ConversationMemberComponent } from "./conversationmembers/conversationmembers.component";
import { DeviceAccountsPopoverComponent } from "./dialog-device-accounts/popover-device-accounts.component";
import { DownloadLogComponent } from "./dialog-download-log/download-log.component";
import { LabelsComponent } from "./labels/labels.component";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MsgAttachmentsComponent } from "./msg-attachment/msg-attachment.component";
import { MsgComponent } from "./message/msg.component";
import { MsgMenuPopoverComponent } from "./popover-msg-menu/msg-menu-popover.component";
import { MsgSearchResultComponent } from "./msg-search-result/msg-search-result.component";
import { PopoverFilterMenuComponent } from "./dialog-chat-filter/popover-filter-menu.component";
import { ReviewersPopoverComponent } from "./dialog-reviewers/popover-reviewers.component";
import { SearchbarComponent } from "./searchbar/searchbar.component";
import { WidgetDataCleaningComponent } from "./widget-data-cleaning/widget-data-cleaning.component";
import { WidgetDeviceComponent } from "./widget-device/widget-device.component";
import { WidgetLicenseComponent } from "./widget-license/widget-license.component";
import { WidgetProfileComponent } from "./widget-profile/widget-profile.component";
import { WidgetUserSessionsComponent } from "./widget-user-sessions/widget-user-sessions.component";
import { WidgetSystemwideSearchThemesComponent } from "./widget-systemwide-search-themes/widget-systemwide-search-themes.component";
import { HeaderComponent } from "./app-header/app-header.component";
import { JumpToDatePopoverComponent } from "./dialog-jump-to-date/popover-jump-to-date.component";
import { ReactionComponent } from "./reaction/reaction.component";
import { WidgetStatisticsComponent } from "./widget-statistics/widget-statistics.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSelectModule } from "@angular/material/select";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ReportFormComponent } from "./report-form/report-form.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatBadgeModule } from "@angular/material/badge";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ChatItemComponent } from "./chat-item/chat-item.component";
import { ServerItemComponent } from "./server-item/server-item.component";
import { TopicItemComponent } from "./topic-item/topic-item.component";
import { MatMenuModule } from "@angular/material/menu";
import { LottieContainerComponent } from "./lottie-container/lottie-container.component";
import { InputFieldComponent } from "./formfields/input-field/input-field.component";
import { DirectivesModule } from "@directives/directives.module";
import { PasswordFieldComponent } from "./formfields/password-field/password-field.component";
import { ChatItemMenuComponent } from "./chat-item-menu/chat-item-menu.component";
import { SelectionFieldComponent } from "./formfields/selection-field/selection-field.component";
import { TextareaFieldComponent } from "./formfields/textarea-field/textarea-field.component";
import { ReportDownloadMenuComponent } from "./report-download-menu/report-download-menu.component";
import { ApkDownloadMenuComponent } from "./apk-download-menu/apk-download-menu.component";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { GenericDialog } from "./dialog-generic/generic-dialog.component";
import { CalendarHeader } from "./calendar/calendar-header/calendar-header.component";
import { ReportProgressBarComponent } from "./report-progress-bar/report-progress-bar.component";
import { ReportBottomNavigationComponent } from "./report-bottom-navigation/report-bottom-navigation.component";
import { CatSelectPopoverComponent } from "./dialog-cat-select/popover-cat-select.component";
import { AccountItemComponent } from "./account-item/account-item.component";
import { CheckboxFieldComponent } from "./formfields/checkbox-field/checkbox-field.component";
import { EncryptionDialog } from "./dialog-encryption/dialog-encryption.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { HistoryTimelineComponent } from "./history-timeline/history-timeline.component";
import { LottieComponent } from "ngx-lottie";
import { ConversationMediaComponent } from "./conversation-media/conversation-media.component";
import { GalleryItemComponent } from "./gallery-item/gallery-item.component";
import { GalleryFilterComponent } from "./gallery-filter/gallery-filter.component";
import { ModalHeaderComponent } from "./modal-header/modal-header.component";

@NgModule({
    imports: [
        CommonModule,
        LottieComponent,
        PipesModule,
        TranslateModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        NgxChartsModule,
        FormsModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatGridListModule,
        MatSelectModule,
        MatCardModule,
        MatButtonModule,
        MatListModule,
        MatSlideToggleModule,
        MatBadgeModule,
        MatToolbarModule,
        MatMenuModule,
        DirectivesModule,
        MatTableModule,
        MatCheckboxModule,
        MatDialogModule,
        MatListModule,
        MatPaginatorModule,
        MatSortModule
    ],
    declarations: [
        AnnotationsComponent,
        AssignUserProfileComponent,
        ChatItemComponent,
        ServerItemComponent,
        TopicItemComponent,
        ConversationMemberComponent,
        DeviceAccountsPopoverComponent,
        DownloadLogComponent,
        LabelsComponent,
        MsgAttachmentsComponent,
        MsgComponent,
        MsgMenuPopoverComponent,
        MsgSearchResultComponent,
        HeaderComponent,
        PopoverFilterMenuComponent,
        ReviewersPopoverComponent,
        SearchbarComponent,
        WidgetDataCleaningComponent,
        WidgetDeviceComponent,
        WidgetLicenseComponent,
        WidgetProfileComponent,
        WidgetUserSessionsComponent,
        WidgetSystemwideSearchThemesComponent,
        WidgetStatisticsComponent,
        JumpToDatePopoverComponent,
        ReactionComponent,
        ReportFormComponent,
        LottieContainerComponent,
        InputFieldComponent,
        PasswordFieldComponent,
        SelectionFieldComponent,
        TextareaFieldComponent,
        CheckboxFieldComponent,
        ChatItemMenuComponent,
        ReportDownloadMenuComponent,
        ApkDownloadMenuComponent,
        GenericDialog,
        CalendarHeader,
        ReportProgressBarComponent,
        ReportBottomNavigationComponent,
        CatSelectPopoverComponent,
        AccountItemComponent,
        EncryptionDialog,
        HistoryTimelineComponent,
        ConversationMediaComponent,
        GalleryItemComponent,
        GalleryFilterComponent,
        ModalHeaderComponent
    ],
    exports: [
        AnnotationsComponent,
        AssignUserProfileComponent,
        ChatItemComponent,
        ServerItemComponent,
        TopicItemComponent,
        ConversationMemberComponent,
        DeviceAccountsPopoverComponent,
        DownloadLogComponent,
        LabelsComponent,
        MsgAttachmentsComponent,
        MsgComponent,
        MsgMenuPopoverComponent,
        MsgSearchResultComponent,
        HeaderComponent,
        PopoverFilterMenuComponent,
        ReviewersPopoverComponent,
        SearchbarComponent,
        WidgetDataCleaningComponent,
        WidgetDeviceComponent,
        WidgetLicenseComponent,
        WidgetProfileComponent,
        WidgetUserSessionsComponent,
        WidgetSystemwideSearchThemesComponent,
        WidgetStatisticsComponent,
        JumpToDatePopoverComponent,
        ReactionComponent,
        ReportFormComponent,
        LottieContainerComponent,
        InputFieldComponent,
        PasswordFieldComponent,
        SelectionFieldComponent,
        TextareaFieldComponent,
        CheckboxFieldComponent,
        ChatItemMenuComponent,
        ReportDownloadMenuComponent,
        ApkDownloadMenuComponent,
        GenericDialog,
        CalendarHeader,
        ReportProgressBarComponent,
        ReportBottomNavigationComponent,
        CatSelectPopoverComponent,
        AccountItemComponent,
        EncryptionDialog,
        HistoryTimelineComponent,
        ConversationMediaComponent,
        GalleryItemComponent,
        GalleryFilterComponent,
        ModalHeaderComponent
    ]
})
export class ComponentsModule {}
