<div class="cat-flex-container cat-flex-column cat-searchresult">

    <div class="cat-padding-md cat-nooverflow-ellipsis">
        <div class="cat-row cat-margin-bottom-md">
            <div class="cat-col cat-flex-grow">
                <b>{{ (msg.received | date: 'd MMMM y, HH:mm:ss' : undefined : tl.locale) }}</b>
            </div>
        </div>
        @if (msg.topic) {
            <div class="cat-row">
                <div class="cat-col cat-flex-grow">
                    <b>{{ msg.topic.name }}</b>
                    @if (msg.topic.state == topicState.TOPICSTATE_LOCKED) {
                        <span>🔒</span>
                    }
                </div>
            </div>
        }
        <div class="cat-row cat-nowrap">
            <div class="cat-col cat-flex-grow cat-nooverflow-ellipsis" color="dark-tint">
                @if (msg.sender) {
                    @if (msg.body) {
                        <span class="cat-nooverflow-ellipsis" [innerHTML]="(msg.sender | accountHandle) + ': ' + (msg.body | highlight : term)"></span>
                    } @else if (msg.content?.length) {
                        <span class="cat-nooverflow-ellipsis" [innerHTML]="(msg.sender | accountHandle) + ': ' + (msg.content[0].value | highlight : term)"></span>
                    } @else {
                        -
                    }
                } @else {
                    @if (msg.body) {
                        <span class="cat-nooverflow-ellipsis" [innerHTML]="(msg.body | highlight : term)"></span>
                    } @else if (msg.content?.length) {
                        <span class="cat-nooverflow-ellipsis" [innerHTML]="(msg.content[0].value | highlight : term)"></span>
                    } @else {
                        -
                    }
                }
            </div>
        </div>
    </div>
</div>