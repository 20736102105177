// Copyright 2018-2024, Volto Labs BV
// All rights reserved

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { userGetDataCleaningStatus, userStartDataCleaning } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";
import { WsService } from "@services/ws/ws.service";
import { Observable, of } from "rxjs";
import { SubscriptionManager } from "@shared/subscription-manager";

/** Decorator that marks a class as an Angular component */
@Component({
    selector: "widget-data-cleaning",
    templateUrl: "./widget-data-cleaning.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetDataCleaningComponent extends SubscriptionManager implements OnInit {

    public status$: Observable<string>;
    public permissionEnum = cat.Permission;
    public permissionDef = protosui.def.Permission;
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _logger: LoggerService,
        private _cdr: ChangeDetectorRef,
        private _ws: WsService
    ) {
        super();
    }

    /**
     * Initialize, get data cleaning status.
     *
     * @memberof WidgetDataCleaningComponent
     */
    ngOnInit() {
        this.addSubscription(
            // Listen for data cleaning notifications.
            this._ws.notifications
                .get(cat.NotificationType.NOTIFICATION_DATA_CLEANING)
                .subscribe((notification: cat.NotificationMsg) => {
                    this.status$ = of(notification.value);
                    this.common.detectChange(this._cdr);
                })
        );

        // Fetch data cleaning status.
        this.getStatus();
    }

    /**
     * Get the data cleaning process status.
     *
     * @memberof WidgetDataCleaningComponent
     */
    async getStatus() {
        try {
            await this._ws.sendRequest(userGetDataCleaningStatus);
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
     * Request to start the data cleaning process.
     *
     * @memberof WidgetDataCleaningComponent
     */
    public async start() {
        try {
            await this._ws.sendRequest(userStartDataCleaning);
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }
}
