<div class="cat-padding-lg">

    <h4 class="cat-no-margin-top cat-margin-bottom-xl">
        {{ uitext.download | translate }}
    </h4>

    <div class="cat-list">

        <div class="cat-block cat-margin-bottom-md">

            <button mat-stroked-button extended color="primary" (click)="picker.open()" class="cat-full-width">
                <mat-icon>calendar_month</mat-icon>
                <ng-container *ngIf="logSelectedValue else noValue">
                    {{ logSelectedValue | date: 'd MMMM y' : undefined : tl.locale }}
                </ng-container>
                <ng-template #noValue>
                    {{ uitext.pickdate | translate }}
                </ng-template>
            </button>

            <div class="cat-hide">
                <mat-form-field>
                    <input
                        matInput #dateInput="ngModel"
                        [matDatepickerFilter]="dayFilter"
                        [(ngModel)]="logSelectedValue"
                        class="cat-dev-null"
                        [max]="maxDate"
                        [min]="minDate"
                        [matDatepicker]="picker">
                    <mat-datepicker #picker [calendarHeaderComponent]="customHeader"></mat-datepicker>
                </mat-form-field>
            </div>

            <mat-label *ngIf="!(dateInput.valid)" class="cat-block cat-margin-bottom-xl" color="warn">
                {{ "Sorry, there is no logfile available for this day." | translate }}
            </mat-label>

            <button mat-flat-button extended (click)="downloadLogs(device)" color="primary" [disabled]="(availableDatesLoading$|async) || (logLoading$|async) || !(dateInput.valid)">
                <mat-icon *ngIf="!(availableDatesLoading$|async) && !(logLoading$|async)">cloud_download</mat-icon>
                <mat-icon *ngIf="(availableDatesLoading$|async) || (logLoading$|async)">
                    <mat-spinner color="white" [diameter]="20" class="cat-float-start cat-block"></mat-spinner>
                </mat-icon>
                <mat-label *ngIf="(availableDatesLoading$|async) || (logLoading$|async)" class="cat-margin-left-sm"> {{ uitext.downloading | translate }}</mat-label>
                <mat-label *ngIf="!(availableDatesLoading$|async) && !(logLoading$|async)"> {{ uitext.download | translate }}</mat-label>
            </button>

        </div>
    </div>
</div>
