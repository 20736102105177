// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IncludePipe } from "./include/include.pipe";
import { GroupbyPipe } from "./groupby/groupby.pipe";
import { SafehtmlPipe } from "./safehtml/safehtml.pipe";
import { ExcludePipe } from "./exclude/exclude.pipe";
import { FilesizePipe } from "./filesize/filesize.pipe";
import { FilterPipe } from "./filter/filter.pipe";
import { HighlightPipe } from "./highlight/highlight.pipe";
import { HumanizeTimePipe } from "./humanizetime/humanizetime.pipe";
import { KeysPipe } from "./keys/keys.pipe";
import { CapitalizeFirstCharPipe } from "./capitalizefirstchar/capitalize-first-char.pipe";
import { HasIdPipe } from "./hasid/hasid.pipe";
import { HumanizeNotificationsPipe } from "./humanizenotifications/humanizenotifications.pipe";
import { ConversationStatusPipe } from "./conversationstatus/conversationstatus.pipe";
import { IsIncomingPipe } from "./isincoming/isincoming.pipe";
import { GetMessageAttachmentTypePipe } from "./getmsgattachmenttype/get-msg-attachment-type.pipe";
import { IsMsgSelectedPipe } from "./isMsgSelected/is-msg-selected.pipe";
import { IsNewDayPipe } from "./isNewDay/is-new-day.pipe";
import { GetForwardConversationName } from "./getforwardconversationname/get-forward-conversation-name.pipe";
import { ISOTimePipe } from "./isotime/isotime.pipe";
import { AccountHandlePipe } from "./accounthandle/account-handle.pipe";
import { AccountNamePipe } from "./accountname/account-name.pipe";
import { SelectOnTopPipe } from "./selectOnTop/selectontop.pipe";
import { HasCallFiltersPipe } from "./hasCallFilters/has-call-filters.pipe";
import { IsOneOnOneChat } from "./isOneOnOneChat/is-one-on-one-chat.pipe";
import { GroupMessagesPipe } from "./groupMessages/group-messages.pipe";
import { IsProfileOwnerPipe } from "./isProfileOwner/isProfileOwner.pipe";
import { HasPermissionPipe } from "./haspermission/haspermission.pipe";
import { SortByPipe } from "./sortby/sortby.pipe";
import { ReplaceTermPipe } from "./replaceterm/replaceterm.pipe";
import { GetMessageServiceBodyPipe } from "./getmsgservicebody/get-msg-service-body.pipe";
import { GetRoleNamePipe } from "./getrolename/getrolename.pipe";
import { IsObjectEmptyPipe } from "./isObjectEmpty/is-object-empty.pipe";
import { IsBufferingPipe } from "./isbuffering/isbuffering.pipe";
import { IncludesMimetypePipe } from "./includesMimetype/includes-mimetype.pipe";
import { GetApptypeByPackagename } from "./getapptypebypackagename/getApptypeByPackagename";
import { DeviceArchitecturesPipe } from "./devicearchitectures/device-architectures.pipe";
import { UniquePipe } from "./unique/unique.pipe";
import { AddPipe } from "./addPipe/addpipe.pipe";
import { GetEnumLabelPipe } from "./getenumlabel/get-enum-label.pipe";
import { HumanizeEnumPipe } from "./humanizeEnum/humanizeEnum.pipe";
import { GetSome } from "./getSome/getsome.pipe";
import { HasThumbPipe } from "./hasThumb/has-thumb.pipe";
import { HasFilePipe } from "./hasFile/has-file.pipe";
import { IconMediaPipe } from "./iconMedia/iconmedia.pipe";
import { DocMediaPipe } from "./docMedia/docmedia.pipe";
import { CatSrcPipe } from "./catsrc/catsrc";
import { ToDatePipe } from "./toDate/to-date.pipe";
import { EmptyValuePipe } from "./emptyValue/empty-value.pipe";
import { GetReportProgressPipe } from "./getReportProgress/getProgress.pipe";
import { GetValidatorMsgPipe } from "./getValidatorMsg/get-validator-msg.pipe";
import { IsEqualPipe } from "./isEqual/isEqual.pipe";
import { HumanizeMimetypePipe } from "./humanizeMimetype/humanizeMimetype.pipe";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        KeysPipe,
        IncludePipe,
        GroupbyPipe,
        SafehtmlPipe,
        ExcludePipe,
        FilesizePipe,
        FilterPipe,
        HighlightPipe,
        HumanizeTimePipe,
        HumanizeNotificationsPipe,
        CapitalizeFirstCharPipe,
        HasIdPipe,
        ConversationStatusPipe,
        IsIncomingPipe,
        GetMessageAttachmentTypePipe,
        GetForwardConversationName,
        IsMsgSelectedPipe,
        IsNewDayPipe,
        ISOTimePipe,
        AccountHandlePipe,
        AccountNamePipe,
        DeviceArchitecturesPipe,
        SelectOnTopPipe,
        HasCallFiltersPipe,
        IsOneOnOneChat,
        GroupMessagesPipe,
        IsProfileOwnerPipe,
        HasPermissionPipe,
        SortByPipe,
        ReplaceTermPipe,
        GetMessageServiceBodyPipe,
        GetRoleNamePipe,
        IsObjectEmptyPipe,
        IsBufferingPipe,
        IncludesMimetypePipe,
        GetApptypeByPackagename,
        UniquePipe,
        AddPipe,
        GetEnumLabelPipe,
        HumanizeEnumPipe,
        HumanizeMimetypePipe,
        GetSome,
        HasThumbPipe,
        HasFilePipe,
        IconMediaPipe,
        DocMediaPipe,
        CatSrcPipe,
        ToDatePipe,
        EmptyValuePipe,
        GetReportProgressPipe,
        GetValidatorMsgPipe,
        IsEqualPipe
    ],
    exports: [
        KeysPipe,
        IncludePipe,
        GroupbyPipe,
        SafehtmlPipe,
        ExcludePipe,
        FilesizePipe,
        FilterPipe,
        HighlightPipe,
        HumanizeTimePipe,
        HumanizeNotificationsPipe,
        CapitalizeFirstCharPipe,
        HasIdPipe,
        ConversationStatusPipe,
        IsIncomingPipe,
        GetMessageAttachmentTypePipe,
        GetForwardConversationName,
        IsMsgSelectedPipe,
        IsNewDayPipe,
        ISOTimePipe,
        AccountHandlePipe,
        AccountNamePipe,
        DeviceArchitecturesPipe,
        SelectOnTopPipe,
        HasCallFiltersPipe,
        IsOneOnOneChat,
        GroupMessagesPipe,
        IsProfileOwnerPipe,
        HasPermissionPipe,
        SortByPipe,
        ReplaceTermPipe,
        GetMessageServiceBodyPipe,
        GetRoleNamePipe,
        IsObjectEmptyPipe,
        IsBufferingPipe,
        IncludesMimetypePipe,
        GetApptypeByPackagename,
        UniquePipe,
        AddPipe,
        GetEnumLabelPipe,
        HumanizeEnumPipe,
        HumanizeMimetypePipe,
        GetSome,
        HasThumbPipe,
        HasFilePipe,
        IconMediaPipe,
        DocMediaPipe,
        CatSrcPipe,
        ToDatePipe,
        EmptyValuePipe,
        GetReportProgressPipe,
        GetValidatorMsgPipe,
        IsEqualPipe
    ]
})
export class PipesModule { }
