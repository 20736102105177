<div
    class="cat-chat-item cat-padding-x-lg cat-background-light-tint cat-border-bottom-sm"
    [class.cat-cursor-pointer]="!disabled"
    [class.cat-selected]="selected"
    [class.cat-disabled]="disabled"
    [id]="selected ? 'selectedAccount' : ''">

    <div class="cat-avatar cat-md">
        <img
            *ngIf="(account.avatar | hasThumb) else noAvatar"
            [src]="account.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
        <ng-template #noAvatar>
            <img matTooltip="{{ uitext.noavatarfound | translate }}" matTooltipPosition="left" src="assets/img/cat-avatar.svg">
        </ng-template>
    </div>
    <div *ngIf="account?.apptype?.id && (apptypes$|async) as apptypes" class="cat-col cat-neg-margin-left-md">
        <div class="cat-avatar cat-sm cat-no-border-radius cat-no-background" color="light" slot="start" float-start>
            <img
                *ngIf="apptypes.has(account.apptype.id)"
                [src]="apptypes.get(account.apptype.id).iconfileid | iconmedia">
        </div>
    </div>

    <div class="cat-flex-fill-remaining cat-margin-x-lg cat-nowrap cat-nooverflow-ellipsis">

        <span class="cat-no-margin cat-nooverflow-ellipsis" color="grey-dark">
            <b>{{ account | accountHandle }}</b>
        </span>

        <div *ngIf="count" class="cat-no-margin cat-margin-top-sm cat-nooverflow-ellipsis cat-opacity-4" color="dark-tint">
            <div class="cat-avatar cat-xs cat-float-start cat-margin-right-sm" color="primary">
                <span class="cat-text-xs cat-flex-center" color="light">
                    <b>{{ count }}</b>
                </span>
            </div>
            {{ uitext.selected | translate }}
        </div>

        <p *ngIf="!count" class="cat-no-margin cat-margin-top-sm cat-nooverflow-ellipsis cat-opacity-4" color="dark-tint">
            {{ account.externid }}
        </p>


    </div>
</div>