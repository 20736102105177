import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";

@Component({
    selector: "chat-item",
    templateUrl: "./chat-item.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatItemComponent {

    // I/O
    @Input() chat: cat.ConversationMsg = cat.ConversationMsg.create();
    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    @Input() unreadCount = 0;
    @Input() selected = false;
    @Input() disabled = false;

    /** Set visibility for specific conversation */
    @Output() emitConversationVisibility: EventEmitter<boolean> = new EventEmitter();

    // Properties
    public mediaType = cat.MediaType;
    public messageType = cat.MessageType;
    public directionType = cat.DirectionType;
    public uitext = protosui.messages.uitext;
    public conversationType = cat.ConversationType;
    public conversationState = cat.ConversationState;
    public conversationVisibility = cat.VisibilityType;
    public conversationTypeEnum = protosui.def.ConversationType;
    public profileId = "";

    constructor(
        public common: CommonService,
        private _activeRoute: ActivatedRoute
    ) {
        this.profileId = this._activeRoute.snapshot.paramMap.get("profileid");
    }
}
