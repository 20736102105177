import { ChangeDetectionStrategy, Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { FormControl, Validators } from "@angular/forms";
import { AngularValidators, IFormFieldData } from "@shared/app-models";

@Component({
  selector: "wizard-textarea-dialog",
  templateUrl: "./wizard-textarea.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WizardTextareaDialog {

    @Input() formControl: FormControl;

    public iterator: { id: number, name: string }[] = [];
    public uitext = protosui.messages.uitext;

    constructor(
        public dialogRef: MatDialogRef<WizardTextareaDialog>,
        public common: CommonService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    public getData() {
        const data: IFormFieldData = {
            label: this.data?.field?.label,
            description: "",
            validatorList: [
                { validator: Validators.required, type: AngularValidators.Required },
                { validator: Validators.maxLength(500), type: AngularValidators.MaxLength, value: 500 }
            ]
        }
        return data;
    }

    /**
     * Close the dialog.
     *
     * @param {("cancel" | "delete")} event
     * @memberof FormDialog
     */
    closeDialog(event: "cancel" | "delete") {
        this.dialogRef.close(event);
    }
}