import { Store } from "@ngxs/store";
import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { IState } from "@shared/app-models";

/**
 * Check if the current user is the profile owner
 */
@Pipe({
    name: "isProfileOwner",
    pure: true
})
export class IsProfileOwnerPipe implements PipeTransform {

    constructor(
        private _store: Store
    ) {}

      transform(): boolean {
        let result = false;

        const permissions = this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserPermissions).list;

        if (permissions?.has(cat.Permission[cat.Permission.PERMISSION_MANAGE_ASSIGNED_PROFILES])) {
            result = true;
        }

        return result;
    }
}

