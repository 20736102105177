// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { inject, Injectable } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { AuthService } from "@services/auth/auth.service";
import { CommonService } from "@services/common/common.service";
import { Store } from "@ngxs/store";
import { protosui } from "@definitions/definitions";
import { LoggerService } from "@services/logger/logger.service";
import { Observable, Subject } from "rxjs";
import { AppRoutingModule } from "@app/app-routing.module";
import { appRouteNames } from "@app/app.routes.names";
import { Router } from "@angular/router";
import { QueryService } from "@services/query/query.service";
import { EnvName, IDeferredPromise, IDialogData, IIdRef, IKeycloak, IKeycloakSettings, IState } from "@shared/app-models";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { MatSnackBar } from "@angular/material/snack-bar";
import { cloneDeep } from "lodash-es";
import * as softwareVersion from "../../version";
import * as StoreActions from "@store/actions";
import * as services from "@assets/proto/services";
import { environment } from "@app/env";
import config from "@config/default.json";

/**
 * Make a deferred promise for send messages.
 * @returns Deferred promise.
 */
const makeDeferred = (): IDeferredPromise => {
    const deferred: IDeferredPromise = {};
    deferred.promise = new Promise<unknown>((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    return deferred;
}

declare let Keycloak: any;
@Injectable({
    providedIn: "root"
})

export class WsService {

    connection$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.WsConnection);

    public copyright = "Copyright 2018-2024, Volto Labs BV";
    public title: string = softwareVersion.title;
    public version: string = softwareVersion.version;
    public notification: Subject<cat.NotificationMsg> = new Subject<cat.NotificationMsg>();
    public notifications: Map<number, Subject<cat.NotificationMsg>> = new Map<number, Subject<cat.NotificationMsg>>();
    public notificationBundle: Subject<cat.NotificationBundleMsg> = new Subject<cat.NotificationBundleMsg>();

    private _ws: WebSocket;
    private _ids: Map<string, IIdRef> = new Map();
    private _tempStreamList: Map<string, Map<string, Map<string, any>>> = new Map<string, Map<string, Map<string, any>>>();
    private _decay = 0;
    private _wsTimeout = 5;
    private _keycloak: IKeycloak;
    private _lastRequest: { name: string, ts: number };

    constructor(
        private _auth: AuthService,
        private _router: Router,
        private _common: CommonService,
        private _snackBar: MatSnackBar,
        private _logger: LoggerService,
        private _queryService: QueryService,
        private _store: Store,
        private _appRouter: AppRoutingModule) {

        this.connect();
        // this.processQueue();

        // Create a map of notification Subjects for all notification types that other
        // components may subscribe to, to receive incoming notification messages.
        Object.values(cat.NotificationType).map((enumvalue: number) => this.notifications.set(enumvalue, new Subject<cat.NotificationMsg>()));

        this._auth.signOut.subscribe(async ({ debugMessage: debugMessage, showMessage: showMessage, noCall: noCall, message: message }) => {
            try {

                if (this._auth.loggedIn && !noCall) {
                    this._auth.modifyUserSessionKeys(cat.UserSessionMsg.create({
                        debuglog: debugMessage
                    }));
                    await this.sendRequest(services.userSignOut);
                }

                // Remove all local storage
                this._auth.removeLocalStorage();

                this._auth.isAuth.next(false);
                this._auth.loggedIn = false;

                // Clear the entire store, so init connection to true
                this._store.dispatch(new StoreActions.userLogout());

                // Clear Keycloak authentication state, including tokens.
                try {
                    await this._auth.gotoDisconnected();
                    await this._keycloak?.logout({
                        redirectUri: `${window.location.origin}/login`
                    });
                } catch (error) {
                    this._logger.error("[signOut] Keycloak error: ", error);
                }

                // Go to the login page
                await this._auth.gotoLogin();

                // Inform the user
                if (showMessage) {
                    const msg: string = (message && message.length)
                        ? message
                        : protosui.messages.uitext.loggedout;
                    this._common.createSnackbar(msg);
                }

            } catch (error) {
                this._logger.error(error);
            }
        });

        // Keep alive fetch (only production).
        setInterval(this.keepAlive, 120000);
    }

    /**
     * Init from app component, upon startup
     *
     * @memberof WsService
     */
    init() {}

    /**
     * A keepalive signal to prevent refreshing.
     *
     * @private
     * @memberof WsService
     */
    private keepAlive() {
        try {
            // Determine the URL
            let baseURL = `${window.location.protocol}//${window.location.hostname}`;
            if (window.location.port) {
                baseURL += `:${window.location.port}`;
            }
            if (baseURL) {
                // Fetch the favicon.
                fetch(`${baseURL}/favicon.ico`).catch(error => this._logger.warn(error));
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

   /**
    * Connects the websocket to the websocket service
    */
    public async connect() {
        try {

            this._store.dispatch(new StoreActions.incrementConnectionCount());

            let url: string;

            const cnxType: string = (window.location.protocol !== "http:") ? "wss:" : "ws:";
            const hostname: string = window.location.hostname;
            const port: string = window.location.port;

            // Construct URL.
            if (environment.envName === EnvName.SERVE || environment.envName === EnvName.HMR) {
                url = `${cnxType}//${hostname}:8080/rpc`;
            } else {
                if (port?.length) {
                    // Include non-standard port
                    url = `${cnxType}//${hostname}:${port}/rpc`;
                } else {
                    // Standard port is used (port is empty string)
                    url = `${cnxType}//${hostname}/rpc`;
                }
            }

            if (!url) {
                throw new Error("Connection data not found");
            }

            this._logger.debug("Connection URL: ", url);

            // Create a new websocket connection
            this._ws = new WebSocket(url);
            this._ws.binaryType = "arraybuffer";

            // Handle websocket events
            this._ws.onerror = (event: Event) => this._logger.error("Websocket error: ", event);
            this._ws.onopen = () => this.openHandler();
            // this._ws.onmessage = (event: MessageEvent) => this.callQueue.push(event);
            this._ws.onmessage = async (event: MessageEvent) => await this.messageHandler(event);
            this._ws.onclose = () => this.closeHandler();

        } catch (error) {
            this._logger.error(error);
            this._common.createSnackbar(error);
        }
    }

    /**
    * Disconnect the websocket (on logout)s
    */
    disconnect() {
        this._ws.close();
    }

    /**
     * Initialize the (opened) websocket (assume version is always right)
     *
     * @private
     * @memberof WsService
     */
    private async initWebsocket() {
        try {
            this._logger.debug(`Websocket init...`);
            // If a usersession is present, make a sign in with session call
            if (this._auth.hasUserSession()) {
                // Remove all overlays
                await this._common.dismissAllOverlays();
                // Attempt to login with the stored user session
                const usersession: cat.UserSessionMsg = this._auth.getUserSession();
                // this._logger.debug(`Usersession: `, usersession);

                if (usersession.expires < Date.now()) {
                    this._logger.warn("User-session already expired, do not try to log in again...");
                    this._auth.removeLocalStorage();
                    await this._auth.gotoLogin();
                } else {
                    await this.sendRequest(services.userSignInWithSession, usersession);
                }

            // If a usersession is not present, logout or redirect to login
            } else {
                if (this._auth.loggedIn) {
                    this._common.createSnackbar(protosui.messages.uitext.expiredsessionheader, "top", 3000);
                    this._auth.logOut("Version subscription", true);
                } else {
                    this._auth.gotoLogin();
                }
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Log in with a keycloak token
     */
    private async signInWithKeycloak() {
        try {
            await this.sendRequest(services.userSignInWithKeycloak);
        } catch (error) {
            this._logger.error(error);
            this._common.createSnackbar(error);
        }
    }

    /**
     * Websocket open handler with the first tasks to execute when a websocket connection has been established.
     */
    private async openHandler() {
        try {
            // Clear the meta data on open, to prevent unauthorized calls
            this._auth.clearMetadata();
            this._store.dispatch(new StoreActions.setConnection(true));
            this._store.dispatch(new StoreActions.resetConnectionCount());
            this._wsTimeout = 5; // Reset
            this._decay = 0; // Reset

            this.requestSettings();
        } catch (error) {
            this._auth.logOut("Open handler websocket", true);
            throw new Error(error);
        }
    }

    /**
     * Websocket close handler with tasks to be performed when the websocket closes.
     */
    async closeHandler() {
        try {
            this._logger.debug("The websocket closed");

            this._auth.clearMetadata();
            await this._common.dismissAllOverlays();
            this._store.dispatch(new StoreActions.setConnection(false));
            this._auth.loggedIn = false;
            this._auth.isAuth.next(false);

            // Reconnect websocket with a decay
            setTimeout(async () => {
                await this.connect();
                this._decay++;
                if (this._decay > 100) { this._wsTimeout++; }
            }, this._wsTimeout); // Variable timeout

        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Sending a request with a promise attached to it, to resolve it when received
     * or reject it when it passed the timeout. Also encodes the payload to a buffer.
     * @param {services.IServiceDescriptor} call The name, request, response of the call
     * @param {any} [msg] The actual message to send
     * @param {string} [callId] A unique call ID, either provided or generated to resolve a promise
     * @param {boolean} [streamEnd] Whether or not this is the end of a stream
     * @param {cat.QueryMsg} [query] A query message with filters for a specific call
     * @returns {Promise<any>} Resulting response from the request.
     * @memberof WsService
     */
    async sendRequest(call: services.IServiceDescriptor, msg?: any, callId?: string, streamEnd?: boolean, query?: cat.QueryMsg): Promise<any> {
        try {

            // Prevent calling the same call twice within less than 10ms.
            // Exception for request streams.
            if (this._lastRequest && !call.requestStream && (this._lastRequest.name === call.methodName) && (Date.now() - this._lastRequest?.ts < 10)) {
                this._logger.warn("Request too soon after one other, do not call", call);
                this._lastRequest = undefined;
                return;
            }
            this._lastRequest = { name: call.methodName, ts: Date.now() };

            // Only allow outgoing request if the session is not locked, or on unlock / signout
            if (!this._store.selectSnapshot((state: IState) => state.cat.SessionLocked) ||
                (call === services.userUnlock) ||
                (call === services.userSignOut) ||
                (call === services.userSignInWithSession) ||
                (call === services.userSignInWithKeycloak)) {

                // Create a promise object for handling async calls
                const defer = makeDeferred();
                const callid: string = callId || await this._auth.generateId();
                const usersession: cat.UserSessionMsg = this._auth.getUserSession();

                // TODO: remove protobuf definition alltogether
                // Remove metadata
                usersession.profileid = "";
                usersession.caseid = "";

                // Add Keycloak token
                if (this._keycloak?.token) {
                    try {
                        usersession.keycloaktoken = this._keycloak.token;
                        // Refresh the Keycloak token
                        const updateToken = await this._keycloak.updateToken();
                        this._logger.debug("[sendRequest] Updated token", updateToken);
                    } catch (error) {
                        this._logger.error("Keycloak error: ", error);
                        this._auth.logOut("Keycloak token invalid", false, true);
                        throw error;
                    }
                }

                // Automatically add request message if not provided
                if ((!msg || !Object.keys(msg).length) && cat[call.requestType]) {
                    msg = cat[call.requestType].create();
                } else if (!cat[call.requestType]) {
                    throw new Error(`${protosui.messages.uitext.unknowncall} ${call.methodName}`);
                }

                // Show error if request message does not match requestType
                if (!(msg instanceof cat[call.requestType])) {
                    this._logger.error("Request message not equal to request type", call);
                }

                // Construct the websocket message
                const payload: cat.WebsocketMsg = cat.WebsocketMsg.create({
                    callname: call.methodName,
                    callid: callid,
                    requesttype: call.requestType,
                    responsetype: call.responseType,
                    usersession: usersession,
                    endstream: streamEnd,
                    payload: msg ? cat[call.requestType].encode(msg).finish() : "",
                    query: (!query) ? undefined : query
                });

                this._logger.debug("%c Payload", "font-weight: normal", { call: payload.callname, payload: payload });

                // Set the loading indicator to true
                this._store.dispatch(new StoreActions.setLoading(true, call.methodName));

                // Clear list if request is QueryMsg.
                if (payload.requesttype === messageDefinitions.QueryMsg) {
                    this._store.dispatch(new StoreActions.clearList(call.methodName));
                }

                // Encode the payload, send the buffer and store the call id
                const buffer: Uint8Array = cat.WebsocketMsg.encode(payload).finish();
                this._ws.send(buffer);

                // Construct the ID Reference object, so we can detect when the call returns
                // along with other essential information.
                const idref: IIdRef = {
                    cb: defer,
                    call: call,
                    request: msg,
                    responseStream: call.responseStream,
                    query: payload.query
                };

                // Remove any outstanding call with the same method name
                if ([services.userGetAppReceiverMedia.methodName].includes(call.methodName)) {
                    for (const [id, ref] of this._ids.entries()) {
                        if (ref.call.methodName === call.methodName) {
                            this._logger.debug("[Websocket service: clear infinite array");
                            this._ids.delete(id);
                            this._store.dispatch(new StoreActions.clearInfiniteArray(ref.call.methodName));
                            break;
                        }
                    }
                }

                // Add call id to internal state.
                this._ids.set(callid, idref);

                this._logger.debug("%c Request", "font-weight: normal", { call: payload.callname, msg: msg });

                if (!call.requestStream && !call.responseStream) {
                    setTimeout(() => {
                        // Rejects, if not already resolved...
                        defer.reject(protosui.messages.uitext.calltimeout);
                    }, config.settings.callTimeout);
                } else if (call.requestStream && !streamEnd) {
                    // For example: uploading files
                    // We want to resolve all the file parts, when the file is fully send we want to wait for the call to finish
                    defer.resolve();
                }

                return defer.promise;
            } else {
                this._logger.warn(`The session is locked, call ${call.methodName} is blocked...`);
                this._common.createSnackbar(protosui.messages.uitext.sessionlocked);
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Request the system settings, handled in messageHandler.
     *
     * @private
     * @memberof WsService
     */
    private requestSettings() {
        try {
            // Send initialize request.
            const payload: cat.WebsocketMsg = cat.WebsocketMsg.create({ callname: "INIT" });
            const buffer: Uint8Array = cat.WebsocketMsg.encode(payload).finish();
            this._ws.send(buffer);
        } catch (error) {
            this._logger.error(error);
        }
    }


    private arrayBufferToWsMsg = async (event: MessageEvent) => {
        return cat.WebsocketMsg.decode(new Uint8Array(event.data));
    }

    // async processQueue() {
    //     while (this.callQueue) {

    //         if (!this.callQueue.length) {
    //             await this._common.timeout(100);
    //         } else {
    //             // Process next item in the queue.
    //             const event = this.callQueue.shift();
    //             this._logger.warn("Item", event);
    //             this.messageHandler(event);
    //         }
    //     }
    // }

    /**
     * Inject keycloak script
     *
     * @private
     * @param {*} location
     * @returns
     * @memberof WsService
     */
    private initKeycloak (location: string) {
        return new Promise((resolve, reject) => {
            try {
                const keycloakScript = document.createElement("script");
                keycloakScript.async = false;
                keycloakScript.src = location;
                document.head.appendChild(keycloakScript);
                keycloakScript.onload = () => resolve(true);
                keycloakScript.onerror = (reason) => reject(reason);
            } catch (error) {
                reject(error);
            }
        });
    }

    /**
     * Initialize Keycloak.
     *
     * @param {IKeycloakSettings} keycloakSettings
     * @memberof WsService
     */
    async runKeycloak(keycloakSettings: IKeycloakSettings) {
        try {

            if (!keycloakSettings) {
                throw new Error(protosui.messages.uitext.nokcsettings);
            }

            try {
                await this.initKeycloak(keycloakSettings.adapter);
            } catch (err) {
                throw new Error(protosui.messages.uitext.nokcconnection);
            }

            this._keycloak = new Keycloak(keycloakSettings);
            let authenticated = false;

            try {
                authenticated = await this._keycloak.init({
                    enableLogging: true,
                    redirectUri: `${window.location.origin}/${appRouteNames.DASHBOARD}`
                });
            } catch (error) {
                throw new Error(protosui.messages.uitext.nokcauthentication);
            }

            try {
                if (!authenticated) {
                    await this._keycloak.login();
                }
            } catch (error) {
                throw new Error(protosui.messages.uitext.nokclogin);
            }

        } catch (error) {
            this._logger.error(error);
            await this._common.createSnackbar(error.message, "bottom", 20000);
            throw new Error(error);
        }
    }

    /**
     * Websocket handler for incoming message events.
     * a) Check for length of the message event data, if <=22 assume websocket version is received.
     * b) Attempt to decode the message event into a websocket protobuf message and interpret the call status.
     * c) Check for empty payloads and differentiate between call types.
     * @param {MessageEvent} event The onmessage event.
     */
    async messageHandler(event: MessageEvent) {
        try {
            // Decode the websocket message event into a websocket protobuf message
            const data = await this.arrayBufferToWsMsg(event);

            if (data.callname === "INIT") {
                this._logger.debug(`Received websocket version: ${String(data.version)}`);

                // If the version differs from the stored version, force a reload.
                if (data.version !== this._auth.getVersion()) {
                    this._logger.warn("Version is different, reload window");
                    // First store the new version, to prevent a endless loop.
                    this._auth.storeVersion(data.version);
                    window.location.reload();
                }

                // TODO: Store setting in store and inject keycloak code to frontend.
                const setting = cat.SettingMsg.decode(data.payload);

                if (setting.id === cat.Settings.SETTING_KEYCLOAK && setting.enabled) {
                    const keycloakSettings: IKeycloakSettings = {
                        url: setting.value[0],
                        realm: setting.value[1],
                        clientId: setting.value[2],
                        adapter: `${setting.value[0]}/js/keycloak.js`
                    }

                    this._logger.warn("Use Keycloak with the following settings: ", keycloakSettings);
                    this._store.dispatch(new StoreActions.setKeycloakMode(true));
                    await this.runKeycloak(keycloakSettings);
                    await this.signInWithKeycloak();
                }

                await this.initWebsocket();
                return;
            }

            if (data.query?.count) {
                this._logger.debug(`Total count: ${data.query?.count} for call: ${data.callname}`);
            }

            if (this._ids.has(data.callid) && !data.endstream) {

                const idref: IIdRef = this._ids.get(data.callid);
                this._ids.set(data.callid, idref);

                // Delete original id from list unless it's a stream
                if (!idref.responseStream) {
                    this._ids.delete(data.callid)
                }

                if (data.callstatus) {
                    switch (data.callstatus) {
                        case cat.CallStatus.CALLSTATUS_CANCELLED:
                        case cat.CallStatus.CALLSTATUS_UNKNOWN:
                        case cat.CallStatus.CALLSTATUS_INVALID_ARGUMENT:
                        case cat.CallStatus.CALLSTATUS_DEADLINE_EXCEEDED:
                        case cat.CallStatus.CALLSTATUS_NOT_FOUND:
                        case cat.CallStatus.CALLSTATUS_ALREADY_EXISTS:
                        case cat.CallStatus.CALLSTATUS_PERMISSION_DENIED:
                        case cat.CallStatus.CALLSTATUS_RESOURCE_EXHAUSTED:
                        case cat.CallStatus.CALLSTATUS_FAILED_PRECONDITION:
                        case cat.CallStatus.CALLSTATUS_ABORTED:
                        case cat.CallStatus.CALLSTATUS_OUT_OF_RANGE:
                        case cat.CallStatus.CALLSTATUS_UNIMPLEMENTED:
                        case cat.CallStatus.CALLSTATUS_INTERNAL:
                        case cat.CallStatus.CALLSTATUS_UNAVAILABLE:
                        case cat.CallStatus.CALLSTATUS_DATA_LOSS: {
                            if (data.errortype) {
                                idref.cb.reject(protosui.def.ErrorType[cat.ErrorType[data.errortype]]?.label);
                            } else {
                                idref.cb.reject(protosui.def.CallStatus[cat.CallStatus[data.callstatus]]?.label);
                            }

                            // On a permission denied, go to the dashboard:
                            if (data.callstatus === cat.CallStatus.CALLSTATUS_PERMISSION_DENIED) {
                                if (this._auth.isLoggedIn) {
                                    await this._router.navigate([appRouteNames.DASHBOARD], { replaceUrl: true });
                                } else {
                                    await this._router.navigate([appRouteNames.DISCONNECTED], { replaceUrl: true });
                                }
                            }
                            break;
                        }
                        case cat.CallStatus.CALLSTATUS_UNAUTHENTICATED: {

                            const essentialCalls: string[] = [
                                services.userSignIn.methodName,
                                services.userSignInWithSession.methodName,
                                services.userSignInWithKeycloak.methodName,
                                services.userGetEula.methodName,
                                services.userChangeSessionRole.methodName,
                                services.userGetCurrentUserPermissions.methodName
                            ];

                            // Logout if the user is not logged in, or does not have a session
                            if (!this._auth.hasUserSession() || !this._auth.isLoggedIn() || essentialCalls.includes(idref?.call?.methodName)) {
                                this._logger.error(`Unauthenticated call detected ${idref?.call?.methodName}: Log out the user...`);
                                // Show alert for Keycloak mode, before redirecting.
                                if (this._store.selectSnapshot((state: IState) => state.cat.KeycloakMode)) {
                                    // Create dialog with Keycloak error
                                    const keycloakerror = this._common.createDialogReference({
                                        title: protosui.messages.uitext.keycloakerror,
                                        content: protosui.def.ErrorType[cat.ErrorType[data.errortype]]?.label,
                                        buttons: [
                                            {
                                                title: protosui.messages.uitext.ok,
                                                color: "primary"
                                            }
                                        ]
                                    });
                                    await keycloakerror.afterClosed().toPromise();
                                }
                                this._auth.logOut("Unauthenticated call", false);
                            } else {
                                // Navigate back to the dashboard
                                await this._router.navigate([`${appRouteNames.DASHBOARD}`]);

                                // Dismiss old overlays
                                await this._common.dismissAllOverlays();

                                // Show dialog.
                                const data: IDialogData = {
                                    title: protosui.messages.uitext.unautheticatedcallheader,
                                    content: protosui.messages.uitext.unautheticatedcallbody
                                }
                                this._common.createDialog(data);
                            }

                            // Check for invalid session after unauthenticated call
                            if (data.errortype) {
                                idref.cb.reject(protosui.def.ErrorType[cat.ErrorType[data.errortype]].label);

                                if (data.errortype === cat.ErrorType.ERROR_INVALID_SESSION) {
                                    this._auth.logOut("Invalid session", false);

                                    // Dismiss potential unauthenticated alert
                                    await this._common.dismissAllOverlays();

                                    // Show dialog.
                                    const data: IDialogData = {
                                        title: protosui.messages.uitext.invalidsessionheader,
                                        content: protosui.messages.uitext.invalidsessionbody
                                    }
                                    this._common.createDialog(data);
                                }
                            } else {
                                idref.cb.reject(protosui.def.CallStatus[cat.CallStatus[data.callstatus]].label);
                            }

                            break;
                        }
                        default: {
                            idref.cb.reject(protosui.messages.uitext.unknowncallstatus);
                            break;
                        }
                    }

                    this._store.dispatch(new StoreActions.setLoading(false, data.callname));

                } else if (!data.callresult) {

                    this._store.dispatch(new StoreActions.setLoading(false, data.callname));
                    idref.cb.reject(protosui.messages.uitext.nocallresult);
                    throw new Error(protosui.messages.uitext.nocallresult);

                } else if (data.payload) {

                    // Decode the payload and fill it with defaults
                    let payload: any = cat[data.responsetype].decode(data.payload);
                    payload = cat[data.responsetype].toObject(payload, {
                        defaults: true, // includes default values
                        arrays: true, // populates empty arrays (repeated fields) even if defaults=false
                        objects: true // populates empty objects (map fields) even if defaults=false
                    });

                    // Handle call specific actions
                    switch (data.callname) {
                        case services.userSignInWithSession.methodName:
                        case services.userSignInWithKeycloak.methodName:
                        case services.userChangeSessionRole.methodName:
                        case services.userSignIn.methodName: {

                            if (data.callname === services.userChangeSessionRole.methodName) {
                                // Before logout, get the Keycloak mode
                                const keycloakMode = this._store.selectSnapshot((state: IState) => state.cat.KeycloakMode);
                                this._logger.debug("Keycloak Mode: " + keycloakMode);
                                // Clear the entire store, so init connection to true
                                this._store.dispatch(new StoreActions.userLogout());
                                // Put the Keycloak mode back immediately for restoreRoute.
                                this._store.dispatch(new StoreActions.setKeycloakMode(keycloakMode));
                            }

                            // Special sign in procedures.
                            document.cookie = `usersession=${data.usersession.token}; Path=/;SameSite=Strict`;
                            localStorage.setItem("usersession", JSON.stringify(cat.UserSessionMsg.toObject(data.usersession as cat.UserSessionMsg)));
                            localStorage.setItem("user", JSON.stringify(cat.UserMsg.toObject(payload)));

                            await this._auth.updateUser(payload);

                            // Get the EULA details, before navigating away
                            await this.getEula();

                            if (!data.usersession.roleid) {
                                this._logger.warn(`No role found, select one first.`);
                                await this._router.navigate([appRouteNames.PREREQUISITES], { replaceUrl: true });
                                break;
                            }

                            // Make sure to set the default filters again
                            this._queryService.initialize();
                            this._auth.loggedIn = true;

                            // Fetch init calls
                            await this.initCalls(payload.licenseaccepted, payload.passwordreset)
                            // Set the dashboard angle
                            this._store.dispatch(new StoreActions.setDashboardAngle("profile"));
                            // Dismiss snackbars (expired session)
                            this._snackBar.dismiss();
                            // Restore the route, either dashboard or stored route.
                            await this._auth.restoreRoute();
                            break;
                        }
                        case services.userAcceptEula.methodName: {
                            const newuser: cat.UserMsg = cloneDeep(this._store.selectSnapshot((state: IState) => state.cat.Me.msg));
                            newuser.licenseaccepted = payload.licenseaccepted;
                            await this._auth.updateUser(newuser);

                            if (!data.usersession.roleid) {
                                await this._router.navigate([appRouteNames.PREREQUISITES], { replaceUrl: true });
                                break;
                            }

                            // Fetch init calls
                            await this.initCalls(payload.licenseaccepted, payload.passwordreset);
                            await this._auth.restoreRoute();
                            break;
                        }
                        case services.userResetCurrentUserPassword.methodName: {
                            const newuser: cat.UserMsg = cloneDeep(this._store.selectSnapshot((state: IState) => state.cat.Me.msg));
                            newuser.passwordreset = false;
                            newuser.passwordexpireson = 0;
                            await this._auth.updateUser(newuser);

                            if (!data.usersession.roleid) {
                                await this._router.navigate([appRouteNames.PREREQUISITES], { replaceUrl: true });
                                break;
                            }

                            await this.initCalls(newuser.licenseaccepted, newuser.passwordreset);
                            await this._auth.restoreRoute();
                            break;
                        }
                        case services.userGetMessageReactions.methodName: {
                            this._logger.debug("%c Stored chunk", "font-weight: bold", { call: data.callname, pay: payload });
                            // Change the attachment in the infinite conversation list
                            this._store.dispatch(new StoreActions.updateMessageReaction(payload, idref.request.id));
                            // Temp store all reactions: on stream end, store the list of all reactions
                            this.fillStreamCache(data, payload);
                            break;
                        }
                        case services.userGetMessageAttachments.methodName: {
                            this._logger.debug("%c Stored chunk", "font-weight: bold", { call: data.callname, pay: payload });
                            // Temp store all attachments: on stream end, store the list of all attachments
                            this.fillStreamCache(data, payload);
                            break;
                        }
                        case services.userGetAccountConversationCount.methodName: {
                            this._store.dispatch(new StoreActions.addProtobufMsg(payload, services.userGetAccountConversationCount))
                            break;
                        }
                        case services.userGetGlobalFailedItemsStatistics.methodName: {
                            const MediaMsg: cat.MediaMsg = cat.MediaMsg.create(payload);
                            MediaMsg.fileid = "faileditems";
                            MediaMsg.type = cat.MediaType.MEDIA_LOG;
                            // this._store.dispatch(new StoreActions.addMedia(MediaMsg));
                            break;
                        }
                        case services.userGetAccountMedia.methodName: {
                            const mediafile: cat.MediaFileMsg = cat.MediaFileMsg.create(payload);
                            if (mediafile) {
                                this._store.dispatch(new StoreActions.appendToInfiteArray([mediafile], services.userGetAccountMedia.methodName));
                            }
                            break;
                        }
                        case services.userGetAppReceiverMedia.methodName: {
                            const mediafile: cat.MediaFileMsg = cat.MediaFileMsg.create(payload);
                            if (mediafile) {
                                this._store.dispatch(new StoreActions.appendToInfiteArray([mediafile], services.userGetAppReceiverMedia.methodName));
                            }
                            break;
                        }
                        case services.userGetUserSessions.methodName: {
                            this._store.dispatch(new StoreActions.addArrayItem(payload, "UserSessions"));
                            break;
                        }
                        default: {
                            // Exception for calls where an ID Filter was sent, update a list item
                            if (this._queryService.hasIdFilter(idref)) {

                                this._logger.debug("Active ID Filter Detected: ", idref);
                                this._logger.debug("%c Update item", "font-weight: bold", { call: data.callname, pay: payload });
                                const responseType: string = idref.call.responseType;
                                const methodName: string = idref.call.methodName;
                                // Update the store item.
                                this._store.dispatch(new StoreActions.updateListItem(payload, responseType, methodName));

                            } else {

                                // Add dynamically to Redux store by default.
                                if (idref.responseStream) {
                                    // this._logger.debug("%c Stored chunk", "font-weight: bold", { call: data.callname, pay: payload });

                                    // Only use stream caches if the request type is not a QueryMsg.
                                    // With a QueryMsg we do not have a proper identifier.
                                    if (idref.call.requestType !== messageDefinitions.QueryMsg) {
                                        this.fillStreamCache(data, payload);
                                    } else {
                                        this._store.dispatch(new StoreActions.addListItem(payload, idref.call.responseType, idref.call.methodName));
                                    }
                                } else {
                                    this._store.dispatch(new StoreActions.addProtobufMsg(payload, idref.call));
                                }
                            }
                        }
                    }

                    if (!idref.responseStream) {
                        this._logger.debug("%c Stored", "font-weight: bold", { call: data.callname, pay: payload });
                        idref.cb.resolve();
                    }
                }

            } else if (data.pushnotification) {

                if (data.payload) {

                    if (data.responsetype === messageDefinitions.NotificationMsg) {
                        const payload: cat.NotificationMsg = cat.NotificationMsg.decode(data.payload);
                        this._logger.debug("Push notification:", payload);
                        this.notificationHandler(payload);
                    } else if (data.responsetype === messageDefinitions.NotificationBundleMsg) {
                        const payload: cat.NotificationBundleMsg = cat.NotificationBundleMsg.decode(data.payload);
                        this._logger.debug("Push notification bundle: ", payload);
                        this.notificationBundle.next(payload);
                    } else {
                        throw new Error(protosui.messages.uitext.invalidnotification);
                    }
                }

            } else if (data.endstream && this._ids.has(data.callid)) {

                const idref: IIdRef = this._ids.get(data.callid);

                // Exception for calls with query, with a filter with single id, do not clear the list
                if (this._queryService.hasIdFilter(idref)) {
                    this._logger.debug(`Received single item ${idref.call.methodName}...`);
                    // this._logger.debug(`Data: `, data);
                } else {

                    // Populate the store with cache, if found
                    // Collect all the streamed data and store it, and clear the temporary map
                    if (idref.call.requestType !== messageDefinitions.QueryMsg) {
                        if (this._tempStreamList.has(data.callname)) {
                            const responsetype: string = this._tempStreamList.get(data.callname).keys().next().value;
                            const items: Map<string, any> = this._tempStreamList.get(data.callname).get(responsetype);
                            this._store.dispatch(new StoreActions.addList(items, data.callname));
                            this._logger.debug("%c List stored", "font-weight: bold", items);
                            this._tempStreamList.delete(data.callname);
                        } else {
                            this._store.dispatch(new StoreActions.clearList(data.callname));
                        }
                    }
                }

                this._ids.delete(data.callid);
                idref.cb.resolve();
                this._logger.debug("%c Stream completed", "font-weight: bold", { call: data.callname });
                this._store.dispatch(new StoreActions.setLoading(false, data.callname));

            } else {
                this._logger.error("Unknown call data: ", data);
                throw new Error(protosui.messages.uitext.nocall);
            }

            // Set the loading indicator to false for non-streaming calls
            if (!data.pushnotification && !data.endstream && !this._ids.has(data.callid)) {
                this._store.dispatch(new StoreActions.setLoading(false, data.callname));
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Fill up the stream cache to prevent flickering, store the results temporarily and store them permanently on stream end.
     *
     * @private
     * @param {cat.WebsocketMsg} data The metadata of the call
     * @param {*} payload The actual data to store
     * @memberof WsService
     */
    private fillStreamCache(data: cat.WebsocketMsg, payload: any) {
        // Fill up the temp cache based on request id or callname
        if (this._tempStreamList.has(data.callname) && this._tempStreamList.get(data.callname).has(data.responsetype)) {
            this._tempStreamList.get(data.callname).get(data.responsetype).set(payload.id || payload.name, payload);
        } else {
            this._tempStreamList
                .set(data.callname, new Map<string, Map<string, any>>([[
                    data.responsetype, new Map<string, any>([[ payload.id || payload.name, payload ]])
                ]]));
        }
    }

    /**
     * First of calls to be made afther authorization.
     *
     * @private
     * @param {boolean} licenseaccepted
     * @param {boolean} passwordreset
     * @memberof WsService
     */
    private async initCalls(licenseaccepted: number, passwordreset: boolean) {
        try {
            const eula: cat.EulaMsg = this._store.selectSnapshot((state: IState) => state.cat.userGetEula.msg);
            if (eula && licenseaccepted && (licenseaccepted > eula.validfrom) && (this._keycloak?.token || !passwordreset)) {
                this._auth.isAuth.next(true);
                await this.userGetCurrentUserPermissions();
                await this.getAppTypes();
                await this.userGetCurrentUserNotifications();
                this._appRouter.updateRoutes();
            } else {
                this._logger.debug("Do not call defaults, license not (yet) accepted, or password needs a reset");
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Check for known notifications and inform subscribers of updates.
     *
     * @param {cat.NotificationMsg} payload The notification payload.
     */
    async notificationHandler(payload: cat.NotificationMsg) {
        try {
            if (payload && payload.type) {

                switch (payload.type) {
                    case cat.NotificationType.NOTIFICATION_USERSESSION_EXPIRES: {
                        this._logger.warn(`User session is about to expire...`);

                        // Remove other overlays
                        await this._common.dismissAllOverlays();

                        // Create dialog with option to extend session
                        const expireDialog = this._common.createDialogReference({
                            title: protosui.messages.uitext.expiresheader,
                            content: protosui.messages.uitext.expiresbody,
                            buttons: [
                                {
                                    title: protosui.messages.uitext.extendsession,
                                    color: "primary"
                                }
                            ]
                        });
                        expireDialog.afterClosed().subscribe(async () => {
                            const hasUsersession = this._auth.hasUserSession();
                            if (hasUsersession) {
                                await this.getAppTypes();
                            }
                        });
                        break;
                    }

                    case cat.NotificationType.NOTIFICATION_USERSESSION_EXPIRED: {
                        this._logger.warn(`User session expired...`);

                        // Remove lock
                        this._store.dispatch(new StoreActions.unlockSession());

                        // Remove other overlays
                        this._auth.logOut("Usersession expired", false, true);
                        await this._common.dismissAllOverlays();

                        // // Only show expired alert, when signOut is not loading (read: when not fired by a user manually)
                        // if (!this._store.selectSnapshot((state: IState) => state.cat.userSignOut.isLoading)) {
                        //     this._snackBar.open(protosui.messages.error.expiredsessionbody, protosui.messages.uitext.ok);
                        // }
                        break;
                    }

                    case cat.NotificationType.NOTIFICATION_USERSESSION_DISABLED: {
                        this._logger.warn(`User session removed, user disabled...`);
                        // Remove other overlays
                        await this._auth.logOut("User session disabled", false, true);
                        await this._common.dismissAllOverlays();

                        // Show dialog.
                        const data: IDialogData = {
                            title: protosui.messages.uitext.disabledsessionheader,
                            content: protosui.messages.uitext.disabledsessionbody
                        }
                        this._common.createDialog(data);
                        break;
                    }

                    case cat.NotificationType.NOTIFICATION_USERSESSION_LOCK: {
                        // Lock the CAT application
                        await this.lockSession();
                        break;
                    }

                    case cat.NotificationType.NOTIFICATION_USERSESSION_PERMISSIONS: {
                        this._logger.warn("Update the permissions");
                        await this.userGetCurrentUserPermissions();
                        this._appRouter.updateRoutes();
                        break;
                    }

                    case Number(cat.NotificationType.NOTIFICATION_UNKNOWN): {
                        this._logger.warn(`Notification type not supported...`);
                        break;
                    }
                    default: {
                        payload["humanized"] = protosui.def.NotificationType[
                            cat.NotificationType[payload.type]
                        ];

                        // Notify subscribers with new notification
                        this.notification.next(payload);
                        this.notifications.get(payload.type).next(payload);
                        break;
                    }
                }
            } else {
                this._logger.error(protosui.messages.uitext.nopayload, payload);
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Lock the CAT application
     *
     * @private
     * @memberof WsService
     */
    private async lockSession() {
        try {

            this._logger.debug("Lock user session...");

            // Apply alert if not already locked
            if (!this._store.selectSnapshot((state: IState) => state.cat.SessionLocked)) {
                this._store.dispatch(new StoreActions.lockSession());

                // Navigate back to the dashboard
                await this._router.navigate([`${appRouteNames.DASHBOARD}`]);

                // Create dialog with option to unlock session.
                const unlockDialog = this._common.createDialogReference({
                    title: protosui.messages.uitext.unlockheader,
                    content: protosui.messages.uitext.unlockbody,
                    buttons: [
                        {
                            title: protosui.messages.uitext.unlock,
                            action: "unlock",
                            color: "primary"
                        }
                    ]
                });
                unlockDialog.afterClosed().subscribe(async (data) => {
                    if (data === "unlock") {
                        await this.sendRequest(services.userUnlock);
                        this._store.dispatch(new StoreActions.unlockSession());
                    }
                });

            } else {
                this._logger.debug("Already locked, not applying an alert again...");
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * After a sign in or refresh, get the user"s permissions to
     * update the menu structure to it"s permission level
     */
    async userGetCurrentUserPermissions() {
        try {
            await this.sendRequest(services.userGetCurrentUserPermissions);
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Fetch the user notifications
     *
     * @memberof DashboardPage
     */
    private async userGetCurrentUserNotifications() {
        try {
            await this.sendRequest(services.userGetCurrentUserNotifications);
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * After a sign in or refresh, get the EULA to determine the validity
     */
    async getEula() {
        try {
            await this.sendRequest(services.userGetEula);
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * After a sign in or refresh, get the system's available app types
     * to show in the interface.
     */
    async getAppTypes() {
        try {
            await this.sendRequest(services.userGetAppTypes);
        } catch (error) {
            throw new Error(error);
        }
    }

}
