import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, inject, Input, ViewChild, ElementRef } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { IState } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { Observable } from "rxjs";
import { WsService } from "@services/ws/ws.service";
import { SubscriptionManager } from "@shared/subscription-manager";
import { appendToInfiteArray, clearList, clearStoreItem } from "@store/actions";
import { userGetConversationMedia, userGetConversationMediaCount } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";

@Component({
    selector: "app-conversation-media",
    templateUrl: "./conversation-media.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationMediaComponent extends SubscriptionManager {

    @ViewChild("content", {static: false}) private content: ElementRef;

    mediafiles$: Observable<cat.MediaFileMsg[]> = inject(Store).select((state: IState) => state.cat.userGetConversationMedia?.infiniteArray);
    isLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetConversationMedia?.isLoading);

    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    @Input() conversation: cat.ConversationMsg = cat.ConversationMsg.create();

    // Public properties
    public uitext = protosui.messages.uitext;
    public mediaTypeChat = cat.MediaType.MEDIA_CONVERSATIONS;
    public path = userGetConversationMedia;
    public countpath = userGetConversationMediaCount;


    // Private properties.
    private _mediaProcessing = false;
    private _allItemsLoaded = false;
    private _filterQuery = cat.QueryMsg.create();
    private _batchSize = 256;

    constructor(
        public common: CommonService,
        private _logger: LoggerService,
        private _ws: WsService,
        private _store: Store
    ) {
        super();
    }

    async ngOnDestroy() {
        this._store.dispatch(new clearStoreItem(userGetConversationMedia));
    }

    /**
     * Scroll event on the main container.
     *
     * @memberof ConversationMediaComponent
     */
    public async onScroll() {

        if (this._allItemsLoaded) {
            return;
        }

        const clientHeight = this.content.nativeElement.clientHeight;
        const scrollTop = this.content.nativeElement.scrollTop;
        const scrollHeight = this.content.nativeElement.scrollHeight;

        if ((Math.abs((clientHeight + scrollTop) - scrollHeight) < 100) && !this._mediaProcessing) {
            await this.userGetConversationMedia();
        }
    }

    public async updateQuery(event) {
        this._logger.warn("Update query: ", event);
        this._filterQuery = event;

        this._store.dispatch(new clearStoreItem(userGetConversationMedia));
        // Refetch results with new filter applied
        await this.userGetConversationMedia();
        await this.userGetConversationMediaCount();
    }

    /**
     * Get the attachments belonging to an account
     *
     * @private
     * @memberof ConversationMediaComponent
     */
    private async userGetConversationMedia() {
        try {
            // Set flag for scroll position
            this._mediaProcessing = true;

            const query = this._filterQuery;
            query.offset = this._store.selectSnapshot((state: IState) => state.cat.userGetConversationMedia).infiniteArray?.length || 0;

            // const query = this.getFilterQuery();
            const payload: cat.ConversationMsg = cat.ConversationMsg.create({ id: this.conversation.id });
            this._logger.debug("Query: ", query);

            // EXCEPTION in websocket, all attachments are directly pushed to array (=> ignore duplicate ids).
            await this._ws.sendRequest(userGetConversationMedia, payload, undefined, undefined, query);

            const buffer = this._store.selectSnapshot((state: IState) => state.cat.userGetConversationMedia.list);

            if (buffer.size) {
                this._allItemsLoaded = false;
                this._store.dispatch(new appendToInfiteArray([...buffer.values()], userGetConversationMedia.methodName));
                // CLear the buffer
                this._store.dispatch(new clearList(userGetConversationMedia.methodName));

                // Determine if all items are loaded.
                const infiniteArray = this._store.selectSnapshot((state: IState) => state.cat.userGetConversationMedia.infiniteArray);
                if (infiniteArray.length < this._batchSize) {
                    this._allItemsLoaded = true;
                }

            } else {
                // Stop trying to load, onScroll stop.
                this._logger.warn("No more media items found.");
                this._allItemsLoaded = true;
            }

        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        } finally {
            // Remove flag for scroll position
            this._mediaProcessing = false;
        }
    }

    /**
     * Get the attachments belonging to an account
     *
     * @private
     * @memberof ConversationMediaComponent
     */
    private async userGetConversationMediaCount() {
        try {

            const payload: cat.QueryMsg = this._filterQuery;
            payload.references = {
                [cat.ReferenceType.REFERENCE_CONVERSATION_ID]: this.conversation.id
            }
            this._logger.debug("Query: ", payload);

            await this._ws.sendRequest(userGetConversationMediaCount, payload);

        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }
}
