import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewChild, Inject, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { cat } from "@assets/proto/msgs";
import { IServiceDescriptor, userAddMessageAnnotation, userGetDetailedMessage, userGetMessageAnnotations, userGetMessageAttachments, userGetMessageLabels, userGetMessageReactions, userRemoveMessageAnnotation, userSetMessageLabel, userUnsetMessageLabel } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { IIconDefinition, IImageDetailPageData, IMessageDefinitions, IMessageDetailData, IState, MessageAction } from "@shared/app-models";
import { Store } from "@ngxs/store";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";
import { WsService } from "@services/ws/ws.service";
import { clearList, clearStoredMessage } from "@store/actions";
import { getList } from "@store/store";
import { Observable } from "rxjs";
import { MediaDetailsDialog } from "../media-details/media-details.dialog";
import { MatTable } from "@angular/material/table";

@Component({
    selector: "message-details",
    templateUrl: "message-details.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDetailsDialog implements OnInit, OnDestroy {

    @ViewChild("tabGroup") tabGroup!:MatTabGroup;
    @ViewChild(MatTable) table: MatTable<any>;

    message$: Observable<cat.MessageMsg> = inject(Store).select((state: IState) => state.cat.userGetDetailedMessage.msg);
    msgLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetDetailedMessage.isLoading);
    attachments$: Observable<cat.AttachmentMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetMessageAttachments));
    reactions$: Observable<cat.ReactionMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetMessageReactions));

    public selectedTabIndex: number = 0;
    public permissionEnum = cat.Permission;
    public icon: IIconDefinition = protosui.msgIcons.ConversationMsg;
    public definition: IMessageDefinitions = this.common.getMessageDefinitions(messageDefinitions.MessageMsg);
    public readonly data: IMessageDetailData = inject(MAT_DIALOG_DATA);

    // Info tab.
    public uitext = protosui.messages.uitext;
    public messageDefinitions = messageDefinitions;

    // Annotations tab.
    public getAnnotations: IServiceDescriptor = userGetMessageAnnotations;
    public addAnnotations: IServiceDescriptor = userAddMessageAnnotation;
    public removeAnnotations: IServiceDescriptor = userRemoveMessageAnnotation;

    // Label tab.
    public userGetMessageLabels: IServiceDescriptor = userGetMessageLabels;
    public userSetMessageLabel: IServiceDescriptor = userSetMessageLabel;
    public userUnsetMessageLabel: IServiceDescriptor = userUnsetMessageLabel;

    // Attachment tab.
    public fileDefinition: IMessageDefinitions;
    public attachmentStatus = cat.AttachmentStatus;
    public attachmentDefinition: IMessageDefinitions;
    public displayedColumns = ["externid", "filename", "filesize", "mimetype", "lastmodified"];

    // History
    public refreshTimeline: number = 0;

    private _msgAction: number = MessageAction.Info;
    private _msgActionEnum = MessageAction;

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _dialog: MatDialog,
        private _logger: LoggerService,
        private _ws: WsService,
        private _store: Store) {
        // Assign the ids.
        this._msgAction = this.data.msgAction;

        if (!this.data.profile?.id || !this.data.account?.id || !this.data.message?.id) {
            throw new Error("Missing profile, account or message id.");
        }

        // Select correct tab.
        // TODO: lookup index based on labelclass?
        switch (this._msgAction) {
            case this._msgActionEnum.Annotate: {
                this.selectedTabIndex = 1;
                break;
            }
            case this._msgActionEnum.Label: {
                this.selectedTabIndex = 2;
                break;
            }
            case this._msgActionEnum.Attachments: {
                this.selectedTabIndex = 3;
                break;
            }
            case this._msgActionEnum.Links: {
                this.selectedTabIndex = 4;
                break;
            }
            case this._msgActionEnum.Reactions: {
                this.selectedTabIndex = 5;
                break;
            }
            case this._msgActionEnum.History: {
                this.selectedTabIndex = 6;
                break;
            }
            default: {
                this.selectedTabIndex = 0;
            }
        }
    }

    public async ngOnInit() {
        // Assign the attachment and file definitions.
        this.attachmentDefinition = this.common.getMessageDefinitions(messageDefinitions.AttachmentMsg);
        this.fileDefinition = this.common.getMessageDefinitions(messageDefinitions.MediaFileMsg);
        // Clear message.
        this._store.dispatch(new clearStoredMessage(userGetDetailedMessage.methodName, messageDefinitions.MessageMsg));
        // Get full message details.
        await this.userGetDetailedMessage();
        await this.userGetMessageAttachments();
        await this.userGetMessageReactions();
    }

    /**
     * Select the correct tab from message actions.
     *
     * @memberof MessageDetailsDialog
     */
    ngAfterViewInit() {
        let label = this.uitext.info;

        // Select correct tab.
        switch (this._msgAction) {
            case this._msgActionEnum.Annotate: {
                label = this.uitext.annotations;
                break;
            }
            case this._msgActionEnum.Label: {
                label = this.uitext.labels;
                break;
            }
            case this._msgActionEnum.Attachments: {
                label = this.uitext.attachments;
                break;
            }
            case this._msgActionEnum.Links: {
                label = this.uitext.links;
                break;
            }
            case this._msgActionEnum.Reactions: {
                label = this.uitext.reactions;
                break;
            }
            case this._msgActionEnum.History: {
                label = this.uitext.history;
                break;
            }
        }
        // Select correct tab based on aria-label.
        const tabIndex = this.tabGroup?._tabs?.toArray()?.findIndex(tab => tab.ariaLabel?.toLowerCase() === label.toLowerCase());
        this.selectedTabIndex = (tabIndex > -1) ? tabIndex : 0;
    }

    public async onTabChanged(event: MatTabChangeEvent) {
        if (!event || !event.tab?.ariaLabel) {
            this._logger.error("No aria label text / event found.");
        }
        // Refetch the message history detail.
        const label = event.tab.ariaLabel.toLowerCase();
        if (label === this.uitext.history.toLowerCase()) {
            await this.userGetDetailedMessage();
        }
    }

    public ngOnDestroy() {
        // Clear message.
        this._store.dispatch(new clearStoredMessage(userGetDetailedMessage.methodName, messageDefinitions.MessageMsg));
        this._store.dispatch(new clearStoredMessage(userGetMessageReactions.methodName, this.data.message.id));
        this._store.dispatch(new clearList(userGetMessageAttachments.methodName));
    }

    /**
     * Get the full detailed message.
     *
     * @private
     * @memberof MessageDetailsPage
     */
    private async userGetDetailedMessage() {
        try {
            const payload = cat.MessageMsg.create({ id: this.data.message.id })
            await this._ws.sendRequest(userGetDetailedMessage, payload);
            this.refreshTimeline = Date.now();
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    generateColor(idx: number): string {
        const bgColors: string[] = ["#FFD700", "#FFA500", "#FF6347", "#FF69B4", "#FFC0CB", "#00FFFF", "#00FF00", "#7FFF00", "#FFDAB9", "#FF4500"];
        if (idx < 10) {
            return bgColors[idx];
        } else {
            return bgColors[Math.floor(Math.random() * 10)];
        }
      }

    /**
     * Get the attachments of the message
     *
     * @memberof MessageDetailsPage
     */
    public async userGetMessageAttachments() {
        try {

            const message = cat.MessageMsg.create({
                id: this.data.message.id
            });
            await this._ws.sendRequest(userGetMessageAttachments, message);

        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(new Error(error));
        }
    }

    /**
     * Get (all) the reactions of the message.
     *
     * @memberof MessageDetailsPage
     */
     public async userGetMessageReactions() {
        try {
            const message = cat.MessageMsg.create({
                id: this.data.message.id
            });
            await this._ws.sendRequest(userGetMessageReactions, message);
        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(new Error(error));
        }
    }

    /**
     * Show a toast or show the original attachment
     *
     * @param {*} event
     * @memberof MessageDetailsPage
     */
    async userGetMedia(event: any) {

        const attachment: cat.AttachmentMsg = event.attachment;
        const message: cat.MessageMsg = event.message;

        try {

            if (!attachment) {
                throw new Error(protosui.messages.uitext.prerequisites);
            } else {
                switch (attachment.status) {
                    case cat.AttachmentStatus.ATTACHMENTSTATUS_PENDING:
                    case cat.AttachmentStatus.ATTACHMENTSTATUS_FAILED: {
                        const selector = cat.AttachmentStatus[attachment.status];
                        await this.common.createSnackbar(protosui.messages.uitext[selector]);
                        break;
                    }
                    case cat.AttachmentStatus.ATTACHMENTSTATUS_COMPLETE: {
                        if (!attachment || !attachment.files || !attachment.files[0] || !attachment.files[0].fileid ||
                            !attachment.files[0].mimetype || !attachment.type) {
                            throw new Error(protosui.messages.uitext.prerequisites);
                        }
                        this.showModal(attachment, message);
                        break;
                    }
                }
            }

        } catch (error) {
            await this.common.createSnackbar(error);
            this._logger.error(error);
        }
    }

    /**
     * Show image detail modal
     *
     * @param {cat.AttachmentMsg} attachment
     * @param {cat.MessageMsg} message
     * @returns
     * @memberof ChatsPage
     */
    async showModal(attachment: cat.AttachmentMsg, message: cat.MessageMsg) {
        if (attachment && message) {
            try {
                const dialogData: IImageDetailPageData = {
                    attachment: attachment,
                    refId: this.data.account.id,
                    accountId: this.data.account.id,
                    mediatype: cat.MediaType.MEDIA_CONVERSATIONS,
                    asProfile: true,
                    profileId: this.data.profile.id
                };

                this._dialog.open(MediaDetailsDialog, {
                    width: "95vw",
                    height: "95vh",
                    data: dialogData
                });

            } catch (error) {
                this._logger.error(error);
            }
        }
    }
}
