import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, OnInit, inject } from "@angular/core";
import { Router } from "@angular/router";
import { appRouteNames } from "@app/app.routes.names";
import { cat } from "@assets/proto/msgs";
import { userGetCurrentUserDevices, userGetCurrentUserProfiles, userSetProfileHidden } from "@assets/proto/services";
import { ReviewersPopoverComponent } from "@components/dialog-reviewers/popover-reviewers.component";
import { protosui } from "@definitions/definitions";
import { IDialogButton, IReviewersData, IState } from "@shared/app-models";
import { HasPermissionPipe } from "@pipes/haspermission/haspermission.pipe";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { TlService } from "@services/tl/tl.service";
import { WsService } from "@services/ws/ws.service";
import { getList } from "@store/store";
import { Observable } from "rxjs";
import { removeListItem } from "@store/actions";
import { MatDialog } from "@angular/material/dialog";
@Component({
    selector: "widget-profile",
    templateUrl: "./widget-profile.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetProfileComponent implements OnInit, OnDestroy {

    profiles$: Observable<cat.ProfileMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetCurrentUserProfiles));
    loadingProfiles$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetCurrentUserProfiles.isLoading);
    apptypes$: Observable<Map<string, cat.AppTypeMsg>> = inject(Store).select((state: IState) => state.cat.userGetAppTypes.list);
    me$: Observable<cat.UserMsg> = inject(Store).select((state: IState) => state.cat.Me.msg);

    @Output() emitProfileCount: any = new EventEmitter<number>();
    @Input() groupBy = ["theme", "name"];
    @Input() set reload(reload: boolean) {
        if (reload) {
            this._logger.debug("Profile widget: Reload profiles ?");
            this.fetchProfiles();
        }
    }

    // Public properties
    public mediaType = cat.MediaType;
    public permissionEnum = cat.Permission;
    public groupByMsgType = messageDefinitions.ThemeMsg;
    public uitext = protosui.messages.uitext;
    public columns: number = 1;

    // Private properties
    private _isInitialized = false;
    private _isDestroyed = false;

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _store: Store,
        private _logger: LoggerService,
        private _dialog: MatDialog,
        private _router: Router,
        private _ws: WsService
    ) {
        this._logger.debug("Construct WidgetProfileComponent");
    }

    /**
     * Destroy from dashboard.ts to prevent unloaded profiles (CAT-2573)
     *
     * @memberof WidgetProfileComponent
     */
    public destroyByParent() {
        this._logger.debug("Destroy WidgetProfileComponent [by dashboard]");
        this.ngOnDestroy();
    }

    public initFromParent() {
        this._logger.debug("Init WidgetProfileComponent [by dashboard]", this);
        this.ngOnInit();
    }

    /**
     * Init function to fetch profiles and set destroy/init flags
     *
     * @memberof WidgetProfileComponent
     */
    async ngOnInit() {
        if (!this._isInitialized) {
            this._logger.debug("Init WidgetProfileComponent");
            this._isInitialized = true;
            this._isDestroyed = false;
            // Fetch the profiles for a user
            await this.fetchProfiles();
        }
    }

    public async ngOnDestroy() {
        if (!this._isDestroyed) {
            this._isInitialized = false;
            this._isDestroyed = true;
            this._logger.debug("Destroy WidgetProfileComponent");
        }
    }

    /**
     * Fetch profiles from constructor to ensure fetching.
     *
     * @private
     * @memberof WidgetProfileComponent
     */
    private async fetchProfiles() {
        try {
            await this.userGetCurrentUserProfiles();

            // Inform dashboard page about profile count
            const profileCount = this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserProfiles)?.list?.size
            this.emitProfileCount.emit(profileCount);

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Get all the apps per profile
     *
     * @memberof WidgetProfileComponent
     */
     public async userGetCurrentUserProfiles() {
        try {

            // Fetch the query from local storage
            let query: cat.QueryMsg = cat.QueryMsg.create(JSON.parse(localStorage.getItem(userGetCurrentUserProfiles.methodName)));
            if (!query) {
                query = cat.QueryMsg.create();
            }

            query.filters.push(cat.FilterMsg.create({
                collection: "profiles",
                field: "hidden",
                value: cat.VisibilityType.VISIBILITY_VISIBLE.toString(),
                operator: cat.FilterOperator.FILTER_EXCLUDE
            }));

            await this._ws.sendRequest(userGetCurrentUserProfiles, undefined, undefined, undefined, query);

        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(new Error(error));
        }
    }

    /**
     * Navigate to a profile dashboard
     * @param {cat.ProfileMsg} profile The profile to go to
     * @memberof WidgetProfileComponent
     */
       async goToProfile(profile: cat.ProfileMsg) {
        try {

            if (profile && profile.id) {
                this._router.navigate([`${this._router.url}/profile/${profile.id}`]);
            } else {
                this.common.createSnackbar(protosui.messages.uitext.noprofile);
            }

        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(new Error(error));
        }
    }

    /**
     * Go to add user profile page.
     *
     * @memberof WidgetProfileComponent
     */
    public async userAddProfile() {
        if (new HasPermissionPipe(this._logger, this._store).transform([cat.Permission.PERMISSION_MANAGE_MY_PROFILES])) {
            await this._router.navigate([`${appRouteNames.USER_PROFILES}/${appRouteNames.ADD_USER_PROFILE}`]);
            this._isInitialized = false;
        } else {
            this._logger.error("No permission");
            this.common.createSnackbar(protosui.messages.uitext.ownaccount)
        }
    }

     /**
     * Show popover with reviewers
     *
     * @param {MouseEvent} event
     * @param {cat.UserMsg[]} reviewers
     * @returns
     * @memberof WidgetProfileComponent
     */
      public async showCollaborators(owner: cat.UserMsg, reviewers: cat.UserMsg[]) {
        try {
            const data: IReviewersData = {
                owner: owner,
                reviewers: reviewers
            };
            const dialogRef = this._dialog.open(ReviewersPopoverComponent, {
                width: "400px",
                height: "400px",
                data: data
            });
            dialogRef.afterClosed().subscribe(result => {
                this._logger.debug(`Dialog result: ${result}`);
            });
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Dismiss popover with show info data
     *
     * @memberof WidgetProfileComponent
     */
    public async showInfo(profile: cat.ProfileMsg) {
        await this._router.navigate([`${appRouteNames.USER_PROFILES}/${profile.id}/${appRouteNames.USER_PROFILE_DETAILS}`]);
        // Check if the profiles are already fetched, if not, fetch
        if (!this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserProfiles).list.size) {
            await this._ws.sendRequest(userGetCurrentUserProfiles);
        }
    }

    /**
     * Hide the profile from the dashboard.
     *
     * @param {cat.ProfileMsg} profile
     * @memberof WidgetProfileComponent
     */
    public async hideFromDashboard(profile: cat.ProfileMsg) {
        try {
            // Show a dialog with a yes / no choice.
            const hideDialog = this.common.createDialogReference({
                title: protosui.messages.uitext.hideprofile,
                content: protosui.messages.uitext.hideprofileinfo,
                buttons: [
                    {
                        title: protosui.messages.uitext.hide,
                        color: "primary",
                        action: "proceed"
                    },
                    {
                        title: protosui.messages.uitext.cancel,
                        color: "primary",
                        action: "cancel"
                    }
                ]
            });

            hideDialog.afterClosed().subscribe(async (result: IDialogButton["action"]) => {
                this._logger.debug("Result", result);
                if (result === "proceed") {
                    const hiddenProfile = cat.ProfileMsg.create({
                        id: profile.id,
                        hidden: cat.VisibilityType.VISIBILITY_HIDDEN
                    });
                    await this._ws.sendRequest(userSetProfileHidden, hiddenProfile);
                    this._store.dispatch(new removeListItem(hiddenProfile, userGetCurrentUserProfiles.methodName));
                }
            });

        } catch (error) {
            this._logger.error(error);
        }
    }

     /**
     * Dismiss popover with edit data
     *
     * @memberof WidgetProfileComponent
     */
     public edit(profile: cat.ProfileMsg) {
        this._router.navigate([`${appRouteNames.USER_PROFILES}/${profile.id}/${appRouteNames.USER_PROFILE_DETAILS}/edit/userprofile/theme`], { queryParams: { from: this._router.url }});
        this._dialog.closeAll();
    }

    /**
     * Jump to the device page, after fetching the current users devices
     * Show a warning when the device is not found
     */
    async gotoDevice(profile: cat.ProfileMsg) {
        try {
            if (profile.device?.id) {
                await this._ws.sendRequest(userGetCurrentUserDevices);
                this._router.navigate([`${appRouteNames.DASHBOARD}/device/${profile.device.id}`], { queryParams: { from: this._router.url }});
            } else {
                this.common.createSnackbar(protosui.messages.uitext.nodevice);
            }
        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(new Error(error));
        }
    }
}
