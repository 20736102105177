<div class="cat-report-content cat-overflow-y">
    <ng-container *ngIf="(members$|async) as contacts">

        <div class="cat-button-row cat-margin-bottom-lg">
            <div class="cat-button-row-item cat-shrink cat-xs">
                <button
                    (click)="refreshMembers()"
                    matTooltip="{{ uitext.refresh | translate }}"
                    matTooltipPosition="above"
                    mat-mini-fab color="light-shade" class="cat-no-shadow">
                    <mat-icon color="primary" class="cat-no-padding">refresh</mat-icon>
                </button>
            </div>

            <div class="cat-button-row-item cat-width-250">
                <mat-form-field appearance="fill" subscriptSizing="dynamic" class="cat-full-width cat-margin-right-xl cat-flex-grow cat-dense cat-width-250">
                    <span matPrefix class="cat-margin-right-md cat-margin-left-lg">
                        <mat-icon class="cat-animation-rotate-clockwise">search</mat-icon>
                    </span>
                    <mat-label>{{ uitext.search | translate | replaceTerm }} ({{ (memberCount$ | async) }})</mat-label>
                    <input matInput #search placeholder="{{ 'Ex. John Doe' | translate }}" matInputAutofocus>
                    <button *ngIf="search?.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchvalue()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="cat-button-row-item cat-push-right">
                <mat-icon (click)="showInfo()" matSuffix matTooltip="help" matTooltipPosition="right" color="primary" class="cat-cursor-pointer">
                    help
                </mat-icon>
            </div>
        </div>

        <section class="cat-full-height cat-overflow-y cat-background-light-shade" tabindex="0">

            <mat-table [dataSource]="contacts" matSort matSortActive="username" matSortDirection="asc" matSortDisableClear>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        {{ uitext.name | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        <div class="cat-avatar cat-sm cat-float-start cat-margin-right-md">
                            <img
                                *ngIf="(contact.avatar | hasThumb) else noAvatar"
                                class="cat-cursor-pointer"
                                [src]="contact.avatar.files[0] | catsrc : 'thumb' : mediaTypeEnum.MEDIA_ACCOUNTAVATARS : (contact?.accountrefid) ? contact.accountrefid : contact.id">
                            <ng-template #noAvatar>
                                <img matTooltip="{{ uitext.noavatarfound | translate }}" matTooltipPosition="above"  src="assets/img/cat-avatar.svg">
                            </ng-template>
                        </div>
                        {{ contact | accountName | emptyValue }}
                        <div
                            *ngIf="contact.externid === account.externid"
                            color="primary"
                            class="cat-opacity-4 cat-margin-left-md">
                            ({{ uitext.you | translate }})
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                        {{ uitext.memberstatus | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        {{ (statusTypeDef[statusType[contact?.memberstatus?.status]]?.label | translate) | emptyValue }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="username">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ uitext.username | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        {{ contact.username | emptyValue }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="externid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ uitext.externid | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let contact">
                        {{ contact.externid | emptyValue }}
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    (click)="openContact(row, row?.accountrefid)"
                    [matTooltip]="row.externid === account.externid ? (uitext.youraccount | translate | replaceTerm) : null" matTooltipPosition="above"
                    class="cat-cursor-pointer">
                </mat-row>

            </mat-table>

            <div *ngIf="!contacts.length && !(isLoading$ | async)" class="cat-no-records">
                {{ uitext.notfound | translate }}
            </div>

        </section>

        <mat-paginator
            class="cat-stick bottom"
            [length]="(memberCount$|async)"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>


    </ng-container>

</div>