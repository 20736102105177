// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

// NOTE: THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT

import { cat } from "../../assets/proto/msgs";
import * as storemodel from "../../assets/proto/store-model";

/** The initial state of the store */
export const INITIAL_STATE: storemodel.IAppState = {
    // Primitive
    WsRetryConnection: 0,
    WsVersion: { version: "", timestamp: 0 },
    QRCode: "",
    WsConnection: false,
    SessionLocked: false,
    KeycloakMode: false,
    AuthUser: false,
    DashboardAngle: "profile",
    DeviceStats: [],
    GlobalSuccessStats: [],
    GlobalFailedStats: [],
    ExcludeReportMessages: [],
    RoleColumns: [],
    DatepickerMonth: "",
    SelectedMessage: cat.MessageMsg.create(),
    CaptureQRApk: storemodel.createMedia({ msg: cat.MediaMsg.create() }),
    CaptureQR: storemodel.createMedia({ msg: cat.MediaMsg.create() }),
    ReportPreviewURL: "",

    // Protobuf service calls
    /** Generated userUnlock store method */
    userUnlock: storemodel.createuserUnlock({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSignOut store method */
    userSignOut: storemodel.createuserSignOut({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userChangeSessionRole store method */
    userChangeSessionRole: storemodel.createuserChangeSessionRole({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userGetUserSessions store method */
    userGetUserSessions: storemodel.createuserGetUserSessions({
        msg: cat.UserSessionMsg.create(),
        isLoading: false
    }),
    /** Generated userGetEula store method */
    userGetEula: storemodel.createuserGetEula({
        msg: cat.EulaMsg.create(),
        isLoading: false
    }),
    /** Generated userGetPasswordPolicy store method */
    userGetPasswordPolicy: storemodel.createuserGetPasswordPolicy({
        msg: cat.PasswordPolicyMsg.create(),
        isLoading: false
    }),
    /** Generated userSetTerminology store method */
    userSetTerminology: storemodel.createuserSetTerminology({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetSystemId store method */
    userGetSystemId: storemodel.createuserGetSystemId({
        msg: cat.LicenseMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentLicense store method */
    userGetCurrentLicense: storemodel.createuserGetCurrentLicense({
        msg: cat.LicenseMsg.create(),
        isLoading: false
    }),
    /** Generated userGetLicenseCount store method */
    userGetLicenseCount: storemodel.createuserGetLicenseCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveLicense store method */
    userRemoveLicense: storemodel.createuserRemoveLicense({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userImportLicense store method */
    userImportLicense: storemodel.createuserImportLicense({
        msg: cat.LicenseMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAvailableLogFileDates store method */
    userGetAvailableLogFileDates: storemodel.createuserGetAvailableLogFileDates({
        msg: cat.FilterMsg.create(),
        isLoading: false
    }),
    /** Generated userSetLogLevel store method */
    userSetLogLevel: storemodel.createuserSetLogLevel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetSystemSetting store method */
    userSetSystemSetting: storemodel.createuserSetSystemSetting({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetSystemEventsFileId store method */
    userGetSystemEventsFileId: storemodel.createuserGetSystemEventsFileId({
        msg: cat.MediaMsg.create(),
        isLoading: false
    }),
    /** Generated userGetLogFileId store method */
    userGetLogFileId: storemodel.createuserGetLogFileId({
        msg: cat.MediaMsg.create(),
        isLoading: false
    }),
    /** Generated userGetSystemEventCount store method */
    userGetSystemEventCount: storemodel.createuserGetSystemEventCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userGetServiceStatuses store method */
    userGetServiceStatuses: storemodel.createuserGetServiceStatuses({
        msg: cat.ServiceStatusMsg.create(),
        isLoading: false
    }),
    /** Generated userGetTerminology store method */
    userGetTerminology: storemodel.createuserGetTerminology({
        msg: cat.TerminologyMsg.create(),
        isLoading: false
    }),
    /** Generated userGetLicenses store method */
    userGetLicenses: storemodel.createuserGetLicenses({
        msg: cat.LicenseMsg.create(),
        isLoading: false
    }),
    /** Generated userGetSystemSettings store method */
    userGetSystemSettings: storemodel.createuserGetSystemSettings({
        msg: cat.SettingMsg.create(),
        isLoading: false
    }),
    /** Generated userGetSystemEvents store method */
    userGetSystemEvents: storemodel.createuserGetSystemEvents({
        msg: cat.SystemEventMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAuditLogCount store method */
    userGetAuditLogCount: storemodel.createuserGetAuditLogCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAuditLogs store method */
    userGetAuditLogs: storemodel.createuserGetAuditLogs({
        msg: cat.AuditLogMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAuditLogUsers store method */
    userGetAuditLogUsers: storemodel.createuserGetAuditLogUsers({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAuditLogCalls store method */
    userGetAuditLogCalls: storemodel.createuserGetAuditLogCalls({
        msg: cat.AuditLogCallMsg.create(),
        isLoading: false
    }),
    /** Generated userGetReportTemplateCount store method */
    userGetReportTemplateCount: storemodel.createuserGetReportTemplateCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userSignIn store method */
    userSignIn: storemodel.createuserSignIn({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userSignInWithSession store method */
    userSignInWithSession: storemodel.createuserSignInWithSession({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userSignInWithKeycloak store method */
    userSignInWithKeycloak: storemodel.createuserSignInWithKeycloak({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userAcceptEula store method */
    userAcceptEula: storemodel.createuserAcceptEula({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userSetCurrentUserPassword store method */
    userSetCurrentUserPassword: storemodel.createuserSetCurrentUserPassword({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserProfileCount store method */
    userGetCurrentUserProfileCount: storemodel.createuserGetCurrentUserProfileCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserCaseCount store method */
    userGetCurrentUserCaseCount: storemodel.createuserGetCurrentUserCaseCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserProfileReportCount store method */
    userGetCurrentUserProfileReportCount: storemodel.createuserGetCurrentUserProfileReportCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveCurrentUserNotifications store method */
    userRemoveCurrentUserNotifications: storemodel.createuserRemoveCurrentUserNotifications({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveProfileAvatar store method */
    userRemoveProfileAvatar: storemodel.createuserRemoveProfileAvatar({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetCurrentUser store method */
    userSetCurrentUser: storemodel.createuserSetCurrentUser({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userSetCurrentUserLastPopover store method */
    userSetCurrentUserLastPopover: storemodel.createuserSetCurrentUserLastPopover({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userRestoreCurrentUserPopovers store method */
    userRestoreCurrentUserPopovers: storemodel.createuserRestoreCurrentUserPopovers({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetCurrentUserLastProfile store method */
    userSetCurrentUserLastProfile: storemodel.createuserSetCurrentUserLastProfile({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetCurrentUserLastConversation store method */
    userSetCurrentUserLastConversation: storemodel.createuserSetCurrentUserLastConversation({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userResetUserPassword store method */
    userResetUserPassword: storemodel.createuserResetUserPassword({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userResetCurrentUserPassword store method */
    userResetCurrentUserPassword: storemodel.createuserResetCurrentUserPassword({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnmergeContact store method */
    userUnmergeContact: storemodel.createuserUnmergeContact({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetContactAlias store method */
    userSetContactAlias: storemodel.createuserSetContactAlias({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetProfileHidden store method */
    userSetProfileHidden: storemodel.createuserSetProfileHidden({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDetailedAccount store method */
    userGetDetailedAccount: storemodel.createuserGetDetailedAccount({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userLinkDeviceToProfile store method */
    userLinkDeviceToProfile: storemodel.createuserLinkDeviceToProfile({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnlinkDeviceFromProfile store method */
    userUnlinkDeviceFromProfile: storemodel.createuserUnlinkDeviceFromProfile({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetRolePermission store method */
    userSetRolePermission: storemodel.createuserSetRolePermission({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnsetRolePermission store method */
    userUnsetRolePermission: storemodel.createuserUnsetRolePermission({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetConversationVisibility store method */
    userSetConversationVisibility: storemodel.createuserSetConversationVisibility({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetServerVisibility store method */
    userSetServerVisibility: storemodel.createuserSetServerVisibility({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDeviceAppStatistics store method */
    userGetDeviceAppStatistics: storemodel.createuserGetDeviceAppStatistics({
        msg: cat.StatisticsSeriesMsg.create(),
        isLoading: false
    }),
    /** Generated userGetGlobalAppStatistics store method */
    userGetGlobalAppStatistics: storemodel.createuserGetGlobalAppStatistics({
        msg: cat.StatisticsSeriesMsg.create(),
        isLoading: false
    }),
    /** Generated userGetGlobalFailedItemsStatistics store method */
    userGetGlobalFailedItemsStatistics: storemodel.createuserGetGlobalFailedItemsStatistics({
        msg: cat.MediaMsg.create(),
        isLoading: false
    }),
    /** Generated userAddDomain store method */
    userAddDomain: storemodel.createuserAddDomain({
        msg: cat.DomainMsg.create(),
        isLoading: false
    }),
    /** Generated userModifyDomain store method */
    userModifyDomain: storemodel.createuserModifyDomain({
        msg: cat.DomainMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveDomain store method */
    userRemoveDomain: storemodel.createuserRemoveDomain({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userLinkDeviceToDomain store method */
    userLinkDeviceToDomain: storemodel.createuserLinkDeviceToDomain({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnlinkDeviceFromDomain store method */
    userUnlinkDeviceFromDomain: storemodel.createuserUnlinkDeviceFromDomain({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileFirstMessageTimestamp store method */
    userGetProfileFirstMessageTimestamp: storemodel.createuserGetProfileFirstMessageTimestamp({
        msg: cat.ProfileMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationReport store method */
    userGetConversationReport: storemodel.createuserGetConversationReport({
        msg: cat.ReportMsg.create(),
        isLoading: false
    }),
    /** Generated userGetContactReport store method */
    userGetContactReport: storemodel.createuserGetContactReport({
        msg: cat.ReportMsg.create(),
        isLoading: false
    }),
    /** Generated userGetExistingReport store method */
    userGetExistingReport: storemodel.createuserGetExistingReport({
        msg: cat.ReportMsg.create(),
        isLoading: false
    }),
    /** Generated userGetReportingCounts store method */
    userGetReportingCounts: storemodel.createuserGetReportingCounts({
        msg: cat.ReportMsg.create(),
        isLoading: false
    }),
    /** Generated userRetryProfileReport store method */
    userRetryProfileReport: storemodel.createuserRetryProfileReport({
        msg: cat.ReportMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileConversationCount store method */
    userGetProfileConversationCount: storemodel.createuserGetProfileConversationCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileConversationCounts store method */
    userGetProfileConversationCounts: storemodel.createuserGetProfileConversationCounts({
        msg: cat.FilterCountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAccountConversationCount store method */
    userGetAccountConversationCount: storemodel.createuserGetAccountConversationCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userGetExcludedTopicsMessages store method */
    userGetExcludedTopicsMessages: storemodel.createuserGetExcludedTopicsMessages({
        msg: cat.ConversationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetUserCount store method */
    userGetUserCount: storemodel.createuserGetUserCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userAddUser store method */
    userAddUser: storemodel.createuserAddUser({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userModifyUser store method */
    userModifyUser: storemodel.createuserModifyUser({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveUser store method */
    userRemoveUser: storemodel.createuserRemoveUser({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddRole store method */
    userAddRole: storemodel.createuserAddRole({
        msg: cat.RoleMsg.create(),
        isLoading: false
    }),
    /** Generated userModifyRole store method */
    userModifyRole: storemodel.createuserModifyRole({
        msg: cat.RoleMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveRole store method */
    userRemoveRole: storemodel.createuserRemoveRole({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetThemeCount store method */
    userGetThemeCount: storemodel.createuserGetThemeCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userAddTheme store method */
    userAddTheme: storemodel.createuserAddTheme({
        msg: cat.ThemeMsg.create(),
        isLoading: false
    }),
    /** Generated userModifyTheme store method */
    userModifyTheme: storemodel.createuserModifyTheme({
        msg: cat.ThemeMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveTheme store method */
    userRemoveTheme: storemodel.createuserRemoveTheme({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCaseCount store method */
    userGetCaseCount: storemodel.createuserGetCaseCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userAddCase store method */
    userAddCase: storemodel.createuserAddCase({
        msg: cat.CaseMsg.create(),
        isLoading: false
    }),
    /** Generated userModifyCase store method */
    userModifyCase: storemodel.createuserModifyCase({
        msg: cat.CaseMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveCase store method */
    userRemoveCase: storemodel.createuserRemoveCase({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddReportTemplate store method */
    userAddReportTemplate: storemodel.createuserAddReportTemplate({
        msg: cat.ReportTemplateMsg.create(),
        isLoading: false
    }),
    /** Generated userModifyReportTemplate store method */
    userModifyReportTemplate: storemodel.createuserModifyReportTemplate({
        msg: cat.ReportTemplateMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveReportTemplate store method */
    userRemoveReportTemplate: storemodel.createuserRemoveReportTemplate({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDeviceCount store method */
    userGetDeviceCount: storemodel.createuserGetDeviceCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userRegisterDevice store method */
    userRegisterDevice: storemodel.createuserRegisterDevice({
        msg: cat.DeviceMsg.create(),
        isLoading: false
    }),
    /** Generated userAddDevice store method */
    userAddDevice: storemodel.createuserAddDevice({
        msg: cat.DeviceMsg.create(),
        isLoading: false
    }),
    /** Generated userModifyDevice store method */
    userModifyDevice: storemodel.createuserModifyDevice({
        msg: cat.DeviceMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveDevice store method */
    userRemoveDevice: storemodel.createuserRemoveDevice({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileCount store method */
    userGetProfileCount: storemodel.createuserGetProfileCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userAddProfile store method */
    userAddProfile: storemodel.createuserAddProfile({
        msg: cat.ProfileMsg.create(),
        isLoading: false
    }),
    /** Generated userAddProfileToCurrentUser store method */
    userAddProfileToCurrentUser: storemodel.createuserAddProfileToCurrentUser({
        msg: cat.ProfileMsg.create(),
        isLoading: false
    }),
    /** Generated userModifyProfile store method */
    userModifyProfile: storemodel.createuserModifyProfile({
        msg: cat.ProfileMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveProfile store method */
    userRemoveProfile: storemodel.createuserRemoveProfile({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveCurrentUserProfile store method */
    userRemoveCurrentUserProfile: storemodel.createuserRemoveCurrentUserProfile({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveProfileMedia store method */
    userRemoveProfileMedia: storemodel.createuserRemoveProfileMedia({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDetailedConversation store method */
    userGetDetailedConversation: storemodel.createuserGetDetailedConversation({
        msg: cat.ConversationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDetailedMessage store method */
    userGetDetailedMessage: storemodel.createuserGetDetailedMessage({
        msg: cat.MessageMsg.create(),
        isLoading: false
    }),
    /** Generated userLinkProfileToCase store method */
    userLinkProfileToCase: storemodel.createuserLinkProfileToCase({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnlinkProfileFromCase store method */
    userUnlinkProfileFromCase: storemodel.createuserUnlinkProfileFromCase({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userLinkSupervisorToCase store method */
    userLinkSupervisorToCase: storemodel.createuserLinkSupervisorToCase({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnlinkSupervisorFromCase store method */
    userUnlinkSupervisorFromCase: storemodel.createuserUnlinkSupervisorFromCase({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddCaseLabel store method */
    userAddCaseLabel: storemodel.createuserAddCaseLabel({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveCaseLabel store method */
    userRemoveCaseLabel: storemodel.createuserRemoveCaseLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddProfileReport store method */
    userAddProfileReport: storemodel.createuserAddProfileReport({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveProfileReport store method */
    userRemoveProfileReport: storemodel.createuserRemoveProfileReport({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddProfileLabel store method */
    userAddProfileLabel: storemodel.createuserAddProfileLabel({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveProfileLabel store method */
    userRemoveProfileLabel: storemodel.createuserRemoveProfileLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileContact store method */
    userGetProfileContact: storemodel.createuserGetProfileContact({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileContactCount store method */
    userGetProfileContactCount: storemodel.createuserGetProfileContactCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userSetProfileAccount store method */
    userSetProfileAccount: storemodel.createuserSetProfileAccount({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userModifyAppAccount store method */
    userModifyAppAccount: storemodel.createuserModifyAppAccount({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDetailedTopic store method */
    userGetDetailedTopic: storemodel.createuserGetDetailedTopic({
        msg: cat.TopicMsg.create(),
        isLoading: false
    }),
    /** Generated userAddGlobalLabel store method */
    userAddGlobalLabel: storemodel.createuserAddGlobalLabel({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveGlobalLabel store method */
    userRemoveGlobalLabel: storemodel.createuserRemoveGlobalLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetMessageLabel store method */
    userSetMessageLabel: storemodel.createuserSetMessageLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnsetMessageLabel store method */
    userUnsetMessageLabel: storemodel.createuserUnsetMessageLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddMessageAnnotation store method */
    userAddMessageAnnotation: storemodel.createuserAddMessageAnnotation({
        msg: cat.AnnotationMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveMessageAnnotation store method */
    userRemoveMessageAnnotation: storemodel.createuserRemoveMessageAnnotation({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddConversationAnnotation store method */
    userAddConversationAnnotation: storemodel.createuserAddConversationAnnotation({
        msg: cat.AnnotationMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveConversationAnnotation store method */
    userRemoveConversationAnnotation: storemodel.createuserRemoveConversationAnnotation({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetTopicLabel store method */
    userSetTopicLabel: storemodel.createuserSetTopicLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnsetTopicLabel store method */
    userUnsetTopicLabel: storemodel.createuserUnsetTopicLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddTopicAnnotation store method */
    userAddTopicAnnotation: storemodel.createuserAddTopicAnnotation({
        msg: cat.AnnotationMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveTopicAnnotation store method */
    userRemoveTopicAnnotation: storemodel.createuserRemoveTopicAnnotation({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetServerLabel store method */
    userSetServerLabel: storemodel.createuserSetServerLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnsetServerLabel store method */
    userUnsetServerLabel: storemodel.createuserUnsetServerLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddServerAnnotation store method */
    userAddServerAnnotation: storemodel.createuserAddServerAnnotation({
        msg: cat.AnnotationMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveServerAnnotation store method */
    userRemoveServerAnnotation: storemodel.createuserRemoveServerAnnotation({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetConversationLabel store method */
    userSetConversationLabel: storemodel.createuserSetConversationLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnsetConversationLabel store method */
    userUnsetConversationLabel: storemodel.createuserUnsetConversationLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDetailedServer store method */
    userGetDetailedServer: storemodel.createuserGetDetailedServer({
        msg: cat.ServerMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationMediaCount store method */
    userGetConversationMediaCount: storemodel.createuserGetConversationMediaCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationMemberCount store method */
    userGetConversationMemberCount: storemodel.createuserGetConversationMemberCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userSetContactLabel store method */
    userSetContactLabel: storemodel.createuserSetContactLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnsetContactLabel store method */
    userUnsetContactLabel: storemodel.createuserUnsetContactLabel({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddContactAnnotation store method */
    userAddContactAnnotation: storemodel.createuserAddContactAnnotation({
        msg: cat.AnnotationMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveContactAnnotation store method */
    userRemoveContactAnnotation: storemodel.createuserRemoveContactAnnotation({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAccountConversationCounts store method */
    userGetAccountConversationCounts: storemodel.createuserGetAccountConversationCounts({
        msg: cat.FilterCountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAccountServerCounts store method */
    userGetAccountServerCounts: storemodel.createuserGetAccountServerCounts({
        msg: cat.FilterCountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetServerConversationCounts store method */
    userGetServerConversationCounts: storemodel.createuserGetServerConversationCounts({
        msg: cat.FilterCountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationTopicCounts store method */
    userGetConversationTopicCounts: storemodel.createuserGetConversationTopicCounts({
        msg: cat.FilterCountMsg.create(),
        isLoading: false
    }),
    /** Generated userSetUserProfileOwner store method */
    userSetUserProfileOwner: storemodel.createuserSetUserProfileOwner({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveUserProfileOwner store method */
    userRemoveUserProfileOwner: storemodel.createuserRemoveUserProfileOwner({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userLinkSupervisorUserToUser store method */
    userLinkSupervisorUserToUser: storemodel.createuserLinkSupervisorUserToUser({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userUnlinkSupervisorUserFromUser store method */
    userUnlinkSupervisorUserFromUser: storemodel.createuserUnlinkSupervisorUserFromUser({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDeviceBackupCount store method */
    userGetDeviceBackupCount: storemodel.createuserGetDeviceBackupCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveDeviceBackup store method */
    userRemoveDeviceBackup: storemodel.createuserRemoveDeviceBackup({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAPKCount store method */
    userGetAPKCount: storemodel.createuserGetAPKCount({
        msg: cat.QueryMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveAPK store method */
    userRemoveAPK: storemodel.createuserRemoveAPK({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userPatchAPK store method */
    userPatchAPK: storemodel.createuserPatchAPK({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDeviceLogFileId store method */
    userGetDeviceLogFileId: storemodel.createuserGetDeviceLogFileId({
        msg: cat.MediaMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAvailableDeviceLogFiles store method */
    userGetAvailableDeviceLogFiles: storemodel.createuserGetAvailableDeviceLogFiles({
        msg: cat.FilterMsg.create(),
        isLoading: false
    }),
    /** Generated userRemoveAccount store method */
    userRemoveAccount: storemodel.createuserRemoveAccount({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userStartDataCleaning store method */
    userStartDataCleaning: storemodel.createuserStartDataCleaning({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDataCleaningStatus store method */
    userGetDataCleaningStatus: storemodel.createuserGetDataCleaningStatus({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserProfilesExportFileId store method */
    userGetCurrentUserProfilesExportFileId: storemodel.createuserGetCurrentUserProfilesExportFileId({
        msg: cat.MediaMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationMemberExportFileId store method */
    userGetConversationMemberExportFileId: storemodel.createuserGetConversationMemberExportFileId({
        msg: cat.MediaMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDevicesExportFileId store method */
    userGetDevicesExportFileId: storemodel.createuserGetDevicesExportFileId({
        msg: cat.MediaMsg.create(),
        isLoading: false
    }),
    /** Generated userGetReportTemplates store method */
    userGetReportTemplates: storemodel.createuserGetReportTemplates({
        msg: cat.ReportTemplateMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserPermissions store method */
    userGetCurrentUserPermissions: storemodel.createuserGetCurrentUserPermissions({
        msg: cat.PermissionMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserProfiles store method */
    userGetCurrentUserProfiles: storemodel.createuserGetCurrentUserProfiles({
        msg: cat.ProfileMsg.create(),
        isLoading: false
    }),
    /** Generated userGetUserProfiles store method */
    userGetUserProfiles: storemodel.createuserGetUserProfiles({
        msg: cat.ProfileMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserCases store method */
    userGetCurrentUserCases: storemodel.createuserGetCurrentUserCases({
        msg: cat.CaseMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAvailableCaseProfiles store method */
    userGetAvailableCaseProfiles: storemodel.createuserGetAvailableCaseProfiles({
        msg: cat.ProfileMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserProfileReports store method */
    userGetCurrentUserProfileReports: storemodel.createuserGetCurrentUserProfileReports({
        msg: cat.ReportMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserProfileMedia store method */
    userGetCurrentUserProfileMedia: storemodel.createuserGetCurrentUserProfileMedia({
        msg: cat.AttachmentMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserDevices store method */
    userGetCurrentUserDevices: storemodel.createuserGetCurrentUserDevices({
        msg: cat.DeviceMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserNotifications store method */
    userGetCurrentUserNotifications: storemodel.createuserGetCurrentUserNotifications({
        msg: cat.NotificationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetContactMerges store method */
    userGetContactMerges: storemodel.createuserGetContactMerges({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCurrentUserThemes store method */
    userGetCurrentUserThemes: storemodel.createuserGetCurrentUserThemes({
        msg: cat.ThemeMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAccountMedia store method */
    userGetAccountMedia: storemodel.createuserGetAccountMedia({
        msg: cat.MediaFileMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileAccounts store method */
    userGetProfileAccounts: storemodel.createuserGetProfileAccounts({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileDevices store method */
    userGetProfileDevices: storemodel.createuserGetProfileDevices({
        msg: cat.DeviceMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileAccountsMediaCount store method */
    userGetProfileAccountsMediaCount: storemodel.createuserGetProfileAccountsMediaCount({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetRolesWithPermissions store method */
    userGetRolesWithPermissions: storemodel.createuserGetRolesWithPermissions({
        msg: cat.RoleMsg.create(),
        isLoading: false
    }),
    /** Generated userGetUsersAssignableToProfile store method */
    userGetUsersAssignableToProfile: storemodel.createuserGetUsersAssignableToProfile({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDomains store method */
    userGetDomains: storemodel.createuserGetDomains({
        msg: cat.DomainMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAvailableReportTemplates store method */
    userGetAvailableReportTemplates: storemodel.createuserGetAvailableReportTemplates({
        msg: cat.ReportTemplateMsg.create(),
        isLoading: false
    }),
    /** Generated userGetReportingLabels store method */
    userGetReportingLabels: storemodel.createuserGetReportingLabels({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userGetReportingAccounts store method */
    userGetReportingAccounts: storemodel.createuserGetReportingAccounts({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileConversations store method */
    userGetProfileConversations: storemodel.createuserGetProfileConversations({
        msg: cat.ConversationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetUsers store method */
    userGetUsers: storemodel.createuserGetUsers({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userGetRoles store method */
    userGetRoles: storemodel.createuserGetRoles({
        msg: cat.RoleMsg.create(),
        isLoading: false
    }),
    /** Generated userGetThemes store method */
    userGetThemes: storemodel.createuserGetThemes({
        msg: cat.ThemeMsg.create(),
        isLoading: false
    }),
    /** Generated userGetThemeUsers store method */
    userGetThemeUsers: storemodel.createuserGetThemeUsers({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userGetThemeUserProfileAccounts store method */
    userGetThemeUserProfileAccounts: storemodel.createuserGetThemeUserProfileAccounts({
        msg: cat.ProfileMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAppTypes store method */
    userGetAppTypes: storemodel.createuserGetAppTypes({
        msg: cat.AppTypeMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCases store method */
    userGetCases: storemodel.createuserGetCases({
        msg: cat.CaseMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDevices store method */
    userGetDevices: storemodel.createuserGetDevices({
        msg: cat.DeviceMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDeviceUsers store method */
    userGetDeviceUsers: storemodel.createuserGetDeviceUsers({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfiles store method */
    userGetProfiles: storemodel.createuserGetProfiles({
        msg: cat.ProfileMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileUsers store method */
    userGetProfileUsers: storemodel.createuserGetProfileUsers({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCaseProfiles store method */
    userGetCaseProfiles: storemodel.createuserGetCaseProfiles({
        msg: cat.ProfileMsg.create(),
        isLoading: false
    }),
    /** Generated userGetCaseLabels store method */
    userGetCaseLabels: storemodel.createuserGetCaseLabels({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileLabels store method */
    userGetProfileLabels: storemodel.createuserGetProfileLabels({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userGetProfileContacts store method */
    userGetProfileContacts: storemodel.createuserGetProfileContacts({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetContactConversations store method */
    userGetContactConversations: storemodel.createuserGetContactConversations({
        msg: cat.ConversationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDeviceAccounts store method */
    userGetDeviceAccounts: storemodel.createuserGetDeviceAccounts({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAccountContacts store method */
    userGetAccountContacts: storemodel.createuserGetAccountContacts({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationMessages store method */
    userGetConversationMessages: storemodel.createuserGetConversationMessages({
        msg: cat.MessageMsg.create(),
        isLoading: false
    }),
    /** Generated userGetMessageMessages store method */
    userGetMessageMessages: storemodel.createuserGetMessageMessages({
        msg: cat.MessageMsg.create(),
        isLoading: false
    }),
    /** Generated userSearchConversationMessages store method */
    userSearchConversationMessages: storemodel.createuserSearchConversationMessages({
        msg: cat.MessageMsg.create(),
        isLoading: false
    }),
    /** Generated userSearchTopicMessages store method */
    userSearchTopicMessages: storemodel.createuserSearchTopicMessages({
        msg: cat.MessageMsg.create(),
        isLoading: false
    }),
    /** Generated userGetTopicMessages store method */
    userGetTopicMessages: storemodel.createuserGetTopicMessages({
        msg: cat.MessageMsg.create(),
        isLoading: false
    }),
    /** Generated userGetMessageAttachments store method */
    userGetMessageAttachments: storemodel.createuserGetMessageAttachments({
        msg: cat.AttachmentMsg.create(),
        isLoading: false
    }),
    /** Generated userGetMessageReactions store method */
    userGetMessageReactions: storemodel.createuserGetMessageReactions({
        msg: cat.ReactionMsg.create(),
        isLoading: false
    }),
    /** Generated userGetGlobalLabels store method */
    userGetGlobalLabels: storemodel.createuserGetGlobalLabels({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userGetMessageLabels store method */
    userGetMessageLabels: storemodel.createuserGetMessageLabels({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userGetMessageAnnotations store method */
    userGetMessageAnnotations: storemodel.createuserGetMessageAnnotations({
        msg: cat.AnnotationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationAnnotations store method */
    userGetConversationAnnotations: storemodel.createuserGetConversationAnnotations({
        msg: cat.AnnotationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetTopicLabels store method */
    userGetTopicLabels: storemodel.createuserGetTopicLabels({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userGetTopicAnnotations store method */
    userGetTopicAnnotations: storemodel.createuserGetTopicAnnotations({
        msg: cat.AnnotationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetServerLabels store method */
    userGetServerLabels: storemodel.createuserGetServerLabels({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userGetServerAnnotations store method */
    userGetServerAnnotations: storemodel.createuserGetServerAnnotations({
        msg: cat.AnnotationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationLabels store method */
    userGetConversationLabels: storemodel.createuserGetConversationLabels({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAccountServers store method */
    userGetAccountServers: storemodel.createuserGetAccountServers({
        msg: cat.ServerMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAccountConversations store method */
    userGetAccountConversations: storemodel.createuserGetAccountConversations({
        msg: cat.ConversationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetServerConversations store method */
    userGetServerConversations: storemodel.createuserGetServerConversations({
        msg: cat.ConversationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationTopics store method */
    userGetConversationTopics: storemodel.createuserGetConversationTopics({
        msg: cat.TopicMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationMembers store method */
    userGetConversationMembers: storemodel.createuserGetConversationMembers({
        msg: cat.AccountMsg.create(),
        isLoading: false
    }),
    /** Generated userGetConversationMedia store method */
    userGetConversationMedia: storemodel.createuserGetConversationMedia({
        msg: cat.MediaFileMsg.create(),
        isLoading: false
    }),
    /** Generated userGetContactLabels store method */
    userGetContactLabels: storemodel.createuserGetContactLabels({
        msg: cat.LabelMsg.create(),
        isLoading: false
    }),
    /** Generated userGetContactAnnotations store method */
    userGetContactAnnotations: storemodel.createuserGetContactAnnotations({
        msg: cat.AnnotationMsg.create(),
        isLoading: false
    }),
    /** Generated userSearchAccountsAndContactsSystemWide store method */
    userSearchAccountsAndContactsSystemWide: storemodel.createuserSearchAccountsAndContactsSystemWide({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userSearchConversationsSystemWide store method */
    userSearchConversationsSystemWide: storemodel.createuserSearchConversationsSystemWide({
        msg: cat.ConversationMsg.create(),
        isLoading: false
    }),
    /** Generated userGetSupervisorUsers store method */
    userGetSupervisorUsers: storemodel.createuserGetSupervisorUsers({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userGetLinkableToSupervisorUsers store method */
    userGetLinkableToSupervisorUsers: storemodel.createuserGetLinkableToSupervisorUsers({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userGetLinkableToDeviceUsers store method */
    userGetLinkableToDeviceUsers: storemodel.createuserGetLinkableToDeviceUsers({
        msg: cat.UserMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDeviceBackups store method */
    userGetDeviceBackups: storemodel.createuserGetDeviceBackups({
        msg: cat.BackUpMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAPKS store method */
    userGetAPKS: storemodel.createuserGetAPKS({
        msg: cat.APKMsg.create(),
        isLoading: false
    }),
    /** Generated userGetAppReceiverMedia store method */
    userGetAppReceiverMedia: storemodel.createuserGetAppReceiverMedia({
        msg: cat.MediaFileMsg.create(),
        isLoading: false
    }),
    /** Generated userGetDeviceReceiverMediaCount store method */
    userGetDeviceReceiverMediaCount: storemodel.createuserGetDeviceReceiverMediaCount({
        msg: cat.AppMsg.create(),
        isLoading: false
    }),
    /** Generated userSetCurrentUserProfileMediaStream store method */
    userSetCurrentUserProfileMediaStream: storemodel.createuserSetCurrentUserProfileMediaStream({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userSetCurrentUserNotificationsRead store method */
    userSetCurrentUserNotificationsRead: storemodel.createuserSetCurrentUserNotificationsRead({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userMergeContacts store method */
    userMergeContacts: storemodel.createuserMergeContacts({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userAddReportTemplateFiles store method */
    userAddReportTemplateFiles: storemodel.createuserAddReportTemplateFiles({
        msg: cat.ReportTemplateMsg.create(),
        isLoading: false
    }),
    /** Generated userAddReportTemplateFile store method */
    userAddReportTemplateFile: storemodel.createuserAddReportTemplateFile({
        msg: cat.ReportTemplateMsg.create(),
        isLoading: false
    }),
    /** Generated userAddAnnotationAttachment store method */
    userAddAnnotationAttachment: storemodel.createuserAddAnnotationAttachment({
        msg: cat.AttachmentMsg.create(),
        isLoading: false
    }),
    /** Generated userAddAPK store method */
    userAddAPK: storemodel.createuserAddAPK({
        msg: cat.APKMsg.create(),
        isLoading: false
    }),
    /** Generated userCancelReport store method */
    userCancelReport: storemodel.createuserCancelReport({
        msg: cat.VoidMsg.create(),
        isLoading: false
    }),
    /** Generated userGetReportPreviewFileId store method */
    userGetReportPreviewFileId: storemodel.createuserGetReportPreviewFileId({
        msg: cat.MediaMsg.create(),
        isLoading: false
    }),
    /** Generated userGetReportTemplatePreviewFileId store method */
    userGetReportTemplatePreviewFileId: storemodel.createuserGetReportTemplatePreviewFileId({
        msg: cat.MediaMsg.create(),
        isLoading: false
    }),
    // Other
    SelectedConversation: cat.ConversationMsg.create(),
    SelectedConversations: [],
    SelectedContacts: [],
    ReplyToMsg: cat.MessageMsg.create(),
    CaseLabels: {},
    FileStreams: {},
    Me: storemodel.createMe({ msg: cat.UserMsg.create() }),
    Terminology: {},
    UserSessions: []
};