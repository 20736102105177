<h4 mat-dialog-title class="cat-no-margin" color="dark">
    <b>{{ "Device properties" | translate }}</b>
</h4>

<div mat-dialog-content class="cat-block cat-margin-y-xl">

    <div class="cat-height-300 cat-overflow-y">
        <ul class="cat-no-padding">
            <li>
                <span class="title">{{ "Last updated" | translate }}</span>
                <span class="value" color="primary">
                    {{ (device.properties.timestamp) ? (device.properties.timestamp | date: 'd MMM y, HH:mm:ss' : undefined : tl.locale) : "" | emptyValue }}
                </span>
            </li>
            <li>
                <span class="title">{{ uitext.imei | translate }}</span>
                <span class="value" color="primary">
                    {{ device.properties.imei | emptyValue }}
                </span>
            </li>
            <li>
                <span class="title">{{ "Brand" | translate }}</span>
                <span class="value" color="primary">
                    {{ device.properties.brand | emptyValue }}
                </span>
            </li>
            <li>
                <span class="title">{{ uitext.name | translate }}</span>
                <span class="value" color="primary">
                    {{ device.properties.name | emptyValue }}
                </span>
            </li>
            <li>
                <span class="title">{{ "Model" | translate }}</span>
                <span class="value" color="primary">
                    {{ device.properties.model | emptyValue }}
                </span>
            </li>
            <li>
                <span class="title">{{ "ABI(s)" | translate }}</span>
                <span class="value" color="primary">
                    {{ device.properties.abis | emptyValue }}
                </span>
            </li>
            <li>
                <span class="title">{{ "Total storage" | translate }}</span>
                <span class="value" color="primary">
                    {{ (device.properties.storage | filesize) | emptyValue }}
                </span>
            </li>
            <li>
                <span class="title">{{ "Total capture app" | translate }}</span>
                <span class="value" color="primary">
                    {{ (device.properties.usage | filesize) | emptyValue }}
                </span>
            </li>
            <li>
                <span class="title">{{ "Total free space" | translate }}</span>
                <span class="value" color="primary">
                    {{ (device.properties.freespace | filesize) | emptyValue }}
                </span>
            </li>
            <li>
                <span class="title">{{ "Battery is charging" | translate }}</span>
                <span class="value" color="primary">
                    <ng-container *ngIf="device.properties.batterycharging else notCharging">
                        {{ uitext.true | translate }}
                    </ng-container>
                    <ng-template #notCharging>
                        {{ uitext.false | translate }}
                    </ng-template>
                </span>
            </li>
            <li>
                <span class="title">{{ "Battery level" | translate }}</span>
                <span class="value" color="primary">
                    {{ device.properties.batterylevel | emptyValue }}
                </span>
            </li>
            <li>
                <span class="title">{{ "OS version" | translate }}</span>
                <span class="value" color="primary">
                    {{ device.properties.osversion | emptyValue }}
                </span>
            </li>
            <li *ngIf="!device.properties?.name">
                <span class="title">{{ uitext.notfound | translate }}</span>
            </li>
        </ul>
    </div>
</div>

<div mat-dialog-actions align="end" class="cat-padding-bottom-lg cat-padding-x-lg">
    <button mat-stroked-button mat-dialog-close cdkFocusInitial class="cat-padding-lg" (click)="closeDialog()">
        {{ uitext.close | translate }}
    </button>
</div>
