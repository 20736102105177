@if (mediaHeaders.includes(attachment.type)) {
    <div
        class="cat-background-light-shade cat-padding-md cat-border-radius-md"
        [class.cat-background-light-blue-shade]="!(msg.direction | isIncoming)">

        @if (attachmentTypeDef[attachmentType[attachment.type]]; as typeDef) {
            <div class="cat-flex cat-align-center">
                <mat-icon class="cat-margin-right-sm" color="dark">{{ typeDef.icon?.matname }}</mat-icon>
                <h6 class="cat-no-margin cat-no-padding cat-font-weight-normal cat-font-weight-bold" color="dark">{{ typeDef.label | translate }}</h6>
            </div>
        }

        @if ((attachment | hasFile) || (attachment | hasThumb)) {
            <div class="cat-margin-y-lg">
                <ng-container *ngTemplateOutlet="mediaThumb"></ng-container>
            </div>
        }

        @if ((attachment | hasFile) || (attachment.content | keys)?.length > 0) {
            <div class="cat-flex-column cat-margin-top-lg">
                @if (attachment | hasFile) {
                    <div class="cat-no-padding cat-margin-bottom-xs">
                        <span class="cat-font-weight-normal cat-text-md">{{ uitext.filename | translate }}</span>: <span class="cat-text-md">{{ attachment.files[0].filename || '' | emptyValue }}</span>
                    </div>
                    <div class="cat-no-padding cat-margin-bottom-xs">
                        <span class="cat-font-weight-normal cat-text-md">{{ uitext.filesize | translate }}</span>: <span class="cat-text-md">{{ attachment.files[0].filesize | filesize }}</span>
                    </div>
                }

                @for (keyvalue of attachment.content | keys; track keyvalue) {
                    @if (keyvalue.value) {
                        <div class="cat-no-padding cat-margin-bottom-xs">
                            <span class="cat-font-weight-normal cat-text-md">{{ keyvalue.key | capitalizeFirstChar }}</span>: <span class="cat-text-md">{{ keyvalue.value }}</span>
                        </div>
                    }
                }
            </div>
        }

    </div>
} @else {
    <ng-container *ngTemplateOutlet="mediaThumb"></ng-container>
}

<ng-template #mediaThumb>
    @if (attachment.files?.[0]; as attachmentFile) {
        @if (attachmentFile.mimetype | includesMimetype : ['image/', 'video/']) {

            @if ((attachmentFile.mimetype | includesMimetype : ["image/gif", "image/webp", "image/apng"]) && (attachment | hasFile)) {
                <div
                    (click)="emitMedia(attachment, msg); $event.stopPropagation();"
                    [class.cat-outgoing]="!(msg.direction | isIncoming)"
                    class="cat-thumbnail-container cat-overflow-hidden cat-cursor-pointer cat-max-width-200">
                    <img [src]="attachmentFile | catsrc : 'media' : mediaType.MEDIA_CONVERSATIONS : account.id">
                </div>
            } @else if (attachment | hasThumb) {
                <div
                    (click)="emitMedia(attachment, msg); $event.stopPropagation();"
                    [class.cat-outgoing]="!(msg.direction | isIncoming)"
                    [class.cat-play-button]="attachmentFile.mimetype | includesMimetype : ['video/']"
                    class="cat-thumbnail-container cat-overflow-hidden cat-cursor-pointer">
                    <img [src]="attachmentFile | catsrc : 'thumb' : mediaType.MEDIA_CONVERSATIONS : account.id">
                </div>
            } @else {
                <ng-container *ngTemplateOutlet="noThumb"></ng-container>
            }

        } @else if (attachmentFile.mimetype | includesMimetype : ['audio/']) {

            <audio controls autobuffer #audioPlayer>
                <source
                    [src]="attachmentFile | catsrc : 'media' : mediaType.MEDIA_CONVERSATIONS : account.id"
                    type="audio/wav" />
                <source
                    [src]="attachmentFile | catsrc : 'media' : mediaType.MEDIA_CONVERSATIONS : account.id"
                    type="audio/opus" />
                <source
                    [src]="attachmentFile | catsrc : 'media' : mediaType.MEDIA_CONVERSATIONS : account.id"
                    type="audio/ogg" />
                <source
                    [src]="attachmentFile | catsrc : 'media' : mediaType.MEDIA_CONVERSATIONS : account.id"
                    type="audio/mpeg" />
                {{ "Your browser does not support the audio element, please download it instead." | translate }}
            </audio>

        } @else if (attachmentFile.mimetype | includesMimetype : ['application/x-tgsticker', 'application/x-lottie']) {

            @if (attachment | hasFile) {
                <lottie-container
                    [file]="attachment.files[0]"
                    [mediatype]="mediaType.MEDIA_CONVERSATIONS"
                    [refId]="account.id">
                </lottie-container>
            } @else {
                <ng-container *ngTemplateOutlet="noThumb"></ng-container>
            }

        } @else if (attachment | hasThumb) {
            <div
                (click)="emitMedia(attachment, msg); $event.stopPropagation();"
                [class.cat-outgoing]="!(msg.direction | isIncoming)"
                [class.cat-play-button]="attachmentFile.mimetype | includesMimetype : ['video/']"
                class="cat-thumbnail-container cat-overflow-hidden cat-cursor-pointer">
                <img [src]="attachmentFile | catsrc : 'thumb' : mediaType.MEDIA_CONVERSATIONS : account.id">
            </div>
        } @else {
            <ng-container *ngTemplateOutlet="noThumb"></ng-container>
        }
    } @else if (attachment.type) {
        <ng-container *ngTemplateOutlet="noThumb"></ng-container>
    }
</ng-template>

<ng-template #noThumb>
    <div
        (click)="emitMedia(attachment, msg); $event.stopPropagation();"
        [class.cat-cursor-pointer]="attachment.files[0]?.fileid"
        [class.cat-outgoing]="!(msg.direction | isIncoming)"
        class="cat-empty-attachment cat-overflow-hidden cat-cursor-pointer cat-border-radius-md">

        @if (attachmentStatusDef[attachmentStatus[attachment.status]]) {
            <img
                (click)="emitMedia(attachment, msg); $event.stopPropagation();"
                src="assets/img/cat-avatar.svg"
                matTooltip="{{ attachmentStatusDef[attachmentStatus[attachment.status]].desc | translate }} {{ (attachment.files[0]?.fileid ? '(click to view)' : undefined) | translate }}" matTooltipPosition="left">
        }

        @if (attachment?.files?.length && attachment.files[0].thumbnailstatus != thumbStatus.THUMBNAILSTATUS_COMPLETE &&
            thumbStatusDef[thumbStatus[attachment.files[0]?.thumbnailstatus]]) {
                <img
                    (click)="emitMedia(attachment, msg); $event.stopPropagation();"
                    src="assets/img/cat-avatar.svg"
                    matTooltip="{{ thumbStatusDef[thumbStatus[attachment.files[0].thumbnailstatus]].label | translate }} {{ (attachment.files[0].fileid ? '(click to view)' : undefined) | translate }}" matTooltipPosition="left">
        }
    </div>
</ng-template>