import { Component, ChangeDetectionStrategy, OnInit, Input, OnDestroy } from "@angular/core";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { FormControl } from "@angular/forms";
import { IFormFieldData } from "@shared/app-models";

@Component({
    selector: "password-field",
    templateUrl: "./password-field.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PasswordFieldComponent implements OnInit, OnDestroy {
    // Inputs
    @Input() data: IFormFieldData;
    @Input() submitted: boolean;
    @Input() control: FormControl;
    @Input() focus: boolean = false;
    @Input() color: "white" | "medium" = "white";

    public hidePassword = true;

    constructor(
        public common: CommonService,
        private _logger: LoggerService) {}


    ngOnInit() {
        // this._logger.debug("INIT PASSWORD FIELD");
    }

    ngOnDestroy(): void {
        // this._logger.debug("DESTROY PASSWORD FIELD");
    }
}