import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";
import { protosui } from "@definitions/definitions";
import { IGroupbyData } from "@shared/app-models";

/**
 * Group sets of data
 */
@Pipe({
    name: "groupby",
    pure: true
})
export class GroupbyPipe implements PipeTransform {

    constructor(
        private _logger: LoggerService
    ) {}

    transform(collection: any[], groupBy: Array<string>, messageType: string, groupList?: Array<string>) {

        if (!collection?.length || !groupBy?.length) {
            // this._logger.debug("Prerequisites not found");
            return collection;
        }
        // Create a value array, if the collection is a keyvalue pair
        if (collection[0].key && collection[0].value) {
            collection = collection.map(item => item["value"]);
        }

        let group = {};
        // Safely get a nested groupBy, e.g. UserMsg.role.name
        const get: any = (p: any, o: any) => p.reduce((xs: any, x: any) => (xs && xs[x]) ? xs[x] : null, o);

        if (typeof collection[0] === "string") {

            group = collection.reduce((objectsByKeyValue: any, value: any) => {

                const definition = protosui.def[messageType][value];
                const val = get(groupBy, definition); // E.g. 1, or Supervisor

                objectsByKeyValue[val] = (objectsByKeyValue[val] || []).concat(value);
                return objectsByKeyValue;
            }, {});

        } else {

            group = collection.reduce((objectsByKeyValue: any, obj: any) => {
                let value = get(groupBy, obj); // E.g. 1, or Supervisor
                objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

                return objectsByKeyValue;
            }, {});
        }

        let result: Array<IGroupbyData>;

        // Check if another (nested) list selector is provided
        if (groupList && groupList.length) {
            // Loop over the group keys (e.g. Instagram, Gmail) and add these as keys to the object
            // Reduce the groupList (e.g. ["contacts", "accounts"]) and select, attach and flatten the correct list
            try {
                result = Object.keys(group).map(key => ({
                    key,
                    value: group[key]
                        .map((groupkey: any) => groupList
                        .reduce((object, index) => object[index], groupkey))
                        .filter((item: any) => item !== undefined)
                        .flat()
                }));
            } catch (error) {
                this._logger.error("Error in getting the grouplist");
            }

        } else {
            // Loop over the group keys (e.g. Instagram, Gmail) and add the corresponding list
            result = Object.keys(group).map(key => ({ key, value: group[key] }));
        }
        return result;
    }
}
