import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";

/**
 * Get the message attachment type
 *
 * @export
 * @class GetMessageAttachmentTypePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: "getMsgAttachmentType",
  pure: true
})


export class GetMessageAttachmentTypePipe implements PipeTransform {
    transform(msg: cat.MessageMsg): string {
        let result: string = protosui.def.AttachmentType.ATTACHMENT_UNKNOWN.label;
        if (msg && msg.attachments && msg.attachments.length) {
            const type = cat.AttachmentType[msg.attachments[0].type];
            if (protosui.def.AttachmentType[type]) {
                result = protosui.def.AttachmentType[type].label;
            }
        }
        return result;
    }
}
