import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy, inject, Output, EventEmitter } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { IHeaderButton, IImageDetailPageData, IState } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { protosui } from "@definitions/definitions";
import { WsService } from "@services/ws/ws.service";
import { userGetDetailedAccount, userGetDetailedConversation } from "@assets/proto/services";
import { SubscriptionManager } from "@shared/subscription-manager";
import { MediaDetailsDialog } from "@modals/detail-pages/media-details/media-details.dialog";

@Component({
    selector: "modal-header",
    templateUrl: "./modal-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ModalHeaderComponent extends SubscriptionManager implements OnInit, OnDestroy {

    // Inputs
    @Input() title = "";
    @Input() appId = "";
    @Input() apptypeId = "";
    @Input() accountId = "";
    @Input() contactId = "";
    @Input() messageId = "";
    @Input() conversationId = "";
    @Input() serverId = "";
    @Input() topicId = "";
    @Input() isLoading = false;
    @Input() keepOverlays = false;
    @Input() disableOpenMedia = false;
    @Input() buttons: IHeaderButton[] = [];
    // Outputs
    @Output() emitAction: EventEmitter<IHeaderButton> = new EventEmitter<IHeaderButton>();

    conversation$: Observable<cat.ConversationMsg> = inject(Store).select((state: IState) => state.cat.userGetDetailedConversation.msg);
    server$: Observable<cat.ServerMsg> = inject(Store).select((state: IState) => state.cat.userGetDetailedServer.msg);
    topic$: Observable<cat.TopicMsg> = inject(Store).select((state: IState) => state.cat.userGetDetailedTopic.msg);
    accounts$: Observable<Map<string, cat.AccountMsg>> = inject(Store).select((state: IState) => state.cat.userGetProfileAccounts.list);
    apps$: Observable<Map<string, cat.AppMsg>> = inject(Store).select((state: IState) => state.cat.userGetDeviceReceiverMediaCount.list);

    public mediaType = cat.MediaType;
    public attachmentType = cat.AttachmentType;
    public uitext = protosui.messages.uitext;
    public conversationType = cat.ConversationType;

    constructor(
        public common: CommonService,
        private _dialog: MatDialog,
        private _store: Store,
        private _ws: WsService,
        private _logger: LoggerService
    ) {
        super();
    }

    async ngOnInit() {
        // this._logger.warn("Account ID: ", this.accountId);
        // this._logger.warn("Contact ID: ", this.contactId);
        // this._logger.warn("Conversation ID: ", this.conversationId);
        // this._logger.warn("Store: ", this._store.selectSnapshot((state: IState) => state.cat.userGetProfileAccounts));

        if (this.accountId) {
            await this.userGetDetailedAccount();
        }

        if (this.conversationId) {
            await this.userGetDetailedConversation();
        }
    }

    get account$(): Observable<cat.IAccountMsg> {
        return this._store.select((state: IState) => state.cat.userGetDetailedAccount.msg);
    }

    get contact$(): Observable<cat.IAccountMsg> {
        return this._store.select((state: IState) => state.cat.userGetProfileContact.msg);
    }


    /**
     * Dismiss the modal.
     *
     * @memberof ModalHeaderComponent
     */
    async dismissPage() {
        try {
            const topDialog = this._dialog.openDialogs[this._dialog.openDialogs.length - 1];
            if (topDialog) {
                topDialog.close();
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Get the correct account.
     *
     * @private
     * @memberof ModalHeaderComponent
     */
    private async userGetDetailedAccount() {
        try {
            if (!this.accountId) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }
            const payload: cat.AccountMsg = cat.AccountMsg.create({ id: this.accountId });
            await this._ws.sendRequest(userGetDetailedAccount, payload);
        } catch (error) {
            this.common.createSnackbar(protosui.messages.uitext.callfail);
            this._logger.error(error);
        }
    }

    /**
     * Get the full detailed conversation.
     *
     * @private
     * @memberof ModalHeaderComponent
     */
    private async userGetDetailedConversation() {
        try {
            const payload = cat.ConversationMsg.create({ id: this.conversationId });
            await this._ws.sendRequest(userGetDetailedConversation, payload);
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
     * View media.
     *
     * @param {(cat.ConversationMsg | cat.AccountMsg)} item
     * @memberof ModalHeaderComponent
     */
    async openMedia(item: cat.ConversationMsg | cat.AccountMsg | cat.ServerMsg | cat.TopicMsg, isConversation = false, isContact = false, isServer = false, isTopic = false) {

        if (this.disableOpenMedia) {
            return;
        }

        try {

            if (!item?.id || !item.avatar?.id || !this.accountId) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            const attachment: cat.AttachmentMsg = cat.AttachmentMsg.create(item.avatar);

            const dialogData: IImageDetailPageData = {
                attachment: attachment,
                refId: this.accountId,
                accountId: this.accountId,
                mediatype: cat.MediaType.MEDIA_ACCOUNTAVATARS,
                asProfile: false,
                conversationId: (isConversation) ? this.conversationId : "",
                contactId: (isContact) ? this.contactId : "",
                serverId: (isServer) ? this.serverId : "",
                topicId: (isTopic) ? this.topicId : ""

            };
            this._logger.debug("Dialog Data: ", dialogData);

            this._dialog.open(MediaDetailsDialog, {
                width: "95vw",
                height: "95vh",
                data: dialogData
            });

        } catch (error) {
            this._logger.error(error);
        }
    }
}
