<div (click)="selectMedia(mediafile)" class="cat-no-padding cat-no-margin cat-no-background cat-grid-item cat-full-width-height cat-center cat-cursor-pointer">

    <div class="cat-thumbnail cat-center" [class.cat-play-button]="(mediafile.mimetype | includesMimetype : ['video/', 'image/gif', 'image/webp']) &&
        mediafile.thumbfileid && (mediafile.thumbnailstatus === thumbStatusEnum.THUMBNAILSTATUS_COMPLETE)">

        @if (mediafile.mimetype | includesMimetype : ['image/', 'video/']) {
            <img
                *ngIf="mediafile.thumbfileid && (mediafile.thumbnailstatus === thumbStatusEnum.THUMBNAILSTATUS_COMPLETE) else noThumb"
                [src]="mediafile | catsrc : 'thumb' : mediatype : refId">
        } @else if (mediafile.mimetype | includesMimetype : ['audio']) {
            <img matTooltip="{{ 'No thumbnail' | translate }}" matTooltipPosition="left" src="assets/img/audio-thumb.png">
        } @else if (mediafile.mimetype | includesMimetype : ['application/x-tgsticker', 'application/x-lottie']) {
            <lottie-container
                [file]="mediafile"
                [mediatype]="mediatype"
                [refId]="refId">
            </lottie-container>
        } @else {
            <img [src]="mediafile | catsrc : 'thumb' : mediatype : refId">
        }
    </div>

    <div class="cat-background-light-tint cat-thumbnail-data">
        <div class="cat-padding-x-sm cat-margin-top-sm">

            <mat-icon
                *ngIf="mediafile.linkstatus === linkStatusEnum.RECEIVERMEDIALINKSTATUS_LINKED"
                matTooltip="{{ 'Media is linked as attachment, avatar or emoji.' | translate }}"
                matTooltipPosition="below"
                color="dark" class="cat-float-start cat-text-md cat-margin-top-xs cat-no-padding">link
            </mat-icon>
            <mat-icon
                *ngIf="mediafile.linkstatus === linkStatusEnum.RECEIVERMEDIALINKSTATUS_UNLINKED"
                matTooltip="{{ 'Media is not (yet) linked as attachment, avatar or emoji.' | translate }}"
                matTooltipPosition="below"
                color="dark" class="cat-float-start cat-text-md cat-margin-top-xs">link_off
            </mat-icon>

            @if (mediafile.mimetype | includesMimetype : ['image/']) {
                <b>{{ "Image" | translate }} </b>
            } @else if (mediafile.mimetype | includesMimetype : ['video/']) {
                <b>{{ "Video" | translate }} </b>
            } @else if (mediafile.mimetype | includesMimetype : ['audio/']) {
                <b>{{ "Audio" | translate }} </b>
            } @else if (mediafile.mimetype | includesMimetype : ['application/x-tgsticker', 'application/x-lottie']) {
                <b>{{ "Sticker" | translate }} </b>
            } @else {
                <b>{{ "Other" | translate }} </b>
            }
            <span class="cat-text-sm">
                {{ mediafile.filesize | filesize }}
            </span>

        </div>
        <div class="cat-item cat-margin-left-sm" matTooltip="{{ mediafile.filename }}" matTooltipPosition="below">
            <span class="cat-filename cat-padding-right-lg cat-text-md">
                {{ mediafile.filename }}
            </span>
        </div>
    </div>
</div>


<ng-template #noThumb>
    <span class="cat-text-sm">
        {{ "no thumbnail" | translate }}
    </span>
</ng-template>