<div class="cat-button-row cat-margin-bottom-sm">
    <div class="cat-button-row-item">
        <mat-form-field appearance="fill" subscriptSizing="dynamic" class="cat-margin-right-md">
            <mat-label>{{ "Filter by app" | translate }}</mat-label>
            <mat-select multiple [(ngModel)]="selectedApps" (selectionChange)="refreshStatistics()" [disabled]="(isLoading$|async)">
                @for (app of (apptypes$|async) | filter: { type: 'enumerator', value: appFilter, path: ['AppType'], sel: 'type' }; track app) {
                    <mat-option value="{{ app.packagename }}" class="cat-padding-top-md cat-padding-bottom-md" [disabled]="(isLoading$|async)">
                        <mat-icon><img [src]="app.iconfileid | iconmedia"></mat-icon>{{ app.name }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    <div class="cat-button-row-item">
        <mat-form-field appearance="fill" class="cat-flex-right">
            <mat-label>{{ "Interval" | translate }}</mat-label>
            <mat-select [formControl]="chartSelect" (selectionChange)="selectInterval($event)" [disabled]="(isLoading$|async)">
                <mat-option *ngFor="let option of intervals" [value]="option.value">
                    {{ option.viewValue | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>


<div class="cat-full-height cat-overflow-y">
    <div class="grid-container">

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">

            <div class="cat-background-white cat-border-radius-sm cat-nooverflow-ellipsis">
                <div class="cat-tile-container">
                    <header class="cat-no-border">
                        <h5><b>{{ "Global captured items (successful)" | translate }}</b></h5>
                    </header>
                    <div class="cat-tile-content">
                        @if (isLoading$|async) {
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        } @else {
                            <ngx-charts-bar-vertical-2d
                                [animations]="true"
                                [results]="(globalSuccessStats$ | async)"
                                [scheme]="colorScheme"
                                [showGridLines]="true"
                                [noBarWhenZero]="true"
                                [legend]="true"
                                [barPadding]="1"
                                [xAxis]="true"
                                [showXAxisLabel]="false"
                                [yAxis]="true"
                                [showYAxisLabel]="true"
                                yAxisLabel="{{ yAxisLabel | translate }}"
                                [yAxisTickFormatting]="getMathPower">
                                <ng-template #tooltipTemplate let-model="model">
                                    <span class="tooltip-label">{{ model.series }} • {{ model.name }}</span>
                                    <span class="tooltip-val">{{ getMathPower(model.value) }} {{ "items" | translate }}</span>
                                    </ng-template>
                                    <ng-template #seriesTooltipTemplate let-model="model">
                                    <span class="tooltip-label" *ngFor="let series of model">
                                        {{ series.series }}: {{ getMathPower(series.value) }}
                                    </span>
                                    </ng-template>
                            </ngx-charts-bar-vertical-2d>
                        }
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

