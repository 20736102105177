import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";

@Pipe({
    name: "unique",
    pure: true
})
/**
 * Deduplicate array based on cursor.
 *
 * @export
 * @class UniquePipe
 * @implements {PipeTransform}
 */
export class UniquePipe implements PipeTransform {

    constructor(
        private _common: CommonService,
        private _logger: LoggerService
    ) {}

    transform(values: any[], cursor: string | string[]): any[] {
        let result = values;
        if (typeof cursor === "string") {
            cursor = [cursor];
        }
        // Create unique list based on cursor.
        if (values?.length && cursor?.length) {
            const arr = values.map(value => this._common.getProp(value, cursor as string[]));
            result = [...new Set(arr)];
        }
        return result;
    }
}
