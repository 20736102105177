import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, OnInit, Input, inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { cat } from "@assets/proto/msgs";
import { IServiceDescriptor, userGetCaseLabels, userGetGlobalLabels, userGetProfileLabels, userAddGlobalLabel, userAddProfileLabel, userAddCaseLabel } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { HasIdPipe } from "@pipes/hasid/hasid.pipe";
import { HasPermissionPipe } from "@pipes/haspermission/haspermission.pipe";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { WsService } from "@services/ws/ws.service";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { getList } from "@store/store";
import { Observable } from "rxjs";
import { IState } from "@shared/app-models";
import { addListItem, removeListItem } from "@store/actions";


/** Decorator that marks a class as an Angular component */
@Component({
    selector: "labels",
    templateUrl: "./labels.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelsComponent implements OnInit {

    globalLabels$: Observable<cat.LabelMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetGlobalLabels));
    profileLabels$: Observable<cat.LabelMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetProfileLabels));

    @Input() _refId = "";
    @Input() routeId = "";
    @Input() _get: IServiceDescriptor;
    @Input() _add: IServiceDescriptor;
    @Input() _remove: IServiceDescriptor;
    @Input() _profile: cat.ProfileMsg = cat.ProfileMsg.create();
    @Input() asProfile = false;

    public caseLabels$: Observable<cat.LabelMsg[]>;
    readonly currentLabels$: Observable<cat.LabelMsg[]>;

    public newGlobalLabel = "";
    public newProfileLabel = "";
    public newCaseLabel = "";

    public showSelect = true;
    public labelForm: FormGroup;
    public uitext = protosui.messages.uitext;
    public profileLabelText: any = {
        header: protosui.messages.uitext.selectprofilelabel,
        translucent: true
    };
    public permissionsEnum = cat.Permission;

    // Private properties
    private _currentProfile = cat.ProfileMsg.create();
    private _currentCaseId = "";

    constructor(
        public common: CommonService,
        private _store: Store,
        private _logger: LoggerService,
        private _ws: WsService
    ) {
        this.labelForm = new FormGroup({ profilelabel: new FormControl(), caselabel: new FormControl() });
        this.currentLabels$ = this._store.select((state: IState) => getList(state.cat[this._get.methodName]));
    }

    /**
     * Get all the relevant labels
     *
     * @memberof LabelsComponent
     */
    ngOnInit() {
        // Only fetch profile labels in the 'profile' scope
        if (this.asProfile) {
            this.userGetProfileLabels();

            // Get case labels, if profile is part of a case
            this._currentProfile = this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserProfiles).list.get(this.routeId);
            this._currentCaseId = this._currentProfile?.cases[0]?.id;
            if (this._currentCaseId) {
                this.caseLabels$ = this._store.select((state: IState) => getList(state.cat.userGetCaseLabels));
                this.userGetCaseLabels(this._currentCaseId);
            }
        }
        // Always fetch the case and added labels
        this.userGetGlobalLabels();
        this.getLabels();
    }

    /**
     * Get the current labels depending on the view
     *
     * @private
     * @memberof LabelsComponent
     */
    private async userGetCaseLabels(caseId: string) {
        try {
            if (new HasPermissionPipe(this._logger, this._store).transform([
                cat.Permission.PERMISSION_MANAGE_CASE_LABELS,
                cat.Permission.PERMISSION_SET_LABELS,
                cat.Permission.PERMISSION_VIEW_LABELS])) {
                const payload = cat.CaseMsg.create({ id: caseId });
                await this._ws.sendRequest(userGetCaseLabels, payload);
            }
        } catch (error) {
            await this.common.createSnackbar(error);
            this._logger.error(new Error(error));
        }
    }

    /**
     * Get the current labels depending on the view
     *
     * @private
     * @memberof LabelsComponent
     */
     private async getLabels() {
        try {
            const payload = cat[this._get.requestType].create({ id: this._refId });
            await this._ws.sendRequest(this._get, payload);
        } catch (error) {
            await this.common.createSnackbar(error);
            this._logger.error(new Error(error));
        }
    }

    /**
     * Get the global labels.
     *
     * @private
     * @memberof LabelsComponent
     */
    private async userGetGlobalLabels() {
        try {
            if (new HasPermissionPipe(this._logger, this._store).transform([
                cat.Permission.PERMISSION_VIEW_LABELS,
                cat.Permission.PERMISSION_SET_LABELS,
                cat.Permission.PERMISSION_MANAGE_GLOBAL_LABELS])) {
                await this._ws.sendRequest(userGetGlobalLabels);
            }
        } catch (error) {
            this._logger.error(new Error(error));
        }
    }

    /**
     * Get the labels of the case.
     *
     * @private
     * @memberof LabelsComponent
     */
    private async userGetProfileLabels() {
        try {
            if (this.asProfile && this.routeId) {
                const payload = cat.ProfileMsg.create({ id: this.routeId });
                await this._ws.sendRequest(userGetProfileLabels, payload);
            }
        } catch (error) {
            this._logger.error(new Error(error));
        }
    }

    /**
     * Remove / add a label to the datastore and local store
     *
     * @param {cat.LabelMsg} label The label to add / remove
     * @memberof LabelsComponent
     */
    public async toggleLabel(label: cat.LabelMsg) {
        try {

            if (!label?.id) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            if (!new HasPermissionPipe(this._logger, this._store).transform([cat.Permission.PERMISSION_SET_LABELS])) {
                await this.common.createSnackbar(protosui.messages.uitext.nopermission);
                return;
            }

            const currentLabels = getList(this._store.selectSnapshot((state: IState) => state.cat[this._get.methodName]));
            const isSelected: boolean = new HasIdPipe(this._logger).transform(label, currentLabels);

            if (isSelected && this._get && this._get.requestType && this._remove) {

                const payload = cat[this._get.requestType].create({
                    id: this._refId,
                    labels: [label]
                });
                await this._ws.sendRequest(this._remove, payload);
                this._store.dispatch(new removeListItem(label, this._get.methodName));
                await this.common.createSnackbar(protosui.messages.uitext.successfullyremoved, "bottom", 1000);

            } else if (this._add && this._add.requestType) {

                const payload = cat[this._add.requestType].create({
                    id: this._refId,
                    labels: [label]
                });
                await this._ws.sendRequest(this._add, payload);
                this._store.dispatch(new addListItem(label, messageDefinitions.LabelMsg, this._get.methodName));
                await this.common.createSnackbar(protosui.messages.uitext.addlabel, "bottom", 1000);

            } else {
                this._logger.error("Could not add / remove label ", protosui.messages.uitext.prerequisites);
            }

        } catch (error) {
            this._logger.error(new Error(error));
        }
    }

    /**
     * Directly add a label (if allowed)
     *
     * @param {("global" | "profile")} labelType
     * @memberof LabelsComponent
     */
    public async addLabel(labelType: "global" | "profile" | "case") {
        try {

            if (!labelType || (!this.newCaseLabel && !this.newGlobalLabel && !this.newProfileLabel)) {
                this._logger.warn("No label type or value found...");
            } else {

                let call: IServiceDescriptor;
                let payload: any;

                switch (labelType) {
                    case "global": {
                        // rpc AddGlobalLabel (LabelMsg) returns (LabelMsg);
                        call = userAddGlobalLabel;
                        payload = cat.LabelMsg.create({
                            name: this.newGlobalLabel
                        });
                        this.newGlobalLabel = "";
                        break;
                    }
                    case "profile": {
                        // rpc AddProfileLabel (ProfileMsg) returns (LabelMsg);
                        if (this.asProfile && this.routeId) {
                            call = userAddProfileLabel;
                            payload = cat.ProfileMsg.create({
                                id: this.routeId || this._profile.id,
                                labels: [
                                    cat.LabelMsg.create({
                                        name: this.newProfileLabel
                                    })
                                ]
                            });
                            this.newProfileLabel = "";
                        }
                        break;
                    }
                    case "case": {
                        // rpc AddCaseLabel (CaseMsg) returns (LabelMsg);
                        if (this._currentCaseId) {
                            call = userAddCaseLabel;
                            payload = cat.CaseMsg.create({
                                id: this._currentCaseId,
                                labels: [
                                    cat.LabelMsg.create({
                                        name: this.newCaseLabel
                                    })
                                ]
                            });
                            this.newCaseLabel = "";
                        } else {
                            this._logger.warn("No case id found, do nothing...");
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }

                // Only perform call with a payload
                if (payload) {
                    await this._ws.sendRequest(call, payload);
                    this.ngOnInit();
                }
            }
        } catch (error) {
            this._logger.error(error);
            await this.common.createSnackbar(error);
        }
    }
}
