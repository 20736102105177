<div class="cat-button-row">

    <ng-container *ngIf="(currentStep$ | async) as currentStep">

        <ng-container *ngIf="!back else close">

            <div *ngIf="currentStep.stepNumber > 1" class="cat-button-row-item">
                <button
                    (click)="goBack()"
                    [disabled]="false"
                    mat-flat-button color="primary" class="cat-no-shadow">
                    <mat-icon color="light">arrow_back</mat-icon>
                    <span color="light">
                        {{ uitext.back | translate  }}
                    </span>
                </button>
            </div>

            <div *ngIf="currentStep.stepNumber <= 4" class="cat-button-row-item cat-push-right cat-no-margin" [class.cat-highlighted]="blinkNext">
                <button
                    (click)="goForward()"
                    [disabled]="!nextAllowed"
                    mat-flat-button color="primary" class="cat-no-shadow">
                    <span color="light">
                        {{ uitext.next | translate  }}
                    </span>
                    <mat-icon color="light" iconPositionEnd>arrow_forward</mat-icon>
                </button>
            </div>

            <div *ngIf="currentStep.stepNumber === 5" class="cat-button-row-item cat-push-right cat-no-margin" [class.cat-highlighted]="blinkNext">
                <button
                    (click)="emitGenerateReport.emit()"
                    mat-flat-button color="success" class="cat-no-shadow">
                    <span color="light">
                        {{ uitext.generate | translate  }}
                    </span>
                    <mat-icon color="light" iconPositionEnd>handyman</mat-icon>
                </button>
            </div>

        </ng-container>

        <ng-template #close>
            <div class="cat-button-row-item cat-push-right cat-no-margin" [class.cat-highlighted-success]="blinkNext">
                <button
                    (click)="closePage()"
                    [disabled]="false"
                    mat-flat-button color="success" class="cat-no-shadow">
                    <mat-icon color="light">done</mat-icon>
                    <span color="light">
                        {{ uitext.ok | translate  }}
                    </span>
                </button>
            </div>
        </ng-template>

    </ng-container>

</div>