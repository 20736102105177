// Copyright 2018-2024, Volto Labs BV
// All rights reserved.
import { Directive, OnInit } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({
  selector: '[matInputAutofocus]',
})
export class MatAutofocusDirective implements OnInit {
    constructor(private matInput: MatInput) { }
    ngOnInit() {
        setTimeout(() => this.matInput.focus(), 250);
    }
}


