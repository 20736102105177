import { Component, ChangeDetectionStrategy, ElementRef, ViewChild, Inject, OnInit, OnDestroy, inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { IIconDefinition, HeaderType, IMessageDefinitions, IAccountDetailData, IFormDialogData, IFormDialogReturnData, IState, AccountAction, IImageDetailPageData } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { WsService } from "@services/ws/ws.service";
import * as calls from "@assets/proto/services";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { cloneDeep } from "lodash-es";
import { FormDialog } from "@modals/form-dialog/form-dialog.modal";
import { TlService } from "@services/tl/tl.service";
import { Store } from "@ngxs/store";
import { clearStoredMessage } from "@store/actions";
import { SubscriptionManager } from "@shared/subscription-manager";
import { Observable } from "rxjs";
import { MediaDetailsDialog } from "../media-details/media-details.dialog";

@Component({
    selector: "account-details",
    templateUrl: "account-details.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountDetailsDialog extends SubscriptionManager implements OnInit, OnDestroy {

    @ViewChild('password') password: ElementRef;
    account$: Observable<cat.AccountMsg> = inject(Store).select((state: IState) => state.cat.userGetDetailedAccount.msg);

    public hidePassword = true;
    public selectedTabIndex: number = 0;
    public accountActionEnum = AccountAction;

    public icon: IIconDefinition = protosui.msgIcons.AccountMsg;
    public uitext = protosui.messages.uitext;
    public messageDefinitions = messageDefinitions;
    public mediaType = cat.MediaType;
    public headerTypeEnum = HeaderType;
    public definition: IMessageDefinitions;
    public device: cat.DeviceMsg = cat.DeviceMsg.create();
    public app: cat.AppMsg = cat.AppMsg.create();
    public account: cat.AccountMsg = cat.AccountMsg.create();
    public profile: cat.ProfileMsg = cat.ProfileMsg.create();

    // History
    public refreshTimeline: number = 0;

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _dialog: MatDialog,
        private _store: Store,
        private _ws: WsService,
        private _dialogRef: MatDialog,
        private _logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) data: IAccountDetailData) {
            super();

            // Assign properties
            this.account = data.account;
            this.profile = data.profile;
            this.definition = this.common.getMessageDefinitions(messageDefinitions.AccountMsg);

            if (!this.account?.id) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }
    }

    public async ngOnInit() {
        // Clear details.
        this._store.dispatch(new clearStoredMessage(calls.userGetDetailedAccount.methodName, messageDefinitions.AccountMsg));
        // Fetch account details
        await this.userGetDetailedAccount();
    }


    public ngOnDestroy() {
        try {
            // Ensure all subscriptions are removed (synchronous and not using callbacks)
            this.unsubscribeAll();
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Handler for tab change events.
     *
     * @memberof AccountDetailsDialog
     */
    public async tabChange() {
        await this.userGetDetailedAccount();
    }

    /**
     * Get the full detailed account.
     *
     * @private
     * @memberof AccountDetailsDialog
     */
    private async userGetDetailedAccount() {
        try {
            const payload = cat.AccountMsg.create({ id: this.account.id });
            await this._ws.sendRequest(calls.userGetDetailedAccount, payload);
            this.refreshTimeline = Date.now();
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
     * Edit the accounts password
     *
     * @memberof AccountDetailsDialog
     */
    public async editPassword(password: string) {
        try {
            const payload: cat.AccountMsg = this.account;
            payload.password = password;
            await this._ws.sendRequest(calls.userModifyAppAccount, payload);
            this.common.createSnackbar(protosui.messages.uitext.edited);
            this.userGetDetailedAccount();
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
     * Open the form dialog to edit a field.
     *
     * @param {string} name
     * @memberof UserDetailsPage
     */
    async openFormDialog(name: string) {
        try {
            this._logger.debug("Open form dialog name: ", name);
            const msg: cat.AccountMsg = cloneDeep(this.account);
            let list: { id: string, name: string }[];


            const data: IFormDialogData = {
                msg: msg,
                definition: this.definition,
                name: name,
                list: list
            }
            const dialogRef = this._dialogRef.open(FormDialog, {
                data: data
            });
            dialogRef.afterClosed().subscribe(async (result: IFormDialogReturnData) => {
                this._logger.debug(`Dialog result: `, result);
                if (result?.data?.name === "password") {
                    this._logger.debug(`Dialog result: `, result);
                    await this.editPassword(result.data.msg.password);
                }
            });
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
    * Enlarge the account avatar
    * @param {cat.AccountMsg} account The current account, with avatar
    */
    async openMedia(account: cat.AccountMsg) {
        try {

            if (!account || !this.account.id || !account.avatar) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            // Close this dialog.
            this.common.dismissAllOverlays();

            const attachment: cat.AttachmentMsg = cat.AttachmentMsg.create(account.avatar);

            const dialogData: IImageDetailPageData = {
                attachment: attachment,
                refId: this.account.id,
                accountId: this.account.id,
                mediatype: cat.MediaType.MEDIA_ACCOUNTAVATARS,
                asProfile: true,
                profileId: this.profile.id
            };
            this._logger.debug("Dialog Data: ", dialogData);

            this._dialog.open(MediaDetailsDialog, {
                width: "95vw",
                height: "95vh",
                data: dialogData
            });

        } catch (error) {
            this._logger.error(error);
        }
    }
}
