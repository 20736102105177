import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, Inject, ViewChild, inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { WsService } from "@services/ws/ws.service";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { appRouteNames } from "@app/app.routes.names";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { clearStoredMessage, setLoading } from "@store/actions";
import { CatSrcPipe } from "@pipes/catsrc/catsrc";
import { ReportWizardService } from "@services/report-wizard/report-wizard.service";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { MediaDetailsDialog } from "../media-details/media-details.dialog";
import { TlService } from "@services/tl/tl.service";
import { SubscriptionManager } from "@shared/subscription-manager";
import { AuthService } from "@services/auth/auth.service";
import * as models from "@shared/app-models";
import * as calls from "@assets/proto/services";

@Component({
    selector: "conversation-details",
    templateUrl: "conversation-details.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationDetailsDialog extends SubscriptionManager implements OnInit, OnDestroy {

    isLoading$: Observable<boolean> = inject(Store).select((state: models.IState) => state.cat.userGetConversationMedia?.isLoading);
    conversation$: Observable<cat.ConversationMsg> = inject(Store).select((state: models.IState) => state.cat.userGetDetailedConversation.msg);
    exportLoading$: Observable<boolean> = inject(Store).select((state: models.IState) => state.cat.userGetConversationMemberExportFileId.isLoading);
    @ViewChild("tabGroup") tabGroup!:MatTabGroup;

    public selectedTabIndex: number = 0;

    public calls = calls;
    public headerTypeEnum = models.HeaderType;
    public contactAction = models.ContactAction;
    public conversationAction: number = models.ConversationAction.View;
    public conversationActionEnum = models.ConversationAction;
    public mediaType: cat.MediaType = cat.MediaType.MEDIA_ACCOUNTAVATARS;
    public conversationType = cat.ConversationType;
    public permissionEnum = cat.Permission;
    public profile: cat.ProfileMsg = cat.ProfileMsg.create();
    public icon: models.IIconDefinition = protosui.msgIcons.ConversationMsg;

    public getAnnotations: calls.IServiceDescriptor = calls.userGetConversationAnnotations;
    public addAnnotations: calls.IServiceDescriptor = calls.userAddConversationAnnotation;
    public removeAnnotations: calls.IServiceDescriptor = calls.userRemoveConversationAnnotation;
    public getLabels: calls.IServiceDescriptor = calls.userGetConversationLabels;
    public setLabel: calls.IServiceDescriptor = calls.userSetConversationLabel;
    public unsetLabel: calls.IServiceDescriptor = calls.userUnsetConversationLabel;

    public definition: models.IMessageDefinitions = this.common.getMessageDefinitions(messageDefinitions.ConversationMsg);
    public messageDefinitions = messageDefinitions;
    public uitext = protosui.messages.uitext;
    public conversationId = "";
    public apptype: cat.AppTypeMsg = cat.AppTypeMsg.create();
    public account: cat.AccountMsg = cat.AccountMsg.create();

    // Header
    public buttons: models.IHeaderButton[] = [
        { text: this.uitext.exportmembers, icon: { matname: "download" }, permissions: [cat.Permission.PERMISSION_EXPORT_PROFILES]  },
        { text: this.uitext.conversationreport, icon: { matname: "article" }, permissions: [cat.Permission.PERMISSION_CREATE_PROFILE_REPORTS]  }
    ]

    // History
    public refreshTimeline: number = 0;

    // Private properties
    private _pendingFileId: string = "";

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _auth: AuthService,
        private _dialog: MatDialog,
        private _store: Store,
        private _reportService: ReportWizardService,
        private _cdr: ChangeDetectorRef,
        private _logger: LoggerService,
        private _router: Router,
        private _ws: WsService,
        @Inject(MAT_DIALOG_DATA) data: models.IConversationDetailData
    ) {
        // SubscriptionManager.
        super();

        this.account = data.account;
        this.profile = data.profile;
        this.conversationId = data.conversation.id;
        this.conversationAction = data.conversationAction;

        if (!this.account.id || !this.profile.id || !this.conversationId) {
            throw new Error("Missing profile, account or conversation id.");
        }
    }

    public async ngOnInit() {

        // Clear details.
       this._store.dispatch(new clearStoredMessage(calls.userGetDetailedConversation.methodName, messageDefinitions.ConversationMsg));

        await this.userGetDetailedConversation();

        this.addSubscriptions([
            // Listen for generated export files.
            this._ws.notifications
                .get(cat.NotificationType.NOTIFICATION_FILE)
                .subscribe((notification: cat.NotificationMsg) => this.handleFileNotification(notification)),

            this._ws.notificationBundle
                .subscribe((notificationBundle: cat.NotificationBundleMsg) => this.bundleHandler(notificationBundle)),
        ]);
    }

    /**
     * Select the correct tab from conversation actions.
     *
     * @memberof ConversationDetailsDialog
     */
    ngAfterViewInit() {
        let label = this.uitext.info;

        // Select correct tab.
        switch (this.conversationAction) {
            case this.conversationActionEnum.Contact: {
                label = this.uitext.members;
                break;
            }
            case this.conversationActionEnum.Annotate: {
                label = this.uitext.annotations;
                break;
            }
            case this.conversationActionEnum.Label: {
                label = this.uitext.labels;
                break;
            }
            case this.conversationActionEnum.History: {
                label = this.uitext.history;
                break;
            }
            case this.conversationActionEnum.Gallery: {
                label = this.uitext.gallery;
                break;
            }
        }
        // Select correct tab based on aria-label.
        const tabIndex = this.tabGroup?._tabs?.toArray()?.findIndex(tab => tab.ariaLabel?.toLowerCase() === label.toLowerCase());
        this.selectedTabIndex = (tabIndex > -1) ? tabIndex : 0;
    }

    public ngOnDestroy() {
        try {
            // Ensure all subscriptions are removed (synchronous and not using callbacks)
            this.unsubscribeAll();
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Actions emitted from the modal header.
     *
     * @param {models.IHeaderButton} event
     * @memberof ConversationDetailsDialog
     */
    public async headerAction(event: models.IHeaderButton) {
        switch (event.text) {
            case this.uitext.exportmembers: {
                await this.downloadExport();
                break;
            }
            case this.uitext.conversationreport: {
                await this.createReport();
                break;
            }
        }
    }

     /**
     * Handle bundled notifications (subscription).
     *
     * @private
     * @param {cat.NotificationBundleMsg} bundle The notification bundle.
     * @memberof ConversationDetailsDialog
     */
     private async bundleHandler(bundle: cat.NotificationBundleMsg) {
        // Check if the account id is currently active
        if (bundle.account?.id === this.account.id && bundle.conversation.length) {
            this._logger.debug(`(${bundle.conversation.length}) conversation notifications found`);
            await this.userGetDetailedConversation();
        }
    }

    public async onTabChanged(event: MatTabChangeEvent) {
        if (!event || !event.tab?.ariaLabel) {
            this._logger.error("No aria label text / event found.");
        }

        const label = event.tab.ariaLabel.toLowerCase();


        if (label === this.uitext.history.toLowerCase()) {
            await this.userGetDetailedConversation();
        }
    }

    /**
     * Handle generated file and download it.
     *
     * @private
     * @param {cat.NotificationMsg} notification The notification.
     * @memberof ConversationDetailsPage
     */
    private async handleFileNotification(notification: cat.NotificationMsg) {
        try {
            if (!notification || !notification.references || !Object.keys(notification.references).length) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            const fileId: string = notification.references[cat.ReferenceType.REFERENCE_FILE_ID];

            if (!fileId) {
                throw new Error(protosui.messages.uitext.invalidnotification);
            }

            // Sometimes the notification is quicker than userGetDeviceLogFileId.
            if (!this._pendingFileId) {
                await this.common.timeout(500);
            }

            if (fileId === this._pendingFileId) {

                // Construct the download URL.
                const mediaFile = cat.MediaFileMsg.create({ fileid: fileId });
                const url = new CatSrcPipe().transform(mediaFile, "download", cat.MediaType.MEDIA_EXPORT, this.conversationId);

                // Download the log file.
                const link: HTMLAnchorElement = document.createElement("a");
                link.download = fileId;
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Clear the pending media id.
                this._pendingFileId = "";

                this.common.detectChange(this._cdr);
            } else {
                this._logger.warn("Retrieved file id differs from pending file id.");
            }

            this._store.dispatch(new setLoading(false, calls.userGetConversationMemberExportFileId.methodName));
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Get the full detailed conversation.
     *
     * @private
     * @memberof ConversationDetailsPage
     */
    private async userGetDetailedConversation() {
        try {
            const payload = cat.ConversationMsg.create({ id: this.conversationId });
            await this._ws.sendRequest(calls.userGetDetailedConversation, payload);
            this.refreshTimeline = Date.now();
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
     * Request an export.
     *
     * @memberof ConversationDetailsPage
     */
    public async downloadExport() {
        try {
            // Show a dialog with a yes / no choice.
            const choiceDialog = this.common.createDialogReference({
                title: protosui.messages.uitext.pleaseselect,
                content: protosui.messages.uitext.includeaccount,
                buttons: [
                    {
                        title: protosui.messages.uitext.yes,
                        color: "primary",
                        action: "proceed"
                    },
                    {
                        title: protosui.messages.uitext.no,
                        color: "primary",
                        action: "cancel"
                    }
                ]
            });

            // Process dialog result and start export
            choiceDialog.afterClosed().subscribe(async (result: models.IDialogButton["action"]) => {

                const filter = result === "proceed" ? cat.FilterOperator.FILTER_INCLUDE : cat.FilterOperator.FILTER_EXCLUDE;

                // Fetch the export file id
                const payload = cat.QueryMsg.create({
                    references: {
                        [cat.ReferenceType.REFERENCE_CONVERSATION_ID]: this.conversationId
                    },
                    filters: [
                        cat.FilterMsg.create({
                            collection: "conversations",
                            operator: filter,
                            field: "account",
                            value: ""
                        })
                    ]
                });
                await this._ws.sendRequest(calls.userGetConversationMemberExportFileId, payload);
                this._store.dispatch(new setLoading(true, calls.userGetConversationMemberExportFileId.methodName));
                const fileId = this._store.selectSnapshot((state: models.IState) => state.cat.userGetConversationMemberExportFileId.msg.fileid);

                // Assign the retrieved file id to the pending media.
                if (fileId) {
                    this._pendingFileId = fileId;
                    this.common.detectChange(this._cdr);
                } else {
                    throw new Error("No file id returned.");
                }
            });
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
    * Enlarge the contact avatar
    * @param {cat.AccountMsg} account The current contact, with avatar
    */
    async openMedia(conversation: cat.ConversationMsg) {
        try {

            if (!conversation || !this.account.id || !conversation.avatar) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            // Close this dialog.
            this.common.dismissAllOverlays();

            const attachment: cat.AttachmentMsg = cat.AttachmentMsg.create(conversation.avatar);

            const dialogData: models.IImageDetailPageData = {
                attachment: attachment,
                refId: this.account.id,
                accountId: this.account.id,
                mediatype: cat.MediaType.MEDIA_ACCOUNTAVATARS,
                asProfile: true,
                profileId: this.profile.id,
                conversationId: this.conversationId
            };
            this._logger.debug("Dialog Data: ", dialogData);

            this._dialog.open(MediaDetailsDialog, {
                width: "95vw",
                height: "95vh",
                data: dialogData
            });

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Create a report from a conversation perspective
     *
     * @memberof ConversationDetailsPage
     */
     public async createReport() {
        try {
            const payload: cat.ConversationMsg = cat.ConversationMsg.create({
                id: this.conversationId
            });
            await this._ws.sendRequest(calls.userGetConversationReport, payload);
            const report = this._store.selectSnapshot((state: models.IState) => state.cat.userGetConversationReport)?.msg;
            this._reportService.startDuplicateReport(report);

            // Make sure the reporter navigates back here.
            this._auth.setBackPath(this._router.url);

            this._router.navigate([`${appRouteNames.DASHBOARD}/profile/${this.profile.id}/${appRouteNames.MANAGE_PROFILE_RERORTS}/${appRouteNames.REPORT_TEMPLATE}`]);
            this.common.dismissAllOverlays();

        } catch (error) {
            this._logger.error(error);
        }
    }
}
