import { DatePipe } from "@angular/common";
import { Component, ChangeDetectionStrategy, Input, Renderer2, ChangeDetectorRef, HostListener, OnDestroy, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { cat } from "@assets/proto/msgs";
import { GenericDialog } from "@components/dialog-generic/generic-dialog.component";
import { protosui } from "@definitions/definitions";
import { MediaDetailsDialog } from "@modals/detail-pages/media-details/media-details.dialog";
import { Store } from "@ngxs/store";
import { GroupMessagesPipe } from "@pipes/groupMessages/group-messages.pipe";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";
import { HistoryItem, IDialogData, IImageDetailPageData, IState, ITimelineData } from "@shared/app-models";

@Component({
    selector: "history-timeline",
    templateUrl: "./history-timeline.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryTimelineComponent  implements AfterViewInit, OnDestroy {

    // The SVG container.
    @ViewChild("chartContainer") chartContainer!: ElementRef;

    @HostListener("document:keyup", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        switch (event.key) {
            case "ArrowLeft":
                this.goBack();
                break;
            case "ArrowRight":
                this.goNext();
                break;
            default:
                break;
        }
    }

    // I/O
    @Input() mode: "msg" | "chat" | "contact" | "server" | "topic" | "account" = "msg";
    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    @Input() profile: cat.ProfileMsg = cat.ProfileMsg.create();
    @Input() message: cat.MessageMsg = cat.MessageMsg.create();
    @Input() conversation: cat.ConversationMsg = cat.ConversationMsg.create();
    @Input() contact: cat.AccountMsg = cat.AccountMsg.create();
    @Input() set refresh(date: any) {
        if (date > 0) {
            if (!this._initDone) {
                setTimeout(() => this.drawChart(this.getDetailedItem()), 500);
            } else {
                this.drawChart(this.getDetailedItem());
            }
        }
    }

    public currentId: string = "";
    public originId: string = "";
    public previousItem: HistoryItem = cat.MessageMsg.create();
    public selectedItem: HistoryItem = cat.MessageMsg.create();
    public messageDefinitions = messageDefinitions;
    public uitext = protosui.messages.uitext;
    public mediaType = cat.MediaType;
    public historyMarker = cat.HistoryMarker;
    public markerType = protosui.def.HistoryMarker;

    public timelineItems: ITimelineData
    public selectedTimestamp = 0;

    private _historyItems: Map<string, number> = new Map();
    private _initDone = false;
    private _historyLengthText: SVGTextElement;

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _cdr: ChangeDetectorRef,
        private _logger: LoggerService,
        private _renderer: Renderer2,
        private _dialog: MatDialog,
        private _store: Store
    ) {
        if (this.mode) {
            this._logger.error("No mode provided", this.mode);
        }
    }

    ngAfterViewInit() {
        this._initDone = true;
    }

    ngOnDestroy() {
        this._initDone = false;
        const chartContainer = this.chartContainer?.nativeElement;
        if (chartContainer) {
            chartContainer.innerHTML = "";
        }
    }

    /**
     * Draw the SVG timeline with the plotted history points.
     *
     * @private
     * @param {(HistoryItem)} historyItem
     * @param {number} [ts]
     * @param {string} [itemId]
     * @memberof HistoryTimelineComponent
     */
    private drawChart(historyItem: HistoryItem, itemId?: string) {

        if (!historyItem) {
            return;
        }

        this._historyItems = new Map();
        // Add the current (latest) item as plot point.
        this.currentId = historyItem.id;
        this._historyItems.set(historyItem.id, historyItem.historytimestamp);
        // Add the history items as plot points.
        historyItem.history.forEach((history: any) => {
            this._historyItems.set(history.id, history.historytimestamp);
        });

        const chartContainer = this.chartContainer.nativeElement;
        // Clear SVG
        chartContainer.innerHTML = "";
        // Define SVG
        const svgHeight = 70;
        const timelineMargin = 10;
        const svg = this._renderer.createElement("svg", "svg");
        this._renderer.setAttribute(svg, "width", "100%");
        this._renderer.setAttribute(svg, "height", `${svgHeight}`);

        // Fetch min / max.
        this.timelineItems = this.findTimelineItems(historyItem);
        // Set the oldest id.
        this.originId = this.timelineItems.minKey;
        // The range (47400 for instance), when it results in 0 (poll for instance) return 100.
        const timeRange = this.timelineItems.maxTs - this.timelineItems.minTs;
        // Select the current item.
        const selectedItemId = itemId ? itemId : this.timelineItems.minKey;
        this.selectedItem = historyItem.id === selectedItemId
            ? historyItem as HistoryItem
            : historyItem.history.find((item) => item.id === selectedItemId) as HistoryItem;

        // Retrieve current position.
        this.selectedTimestamp = this.selectedItem.historytimestamp;

        // Determine the previous item
        const timelineData = this.findTimelineItems(historyItem, selectedItemId);
        this.previousItem = historyItem.history.find((item) => item.id === timelineData.previousKey) as HistoryItem
        // Determine the selected position, first and last date.
        const selectedPosition = (this.selectedTimestamp - this.timelineItems.minTs) / timeRange * 100;
        const firstDate = this.timelineItems.minTs ? new DatePipe(this.tl.locale).transform(this.timelineItems.minTs, "d MMMM y, HH:mm:ss", undefined, this.tl.locale) : "";
        const lastDate = this.timelineItems.maxTs ? new DatePipe(this.tl.locale).transform(this.timelineItems.maxTs, "d MMMM y, HH:mm:ss", undefined, this.tl.locale) : "";

        if (this._historyItems.size > 1) {

            // Horizontal timeline.
            const horizontalLine = this._renderer.createElement("line", "svg");
            this._renderer.setAttribute(horizontalLine, "x1", `${timelineMargin}px`);
            this._renderer.setAttribute(horizontalLine, "y1", `${svgHeight - 40}`);
            this._renderer.setAttribute(horizontalLine, "x2", `calc(100% - ${timelineMargin}px)`);
            this._renderer.setAttribute(horizontalLine, "y2", `${svgHeight - 40}`);
            this._renderer.setAttribute(horizontalLine, "stroke", "#005C9B");
            this._renderer.setAttribute(horizontalLine, "stroke-width", "3");
            this._renderer.setAttribute(horizontalLine, "stroke-linecap", "round");
            this._renderer.appendChild(svg, horizontalLine);

            // Oldest timestamp text.
            const textStart = this._renderer.createElement("text", "svg");
            this._renderer.setAttribute(textStart, "x", "0");
            this._renderer.setAttribute(textStart, "y", `${svgHeight - 5}`);
            this._renderer.setAttribute(textStart, "fill", "#005C9B");
            this._renderer.setAttribute(textStart, "text-anchor", "start");
            this._renderer.setAttribute(textStart, "font-size", "10px");
            this._renderer.setAttribute(textStart, "font-weight", "bold");
            const textStartValue = this._renderer.createText(firstDate);
            this._renderer.appendChild(textStart, textStartValue);
            this._renderer.appendChild(svg, textStart);

            // Position text.
            this._historyLengthText = this._renderer.createElement("text", "svg");
            this._renderer.setAttribute(this._historyLengthText, "x", "50%");
            this._renderer.setAttribute(this._historyLengthText, "y", `${svgHeight - 5}`);
            this._renderer.setAttribute(this._historyLengthText, "fill", "#005C9B");
            this._renderer.setAttribute(this._historyLengthText, "text-anchor", "middle");
            this._renderer.setAttribute(this._historyLengthText, "font-size", "12px");
            this._renderer.setAttribute(this._historyLengthText, "font-weight", "bold");
            const value = this._renderer.createText("");
            this._renderer.appendChild(this._historyLengthText, value);
            this._renderer.appendChild(svg, this._historyLengthText);

            // Latest timestamp text.
            const textEnd = this._renderer.createElement("text", "svg");
            this._renderer.setAttribute(textEnd, "x", "100%");
            this._renderer.setAttribute(textEnd, "y", `${svgHeight - 5}`);
            this._renderer.setAttribute(textEnd, "fill", "#005C9B");
            this._renderer.setAttribute(textEnd, "text-anchor", "end");
            this._renderer.setAttribute(textEnd, "font-size", "10px");
            this._renderer.setAttribute(textEnd, "font-weight", "bold");
            const textEndValue = this._renderer.createText(lastDate);
            this._renderer.appendChild(textEnd, textEndValue);
            this._renderer.appendChild(svg, textEnd);

            // Selection indicator (blue circle).
            const circle = this._renderer.createElement("circle", "svg");
            this._renderer.setAttribute(circle, "cx", `${selectedPosition}%`);
            this._renderer.setAttribute(circle, "cy", `${svgHeight - 40}`);
            this._renderer.setAttribute(circle, "r", "7");
            this._renderer.setAttribute(circle, "fill", "#005C9B");
            // Adjust circle Xpos taking margin into account.
            if (selectedPosition >= 50) {
                this._renderer.setAttribute(circle, "cx", `calc(${selectedPosition}% - ${timelineMargin}px)`);
            } else {
                this._renderer.setAttribute(circle, "cx", `calc(${selectedPosition}% + ${timelineMargin}px)`);
            }

            // Create the points on the timeline.
            this._historyItems.forEach((timestamp, msgId) => {
                // Relative position on timeline.
                const relativePosition = (timestamp - this.timelineItems.minTs) / timeRange * 100;
                // Time circle.
                const circle = this._renderer.createElement("circle", "svg");
                this._renderer.setAttribute(circle, "cx", `${relativePosition}%`);
                this._renderer.setAttribute(circle, "cy", `${svgHeight - 40}`);
                this._renderer.setAttribute(circle, "r", "7");
                this._renderer.setAttribute(circle, "stroke-width", "3");
                this._renderer.setAttribute(circle, "stroke", "#005C9B");
                this._renderer.setAttribute(circle, "fill", "#FFFFFF");
                this._renderer.addClass(circle, "cat-cursor-pointer");

                // Adjust vertical line Xpos for 10px padding on horizontal timeline.
                if (relativePosition >= 50) {
                    this._renderer.setAttribute(circle, "cx", `calc(${relativePosition}% - ${timelineMargin}px)`);
                    this._renderer.setAttribute(circle, "cx", `calc(${relativePosition}% - ${timelineMargin}px)`);
                } else {
                    this._renderer.setAttribute(circle, "cx", `calc(${relativePosition}% + ${timelineMargin}px)`);
                    this._renderer.setAttribute(circle, "cx", `calc(${relativePosition}% + ${timelineMargin}px)`);
                }

                // Add the line to the SVG.
                this._renderer.appendChild(svg, circle);

                // Make lines clickable.
                this._renderer.listen(circle, "click", () => this.timestampClicked(msgId, historyItem));
            });

            // Add the selection circle after adjusting padding.
            this._renderer.appendChild(svg, circle);
        }

        this._renderer.appendChild(chartContainer, svg);
        // Update the position
        this._renderer.setProperty(this._historyLengthText, "textContent", this.getPositionText(selectedItemId));
        // Detect the changes.
        this.common.detectChange(this._cdr);
    }



    /**
     * Find min/max and neighbouring items in one function.
     *
     * @param {number} [selection] Request neighbours for a specific timestamp (next/back).
     * @returns ITimelineData
     * @memberof HistoryTimelineComponent
     */
    public findTimelineItems(currentItem: any, selectionId?: string, ): ITimelineData {
        let minValue: number,
        minKey: string,
        maxValue: number,
        maxKey: string,
        previousValue: number,
        previousKey: string,
        nextValue: number,
        nextKey: string;

        // Reverse => oldest key to newest
        const entries = Array.from(this._historyItems.entries())?.reverse();

        if (entries.length) {
            // Min
            [minKey, minValue] = entries[0];
            // Max: always set the max to the current item.
            [maxKey, maxValue] = [currentItem.id, currentItem.historytimestamp];
            // Next / Previous
            if (selectionId) {
                const idx = entries.findIndex((item: [string, number]) => item[0] === selectionId);
                if (idx > 0) {
                    previousKey = entries[idx - 1][0];
                    previousValue = entries[idx - 1][1];
                }
                if (idx < entries.length - 1) {
                    nextKey = entries[idx + 1][0];
                    nextValue = entries[idx + 1][1];
                }
            }
        }

        this._logger.warn("Timeline items:", {
            minValue: minValue,
            minKey: minKey,
            maxValue: maxValue,
            maxKey: maxKey,
            previousValue: previousValue,
            previousKey: previousKey,
            nextValue: nextValue,
            nextKey: nextKey
        });

        // This should not happen, add 1 to max to prevent errors.
        if (minValue === maxValue) {
            this._logger.warn("[findTimelineItems]: History timestamp is same for min and max, this should not happen.");
            maxValue++;
        }

        return {
            minTs: minValue || 0,
            minKey: minKey,
            maxTs: maxValue || 0,
            maxKey: maxKey,
            previousTs: previousValue || 0,
            previousKey: previousKey,
            nextTs: nextValue || 0,
            nextKey: nextKey
        }
    }

    /**
     * Show history info dialog.
     *
     * @memberof HistoryTimelineComponent
     */
    public showInfo() {
        try {
            const data: IDialogData = {
                title: protosui.messages.uitext.info,
                content: protosui.messages.uitext.historyinfo
            };

            const dialogRef = this._dialog.open(GenericDialog, {
                data: data
            });
            dialogRef.afterClosed().subscribe(result => {
                this._logger.debug(`Dialog result: ${result}`);
            });
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * When a user clicks on a timeline item, select it.
     *
     * @param {string} itemId The item id.
     * @param {HistoryItem} historyItem History item.
     * @memberof HistoryTimelineComponent
     */
    public timestampClicked(itemId: string, item: HistoryItem) {
        this.drawChart(item, itemId);
    }

    /**
     * Select the current history item.
     *
     * @private
     * @returns {HistoryItem} The current item with the history in it.
     * @memberof HistoryTimelineComponent
     */
    private getDetailedItem(): HistoryItem {
        let result: HistoryItem;
        switch (this.mode) {
            case "msg": {
                const msg = this._store.selectSnapshot((store: IState) => store.cat.userGetDetailedMessage.msg);
                if (msg.groupmessages?.length) {

                    // Combine the message history.
                    const mergedHistory = [];
                    for (const history of msg.history) {
                        const msgArray = [history as cat.MessageMsg].concat(history.groupmessages as cat.MessageMsg[]);
                        const mergedMessages = new GroupMessagesPipe().transform(msgArray)[0];
                        mergedHistory.push(mergedMessages);
                    }

                    const msgArray = [msg as cat.MessageMsg].concat(msg.groupmessages as cat.MessageMsg[]);
                    // Combine the group of messages (attachments, lastedited, body)
                    const mergedGroup = new GroupMessagesPipe().transform(msgArray)[0];
                    // Replace the combined history.
                    mergedGroup.history = mergedHistory;
                    result = mergedGroup;
                } else {
                    result = msg;
                }
                break;
            }
            case "chat": {
                result = this._store.selectSnapshot((store: IState) => store.cat.userGetDetailedConversation.msg);
                break;
            }
            case "contact": {
                result = this._store.selectSnapshot((store: IState) => store.cat.userGetProfileContact.msg);
                break;
            }
            case "server": {
                result = this._store.selectSnapshot((store: IState) => store.cat.userGetDetailedServer.msg);
                break;
            }
            case "topic": {
                result = this._store.selectSnapshot((store: IState) => store.cat.userGetDetailedTopic.msg);
                break;
            }
            case "account": {
                result = this._store.selectSnapshot((store: IState) => store.cat.userGetDetailedAccount.msg);
                break;
            }
        }
        this._logger.warn("Result", result);
        return result;
    }

    /**
     * Calculate the current position like 5/8.
     *
     * @private
     * @param {string} currentKey
     * @returns {string}
     * @memberof HistoryTimelineComponent
     */
    private getPositionText(currentKey: string): string {
        const position = Array.from(this._historyItems.keys())?.reverse()?.findIndex(key => key === currentKey) || 0;
        return `${position} / ${this._historyItems.size - 1}`;
    }

    /**
     * Go to the next history item (if possible).
     *
     * @memberof HistoryTimelineComponent
     */
    goNext() {
        // Only if it's not already the last item.
        if (this.selectedItem?.id !== this.timelineItems?.maxKey) {
            // Request next item of specific plot point.
            const items = this.findTimelineItems(this.getDetailedItem(), this.selectedItem.id);
            //Draw the chart again with the next value selected.
            this.drawChart(this.getDetailedItem(), items.nextKey);
        }
    }

    /**
     * Go to the previous history item (if possible).
     *
     * @memberof HistoryTimelineComponent
     */
    public goBack() {
        // Only if it's not the first item.
        if (this.selectedItem?.id !== this.timelineItems?.minKey) {
            // Request previous item of specific plot point.
            const items = this.findTimelineItems(this.getDetailedItem(), this.selectedItem.id);
            //Draw the chart again with the previous value selected.
            this.drawChart(this.getDetailedItem(), items.previousKey);
        }
    }

    /**
     * Go to the latest (newest) item at once.
     *
     * @memberof HistoryTimelineComponent
     */
    public goMax() {
        // Only if it's not already the last item.
        if (this.selectedItem?.id !== this.timelineItems?.maxKey) {
            // Draw the chart again with the max value selected.
            this.drawChart(this.getDetailedItem(), this.timelineItems.maxKey);
        }
    }

    /**
     * Go to the earliest (oldest) item at once.
     *
     * @memberof HistoryTimelineComponent
     */
    public goMin() {
        // Only if it's not already the last item.
        if (this.selectedItem?.id !== this.timelineItems?.minKey) {
            // Draw the chart again with the min value selected.
            this.drawChart(this.getDetailedItem(), this.timelineItems.minKey);
        }
    }

    /**
     * Navigate through time with scrollwheel.
     *
     * @param {*} event
     * @memberof HistoryTimelineComponent
     */
    public onScroll(event) {
        if (event.deltaY > 0) {
            this.goBack();
        } else if (event.deltaY < 0) {
            this.goNext();
        }
    }

    /**
    * Enlarge the avatar
    * @param {cat.ConversationMsg | cat.TopicMsg | cat.AccountMsg | cat.ServerMsg} item The current item, with avatar
    */
    async openMedia(item: cat.ConversationMsg | cat.TopicMsg | cat.AccountMsg | cat.ServerMsg) {
        try {

            if (!item?.id || !item.avatar?.id) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            const attachment: cat.AttachmentMsg = cat.AttachmentMsg.create(item.avatar);

            const dialogData: IImageDetailPageData = {
                attachment: attachment,
                refId: this.account.id,
                accountId: this.account.id,
                mediatype: cat.MediaType.MEDIA_ACCOUNTAVATARS,
                asProfile: true,
                profileId: this.profile.id
            };
            this._logger.debug("Dialog Data: ", dialogData);

            this._dialog.open(MediaDetailsDialog, {
                width: "95vw",
                height: "95vh",
                data: dialogData
            });

        } catch (error) {
            this._logger.error(error);
        }
    }
}
