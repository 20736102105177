<div class="cat-flex-container cat-flex-column">

    <modal-header
        [accountId]="data?.account?.id"
        [serverId]="data?.server?.id"
        [title]="uitext.serverdetails"
        class="cat-margin-bottom-lg">
    </modal-header>

    <div class="cat-flex-grow cat-nooverflow-ellipsis">

        <ng-container *ngIf="(server$|async) as server">

            <div class="cat-flex-container cat-flex-column">

                <div class="cat-flex-grow cat-nooverflow-ellipsis">

                    <mat-tab-group
                        preserveContent class="cat-full-height cat-overflow-y" animationDuration="0"
                        [selectedIndex]="selectedTabIndex"
                        (selectedTabChange)="onTabChanged($event)">
                        <mat-tab label="{{ uitext.info | translate }}" [aria-label]="uitext.info">
                            <ng-template matTabContent>

                                <div class="grid-container cat-margin-xl">

                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                        <mat-list-item class="cat-cursor-text">
                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                            <div matListItemTitle>
                                                {{ uitext.id | translate }}
                                            </div>
                                            <div matListItemLine>
                                                {{ server?.id | emptyValue }}
                                            </div>
                                        </mat-list-item>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                        <mat-list-item class="cat-cursor-text">
                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                            <div matListItemTitle>
                                                {{ uitext.externid | translate }}
                                            </div>
                                            <div matListItemLine>
                                                {{ server?.externid | emptyValue }}
                                            </div>
                                        </mat-list-item>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                        <mat-list-item class="cat-cursor-text">
                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                            <div matListItemTitle>
                                                {{ uitext.name | translate }}
                                            </div>
                                            <div matListItemLine>
                                                {{ server?.name | emptyValue }}
                                            </div>
                                        </mat-list-item>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                        <mat-list-item class="cat-cursor-text cat-full-height">
                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                            <div matListItemTitle>
                                                {{ uitext.description | translate }}
                                            </div>
                                            <div matListItemLine class="cat-wrap cat-break-word">
                                                {{ server?.description | emptyValue }}
                                            </div>
                                        </mat-list-item>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                        <mat-list-item class="cat-cursor-text">
                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                            <div matListItemTitle>
                                                {{ uitext.conversations | translate }}
                                            </div>
                                            <div matListItemLine>
                                                {{ server?.conversationcount | emptyValue }}
                                            </div>
                                        </mat-list-item>
                                    </div>
                                        <!-- <mat-list-item class="cat-cursor-text cat-full-height cat-margin-bottom-lg">
                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                            <div matListItemTitle>
                                                {{ uitext.nicknames | translate }}
                                            </div>
                                            <div matListItemLine class="cat-wrap cat-padding-bottom-md">
                                                <span *ngFor="let item of server.usernicknames let last = last">
                                                    {{ item }}<span *ngIf="!last">, </span>
                                                </span>
                                                <ng-container *ngIf="!server.usernicknames?.length">
                                                    {{ "" | emptyValue }}
                                                </ng-container>
                                            </div>
                                        </mat-list-item> -->

                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                        <mat-list-item class="cat-cursor-text">
                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                            <div matListItemTitle>
                                                {{ uitext.type | translate }}
                                            </div>
                                            <div matListItemLine>
                                                {{ (messageDefinitions.ServerType | getEnumLabel : server.type) | emptyValue | translate }}
                                            </div>
                                        </mat-list-item>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                        <mat-list-item class="cat-cursor-text">
                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                            <div matListItemTitle>
                                                {{ uitext.state | translate }}
                                            </div>
                                            <div matListItemLine>
                                                {{ (messageDefinitions.ServerState | getEnumLabel : server.state) | emptyValue | translate }}
                                            </div>
                                        </mat-list-item>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                        <mat-list-item class="cat-cursor-text">
                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                            <div matListItemTitle>
                                                {{ uitext.lastmodified | translate }}
                                            </div>
                                            <div matListItemLine>
                                                {{ (server.lastmodified | date: 'd MMMM y, HH:mm' : undefined : tl.locale) }}
                                            </div>
                                        </mat-list-item>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                        <mat-list-item class="cat-cursor-text">
                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                            <div matListItemTitle>
                                                {{ uitext.created | translate }}
                                            </div>
                                            <div matListItemLine>
                                                {{ (server.created | date: 'd MMMM y, HH:mm' : undefined : tl.locale) }}
                                            </div>
                                        </mat-list-item>
                                    </div>
                                </div>

                            </ng-template>
                        </mat-tab>

                        @if ([permissionEnum.PERMISSION_VIEW_ANNOTATIONS, permissionEnum.PERMISSION_SET_ANNOTATIONS] | hasPermission) {
                            <mat-tab label="{{ uitext.annotations | translate }}" [aria-label]="uitext.annotations">
                                <ng-template matTabContent>
                                    <div class="cat-padding-xl">
                                        <app-annotations
                                            *ngIf="([permissionEnum.PERMISSION_VIEW_ANNOTATIONS, permissionEnum.PERMISSION_SET_ANNOTATIONS] | hasPermission)"
                                            [_accountId]="account.id"
                                            [_refId]="server.id"
                                            [_get]="getAnnotations"
                                            [_remove]="removeAnnotations"
                                            [_add]="addAnnotations">
                                        </app-annotations>
                                    </div>
                                </ng-template>
                            </mat-tab>
                        }

                        @if ([permissionEnum.PERMISSION_VIEW_LABELS, permissionEnum.PERMISSION_SET_LABELS] | hasPermission) {
                            <mat-tab label="{{ uitext.labels | translate }}" [aria-label]="uitext.labels">
                                <ng-template matTabContent>
                                    <div class="cat-padding-xl">
                                        <labels
                                            *ngIf="([permissionEnum.PERMISSION_VIEW_LABELS, permissionEnum.PERMISSION_SET_LABELS] | hasPermission)"
                                            [_refId]="server.id"
                                            [asProfile]="true"
                                            [routeId]="profile.id"
                                            [_profile]="profile"
                                            [_get]="getLabels"
                                            [_add]="setLabel"
                                            [_remove]="unsetLabel">
                                        </labels>
                                    </div>
                                </ng-template>
                            </mat-tab>
                        }

                        <mat-tab label="{{ uitext.history | translate }}" [aria-label]="uitext.history" class="cat-full-height cat-nooverflow-ellipsis">
                            <ng-template matTabContent class="cat-nooverflow-ellipsis">
                                <mat-tab-body class="cat-nooverflow-ellipsis cat-background-white">

                                    <history-timeline
                                        [server]="server"
                                        [profile]="profile"
                                        [account]="account"
                                        [mode]="'server'"
                                        [refresh]="refreshTimeline">
                                    </history-timeline>

                                </mat-tab-body>
                            </ng-template>
                        </mat-tab>

                    </mat-tab-group>
                </div>

            </div>

        </ng-container>
    </div>

</div>
