<ng-container *ngIf="file && mediatype">
    <ng-lottie
        [class.cat-full-width-height]="fullwidth"
        [class.cat-center]="large"
        [class.cat-lg]="large"
        (mouseenter)="mouseEnter(file.fileid)"
        (mouseleave)="mouseLeave(file.fileid)"
        (animationCreated)="animationCreated($event, file.fileid)"
        [options]="{
            autoplay: autoplay,
            renderer: 'svg',
            path: (file | catsrc : 'media' : mediatype : refId)
        }">
    </ng-lottie>
</ng-container>