import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";

/**
 * Check if a given message is selected
 *
 * @export
 * @class IsMsgSelectedPipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: "isMsgSelected",
    pure: true
})

export class IsMsgSelectedPipe implements PipeTransform {
    transform(messageIds: string[], msg: cat.MessageMsg): boolean {
        return messageIds?.some((storedMsg: string) => storedMsg === msg.id) || false;
    }
}
