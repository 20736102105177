import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { IPopoverFilterData, IAvailableFilters, IState } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { QueryService } from "@services/query/query.service";
import { Observable } from "rxjs";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
    selector: "cat-filter-menu",
    templateUrl: "./popover-filter-menu.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverFilterMenuComponent implements OnInit {

    // Filters obtained from Query Service
    public availableFilters: IAvailableFilters[] = [];
    public currentFilters: { [key: string]: string[] } = {};

    // Counts
    public currentCounts: { [key: number]: number } = {};
    public counts$: Observable<cat.FilterCountMsg>;

    // Definition properties
    public filterOperatorEnum = cat.FilterOperator;
    public serverState = cat.ServerState;
    public serverStateDef = protosui.def.ServerState;
    public serverType = cat.ServerType;
    public serverTypeDef = protosui.def.ServerType;
    public conversationState = cat.ConversationState;
    public conversationStateDef = protosui.def.ConversationState;
    public topicState = cat.TopicState;
    public topicStateDef = protosui.def.TopicState;
    public conversationType = cat.ConversationType;
    public conversationTypeDef = protosui.def.ConversationType;
    public visibilityType = cat.VisibilityType;
    public visibilityTypeDef = protosui.def.VisibilityType;
    public uitext = protosui.messages.uitext;

    constructor(
        private _queryService: QueryService,
        private _store: Store,
        private _cdr: ChangeDetectorRef,
        private _common: CommonService,
        private _logger: LoggerService,
        private _dialogRef: MatDialogRef<PopoverFilterMenuComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IPopoverFilterData = {
            path: "",
            title: "",
            mode: "chat",
            removeEnabled: true,
            countCursor: ""
        }
    ) {}

    /**
     * Process the provided popover data, assign to public properties,
     * Get the available and current filters.
     *
     * @memberof PopoverFilterMenuComponent
     */
    async ngOnInit() {
        // Get the provided data.
        if (!this.data?.path || !this.data?.mode || !this.data?.title || !this.data?.countCursor) {
            this._common.createSnackbar(protosui.messages.uitext.prerequisites);
            throw new Error(protosui.messages.uitext.prerequisites);
        }

        this.counts$ = this._store.select((state: IState) => state.cat[this.data.countCursor].msg);

        // Assign the filters from the query service.
        this.availableFilters = this._queryService.availableFilters.get(this.data.path);
        this.currentFilters = this._queryService.getCurrentFilters(this.data.path);

        if (this.data.removeEnabled !== undefined) {
            this.data.removeEnabled = this.data.removeEnabled;
        }

        this._common.detectChange(this._cdr);
    }

    /**
     * Select a filter
     *
     * @param {CustomEvent} event
     * @param {IFilterOptions} filter
     * @memberof PopoverFilterMenuComponent
     */
    async selectFilter(filter: cat.FilterMsg, value: any) {
        try {
            this._logger.debug(`Filter selected ${filter.field} with value ${value} and path ${this.data.path}`);

            if (this.currentFilters?.[filter?.field]?.includes(value)) {
                this._logger.debug(`Value alreay included, remove from field ${filter.field}`);
                this._queryService.removeFilterValue(this.data.path, filter.field, value);
            } else {
                this._logger.debug(`Value not yet included, add to field ${filter?.field}`);
                this._queryService.addFilterValue(this.data.path, filter.field, value);
            }

            this.currentFilters = this._queryService.getCurrentFilters(this.data.path);
            this._common.detectChange(this._cdr);

            // Emit a change
            this._queryService.filterCalls.get(this.data.path).next(true);

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Toggle a filter
     *
     * @param {MatSlideToggleChange} event
     * @param {IFilterOptions} filter
     * @memberof PopoverFilterMenuComponent
     */
    async toggleFilter(event: MatSlideToggleChange, filter: cat.FilterMsg) {
        try {
            this._logger.debug(`Filter toggled ${filter.field} with ${event.checked} and path ${this.data.path}`);

            if (event.checked) {
                this._queryService.removeFilter(this.data.path, filter.field);
            } else {
                this._queryService.addAvailableFilter(this.data.path, filter.field);
            }

            this.currentFilters = this._queryService.getCurrentFilters(this.data.path);
            this._common.detectChange(this._cdr);

            // Emit a change
            this._queryService.filterCalls.get(this.data.path).next(true);

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Remove all filters
     *
     * @memberof PopoverFilterMenuComponent
     */
    public async removeQuery() {
        try {
            this._queryService.removeQuery(this.data.path);
            await this._common.createSnackbar("Filters cleared");
            // Emit a change
            this._queryService.filterCalls.get(this.data.path).next(true);
            this.dismiss();
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Dismiss the popover
     *
     * @memberof PopoverFilterMenuComponent
     */
    public dismiss() {
        this._dialogRef.close();
    }
}
