import { Component, ChangeDetectionStrategy, OnInit, Input, OnDestroy } from "@angular/core";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { FormControl } from "@angular/forms";
import { IFormFieldData } from "@shared/app-models";
@Component({
    selector: "textarea-field",
    templateUrl: "./textarea-field.component.html",
    changeDetection: ChangeDetectionStrategy.Default
})

export class TextareaFieldComponent implements OnInit, OnDestroy {
    // Inputs
    @Input() submitted: boolean;
    @Input() maxRows: number = 7;
    @Input() data: IFormFieldData;
    @Input() control: FormControl;
    @Input() isReadOnly: boolean = false;
    @Input() focus: boolean = false;

    constructor(
        public common: CommonService,
        private _logger: LoggerService)
    {}

    ngOnInit() {
        // this._logger.debug("INIT TEXTAREA FIELD");
    }

    ngOnDestroy(): void {
        // this._logger.debug("DESTROY TEXTAREA FIELD");
    }
}