import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { protosui } from "@definitions/definitions";
import { IDialogEncryption } from "@shared/app-models";

@Component({
  selector: "dialog-encryption",
  templateUrl: "./dialog-encryption.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EncryptionDialog {

    public checked = false;
    public uitext = protosui.messages.uitext;

    constructor(
        public dialogRef: MatDialogRef<EncryptionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogEncryption
    ) {}


    /**
     * Close the dialog with the close button.
     *
     * @memberof EncryptionDialog
     */
    closeDialog(event: "cancel" | "submit") {
        this.dialogRef.close(event);
    }
}