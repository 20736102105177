import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, Inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { userAddProfileToCurrentUser, userGetCurrentUserProfiles } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { WsService } from "@services/ws/ws.service";
import { IState } from "@shared/app-models";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "cat-select-popover",
    templateUrl: "./popover-cat-select.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatSelectPopoverComponent {

    @Input() list = [];

    public permissionEnum = cat.Permission;
    public newProfileName = "";
    public currentItem: cat.AccountMsg | cat.DeviceMsg;
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        private _cdr: ChangeDetectorRef,
        private _store: Store,
        private _ws: WsService,
        private _logger: LoggerService,
        private _dialogRef: MatDialogRef<CatSelectPopoverComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (!this.data) {
            this.common.createSnackbar(protosui.messages.uitext.prerequisites);
            throw new Error(protosui.messages.uitext.prerequisites);
        }

        this.list = this.data.list;
        this.currentItem = this.data.currentItem;
    }

    public selectItem(item: cat.ProfileMsg | any) {
        this._logger.debug("Item", item);
        this._dialogRef.close(item);
    }

    /**
     * Add a new profile
     *
     * @memberof DevicePage
     */
     public async userAddProfile() {
        try {
            if (this.newProfileName.length) {
                const payload: cat.ProfileMsg = cat.ProfileMsg.create({
                    fullname: this.newProfileName
                });
                await this._ws.sendRequest(userAddProfileToCurrentUser, payload);
                this.newProfileName = "";

                // Update list
                await this._ws.sendRequest(userGetCurrentUserProfiles);
                const newProfile = this._store.selectSnapshot((state: IState) => state.cat.userAddProfileToCurrentUser).msg;
                this.list.push(newProfile);
                this.common.detectChange(this._cdr);

            } else {
                this._logger.error(protosui.messages.uitext.prerequisites, this.newProfileName);
            }

        } catch (error) {
            this._logger.error(error);
            await this.common.createSnackbar(error);
        }
    }

}
