import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IFormDialogData, IFormDialogReturnData } from "@shared/app-models";
import { LoggerService } from "@services/logger/logger.service";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { cat } from "@assets/proto/msgs";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { FormGroup } from "@angular/forms";
import { HumanizeEnumPipe } from "@pipes/humanizeEnum/humanizeEnum.pipe";

@Component({
  selector: "form-dialog",
  templateUrl: "./form-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormDialog implements OnInit, OnDestroy {

    public iterator: { id: number, name: string }[] = [];
    public protosui = protosui;
    public formResult: IFormDialogReturnData = { data: undefined, files: new Map() };
    public hidePassword = true;
    public mediaType = cat.MediaType;
    public uitext = protosui.messages.uitext;
    public editForm: FormGroup;
    public selectionOptions: { value: number, label: string }[];
    public submitted = false;

    constructor(
        public dialogRef: MatDialogRef<FormDialog>,
        public common: CommonService,
        private _logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) public data: IFormDialogData
    ) {}

    // Function to detect changes in object select.
    public compareObjectIds(o1: any, o2: any) {
        return (o1.id == o2.id);
    }

    /**
     * Attach the data, if applicable.
     *
     * @memberof FormDialog
     */
    ngOnInit() {
        try {
            this._logger.debug("Data", this.data);

            const msgDef = this.data?.definition?.msg?.[this.data?.name];

            if (!msgDef) {
                throw new Error("No definition found.");
            }

            if (this.data?.fieldData?.[this.data.name]) {
                this.editForm = this.common.createFormGroup(this.data.fieldData);

                if (msgDef.enumerator) {
                    this.selectionOptions = new HumanizeEnumPipe(this._logger).transform(msgDef.enumerator);
                }

                this.editForm.patchValue({
                    [this.data.name]: this.data.msg[this.data.name]
                })

            }

            if (!msgDef) {
                this.closeDialog("cancel");
                this.common.createSnackbar(this.uitext.prerequisites);
                throw new Error(this.uitext.prerequisites);
            }

            // Add enum array list to choose from
            if (msgDef.type === 'select' && msgDef.enumerator) {
                // Note: language flag to filter out inactive languages.
                this.iterator = this.common.humanifyEnum(this.data.definition.msg[this.data.name], (msgDef.enumerator === messageDefinitions.SupportedLanguages));
            }

            // Bind the result to the input data.
            this.formResult.data = this.data;
            this.formResult.files = new Map();
            this._logger.debug("Dialog iterator", this.iterator);

        } catch (error) {
            this._logger.error(error);
            this.dialogRef.close();
        }

    }

    ngOnDestroy(): void {
        // Append the form value (if found).
        if (this.editForm?.value?.[this.data.name]) {
            this.formResult.data.msg[this.data.name] = this.editForm.value[this.data.name];
        }
    }

    public change(event) {
        this._logger.debug("Change event", event);
    }

    /**
     * Handle the uploaded file.
     *
     * @param {FileList} files
     * @memberof FormDialog
     */
    public async handleFileInput(files: FileList) {
        try {

            // Validate call arguments.
            this.common.validateCallArguments([
                ["files", files]
            ]);

            // Set files to save
            this.formResult.files.set(this.data.name, files);
            this._logger.debug("Files", files);

        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
     * Close the dialog.
     *
     * @param {("cancel" | "delete")} event
     * @memberof FormDialog
     */
    closeDialog(event: "cancel" | "delete") {
        this.dialogRef.close(event);
    }
}