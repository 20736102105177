import { Pipe, PipeTransform } from "@angular/core";

/**
 * Convert DayJS dates to a JS date.
 */
@Pipe({
    name: "toDate",
    pure: true
})
export class ToDatePipe implements PipeTransform {
    transform(value: any): number {
        return value.toDate();
    }
}
