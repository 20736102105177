// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { Injectable, inject } from "@angular/core";
import { Router, ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { cat } from "@assets/proto/msgs";
import { CommonService } from "@services/common/common.service";
import { Store } from "@ngxs/store";
import { TlService } from "@services/tl/tl.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { LoggerService } from "@services/logger/logger.service";
import { protosui } from "@definitions/definitions";
import { appRouteNames } from "@app/app.routes.names";
import { addMe } from "@store/actions";
import { ThemeService } from "@services/theme/theme.service";
import { ISignOut, IState } from "@shared/app-models";
import { v4 as uuidv4 } from "uuid";
import shajs from "sha.js";

@Injectable({
    providedIn: "root"
})
export class AuthService {

    public signOut = new Subject<ISignOut>();
    public isAuth: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loggedIn = false;

    constructor(
        private _router: Router,
        private _theme: ThemeService,
        private _translate: TlService,
        private _logger: LoggerService,
        private _common: CommonService,
        private _store: Store
    ) {}

    /**
    * Check if a user is logged in
    * @returns Promise<boolean>
    */
    isLoggedIn(): Observable<boolean> {
        return this.isAuth.asObservable();
    }

    /**
     * Go to the disconnected page.
     */
     async gotoDisconnected() {
        await this._router.navigate([appRouteNames.DISCONNECTED], { replaceUrl: true, skipLocationChange: true });
    }

    /**
     * Go to the login page.
     */
    async gotoLogin() {
        await this._router.navigate([appRouteNames.LOGIN], { replaceUrl: true });
    }

    /**
     * Log out the current user and remove the user session
     *
     * @param {boolean} showMessage logout message after logout.
     * @memberof AuthService
     */
    logOut(debugMessage: string, showMessage: boolean, noCall?: boolean, message?: string) {
        try {
            // Sign out (backend) with stored usersession
            this._logger.debug("Logging out...", arguments);
            // Notify websocket of sign out
            this.signOut.next({
                showMessage: showMessage,
                noCall: noCall,
                message: message,
                debugMessage: debugMessage
            } as ISignOut);
        } catch (error) {
            throw new Error(error);
        }
    }

   /**
    * Store the current route for navigating after refresh/disconnected websocket
    * @param {ActivatedRouteSnapshot} route The current route to store
    */
   async storeRoute(route: ActivatedRouteSnapshot, path?: string) {
    try {

        if (!path) {
            // Construct a full url, with all segments.
            const fullUrl = route?.pathFromRoot
                .map(activatedRoute => activatedRoute.url?.map(segment => segment.toString())?.join('/'))
                ?.join('/');

            if (fullUrl) {
                localStorage.setItem("route", fullUrl);
            }
        } else {
            localStorage.setItem("route", path);
        }

    } catch (error) {
        throw new Error(error);
    }
}

   /**
    * Restore the last known route of the current user
    */
    async restoreRoute() {
        try {

            if (this.hasUserSession()) {

                // Check at this point if the user has the right prerequisites set
                const me: cat.UserMsg = this._store.selectSnapshot((state: IState) => state.cat.Me.msg);
                const eula: cat.EulaMsg = this._store.selectSnapshot((state: IState) => state.cat.userGetEula).msg;
                const keycloakMode = this._store.selectSnapshot((state: IState) => state.cat.KeycloakMode);

                if ((!keycloakMode && me.passwordreset) || !me.licenseaccepted || !eula.validfrom || (me.licenseaccepted < 1) || (me.licenseaccepted < eula.validfrom)) {
                    await this._router.navigate([appRouteNames.PREREQUISITES], { replaceUrl: true,  });

                } else {
                    // Get the stored route
                    const route = localStorage.getItem("route");

                    if (route) {

                        // Set the last known route.
                        await this._router.navigate([route], { replaceUrl: true });

                    } else {
                        // Go to dashboard by default
                        this._logger.debug("No stored route");
                        await this._router.navigate([appRouteNames.DASHBOARD], { replaceUrl: true });
                    }
                }

            } else {
                this._logger.debug("No user session");
                await this.gotoLogin();
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Store the current version of the CAT deployment.
     *
     * @param {string} version
     * @memberof AuthService
     */
    public storeVersion(version: string) {
        try {
            if (!version || typeof version !== "string") {
                throw new Error("No version provided.");
            }
            this._logger.debug(`Store CAT version ${version}`);
            localStorage.setItem("catVersion", version);
        } catch (error) {
            this._logger.error(error);
            throw new Error(error);
        }
    }

    /**
     * Get the current version of the CAT deployment.
     *
     * @returns {string}
     * @memberof AuthService
     */
    public getVersion(): string {
        try {
            const version = localStorage.getItem("catVersion");
            this._logger.debug(`Get current CAT version ${version}`);
            return version;
        } catch (error) {
            this._logger.error(error);
            throw new Error(error);
        }
    }

    /**
    * Check if a user has the proper permissions to access a route
    * @param {ActivatedRouteSnapshot} route The current route to check
    * @returns boolean
    */
    public hasRoutePermission(route: ActivatedRouteSnapshot): boolean {
        try {

            let hasPermission = false;

            // Always allowed routes
            const _allowedRoutes: Array<string> = [appRouteNames.DASHBOARD, `${appRouteNames.USER_SETTINGS}/:userid`, appRouteNames.NOTIFICATIONS, appRouteNames.HELP];

            if (_allowedRoutes.includes(route.routeConfig.path) && this.hasUserSession()) {
                hasPermission = true;
            } else {
                // Check if one of the provided route permissions was found for the user.
                const storedPermissions = this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserPermissions).list;
                for (const permission of route?.data?.permissions) {
                    hasPermission = storedPermissions.has(cat.Permission[permission]);
                    if (hasPermission) {
                        break;
                    }
                }
            }

            return hasPermission;

        } catch (error) {
            this._logger.error(error);
        }
    }

   /**
    * Generate a unique ID based on the date and PID
    * @returns Promise<string> (Base64)
    */
    generateId(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            try {
                const uuid = uuidv4();
                const callId: string = btoa(uuid);
                resolve(callId);
            } catch (error) {
                reject(new Error(error));
            }
        });
    }

   /**
    * Generate a SHA256 hash from a string, used for passwords
    * @param {string} value The string value
    * @returns Promise<string> (Base64)
    */
    public async createHash(value: string): Promise<string> {
        try {
            return shajs("sha256").update(value).digest("hex");
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Remove all locally stored user details, except for persistent storage.
     *
     * @returns {Promise<boolean>}
     * @memberof AuthService
     */
    public removeLocalStorage(): boolean {
        try {

            // List of persistent storage items.
            const persistentKeys = ["isMenuOpen", "catVersion", "tableColumns_", "backPath"];

            // Collect all entries we need to persist.
            const filteredEntries = Object.entries(localStorage).filter(([key]) => persistentKeys.some(item => key.startsWith(item)));

            // Clear all local storage items.
            localStorage.clear();
            this.deleteAllCookies();

            // Restore persistent items.
            filteredEntries.map(([key, value]) => localStorage.setItem(key, value));

            return true;
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Set all cookies to expired.
     *
     * @private
     * @memberof AuthService
     */
    private deleteAllCookies() {
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
            const equalSign = cookie.indexOf("=");
            const name = (equalSign < 0)
                ? cookie
                : cookie.substring(0, equalSign);
            if (name) {
                document.cookie = `${name}=; Path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
            }
        }
    }

   /**
    * Check whether the current user has a user session
    * @returns boolean
    */
    hasUserSession(): boolean {
        try {
            // Get the user session from storage
            const usersession: cat.UserSessionMsg = this.getUserSession();
            return (usersession?.userid && usersession?.token && usersession?.username) !== undefined;
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Get user session keys from persistent storage.
     *
     * @returns {<cat.UserSessionMsg>}
     * @memberof AuthService
     */
      public getUserSession(): cat.UserSessionMsg {
        try {
            const storedUsm: cat.UserSessionMsg = cat.UserSessionMsg.create(JSON.parse(localStorage.getItem("usersession")));
            return storedUsm;
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Set the backpath in local storage.
     *
     * @memberof AuthService
     */
    public setBackPath(backPath: string) {
        if (!backPath) {
            throw new Error(protosui.messages.uitext.prerequisites);
        }
        try {
            localStorage.setItem("backPath", backPath);
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Get the backpath from local storage.
     *
     * @returns {string}
     * @memberof AuthService
     */
    public getBackPath(): string {
        try {
            const backPath: string = localStorage.getItem("backPath");
            return backPath;
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Clear the backpath from local storage.
     *
     * @memberof AuthService
     */
    public clearBackPath() {
        try {
            localStorage.removeItem("backPath");
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Clear meta data from user session.
     *
     * @memberof AuthService
     */
      public clearMetadata() {
        try {
            // Fetch the locally stored user session
            const storedUsm: cat.UserSessionMsg = cat.UserSessionMsg.create(JSON.parse(localStorage.getItem("usersession")));
            // Fetch the locally stored user session
            const emptyMetadata: cat.UserSessionMsg = cat.UserSessionMsg.create({
                caseid: "",
                profileid: "",
                expires: null
            });
            // Append the user session with the new provided values
            const newUsm: cat.UserSessionMsg = cat.UserSessionMsg.create({
                ...storedUsm,
                ...emptyMetadata
            });
            // Store the new user session
            localStorage.setItem("usersession", JSON.stringify(cat.UserSessionMsg.toObject(newUsm)));

        } catch (error) {
            throw new Error(error);
        }
    }

    /**
    * Modify specific user session keys from persistent storage
    * @param {cat.UserSessionMsg} usm User session key/value pairs to be modified
    */
    public modifyUserSessionKeys(usm: cat.UserSessionMsg) {
        try {

            if (!usm) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            // Fetch the locally stored user session
            const storedUsm: cat.UserSessionMsg = cat.UserSessionMsg.create(JSON.parse(localStorage.getItem("usersession")));

            // Append the user session with the new provided values
            const newUsm: cat.UserSessionMsg = cat.UserSessionMsg.create({
                ...storedUsm,
                ...usm
            });
            // Store the new user session
            localStorage.setItem("usersession", JSON.stringify(cat.UserSessionMsg.toObject(newUsm)));

        } catch (error) {
            throw new Error(error);
        }
    }

    /**
    * @example
    * updateUser(cat.UserMsg.create())
    * Update the user, set the language and store it locally
    * @param {cat.UserMsg} user The current user
    * @returns boolean
    */
    async updateUser(user: cat.UserMsg) {
        try {
            if (!user || !user.id) {
                throw new Error("Invalid argument");
            }
            const myuser: cat.UserMsg = cat.UserMsg.create(JSON.parse(localStorage.getItem("user")));
            if (user && myuser && (user.id === myuser.id)) {
                localStorage.setItem("user", JSON.stringify(cat.UserMsg.toObject(user)));

                // Store the user
                this._translate.useLanguage(user.language);
                this._store.dispatch(new addMe(user));
                this._theme.setTheme(user.appearancemode);
            }
        } catch (error) {
            throw new Error(error);
        }
    }


   /**
    * Check if a user may activate a route with a promise.
    * @param {ActivatedRouteSnapshot} route The current route to activate
    * @returns boolean
    */
    canActivate(route: ActivatedRouteSnapshot): boolean {

        // Don't allow by default.
        let isAllowed = false;

        if (route.routeConfig.path === appRouteNames.LOGOUT) {
            this.logOut("[Log out] Don't check route activation, can always be activated.", false);
            isAllowed = true;
        } else if (this.hasUserSession() && this.hasRoutePermission(route)) {
            this.storeRoute(route);
            isAllowed = true;
        }

        if (!isAllowed) {
            this._common.createSnackbar(protosui.messages.uitext.nopermission);
        }

        return isAllowed;
    }
}


export const catAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => inject(AuthService).canActivate(route);