import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LoggerService } from "@services/logger/logger.service";
import { IDialogData } from "@shared/app-models";
import { protosui } from "@definitions/definitions";

@Component({
  selector: "generic-dialog",
  templateUrl: "./generic-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericDialog implements OnInit {

    public title = "";
    public content = "";
    public buttons = [];
    public uitext = protosui.messages.uitext;

    constructor(
        public dialogRef: MatDialogRef<GenericDialog>,
        private _logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) private data: IDialogData
        ) {}

    /**
     * Attach the data, if applicable.
     *
     * @memberof GenericDialog
     */
    ngOnInit() {
        this.title = this.data.title;
        this.content = this.data.content;
        this.buttons = this.data.buttons || [];
        this._logger.warn("[Generic dialog] init.")
    }

    /**
     * Close the dialog with the close button.
     *
     * @memberof GenericDialog
     */
    closeDialog(event: "cancel" | "delete") {
        this.dialogRef.close(event);
    }
}