import { Store } from "@ngxs/store";
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { userGetThemeUsers, userGetThemes, userGetThemeUserProfileAccounts } from "@assets/proto/services";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { WsService } from "@services/ws/ws.service";
import { getList } from "@store/store";
import { Observable } from "rxjs";
import { IState } from "@shared/app-models";
import { MatSelectChange } from "@angular/material/select";
import { clearList, clearStoreItem } from "@store/actions";
import { protosui } from "@definitions/definitions";


/** Decorator that marks a class as an Angular component */
@Component({
    selector: "widget-systemwide-search-themes",
    templateUrl: "./widget-systemwide-search-themes.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetSystemwideSearchThemesComponent implements OnInit, OnDestroy {

    isThemesLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetThemes?.isLoading);
    themes$: Observable<cat.ThemeMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetThemes));
    users$: Observable<cat.UserMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetThemeUsers));

    public profiles$: Observable<cat.ProfileMsg[]>;
    public uitext = protosui.messages.uitext;
    public permissionEnum = cat.Permission;
    public selectedTheme: cat.ThemeMsg;
    public selectedUser: cat.UserMsg;

    constructor(
        public common: CommonService,
        private _logger: LoggerService,
        private _store: Store,
        private _cdr: ChangeDetectorRef,
        private _ws: WsService
    ) {
    }

    /**
     * Initialize the widget
     */
    ngOnInit() {
        this.getThemes();
    }

    /**
     * Delete subscriptions
     *
     * @memberof WidgetSystemwideSearchThemesComponent
     */
    ngOnDestroy() {
    }

    /**
     * Change event when a theme is selected.
     *
     * @memberof WidgetSystemwideSearchThemesComponent
     */
    public async onChangeTheme(event: MatSelectChange) {
        try {
            this.selectedTheme = event.value;
            this._logger.debug("Theme selection changed", this.selectedTheme);
            await this.getUsers();
            this.common.detectChange(this._cdr);
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Get the themes.
     */
    public async getThemes() {
        try {
            this.selectedTheme = undefined;
            this.selectedUser = undefined;
            this._store.dispatch(new clearList(userGetThemeUsers.methodName));
            this._store.dispatch(new clearList(userGetThemeUserProfileAccounts.methodName));

            await this._ws.sendRequest(userGetThemes);
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Change event when a user is selected.
     *
     * @memberof WidgetSystemwideSearchThemesComponent
     */
    public async onChangeUser(event: MatSelectChange) {
        try {
            this.selectedUser = event.value;
            this._logger.debug("User selection changed", this.selectedUser);
            await this.getProfileAccounts();
            this.common.detectChange(this._cdr);
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Get the users that belong to the theme.
     */
    public async getUsers() {
        try {
            this.selectedUser = undefined;
            this._store.dispatch(new clearList(userGetThemeUserProfileAccounts.methodName));

            if (this.selectedTheme) {
                const payload = cat.ThemeMsg.create({ id: this.selectedTheme.id });
                await this._ws.sendRequest(userGetThemeUsers, payload);
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Get the profiles and accounts for the user and theme.
     *
     * @memberof WidgetSystemwideSearchThemesComponent
     */
    public async getProfileAccounts() {
        try {
            if (this.selectedTheme && this.selectedUser) {
                const query: cat.QueryMsg = cat.QueryMsg.create({
                    references: {
                        [cat.ReferenceType.REFERENCE_REMOTE_USER_ID]: this.selectedUser.id,
                        [cat.ReferenceType.REFERENCE_THEME_ID]: this.selectedTheme.id
                    }
                });
                await this._ws.sendRequest(userGetThemeUserProfileAccounts, query);

                this.profiles$ = this._store.select((state: IState) => getList(state.cat.userGetThemeUserProfileAccounts) as cat.ProfileMsg[]);
                this.common.detectChange(this._cdr);
            }
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }
}
