

import { Store } from "@ngxs/store";
import { Location } from "@angular/common";
import { Component, ChangeDetectionStrategy, Input, inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { CommonService } from "@services/common/common.service";
import { WsService } from "@services/ws/ws.service";
import { IState } from "@shared/app-models";
import { getList } from "@store/store";
import { Observable } from "rxjs";
import * as calls from "@assets/proto/services";
import { LoggerService } from "@services/logger/logger.service";
import { protosui } from "@definitions/definitions";

/** Decorator that marks a class as an Angular component */
@Component({
    selector: "assign-user-profile",
    templateUrl: "./assign-user-profile.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AssignUserProfileComponent {

    @Input() profile: cat.ProfileMsg = cat.ProfileMsg.create();
    users$: Observable<cat.UserMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetUsersAssignableToProfile));

    public uitext = protosui.messages.uitext;
    public displayedColumns = ["fullname", "roles", "owner"];

    constructor(
        public common: CommonService,
        private _logger: LoggerService,
        private _store: Store,
        private _location: Location,
        private _ws: WsService
    ) {}

    async ngOnInit() {
        await this._ws.sendRequest(calls.userGetUsersAssignableToProfile);
    }

    /**
    * Toggle the owner of the profile
    * @param {cat.AccountMsg} user The selected user
    */
    public async toggleOwner(toggled: boolean, user: cat.UserMsg) {
        if (user && user.id) {
            const payload = cat.UserMsg.create({
                id: user.id,
                profiles: [cat.ProfileMsg.create({
                    id: this.profile.id
                })]
            });

            try {
                const profile = this._store.selectSnapshot((state: IState) => state.cat.userGetProfiles).list.get(this.profile.id);
                if (!toggled) {
                    await this._ws.sendRequest(calls.userSetUserProfileOwner, payload)
                    profile.user = user;
                } else {
                    await this._ws.sendRequest(calls.userRemoveUserProfileOwner, payload)
                    profile.user = undefined;
                    profile.reviewers.push(user);
                }

                await this.common.createSnackbar("Succesfully updated ownership");
            } catch (error) {
                await this.common.createSnackbar(error);
            }

            // Fetch new profiles to update views.
            await this.getProfiles();
        }
    }

   /**
    * Go back one step, in the navigation stack
    */
    goBack() {
       this._location.back();
    }

    /**
     * Request the user profiles.
     *
     * @private
     * @memberof AssignUserProfileComponent
     */
    private async getProfiles() {
        try {
            await this._ws.sendRequest(calls.userGetProfiles);
        } catch (error) {
            this._logger.error(error);
            await this.common.createSnackbar(error);
        }
    }
}
