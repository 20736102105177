<h4 mat-dialog-title class="cat-no-margin" color="dark">
    <b>{{ data.definition.msg[data.name].label | translate | replaceTerm }}</b>
</h4>

<div mat-dialog-content>

    <p>
        {{ data.definition.msg[data.name].desc | translate | replaceTerm }}
    </p>

    <form *ngIf="editForm else noForm" [formGroup]="editForm" class="cat-width-350 cat-no-margin cat-padding-top-sm" ngNativeValidate>
        <ng-container *ngIf="data.definition.msg[data.name].type === 'input'">
            <input-field
                [data]="data?.fieldData?.[data?.name]"
                [submitted]="submitted"
                [control]="editForm.get(data?.name)">
            </input-field>
        </ng-container>
        <ng-container *ngIf="data.definition.msg[data.name].type === 'password'">
            <password-field
                [data]="data?.fieldData?.[data?.name]"
                [submitted]="submitted"
                [control]="editForm.get(data?.name)">
            </password-field>
        </ng-container>
        <ng-container *ngIf="data.definition.msg[data.name].type === 'select'">
            <selection-field
                [data]="data?.fieldData?.[data?.name]"
                [submitted]="submitted"
                [control]="editForm.get(data?.name)"
                [options]="selectionOptions">
            </selection-field>
        </ng-container>
    </form>

    <ng-template #noForm>

        <mat-form-field *ngIf="data.definition.msg[data.name].type !== 'map'" appearance="fill" class="cat-full-width" (change)="change($event)">

            <mat-label *ngIf="data.definition.msg[data.name].type !== 'upload'">
                {{ data.definition.msg[data.name].label | translate | replaceTerm }}
            </mat-label>
    <!--
            | "date" | "boolean" |
            "birthday" | "list" | "repeatedstring" | "repeated_input" | "repeated_list" | "repeated_message" |
            "checkbox" | "checkbox_list" | "proto" | "filesize" -->

            <ng-container *ngIf="data.definition.msg[data.name].type === 'textarea'">
                <textarea matInput [(ngModel)]='data.msg[data.name]' rows="5" matInputAutofocus></textarea>
            </ng-container>

            <ng-container *ngIf="data.definition.msg[data.name].type === 'input'">
                <input matInput [(ngModel)]="data.msg[data.name]" matInputAutofocus>
            </ng-container>

            <!-- Password fields -->
            <ng-container *ngIf="data.definition.msg[data.name].type === 'password'">
                <input matInput [(ngModel)]="data.msg[data.name]" [type]="hidePassword ? 'password' : 'text'" class="cat-cursor-pointer">
            </ng-container>
            <!-- Must be outside the ng-container above -->
            <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="cat-cursor-pointer" *ngIf="data.definition.msg[data.name].type === 'password'">
                {{ hidePassword ? 'visibility_off' : 'visibility'}}
            </mat-icon>

            <!-- ENUM fields -->
            <ng-container *ngIf="data.definition.msg[data.name].type === 'select' && data.definition.msg[data.name].enumerator">
                <mat-select [(value)]="data.msg[data.name]" [multiple]="data.definition.msg[data.name].multiselect">
                    <mat-option *ngFor="let item of iterator trackBy: common.trackById" [(value)]="item.id">
                        {{ item.name | translate | replaceTerm }}
                    </mat-option>
                </mat-select>
            </ng-container>

            <!-- Extern list fields -->
            <ng-container *ngIf="data.definition.msg[data.name].type === 'select' && data.list?.length">

                <ng-container *ngIf="data.definition.msg[data.name].repeated else singleItem">
                    <mat-select [(value)]="data.msg[data.name]" [multiple]="data.definition.msg[data.name].multiselect" [compareWith]="compareObjectIds">
                        <mat-option *ngFor="let item of data.list trackBy: common.trackById" [value]="item">
                            {{ item.name | translate | replaceTerm }}
                        </mat-option>
                    </mat-select>
                </ng-container>

                <ng-template #singleItem>
                    <mat-select [(value)]="data.msg[data.name].id" [multiple]="data.definition.msg[data.name].multiselect">
                        <mat-option *ngFor="let item of data.list trackBy: common.trackById" [value]="item.id">
                            {{ item.name | translate | replaceTerm }}
                        </mat-option>
                    </mat-select>
                </ng-template>

            </ng-container>

            <ng-container *ngIf="data.definition.msg[data.name].type === 'upload'">

                <div class="cat-margin-bottom-md">
                    <p class="cat-no-margin">

                        <span class="cat-block">
                            <b>{{ "Selected file" | translate }}</b>:
                            <span *ngIf="formResult.files.get(data.name)?.length; else noSelectedFile">{{ formResult.files.get(data.name)[0].name }}</span>
                            <ng-template #noSelectedFile>
                                {{ "" | emptyValue }}
                            </ng-template>
                        </span>

                        <span *ngIf="data.msg[data.name] && data.msg[data.name]['filename']" class="cat-block">
                            <b>{{ "Current file" | translate }}</b>:
                            {{ data.msg[data.name]['filename'] }}
                            <span *ngIf="data.msg[data.name]['filesize']">({{ data.msg[data.name]['filesize'] | filesize }})</span>
                        </span>
                    </p>
                </div>

                <div class="cat-button-row cat-margin-top-xl">
                    <div *ngIf="!data.readonly" class="cat-button-row-item">
                        <button mat-flat-button color="primary" type="button" (click)="uploadInput.click()">
                            <span *ngIf="data.msg[data.name]; else noFile">
                                {{ "Change file" | translate }}
                            </span>
                            <ng-template #noFile>
                                {{ "Upload file" | translate }}
                            </ng-template>
                        </button>
                    </div>
                    <div *ngIf="data.msg[data.name] && data.msg[data.name]['fileid']" class="cat-button-row-item">
                        <a mat-button
                            mat-stroked-button
                            download
                            [href]="data.msg[data.name] | catsrc : 'download' : mediaType.MEDIA_REPORTTEMPLATES" >
                            {{ uitext.download | translate }}
                        </a>
                    </div>
                </div>

                <input hidden matInput class="cat-dev-null">
                <input hidden (change)="handleFileInput($event.target.files)" #uploadInput type="file" [accept]="data.definition.msg[data.name].mimeTypes.join()">
            </ng-container>

        </mat-form-field>

        <ng-container *ngIf="data.definition.msg[data.name].type === 'map'">
            <ng-container *ngFor="let mapItem of data.msg[data.name] | keys let last = last trackBy: common.trackByKey">
                <mat-form-field appearance="fill" class="cat-full-width" (change)="change($event)">
                    <div class="cat-padding-lg">
                        <span
                            *ngIf="data.definition.msg[data.name]?.enumerator &&
                            protosui.def[data.definition.msg[data.name].enumerator] &&
                            protosui.def[data.definition.msg[data.name].enumerator][mapItem.key]
                            else noEnum">
                            {{ protosui.def[data.definition.msg[data.name].enumerator][mapItem.key]?.label | translate }}
                        </span>
                        <ng-template #noEnum>
                            {{ mapItem.key }}
                        </ng-template>
                        <input [id]="mapItem.key" matInput [(ngModel)]='data.msg[data.name][mapItem.key]'>
                    </div>
                </mat-form-field>
            </ng-container>
        </ng-container>
    </ng-template>
</div>

<mat-dialog-actions align="end" class="cat-padding-bottom-lg cat-padding-x-lg">
    <button mat-stroked-button mat-dialog-close (click)="closeDialog('cancel')">{{ uitext.cancel | translate }}</button>
    <button mat-flat-button mat-dialog-close [mat-dialog-close]="formResult" cdkFocusInitial color="primary" [disabled]="editForm && !editForm.valid">{{ "Save" | translate }}</button>
</mat-dialog-actions>