<div class="cat-row">
    <div class="cat-col cat-width-50-percent cat-padding-lg cat-background-white cat-border-radius">

        <h6 class="cat-no-padding cat-no-margin">
            {{ "Active license" | translate }}
        </h6>

        <p class="cat-block cat-margin-y-lg cat-opacity-4 cat-lineheight-md">
            {{ permissionDef[permissionEnum[permissionEnum.PERMISSION_VIEW_CURRENT_LICENSE]].desc | translate | replaceTerm }}
        </p>

        <ng-container *ngIf="license$|async as license">

            <ng-container *ngIf="license.mode">
                <span class="cat-block cat-margin-top-lg">{{ "Mode: " | translate }}<b>{{ receiverModeDef[receiverModeEnum[license.mode]]?.label | translate }}</b></span>
                <span class="cat-block">{{ "Valid to: " | translate }}<b>{{ license.enddate | date: 'd MMMM y' : undefined : tl.locale }}</b></span>
            </ng-container>

            <ng-container *ngIf="!license.mode">
                <span class="cat-block cat-margin-bottom-sm">
                    {{ "No active license found." | translate }}
                </span>
            </ng-container>

            <div *ngIf="(permissionEnum.PERMISSION_MANAGE_LICENSES | hasPermission)" class="cat-col cat-push-right cat-justify-center">
                <button
                    (click)="gotoLicense(); $event.stopPropagation()"
                    mat-flat-button color="primary" class="cat-nowrap">
                    {{ "Import a license" | translate }}
                </button>
            </div>

        </ng-container>

    </div>
</div>