import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject, Optional, forwardRef } from "@angular/core";
import { setDatepickerMonth } from "@store/actions";
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { MatCalendarHeader, MatDatepickerIntl, MatCalendar } from "@angular/material/datepicker";
import dayjs from "dayjs/esm";


/** Decorator that marks a class as an Angular component */
@Component({
    selector: "calendar-header",
    templateUrl: "./calendar-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarHeader extends MatCalendarHeader<any> {
    constructor(
        _intl: MatDatepickerIntl,
        @Inject(forwardRef(() => MatCalendar)) calendar: MatCalendar<any>,
        @Optional() _dateAdapter: DateAdapter<any>,
        @Optional() @Inject(MAT_DATE_FORMATS) _dateFormats: MatDateFormats,
        changeDetectorRef: ChangeDetectorRef,
        private _store: Store
    ) {
        super(_intl, calendar, _dateAdapter, _dateFormats, changeDetectorRef);
        this.calendar.stateChanges.subscribe(() => changeDetectorRef.markForCheck());
    }

    /** Handle the period label. */
    currentPeriodClicked(): void {
        this.calendar.currentView = this.calendar.currentView == 'month' ? 'multi-year' : 'month';
    }

    /** Handle the previous button. */
    customPrev(): void {
        console.warn("Custom prev");
        this.previousClicked();
        const selectedMonth = dayjs(this.calendar.activeDate).format("YYYYMM");
        this._store.dispatch(new setDatepickerMonth(selectedMonth));
    }

    /** Handle the next button. */
    customNext(): void {
        console.warn("Custom next");
        this.nextClicked();
        const selectedMonth = dayjs(this.calendar.activeDate).format("YYYYMM");
        this._store.dispatch(new setDatepickerMonth(selectedMonth));
    }
}