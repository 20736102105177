import { ChangeDetectionStrategy, Component, inject, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LoggerService } from "@services/logger/logger.service";
import { IDialogDeviceStats, IState } from "@shared/app-models";
import { Observable } from "rxjs";
import { cat } from "@assets/proto/msgs";
import { Store } from "@ngxs/store";
import { getList } from "@store/store";
import { protosui } from "@definitions/definitions";
@Component({
  selector: "device-stats",
  templateUrl: "./device-stats.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceStats implements OnInit, OnDestroy {

    devices$: Observable<cat.DeviceMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetProfileDevices));

    public profileId = "";
    public packageName = "";
    public uitext = protosui.messages.uitext;

    constructor(
        public dialogRef: MatDialogRef<DeviceStats>,
        private _logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) private data: IDialogDeviceStats
        ) {}

    /**
     * Attach the data, if applicable.
     *
     * @memberof DeviceStats
     */
    ngOnInit() {
        this.profileId = this.data.profileId;
        this.packageName = this.data.packageName;
        this._logger.debug("[Generic dialog] init.")
    }

    /**
     * Destroy.
     *
     * @memberof DeviceStats
     */
    ngOnDestroy() {
        this._logger.debug("[Generic dialog] destroy.")
    }

    /**
     * Close the dialog with the close button.
     *
     * @memberof DeviceStats
     */
    closeDialog(event: "cancel" | "delete") {
        this.dialogRef.close(event);
    }
}