
<ng-container *ngIf="(profiles$ | async) as profiles">
    <div *ngFor="let item of profiles | groupby: groupBy : groupByMsgType let last = last trackBy: common.trackById" class="cat-margin-bottom-xl">

        <h5 color="dark" *ngIf="item.key !== 'null'" class="cat-no-padding cat-margin-bottom-md ">
            {{ item.key }}
        </h5>
        <h5 color="dark" *ngIf="item.key === 'null'" class="cat-no-padding cat-margin-bottom-md">
            {{ "Not assigned to a {theme}" | translate | replaceTerm }}
        </h5>

        <div class="grid-container">
            <div
                *ngFor="let profile of item.value trackBy: common.trackById"
                (click)="goToProfile(profile)"
                class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">

                <mat-card class="cat-dashboard cat-cursor-pointer">

                    <div *ngIf="profile.user && profile.reviewers && profile.reviewers.length"
                        (click)="showCollaborators(profile.user, profile.reviewers); $event.stopPropagation()"
                        class="cat-chip cat-cursor-pointer cat-position-right-bottom cat-on-top cat-padding-sm" color="white">
                        <div class="cat-chip-content cat-padding-xs">
                            <mat-icon class="cat-align-self-center cat-font-md" color="medium">visibility</mat-icon>
                            <span class="cat-margin-left-xs cat-margin-right-sm">
                                {{ profile.reviewers.length + 1 }}
                            </span>
                        </div>
                    </div>

                    <mat-card-content class="cat-no-padding cat-no-margin cat-full-width-height">

                        <button
                            mat-icon-button
                            [matMenuTriggerFor]="profileMenu"
                            [matMenuTriggerData]="{ profile: profile }"
                            id="profile-menu"
                            class="cat-sm cat-position-right-top cat-on-top cat-no-padding" color="white"
                            (click)="$event.stopPropagation()">
                            <mat-icon color="primary">more_vert</mat-icon>
                        </button>

                        <div class="cat-grid">

                            <div class="cat-row cat-gap-md cat-margin-md">

                                <div class="cat-col">

                                    <div class="cat-item cat-start cat-center cat-no-background cat-no-margin cat-no-padding">
                                        <div class="cat-avatar cat-md cat-no-margin cat-flex-center">
                                            <img
                                                *ngIf="(profile?.avatar | hasThumb) else noAvatar"
                                                class="cat-auto-width cat-full-height"
                                                [src]="profile.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_PROFILEAVATARS : profile.id">
                                            <ng-template #noAvatar>
                                                <img matTooltip="{{ uitext.noavatarfound | translate }}" matTooltipPosition="above"  src="assets/img/cat-avatar.svg">
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                                <div class="cat-col cat-flex-grow cat-no-min-width cat-margin-right-xl">

                                    <div class="cat-row">
                                        <h6 class="cat-nowrap cat-nooverflow-ellipsis cat-no-margin">
                                            {{ profile.fullname }}
                                        </h6>
                                    </div>
                                    <div class="cat-row cat-margin-top-md">
                                        <span *ngIf="(me$|async) as me" color="dark-tint" class="cat-nowrap cat-nooverflow-ellipsis cat-text-sm">
                                            <b>{{ uitext.role | translate }}</b>:
                                            <ng-container *ngIf="(me.id === profile.user?.id)">
                                                {{ "owner" | translate }}
                                            </ng-container>
                                            <ng-container *ngIf="(me.id !== profile.user?.id)">
                                                {{ "supervisor" | translate }}
                                            </ng-container>
                                        </span>
                                    </div>
                                    <div class="cat-row" *ngIf="profile.cases?.length">
                                        <span color="dark-tint" class="cat-nowrap cat-nooverflow-ellipsis cat-text-sm">
                                            <b>{{ uitext.case | translate | replaceTerm }}</b>:
                                            {{ profile.cases[0].name }}
                                            <ng-container *ngIf="profile.cases?.length > 1">
                                                (+{{ profile.cases?.length - 1 }})
                                            </ng-container>
                                        </span>
                                    </div>

                                    <div class="cat-row" *ngIf="profile.device">
                                        <span color="dark-tint" class="cat-nowrap cat-nooverflow-ellipsis cat-text-sm">
                                            <b>{{ "Device" | translate }}</b>:
                                            {{ profile.device.name }}
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <div class="cat-row cat-push-bottom cat-start cat-margin-md">
                                <ng-container *ngFor="let apptype of profile.apptypes trackBy: common.trackById">
                                    <div *ngIf="apptype.id" class="cat-col">
                                        <div class="cat-avatar cat-xs cat-margin-right-sm cat-no-background cat-no-border-radius cat-no-background">
                                            <img [src]="apptype.iconfileid | iconmedia">
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!profile.apptypes?.length">
                                    <span class="cat-opacity-2 cat-text-sm">{{ "No apps" | translate }}</span>
                                </ng-container>
                            </div>

                        </div>
                    </mat-card-content>
                </mat-card>

            </div>

        </div>
    </div>

    @if (!profiles?.length) {
        <div class="cat-margin-bottom-xl">
            {{ uitext.notfound | translate }}
        </div>
    }

    @if (permissionEnum.PERMISSION_MANAGE_MY_PROFILES | hasPermission) {
        <div class="grid-container">
            <div (click)="userAddProfile()" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-card class="cat-dashboard cat-cursor-pointer">
                    <mat-card-content class="cat-no-padding cat-no-margin cat-full-width-height cat-center cat-align-center">
                        <div class="cat-flex-container cat-align-center cat-padding-left-md">
                            <mat-icon color="primary">add</mat-icon>
                            <h6 class="cat-no-margin cat-padding-left-md" color="primary">{{ uitext.profile | translate | replaceTerm }}</h6>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    }

    <div class="cat-height-100"></div>
</ng-container>




<mat-menu #profileMenu="matMenu">
    <ng-template matMenuContent let-profile="profile">

        <button
            (click)="hideFromDashboard(profile)"
            mat-menu-item extended color="primary">
            <mat-icon>visibility</mat-icon>
            <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
                <span color="primary"><b>{{ "Hide from dashboard" | translate | replaceTerm }}</b></span>
                <br>
                <span color="medium-shade">{{ "Hides your {profile}" | translate | replaceTerm }}</span>
            </div>
        </button>

        <button
            (click)="showInfo(profile)"
            mat-menu-item extended color="primary">
            <mat-icon>info</mat-icon>
            <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
                <span color="primary"><b>{{ uitext.info | translate | replaceTerm }}</b></span>
                <br>
                <span color="medium-shade">{{ "Show info of the {profile}" | translate | replaceTerm }}</span>
            </div>
        </button>

        <button
            *ngIf="profile?.device && ([permissionEnum.PERMISSION_MANAGE_ASSIGNED_DEVICES, permissionEnum.PERMISSION_VIEW_ASSIGNED_DEVICES, permissionEnum.PERMISSION_DEVICE_AS_PROFILE_APP] | hasPermission)"
            (click)="gotoDevice(profile)"
            mat-menu-item extended color="primary">
            <mat-icon>smartphone</mat-icon>
            <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
                <span color="primary"><b>{{ "View device" | translate | replaceTerm }}</b></span>
                <br>
                <span color="medium-shade">{{ "View the device" | translate | replaceTerm }}</span>
            </div>
        </button>
    </ng-template>
</mat-menu>