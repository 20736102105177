<!-- Copyright 2018-2024, Volto Labs BV
All rights reserved -->

<div class="cat-row">
    <div class="cat-col cat-width-50-percent cat-padding-lg cat-background-white cat-border-radius">

        <h6 class="cat-no-padding cat-no-margin">
            {{ "Data cleaning" | translate }}
        </h6>

        <p class="cat-block cat-margin-y-lg cat-opacity-4 cat-lineheight-md">
            {{ permissionDef[permissionEnum[permissionEnum.PERMISSION_DATA_CLEANING]].desc | translate | replaceTerm }}
        </p>

        <p class="cat-block cat-margin-y-lg cat-lineheight-md">
            {{ uitext.status | translate }}: <span color="success-shade">{{ (status$ | async) }}</span>
        </p>

        <div class="cat-col cat-push-right cat-justify-center cat-margin-top-lg">
            <button
                (click)="start(); $event.stopPropagation()"
                mat-flat-button color="primary" class="cat-nowrap">
                {{ "Start data cleaning" | translate }}
            </button>
        </div>
    </div>
</div>