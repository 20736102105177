// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { cat } from "@assets/proto/msgs";
import { produce } from "immer";
import { INITIAL_STATE } from "@assets/proto/store-state";
import { INewDayMsg } from "@shared/app-models";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { IServiceDescriptor } from "@assets/proto/services";
import { Action, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { cloneDeep } from "lodash-es";
import { LoggerService } from "@services/logger/logger.service";

import * as actions from "@store/actions";
import * as storemodel from "@assets/proto/store-model";
@State<storemodel.IAppState>({
    name: "cat",
    defaults: INITIAL_STATE
})

@Injectable()
export class AppState {

    constructor(private _logger: LoggerService) {}

    // ----
    // ACTIONS
    // ----
    @Action(actions.setConnection)
    setConnection(ctx: StateContext<storemodel.IAppState>, action: actions.setConnection) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.WsConnection = action.isConnected;
        }));
    }

    @Action(actions.setWebsocketVersion)
    setWebsocketVersion(ctx: StateContext<storemodel.IAppState>, action: actions.setWebsocketVersion) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.WsVersion = action.versionMsg;
        }));
    }

    @Action(actions.addMe)
    addMe(ctx: StateContext<storemodel.IAppState>, payload: actions.addMe) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.Me.msg = payload.user;
        }));
    }

    @Action(actions.addCaptureQR)
    addCaptureQR(ctx: StateContext<storemodel.IAppState>, payload: actions.addCaptureQR) {

        const media: cat.MediaMsg = payload.qr;
        const fileId: string = media.fileid;

        if (media && fileId) {

            // To prevent mutation, make a copy
            const state = ctx.getState();
            const clonedMap = new Map(state.CaptureQR.list);
            clonedMap.set(fileId, media);

            ctx.setState(produce(ctx.getState(), draft => {
                draft.CaptureQR.list = clonedMap;
            }));
        } else {
            this._logger.error("[Store action] missing media.");
        }
    }

    @Action(actions.addCaptureAPKQR)
    addCaptureAPKQR(ctx: StateContext<storemodel.IAppState>, payload: actions.addCaptureAPKQR) {

        const media: cat.MediaMsg = payload.qr;
        const fileId: string = media.fileid;

        if (media && fileId) {

            // To prevent mutation, make a copy
            const state = ctx.getState();
            const clonedMap = new Map(state.CaptureQRApk.list);
            clonedMap.set(fileId, media);

            ctx.setState(produce(ctx.getState(), draft => {
                draft.CaptureQRApk.list = clonedMap;
            }));
        } else {
            this._logger.error("[Store action] missing media.");
        }
    }

    @Action(actions.setKeycloakMode)
    addKeycloakSettings(ctx: StateContext<storemodel.IAppState>, action: actions.setKeycloakMode) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.KeycloakMode = action.mode;
        }));
    }

    @Action(actions.userLogout)
    userLogout(ctx: StateContext<storemodel.IAppState>) {
        ctx.setState(INITIAL_STATE)
    }

    @Action(actions.resetConnectionCount)
    resetConnectionCount(ctx: StateContext<storemodel.IAppState>) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.WsRetryConnection = 0;
        }));
    }

    @Action(actions.incrementConnectionCount)
    incrementConnectionCount(ctx: StateContext<storemodel.IAppState>) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.WsRetryConnection = ctx.getState().WsRetryConnection + 1;
        }));
    }

    @Action(actions.addProtobufMsg)
    addProtobufMsg(ctx: StateContext<storemodel.IAppState>, payload: actions.addProtobufMsg) {

        // Create dynamic store path / message type
        const responseType = `${payload.call.responseType}`; // e.g. app types
        const message: any = payload.message;
        const call: IServiceDescriptor = payload.call;

        if (responseType !== messageDefinitions.VoidMsg) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft[call.methodName].msg = cat[responseType].create(message);
            }));
        } else {
            this._logger.warn("[Store] Void message returned, do nothing...");
        }
    }

    @Action(actions.setLoading)
    setLoading(ctx: StateContext<storemodel.IAppState>, payload: actions.setLoading) {

        const isLoading: boolean = payload.isLoading;
        const cursor: string = payload.cursor;

        if (cursor) {
            // Update all items loaded flag in state
            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].isLoading = isLoading;
            }));
        } else {
            this._logger.error("[Store action] missing cursor.");
        }
    }

    @Action(actions.addList)
    addList(ctx: StateContext<storemodel.IAppState>, payload: actions.addList) {

        const cursor: string = payload.cursor;
        const listMap: Map<string, any> = payload.listMap;

        if (cursor && listMap.size) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].list = listMap;
            }));
        } else {
            this._logger.error("[Store action] missing cursor / listmap.");
        }
    }

    @Action(actions.addListItem)
    addListItem(ctx: StateContext<storemodel.IAppState>, payload: actions.addListItem) {

        const cursor: string = payload.cursor;
        const message: any = payload.message;
        const messageType: string = payload.messageType;
        const itemId: string = message?.id || message?.name;

        // this._logger.warn("cursor", cursor);
        // this._logger.warn("message", message);
        // this._logger.warn("messageType", messageType);
        // this._logger.warn("itemId", itemId);

        if (cursor && itemId && messageType) {
            // To prevent mutation, make a copy
            const state = ctx.getState();
            const clonedMap = new Map(state[cursor].list);
            clonedMap.set(itemId, cat[messageType].create(message));

            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].list = clonedMap;
            }));
        } else {
            this._logger.error("[Store action: addListItem] missing cursor / itemid / messagetype.");
        }
    }

    @Action(actions.clearList)
    clearList(ctx: StateContext<storemodel.IAppState>, payload: actions.clearList) {

        const cursor: string = payload.cursor;
        this._logger.debug(`Clear list: ${cursor}`);

        if (cursor) {

            // To prevent mutation, make a copy
            const state = ctx.getState();
            const clonedMap = new Map(state[cursor].list);
            clonedMap.clear();

            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].list = clonedMap;
            }));
        } else {
            this._logger.error("[Store action] missing cursor.");
        }
    }

    @Action(actions.addArrayItem)
    addArrayItem(ctx: StateContext<storemodel.IAppState>, payload: actions.addArrayItem) {

        const cursor: string = payload.cursor;
        const message: string = payload.message;

        if (cursor && message) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].push(message);
            }));
        } else {
            this._logger.error("[Store: addArrayItem] missing cursor / message.");
        }
    }

    @Action(actions.addInfiteList)
    addInfiteList(ctx: StateContext<storemodel.IAppState>, payload: actions.addInfiteList) {

        const cursor: string = payload.cursor;
        const listMap: Map<string, any> = payload.listMap;

        if (cursor && listMap.size) {

            // To prevent mutation, make a copy
            const state = ctx.getState();
            const clonedMap: Map<string, any> = new Map(state[cursor].infinite);
            const newMap = new Map([...clonedMap, ...listMap]);

            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].infinite = newMap;
            }));
        } else {
            this._logger.error("[Store: addInfiteList] missing cursor / listmap.");
        }
    }

    @Action(actions.modifyInfiteList)
    modifyInfiteList(ctx: StateContext<storemodel.IAppState>, payload: actions.modifyInfiteList) {

        const cursor: string = payload.cursor;
        const message: any = payload.message;
        const messageType: string = payload.messageType;
        const itemId: string = message?.id || message?.name;

        if (cursor && message && messageType && itemId) {

            // To prevent mutation, make a copy
            const state = ctx.getState();
            const clonedMap: Map<string, any> = new Map(state[cursor].infinite);
            clonedMap.set(itemId, cat[messageType].create(message));

            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].infinite = clonedMap;
            }));
        } else {
            this._logger.error("[Store: modifyInfiteList] missing cursor / message / message type / item id.");
        }
    }

    @Action(actions.prependToInfiteArray)
    prependToInfiteArray(ctx: StateContext<storemodel.IAppState>, payload: actions.prependToInfiteArray) {

        const state = ctx.getState();
        const cursor: string = payload.cursor;
        const list: any[] = payload.list;

        // this._logger.debug("list", list);
        // this._logger.debug("cursor", cursor);

        if (cursor && list.length) {
            if (cursor && list?.length) {
                ctx.setState(produce(state, draft => {
                    draft[cursor].infiniteArray.unshift(...list);
                }));
            }
        } else {
            this._logger.error("[Store: prependToInfiteArray] missing cursor / list.");
        }
    }

    @Action(actions.appendToInfiteArray)
    appendToInfiteArray(ctx: StateContext<storemodel.IAppState>, payload: actions.appendToInfiteArray) {

        const state = ctx.getState();
        const cursor: string = payload.cursor;
        const list: any[] = payload.list;

        if (cursor && list?.length) {
            ctx.setState(produce(state, draft => {
                draft[cursor].infiniteArray.push(...list);
            }));
        } else {
            this._logger.error("[Store: appendToInfiteArray] missing cursor / list.");
        }
    }

    @Action(actions.removeDateFromInfiniteArray)
    removeDateFromInfiniteArray(ctx: StateContext<storemodel.IAppState>, payload: actions.removeDateFromInfiniteArray) {

        const cursor: string = payload.cursor;
        const message: any = payload.message;

        if (cursor && message) {

            const state = ctx.getState();
            const index = state[cursor]?.infiniteArray?.findIndex(item => item.isNewDay && (item.id === message.id));

            if (index > -1) {
                ctx.setState(produce(ctx.getState(), draft => {
                    draft[cursor].infiniteArray.splice(index, 1);
                }));
            } else {
                this._logger.error("[Store: removeDateFromInfiniteArray] Message not found to remove from infinite array.");
            }
        } else {
            this._logger.error("[Store: removeDateFromInfiniteArray] Missing cursor / message.");
        }
    }

    @Action(actions.setDatepickerMonth)
    setDatepickerMonth(ctx: StateContext<storemodel.IAppState>, payload: actions.setDatepickerMonth) {
        const yearmonth: string = payload.yearmonth;
        if (yearmonth) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft.DatepickerMonth = yearmonth;
            }));
        } else {
            this._logger.error("[Store: setDatepickerMonth] Missing yearmonth.");
        }
    }

    @Action(actions.modifyItem)
    modifyItem(ctx: StateContext<storemodel.IAppState>, payload: actions.modifyItem) {

        const cursor: string = payload.cursor;
        const message: any = payload.message;

        if (cursor && message) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].msg = message;
            }));
        } else {
            this._logger.error("[Store: modifyItem] missing cursor / message.");
        }
    }

    @Action(actions.modifyListItem)
    modifyListItem(ctx: StateContext<storemodel.IAppState>, payload: actions.modifyListItem) {

        const cursor: string = payload.cursor;
        const message: any = payload.message;
        const messageType: string = payload.messageType;
        const itemId: string = message?.id || message?.name;

        if (cursor && message && messageType && itemId) {

            // To prevent mutation, make a copy
            const state = ctx.getState();
            const clonedMap = new Map(state[cursor].list);
            clonedMap.set(itemId, cat[messageType].create(message));

            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].list = clonedMap;
            }));
        } else {
            this._logger.error("[Store: modifyListItem] missing cursor / message / message type / item id.");
        }
    }

    @Action(actions.removeListItem)
    removeListItem(ctx: StateContext<storemodel.IAppState>, payload: actions.removeListItem) {

        const cursor: string = payload.cursor;
        const message: any = payload.message;
        const itemId: string = message?.id || message?.name;

        // this._logger.debug("cursor", cursor);
        // this._logger.debug("message", message);
        // this._logger.debug("itemId", itemId);

        if (cursor && message && itemId) {

            // To prevent mutation, make a copy
            const state = ctx.getState();
            const clonedMap = new Map(state[cursor].list);
            clonedMap.delete(itemId);

            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].list = clonedMap;
            }));
        } else {
            this._logger.error("[Store: removeListItem] missing cursor / message / item id.");
        }
    }

    @Action(actions.clearMessageList)
    clearMessageList(ctx: StateContext<storemodel.IAppState>) {
        const chatItem: storemodel.IuserGetConversationMessages = storemodel.createuserGetConversationMessages({ msg: cat.MessageMsg.create() });
        const topicItem: storemodel.IuserGetTopicMessages = storemodel.createuserGetTopicMessages({ msg: cat.MessageMsg.create() });
        ctx.setState(produce(ctx.getState(), draft => {
            draft.userGetConversationMessages = chatItem;
            draft.userGetTopicMessages = topicItem;
        }));
    }

    @Action(actions.clearStoredMessage)
    clearStoredMessage(ctx: StateContext<storemodel.IAppState>, payload: actions.clearStoredMessage) {

        const cursor: string = payload.cursor;
        const messageType: string = payload.messageType;

        if (cursor && messageType) {

            // To prevent mutation, make a copy
            const message = cat[messageType]?.toObject(cat[messageType].create({}), { defaults: true, arrays: true });
            const emptyMessage = cat[messageType]?.create(message);

            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].msg = emptyMessage;
            }));
        } else {
            this._logger.error("[Store: clearStoredMessage] missing cursor / message type.");
        }
    }

    @Action(actions.clearStoreItem)
    clearStoreItem(ctx: StateContext<storemodel.IAppState>, payload: actions.clearStoreItem) {
        this._logger.debug("Clear store item", payload);
        const service: IServiceDescriptor = payload?.service;
        const emptyMessage = cat[service?.responseType]?.create();

        if (service && emptyMessage) {
            const emptyStoreItem: storemodel.IStoreItem = {
                isLoading: false,
                allitemsloaded: false,
                msg: emptyMessage,
                list: new Map<string, cat.VoidMsg>(),
                infinite: new Map<string, cat.VoidMsg>(),
                infiniteArray: []
            }

            ctx.setState(produce(ctx.getState(), draft => {
                draft[service.methodName] = emptyStoreItem;
            }));
        } else {
            this._logger.error("[Store: clearStoreItem] missing service / empty message.");
        }
    }

    @Action(actions.clearArray)
    clearArray(ctx: StateContext<storemodel.IAppState>, payload: actions.clearArray) {
        const cursor: string = payload.cursor;
        if (cursor) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor] = [];
            }));
        } else {
            this._logger.error("[Store: clearArray] missing cursor.");
        }
    }

    @Action(actions.clearInfiniteArray)
    clearInfiniteArray(ctx: StateContext<storemodel.IAppState>, payload: actions.clearInfiniteArray) {

        const cursor: string = payload.cursor;

        if (cursor) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].infiniteArray = [];
            }));
        } else {
            this._logger.error("[Store: clearInfiniteArray] missing cursor.");
        }
    }

    @Action(actions.clearInfiniteList)
    clearInfiniteList(ctx: StateContext<storemodel.IAppState>, payload: actions.clearInfiniteList) {

        const cursor: string = payload.cursor;

        if (cursor) {
            const state = ctx.getState();
            const clonedMap = new Map(state[cursor].infinite);
            clonedMap.clear();

            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].infinite = clonedMap;
            }));
        } else {
            this._logger.error("[Store: clearInfiniteList] missing cursor.");
        }
    }

    @Action(actions.updateMessageAttachment)
    updateMessageAttachment(ctx: StateContext<storemodel.IAppState>, payload: actions.updateMessageAttachment) {

        const updatedAttachment: cat.AttachmentMsg = payload.attachment;
        const messageId: string = payload.messageId;

        // this._logger.debug("updatedAttachment", updatedAttachment);
        // this._logger.debug("messageId", messageId);

        if (updatedAttachment && messageId) {

            const state = ctx.getState();
            const msgIndex = state.userGetConversationMessages.infiniteArray.findIndex((msg: cat.MessageMsg) => (msg.id === messageId));
            const clonedMessage: cat.MessageMsg = cloneDeep(state.userGetConversationMessages.infiniteArray.at(msgIndex));

            // this._logger.debug("msgIndex", msgIndex);
            // this._logger.debug("clonedMessage", clonedMessage);

            if (clonedMessage) {

                // Check if the attachment already exists
                const attIndex = clonedMessage.attachments?.findIndex((attachment: cat.AttachmentMsg) => attachment.id === updatedAttachment.id);

                // Update or add an attachment
                if (attIndex < 0) {
                    clonedMessage.attachments.push(updatedAttachment);
                } else {
                    clonedMessage.attachments[attIndex] = updatedAttachment;
                }

                ctx.setState(produce(state, draft => {
                    draft.userGetConversationMessages.infiniteArray[msgIndex] = clonedMessage;
                }));

            } else {
                this._logger.warn("[Store: updateMessageAttachment] message not found.")
            }

        } else {
            this._logger.error("[Store: updateMessageAttachment] missing attachment / message id.");
        }
    }

    @Action(actions.updateMessageReaction)
    updateMessageReaction(ctx: StateContext<storemodel.IAppState>, payload: actions.updateMessageReaction) {

        const updatedReaction: cat.ReactionMsg = payload.reaction;
        const messageId: string = payload.messageId;

        if (updatedReaction && messageId) {

            const state = ctx.getState();
            const clonedMap = new Map(state.userGetConversationMessages.infinite);
            const clonedMessage = cat.MessageMsg.create(state.userGetConversationMessages.infinite.get(messageId));

            // Check if the attachment already exists
            const foundIndex = clonedMessage.reactions.findIndex((reaction: cat.ReactionMsg) => reaction.id === updatedReaction.id);

            if (foundIndex < 0) {
                clonedMessage.reactions.push(updatedReaction);
            } else {
                clonedMessage.reactions[foundIndex] = updatedReaction;
            }

            clonedMap.set(messageId, clonedMessage);

            ctx.setState(produce(ctx.getState(), draft => {
                draft.userGetConversationMessages.infinite = clonedMap;
            }));
        } else {
            this._logger.error("[Store: updateMessageReaction] missing reaction / message id.");
        }
    }

    @Action(actions.updateContactAnnotations)
    updateContactAnnotations(ctx: StateContext<storemodel.IAppState>, payload: actions.updateContactAnnotations) {

        const annotations: cat.AnnotationMsg[] = payload.annotations;
        const messageId: string = payload.messageId;

        if (annotations?.length && messageId) {

            const state = ctx.getState();
            const clonedMap = new Map(state.userGetProfileContacts.infinite);
            const clonedMessage = cat.AccountMsg.create(state.userGetProfileContacts.infinite.get(messageId));
            clonedMessage.annotations = annotations;
            clonedMap.set(messageId, clonedMessage);

            ctx.setState(produce(ctx.getState(), draft => {
                draft.userGetProfileContacts.infinite = clonedMap;
            }));
        } else {
            this._logger.error("[Store: updateContactAnnotations] missing annotation(s) / message id.");
        }
    }

    @Action(actions.updateDeviceSyncProgress)
    updateDeviceSyncProgress(ctx: StateContext<storemodel.IAppState>, payload: actions.updateDeviceSyncProgress) {

        const device: cat.DeviceMsg = payload.device;
        const cursor: string = payload.cursor;
        const deviceId: string = device.id;
        const deviceStatus: cat.ReceiverBufferStatusMsg[] = device.receiverbufferstatus as cat.ReceiverBufferStatusMsg[];
        const state = ctx.getState();

        if (device && cursor) {

            // To prevent mutation, make a copy
            const clonedMap = new Map(state[cursor].list);
            const clonedDevice = cat.DeviceMsg.create(state[cursor].list.get(deviceId));

            if (clonedDevice) {
                // Attach new status to device
                clonedDevice.receiverbufferstatus = deviceStatus;
                clonedMap.set(deviceId, clonedDevice);

                ctx.setState(produce(ctx.getState(), draft => {
                    draft[cursor].list = clonedMap;
                }));
            } else {
                return state;
            }

        } else {
            this._logger.error("[Store: updateDeviceSyncProgress] missing device / cursor.");
        }
    }

    @Action(actions.selectContact)
    selectContact(ctx: StateContext<storemodel.IAppState>, payload: actions.selectContact) {

        const contact: cat.AccountMsg = payload.contact;

        if (contact) {

            const state = ctx.getState();
            const clonedArray = state.SelectedContacts.concat(contact);

            ctx.setState(produce(ctx.getState(), draft => {
                draft.SelectedContacts = clonedArray;
            }));
        } else {
            this._logger.error("[Store: selectContact] missing contact.");
        }
    }

    @Action(actions.deselectContact)
    deselectContact(ctx: StateContext<storemodel.IAppState>, payload: actions.deselectContact) {

        const contact: cat.AccountMsg = payload.contact;

        if (contact) {

            const state = ctx.getState();
            const clonedArray = state.SelectedContacts.filter((item: cat.AccountMsg) => item.id !== contact.id);

            ctx.setState(produce(ctx.getState(), draft => {
                draft.SelectedContacts = clonedArray;
            }));
        } else {
            this._logger.error("[Store: deselectContact] missing contact.");
        }
    }

    @Action(actions.clearSelectedContacts)
    clearSelectedContacts(ctx: StateContext<storemodel.IAppState>) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.SelectedContacts = [];
        }));
    }

    @Action(actions.selectConversation)
    selectConversation(ctx: StateContext<storemodel.IAppState>, payload: actions.selectConversation) {

        const conversation: cat.ConversationMsg = payload.conversation;

        if (conversation) {

            const state = ctx.getState();
            // Only add chats that are not selected already.
            if (state.SelectedConversations.findIndex((storedChat) => storedChat.id === conversation.id) < 0) {
                const clonedArray = state.SelectedConversations.concat(conversation);
                ctx.setState(produce(ctx.getState(), draft => {
                    draft.SelectedConversations = clonedArray;
                }));
            } else {
                this._logger.debug(`Not adding conversation ${conversation.id}, already selected.`);
            }

        } else {
            this._logger.error("[Store: selectConversation] missing conversation.");
        }
    }

    @Action(actions.deselectConversation)
    deselectConversation(ctx: StateContext<storemodel.IAppState>, payload: actions.deselectConversation) {

        const conversation: cat.ConversationMsg = payload.conversation;

        if (conversation) {

            const state = ctx.getState();
            const clonedArray = state.SelectedConversations.filter((item: cat.ConversationMsg) => item.id !== conversation.id);

            ctx.setState(produce(ctx.getState(), draft => {
                draft.SelectedConversations = clonedArray;
            }));
        } else {
            this._logger.error("[Store: deselectConversation] missing conversation.");
        }
    }

    @Action(actions.clearSelectedConversations)
    clearSelectedConversations(ctx: StateContext<storemodel.IAppState>) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.SelectedConversations = [];
        }));
    }

    @Action(actions.userSetTerminology)
    userSetTerminology(ctx: StateContext<storemodel.IAppState>, payload: actions.userSetTerminology) {

        const terminology: cat.TerminologyMsg = payload.terminology;

        if (terminology) {

            const state = ctx.getState();
            let clonedTerm = cloneDeep(state.Me.msg.terminology.find(term => term.id === terminology.id));

            Object.keys(terminology?.singular).map(termKey => {
                clonedTerm["singular"][termKey] = terminology?.singular[termKey]
            });

            Object.keys(terminology?.plural).map(termKey => {
                clonedTerm["plural"][termKey] = terminology?.plural[termKey]
            });

            if (!clonedTerm || clonedTerm === undefined) {
                clonedTerm = terminology;
            }

            ctx.setState(produce(ctx.getState(), draft => {
                draft.Me.msg.terminology[terminology.id] = clonedTerm;
            }));
        } else {
            this._logger.error("[Store: userSetTerminology] missing terminology.");
        }
    }

    @Action(actions.lockSession)
    lockSession(ctx: StateContext<storemodel.IAppState>) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.SessionLocked = true;
        }));
    }

    @Action(actions.unlockSession)
    unlockSession(ctx: StateContext<storemodel.IAppState>) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.SessionLocked = false;
        }));
    }

    @Action(actions.updateListItem)
    updateListItem(ctx: StateContext<storemodel.IAppState>, payload: actions.updateListItem) {

        const message: any = payload.message;
        const cursor: string = payload.cursor;
        const messageType: string = payload.messageType;
        const itemId: string = message?.id || message?.name;

        if (message && cursor && messageType) {

            const state = ctx.getState();
            // To prevent mutation, make a copy
            const clonedMap = new Map(state[cursor].list);
            clonedMap.set(itemId, cat[messageType].create(message));

            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor].list = clonedMap;
            }));
        } else {
            this._logger.error("[Store: updateListItem] missing message / cursor / message type.");
        }
    }

    @Action(actions.setDashboardAngle)
    setDashboardAngle(ctx: StateContext<storemodel.IAppState>, payload: actions.setDashboardAngle) {

        const angle: "profile" | "device" | "stats" = payload.angle;

        if (angle) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft.DashboardAngle = angle;
            }));
        } else {
            this._logger.error("[Store: setDashboardAngle] missing angle.");
        }
    }

    @Action(actions.updateMessage)
    updateMessage(ctx: StateContext<storemodel.IAppState>, payload: actions.updateMessage) {

        const updatedMessage: cat.MessageMsg = payload.message;
        const cursor: string = payload.cursor;

        // this._logger.debug("updatedMessage", updatedMessage);
        // this._logger.debug("cursor", cursor);

        if (updatedMessage && cursor) {

            const state = ctx.getState();

            const messageCurrentIdx = state[cursor]?.infiniteArray
                .findIndex((message: cat.MessageMsg | INewDayMsg) => !(message as INewDayMsg)?.isNewDay && (message.id === updatedMessage.id));


            ctx.setState(produce(state, draft => {
                draft[cursor].infiniteArray[messageCurrentIdx] = updatedMessage;
            }));

        } else {
            this._logger.error("[Store: updateMessage] missing message / cursor.");
        }
    }

    @Action(actions.updateInfiniteItem)
    updateInfiniteItem(ctx: StateContext<storemodel.IAppState>, payload: actions.updateInfiniteItem) {

        const updatedMessage: any = payload.message;
        const cursor: string = payload.cursor;
        // this._logger.debug("updateInfiniteItem", updatedMessage);
        // this._logger.debug("cursor", cursor);


        if (updatedMessage && cursor) {

            const state = ctx.getState();
            const currentIdx = state[cursor]?.infiniteArray?.findIndex((item: any) => (item.id === updatedMessage.id));

            if (currentIdx > -1) {
                ctx.setState(produce(ctx.getState(), draft => {
                    draft[cursor].infiniteArray[currentIdx] = updatedMessage;
                }));
            }

        } else {
            this._logger.error("[Store: updateInfiniteItem] missing message / cursor.");
        }
    }

    @Action(actions.removeInfiniteItem)
    removeInfiniteItem(ctx: StateContext<storemodel.IAppState>, payload: actions.removeInfiniteItem) {

        const message: any = payload.message;
        const cursor: string = payload.cursor;

        if (message && cursor) {

            const state = ctx.getState();
            const index = state[cursor]?.infiniteArray?.findIndex(item => (item.id === message.id));

            if (index > -1) {
                ctx.setState(produce(ctx.getState(), draft => {
                    draft[cursor].infiniteArray.splice(index, 1);
                }));
            }


        } else {
            this._logger.error("[Store: removeInfiniteItem] missing message / cursor.");
        }
    }

    @Action(actions.togglePermission)
    togglePermission(ctx: StateContext<storemodel.IAppState>, payload: actions.togglePermission) {

        const permissionId = payload.permissionId;
        const roleId = payload.roleId;
        const addPermission = payload.addPermission;

        const state = ctx.getState();
        // To prevent mutation, make a copy
        const clonedMap = new Map(state.userGetRolesWithPermissions.list);
        const rolePermissions = clonedMap.get(roleId);

        if (addPermission) {
             rolePermissions.permissions.push(cat.PermissionMsg.create({ id: permissionId }));
        } else {
            const idx = rolePermissions.permissions.findIndex(permission => permission.id === permissionId);
            rolePermissions.permissions.splice(idx, 1);
        }

        clonedMap.set(roleId, rolePermissions);
        ctx.setState(produce(ctx.getState(), draft => {
            draft.userGetRolesWithPermissions.list = clonedMap;
        }));
    }

    @Action(actions.setStatData)
    setStatData(ctx: StateContext<storemodel.IAppState>, payload: actions.setStatData) {

        const cursor = payload.cursor;
        const stats = payload.stats;

        // this._logger.debug("cursor", cursor);
        // this._logger.debug("stats", stats);

        if (cursor) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor] = stats;
            }));
        } else {
            this._logger.error("[Store: setStatData] missing cursor.");
        }
    }

    @Action(actions.clearStatData)
    clearStatData(ctx: StateContext<storemodel.IAppState>, payload: actions.clearStatData) {
        const cursor = payload.cursor;
        // this._logger.debug("cursor", cursor);
        if (cursor) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft[cursor] = [];
            }));
        } else {
            this._logger.error("[Store: clearStatData] missing cursor.");
        }
    }

    @Action(actions.setExcludedReportMessages)
    setExcludedReportMessages(ctx: StateContext<storemodel.IAppState>, payload: actions.setExcludedReportMessages) {
        const messageIds = payload.messageIds;
        // this._logger.debug("messageIds", messageIds);
        ctx.setState(produce(ctx.getState(), draft => {
            draft.ExcludeReportMessages = messageIds;
        }));
    }

    @Action(actions.clearExcludedReportMessages)
    clearExcludedReportMessages(ctx: StateContext<storemodel.IAppState>) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.ExcludeReportMessages = [];
        }));
    }

    @Action(actions.addRoleColumn)
    addRoleColumn(ctx: StateContext<storemodel.IAppState>, action: actions.addRoleColumn) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.RoleColumns.push(action.column);
        }))
    }

    @Action(actions.clearRoleColumn)
    clearRoleColumn(ctx: StateContext<storemodel.IAppState>, action: actions.addRoleColumn) {
        const state = ctx.getState();
        ctx.setState(produce(state, draft => {
            draft.RoleColumns = draft.RoleColumns.filter(item => item != action.column);
        }))
    }

    @Action(actions.clearRoleColumns)
    clearRoleColumns(ctx: StateContext<storemodel.IAppState>) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.RoleColumns = [];
        }))
    }

    @Action(actions.setPreviewURL)
    setPreviewURL(ctx: StateContext<storemodel.IAppState>, payload: actions.setPreviewURL) {
        const url: string = payload.url;
        if (url) {
            ctx.setState(produce(ctx.getState(), draft => {
                draft.ReportPreviewURL = url;
            }));
        } else {
            this._logger.error("[Store action] missing URL.");
        }
    }

    @Action(actions.clearPreviewURL)
    clearPreviewURL(ctx: StateContext<storemodel.IAppState>) {
        ctx.setState(produce(ctx.getState(), draft => {
            draft.ReportPreviewURL = "";
        }));
    }
}

/**
* Custom selector for selecting a single item from a list with an item id
* @param {IAppState} state The current state
* @param {string} cursor The store cursor path
* @param {string} id The ID of the item
* @returns Array<any>
*/
export const getListItemById = (state: storemodel.IAppState, cursor: string, id: string) => state[cursor].list.get(id);

/**
* Custom selector for selecting a complete list and convert the map to an array for easy iteration
* @param {any} item The current state item
* @returns Array<any>
*/
export const getList = (item: storemodel.IStoreItem) => Array.from(item.list.values());

/**
* Custom selector for selecting a infinite list and convert the map to an array for easy iteration
* @param {IStoreItem} item The current state item
* @returns Array<any>
*/
export const getInifiteList = (item: storemodel.IStoreItem) =>  Array.from(item.infinite.values());