<h2 mat-dialog-title>
    {{ uitext.areyousure | translate }}
</h2>
<mat-dialog-content>

    <div class="cat-padding-md cat-margin-bottom-lg">

        <span class="cat-block cat-margin-bottom-lg">
            {{ uitext.abouttoencrypt | translate }}
        </span>
        <span color="danger">
            <b>
                {{ uitext.irreversible | translate }}
            </b>
        </span>
    </div>

    <mat-checkbox color="primary" [(ngModel)]="checked">
        {{ uitext.iaccept | translate }}
    </mat-checkbox>

</mat-dialog-content>


<mat-dialog-actions align="end" class="cat-padding-bottom-lg cat-padding-x-lg">

    <button (click)="closeDialog('cancel')" mat-stroked-button mat-dialog-close cdkFocusInitial color="primary">
        {{ uitext.cancel | translate }}
    </button>

    <button [disabled]="!checked" (click)="closeDialog('submit')" mat-flat-button color="primary">
        {{ uitext.save | translate }}
    </button>

</mat-dialog-actions>