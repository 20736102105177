<div class="cat-nooverflow-ellipsis">

    <div class="cat-padding-xl">

        <h4 class="cat-no-margin">
            {{ "Access to {profile}:" | translate | replaceTerm }}
        </h4>

        <div class="cat-overflow-y cat-margin-top-xl cat-height-250">

            <div class="cat-item cat-no-margin cat-no-background">
                <div class="cat-grid cat-margin-left-md">
                    <span class="cat-block cat-font-lg cat-padding-y-md">
                        <b>{{ owner.fullname }}</b>
                        <span color="success">
                            ({{ uitext.owner | translate }})
                        </span>
                    </span>
                </div>
            </div>

            <div
                *ngFor="let user of reviewers trackBy: common.trackById"
                class="cat-item cat-no-margin cat-no-background">
                <div class="cat-grid cat-margin-left-md">
                    <span class="cat-block cat-font-lg cat-padding-y-md"><b>{{ user.fullname }}</b></span>
                </div>
            </div>

        </div>

    </div>

</div>
