import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, inject } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngxs/store";
import { cat } from "@assets/proto/msgs";
import { userGetAvailableDeviceLogFiles, userGetDeviceLogFileId } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";
import { WsService } from "@services/ws/ws.service";
import { setDatepickerMonth, setLoading } from "@store/actions";
import { IState } from "@shared/app-models";
import { CatSrcPipe } from "@pipes/catsrc/catsrc";
import { CalendarHeader } from "@components/calendar/calendar-header/calendar-header.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SubscriptionManager } from "@shared/subscription-manager";
import dayjs from "dayjs/esm";

@Component({
    selector: "app-download-log",
    templateUrl: "./download-log.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadLogComponent extends SubscriptionManager implements OnInit {

    // Redux store selectors
    availableDatesLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetAvailableDeviceLogFiles.isLoading);
    logLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetDeviceLogFileId.isLoading);

    // Datepicker properties
    private _logRetentionDays = 365;
    public logSelectedValue = new Date();
    public maxDate = new Date();
    public minDate = dayjs().subtract(this._logRetentionDays, 'days').toDate();
    public availableDates: string[] = [
        // YEAR MONTH DAY e.g. "20211220"
    ];
    public customHeader = CalendarHeader;
    public dayFilter: any;
    public pendingFileId: string = "";
    public uitext = protosui.messages.uitext;

    constructor(
        public tl: TlService,
        private _ws: WsService,
        private _logger: LoggerService,
        private _common: CommonService,
        private _cdr: ChangeDetectorRef,
        private _store: Store,
        private _dialogRef: MatDialogRef<DownloadLogComponent>,
        @Inject(MAT_DIALOG_DATA) public device: cat.DeviceMsg = cat.DeviceMsg.create()
    ) {
        super();
        if (!device) {
            this._common.createSnackbar(protosui.messages.uitext.prerequisites);
            throw new Error(protosui.messages.uitext.prerequisites);
        }
    }

    /** Initialize the component */
    ngOnInit() {
        this.device = this.device;
        this.addSubscriptions([
            this._store.select((state: IState) => state.cat.DatepickerMonth).subscribe(async (pickerDate: string) => {
                if (!pickerDate) {
                    // Do nothing
                } else {
                    this._logger.debug(`pickerDate: ${pickerDate}`);
                    await this.userGetAvailableDeviceLogFiles(pickerDate);
                }
            }),
            // Listen for generated log files.
            this._ws.notifications
                .get(cat.NotificationType.NOTIFICATION_FILE)
                .subscribe((notification: cat.NotificationMsg) => this.handleFileNotification(notification))
        ])

        // Fetch logs for the current month, change month and trigger observable
        const selectedMonth = dayjs().format("YYYYMM");
        this._store.dispatch(new setDatepickerMonth(selectedMonth));
    }

    /**
     * Determine for which days log files are available.
     *
     * @memberof DownloadLogComponent
     */
     public async userGetAvailableDeviceLogFiles(selectedDate: string) {
        try {

            if (selectedDate && selectedDate.length) {
                const query: cat.QueryMsg = cat.QueryMsg.create({
                    search: selectedDate
                });

                // Get aavailable dates.
                await this._ws.sendRequest(userGetAvailableDeviceLogFiles, cat.DeviceMsg.create({ id: this.device.id }), undefined, undefined, query);
                const days: string[] = this._store.selectSnapshot((state: IState) => state.cat.userGetAvailableDeviceLogFiles).msg.values;

                // Clear the available dates;
                this.availableDates = [];

                // Convert days to desired format and push into available dates
                days.map((day: string) => {
                    const yearmonthday = selectedDate + ((day.length < 2) ? `0${day}` : day);
                    this.availableDates.push(yearmonthday);
                });

                // Instatiate a new filter, after the available dates is updated, async dayfilter is not available
                this.dayFilter = (d: Date | null): boolean => {
                    return d
                        ? this.availableDates.includes(dayjs(d).format("YYYYMMDD"))
                        : false
                };

                if (!this.availableDates?.length) {
                    this._common.createSnackbar(protosui.messages.uitext.nologsavailable);
                }

                this._common.detectChange(this._cdr);

            } else {
                this._logger.debug("No date selected, do nothing...");
            }
        } catch (error) {
            this._logger.error(error);
            this._common.createSnackbar(error);
        }
    }

    /**
     * Handle generated file and download it.
     *
     * @private
     * @param {cat.NotificationMsg} notification The notification.
     * @memberof ManageLogsPage
     */
    private async handleFileNotification(notification: cat.NotificationMsg) {
        try {

            if (!notification || !notification.references || !Object.keys(notification.references).length) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            const fileId: string = notification.references[cat.ReferenceType.REFERENCE_FILE_ID];

            if (!fileId) {
                throw new Error(protosui.messages.uitext.invalidnotification);
            }

            // Sometimes the notification is quicker than userGetDeviceLogFileId.
            if (!this.pendingFileId) {
                await this._common.timeout(500);
            }

            if (fileId === this.pendingFileId) {

                // Construct the download URL.
                const mediaFile = cat.MediaFileMsg.create({ fileid: fileId });
                const url = new CatSrcPipe().transform(mediaFile, "download", cat.MediaType.MEDIA_LOG, "device");

                // Download the log file.
                const link: HTMLAnchorElement = document.createElement("a");
                link.download = fileId;
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Clear the pending media object.
                this.pendingFileId = "";
                this._common.detectChange(this._cdr);
            } else {
                this._logger.warn("Retrieved file id differs from pending file id.");
            }

            this._store.dispatch(new setLoading(false, userGetDeviceLogFileId.methodName));

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Create a filename and download the zipped log file.
     *
     * @param {cat.DeviceMsg} device
     * @memberof DownloadLogComponent
     */
    public async downloadLogs(device: cat.DeviceMsg) {
        try {

            if (!device || !device.id) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            // Always filter with date (should go in search field?)
            const payload: cat.QueryMsg = cat.QueryMsg.create({
                references: {
                    [cat.ReferenceType.REFERENCE_DEVICE_ID]: device.id
                },
                search: dayjs(this.logSelectedValue).format("YYYYMMDD")
            });

            // Fetch the log of the selected day
            await this._ws.sendRequest(userGetDeviceLogFileId, payload);
            this._store.dispatch(new setLoading(true, userGetDeviceLogFileId.methodName));
            const fileId = this._store.selectSnapshot((state: IState) => state.cat.userGetDeviceLogFileId.msg.fileid);

            // Assign the retrieved file id to the pending media.
            if (fileId) {
                this.pendingFileId = fileId;
                this._common.detectChange(this._cdr);
            } else {
                throw new Error("No file id returned.");
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /** Close the dialog. */
    public dismiss() {
        this._dialogRef.close();
    }
}