import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { IFilterPipeOptions } from "@shared/app-models";

/**
 * Filter data
 */
@Pipe({
    name: "filter",
    pure: true
})
export class FilterPipe implements PipeTransform {
    transform(value: any, options: IFilterPipeOptions) {

        if (value?.length) {

            if (options?.sel && options?.type && options?.path && options?.value) {

                if (options.type === "enumerator") {
                    let cats = cat;
                    options.path.map(arg => { if (cats[arg]) { cats = cats[arg]; } });
                    return value.filter(v => {
                        return v[options.sel] === cats[options.value];
                    });
                }

            } else if (options?.type && (options?.type === "mimetype") && options?.value) {

                return value.filter(item => {
                    if (item.mimetype) {
                        return item.mimetype.includes(options.value);
                    }
                });

            } else if (options?.type && options?.value && (options?.type === "string") && options?.value.trim() !== "") {
                return value.filter(item => {
                    if (item.body) {
                        return item.body.toLowerCase().includes(options.value.toLowerCase());
                    }
                });

            } else if (options?.type && options?.value && options?.key && options?.type === "extension") {

                const extensionType: string = options.value;
                return value.filter(item => item[options.key].endsWith(extensionType));

            } else if (options?.type && options?.type === "id") {
                return value.filter(v => {
                    if (v.id && options.value) {
                        return v.id.toLowerCase() === options.value.toLowerCase();
                    } else {
                        return;
                    }
                });
            } else if (options?.type === "ids") {

                if (options.value && options.value.length) {
                    return value.filter((v: any) => {
                        if (v.id) {
                            const lowerValue: string[] = options.value.map((val: any) => {
                                let result: string;
                                if (typeof val === "string") {
                                    result = val.toLowerCase();
                                } else if (typeof val === "object" && val.key && val.key.length) {
                                    result = val.key.toLowerCase();
                                }
                                return result;
                            });
                            return (lowerValue.includes(v.id.toLowerCase()));
                        }
                    });

                } else {
                    return [];
                }

            } else if (options?.type === "path" && options?.value) {

                // Options.value = object path e.g. ["field", "key"]
                if (options.path.length) {

                    for (const arrayItem of value) {

                        let result: any = arrayItem;

                        for (const objKey of options.path) {

                            if (result[objKey] && typeof result[objKey] === "object") {
                                result = result[objKey];
                            } else if (result[objKey] === options.value) {
                                return result;
                            }
                        }
                    }

                } else {
                    return [];
                }

            } else if (options?.type && (options?.type === "read" || options?.type === "unread")) {
                return value.filter((v: any) => (options.type === "unread") ? (v.read < 1) : (v.read > 0));
            } else if (options?.type && (options?.type === "true" || options?.type === "false")) {
                return value.filter((v: any) => v.value === (options.type === "true"));
            } else {
                return value;
            }
        } else {
            return value;
        }
    }
}
