<div class="cat-list cat-padding-lg cat-margin-bottom-xl">

    <h6 class="cat-no-padding cat-no-margin cat-text-lg">
        {{ uitext.reactions | translate }}
    </h6>

    <ng-container *ngIf="(reactions$|async) as reactions">
        <div *ngIf="!(loading$|async)" class="cat-list cat-margin-top-lg cat-max-height-lg cat-overflow-y">
            <ng-container *ngFor="let reaction of reactions let last = last trackBy: common.trackById">
                <reaction
                    class="cat-block"
                    [class.cat-margin-bottom-md]="!last"
                    [account]="data.account"
                    [reaction]="reaction"
                    [loading]="(loading$|async)">
                </reaction>
            </ng-container>
            <div *ngIf="!reactions.length" class="cat-item cat-no-background">
                {{ uitext.notfound | translate }}
            </div>
        </div>

    </ng-container>

    <div
        *ngIf="(loading$|async)"
        class="cat-item cat-no-padding cat-no-margin">
    <div class="cat-label cat-block cat-font-lg">{{ uitext.loading | translate  }}</div>
</div>
