import { Pipe, PipeTransform } from "@angular/core";
import { protosui } from "src/definitions/definitions";
import { cat } from "@assets/proto/msgs";

@Pipe({
  name: "getEnumLabel",
  pure: true
})
/**
* Get the definition label for a specific enum value
* @param {string} enumType The enum type.
* @param {number} enumValue The enum value.
* @returns {string} The best available label.
*/
export class GetEnumLabelPipe implements PipeTransform {
    transform(enumType: string, enumValue: number): string {
        let label = "";
        if (enumType && cat[enumType] && cat[enumType][enumValue] &&
            protosui.def[enumType] && protosui.def[enumType][cat[enumType][enumValue]] &&
            protosui.def[enumType][cat[enumType][enumValue]].label) {
            label = protosui.def[enumType][cat[enumType][enumValue]].label;
        }
        return label;
    }
}
