import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LoggerService } from "@services/logger/logger.service";
import { CommonService } from "@services/common/common.service";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";

@Component({
  selector: "documentation-dialog",
  templateUrl: "./documentation-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentationDialog implements OnInit {

    @ViewChild("doc", {static: false}) doc: ElementRef;

    public mediaType = cat.MediaType;
    public uitext = protosui.messages.uitext;

    constructor(
        public dialogRef: MatDialogRef<DocumentationDialog>,
        public common: CommonService,
        private _logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) public data: { doc: string, title: string}) {}

    ngOnInit() {
        this._logger.debug("Data", this.data);
    }

    /**
     * Close the dialog.
     *
     * @param {("cancel" | "delete")} event
     * @memberof FormDialog
     */
    closeDialog(event: "cancel" | "delete") {
        this.dialogRef.close(event);
    }
}