import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from "@services/common/common.service";
import { cat } from "@assets/proto/msgs";
import { IMessageDefinitions } from "@shared/app-models";
import { protosui } from "@definitions/definitions";
import { TlService } from "@services/tl/tl.service";

@Component({
  selector: "device-properties-modal",
  templateUrl: "./device-properties.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicePropertiesModal implements OnInit {

    public device: cat.DeviceMsg = cat.DeviceMsg.create();
    public properties: cat.DevicePropertiesMsg = cat.DevicePropertiesMsg.create();
    public devicePropertiesDefinition: IMessageDefinitions = { msg: protosui.def.DevicePropertiesMsg };
    public uitext = protosui.messages.uitext;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DevicePropertiesModal>,
        public common: CommonService,
        public tl: TlService,
    ) {}

    /**
    * Fetch the properties everytime the popup opens
    */
    ngOnInit() {
        this.device.properties = this.data?.device?.properties;
    }

    /**
     * Close dialig by button.
     *
     * @memberof DevicePropertiesModal
     */
    closeDialog() {
        this.dialogRef.close();
    }
}