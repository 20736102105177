import { Component, OnInit, ChangeDetectionStrategy, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { protosui } from "@definitions/definitions";
import { IReviewersData } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { cat } from "@assets/proto/msgs";

@Component({
    selector: "app-reviewers-popover",
    templateUrl: "./popover-reviewers.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewersPopoverComponent implements OnInit {

    public owner: cat.UserMsg = cat.UserMsg.create();
    public reviewers: cat.UserMsg[] = [];
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        @Inject(MAT_DIALOG_DATA) public data: IReviewersData
    ) {
        if (!this.data?.reviewers) {
            this.common.createSnackbar(protosui.messages.uitext.prerequisites);
            throw new Error(protosui.messages.uitext.prerequisites);
        }
    }

    /**
    * Fetch the params everytime the popup opens
    */
    ngOnInit() {
        this.owner = this.data?.owner;
        this.reviewers = this.data?.reviewers;
    }
}
