<mat-form-field appearance="fill" [color]="(noBackground) ? 'transparent' : 'white'" class="cat-full-width cat-margin-right-xl cat-flex-grow" [class.cat-dense]="dense" subscriptSizing="dynamic">
    <span matPrefix>
        <mat-icon class="cat-animation-rotate-clockwise" [class.cat-opacity-6]="disabled">search</mat-icon>
    </span>

    @if (multiSearch) {
        <span matPrefix>
            <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="disabled">
                <mat-icon>arrow_drop_down</mat-icon>
            </button>
        </span>
    }
    <mat-label>
        @if (!multiSearch) {
            {{ searchLabel | translate | replaceTerm }}
        } @else {
            {{ uitext.search | translate }} {{ selectedType.name | lowercase | translate }}
        }
        {{ (searchCount) ? ('(' + searchCount + ')') : '(0)' }}
    </mat-label>
    <input matInput [ngModel]="searchValue" [disabled]="disabled" (input)="onInputChange($event.target.value)" #searchField1 matInputAutofocus>
    @if (searchValue && !disabled) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchvalue()">
        <mat-icon>close</mat-icon>
        </button>
    }
</mat-form-field>

<mat-menu #menu="matMenu">
    @for (type of searchTypes; track type) {
        <button mat-menu-item (click)="selectType(type)" [class.cat-selected]="type.value === selectedType.value">
            {{ type.name }}
        </button>
    }
</mat-menu>