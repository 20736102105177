import { Component, ChangeDetectionStrategy, OnDestroy, HostListener, OnInit, ChangeDetectorRef, inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { cat } from "@assets/proto/msgs";
import { userGetGlobalAppStatistics } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { IState, statisticInterval } from "@shared/app-models";
import { Store } from "@ngxs/store";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";
import { WsService } from "@services/ws/ws.service";
import { clearStatData, setStatData } from "@store/actions";
import { Observable } from "rxjs";
import dayjs from "dayjs/esm";
import { getList } from "@store/store";

@Component({
    selector: "widget-statistics",
    templateUrl: "./widget-statistics.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetStatisticsComponent implements OnInit, OnDestroy {

    @HostListener("unloaded")
    ngOnDestroy() {
        this._logger.debug("Destroy WidgetStatisticsComponent");
        this._store.dispatch(new clearStatData("GlobalSuccessStats"));
    }

    isLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetGlobalAppStatistics?.isLoading);
    globalSuccessStats$: Observable<{ name: string, series: { name: string, value: number }[] }[]> = inject(Store).select((state: IState) => state.cat.GlobalSuccessStats);
    apptypes$: Observable<cat.AppTypeMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetAppTypes));

    public chartSelect = new FormControl("");
    public intervals: statisticInterval[] = [
        { value: "hour", viewValue: protosui.messages.uitext.last12hours  },
        { value: "week", viewValue: protosui.messages.uitext.last7days },
        { value: "month", viewValue: protosui.messages.uitext.last30days },
        { value: "halfyear", viewValue: protosui.messages.uitext.last6months  }
    ];
    public selectedInterval = this.intervals[0].value;
    public yAxisLabel = protosui.messages.uitext.captureditems;
    public colorScheme = { domain: [] };
    // App filter
    public selectedApps: string[] = [];
    public appFilter = cat.AppType[cat.AppType.APP_DEVICE];


    constructor(
        public common: CommonService,
        public tl: TlService,
        private _ws: WsService,
        private _cdr: ChangeDetectorRef,
        private _store: Store,
        private _logger: LoggerService    ) {
        this._logger.debug("Construct WidgetProfileComponent");
    }

    async ngOnInit() {
        this.chartSelect.patchValue(this.intervals[0].value);
        await this.refreshStatistics();
    }

    public async selectInterval(event: MatSelectChange) {
        this._logger.debug("Event", event);
        this.selectedInterval = event.value || "hour";
        this._logger.debug("selectedInterval", this.selectedInterval);
        await this.refreshStatistics();
    }

    async refreshStatistics() {
        // Reset color.
        this.colorScheme.domain = [];
        // Reset stat data.
        this._store.dispatch(new clearStatData("GlobalSuccessStats"));
        // Fetch new data.
        await this.userGetGlobalAppStatistics();
    }

    /**
     * Get the device stats.
     *
     * @private
     * @memberof DevicePage
     */
     private async userGetGlobalAppStatistics() {
        try {

            let duration: dayjs.UnitType = "hour";
            let durationCount = 12;
            let dateFormat = "HH";

            switch (this.selectedInterval) {
                case "hour": {
                    duration = "hour";
                    durationCount = 12;
                    dateFormat = "HH";
                    break;
                }
                case "week": {
                    duration = "days";
                    durationCount = 6;
                    dateFormat = "DD/MM";
                    break;
                }
                case "month": {
                    duration = "days";
                    durationCount = 29;
                    dateFormat = "DD/MM";
                    break;
                }
                case "halfyear": {
                    duration = "months";
                    durationCount = 5;
                    dateFormat = "MMM";
                    break;
                }
            }

            const dateFrom = dayjs().subtract(durationCount, duration).unix() * 1000;
            const query = cat.QueryMsg.create({
                filters: [
                    cat.FilterMsg.create({
                        collection: "statistics",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        field: "start",
                        value: dateFrom.toString()
                    }),
                    cat.FilterMsg.create({
                        collection: "statistics",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        field: "end",
                        value: Date.now().toString()
                    }),
                    cat.FilterMsg.create({
                        collection: "statistics",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        field: "interval",
                        value: duration
                    }),
                    cat.FilterMsg.create({
                        collection: "statistics",
                        operator: cat.FilterOperator.FILTER_INCLUDE,
                        field: "success",
                        value: "true"
                    })
                ]
            });

            // Filter on specific apps.
            if (this.selectedApps.length) {
                query.filters.push(cat.FilterMsg.create({
                    field: "appname",
                    collection: "statistics",
                    operator: cat.FilterOperator.FILTER_INCLUDE,
                    values: this.selectedApps.map((app: string) => app)
                }));
            }

            await this._ws.sendRequest(userGetGlobalAppStatistics, undefined, undefined, undefined, query);
            const stats = this._store.selectSnapshot((state: IState) => state.cat.userGetGlobalAppStatistics.msg);
            const labels = stats.labels.map(label => dayjs.unix(Number(label) / 1000).format(dateFormat));
            const chartData: { name: string, series: { name: string, value: number }[] }[] = [];

            for (const [idx, label] of labels.entries()) {
                const serie = [];
                for (const stat of stats.series) {
                    const value = (Number(stat.data[idx]) > 0)
                        ? Math.log10(Number(stat.data[idx]))
                        : 0;

                    // Add color and data if stat has a value
                    if (value > 0) {
                        // Add color scheme.
                        const color = this.common.appColors.get(stat.name.toLowerCase());
                        if (color && !this.colorScheme.domain.includes(color)) {
                            this.colorScheme.domain.push(color);
                        }
                        // Data
                        serie.push({
                            name: stat.name,
                            value: value
                        })
                    }

                }
                chartData.push({
                    name: label,
                    series: serie
                })
            }

            this._store.dispatch(new setStatData(chartData, "GlobalSuccessStats"));

            this.common.detectChange(this._cdr);

        } catch (error) {
            this._logger.error(error);
        }
    }

    getMathPower(val: number){
        return Math.round(Math.pow(10, val));
    }

}
