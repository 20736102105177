import { Pipe, PipeTransform } from "@angular/core";

/**
 * Exclude key/value pairs
 */
@Pipe({
  name: "exclude",
  pure: true
})
export class ExcludePipe implements PipeTransform {
    transform(value: Array<any>, exclude: Array<any>) {

        let result: Array<any> = value;

        // Check if both arrays have a length
        if (value && value.length && exclude && exclude.length > 0) {

            const excludeIds: Array<string> = exclude.map(e => e.id);
            result = [];

            value.filter(v => {
                // Differentiate between object keys, enums and dictionaries
                if (typeof exclude[0] === "string" && v.key && !exclude.includes(v.key)) {
                    result.push(v);
                } else if (typeof exclude[0] === "number" && v.value && v.key && !exclude.includes(v.value)) {
                    result.push(v);
                } else if (typeof exclude[0] === "object" && v.id && !excludeIds.includes(v.id)) {
                    result.push(v);
                }
            });
        }
        return result;
    }
}
