import { Pipe, PipeTransform } from "@angular/core";
import { IKeyPipe } from "@shared/app-models";

/**
 * Get the keys/values from an object
 */
@Pipe({
  name: "keys",
  pure: true
})
export class KeysPipe implements PipeTransform {
    transform(value, allowId?: boolean): IKeyPipe[] {
        const keys = [];
        let idx = 0;
        if (value) {
            for (const key in value) {
                if (key !== "toJSON") {
                    if (allowId) {
                        keys.push({key: key, value: value[key], timestamp: idx++ });
                    } else if (key !== "id") {
                        keys.push({key: key, value: value[key], timestamp: idx++ });
                    }
                }
            }
        }
        return keys;
    }
}
