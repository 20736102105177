<a
    *ngIf="report.pdffile"
    download [href]="report.pdffile | catsrc : 'download' : mediaType.MEDIA_PROFILEREPORTS : profileId"
    mat-menu-item extended color="primary">
    <mat-icon color="dark">picture_as_pdf</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
        <span color="primary"><b>{{ "Download PDF" | translate }}</b></span>
        <br>
        <span color="medium-shade">{{ report.pdffile.filesize | filesize }}</span>
    </div>
</a>

<a
    *ngIf="report.htmlfile"
    download [href]="report.htmlfile | catsrc : 'download' : mediaType.MEDIA_PROFILEREPORTS : profileId"
    mat-menu-item extended color="primary">
    <mat-icon color="dark">html</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
        <span color="primary"><b>{{ "Download HTML" | translate }}</b></span>
        <br>
        <span color="medium-shade">{{ report.htmlfile.filesize | filesize }}</span>
    </div>
</a>

<a
    *ngIf="report.zipfile"
    download [href]="report.zipfile | catsrc : 'download' : mediaType.MEDIA_PROFILEREPORTS : profileId"
    mat-menu-item extended color="primary">
    <mat-icon color="dark">folder_zip</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
        <span color="primary"><b>{{ "Download ZIP" | translate }}</b></span>
        <br>
        <span color="medium-shade">{{ report.zipfile.filesize | filesize }}</span>
    </div>
</a>

<a
    *ngIf="report.logfile"
    download [href]="report.logfile | catsrc : 'download' : mediaType.MEDIA_PROFILEREPORTS : profileId"
    mat-menu-item extended color="primary">
    <mat-icon color="dark">terminal</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
        <span color="primary"><b>{{ "Download LOG" | translate }}</b></span>
        <br>
        <span color="medium-shade">{{ report.logfile.filesize | filesize }}</span>
    </div>
</a>

<a
    *ngIf="report.jsonfile"
    download [href]="report.jsonfile | catsrc : 'download' : mediaType.MEDIA_PROFILEREPORTS : profileId"
    mat-menu-item extended color="primary">
    <mat-icon color="dark">data_object</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-xl cat-lineheight-md">
        <span color="primary"><b>{{ "Download JSON" | translate }}</b></span>
        <br>
        <span color="medium-shade">{{ report.jsonfile.filesize | filesize }}</span>
    </div>
</a>