// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvName } from "@shared/app-models";

export const environment = {
    production      : false,
    hmr             : false,
    appName         : 'CAT',
    envName         : EnvName.LOCAL
};
