import { Pipe, PipeTransform } from "@angular/core";

/**
 * Include certain key/value pairs
 */
@Pipe({
    name: "include",
    pure: true
})
export class IncludePipe implements PipeTransform {
    transform(value: Array<any>, include: Array<string>, isObject?: boolean): Array<any> {

        let result: Array<any> = [];

        if (value && value.length && include) {
            result = value;

            if (!isObject && include.length > 0) {

                // Order array by object property
                // eslint-disable-next-line no-inner-declarations
                function mapOrder (array: any[], order: string | any[], key: string): Array<any> {
                    array.sort( function (a: { [x: string]: any; }, b: { [x: string]: any; }) {
                        const A = a[key], B = b[key];
                        return (order.indexOf(A) > order.indexOf(B)) ? 1 : -1;
                    });
                    return array;
                }

                // Return result in order of bind array, but filter first out the includes
                result = mapOrder(value.filter(item => include.includes(item.key)), include, "key");
            } else if (isObject) {
                const allowedIds = include.map((inc: any) => inc.id);
                result = value.filter(val => allowedIds.includes(val.id));
            }
        }

        return result;
    }
}
