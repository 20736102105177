import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";

@Pipe({
    name: "hasid",
    pure: true
})
/**
 * Check if an id exists in an array of items
 *
 * @export
 * @class HasIdPipe
 * @implements {PipeTransform}
 */
export class HasIdPipe implements PipeTransform {

    constructor(
        private _logger: LoggerService
    ) {}

    transform(value: any, include: Array<any>): boolean {

        let result = false;

        if (value && include?.length) {

            switch (typeof value) {
                case "string": {
                    result = (include.map((item: any) => item.id)).includes(value);
                    break;
                }
                case "object": {
                    if (value.id) {
                        result = (include.map((item: any) => item.id)).includes(value.id);
                    }
                    break;
                }
                default: {
                    this._logger.debug("Unknown type found in hasId pipe: ", typeof value);
                    break;
                }
            }
        }
        return result;
    }
}
