import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";

@Pipe({
    name: "getSome",
    pure: true
})
/**
 * Get a specific object from object array.
 *
 * @export
 * @class GetSome
 * @implements {PipeTransform}
 */
export class GetSome implements PipeTransform {

    constructor(
        private _logger: LoggerService
    ) {}

    transform(value: Array<any>, property: string, field: string, startsWith = false): any {
        if (value?.length && property && field) {
            if (!startsWith) {
                return value.find(value => value?.[property] === field);
            } else {
                return value.find(value => value?.[property]?.startsWith(field));
            }
        }
    }
}
