import { Component, ChangeDetectionStrategy, OnInit, Input, OnDestroy } from "@angular/core";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { FormControl } from "@angular/forms";
import { IFormFieldData } from "@shared/app-models";

@Component({
    selector: "selection-field",
    templateUrl: "./selection-field.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SelectionFieldComponent implements OnInit, OnDestroy {
    // Inputs
    @Input() data: IFormFieldData;
    @Input() submitted: boolean;
    @Input() control: FormControl;
    @Input() options: { value: any, label: string }[];
    @Input() multiple: boolean;

    constructor(
        public common: CommonService,
        private _logger: LoggerService) {}

    ngOnInit() {
        // this._logger.debug("INIT SELECTION FIELD");
    }

    ngOnDestroy(): void {
        // this._logger.debug("DESTROY SELECTION FIELD");
    }
}