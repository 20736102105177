<div class="cat-row">
    <div class="cat-col cat-width-50-percent cat-padding-lg cat-background-white cat-border-radius">

        <h6 class="cat-no-padding cat-no-margin">
            {{ "Active user sessions" | translate }}
        </h6>

        <p class="cat-block cat-margin-y-lg cat-opacity-4 cat-lineheight-md">
            {{ permissionDef[permissionEnum[permissionEnum.PERMISSION_VIEW_USER_SESSIONS]].desc | translate | replaceTerm }}
        </p>

        <div class="cat-list cat-no-margin cat-no-background">
            <ng-container *ngIf="(sessions$ | async) as sessions">
                <div *ngFor="let session of sessions trackBy: common.trackById" class="cat-item cat-background-light-shade cat-no-margin cat-padding-md cat-border-radius">
                    <mat-icon *ngIf="session.expires" color="success" class="cat-margin-right-sm">person</mat-icon>
                    <mat-icon *ngIf="!session.expires" color="danger" class="cat-margin-right-sm">person</mat-icon>

                    <div class="cat-grid">
                        <div class="cat-row">
                            <h5 class="cat-no-margin cat-no-padding cat-text-md">
                                <b>{{ session.username }}</b>
                            </h5>
                        </div>
                        <div class="cat-row">
                            <span class="cat-text-xs">
                                {{ "Expires by" | translate }}: {{ session.expires | date: 'd MMMM y, HH:mm' : undefined : tl.locale }}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>