<mat-toolbar class="cat-no-background cat-app-header">

    <div class="cat-flex-row cat-full-width cat-button-wrapper cat-full-height">

        <button *ngIf="dismiss" (click)="dismissPage()" mat-icon-button color="dark">
            <mat-icon>close</mat-icon>
        </button>

        <button *ngIf="back" (click)="goBack()" mat-icon-button color="dark">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>

        <div *ngIf="icon?.matname" class="cat-avatar cat-md cat-background-dark">
            <mat-icon color="dark" class="cat-full-width-height cat-flex-center">{{ icon.matname }}</mat-icon>
        </div>

        <h6 color="dark" class="cat-padding-left-sm">
            <b>{{ title | translate | replaceTerm | capitalizeFirstChar }}</b>
        </h6>

        <div class="cat-float-end cat-full-height cat-button-wrapper">

            <ng-container *ngIf="(notifications$ | async) as notifications">
                <button *ngIf="!hideNotifications" (click)="toNotifications()" mat-icon-button color="primary" class="cat-float-start">
                    <mat-icon
                        color="success"
                        class="cat-font-lg"
                        [matBadgeHidden]="(notifications | filter: { type: 'unread' }).length === 0"
                        matBadgeSize="medium"
                        aria-hidden="false"
                        [matBadge]="(notifications | filter: { type: 'unread' }).length"
                        matBadgeColor="primary">notifications</mat-icon>
                </button>
            </ng-container>

            <div *ngIf="!hideUserDetails && !profileMode" id="help-user-settings" class="cat-user-profile cat-animation-fade-left cat-cursor-pointer" (click)="openUserDetails()">
                <div class="cat-full-width cat-end cat-padding-right-md cat-nooverflow-ellipsis">
                    <ng-container *ngIf="(me$ | async) as me">
                        <div class="cat-nooverflow-ellipsis">
                            <span color="dark" class="cat-text-md"><b>{{ me.fullname || 'You' }}</b></span>
                        </div>
                        <ng-container *ngIf="me.roles?.length === 1 else multipleRoles">
                            <div class="cat-text-xs cat-nooverflow-ellipsis cat-margin-top-sm cat-role">
                                {{ undefined | getRoleName : usm.roleid }}
                            </div>
                        </ng-container>
                        <ng-template #multipleRoles>
                            <div (click)="changeRole(); $event.stopPropagation()" class="cat-text-xs cat-nooverflow-ellipsis cat-margin-top-sm cat-cursor-pointer cat-role">
                                {{ undefined | getRoleName : usm.roleid }}
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
                <div class="cat-user-avatar">
                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+" />
                </div>
            </div>

            @if ((profile$ | async); as profile) {
                @if (profileMode) {
                    <div
                        (click)="openStatDialog(apptype?.packagename)"
                        [class.cat-cursor-pointer]="((devices$|async) | isBuffering : apptype)" class="cat-user-profile cat-animation-fade-left">
                        <div class="cat-full-width cat-end cat-padding-right-md cat-nooverflow-ellipsis cat-lineheight-sm">
                            <div class="cat-nooverflow-ellipsis cat-padding-y-sm">
                                <span color="dark">
                                    <b>{{ profile?.fullname }}</b>
                                </span>
                            </div>
                            <div *ngIf="profile?.cases?.length" class="cat-text-xs cat-nooverflow-ellipsis">
                                {{ uitext.case | translate | replaceTerm }}: <b>{{ profile?.cases[0].name }}
                                    <ng-container *ngIf="profile.cases?.length > 1">
                                    (+{{ profile.cases?.length - 1 }})
                                </ng-container>
                            </b>
                            </div>
                            <div *ngIf="((devices$|async) | isBuffering : apptype)" class="cat-text-xs">
                                <span class="cat-animation-flash cat-cursor-pointer cat-text-xs">
                                    {{ uitext.processing | translate }}...
                                </span>
                            </div>
                        </div>
                        <div class="cat-user-avatar cat-flex-center">
                            <img
                                *ngIf="profile && (profile.avatar | hasThumb) else noAvatar"
                                class="cat-auto-width cat-full-height"
                                [src]="profile.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_PROFILEAVATARS : profile.id">
                            <ng-template #noAvatar>
                                <img src="assets/img/cat-avatar.svg">
                            </ng-template>
                        </div>
                    </div>
                }
            }

        </div>
    </div>
</mat-toolbar>

@if (!hideProgressBar) {
    <div class="cat-app-header-progress">
        <ng-container *ngIf="loading">
            <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate"></mat-progress-bar>
        </ng-container>
        <mat-progress-bar *ngIf="!loading" mode="determinate" value="-1" color="light-shade"></mat-progress-bar>
    </div>
}