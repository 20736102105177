<h4 mat-dialog-title class="cat-no-margin" color="dark">
    <b>{{ title | translate | replaceTerm }}</b>
</h4>

<div mat-dialog-content class="cat-block cat-margin-bottom-xl">
    <div class="cat-margin-bottom-xl" [innerHTML]="(content | translate | replaceTerm)">
    </div>
</div>

<mat-dialog-actions *ngIf="!buttons.length" align="end" class="cat-padding-bottom-lg cat-padding-x-lg">
    <button mat-stroked-button mat-dialog-close class="cat-padding-lg" (click)="closeDialog('cancel')">
        {{ uitext.close | translate }}
    </button>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="buttons.length" align="end" class="cat-padding-bottom-lg cat-padding-x-lg">
    <ng-container *ngFor="let button of buttons">

        <ng-container *ngIf="button.action === 'cancel'; else actionButton">
            <button (click)="closeDialog(button.action)" mat-stroked-button mat-dialog-close cdkFocusInitial [color]="button.color">
                {{ button.title | translate | replaceTerm }}
            </button>
        </ng-container>

        <ng-template #actionButton>
            <button (click)="closeDialog(button.action)" mat-flat-button mat-dialog-close [color]="button.color">
                {{ button.title | translate | replaceTerm }}
            </button>
        </ng-template>

    </ng-container>
</mat-dialog-actions>


