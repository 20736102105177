import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";

/**
 * Put a selection on top of a list.
 */
@Pipe({
    name: "onTop",
    pure: true
})
export class SelectOnTopPipe implements PipeTransform {

    constructor(
        private _logger: LoggerService
    ) {}

      transform(items: any[], selectedItems: any[], flat = false): any {
        if (items?.length && selectedItems?.length) {
            items.sort(((a: any, b: any) => {

                const itemA = selectedItems.some(selectedItem => ((flat) ? selectedItem : selectedItem.id) === a.id);
                const itemB = selectedItems.some(selectedItem => ((flat) ? selectedItem : selectedItem.id) === b.id);

                if (itemA && !itemB) {
                    return -1; // A comes before B.
                } else if (!itemA && itemB) {
                    return 1; // B comes before A.
                } else {
                    return 0; // Leave the order unchanged.
                }
            }));
        } else {
            this._logger.debug("No value or filter found....");
        }
        return items;
    }
}
