import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cat } from "@assets/proto/msgs";
import { WsService } from "@services/ws/ws.service";
import { Store } from "@ngxs/store";
import { ILinkDeviceModal, IState } from "@shared/app-models";
import { Observable } from "rxjs";
import { protosui } from "@definitions/definitions";
import { LoggerService } from "@services/logger/logger.service";
import { HasPermissionPipe } from "@pipes/haspermission/haspermission.pipe";
import { CommonService } from "@services/common/common.service";
import { SelectionModel } from "@angular/cdk/collections";
import { getList } from "@store/store";
import { userGetDevices, userLinkDeviceToDomain, userUnlinkDeviceFromDomain } from "@assets/proto/services";
import { clearStoreItem } from "@store/actions";
import { messageDefinitions } from "@assets/proto/message-definitions";

@Component({
  selector: "link-device-domain-modal",
  templateUrl: "./link-device-domain.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkDeviceDomainsModal implements OnInit, OnDestroy {

    public devices: cat.DeviceMsg[] = [];
    public displayedColumns = ["select", "name", "domain", "physicalid", "status", "type"];
    public currentSelection = new SelectionModel<string>(true, []);
    public selection = new SelectionModel<string>(true, []);
    public uitext = protosui.messages.uitext;
    public messageDefinitions = messageDefinitions;
    public selectAll = false;
    public selectionChanged = false;

    private _domainId: string;
    private _devices: Map<string, cat.DeviceMsg> = new Map();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ILinkDeviceModal,
        private _logger: LoggerService,
        private _common: CommonService,
        private _cdr: ChangeDetectorRef,
        private _store: Store,
        private _ws: WsService,
        public dialogRef: MatDialogRef<LinkDeviceDomainsModal>
        ) {
            this._domainId = data.domainId;
        }

    /**
    * Fetch the params everytime the popup opens
    */
    async ngOnInit() {
        if (new HasPermissionPipe(this._logger, this._store).transform([
            cat.Permission.PERMISSION_MANAGE_DEVICES,
            cat.Permission.PERMISSION_VIEW_DEVICES])) {
            await this.userGetDevices();
        }
    }

    /**
     * Remove the devices.
     *
     * @memberof LinkDeviceDomainsModal
     */
    ngOnDestroy() {
        this._store.dispatch(new clearStoreItem(userGetDevices));
    }

    /**
     * Get the devices for the domain.
     *
     * @private
     * @memberof DomainDetailsPage
     */
    private async userGetDevices() {
        try {
            // First get all devices.
            await this._ws.sendRequest(userGetDevices);
            this.devices = getList(this._store.selectSnapshot((state: IState) => state.cat.userGetDevices));
            this._devices = this._store.selectSnapshot((state: IState) => state.cat.userGetDevices.list);

            // Next get just the domain devices.
            const payload = cat.DeviceMsg.create({
                domain: cat.DomainMsg.create({
                    id: this._domainId
                })
            });
            await this._ws.sendRequest(userGetDevices, payload);
            const domainDevices = getList(this._store.selectSnapshot((state: IState) => state.cat.userGetDevices));

            // Select the linked domain devices.
            if (domainDevices?.length) {
                for (const device of domainDevices) {
                    this.currentSelection.select(device.id);
                    this.selection.select(device.id);
                }
            }
        } catch (error) {
            this._logger.error(error);
            this._common.createSnackbar(error);
        } finally {
            this._common.detectChange(this._cdr);
        }
    }

    /**
     * Undo the selection
     *
     * @memberof LinkDeviceDomainsModal
     */
    public reset() {
        try {
            this.selection.clear();
            this.selection.select(...this.currentSelection.selected);
            this.selectionChanged = false;
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * (De)-select all devices.
     *
     * @memberof LinkDeviceDomainsModal
     */
    public toggleAll() {
        try {

            this.selectAll = !this.selectAll;
            this.selectionChanged = true;

            if (!this.selectAll) {
                this.selection.clear(true);
                this.selectionChanged = true;
            } else {
                this.selection.select(...Array.from(this._devices.values()).map(device => device.id));
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Store the changes.
     *
     * @memberof LinkDeviceDomainsModal
     */
    public async save() {
        try {

            const payload = cat.DeviceMsg.create({
                domain: cat.DomainMsg.create({ id: this._domainId })
            });

            // Loop over all devices to set the connection right.
            for (const device of this._devices.values()) {
                // Set the device id in the payload.
                payload.id = device.id;
                // If the device is selected, link. Otherwise unlink if part of the domain.
                if (this.selection.isSelected(device.id)) {
                    await this._ws.sendRequest(userLinkDeviceToDomain, payload);
                } else if (device.domain?.id === this._domainId) {
                    await this._ws.sendRequest(userUnlinkDeviceFromDomain, payload);
                }
            }
            await this._common.createSnackbar(protosui.messages.uitext.successfullyedited);
            this.closeDialog();

        } catch (error) {
            this._logger.error(error);
        }
    }


    /**
     * Close dialig by button.
     *
     * @memberof LinkDeviceDomainsModal
     */
    closeDialog() {
        this.dialogRef.close();
    }
}