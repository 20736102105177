<div class="cat-flex-container cat-flex-column">
    <div class="cat-height-60 cat-nooverflow-ellipsis cat-flex cat-align-center cat-padding-x-md cat-border-box">

        <h6 class="cat-font-weight-black cat-padding-top-xs cat-letter-spacing-100 cat-margin-left-md">
            {{ title | translate | replaceTerm | uppercase | emptyValue }}
        </h6>

        <div class="cat-push-right cat-flex cat-align-center">
            <button (click)="dismissPage()" mat-icon-button color="dark" class="cat-margin-left-xl">
                <b><mat-icon class="cat-font-weight-bold">close</mat-icon></b>
            </button>
        </div>
    </div>

    <div class="cat-height-60 cat-nooverflow-ellipsis cat-flex cat-align-center cat-padding-x-md cat-border-box cat-wrap">

        <div class="cat-margin-left-md"></div>

        @if (appId) {
            @if ((apps$|async)?.get(appId); as app) {
                <div class="cat-avatar cat-sm cat-no-border-radius cat-background-white">
                    @if (app.apptype?.iconfileid) {
                        <img [src]="app.apptype.iconfileid | iconmedia">
                    }
                </div>
                <div class="cat-font-weight-light cat-margin-left-md">
                    <div class="cat-margin-bottom-xs cat-text-md">
                        <b>{{ uitext.app | translate | replaceTerm }}</b>
                    </div>
                    <div class="cat-text-md">
                        {{ app.apptype?.name }}
                    </div>
                </div>
            }
        }

        @if (accountId) {
            @if (account$|async; as account) {

                <div class="cat-avatar cat-sm cat-no-border-radius cat-background-white">
                    @if (account.apptype?.iconfileid) {
                        <img [src]="account.apptype.iconfileid | iconmedia">
                    }
                </div>
                <div class="cat-font-weight-light cat-margin-left-md">
                    <div class="cat-margin-bottom-xs cat-text-md">
                        <b>{{ uitext.app | translate | replaceTerm }}</b>
                    </div>
                    <div class="cat-text-md">
                        {{ account.apptype?.name }}
                    </div>
                </div>

                <div class="cat-margin-x-lg cat-button-wrapper cat-text-sm">
                    <mat-icon class="cat-sm">chevron_right</mat-icon>
                </div>

                <div class="cat-avatar cat-sm cat-background-white">
                    @if (account.avatar | hasThumb) {
                        <img
                            (click)="openMedia(account); $event.stopPropagation()"
                            [class.cat-cursor-pointer]="!disableOpenMedia"
                            [src]="account.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : accountId">
                    } @else {
                        <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                    }

                </div>
                <div class="cat-font-weight-light cat-margin-left-md">
                    <div class="cat-margin-bottom-xs cat-text-md">
                        <b>{{ uitext.account | translate | replaceTerm }}</b>
                    </div>
                    <div class="cat-text-md cat-max-width-xl cat-nooverflow-ellipsis cat-nowrap">
                        {{ account | accountHandle | emptyValue }}
                    </div>
                </div>
            }
        }

        @if (contactId) {
            <div class="cat-margin-x-lg cat-button-wrapper cat-text-sm">
                <mat-icon class="cat-sm">chevron_right</mat-icon>
            </div>
            @if (contact$|async; as contact) {
                <div class="cat-avatar cat-sm cat-background-white">
                    @if (contact.avatar | hasThumb) {
                        <img
                            (click)="openMedia(contact, false, true); $event.stopPropagation()"
                            [class.cat-cursor-pointer]="!disableOpenMedia"
                            [src]="contact.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : accountId">
                    } @else {
                        <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                    }

                </div>
                <div class="cat-font-weight-light cat-margin-left-md">
                    <div class="cat-margin-bottom-xs cat-text-md">
                        <b>{{ uitext.contact | translate | replaceTerm }}</b>
                    </div>
                    <div class="cat-text-md cat-max-width-xl cat-nooverflow-ellipsis cat-nowrap">
                        {{ contact | accountHandle | emptyValue }}
                    </div>
                </div>
            }
        }

        @if (serverId) {
            <div class="cat-margin-x-lg cat-button-wrapper cat-text-sm">
                <mat-icon class="cat-sm">chevron_right</mat-icon>
            </div>
            @if (server$|async; as server) {
                <div class="cat-avatar cat-sm cat-background-white">
                    @if (server.avatar?.type === attachmentType.ATTACHMENT_TGS) {
                        @if (server.avatar | hasFile) {
                            <lottie-container
                                (click)="openMedia(server, false, false, true); $event.stopPropagation()"
                                [class.cat-cursor-pointer]="!disableOpenMedia"
                                [fullwidth]="true"
                                [file]="server.avatar.files[0]"
                                [mediatype]="mediaType"
                                [refId]="account.id">
                            </lottie-container>
                        } @else {
                            <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                        }

                    } @else if (server.avatar | hasThumb) {
                        <img
                            (click)="openMedia(server, false, false, true); $event.stopPropagation()"
                            [class.cat-cursor-pointer]="!disableOpenMedia"
                            [src]="server.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : accountId">
                    } @else {
                        <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                    }
                </div>
                <div class="cat-font-weight-light cat-margin-left-md">
                    <div class="cat-margin-bottom-xs cat-text-md">
                        <b>{{ uitext.server | translate | replaceTerm }}</b>
                    </div>
                    <div class="cat-text-md cat-max-width-xl cat-nooverflow-ellipsis cat-nowrap">
                        {{ (server.name || server.externid) | emptyValue }}
                    </div>
                </div>
            }
        }

        @if (topicId) {
            <div class="cat-margin-x-lg cat-button-wrapper cat-text-sm">
                <mat-icon class="cat-sm">chevron_right</mat-icon>
            </div>
            @if (topic$|async; as topic) {
                <div class="cat-avatar cat-sm cat-background-white">
                    @if (topic.avatar?.type === attachmentType.ATTACHMENT_TGS) {
                        @if (topic.avatar | hasFile) {
                            <lottie-container
                                (click)="openMedia(topic, false, false, false, true); $event.stopPropagation()"
                                [class.cat-cursor-pointer]="!disableOpenMedia"
                                [fullwidth]="true"
                                [file]="topic.avatar.files[0]"
                                [mediatype]="mediaType"
                                [refId]="accountId">
                            </lottie-container>
                        } @else {
                            <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                        }

                    } @else if (topic.avatar | hasThumb) {
                        <img
                            (click)="openMedia(topic, false, false, false, true); $event.stopPropagation()"
                            [class.cat-cursor-pointer]="!disableOpenMedia"
                            [src]="topic.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : accountId">
                    } @else {
                        <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                    }
                </div>
                <div class="cat-font-weight-light cat-margin-left-md">
                    <div class="cat-margin-bottom-xs cat-text-md">
                        <b>{{ uitext.topic | translate | replaceTerm }}</b>
                    </div>
                    <div class="cat-text-md cat-max-width-xl cat-nooverflow-ellipsis cat-nowrap">
                        {{ (topic.name || topic.externid) | emptyValue }}
                    </div>
                </div>
            }
        }



        @if (conversationId) {
            <div class="cat-margin-x-lg cat-button-wrapper cat-text-sm">
                <mat-icon class="cat-sm">chevron_right</mat-icon>
            </div>
            @if (conversation$|async; as conversation) {
                <div class="cat-avatar cat-sm cat-background-white">
                    @if (conversation.avatar | hasThumb) {
                        <img
                            [class.cat-story]="(conversation.type === conversationType.CONVERSATION_STORY)"
                            (click)="openMedia(conversation, true); $event.stopPropagation()"
                            [class.cat-cursor-pointer]="!disableOpenMedia"
                            [src]="conversation.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : accountId">

                    } @else {
                        <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                    }

                </div>
                <div class="cat-font-weight-light cat-margin-left-md">
                    <div class="cat-margin-bottom-xs cat-text-md">
                        <b>{{ uitext.conversation | translate | replaceTerm }}</b>
                    </div>
                    <div class="cat-text-md cat-max-width-xl cat-nooverflow-ellipsis cat-nowrap">
                        {{ (conversation.name || conversation.externid) | emptyValue }}
                    </div>
                </div>
            }
        }

        @if (messageId) {
            <div class="cat-margin-x-lg cat-button-wrapper cat-text-sm">
                <mat-icon class="cat-sm">chevron_right</mat-icon>
            </div>
            <div class="cat-font-weight-light">
                <div class="cat-margin-bottom-xs cat-text-md">
                    <b>{{ uitext.message | translate | replaceTerm }}</b>
                </div>
                <div class="cat-text-md">
                    {{ (messageId) | emptyValue }}
                </div>
            </div>
        }

        <div class="cat-push-right cat-flex cat-align-center">
            @for (button of buttons; track button.text; let first = $first, last = $last) {
                @if (button.permissions | hasPermission) {
                    <button
                        (click)="emitAction.emit(button); $event.stopPropagation()"
                        [disabled]="isLoading"
                        mat-flat-button color="light-shade" class="cat-nowrap" [class.cat-margin-right-md]="!last">
                        <mat-icon class="cat-font-weight-bold cat-padding-right-sm" color="primary">
                            {{ button.icon.matname }}
                        </mat-icon>
                        {{ button.text | translate | replaceTerm }}
                    </button>
                }
            }
        </div>
    </div>
</div>



<ng-template #noAvatar>
    <img matTooltip="{{ uitext.noavatarfound | translate }}" matTooltipPosition="left" src="assets/img/cat-avatar.svg">
</ng-template>