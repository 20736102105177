

<ng-container *ngIf="(reportStatus$|async) as status">

    <header class="cat-padding-xl cat-nowrap">
        <h3 class="cat-no-margin cat-nooverflow-ellipsis">
            <b>{{ "{Profile} Report" | translate | replaceTerm }}</b>
        </h3>
        <h5 class="cat-margin-top-md cat-opacity-4">
            {{ "Follow these steps" | translate | replaceTerm }}
        </h5>
    </header>

    <ng-container *ngIf="(currentStep$|async) as currentStep">

        <div class="cat-padding-bottom-xl">

            <ng-container *ngIf="steps[0] as templateStep">
                <div
                    (click)="goto(templateStep, true)"
                    class="cat-progress-item cat-padding-left-xl" [class.cat-cursor-pointer]="(templateStep.stepCompleted)" [class.cat-active]="currentStep.stepNumber === templateStep.stepNumber">
                    <h6 class="cat-margin-bottom-sm">
                        <b>{{ templateStep.title | translate }}</b>
                    </h6>
                    <span [attr.color]="(templateStep.stepCompleted) ? 'success' : 'danger'" [class.cat-opacity-8]="(templateStep.stepCompleted)" class="cat-opacity-4">
                        <ng-container *ngIf="status.template?.id else noValue">
                            {{ status.template.name }}
                        </ng-container>
                    </span>
                    <ng-container *ngIf="status.template?.id">
                        <span *ngIf="status.template?.id" class="cat-block cat-opacity-4" [class.cat-opacity-8]="(status.flags?.length)" color="success">
                            {{ status.flags?.length }} {{ "options selected" | translate }}
                        </span>
                    </ng-container>

                </div>
            </ng-container>

            <ng-container *ngIf="steps[1] as rangeStep">
                <div
                    (click)="goto(rangeStep, (status.template?.id))"
                    class="cat-progress-item cat-padding-left-xl" [class.cat-cursor-pointer]="(steps[0].stepCompleted)" [class.cat-active]="currentStep.stepNumber === rangeStep.stepNumber">
                    <h6 class="cat-margin-bottom-sm">
                        <b>{{ rangeStep.title | translate }}</b>
                    </h6>
                    <span [attr.color]="(rangeStep.stepCompleted) ? 'success' : 'danger'" [class.cat-opacity-8]="(rangeStep.stepCompleted)" class="cat-opacity-4">
                        <ng-container *ngIf="(status.start && status.end) else noValue">
                            {{ "Start" | translate }}: {{ (status.start | date: 'dd/MM/y, HH:mm:ss' : undefined : tl.locale) }}
                            <br>
                            {{ "End" | translate }}: {{ (status.end | date: 'dd/MM/y, HH:mm:ss' : undefined : tl.locale) }}
                        </ng-container>
                    </span>
                </div>
            </ng-container>

            <ng-container *ngIf="steps[2] as chatStep">
                <div
                    (click)="goto(chatStep, (status.template?.id && status.start && status.end))"
                    class="cat-progress-item cat-padding-left-xl" [class.cat-cursor-pointer]="(status.template?.id && status.start && status.end)" [class.cat-active]="currentStep.stepNumber === chatStep.stepNumber">
                    <h6 class="cat-margin-bottom-sm">
                        <b>{{ chatStep.title | translate }}</b>
                    </h6>
                    <span [attr.color]="(chatStep.stepCompleted) ? 'success' : 'danger'" [class.cat-opacity-8]="(status.template?.id && status.start && status.end)" class="cat-opacity-4">
                        <ng-container *ngIf="(status.conversations?.length) else noValue">
                            {{ status.conversations.length }} {{ "selected" | translate }}
                        </ng-container>
                    </span>
                </div>
            </ng-container>

            <ng-container *ngIf="steps[3] as detailStep">
                <div
                    (click)="goto(detailStep, (status.template?.id && status.start && status.end))"
                    class="cat-progress-item cat-padding-left-xl" [class.cat-cursor-pointer]="(status.template?.id && status.start && status.end)" [class.cat-active]="currentStep.stepNumber === detailStep.stepNumber">
                    <h6 class="cat-margin-bottom-sm">
                        <b>{{ detailStep.title | translate }}</b>
                    </h6>
                    <span [attr.color]="(detailStep.stepCompleted) ? 'success' : 'danger'" [class.cat-opacity-8]="(detailStep.stepCompleted)" class="cat-opacity-4">
                        <ng-container *ngIf="detailStep.stepCompleted else noValue">
                            {{ "All required fields filled" | translate }}
                        </ng-container>
                    </span>
                </div>

                <ng-container *ngIf="steps[4] as generateStep">
                    <div
                        (click)="goto(generateStep, (status.template?.id && status.start && status.end && detailStep.stepCompleted))" [class.cat-opacity-8]="(detailStep.stepCompleted)"
                        class="cat-progress-item cat-padding-left-xl cat-opacity-4" [class.cat-cursor-pointer]="(detailStep.stepCompleted)" [class.cat-active]="currentStep.stepNumber === generateStep.stepNumber">
                        <h6 class="cat-margin-bottom-sm">
                            <b>{{ generateStep.title | translate }}</b>
                        </h6>
                    </div>
                </ng-container>
            </ng-container>


        </div>

        <footer class="cat-progress-bar cat-padding-xl">
            <div class="cat-opacity-4">
                {{ currentStep.title | translate }}
                <span class="cat-float-end">{{ "Step " | translate }} {{ currentStep.stepNumber }} / {{ steps.length }}</span>
            </div>
            <mat-progress-bar mode="determinate" value="{{ status | getProgress : steps[3]?.stepCompleted }}" class="cat-margin-top-md cat-height-md"></mat-progress-bar>
        </footer>



    </ng-container>
</ng-container>


<ng-template #noValue>
    {{ "Not complete" | translate }}
</ng-template>