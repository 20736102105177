import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "capitalizeFirstChar",
  pure: true
})

/**
* @example
* this is a test > This is a test
* the Eiffel Tower > The Eiffel Tower
* @param {string} string The string to capitalize.
* @returns {string} A string with capitalized first character.
*/
export class CapitalizeFirstCharPipe implements PipeTransform {
    transform(string: string): string {
        let result = "";
        if (string && typeof string === "string" && string.length) {
            result = string.toLowerCase();
            result = result.charAt(0).toUpperCase() + result.slice(1);
        }
        return result;
    }
}
