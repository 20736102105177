import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";

@Pipe({
  name: "accountName",
  pure: true
})
/**
* Get the contact name, depending on the provided information
* @param {cat.AccountMsg} sender The account to get the name for.
* @returns {string} The best available name of the account or contact.
*/
export class AccountNamePipe implements PipeTransform {
    transform(account: cat.AccountMsg): string {
        return accountName(account);
    }
}

export const accountName = (account: cat.IAccountMsg) => {
    let result = "";
    if (account) {
        if (account.fullname) {
            result = account.fullname;
        } else {
            if (account.firstname && account.firstname.length) {
                result = account.firstname;
            }
            if (account.lastname && account.lastname.length) {
                if (!result.length) {
                    result = account.lastname;
                } else {
                    result += ` ${account.lastname}`;
                }
            }
        }
    }
    return result.trimStart();
}