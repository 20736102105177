<h4 mat-dialog-title class="cat-no-margin" color="dark">
    <b>{{ "Currently processing" | translate }}</b>
</h4>

<div mat-dialog-content>

    <ng-container *ngIf="(devices$|async) as devices">
        <ng-container *ngIf="(devices | isBuffering); else noProcessing">

            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>

            <div class="cat-padding-y-xl">
                <ng-container *ngFor="let device of devices">
                    <ng-container *ngIf="(device.receiverbufferstatus | addPipe : 'items') || (device.receiverbufferstatus | addPipe : 'files')">

                        <div class="cat-margin-bottom-xl">

                            <h6 class="cat-no-margin cat-no-padding">
                                <b>{{ device.name }}:</b>
                            </h6>

                            <ng-container *ngFor="let stats of device.receiverbufferstatus">
                                <ng-container *ngIf="(stats.items > 0) || (stats.files > 0)">

                                    <ng-container *ngIf="stats.items > 0">
                                        <b>{{ stats.items }}</b>
                                        <ng-template
                                            [ngTemplateOutlet]="items"
                                            [ngTemplateOutletContext]="{ itemCount: stats.items }">
                                        </ng-template>
                                    </ng-container>

                                    <ng-container *ngIf="stats.files > 0">
                                        <ng-container *ngIf="stats.items > 0">& </ng-container>
                                        <b>{{ stats.files }}</b>
                                        <ng-template
                                            [ngTemplateOutlet]="files"
                                            [ngTemplateOutletContext]="{ fileCount: stats.files }">
                                        </ng-template>
                                    </ng-container>

                                    {{ " for" | translate }}

                                    <ng-container *ngIf="packageName && (stats.packagename === packageName); else noPackagename">
                                        <span color="dark"><b>{{ stats.packagename }}</b></span>
                                    </ng-container>
                                    <ng-template #noPackagename>
                                        {{ stats.packagename }}
                                    </ng-template>

                                    <br>

                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="!device.receiverbufferstatus?.length">
                                {{ "No items to process" | translate }}
                            </ng-container>
                        </div>

                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #noProcessing>
            <div class="cat-margin-bottom-xl">
                {{ "Currently not processing items" | translate }}
            </div>
        </ng-template>
    </ng-container>
</div>

<mat-dialog-actions align="end" class="cat-padding-bottom-lg cat-padding-x-lg">
    <button mat-stroked-button mat-dialog-close cdkFocusInitial class="cat-padding-lg" (click)="closeDialog('cancel')">
        {{ uitext.close | translate }}
    </button>
</mat-dialog-actions>

<ng-template #items let-count="itemCount">
    <ng-container *ngIf="count > 1">
        {{ "items" | translate }}
    </ng-container>
    <ng-container *ngIf="count === 1">
        {{ "item" | translate }}
    </ng-container>
</ng-template>

<ng-template #files let-count="fileCount">
    <ng-container *ngIf="count > 1">
        {{ "files" | translate }}
    </ng-container>
    <ng-container *ngIf="count === 1">
        {{ "file" | translate }}
    </ng-container>
</ng-template>