<div class="cat-padding-xs cat-flex cat-align-center cat-justify-start">
    @if (reaction.customemoji?.files?.length) {
        <mat-icon>
            @if (reaction.customemoji | hasThumb) {
                <img [src]="reaction.customemoji.files[0] | catsrc : 'thumb' : mediaType.MEDIA_EMOJIS : account.id">
            } @else {
                <ng-container *ngTemplateOutlet="noCustomEmoji"></ng-container>
            }
        </mat-icon>
    } @else {
        <ng-container *ngTemplateOutlet="noCustomEmoji"></ng-container>
    }

    <div class="cat-margin-left-lg cat-flex cat-justify-center cat-align-center">

        @if (reaction.sender?.avatar | hasThumb) {
            <div class="cat-avatar cat-sm cat-margin-right-md" color="light-shade">
                <img [src]="reaction.sender.avatar.files[0]| catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
            </div>
        }

        <div>
            @if (reaction.sender) {
                {{ reaction.sender | accountHandle }}
            } @else {
                {{ reaction.count }}
            }
        </div>

    </div>
</div>


<ng-template #noCustomEmoji>
    <span class="cat-text-lg cat-min-width-30" color="transparent">
        {{ reaction.emoji }}
    </span>
</ng-template>