import { Component, OnInit, ChangeDetectionStrategy, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
@Component({
    selector: "app-device-accounts-popover",
    templateUrl: "./popover-device-accounts.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceAccountsPopoverComponent implements OnInit {

    constructor(
        public common: CommonService,
        private _logger: LoggerService,
        private _dialogRef: MatDialogRef<DeviceAccountsPopoverComponent>,
        @Inject(MAT_DIALOG_DATA) public accounts: cat.AccountMsg[] = []
    ) {
        if (!accounts) {
            this.common.createSnackbar(protosui.messages.uitext.prerequisites);
            throw new Error(protosui.messages.uitext.prerequisites);
        }
    }

    /**
    * Fetch the params everytime the popup opens
    */
    ngOnInit() {
        this._logger.debug("NavParams", this.accounts);
    }

    dismiss() {
        this._dialogRef.close("close");
    }
}
