import { Pipe, PipeTransform } from "@angular/core";

/**
 * Return a default empty value for fields.
 */
@Pipe({
    name: "emptyValue",
    pure: true
})
export class EmptyValuePipe implements PipeTransform {
    transform(value: any): any {
        const emptyToken = "—";
        let returnEmpty = false;
        if (Array.isArray(value)) {
            if (!value.length) {
                returnEmpty = true;
            }
        } else if (value === undefined || value === null || !value) {
            returnEmpty = true;
        }
        return (returnEmpty) ? emptyToken : value;
    }
}
