import { Pipe, PipeTransform } from "@angular/core";

/**
 * Human readable filesizes
 */
@Pipe({
    name: "filesize",
    pure: true
})
export class FilesizePipe implements PipeTransform {

    private units = [
        "bytes",
        "kB",
        "MB",
        "GB",
        "TB",
        "PB"
      ];

      transform(bytes = 0, precision = 0): string {

        if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) {
            return "?";
        }

        let unit = 0;

        while ( bytes >= 1024 ) {
            bytes /= 1024;
            unit ++;
        }
        return bytes.toFixed( + precision ) + " " + this.units[ unit ];
      }

}
