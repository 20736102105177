import { Pipe, PipeTransform } from "@angular/core";

/**
 * A pipe to add numbers.
 */
@Pipe({
    name: "addPipe",
    pure: true
})
export class AddPipe implements PipeTransform {

    // Note: Use pipe provider by name as specified in app.module.
    transform(value: any[], cursor: string): any {
        let result = value;
        if (value.length && cursor) {
            result = value.reduce((accum, current) => accum + current[cursor], 0);
        }
        return result;
    }

}
