import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";
import { cat } from "src/assets/proto/msgs";
@Pipe({
  name: "isBuffering",
  pure: true
})
/**
* Detect if one (of the) devices is syncing
*/
export class IsBufferingPipe implements PipeTransform {
    constructor(
        private _logger: LoggerService
    ) {}
    transform(devices: cat.DeviceMsg[], apptype?: cat.AppTypeMsg): boolean {
        return devices?.some((device: cat.DeviceMsg) => {

            let statusArray = Object.values(device.receiverbufferstatus);

            // Optionally filter out stats for a packagename.
            if (apptype?.packagename) {
                statusArray = statusArray.filter(stat => stat.packagename === apptype.packagename);
            }

            const itemsCount = statusArray.reduce((accum, current) => accum + current.items, 0);
            const filesCount = statusArray.reduce((accum, current) => accum + current.files, 0);
            return itemsCount > 0 || filesCount > 0;
        });
    }
}
