// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { cat } from "@assets/proto/msgs";
import { IServiceDescriptor } from "@assets/proto/services";
import { IAppVersion } from "@shared/app-models";

export enum ActionTypes {

    SET_LOADING = "[General] Item loading.",
    ADD_PROTOBUF_MSG = "[General] Add message.",
    KEYCLOAK_MODE = "[General] Keycloak mode.",
    LOCK_USERSESSION = "[General] Lock user session.",
    UNLOCK_USERSESSION = "[General] Unlock user session.",

    SET_CONNECTION = "[Websocket] Set connection.",
    SET_WEBSOCKET_VERSION = "[Websocket] Set connection version.",
    RESET_CONNECTION_COUNT = "[Websocket] Reset connection count.",
    INCREMENT_CONNECTION_COUNT = "[Websocket] Increment connection count.",

    ADD_CAPTURE_QR = "[QR] Add capture QR.",
    ADD_CAPTURE_APK_QR = "[QR] Add capture APK QR.",

    SET_DOWNLOADING_APK = "[Capture] Set downloading APK.",

    USER_LOGOUT = "[User] Log out.",
    ADD_ME = "[User] Add current user (me).",

    ADD_ARRAY_ITEM = "[Array] Add array item.",
    PREPEND_TO_INFINITE_ARRAY = "[Array] Prepend to array.",
    APPEND_TO_INFINITE_ARRAY = "[Array] Append to array.",
    REMOVE_DATEMSG_FROM_INFINITE_ARRAY = "[Array] remove date message.",
    CLEAR_ARRAY = "[Array] Clear array.",
    CLEAR_INFINITEARRAY = "[Array] Clear infinite array.",

    ADD_LIST = "[Map] Add list.",
    ADD_LIST_ITEM = "[Map] Add list item.",

    ADD_INFINITE_LIST = "[Map] Add infinite list.",
    MODIFY_INFINITE_LIST = "[Map] Modify infinite list.",
    CLEAR_LIST = "[Map] Clear list.",
    MODIFY_ITEM = "[Map] Modify item.",
    MODIFY_LIST_ITEM = "[Map] Modify list item.",
    REMOVE_LIST_ITEM = "[Map] Remove list item.",
    CLEAR_INFINITELIST = "[Map] Clear infinite list.",
    UPDATE_LIST_ITEM = "[Map] Update list item.",
    UPDATE_INFINITE_ITEM = "[Map] Update infinite item.",
    REMOVE_INFINITE_ITEM = "[Map] Remove infinite item.",

    UPDATE_MESSAGE_ATTACHMENT = "[Message] Update message attachment.",
    UPDATE_MESSAGE_REACTION = "[Message] Update message reaction.",
    UPDATE_INFINITE_MESSAGE = "[Message] Update (infinite) message.",

    UPDATE_CONTACT_ANNOTATIONS = "[Contact] Update contact annotations",
    SELECT_CONTACT = "[Contact] Select contact.",
    DESELECT_CONTACT = "[Contact] Deselect contact.",
    CLEAR_SELECTED_CONTACTS = "[Contact] Clear selected contacts.",

    SELECT_CONVERSATION = "[Conversation] Select Conversation.",
    DESELECT_CONVERSATION = "[Conversation] Deselect Conversation.",
    CLEAR_SELECTED_CONVERSATIONS = "[Conversation] Clear selected conversations.",

    UPDATE_DEVICE_SYNC_PROGRESS = "[Device] Update sync progress.",

    SET_DATEPICKER_MONTH = "[Misc] Set datepicker month.",
    CLEAR_MESSAGELIST = "[Misc] Clear message lists.",
    CLEAR_STORED_MSG = "[Misc] Clear stored message.",
    CLEAR_STORE_ITEM = "[Misc] Clear store item.",
    SET_TERMINOLOGY = "[Misc] Set terminology",
    SET_DASHBOARD_ANGLE = "[Misc] Set dashboard angle.",
    SET_EXCLUDED_REPORT_MESSAGES = "[Misc] Set excluded report messages list.",
    CLEAR_EXCLUDED_REPORT_MESSAGES = "[Misc] Clear excluded report messages list.",
    ADD_ROLE_COLUMN = "[Misc] Add role column.",
    CLEAR_ROLE_COLUMN = "[Misc] Clear role column.",
    CLEAR_ROLE_COLUMNS = "[Misc] Clear role columns.",

    TOGGLE_PERMISSIONS = "[Permissions] Toggle permission for role.",

    SET_STAT_DATA = "[Stats] Set stat data",
    CLEAR_STAT_DATA = "[Stats] Clear stat data",

    SET_PREVIEW_URL = "[Reporting] Set the preview url",
    CLEAR_PREVIEW_URL = "[Reporting] Clear the preview url"
}

export class setConnection {
    static readonly type = ActionTypes.SET_CONNECTION;
    constructor(public isConnected: boolean) {}
}
export class setWebsocketVersion {
    static readonly type = ActionTypes.SET_WEBSOCKET_VERSION;
    constructor(public versionMsg: IAppVersion) {}
}
export class addMe {
    static readonly type = ActionTypes.ADD_ME;
    constructor(public user: cat.UserMsg) {}
}
export class userLogout {
    static readonly type = ActionTypes.USER_LOGOUT;
}
export class resetConnectionCount {
    static readonly type = ActionTypes.RESET_CONNECTION_COUNT;
}
export class incrementConnectionCount {
    static readonly type = ActionTypes.INCREMENT_CONNECTION_COUNT;
}
export class addProtobufMsg {
    static readonly type = ActionTypes.ADD_PROTOBUF_MSG;
    constructor(public message: any, public call: IServiceDescriptor) {}
}
export class setKeycloakMode {
    static readonly type = ActionTypes.KEYCLOAK_MODE;
    constructor(public mode: boolean) {}
}
export class setLoading {
    static readonly type = ActionTypes.SET_LOADING;
    constructor(public isLoading: boolean, public cursor: string) {}
}
export class addList {
    static readonly type = ActionTypes.ADD_LIST;
    constructor(public listMap: Map<string, any>, public cursor: string) {}
}
export class addListItem {
    static readonly type = ActionTypes.ADD_LIST_ITEM;
    constructor(public message: any, public messageType: string, public cursor: string) {}
}
export class clearList {
    static readonly type = ActionTypes.CLEAR_LIST;
    constructor(public cursor: string) {}
}
export class addArrayItem {
    static readonly type = ActionTypes.ADD_ARRAY_ITEM;
    constructor(public message: any, public cursor: string) {}
}
export class addInfiteList {
    static readonly type = ActionTypes.ADD_INFINITE_LIST;
    constructor(public listMap: Map<string, any>, public cursor: string) {}
}
export class modifyInfiteList {
    static readonly type = ActionTypes.MODIFY_INFINITE_LIST;
    constructor(public message: any, public messageType: string, public cursor: string) {}
}
export class prependToInfiteArray {
    static readonly type = ActionTypes.PREPEND_TO_INFINITE_ARRAY;
    constructor(public list: any[], public cursor: string) {}
}
export class appendToInfiteArray {
    static readonly type = ActionTypes.APPEND_TO_INFINITE_ARRAY;
    constructor(public list: any[], public cursor: string) {}
}
export class removeDateFromInfiniteArray {
    static readonly type = ActionTypes.REMOVE_DATEMSG_FROM_INFINITE_ARRAY;
    constructor(public message: any, public cursor: string) {}
}
export class setDatepickerMonth {
    static readonly type = ActionTypes.SET_DATEPICKER_MONTH;
    constructor(public yearmonth: string) {}
}
export class modifyItem {
    static readonly type = ActionTypes.MODIFY_ITEM;
    constructor(public message: any, public cursor: string) {}
}

export class modifyListItem {
    static readonly type = ActionTypes.MODIFY_LIST_ITEM;
    constructor(public message: any, public messageType: string, public cursor: string, public identifier?: string) {}
}
export class removeListItem {
    static readonly type = ActionTypes.REMOVE_LIST_ITEM;
    constructor(public message: any, public cursor: string) {}
}
export class clearMessageList {
    static readonly type = ActionTypes.CLEAR_MESSAGELIST;
}
export class clearStoredMessage {
    static readonly type = ActionTypes.CLEAR_STORED_MSG;
    constructor(public cursor: string, public messageType: string) {}
}
export class clearStoreItem {
    static readonly type = ActionTypes.CLEAR_STORE_ITEM;
    constructor(public service: IServiceDescriptor) {}
}
export class clearArray {
    static readonly type = ActionTypes.CLEAR_ARRAY;
    constructor(public cursor: string) {}
}
export class clearInfiniteArray {
    static readonly type = ActionTypes.CLEAR_INFINITEARRAY;
    constructor(public cursor: string) {}
}
export class clearInfiniteList {
    static readonly type = ActionTypes.CLEAR_INFINITELIST;
    constructor(public cursor: string) {}
}
export class updateMessageAttachment {
    static readonly type = ActionTypes.UPDATE_MESSAGE_ATTACHMENT;
    constructor(public attachment: cat.AttachmentMsg, public messageId: string) {}
}
export class updateMessageReaction {
    static readonly type = ActionTypes.UPDATE_MESSAGE_REACTION;
    constructor(public reaction: cat.ReactionMsg, public messageId: string) {}
}
export class updateContactAnnotations {
    static readonly type = ActionTypes.UPDATE_CONTACT_ANNOTATIONS;
    constructor(public annotations: cat.AnnotationMsg[], public messageId: string) {}
}
export class updateDeviceSyncProgress {
    static readonly type = ActionTypes.UPDATE_DEVICE_SYNC_PROGRESS;
    constructor(public device: cat.DeviceMsg, public cursor: string) {}
}
export class selectContact {
    static readonly type = ActionTypes.SELECT_CONTACT;
    constructor(public contact: cat.AccountMsg) {}
}
export class deselectContact {
    static readonly type = ActionTypes.DESELECT_CONTACT;
    constructor(public contact: cat.AccountMsg) {}
}
export class clearSelectedContacts {
    static readonly type = ActionTypes.CLEAR_SELECTED_CONTACTS;
}
export class selectConversation {
    static readonly type = ActionTypes.SELECT_CONVERSATION;
    constructor(public conversation: cat.ConversationMsg) {}
}
export class deselectConversation {
    static readonly type = ActionTypes.DESELECT_CONVERSATION;
    constructor(public conversation: cat.ConversationMsg) {}
}
export class clearSelectedConversations {
    static readonly type = ActionTypes.CLEAR_SELECTED_CONVERSATIONS;
}
export class userSetTerminology {
    static readonly type = ActionTypes.SET_TERMINOLOGY;
    constructor(public terminology: cat.TerminologyMsg) {}
}
export class lockSession {
    static readonly type = ActionTypes.LOCK_USERSESSION;
}
export class unlockSession {
    static readonly type = ActionTypes.UNLOCK_USERSESSION;
}
export class updateListItem {
    static readonly type = ActionTypes.UPDATE_LIST_ITEM;
    constructor(public message: any, public messageType: string, public cursor: string) {}
}
export class setDashboardAngle {
    static readonly type = ActionTypes.SET_DASHBOARD_ANGLE;
    constructor(public angle: "profile" | "device" | "stats") {}
}
export class updateMessage {
    static readonly type = ActionTypes.UPDATE_INFINITE_MESSAGE;
    constructor(public message: cat.MessageMsg, public cursor: string) {}
}
export class updateInfiniteItem {
    static readonly type = ActionTypes.UPDATE_INFINITE_ITEM;
    constructor(public message: any, public cursor: string) {}
}
export class removeInfiniteItem {
    static readonly type = ActionTypes.REMOVE_INFINITE_ITEM;
    constructor(public message: any, public cursor: string) {}
}

export class togglePermission {
    static readonly type = ActionTypes.TOGGLE_PERMISSIONS;
    constructor(public permissionId: any, public roleId: string, public addPermission: boolean) {}
}

export class setStatData {
    static readonly type = ActionTypes.SET_STAT_DATA;
    constructor(public stats: { name: string, series: { name: string, value: number }[] }[], public cursor: "DeviceStats" | "GlobalSuccessStats" | "GlobalFailedStats") {}
}

export class clearStatData {
    static readonly type = ActionTypes.CLEAR_STAT_DATA;
    constructor(public cursor: "DeviceStats" | "GlobalSuccessStats" | "GlobalFailedStats") {}
}

export class setExcludedReportMessages {
    static readonly type = ActionTypes.SET_EXCLUDED_REPORT_MESSAGES;
    constructor(public messageIds: string[]) {}
}

export class clearExcludedReportMessages {
    static readonly type = ActionTypes.CLEAR_EXCLUDED_REPORT_MESSAGES;
}

export class addRoleColumn {
    static readonly type = ActionTypes.ADD_ROLE_COLUMN;
    constructor(public column: string) {}
}

export class clearRoleColumn {
    static readonly type = ActionTypes.CLEAR_ROLE_COLUMN;
    constructor(public column: string) {}
}

export class clearRoleColumns {
    static readonly type = ActionTypes.CLEAR_ROLE_COLUMNS;
}

export class addCaptureQR {
    static readonly type = ActionTypes.ADD_CAPTURE_QR;
    constructor(public qr: cat.MediaMsg) {}
}
export class addCaptureAPKQR {
    static readonly type = ActionTypes.ADD_CAPTURE_APK_QR;
    constructor(public qr: cat.MediaMsg) {}
}

export class setPreviewURL {
    static readonly type = ActionTypes.SET_PREVIEW_URL;
    constructor(public url: string) {}
}

export class clearPreviewURL {
    static readonly type = ActionTypes.CLEAR_PREVIEW_URL;
}