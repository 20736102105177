<ng-container *ngIf="(devices$ | async) as devices">

    <div class="grid-container">

        <div
            *ngFor="let device of devices trackBy: common.trackById"
            (click)="goToDevice(device)"
            class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">

            <mat-card class="cat-dashboard cat-cursor-pointer">

                <div (click)="showAccounts(device.accounts); $event.stopPropagation()"
                    class="cat-chip cat-cursor-pointer cat-position-right-bottom cat-on-top cat-padding-sm" color="white">
                    <div class="cat-chip-content cat-padding-xs">
                        <mat-icon class="cat-align-self-center cat-font-md" color="medium">visibility</mat-icon>
                        <span class="cat-margin-left-xs cat-margin-right-sm">
                            {{ device.accounts?.length }}
                        </span>
                    </div>
                </div>

                <mat-card-content class="cat-no-padding cat-no-margin cat-full-width-height">

                    <div class="cat-grid">

                        <div class="cat-row cat-gap-md cat-margin-md">

                            <div class="cat-col">

                                <div class="cat-item cat-start cat-center cat-no-background cat-no-margin cat-no-padding">
                                    <div class="cat-avatar cat-md cat-no-margin cat-flex-center">
                                        <mat-icon>smartphone</mat-icon>
                                    </div>
                                </div>
                            </div>

                            <div class="cat-col cat-flex-grow cat-no-min-width cat-margin-right-xl">

                                <div class="cat-row">
                                    <h6 class="cat-nowrap cat-nooverflow-ellipsis cat-no-margin">
                                        <b>{{ device.name }}</b>
                                    </h6>
                                </div>

                                <div class="cat-row cat-margin-top-md">
                                    <span color="dark-tint" class="cat-nowrap cat-nooverflow-ellipsis cat-text-sm">
                                        <b>{{ uitext.lastsynced | translate }}</b>:
                                        {{ (device.lastsync) ? (device.lastsync | date: 'd MMM y, HH:mm:ss' : undefined : tl.locale) : "" | emptyValue }}
                                    </span>
                                </div>

                                <div class="cat-row">
                                    <span color="dark-tint" class="cat-nowrap cat-nooverflow-ellipsis cat-text-sm">
                                        <b>{{ "Unlinked {accounts}" | translate | replaceTerm }}</b>:
                                        {{ device.unlinkedaccounts }}
                                    </span>
                                </div>

                                <div class="cat-row" *ngIf="([device] | isBuffering)">
                                    <span color="dark-tint" class="cat-nowrap cat-nooverflow-ellipsis cat-text-sm">
                                        {{ (device.receiverbufferstatus | addPipe : 'files') }} {{ "file(s)" | translate }} / {{ (device.receiverbufferstatus | addPipe : 'items') }} {{ "item(s)" | translate }}
                                        <mat-progress-bar value="0.25" bufferValue="0.5" color="primary" mode="indeterminate"></mat-progress-bar>
                                    </span>
                                </div>

                            </div>
                        </div>

                    </div>

                </mat-card-content>

            </mat-card>

        </div>
    </div>

    @if (!devices?.length) {
        <div class="cat-margin-bottom-xl">
            {{ uitext.notfound | translate }}
        </div>
    }

    @if (permissionEnum.PERMISSION_MANAGE_ASSIGNED_DEVICES | hasPermission) {
        <div class="grid-container cat-margin-top-md">
            <div (click)="userRegisterDevice()" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-card class="cat-dashboard cat-cursor-pointer">
                    <mat-card-content class="cat-no-padding cat-no-margin cat-full-width-height cat-center cat-align-center">
                        <div class="cat-flex-container cat-align-center cat-align-center cat-padding-left-md">
                            <mat-icon color="primary">add</mat-icon>
                            <h6 class="cat-no-margin cat-padding-left-md" color="primary">{{ "Device" | translate | replaceTerm }}</h6>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    }

    <div class="cat-height-100"></div>

</ng-container>