import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { MediaDetailsDialog } from "@modals/detail-pages/media-details/media-details.dialog";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { IImageDetailPageData } from "@shared/app-models";

@Component({
    selector: "gallery-item",
    templateUrl: "./gallery-item.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryItemComponent {

    @Input() refId: string = "";
    @Input() appId: string = "";
    @Input() accountId: string = "";
    @Input() conversationId: string = "";
    @Input() mediafile: cat.MediaFileMsg = cat.MediaFileMsg.create();
    @Input() mediatype: cat.MediaType = cat.MediaType.MEDIA_UNKNOWN;

    public thumbStatusEnum = cat.ThumbnailStatus;
    public linkStatusEnum = cat.ReceiverMediaLinkStatus;
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        private _logger: LoggerService,
        private _dialog: MatDialog
    ) {}

    /**
     * Open the details.
     *
     * @param {cat.MediaFileMsg} mediafile
     * @memberof GalleryItemComponent
     */
    public selectMedia(mediafile: cat.MediaFileMsg) {
        try {

            const attachment = cat.AttachmentMsg.create({
                files: [mediafile]
            });
            const dialogData: IImageDetailPageData = {
                attachment: attachment,
                refId: this.refId,
                appId: this.appId,
                accountId: this.accountId,
                conversationId: this.conversationId,
                mediatype: this.mediatype,
                asProfile: false,
                messageLink: true
            };
            this._logger.debug("Dialog Data: ", dialogData);
            this._dialog.open(MediaDetailsDialog, {
                width: "95vw",
                height: "95vh",
                data: dialogData
            });

        } catch (error) {
            this._logger.error(error);
        }
    }
}