// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

// NOTE: THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT

export interface IServiceDescriptor {
    /** The method (call) name of the service */
    methodName: string;
    /** The request type of the method */
    requestType: string;
    /** The response type of the method */
    responseType: string;
    /** The type of the service the method belongs to */
    serviceType: string;
    /** Response stream indicator  */
    responseStream: boolean;
    /** Request stream indicator */
    requestStream: boolean;
}

/** Generated userUnlock service descriptor instance */
export const userUnlock: IServiceDescriptor = {
    methodName: "userUnlock",
    requestType: "VoidMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSignOut service descriptor instance */
export const userSignOut: IServiceDescriptor = {
    methodName: "userSignOut",
    requestType: "VoidMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userChangeSessionRole service descriptor instance */
export const userChangeSessionRole: IServiceDescriptor = {
    methodName: "userChangeSessionRole",
    requestType: "UserMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetUserSessions server stream service descriptor instance */
export const userGetUserSessions: IServiceDescriptor = {
    methodName: "userGetUserSessions",
    requestType: "VoidMsg",
    responseType: "UserSessionMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetEula service descriptor instance */
export const userGetEula: IServiceDescriptor = {
    methodName: "userGetEula",
    requestType: "VoidMsg",
    responseType: "EulaMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetPasswordPolicy service descriptor instance */
export const userGetPasswordPolicy: IServiceDescriptor = {
    methodName: "userGetPasswordPolicy",
    requestType: "VoidMsg",
    responseType: "PasswordPolicyMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetTerminology service descriptor instance */
export const userSetTerminology: IServiceDescriptor = {
    methodName: "userSetTerminology",
    requestType: "TerminologyMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetSystemId service descriptor instance */
export const userGetSystemId: IServiceDescriptor = {
    methodName: "userGetSystemId",
    requestType: "VoidMsg",
    responseType: "LicenseMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetCurrentLicense service descriptor instance */
export const userGetCurrentLicense: IServiceDescriptor = {
    methodName: "userGetCurrentLicense",
    requestType: "VoidMsg",
    responseType: "LicenseMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetLicenseCount service descriptor instance */
export const userGetLicenseCount: IServiceDescriptor = {
    methodName: "userGetLicenseCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveLicense service descriptor instance */
export const userRemoveLicense: IServiceDescriptor = {
    methodName: "userRemoveLicense",
    requestType: "LicenseMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userImportLicense service descriptor instance */
export const userImportLicense: IServiceDescriptor = {
    methodName: "userImportLicense",
    requestType: "MediaMsg",
    responseType: "LicenseMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetAvailableLogFileDates service descriptor instance */
export const userGetAvailableLogFileDates: IServiceDescriptor = {
    methodName: "userGetAvailableLogFileDates",
    requestType: "VoidMsg",
    responseType: "FilterMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetLogLevel service descriptor instance */
export const userSetLogLevel: IServiceDescriptor = {
    methodName: "userSetLogLevel",
    requestType: "ServiceUpdateMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetSystemSetting service descriptor instance */
export const userSetSystemSetting: IServiceDescriptor = {
    methodName: "userSetSystemSetting",
    requestType: "SettingMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetSystemEventsFileId service descriptor instance */
export const userGetSystemEventsFileId: IServiceDescriptor = {
    methodName: "userGetSystemEventsFileId",
    requestType: "QueryMsg",
    responseType: "MediaMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetLogFileId service descriptor instance */
export const userGetLogFileId: IServiceDescriptor = {
    methodName: "userGetLogFileId",
    requestType: "QueryMsg",
    responseType: "MediaMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetSystemEventCount service descriptor instance */
export const userGetSystemEventCount: IServiceDescriptor = {
    methodName: "userGetSystemEventCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetServiceStatuses server stream service descriptor instance */
export const userGetServiceStatuses: IServiceDescriptor = {
    methodName: "userGetServiceStatuses",
    requestType: "VoidMsg",
    responseType: "ServiceStatusMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetTerminology server stream service descriptor instance */
export const userGetTerminology: IServiceDescriptor = {
    methodName: "userGetTerminology",
    requestType: "VoidMsg",
    responseType: "TerminologyMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetLicenses server stream service descriptor instance */
export const userGetLicenses: IServiceDescriptor = {
    methodName: "userGetLicenses",
    requestType: "QueryMsg",
    responseType: "LicenseMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetSystemSettings server stream service descriptor instance */
export const userGetSystemSettings: IServiceDescriptor = {
    methodName: "userGetSystemSettings",
    requestType: "VoidMsg",
    responseType: "SettingMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetSystemEvents server stream service descriptor instance */
export const userGetSystemEvents: IServiceDescriptor = {
    methodName: "userGetSystemEvents",
    requestType: "QueryMsg",
    responseType: "SystemEventMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAuditLogCount service descriptor instance */
export const userGetAuditLogCount: IServiceDescriptor = {
    methodName: "userGetAuditLogCount",
    requestType: "VoidMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetAuditLogs server stream service descriptor instance */
export const userGetAuditLogs: IServiceDescriptor = {
    methodName: "userGetAuditLogs",
    requestType: "VoidMsg",
    responseType: "AuditLogMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAuditLogUsers server stream service descriptor instance */
export const userGetAuditLogUsers: IServiceDescriptor = {
    methodName: "userGetAuditLogUsers",
    requestType: "VoidMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAuditLogCalls server stream service descriptor instance */
export const userGetAuditLogCalls: IServiceDescriptor = {
    methodName: "userGetAuditLogCalls",
    requestType: "VoidMsg",
    responseType: "AuditLogCallMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetReportTemplateCount service descriptor instance */
export const userGetReportTemplateCount: IServiceDescriptor = {
    methodName: "userGetReportTemplateCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSignIn service descriptor instance */
export const userSignIn: IServiceDescriptor = {
    methodName: "userSignIn",
    requestType: "UserMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSignInWithSession service descriptor instance */
export const userSignInWithSession: IServiceDescriptor = {
    methodName: "userSignInWithSession",
    requestType: "UserSessionMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSignInWithKeycloak service descriptor instance */
export const userSignInWithKeycloak: IServiceDescriptor = {
    methodName: "userSignInWithKeycloak",
    requestType: "UserMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAcceptEula service descriptor instance */
export const userAcceptEula: IServiceDescriptor = {
    methodName: "userAcceptEula",
    requestType: "VoidMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetCurrentUserPassword service descriptor instance */
export const userSetCurrentUserPassword: IServiceDescriptor = {
    methodName: "userSetCurrentUserPassword",
    requestType: "UserMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetCurrentUserProfileCount service descriptor instance */
export const userGetCurrentUserProfileCount: IServiceDescriptor = {
    methodName: "userGetCurrentUserProfileCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetCurrentUserCaseCount service descriptor instance */
export const userGetCurrentUserCaseCount: IServiceDescriptor = {
    methodName: "userGetCurrentUserCaseCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetCurrentUserProfileReportCount service descriptor instance */
export const userGetCurrentUserProfileReportCount: IServiceDescriptor = {
    methodName: "userGetCurrentUserProfileReportCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveCurrentUserNotifications service descriptor instance */
export const userRemoveCurrentUserNotifications: IServiceDescriptor = {
    methodName: "userRemoveCurrentUserNotifications",
    requestType: "VoidMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveProfileAvatar service descriptor instance */
export const userRemoveProfileAvatar: IServiceDescriptor = {
    methodName: "userRemoveProfileAvatar",
    requestType: "ProfileMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetCurrentUser service descriptor instance */
export const userSetCurrentUser: IServiceDescriptor = {
    methodName: "userSetCurrentUser",
    requestType: "UserMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetCurrentUserLastPopover service descriptor instance */
export const userSetCurrentUserLastPopover: IServiceDescriptor = {
    methodName: "userSetCurrentUserLastPopover",
    requestType: "UserMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRestoreCurrentUserPopovers service descriptor instance */
export const userRestoreCurrentUserPopovers: IServiceDescriptor = {
    methodName: "userRestoreCurrentUserPopovers",
    requestType: "VoidMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetCurrentUserLastProfile service descriptor instance */
export const userSetCurrentUserLastProfile: IServiceDescriptor = {
    methodName: "userSetCurrentUserLastProfile",
    requestType: "ProfileMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetCurrentUserLastConversation service descriptor instance */
export const userSetCurrentUserLastConversation: IServiceDescriptor = {
    methodName: "userSetCurrentUserLastConversation",
    requestType: "ConversationMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userResetUserPassword service descriptor instance */
export const userResetUserPassword: IServiceDescriptor = {
    methodName: "userResetUserPassword",
    requestType: "UserMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userResetCurrentUserPassword service descriptor instance */
export const userResetCurrentUserPassword: IServiceDescriptor = {
    methodName: "userResetCurrentUserPassword",
    requestType: "UserMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnmergeContact service descriptor instance */
export const userUnmergeContact: IServiceDescriptor = {
    methodName: "userUnmergeContact",
    requestType: "AccountMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetContactAlias service descriptor instance */
export const userSetContactAlias: IServiceDescriptor = {
    methodName: "userSetContactAlias",
    requestType: "AccountMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetProfileHidden service descriptor instance */
export const userSetProfileHidden: IServiceDescriptor = {
    methodName: "userSetProfileHidden",
    requestType: "ProfileMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDetailedAccount service descriptor instance */
export const userGetDetailedAccount: IServiceDescriptor = {
    methodName: "userGetDetailedAccount",
    requestType: "AccountMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userLinkDeviceToProfile service descriptor instance */
export const userLinkDeviceToProfile: IServiceDescriptor = {
    methodName: "userLinkDeviceToProfile",
    requestType: "DeviceMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnlinkDeviceFromProfile service descriptor instance */
export const userUnlinkDeviceFromProfile: IServiceDescriptor = {
    methodName: "userUnlinkDeviceFromProfile",
    requestType: "DeviceMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetRolePermission service descriptor instance */
export const userSetRolePermission: IServiceDescriptor = {
    methodName: "userSetRolePermission",
    requestType: "RoleMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnsetRolePermission service descriptor instance */
export const userUnsetRolePermission: IServiceDescriptor = {
    methodName: "userUnsetRolePermission",
    requestType: "RoleMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetConversationVisibility service descriptor instance */
export const userSetConversationVisibility: IServiceDescriptor = {
    methodName: "userSetConversationVisibility",
    requestType: "ConversationMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetServerVisibility service descriptor instance */
export const userSetServerVisibility: IServiceDescriptor = {
    methodName: "userSetServerVisibility",
    requestType: "ServerMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDeviceAppStatistics service descriptor instance */
export const userGetDeviceAppStatistics: IServiceDescriptor = {
    methodName: "userGetDeviceAppStatistics",
    requestType: "DeviceMsg",
    responseType: "StatisticsSeriesMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetGlobalAppStatistics service descriptor instance */
export const userGetGlobalAppStatistics: IServiceDescriptor = {
    methodName: "userGetGlobalAppStatistics",
    requestType: "VoidMsg",
    responseType: "StatisticsSeriesMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetGlobalFailedItemsStatistics service descriptor instance */
export const userGetGlobalFailedItemsStatistics: IServiceDescriptor = {
    methodName: "userGetGlobalFailedItemsStatistics",
    requestType: "DeviceMsg",
    responseType: "MediaMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddDomain service descriptor instance */
export const userAddDomain: IServiceDescriptor = {
    methodName: "userAddDomain",
    requestType: "DomainMsg",
    responseType: "DomainMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userModifyDomain service descriptor instance */
export const userModifyDomain: IServiceDescriptor = {
    methodName: "userModifyDomain",
    requestType: "DomainMsg",
    responseType: "DomainMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveDomain service descriptor instance */
export const userRemoveDomain: IServiceDescriptor = {
    methodName: "userRemoveDomain",
    requestType: "QueryMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userLinkDeviceToDomain service descriptor instance */
export const userLinkDeviceToDomain: IServiceDescriptor = {
    methodName: "userLinkDeviceToDomain",
    requestType: "DeviceMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnlinkDeviceFromDomain service descriptor instance */
export const userUnlinkDeviceFromDomain: IServiceDescriptor = {
    methodName: "userUnlinkDeviceFromDomain",
    requestType: "DeviceMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetProfileFirstMessageTimestamp service descriptor instance */
export const userGetProfileFirstMessageTimestamp: IServiceDescriptor = {
    methodName: "userGetProfileFirstMessageTimestamp",
    requestType: "ProfileMsg",
    responseType: "ProfileMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetConversationReport service descriptor instance */
export const userGetConversationReport: IServiceDescriptor = {
    methodName: "userGetConversationReport",
    requestType: "ConversationMsg",
    responseType: "ReportMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetContactReport service descriptor instance */
export const userGetContactReport: IServiceDescriptor = {
    methodName: "userGetContactReport",
    requestType: "AccountMsg",
    responseType: "ReportMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetExistingReport service descriptor instance */
export const userGetExistingReport: IServiceDescriptor = {
    methodName: "userGetExistingReport",
    requestType: "ReportMsg",
    responseType: "ReportMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetReportingCounts service descriptor instance */
export const userGetReportingCounts: IServiceDescriptor = {
    methodName: "userGetReportingCounts",
    requestType: "ReportMsg",
    responseType: "ReportMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRetryProfileReport service descriptor instance */
export const userRetryProfileReport: IServiceDescriptor = {
    methodName: "userRetryProfileReport",
    requestType: "ProfileMsg",
    responseType: "ReportMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetProfileConversationCount service descriptor instance */
export const userGetProfileConversationCount: IServiceDescriptor = {
    methodName: "userGetProfileConversationCount",
    requestType: "ProfileMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetProfileConversationCounts service descriptor instance */
export const userGetProfileConversationCounts: IServiceDescriptor = {
    methodName: "userGetProfileConversationCounts",
    requestType: "QueryMsg",
    responseType: "FilterCountMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetAccountConversationCount service descriptor instance */
export const userGetAccountConversationCount: IServiceDescriptor = {
    methodName: "userGetAccountConversationCount",
    requestType: "AccountMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetExcludedTopicsMessages service descriptor instance */
export const userGetExcludedTopicsMessages: IServiceDescriptor = {
    methodName: "userGetExcludedTopicsMessages",
    requestType: "ConversationMsg",
    responseType: "ConversationMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetUserCount service descriptor instance */
export const userGetUserCount: IServiceDescriptor = {
    methodName: "userGetUserCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddUser service descriptor instance */
export const userAddUser: IServiceDescriptor = {
    methodName: "userAddUser",
    requestType: "UserMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userModifyUser service descriptor instance */
export const userModifyUser: IServiceDescriptor = {
    methodName: "userModifyUser",
    requestType: "UserMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveUser service descriptor instance */
export const userRemoveUser: IServiceDescriptor = {
    methodName: "userRemoveUser",
    requestType: "UserMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddRole service descriptor instance */
export const userAddRole: IServiceDescriptor = {
    methodName: "userAddRole",
    requestType: "RoleMsg",
    responseType: "RoleMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userModifyRole service descriptor instance */
export const userModifyRole: IServiceDescriptor = {
    methodName: "userModifyRole",
    requestType: "RoleMsg",
    responseType: "RoleMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveRole service descriptor instance */
export const userRemoveRole: IServiceDescriptor = {
    methodName: "userRemoveRole",
    requestType: "RoleMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetThemeCount service descriptor instance */
export const userGetThemeCount: IServiceDescriptor = {
    methodName: "userGetThemeCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddTheme service descriptor instance */
export const userAddTheme: IServiceDescriptor = {
    methodName: "userAddTheme",
    requestType: "ThemeMsg",
    responseType: "ThemeMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userModifyTheme service descriptor instance */
export const userModifyTheme: IServiceDescriptor = {
    methodName: "userModifyTheme",
    requestType: "ThemeMsg",
    responseType: "ThemeMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveTheme service descriptor instance */
export const userRemoveTheme: IServiceDescriptor = {
    methodName: "userRemoveTheme",
    requestType: "ThemeMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetCaseCount service descriptor instance */
export const userGetCaseCount: IServiceDescriptor = {
    methodName: "userGetCaseCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddCase service descriptor instance */
export const userAddCase: IServiceDescriptor = {
    methodName: "userAddCase",
    requestType: "CaseMsg",
    responseType: "CaseMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userModifyCase service descriptor instance */
export const userModifyCase: IServiceDescriptor = {
    methodName: "userModifyCase",
    requestType: "CaseMsg",
    responseType: "CaseMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveCase service descriptor instance */
export const userRemoveCase: IServiceDescriptor = {
    methodName: "userRemoveCase",
    requestType: "CaseMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddReportTemplate service descriptor instance */
export const userAddReportTemplate: IServiceDescriptor = {
    methodName: "userAddReportTemplate",
    requestType: "ReportTemplateMsg",
    responseType: "ReportTemplateMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userModifyReportTemplate service descriptor instance */
export const userModifyReportTemplate: IServiceDescriptor = {
    methodName: "userModifyReportTemplate",
    requestType: "ReportTemplateMsg",
    responseType: "ReportTemplateMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveReportTemplate service descriptor instance */
export const userRemoveReportTemplate: IServiceDescriptor = {
    methodName: "userRemoveReportTemplate",
    requestType: "ReportTemplateMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDeviceCount service descriptor instance */
export const userGetDeviceCount: IServiceDescriptor = {
    methodName: "userGetDeviceCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRegisterDevice service descriptor instance */
export const userRegisterDevice: IServiceDescriptor = {
    methodName: "userRegisterDevice",
    requestType: "DeviceMsg",
    responseType: "DeviceMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddDevice service descriptor instance */
export const userAddDevice: IServiceDescriptor = {
    methodName: "userAddDevice",
    requestType: "DeviceMsg",
    responseType: "DeviceMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userModifyDevice service descriptor instance */
export const userModifyDevice: IServiceDescriptor = {
    methodName: "userModifyDevice",
    requestType: "DeviceMsg",
    responseType: "DeviceMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveDevice service descriptor instance */
export const userRemoveDevice: IServiceDescriptor = {
    methodName: "userRemoveDevice",
    requestType: "DeviceMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetProfileCount service descriptor instance */
export const userGetProfileCount: IServiceDescriptor = {
    methodName: "userGetProfileCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddProfile service descriptor instance */
export const userAddProfile: IServiceDescriptor = {
    methodName: "userAddProfile",
    requestType: "ProfileMsg",
    responseType: "ProfileMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddProfileToCurrentUser service descriptor instance */
export const userAddProfileToCurrentUser: IServiceDescriptor = {
    methodName: "userAddProfileToCurrentUser",
    requestType: "ProfileMsg",
    responseType: "ProfileMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userModifyProfile service descriptor instance */
export const userModifyProfile: IServiceDescriptor = {
    methodName: "userModifyProfile",
    requestType: "ProfileMsg",
    responseType: "ProfileMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveProfile service descriptor instance */
export const userRemoveProfile: IServiceDescriptor = {
    methodName: "userRemoveProfile",
    requestType: "ProfileMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveCurrentUserProfile service descriptor instance */
export const userRemoveCurrentUserProfile: IServiceDescriptor = {
    methodName: "userRemoveCurrentUserProfile",
    requestType: "ProfileMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveProfileMedia service descriptor instance */
export const userRemoveProfileMedia: IServiceDescriptor = {
    methodName: "userRemoveProfileMedia",
    requestType: "ProfileMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDetailedConversation service descriptor instance */
export const userGetDetailedConversation: IServiceDescriptor = {
    methodName: "userGetDetailedConversation",
    requestType: "ConversationMsg",
    responseType: "ConversationMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDetailedMessage service descriptor instance */
export const userGetDetailedMessage: IServiceDescriptor = {
    methodName: "userGetDetailedMessage",
    requestType: "MessageMsg",
    responseType: "MessageMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userLinkProfileToCase service descriptor instance */
export const userLinkProfileToCase: IServiceDescriptor = {
    methodName: "userLinkProfileToCase",
    requestType: "CaseMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnlinkProfileFromCase service descriptor instance */
export const userUnlinkProfileFromCase: IServiceDescriptor = {
    methodName: "userUnlinkProfileFromCase",
    requestType: "CaseMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userLinkSupervisorToCase service descriptor instance */
export const userLinkSupervisorToCase: IServiceDescriptor = {
    methodName: "userLinkSupervisorToCase",
    requestType: "CaseMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnlinkSupervisorFromCase service descriptor instance */
export const userUnlinkSupervisorFromCase: IServiceDescriptor = {
    methodName: "userUnlinkSupervisorFromCase",
    requestType: "CaseMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddCaseLabel service descriptor instance */
export const userAddCaseLabel: IServiceDescriptor = {
    methodName: "userAddCaseLabel",
    requestType: "CaseMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveCaseLabel service descriptor instance */
export const userRemoveCaseLabel: IServiceDescriptor = {
    methodName: "userRemoveCaseLabel",
    requestType: "CaseMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddProfileReport service descriptor instance */
export const userAddProfileReport: IServiceDescriptor = {
    methodName: "userAddProfileReport",
    requestType: "ProfileMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveProfileReport service descriptor instance */
export const userRemoveProfileReport: IServiceDescriptor = {
    methodName: "userRemoveProfileReport",
    requestType: "ReportMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddProfileLabel service descriptor instance */
export const userAddProfileLabel: IServiceDescriptor = {
    methodName: "userAddProfileLabel",
    requestType: "ProfileMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveProfileLabel service descriptor instance */
export const userRemoveProfileLabel: IServiceDescriptor = {
    methodName: "userRemoveProfileLabel",
    requestType: "ProfileMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetProfileContact service descriptor instance */
export const userGetProfileContact: IServiceDescriptor = {
    methodName: "userGetProfileContact",
    requestType: "AccountMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetProfileContactCount service descriptor instance */
export const userGetProfileContactCount: IServiceDescriptor = {
    methodName: "userGetProfileContactCount",
    requestType: "ProfileMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetProfileAccount service descriptor instance */
export const userSetProfileAccount: IServiceDescriptor = {
    methodName: "userSetProfileAccount",
    requestType: "ProfileMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userModifyAppAccount service descriptor instance */
export const userModifyAppAccount: IServiceDescriptor = {
    methodName: "userModifyAppAccount",
    requestType: "AccountMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDetailedTopic service descriptor instance */
export const userGetDetailedTopic: IServiceDescriptor = {
    methodName: "userGetDetailedTopic",
    requestType: "TopicMsg",
    responseType: "TopicMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddGlobalLabel service descriptor instance */
export const userAddGlobalLabel: IServiceDescriptor = {
    methodName: "userAddGlobalLabel",
    requestType: "LabelMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveGlobalLabel service descriptor instance */
export const userRemoveGlobalLabel: IServiceDescriptor = {
    methodName: "userRemoveGlobalLabel",
    requestType: "LabelMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetMessageLabel service descriptor instance */
export const userSetMessageLabel: IServiceDescriptor = {
    methodName: "userSetMessageLabel",
    requestType: "MessageMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnsetMessageLabel service descriptor instance */
export const userUnsetMessageLabel: IServiceDescriptor = {
    methodName: "userUnsetMessageLabel",
    requestType: "MessageMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddMessageAnnotation service descriptor instance */
export const userAddMessageAnnotation: IServiceDescriptor = {
    methodName: "userAddMessageAnnotation",
    requestType: "MessageMsg",
    responseType: "AnnotationMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveMessageAnnotation service descriptor instance */
export const userRemoveMessageAnnotation: IServiceDescriptor = {
    methodName: "userRemoveMessageAnnotation",
    requestType: "MessageMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddConversationAnnotation service descriptor instance */
export const userAddConversationAnnotation: IServiceDescriptor = {
    methodName: "userAddConversationAnnotation",
    requestType: "ConversationMsg",
    responseType: "AnnotationMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveConversationAnnotation service descriptor instance */
export const userRemoveConversationAnnotation: IServiceDescriptor = {
    methodName: "userRemoveConversationAnnotation",
    requestType: "ConversationMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetTopicLabel service descriptor instance */
export const userSetTopicLabel: IServiceDescriptor = {
    methodName: "userSetTopicLabel",
    requestType: "TopicMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnsetTopicLabel service descriptor instance */
export const userUnsetTopicLabel: IServiceDescriptor = {
    methodName: "userUnsetTopicLabel",
    requestType: "TopicMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddTopicAnnotation service descriptor instance */
export const userAddTopicAnnotation: IServiceDescriptor = {
    methodName: "userAddTopicAnnotation",
    requestType: "TopicMsg",
    responseType: "AnnotationMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveTopicAnnotation service descriptor instance */
export const userRemoveTopicAnnotation: IServiceDescriptor = {
    methodName: "userRemoveTopicAnnotation",
    requestType: "TopicMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetServerLabel service descriptor instance */
export const userSetServerLabel: IServiceDescriptor = {
    methodName: "userSetServerLabel",
    requestType: "ServerMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnsetServerLabel service descriptor instance */
export const userUnsetServerLabel: IServiceDescriptor = {
    methodName: "userUnsetServerLabel",
    requestType: "ServerMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddServerAnnotation service descriptor instance */
export const userAddServerAnnotation: IServiceDescriptor = {
    methodName: "userAddServerAnnotation",
    requestType: "ServerMsg",
    responseType: "AnnotationMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveServerAnnotation service descriptor instance */
export const userRemoveServerAnnotation: IServiceDescriptor = {
    methodName: "userRemoveServerAnnotation",
    requestType: "ServerMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetConversationLabel service descriptor instance */
export const userSetConversationLabel: IServiceDescriptor = {
    methodName: "userSetConversationLabel",
    requestType: "ConversationMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnsetConversationLabel service descriptor instance */
export const userUnsetConversationLabel: IServiceDescriptor = {
    methodName: "userUnsetConversationLabel",
    requestType: "ConversationMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDetailedServer service descriptor instance */
export const userGetDetailedServer: IServiceDescriptor = {
    methodName: "userGetDetailedServer",
    requestType: "ServerMsg",
    responseType: "ServerMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetConversationMediaCount service descriptor instance */
export const userGetConversationMediaCount: IServiceDescriptor = {
    methodName: "userGetConversationMediaCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetConversationMemberCount service descriptor instance */
export const userGetConversationMemberCount: IServiceDescriptor = {
    methodName: "userGetConversationMemberCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetContactLabel service descriptor instance */
export const userSetContactLabel: IServiceDescriptor = {
    methodName: "userSetContactLabel",
    requestType: "AccountMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnsetContactLabel service descriptor instance */
export const userUnsetContactLabel: IServiceDescriptor = {
    methodName: "userUnsetContactLabel",
    requestType: "AccountMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userAddContactAnnotation service descriptor instance */
export const userAddContactAnnotation: IServiceDescriptor = {
    methodName: "userAddContactAnnotation",
    requestType: "AccountMsg",
    responseType: "AnnotationMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveContactAnnotation service descriptor instance */
export const userRemoveContactAnnotation: IServiceDescriptor = {
    methodName: "userRemoveContactAnnotation",
    requestType: "AccountMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetAccountConversationCounts service descriptor instance */
export const userGetAccountConversationCounts: IServiceDescriptor = {
    methodName: "userGetAccountConversationCounts",
    requestType: "AccountMsg",
    responseType: "FilterCountMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetAccountServerCounts service descriptor instance */
export const userGetAccountServerCounts: IServiceDescriptor = {
    methodName: "userGetAccountServerCounts",
    requestType: "AccountMsg",
    responseType: "FilterCountMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetServerConversationCounts service descriptor instance */
export const userGetServerConversationCounts: IServiceDescriptor = {
    methodName: "userGetServerConversationCounts",
    requestType: "ServerMsg",
    responseType: "FilterCountMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetConversationTopicCounts service descriptor instance */
export const userGetConversationTopicCounts: IServiceDescriptor = {
    methodName: "userGetConversationTopicCounts",
    requestType: "ConversationMsg",
    responseType: "FilterCountMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userSetUserProfileOwner service descriptor instance */
export const userSetUserProfileOwner: IServiceDescriptor = {
    methodName: "userSetUserProfileOwner",
    requestType: "UserMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveUserProfileOwner service descriptor instance */
export const userRemoveUserProfileOwner: IServiceDescriptor = {
    methodName: "userRemoveUserProfileOwner",
    requestType: "UserMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userLinkSupervisorUserToUser service descriptor instance */
export const userLinkSupervisorUserToUser: IServiceDescriptor = {
    methodName: "userLinkSupervisorUserToUser",
    requestType: "UserMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userUnlinkSupervisorUserFromUser service descriptor instance */
export const userUnlinkSupervisorUserFromUser: IServiceDescriptor = {
    methodName: "userUnlinkSupervisorUserFromUser",
    requestType: "UserMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDeviceBackupCount service descriptor instance */
export const userGetDeviceBackupCount: IServiceDescriptor = {
    methodName: "userGetDeviceBackupCount",
    requestType: "DeviceMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveDeviceBackup service descriptor instance */
export const userRemoveDeviceBackup: IServiceDescriptor = {
    methodName: "userRemoveDeviceBackup",
    requestType: "BackUpMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetAPKCount service descriptor instance */
export const userGetAPKCount: IServiceDescriptor = {
    methodName: "userGetAPKCount",
    requestType: "QueryMsg",
    responseType: "QueryMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveAPK service descriptor instance */
export const userRemoveAPK: IServiceDescriptor = {
    methodName: "userRemoveAPK",
    requestType: "AppMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userPatchAPK service descriptor instance */
export const userPatchAPK: IServiceDescriptor = {
    methodName: "userPatchAPK",
    requestType: "APKMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDeviceLogFileId service descriptor instance */
export const userGetDeviceLogFileId: IServiceDescriptor = {
    methodName: "userGetDeviceLogFileId",
    requestType: "QueryMsg",
    responseType: "MediaMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetAvailableDeviceLogFiles service descriptor instance */
export const userGetAvailableDeviceLogFiles: IServiceDescriptor = {
    methodName: "userGetAvailableDeviceLogFiles",
    requestType: "DeviceMsg",
    responseType: "FilterMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userRemoveAccount service descriptor instance */
export const userRemoveAccount: IServiceDescriptor = {
    methodName: "userRemoveAccount",
    requestType: "QueryMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userStartDataCleaning service descriptor instance */
export const userStartDataCleaning: IServiceDescriptor = {
    methodName: "userStartDataCleaning",
    requestType: "VoidMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDataCleaningStatus service descriptor instance */
export const userGetDataCleaningStatus: IServiceDescriptor = {
    methodName: "userGetDataCleaningStatus",
    requestType: "VoidMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetCurrentUserProfilesExportFileId service descriptor instance */
export const userGetCurrentUserProfilesExportFileId: IServiceDescriptor = {
    methodName: "userGetCurrentUserProfilesExportFileId",
    requestType: "QueryMsg",
    responseType: "MediaMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetConversationMemberExportFileId service descriptor instance */
export const userGetConversationMemberExportFileId: IServiceDescriptor = {
    methodName: "userGetConversationMemberExportFileId",
    requestType: "QueryMsg",
    responseType: "MediaMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetDevicesExportFileId service descriptor instance */
export const userGetDevicesExportFileId: IServiceDescriptor = {
    methodName: "userGetDevicesExportFileId",
    requestType: "QueryMsg",
    responseType: "MediaMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetReportTemplates server stream service descriptor instance */
export const userGetReportTemplates: IServiceDescriptor = {
    methodName: "userGetReportTemplates",
    requestType: "ReportTemplateMsg",
    responseType: "ReportTemplateMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCurrentUserPermissions server stream service descriptor instance */
export const userGetCurrentUserPermissions: IServiceDescriptor = {
    methodName: "userGetCurrentUserPermissions",
    requestType: "VoidMsg",
    responseType: "PermissionMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCurrentUserProfiles server stream service descriptor instance */
export const userGetCurrentUserProfiles: IServiceDescriptor = {
    methodName: "userGetCurrentUserProfiles",
    requestType: "ProfileMsg",
    responseType: "ProfileMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetUserProfiles server stream service descriptor instance */
export const userGetUserProfiles: IServiceDescriptor = {
    methodName: "userGetUserProfiles",
    requestType: "UserMsg",
    responseType: "ProfileMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCurrentUserCases server stream service descriptor instance */
export const userGetCurrentUserCases: IServiceDescriptor = {
    methodName: "userGetCurrentUserCases",
    requestType: "VoidMsg",
    responseType: "CaseMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAvailableCaseProfiles server stream service descriptor instance */
export const userGetAvailableCaseProfiles: IServiceDescriptor = {
    methodName: "userGetAvailableCaseProfiles",
    requestType: "CaseMsg",
    responseType: "ProfileMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCurrentUserProfileReports server stream service descriptor instance */
export const userGetCurrentUserProfileReports: IServiceDescriptor = {
    methodName: "userGetCurrentUserProfileReports",
    requestType: "QueryMsg",
    responseType: "ReportMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCurrentUserProfileMedia server stream service descriptor instance */
export const userGetCurrentUserProfileMedia: IServiceDescriptor = {
    methodName: "userGetCurrentUserProfileMedia",
    requestType: "ProfileMsg",
    responseType: "AttachmentMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCurrentUserDevices server stream service descriptor instance */
export const userGetCurrentUserDevices: IServiceDescriptor = {
    methodName: "userGetCurrentUserDevices",
    requestType: "DeviceMsg",
    responseType: "DeviceMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCurrentUserNotifications server stream service descriptor instance */
export const userGetCurrentUserNotifications: IServiceDescriptor = {
    methodName: "userGetCurrentUserNotifications",
    requestType: "VoidMsg",
    responseType: "NotificationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetContactMerges server stream service descriptor instance */
export const userGetContactMerges: IServiceDescriptor = {
    methodName: "userGetContactMerges",
    requestType: "AccountMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCurrentUserThemes server stream service descriptor instance */
export const userGetCurrentUserThemes: IServiceDescriptor = {
    methodName: "userGetCurrentUserThemes",
    requestType: "VoidMsg",
    responseType: "ThemeMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAccountMedia server stream service descriptor instance */
export const userGetAccountMedia: IServiceDescriptor = {
    methodName: "userGetAccountMedia",
    requestType: "AccountMsg",
    responseType: "MediaFileMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetProfileAccounts server stream service descriptor instance */
export const userGetProfileAccounts: IServiceDescriptor = {
    methodName: "userGetProfileAccounts",
    requestType: "ProfileMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetProfileDevices server stream service descriptor instance */
export const userGetProfileDevices: IServiceDescriptor = {
    methodName: "userGetProfileDevices",
    requestType: "ProfileMsg",
    responseType: "DeviceMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetProfileAccountsMediaCount server stream service descriptor instance */
export const userGetProfileAccountsMediaCount: IServiceDescriptor = {
    methodName: "userGetProfileAccountsMediaCount",
    requestType: "ProfileMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetRolesWithPermissions server stream service descriptor instance */
export const userGetRolesWithPermissions: IServiceDescriptor = {
    methodName: "userGetRolesWithPermissions",
    requestType: "RoleMsg",
    responseType: "RoleMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetUsersAssignableToProfile server stream service descriptor instance */
export const userGetUsersAssignableToProfile: IServiceDescriptor = {
    methodName: "userGetUsersAssignableToProfile",
    requestType: "VoidMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetDomains server stream service descriptor instance */
export const userGetDomains: IServiceDescriptor = {
    methodName: "userGetDomains",
    requestType: "VoidMsg",
    responseType: "DomainMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAvailableReportTemplates server stream service descriptor instance */
export const userGetAvailableReportTemplates: IServiceDescriptor = {
    methodName: "userGetAvailableReportTemplates",
    requestType: "VoidMsg",
    responseType: "ReportTemplateMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetReportingLabels server stream service descriptor instance */
export const userGetReportingLabels: IServiceDescriptor = {
    methodName: "userGetReportingLabels",
    requestType: "ProfileMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetReportingAccounts server stream service descriptor instance */
export const userGetReportingAccounts: IServiceDescriptor = {
    methodName: "userGetReportingAccounts",
    requestType: "QueryMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetProfileConversations server stream service descriptor instance */
export const userGetProfileConversations: IServiceDescriptor = {
    methodName: "userGetProfileConversations",
    requestType: "ProfileMsg",
    responseType: "ConversationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetUsers server stream service descriptor instance */
export const userGetUsers: IServiceDescriptor = {
    methodName: "userGetUsers",
    requestType: "UserMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetRoles server stream service descriptor instance */
export const userGetRoles: IServiceDescriptor = {
    methodName: "userGetRoles",
    requestType: "RoleMsg",
    responseType: "RoleMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetThemes server stream service descriptor instance */
export const userGetThemes: IServiceDescriptor = {
    methodName: "userGetThemes",
    requestType: "ThemeMsg",
    responseType: "ThemeMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetThemeUsers server stream service descriptor instance */
export const userGetThemeUsers: IServiceDescriptor = {
    methodName: "userGetThemeUsers",
    requestType: "ThemeMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetThemeUserProfileAccounts server stream service descriptor instance */
export const userGetThemeUserProfileAccounts: IServiceDescriptor = {
    methodName: "userGetThemeUserProfileAccounts",
    requestType: "QueryMsg",
    responseType: "ProfileMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAppTypes server stream service descriptor instance */
export const userGetAppTypes: IServiceDescriptor = {
    methodName: "userGetAppTypes",
    requestType: "VoidMsg",
    responseType: "AppTypeMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCases server stream service descriptor instance */
export const userGetCases: IServiceDescriptor = {
    methodName: "userGetCases",
    requestType: "CaseMsg",
    responseType: "CaseMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetDevices server stream service descriptor instance */
export const userGetDevices: IServiceDescriptor = {
    methodName: "userGetDevices",
    requestType: "DeviceMsg",
    responseType: "DeviceMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetDeviceUsers server stream service descriptor instance */
export const userGetDeviceUsers: IServiceDescriptor = {
    methodName: "userGetDeviceUsers",
    requestType: "VoidMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetProfiles server stream service descriptor instance */
export const userGetProfiles: IServiceDescriptor = {
    methodName: "userGetProfiles",
    requestType: "ProfileMsg",
    responseType: "ProfileMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetProfileUsers server stream service descriptor instance */
export const userGetProfileUsers: IServiceDescriptor = {
    methodName: "userGetProfileUsers",
    requestType: "VoidMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCaseProfiles server stream service descriptor instance */
export const userGetCaseProfiles: IServiceDescriptor = {
    methodName: "userGetCaseProfiles",
    requestType: "CaseMsg",
    responseType: "ProfileMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetCaseLabels server stream service descriptor instance */
export const userGetCaseLabels: IServiceDescriptor = {
    methodName: "userGetCaseLabels",
    requestType: "CaseMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetProfileLabels server stream service descriptor instance */
export const userGetProfileLabels: IServiceDescriptor = {
    methodName: "userGetProfileLabels",
    requestType: "ProfileMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetProfileContacts server stream service descriptor instance */
export const userGetProfileContacts: IServiceDescriptor = {
    methodName: "userGetProfileContacts",
    requestType: "ProfileMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetContactConversations server stream service descriptor instance */
export const userGetContactConversations: IServiceDescriptor = {
    methodName: "userGetContactConversations",
    requestType: "AccountMsg",
    responseType: "ConversationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetDeviceAccounts server stream service descriptor instance */
export const userGetDeviceAccounts: IServiceDescriptor = {
    methodName: "userGetDeviceAccounts",
    requestType: "DeviceMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAccountContacts server stream service descriptor instance */
export const userGetAccountContacts: IServiceDescriptor = {
    methodName: "userGetAccountContacts",
    requestType: "AccountMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetConversationMessages server stream service descriptor instance */
export const userGetConversationMessages: IServiceDescriptor = {
    methodName: "userGetConversationMessages",
    requestType: "ConversationMsg",
    responseType: "MessageMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetMessageMessages server stream service descriptor instance */
export const userGetMessageMessages: IServiceDescriptor = {
    methodName: "userGetMessageMessages",
    requestType: "ConversationMsg",
    responseType: "MessageMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userSearchConversationMessages server stream service descriptor instance */
export const userSearchConversationMessages: IServiceDescriptor = {
    methodName: "userSearchConversationMessages",
    requestType: "ConversationMsg",
    responseType: "MessageMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userSearchTopicMessages server stream service descriptor instance */
export const userSearchTopicMessages: IServiceDescriptor = {
    methodName: "userSearchTopicMessages",
    requestType: "TopicMsg",
    responseType: "MessageMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetTopicMessages server stream service descriptor instance */
export const userGetTopicMessages: IServiceDescriptor = {
    methodName: "userGetTopicMessages",
    requestType: "TopicMsg",
    responseType: "MessageMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetMessageAttachments server stream service descriptor instance */
export const userGetMessageAttachments: IServiceDescriptor = {
    methodName: "userGetMessageAttachments",
    requestType: "MessageMsg",
    responseType: "AttachmentMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetMessageReactions server stream service descriptor instance */
export const userGetMessageReactions: IServiceDescriptor = {
    methodName: "userGetMessageReactions",
    requestType: "MessageMsg",
    responseType: "ReactionMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetGlobalLabels server stream service descriptor instance */
export const userGetGlobalLabels: IServiceDescriptor = {
    methodName: "userGetGlobalLabels",
    requestType: "VoidMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetMessageLabels server stream service descriptor instance */
export const userGetMessageLabels: IServiceDescriptor = {
    methodName: "userGetMessageLabels",
    requestType: "MessageMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetMessageAnnotations server stream service descriptor instance */
export const userGetMessageAnnotations: IServiceDescriptor = {
    methodName: "userGetMessageAnnotations",
    requestType: "MessageMsg",
    responseType: "AnnotationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetConversationAnnotations server stream service descriptor instance */
export const userGetConversationAnnotations: IServiceDescriptor = {
    methodName: "userGetConversationAnnotations",
    requestType: "ConversationMsg",
    responseType: "AnnotationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetTopicLabels server stream service descriptor instance */
export const userGetTopicLabels: IServiceDescriptor = {
    methodName: "userGetTopicLabels",
    requestType: "TopicMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetTopicAnnotations server stream service descriptor instance */
export const userGetTopicAnnotations: IServiceDescriptor = {
    methodName: "userGetTopicAnnotations",
    requestType: "TopicMsg",
    responseType: "AnnotationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetServerLabels server stream service descriptor instance */
export const userGetServerLabels: IServiceDescriptor = {
    methodName: "userGetServerLabels",
    requestType: "ServerMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetServerAnnotations server stream service descriptor instance */
export const userGetServerAnnotations: IServiceDescriptor = {
    methodName: "userGetServerAnnotations",
    requestType: "ServerMsg",
    responseType: "AnnotationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetConversationLabels server stream service descriptor instance */
export const userGetConversationLabels: IServiceDescriptor = {
    methodName: "userGetConversationLabels",
    requestType: "ConversationMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAccountServers server stream service descriptor instance */
export const userGetAccountServers: IServiceDescriptor = {
    methodName: "userGetAccountServers",
    requestType: "AccountMsg",
    responseType: "ServerMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAccountConversations server stream service descriptor instance */
export const userGetAccountConversations: IServiceDescriptor = {
    methodName: "userGetAccountConversations",
    requestType: "AccountMsg",
    responseType: "ConversationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetServerConversations server stream service descriptor instance */
export const userGetServerConversations: IServiceDescriptor = {
    methodName: "userGetServerConversations",
    requestType: "ServerMsg",
    responseType: "ConversationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetConversationTopics server stream service descriptor instance */
export const userGetConversationTopics: IServiceDescriptor = {
    methodName: "userGetConversationTopics",
    requestType: "ConversationMsg",
    responseType: "TopicMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetConversationMembers server stream service descriptor instance */
export const userGetConversationMembers: IServiceDescriptor = {
    methodName: "userGetConversationMembers",
    requestType: "ConversationMsg",
    responseType: "AccountMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetConversationMedia server stream service descriptor instance */
export const userGetConversationMedia: IServiceDescriptor = {
    methodName: "userGetConversationMedia",
    requestType: "ConversationMsg",
    responseType: "MediaFileMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetContactLabels server stream service descriptor instance */
export const userGetContactLabels: IServiceDescriptor = {
    methodName: "userGetContactLabels",
    requestType: "AccountMsg",
    responseType: "LabelMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetContactAnnotations server stream service descriptor instance */
export const userGetContactAnnotations: IServiceDescriptor = {
    methodName: "userGetContactAnnotations",
    requestType: "AccountMsg",
    responseType: "AnnotationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userSearchAccountsAndContactsSystemWide server stream service descriptor instance */
export const userSearchAccountsAndContactsSystemWide: IServiceDescriptor = {
    methodName: "userSearchAccountsAndContactsSystemWide",
    requestType: "QueryMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userSearchConversationsSystemWide server stream service descriptor instance */
export const userSearchConversationsSystemWide: IServiceDescriptor = {
    methodName: "userSearchConversationsSystemWide",
    requestType: "QueryMsg",
    responseType: "ConversationMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetSupervisorUsers server stream service descriptor instance */
export const userGetSupervisorUsers: IServiceDescriptor = {
    methodName: "userGetSupervisorUsers",
    requestType: "VoidMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetLinkableToSupervisorUsers server stream service descriptor instance */
export const userGetLinkableToSupervisorUsers: IServiceDescriptor = {
    methodName: "userGetLinkableToSupervisorUsers",
    requestType: "UserMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetLinkableToDeviceUsers server stream service descriptor instance */
export const userGetLinkableToDeviceUsers: IServiceDescriptor = {
    methodName: "userGetLinkableToDeviceUsers",
    requestType: "VoidMsg",
    responseType: "UserMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetDeviceBackups server stream service descriptor instance */
export const userGetDeviceBackups: IServiceDescriptor = {
    methodName: "userGetDeviceBackups",
    requestType: "DeviceMsg",
    responseType: "BackUpMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAPKS server stream service descriptor instance */
export const userGetAPKS: IServiceDescriptor = {
    methodName: "userGetAPKS",
    requestType: "VoidMsg",
    responseType: "APKMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetAppReceiverMedia server stream service descriptor instance */
export const userGetAppReceiverMedia: IServiceDescriptor = {
    methodName: "userGetAppReceiverMedia",
    requestType: "AppMsg",
    responseType: "MediaFileMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userGetDeviceReceiverMediaCount server stream service descriptor instance */
export const userGetDeviceReceiverMediaCount: IServiceDescriptor = {
    methodName: "userGetDeviceReceiverMediaCount",
    requestType: "DeviceMsg",
    responseType: "AppMsg",
    serviceType: "",
    responseStream: true,
    requestStream: false
};

/** Generated userSetCurrentUserProfileMediaStream client stream service descriptor instance */
export const userSetCurrentUserProfileMediaStream: IServiceDescriptor = {
    methodName: "userSetCurrentUserProfileMediaStream",
    requestType: "ProfileMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: true
};

/** Generated userSetCurrentUserNotificationsRead client stream service descriptor instance */
export const userSetCurrentUserNotificationsRead: IServiceDescriptor = {
    methodName: "userSetCurrentUserNotificationsRead",
    requestType: "NotificationMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: true
};

/** Generated userMergeContacts client stream service descriptor instance */
export const userMergeContacts: IServiceDescriptor = {
    methodName: "userMergeContacts",
    requestType: "AccountMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: true
};

/** Generated userAddReportTemplateFiles client stream service descriptor instance */
export const userAddReportTemplateFiles: IServiceDescriptor = {
    methodName: "userAddReportTemplateFiles",
    requestType: "MediaFileMsg",
    responseType: "ReportTemplateMsg",
    serviceType: "",
    responseStream: false,
    requestStream: true
};

/** Generated userAddReportTemplateFile client stream service descriptor instance */
export const userAddReportTemplateFile: IServiceDescriptor = {
    methodName: "userAddReportTemplateFile",
    requestType: "MediaFileMsg",
    responseType: "ReportTemplateMsg",
    serviceType: "",
    responseStream: false,
    requestStream: true
};

/** Generated userAddAnnotationAttachment client stream service descriptor instance */
export const userAddAnnotationAttachment: IServiceDescriptor = {
    methodName: "userAddAnnotationAttachment",
    requestType: "MediaFileMsg",
    responseType: "AttachmentMsg",
    serviceType: "",
    responseStream: false,
    requestStream: true
};

/** Generated userAddAPK client stream service descriptor instance */
export const userAddAPK: IServiceDescriptor = {
    methodName: "userAddAPK",
    requestType: "MediaMsg",
    responseType: "APKMsg",
    serviceType: "",
    responseStream: false,
    requestStream: true
};

/** Generated userCancelReport service descriptor instance */
export const userCancelReport: IServiceDescriptor = {
    methodName: "userCancelReport",
    requestType: "ReportMsg",
    responseType: "VoidMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetReportPreviewFileId service descriptor instance */
export const userGetReportPreviewFileId: IServiceDescriptor = {
    methodName: "userGetReportPreviewFileId",
    requestType: "ProfileMsg",
    responseType: "MediaMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

/** Generated userGetReportTemplatePreviewFileId service descriptor instance */
export const userGetReportTemplatePreviewFileId: IServiceDescriptor = {
    methodName: "userGetReportTemplatePreviewFileId",
    requestType: "ReportTemplateMsg",
    responseType: "MediaMsg",
    serviceType: "",
    responseStream: false,
    requestStream: false
};

