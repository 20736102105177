
<div *ngIf="isFirst" class="cat-block cat-margin-top-sm"></div>

<div *ngIf="!isNewDay" class="cat-item cat-no-padding cat-no-background">
    <div class="cat-grid cat-no-padding">

        <div class="cat-row cat-margin-x-sm-y-xs" [ngClass]="{
            'cat-cursor-pointer': mode === msgMode.Report,
            'cat-background-selected': (((reportStatus$|async)?.conversations | getSome : 'id' : chatId)?.excludedmessages | isMsgSelected : msg),
            'cat-highlighted': isHighlighted
        }">
            <ng-container
                *ngIf="(msg.type !== msgType.MESSAGE_MESSAGE) &&
                    (msg.type !== msgType.MESSAGE_STORY_SHARE) &&
                    (msg.type !== msgType.MESSAGE_POST_SHARE) &&
                    (msg.type !== msgType.MESSAGE_POLL)"
                [class.cat-padding-x-lg]="mode !== msgMode.MsgLink"
                class="cat-full-width" [class.cat-highlighted]="isHighlighted">

                <div color="primary" class="cat-chip cat-wrap cat-padding-sm cat-center">

                    <div class="cat-flex-row cat-justify-end cat-align-center cat-wrap">

                        <div class="cat-padding-sm">
                            <span *ngIf="msg.topic" class="cat-start">
                                {{ msg.topic.name }}<span *ngIf="msg.topic.state == topicState.TOPICSTATE_LOCKED"> 🔒</span>:
                            </span>
                            {{ msg | getMsgServiceBody | translate | replaceTerm }}
                        </div>

                        <div class="cat-chip">

                            <mat-icon
                                *ngIf="msg.body?.length" (click)="showOriginalServiceMessage(msg); $event.stopPropagation();"
                                class="cat-margin-left-xl cat-cursor-pointer"
                                color="white">
                                info
                            </mat-icon>

                            <div
                                [matTooltip]="(msg.received | date: 'd MMMM y, HH:mm:ss' : undefined : tl.locale)"
                                matTooltipPosition="above"
                                matTooltipShowDelay="400"
                                class="cat-padding-sm cat-underline">
                                {{ msg.received | date: 'HH:mm:ss' }}
                            </div>
                            <div
                                *ngIf="showMenu"
                                (click)="$event.stopPropagation()"
                                [matMenuTriggerFor]="msgMenu"
                                class="cat-chip cat-cursor-pointer cat-no-margin cat-text-xs" [class.cat-outgoing]="!(msg.direction | isIncoming)">
                                <mat-icon
                                    color="white">
                                    more_vert
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

            <div
                *ngIf="(msg.type === msgType.MESSAGE_MESSAGE) ||
                    (msg.type === msgType.MESSAGE_STORY_SHARE) ||
                    (msg.type === msgType.MESSAGE_POST_SHARE) ||
                    (msg.type === msgType.MESSAGE_POLL)"
                [ngClass]="{
                    'cat-highlighted': isHighlighted,
                    'cat-float-start': (msg.direction | isIncoming),
                    'cat-float-end': !(msg.direction | isIncoming),
                    'cat-outgoing': !(msg.direction | isIncoming),
                    'cat-message-tail': (msg.type === msgType.MESSAGE_MESSAGE)
                }"
                class="cat-msg-container">

                <header *ngIf="msg.topic || ((msg.direction | isIncoming) && msg.sender) || msg.subject" class="cat-nooverflow-ellipsis cat-padding-md">

                    <div *ngIf="(msg.topic?.avatar | hasThumb) else animatedAvatar" class="cat-avatar cat-xs cat-float-start cat-margin-right-sm cat-background-white">
                        <img [src]="msg.topic.avatar.files[0].thumbfileid | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
                    </div>
                    <div *ngIf="msg.topic" color="warning-shade" class="cat-margin-bottom-md">
                        {{ msg.topic.name }}
                        <span *ngIf="msg.topic.state == topicState.TOPICSTATE_LOCKED">🔒</span>
                    </div>

                    <div
                        *ngIf="(msg.direction | isIncoming) && msg.sender"
                        (click)="openContact(msg.sender); $event.stopPropagation();"
                        [class.cat-cursor-pointer]="(permissionEnum.PERMISSION_VIEW_PROFILE_CONTACTS | hasPermission)"
                        class="cat-flex-row cat-align-center">

                        <div class="cat-avatar cat-sm cat-float-start cat-margin-right-sm cat-background-white">
                            <img *ngIf="(msg.sender.avatar | hasThumb) else noAvatar" [src]="msg.sender.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
                        </div>

                        <div class="cat-margin-left-sm">
                            <span color="dark" class="cat-sender">
                                <b>{{ msg.sender | accountHandle }}</b>
                            </span>
                            <span *ngIf="msg.type === msgType.MESSAGE_STORY_SHARE" color="medium">
                                ({{ uitext.storyshare | translate }})
                            </span>
                            <span *ngIf="msg.type === msgType.MESSAGE_POST_SHARE" color="medium">
                                ({{ uitext.postshare | translate }})
                            </span>
                            <span *ngIf="msg.sender?.alias" matTooltip="{{ 'Alias' | translate }}" matTooltipPosition="right" >
                                🅰️
                            </span>
                        </div>
                    </div>

                    <div *ngIf="msg.subject" class="cat-flex-row cat-align-center">
                        <b>{{ msg.subject }}</b>
                    </div>

                </header>

                <section
                    class="cat-padding-x-md"
                    [class.cat-float-start]="!msg?.attachments?.length && !msg.owner && !msg.replyto"
                    [class.cat-padding-top-md]="!msg.topic && (((msg.direction | isIncoming) && !msg.sender) || !(msg.direction | isIncoming))">

                    <span [class.cat-story]="msg.owner">

                        <div *ngIf="msg.owner" (click)="openContact(msg.owner); $event.stopPropagation();" class="cat-margin-bottom-sm" [class.cat-cursor-pointer]="(permissionEnum.PERMISSION_VIEW_PROFILE_CONTACTS | hasPermission)">
                            <div class="cat-avatar cat-xs cat-float-start cat-margin-right-sm cat-background-white">
                                <img *ngIf="(msg.owner.avatar | hasThumb) else noAvatar" [src]="msg.owner.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
                            </div>
                            <div color="dark" class="cat-nowrap cat-text-bold cat-text-sm cat-padding-y-xs">
                                <b>{{ msg.owner | accountHandle }}</b>
                            </div>
                        </div>

                        <div
                            *ngIf="msg.replyto && (msg.replyto.id != selectedMsg?.channel?.firstmessageid)"
                            (click)="jumpToMsg(msg.replyto)"
                            [class.cat-cursor-pointer]="mode !== msgMode.MsgLink" [class.cat-outgoing]="!(msg.direction | isIncoming)"
                            class="cat-reply-to-msg cat-padding-md cat-margin-bottom-md">

                            <div
                                *ngIf="msg.replyto.sender"
                                (click)="openContact(msg.replyto.sender); $event.stopPropagation();"
                                [class.cat-cursor-pointer]="(permissionEnum.PERMISSION_VIEW_PROFILE_CONTACTS | hasPermission)"
                                class="cat-margin-bottom-sm">
                                <div class="cat-avatar cat-xs cat-float-start cat-margin-right-sm cat-background-white">
                                    <img *ngIf="(msg.replyto.sender.avatar | hasThumb) else noAvatar" [src]="msg.replyto.sender.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
                                </div>
                                <span color="primary" class="cat-nowrap cat-no-padding cat-text-sm cat-block">
                                    <b>{{ msg.replyto.sender | accountHandle }}</b>
                                </span>
                            </div>

                            <ng-container *ngIf="msg.replyto.attachments?.length">
                                <ng-container *ngFor="let attachment of msg.replyto.attachments let last = last trackBy: common.trackById">
                                    <app-msg-attachment
                                        class="cat-block"
                                        [ngClass]="{ 'cat-padding-bottom-md' : (!last && msg.replyto.attachments?.length > 1) }"
                                        (emitGetMedia)="userGetMedia(attachment, msg.replyto)"
                                        [attachment]="attachment"
                                        [account]="account"
                                        [msg]="msg">
                                    </app-msg-attachment>
                                </ng-container>
                            </ng-container>

                            @if ((msg.replyto.type !== msgType.MESSAGE_MESSAGE) &&
                            (msg.replyto.type !== msgType.MESSAGE_STORY_SHARE) &&
                            (msg.replyto.type !== msgType.MESSAGE_POST_SHARE) &&
                            (msg.replyto.type !== msgType.MESSAGE_POLL)) {
                                <div color="primary" class="cat-chip cat-wrap cat-padding-sm cat-center">
                                    {{ msg.replyto | getMsgServiceBody | translate | replaceTerm }}
                                </div>
                            } @else if (msg.replyto.body || msg.replyto.content?.length) {
                                <div class="cat-justify-end" [ngClass]="{ 'cat-end': !(msg.direction | isIncoming), 'cat-padding-top-md' : msg.replyto.attachments?.length }">
                                    <ng-template
                                        [ngTemplateOutlet]="body"
                                        [ngTemplateOutletContext]="{ msg: msg.replyto }">
                                    </ng-template>
                                </div>
                            }
                        </div>

                        <ng-container *ngFor="let attachment of msg?.attachments let last = last trackBy: common.trackById">
                            <app-msg-attachment
                                class="cat-block"
                                [ngClass]="{ 'cat-padding-bottom-md' : (!last && msg.attachments?.length > 1) }"
                                (emitGetMedia)="userGetMedia(attachment, msg)"
                                [attachment]="attachment"
                                [account]="account"
                                [msg]="msg">
                            </app-msg-attachment>
                        </ng-container>

                        <div *ngIf="(msg.body || msg.content?.length)" color="dark" class="cat-wrap cat-break-word" [ngClass]="{ 'cat-padding-top-md' : msg.attachments?.length }">
                            <b *ngIf="(msg.type === msgType.MESSAGE_POLL) else noPoll">
                                <ng-template
                                    [ngTemplateOutlet]="body"
                                    [ngTemplateOutletContext]="{ msg: msg }">
                                </ng-template>
                            </b>
                            <ng-template #noPoll>
                                <ng-template
                                    [ngTemplateOutlet]="body"
                                    [ngTemplateOutletContext]="{ msg: msg }">
                                </ng-template>
                            </ng-template>
                        </div>

                    </span>

                    <span *ngIf="msg.type === msgType.MESSAGE_POLL" class="cat-block cat-opacity-4 cat-margin-top-sm">
                        {{ uitext.poll | translate }}
                    </span>

                    <div *ngIf="(msg.options | json) !== '{}'" class="cat-grid cat-full-width cat-margin-y-lg">

                        <ng-container *ngFor="let option of msg.options | keyvalue let last = last trackBy: common.trackByKey">

                            <ng-container *ngIf="!option.key.startsWith('count') && !option.key.startsWith('button')">
                                <div class="cat-full-width cat-padding-y-lg">
                                    <div class="cat-margin-bottom-md">
                                        {{ option.key }}
                                        <span class="cat-opacity-4">
                                            - {{ option.value }} {{ uitext.votes | translate }}
                                        </span>
                                    </div>
                                    <div>
                                        <mat-progress-bar
                                            [value]="option.value > 0 ? 100 : 0">
                                        </mat-progress-bar>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="option.key.startsWith('count')">
                                <div class="cat-margin-y-xl">
                                    <b>{{ uitext.total | translate }}:<br></b>
                                    <span *ngIf="(option.value === '0') || (option.value === 'undefined')" class="cat-wrap cat-break-word">
                                        {{ uitext.novotes | translate }}
                                    </span>
                                    <span *ngIf="(option.value !== '0') && (option.value !== 'undefined')" class="cat-wrap cat-break-word">
                                        {{ option.value }} {{ uitext.votes | translate }}
                                    </span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="option.key.startsWith('button')">
                                <div class="cat-background-light-shade cat-margin-top-sm cat-padding-md">
                                    {{ option.value }}
                                </div>
                            </ng-container>

                        </ng-container>
                    </div>
                </section>

                <footer class="cat-padding-md" [class.cat-float-end]="(!hideComments && (msg.channel?.totalmessagescount < 1))">

                    <div class="cat-flex-row cat-justify-end cat-align-center cat-wrap" [class.cat-margin-bottom-xs]="!hideComments && (msg.channel?.totalmessagescount >= 1)">

                        <div *ngIf="msg.reactions?.length" class="cat-start cat-padding-y-sm">
                            <ng-container *ngFor="let reaction of msg.reactions let last = last trackBy: common.trackById">
                                <span
                                    (click)="openReaction($event, reaction); $event.stopPropagation()"
                                    class="cat-chip cat-inline-block cat-margin-left-md cat-opacity-1 cat-margin-y-sm cat-cursor-pointer" [class.cat-margin-right-sm]="last" color="transparent">
                                    <ng-container *ngIf="reaction.customemoji?.files?.length; else noCustomEmoji">
                                        <mat-icon>
                                            <img
                                                *ngIf="(reaction.customemoji | hasThumb) else noCustomEmoji"
                                                [src]="reaction.customemoji.files[0] | catsrc : 'thumb' : mediaType.MEDIA_EMOJIS : account.id">
                                        </mat-icon>
                                    </ng-container>
                                    <ng-template #noCustomEmoji>
                                        <span color="transparent">
                                            {{ reaction.emoji }}
                                        </span>
                                    </ng-template>
                                    <span [attr.color]="'dark'">{{ reaction.count }}</span>
                                </span>
                            </ng-container>
                        </div>

                        <div class="cat-chip cat-justify-end cat-align-center cat-wrap" color="transparent">
                            <div
                                *ngIf="msg.forwarded"
                                color="primary"
                                class="cat-nowrap cat-padding-y-sm cat-margin-left-md cat-wrap">
                                {{ uitext.forwarded | translate }}
                                <span *ngIf="msg.forward?.id?.length" color="primary">
                                    {{ uitext.from | translate }} {{ msg.forward | accountHandle }}
                                </span>
                                <span *ngIf="msg.forward?.conversations?.length" color="primary">
                                    {{ uitext.from | translate }} {{ (msg | getForwardConversationName) }}
                                </span>
                            </div>

                            <div
                                *ngIf="msg.lastedited"
                                color="warning-shade"
                                class="cat-padding-y-sm cat-nowrap cat-margin-left-md">
                                {{ uitext.edited | translate }} {{ msg.lastedited | date: 'HH:mm:ss' : undefined : tl.locale }}
                            </div>

                            <div
                                *ngIf="msg.expires"
                                color="danger"
                                class="cat-nowrap cat-margin-left-md">
                                {{ uitext.disappearing | translate }}
                            </div>

                            <div
                                *ngIf="msg.replyto && (msg.replyto.id != selectedMsg?.channel?.firstmessageid)"
                                color="success"
                                class="cat-padding-y-sm cat-nowrap cat-margin-left-md">
                                {{ uitext.reply | translate }}
                            </div>

                            <div
                                *ngIf="msg.deleted"
                                color="danger"
                                class="cat-padding-y-sm cat-nowrap cat-margin-left-md">
                                {{ uitext.deleted | translate }}
                            </div>

                            <div
                                #tooltip="matTooltip"
                                [matTooltip]="(msg.received | date: 'd MMMM y, HH:mm:ss' : undefined : tl.locale)"
                                matTooltipPosition="above"
                                matTooltipShowDelay="400"
                                color="dark"
                                class="cat-padding-sm cat-underline">
                                {{ msg.received | date: 'HH:mm:ss' }}
                            </div>

                            <div
                                *ngIf="showMenu"
                                (click)="$event.stopPropagation()"
                                [matMenuTriggerFor]="msgMenu"
                                color="transparent"
                                class="cat-chip cat-cursor-pointer cat-no-margin" [class.cat-outgoing]="!(msg.direction | isIncoming)">
                                <mat-icon
                                    [attr.color]="(msg.direction | isIncoming) ? 'dark' : 'primary'">
                                    more_vert
                                </mat-icon>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="!hideComments && (msg.channel?.totalmessagescount > 1)" class="cat-flex-row cat-justify-end cat-comments cat-padding-top-sm" [class.cat-outgoing]="!(msg.direction | isIncoming)">

                        <div (click)="openComments(); $event.stopPropagation();" class="cat-chip cat-full-width cat-justify-end" [class.cat-cursor-pointer]="mode !== msgMode.Report" color="transparent">

                            <div class="cat-padding-sm" color="primary">
                                <b>
                                    <span *ngIf="mode !== msgMode.Report" color="primary">{{ uitext.view | translate }}</span>
                                    {{ msg.channel.totalmessagescount - 1 }}
                                    <span *ngIf="msg.channel?.totalmessagescount === 1" color="primary">{{ uitext.comment | translate }}</span>
                                    <span *ngIf="msg.channel?.totalmessagescount > 1" color="primary">{{ uitext.comments | translate }}</span>
                                </b>
                            </div>

                            <div
                                *ngIf="mode !== msgMode.Report"
                                color="transparent"
                                class="cat-chip cat-cursor-pointer cat-no-margin cat-float-end" [class.cat-outgoing]="!(msg.direction | isIncoming)">
                                <mat-icon color="primary">
                                    navigate_next
                                </mat-icon>
                            </div>
                        </div>
                    </div>

                </footer>
            </div>
        </div>

        <div *ngIf="msg.labels?.length && ((msg.type === msgType.MESSAGE_MESSAGE) ||
        (msg.type === msgType.MESSAGE_STORY_SHARE) ||
        (msg.type === msgType.MESSAGE_POST_SHARE) ||
        (msg.type === msgType.MESSAGE_POLL))" class="cat-row cat-margin-x-sm cat-msg-additions">
            <div
                [ngClass]="{
                    'cat-float-start': (msg.direction | isIncoming),
                    'cat-float-end': !(msg.direction | isIncoming),
                    'cat-outgoing': !(msg.direction | isIncoming)
                }">
                <mat-icon
                    color="success"
                    class="cat-label-shortcut cat-cursor-pointer"
                    (click)="openMessageInfo(msg, messageAction.Label)">
                    bookmark
                </mat-icon>
            </div>
        </div>

        <div
            *ngIf="mode === msgMode.Report"
            class="cat-message-overlay cat-cursor-pointer"
            [class.cat-active]="(((reportStatus$|async)?.conversations | getSome : 'id' : chatId)?.excludedmessages | isMsgSelected : msg)"
            (click)="selectMessage(msg)"
            #tooltip="matTooltip"
            matTooltip="{{
                (((reportStatus$|async)?.conversations | getSome : 'id' : chatId)?.excludedmessages | isMsgSelected : msg)
                ? ('Click to include' | translate)
                : ('Click to exclude' | translate)
            }}"
            matTooltipPosition="right"
            matTooltipShowDelay="500"
            matTooltipHideDelay="50">
        </div>

    </div>
</div>

<div *ngIf="isNewDay" class="cat-stick-date cat-center">
    <button (click)="openJump($event)" class="cat-day-divider cat-padding-x-sm cat-nooverflow-ellipsis" [class.cat-cursor-text]="!showJumpDate">
        <span class="cat-margin-left-sm">{{ msg.received | date: 'd MMMM y' : undefined : tl.locale }}</span>
        <mat-icon *ngIf="showJumpDate" class="cat-push-right">expand_more</mat-icon>
    </button>
</div>


<div *ngIf="isLast" class="cat-block cat-margin-bottom-lg"></div>

<ng-template #body let-msg="msg">
    <span
        [ngClass]="(msg.direction | isIncoming) ? 'cat-text incoming' : 'cat-text outgoing' "
        color="dark"
        class="cat-preline cat-cursor-text cat-body-text" dir="auto">
        <ng-container *ngIf="msg.content?.length else noContent">
            <ng-container *ngFor="let content of msg.content">
                <ng-container *ngIf="content.type === contentTypeEnum.CONTENTTYPE_TEXT">
                    {{ content.value }}
                </ng-container>
                <ng-container *ngIf="content.type === contentTypeEnum.CONTENTTYPE_CUSTOMEMOJI">
                    <ng-container *ngFor="let emoji of msg.emojis trackBy: common.trackById">
                        <ng-container *ngIf="hasExternid(content.value, emoji)">
                            <mat-icon *ngIf="(emoji | hasThumb); else noEmoji">
                                <img [src]="emoji.files[0] | catsrc : 'thumb' : mediaType.MEDIA_EMOJIS : account.id">
                            </mat-icon>
                            <ng-template #noEmoji>
                                <span color="warn" matTooltipPosition="above" matTooltip="{{ 'Emoji not downloaded (yet)' | translate }}">
                                    {{ getEmojiname(content.value) }}
                                </span>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-template #noContent>
            {{ msg.body }}
        </ng-template>
    </span>
</ng-template>

<ng-template #animatedAvatar>
    <div *ngIf="msg.topic?.avatar?.type === attachmentType.ATTACHMENT_TGS" class="cat-avatar cat-xs cat-float-start cat-margin-right-sm cat-background-white">
        <ng-container *ngIf="(msg.topic.avatar | hasFile)">
            <lottie-container
                [fullwidth]="true"
                [file]="msg.topic.avatar.files[0]"
                [mediatype]="mediaType.MEDIA_ACCOUNTAVATARS"
                [refId]="account.id">
            </lottie-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #noAvatar>
    <img src="assets/img/cat-avatar.svg" matTooltipPosition="above" matTooltip="{{ uitext.noavatarfound | translate }}">
</ng-template>

<mat-menu #msgMenu="matMenu">
    <app-msg-menu-popover [msg]="msg" (emitOpenDetails)="openMessageInfo(msg, $event)"></app-msg-menu-popover>
</mat-menu>


