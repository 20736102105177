<div class="cat-button-row cat-margin-left-xl">

    <div class="cat-button-row-item cat-shrink cat-xs">
        <button
            (click)="onFilterChange()"
            matTooltip="{{ uitext.refresh | translate }}"
            matTooltipPosition="above"
            mat-mini-fab color="light-shade" class="cat-no-shadow">
            <mat-icon color="primary">refresh</mat-icon>
        </button>
    </div>

    <div class="cat-button-row-item cat-shrink">
        <mat-form-field appearance="fill" class="cat-dense">
            <mat-label>{{ "Type filter" | translate }}</mat-label>
            <mat-select multiple [(ngModel)]="selectedMimeTypes" (selectionChange)="onFilterChange()">
                <mat-option *ngFor="let mimetype of mimeTypes" [value]="mimetype.value">
                    {{ mimetype.label | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    @if (includeLinkStatus) {
        <div class="cat-button-row-item cat-shrink">
            <mat-form-field appearance="fill" class="cat-dense">
                <mat-label>{{ "Link status filter" | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedLinkStatus" (selectionChange)="onFilterChange()">
                    <mat-option value="all">
                        {{ "Linked and not linked" | translate }}
                    </mat-option>
                    <mat-option value="linked">
                        {{ "Linked" | translate }}
                    </mat-option>
                    <mat-option value="unlinked">
                        {{ "Not linked" | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    }

    @if (includeLinkType) {
        <div class="cat-button-row-item cat-shrink">
            <mat-form-field appearance="fill" class="cat-dense">
                <mat-label>{{ "Link type filter" | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedLinkType" (selectionChange)="onFilterChange()">
                    <mat-option *ngFor="let linktype of linkTypes" [value]="linktype.value">
                        {{ linktype.label | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    }

    <div class="cat-button-row-item cat-shrink">
        <mat-form-field appearance="fill" class="cat-dense">
            <mat-label>{{ "Order" | translate }}</mat-label>
            <mat-select [(value)]="selectedSort" (selectionChange)="onFilterChange()">
                <mat-option *ngFor="let sort of sortFields" [value]="sort">
                    {{ sort.text | translate  }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="cat-button-row-item cat-shrink cat-cursor-pointer" (click)="openHelp()">
        <mat-icon color="primary">help</mat-icon>
    </div>

    @if (countpath?.methodName) {
        <div class="cat-button-row-item cat-push-right cat-text-sm cat-padding-lg cat-text-sm">
            {{ (count$|async)?.count }} items
        </div>
    }

</div>