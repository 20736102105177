import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IReactionsData, IState } from "@shared/app-models";
import { LoggerService } from "@services/logger/logger.service";
import { CommonService } from "@services/common/common.service";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { cat } from "@assets/proto/msgs";
import { userGetMessageReactions } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { WsService } from "@services/ws/ws.service";
import { getList } from "@store/store";

@Component({
  selector: "reaction-dialog",
  templateUrl: "./reaction-dialog.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactionDialog implements OnInit {

    loading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetMessageReactions.isLoading);
    reactions$: Observable<cat.ReactionMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetMessageReactions));

    public uitext = protosui.messages.uitext;

    constructor(
        public dialogRef: MatDialogRef<ReactionDialog>,
        public common: CommonService,
        private _cdr: ChangeDetectorRef,
        private _ws: WsService,
        private _logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) public data: IReactionsData
        ) {}

    ngOnInit() {
        this._logger.debug("Data", this.data);
        if (!this.data.reaction || !this.data.message?.id) {
            this.common.createSnackbar(protosui.messages.uitext.prerequisites);
            throw new Error(protosui.messages.uitext.prerequisites);
        }

        // Get the full reactions.
        this.userGetMessageReactions();
    }

    private async userGetMessageReactions() {
        try {
            // const query = cat.QueryMsg.create({
            //     filters: [
            //         cat.FilterMsg.create( {
            //             collection: "reactions",
            //             field: "emoji",
            //             value: this.data.reaction.emoji
            //         })
            //     ]
            // })

            const payload = cat.MessageMsg.create({ id: this.data.message.id });

            await this._ws.sendRequest(userGetMessageReactions, payload);

            this.common.detectChange(this._cdr);
        } catch (error) {
            this._logger.error(error);
        }
    }
}