import { Store } from "@ngxs/store";
import { Component, OnInit, ChangeDetectionStrategy, inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { userGetUserSessions } from "@assets/proto/services";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";
import { WsService } from "@services/ws/ws.service";
import { clearArray } from "@store/actions";
import { Observable } from "rxjs";
import { IState } from "@shared/app-models";
import { protosui } from "@definitions/definitions";

@Component({
    selector: "widget-user-sessions",
    templateUrl: "./widget-user-sessions.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetUserSessionsComponent implements OnInit {

    public permissionEnum = cat.Permission;
    public permissionDef = protosui.def.Permission;

    sessions$: Observable<cat.UserSessionMsg[]> = inject(Store).select((state: IState) => state.cat.UserSessions);

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _logger: LoggerService,
        private _ws: WsService,
        private _store: Store
    ) {
    }

    /**
     * Initialize, get user sessions.
     *
     * @memberof WidgetUserSessionsComponent
     */
    ngOnInit() {
        // Fetch user sessions.
        this.getUserSessions();
    }

    /**
     * Get the user sessions.
     *
     * @memberof WidgetUserSessionsComponent
     */
    async getUserSessions() {
        try {
            this._store.dispatch(new clearArray("UserSessions"));
            await this._ws.sendRequest(userGetUserSessions);
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }
}
