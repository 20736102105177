import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, Input, OnDestroy, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit, inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { ContactAction, IAccountDetailData, IContactDetailData, IDialogData, IState } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { Observable, debounceTime, distinctUntilChanged, fromEvent, merge, tap } from "rxjs";
import { clearList } from "@store/actions";
import { userGetConversationMemberCount, userGetConversationMembers } from "@assets/proto/services";
import { WsService } from "@services/ws/ws.service";
import { getList } from "@store/store";
import { QueryService } from "@services/query/query.service";
import { MatDialog } from "@angular/material/dialog";
import { ContactDetailsDialog } from "@modals/detail-pages/contact-details/contact-details.dialog";
import { SubscriptionManager } from "@shared/subscription-manager";
import { AccountDetailsDialog } from "@modals/detail-pages/account-details/account-details.dialog";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { GenericDialog } from "@components/dialog-generic/generic-dialog.component";

@Component({
    selector: "app-conversation-members",
    templateUrl: "./conversationmembers.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationMemberComponent extends SubscriptionManager implements AfterViewInit, OnDestroy, OnChanges {

    @ViewChild(MatSort) sort: MatSort = new MatSort();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('search') search: ElementRef;

    isLoading$: Observable<boolean> = inject(Store).select((state: IState) => state.cat.userGetConversationMembers.isLoading);
    apptypes$: Observable<Map<string, cat.AppTypeMsg>> = inject(Store).select((state: IState) => state.cat.userGetAppTypes.list);
    memberCount$: Observable<number> = inject(Store).select((state: IState) => state.cat.userGetConversationMemberCount.msg.count);
    members$: Observable<cat.AccountMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetConversationMembers));

    @Input() conversation: cat.ConversationMsg = cat.ConversationMsg.create();
    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    @Input() profile: cat.ProfileMsg = cat.ProfileMsg.create();
    @Input() apptype: cat.AppTypeMsg = cat.AppTypeMsg.create();

    // Properties
    public mediaTypeEnum = cat.MediaType;
    public displayedColumns: string[] = ["name", "status", "username", "externid"];
    public statusType = cat.MemberStatus;
    public statusTypeDef = protosui.def.MemberStatus;
    public uitext = protosui.messages.uitext;
    public pageSizeOptions = [10,50,100];
    public pageSize = 10;

    private _selectedSort = "username";

    constructor(
        public common: CommonService,
        private _queryService: QueryService,
        private _logger: LoggerService,
        private _dialog: MatDialog,
        private _ws: WsService,
        private _store: Store
    ) {
        super();
    }

    async ngAfterViewInit() {
        this._logger.debug("On Init: ConversationMemberComponent");
        // Clear both the infinite and request list.
        this.clearStore();
        // Subscribe to new messages, to update the view
        this.addSubscription(
            this._queryService.filterCalls
                .get(userGetConversationMembers.methodName)
                .subscribe(async (refetch: boolean) => {
                    await this.refreshMembers();
            })
        )

        // Search the datastore
        this.addSubscription(
            fromEvent(this?.search?.nativeElement,'keyup')
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(async () => {
                    this._logger.debug("Search members", this.search.nativeElement.value);
                    this.paginator.pageIndex = 0;
                    await this.refreshMembers();
                })
            )
            .subscribe());

        // Reset the paginator after sorting.
        this.addSubscription(this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0));

        this.addSubscription(
            merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(async () => {
                    this._logger.debug("Sort / pagination changed");
                    this._logger.debug(this.sort);
                    this._logger.debug(`Paginator index: ${this.paginator.pageIndex}`);
                    this._logger.debug(`Paginator size: ${this.paginator.pageSize}`);
                    this._logger.debug(`Sort field: ${this.sort.active}`);
                    this._logger.debug(`Sort order: ${this.sort.direction}`);
                    this._selectedSort = this.sort.active;
                    await this.refreshMembers();
                })
            ).subscribe());
    }

    /**
     * Detect when the conversation input is set.
     *
     * @param {SimpleChanges} changes
     * @memberof ConversationMemberComponent
     */
    async ngOnChanges(changes: SimpleChanges) {
        if ("conversation" in changes && changes?.conversation?.currentValue?.id) {
            await this.refreshMembers();
        }
    }

    ngOnDestroy(): void {
        this.clearStore();
        // Ensure all subscriptions are removed.
        this.unsubscribeAll();
    }

    public async clearSearchvalue() {
        this.search.nativeElement.value = "";
        await this.refreshMembers();
    }

    clearStore() {
        // Clear both the infinite and request list
        this._store.dispatch(new clearList(userGetConversationMembers.methodName));
    }

    public async refreshMembers() {
        // Fetch the members.
        await this.userGetConversationMembers();
        await this.userGetMemberCount();
    }

    /**
    * Get the conversation members.
    */
    async userGetConversationMembers() {
        try {

            if (!this.conversation?.id) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            // Payload with the required conversation id
            const payload: cat.ConversationMsg = cat.ConversationMsg.create({
                id: this.conversation?.id
            });

            const query = this.getFilterQuery();

            await this._ws.sendRequest(userGetConversationMembers, payload, undefined, undefined, query);

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Create a query message from all the filter values.
     * TODO: create a common function for getFilterQuery.
     *
     * @private
     * @returns {cat.QueryMsg}
     * @memberof ManageUsersPage
     */
    private getFilterQuery(): cat.QueryMsg {
        try {
            // Add a sort direction.
            const sortDirection = this.sort.direction === "asc"
                ? cat.SortDirection.SORT_ASCENDING
                : cat.SortDirection.SORT_DESCENDING;

            // Prevent [undefined] entries
            const sortfields = [];
            if (this._selectedSort) {
                sortfields.push(this._selectedSort);
            }

            // Construct basic query message.
            const query = cat.QueryMsg.create({
                count: this.paginator?.pageSize || this.pageSize,
                offset: this.paginator?.pageIndex || 0,
                sortdirection: sortDirection,
                filters: [],
                sortfields: sortfields
            });

            query.filters.push(
                cat.FilterMsg.create({
                    field: "account",
                    collection: "accounts",
                    operator: cat.FilterOperator.FILTER_INCLUDE
                })
            )

            query.references = {
                [cat.ReferenceType.REFERENCE_CONVERSATION_ID]: this.conversation.id
            }

            // Filter on search query.
            if (this.search?.nativeElement?.value) {
                query.search = this.search.nativeElement.value;
            }

            this._logger.debug("Query: ", query);
            return query;

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Get the total count, taking the filters into account.
     *
     * @private
     * @memberof ManageUsersPage
     */
    private async userGetMemberCount() {
        try {
            const query = this.getFilterQuery();
            await this._ws.sendRequest(userGetConversationMemberCount, query);
        } catch (error) {
            this._logger.error(error);
            await this.common.createSnackbar(error);
        }
    }

    public showInfo() {
        try {
            const data: IDialogData = {
                title: protosui.messages.uitext.info,
                content: protosui.messages.uitext.memberinfo
            };

            const dialogRef = this._dialog.open(GenericDialog, {
                data: data
            });
            dialogRef.afterClosed().subscribe(result => {
                this._logger.debug(`Dialog result: ${result}`);
            });
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Open the details of a contact / member.
     *
     * @param {cat.AccountMsg} member the member
     * @returns
     * @memberof ConversationMemberComponent
     */
    async openContact(contact: cat.AccountMsg, isContact = false) {
        try {

            this._logger.debug("Open contact", contact);

            if (!contact?.id || !this.profile || !this.profile.id) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            if (isContact) {
                const dialogData: IContactDetailData = {
                    accountId: this.account?.id,
                    contact: contact,
                    contactAction: ContactAction.View,
                    profileId: this.profile?.id
                };
                this._logger.debug("Dialog Data: ", dialogData);
                this._dialog.open(ContactDetailsDialog, {
                    width: "95vw",
                    height: "95vh",
                    data: dialogData
                });
            } else {
                const dialogData: IAccountDetailData = {
                    account: this.account,
                    profile: this.profile
                };
                this._logger.debug("Dialog Data: ", dialogData);
                this._dialog.open(AccountDetailsDialog, {
                    width: "95vw",
                    height: "95vh",
                    data: dialogData
                });
            }

        } catch (error) {
            this._logger.error(error);
        }
    }
}
