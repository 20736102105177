<div class="cat-grid">

    <ng-container *ngIf="currentLabels$|async as currentlabels">
        <ng-container *ngIf="globalLabels$|async as globalLabels">

            <div class="cat-row">
                <h4 class="cat-block cat-no-margin cat-no-padding">{{ uitext.globallabels | translate }}</h4>
            </div>

            <div class="cat-row cat-no-padding">

                <div class="cat-item cat-no-background cat-no-margin cat-no-padding" *ngIf="!globalLabels.length">
                    <div class="cat-label" color="medium-shade">
                        {{ uitext.notfound | translate }}
                    </div>
                </div>

                <mat-chip-listbox selectable multiple>
                    <mat-chip-option
                        *ngFor="let label of globalLabels trackBy: common.trackById"
                        [ngStyle]="{ 'background-color': '#'+label.color }"
                        [class.cat-outline]="!(label | hasid: currentlabels)"
                        (click)="toggleLabel(label)"
                        [selected]="(label | hasid: currentlabels)">
                        {{ label.name }}
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>

            <div *ngIf="(permissionsEnum.PERMISSION_MANAGE_GLOBAL_LABELS | hasPermission)" class="cat-row cat-margin-top-md">
                <mat-form-field color="white">
                    <mat-label>{{ uitext.add | translate }}</mat-label>
                    <input matInput
                        (keyup.enter)="addLabel('global')"
                        clearInput
                        [(ngModel)]="newGlobalLabel"
                        autocorrect="off"
                        autocomplete="off"
                        inputmode="text">
                </mat-form-field>
            </div>

        </ng-container>


        <ng-container *ngIf="caseLabels$|async as caseLabels">

            <div class="cat-row cat-margin-top-xxl">
                <h4 class="cat-block cat-no-margin cat-no-padding">{{ uitext.caselabels | translate | replaceTerm }}</h4>
            </div>

            <div class="cat-row cat-no-padding">

                <div class="cat-item cat-no-background cat-no-margin cat-no-padding" *ngIf="!caseLabels.length">
                    <div class="cat-label" color="medium-shade">
                        {{ uitext.notfound | translate }}
                    </div>
                </div>

                <mat-chip-listbox selectable multiple>
                    <mat-chip-option
                        *ngFor="let label of caseLabels trackBy: common.trackById"
                        [ngStyle]="{ 'background-color': '#'+label.color }"
                        [class.cat-outline]="!(label | hasid: currentlabels)"
                        (click)="toggleLabel(label)"
                        [selected]="(label | hasid: currentlabels)">
                        {{ label.name }}
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>

            <div *ngIf="(permissionsEnum.PERMISSION_MANAGE_CASE_LABELS | hasPermission)" class="cat-row cat-margin-top-md">
                <mat-form-field color="white">
                    <mat-label>{{ uitext.add | translate }}</mat-label>
                    <input matInput
                        (keyup.enter)="addLabel('case')"
                        clearInput
                        [(ngModel)]="newCaseLabel"
                        autocorrect="off"
                        autocomplete="off"
                        inputmode="text">
                </mat-form-field>
            </div>

        </ng-container>



        <ng-container *ngIf="profileLabels$|async as profilelabels">

            <div class="cat-row cat-margin-top-xxl">
                <h4 class="cat-block cat-no-margin cat-no-padding">{{ uitext.profilelabels | translate | replaceTerm }}</h4>
            </div>

            <div class="cat-row cat-no-padding">

                <div class="cat-item cat-no-background cat-no-margin cat-no-padding" *ngIf="!profilelabels.length">
                    <div class="cat-label" color="medium-shade">
                        {{ uitext.notfound | translate }}
                    </div>
                </div>

                <mat-chip-listbox selectable multiple>
                    <mat-chip-option
                        *ngFor="let label of profilelabels trackBy: common.trackById"
                        [ngStyle]="{ 'background-color': '#'+label.color }"
                        [class.cat-outline]="!(label | hasid: currentlabels)"
                        (click)="toggleLabel(label)"
                        [selected]="(label | hasid: currentlabels)">
                        {{ label.name }}
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>

            <div *ngIf="([permissionsEnum.PERMISSION_MANAGE_PROFILE_LABELS] | hasPermission)" class="cat-row cat-margin-top-md">
                <mat-form-field color="white">
                    <mat-label>{{ uitext.add | translate }}</mat-label>
                    <input matInput
                        (keyup.enter)="addLabel('profile')"
                        clearInput
                        [(ngModel)]="newProfileLabel"
                        autocorrect="off"
                        autocomplete="off"
                        inputmode="text">
                </mat-form-field>
            </div>

        </ng-container>
    </ng-container>
</div>