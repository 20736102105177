import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { LoggerService } from "@services/logger/logger.service";


/**
 * Human readable enums.
 */
@Pipe({
  name: "humanizeEnum",
  pure: true
})
export class HumanizeEnumPipe implements PipeTransform {

    constructor(private _logger: LoggerService) {}

    transform(enumName: string): { value: number, label: string, numberKey: string }[] {
        let result = [];
        if (enumName && cat[enumName]) {
            result = Object.keys(cat[enumName])
                .filter(key => !key.endsWith("UNKNOWN"))
                .map(key => ({ value: key, label: protosui.def?.[enumName]?.[key]?.label || key, numberKey: cat[enumName]?.[key] }));
        }
        this._logger.debug("[HumanizeEnumPipe] Result", result);
        return result;
    }
}

