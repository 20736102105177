// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

/** The app route names */
export const appRouteNames = {

    // Basic routes.
    DISCONNECTED: "disconnected",
    PREREQUISITES: "prerequisites",
    LOGIN: "login",
    USER_SETTINGS: "usersettings",
    DASHBOARD: "dashboard",
    LOGOUT: "logout",

    // Case related.
    ADD_CASE: "addcase",
    MANAGE_CASES: "cases",
    CASE_DETAILS: "case-details",
    USER_CASE_DETAILS: "usercase-details",
    USER_CASES: "usercases",

    // Domain related
    ADD_DOMAIN: "adddomain",
    MANAGE_DOMAINS: "domains",
    DOMAIN_DETAILS: "domain-details",

    // Theme related.
    ADD_THEME: "addtheme",
    MANAGE_THEMES: "themes",
    THEME_DETAILS: "theme-details",

    // Profile related.
    USER_PROFILES: "userprofiles",
    USER_PROFILE_DETAILS: "userprofiledetails",
    PROFILE_CONTACTS: "contacts",
    MANAGE_PROFILELABELS: "profile-labels",
    PROFILE_GALLERY: "profile-gallery",

    // Dashboard related.
    PROFILE_DASHBOARD: "profile/:profileid",
    DEVICE_DASHBOARD: "device/:deviceid",
    DEVICE_PROFILE_DASHBOARD: "device/:deviceid/profile/:profileid",

    // User related.
    MANAGE_USERS: "users",
    ADD_USER: "add-user",
    USER_DETAILS: "user-details",

    // Profile related.
    MANAGE_PROFILES: "profiles",
    ADD_PROFILE: "add-profile",
    ADD_USER_PROFILE: "adduserprofile",
    PROFILE_DETAILS: "profile-details",

    // Role related.
    ADD_ROLE: "addrole",
    MANAGE_ROLES: "roles",
    ROLE_DETAILS: "role-details",

    // Device related.
    MANAGE_DEVICES: "devices",
    ADD_DEVICE: "add-device",
    REGISTER_DEVICE: "register-device",
    DEVICE_DETAILS: "devicedetails",
    VIEW_DEVICES: "viewdevices",
    DEVICE_ALBUMS: "device-albums",

    // Report related.
    MANAGE_REPORTTEMPLATES: "report-templates",
    MANAGE_PROFILE_RERORTS: "profile-reports",
    ADD_REPORTTEMPLATE: "add-report-template",
    REPORT_TEMPLATE_DETAILS: "template-details",
    PROFILE_REPORT_DETAILS: "profile-report-details",
    REPORT_TYPE: "type",
    REPORT_TEMPLATE: "template",
    REPORT_RANGE: "range",
    REPORT_CONVERSATIONS: "conversations",
    REPORT_DETAILS: "details",
    REPORT_GENERATE: "generate",
    SELECT_TOPICS: "select-topics",
    CHOOSE_CONVERSATION: "conversation",
    CHOOSE_CONTACT: "contact",
    CHOOSE_DUPLICATE: "duplicate",
    CHOOSE_TEMPLATE: "template",
    PREVIEW_TEMPLATE: "preview",
    EDIT_CONVERSATION: "conversation",

    // Audit related.
    AUDIT: "audit",
    AUDIT_DETAILS: "auditdetails",

    // APK related.
    MANAGE_APK: "apk",
    APPBACKUPS: "app-backups",

    // Misc.
    MANAGE_TERMINOLOGY: "manageterminology",
    MANAGE_GLOBALLABELS: "labels",
    MANAGE_LOGS: "managelogs",
    VIEW_SYSTEMEVENTS: "viewsystemevents",
    MANAGE_LICENSES: "license",
    MANAGE_SYSTEM_SETTINGS: "systemsettings",
    MANAGE_USER_SUPERVISION: "manageusersupervision",
    HELP: "help",
    MEDIA_GALLERY: "media-gallery",
    NOTIFICATIONS: "notifications",
    SYSTEMWIDE_SEARCH: "systemwide-search"

};
