<div class="cat-flex-container cat-flex-column cat-font-weight-light">

    <modal-header
        [appId]="data?.appId"
        [accountId]="data?.accountId"
        [conversationId]="data?.conversationId"
        [contactId]="data?.contactId"
        [messageId]="data?.messageId"
        [serverId]="data?.serverId"
        [topicId]="data?.topicId"
        [keepOverlays]="true"
        [disableOpenMedia]="true"
        [title]="uitext.mediadetails"
        class="cat-margin-bottom-lg">
    </modal-header>

    @if (data?.attachment?.files[0]; as file) {
        <div class="cat-flex-container cat-border-top">
            <div class="cat-flex-grow cat-flex-column">
                <div class="cat-flex-grow cat-flex cat-padding-xl cat-align-center cat-justify-center cat-nooverflow-ellipsis">
                    @if (file.mimetype | includesMimetype : ['image/']) {
                        <img
                            class="cat-object-fit"
                            [src]="file | catsrc : 'media' : data.mediatype : data.refId">
                    } @else if (file.mimetype | includesMimetype : ['video/']) {
                        <video controls>
                            <source
                                [src]="file | catsrc : 'media' : data.mediatype : data.refId"
                                [type]="file.mimetype" />
                                {{ "Your browser does not support the video tag, please download it instead." | translate }}
                        </video>
                    } @else if (file.mimetype | includesMimetype : ['audio']) {
                        <audio controls autobuffer>
                            <source
                                [src]="file | catsrc : 'media' : data.mediatype : data.refId"
                                type="audio/wav" />
                            <source
                                [src]="file | catsrc : 'media' : data.mediatype : data.refId"
                                type="audio/opus" />
                            <source
                                [src]="file | catsrc : 'media' : data.mediatype : data.refId"
                                type="audio/ogg" />
                            <source
                                [src]="file | catsrc : 'media' : data.mediatype : data.refId"
                                type="audio/mpeg" />
                            {{ "Your browser does not support the audio element, please download it instead." | translate }}
                        </audio>
                    } @else if (file.mimetype | includesMimetype : ['application/x-tgsticker', 'application/x-lottie']) {
                        <lottie-container
                            [autoplay]="true"
                            [large]="true"
                            [file]="file"
                            [mediatype]="data.mediatype"
                            [refId]="data.refId">
                        </lottie-container>
                        @if (data.attachment.content) {
                            @for (keyvalue of data.attachment.content | keys; track keyvalue.key) {
                                <div class="cat-additional-attachment-info cat-full-width">
                                    <div class="cat-block cat-no-padding cat-no-margin">
                                        <b>{{ keyvalue.key }}</b> {{ keyvalue.value }}
                                    </div>
                                </div>
                            }
                        }
                    } @else {
                        <div>
                            <h4>{{ "Unknown media type, please download it to view." | translate }}</h4>
                        </div>
                    }
                </div>
                <footer class="cat-height-60 cat-flex-row cat-align-center cat-justify-center cat-border-top">

                    @if (data.messageLink && file.messagerefid) {
                        <button (click)="gotoMessage(file.messagerefid)" mat-button color="primary" class="cat-margin-right-xl">
                            <mat-icon color="primary">chevron_right</mat-icon>
                            <span color="primary">
                                {{ uitext.gotomessage | translate }}
                            </span>
                        </button>
                    }

                    @if (!(data.attachment.files[0].mimetype | includesMimetype : ['image', 'video', 'audio', 'application/x-tgsticker', 'application/x-lottie'])) {
                        <div class="cat-no-margin">
                            <i>{{ uitext.unsafematerial | translate }}</i>
                        </div>
                    }

                    <a
                        mat-button
                        download
                        [href]="data.attachment?.files[0] | catsrc : 'download' : data.mediatype : data.refId">
                        <mat-icon color="primary">download</mat-icon>
                        <span color="primary">
                            {{ uitext.download | translate }}
                        </span>
                    </a>
                </footer>

            </div>


            <div class="cat-sidebar cat-border-left">

                <div class="cat-overflow-y cat-full-height cat-padding-xl cat-border-box">

                    <div class="cat-break-all">
                        <div class="cat-margin-bottom-sm ">
                            <b>
                                {{ uitext.filename | translate }}
                            </b>
                        </div>
                        <div class="">
                            {{ file?.filename | emptyValue }}
                        </div>
                    </div>

                    <div class="cat-margin-top-xl cat-break-all">
                        <div class="cat-margin-bottom-sm">
                            <b>
                                {{ uitext.type | translate }}
                            </b>
                        </div>
                        <div>
                            {{ file?.mimetype | humanizeMimetype }} ({{ file?.mimetype | emptyValue }})
                        </div>
                    </div>

                    <div class="cat-margin-top-xl cat-break-all">
                        <div class="cat-margin-bottom-sm">
                            <b>
                                {{ uitext.size | translate }}
                            </b>
                        </div>
                        <div>
                            {{ (file?.filesize | filesize) | emptyValue }}
                        </div>
                    </div>

                    <div class="cat-margin-top-xl cat-break-all">
                        <div class="cat-margin-bottom-sm">
                            <b>
                                {{ uitext.externid | translate }}
                            </b>
                        </div>
                        <div>
                            {{ file?.externid | emptyValue }}
                        </div>
                    </div>

                    @for (keyvalue of file.filehashes | keys; track keyvalue.key) {
                        <div class="cat-margin-top-xl cat-break-all">
                            <div class="cat-margin-bottom-sm">
                                <b>
                                    {{ keyvalue.key | uppercase }} {{ uitext.hash | translate }}
                                </b>
                            </div>
                            <div>
                                {{ keyvalue.value | emptyValue }}
                            </div>
                        </div>
                    }

                    <div class="cat-margin-top-xl cat-break-all">
                        <div class="cat-margin-bottom-sm">
                            <b>
                                {{ uitext.origin | translate }}
                            </b>
                        </div>
                        <div>
                            {{ file?.origin | emptyValue }}
                        </div>
                    </div>

                    @if (file?.timestamputc) {
                        <div class="cat-margin-top-xl cat-break-all">
                            <div class="cat-margin-bottom-sm">
                                <b>
                                    {{ uitext.captured | translate }}
                                </b>
                            </div>
                            <div>
                                {{ (file?.timestamputc + 'Z' | date: 'd MMMM y, HH:mm:ss' : undefined : tl.locale) | emptyValue }}
                            </div>
                        </div>
                    }

                    <hr class="cat-block cat-margin-y-xl">

                    <div class="cat-margin-top-xl cat-break-all">
                        <div class="cat-margin-bottom-sm">
                            <b>
                                {{ uitext.created | translate }}
                            </b>
                            <mat-icon class="cat-cursor-pointer cat-margin-left-sm cat-font-md" matTooltip="{{ uitext.systeminfo | translate }}" matTooltipPosition="above">
                                help
                            </mat-icon>
                        </div>
                        <div>
                            {{ file?.created | date: 'd MMMM y, HH:mm:ss' : undefined : tl.locale }}
                        </div>
                    </div>

                    <div class="cat-margin-top-xl cat-margin-bottom-xl cat-break-all">
                        <div class="cat-margin-bottom-sm">
                            <b>
                                {{ uitext.lastmodified | translate }}
                            </b>
                            <mat-icon class="cat-cursor-pointer cat-margin-left-sm cat-font-md" matTooltip="{{ uitext.systeminfo | translate }}" matTooltipPosition="above">
                                help
                            </mat-icon>
                        </div>
                        <div>
                            {{ file?.lastmodified | date: 'd MMMM y, HH:mm:ss' : undefined : tl.locale }}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    }

</div>