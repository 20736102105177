<div class="cat-chat-item cat-padding-x-lg cat-cursor-pointer" [class.cat-selected]="selected" (click)="selectTopic()" [class.cat-disabled]="disabled">

    <div class="cat-avatar cat-md">

        <ng-container *ngIf="topic.avatar && topic.avatar.type === attachmentType.ATTACHMENT_TGS else avatar">
            <ng-container *ngIf="(topic.avatar | hasFile)">
                <lottie-container
                    [fullwidth]="true"
                    [file]="topic.avatar.files[0]"
                    [mediatype]="mediaType.MEDIA_ACCOUNTAVATARS"
                    [refId]="account.id">
                </lottie-container>
            </ng-container>
        </ng-container>

        <ng-template #avatar>
            <img
                *ngIf="(topic.avatar | hasThumb) else noAvatar"
                [src]="topic.avatar.files[0].thumbfileid | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
        </ng-template>

        <ng-template #noAvatar>
            <img matTooltip="{{ uitext.noavatarfound | translate }}" matTooltipPosition="left"  src="assets/img/cat-avatar.svg">
        </ng-template>
    </div>

    <div class="cat-flex-fill-remaining cat-margin-x-lg cat-nowrap cat-nooverflow-ellipsis">
        <span class="cat-no-margin cat-nooverflow-ellipsis" color="grey-dark">
            <b>{{ topic.name }}</b>
        </span>
        <p class="cat-no-margin cat-margin-top-sm cat-nooverflow-ellipsis" color="dark">
            <ng-container *ngIf="topic.messages && topic.messages.length else noMessage">
                <ng-container *ngIf="topic.messages[0].type === messageType.MESSAGE_MESSAGE">
                    {{ topic.messages[0].body }}
                    <ng-container *ngIf="(!topic.messages[0]?.body || topic.messages[0]?.attachments?.length)">
                        {{ (topic.messages[0] | getMsgAttachmentType) | translate }}
                        {{ "message" | translate }}
                    </ng-container>
                    <ng-container *ngIf="(!topic.messages[0]?.body && !topic.messages[0]?.attachments?.length) && topic.description?.length">
                        {{ topic.description }}
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="topic.messages[0].type === messageType.MESSAGE_POLL">
                    {{ "Poll" | translate }}
                    {{ topic.messages[0].body | emptyValue }}
                </ng-container>

                <ng-container *ngIf="topic.messages[0].type === messageType.MESSAGE_STORY_SHARE">
                    {{ (topic.messages[0].direction | isIncoming) ? topic.name : "You" | translate }}
                    {{ "shared a story" | translate }}
                </ng-container>

                <ng-container *ngIf="topic.messages[0].type === messageType.MESSAGE_POST_SHARE">
                    {{ (topic.messages[0].direction | isIncoming) ? topic.name : "You" | translate }}
                    {{ "shared a post" | translate }}
                </ng-container>

                <ng-container *ngIf="!(topic.messages[0].type === messageType.MESSAGE_MESSAGE) &&  !(topic.messages[0].type === messageType.MESSAGE_POLL) && !(topic.messages[0].type === messageType.MESSAGE_STORY_SHARE) && !(topic.messages[0].type === messageType.MESSAGE_POST_SHARE)">
                    {{ topic.messages[0] | getMsgServiceBody | translate | replaceTerm }}
                </ng-container>
            </ng-container>
            <ng-template #noMessage>-</ng-template>
        </p>
    </div>

    <div *ngIf="selected && unreadCount > 0" class="cat-nowrap cat-nooverflow-ellipsis cat-end cat-max-width-md cat-flex-column">
        <div class="cat-no-margin cat-nooverflow-ellipsis cat-button-wrapper">
            <div
                class="cat-avatar cat-xs cat-center"
                color="primary">
                <b>{{ unreadCount }}</b>
            </div>
        </div>
    </div>

    <mat-icon
        *ngIf="topic.state === topicState.TOPICSTATE_CLOSED"
        matTooltip="{{ 'Closed / ended' | translate }}" matTooltipPosition="above" matTooltipShowDelay="500"
        class="cat-font-lg cat-margin-left-sm"
        color="warn">
        close
    </mat-icon>

    <mat-icon
        *ngIf="topic.state === topicState.TOPICSTATE_LOCKED"
        matTooltip="{{ 'Locked' | translate }}" matTooltipPosition="above" matTooltipShowDelay="500"
        class="cat-font-lg cat-margin-left-sm"
        color="warn">
        lock
    </mat-icon>

    <mat-icon
        (click)="openTopicDetails(); $event.stopPropagation()"
        matTooltip="{{ uitext.info | translate }}" matTooltipPosition="above" matTooltipShowDelay="500"
        class="cat-font-lg cat-margin-left-sm"
        color="primary">
        info
    </mat-icon>

</div>