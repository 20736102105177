import { Pipe, PipeTransform } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { protosui } from "@definitions/definitions";
import { AngularValidators, IValidatorListItem } from "@shared/app-models";

@Pipe({
  name: "getValidatorMsg",
  pure: true
})
/**
* Get the definition message for a specific form error.
* @param {ValidationErrors} formErrors The form errors.
* @param {IValidatorListItem[]} catValidators The CAT defined field validators.
* @returns {string} The best available message.
*/
export class GetValidatorMsgPipe implements PipeTransform {
    transform(formErrors: ValidationErrors, catValidators: IValidatorListItem[]): string {
        let msg = "";
        if (formErrors) {
            const errorKeys = Object.keys(formErrors);
            if (errorKeys.length) {
                // Only show the first error.
                let catValidator = catValidators.find(value => value.type.toLowerCase() === errorKeys[0])
                if (!catValidator) {
                    // Determine whether a custom pattern validator is used.
                    if (errorKeys[0] === AngularValidators.Pattern.toLowerCase()) {
                        if (formErrors[AngularValidators.Pattern]?.requiredPattern.includes("[a-z0-9A-Z]")) {
                            errorKeys[0] = AngularValidators.patternSymbols.toLowerCase();
                        } else if (formErrors[AngularValidators.Pattern]?.requiredPattern.includes("[0-9]")) {
                            errorKeys[0] = AngularValidators.patternDigits.toLowerCase();
                        } else if (formErrors[AngularValidators.Pattern]?.requiredPattern.includes("[a-z]")) {
                            errorKeys[0] = AngularValidators.patternLowerCase.toLowerCase();
                        } else if (formErrors[AngularValidators.Pattern]?.requiredPattern.includes("[A-Z]")) {
                            errorKeys[0] = AngularValidators.patternUpperCase.toLowerCase();
                        }
                        catValidator = catValidators.find(value => value.type.toLowerCase() === errorKeys[0]);
                    }
                }
                // Process validator
                if (catValidator) {
                    msg = protosui.messages.validatorTexts[catValidator.type];
                    if (catValidator.value) {
                        msg += ` (${catValidator.value})`;
                    }
                }
            }
        }
        return msg;
    }
}
