// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { Injectable } from "@angular/core";
import { appRouteNames } from "@app/app.routes.names";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { LoggerService } from "@services/logger/logger.service";
import dayjs from "dayjs/esm";
import { cloneDeep } from "lodash-es";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})

export class ReportWizardService {

    // Report state subject.
    public reportSubject: BehaviorSubject<cat.ReportMsg> = new BehaviorSubject<cat.ReportMsg>(cat.ReportMsg.create());
    report$: Observable<cat.ReportMsg> = this.reportSubject.asObservable();

    // Step subject, with the first step as default.
    public stepSubject: BehaviorSubject<Steps> = new BehaviorSubject<Steps>(steps[0]);
    step$: Observable<Steps> = this.stepSubject.asObservable();

    // Detail validity.
    public detailValiditySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    detailsValid$: Observable<boolean> = this.detailValiditySubject.asObservable();

    constructor(private _logger: LoggerService) {
        this._logger.debug("ReportWizardService: constructed");
        this.initialize();
    }

    /**
     * Set the default / stored properties update completion level.
     *
     * @memberof ReportWizardService
     */
    public initialize() {
        // Get the report from local storage
        const report: cat.ReportMsg = cat.ReportMsg.create(JSON.parse(localStorage.getItem("reportStatus")));
        this.setCurrentStatus(report);

        // Set all the correct steps to completed.
        if (report?.template?.id) {
            this.markStepCompleted(steps.find(step => step.stepName === "template"));
        }
        if (report?.start && report?.end) {
            this.markStepCompleted(steps.find(step => step.stepName === "range"));
        }
        if (report?.conversations?.length) {
            this.markStepCompleted(steps.find(step => step.stepName === "conversations"));
        }
        if (report?.textfields?.filter(field => field.required).every(field => field.content) && report?.name) {
            this.markStepCompleted(steps.find(step => step.stepName === "details"));
        }

        // Get the current step from local storage, or use the default.
        const currentStep: Steps = JSON.parse(localStorage.getItem("currentStep")) || steps[0];
        this.setCurrentStep(currentStep);
    }

    /**
     * Set a selected template to the report.
     *
     * @param {cat.ReportTemplateMsg} template The report template.
     * @memberof ReportWizardService
     */
    public setTemplate(template: cat.ReportTemplateMsg) {
        this._logger.debug("Set the report template: ", template?.name);
        const report = this.reportSubject.value;
        report.template = template;
        this.setCurrentStatus(report);
    }

    /**
     * Remove the template from the report.
     *
     * @memberof ReportWizardService
     */
    public removeTemplate() {
        this._logger.debug("Remove the report template");
        const report = this.reportSubject.value;
        report.template = undefined;
        this.setCurrentStatus(report);
    }

    /**
     * Set a unix timerange in milliseconds.
     *
     * @param {dayjs.Dayjs} start
     * @param {dayjs.Dayjs} end
     * @memberof ReportWizardService
     */
    public setUnixRange(start: dayjs.Dayjs, end: dayjs.Dayjs) {
        this._logger.debug("Set the report range: ", start + " end: " + end);

        try {

            // Always add the minimum/maximum amount of milliseconds to include everything within the second.
            const startMS = start?.millisecond(0)?.valueOf();
            const endMS = end?.millisecond(999).valueOf();

            // Check if it's milliseconds.
            if (startMS?.toString()?.length !== 13 || (endMS?.toString()?.length !== 13)) {
                throw new Error("Wrong format, not milliseconds");
            }

            // Store the range.
            const report = this.reportSubject.value;
            report.start = startMS;
            report.end = endMS;
            this.setCurrentStatus(report);

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Set the name of the report, always required.
     *
     * @param {string} name The name of the report.
     * @memberof ReportWizardService
     */
    public setName(name: string) {
        this._logger.debug("Set the report name: ", name);

        const report = this.reportSubject.value;

        // Add the content of the fields.
        report.name = name;

        // Store the fields with content.
        this.setCurrentStatus(report);

    }

    /**
     * Clear the name of the report.
     *
     * @memberof ReportWizardService
     */
    public clearName() {
        this._logger.debug("Clear the report name");

        const report = this.reportSubject.value;

        // Add the content of the fields.
        report.name = "";

        // Store the fields with content.
        this.setCurrentStatus(report);

        // Update the completed flag, unmark it.
        const step = steps.find(step => step.stepName === "details");
        this.unmarkStepCompleted(step);
    }

    public setChatExcludedMessage(accountId: string, messageId: string, chatId: string) {
        try {

            const report = this.reportSubject.value;
            let chat = report.conversations.find(chat => chat.id === chatId);

            // Check if chat already exists in state.
            if (!chat) {
                // Create new chat, with excluded message list.
                const chat = cat.ConversationMsg.create({ id: chatId, excludedmessages: [ messageId ] });
                if (report.conversations?.length) {
                    report.conversations.push(chat);
                } else {
                    report.conversations = [ chat ];
                }
            } else {
                if (chat.excludedmessages?.includes(messageId)) {
                    const index = chat.excludedmessages.findIndex(msgId => msgId === messageId);
                    chat.excludedmessages.splice(index, 1);
                } else {
                    chat.excludedmessages.push(messageId);
                }
            }

            // Store the fields with content.
            this.setCurrentStatus(report);

        } catch (error) {
            this._logger.error(error);
        }
    }

    public getExcludedMessagesMap() {
        return JSON.parse(localStorage.getItem("excludeMap")) || {};
    }

    /**
     * Set the detail fields of the report.
     *
     * @param {*} details The detail fields.
     * @param {boolean} validity The validity.
     * @memberof ReportWizardService
     */
    public setDetails(details: any, validity: boolean) {
        this._logger.debug("Set the report details: ", details);

        const report = this.reportSubject.value;
        const fields = cloneDeep(report.template.inputfields);

        // Add the content of the fields.
        for (const field of fields) {
            field.content = details[field.name];
        }
        // Assign the fields.
        report.textfields = fields;
        // Update form validity (for the progress bar).
        this.detailValiditySubject.next(validity);
        // Store the fields with content, ignore changes to prevent input focus loss.
        this.setCurrentStatus(report, true);
    }

    /**
     * Clear the details of the report.
     *
     * @memberof ReportWizardService
     */
    public clearDetails() {
        this._logger.debug("Clear the report details (textfields)");

        const report = this.reportSubject.value;

        // Add the content of the fields.
        report.textfields = [];

        // Store the fields with content.
        this.setCurrentStatus(report);

        // Update form validity (for the progress bar).
        this.detailValiditySubject.next(false);

        // Update the completed flag, unmark it.
        const step = steps.find(step => step.stepName === "details");
        this.unmarkStepCompleted(step);
    }

    /**
     * Set the template options.
     *
     * @param {{ [key: string]: boolean }} options The options to set.
     * @memberof ReportWizardService
     */
    public setReportFlags(options: { [key: string]: boolean }) {
        this._logger.debug("Set the report template options: ", options);

        const report = this.reportSubject.value;

        // Contruct array with valid enums and true values.
        const optionList: cat.TemplateFlag[] = Object.keys(options)
            .filter((option) => cat.TemplateFlag[option] && options[option])
            .map(option => cat.TemplateFlag[option]);

        // // Assign the flags to the report.
        report.flags = optionList;
        // Store the fields with content.
        this.setCurrentStatus(report);
    }

    /**
     * Remove all template options.
     *
     * @memberof ReportWizardService
     */
    public remvoveTemplateFlags() {
        this._logger.debug("Remove the report template options.");

        const report = this.reportSubject.value;

        // Assign empty array.
        report.flags = [];
        // Store the fields with content.
        this.setCurrentStatus(report);
    }

    /**
     * Add a conversation with optional the members, a start and end.
     *
     * @param {string} chatId The conversation id.
     * @param {string} accountId The account id.
     * @param {cat.AccountMsg[]} [members] The selected members.
     * @param {number} [start] The (adjusted) start date.
     * @param {number} [end] The (adjusted) end date.
     * @memberof ReportWizardService
     */
    public addConversation(chatId: string, accountId: string, members?: cat.AccountMsg[], start?: number, end?: number, excludedMessages?: string[], topics?: cat.ITopicMsg[]) {
        this._logger.debug("Add a conversation: ", chatId);
        this._logger.debug("Set the chat range: ", start + " end: " + end);

        // Get the current report.
        const report = this.reportSubject.value;
        // Construct the conversation message, optionally with a member.
        const conversation = cat.ConversationMsg.create({
            id: chatId,
            account: cat.AccountMsg.create({ id: accountId })
        });

        // If there are members, store them
        if (members?.length) {
            conversation.members = members;
            conversation.allmembersselected = false;
        } else {
            conversation.members = undefined;
            conversation.allmembersselected = true;
        }

        if (topics?.length) {
            conversation.topics = topics;
        }

        // Provided excluded list takes priority, then already added messages, otherwise empty it.
        // When the global time range has changed, take the old excluded messages.
        const storedMessages = report.conversations.find(chat => chat.id === chatId);
        if (excludedMessages?.length) {
            conversation.excludedmessages = excludedMessages;
        } else if (storedMessages?.excludedmessages?.length) {
            conversation.excludedmessages = storedMessages.excludedmessages;
        } else {
            conversation.excludedmessages = [];
        }

        // Set specific range for conversation, always round to min/max milliseconds.
        const startMS = dayjs(start)?.millisecond(0)?.valueOf();
        const endMS = dayjs(end)?.millisecond(999)?.valueOf();
        if (startMS && endMS) {
            conversation.start = startMS;
            conversation.end = endMS;
        } else {
            conversation.start = report.start;
            conversation.end = report.end;
        }

        // Add the conversation to the report.
        if (report.conversations?.length) {
            const index = report.conversations.findIndex(chat => chat.id === chatId);
            if (index !== -1) {
                this._logger.warn("Chat already added, replace: ", index);
                report.conversations[index] = conversation;
            } else {
                report.conversations.push(conversation);
            }
        } else {
            report.conversations = [ conversation ];
        }

        this.setCurrentStatus(report);
        // If a conversation is added, the step is complete.
        const step = steps.find(step => step.stepName === "conversations");
        this.markStepCompleted(step);
    }

    /**
     * Remove a conversation from the report.
     *
     * @param {string} chatId The conversation to remove.
     * @memberof ReportWizardService
     */
    public removeConversation(chatId: string) {
        this._logger.debug("Remove a conversation: ", chatId);
        const report = this.reportSubject.value;
        if (report.conversations?.length) {
            report.conversations = report.conversations.filter((chat: cat.ConversationMsg) => chat.id !== chatId);
        } else {
            // If no conversations are present, the step is incomplete.
            const step = steps.find(step => step.stepName === "conversations");
            this.unmarkStepCompleted(step);
        }
        this.setCurrentStatus(report);
    }

    public adaptConversationTimes(start: number, end: number) {
        this._logger.warn("Adapt time range.", start + "  -  " + end);
        const report = this.reportSubject.value;
        for (const chat of report.conversations) {
            // Always clear the excluded messages on time range change.
            chat.excludedmessages = [];
            this.addConversation(chat.id, chat.account.id, chat.members as cat.AccountMsg[], start, end, [], chat.topics);
        }
    }

    /**
     * Toggle the select all members.
     *
     * @param {boolean} selectAll
     * @param {string} chatId
     * @memberof ReportWizardService
     */
    public toggleSelectAllMembers(selectAll: boolean, chatId: string) {
        this._logger.debug("Toggle all members: ", chatId + selectAll);

        // Get the current report.
        const report = this.reportSubject.value;
        // Find the correct chat.
        const chat = report.conversations.find(chat => chat.id === chatId);

        if (chat) {
            chat.allmembersselected = selectAll;
            this.setCurrentStatus(report);
            // Remove any stored members if select all is turned on.
            if (selectAll === true) {
                this.resetMemberSelection(chatId);
            }
        } else {
            this._logger.warn("Chat not found, could not add member");
        }

    }

    public resetMemberSelection(chatId: string) {
        // Get the current report.
        const report = this.reportSubject.value;
        // Find the correct chat.
        const chat = report.conversations.find(chat => chat.id === chatId);

        // Default is allmembersselected, remove selected members.
        chat.allmembersselected = true;
        chat.members = [];
        // Set the report.
        this.setCurrentStatus(report);
    }

    /**
     * Toggle a single member for a specific conversation.
     *
     * @param {string} chatId The conversation id.
     * @param {string} memberId The member id.
     * @memberof ReportWizardService
     */
    public toggleMember(chatId: string, memberId: string) {
        this._logger.debug("Toggle a member: ", chatId + memberId);

        // Get the current report.
        const report = this.reportSubject.value;

        // Find the correct chat.
        const chat = report.conversations.find(chat => chat.id === chatId);

        if (chat) {

            // Add an empty array if needed.
            if (!chat.members?.length) {
                chat.members = [];
            }

            // Toggle the memberby adding or removing the id.
            const index = chat.members?.findIndex(member => member.id === memberId);
            if (index !== -1) {
                this._logger.warn("Member already added, remove: ", index);
                chat.members.splice(index, 1);
            } else {
                chat.members.push(cat.AccountMsg.create({ id: memberId }));
            }

            this.setCurrentStatus(report);

        } else {
            this._logger.warn("Chat not found, could not add member");
        }
    }

    /**
     * Set the export form field values.
     *
     * @param {boolean} exportfiles Export files field boolean.
     * @param {boolean} viewableexport Viewable export field boolean.
     * @memberof ReportWizardService
     */
    public setExportOptions(exportfiles: boolean, viewableexport: boolean) {
        this._logger.debug("Set the report export options: ", exportfiles);

        const report = this.reportSubject.value;

        // Add the content of the fields.
        report.exportfiles = exportfiles;
        report.viewableexport = viewableexport;

        // Store the fields with content.
        this.setCurrentStatus(report);
    }

    /**
     * Return the step by number reference.
     *
     * @param {number} stepNumber
     * @returns
     * @memberof ReportWizardService
     */
    public getStepDefinition(stepNumber: number) {
        return steps.find(step => step.stepNumber === stepNumber);
    }

    /**
     * Get the previous step.
     *
     * @param {number} stepNumber
     * @returns
     * @memberof ReportWizardService
     */
    public getPreviousStep(stepNumber: number) {
        return steps.find(step => step.stepNumber === stepNumber - 1);
    }

    /**
     * Get the next, not completed, step.
     *
     * @returns
     * @memberof ReportWizardService
     */
    public getNextStep(stepNumber: number) {
        return steps.find(step => step.stepNumber === stepNumber + 1);
    }

    /**
     * Set the current step.
     *
     * @param {Steps} step
     * @memberof ReportWizardService
     */
    public setCurrentStep(step: Steps) {
        if (step) {
            localStorage.setItem("currentStep", JSON.stringify(step));
            // Use clone to trigger changes.
            this.stepSubject.next(cloneDeep(step));
        } else {
            this._logger.error("No step provided, couldn't store.")
        }
    }

    /**
     * Store the selected account.
     *
     * @param {string} accountId
     * @memberof ReportWizardService
     */
    public selectAccount(accountId: string) {
        if (accountId) {
            localStorage.setItem("selectedAccount", accountId);
        } else {
            this._logger.error("No step provided, couldn't store.")
        }
    }

    /**
     * Get the stored account.
     *
     * @returns
     * @memberof ReportWizardService
     */
    public getAccount() {
        return localStorage.getItem("selectedAccount")?.trim();
    }

    /**
     * Remove the account from storage.
     *
     * @memberof ReportWizardService
     */
    public deselectAccount() {
        localStorage.removeItem("selectedAccount");
    }

    /**
     * Set the conversation view.
     *
     * @param {("choose" | "selection")} view The view to store.
     * @memberof ReportWizardService
     */
    public setView(view: "choose" | "selection") {
        if (view) {
            localStorage.setItem("conversationView", view);
        } else {
            this._logger.error("No view provided, couldn't store.")
        }
    }

    /**
     * Get the conversation view.
     *
     * @returns {("choose" | "selection")}
     * @memberof ReportWizardService
     */
    public getView(): "choose" | "selection" {
        return localStorage.getItem("conversationView")?.trim() as "choose" | "selection" || "choose";
    }

    /**
     * Mark a reporting step as completed.
     *
     * @param {Steps} step The step.
     * @memberof ReportWizardService
     */
    public markStepCompleted(step: Steps) {
        if (step) {
            step.stepCompleted = true;
        } else {
            this._logger.error("No step provided, couldn't store.")
        }
    }

    /**
     * Unmark a step as incomplete.
     *
     * @param {Steps} step The step.
     * @memberof ReportWizardService
     */
    public unmarkStepCompleted(step: Steps) {
        if (step) {
            step.stepCompleted = false;
        } else {
            this._logger.error("No step provided, couldn't store.")
        }
    }

    /**
     * General function to store the report, always use this.
     *
     * @param {cat.ReportMsg} report
     * @memberof ReportWizardService
     */
    public setCurrentStatus(report: cat.ReportMsg, ignoreChange = false) {
        if (report) {
            localStorage.setItem("reportStatus", JSON.stringify(cat.ReportMsg.toObject(report, { arrays: true })));
            this._logger.debug("Set report", cat.ReportMsg.toObject(report));
            if (!ignoreChange) {
                // Use clone to trigger changes.
                this.reportSubject.next(cloneDeep(report));
            } else {
                this.reportSubject.next(report);
            }
        } else {
            this._logger.error("No report provided, couldn't store.")
        }
    }

    /**
     * Set the report mode, empty for a blank start, prefilled for a contact, conversation or duplicate.
     *
     * @param {("empty" | "prefilled")} mode
     * @memberof ReportWizardService
     */
    public setReportMode(mode: "empty" | "prefilled") {
        if (mode) {
            localStorage.setItem("reportMode", mode);
        } else {
            this._logger.error("No mode provided, couldn't store.")
        }
    }

    /**
     * Get the current report mode.
     *
     * @returns {("empty" | "prefilled")}
     * @memberof ReportWizardService
     */
    public getReportMode(): "empty" | "prefilled" {
        return localStorage.getItem("reportMode") as "empty" | "prefilled";
    }

    /**
     * Clear the report mode.
     *
     * @memberof ReportWizardService
     */
    public clearReportMode() {
        localStorage.removeItem("reportMode");
    }

    /**
     * Remove all local storage and reset the subjects.
     *
     * @memberof ReportWizardService
     */
    public removeCurrentState() {
        this._logger.warn("Removing the current report state / steps.");
        localStorage.removeItem("reportStatus");
        localStorage.removeItem("currentStep");
        localStorage.removeItem("conversationView");
        localStorage.removeItem("excludeMap");
        this.clearReportMode();
        this.reportSubject.next(cat.ReportMsg.create());
        this.stepSubject.next(steps[0]);
        // Set all the steps to incomplete.
        steps.map(step => step.stepCompleted = false);
    }

    /**
     * Start a new report from another one, duplicate, chat or contact.
     *
     * @param {cat.ReportMsg} report The report with the report details.
     * @memberof ReportWizardService
     */
    public startDuplicateReport(report: cat.ReportMsg) {
        try {

            this._logger.warn("REPORT TO START WITH", report);

            if (!report) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            // First remove any stored state.
            this.removeCurrentState();

            // Store the range.
            const start = dayjs(report.start);
            const end = dayjs(report.end);

            this.setUnixRange(start, end);

            // Store the conversations.
            for (const chat of report.conversations) {
                // Set end to 'lastmodified' if not provided.
                const chatEnd = chat.end ? chat.end : chat.lastmodified;
                this.addConversation(chat.id, chat.account.id, chat.members as cat.AccountMsg[], chat.start, chatEnd, chat.excludedmessages, chat.topics);
            }
            // Store the template.
            if (report.template) {
                this.setTemplate(report.template as cat.ReportTemplateMsg);
                // Mark completed.
                const templateStep = steps.find(step => step.stepName === "template");
                this.markStepCompleted(templateStep);
            }

            // Store the textfields.
            if (report.textfields.length) {
                const values = {};
                report.textfields.map(field => (values[field.name] = field.content));
                this.setDetails(values, true);
                // Mark completed.
                const detailStep = steps.find(step => step.stepName === "details");
                this.markStepCompleted(detailStep);
            }


            // Set the name.
            this.setName(report.name);

            // Set the export fields.
            this.setExportOptions(report.exportfiles, report.viewableexport);

            // Store report flags, if available.
            const flags = {};
            Object.keys(cat.TemplateFlag)
                .filter(key => !key.match(/UNKNOWN/g))
                .map(key => flags[key] = (report.flags?.includes(cat.TemplateFlag[key]) ));
            this.setReportFlags(flags);

            // Mark the chat and range step completed.
            const templateStep = steps.find(step => step.stepName === "template");
            const rangeStep = steps.find(step => step.stepName === "range");
            const chatStep = steps.find(step => step.stepName === "conversations");
            this.markStepCompleted(rangeStep);
            this.markStepCompleted(chatStep);

            // Go to the first step: template.
            this.setCurrentStep(templateStep);

            // Set the reportMode to 'prefilled'.
            this.setReportMode("prefilled");

        } catch (error) {
            this._logger.error(error);
        }
    }
}

// Create the step definitions.
export class Steps {
    constructor(
        public title: string,
        public route: string,
        public stepName: "template" | "range" | "conversations" | "details" | "generate",
        public stepCompleted: boolean,
        public stepNumber?: number
    ) {}
}

const createSteps = (
    title: string,
    route: string,
    stepName: "template" | "range" | "conversations" | "details" | "generate",
    stepCompleted: boolean,
    stepNumber: number): Steps => new Steps(title, route, stepName, stepCompleted, stepNumber);

export const steps: Steps[] = [
    createSteps(protosui.messages.uitext.reportstep1, appRouteNames.REPORT_TEMPLATE, "template", false, 1),
    createSteps(protosui.messages.uitext.reportstep2, appRouteNames.REPORT_RANGE, "range", false, 2),
    createSteps(protosui.messages.uitext.reportstep3, appRouteNames.REPORT_CONVERSATIONS, "conversations", false, 3),
    createSteps(protosui.messages.uitext.reportstep4, appRouteNames.REPORT_DETAILS, "details", false, 4),
    createSteps(protosui.messages.uitext.reportstep5, appRouteNames.REPORT_GENERATE, "generate", false, 5)
];