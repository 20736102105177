/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const cat = $root.cat = (() => {

    /**
     * Namespace cat.
     * @exports cat
     * @namespace
     */
    const cat = {};

    cat.WebsocketMsg = (function() {

        /**
         * Properties of a WebsocketMsg.
         * @memberof cat
         * @interface IWebsocketMsg
         * @property {string|null} [version] WebsocketMsg version
         * @property {string|null} [callname] WebsocketMsg callname
         * @property {boolean|null} [pushnotification] WebsocketMsg pushnotification
         * @property {string|null} [requesttype] WebsocketMsg requesttype
         * @property {string|null} [responsetype] WebsocketMsg responsetype
         * @property {cat.IUserSessionMsg|null} [usersession] WebsocketMsg usersession
         * @property {boolean|null} [callresult] WebsocketMsg callresult
         * @property {boolean|null} [endstream] WebsocketMsg endstream
         * @property {cat.CallStatus|null} [callstatus] WebsocketMsg callstatus
         * @property {Uint8Array|null} [payload] WebsocketMsg payload
         * @property {string|null} [callid] WebsocketMsg callid
         * @property {cat.ErrorType|null} [errortype] WebsocketMsg errortype
         * @property {cat.IQueryMsg|null} [query] WebsocketMsg query
         */

        /**
         * Constructs a new WebsocketMsg.
         * @memberof cat
         * @classdesc Represents a WebsocketMsg.
         * @implements IWebsocketMsg
         * @constructor
         * @param {cat.IWebsocketMsg=} [p] Properties to set
         */
        function WebsocketMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WebsocketMsg version.
         * @member {string} version
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.version = "";

        /**
         * WebsocketMsg callname.
         * @member {string} callname
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.callname = "";

        /**
         * WebsocketMsg pushnotification.
         * @member {boolean} pushnotification
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.pushnotification = false;

        /**
         * WebsocketMsg requesttype.
         * @member {string} requesttype
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.requesttype = "";

        /**
         * WebsocketMsg responsetype.
         * @member {string} responsetype
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.responsetype = "";

        /**
         * WebsocketMsg usersession.
         * @member {cat.IUserSessionMsg|null|undefined} usersession
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.usersession = null;

        /**
         * WebsocketMsg callresult.
         * @member {boolean} callresult
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.callresult = false;

        /**
         * WebsocketMsg endstream.
         * @member {boolean} endstream
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.endstream = false;

        /**
         * WebsocketMsg callstatus.
         * @member {cat.CallStatus} callstatus
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.callstatus = 0;

        /**
         * WebsocketMsg payload.
         * @member {Uint8Array} payload
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.payload = $util.newBuffer([]);

        /**
         * WebsocketMsg callid.
         * @member {string} callid
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.callid = "";

        /**
         * WebsocketMsg errortype.
         * @member {cat.ErrorType} errortype
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.errortype = 0;

        /**
         * WebsocketMsg query.
         * @member {cat.IQueryMsg|null|undefined} query
         * @memberof cat.WebsocketMsg
         * @instance
         */
        WebsocketMsg.prototype.query = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(WebsocketMsg.prototype, "_query", {
            get: $util.oneOfGetter($oneOfFields = ["query"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new WebsocketMsg instance using the specified properties.
         * @function create
         * @memberof cat.WebsocketMsg
         * @static
         * @param {cat.IWebsocketMsg=} [properties] Properties to set
         * @returns {cat.WebsocketMsg} WebsocketMsg instance
         */
        WebsocketMsg.create = function create(properties) {
            return new WebsocketMsg(properties);
        };

        /**
         * Encodes the specified WebsocketMsg message. Does not implicitly {@link cat.WebsocketMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.WebsocketMsg
         * @static
         * @param {cat.IWebsocketMsg} m WebsocketMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebsocketMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(10).string(m.version);
            if (m.callname != null && Object.hasOwnProperty.call(m, "callname"))
                w.uint32(18).string(m.callname);
            if (m.pushnotification != null && Object.hasOwnProperty.call(m, "pushnotification"))
                w.uint32(24).bool(m.pushnotification);
            if (m.requesttype != null && Object.hasOwnProperty.call(m, "requesttype"))
                w.uint32(34).string(m.requesttype);
            if (m.responsetype != null && Object.hasOwnProperty.call(m, "responsetype"))
                w.uint32(42).string(m.responsetype);
            if (m.usersession != null && Object.hasOwnProperty.call(m, "usersession"))
                $root.cat.UserSessionMsg.encode(m.usersession, w.uint32(50).fork()).ldelim();
            if (m.callresult != null && Object.hasOwnProperty.call(m, "callresult"))
                w.uint32(56).bool(m.callresult);
            if (m.endstream != null && Object.hasOwnProperty.call(m, "endstream"))
                w.uint32(64).bool(m.endstream);
            if (m.callstatus != null && Object.hasOwnProperty.call(m, "callstatus"))
                w.uint32(72).int32(m.callstatus);
            if (m.payload != null && Object.hasOwnProperty.call(m, "payload"))
                w.uint32(82).bytes(m.payload);
            if (m.callid != null && Object.hasOwnProperty.call(m, "callid"))
                w.uint32(90).string(m.callid);
            if (m.errortype != null && Object.hasOwnProperty.call(m, "errortype"))
                w.uint32(96).int32(m.errortype);
            if (m.query != null && Object.hasOwnProperty.call(m, "query"))
                $root.cat.QueryMsg.encode(m.query, w.uint32(106).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a WebsocketMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.WebsocketMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.WebsocketMsg} WebsocketMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebsocketMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.WebsocketMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.version = r.string();
                        break;
                    }
                case 2: {
                        m.callname = r.string();
                        break;
                    }
                case 3: {
                        m.pushnotification = r.bool();
                        break;
                    }
                case 4: {
                        m.requesttype = r.string();
                        break;
                    }
                case 5: {
                        m.responsetype = r.string();
                        break;
                    }
                case 6: {
                        m.usersession = $root.cat.UserSessionMsg.decode(r, r.uint32());
                        break;
                    }
                case 7: {
                        m.callresult = r.bool();
                        break;
                    }
                case 8: {
                        m.endstream = r.bool();
                        break;
                    }
                case 9: {
                        m.callstatus = r.int32();
                        break;
                    }
                case 10: {
                        m.payload = r.bytes();
                        break;
                    }
                case 11: {
                        m.callid = r.string();
                        break;
                    }
                case 12: {
                        m.errortype = r.int32();
                        break;
                    }
                case 13: {
                        m.query = $root.cat.QueryMsg.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WebsocketMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.WebsocketMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.WebsocketMsg} WebsocketMsg
         */
        WebsocketMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.WebsocketMsg)
                return d;
            var m = new $root.cat.WebsocketMsg();
            if (d.version != null) {
                m.version = String(d.version);
            }
            if (d.callname != null) {
                m.callname = String(d.callname);
            }
            if (d.pushnotification != null) {
                m.pushnotification = Boolean(d.pushnotification);
            }
            if (d.requesttype != null) {
                m.requesttype = String(d.requesttype);
            }
            if (d.responsetype != null) {
                m.responsetype = String(d.responsetype);
            }
            if (d.usersession != null) {
                if (typeof d.usersession !== "object")
                    throw TypeError(".cat.WebsocketMsg.usersession: object expected");
                m.usersession = $root.cat.UserSessionMsg.fromObject(d.usersession);
            }
            if (d.callresult != null) {
                m.callresult = Boolean(d.callresult);
            }
            if (d.endstream != null) {
                m.endstream = Boolean(d.endstream);
            }
            switch (d.callstatus) {
            default:
                if (typeof d.callstatus === "number") {
                    m.callstatus = d.callstatus;
                    break;
                }
                break;
            case "CALLSTATUS_OK":
            case 0:
                m.callstatus = 0;
                break;
            case "CALLSTATUS_CANCELLED":
            case 1:
                m.callstatus = 1;
                break;
            case "CALLSTATUS_UNKNOWN":
            case 2:
                m.callstatus = 2;
                break;
            case "CALLSTATUS_INVALID_ARGUMENT":
            case 3:
                m.callstatus = 3;
                break;
            case "CALLSTATUS_DEADLINE_EXCEEDED":
            case 4:
                m.callstatus = 4;
                break;
            case "CALLSTATUS_NOT_FOUND":
            case 5:
                m.callstatus = 5;
                break;
            case "CALLSTATUS_ALREADY_EXISTS":
            case 6:
                m.callstatus = 6;
                break;
            case "CALLSTATUS_PERMISSION_DENIED":
            case 7:
                m.callstatus = 7;
                break;
            case "CALLSTATUS_RESOURCE_EXHAUSTED":
            case 8:
                m.callstatus = 8;
                break;
            case "CALLSTATUS_FAILED_PRECONDITION":
            case 9:
                m.callstatus = 9;
                break;
            case "CALLSTATUS_ABORTED":
            case 10:
                m.callstatus = 10;
                break;
            case "CALLSTATUS_OUT_OF_RANGE":
            case 11:
                m.callstatus = 11;
                break;
            case "CALLSTATUS_UNIMPLEMENTED":
            case 12:
                m.callstatus = 12;
                break;
            case "CALLSTATUS_INTERNAL":
            case 13:
                m.callstatus = 13;
                break;
            case "CALLSTATUS_UNAVAILABLE":
            case 14:
                m.callstatus = 14;
                break;
            case "CALLSTATUS_DATA_LOSS":
            case 15:
                m.callstatus = 15;
                break;
            case "CALLSTATUS_UNAUTHENTICATED":
            case 16:
                m.callstatus = 16;
                break;
            }
            if (d.payload != null) {
                if (typeof d.payload === "string")
                    $util.base64.decode(d.payload, m.payload = $util.newBuffer($util.base64.length(d.payload)), 0);
                else if (d.payload.length >= 0)
                    m.payload = d.payload;
            }
            if (d.callid != null) {
                m.callid = String(d.callid);
            }
            switch (d.errortype) {
            default:
                if (typeof d.errortype === "number") {
                    m.errortype = d.errortype;
                    break;
                }
                break;
            case "ERROR_UNKNOWN":
            case 0:
                m.errortype = 0;
                break;
            case "ERROR_INVALID_CREDENTIALS":
            case 1:
                m.errortype = 1;
                break;
            case "ERROR_MISSING_ARGUMENT":
            case 2:
                m.errortype = 2;
                break;
            case "ERROR_INVALID_SESSION":
            case 3:
                m.errortype = 3;
                break;
            case "ERROR_PERMISSION_DENIED":
            case 4:
                m.errortype = 4;
                break;
            case "ERROR_DATABASE_ERROR":
            case 5:
                m.errortype = 5;
                break;
            case "ERROR_MISSING_ROLE":
            case 6:
                m.errortype = 6;
                break;
            case "ERROR_ROLE_IN_USE":
            case 7:
                m.errortype = 7;
                break;
            case "ERROR_THEME_IN_USE":
            case 8:
                m.errortype = 8;
                break;
            case "ERROR_DEVICE_IN_USE":
            case 9:
                m.errortype = 9;
                break;
            case "ERROR_DEVICE_REQUIREMENTS":
            case 10:
                m.errortype = 10;
                break;
            case "ERROR_APP_IN_USE":
            case 11:
                m.errortype = 11;
                break;
            case "ERROR_APP_ALREADY_EXISTS":
            case 12:
                m.errortype = 12;
                break;
            case "ERROR_APP_REQUIREMENTS":
            case 13:
                m.errortype = 13;
                break;
            case "ERROR_DATA_ACCESS":
            case 14:
                m.errortype = 14;
                break;
            case "ERROR_ALREADY_EXISTS":
            case 15:
                m.errortype = 15;
                break;
            case "ERROR_INVALID_ROLE":
            case 16:
                m.errortype = 16;
                break;
            case "ERROR_ROLE_IS_READONLY":
            case 17:
                m.errortype = 17;
                break;
            case "ERROR_PASSWORD_EXPIRED":
            case 18:
                m.errortype = 18;
                break;
            case "ERROR_PROFILE_IN_USE":
            case 19:
                m.errortype = 19;
                break;
            case "ERROR_PERMISSION_IS_REQUIRED":
            case 20:
                m.errortype = 20;
                break;
            case "ERROR_CASE_IN_USE":
            case 21:
                m.errortype = 21;
                break;
            case "ERROR_PROFILE_NOT_LICENSED":
            case 22:
                m.errortype = 22;
                break;
            case "ERROR_INVALID_LICENSE":
            case 23:
                m.errortype = 23;
                break;
            case "ERROR_IMAGE_WRONG_ASPECT_RATIO":
            case 26:
                m.errortype = 26;
                break;
            case "ERROR_DEVICE_NOT_AVAILABLE":
            case 27:
                m.errortype = 27;
                break;
            case "ERROR_DEVICE_ALREADY_LOCKED":
            case 28:
                m.errortype = 28;
                break;
            case "ERROR_DEVICE_NOT_LOCKED":
            case 29:
                m.errortype = 29;
                break;
            case "ERROR_USERNAME_OCCUPIED":
            case 30:
                m.errortype = 30;
                break;
            case "ERROR_FIRSTNAME_EMPTY":
            case 31:
                m.errortype = 31;
                break;
            case "ERROR_FULLNAME_NOT_SET":
            case 33:
                m.errortype = 33;
                break;
            case "ERROR_BIO_NOT_SET":
            case 34:
                m.errortype = 34;
                break;
            case "ERROR_WEBSITE_NOT_SET":
            case 35:
                m.errortype = 35;
                break;
            case "ERROR_IMAGE_UNSUPPORTED_FORMAT":
            case 37:
                m.errortype = 37;
                break;
            case "ERROR_IMAGE_TOO_LARGE":
            case 38:
                m.errortype = 38;
                break;
            case "ERROR_CHANGE_FRESH_PHONENUMBER":
            case 39:
                m.errortype = 39;
                break;
            case "ERROR_MISSING_CONFIGURATION":
            case 40:
                m.errortype = 40;
                break;
            case "ERROR_INVALID_CALL":
            case 41:
                m.errortype = 41;
                break;
            case "ERROR_APP_ALREADY_ASSIGNED":
            case 42:
                m.errortype = 42;
                break;
            case "ERROR_ROLE_CHANGE_DENIED_PROFILES":
            case 43:
                m.errortype = 43;
                break;
            case "ERROR_DELETE_PROFILE_HAS_CASES":
            case 44:
                m.errortype = 44;
                break;
            case "ERROR_DELETE_PROFILE_HAS_ACCOUNTS":
            case 45:
                m.errortype = 45;
                break;
            case "ERROR_DELETE_PROFILE_HAS_REPORTS":
            case 46:
                m.errortype = 46;
                break;
            case "ERROR_DELETE_PROFILE_HAS_MEDIA":
            case 47:
                m.errortype = 47;
                break;
            case "ERROR_KEYCLOAK_NO_ROLES":
            case 48:
                m.errortype = 48;
                break;
            case "ERROR_INVALID_REPORT_JSON":
            case 50:
                m.errortype = 50;
                break;
            case "ERROR_REPORT_TEMPLATE_NOT_FOUND":
            case 51:
                m.errortype = 51;
                break;
            case "ERROR_CANNOT_DISABLE_SELF":
            case 52:
                m.errortype = 52;
                break;
            case "ERROR_INVALID_FILTER":
            case 53:
                m.errortype = 53;
                break;
            case "ERROR_DELETE_USER_HAS_CASES":
            case 54:
                m.errortype = 54;
                break;
            case "ERROR_DELETE_USER_HAS_USERS":
            case 55:
                m.errortype = 55;
                break;
            case "ERROR_DELETE_USER_HAS_PROFILES":
            case 56:
                m.errortype = 56;
                break;
            case "ERROR_DOMAIN_IN_USE":
            case 57:
                m.errortype = 57;
                break;
            case "ERROR_PASSWORD_IDENTICAL":
            case 58:
                m.errortype = 58;
                break;
            }
            if (d.query != null) {
                if (typeof d.query !== "object")
                    throw TypeError(".cat.WebsocketMsg.query: object expected");
                m.query = $root.cat.QueryMsg.fromObject(d.query);
            }
            return m;
        };

        /**
         * Creates a plain object from a WebsocketMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.WebsocketMsg
         * @static
         * @param {cat.WebsocketMsg} m WebsocketMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WebsocketMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.version = "";
                d.callname = "";
                d.pushnotification = false;
                d.requesttype = "";
                d.responsetype = "";
                d.usersession = null;
                d.callresult = false;
                d.endstream = false;
                d.callstatus = o.enums === String ? "CALLSTATUS_OK" : 0;
                if (o.bytes === String)
                    d.payload = "";
                else {
                    d.payload = [];
                    if (o.bytes !== Array)
                        d.payload = $util.newBuffer(d.payload);
                }
                d.callid = "";
                d.errortype = o.enums === String ? "ERROR_UNKNOWN" : 0;
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                d.version = m.version;
            }
            if (m.callname != null && m.hasOwnProperty("callname")) {
                d.callname = m.callname;
            }
            if (m.pushnotification != null && m.hasOwnProperty("pushnotification")) {
                d.pushnotification = m.pushnotification;
            }
            if (m.requesttype != null && m.hasOwnProperty("requesttype")) {
                d.requesttype = m.requesttype;
            }
            if (m.responsetype != null && m.hasOwnProperty("responsetype")) {
                d.responsetype = m.responsetype;
            }
            if (m.usersession != null && m.hasOwnProperty("usersession")) {
                d.usersession = $root.cat.UserSessionMsg.toObject(m.usersession, o);
            }
            if (m.callresult != null && m.hasOwnProperty("callresult")) {
                d.callresult = m.callresult;
            }
            if (m.endstream != null && m.hasOwnProperty("endstream")) {
                d.endstream = m.endstream;
            }
            if (m.callstatus != null && m.hasOwnProperty("callstatus")) {
                d.callstatus = o.enums === String ? $root.cat.CallStatus[m.callstatus] === undefined ? m.callstatus : $root.cat.CallStatus[m.callstatus] : m.callstatus;
            }
            if (m.payload != null && m.hasOwnProperty("payload")) {
                d.payload = o.bytes === String ? $util.base64.encode(m.payload, 0, m.payload.length) : o.bytes === Array ? Array.prototype.slice.call(m.payload) : m.payload;
            }
            if (m.callid != null && m.hasOwnProperty("callid")) {
                d.callid = m.callid;
            }
            if (m.errortype != null && m.hasOwnProperty("errortype")) {
                d.errortype = o.enums === String ? $root.cat.ErrorType[m.errortype] === undefined ? m.errortype : $root.cat.ErrorType[m.errortype] : m.errortype;
            }
            if (m.query != null && m.hasOwnProperty("query")) {
                d.query = $root.cat.QueryMsg.toObject(m.query, o);
                if (o.oneofs)
                    d._query = "query";
            }
            return d;
        };

        /**
         * Converts this WebsocketMsg to JSON.
         * @function toJSON
         * @memberof cat.WebsocketMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WebsocketMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WebsocketMsg
         * @function getTypeUrl
         * @memberof cat.WebsocketMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebsocketMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.WebsocketMsg";
        };

        return WebsocketMsg;
    })();

    /**
     * CallStatus enum.
     * @name cat.CallStatus
     * @enum {number}
     * @property {number} CALLSTATUS_OK=0 CALLSTATUS_OK value
     * @property {number} CALLSTATUS_CANCELLED=1 CALLSTATUS_CANCELLED value
     * @property {number} CALLSTATUS_UNKNOWN=2 CALLSTATUS_UNKNOWN value
     * @property {number} CALLSTATUS_INVALID_ARGUMENT=3 CALLSTATUS_INVALID_ARGUMENT value
     * @property {number} CALLSTATUS_DEADLINE_EXCEEDED=4 CALLSTATUS_DEADLINE_EXCEEDED value
     * @property {number} CALLSTATUS_NOT_FOUND=5 CALLSTATUS_NOT_FOUND value
     * @property {number} CALLSTATUS_ALREADY_EXISTS=6 CALLSTATUS_ALREADY_EXISTS value
     * @property {number} CALLSTATUS_PERMISSION_DENIED=7 CALLSTATUS_PERMISSION_DENIED value
     * @property {number} CALLSTATUS_RESOURCE_EXHAUSTED=8 CALLSTATUS_RESOURCE_EXHAUSTED value
     * @property {number} CALLSTATUS_FAILED_PRECONDITION=9 CALLSTATUS_FAILED_PRECONDITION value
     * @property {number} CALLSTATUS_ABORTED=10 CALLSTATUS_ABORTED value
     * @property {number} CALLSTATUS_OUT_OF_RANGE=11 CALLSTATUS_OUT_OF_RANGE value
     * @property {number} CALLSTATUS_UNIMPLEMENTED=12 CALLSTATUS_UNIMPLEMENTED value
     * @property {number} CALLSTATUS_INTERNAL=13 CALLSTATUS_INTERNAL value
     * @property {number} CALLSTATUS_UNAVAILABLE=14 CALLSTATUS_UNAVAILABLE value
     * @property {number} CALLSTATUS_DATA_LOSS=15 CALLSTATUS_DATA_LOSS value
     * @property {number} CALLSTATUS_UNAUTHENTICATED=16 CALLSTATUS_UNAUTHENTICATED value
     */
    cat.CallStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CALLSTATUS_OK"] = 0;
        values[valuesById[1] = "CALLSTATUS_CANCELLED"] = 1;
        values[valuesById[2] = "CALLSTATUS_UNKNOWN"] = 2;
        values[valuesById[3] = "CALLSTATUS_INVALID_ARGUMENT"] = 3;
        values[valuesById[4] = "CALLSTATUS_DEADLINE_EXCEEDED"] = 4;
        values[valuesById[5] = "CALLSTATUS_NOT_FOUND"] = 5;
        values[valuesById[6] = "CALLSTATUS_ALREADY_EXISTS"] = 6;
        values[valuesById[7] = "CALLSTATUS_PERMISSION_DENIED"] = 7;
        values[valuesById[8] = "CALLSTATUS_RESOURCE_EXHAUSTED"] = 8;
        values[valuesById[9] = "CALLSTATUS_FAILED_PRECONDITION"] = 9;
        values[valuesById[10] = "CALLSTATUS_ABORTED"] = 10;
        values[valuesById[11] = "CALLSTATUS_OUT_OF_RANGE"] = 11;
        values[valuesById[12] = "CALLSTATUS_UNIMPLEMENTED"] = 12;
        values[valuesById[13] = "CALLSTATUS_INTERNAL"] = 13;
        values[valuesById[14] = "CALLSTATUS_UNAVAILABLE"] = 14;
        values[valuesById[15] = "CALLSTATUS_DATA_LOSS"] = 15;
        values[valuesById[16] = "CALLSTATUS_UNAUTHENTICATED"] = 16;
        return values;
    })();

    /**
     * ErrorType enum.
     * @name cat.ErrorType
     * @enum {number}
     * @property {number} ERROR_UNKNOWN=0 ERROR_UNKNOWN value
     * @property {number} ERROR_INVALID_CREDENTIALS=1 ERROR_INVALID_CREDENTIALS value
     * @property {number} ERROR_MISSING_ARGUMENT=2 ERROR_MISSING_ARGUMENT value
     * @property {number} ERROR_INVALID_SESSION=3 ERROR_INVALID_SESSION value
     * @property {number} ERROR_PERMISSION_DENIED=4 ERROR_PERMISSION_DENIED value
     * @property {number} ERROR_DATABASE_ERROR=5 ERROR_DATABASE_ERROR value
     * @property {number} ERROR_MISSING_ROLE=6 ERROR_MISSING_ROLE value
     * @property {number} ERROR_ROLE_IN_USE=7 ERROR_ROLE_IN_USE value
     * @property {number} ERROR_THEME_IN_USE=8 ERROR_THEME_IN_USE value
     * @property {number} ERROR_DEVICE_IN_USE=9 ERROR_DEVICE_IN_USE value
     * @property {number} ERROR_DEVICE_REQUIREMENTS=10 ERROR_DEVICE_REQUIREMENTS value
     * @property {number} ERROR_APP_IN_USE=11 ERROR_APP_IN_USE value
     * @property {number} ERROR_APP_ALREADY_EXISTS=12 ERROR_APP_ALREADY_EXISTS value
     * @property {number} ERROR_APP_REQUIREMENTS=13 ERROR_APP_REQUIREMENTS value
     * @property {number} ERROR_DATA_ACCESS=14 ERROR_DATA_ACCESS value
     * @property {number} ERROR_ALREADY_EXISTS=15 ERROR_ALREADY_EXISTS value
     * @property {number} ERROR_INVALID_ROLE=16 ERROR_INVALID_ROLE value
     * @property {number} ERROR_ROLE_IS_READONLY=17 ERROR_ROLE_IS_READONLY value
     * @property {number} ERROR_PASSWORD_EXPIRED=18 ERROR_PASSWORD_EXPIRED value
     * @property {number} ERROR_PROFILE_IN_USE=19 ERROR_PROFILE_IN_USE value
     * @property {number} ERROR_PERMISSION_IS_REQUIRED=20 ERROR_PERMISSION_IS_REQUIRED value
     * @property {number} ERROR_CASE_IN_USE=21 ERROR_CASE_IN_USE value
     * @property {number} ERROR_PROFILE_NOT_LICENSED=22 ERROR_PROFILE_NOT_LICENSED value
     * @property {number} ERROR_INVALID_LICENSE=23 ERROR_INVALID_LICENSE value
     * @property {number} ERROR_IMAGE_WRONG_ASPECT_RATIO=26 ERROR_IMAGE_WRONG_ASPECT_RATIO value
     * @property {number} ERROR_DEVICE_NOT_AVAILABLE=27 ERROR_DEVICE_NOT_AVAILABLE value
     * @property {number} ERROR_DEVICE_ALREADY_LOCKED=28 ERROR_DEVICE_ALREADY_LOCKED value
     * @property {number} ERROR_DEVICE_NOT_LOCKED=29 ERROR_DEVICE_NOT_LOCKED value
     * @property {number} ERROR_USERNAME_OCCUPIED=30 ERROR_USERNAME_OCCUPIED value
     * @property {number} ERROR_FIRSTNAME_EMPTY=31 ERROR_FIRSTNAME_EMPTY value
     * @property {number} ERROR_FULLNAME_NOT_SET=33 ERROR_FULLNAME_NOT_SET value
     * @property {number} ERROR_BIO_NOT_SET=34 ERROR_BIO_NOT_SET value
     * @property {number} ERROR_WEBSITE_NOT_SET=35 ERROR_WEBSITE_NOT_SET value
     * @property {number} ERROR_IMAGE_UNSUPPORTED_FORMAT=37 ERROR_IMAGE_UNSUPPORTED_FORMAT value
     * @property {number} ERROR_IMAGE_TOO_LARGE=38 ERROR_IMAGE_TOO_LARGE value
     * @property {number} ERROR_CHANGE_FRESH_PHONENUMBER=39 ERROR_CHANGE_FRESH_PHONENUMBER value
     * @property {number} ERROR_MISSING_CONFIGURATION=40 ERROR_MISSING_CONFIGURATION value
     * @property {number} ERROR_INVALID_CALL=41 ERROR_INVALID_CALL value
     * @property {number} ERROR_APP_ALREADY_ASSIGNED=42 ERROR_APP_ALREADY_ASSIGNED value
     * @property {number} ERROR_ROLE_CHANGE_DENIED_PROFILES=43 ERROR_ROLE_CHANGE_DENIED_PROFILES value
     * @property {number} ERROR_DELETE_PROFILE_HAS_CASES=44 ERROR_DELETE_PROFILE_HAS_CASES value
     * @property {number} ERROR_DELETE_PROFILE_HAS_ACCOUNTS=45 ERROR_DELETE_PROFILE_HAS_ACCOUNTS value
     * @property {number} ERROR_DELETE_PROFILE_HAS_REPORTS=46 ERROR_DELETE_PROFILE_HAS_REPORTS value
     * @property {number} ERROR_DELETE_PROFILE_HAS_MEDIA=47 ERROR_DELETE_PROFILE_HAS_MEDIA value
     * @property {number} ERROR_KEYCLOAK_NO_ROLES=48 ERROR_KEYCLOAK_NO_ROLES value
     * @property {number} ERROR_INVALID_REPORT_JSON=50 ERROR_INVALID_REPORT_JSON value
     * @property {number} ERROR_REPORT_TEMPLATE_NOT_FOUND=51 ERROR_REPORT_TEMPLATE_NOT_FOUND value
     * @property {number} ERROR_CANNOT_DISABLE_SELF=52 ERROR_CANNOT_DISABLE_SELF value
     * @property {number} ERROR_INVALID_FILTER=53 ERROR_INVALID_FILTER value
     * @property {number} ERROR_DELETE_USER_HAS_CASES=54 ERROR_DELETE_USER_HAS_CASES value
     * @property {number} ERROR_DELETE_USER_HAS_USERS=55 ERROR_DELETE_USER_HAS_USERS value
     * @property {number} ERROR_DELETE_USER_HAS_PROFILES=56 ERROR_DELETE_USER_HAS_PROFILES value
     * @property {number} ERROR_DOMAIN_IN_USE=57 ERROR_DOMAIN_IN_USE value
     * @property {number} ERROR_PASSWORD_IDENTICAL=58 ERROR_PASSWORD_IDENTICAL value
     */
    cat.ErrorType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ERROR_UNKNOWN"] = 0;
        values[valuesById[1] = "ERROR_INVALID_CREDENTIALS"] = 1;
        values[valuesById[2] = "ERROR_MISSING_ARGUMENT"] = 2;
        values[valuesById[3] = "ERROR_INVALID_SESSION"] = 3;
        values[valuesById[4] = "ERROR_PERMISSION_DENIED"] = 4;
        values[valuesById[5] = "ERROR_DATABASE_ERROR"] = 5;
        values[valuesById[6] = "ERROR_MISSING_ROLE"] = 6;
        values[valuesById[7] = "ERROR_ROLE_IN_USE"] = 7;
        values[valuesById[8] = "ERROR_THEME_IN_USE"] = 8;
        values[valuesById[9] = "ERROR_DEVICE_IN_USE"] = 9;
        values[valuesById[10] = "ERROR_DEVICE_REQUIREMENTS"] = 10;
        values[valuesById[11] = "ERROR_APP_IN_USE"] = 11;
        values[valuesById[12] = "ERROR_APP_ALREADY_EXISTS"] = 12;
        values[valuesById[13] = "ERROR_APP_REQUIREMENTS"] = 13;
        values[valuesById[14] = "ERROR_DATA_ACCESS"] = 14;
        values[valuesById[15] = "ERROR_ALREADY_EXISTS"] = 15;
        values[valuesById[16] = "ERROR_INVALID_ROLE"] = 16;
        values[valuesById[17] = "ERROR_ROLE_IS_READONLY"] = 17;
        values[valuesById[18] = "ERROR_PASSWORD_EXPIRED"] = 18;
        values[valuesById[19] = "ERROR_PROFILE_IN_USE"] = 19;
        values[valuesById[20] = "ERROR_PERMISSION_IS_REQUIRED"] = 20;
        values[valuesById[21] = "ERROR_CASE_IN_USE"] = 21;
        values[valuesById[22] = "ERROR_PROFILE_NOT_LICENSED"] = 22;
        values[valuesById[23] = "ERROR_INVALID_LICENSE"] = 23;
        values[valuesById[26] = "ERROR_IMAGE_WRONG_ASPECT_RATIO"] = 26;
        values[valuesById[27] = "ERROR_DEVICE_NOT_AVAILABLE"] = 27;
        values[valuesById[28] = "ERROR_DEVICE_ALREADY_LOCKED"] = 28;
        values[valuesById[29] = "ERROR_DEVICE_NOT_LOCKED"] = 29;
        values[valuesById[30] = "ERROR_USERNAME_OCCUPIED"] = 30;
        values[valuesById[31] = "ERROR_FIRSTNAME_EMPTY"] = 31;
        values[valuesById[33] = "ERROR_FULLNAME_NOT_SET"] = 33;
        values[valuesById[34] = "ERROR_BIO_NOT_SET"] = 34;
        values[valuesById[35] = "ERROR_WEBSITE_NOT_SET"] = 35;
        values[valuesById[37] = "ERROR_IMAGE_UNSUPPORTED_FORMAT"] = 37;
        values[valuesById[38] = "ERROR_IMAGE_TOO_LARGE"] = 38;
        values[valuesById[39] = "ERROR_CHANGE_FRESH_PHONENUMBER"] = 39;
        values[valuesById[40] = "ERROR_MISSING_CONFIGURATION"] = 40;
        values[valuesById[41] = "ERROR_INVALID_CALL"] = 41;
        values[valuesById[42] = "ERROR_APP_ALREADY_ASSIGNED"] = 42;
        values[valuesById[43] = "ERROR_ROLE_CHANGE_DENIED_PROFILES"] = 43;
        values[valuesById[44] = "ERROR_DELETE_PROFILE_HAS_CASES"] = 44;
        values[valuesById[45] = "ERROR_DELETE_PROFILE_HAS_ACCOUNTS"] = 45;
        values[valuesById[46] = "ERROR_DELETE_PROFILE_HAS_REPORTS"] = 46;
        values[valuesById[47] = "ERROR_DELETE_PROFILE_HAS_MEDIA"] = 47;
        values[valuesById[48] = "ERROR_KEYCLOAK_NO_ROLES"] = 48;
        values[valuesById[50] = "ERROR_INVALID_REPORT_JSON"] = 50;
        values[valuesById[51] = "ERROR_REPORT_TEMPLATE_NOT_FOUND"] = 51;
        values[valuesById[52] = "ERROR_CANNOT_DISABLE_SELF"] = 52;
        values[valuesById[53] = "ERROR_INVALID_FILTER"] = 53;
        values[valuesById[54] = "ERROR_DELETE_USER_HAS_CASES"] = 54;
        values[valuesById[55] = "ERROR_DELETE_USER_HAS_USERS"] = 55;
        values[valuesById[56] = "ERROR_DELETE_USER_HAS_PROFILES"] = 56;
        values[valuesById[57] = "ERROR_DOMAIN_IN_USE"] = 57;
        values[valuesById[58] = "ERROR_PASSWORD_IDENTICAL"] = 58;
        return values;
    })();

    cat.UserSessionMsg = (function() {

        /**
         * Properties of a UserSessionMsg.
         * @memberof cat
         * @interface IUserSessionMsg
         * @property {string|null} [token] UserSessionMsg token
         * @property {string|null} [username] UserSessionMsg username
         * @property {string|null} [caseid] UserSessionMsg caseid
         * @property {string|null} [profileid] UserSessionMsg profileid
         * @property {number|null} [expires] UserSessionMsg expires
         * @property {string|null} [userid] UserSessionMsg userid
         * @property {string|null} [roleid] UserSessionMsg roleid
         * @property {string|null} [debuglog] UserSessionMsg debuglog
         * @property {string|null} [deviceid] UserSessionMsg deviceid
         * @property {string|null} [keycloaktoken] UserSessionMsg keycloaktoken
         */

        /**
         * Constructs a new UserSessionMsg.
         * @memberof cat
         * @classdesc Represents a UserSessionMsg.
         * @implements IUserSessionMsg
         * @constructor
         * @param {cat.IUserSessionMsg=} [p] Properties to set
         */
        function UserSessionMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserSessionMsg token.
         * @member {string} token
         * @memberof cat.UserSessionMsg
         * @instance
         */
        UserSessionMsg.prototype.token = "";

        /**
         * UserSessionMsg username.
         * @member {string} username
         * @memberof cat.UserSessionMsg
         * @instance
         */
        UserSessionMsg.prototype.username = "";

        /**
         * UserSessionMsg caseid.
         * @member {string} caseid
         * @memberof cat.UserSessionMsg
         * @instance
         */
        UserSessionMsg.prototype.caseid = "";

        /**
         * UserSessionMsg profileid.
         * @member {string} profileid
         * @memberof cat.UserSessionMsg
         * @instance
         */
        UserSessionMsg.prototype.profileid = "";

        /**
         * UserSessionMsg expires.
         * @member {number} expires
         * @memberof cat.UserSessionMsg
         * @instance
         */
        UserSessionMsg.prototype.expires = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UserSessionMsg userid.
         * @member {string} userid
         * @memberof cat.UserSessionMsg
         * @instance
         */
        UserSessionMsg.prototype.userid = "";

        /**
         * UserSessionMsg roleid.
         * @member {string} roleid
         * @memberof cat.UserSessionMsg
         * @instance
         */
        UserSessionMsg.prototype.roleid = "";

        /**
         * UserSessionMsg debuglog.
         * @member {string} debuglog
         * @memberof cat.UserSessionMsg
         * @instance
         */
        UserSessionMsg.prototype.debuglog = "";

        /**
         * UserSessionMsg deviceid.
         * @member {string} deviceid
         * @memberof cat.UserSessionMsg
         * @instance
         */
        UserSessionMsg.prototype.deviceid = "";

        /**
         * UserSessionMsg keycloaktoken.
         * @member {string|null|undefined} keycloaktoken
         * @memberof cat.UserSessionMsg
         * @instance
         */
        UserSessionMsg.prototype.keycloaktoken = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(UserSessionMsg.prototype, "_keycloaktoken", {
            get: $util.oneOfGetter($oneOfFields = ["keycloaktoken"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UserSessionMsg instance using the specified properties.
         * @function create
         * @memberof cat.UserSessionMsg
         * @static
         * @param {cat.IUserSessionMsg=} [properties] Properties to set
         * @returns {cat.UserSessionMsg} UserSessionMsg instance
         */
        UserSessionMsg.create = function create(properties) {
            return new UserSessionMsg(properties);
        };

        /**
         * Encodes the specified UserSessionMsg message. Does not implicitly {@link cat.UserSessionMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.UserSessionMsg
         * @static
         * @param {cat.IUserSessionMsg} m UserSessionMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSessionMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                w.uint32(10).string(m.token);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(18).string(m.username);
            if (m.caseid != null && Object.hasOwnProperty.call(m, "caseid"))
                w.uint32(34).string(m.caseid);
            if (m.profileid != null && Object.hasOwnProperty.call(m, "profileid"))
                w.uint32(42).string(m.profileid);
            if (m.expires != null && Object.hasOwnProperty.call(m, "expires"))
                w.uint32(88).uint64(m.expires);
            if (m.userid != null && Object.hasOwnProperty.call(m, "userid"))
                w.uint32(98).string(m.userid);
            if (m.roleid != null && Object.hasOwnProperty.call(m, "roleid"))
                w.uint32(106).string(m.roleid);
            if (m.debuglog != null && Object.hasOwnProperty.call(m, "debuglog"))
                w.uint32(114).string(m.debuglog);
            if (m.deviceid != null && Object.hasOwnProperty.call(m, "deviceid"))
                w.uint32(122).string(m.deviceid);
            if (m.keycloaktoken != null && Object.hasOwnProperty.call(m, "keycloaktoken"))
                w.uint32(130).string(m.keycloaktoken);
            return w;
        };

        /**
         * Decodes a UserSessionMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.UserSessionMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.UserSessionMsg} UserSessionMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSessionMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.UserSessionMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.token = r.string();
                        break;
                    }
                case 2: {
                        m.username = r.string();
                        break;
                    }
                case 4: {
                        m.caseid = r.string();
                        break;
                    }
                case 5: {
                        m.profileid = r.string();
                        break;
                    }
                case 11: {
                        m.expires = r.uint64();
                        break;
                    }
                case 12: {
                        m.userid = r.string();
                        break;
                    }
                case 13: {
                        m.roleid = r.string();
                        break;
                    }
                case 14: {
                        m.debuglog = r.string();
                        break;
                    }
                case 15: {
                        m.deviceid = r.string();
                        break;
                    }
                case 16: {
                        m.keycloaktoken = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserSessionMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.UserSessionMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.UserSessionMsg} UserSessionMsg
         */
        UserSessionMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.UserSessionMsg)
                return d;
            var m = new $root.cat.UserSessionMsg();
            if (d.token != null) {
                m.token = String(d.token);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.caseid != null) {
                m.caseid = String(d.caseid);
            }
            if (d.profileid != null) {
                m.profileid = String(d.profileid);
            }
            if (d.expires != null) {
                if ($util.Long)
                    (m.expires = $util.Long.fromValue(d.expires)).unsigned = true;
                else if (typeof d.expires === "string")
                    m.expires = parseInt(d.expires, 10);
                else if (typeof d.expires === "number")
                    m.expires = d.expires;
                else if (typeof d.expires === "object")
                    m.expires = new $util.LongBits(d.expires.low >>> 0, d.expires.high >>> 0).toNumber(true);
            }
            if (d.userid != null) {
                m.userid = String(d.userid);
            }
            if (d.roleid != null) {
                m.roleid = String(d.roleid);
            }
            if (d.debuglog != null) {
                m.debuglog = String(d.debuglog);
            }
            if (d.deviceid != null) {
                m.deviceid = String(d.deviceid);
            }
            if (d.keycloaktoken != null) {
                m.keycloaktoken = String(d.keycloaktoken);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserSessionMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.UserSessionMsg
         * @static
         * @param {cat.UserSessionMsg} m UserSessionMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSessionMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.token = "";
                d.username = "";
                d.caseid = "";
                d.profileid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.expires = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.expires = o.longs === String ? "0" : 0;
                d.userid = "";
                d.roleid = "";
                d.debuglog = "";
                d.deviceid = "";
            }
            if (m.token != null && m.hasOwnProperty("token")) {
                d.token = m.token;
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
            }
            if (m.caseid != null && m.hasOwnProperty("caseid")) {
                d.caseid = m.caseid;
            }
            if (m.profileid != null && m.hasOwnProperty("profileid")) {
                d.profileid = m.profileid;
            }
            if (m.expires != null && m.hasOwnProperty("expires")) {
                if (typeof m.expires === "number")
                    d.expires = o.longs === String ? String(m.expires) : m.expires;
                else
                    d.expires = o.longs === String ? $util.Long.prototype.toString.call(m.expires) : o.longs === Number ? new $util.LongBits(m.expires.low >>> 0, m.expires.high >>> 0).toNumber(true) : m.expires;
            }
            if (m.userid != null && m.hasOwnProperty("userid")) {
                d.userid = m.userid;
            }
            if (m.roleid != null && m.hasOwnProperty("roleid")) {
                d.roleid = m.roleid;
            }
            if (m.debuglog != null && m.hasOwnProperty("debuglog")) {
                d.debuglog = m.debuglog;
            }
            if (m.deviceid != null && m.hasOwnProperty("deviceid")) {
                d.deviceid = m.deviceid;
            }
            if (m.keycloaktoken != null && m.hasOwnProperty("keycloaktoken")) {
                d.keycloaktoken = m.keycloaktoken;
                if (o.oneofs)
                    d._keycloaktoken = "keycloaktoken";
            }
            return d;
        };

        /**
         * Converts this UserSessionMsg to JSON.
         * @function toJSON
         * @memberof cat.UserSessionMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSessionMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserSessionMsg
         * @function getTypeUrl
         * @memberof cat.UserSessionMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserSessionMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.UserSessionMsg";
        };

        return UserSessionMsg;
    })();

    cat.VoidMsg = (function() {

        /**
         * Properties of a VoidMsg.
         * @memberof cat
         * @interface IVoidMsg
         */

        /**
         * Constructs a new VoidMsg.
         * @memberof cat
         * @classdesc Represents a VoidMsg.
         * @implements IVoidMsg
         * @constructor
         * @param {cat.IVoidMsg=} [p] Properties to set
         */
        function VoidMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Creates a new VoidMsg instance using the specified properties.
         * @function create
         * @memberof cat.VoidMsg
         * @static
         * @param {cat.IVoidMsg=} [properties] Properties to set
         * @returns {cat.VoidMsg} VoidMsg instance
         */
        VoidMsg.create = function create(properties) {
            return new VoidMsg(properties);
        };

        /**
         * Encodes the specified VoidMsg message. Does not implicitly {@link cat.VoidMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.VoidMsg
         * @static
         * @param {cat.IVoidMsg} m VoidMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VoidMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            return w;
        };

        /**
         * Decodes a VoidMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.VoidMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.VoidMsg} VoidMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VoidMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.VoidMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a VoidMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.VoidMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.VoidMsg} VoidMsg
         */
        VoidMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.VoidMsg)
                return d;
            return new $root.cat.VoidMsg();
        };

        /**
         * Creates a plain object from a VoidMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.VoidMsg
         * @static
         * @param {cat.VoidMsg} m VoidMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VoidMsg.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this VoidMsg to JSON.
         * @function toJSON
         * @memberof cat.VoidMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VoidMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VoidMsg
         * @function getTypeUrl
         * @memberof cat.VoidMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VoidMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.VoidMsg";
        };

        return VoidMsg;
    })();

    /**
     * SortDirection enum.
     * @name cat.SortDirection
     * @enum {number}
     * @property {number} SORT_NONE=0 SORT_NONE value
     * @property {number} SORT_ASCENDING=1 SORT_ASCENDING value
     * @property {number} SORT_DESCENDING=2 SORT_DESCENDING value
     */
    cat.SortDirection = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SORT_NONE"] = 0;
        values[valuesById[1] = "SORT_ASCENDING"] = 1;
        values[valuesById[2] = "SORT_DESCENDING"] = 2;
        return values;
    })();

    /**
     * FilterOperator enum.
     * @name cat.FilterOperator
     * @enum {number}
     * @property {number} FILTER_UNKNOWN=0 FILTER_UNKNOWN value
     * @property {number} FILTER_INCLUDE=1 FILTER_INCLUDE value
     * @property {number} FILTER_EXCLUDE=2 FILTER_EXCLUDE value
     */
    cat.FilterOperator = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "FILTER_UNKNOWN"] = 0;
        values[valuesById[1] = "FILTER_INCLUDE"] = 1;
        values[valuesById[2] = "FILTER_EXCLUDE"] = 2;
        return values;
    })();

    cat.FilterMsg = (function() {

        /**
         * Properties of a FilterMsg.
         * @memberof cat
         * @interface IFilterMsg
         * @property {string|null} [collection] FilterMsg collection
         * @property {cat.FilterOperator|null} [operator] FilterMsg operator
         * @property {string|null} [field] FilterMsg field
         * @property {string|null} [value] FilterMsg value
         * @property {Array.<string>|null} [values] FilterMsg values
         */

        /**
         * Constructs a new FilterMsg.
         * @memberof cat
         * @classdesc Represents a FilterMsg.
         * @implements IFilterMsg
         * @constructor
         * @param {cat.IFilterMsg=} [p] Properties to set
         */
        function FilterMsg(p) {
            this.values = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FilterMsg collection.
         * @member {string} collection
         * @memberof cat.FilterMsg
         * @instance
         */
        FilterMsg.prototype.collection = "";

        /**
         * FilterMsg operator.
         * @member {cat.FilterOperator} operator
         * @memberof cat.FilterMsg
         * @instance
         */
        FilterMsg.prototype.operator = 0;

        /**
         * FilterMsg field.
         * @member {string} field
         * @memberof cat.FilterMsg
         * @instance
         */
        FilterMsg.prototype.field = "";

        /**
         * FilterMsg value.
         * @member {string} value
         * @memberof cat.FilterMsg
         * @instance
         */
        FilterMsg.prototype.value = "";

        /**
         * FilterMsg values.
         * @member {Array.<string>} values
         * @memberof cat.FilterMsg
         * @instance
         */
        FilterMsg.prototype.values = $util.emptyArray;

        /**
         * Creates a new FilterMsg instance using the specified properties.
         * @function create
         * @memberof cat.FilterMsg
         * @static
         * @param {cat.IFilterMsg=} [properties] Properties to set
         * @returns {cat.FilterMsg} FilterMsg instance
         */
        FilterMsg.create = function create(properties) {
            return new FilterMsg(properties);
        };

        /**
         * Encodes the specified FilterMsg message. Does not implicitly {@link cat.FilterMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.FilterMsg
         * @static
         * @param {cat.IFilterMsg} m FilterMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FilterMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.collection != null && Object.hasOwnProperty.call(m, "collection"))
                w.uint32(10).string(m.collection);
            if (m.operator != null && Object.hasOwnProperty.call(m, "operator"))
                w.uint32(16).int32(m.operator);
            if (m.field != null && Object.hasOwnProperty.call(m, "field"))
                w.uint32(26).string(m.field);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(34).string(m.value);
            if (m.values != null && m.values.length) {
                for (var i = 0; i < m.values.length; ++i)
                    w.uint32(42).string(m.values[i]);
            }
            return w;
        };

        /**
         * Decodes a FilterMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.FilterMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.FilterMsg} FilterMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FilterMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.FilterMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.collection = r.string();
                        break;
                    }
                case 2: {
                        m.operator = r.int32();
                        break;
                    }
                case 3: {
                        m.field = r.string();
                        break;
                    }
                case 4: {
                        m.value = r.string();
                        break;
                    }
                case 5: {
                        if (!(m.values && m.values.length))
                            m.values = [];
                        m.values.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a FilterMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.FilterMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.FilterMsg} FilterMsg
         */
        FilterMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.FilterMsg)
                return d;
            var m = new $root.cat.FilterMsg();
            if (d.collection != null) {
                m.collection = String(d.collection);
            }
            switch (d.operator) {
            default:
                if (typeof d.operator === "number") {
                    m.operator = d.operator;
                    break;
                }
                break;
            case "FILTER_UNKNOWN":
            case 0:
                m.operator = 0;
                break;
            case "FILTER_INCLUDE":
            case 1:
                m.operator = 1;
                break;
            case "FILTER_EXCLUDE":
            case 2:
                m.operator = 2;
                break;
            }
            if (d.field != null) {
                m.field = String(d.field);
            }
            if (d.value != null) {
                m.value = String(d.value);
            }
            if (d.values) {
                if (!Array.isArray(d.values))
                    throw TypeError(".cat.FilterMsg.values: array expected");
                m.values = [];
                for (var i = 0; i < d.values.length; ++i) {
                    m.values[i] = String(d.values[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a FilterMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.FilterMsg
         * @static
         * @param {cat.FilterMsg} m FilterMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FilterMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.values = [];
            }
            if (o.defaults) {
                d.collection = "";
                d.operator = o.enums === String ? "FILTER_UNKNOWN" : 0;
                d.field = "";
                d.value = "";
            }
            if (m.collection != null && m.hasOwnProperty("collection")) {
                d.collection = m.collection;
            }
            if (m.operator != null && m.hasOwnProperty("operator")) {
                d.operator = o.enums === String ? $root.cat.FilterOperator[m.operator] === undefined ? m.operator : $root.cat.FilterOperator[m.operator] : m.operator;
            }
            if (m.field != null && m.hasOwnProperty("field")) {
                d.field = m.field;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            if (m.values && m.values.length) {
                d.values = [];
                for (var j = 0; j < m.values.length; ++j) {
                    d.values[j] = m.values[j];
                }
            }
            return d;
        };

        /**
         * Converts this FilterMsg to JSON.
         * @function toJSON
         * @memberof cat.FilterMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FilterMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FilterMsg
         * @function getTypeUrl
         * @memberof cat.FilterMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FilterMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.FilterMsg";
        };

        return FilterMsg;
    })();

    cat.QueryMsg = (function() {

        /**
         * Properties of a QueryMsg.
         * @memberof cat
         * @interface IQueryMsg
         * @property {number|null} [offset] QueryMsg offset
         * @property {number|null} [count] QueryMsg count
         * @property {cat.SortDirection|null} [sortdirection] QueryMsg sortdirection
         * @property {Array.<string>|null} [sortfields] QueryMsg sortfields
         * @property {string|null} [search] QueryMsg search
         * @property {Array.<cat.IFilterMsg>|null} [filters] QueryMsg filters
         * @property {Object.<string,string>|null} [references] QueryMsg references
         * @property {boolean|null} [flag] QueryMsg flag
         */

        /**
         * Constructs a new QueryMsg.
         * @memberof cat
         * @classdesc Represents a QueryMsg.
         * @implements IQueryMsg
         * @constructor
         * @param {cat.IQueryMsg=} [p] Properties to set
         */
        function QueryMsg(p) {
            this.sortfields = [];
            this.filters = [];
            this.references = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QueryMsg offset.
         * @member {number} offset
         * @memberof cat.QueryMsg
         * @instance
         */
        QueryMsg.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * QueryMsg count.
         * @member {number} count
         * @memberof cat.QueryMsg
         * @instance
         */
        QueryMsg.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * QueryMsg sortdirection.
         * @member {cat.SortDirection} sortdirection
         * @memberof cat.QueryMsg
         * @instance
         */
        QueryMsg.prototype.sortdirection = 0;

        /**
         * QueryMsg sortfields.
         * @member {Array.<string>} sortfields
         * @memberof cat.QueryMsg
         * @instance
         */
        QueryMsg.prototype.sortfields = $util.emptyArray;

        /**
         * QueryMsg search.
         * @member {string} search
         * @memberof cat.QueryMsg
         * @instance
         */
        QueryMsg.prototype.search = "";

        /**
         * QueryMsg filters.
         * @member {Array.<cat.IFilterMsg>} filters
         * @memberof cat.QueryMsg
         * @instance
         */
        QueryMsg.prototype.filters = $util.emptyArray;

        /**
         * QueryMsg references.
         * @member {Object.<string,string>} references
         * @memberof cat.QueryMsg
         * @instance
         */
        QueryMsg.prototype.references = $util.emptyObject;

        /**
         * QueryMsg flag.
         * @member {boolean|null|undefined} flag
         * @memberof cat.QueryMsg
         * @instance
         */
        QueryMsg.prototype.flag = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(QueryMsg.prototype, "_flag", {
            get: $util.oneOfGetter($oneOfFields = ["flag"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new QueryMsg instance using the specified properties.
         * @function create
         * @memberof cat.QueryMsg
         * @static
         * @param {cat.IQueryMsg=} [properties] Properties to set
         * @returns {cat.QueryMsg} QueryMsg instance
         */
        QueryMsg.create = function create(properties) {
            return new QueryMsg(properties);
        };

        /**
         * Encodes the specified QueryMsg message. Does not implicitly {@link cat.QueryMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.QueryMsg
         * @static
         * @param {cat.IQueryMsg} m QueryMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
                w.uint32(8).uint64(m.offset);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(16).uint64(m.count);
            if (m.sortdirection != null && Object.hasOwnProperty.call(m, "sortdirection"))
                w.uint32(24).int32(m.sortdirection);
            if (m.sortfields != null && m.sortfields.length) {
                for (var i = 0; i < m.sortfields.length; ++i)
                    w.uint32(34).string(m.sortfields[i]);
            }
            if (m.search != null && Object.hasOwnProperty.call(m, "search"))
                w.uint32(42).string(m.search);
            if (m.filters != null && m.filters.length) {
                for (var i = 0; i < m.filters.length; ++i)
                    $root.cat.FilterMsg.encode(m.filters[i], w.uint32(50).fork()).ldelim();
            }
            if (m.references != null && Object.hasOwnProperty.call(m, "references")) {
                for (var ks = Object.keys(m.references), i = 0; i < ks.length; ++i) {
                    w.uint32(58).fork().uint32(8).uint32(ks[i]).uint32(18).string(m.references[ks[i]]).ldelim();
                }
            }
            if (m.flag != null && Object.hasOwnProperty.call(m, "flag"))
                w.uint32(64).bool(m.flag);
            return w;
        };

        /**
         * Decodes a QueryMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.QueryMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.QueryMsg} QueryMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.QueryMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.offset = r.uint64();
                        break;
                    }
                case 2: {
                        m.count = r.uint64();
                        break;
                    }
                case 3: {
                        m.sortdirection = r.int32();
                        break;
                    }
                case 4: {
                        if (!(m.sortfields && m.sortfields.length))
                            m.sortfields = [];
                        m.sortfields.push(r.string());
                        break;
                    }
                case 5: {
                        m.search = r.string();
                        break;
                    }
                case 6: {
                        if (!(m.filters && m.filters.length))
                            m.filters = [];
                        m.filters.push($root.cat.FilterMsg.decode(r, r.uint32()));
                        break;
                    }
                case 7: {
                        if (m.references === $util.emptyObject)
                            m.references = {};
                        var c2 = r.uint32() + r.pos;
                        k = 0;
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.uint32();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.references[k] = value;
                        break;
                    }
                case 8: {
                        m.flag = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a QueryMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.QueryMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.QueryMsg} QueryMsg
         */
        QueryMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.QueryMsg)
                return d;
            var m = new $root.cat.QueryMsg();
            if (d.offset != null) {
                if ($util.Long)
                    (m.offset = $util.Long.fromValue(d.offset)).unsigned = true;
                else if (typeof d.offset === "string")
                    m.offset = parseInt(d.offset, 10);
                else if (typeof d.offset === "number")
                    m.offset = d.offset;
                else if (typeof d.offset === "object")
                    m.offset = new $util.LongBits(d.offset.low >>> 0, d.offset.high >>> 0).toNumber(true);
            }
            if (d.count != null) {
                if ($util.Long)
                    (m.count = $util.Long.fromValue(d.count)).unsigned = true;
                else if (typeof d.count === "string")
                    m.count = parseInt(d.count, 10);
                else if (typeof d.count === "number")
                    m.count = d.count;
                else if (typeof d.count === "object")
                    m.count = new $util.LongBits(d.count.low >>> 0, d.count.high >>> 0).toNumber(true);
            }
            switch (d.sortdirection) {
            default:
                if (typeof d.sortdirection === "number") {
                    m.sortdirection = d.sortdirection;
                    break;
                }
                break;
            case "SORT_NONE":
            case 0:
                m.sortdirection = 0;
                break;
            case "SORT_ASCENDING":
            case 1:
                m.sortdirection = 1;
                break;
            case "SORT_DESCENDING":
            case 2:
                m.sortdirection = 2;
                break;
            }
            if (d.sortfields) {
                if (!Array.isArray(d.sortfields))
                    throw TypeError(".cat.QueryMsg.sortfields: array expected");
                m.sortfields = [];
                for (var i = 0; i < d.sortfields.length; ++i) {
                    m.sortfields[i] = String(d.sortfields[i]);
                }
            }
            if (d.search != null) {
                m.search = String(d.search);
            }
            if (d.filters) {
                if (!Array.isArray(d.filters))
                    throw TypeError(".cat.QueryMsg.filters: array expected");
                m.filters = [];
                for (var i = 0; i < d.filters.length; ++i) {
                    if (typeof d.filters[i] !== "object")
                        throw TypeError(".cat.QueryMsg.filters: object expected");
                    m.filters[i] = $root.cat.FilterMsg.fromObject(d.filters[i]);
                }
            }
            if (d.references) {
                if (typeof d.references !== "object")
                    throw TypeError(".cat.QueryMsg.references: object expected");
                m.references = {};
                for (var ks = Object.keys(d.references), i = 0; i < ks.length; ++i) {
                    m.references[ks[i]] = String(d.references[ks[i]]);
                }
            }
            if (d.flag != null) {
                m.flag = Boolean(d.flag);
            }
            return m;
        };

        /**
         * Creates a plain object from a QueryMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.QueryMsg
         * @static
         * @param {cat.QueryMsg} m QueryMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.sortfields = [];
                d.filters = [];
            }
            if (o.objects || o.defaults) {
                d.references = {};
            }
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.offset = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.offset = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.count = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.count = o.longs === String ? "0" : 0;
                d.sortdirection = o.enums === String ? "SORT_NONE" : 0;
                d.search = "";
            }
            if (m.offset != null && m.hasOwnProperty("offset")) {
                if (typeof m.offset === "number")
                    d.offset = o.longs === String ? String(m.offset) : m.offset;
                else
                    d.offset = o.longs === String ? $util.Long.prototype.toString.call(m.offset) : o.longs === Number ? new $util.LongBits(m.offset.low >>> 0, m.offset.high >>> 0).toNumber(true) : m.offset;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                if (typeof m.count === "number")
                    d.count = o.longs === String ? String(m.count) : m.count;
                else
                    d.count = o.longs === String ? $util.Long.prototype.toString.call(m.count) : o.longs === Number ? new $util.LongBits(m.count.low >>> 0, m.count.high >>> 0).toNumber(true) : m.count;
            }
            if (m.sortdirection != null && m.hasOwnProperty("sortdirection")) {
                d.sortdirection = o.enums === String ? $root.cat.SortDirection[m.sortdirection] === undefined ? m.sortdirection : $root.cat.SortDirection[m.sortdirection] : m.sortdirection;
            }
            if (m.sortfields && m.sortfields.length) {
                d.sortfields = [];
                for (var j = 0; j < m.sortfields.length; ++j) {
                    d.sortfields[j] = m.sortfields[j];
                }
            }
            if (m.search != null && m.hasOwnProperty("search")) {
                d.search = m.search;
            }
            if (m.filters && m.filters.length) {
                d.filters = [];
                for (var j = 0; j < m.filters.length; ++j) {
                    d.filters[j] = $root.cat.FilterMsg.toObject(m.filters[j], o);
                }
            }
            var ks2;
            if (m.references && (ks2 = Object.keys(m.references)).length) {
                d.references = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.references[ks2[j]] = m.references[ks2[j]];
                }
            }
            if (m.flag != null && m.hasOwnProperty("flag")) {
                d.flag = m.flag;
                if (o.oneofs)
                    d._flag = "flag";
            }
            return d;
        };

        /**
         * Converts this QueryMsg to JSON.
         * @function toJSON
         * @memberof cat.QueryMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QueryMsg
         * @function getTypeUrl
         * @memberof cat.QueryMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QueryMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.QueryMsg";
        };

        return QueryMsg;
    })();

    cat.FilterCountMsg = (function() {

        /**
         * Properties of a FilterCountMsg.
         * @memberof cat
         * @interface IFilterCountMsg
         * @property {Object.<string,number>|null} [count] FilterCountMsg count
         * @property {Object.<string,number>|null} [statecount] FilterCountMsg statecount
         * @property {Object.<string,number>|null} [typecount] FilterCountMsg typecount
         * @property {Object.<string,number>|null} [visibilitycount] FilterCountMsg visibilitycount
         * @property {number|null} [emptycount] FilterCountMsg emptycount
         */

        /**
         * Constructs a new FilterCountMsg.
         * @memberof cat
         * @classdesc Represents a FilterCountMsg.
         * @implements IFilterCountMsg
         * @constructor
         * @param {cat.IFilterCountMsg=} [p] Properties to set
         */
        function FilterCountMsg(p) {
            this.count = {};
            this.statecount = {};
            this.typecount = {};
            this.visibilitycount = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FilterCountMsg count.
         * @member {Object.<string,number>} count
         * @memberof cat.FilterCountMsg
         * @instance
         */
        FilterCountMsg.prototype.count = $util.emptyObject;

        /**
         * FilterCountMsg statecount.
         * @member {Object.<string,number>} statecount
         * @memberof cat.FilterCountMsg
         * @instance
         */
        FilterCountMsg.prototype.statecount = $util.emptyObject;

        /**
         * FilterCountMsg typecount.
         * @member {Object.<string,number>} typecount
         * @memberof cat.FilterCountMsg
         * @instance
         */
        FilterCountMsg.prototype.typecount = $util.emptyObject;

        /**
         * FilterCountMsg visibilitycount.
         * @member {Object.<string,number>} visibilitycount
         * @memberof cat.FilterCountMsg
         * @instance
         */
        FilterCountMsg.prototype.visibilitycount = $util.emptyObject;

        /**
         * FilterCountMsg emptycount.
         * @member {number} emptycount
         * @memberof cat.FilterCountMsg
         * @instance
         */
        FilterCountMsg.prototype.emptycount = 0;

        /**
         * Creates a new FilterCountMsg instance using the specified properties.
         * @function create
         * @memberof cat.FilterCountMsg
         * @static
         * @param {cat.IFilterCountMsg=} [properties] Properties to set
         * @returns {cat.FilterCountMsg} FilterCountMsg instance
         */
        FilterCountMsg.create = function create(properties) {
            return new FilterCountMsg(properties);
        };

        /**
         * Encodes the specified FilterCountMsg message. Does not implicitly {@link cat.FilterCountMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.FilterCountMsg
         * @static
         * @param {cat.IFilterCountMsg} m FilterCountMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FilterCountMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.count != null && Object.hasOwnProperty.call(m, "count")) {
                for (var ks = Object.keys(m.count), i = 0; i < ks.length; ++i) {
                    w.uint32(10).fork().uint32(8).uint32(ks[i]).uint32(16).uint32(m.count[ks[i]]).ldelim();
                }
            }
            if (m.statecount != null && Object.hasOwnProperty.call(m, "statecount")) {
                for (var ks = Object.keys(m.statecount), i = 0; i < ks.length; ++i) {
                    w.uint32(18).fork().uint32(8).uint32(ks[i]).uint32(16).uint32(m.statecount[ks[i]]).ldelim();
                }
            }
            if (m.typecount != null && Object.hasOwnProperty.call(m, "typecount")) {
                for (var ks = Object.keys(m.typecount), i = 0; i < ks.length; ++i) {
                    w.uint32(26).fork().uint32(8).uint32(ks[i]).uint32(16).uint32(m.typecount[ks[i]]).ldelim();
                }
            }
            if (m.visibilitycount != null && Object.hasOwnProperty.call(m, "visibilitycount")) {
                for (var ks = Object.keys(m.visibilitycount), i = 0; i < ks.length; ++i) {
                    w.uint32(34).fork().uint32(8).uint32(ks[i]).uint32(16).uint32(m.visibilitycount[ks[i]]).ldelim();
                }
            }
            if (m.emptycount != null && Object.hasOwnProperty.call(m, "emptycount"))
                w.uint32(40).uint32(m.emptycount);
            return w;
        };

        /**
         * Decodes a FilterCountMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.FilterCountMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.FilterCountMsg} FilterCountMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FilterCountMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.FilterCountMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (m.count === $util.emptyObject)
                            m.count = {};
                        var c2 = r.uint32() + r.pos;
                        k = 0;
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.uint32();
                                break;
                            case 2:
                                value = r.uint32();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.count[k] = value;
                        break;
                    }
                case 2: {
                        if (m.statecount === $util.emptyObject)
                            m.statecount = {};
                        var c2 = r.uint32() + r.pos;
                        k = 0;
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.uint32();
                                break;
                            case 2:
                                value = r.uint32();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.statecount[k] = value;
                        break;
                    }
                case 3: {
                        if (m.typecount === $util.emptyObject)
                            m.typecount = {};
                        var c2 = r.uint32() + r.pos;
                        k = 0;
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.uint32();
                                break;
                            case 2:
                                value = r.uint32();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.typecount[k] = value;
                        break;
                    }
                case 4: {
                        if (m.visibilitycount === $util.emptyObject)
                            m.visibilitycount = {};
                        var c2 = r.uint32() + r.pos;
                        k = 0;
                        value = 0;
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.uint32();
                                break;
                            case 2:
                                value = r.uint32();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.visibilitycount[k] = value;
                        break;
                    }
                case 5: {
                        m.emptycount = r.uint32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a FilterCountMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.FilterCountMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.FilterCountMsg} FilterCountMsg
         */
        FilterCountMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.FilterCountMsg)
                return d;
            var m = new $root.cat.FilterCountMsg();
            if (d.count) {
                if (typeof d.count !== "object")
                    throw TypeError(".cat.FilterCountMsg.count: object expected");
                m.count = {};
                for (var ks = Object.keys(d.count), i = 0; i < ks.length; ++i) {
                    m.count[ks[i]] = d.count[ks[i]] >>> 0;
                }
            }
            if (d.statecount) {
                if (typeof d.statecount !== "object")
                    throw TypeError(".cat.FilterCountMsg.statecount: object expected");
                m.statecount = {};
                for (var ks = Object.keys(d.statecount), i = 0; i < ks.length; ++i) {
                    m.statecount[ks[i]] = d.statecount[ks[i]] >>> 0;
                }
            }
            if (d.typecount) {
                if (typeof d.typecount !== "object")
                    throw TypeError(".cat.FilterCountMsg.typecount: object expected");
                m.typecount = {};
                for (var ks = Object.keys(d.typecount), i = 0; i < ks.length; ++i) {
                    m.typecount[ks[i]] = d.typecount[ks[i]] >>> 0;
                }
            }
            if (d.visibilitycount) {
                if (typeof d.visibilitycount !== "object")
                    throw TypeError(".cat.FilterCountMsg.visibilitycount: object expected");
                m.visibilitycount = {};
                for (var ks = Object.keys(d.visibilitycount), i = 0; i < ks.length; ++i) {
                    m.visibilitycount[ks[i]] = d.visibilitycount[ks[i]] >>> 0;
                }
            }
            if (d.emptycount != null) {
                m.emptycount = d.emptycount >>> 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a FilterCountMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.FilterCountMsg
         * @static
         * @param {cat.FilterCountMsg} m FilterCountMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FilterCountMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.objects || o.defaults) {
                d.count = {};
                d.statecount = {};
                d.typecount = {};
                d.visibilitycount = {};
            }
            if (o.defaults) {
                d.emptycount = 0;
            }
            var ks2;
            if (m.count && (ks2 = Object.keys(m.count)).length) {
                d.count = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.count[ks2[j]] = m.count[ks2[j]];
                }
            }
            if (m.statecount && (ks2 = Object.keys(m.statecount)).length) {
                d.statecount = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.statecount[ks2[j]] = m.statecount[ks2[j]];
                }
            }
            if (m.typecount && (ks2 = Object.keys(m.typecount)).length) {
                d.typecount = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.typecount[ks2[j]] = m.typecount[ks2[j]];
                }
            }
            if (m.visibilitycount && (ks2 = Object.keys(m.visibilitycount)).length) {
                d.visibilitycount = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.visibilitycount[ks2[j]] = m.visibilitycount[ks2[j]];
                }
            }
            if (m.emptycount != null && m.hasOwnProperty("emptycount")) {
                d.emptycount = m.emptycount;
            }
            return d;
        };

        /**
         * Converts this FilterCountMsg to JSON.
         * @function toJSON
         * @memberof cat.FilterCountMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FilterCountMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FilterCountMsg
         * @function getTypeUrl
         * @memberof cat.FilterCountMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FilterCountMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.FilterCountMsg";
        };

        return FilterCountMsg;
    })();

    cat.EulaMsg = (function() {

        /**
         * Properties of an EulaMsg.
         * @memberof cat
         * @interface IEulaMsg
         * @property {string|null} [name] EulaMsg name
         * @property {string|null} [description] EulaMsg description
         * @property {string|null} [language] EulaMsg language
         * @property {string|null} [body] EulaMsg body
         * @property {number|null} [validfrom] EulaMsg validfrom
         */

        /**
         * Constructs a new EulaMsg.
         * @memberof cat
         * @classdesc Represents an EulaMsg.
         * @implements IEulaMsg
         * @constructor
         * @param {cat.IEulaMsg=} [p] Properties to set
         */
        function EulaMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * EulaMsg name.
         * @member {string} name
         * @memberof cat.EulaMsg
         * @instance
         */
        EulaMsg.prototype.name = "";

        /**
         * EulaMsg description.
         * @member {string} description
         * @memberof cat.EulaMsg
         * @instance
         */
        EulaMsg.prototype.description = "";

        /**
         * EulaMsg language.
         * @member {string} language
         * @memberof cat.EulaMsg
         * @instance
         */
        EulaMsg.prototype.language = "";

        /**
         * EulaMsg body.
         * @member {string} body
         * @memberof cat.EulaMsg
         * @instance
         */
        EulaMsg.prototype.body = "";

        /**
         * EulaMsg validfrom.
         * @member {number} validfrom
         * @memberof cat.EulaMsg
         * @instance
         */
        EulaMsg.prototype.validfrom = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new EulaMsg instance using the specified properties.
         * @function create
         * @memberof cat.EulaMsg
         * @static
         * @param {cat.IEulaMsg=} [properties] Properties to set
         * @returns {cat.EulaMsg} EulaMsg instance
         */
        EulaMsg.create = function create(properties) {
            return new EulaMsg(properties);
        };

        /**
         * Encodes the specified EulaMsg message. Does not implicitly {@link cat.EulaMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.EulaMsg
         * @static
         * @param {cat.IEulaMsg} m EulaMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EulaMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(18).string(m.description);
            if (m.language != null && Object.hasOwnProperty.call(m, "language"))
                w.uint32(26).string(m.language);
            if (m.body != null && Object.hasOwnProperty.call(m, "body"))
                w.uint32(34).string(m.body);
            if (m.validfrom != null && Object.hasOwnProperty.call(m, "validfrom"))
                w.uint32(40).uint64(m.validfrom);
            return w;
        };

        /**
         * Decodes an EulaMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.EulaMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.EulaMsg} EulaMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EulaMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.EulaMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 2: {
                        m.description = r.string();
                        break;
                    }
                case 3: {
                        m.language = r.string();
                        break;
                    }
                case 4: {
                        m.body = r.string();
                        break;
                    }
                case 5: {
                        m.validfrom = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an EulaMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.EulaMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.EulaMsg} EulaMsg
         */
        EulaMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.EulaMsg)
                return d;
            var m = new $root.cat.EulaMsg();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.language != null) {
                m.language = String(d.language);
            }
            if (d.body != null) {
                m.body = String(d.body);
            }
            if (d.validfrom != null) {
                if ($util.Long)
                    (m.validfrom = $util.Long.fromValue(d.validfrom)).unsigned = true;
                else if (typeof d.validfrom === "string")
                    m.validfrom = parseInt(d.validfrom, 10);
                else if (typeof d.validfrom === "number")
                    m.validfrom = d.validfrom;
                else if (typeof d.validfrom === "object")
                    m.validfrom = new $util.LongBits(d.validfrom.low >>> 0, d.validfrom.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from an EulaMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.EulaMsg
         * @static
         * @param {cat.EulaMsg} m EulaMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EulaMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.name = "";
                d.description = "";
                d.language = "";
                d.body = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.validfrom = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.validfrom = o.longs === String ? "0" : 0;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.language != null && m.hasOwnProperty("language")) {
                d.language = m.language;
            }
            if (m.body != null && m.hasOwnProperty("body")) {
                d.body = m.body;
            }
            if (m.validfrom != null && m.hasOwnProperty("validfrom")) {
                if (typeof m.validfrom === "number")
                    d.validfrom = o.longs === String ? String(m.validfrom) : m.validfrom;
                else
                    d.validfrom = o.longs === String ? $util.Long.prototype.toString.call(m.validfrom) : o.longs === Number ? new $util.LongBits(m.validfrom.low >>> 0, m.validfrom.high >>> 0).toNumber(true) : m.validfrom;
            }
            return d;
        };

        /**
         * Converts this EulaMsg to JSON.
         * @function toJSON
         * @memberof cat.EulaMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EulaMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EulaMsg
         * @function getTypeUrl
         * @memberof cat.EulaMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EulaMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.EulaMsg";
        };

        return EulaMsg;
    })();

    /**
     * ServiceType enum.
     * @name cat.ServiceType
     * @enum {number}
     * @property {number} SERVICE_UNKNOWN=0 SERVICE_UNKNOWN value
     * @property {number} SERVICE_DATASTORE=1 SERVICE_DATASTORE value
     * @property {number} SERVICE_CONTROLLER=3 SERVICE_CONTROLLER value
     * @property {number} SERVICE_GATEWAY=4 SERVICE_GATEWAY value
     * @property {number} SERVICE_REPORTER=5 SERVICE_REPORTER value
     * @property {number} SERVICE_AUDITSTORE=7 SERVICE_AUDITSTORE value
     * @property {number} SERVICE_RECEIVER=12 SERVICE_RECEIVER value
     * @property {number} SERVICE_PROCESSOR=15 SERVICE_PROCESSOR value
     */
    cat.ServiceType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SERVICE_UNKNOWN"] = 0;
        values[valuesById[1] = "SERVICE_DATASTORE"] = 1;
        values[valuesById[3] = "SERVICE_CONTROLLER"] = 3;
        values[valuesById[4] = "SERVICE_GATEWAY"] = 4;
        values[valuesById[5] = "SERVICE_REPORTER"] = 5;
        values[valuesById[7] = "SERVICE_AUDITSTORE"] = 7;
        values[valuesById[12] = "SERVICE_RECEIVER"] = 12;
        values[valuesById[15] = "SERVICE_PROCESSOR"] = 15;
        return values;
    })();

    cat.ServiceStatusMsg = (function() {

        /**
         * Properties of a ServiceStatusMsg.
         * @memberof cat
         * @interface IServiceStatusMsg
         * @property {string|null} [name] ServiceStatusMsg name
         * @property {cat.ServiceType|null} [type] ServiceStatusMsg type
         * @property {boolean|null} [status] ServiceStatusMsg status
         * @property {number|null} [timestamp] ServiceStatusMsg timestamp
         * @property {cat.LogLevel|null} [level] ServiceStatusMsg level
         * @property {string|null} [refid] ServiceStatusMsg refid
         */

        /**
         * Constructs a new ServiceStatusMsg.
         * @memberof cat
         * @classdesc Represents a ServiceStatusMsg.
         * @implements IServiceStatusMsg
         * @constructor
         * @param {cat.IServiceStatusMsg=} [p] Properties to set
         */
        function ServiceStatusMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ServiceStatusMsg name.
         * @member {string} name
         * @memberof cat.ServiceStatusMsg
         * @instance
         */
        ServiceStatusMsg.prototype.name = "";

        /**
         * ServiceStatusMsg type.
         * @member {cat.ServiceType} type
         * @memberof cat.ServiceStatusMsg
         * @instance
         */
        ServiceStatusMsg.prototype.type = 0;

        /**
         * ServiceStatusMsg status.
         * @member {boolean} status
         * @memberof cat.ServiceStatusMsg
         * @instance
         */
        ServiceStatusMsg.prototype.status = false;

        /**
         * ServiceStatusMsg timestamp.
         * @member {number} timestamp
         * @memberof cat.ServiceStatusMsg
         * @instance
         */
        ServiceStatusMsg.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ServiceStatusMsg level.
         * @member {cat.LogLevel} level
         * @memberof cat.ServiceStatusMsg
         * @instance
         */
        ServiceStatusMsg.prototype.level = 0;

        /**
         * ServiceStatusMsg refid.
         * @member {string} refid
         * @memberof cat.ServiceStatusMsg
         * @instance
         */
        ServiceStatusMsg.prototype.refid = "";

        /**
         * Creates a new ServiceStatusMsg instance using the specified properties.
         * @function create
         * @memberof cat.ServiceStatusMsg
         * @static
         * @param {cat.IServiceStatusMsg=} [properties] Properties to set
         * @returns {cat.ServiceStatusMsg} ServiceStatusMsg instance
         */
        ServiceStatusMsg.create = function create(properties) {
            return new ServiceStatusMsg(properties);
        };

        /**
         * Encodes the specified ServiceStatusMsg message. Does not implicitly {@link cat.ServiceStatusMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ServiceStatusMsg
         * @static
         * @param {cat.IServiceStatusMsg} m ServiceStatusMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceStatusMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(24).bool(m.status);
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                w.uint32(32).uint64(m.timestamp);
            if (m.level != null && Object.hasOwnProperty.call(m, "level"))
                w.uint32(40).int32(m.level);
            if (m.refid != null && Object.hasOwnProperty.call(m, "refid"))
                w.uint32(50).string(m.refid);
            return w;
        };

        /**
         * Decodes a ServiceStatusMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ServiceStatusMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ServiceStatusMsg} ServiceStatusMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceStatusMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ServiceStatusMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 2: {
                        m.type = r.int32();
                        break;
                    }
                case 3: {
                        m.status = r.bool();
                        break;
                    }
                case 4: {
                        m.timestamp = r.uint64();
                        break;
                    }
                case 5: {
                        m.level = r.int32();
                        break;
                    }
                case 6: {
                        m.refid = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ServiceStatusMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ServiceStatusMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ServiceStatusMsg} ServiceStatusMsg
         */
        ServiceStatusMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ServiceStatusMsg)
                return d;
            var m = new $root.cat.ServiceStatusMsg();
            if (d.name != null) {
                m.name = String(d.name);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "SERVICE_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "SERVICE_DATASTORE":
            case 1:
                m.type = 1;
                break;
            case "SERVICE_CONTROLLER":
            case 3:
                m.type = 3;
                break;
            case "SERVICE_GATEWAY":
            case 4:
                m.type = 4;
                break;
            case "SERVICE_REPORTER":
            case 5:
                m.type = 5;
                break;
            case "SERVICE_AUDITSTORE":
            case 7:
                m.type = 7;
                break;
            case "SERVICE_RECEIVER":
            case 12:
                m.type = 12;
                break;
            case "SERVICE_PROCESSOR":
            case 15:
                m.type = 15;
                break;
            }
            if (d.status != null) {
                m.status = Boolean(d.status);
            }
            if (d.timestamp != null) {
                if ($util.Long)
                    (m.timestamp = $util.Long.fromValue(d.timestamp)).unsigned = true;
                else if (typeof d.timestamp === "string")
                    m.timestamp = parseInt(d.timestamp, 10);
                else if (typeof d.timestamp === "number")
                    m.timestamp = d.timestamp;
                else if (typeof d.timestamp === "object")
                    m.timestamp = new $util.LongBits(d.timestamp.low >>> 0, d.timestamp.high >>> 0).toNumber(true);
            }
            switch (d.level) {
            default:
                if (typeof d.level === "number") {
                    m.level = d.level;
                    break;
                }
                break;
            case "LOGLEVEL_UNKNOWN":
            case 0:
                m.level = 0;
                break;
            case "LOGLEVEL_ERROR":
            case 1:
                m.level = 1;
                break;
            case "LOGLEVEL_WARN":
            case 2:
                m.level = 2;
                break;
            case "LOGLEVEL_INFO":
            case 3:
                m.level = 3;
                break;
            case "LOGLEVEL_DEBUG":
            case 4:
                m.level = 4;
                break;
            case "LOGLEVEL_VERBOSE":
            case 5:
                m.level = 5;
                break;
            }
            if (d.refid != null) {
                m.refid = String(d.refid);
            }
            return m;
        };

        /**
         * Creates a plain object from a ServiceStatusMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ServiceStatusMsg
         * @static
         * @param {cat.ServiceStatusMsg} m ServiceStatusMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceStatusMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.name = "";
                d.type = o.enums === String ? "SERVICE_UNKNOWN" : 0;
                d.status = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timestamp = o.longs === String ? "0" : 0;
                d.level = o.enums === String ? "LOGLEVEL_UNKNOWN" : 0;
                d.refid = "";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.ServiceType[m.type] === undefined ? m.type : $root.cat.ServiceType[m.type] : m.type;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                if (typeof m.timestamp === "number")
                    d.timestamp = o.longs === String ? String(m.timestamp) : m.timestamp;
                else
                    d.timestamp = o.longs === String ? $util.Long.prototype.toString.call(m.timestamp) : o.longs === Number ? new $util.LongBits(m.timestamp.low >>> 0, m.timestamp.high >>> 0).toNumber(true) : m.timestamp;
            }
            if (m.level != null && m.hasOwnProperty("level")) {
                d.level = o.enums === String ? $root.cat.LogLevel[m.level] === undefined ? m.level : $root.cat.LogLevel[m.level] : m.level;
            }
            if (m.refid != null && m.hasOwnProperty("refid")) {
                d.refid = m.refid;
            }
            return d;
        };

        /**
         * Converts this ServiceStatusMsg to JSON.
         * @function toJSON
         * @memberof cat.ServiceStatusMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceStatusMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ServiceStatusMsg
         * @function getTypeUrl
         * @memberof cat.ServiceStatusMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ServiceStatusMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ServiceStatusMsg";
        };

        return ServiceStatusMsg;
    })();

    cat.ServiceMsg = (function() {

        /**
         * Properties of a ServiceMsg.
         * @memberof cat
         * @interface IServiceMsg
         * @property {string|null} [name] ServiceMsg name
         * @property {cat.ServiceType|null} [servicetype] ServiceMsg servicetype
         * @property {string|null} [ipaddress] ServiceMsg ipaddress
         * @property {number|null} [port] ServiceMsg port
         * @property {string|null} [version] ServiceMsg version
         * @property {string|null} [refid] ServiceMsg refid
         */

        /**
         * Constructs a new ServiceMsg.
         * @memberof cat
         * @classdesc Represents a ServiceMsg.
         * @implements IServiceMsg
         * @constructor
         * @param {cat.IServiceMsg=} [p] Properties to set
         */
        function ServiceMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ServiceMsg name.
         * @member {string} name
         * @memberof cat.ServiceMsg
         * @instance
         */
        ServiceMsg.prototype.name = "";

        /**
         * ServiceMsg servicetype.
         * @member {cat.ServiceType} servicetype
         * @memberof cat.ServiceMsg
         * @instance
         */
        ServiceMsg.prototype.servicetype = 0;

        /**
         * ServiceMsg ipaddress.
         * @member {string} ipaddress
         * @memberof cat.ServiceMsg
         * @instance
         */
        ServiceMsg.prototype.ipaddress = "";

        /**
         * ServiceMsg port.
         * @member {number} port
         * @memberof cat.ServiceMsg
         * @instance
         */
        ServiceMsg.prototype.port = 0;

        /**
         * ServiceMsg version.
         * @member {string} version
         * @memberof cat.ServiceMsg
         * @instance
         */
        ServiceMsg.prototype.version = "";

        /**
         * ServiceMsg refid.
         * @member {string} refid
         * @memberof cat.ServiceMsg
         * @instance
         */
        ServiceMsg.prototype.refid = "";

        /**
         * Creates a new ServiceMsg instance using the specified properties.
         * @function create
         * @memberof cat.ServiceMsg
         * @static
         * @param {cat.IServiceMsg=} [properties] Properties to set
         * @returns {cat.ServiceMsg} ServiceMsg instance
         */
        ServiceMsg.create = function create(properties) {
            return new ServiceMsg(properties);
        };

        /**
         * Encodes the specified ServiceMsg message. Does not implicitly {@link cat.ServiceMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ServiceMsg
         * @static
         * @param {cat.IServiceMsg} m ServiceMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.servicetype != null && Object.hasOwnProperty.call(m, "servicetype"))
                w.uint32(16).int32(m.servicetype);
            if (m.ipaddress != null && Object.hasOwnProperty.call(m, "ipaddress"))
                w.uint32(26).string(m.ipaddress);
            if (m.port != null && Object.hasOwnProperty.call(m, "port"))
                w.uint32(32).uint32(m.port);
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(42).string(m.version);
            if (m.refid != null && Object.hasOwnProperty.call(m, "refid"))
                w.uint32(50).string(m.refid);
            return w;
        };

        /**
         * Decodes a ServiceMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ServiceMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ServiceMsg} ServiceMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ServiceMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 2: {
                        m.servicetype = r.int32();
                        break;
                    }
                case 3: {
                        m.ipaddress = r.string();
                        break;
                    }
                case 4: {
                        m.port = r.uint32();
                        break;
                    }
                case 5: {
                        m.version = r.string();
                        break;
                    }
                case 6: {
                        m.refid = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ServiceMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ServiceMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ServiceMsg} ServiceMsg
         */
        ServiceMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ServiceMsg)
                return d;
            var m = new $root.cat.ServiceMsg();
            if (d.name != null) {
                m.name = String(d.name);
            }
            switch (d.servicetype) {
            default:
                if (typeof d.servicetype === "number") {
                    m.servicetype = d.servicetype;
                    break;
                }
                break;
            case "SERVICE_UNKNOWN":
            case 0:
                m.servicetype = 0;
                break;
            case "SERVICE_DATASTORE":
            case 1:
                m.servicetype = 1;
                break;
            case "SERVICE_CONTROLLER":
            case 3:
                m.servicetype = 3;
                break;
            case "SERVICE_GATEWAY":
            case 4:
                m.servicetype = 4;
                break;
            case "SERVICE_REPORTER":
            case 5:
                m.servicetype = 5;
                break;
            case "SERVICE_AUDITSTORE":
            case 7:
                m.servicetype = 7;
                break;
            case "SERVICE_RECEIVER":
            case 12:
                m.servicetype = 12;
                break;
            case "SERVICE_PROCESSOR":
            case 15:
                m.servicetype = 15;
                break;
            }
            if (d.ipaddress != null) {
                m.ipaddress = String(d.ipaddress);
            }
            if (d.port != null) {
                m.port = d.port >>> 0;
            }
            if (d.version != null) {
                m.version = String(d.version);
            }
            if (d.refid != null) {
                m.refid = String(d.refid);
            }
            return m;
        };

        /**
         * Creates a plain object from a ServiceMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ServiceMsg
         * @static
         * @param {cat.ServiceMsg} m ServiceMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.name = "";
                d.servicetype = o.enums === String ? "SERVICE_UNKNOWN" : 0;
                d.ipaddress = "";
                d.port = 0;
                d.version = "";
                d.refid = "";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.servicetype != null && m.hasOwnProperty("servicetype")) {
                d.servicetype = o.enums === String ? $root.cat.ServiceType[m.servicetype] === undefined ? m.servicetype : $root.cat.ServiceType[m.servicetype] : m.servicetype;
            }
            if (m.ipaddress != null && m.hasOwnProperty("ipaddress")) {
                d.ipaddress = m.ipaddress;
            }
            if (m.port != null && m.hasOwnProperty("port")) {
                d.port = m.port;
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                d.version = m.version;
            }
            if (m.refid != null && m.hasOwnProperty("refid")) {
                d.refid = m.refid;
            }
            return d;
        };

        /**
         * Converts this ServiceMsg to JSON.
         * @function toJSON
         * @memberof cat.ServiceMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ServiceMsg
         * @function getTypeUrl
         * @memberof cat.ServiceMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ServiceMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ServiceMsg";
        };

        return ServiceMsg;
    })();

    /**
     * LogLevel enum.
     * @name cat.LogLevel
     * @enum {number}
     * @property {number} LOGLEVEL_UNKNOWN=0 LOGLEVEL_UNKNOWN value
     * @property {number} LOGLEVEL_ERROR=1 LOGLEVEL_ERROR value
     * @property {number} LOGLEVEL_WARN=2 LOGLEVEL_WARN value
     * @property {number} LOGLEVEL_INFO=3 LOGLEVEL_INFO value
     * @property {number} LOGLEVEL_DEBUG=4 LOGLEVEL_DEBUG value
     * @property {number} LOGLEVEL_VERBOSE=5 LOGLEVEL_VERBOSE value
     */
    cat.LogLevel = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LOGLEVEL_UNKNOWN"] = 0;
        values[valuesById[1] = "LOGLEVEL_ERROR"] = 1;
        values[valuesById[2] = "LOGLEVEL_WARN"] = 2;
        values[valuesById[3] = "LOGLEVEL_INFO"] = 3;
        values[valuesById[4] = "LOGLEVEL_DEBUG"] = 4;
        values[valuesById[5] = "LOGLEVEL_VERBOSE"] = 5;
        return values;
    })();

    /**
     * ServiceUpdateType enum.
     * @name cat.ServiceUpdateType
     * @enum {number}
     * @property {number} SERVICEUPDATE_UNKNOWN=0 SERVICEUPDATE_UNKNOWN value
     * @property {number} SERVICEUPDATE_STOP=1 SERVICEUPDATE_STOP value
     * @property {number} SERVICEUPDATE_SET_LOGLEVEL=2 SERVICEUPDATE_SET_LOGLEVEL value
     * @property {number} SERVICEUPDATE_SET_SETTING=3 SERVICEUPDATE_SET_SETTING value
     * @property {number} SERVICEUPDATE_DEVICE=4 SERVICEUPDATE_DEVICE value
     */
    cat.ServiceUpdateType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SERVICEUPDATE_UNKNOWN"] = 0;
        values[valuesById[1] = "SERVICEUPDATE_STOP"] = 1;
        values[valuesById[2] = "SERVICEUPDATE_SET_LOGLEVEL"] = 2;
        values[valuesById[3] = "SERVICEUPDATE_SET_SETTING"] = 3;
        values[valuesById[4] = "SERVICEUPDATE_DEVICE"] = 4;
        return values;
    })();

    cat.ServiceUpdateMsg = (function() {

        /**
         * Properties of a ServiceUpdateMsg.
         * @memberof cat
         * @interface IServiceUpdateMsg
         * @property {cat.ServiceUpdateType|null} [type] ServiceUpdateMsg type
         * @property {string|null} [refid] ServiceUpdateMsg refid
         * @property {cat.LogLevel|null} [level] ServiceUpdateMsg level
         * @property {cat.Settings|null} [setting] ServiceUpdateMsg setting
         */

        /**
         * Constructs a new ServiceUpdateMsg.
         * @memberof cat
         * @classdesc Represents a ServiceUpdateMsg.
         * @implements IServiceUpdateMsg
         * @constructor
         * @param {cat.IServiceUpdateMsg=} [p] Properties to set
         */
        function ServiceUpdateMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ServiceUpdateMsg type.
         * @member {cat.ServiceUpdateType} type
         * @memberof cat.ServiceUpdateMsg
         * @instance
         */
        ServiceUpdateMsg.prototype.type = 0;

        /**
         * ServiceUpdateMsg refid.
         * @member {string} refid
         * @memberof cat.ServiceUpdateMsg
         * @instance
         */
        ServiceUpdateMsg.prototype.refid = "";

        /**
         * ServiceUpdateMsg level.
         * @member {cat.LogLevel} level
         * @memberof cat.ServiceUpdateMsg
         * @instance
         */
        ServiceUpdateMsg.prototype.level = 0;

        /**
         * ServiceUpdateMsg setting.
         * @member {cat.Settings} setting
         * @memberof cat.ServiceUpdateMsg
         * @instance
         */
        ServiceUpdateMsg.prototype.setting = 0;

        /**
         * Creates a new ServiceUpdateMsg instance using the specified properties.
         * @function create
         * @memberof cat.ServiceUpdateMsg
         * @static
         * @param {cat.IServiceUpdateMsg=} [properties] Properties to set
         * @returns {cat.ServiceUpdateMsg} ServiceUpdateMsg instance
         */
        ServiceUpdateMsg.create = function create(properties) {
            return new ServiceUpdateMsg(properties);
        };

        /**
         * Encodes the specified ServiceUpdateMsg message. Does not implicitly {@link cat.ServiceUpdateMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ServiceUpdateMsg
         * @static
         * @param {cat.IServiceUpdateMsg} m ServiceUpdateMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceUpdateMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.refid != null && Object.hasOwnProperty.call(m, "refid"))
                w.uint32(18).string(m.refid);
            if (m.level != null && Object.hasOwnProperty.call(m, "level"))
                w.uint32(24).int32(m.level);
            if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                w.uint32(32).int32(m.setting);
            return w;
        };

        /**
         * Decodes a ServiceUpdateMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ServiceUpdateMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ServiceUpdateMsg} ServiceUpdateMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceUpdateMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ServiceUpdateMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.type = r.int32();
                        break;
                    }
                case 2: {
                        m.refid = r.string();
                        break;
                    }
                case 3: {
                        m.level = r.int32();
                        break;
                    }
                case 4: {
                        m.setting = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ServiceUpdateMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ServiceUpdateMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ServiceUpdateMsg} ServiceUpdateMsg
         */
        ServiceUpdateMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ServiceUpdateMsg)
                return d;
            var m = new $root.cat.ServiceUpdateMsg();
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "SERVICEUPDATE_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "SERVICEUPDATE_STOP":
            case 1:
                m.type = 1;
                break;
            case "SERVICEUPDATE_SET_LOGLEVEL":
            case 2:
                m.type = 2;
                break;
            case "SERVICEUPDATE_SET_SETTING":
            case 3:
                m.type = 3;
                break;
            case "SERVICEUPDATE_DEVICE":
            case 4:
                m.type = 4;
                break;
            }
            if (d.refid != null) {
                m.refid = String(d.refid);
            }
            switch (d.level) {
            default:
                if (typeof d.level === "number") {
                    m.level = d.level;
                    break;
                }
                break;
            case "LOGLEVEL_UNKNOWN":
            case 0:
                m.level = 0;
                break;
            case "LOGLEVEL_ERROR":
            case 1:
                m.level = 1;
                break;
            case "LOGLEVEL_WARN":
            case 2:
                m.level = 2;
                break;
            case "LOGLEVEL_INFO":
            case 3:
                m.level = 3;
                break;
            case "LOGLEVEL_DEBUG":
            case 4:
                m.level = 4;
                break;
            case "LOGLEVEL_VERBOSE":
            case 5:
                m.level = 5;
                break;
            }
            switch (d.setting) {
            default:
                if (typeof d.setting === "number") {
                    m.setting = d.setting;
                    break;
                }
                break;
            case "SETTING_UNKNOWN":
            case 0:
                m.setting = 0;
                break;
            case "SETTING_MD5HASH":
            case 1:
                m.setting = 1;
                break;
            case "SETTING_REPORTER":
            case 2:
                m.setting = 2;
                break;
            case "SETTING_LANGUAGES":
            case 5:
                m.setting = 5;
                break;
            case "SETTING_KEYCLOAK":
            case 6:
                m.setting = 6;
                break;
            case "SETTING_FILE_ENCRYPTION":
            case 7:
                m.setting = 7;
                break;
            case "SETTING_APK_PATCH":
            case 8:
                m.setting = 8;
                break;
            case "SETTING_PASSWORD_POLICY":
            case 9:
                m.setting = 9;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a ServiceUpdateMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ServiceUpdateMsg
         * @static
         * @param {cat.ServiceUpdateMsg} m ServiceUpdateMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceUpdateMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = o.enums === String ? "SERVICEUPDATE_UNKNOWN" : 0;
                d.refid = "";
                d.level = o.enums === String ? "LOGLEVEL_UNKNOWN" : 0;
                d.setting = o.enums === String ? "SETTING_UNKNOWN" : 0;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.ServiceUpdateType[m.type] === undefined ? m.type : $root.cat.ServiceUpdateType[m.type] : m.type;
            }
            if (m.refid != null && m.hasOwnProperty("refid")) {
                d.refid = m.refid;
            }
            if (m.level != null && m.hasOwnProperty("level")) {
                d.level = o.enums === String ? $root.cat.LogLevel[m.level] === undefined ? m.level : $root.cat.LogLevel[m.level] : m.level;
            }
            if (m.setting != null && m.hasOwnProperty("setting")) {
                d.setting = o.enums === String ? $root.cat.Settings[m.setting] === undefined ? m.setting : $root.cat.Settings[m.setting] : m.setting;
            }
            return d;
        };

        /**
         * Converts this ServiceUpdateMsg to JSON.
         * @function toJSON
         * @memberof cat.ServiceUpdateMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceUpdateMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ServiceUpdateMsg
         * @function getTypeUrl
         * @memberof cat.ServiceUpdateMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ServiceUpdateMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ServiceUpdateMsg";
        };

        return ServiceUpdateMsg;
    })();

    cat.ServiceStatisticsMsg = (function() {

        /**
         * Properties of a ServiceStatisticsMsg.
         * @memberof cat
         * @interface IServiceStatisticsMsg
         * @property {string|null} [id] ServiceStatisticsMsg id
         * @property {number|null} [cpupercentage] ServiceStatisticsMsg cpupercentage
         * @property {number|null} [memusage] ServiceStatisticsMsg memusage
         * @property {number|null} [memlimit] ServiceStatisticsMsg memlimit
         * @property {number|null} [mempercentage] ServiceStatisticsMsg mempercentage
         * @property {number|null} [pids] ServiceStatisticsMsg pids
         */

        /**
         * Constructs a new ServiceStatisticsMsg.
         * @memberof cat
         * @classdesc Represents a ServiceStatisticsMsg.
         * @implements IServiceStatisticsMsg
         * @constructor
         * @param {cat.IServiceStatisticsMsg=} [p] Properties to set
         */
        function ServiceStatisticsMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ServiceStatisticsMsg id.
         * @member {string} id
         * @memberof cat.ServiceStatisticsMsg
         * @instance
         */
        ServiceStatisticsMsg.prototype.id = "";

        /**
         * ServiceStatisticsMsg cpupercentage.
         * @member {number} cpupercentage
         * @memberof cat.ServiceStatisticsMsg
         * @instance
         */
        ServiceStatisticsMsg.prototype.cpupercentage = 0;

        /**
         * ServiceStatisticsMsg memusage.
         * @member {number} memusage
         * @memberof cat.ServiceStatisticsMsg
         * @instance
         */
        ServiceStatisticsMsg.prototype.memusage = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ServiceStatisticsMsg memlimit.
         * @member {number} memlimit
         * @memberof cat.ServiceStatisticsMsg
         * @instance
         */
        ServiceStatisticsMsg.prototype.memlimit = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ServiceStatisticsMsg mempercentage.
         * @member {number} mempercentage
         * @memberof cat.ServiceStatisticsMsg
         * @instance
         */
        ServiceStatisticsMsg.prototype.mempercentage = 0;

        /**
         * ServiceStatisticsMsg pids.
         * @member {number} pids
         * @memberof cat.ServiceStatisticsMsg
         * @instance
         */
        ServiceStatisticsMsg.prototype.pids = 0;

        /**
         * Creates a new ServiceStatisticsMsg instance using the specified properties.
         * @function create
         * @memberof cat.ServiceStatisticsMsg
         * @static
         * @param {cat.IServiceStatisticsMsg=} [properties] Properties to set
         * @returns {cat.ServiceStatisticsMsg} ServiceStatisticsMsg instance
         */
        ServiceStatisticsMsg.create = function create(properties) {
            return new ServiceStatisticsMsg(properties);
        };

        /**
         * Encodes the specified ServiceStatisticsMsg message. Does not implicitly {@link cat.ServiceStatisticsMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ServiceStatisticsMsg
         * @static
         * @param {cat.IServiceStatisticsMsg} m ServiceStatisticsMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceStatisticsMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.cpupercentage != null && Object.hasOwnProperty.call(m, "cpupercentage"))
                w.uint32(16).uint32(m.cpupercentage);
            if (m.memusage != null && Object.hasOwnProperty.call(m, "memusage"))
                w.uint32(24).uint64(m.memusage);
            if (m.memlimit != null && Object.hasOwnProperty.call(m, "memlimit"))
                w.uint32(32).uint64(m.memlimit);
            if (m.mempercentage != null && Object.hasOwnProperty.call(m, "mempercentage"))
                w.uint32(40).uint32(m.mempercentage);
            if (m.pids != null && Object.hasOwnProperty.call(m, "pids"))
                w.uint32(48).uint32(m.pids);
            return w;
        };

        /**
         * Decodes a ServiceStatisticsMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ServiceStatisticsMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ServiceStatisticsMsg} ServiceStatisticsMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceStatisticsMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ServiceStatisticsMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.cpupercentage = r.uint32();
                        break;
                    }
                case 3: {
                        m.memusage = r.uint64();
                        break;
                    }
                case 4: {
                        m.memlimit = r.uint64();
                        break;
                    }
                case 5: {
                        m.mempercentage = r.uint32();
                        break;
                    }
                case 6: {
                        m.pids = r.uint32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ServiceStatisticsMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ServiceStatisticsMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ServiceStatisticsMsg} ServiceStatisticsMsg
         */
        ServiceStatisticsMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ServiceStatisticsMsg)
                return d;
            var m = new $root.cat.ServiceStatisticsMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.cpupercentage != null) {
                m.cpupercentage = d.cpupercentage >>> 0;
            }
            if (d.memusage != null) {
                if ($util.Long)
                    (m.memusage = $util.Long.fromValue(d.memusage)).unsigned = true;
                else if (typeof d.memusage === "string")
                    m.memusage = parseInt(d.memusage, 10);
                else if (typeof d.memusage === "number")
                    m.memusage = d.memusage;
                else if (typeof d.memusage === "object")
                    m.memusage = new $util.LongBits(d.memusage.low >>> 0, d.memusage.high >>> 0).toNumber(true);
            }
            if (d.memlimit != null) {
                if ($util.Long)
                    (m.memlimit = $util.Long.fromValue(d.memlimit)).unsigned = true;
                else if (typeof d.memlimit === "string")
                    m.memlimit = parseInt(d.memlimit, 10);
                else if (typeof d.memlimit === "number")
                    m.memlimit = d.memlimit;
                else if (typeof d.memlimit === "object")
                    m.memlimit = new $util.LongBits(d.memlimit.low >>> 0, d.memlimit.high >>> 0).toNumber(true);
            }
            if (d.mempercentage != null) {
                m.mempercentage = d.mempercentage >>> 0;
            }
            if (d.pids != null) {
                m.pids = d.pids >>> 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ServiceStatisticsMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ServiceStatisticsMsg
         * @static
         * @param {cat.ServiceStatisticsMsg} m ServiceStatisticsMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceStatisticsMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.cpupercentage = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.memusage = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.memusage = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.memlimit = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.memlimit = o.longs === String ? "0" : 0;
                d.mempercentage = 0;
                d.pids = 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.cpupercentage != null && m.hasOwnProperty("cpupercentage")) {
                d.cpupercentage = m.cpupercentage;
            }
            if (m.memusage != null && m.hasOwnProperty("memusage")) {
                if (typeof m.memusage === "number")
                    d.memusage = o.longs === String ? String(m.memusage) : m.memusage;
                else
                    d.memusage = o.longs === String ? $util.Long.prototype.toString.call(m.memusage) : o.longs === Number ? new $util.LongBits(m.memusage.low >>> 0, m.memusage.high >>> 0).toNumber(true) : m.memusage;
            }
            if (m.memlimit != null && m.hasOwnProperty("memlimit")) {
                if (typeof m.memlimit === "number")
                    d.memlimit = o.longs === String ? String(m.memlimit) : m.memlimit;
                else
                    d.memlimit = o.longs === String ? $util.Long.prototype.toString.call(m.memlimit) : o.longs === Number ? new $util.LongBits(m.memlimit.low >>> 0, m.memlimit.high >>> 0).toNumber(true) : m.memlimit;
            }
            if (m.mempercentage != null && m.hasOwnProperty("mempercentage")) {
                d.mempercentage = m.mempercentage;
            }
            if (m.pids != null && m.hasOwnProperty("pids")) {
                d.pids = m.pids;
            }
            return d;
        };

        /**
         * Converts this ServiceStatisticsMsg to JSON.
         * @function toJSON
         * @memberof cat.ServiceStatisticsMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceStatisticsMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ServiceStatisticsMsg
         * @function getTypeUrl
         * @memberof cat.ServiceStatisticsMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ServiceStatisticsMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ServiceStatisticsMsg";
        };

        return ServiceStatisticsMsg;
    })();

    /**
     * StatusType enum.
     * @name cat.StatusType
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} ENABLED=1 ENABLED value
     * @property {number} DISABLED=2 DISABLED value
     */
    cat.StatusType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "ENABLED"] = 1;
        values[valuesById[2] = "DISABLED"] = 2;
        return values;
    })();

    /**
     * ConfigurationType enum.
     * @name cat.ConfigurationType
     * @enum {number}
     * @property {number} CONFIGURATION_UNKNOWN=0 CONFIGURATION_UNKNOWN value
     * @property {number} CONFIGURATION_SYSTEM=1 CONFIGURATION_SYSTEM value
     * @property {number} CONFIGURATION_LOG=2 CONFIGURATION_LOG value
     * @property {number} CONFIGURATION_AUDITSTORE=5 CONFIGURATION_AUDITSTORE value
     * @property {number} CONFIGURATION_DATASTORE=6 CONFIGURATION_DATASTORE value
     * @property {number} CONFIGURATION_GATEWAY=7 CONFIGURATION_GATEWAY value
     * @property {number} CONFIGURATION_WEBSERVER=8 CONFIGURATION_WEBSERVER value
     * @property {number} CONFIGURATION_RETENTION=12 CONFIGURATION_RETENTION value
     * @property {number} CONFIGURATION_RECEIVER=13 CONFIGURATION_RECEIVER value
     */
    cat.ConfigurationType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CONFIGURATION_UNKNOWN"] = 0;
        values[valuesById[1] = "CONFIGURATION_SYSTEM"] = 1;
        values[valuesById[2] = "CONFIGURATION_LOG"] = 2;
        values[valuesById[5] = "CONFIGURATION_AUDITSTORE"] = 5;
        values[valuesById[6] = "CONFIGURATION_DATASTORE"] = 6;
        values[valuesById[7] = "CONFIGURATION_GATEWAY"] = 7;
        values[valuesById[8] = "CONFIGURATION_WEBSERVER"] = 8;
        values[valuesById[12] = "CONFIGURATION_RETENTION"] = 12;
        values[valuesById[13] = "CONFIGURATION_RECEIVER"] = 13;
        return values;
    })();

    cat.ServiceConfigurationMsg = (function() {

        /**
         * Properties of a ServiceConfigurationMsg.
         * @memberof cat
         * @interface IServiceConfigurationMsg
         * @property {string|null} [name] ServiceConfigurationMsg name
         * @property {string|null} [host] ServiceConfigurationMsg host
         * @property {number|null} [port] ServiceConfigurationMsg port
         * @property {string|null} [rootpath] ServiceConfigurationMsg rootpath
         * @property {string|null} [basepath] ServiceConfigurationMsg basepath
         * @property {string|null} [logpath] ServiceConfigurationMsg logpath
         * @property {string|null} [temppath] ServiceConfigurationMsg temppath
         * @property {string|null} [datapath] ServiceConfigurationMsg datapath
         */

        /**
         * Constructs a new ServiceConfigurationMsg.
         * @memberof cat
         * @classdesc Represents a ServiceConfigurationMsg.
         * @implements IServiceConfigurationMsg
         * @constructor
         * @param {cat.IServiceConfigurationMsg=} [p] Properties to set
         */
        function ServiceConfigurationMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ServiceConfigurationMsg name.
         * @member {string} name
         * @memberof cat.ServiceConfigurationMsg
         * @instance
         */
        ServiceConfigurationMsg.prototype.name = "";

        /**
         * ServiceConfigurationMsg host.
         * @member {string} host
         * @memberof cat.ServiceConfigurationMsg
         * @instance
         */
        ServiceConfigurationMsg.prototype.host = "";

        /**
         * ServiceConfigurationMsg port.
         * @member {number} port
         * @memberof cat.ServiceConfigurationMsg
         * @instance
         */
        ServiceConfigurationMsg.prototype.port = 0;

        /**
         * ServiceConfigurationMsg rootpath.
         * @member {string} rootpath
         * @memberof cat.ServiceConfigurationMsg
         * @instance
         */
        ServiceConfigurationMsg.prototype.rootpath = "";

        /**
         * ServiceConfigurationMsg basepath.
         * @member {string} basepath
         * @memberof cat.ServiceConfigurationMsg
         * @instance
         */
        ServiceConfigurationMsg.prototype.basepath = "";

        /**
         * ServiceConfigurationMsg logpath.
         * @member {string} logpath
         * @memberof cat.ServiceConfigurationMsg
         * @instance
         */
        ServiceConfigurationMsg.prototype.logpath = "";

        /**
         * ServiceConfigurationMsg temppath.
         * @member {string} temppath
         * @memberof cat.ServiceConfigurationMsg
         * @instance
         */
        ServiceConfigurationMsg.prototype.temppath = "";

        /**
         * ServiceConfigurationMsg datapath.
         * @member {string} datapath
         * @memberof cat.ServiceConfigurationMsg
         * @instance
         */
        ServiceConfigurationMsg.prototype.datapath = "";

        /**
         * Creates a new ServiceConfigurationMsg instance using the specified properties.
         * @function create
         * @memberof cat.ServiceConfigurationMsg
         * @static
         * @param {cat.IServiceConfigurationMsg=} [properties] Properties to set
         * @returns {cat.ServiceConfigurationMsg} ServiceConfigurationMsg instance
         */
        ServiceConfigurationMsg.create = function create(properties) {
            return new ServiceConfigurationMsg(properties);
        };

        /**
         * Encodes the specified ServiceConfigurationMsg message. Does not implicitly {@link cat.ServiceConfigurationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ServiceConfigurationMsg
         * @static
         * @param {cat.IServiceConfigurationMsg} m ServiceConfigurationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceConfigurationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.host != null && Object.hasOwnProperty.call(m, "host"))
                w.uint32(18).string(m.host);
            if (m.port != null && Object.hasOwnProperty.call(m, "port"))
                w.uint32(24).uint32(m.port);
            if (m.rootpath != null && Object.hasOwnProperty.call(m, "rootpath"))
                w.uint32(34).string(m.rootpath);
            if (m.basepath != null && Object.hasOwnProperty.call(m, "basepath"))
                w.uint32(42).string(m.basepath);
            if (m.logpath != null && Object.hasOwnProperty.call(m, "logpath"))
                w.uint32(50).string(m.logpath);
            if (m.temppath != null && Object.hasOwnProperty.call(m, "temppath"))
                w.uint32(66).string(m.temppath);
            if (m.datapath != null && Object.hasOwnProperty.call(m, "datapath"))
                w.uint32(74).string(m.datapath);
            return w;
        };

        /**
         * Decodes a ServiceConfigurationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ServiceConfigurationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ServiceConfigurationMsg} ServiceConfigurationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceConfigurationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ServiceConfigurationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 2: {
                        m.host = r.string();
                        break;
                    }
                case 3: {
                        m.port = r.uint32();
                        break;
                    }
                case 4: {
                        m.rootpath = r.string();
                        break;
                    }
                case 5: {
                        m.basepath = r.string();
                        break;
                    }
                case 6: {
                        m.logpath = r.string();
                        break;
                    }
                case 8: {
                        m.temppath = r.string();
                        break;
                    }
                case 9: {
                        m.datapath = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ServiceConfigurationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ServiceConfigurationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ServiceConfigurationMsg} ServiceConfigurationMsg
         */
        ServiceConfigurationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ServiceConfigurationMsg)
                return d;
            var m = new $root.cat.ServiceConfigurationMsg();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.host != null) {
                m.host = String(d.host);
            }
            if (d.port != null) {
                m.port = d.port >>> 0;
            }
            if (d.rootpath != null) {
                m.rootpath = String(d.rootpath);
            }
            if (d.basepath != null) {
                m.basepath = String(d.basepath);
            }
            if (d.logpath != null) {
                m.logpath = String(d.logpath);
            }
            if (d.temppath != null) {
                m.temppath = String(d.temppath);
            }
            if (d.datapath != null) {
                m.datapath = String(d.datapath);
            }
            return m;
        };

        /**
         * Creates a plain object from a ServiceConfigurationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ServiceConfigurationMsg
         * @static
         * @param {cat.ServiceConfigurationMsg} m ServiceConfigurationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceConfigurationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.name = "";
                d.host = "";
                d.port = 0;
                d.rootpath = "";
                d.basepath = "";
                d.logpath = "";
                d.temppath = "";
                d.datapath = "";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.host != null && m.hasOwnProperty("host")) {
                d.host = m.host;
            }
            if (m.port != null && m.hasOwnProperty("port")) {
                d.port = m.port;
            }
            if (m.rootpath != null && m.hasOwnProperty("rootpath")) {
                d.rootpath = m.rootpath;
            }
            if (m.basepath != null && m.hasOwnProperty("basepath")) {
                d.basepath = m.basepath;
            }
            if (m.logpath != null && m.hasOwnProperty("logpath")) {
                d.logpath = m.logpath;
            }
            if (m.temppath != null && m.hasOwnProperty("temppath")) {
                d.temppath = m.temppath;
            }
            if (m.datapath != null && m.hasOwnProperty("datapath")) {
                d.datapath = m.datapath;
            }
            return d;
        };

        /**
         * Converts this ServiceConfigurationMsg to JSON.
         * @function toJSON
         * @memberof cat.ServiceConfigurationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceConfigurationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ServiceConfigurationMsg
         * @function getTypeUrl
         * @memberof cat.ServiceConfigurationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ServiceConfigurationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ServiceConfigurationMsg";
        };

        return ServiceConfigurationMsg;
    })();

    cat.LogConfigurationMsg = (function() {

        /**
         * Properties of a LogConfigurationMsg.
         * @memberof cat
         * @interface ILogConfigurationMsg
         * @property {string|null} [level] LogConfigurationMsg level
         * @property {string|null} [maxfilesize] LogConfigurationMsg maxfilesize
         * @property {string|null} [maxfiles] LogConfigurationMsg maxfiles
         * @property {string|null} [timestampformat] LogConfigurationMsg timestampformat
         * @property {Object.<string,string>|null} [componentlevels] LogConfigurationMsg componentlevels
         * @property {string|null} [filename] LogConfigurationMsg filename
         */

        /**
         * Constructs a new LogConfigurationMsg.
         * @memberof cat
         * @classdesc Represents a LogConfigurationMsg.
         * @implements ILogConfigurationMsg
         * @constructor
         * @param {cat.ILogConfigurationMsg=} [p] Properties to set
         */
        function LogConfigurationMsg(p) {
            this.componentlevels = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LogConfigurationMsg level.
         * @member {string} level
         * @memberof cat.LogConfigurationMsg
         * @instance
         */
        LogConfigurationMsg.prototype.level = "";

        /**
         * LogConfigurationMsg maxfilesize.
         * @member {string} maxfilesize
         * @memberof cat.LogConfigurationMsg
         * @instance
         */
        LogConfigurationMsg.prototype.maxfilesize = "";

        /**
         * LogConfigurationMsg maxfiles.
         * @member {string} maxfiles
         * @memberof cat.LogConfigurationMsg
         * @instance
         */
        LogConfigurationMsg.prototype.maxfiles = "";

        /**
         * LogConfigurationMsg timestampformat.
         * @member {string} timestampformat
         * @memberof cat.LogConfigurationMsg
         * @instance
         */
        LogConfigurationMsg.prototype.timestampformat = "";

        /**
         * LogConfigurationMsg componentlevels.
         * @member {Object.<string,string>} componentlevels
         * @memberof cat.LogConfigurationMsg
         * @instance
         */
        LogConfigurationMsg.prototype.componentlevels = $util.emptyObject;

        /**
         * LogConfigurationMsg filename.
         * @member {string} filename
         * @memberof cat.LogConfigurationMsg
         * @instance
         */
        LogConfigurationMsg.prototype.filename = "";

        /**
         * Creates a new LogConfigurationMsg instance using the specified properties.
         * @function create
         * @memberof cat.LogConfigurationMsg
         * @static
         * @param {cat.ILogConfigurationMsg=} [properties] Properties to set
         * @returns {cat.LogConfigurationMsg} LogConfigurationMsg instance
         */
        LogConfigurationMsg.create = function create(properties) {
            return new LogConfigurationMsg(properties);
        };

        /**
         * Encodes the specified LogConfigurationMsg message. Does not implicitly {@link cat.LogConfigurationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.LogConfigurationMsg
         * @static
         * @param {cat.ILogConfigurationMsg} m LogConfigurationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LogConfigurationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.level != null && Object.hasOwnProperty.call(m, "level"))
                w.uint32(10).string(m.level);
            if (m.maxfilesize != null && Object.hasOwnProperty.call(m, "maxfilesize"))
                w.uint32(18).string(m.maxfilesize);
            if (m.maxfiles != null && Object.hasOwnProperty.call(m, "maxfiles"))
                w.uint32(26).string(m.maxfiles);
            if (m.timestampformat != null && Object.hasOwnProperty.call(m, "timestampformat"))
                w.uint32(34).string(m.timestampformat);
            if (m.componentlevels != null && Object.hasOwnProperty.call(m, "componentlevels")) {
                for (var ks = Object.keys(m.componentlevels), i = 0; i < ks.length; ++i) {
                    w.uint32(42).fork().uint32(10).string(ks[i]).uint32(18).string(m.componentlevels[ks[i]]).ldelim();
                }
            }
            if (m.filename != null && Object.hasOwnProperty.call(m, "filename"))
                w.uint32(810).string(m.filename);
            return w;
        };

        /**
         * Decodes a LogConfigurationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.LogConfigurationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.LogConfigurationMsg} LogConfigurationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LogConfigurationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.LogConfigurationMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.level = r.string();
                        break;
                    }
                case 2: {
                        m.maxfilesize = r.string();
                        break;
                    }
                case 3: {
                        m.maxfiles = r.string();
                        break;
                    }
                case 4: {
                        m.timestampformat = r.string();
                        break;
                    }
                case 5: {
                        if (m.componentlevels === $util.emptyObject)
                            m.componentlevels = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.componentlevels[k] = value;
                        break;
                    }
                case 101: {
                        m.filename = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a LogConfigurationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.LogConfigurationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.LogConfigurationMsg} LogConfigurationMsg
         */
        LogConfigurationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.LogConfigurationMsg)
                return d;
            var m = new $root.cat.LogConfigurationMsg();
            if (d.level != null) {
                m.level = String(d.level);
            }
            if (d.maxfilesize != null) {
                m.maxfilesize = String(d.maxfilesize);
            }
            if (d.maxfiles != null) {
                m.maxfiles = String(d.maxfiles);
            }
            if (d.timestampformat != null) {
                m.timestampformat = String(d.timestampformat);
            }
            if (d.componentlevels) {
                if (typeof d.componentlevels !== "object")
                    throw TypeError(".cat.LogConfigurationMsg.componentlevels: object expected");
                m.componentlevels = {};
                for (var ks = Object.keys(d.componentlevels), i = 0; i < ks.length; ++i) {
                    m.componentlevels[ks[i]] = String(d.componentlevels[ks[i]]);
                }
            }
            if (d.filename != null) {
                m.filename = String(d.filename);
            }
            return m;
        };

        /**
         * Creates a plain object from a LogConfigurationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.LogConfigurationMsg
         * @static
         * @param {cat.LogConfigurationMsg} m LogConfigurationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LogConfigurationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.objects || o.defaults) {
                d.componentlevels = {};
            }
            if (o.defaults) {
                d.level = "";
                d.maxfilesize = "";
                d.maxfiles = "";
                d.timestampformat = "";
                d.filename = "";
            }
            if (m.level != null && m.hasOwnProperty("level")) {
                d.level = m.level;
            }
            if (m.maxfilesize != null && m.hasOwnProperty("maxfilesize")) {
                d.maxfilesize = m.maxfilesize;
            }
            if (m.maxfiles != null && m.hasOwnProperty("maxfiles")) {
                d.maxfiles = m.maxfiles;
            }
            if (m.timestampformat != null && m.hasOwnProperty("timestampformat")) {
                d.timestampformat = m.timestampformat;
            }
            var ks2;
            if (m.componentlevels && (ks2 = Object.keys(m.componentlevels)).length) {
                d.componentlevels = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.componentlevels[ks2[j]] = m.componentlevels[ks2[j]];
                }
            }
            if (m.filename != null && m.hasOwnProperty("filename")) {
                d.filename = m.filename;
            }
            return d;
        };

        /**
         * Converts this LogConfigurationMsg to JSON.
         * @function toJSON
         * @memberof cat.LogConfigurationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LogConfigurationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LogConfigurationMsg
         * @function getTypeUrl
         * @memberof cat.LogConfigurationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LogConfigurationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.LogConfigurationMsg";
        };

        return LogConfigurationMsg;
    })();

    cat.DatabaseConfigurationMsg = (function() {

        /**
         * Properties of a DatabaseConfigurationMsg.
         * @memberof cat
         * @interface IDatabaseConfigurationMsg
         * @property {string|null} [url] DatabaseConfigurationMsg url
         * @property {string|null} [host] DatabaseConfigurationMsg host
         * @property {number|null} [port] DatabaseConfigurationMsg port
         * @property {string|null} [name] DatabaseConfigurationMsg name
         * @property {string|null} [username] DatabaseConfigurationMsg username
         * @property {string|null} [password] DatabaseConfigurationMsg password
         * @property {Array.<string>|null} [sources] DatabaseConfigurationMsg sources
         */

        /**
         * Constructs a new DatabaseConfigurationMsg.
         * @memberof cat
         * @classdesc Represents a DatabaseConfigurationMsg.
         * @implements IDatabaseConfigurationMsg
         * @constructor
         * @param {cat.IDatabaseConfigurationMsg=} [p] Properties to set
         */
        function DatabaseConfigurationMsg(p) {
            this.sources = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DatabaseConfigurationMsg url.
         * @member {string} url
         * @memberof cat.DatabaseConfigurationMsg
         * @instance
         */
        DatabaseConfigurationMsg.prototype.url = "";

        /**
         * DatabaseConfigurationMsg host.
         * @member {string} host
         * @memberof cat.DatabaseConfigurationMsg
         * @instance
         */
        DatabaseConfigurationMsg.prototype.host = "";

        /**
         * DatabaseConfigurationMsg port.
         * @member {number} port
         * @memberof cat.DatabaseConfigurationMsg
         * @instance
         */
        DatabaseConfigurationMsg.prototype.port = 0;

        /**
         * DatabaseConfigurationMsg name.
         * @member {string} name
         * @memberof cat.DatabaseConfigurationMsg
         * @instance
         */
        DatabaseConfigurationMsg.prototype.name = "";

        /**
         * DatabaseConfigurationMsg username.
         * @member {string} username
         * @memberof cat.DatabaseConfigurationMsg
         * @instance
         */
        DatabaseConfigurationMsg.prototype.username = "";

        /**
         * DatabaseConfigurationMsg password.
         * @member {string} password
         * @memberof cat.DatabaseConfigurationMsg
         * @instance
         */
        DatabaseConfigurationMsg.prototype.password = "";

        /**
         * DatabaseConfigurationMsg sources.
         * @member {Array.<string>} sources
         * @memberof cat.DatabaseConfigurationMsg
         * @instance
         */
        DatabaseConfigurationMsg.prototype.sources = $util.emptyArray;

        /**
         * Creates a new DatabaseConfigurationMsg instance using the specified properties.
         * @function create
         * @memberof cat.DatabaseConfigurationMsg
         * @static
         * @param {cat.IDatabaseConfigurationMsg=} [properties] Properties to set
         * @returns {cat.DatabaseConfigurationMsg} DatabaseConfigurationMsg instance
         */
        DatabaseConfigurationMsg.create = function create(properties) {
            return new DatabaseConfigurationMsg(properties);
        };

        /**
         * Encodes the specified DatabaseConfigurationMsg message. Does not implicitly {@link cat.DatabaseConfigurationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.DatabaseConfigurationMsg
         * @static
         * @param {cat.IDatabaseConfigurationMsg} m DatabaseConfigurationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DatabaseConfigurationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                w.uint32(10).string(m.url);
            if (m.host != null && Object.hasOwnProperty.call(m, "host"))
                w.uint32(18).string(m.host);
            if (m.port != null && Object.hasOwnProperty.call(m, "port"))
                w.uint32(24).uint32(m.port);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(42).string(m.username);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(50).string(m.password);
            if (m.sources != null && m.sources.length) {
                for (var i = 0; i < m.sources.length; ++i)
                    w.uint32(58).string(m.sources[i]);
            }
            return w;
        };

        /**
         * Decodes a DatabaseConfigurationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.DatabaseConfigurationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.DatabaseConfigurationMsg} DatabaseConfigurationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DatabaseConfigurationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.DatabaseConfigurationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.url = r.string();
                        break;
                    }
                case 2: {
                        m.host = r.string();
                        break;
                    }
                case 3: {
                        m.port = r.uint32();
                        break;
                    }
                case 4: {
                        m.name = r.string();
                        break;
                    }
                case 5: {
                        m.username = r.string();
                        break;
                    }
                case 6: {
                        m.password = r.string();
                        break;
                    }
                case 7: {
                        if (!(m.sources && m.sources.length))
                            m.sources = [];
                        m.sources.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DatabaseConfigurationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.DatabaseConfigurationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.DatabaseConfigurationMsg} DatabaseConfigurationMsg
         */
        DatabaseConfigurationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.DatabaseConfigurationMsg)
                return d;
            var m = new $root.cat.DatabaseConfigurationMsg();
            if (d.url != null) {
                m.url = String(d.url);
            }
            if (d.host != null) {
                m.host = String(d.host);
            }
            if (d.port != null) {
                m.port = d.port >>> 0;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            if (d.sources) {
                if (!Array.isArray(d.sources))
                    throw TypeError(".cat.DatabaseConfigurationMsg.sources: array expected");
                m.sources = [];
                for (var i = 0; i < d.sources.length; ++i) {
                    m.sources[i] = String(d.sources[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a DatabaseConfigurationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.DatabaseConfigurationMsg
         * @static
         * @param {cat.DatabaseConfigurationMsg} m DatabaseConfigurationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DatabaseConfigurationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.sources = [];
            }
            if (o.defaults) {
                d.url = "";
                d.host = "";
                d.port = 0;
                d.name = "";
                d.username = "";
                d.password = "";
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = m.url;
            }
            if (m.host != null && m.hasOwnProperty("host")) {
                d.host = m.host;
            }
            if (m.port != null && m.hasOwnProperty("port")) {
                d.port = m.port;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            if (m.sources && m.sources.length) {
                d.sources = [];
                for (var j = 0; j < m.sources.length; ++j) {
                    d.sources[j] = m.sources[j];
                }
            }
            return d;
        };

        /**
         * Converts this DatabaseConfigurationMsg to JSON.
         * @function toJSON
         * @memberof cat.DatabaseConfigurationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DatabaseConfigurationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DatabaseConfigurationMsg
         * @function getTypeUrl
         * @memberof cat.DatabaseConfigurationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DatabaseConfigurationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.DatabaseConfigurationMsg";
        };

        return DatabaseConfigurationMsg;
    })();

    cat.FileServerConfigurationMsg = (function() {

        /**
         * Properties of a FileServerConfigurationMsg.
         * @memberof cat
         * @interface IFileServerConfigurationMsg
         * @property {string|null} [path] FileServerConfigurationMsg path
         * @property {Array.<string>|null} [sources] FileServerConfigurationMsg sources
         */

        /**
         * Constructs a new FileServerConfigurationMsg.
         * @memberof cat
         * @classdesc Represents a FileServerConfigurationMsg.
         * @implements IFileServerConfigurationMsg
         * @constructor
         * @param {cat.IFileServerConfigurationMsg=} [p] Properties to set
         */
        function FileServerConfigurationMsg(p) {
            this.sources = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FileServerConfigurationMsg path.
         * @member {string} path
         * @memberof cat.FileServerConfigurationMsg
         * @instance
         */
        FileServerConfigurationMsg.prototype.path = "";

        /**
         * FileServerConfigurationMsg sources.
         * @member {Array.<string>} sources
         * @memberof cat.FileServerConfigurationMsg
         * @instance
         */
        FileServerConfigurationMsg.prototype.sources = $util.emptyArray;

        /**
         * Creates a new FileServerConfigurationMsg instance using the specified properties.
         * @function create
         * @memberof cat.FileServerConfigurationMsg
         * @static
         * @param {cat.IFileServerConfigurationMsg=} [properties] Properties to set
         * @returns {cat.FileServerConfigurationMsg} FileServerConfigurationMsg instance
         */
        FileServerConfigurationMsg.create = function create(properties) {
            return new FileServerConfigurationMsg(properties);
        };

        /**
         * Encodes the specified FileServerConfigurationMsg message. Does not implicitly {@link cat.FileServerConfigurationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.FileServerConfigurationMsg
         * @static
         * @param {cat.IFileServerConfigurationMsg} m FileServerConfigurationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileServerConfigurationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.path != null && Object.hasOwnProperty.call(m, "path"))
                w.uint32(10).string(m.path);
            if (m.sources != null && m.sources.length) {
                for (var i = 0; i < m.sources.length; ++i)
                    w.uint32(18).string(m.sources[i]);
            }
            return w;
        };

        /**
         * Decodes a FileServerConfigurationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.FileServerConfigurationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.FileServerConfigurationMsg} FileServerConfigurationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileServerConfigurationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.FileServerConfigurationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.path = r.string();
                        break;
                    }
                case 2: {
                        if (!(m.sources && m.sources.length))
                            m.sources = [];
                        m.sources.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a FileServerConfigurationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.FileServerConfigurationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.FileServerConfigurationMsg} FileServerConfigurationMsg
         */
        FileServerConfigurationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.FileServerConfigurationMsg)
                return d;
            var m = new $root.cat.FileServerConfigurationMsg();
            if (d.path != null) {
                m.path = String(d.path);
            }
            if (d.sources) {
                if (!Array.isArray(d.sources))
                    throw TypeError(".cat.FileServerConfigurationMsg.sources: array expected");
                m.sources = [];
                for (var i = 0; i < d.sources.length; ++i) {
                    m.sources[i] = String(d.sources[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a FileServerConfigurationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.FileServerConfigurationMsg
         * @static
         * @param {cat.FileServerConfigurationMsg} m FileServerConfigurationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileServerConfigurationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.sources = [];
            }
            if (o.defaults) {
                d.path = "";
            }
            if (m.path != null && m.hasOwnProperty("path")) {
                d.path = m.path;
            }
            if (m.sources && m.sources.length) {
                d.sources = [];
                for (var j = 0; j < m.sources.length; ++j) {
                    d.sources[j] = m.sources[j];
                }
            }
            return d;
        };

        /**
         * Converts this FileServerConfigurationMsg to JSON.
         * @function toJSON
         * @memberof cat.FileServerConfigurationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileServerConfigurationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FileServerConfigurationMsg
         * @function getTypeUrl
         * @memberof cat.FileServerConfigurationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FileServerConfigurationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.FileServerConfigurationMsg";
        };

        return FileServerConfigurationMsg;
    })();

    cat.WebServiceConfigurationMsg = (function() {

        /**
         * Properties of a WebServiceConfigurationMsg.
         * @memberof cat
         * @interface IWebServiceConfigurationMsg
         * @property {string|null} [url] WebServiceConfigurationMsg url
         * @property {string|null} [username] WebServiceConfigurationMsg username
         * @property {string|null} [password] WebServiceConfigurationMsg password
         * @property {Array.<string>|null} [credentials] WebServiceConfigurationMsg credentials
         */

        /**
         * Constructs a new WebServiceConfigurationMsg.
         * @memberof cat
         * @classdesc Represents a WebServiceConfigurationMsg.
         * @implements IWebServiceConfigurationMsg
         * @constructor
         * @param {cat.IWebServiceConfigurationMsg=} [p] Properties to set
         */
        function WebServiceConfigurationMsg(p) {
            this.credentials = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WebServiceConfigurationMsg url.
         * @member {string} url
         * @memberof cat.WebServiceConfigurationMsg
         * @instance
         */
        WebServiceConfigurationMsg.prototype.url = "";

        /**
         * WebServiceConfigurationMsg username.
         * @member {string} username
         * @memberof cat.WebServiceConfigurationMsg
         * @instance
         */
        WebServiceConfigurationMsg.prototype.username = "";

        /**
         * WebServiceConfigurationMsg password.
         * @member {string} password
         * @memberof cat.WebServiceConfigurationMsg
         * @instance
         */
        WebServiceConfigurationMsg.prototype.password = "";

        /**
         * WebServiceConfigurationMsg credentials.
         * @member {Array.<string>} credentials
         * @memberof cat.WebServiceConfigurationMsg
         * @instance
         */
        WebServiceConfigurationMsg.prototype.credentials = $util.emptyArray;

        /**
         * Creates a new WebServiceConfigurationMsg instance using the specified properties.
         * @function create
         * @memberof cat.WebServiceConfigurationMsg
         * @static
         * @param {cat.IWebServiceConfigurationMsg=} [properties] Properties to set
         * @returns {cat.WebServiceConfigurationMsg} WebServiceConfigurationMsg instance
         */
        WebServiceConfigurationMsg.create = function create(properties) {
            return new WebServiceConfigurationMsg(properties);
        };

        /**
         * Encodes the specified WebServiceConfigurationMsg message. Does not implicitly {@link cat.WebServiceConfigurationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.WebServiceConfigurationMsg
         * @static
         * @param {cat.IWebServiceConfigurationMsg} m WebServiceConfigurationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebServiceConfigurationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                w.uint32(10).string(m.url);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(18).string(m.username);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(26).string(m.password);
            if (m.credentials != null && m.credentials.length) {
                for (var i = 0; i < m.credentials.length; ++i)
                    w.uint32(34).string(m.credentials[i]);
            }
            return w;
        };

        /**
         * Decodes a WebServiceConfigurationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.WebServiceConfigurationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.WebServiceConfigurationMsg} WebServiceConfigurationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebServiceConfigurationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.WebServiceConfigurationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.url = r.string();
                        break;
                    }
                case 2: {
                        m.username = r.string();
                        break;
                    }
                case 3: {
                        m.password = r.string();
                        break;
                    }
                case 4: {
                        if (!(m.credentials && m.credentials.length))
                            m.credentials = [];
                        m.credentials.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WebServiceConfigurationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.WebServiceConfigurationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.WebServiceConfigurationMsg} WebServiceConfigurationMsg
         */
        WebServiceConfigurationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.WebServiceConfigurationMsg)
                return d;
            var m = new $root.cat.WebServiceConfigurationMsg();
            if (d.url != null) {
                m.url = String(d.url);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            if (d.credentials) {
                if (!Array.isArray(d.credentials))
                    throw TypeError(".cat.WebServiceConfigurationMsg.credentials: array expected");
                m.credentials = [];
                for (var i = 0; i < d.credentials.length; ++i) {
                    m.credentials[i] = String(d.credentials[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WebServiceConfigurationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.WebServiceConfigurationMsg
         * @static
         * @param {cat.WebServiceConfigurationMsg} m WebServiceConfigurationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WebServiceConfigurationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.credentials = [];
            }
            if (o.defaults) {
                d.url = "";
                d.username = "";
                d.password = "";
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = m.url;
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            if (m.credentials && m.credentials.length) {
                d.credentials = [];
                for (var j = 0; j < m.credentials.length; ++j) {
                    d.credentials[j] = m.credentials[j];
                }
            }
            return d;
        };

        /**
         * Converts this WebServiceConfigurationMsg to JSON.
         * @function toJSON
         * @memberof cat.WebServiceConfigurationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WebServiceConfigurationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WebServiceConfigurationMsg
         * @function getTypeUrl
         * @memberof cat.WebServiceConfigurationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebServiceConfigurationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.WebServiceConfigurationMsg";
        };

        return WebServiceConfigurationMsg;
    })();

    cat.DockerConfigurationMsg = (function() {

        /**
         * Properties of a DockerConfigurationMsg.
         * @memberof cat
         * @interface IDockerConfigurationMsg
         * @property {string|null} [network] DockerConfigurationMsg network
         * @property {string|null} [bridgenetwork] DockerConfigurationMsg bridgenetwork
         * @property {string|null} [socketpath] DockerConfigurationMsg socketpath
         * @property {string|null} [label] DockerConfigurationMsg label
         * @property {Array.<string>|null} [startsequence] DockerConfigurationMsg startsequence
         */

        /**
         * Constructs a new DockerConfigurationMsg.
         * @memberof cat
         * @classdesc Represents a DockerConfigurationMsg.
         * @implements IDockerConfigurationMsg
         * @constructor
         * @param {cat.IDockerConfigurationMsg=} [p] Properties to set
         */
        function DockerConfigurationMsg(p) {
            this.startsequence = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DockerConfigurationMsg network.
         * @member {string} network
         * @memberof cat.DockerConfigurationMsg
         * @instance
         */
        DockerConfigurationMsg.prototype.network = "";

        /**
         * DockerConfigurationMsg bridgenetwork.
         * @member {string} bridgenetwork
         * @memberof cat.DockerConfigurationMsg
         * @instance
         */
        DockerConfigurationMsg.prototype.bridgenetwork = "";

        /**
         * DockerConfigurationMsg socketpath.
         * @member {string} socketpath
         * @memberof cat.DockerConfigurationMsg
         * @instance
         */
        DockerConfigurationMsg.prototype.socketpath = "";

        /**
         * DockerConfigurationMsg label.
         * @member {string} label
         * @memberof cat.DockerConfigurationMsg
         * @instance
         */
        DockerConfigurationMsg.prototype.label = "";

        /**
         * DockerConfigurationMsg startsequence.
         * @member {Array.<string>} startsequence
         * @memberof cat.DockerConfigurationMsg
         * @instance
         */
        DockerConfigurationMsg.prototype.startsequence = $util.emptyArray;

        /**
         * Creates a new DockerConfigurationMsg instance using the specified properties.
         * @function create
         * @memberof cat.DockerConfigurationMsg
         * @static
         * @param {cat.IDockerConfigurationMsg=} [properties] Properties to set
         * @returns {cat.DockerConfigurationMsg} DockerConfigurationMsg instance
         */
        DockerConfigurationMsg.create = function create(properties) {
            return new DockerConfigurationMsg(properties);
        };

        /**
         * Encodes the specified DockerConfigurationMsg message. Does not implicitly {@link cat.DockerConfigurationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.DockerConfigurationMsg
         * @static
         * @param {cat.IDockerConfigurationMsg} m DockerConfigurationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DockerConfigurationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.network != null && Object.hasOwnProperty.call(m, "network"))
                w.uint32(10).string(m.network);
            if (m.bridgenetwork != null && Object.hasOwnProperty.call(m, "bridgenetwork"))
                w.uint32(18).string(m.bridgenetwork);
            if (m.socketpath != null && Object.hasOwnProperty.call(m, "socketpath"))
                w.uint32(26).string(m.socketpath);
            if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                w.uint32(34).string(m.label);
            if (m.startsequence != null && m.startsequence.length) {
                for (var i = 0; i < m.startsequence.length; ++i)
                    w.uint32(42).string(m.startsequence[i]);
            }
            return w;
        };

        /**
         * Decodes a DockerConfigurationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.DockerConfigurationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.DockerConfigurationMsg} DockerConfigurationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DockerConfigurationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.DockerConfigurationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.network = r.string();
                        break;
                    }
                case 2: {
                        m.bridgenetwork = r.string();
                        break;
                    }
                case 3: {
                        m.socketpath = r.string();
                        break;
                    }
                case 4: {
                        m.label = r.string();
                        break;
                    }
                case 5: {
                        if (!(m.startsequence && m.startsequence.length))
                            m.startsequence = [];
                        m.startsequence.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DockerConfigurationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.DockerConfigurationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.DockerConfigurationMsg} DockerConfigurationMsg
         */
        DockerConfigurationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.DockerConfigurationMsg)
                return d;
            var m = new $root.cat.DockerConfigurationMsg();
            if (d.network != null) {
                m.network = String(d.network);
            }
            if (d.bridgenetwork != null) {
                m.bridgenetwork = String(d.bridgenetwork);
            }
            if (d.socketpath != null) {
                m.socketpath = String(d.socketpath);
            }
            if (d.label != null) {
                m.label = String(d.label);
            }
            if (d.startsequence) {
                if (!Array.isArray(d.startsequence))
                    throw TypeError(".cat.DockerConfigurationMsg.startsequence: array expected");
                m.startsequence = [];
                for (var i = 0; i < d.startsequence.length; ++i) {
                    m.startsequence[i] = String(d.startsequence[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a DockerConfigurationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.DockerConfigurationMsg
         * @static
         * @param {cat.DockerConfigurationMsg} m DockerConfigurationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DockerConfigurationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.startsequence = [];
            }
            if (o.defaults) {
                d.network = "";
                d.bridgenetwork = "";
                d.socketpath = "";
                d.label = "";
            }
            if (m.network != null && m.hasOwnProperty("network")) {
                d.network = m.network;
            }
            if (m.bridgenetwork != null && m.hasOwnProperty("bridgenetwork")) {
                d.bridgenetwork = m.bridgenetwork;
            }
            if (m.socketpath != null && m.hasOwnProperty("socketpath")) {
                d.socketpath = m.socketpath;
            }
            if (m.label != null && m.hasOwnProperty("label")) {
                d.label = m.label;
            }
            if (m.startsequence && m.startsequence.length) {
                d.startsequence = [];
                for (var j = 0; j < m.startsequence.length; ++j) {
                    d.startsequence[j] = m.startsequence[j];
                }
            }
            return d;
        };

        /**
         * Converts this DockerConfigurationMsg to JSON.
         * @function toJSON
         * @memberof cat.DockerConfigurationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DockerConfigurationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DockerConfigurationMsg
         * @function getTypeUrl
         * @memberof cat.DockerConfigurationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DockerConfigurationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.DockerConfigurationMsg";
        };

        return DockerConfigurationMsg;
    })();

    cat.RetentionConfigurationMsg = (function() {

        /**
         * Properties of a RetentionConfigurationMsg.
         * @memberof cat
         * @interface IRetentionConfigurationMsg
         * @property {cat.IDateMsg|null} [notifications] RetentionConfigurationMsg notifications
         * @property {cat.IDateMsg|null} [apps] RetentionConfigurationMsg apps
         * @property {cat.IDateMsg|null} [accounts] RetentionConfigurationMsg accounts
         */

        /**
         * Constructs a new RetentionConfigurationMsg.
         * @memberof cat
         * @classdesc Represents a RetentionConfigurationMsg.
         * @implements IRetentionConfigurationMsg
         * @constructor
         * @param {cat.IRetentionConfigurationMsg=} [p] Properties to set
         */
        function RetentionConfigurationMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * RetentionConfigurationMsg notifications.
         * @member {cat.IDateMsg|null|undefined} notifications
         * @memberof cat.RetentionConfigurationMsg
         * @instance
         */
        RetentionConfigurationMsg.prototype.notifications = null;

        /**
         * RetentionConfigurationMsg apps.
         * @member {cat.IDateMsg|null|undefined} apps
         * @memberof cat.RetentionConfigurationMsg
         * @instance
         */
        RetentionConfigurationMsg.prototype.apps = null;

        /**
         * RetentionConfigurationMsg accounts.
         * @member {cat.IDateMsg|null|undefined} accounts
         * @memberof cat.RetentionConfigurationMsg
         * @instance
         */
        RetentionConfigurationMsg.prototype.accounts = null;

        /**
         * Creates a new RetentionConfigurationMsg instance using the specified properties.
         * @function create
         * @memberof cat.RetentionConfigurationMsg
         * @static
         * @param {cat.IRetentionConfigurationMsg=} [properties] Properties to set
         * @returns {cat.RetentionConfigurationMsg} RetentionConfigurationMsg instance
         */
        RetentionConfigurationMsg.create = function create(properties) {
            return new RetentionConfigurationMsg(properties);
        };

        /**
         * Encodes the specified RetentionConfigurationMsg message. Does not implicitly {@link cat.RetentionConfigurationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.RetentionConfigurationMsg
         * @static
         * @param {cat.IRetentionConfigurationMsg} m RetentionConfigurationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RetentionConfigurationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.notifications != null && Object.hasOwnProperty.call(m, "notifications"))
                $root.cat.DateMsg.encode(m.notifications, w.uint32(10).fork()).ldelim();
            if (m.apps != null && Object.hasOwnProperty.call(m, "apps"))
                $root.cat.DateMsg.encode(m.apps, w.uint32(18).fork()).ldelim();
            if (m.accounts != null && Object.hasOwnProperty.call(m, "accounts"))
                $root.cat.DateMsg.encode(m.accounts, w.uint32(26).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a RetentionConfigurationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.RetentionConfigurationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.RetentionConfigurationMsg} RetentionConfigurationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RetentionConfigurationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.RetentionConfigurationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.notifications = $root.cat.DateMsg.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.apps = $root.cat.DateMsg.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.accounts = $root.cat.DateMsg.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a RetentionConfigurationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.RetentionConfigurationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.RetentionConfigurationMsg} RetentionConfigurationMsg
         */
        RetentionConfigurationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.RetentionConfigurationMsg)
                return d;
            var m = new $root.cat.RetentionConfigurationMsg();
            if (d.notifications != null) {
                if (typeof d.notifications !== "object")
                    throw TypeError(".cat.RetentionConfigurationMsg.notifications: object expected");
                m.notifications = $root.cat.DateMsg.fromObject(d.notifications);
            }
            if (d.apps != null) {
                if (typeof d.apps !== "object")
                    throw TypeError(".cat.RetentionConfigurationMsg.apps: object expected");
                m.apps = $root.cat.DateMsg.fromObject(d.apps);
            }
            if (d.accounts != null) {
                if (typeof d.accounts !== "object")
                    throw TypeError(".cat.RetentionConfigurationMsg.accounts: object expected");
                m.accounts = $root.cat.DateMsg.fromObject(d.accounts);
            }
            return m;
        };

        /**
         * Creates a plain object from a RetentionConfigurationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.RetentionConfigurationMsg
         * @static
         * @param {cat.RetentionConfigurationMsg} m RetentionConfigurationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RetentionConfigurationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.notifications = null;
                d.apps = null;
                d.accounts = null;
            }
            if (m.notifications != null && m.hasOwnProperty("notifications")) {
                d.notifications = $root.cat.DateMsg.toObject(m.notifications, o);
            }
            if (m.apps != null && m.hasOwnProperty("apps")) {
                d.apps = $root.cat.DateMsg.toObject(m.apps, o);
            }
            if (m.accounts != null && m.hasOwnProperty("accounts")) {
                d.accounts = $root.cat.DateMsg.toObject(m.accounts, o);
            }
            return d;
        };

        /**
         * Converts this RetentionConfigurationMsg to JSON.
         * @function toJSON
         * @memberof cat.RetentionConfigurationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RetentionConfigurationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RetentionConfigurationMsg
         * @function getTypeUrl
         * @memberof cat.RetentionConfigurationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RetentionConfigurationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.RetentionConfigurationMsg";
        };

        return RetentionConfigurationMsg;
    })();

    /**
     * ReceiverMode enum.
     * @name cat.ReceiverMode
     * @enum {number}
     * @property {number} RECEIVER_UNKNOWN=0 RECEIVER_UNKNOWN value
     * @property {number} RECEIVER_FULL=1 RECEIVER_FULL value
     * @property {number} RECEIVER_RECEIVE=2 RECEIVER_RECEIVE value
     * @property {number} RECEIVER_PROCESS=3 RECEIVER_PROCESS value
     * @property {number} RECEIVER_STANDALONE=4 RECEIVER_STANDALONE value
     */
    cat.ReceiverMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RECEIVER_UNKNOWN"] = 0;
        values[valuesById[1] = "RECEIVER_FULL"] = 1;
        values[valuesById[2] = "RECEIVER_RECEIVE"] = 2;
        values[valuesById[3] = "RECEIVER_PROCESS"] = 3;
        values[valuesById[4] = "RECEIVER_STANDALONE"] = 4;
        return values;
    })();

    cat.ReceiverConfigurationMsg = (function() {

        /**
         * Properties of a ReceiverConfigurationMsg.
         * @memberof cat
         * @interface IReceiverConfigurationMsg
         * @property {cat.ReceiverMode|null} [mode] ReceiverConfigurationMsg mode
         */

        /**
         * Constructs a new ReceiverConfigurationMsg.
         * @memberof cat
         * @classdesc Represents a ReceiverConfigurationMsg.
         * @implements IReceiverConfigurationMsg
         * @constructor
         * @param {cat.IReceiverConfigurationMsg=} [p] Properties to set
         */
        function ReceiverConfigurationMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReceiverConfigurationMsg mode.
         * @member {cat.ReceiverMode} mode
         * @memberof cat.ReceiverConfigurationMsg
         * @instance
         */
        ReceiverConfigurationMsg.prototype.mode = 0;

        /**
         * Creates a new ReceiverConfigurationMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReceiverConfigurationMsg
         * @static
         * @param {cat.IReceiverConfigurationMsg=} [properties] Properties to set
         * @returns {cat.ReceiverConfigurationMsg} ReceiverConfigurationMsg instance
         */
        ReceiverConfigurationMsg.create = function create(properties) {
            return new ReceiverConfigurationMsg(properties);
        };

        /**
         * Encodes the specified ReceiverConfigurationMsg message. Does not implicitly {@link cat.ReceiverConfigurationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReceiverConfigurationMsg
         * @static
         * @param {cat.IReceiverConfigurationMsg} m ReceiverConfigurationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiverConfigurationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.mode != null && Object.hasOwnProperty.call(m, "mode"))
                w.uint32(8).int32(m.mode);
            return w;
        };

        /**
         * Decodes a ReceiverConfigurationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReceiverConfigurationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReceiverConfigurationMsg} ReceiverConfigurationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiverConfigurationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReceiverConfigurationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.mode = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReceiverConfigurationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReceiverConfigurationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReceiverConfigurationMsg} ReceiverConfigurationMsg
         */
        ReceiverConfigurationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReceiverConfigurationMsg)
                return d;
            var m = new $root.cat.ReceiverConfigurationMsg();
            switch (d.mode) {
            default:
                if (typeof d.mode === "number") {
                    m.mode = d.mode;
                    break;
                }
                break;
            case "RECEIVER_UNKNOWN":
            case 0:
                m.mode = 0;
                break;
            case "RECEIVER_FULL":
            case 1:
                m.mode = 1;
                break;
            case "RECEIVER_RECEIVE":
            case 2:
                m.mode = 2;
                break;
            case "RECEIVER_PROCESS":
            case 3:
                m.mode = 3;
                break;
            case "RECEIVER_STANDALONE":
            case 4:
                m.mode = 4;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a ReceiverConfigurationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReceiverConfigurationMsg
         * @static
         * @param {cat.ReceiverConfigurationMsg} m ReceiverConfigurationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiverConfigurationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.mode = o.enums === String ? "RECEIVER_UNKNOWN" : 0;
            }
            if (m.mode != null && m.hasOwnProperty("mode")) {
                d.mode = o.enums === String ? $root.cat.ReceiverMode[m.mode] === undefined ? m.mode : $root.cat.ReceiverMode[m.mode] : m.mode;
            }
            return d;
        };

        /**
         * Converts this ReceiverConfigurationMsg to JSON.
         * @function toJSON
         * @memberof cat.ReceiverConfigurationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiverConfigurationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiverConfigurationMsg
         * @function getTypeUrl
         * @memberof cat.ReceiverConfigurationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiverConfigurationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReceiverConfigurationMsg";
        };

        return ReceiverConfigurationMsg;
    })();

    cat.ConfigurationMsg = (function() {

        /**
         * Properties of a ConfigurationMsg.
         * @memberof cat
         * @interface IConfigurationMsg
         * @property {string|null} [id] ConfigurationMsg id
         * @property {cat.ConfigurationType|null} [type] ConfigurationMsg type
         * @property {number|null} [created] ConfigurationMsg created
         * @property {number|null} [lastmodified] ConfigurationMsg lastmodified
         * @property {cat.StatusType|null} [status] ConfigurationMsg status
         * @property {string|null} [systemid] ConfigurationMsg systemid
         * @property {string|null} [version] ConfigurationMsg version
         * @property {cat.IServiceConfigurationMsg|null} [service] ConfigurationMsg service
         * @property {cat.IServiceConfigurationMsg|null} [controller] ConfigurationMsg controller
         * @property {cat.ILogConfigurationMsg|null} [log] ConfigurationMsg log
         * @property {cat.IDatabaseConfigurationMsg|null} [db] ConfigurationMsg db
         * @property {cat.IFileServerConfigurationMsg|null} [files] ConfigurationMsg files
         * @property {cat.IWebServiceConfigurationMsg|null} [web] ConfigurationMsg web
         * @property {cat.IWebServiceConfigurationMsg|null} [registry] ConfigurationMsg registry
         * @property {cat.IDockerConfigurationMsg|null} [docker] ConfigurationMsg docker
         * @property {cat.IRetentionConfigurationMsg|null} [retentions] ConfigurationMsg retentions
         * @property {cat.IReceiverConfigurationMsg|null} [receiver] ConfigurationMsg receiver
         * @property {Array.<cat.ISettingMsg>|null} [settings] ConfigurationMsg settings
         * @property {string|null} [refid] ConfigurationMsg refid
         */

        /**
         * Constructs a new ConfigurationMsg.
         * @memberof cat
         * @classdesc Represents a ConfigurationMsg.
         * @implements IConfigurationMsg
         * @constructor
         * @param {cat.IConfigurationMsg=} [p] Properties to set
         */
        function ConfigurationMsg(p) {
            this.settings = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ConfigurationMsg id.
         * @member {string} id
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.id = "";

        /**
         * ConfigurationMsg type.
         * @member {cat.ConfigurationType} type
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.type = 0;

        /**
         * ConfigurationMsg created.
         * @member {number} created
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConfigurationMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConfigurationMsg status.
         * @member {cat.StatusType|null|undefined} status
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.status = null;

        /**
         * ConfigurationMsg systemid.
         * @member {string|null|undefined} systemid
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.systemid = null;

        /**
         * ConfigurationMsg version.
         * @member {string|null|undefined} version
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.version = null;

        /**
         * ConfigurationMsg service.
         * @member {cat.IServiceConfigurationMsg|null|undefined} service
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.service = null;

        /**
         * ConfigurationMsg controller.
         * @member {cat.IServiceConfigurationMsg|null|undefined} controller
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.controller = null;

        /**
         * ConfigurationMsg log.
         * @member {cat.ILogConfigurationMsg|null|undefined} log
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.log = null;

        /**
         * ConfigurationMsg db.
         * @member {cat.IDatabaseConfigurationMsg|null|undefined} db
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.db = null;

        /**
         * ConfigurationMsg files.
         * @member {cat.IFileServerConfigurationMsg|null|undefined} files
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.files = null;

        /**
         * ConfigurationMsg web.
         * @member {cat.IWebServiceConfigurationMsg|null|undefined} web
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.web = null;

        /**
         * ConfigurationMsg registry.
         * @member {cat.IWebServiceConfigurationMsg|null|undefined} registry
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.registry = null;

        /**
         * ConfigurationMsg docker.
         * @member {cat.IDockerConfigurationMsg|null|undefined} docker
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.docker = null;

        /**
         * ConfigurationMsg retentions.
         * @member {cat.IRetentionConfigurationMsg|null|undefined} retentions
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.retentions = null;

        /**
         * ConfigurationMsg receiver.
         * @member {cat.IReceiverConfigurationMsg|null|undefined} receiver
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.receiver = null;

        /**
         * ConfigurationMsg settings.
         * @member {Array.<cat.ISettingMsg>} settings
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.settings = $util.emptyArray;

        /**
         * ConfigurationMsg refid.
         * @member {string} refid
         * @memberof cat.ConfigurationMsg
         * @instance
         */
        ConfigurationMsg.prototype.refid = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_status", {
            get: $util.oneOfGetter($oneOfFields = ["status"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_systemid", {
            get: $util.oneOfGetter($oneOfFields = ["systemid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_version", {
            get: $util.oneOfGetter($oneOfFields = ["version"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_service", {
            get: $util.oneOfGetter($oneOfFields = ["service"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_controller", {
            get: $util.oneOfGetter($oneOfFields = ["controller"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_log", {
            get: $util.oneOfGetter($oneOfFields = ["log"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_db", {
            get: $util.oneOfGetter($oneOfFields = ["db"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_files", {
            get: $util.oneOfGetter($oneOfFields = ["files"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_web", {
            get: $util.oneOfGetter($oneOfFields = ["web"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_registry", {
            get: $util.oneOfGetter($oneOfFields = ["registry"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_docker", {
            get: $util.oneOfGetter($oneOfFields = ["docker"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_retentions", {
            get: $util.oneOfGetter($oneOfFields = ["retentions"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConfigurationMsg.prototype, "_receiver", {
            get: $util.oneOfGetter($oneOfFields = ["receiver"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ConfigurationMsg instance using the specified properties.
         * @function create
         * @memberof cat.ConfigurationMsg
         * @static
         * @param {cat.IConfigurationMsg=} [properties] Properties to set
         * @returns {cat.ConfigurationMsg} ConfigurationMsg instance
         */
        ConfigurationMsg.create = function create(properties) {
            return new ConfigurationMsg(properties);
        };

        /**
         * Encodes the specified ConfigurationMsg message. Does not implicitly {@link cat.ConfigurationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ConfigurationMsg
         * @static
         * @param {cat.IConfigurationMsg} m ConfigurationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigurationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(24).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(32).uint64(m.lastmodified);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(40).int32(m.status);
            if (m.systemid != null && Object.hasOwnProperty.call(m, "systemid"))
                w.uint32(50).string(m.systemid);
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(58).string(m.version);
            if (m.service != null && Object.hasOwnProperty.call(m, "service"))
                $root.cat.ServiceConfigurationMsg.encode(m.service, w.uint32(66).fork()).ldelim();
            if (m.controller != null && Object.hasOwnProperty.call(m, "controller"))
                $root.cat.ServiceConfigurationMsg.encode(m.controller, w.uint32(74).fork()).ldelim();
            if (m.log != null && Object.hasOwnProperty.call(m, "log"))
                $root.cat.LogConfigurationMsg.encode(m.log, w.uint32(82).fork()).ldelim();
            if (m.db != null && Object.hasOwnProperty.call(m, "db"))
                $root.cat.DatabaseConfigurationMsg.encode(m.db, w.uint32(90).fork()).ldelim();
            if (m.files != null && Object.hasOwnProperty.call(m, "files"))
                $root.cat.FileServerConfigurationMsg.encode(m.files, w.uint32(98).fork()).ldelim();
            if (m.web != null && Object.hasOwnProperty.call(m, "web"))
                $root.cat.WebServiceConfigurationMsg.encode(m.web, w.uint32(106).fork()).ldelim();
            if (m.registry != null && Object.hasOwnProperty.call(m, "registry"))
                $root.cat.WebServiceConfigurationMsg.encode(m.registry, w.uint32(114).fork()).ldelim();
            if (m.docker != null && Object.hasOwnProperty.call(m, "docker"))
                $root.cat.DockerConfigurationMsg.encode(m.docker, w.uint32(122).fork()).ldelim();
            if (m.retentions != null && Object.hasOwnProperty.call(m, "retentions"))
                $root.cat.RetentionConfigurationMsg.encode(m.retentions, w.uint32(130).fork()).ldelim();
            if (m.receiver != null && Object.hasOwnProperty.call(m, "receiver"))
                $root.cat.ReceiverConfigurationMsg.encode(m.receiver, w.uint32(138).fork()).ldelim();
            if (m.settings != null && m.settings.length) {
                for (var i = 0; i < m.settings.length; ++i)
                    $root.cat.SettingMsg.encode(m.settings[i], w.uint32(146).fork()).ldelim();
            }
            if (m.refid != null && Object.hasOwnProperty.call(m, "refid"))
                w.uint32(810).string(m.refid);
            return w;
        };

        /**
         * Decodes a ConfigurationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ConfigurationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ConfigurationMsg} ConfigurationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigurationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ConfigurationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.type = r.int32();
                        break;
                    }
                case 3: {
                        m.created = r.uint64();
                        break;
                    }
                case 4: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 5: {
                        m.status = r.int32();
                        break;
                    }
                case 6: {
                        m.systemid = r.string();
                        break;
                    }
                case 7: {
                        m.version = r.string();
                        break;
                    }
                case 8: {
                        m.service = $root.cat.ServiceConfigurationMsg.decode(r, r.uint32());
                        break;
                    }
                case 9: {
                        m.controller = $root.cat.ServiceConfigurationMsg.decode(r, r.uint32());
                        break;
                    }
                case 10: {
                        m.log = $root.cat.LogConfigurationMsg.decode(r, r.uint32());
                        break;
                    }
                case 11: {
                        m.db = $root.cat.DatabaseConfigurationMsg.decode(r, r.uint32());
                        break;
                    }
                case 12: {
                        m.files = $root.cat.FileServerConfigurationMsg.decode(r, r.uint32());
                        break;
                    }
                case 13: {
                        m.web = $root.cat.WebServiceConfigurationMsg.decode(r, r.uint32());
                        break;
                    }
                case 14: {
                        m.registry = $root.cat.WebServiceConfigurationMsg.decode(r, r.uint32());
                        break;
                    }
                case 15: {
                        m.docker = $root.cat.DockerConfigurationMsg.decode(r, r.uint32());
                        break;
                    }
                case 16: {
                        m.retentions = $root.cat.RetentionConfigurationMsg.decode(r, r.uint32());
                        break;
                    }
                case 17: {
                        m.receiver = $root.cat.ReceiverConfigurationMsg.decode(r, r.uint32());
                        break;
                    }
                case 18: {
                        if (!(m.settings && m.settings.length))
                            m.settings = [];
                        m.settings.push($root.cat.SettingMsg.decode(r, r.uint32()));
                        break;
                    }
                case 101: {
                        m.refid = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ConfigurationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ConfigurationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ConfigurationMsg} ConfigurationMsg
         */
        ConfigurationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ConfigurationMsg)
                return d;
            var m = new $root.cat.ConfigurationMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "CONFIGURATION_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "CONFIGURATION_SYSTEM":
            case 1:
                m.type = 1;
                break;
            case "CONFIGURATION_LOG":
            case 2:
                m.type = 2;
                break;
            case "CONFIGURATION_AUDITSTORE":
            case 5:
                m.type = 5;
                break;
            case "CONFIGURATION_DATASTORE":
            case 6:
                m.type = 6;
                break;
            case "CONFIGURATION_GATEWAY":
            case 7:
                m.type = 7;
                break;
            case "CONFIGURATION_WEBSERVER":
            case 8:
                m.type = 8;
                break;
            case "CONFIGURATION_RETENTION":
            case 12:
                m.type = 12;
                break;
            case "CONFIGURATION_RECEIVER":
            case 13:
                m.type = 13;
                break;
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ENABLED":
            case 1:
                m.status = 1;
                break;
            case "DISABLED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.systemid != null) {
                m.systemid = String(d.systemid);
            }
            if (d.version != null) {
                m.version = String(d.version);
            }
            if (d.service != null) {
                if (typeof d.service !== "object")
                    throw TypeError(".cat.ConfigurationMsg.service: object expected");
                m.service = $root.cat.ServiceConfigurationMsg.fromObject(d.service);
            }
            if (d.controller != null) {
                if (typeof d.controller !== "object")
                    throw TypeError(".cat.ConfigurationMsg.controller: object expected");
                m.controller = $root.cat.ServiceConfigurationMsg.fromObject(d.controller);
            }
            if (d.log != null) {
                if (typeof d.log !== "object")
                    throw TypeError(".cat.ConfigurationMsg.log: object expected");
                m.log = $root.cat.LogConfigurationMsg.fromObject(d.log);
            }
            if (d.db != null) {
                if (typeof d.db !== "object")
                    throw TypeError(".cat.ConfigurationMsg.db: object expected");
                m.db = $root.cat.DatabaseConfigurationMsg.fromObject(d.db);
            }
            if (d.files != null) {
                if (typeof d.files !== "object")
                    throw TypeError(".cat.ConfigurationMsg.files: object expected");
                m.files = $root.cat.FileServerConfigurationMsg.fromObject(d.files);
            }
            if (d.web != null) {
                if (typeof d.web !== "object")
                    throw TypeError(".cat.ConfigurationMsg.web: object expected");
                m.web = $root.cat.WebServiceConfigurationMsg.fromObject(d.web);
            }
            if (d.registry != null) {
                if (typeof d.registry !== "object")
                    throw TypeError(".cat.ConfigurationMsg.registry: object expected");
                m.registry = $root.cat.WebServiceConfigurationMsg.fromObject(d.registry);
            }
            if (d.docker != null) {
                if (typeof d.docker !== "object")
                    throw TypeError(".cat.ConfigurationMsg.docker: object expected");
                m.docker = $root.cat.DockerConfigurationMsg.fromObject(d.docker);
            }
            if (d.retentions != null) {
                if (typeof d.retentions !== "object")
                    throw TypeError(".cat.ConfigurationMsg.retentions: object expected");
                m.retentions = $root.cat.RetentionConfigurationMsg.fromObject(d.retentions);
            }
            if (d.receiver != null) {
                if (typeof d.receiver !== "object")
                    throw TypeError(".cat.ConfigurationMsg.receiver: object expected");
                m.receiver = $root.cat.ReceiverConfigurationMsg.fromObject(d.receiver);
            }
            if (d.settings) {
                if (!Array.isArray(d.settings))
                    throw TypeError(".cat.ConfigurationMsg.settings: array expected");
                m.settings = [];
                for (var i = 0; i < d.settings.length; ++i) {
                    if (typeof d.settings[i] !== "object")
                        throw TypeError(".cat.ConfigurationMsg.settings: object expected");
                    m.settings[i] = $root.cat.SettingMsg.fromObject(d.settings[i]);
                }
            }
            if (d.refid != null) {
                m.refid = String(d.refid);
            }
            return m;
        };

        /**
         * Creates a plain object from a ConfigurationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ConfigurationMsg
         * @static
         * @param {cat.ConfigurationMsg} m ConfigurationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfigurationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.settings = [];
            }
            if (o.defaults) {
                d.id = "";
                d.type = o.enums === String ? "CONFIGURATION_UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.refid = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.ConfigurationType[m.type] === undefined ? m.type : $root.cat.ConfigurationType[m.type] : m.type;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.StatusType[m.status] === undefined ? m.status : $root.cat.StatusType[m.status] : m.status;
                if (o.oneofs)
                    d._status = "status";
            }
            if (m.systemid != null && m.hasOwnProperty("systemid")) {
                d.systemid = m.systemid;
                if (o.oneofs)
                    d._systemid = "systemid";
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                d.version = m.version;
                if (o.oneofs)
                    d._version = "version";
            }
            if (m.service != null && m.hasOwnProperty("service")) {
                d.service = $root.cat.ServiceConfigurationMsg.toObject(m.service, o);
                if (o.oneofs)
                    d._service = "service";
            }
            if (m.controller != null && m.hasOwnProperty("controller")) {
                d.controller = $root.cat.ServiceConfigurationMsg.toObject(m.controller, o);
                if (o.oneofs)
                    d._controller = "controller";
            }
            if (m.log != null && m.hasOwnProperty("log")) {
                d.log = $root.cat.LogConfigurationMsg.toObject(m.log, o);
                if (o.oneofs)
                    d._log = "log";
            }
            if (m.db != null && m.hasOwnProperty("db")) {
                d.db = $root.cat.DatabaseConfigurationMsg.toObject(m.db, o);
                if (o.oneofs)
                    d._db = "db";
            }
            if (m.files != null && m.hasOwnProperty("files")) {
                d.files = $root.cat.FileServerConfigurationMsg.toObject(m.files, o);
                if (o.oneofs)
                    d._files = "files";
            }
            if (m.web != null && m.hasOwnProperty("web")) {
                d.web = $root.cat.WebServiceConfigurationMsg.toObject(m.web, o);
                if (o.oneofs)
                    d._web = "web";
            }
            if (m.registry != null && m.hasOwnProperty("registry")) {
                d.registry = $root.cat.WebServiceConfigurationMsg.toObject(m.registry, o);
                if (o.oneofs)
                    d._registry = "registry";
            }
            if (m.docker != null && m.hasOwnProperty("docker")) {
                d.docker = $root.cat.DockerConfigurationMsg.toObject(m.docker, o);
                if (o.oneofs)
                    d._docker = "docker";
            }
            if (m.retentions != null && m.hasOwnProperty("retentions")) {
                d.retentions = $root.cat.RetentionConfigurationMsg.toObject(m.retentions, o);
                if (o.oneofs)
                    d._retentions = "retentions";
            }
            if (m.receiver != null && m.hasOwnProperty("receiver")) {
                d.receiver = $root.cat.ReceiverConfigurationMsg.toObject(m.receiver, o);
                if (o.oneofs)
                    d._receiver = "receiver";
            }
            if (m.settings && m.settings.length) {
                d.settings = [];
                for (var j = 0; j < m.settings.length; ++j) {
                    d.settings[j] = $root.cat.SettingMsg.toObject(m.settings[j], o);
                }
            }
            if (m.refid != null && m.hasOwnProperty("refid")) {
                d.refid = m.refid;
            }
            return d;
        };

        /**
         * Converts this ConfigurationMsg to JSON.
         * @function toJSON
         * @memberof cat.ConfigurationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfigurationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConfigurationMsg
         * @function getTypeUrl
         * @memberof cat.ConfigurationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfigurationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ConfigurationMsg";
        };

        return ConfigurationMsg;
    })();

    /**
     * Settings enum.
     * @name cat.Settings
     * @enum {number}
     * @property {number} SETTING_UNKNOWN=0 SETTING_UNKNOWN value
     * @property {number} SETTING_MD5HASH=1 SETTING_MD5HASH value
     * @property {number} SETTING_REPORTER=2 SETTING_REPORTER value
     * @property {number} SETTING_LANGUAGES=5 SETTING_LANGUAGES value
     * @property {number} SETTING_KEYCLOAK=6 SETTING_KEYCLOAK value
     * @property {number} SETTING_FILE_ENCRYPTION=7 SETTING_FILE_ENCRYPTION value
     * @property {number} SETTING_APK_PATCH=8 SETTING_APK_PATCH value
     * @property {number} SETTING_PASSWORD_POLICY=9 SETTING_PASSWORD_POLICY value
     */
    cat.Settings = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SETTING_UNKNOWN"] = 0;
        values[valuesById[1] = "SETTING_MD5HASH"] = 1;
        values[valuesById[2] = "SETTING_REPORTER"] = 2;
        values[valuesById[5] = "SETTING_LANGUAGES"] = 5;
        values[valuesById[6] = "SETTING_KEYCLOAK"] = 6;
        values[valuesById[7] = "SETTING_FILE_ENCRYPTION"] = 7;
        values[valuesById[8] = "SETTING_APK_PATCH"] = 8;
        values[valuesById[9] = "SETTING_PASSWORD_POLICY"] = 9;
        return values;
    })();

    cat.SettingMsg = (function() {

        /**
         * Properties of a SettingMsg.
         * @memberof cat
         * @interface ISettingMsg
         * @property {cat.Settings|null} [id] SettingMsg id
         * @property {number|null} [created] SettingMsg created
         * @property {number|null} [lastmodified] SettingMsg lastmodified
         * @property {boolean|null} [enabled] SettingMsg enabled
         * @property {boolean|null} [applyretroactively] SettingMsg applyretroactively
         * @property {Array.<string>|null} [value] SettingMsg value
         */

        /**
         * Constructs a new SettingMsg.
         * @memberof cat
         * @classdesc Represents a SettingMsg.
         * @implements ISettingMsg
         * @constructor
         * @param {cat.ISettingMsg=} [p] Properties to set
         */
        function SettingMsg(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SettingMsg id.
         * @member {cat.Settings} id
         * @memberof cat.SettingMsg
         * @instance
         */
        SettingMsg.prototype.id = 0;

        /**
         * SettingMsg created.
         * @member {number} created
         * @memberof cat.SettingMsg
         * @instance
         */
        SettingMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * SettingMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.SettingMsg
         * @instance
         */
        SettingMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * SettingMsg enabled.
         * @member {boolean} enabled
         * @memberof cat.SettingMsg
         * @instance
         */
        SettingMsg.prototype.enabled = false;

        /**
         * SettingMsg applyretroactively.
         * @member {boolean|null|undefined} applyretroactively
         * @memberof cat.SettingMsg
         * @instance
         */
        SettingMsg.prototype.applyretroactively = null;

        /**
         * SettingMsg value.
         * @member {Array.<string>} value
         * @memberof cat.SettingMsg
         * @instance
         */
        SettingMsg.prototype.value = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(SettingMsg.prototype, "_applyretroactively", {
            get: $util.oneOfGetter($oneOfFields = ["applyretroactively"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SettingMsg instance using the specified properties.
         * @function create
         * @memberof cat.SettingMsg
         * @static
         * @param {cat.ISettingMsg=} [properties] Properties to set
         * @returns {cat.SettingMsg} SettingMsg instance
         */
        SettingMsg.create = function create(properties) {
            return new SettingMsg(properties);
        };

        /**
         * Encodes the specified SettingMsg message. Does not implicitly {@link cat.SettingMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.SettingMsg
         * @static
         * @param {cat.ISettingMsg} m SettingMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SettingMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(8).int32(m.id);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(16).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(24).uint64(m.lastmodified);
            if (m.enabled != null && Object.hasOwnProperty.call(m, "enabled"))
                w.uint32(32).bool(m.enabled);
            if (m.applyretroactively != null && Object.hasOwnProperty.call(m, "applyretroactively"))
                w.uint32(40).bool(m.applyretroactively);
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    w.uint32(50).string(m.value[i]);
            }
            return w;
        };

        /**
         * Decodes a SettingMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.SettingMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.SettingMsg} SettingMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SettingMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.SettingMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.int32();
                        break;
                    }
                case 2: {
                        m.created = r.uint64();
                        break;
                    }
                case 3: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 4: {
                        m.enabled = r.bool();
                        break;
                    }
                case 5: {
                        m.applyretroactively = r.bool();
                        break;
                    }
                case 6: {
                        if (!(m.value && m.value.length))
                            m.value = [];
                        m.value.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a SettingMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.SettingMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.SettingMsg} SettingMsg
         */
        SettingMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.SettingMsg)
                return d;
            var m = new $root.cat.SettingMsg();
            switch (d.id) {
            default:
                if (typeof d.id === "number") {
                    m.id = d.id;
                    break;
                }
                break;
            case "SETTING_UNKNOWN":
            case 0:
                m.id = 0;
                break;
            case "SETTING_MD5HASH":
            case 1:
                m.id = 1;
                break;
            case "SETTING_REPORTER":
            case 2:
                m.id = 2;
                break;
            case "SETTING_LANGUAGES":
            case 5:
                m.id = 5;
                break;
            case "SETTING_KEYCLOAK":
            case 6:
                m.id = 6;
                break;
            case "SETTING_FILE_ENCRYPTION":
            case 7:
                m.id = 7;
                break;
            case "SETTING_APK_PATCH":
            case 8:
                m.id = 8;
                break;
            case "SETTING_PASSWORD_POLICY":
            case 9:
                m.id = 9;
                break;
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.enabled != null) {
                m.enabled = Boolean(d.enabled);
            }
            if (d.applyretroactively != null) {
                m.applyretroactively = Boolean(d.applyretroactively);
            }
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".cat.SettingMsg.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    m.value[i] = String(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a SettingMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.SettingMsg
         * @static
         * @param {cat.SettingMsg} m SettingMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SettingMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (o.defaults) {
                d.id = o.enums === String ? "SETTING_UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.enabled = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = o.enums === String ? $root.cat.Settings[m.id] === undefined ? m.id : $root.cat.Settings[m.id] : m.id;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.enabled != null && m.hasOwnProperty("enabled")) {
                d.enabled = m.enabled;
            }
            if (m.applyretroactively != null && m.hasOwnProperty("applyretroactively")) {
                d.applyretroactively = m.applyretroactively;
                if (o.oneofs)
                    d._applyretroactively = "applyretroactively";
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = m.value[j];
                }
            }
            return d;
        };

        /**
         * Converts this SettingMsg to JSON.
         * @function toJSON
         * @memberof cat.SettingMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SettingMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SettingMsg
         * @function getTypeUrl
         * @memberof cat.SettingMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SettingMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.SettingMsg";
        };

        return SettingMsg;
    })();

    cat.PasswordPolicyMsg = (function() {

        /**
         * Properties of a PasswordPolicyMsg.
         * @memberof cat
         * @interface IPasswordPolicyMsg
         * @property {number|null} [minlength] PasswordPolicyMsg minlength
         * @property {number|null} [maxlength] PasswordPolicyMsg maxlength
         * @property {number|null} [lowercase] PasswordPolicyMsg lowercase
         * @property {number|null} [uppercase] PasswordPolicyMsg uppercase
         * @property {number|null} [digits] PasswordPolicyMsg digits
         * @property {number|null} [symbols] PasswordPolicyMsg symbols
         * @property {boolean|null} [enforcechange] PasswordPolicyMsg enforcechange
         */

        /**
         * Constructs a new PasswordPolicyMsg.
         * @memberof cat
         * @classdesc Represents a PasswordPolicyMsg.
         * @implements IPasswordPolicyMsg
         * @constructor
         * @param {cat.IPasswordPolicyMsg=} [p] Properties to set
         */
        function PasswordPolicyMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PasswordPolicyMsg minlength.
         * @member {number} minlength
         * @memberof cat.PasswordPolicyMsg
         * @instance
         */
        PasswordPolicyMsg.prototype.minlength = 0;

        /**
         * PasswordPolicyMsg maxlength.
         * @member {number} maxlength
         * @memberof cat.PasswordPolicyMsg
         * @instance
         */
        PasswordPolicyMsg.prototype.maxlength = 0;

        /**
         * PasswordPolicyMsg lowercase.
         * @member {number} lowercase
         * @memberof cat.PasswordPolicyMsg
         * @instance
         */
        PasswordPolicyMsg.prototype.lowercase = 0;

        /**
         * PasswordPolicyMsg uppercase.
         * @member {number} uppercase
         * @memberof cat.PasswordPolicyMsg
         * @instance
         */
        PasswordPolicyMsg.prototype.uppercase = 0;

        /**
         * PasswordPolicyMsg digits.
         * @member {number} digits
         * @memberof cat.PasswordPolicyMsg
         * @instance
         */
        PasswordPolicyMsg.prototype.digits = 0;

        /**
         * PasswordPolicyMsg symbols.
         * @member {number} symbols
         * @memberof cat.PasswordPolicyMsg
         * @instance
         */
        PasswordPolicyMsg.prototype.symbols = 0;

        /**
         * PasswordPolicyMsg enforcechange.
         * @member {boolean} enforcechange
         * @memberof cat.PasswordPolicyMsg
         * @instance
         */
        PasswordPolicyMsg.prototype.enforcechange = false;

        /**
         * Creates a new PasswordPolicyMsg instance using the specified properties.
         * @function create
         * @memberof cat.PasswordPolicyMsg
         * @static
         * @param {cat.IPasswordPolicyMsg=} [properties] Properties to set
         * @returns {cat.PasswordPolicyMsg} PasswordPolicyMsg instance
         */
        PasswordPolicyMsg.create = function create(properties) {
            return new PasswordPolicyMsg(properties);
        };

        /**
         * Encodes the specified PasswordPolicyMsg message. Does not implicitly {@link cat.PasswordPolicyMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PasswordPolicyMsg
         * @static
         * @param {cat.IPasswordPolicyMsg} m PasswordPolicyMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PasswordPolicyMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.minlength != null && Object.hasOwnProperty.call(m, "minlength"))
                w.uint32(808).uint32(m.minlength);
            if (m.maxlength != null && Object.hasOwnProperty.call(m, "maxlength"))
                w.uint32(816).uint32(m.maxlength);
            if (m.lowercase != null && Object.hasOwnProperty.call(m, "lowercase"))
                w.uint32(824).uint32(m.lowercase);
            if (m.uppercase != null && Object.hasOwnProperty.call(m, "uppercase"))
                w.uint32(832).uint32(m.uppercase);
            if (m.digits != null && Object.hasOwnProperty.call(m, "digits"))
                w.uint32(840).uint32(m.digits);
            if (m.symbols != null && Object.hasOwnProperty.call(m, "symbols"))
                w.uint32(848).uint32(m.symbols);
            if (m.enforcechange != null && Object.hasOwnProperty.call(m, "enforcechange"))
                w.uint32(880).bool(m.enforcechange);
            return w;
        };

        /**
         * Decodes a PasswordPolicyMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PasswordPolicyMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PasswordPolicyMsg} PasswordPolicyMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PasswordPolicyMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PasswordPolicyMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 101: {
                        m.minlength = r.uint32();
                        break;
                    }
                case 102: {
                        m.maxlength = r.uint32();
                        break;
                    }
                case 103: {
                        m.lowercase = r.uint32();
                        break;
                    }
                case 104: {
                        m.uppercase = r.uint32();
                        break;
                    }
                case 105: {
                        m.digits = r.uint32();
                        break;
                    }
                case 106: {
                        m.symbols = r.uint32();
                        break;
                    }
                case 110: {
                        m.enforcechange = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PasswordPolicyMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PasswordPolicyMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PasswordPolicyMsg} PasswordPolicyMsg
         */
        PasswordPolicyMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PasswordPolicyMsg)
                return d;
            var m = new $root.cat.PasswordPolicyMsg();
            if (d.minlength != null) {
                m.minlength = d.minlength >>> 0;
            }
            if (d.maxlength != null) {
                m.maxlength = d.maxlength >>> 0;
            }
            if (d.lowercase != null) {
                m.lowercase = d.lowercase >>> 0;
            }
            if (d.uppercase != null) {
                m.uppercase = d.uppercase >>> 0;
            }
            if (d.digits != null) {
                m.digits = d.digits >>> 0;
            }
            if (d.symbols != null) {
                m.symbols = d.symbols >>> 0;
            }
            if (d.enforcechange != null) {
                m.enforcechange = Boolean(d.enforcechange);
            }
            return m;
        };

        /**
         * Creates a plain object from a PasswordPolicyMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PasswordPolicyMsg
         * @static
         * @param {cat.PasswordPolicyMsg} m PasswordPolicyMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PasswordPolicyMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.minlength = 0;
                d.maxlength = 0;
                d.lowercase = 0;
                d.uppercase = 0;
                d.digits = 0;
                d.symbols = 0;
                d.enforcechange = false;
            }
            if (m.minlength != null && m.hasOwnProperty("minlength")) {
                d.minlength = m.minlength;
            }
            if (m.maxlength != null && m.hasOwnProperty("maxlength")) {
                d.maxlength = m.maxlength;
            }
            if (m.lowercase != null && m.hasOwnProperty("lowercase")) {
                d.lowercase = m.lowercase;
            }
            if (m.uppercase != null && m.hasOwnProperty("uppercase")) {
                d.uppercase = m.uppercase;
            }
            if (m.digits != null && m.hasOwnProperty("digits")) {
                d.digits = m.digits;
            }
            if (m.symbols != null && m.hasOwnProperty("symbols")) {
                d.symbols = m.symbols;
            }
            if (m.enforcechange != null && m.hasOwnProperty("enforcechange")) {
                d.enforcechange = m.enforcechange;
            }
            return d;
        };

        /**
         * Converts this PasswordPolicyMsg to JSON.
         * @function toJSON
         * @memberof cat.PasswordPolicyMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PasswordPolicyMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PasswordPolicyMsg
         * @function getTypeUrl
         * @memberof cat.PasswordPolicyMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PasswordPolicyMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PasswordPolicyMsg";
        };

        return PasswordPolicyMsg;
    })();

    /**
     * CertificateType enum.
     * @name cat.CertificateType
     * @enum {number}
     * @property {number} CERTIFICATE_UNKNOWN=0 CERTIFICATE_UNKNOWN value
     * @property {number} CERTIFICATE_TSA=1 CERTIFICATE_TSA value
     * @property {number} CERTIFICATE_JKS=2 CERTIFICATE_JKS value
     */
    cat.CertificateType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CERTIFICATE_UNKNOWN"] = 0;
        values[valuesById[1] = "CERTIFICATE_TSA"] = 1;
        values[valuesById[2] = "CERTIFICATE_JKS"] = 2;
        return values;
    })();

    cat.CertificateMsg = (function() {

        /**
         * Properties of a CertificateMsg.
         * @memberof cat
         * @interface ICertificateMsg
         * @property {string|null} [id] CertificateMsg id
         * @property {string|null} [name] CertificateMsg name
         * @property {cat.CertificateType|null} [type] CertificateMsg type
         * @property {number|null} [created] CertificateMsg created
         * @property {number|null} [lastmodified] CertificateMsg lastmodified
         * @property {string|null} [key] CertificateMsg key
         * @property {string|null} [cert] CertificateMsg cert
         * @property {string|null} [store] CertificateMsg store
         * @property {number|null} [startdate] CertificateMsg startdate
         * @property {number|null} [endate] CertificateMsg endate
         */

        /**
         * Constructs a new CertificateMsg.
         * @memberof cat
         * @classdesc Represents a CertificateMsg.
         * @implements ICertificateMsg
         * @constructor
         * @param {cat.ICertificateMsg=} [p] Properties to set
         */
        function CertificateMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CertificateMsg id.
         * @member {string} id
         * @memberof cat.CertificateMsg
         * @instance
         */
        CertificateMsg.prototype.id = "";

        /**
         * CertificateMsg name.
         * @member {string} name
         * @memberof cat.CertificateMsg
         * @instance
         */
        CertificateMsg.prototype.name = "";

        /**
         * CertificateMsg type.
         * @member {cat.CertificateType} type
         * @memberof cat.CertificateMsg
         * @instance
         */
        CertificateMsg.prototype.type = 0;

        /**
         * CertificateMsg created.
         * @member {number} created
         * @memberof cat.CertificateMsg
         * @instance
         */
        CertificateMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CertificateMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.CertificateMsg
         * @instance
         */
        CertificateMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CertificateMsg key.
         * @member {string|null|undefined} key
         * @memberof cat.CertificateMsg
         * @instance
         */
        CertificateMsg.prototype.key = null;

        /**
         * CertificateMsg cert.
         * @member {string|null|undefined} cert
         * @memberof cat.CertificateMsg
         * @instance
         */
        CertificateMsg.prototype.cert = null;

        /**
         * CertificateMsg store.
         * @member {string|null|undefined} store
         * @memberof cat.CertificateMsg
         * @instance
         */
        CertificateMsg.prototype.store = null;

        /**
         * CertificateMsg startdate.
         * @member {number} startdate
         * @memberof cat.CertificateMsg
         * @instance
         */
        CertificateMsg.prototype.startdate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CertificateMsg endate.
         * @member {number} endate
         * @memberof cat.CertificateMsg
         * @instance
         */
        CertificateMsg.prototype.endate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(CertificateMsg.prototype, "_key", {
            get: $util.oneOfGetter($oneOfFields = ["key"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(CertificateMsg.prototype, "_cert", {
            get: $util.oneOfGetter($oneOfFields = ["cert"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(CertificateMsg.prototype, "_store", {
            get: $util.oneOfGetter($oneOfFields = ["store"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CertificateMsg instance using the specified properties.
         * @function create
         * @memberof cat.CertificateMsg
         * @static
         * @param {cat.ICertificateMsg=} [properties] Properties to set
         * @returns {cat.CertificateMsg} CertificateMsg instance
         */
        CertificateMsg.create = function create(properties) {
            return new CertificateMsg(properties);
        };

        /**
         * Encodes the specified CertificateMsg message. Does not implicitly {@link cat.CertificateMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.CertificateMsg
         * @static
         * @param {cat.ICertificateMsg} m CertificateMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CertificateMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(32).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(40).uint64(m.lastmodified);
            if (m.key != null && Object.hasOwnProperty.call(m, "key"))
                w.uint32(50).string(m.key);
            if (m.cert != null && Object.hasOwnProperty.call(m, "cert"))
                w.uint32(58).string(m.cert);
            if (m.store != null && Object.hasOwnProperty.call(m, "store"))
                w.uint32(66).string(m.store);
            if (m.startdate != null && Object.hasOwnProperty.call(m, "startdate"))
                w.uint32(72).uint64(m.startdate);
            if (m.endate != null && Object.hasOwnProperty.call(m, "endate"))
                w.uint32(80).uint64(m.endate);
            return w;
        };

        /**
         * Decodes a CertificateMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.CertificateMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.CertificateMsg} CertificateMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CertificateMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.CertificateMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.type = r.int32();
                        break;
                    }
                case 4: {
                        m.created = r.uint64();
                        break;
                    }
                case 5: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 6: {
                        m.key = r.string();
                        break;
                    }
                case 7: {
                        m.cert = r.string();
                        break;
                    }
                case 8: {
                        m.store = r.string();
                        break;
                    }
                case 9: {
                        m.startdate = r.uint64();
                        break;
                    }
                case 10: {
                        m.endate = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CertificateMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.CertificateMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.CertificateMsg} CertificateMsg
         */
        CertificateMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.CertificateMsg)
                return d;
            var m = new $root.cat.CertificateMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "CERTIFICATE_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "CERTIFICATE_TSA":
            case 1:
                m.type = 1;
                break;
            case "CERTIFICATE_JKS":
            case 2:
                m.type = 2;
                break;
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.key != null) {
                m.key = String(d.key);
            }
            if (d.cert != null) {
                m.cert = String(d.cert);
            }
            if (d.store != null) {
                m.store = String(d.store);
            }
            if (d.startdate != null) {
                if ($util.Long)
                    (m.startdate = $util.Long.fromValue(d.startdate)).unsigned = true;
                else if (typeof d.startdate === "string")
                    m.startdate = parseInt(d.startdate, 10);
                else if (typeof d.startdate === "number")
                    m.startdate = d.startdate;
                else if (typeof d.startdate === "object")
                    m.startdate = new $util.LongBits(d.startdate.low >>> 0, d.startdate.high >>> 0).toNumber(true);
            }
            if (d.endate != null) {
                if ($util.Long)
                    (m.endate = $util.Long.fromValue(d.endate)).unsigned = true;
                else if (typeof d.endate === "string")
                    m.endate = parseInt(d.endate, 10);
                else if (typeof d.endate === "number")
                    m.endate = d.endate;
                else if (typeof d.endate === "object")
                    m.endate = new $util.LongBits(d.endate.low >>> 0, d.endate.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a CertificateMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.CertificateMsg
         * @static
         * @param {cat.CertificateMsg} m CertificateMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CertificateMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.type = o.enums === String ? "CERTIFICATE_UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.startdate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startdate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.endate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endate = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.CertificateType[m.type] === undefined ? m.type : $root.cat.CertificateType[m.type] : m.type;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.key != null && m.hasOwnProperty("key")) {
                d.key = m.key;
                if (o.oneofs)
                    d._key = "key";
            }
            if (m.cert != null && m.hasOwnProperty("cert")) {
                d.cert = m.cert;
                if (o.oneofs)
                    d._cert = "cert";
            }
            if (m.store != null && m.hasOwnProperty("store")) {
                d.store = m.store;
                if (o.oneofs)
                    d._store = "store";
            }
            if (m.startdate != null && m.hasOwnProperty("startdate")) {
                if (typeof m.startdate === "number")
                    d.startdate = o.longs === String ? String(m.startdate) : m.startdate;
                else
                    d.startdate = o.longs === String ? $util.Long.prototype.toString.call(m.startdate) : o.longs === Number ? new $util.LongBits(m.startdate.low >>> 0, m.startdate.high >>> 0).toNumber(true) : m.startdate;
            }
            if (m.endate != null && m.hasOwnProperty("endate")) {
                if (typeof m.endate === "number")
                    d.endate = o.longs === String ? String(m.endate) : m.endate;
                else
                    d.endate = o.longs === String ? $util.Long.prototype.toString.call(m.endate) : o.longs === Number ? new $util.LongBits(m.endate.low >>> 0, m.endate.high >>> 0).toNumber(true) : m.endate;
            }
            return d;
        };

        /**
         * Converts this CertificateMsg to JSON.
         * @function toJSON
         * @memberof cat.CertificateMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CertificateMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CertificateMsg
         * @function getTypeUrl
         * @memberof cat.CertificateMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CertificateMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.CertificateMsg";
        };

        return CertificateMsg;
    })();

    /**
     * AppearanceMode enum.
     * @name cat.AppearanceMode
     * @enum {number}
     * @property {number} APPEARANCE_UNKNOWN=0 APPEARANCE_UNKNOWN value
     * @property {number} APPEARANCE_LIGHT=1 APPEARANCE_LIGHT value
     * @property {number} APPEARANCE_DARK=2 APPEARANCE_DARK value
     */
    cat.AppearanceMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "APPEARANCE_UNKNOWN"] = 0;
        values[valuesById[1] = "APPEARANCE_LIGHT"] = 1;
        values[valuesById[2] = "APPEARANCE_DARK"] = 2;
        return values;
    })();

    /**
     * Popover enum.
     * @name cat.Popover
     * @enum {number}
     * @property {number} POPOVER_UNKNOWN=0 POPOVER_UNKNOWN value
     * @property {number} POPOVER_WELCOME=1 POPOVER_WELCOME value
     * @property {number} POPOVER_DASHBOARD_TOUR=2 POPOVER_DASHBOARD_TOUR value
     * @property {number} POPOVER_PROFILE_DASHBOARD_TOUR=3 POPOVER_PROFILE_DASHBOARD_TOUR value
     * @property {number} POPOVER_DEVICE_DASHBOARD_TOUR=4 POPOVER_DEVICE_DASHBOARD_TOUR value
     * @property {number} POPOVER_CONVERSATION_TOUR=5 POPOVER_CONVERSATION_TOUR value
     * @property {number} POPOVER_CREATE_REPORT_TOUR=6 POPOVER_CREATE_REPORT_TOUR value
     */
    cat.Popover = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "POPOVER_UNKNOWN"] = 0;
        values[valuesById[1] = "POPOVER_WELCOME"] = 1;
        values[valuesById[2] = "POPOVER_DASHBOARD_TOUR"] = 2;
        values[valuesById[3] = "POPOVER_PROFILE_DASHBOARD_TOUR"] = 3;
        values[valuesById[4] = "POPOVER_DEVICE_DASHBOARD_TOUR"] = 4;
        values[valuesById[5] = "POPOVER_CONVERSATION_TOUR"] = 5;
        values[valuesById[6] = "POPOVER_CREATE_REPORT_TOUR"] = 6;
        return values;
    })();

    cat.UserMsg = (function() {

        /**
         * Properties of a UserMsg.
         * @memberof cat
         * @interface IUserMsg
         * @property {string|null} [id] UserMsg id
         * @property {string|null} [username] UserMsg username
         * @property {string|null} [password] UserMsg password
         * @property {number|null} [created] UserMsg created
         * @property {number|null} [lastmodified] UserMsg lastmodified
         * @property {number|null} [licenseaccepted] UserMsg licenseaccepted
         * @property {number|null} [passwordexpireson] UserMsg passwordexpireson
         * @property {boolean|null} [passwordreset] UserMsg passwordreset
         * @property {cat.StatusType|null} [status] UserMsg status
         * @property {string|null} [fullname] UserMsg fullname
         * @property {string|null} [email] UserMsg email
         * @property {string|null} [organization] UserMsg organization
         * @property {cat.SupportedLanguages|null} [language] UserMsg language
         * @property {cat.AppearanceMode|null} [appearancemode] UserMsg appearancemode
         * @property {Array.<cat.Popover>|null} [popovers] UserMsg popovers
         * @property {string|null} [lastprofileid] UserMsg lastprofileid
         * @property {Array.<string>|null} [lastconversationids] UserMsg lastconversationids
         * @property {string|null} [newpassword] UserMsg newpassword
         * @property {Array.<cat.IRoleMsg>|null} [roles] UserMsg roles
         * @property {Array.<cat.IThemeMsg>|null} [themes] UserMsg themes
         * @property {Array.<cat.ICaseMsg>|null} [cases] UserMsg cases
         * @property {Array.<cat.IProfileMsg>|null} [profiles] UserMsg profiles
         * @property {Array.<cat.IUserMsg>|null} [assignedusers] UserMsg assignedusers
         * @property {cat.IDeviceMsg|null} [device] UserMsg device
         * @property {Array.<cat.ISettingMsg>|null} [settings] UserMsg settings
         * @property {Array.<cat.ITerminologyMsg>|null} [terminology] UserMsg terminology
         * @property {boolean|null} [searchresultaccount] UserMsg searchresultaccount
         * @property {Array.<cat.IDeviceMsg>|null} [devices] UserMsg devices
         * @property {cat.ProfileFilterType|null} [profilefilter] UserMsg profilefilter
         */

        /**
         * Constructs a new UserMsg.
         * @memberof cat
         * @classdesc Represents a UserMsg.
         * @implements IUserMsg
         * @constructor
         * @param {cat.IUserMsg=} [p] Properties to set
         */
        function UserMsg(p) {
            this.popovers = [];
            this.lastconversationids = [];
            this.roles = [];
            this.themes = [];
            this.cases = [];
            this.profiles = [];
            this.assignedusers = [];
            this.settings = [];
            this.terminology = [];
            this.devices = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserMsg id.
         * @member {string} id
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.id = "";

        /**
         * UserMsg username.
         * @member {string} username
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.username = "";

        /**
         * UserMsg password.
         * @member {string} password
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.password = "";

        /**
         * UserMsg created.
         * @member {number} created
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UserMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UserMsg licenseaccepted.
         * @member {number} licenseaccepted
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.licenseaccepted = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UserMsg passwordexpireson.
         * @member {number} passwordexpireson
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.passwordexpireson = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UserMsg passwordreset.
         * @member {boolean} passwordreset
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.passwordreset = false;

        /**
         * UserMsg status.
         * @member {cat.StatusType} status
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.status = 0;

        /**
         * UserMsg fullname.
         * @member {string} fullname
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.fullname = "";

        /**
         * UserMsg email.
         * @member {string} email
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.email = "";

        /**
         * UserMsg organization.
         * @member {string|null|undefined} organization
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.organization = null;

        /**
         * UserMsg language.
         * @member {cat.SupportedLanguages} language
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.language = 0;

        /**
         * UserMsg appearancemode.
         * @member {cat.AppearanceMode} appearancemode
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.appearancemode = 0;

        /**
         * UserMsg popovers.
         * @member {Array.<cat.Popover>} popovers
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.popovers = $util.emptyArray;

        /**
         * UserMsg lastprofileid.
         * @member {string} lastprofileid
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.lastprofileid = "";

        /**
         * UserMsg lastconversationids.
         * @member {Array.<string>} lastconversationids
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.lastconversationids = $util.emptyArray;

        /**
         * UserMsg newpassword.
         * @member {string} newpassword
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.newpassword = "";

        /**
         * UserMsg roles.
         * @member {Array.<cat.IRoleMsg>} roles
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.roles = $util.emptyArray;

        /**
         * UserMsg themes.
         * @member {Array.<cat.IThemeMsg>} themes
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.themes = $util.emptyArray;

        /**
         * UserMsg cases.
         * @member {Array.<cat.ICaseMsg>} cases
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.cases = $util.emptyArray;

        /**
         * UserMsg profiles.
         * @member {Array.<cat.IProfileMsg>} profiles
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.profiles = $util.emptyArray;

        /**
         * UserMsg assignedusers.
         * @member {Array.<cat.IUserMsg>} assignedusers
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.assignedusers = $util.emptyArray;

        /**
         * UserMsg device.
         * @member {cat.IDeviceMsg|null|undefined} device
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.device = null;

        /**
         * UserMsg settings.
         * @member {Array.<cat.ISettingMsg>} settings
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.settings = $util.emptyArray;

        /**
         * UserMsg terminology.
         * @member {Array.<cat.ITerminologyMsg>} terminology
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.terminology = $util.emptyArray;

        /**
         * UserMsg searchresultaccount.
         * @member {boolean|null|undefined} searchresultaccount
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.searchresultaccount = null;

        /**
         * UserMsg devices.
         * @member {Array.<cat.IDeviceMsg>} devices
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.devices = $util.emptyArray;

        /**
         * UserMsg profilefilter.
         * @member {cat.ProfileFilterType|null|undefined} profilefilter
         * @memberof cat.UserMsg
         * @instance
         */
        UserMsg.prototype.profilefilter = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(UserMsg.prototype, "_organization", {
            get: $util.oneOfGetter($oneOfFields = ["organization"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(UserMsg.prototype, "_searchresultaccount", {
            get: $util.oneOfGetter($oneOfFields = ["searchresultaccount"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(UserMsg.prototype, "_profilefilter", {
            get: $util.oneOfGetter($oneOfFields = ["profilefilter"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UserMsg instance using the specified properties.
         * @function create
         * @memberof cat.UserMsg
         * @static
         * @param {cat.IUserMsg=} [properties] Properties to set
         * @returns {cat.UserMsg} UserMsg instance
         */
        UserMsg.create = function create(properties) {
            return new UserMsg(properties);
        };

        /**
         * Encodes the specified UserMsg message. Does not implicitly {@link cat.UserMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.UserMsg
         * @static
         * @param {cat.IUserMsg} m UserMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(18).string(m.username);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(26).string(m.password);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(32).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(40).uint64(m.lastmodified);
            if (m.licenseaccepted != null && Object.hasOwnProperty.call(m, "licenseaccepted"))
                w.uint32(48).uint64(m.licenseaccepted);
            if (m.passwordexpireson != null && Object.hasOwnProperty.call(m, "passwordexpireson"))
                w.uint32(56).uint64(m.passwordexpireson);
            if (m.passwordreset != null && Object.hasOwnProperty.call(m, "passwordreset"))
                w.uint32(64).bool(m.passwordreset);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(72).int32(m.status);
            if (m.fullname != null && Object.hasOwnProperty.call(m, "fullname"))
                w.uint32(82).string(m.fullname);
            if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                w.uint32(90).string(m.email);
            if (m.organization != null && Object.hasOwnProperty.call(m, "organization"))
                w.uint32(98).string(m.organization);
            if (m.language != null && Object.hasOwnProperty.call(m, "language"))
                w.uint32(104).int32(m.language);
            if (m.appearancemode != null && Object.hasOwnProperty.call(m, "appearancemode"))
                w.uint32(112).int32(m.appearancemode);
            if (m.popovers != null && m.popovers.length) {
                w.uint32(122).fork();
                for (var i = 0; i < m.popovers.length; ++i)
                    w.int32(m.popovers[i]);
                w.ldelim();
            }
            if (m.lastprofileid != null && Object.hasOwnProperty.call(m, "lastprofileid"))
                w.uint32(130).string(m.lastprofileid);
            if (m.lastconversationids != null && m.lastconversationids.length) {
                for (var i = 0; i < m.lastconversationids.length; ++i)
                    w.uint32(138).string(m.lastconversationids[i]);
            }
            if (m.newpassword != null && Object.hasOwnProperty.call(m, "newpassword"))
                w.uint32(810).string(m.newpassword);
            if (m.roles != null && m.roles.length) {
                for (var i = 0; i < m.roles.length; ++i)
                    $root.cat.RoleMsg.encode(m.roles[i], w.uint32(818).fork()).ldelim();
            }
            if (m.themes != null && m.themes.length) {
                for (var i = 0; i < m.themes.length; ++i)
                    $root.cat.ThemeMsg.encode(m.themes[i], w.uint32(826).fork()).ldelim();
            }
            if (m.cases != null && m.cases.length) {
                for (var i = 0; i < m.cases.length; ++i)
                    $root.cat.CaseMsg.encode(m.cases[i], w.uint32(834).fork()).ldelim();
            }
            if (m.profiles != null && m.profiles.length) {
                for (var i = 0; i < m.profiles.length; ++i)
                    $root.cat.ProfileMsg.encode(m.profiles[i], w.uint32(842).fork()).ldelim();
            }
            if (m.assignedusers != null && m.assignedusers.length) {
                for (var i = 0; i < m.assignedusers.length; ++i)
                    $root.cat.UserMsg.encode(m.assignedusers[i], w.uint32(850).fork()).ldelim();
            }
            if (m.device != null && Object.hasOwnProperty.call(m, "device"))
                $root.cat.DeviceMsg.encode(m.device, w.uint32(858).fork()).ldelim();
            if (m.settings != null && m.settings.length) {
                for (var i = 0; i < m.settings.length; ++i)
                    $root.cat.SettingMsg.encode(m.settings[i], w.uint32(866).fork()).ldelim();
            }
            if (m.terminology != null && m.terminology.length) {
                for (var i = 0; i < m.terminology.length; ++i)
                    $root.cat.TerminologyMsg.encode(m.terminology[i], w.uint32(874).fork()).ldelim();
            }
            if (m.searchresultaccount != null && Object.hasOwnProperty.call(m, "searchresultaccount"))
                w.uint32(880).bool(m.searchresultaccount);
            if (m.devices != null && m.devices.length) {
                for (var i = 0; i < m.devices.length; ++i)
                    $root.cat.DeviceMsg.encode(m.devices[i], w.uint32(898).fork()).ldelim();
            }
            if (m.profilefilter != null && Object.hasOwnProperty.call(m, "profilefilter"))
                w.uint32(904).int32(m.profilefilter);
            return w;
        };

        /**
         * Decodes a UserMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.UserMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.UserMsg} UserMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.UserMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.username = r.string();
                        break;
                    }
                case 3: {
                        m.password = r.string();
                        break;
                    }
                case 4: {
                        m.created = r.uint64();
                        break;
                    }
                case 5: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 6: {
                        m.licenseaccepted = r.uint64();
                        break;
                    }
                case 7: {
                        m.passwordexpireson = r.uint64();
                        break;
                    }
                case 8: {
                        m.passwordreset = r.bool();
                        break;
                    }
                case 9: {
                        m.status = r.int32();
                        break;
                    }
                case 10: {
                        m.fullname = r.string();
                        break;
                    }
                case 11: {
                        m.email = r.string();
                        break;
                    }
                case 12: {
                        m.organization = r.string();
                        break;
                    }
                case 13: {
                        m.language = r.int32();
                        break;
                    }
                case 14: {
                        m.appearancemode = r.int32();
                        break;
                    }
                case 15: {
                        if (!(m.popovers && m.popovers.length))
                            m.popovers = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.popovers.push(r.int32());
                        } else
                            m.popovers.push(r.int32());
                        break;
                    }
                case 16: {
                        m.lastprofileid = r.string();
                        break;
                    }
                case 17: {
                        if (!(m.lastconversationids && m.lastconversationids.length))
                            m.lastconversationids = [];
                        m.lastconversationids.push(r.string());
                        break;
                    }
                case 101: {
                        m.newpassword = r.string();
                        break;
                    }
                case 102: {
                        if (!(m.roles && m.roles.length))
                            m.roles = [];
                        m.roles.push($root.cat.RoleMsg.decode(r, r.uint32()));
                        break;
                    }
                case 103: {
                        if (!(m.themes && m.themes.length))
                            m.themes = [];
                        m.themes.push($root.cat.ThemeMsg.decode(r, r.uint32()));
                        break;
                    }
                case 104: {
                        if (!(m.cases && m.cases.length))
                            m.cases = [];
                        m.cases.push($root.cat.CaseMsg.decode(r, r.uint32()));
                        break;
                    }
                case 105: {
                        if (!(m.profiles && m.profiles.length))
                            m.profiles = [];
                        m.profiles.push($root.cat.ProfileMsg.decode(r, r.uint32()));
                        break;
                    }
                case 106: {
                        if (!(m.assignedusers && m.assignedusers.length))
                            m.assignedusers = [];
                        m.assignedusers.push($root.cat.UserMsg.decode(r, r.uint32()));
                        break;
                    }
                case 107: {
                        m.device = $root.cat.DeviceMsg.decode(r, r.uint32());
                        break;
                    }
                case 108: {
                        if (!(m.settings && m.settings.length))
                            m.settings = [];
                        m.settings.push($root.cat.SettingMsg.decode(r, r.uint32()));
                        break;
                    }
                case 109: {
                        if (!(m.terminology && m.terminology.length))
                            m.terminology = [];
                        m.terminology.push($root.cat.TerminologyMsg.decode(r, r.uint32()));
                        break;
                    }
                case 110: {
                        m.searchresultaccount = r.bool();
                        break;
                    }
                case 112: {
                        if (!(m.devices && m.devices.length))
                            m.devices = [];
                        m.devices.push($root.cat.DeviceMsg.decode(r, r.uint32()));
                        break;
                    }
                case 113: {
                        m.profilefilter = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.UserMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.UserMsg} UserMsg
         */
        UserMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.UserMsg)
                return d;
            var m = new $root.cat.UserMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.licenseaccepted != null) {
                if ($util.Long)
                    (m.licenseaccepted = $util.Long.fromValue(d.licenseaccepted)).unsigned = true;
                else if (typeof d.licenseaccepted === "string")
                    m.licenseaccepted = parseInt(d.licenseaccepted, 10);
                else if (typeof d.licenseaccepted === "number")
                    m.licenseaccepted = d.licenseaccepted;
                else if (typeof d.licenseaccepted === "object")
                    m.licenseaccepted = new $util.LongBits(d.licenseaccepted.low >>> 0, d.licenseaccepted.high >>> 0).toNumber(true);
            }
            if (d.passwordexpireson != null) {
                if ($util.Long)
                    (m.passwordexpireson = $util.Long.fromValue(d.passwordexpireson)).unsigned = true;
                else if (typeof d.passwordexpireson === "string")
                    m.passwordexpireson = parseInt(d.passwordexpireson, 10);
                else if (typeof d.passwordexpireson === "number")
                    m.passwordexpireson = d.passwordexpireson;
                else if (typeof d.passwordexpireson === "object")
                    m.passwordexpireson = new $util.LongBits(d.passwordexpireson.low >>> 0, d.passwordexpireson.high >>> 0).toNumber(true);
            }
            if (d.passwordreset != null) {
                m.passwordreset = Boolean(d.passwordreset);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ENABLED":
            case 1:
                m.status = 1;
                break;
            case "DISABLED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.fullname != null) {
                m.fullname = String(d.fullname);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.organization != null) {
                m.organization = String(d.organization);
            }
            switch (d.language) {
            default:
                if (typeof d.language === "number") {
                    m.language = d.language;
                    break;
                }
                break;
            case "LANGUAGE_UNKNOWN":
            case 0:
                m.language = 0;
                break;
            case "LANGUAGE_ENGLISH":
            case 1:
                m.language = 1;
                break;
            case "LANGUAGE_DUTCH":
            case 2:
                m.language = 2;
                break;
            case "LANGUAGE_GERMAN":
            case 3:
                m.language = 3;
                break;
            case "LANGUAGE_FRENCH":
            case 4:
                m.language = 4;
                break;
            case "LANGUAGE_SPANISH":
            case 5:
                m.language = 5;
                break;
            }
            switch (d.appearancemode) {
            default:
                if (typeof d.appearancemode === "number") {
                    m.appearancemode = d.appearancemode;
                    break;
                }
                break;
            case "APPEARANCE_UNKNOWN":
            case 0:
                m.appearancemode = 0;
                break;
            case "APPEARANCE_LIGHT":
            case 1:
                m.appearancemode = 1;
                break;
            case "APPEARANCE_DARK":
            case 2:
                m.appearancemode = 2;
                break;
            }
            if (d.popovers) {
                if (!Array.isArray(d.popovers))
                    throw TypeError(".cat.UserMsg.popovers: array expected");
                m.popovers = [];
                for (var i = 0; i < d.popovers.length; ++i) {
                    switch (d.popovers[i]) {
                    default:
                        if (typeof d.popovers[i] === "number") {
                            m.popovers[i] = d.popovers[i];
                            break;
                        }
                    case "POPOVER_UNKNOWN":
                    case 0:
                        m.popovers[i] = 0;
                        break;
                    case "POPOVER_WELCOME":
                    case 1:
                        m.popovers[i] = 1;
                        break;
                    case "POPOVER_DASHBOARD_TOUR":
                    case 2:
                        m.popovers[i] = 2;
                        break;
                    case "POPOVER_PROFILE_DASHBOARD_TOUR":
                    case 3:
                        m.popovers[i] = 3;
                        break;
                    case "POPOVER_DEVICE_DASHBOARD_TOUR":
                    case 4:
                        m.popovers[i] = 4;
                        break;
                    case "POPOVER_CONVERSATION_TOUR":
                    case 5:
                        m.popovers[i] = 5;
                        break;
                    case "POPOVER_CREATE_REPORT_TOUR":
                    case 6:
                        m.popovers[i] = 6;
                        break;
                    }
                }
            }
            if (d.lastprofileid != null) {
                m.lastprofileid = String(d.lastprofileid);
            }
            if (d.lastconversationids) {
                if (!Array.isArray(d.lastconversationids))
                    throw TypeError(".cat.UserMsg.lastconversationids: array expected");
                m.lastconversationids = [];
                for (var i = 0; i < d.lastconversationids.length; ++i) {
                    m.lastconversationids[i] = String(d.lastconversationids[i]);
                }
            }
            if (d.newpassword != null) {
                m.newpassword = String(d.newpassword);
            }
            if (d.roles) {
                if (!Array.isArray(d.roles))
                    throw TypeError(".cat.UserMsg.roles: array expected");
                m.roles = [];
                for (var i = 0; i < d.roles.length; ++i) {
                    if (typeof d.roles[i] !== "object")
                        throw TypeError(".cat.UserMsg.roles: object expected");
                    m.roles[i] = $root.cat.RoleMsg.fromObject(d.roles[i]);
                }
            }
            if (d.themes) {
                if (!Array.isArray(d.themes))
                    throw TypeError(".cat.UserMsg.themes: array expected");
                m.themes = [];
                for (var i = 0; i < d.themes.length; ++i) {
                    if (typeof d.themes[i] !== "object")
                        throw TypeError(".cat.UserMsg.themes: object expected");
                    m.themes[i] = $root.cat.ThemeMsg.fromObject(d.themes[i]);
                }
            }
            if (d.cases) {
                if (!Array.isArray(d.cases))
                    throw TypeError(".cat.UserMsg.cases: array expected");
                m.cases = [];
                for (var i = 0; i < d.cases.length; ++i) {
                    if (typeof d.cases[i] !== "object")
                        throw TypeError(".cat.UserMsg.cases: object expected");
                    m.cases[i] = $root.cat.CaseMsg.fromObject(d.cases[i]);
                }
            }
            if (d.profiles) {
                if (!Array.isArray(d.profiles))
                    throw TypeError(".cat.UserMsg.profiles: array expected");
                m.profiles = [];
                for (var i = 0; i < d.profiles.length; ++i) {
                    if (typeof d.profiles[i] !== "object")
                        throw TypeError(".cat.UserMsg.profiles: object expected");
                    m.profiles[i] = $root.cat.ProfileMsg.fromObject(d.profiles[i]);
                }
            }
            if (d.assignedusers) {
                if (!Array.isArray(d.assignedusers))
                    throw TypeError(".cat.UserMsg.assignedusers: array expected");
                m.assignedusers = [];
                for (var i = 0; i < d.assignedusers.length; ++i) {
                    if (typeof d.assignedusers[i] !== "object")
                        throw TypeError(".cat.UserMsg.assignedusers: object expected");
                    m.assignedusers[i] = $root.cat.UserMsg.fromObject(d.assignedusers[i]);
                }
            }
            if (d.device != null) {
                if (typeof d.device !== "object")
                    throw TypeError(".cat.UserMsg.device: object expected");
                m.device = $root.cat.DeviceMsg.fromObject(d.device);
            }
            if (d.settings) {
                if (!Array.isArray(d.settings))
                    throw TypeError(".cat.UserMsg.settings: array expected");
                m.settings = [];
                for (var i = 0; i < d.settings.length; ++i) {
                    if (typeof d.settings[i] !== "object")
                        throw TypeError(".cat.UserMsg.settings: object expected");
                    m.settings[i] = $root.cat.SettingMsg.fromObject(d.settings[i]);
                }
            }
            if (d.terminology) {
                if (!Array.isArray(d.terminology))
                    throw TypeError(".cat.UserMsg.terminology: array expected");
                m.terminology = [];
                for (var i = 0; i < d.terminology.length; ++i) {
                    if (typeof d.terminology[i] !== "object")
                        throw TypeError(".cat.UserMsg.terminology: object expected");
                    m.terminology[i] = $root.cat.TerminologyMsg.fromObject(d.terminology[i]);
                }
            }
            if (d.searchresultaccount != null) {
                m.searchresultaccount = Boolean(d.searchresultaccount);
            }
            if (d.devices) {
                if (!Array.isArray(d.devices))
                    throw TypeError(".cat.UserMsg.devices: array expected");
                m.devices = [];
                for (var i = 0; i < d.devices.length; ++i) {
                    if (typeof d.devices[i] !== "object")
                        throw TypeError(".cat.UserMsg.devices: object expected");
                    m.devices[i] = $root.cat.DeviceMsg.fromObject(d.devices[i]);
                }
            }
            switch (d.profilefilter) {
            default:
                if (typeof d.profilefilter === "number") {
                    m.profilefilter = d.profilefilter;
                    break;
                }
                break;
            case "PROFILEFILTER_UNKNOWN":
            case 0:
                m.profilefilter = 0;
                break;
            case "PROFILEFILTER_OFF":
            case 1:
                m.profilefilter = 1;
                break;
            case "PROFILEFILTER_CASE_MATCH":
            case 2:
                m.profilefilter = 2;
                break;
            case "PROFILEFILTER_STRICT_CASE_MATCH":
            case 3:
                m.profilefilter = 3;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a UserMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.UserMsg
         * @static
         * @param {cat.UserMsg} m UserMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.popovers = [];
                d.lastconversationids = [];
                d.roles = [];
                d.themes = [];
                d.cases = [];
                d.profiles = [];
                d.assignedusers = [];
                d.settings = [];
                d.terminology = [];
                d.devices = [];
            }
            if (o.defaults) {
                d.id = "";
                d.username = "";
                d.password = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.licenseaccepted = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.licenseaccepted = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.passwordexpireson = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.passwordexpireson = o.longs === String ? "0" : 0;
                d.passwordreset = false;
                d.status = o.enums === String ? "UNKNOWN" : 0;
                d.fullname = "";
                d.email = "";
                d.language = o.enums === String ? "LANGUAGE_UNKNOWN" : 0;
                d.appearancemode = o.enums === String ? "APPEARANCE_UNKNOWN" : 0;
                d.lastprofileid = "";
                d.newpassword = "";
                d.device = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.licenseaccepted != null && m.hasOwnProperty("licenseaccepted")) {
                if (typeof m.licenseaccepted === "number")
                    d.licenseaccepted = o.longs === String ? String(m.licenseaccepted) : m.licenseaccepted;
                else
                    d.licenseaccepted = o.longs === String ? $util.Long.prototype.toString.call(m.licenseaccepted) : o.longs === Number ? new $util.LongBits(m.licenseaccepted.low >>> 0, m.licenseaccepted.high >>> 0).toNumber(true) : m.licenseaccepted;
            }
            if (m.passwordexpireson != null && m.hasOwnProperty("passwordexpireson")) {
                if (typeof m.passwordexpireson === "number")
                    d.passwordexpireson = o.longs === String ? String(m.passwordexpireson) : m.passwordexpireson;
                else
                    d.passwordexpireson = o.longs === String ? $util.Long.prototype.toString.call(m.passwordexpireson) : o.longs === Number ? new $util.LongBits(m.passwordexpireson.low >>> 0, m.passwordexpireson.high >>> 0).toNumber(true) : m.passwordexpireson;
            }
            if (m.passwordreset != null && m.hasOwnProperty("passwordreset")) {
                d.passwordreset = m.passwordreset;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.StatusType[m.status] === undefined ? m.status : $root.cat.StatusType[m.status] : m.status;
            }
            if (m.fullname != null && m.hasOwnProperty("fullname")) {
                d.fullname = m.fullname;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.organization != null && m.hasOwnProperty("organization")) {
                d.organization = m.organization;
                if (o.oneofs)
                    d._organization = "organization";
            }
            if (m.language != null && m.hasOwnProperty("language")) {
                d.language = o.enums === String ? $root.cat.SupportedLanguages[m.language] === undefined ? m.language : $root.cat.SupportedLanguages[m.language] : m.language;
            }
            if (m.appearancemode != null && m.hasOwnProperty("appearancemode")) {
                d.appearancemode = o.enums === String ? $root.cat.AppearanceMode[m.appearancemode] === undefined ? m.appearancemode : $root.cat.AppearanceMode[m.appearancemode] : m.appearancemode;
            }
            if (m.popovers && m.popovers.length) {
                d.popovers = [];
                for (var j = 0; j < m.popovers.length; ++j) {
                    d.popovers[j] = o.enums === String ? $root.cat.Popover[m.popovers[j]] === undefined ? m.popovers[j] : $root.cat.Popover[m.popovers[j]] : m.popovers[j];
                }
            }
            if (m.lastprofileid != null && m.hasOwnProperty("lastprofileid")) {
                d.lastprofileid = m.lastprofileid;
            }
            if (m.lastconversationids && m.lastconversationids.length) {
                d.lastconversationids = [];
                for (var j = 0; j < m.lastconversationids.length; ++j) {
                    d.lastconversationids[j] = m.lastconversationids[j];
                }
            }
            if (m.newpassword != null && m.hasOwnProperty("newpassword")) {
                d.newpassword = m.newpassword;
            }
            if (m.roles && m.roles.length) {
                d.roles = [];
                for (var j = 0; j < m.roles.length; ++j) {
                    d.roles[j] = $root.cat.RoleMsg.toObject(m.roles[j], o);
                }
            }
            if (m.themes && m.themes.length) {
                d.themes = [];
                for (var j = 0; j < m.themes.length; ++j) {
                    d.themes[j] = $root.cat.ThemeMsg.toObject(m.themes[j], o);
                }
            }
            if (m.cases && m.cases.length) {
                d.cases = [];
                for (var j = 0; j < m.cases.length; ++j) {
                    d.cases[j] = $root.cat.CaseMsg.toObject(m.cases[j], o);
                }
            }
            if (m.profiles && m.profiles.length) {
                d.profiles = [];
                for (var j = 0; j < m.profiles.length; ++j) {
                    d.profiles[j] = $root.cat.ProfileMsg.toObject(m.profiles[j], o);
                }
            }
            if (m.assignedusers && m.assignedusers.length) {
                d.assignedusers = [];
                for (var j = 0; j < m.assignedusers.length; ++j) {
                    d.assignedusers[j] = $root.cat.UserMsg.toObject(m.assignedusers[j], o);
                }
            }
            if (m.device != null && m.hasOwnProperty("device")) {
                d.device = $root.cat.DeviceMsg.toObject(m.device, o);
            }
            if (m.settings && m.settings.length) {
                d.settings = [];
                for (var j = 0; j < m.settings.length; ++j) {
                    d.settings[j] = $root.cat.SettingMsg.toObject(m.settings[j], o);
                }
            }
            if (m.terminology && m.terminology.length) {
                d.terminology = [];
                for (var j = 0; j < m.terminology.length; ++j) {
                    d.terminology[j] = $root.cat.TerminologyMsg.toObject(m.terminology[j], o);
                }
            }
            if (m.searchresultaccount != null && m.hasOwnProperty("searchresultaccount")) {
                d.searchresultaccount = m.searchresultaccount;
                if (o.oneofs)
                    d._searchresultaccount = "searchresultaccount";
            }
            if (m.devices && m.devices.length) {
                d.devices = [];
                for (var j = 0; j < m.devices.length; ++j) {
                    d.devices[j] = $root.cat.DeviceMsg.toObject(m.devices[j], o);
                }
            }
            if (m.profilefilter != null && m.hasOwnProperty("profilefilter")) {
                d.profilefilter = o.enums === String ? $root.cat.ProfileFilterType[m.profilefilter] === undefined ? m.profilefilter : $root.cat.ProfileFilterType[m.profilefilter] : m.profilefilter;
                if (o.oneofs)
                    d._profilefilter = "profilefilter";
            }
            return d;
        };

        /**
         * Converts this UserMsg to JSON.
         * @function toJSON
         * @memberof cat.UserMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserMsg
         * @function getTypeUrl
         * @memberof cat.UserMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.UserMsg";
        };

        return UserMsg;
    })();

    /**
     * ProfileFilterType enum.
     * @name cat.ProfileFilterType
     * @enum {number}
     * @property {number} PROFILEFILTER_UNKNOWN=0 PROFILEFILTER_UNKNOWN value
     * @property {number} PROFILEFILTER_OFF=1 PROFILEFILTER_OFF value
     * @property {number} PROFILEFILTER_CASE_MATCH=2 PROFILEFILTER_CASE_MATCH value
     * @property {number} PROFILEFILTER_STRICT_CASE_MATCH=3 PROFILEFILTER_STRICT_CASE_MATCH value
     */
    cat.ProfileFilterType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PROFILEFILTER_UNKNOWN"] = 0;
        values[valuesById[1] = "PROFILEFILTER_OFF"] = 1;
        values[valuesById[2] = "PROFILEFILTER_CASE_MATCH"] = 2;
        values[valuesById[3] = "PROFILEFILTER_STRICT_CASE_MATCH"] = 3;
        return values;
    })();

    cat.RoleMsg = (function() {

        /**
         * Properties of a RoleMsg.
         * @memberof cat
         * @interface IRoleMsg
         * @property {string|null} [id] RoleMsg id
         * @property {string|null} [name] RoleMsg name
         * @property {Object.<string,string>|null} [nametranslations] RoleMsg nametranslations
         * @property {string|null} [description] RoleMsg description
         * @property {number|null} [created] RoleMsg created
         * @property {number|null} [lastmodified] RoleMsg lastmodified
         * @property {boolean|null} [readonly] RoleMsg readonly
         * @property {Array.<cat.IPermissionMsg>|null} [permissions] RoleMsg permissions
         */

        /**
         * Constructs a new RoleMsg.
         * @memberof cat
         * @classdesc Represents a RoleMsg.
         * @implements IRoleMsg
         * @constructor
         * @param {cat.IRoleMsg=} [p] Properties to set
         */
        function RoleMsg(p) {
            this.nametranslations = {};
            this.permissions = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * RoleMsg id.
         * @member {string} id
         * @memberof cat.RoleMsg
         * @instance
         */
        RoleMsg.prototype.id = "";

        /**
         * RoleMsg name.
         * @member {string} name
         * @memberof cat.RoleMsg
         * @instance
         */
        RoleMsg.prototype.name = "";

        /**
         * RoleMsg nametranslations.
         * @member {Object.<string,string>} nametranslations
         * @memberof cat.RoleMsg
         * @instance
         */
        RoleMsg.prototype.nametranslations = $util.emptyObject;

        /**
         * RoleMsg description.
         * @member {string} description
         * @memberof cat.RoleMsg
         * @instance
         */
        RoleMsg.prototype.description = "";

        /**
         * RoleMsg created.
         * @member {number} created
         * @memberof cat.RoleMsg
         * @instance
         */
        RoleMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * RoleMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.RoleMsg
         * @instance
         */
        RoleMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * RoleMsg readonly.
         * @member {boolean} readonly
         * @memberof cat.RoleMsg
         * @instance
         */
        RoleMsg.prototype.readonly = false;

        /**
         * RoleMsg permissions.
         * @member {Array.<cat.IPermissionMsg>} permissions
         * @memberof cat.RoleMsg
         * @instance
         */
        RoleMsg.prototype.permissions = $util.emptyArray;

        /**
         * Creates a new RoleMsg instance using the specified properties.
         * @function create
         * @memberof cat.RoleMsg
         * @static
         * @param {cat.IRoleMsg=} [properties] Properties to set
         * @returns {cat.RoleMsg} RoleMsg instance
         */
        RoleMsg.create = function create(properties) {
            return new RoleMsg(properties);
        };

        /**
         * Encodes the specified RoleMsg message. Does not implicitly {@link cat.RoleMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.RoleMsg
         * @static
         * @param {cat.IRoleMsg} m RoleMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoleMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.nametranslations != null && Object.hasOwnProperty.call(m, "nametranslations")) {
                for (var ks = Object.keys(m.nametranslations), i = 0; i < ks.length; ++i) {
                    w.uint32(26).fork().uint32(10).string(ks[i]).uint32(18).string(m.nametranslations[ks[i]]).ldelim();
                }
            }
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(40).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(48).uint64(m.lastmodified);
            if (m.readonly != null && Object.hasOwnProperty.call(m, "readonly"))
                w.uint32(56).bool(m.readonly);
            if (m.permissions != null && m.permissions.length) {
                for (var i = 0; i < m.permissions.length; ++i)
                    $root.cat.PermissionMsg.encode(m.permissions[i], w.uint32(810).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a RoleMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.RoleMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.RoleMsg} RoleMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoleMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.RoleMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        if (m.nametranslations === $util.emptyObject)
                            m.nametranslations = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.nametranslations[k] = value;
                        break;
                    }
                case 4: {
                        m.description = r.string();
                        break;
                    }
                case 5: {
                        m.created = r.uint64();
                        break;
                    }
                case 6: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 7: {
                        m.readonly = r.bool();
                        break;
                    }
                case 101: {
                        if (!(m.permissions && m.permissions.length))
                            m.permissions = [];
                        m.permissions.push($root.cat.PermissionMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a RoleMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.RoleMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.RoleMsg} RoleMsg
         */
        RoleMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.RoleMsg)
                return d;
            var m = new $root.cat.RoleMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.nametranslations) {
                if (typeof d.nametranslations !== "object")
                    throw TypeError(".cat.RoleMsg.nametranslations: object expected");
                m.nametranslations = {};
                for (var ks = Object.keys(d.nametranslations), i = 0; i < ks.length; ++i) {
                    m.nametranslations[ks[i]] = String(d.nametranslations[ks[i]]);
                }
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.readonly != null) {
                m.readonly = Boolean(d.readonly);
            }
            if (d.permissions) {
                if (!Array.isArray(d.permissions))
                    throw TypeError(".cat.RoleMsg.permissions: array expected");
                m.permissions = [];
                for (var i = 0; i < d.permissions.length; ++i) {
                    if (typeof d.permissions[i] !== "object")
                        throw TypeError(".cat.RoleMsg.permissions: object expected");
                    m.permissions[i] = $root.cat.PermissionMsg.fromObject(d.permissions[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a RoleMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.RoleMsg
         * @static
         * @param {cat.RoleMsg} m RoleMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RoleMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.permissions = [];
            }
            if (o.objects || o.defaults) {
                d.nametranslations = {};
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.description = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.readonly = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            var ks2;
            if (m.nametranslations && (ks2 = Object.keys(m.nametranslations)).length) {
                d.nametranslations = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.nametranslations[ks2[j]] = m.nametranslations[ks2[j]];
                }
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.readonly != null && m.hasOwnProperty("readonly")) {
                d.readonly = m.readonly;
            }
            if (m.permissions && m.permissions.length) {
                d.permissions = [];
                for (var j = 0; j < m.permissions.length; ++j) {
                    d.permissions[j] = $root.cat.PermissionMsg.toObject(m.permissions[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this RoleMsg to JSON.
         * @function toJSON
         * @memberof cat.RoleMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RoleMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RoleMsg
         * @function getTypeUrl
         * @memberof cat.RoleMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RoleMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.RoleMsg";
        };

        return RoleMsg;
    })();

    cat.PermissionMsg = (function() {

        /**
         * Properties of a PermissionMsg.
         * @memberof cat
         * @interface IPermissionMsg
         * @property {string|null} [id] PermissionMsg id
         * @property {boolean|null} [required] PermissionMsg required
         * @property {boolean|null} [warning] PermissionMsg warning
         * @property {Array.<string>|null} [authcalls] PermissionMsg authcalls
         */

        /**
         * Constructs a new PermissionMsg.
         * @memberof cat
         * @classdesc Represents a PermissionMsg.
         * @implements IPermissionMsg
         * @constructor
         * @param {cat.IPermissionMsg=} [p] Properties to set
         */
        function PermissionMsg(p) {
            this.authcalls = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PermissionMsg id.
         * @member {string} id
         * @memberof cat.PermissionMsg
         * @instance
         */
        PermissionMsg.prototype.id = "";

        /**
         * PermissionMsg required.
         * @member {boolean|null|undefined} required
         * @memberof cat.PermissionMsg
         * @instance
         */
        PermissionMsg.prototype.required = null;

        /**
         * PermissionMsg warning.
         * @member {boolean|null|undefined} warning
         * @memberof cat.PermissionMsg
         * @instance
         */
        PermissionMsg.prototype.warning = null;

        /**
         * PermissionMsg authcalls.
         * @member {Array.<string>} authcalls
         * @memberof cat.PermissionMsg
         * @instance
         */
        PermissionMsg.prototype.authcalls = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(PermissionMsg.prototype, "_required", {
            get: $util.oneOfGetter($oneOfFields = ["required"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(PermissionMsg.prototype, "_warning", {
            get: $util.oneOfGetter($oneOfFields = ["warning"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PermissionMsg instance using the specified properties.
         * @function create
         * @memberof cat.PermissionMsg
         * @static
         * @param {cat.IPermissionMsg=} [properties] Properties to set
         * @returns {cat.PermissionMsg} PermissionMsg instance
         */
        PermissionMsg.create = function create(properties) {
            return new PermissionMsg(properties);
        };

        /**
         * Encodes the specified PermissionMsg message. Does not implicitly {@link cat.PermissionMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PermissionMsg
         * @static
         * @param {cat.IPermissionMsg} m PermissionMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PermissionMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.required != null && Object.hasOwnProperty.call(m, "required"))
                w.uint32(16).bool(m.required);
            if (m.warning != null && Object.hasOwnProperty.call(m, "warning"))
                w.uint32(24).bool(m.warning);
            if (m.authcalls != null && m.authcalls.length) {
                for (var i = 0; i < m.authcalls.length; ++i)
                    w.uint32(810).string(m.authcalls[i]);
            }
            return w;
        };

        /**
         * Decodes a PermissionMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PermissionMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PermissionMsg} PermissionMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PermissionMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PermissionMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.required = r.bool();
                        break;
                    }
                case 3: {
                        m.warning = r.bool();
                        break;
                    }
                case 101: {
                        if (!(m.authcalls && m.authcalls.length))
                            m.authcalls = [];
                        m.authcalls.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PermissionMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PermissionMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PermissionMsg} PermissionMsg
         */
        PermissionMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PermissionMsg)
                return d;
            var m = new $root.cat.PermissionMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.required != null) {
                m.required = Boolean(d.required);
            }
            if (d.warning != null) {
                m.warning = Boolean(d.warning);
            }
            if (d.authcalls) {
                if (!Array.isArray(d.authcalls))
                    throw TypeError(".cat.PermissionMsg.authcalls: array expected");
                m.authcalls = [];
                for (var i = 0; i < d.authcalls.length; ++i) {
                    m.authcalls[i] = String(d.authcalls[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PermissionMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PermissionMsg
         * @static
         * @param {cat.PermissionMsg} m PermissionMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PermissionMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.authcalls = [];
            }
            if (o.defaults) {
                d.id = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.required != null && m.hasOwnProperty("required")) {
                d.required = m.required;
                if (o.oneofs)
                    d._required = "required";
            }
            if (m.warning != null && m.hasOwnProperty("warning")) {
                d.warning = m.warning;
                if (o.oneofs)
                    d._warning = "warning";
            }
            if (m.authcalls && m.authcalls.length) {
                d.authcalls = [];
                for (var j = 0; j < m.authcalls.length; ++j) {
                    d.authcalls[j] = m.authcalls[j];
                }
            }
            return d;
        };

        /**
         * Converts this PermissionMsg to JSON.
         * @function toJSON
         * @memberof cat.PermissionMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PermissionMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PermissionMsg
         * @function getTypeUrl
         * @memberof cat.PermissionMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PermissionMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PermissionMsg";
        };

        return PermissionMsg;
    })();

    cat.AuthCallMsg = (function() {

        /**
         * Properties of an AuthCallMsg.
         * @memberof cat
         * @interface IAuthCallMsg
         * @property {string|null} [id] AuthCallMsg id
         */

        /**
         * Constructs a new AuthCallMsg.
         * @memberof cat
         * @classdesc Represents an AuthCallMsg.
         * @implements IAuthCallMsg
         * @constructor
         * @param {cat.IAuthCallMsg=} [p] Properties to set
         */
        function AuthCallMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AuthCallMsg id.
         * @member {string} id
         * @memberof cat.AuthCallMsg
         * @instance
         */
        AuthCallMsg.prototype.id = "";

        /**
         * Creates a new AuthCallMsg instance using the specified properties.
         * @function create
         * @memberof cat.AuthCallMsg
         * @static
         * @param {cat.IAuthCallMsg=} [properties] Properties to set
         * @returns {cat.AuthCallMsg} AuthCallMsg instance
         */
        AuthCallMsg.create = function create(properties) {
            return new AuthCallMsg(properties);
        };

        /**
         * Encodes the specified AuthCallMsg message. Does not implicitly {@link cat.AuthCallMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.AuthCallMsg
         * @static
         * @param {cat.IAuthCallMsg} m AuthCallMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthCallMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            return w;
        };

        /**
         * Decodes an AuthCallMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.AuthCallMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.AuthCallMsg} AuthCallMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthCallMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.AuthCallMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AuthCallMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.AuthCallMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.AuthCallMsg} AuthCallMsg
         */
        AuthCallMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.AuthCallMsg)
                return d;
            var m = new $root.cat.AuthCallMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            return m;
        };

        /**
         * Creates a plain object from an AuthCallMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.AuthCallMsg
         * @static
         * @param {cat.AuthCallMsg} m AuthCallMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthCallMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            return d;
        };

        /**
         * Converts this AuthCallMsg to JSON.
         * @function toJSON
         * @memberof cat.AuthCallMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthCallMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthCallMsg
         * @function getTypeUrl
         * @memberof cat.AuthCallMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthCallMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.AuthCallMsg";
        };

        return AuthCallMsg;
    })();

    cat.AuthCallListMsg = (function() {

        /**
         * Properties of an AuthCallListMsg.
         * @memberof cat
         * @interface IAuthCallListMsg
         * @property {Array.<string>|null} [authcalls] AuthCallListMsg authcalls
         */

        /**
         * Constructs a new AuthCallListMsg.
         * @memberof cat
         * @classdesc Represents an AuthCallListMsg.
         * @implements IAuthCallListMsg
         * @constructor
         * @param {cat.IAuthCallListMsg=} [p] Properties to set
         */
        function AuthCallListMsg(p) {
            this.authcalls = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AuthCallListMsg authcalls.
         * @member {Array.<string>} authcalls
         * @memberof cat.AuthCallListMsg
         * @instance
         */
        AuthCallListMsg.prototype.authcalls = $util.emptyArray;

        /**
         * Creates a new AuthCallListMsg instance using the specified properties.
         * @function create
         * @memberof cat.AuthCallListMsg
         * @static
         * @param {cat.IAuthCallListMsg=} [properties] Properties to set
         * @returns {cat.AuthCallListMsg} AuthCallListMsg instance
         */
        AuthCallListMsg.create = function create(properties) {
            return new AuthCallListMsg(properties);
        };

        /**
         * Encodes the specified AuthCallListMsg message. Does not implicitly {@link cat.AuthCallListMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.AuthCallListMsg
         * @static
         * @param {cat.IAuthCallListMsg} m AuthCallListMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthCallListMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.authcalls != null && m.authcalls.length) {
                for (var i = 0; i < m.authcalls.length; ++i)
                    w.uint32(10).string(m.authcalls[i]);
            }
            return w;
        };

        /**
         * Decodes an AuthCallListMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.AuthCallListMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.AuthCallListMsg} AuthCallListMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthCallListMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.AuthCallListMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (!(m.authcalls && m.authcalls.length))
                            m.authcalls = [];
                        m.authcalls.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AuthCallListMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.AuthCallListMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.AuthCallListMsg} AuthCallListMsg
         */
        AuthCallListMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.AuthCallListMsg)
                return d;
            var m = new $root.cat.AuthCallListMsg();
            if (d.authcalls) {
                if (!Array.isArray(d.authcalls))
                    throw TypeError(".cat.AuthCallListMsg.authcalls: array expected");
                m.authcalls = [];
                for (var i = 0; i < d.authcalls.length; ++i) {
                    m.authcalls[i] = String(d.authcalls[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from an AuthCallListMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.AuthCallListMsg
         * @static
         * @param {cat.AuthCallListMsg} m AuthCallListMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthCallListMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.authcalls = [];
            }
            if (m.authcalls && m.authcalls.length) {
                d.authcalls = [];
                for (var j = 0; j < m.authcalls.length; ++j) {
                    d.authcalls[j] = m.authcalls[j];
                }
            }
            return d;
        };

        /**
         * Converts this AuthCallListMsg to JSON.
         * @function toJSON
         * @memberof cat.AuthCallListMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthCallListMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuthCallListMsg
         * @function getTypeUrl
         * @memberof cat.AuthCallListMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuthCallListMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.AuthCallListMsg";
        };

        return AuthCallListMsg;
    })();

    cat.ThemeMsg = (function() {

        /**
         * Properties of a ThemeMsg.
         * @memberof cat
         * @interface IThemeMsg
         * @property {string|null} [id] ThemeMsg id
         * @property {string|null} [name] ThemeMsg name
         * @property {string|null} [description] ThemeMsg description
         * @property {number|null} [created] ThemeMsg created
         * @property {number|null} [lastmodified] ThemeMsg lastmodified
         */

        /**
         * Constructs a new ThemeMsg.
         * @memberof cat
         * @classdesc Represents a ThemeMsg.
         * @implements IThemeMsg
         * @constructor
         * @param {cat.IThemeMsg=} [p] Properties to set
         */
        function ThemeMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ThemeMsg id.
         * @member {string} id
         * @memberof cat.ThemeMsg
         * @instance
         */
        ThemeMsg.prototype.id = "";

        /**
         * ThemeMsg name.
         * @member {string} name
         * @memberof cat.ThemeMsg
         * @instance
         */
        ThemeMsg.prototype.name = "";

        /**
         * ThemeMsg description.
         * @member {string} description
         * @memberof cat.ThemeMsg
         * @instance
         */
        ThemeMsg.prototype.description = "";

        /**
         * ThemeMsg created.
         * @member {number} created
         * @memberof cat.ThemeMsg
         * @instance
         */
        ThemeMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ThemeMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.ThemeMsg
         * @instance
         */
        ThemeMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ThemeMsg instance using the specified properties.
         * @function create
         * @memberof cat.ThemeMsg
         * @static
         * @param {cat.IThemeMsg=} [properties] Properties to set
         * @returns {cat.ThemeMsg} ThemeMsg instance
         */
        ThemeMsg.create = function create(properties) {
            return new ThemeMsg(properties);
        };

        /**
         * Encodes the specified ThemeMsg message. Does not implicitly {@link cat.ThemeMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ThemeMsg
         * @static
         * @param {cat.IThemeMsg} m ThemeMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThemeMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(26).string(m.description);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(32).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(40).uint64(m.lastmodified);
            return w;
        };

        /**
         * Decodes a ThemeMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ThemeMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ThemeMsg} ThemeMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThemeMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ThemeMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.description = r.string();
                        break;
                    }
                case 4: {
                        m.created = r.uint64();
                        break;
                    }
                case 5: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ThemeMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ThemeMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ThemeMsg} ThemeMsg
         */
        ThemeMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ThemeMsg)
                return d;
            var m = new $root.cat.ThemeMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a ThemeMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ThemeMsg
         * @static
         * @param {cat.ThemeMsg} m ThemeMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ThemeMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.description = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            return d;
        };

        /**
         * Converts this ThemeMsg to JSON.
         * @function toJSON
         * @memberof cat.ThemeMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ThemeMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ThemeMsg
         * @function getTypeUrl
         * @memberof cat.ThemeMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ThemeMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ThemeMsg";
        };

        return ThemeMsg;
    })();

    cat.CaseMsg = (function() {

        /**
         * Properties of a CaseMsg.
         * @memberof cat
         * @interface ICaseMsg
         * @property {string|null} [id] CaseMsg id
         * @property {string|null} [name] CaseMsg name
         * @property {string|null} [description] CaseMsg description
         * @property {number|null} [created] CaseMsg created
         * @property {number|null} [lastmodified] CaseMsg lastmodified
         * @property {cat.StatusType|null} [status] CaseMsg status
         * @property {Array.<cat.IUserMsg>|null} [members] CaseMsg members
         * @property {Array.<cat.IProfileMsg>|null} [profiles] CaseMsg profiles
         * @property {Array.<cat.IReportMsg>|null} [reports] CaseMsg reports
         * @property {Array.<cat.ILabelMsg>|null} [labels] CaseMsg labels
         * @property {Array.<cat.ITriggerMsg>|null} [triggers] CaseMsg triggers
         * @property {number|null} [profilecount] CaseMsg profilecount
         * @property {number|null} [supervisorcount] CaseMsg supervisorcount
         * @property {number|null} [offset] CaseMsg offset
         * @property {number|null} [count] CaseMsg count
         */

        /**
         * Constructs a new CaseMsg.
         * @memberof cat
         * @classdesc Represents a CaseMsg.
         * @implements ICaseMsg
         * @constructor
         * @param {cat.ICaseMsg=} [p] Properties to set
         */
        function CaseMsg(p) {
            this.members = [];
            this.profiles = [];
            this.reports = [];
            this.labels = [];
            this.triggers = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CaseMsg id.
         * @member {string} id
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.id = "";

        /**
         * CaseMsg name.
         * @member {string} name
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.name = "";

        /**
         * CaseMsg description.
         * @member {string} description
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.description = "";

        /**
         * CaseMsg created.
         * @member {number} created
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CaseMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CaseMsg status.
         * @member {cat.StatusType} status
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.status = 0;

        /**
         * CaseMsg members.
         * @member {Array.<cat.IUserMsg>} members
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.members = $util.emptyArray;

        /**
         * CaseMsg profiles.
         * @member {Array.<cat.IProfileMsg>} profiles
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.profiles = $util.emptyArray;

        /**
         * CaseMsg reports.
         * @member {Array.<cat.IReportMsg>} reports
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.reports = $util.emptyArray;

        /**
         * CaseMsg labels.
         * @member {Array.<cat.ILabelMsg>} labels
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.labels = $util.emptyArray;

        /**
         * CaseMsg triggers.
         * @member {Array.<cat.ITriggerMsg>} triggers
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.triggers = $util.emptyArray;

        /**
         * CaseMsg profilecount.
         * @member {number} profilecount
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.profilecount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CaseMsg supervisorcount.
         * @member {number} supervisorcount
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.supervisorcount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CaseMsg offset.
         * @member {number} offset
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CaseMsg count.
         * @member {number} count
         * @memberof cat.CaseMsg
         * @instance
         */
        CaseMsg.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new CaseMsg instance using the specified properties.
         * @function create
         * @memberof cat.CaseMsg
         * @static
         * @param {cat.ICaseMsg=} [properties] Properties to set
         * @returns {cat.CaseMsg} CaseMsg instance
         */
        CaseMsg.create = function create(properties) {
            return new CaseMsg(properties);
        };

        /**
         * Encodes the specified CaseMsg message. Does not implicitly {@link cat.CaseMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.CaseMsg
         * @static
         * @param {cat.ICaseMsg} m CaseMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CaseMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(26).string(m.description);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(32).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(40).uint64(m.lastmodified);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(48).int32(m.status);
            if (m.members != null && m.members.length) {
                for (var i = 0; i < m.members.length; ++i)
                    $root.cat.UserMsg.encode(m.members[i], w.uint32(818).fork()).ldelim();
            }
            if (m.profiles != null && m.profiles.length) {
                for (var i = 0; i < m.profiles.length; ++i)
                    $root.cat.ProfileMsg.encode(m.profiles[i], w.uint32(826).fork()).ldelim();
            }
            if (m.reports != null && m.reports.length) {
                for (var i = 0; i < m.reports.length; ++i)
                    $root.cat.ReportMsg.encode(m.reports[i], w.uint32(834).fork()).ldelim();
            }
            if (m.labels != null && m.labels.length) {
                for (var i = 0; i < m.labels.length; ++i)
                    $root.cat.LabelMsg.encode(m.labels[i], w.uint32(842).fork()).ldelim();
            }
            if (m.triggers != null && m.triggers.length) {
                for (var i = 0; i < m.triggers.length; ++i)
                    $root.cat.TriggerMsg.encode(m.triggers[i], w.uint32(850).fork()).ldelim();
            }
            if (m.profilecount != null && Object.hasOwnProperty.call(m, "profilecount"))
                w.uint32(856).uint64(m.profilecount);
            if (m.supervisorcount != null && Object.hasOwnProperty.call(m, "supervisorcount"))
                w.uint32(864).uint64(m.supervisorcount);
            if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
                w.uint32(872).uint64(m.offset);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(880).uint64(m.count);
            return w;
        };

        /**
         * Decodes a CaseMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.CaseMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.CaseMsg} CaseMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CaseMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.CaseMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.description = r.string();
                        break;
                    }
                case 4: {
                        m.created = r.uint64();
                        break;
                    }
                case 5: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 6: {
                        m.status = r.int32();
                        break;
                    }
                case 102: {
                        if (!(m.members && m.members.length))
                            m.members = [];
                        m.members.push($root.cat.UserMsg.decode(r, r.uint32()));
                        break;
                    }
                case 103: {
                        if (!(m.profiles && m.profiles.length))
                            m.profiles = [];
                        m.profiles.push($root.cat.ProfileMsg.decode(r, r.uint32()));
                        break;
                    }
                case 104: {
                        if (!(m.reports && m.reports.length))
                            m.reports = [];
                        m.reports.push($root.cat.ReportMsg.decode(r, r.uint32()));
                        break;
                    }
                case 105: {
                        if (!(m.labels && m.labels.length))
                            m.labels = [];
                        m.labels.push($root.cat.LabelMsg.decode(r, r.uint32()));
                        break;
                    }
                case 106: {
                        if (!(m.triggers && m.triggers.length))
                            m.triggers = [];
                        m.triggers.push($root.cat.TriggerMsg.decode(r, r.uint32()));
                        break;
                    }
                case 107: {
                        m.profilecount = r.uint64();
                        break;
                    }
                case 108: {
                        m.supervisorcount = r.uint64();
                        break;
                    }
                case 109: {
                        m.offset = r.uint64();
                        break;
                    }
                case 110: {
                        m.count = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CaseMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.CaseMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.CaseMsg} CaseMsg
         */
        CaseMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.CaseMsg)
                return d;
            var m = new $root.cat.CaseMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ENABLED":
            case 1:
                m.status = 1;
                break;
            case "DISABLED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.members) {
                if (!Array.isArray(d.members))
                    throw TypeError(".cat.CaseMsg.members: array expected");
                m.members = [];
                for (var i = 0; i < d.members.length; ++i) {
                    if (typeof d.members[i] !== "object")
                        throw TypeError(".cat.CaseMsg.members: object expected");
                    m.members[i] = $root.cat.UserMsg.fromObject(d.members[i]);
                }
            }
            if (d.profiles) {
                if (!Array.isArray(d.profiles))
                    throw TypeError(".cat.CaseMsg.profiles: array expected");
                m.profiles = [];
                for (var i = 0; i < d.profiles.length; ++i) {
                    if (typeof d.profiles[i] !== "object")
                        throw TypeError(".cat.CaseMsg.profiles: object expected");
                    m.profiles[i] = $root.cat.ProfileMsg.fromObject(d.profiles[i]);
                }
            }
            if (d.reports) {
                if (!Array.isArray(d.reports))
                    throw TypeError(".cat.CaseMsg.reports: array expected");
                m.reports = [];
                for (var i = 0; i < d.reports.length; ++i) {
                    if (typeof d.reports[i] !== "object")
                        throw TypeError(".cat.CaseMsg.reports: object expected");
                    m.reports[i] = $root.cat.ReportMsg.fromObject(d.reports[i]);
                }
            }
            if (d.labels) {
                if (!Array.isArray(d.labels))
                    throw TypeError(".cat.CaseMsg.labels: array expected");
                m.labels = [];
                for (var i = 0; i < d.labels.length; ++i) {
                    if (typeof d.labels[i] !== "object")
                        throw TypeError(".cat.CaseMsg.labels: object expected");
                    m.labels[i] = $root.cat.LabelMsg.fromObject(d.labels[i]);
                }
            }
            if (d.triggers) {
                if (!Array.isArray(d.triggers))
                    throw TypeError(".cat.CaseMsg.triggers: array expected");
                m.triggers = [];
                for (var i = 0; i < d.triggers.length; ++i) {
                    if (typeof d.triggers[i] !== "object")
                        throw TypeError(".cat.CaseMsg.triggers: object expected");
                    m.triggers[i] = $root.cat.TriggerMsg.fromObject(d.triggers[i]);
                }
            }
            if (d.profilecount != null) {
                if ($util.Long)
                    (m.profilecount = $util.Long.fromValue(d.profilecount)).unsigned = true;
                else if (typeof d.profilecount === "string")
                    m.profilecount = parseInt(d.profilecount, 10);
                else if (typeof d.profilecount === "number")
                    m.profilecount = d.profilecount;
                else if (typeof d.profilecount === "object")
                    m.profilecount = new $util.LongBits(d.profilecount.low >>> 0, d.profilecount.high >>> 0).toNumber(true);
            }
            if (d.supervisorcount != null) {
                if ($util.Long)
                    (m.supervisorcount = $util.Long.fromValue(d.supervisorcount)).unsigned = true;
                else if (typeof d.supervisorcount === "string")
                    m.supervisorcount = parseInt(d.supervisorcount, 10);
                else if (typeof d.supervisorcount === "number")
                    m.supervisorcount = d.supervisorcount;
                else if (typeof d.supervisorcount === "object")
                    m.supervisorcount = new $util.LongBits(d.supervisorcount.low >>> 0, d.supervisorcount.high >>> 0).toNumber(true);
            }
            if (d.offset != null) {
                if ($util.Long)
                    (m.offset = $util.Long.fromValue(d.offset)).unsigned = true;
                else if (typeof d.offset === "string")
                    m.offset = parseInt(d.offset, 10);
                else if (typeof d.offset === "number")
                    m.offset = d.offset;
                else if (typeof d.offset === "object")
                    m.offset = new $util.LongBits(d.offset.low >>> 0, d.offset.high >>> 0).toNumber(true);
            }
            if (d.count != null) {
                if ($util.Long)
                    (m.count = $util.Long.fromValue(d.count)).unsigned = true;
                else if (typeof d.count === "string")
                    m.count = parseInt(d.count, 10);
                else if (typeof d.count === "number")
                    m.count = d.count;
                else if (typeof d.count === "object")
                    m.count = new $util.LongBits(d.count.low >>> 0, d.count.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a CaseMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.CaseMsg
         * @static
         * @param {cat.CaseMsg} m CaseMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CaseMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.members = [];
                d.profiles = [];
                d.reports = [];
                d.labels = [];
                d.triggers = [];
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.description = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.status = o.enums === String ? "UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.profilecount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.profilecount = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.supervisorcount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.supervisorcount = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.offset = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.offset = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.count = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.count = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.StatusType[m.status] === undefined ? m.status : $root.cat.StatusType[m.status] : m.status;
            }
            if (m.members && m.members.length) {
                d.members = [];
                for (var j = 0; j < m.members.length; ++j) {
                    d.members[j] = $root.cat.UserMsg.toObject(m.members[j], o);
                }
            }
            if (m.profiles && m.profiles.length) {
                d.profiles = [];
                for (var j = 0; j < m.profiles.length; ++j) {
                    d.profiles[j] = $root.cat.ProfileMsg.toObject(m.profiles[j], o);
                }
            }
            if (m.reports && m.reports.length) {
                d.reports = [];
                for (var j = 0; j < m.reports.length; ++j) {
                    d.reports[j] = $root.cat.ReportMsg.toObject(m.reports[j], o);
                }
            }
            if (m.labels && m.labels.length) {
                d.labels = [];
                for (var j = 0; j < m.labels.length; ++j) {
                    d.labels[j] = $root.cat.LabelMsg.toObject(m.labels[j], o);
                }
            }
            if (m.triggers && m.triggers.length) {
                d.triggers = [];
                for (var j = 0; j < m.triggers.length; ++j) {
                    d.triggers[j] = $root.cat.TriggerMsg.toObject(m.triggers[j], o);
                }
            }
            if (m.profilecount != null && m.hasOwnProperty("profilecount")) {
                if (typeof m.profilecount === "number")
                    d.profilecount = o.longs === String ? String(m.profilecount) : m.profilecount;
                else
                    d.profilecount = o.longs === String ? $util.Long.prototype.toString.call(m.profilecount) : o.longs === Number ? new $util.LongBits(m.profilecount.low >>> 0, m.profilecount.high >>> 0).toNumber(true) : m.profilecount;
            }
            if (m.supervisorcount != null && m.hasOwnProperty("supervisorcount")) {
                if (typeof m.supervisorcount === "number")
                    d.supervisorcount = o.longs === String ? String(m.supervisorcount) : m.supervisorcount;
                else
                    d.supervisorcount = o.longs === String ? $util.Long.prototype.toString.call(m.supervisorcount) : o.longs === Number ? new $util.LongBits(m.supervisorcount.low >>> 0, m.supervisorcount.high >>> 0).toNumber(true) : m.supervisorcount;
            }
            if (m.offset != null && m.hasOwnProperty("offset")) {
                if (typeof m.offset === "number")
                    d.offset = o.longs === String ? String(m.offset) : m.offset;
                else
                    d.offset = o.longs === String ? $util.Long.prototype.toString.call(m.offset) : o.longs === Number ? new $util.LongBits(m.offset.low >>> 0, m.offset.high >>> 0).toNumber(true) : m.offset;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                if (typeof m.count === "number")
                    d.count = o.longs === String ? String(m.count) : m.count;
                else
                    d.count = o.longs === String ? $util.Long.prototype.toString.call(m.count) : o.longs === Number ? new $util.LongBits(m.count.low >>> 0, m.count.high >>> 0).toNumber(true) : m.count;
            }
            return d;
        };

        /**
         * Converts this CaseMsg to JSON.
         * @function toJSON
         * @memberof cat.CaseMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CaseMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CaseMsg
         * @function getTypeUrl
         * @memberof cat.CaseMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CaseMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.CaseMsg";
        };

        return CaseMsg;
    })();

    /**
     * VisibilityType enum.
     * @name cat.VisibilityType
     * @enum {number}
     * @property {number} VISIBILITY_UNKNOWN=0 VISIBILITY_UNKNOWN value
     * @property {number} VISIBILITY_VISIBLE=1 VISIBILITY_VISIBLE value
     * @property {number} VISIBILITY_HIDDEN=2 VISIBILITY_HIDDEN value
     */
    cat.VisibilityType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "VISIBILITY_UNKNOWN"] = 0;
        values[valuesById[1] = "VISIBILITY_VISIBLE"] = 1;
        values[valuesById[2] = "VISIBILITY_HIDDEN"] = 2;
        return values;
    })();

    cat.ProfileMsg = (function() {

        /**
         * Properties of a ProfileMsg.
         * @memberof cat
         * @interface IProfileMsg
         * @property {string|null} [id] ProfileMsg id
         * @property {string|null} [fullname] ProfileMsg fullname
         * @property {string|null} [legend] ProfileMsg legend
         * @property {number|null} [created] ProfileMsg created
         * @property {number|null} [lastmodified] ProfileMsg lastmodified
         * @property {cat.IAttachmentMsg|null} [avatar] ProfileMsg avatar
         * @property {cat.IAttachmentMsg|null} [mediaitem] ProfileMsg mediaitem
         * @property {cat.IDeviceMsg|null} [device] ProfileMsg device
         * @property {Array.<cat.IAppTypeMsg>|null} [apptypes] ProfileMsg apptypes
         * @property {Array.<cat.IReportMsg>|null} [reports] ProfileMsg reports
         * @property {Array.<cat.ILabelMsg>|null} [labels] ProfileMsg labels
         * @property {Array.<cat.ITriggerMsg>|null} [triggers] ProfileMsg triggers
         * @property {Array.<cat.ICaseMsg>|null} [cases] ProfileMsg cases
         * @property {number|null} [totalunreadmessages] ProfileMsg totalunreadmessages
         * @property {number|null} [startdate] ProfileMsg startdate
         * @property {number|null} [enddate] ProfileMsg enddate
         * @property {number|null} [offset] ProfileMsg offset
         * @property {number|null} [count] ProfileMsg count
         * @property {number|null} [firstmessagedate] ProfileMsg firstmessagedate
         * @property {cat.IReportMsg|null} [report] ProfileMsg report
         * @property {cat.IThemeMsg|null} [theme] ProfileMsg theme
         * @property {cat.IUserMsg|null} [user] ProfileMsg user
         * @property {Array.<cat.IUserMsg>|null} [reviewers] ProfileMsg reviewers
         * @property {cat.VisibilityType|null} [hidden] ProfileMsg hidden
         * @property {Array.<cat.IAccountMsg>|null} [accounts] ProfileMsg accounts
         */

        /**
         * Constructs a new ProfileMsg.
         * @memberof cat
         * @classdesc Represents a ProfileMsg.
         * @implements IProfileMsg
         * @constructor
         * @param {cat.IProfileMsg=} [p] Properties to set
         */
        function ProfileMsg(p) {
            this.apptypes = [];
            this.reports = [];
            this.labels = [];
            this.triggers = [];
            this.cases = [];
            this.reviewers = [];
            this.accounts = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ProfileMsg id.
         * @member {string} id
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.id = "";

        /**
         * ProfileMsg fullname.
         * @member {string|null|undefined} fullname
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.fullname = null;

        /**
         * ProfileMsg legend.
         * @member {string|null|undefined} legend
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.legend = null;

        /**
         * ProfileMsg created.
         * @member {number} created
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProfileMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProfileMsg avatar.
         * @member {cat.IAttachmentMsg|null|undefined} avatar
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.avatar = null;

        /**
         * ProfileMsg mediaitem.
         * @member {cat.IAttachmentMsg|null|undefined} mediaitem
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.mediaitem = null;

        /**
         * ProfileMsg device.
         * @member {cat.IDeviceMsg|null|undefined} device
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.device = null;

        /**
         * ProfileMsg apptypes.
         * @member {Array.<cat.IAppTypeMsg>} apptypes
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.apptypes = $util.emptyArray;

        /**
         * ProfileMsg reports.
         * @member {Array.<cat.IReportMsg>} reports
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.reports = $util.emptyArray;

        /**
         * ProfileMsg labels.
         * @member {Array.<cat.ILabelMsg>} labels
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.labels = $util.emptyArray;

        /**
         * ProfileMsg triggers.
         * @member {Array.<cat.ITriggerMsg>} triggers
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.triggers = $util.emptyArray;

        /**
         * ProfileMsg cases.
         * @member {Array.<cat.ICaseMsg>} cases
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.cases = $util.emptyArray;

        /**
         * ProfileMsg totalunreadmessages.
         * @member {number} totalunreadmessages
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.totalunreadmessages = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProfileMsg startdate.
         * @member {number} startdate
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.startdate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProfileMsg enddate.
         * @member {number} enddate
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.enddate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProfileMsg offset.
         * @member {number} offset
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProfileMsg count.
         * @member {number} count
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProfileMsg firstmessagedate.
         * @member {number} firstmessagedate
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.firstmessagedate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProfileMsg report.
         * @member {cat.IReportMsg|null|undefined} report
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.report = null;

        /**
         * ProfileMsg theme.
         * @member {cat.IThemeMsg|null|undefined} theme
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.theme = null;

        /**
         * ProfileMsg user.
         * @member {cat.IUserMsg|null|undefined} user
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.user = null;

        /**
         * ProfileMsg reviewers.
         * @member {Array.<cat.IUserMsg>} reviewers
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.reviewers = $util.emptyArray;

        /**
         * ProfileMsg hidden.
         * @member {cat.VisibilityType} hidden
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.hidden = 0;

        /**
         * ProfileMsg accounts.
         * @member {Array.<cat.IAccountMsg>} accounts
         * @memberof cat.ProfileMsg
         * @instance
         */
        ProfileMsg.prototype.accounts = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ProfileMsg.prototype, "_fullname", {
            get: $util.oneOfGetter($oneOfFields = ["fullname"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ProfileMsg.prototype, "_legend", {
            get: $util.oneOfGetter($oneOfFields = ["legend"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ProfileMsg instance using the specified properties.
         * @function create
         * @memberof cat.ProfileMsg
         * @static
         * @param {cat.IProfileMsg=} [properties] Properties to set
         * @returns {cat.ProfileMsg} ProfileMsg instance
         */
        ProfileMsg.create = function create(properties) {
            return new ProfileMsg(properties);
        };

        /**
         * Encodes the specified ProfileMsg message. Does not implicitly {@link cat.ProfileMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ProfileMsg
         * @static
         * @param {cat.IProfileMsg} m ProfileMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProfileMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.fullname != null && Object.hasOwnProperty.call(m, "fullname"))
                w.uint32(18).string(m.fullname);
            if (m.legend != null && Object.hasOwnProperty.call(m, "legend"))
                w.uint32(26).string(m.legend);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(32).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(40).uint64(m.lastmodified);
            if (m.avatar != null && Object.hasOwnProperty.call(m, "avatar"))
                $root.cat.AttachmentMsg.encode(m.avatar, w.uint32(810).fork()).ldelim();
            if (m.mediaitem != null && Object.hasOwnProperty.call(m, "mediaitem"))
                $root.cat.AttachmentMsg.encode(m.mediaitem, w.uint32(826).fork()).ldelim();
            if (m.device != null && Object.hasOwnProperty.call(m, "device"))
                $root.cat.DeviceMsg.encode(m.device, w.uint32(834).fork()).ldelim();
            if (m.apptypes != null && m.apptypes.length) {
                for (var i = 0; i < m.apptypes.length; ++i)
                    $root.cat.AppTypeMsg.encode(m.apptypes[i], w.uint32(842).fork()).ldelim();
            }
            if (m.reports != null && m.reports.length) {
                for (var i = 0; i < m.reports.length; ++i)
                    $root.cat.ReportMsg.encode(m.reports[i], w.uint32(850).fork()).ldelim();
            }
            if (m.labels != null && m.labels.length) {
                for (var i = 0; i < m.labels.length; ++i)
                    $root.cat.LabelMsg.encode(m.labels[i], w.uint32(858).fork()).ldelim();
            }
            if (m.triggers != null && m.triggers.length) {
                for (var i = 0; i < m.triggers.length; ++i)
                    $root.cat.TriggerMsg.encode(m.triggers[i], w.uint32(866).fork()).ldelim();
            }
            if (m.cases != null && m.cases.length) {
                for (var i = 0; i < m.cases.length; ++i)
                    $root.cat.CaseMsg.encode(m.cases[i], w.uint32(874).fork()).ldelim();
            }
            if (m.totalunreadmessages != null && Object.hasOwnProperty.call(m, "totalunreadmessages"))
                w.uint32(880).uint64(m.totalunreadmessages);
            if (m.startdate != null && Object.hasOwnProperty.call(m, "startdate"))
                w.uint32(888).uint64(m.startdate);
            if (m.enddate != null && Object.hasOwnProperty.call(m, "enddate"))
                w.uint32(896).uint64(m.enddate);
            if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
                w.uint32(904).uint64(m.offset);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(912).uint64(m.count);
            if (m.firstmessagedate != null && Object.hasOwnProperty.call(m, "firstmessagedate"))
                w.uint32(920).uint64(m.firstmessagedate);
            if (m.report != null && Object.hasOwnProperty.call(m, "report"))
                $root.cat.ReportMsg.encode(m.report, w.uint32(930).fork()).ldelim();
            if (m.theme != null && Object.hasOwnProperty.call(m, "theme"))
                $root.cat.ThemeMsg.encode(m.theme, w.uint32(946).fork()).ldelim();
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.cat.UserMsg.encode(m.user, w.uint32(954).fork()).ldelim();
            if (m.reviewers != null && m.reviewers.length) {
                for (var i = 0; i < m.reviewers.length; ++i)
                    $root.cat.UserMsg.encode(m.reviewers[i], w.uint32(962).fork()).ldelim();
            }
            if (m.hidden != null && Object.hasOwnProperty.call(m, "hidden"))
                w.uint32(968).int32(m.hidden);
            if (m.accounts != null && m.accounts.length) {
                for (var i = 0; i < m.accounts.length; ++i)
                    $root.cat.AccountMsg.encode(m.accounts[i], w.uint32(978).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ProfileMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ProfileMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ProfileMsg} ProfileMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProfileMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ProfileMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.fullname = r.string();
                        break;
                    }
                case 3: {
                        m.legend = r.string();
                        break;
                    }
                case 4: {
                        m.created = r.uint64();
                        break;
                    }
                case 5: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 101: {
                        m.avatar = $root.cat.AttachmentMsg.decode(r, r.uint32());
                        break;
                    }
                case 103: {
                        m.mediaitem = $root.cat.AttachmentMsg.decode(r, r.uint32());
                        break;
                    }
                case 104: {
                        m.device = $root.cat.DeviceMsg.decode(r, r.uint32());
                        break;
                    }
                case 105: {
                        if (!(m.apptypes && m.apptypes.length))
                            m.apptypes = [];
                        m.apptypes.push($root.cat.AppTypeMsg.decode(r, r.uint32()));
                        break;
                    }
                case 106: {
                        if (!(m.reports && m.reports.length))
                            m.reports = [];
                        m.reports.push($root.cat.ReportMsg.decode(r, r.uint32()));
                        break;
                    }
                case 107: {
                        if (!(m.labels && m.labels.length))
                            m.labels = [];
                        m.labels.push($root.cat.LabelMsg.decode(r, r.uint32()));
                        break;
                    }
                case 108: {
                        if (!(m.triggers && m.triggers.length))
                            m.triggers = [];
                        m.triggers.push($root.cat.TriggerMsg.decode(r, r.uint32()));
                        break;
                    }
                case 109: {
                        if (!(m.cases && m.cases.length))
                            m.cases = [];
                        m.cases.push($root.cat.CaseMsg.decode(r, r.uint32()));
                        break;
                    }
                case 110: {
                        m.totalunreadmessages = r.uint64();
                        break;
                    }
                case 111: {
                        m.startdate = r.uint64();
                        break;
                    }
                case 112: {
                        m.enddate = r.uint64();
                        break;
                    }
                case 113: {
                        m.offset = r.uint64();
                        break;
                    }
                case 114: {
                        m.count = r.uint64();
                        break;
                    }
                case 115: {
                        m.firstmessagedate = r.uint64();
                        break;
                    }
                case 116: {
                        m.report = $root.cat.ReportMsg.decode(r, r.uint32());
                        break;
                    }
                case 118: {
                        m.theme = $root.cat.ThemeMsg.decode(r, r.uint32());
                        break;
                    }
                case 119: {
                        m.user = $root.cat.UserMsg.decode(r, r.uint32());
                        break;
                    }
                case 120: {
                        if (!(m.reviewers && m.reviewers.length))
                            m.reviewers = [];
                        m.reviewers.push($root.cat.UserMsg.decode(r, r.uint32()));
                        break;
                    }
                case 121: {
                        m.hidden = r.int32();
                        break;
                    }
                case 122: {
                        if (!(m.accounts && m.accounts.length))
                            m.accounts = [];
                        m.accounts.push($root.cat.AccountMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ProfileMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ProfileMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ProfileMsg} ProfileMsg
         */
        ProfileMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ProfileMsg)
                return d;
            var m = new $root.cat.ProfileMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.fullname != null) {
                m.fullname = String(d.fullname);
            }
            if (d.legend != null) {
                m.legend = String(d.legend);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.avatar != null) {
                if (typeof d.avatar !== "object")
                    throw TypeError(".cat.ProfileMsg.avatar: object expected");
                m.avatar = $root.cat.AttachmentMsg.fromObject(d.avatar);
            }
            if (d.mediaitem != null) {
                if (typeof d.mediaitem !== "object")
                    throw TypeError(".cat.ProfileMsg.mediaitem: object expected");
                m.mediaitem = $root.cat.AttachmentMsg.fromObject(d.mediaitem);
            }
            if (d.device != null) {
                if (typeof d.device !== "object")
                    throw TypeError(".cat.ProfileMsg.device: object expected");
                m.device = $root.cat.DeviceMsg.fromObject(d.device);
            }
            if (d.apptypes) {
                if (!Array.isArray(d.apptypes))
                    throw TypeError(".cat.ProfileMsg.apptypes: array expected");
                m.apptypes = [];
                for (var i = 0; i < d.apptypes.length; ++i) {
                    if (typeof d.apptypes[i] !== "object")
                        throw TypeError(".cat.ProfileMsg.apptypes: object expected");
                    m.apptypes[i] = $root.cat.AppTypeMsg.fromObject(d.apptypes[i]);
                }
            }
            if (d.reports) {
                if (!Array.isArray(d.reports))
                    throw TypeError(".cat.ProfileMsg.reports: array expected");
                m.reports = [];
                for (var i = 0; i < d.reports.length; ++i) {
                    if (typeof d.reports[i] !== "object")
                        throw TypeError(".cat.ProfileMsg.reports: object expected");
                    m.reports[i] = $root.cat.ReportMsg.fromObject(d.reports[i]);
                }
            }
            if (d.labels) {
                if (!Array.isArray(d.labels))
                    throw TypeError(".cat.ProfileMsg.labels: array expected");
                m.labels = [];
                for (var i = 0; i < d.labels.length; ++i) {
                    if (typeof d.labels[i] !== "object")
                        throw TypeError(".cat.ProfileMsg.labels: object expected");
                    m.labels[i] = $root.cat.LabelMsg.fromObject(d.labels[i]);
                }
            }
            if (d.triggers) {
                if (!Array.isArray(d.triggers))
                    throw TypeError(".cat.ProfileMsg.triggers: array expected");
                m.triggers = [];
                for (var i = 0; i < d.triggers.length; ++i) {
                    if (typeof d.triggers[i] !== "object")
                        throw TypeError(".cat.ProfileMsg.triggers: object expected");
                    m.triggers[i] = $root.cat.TriggerMsg.fromObject(d.triggers[i]);
                }
            }
            if (d.cases) {
                if (!Array.isArray(d.cases))
                    throw TypeError(".cat.ProfileMsg.cases: array expected");
                m.cases = [];
                for (var i = 0; i < d.cases.length; ++i) {
                    if (typeof d.cases[i] !== "object")
                        throw TypeError(".cat.ProfileMsg.cases: object expected");
                    m.cases[i] = $root.cat.CaseMsg.fromObject(d.cases[i]);
                }
            }
            if (d.totalunreadmessages != null) {
                if ($util.Long)
                    (m.totalunreadmessages = $util.Long.fromValue(d.totalunreadmessages)).unsigned = true;
                else if (typeof d.totalunreadmessages === "string")
                    m.totalunreadmessages = parseInt(d.totalunreadmessages, 10);
                else if (typeof d.totalunreadmessages === "number")
                    m.totalunreadmessages = d.totalunreadmessages;
                else if (typeof d.totalunreadmessages === "object")
                    m.totalunreadmessages = new $util.LongBits(d.totalunreadmessages.low >>> 0, d.totalunreadmessages.high >>> 0).toNumber(true);
            }
            if (d.startdate != null) {
                if ($util.Long)
                    (m.startdate = $util.Long.fromValue(d.startdate)).unsigned = true;
                else if (typeof d.startdate === "string")
                    m.startdate = parseInt(d.startdate, 10);
                else if (typeof d.startdate === "number")
                    m.startdate = d.startdate;
                else if (typeof d.startdate === "object")
                    m.startdate = new $util.LongBits(d.startdate.low >>> 0, d.startdate.high >>> 0).toNumber(true);
            }
            if (d.enddate != null) {
                if ($util.Long)
                    (m.enddate = $util.Long.fromValue(d.enddate)).unsigned = true;
                else if (typeof d.enddate === "string")
                    m.enddate = parseInt(d.enddate, 10);
                else if (typeof d.enddate === "number")
                    m.enddate = d.enddate;
                else if (typeof d.enddate === "object")
                    m.enddate = new $util.LongBits(d.enddate.low >>> 0, d.enddate.high >>> 0).toNumber(true);
            }
            if (d.offset != null) {
                if ($util.Long)
                    (m.offset = $util.Long.fromValue(d.offset)).unsigned = true;
                else if (typeof d.offset === "string")
                    m.offset = parseInt(d.offset, 10);
                else if (typeof d.offset === "number")
                    m.offset = d.offset;
                else if (typeof d.offset === "object")
                    m.offset = new $util.LongBits(d.offset.low >>> 0, d.offset.high >>> 0).toNumber(true);
            }
            if (d.count != null) {
                if ($util.Long)
                    (m.count = $util.Long.fromValue(d.count)).unsigned = true;
                else if (typeof d.count === "string")
                    m.count = parseInt(d.count, 10);
                else if (typeof d.count === "number")
                    m.count = d.count;
                else if (typeof d.count === "object")
                    m.count = new $util.LongBits(d.count.low >>> 0, d.count.high >>> 0).toNumber(true);
            }
            if (d.firstmessagedate != null) {
                if ($util.Long)
                    (m.firstmessagedate = $util.Long.fromValue(d.firstmessagedate)).unsigned = true;
                else if (typeof d.firstmessagedate === "string")
                    m.firstmessagedate = parseInt(d.firstmessagedate, 10);
                else if (typeof d.firstmessagedate === "number")
                    m.firstmessagedate = d.firstmessagedate;
                else if (typeof d.firstmessagedate === "object")
                    m.firstmessagedate = new $util.LongBits(d.firstmessagedate.low >>> 0, d.firstmessagedate.high >>> 0).toNumber(true);
            }
            if (d.report != null) {
                if (typeof d.report !== "object")
                    throw TypeError(".cat.ProfileMsg.report: object expected");
                m.report = $root.cat.ReportMsg.fromObject(d.report);
            }
            if (d.theme != null) {
                if (typeof d.theme !== "object")
                    throw TypeError(".cat.ProfileMsg.theme: object expected");
                m.theme = $root.cat.ThemeMsg.fromObject(d.theme);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".cat.ProfileMsg.user: object expected");
                m.user = $root.cat.UserMsg.fromObject(d.user);
            }
            if (d.reviewers) {
                if (!Array.isArray(d.reviewers))
                    throw TypeError(".cat.ProfileMsg.reviewers: array expected");
                m.reviewers = [];
                for (var i = 0; i < d.reviewers.length; ++i) {
                    if (typeof d.reviewers[i] !== "object")
                        throw TypeError(".cat.ProfileMsg.reviewers: object expected");
                    m.reviewers[i] = $root.cat.UserMsg.fromObject(d.reviewers[i]);
                }
            }
            switch (d.hidden) {
            default:
                if (typeof d.hidden === "number") {
                    m.hidden = d.hidden;
                    break;
                }
                break;
            case "VISIBILITY_UNKNOWN":
            case 0:
                m.hidden = 0;
                break;
            case "VISIBILITY_VISIBLE":
            case 1:
                m.hidden = 1;
                break;
            case "VISIBILITY_HIDDEN":
            case 2:
                m.hidden = 2;
                break;
            }
            if (d.accounts) {
                if (!Array.isArray(d.accounts))
                    throw TypeError(".cat.ProfileMsg.accounts: array expected");
                m.accounts = [];
                for (var i = 0; i < d.accounts.length; ++i) {
                    if (typeof d.accounts[i] !== "object")
                        throw TypeError(".cat.ProfileMsg.accounts: object expected");
                    m.accounts[i] = $root.cat.AccountMsg.fromObject(d.accounts[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ProfileMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ProfileMsg
         * @static
         * @param {cat.ProfileMsg} m ProfileMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProfileMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.apptypes = [];
                d.reports = [];
                d.labels = [];
                d.triggers = [];
                d.cases = [];
                d.reviewers = [];
                d.accounts = [];
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.avatar = null;
                d.mediaitem = null;
                d.device = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.totalunreadmessages = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.totalunreadmessages = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.startdate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startdate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.enddate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.enddate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.offset = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.offset = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.count = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.count = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.firstmessagedate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.firstmessagedate = o.longs === String ? "0" : 0;
                d.report = null;
                d.theme = null;
                d.user = null;
                d.hidden = o.enums === String ? "VISIBILITY_UNKNOWN" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.fullname != null && m.hasOwnProperty("fullname")) {
                d.fullname = m.fullname;
                if (o.oneofs)
                    d._fullname = "fullname";
            }
            if (m.legend != null && m.hasOwnProperty("legend")) {
                d.legend = m.legend;
                if (o.oneofs)
                    d._legend = "legend";
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.avatar != null && m.hasOwnProperty("avatar")) {
                d.avatar = $root.cat.AttachmentMsg.toObject(m.avatar, o);
            }
            if (m.mediaitem != null && m.hasOwnProperty("mediaitem")) {
                d.mediaitem = $root.cat.AttachmentMsg.toObject(m.mediaitem, o);
            }
            if (m.device != null && m.hasOwnProperty("device")) {
                d.device = $root.cat.DeviceMsg.toObject(m.device, o);
            }
            if (m.apptypes && m.apptypes.length) {
                d.apptypes = [];
                for (var j = 0; j < m.apptypes.length; ++j) {
                    d.apptypes[j] = $root.cat.AppTypeMsg.toObject(m.apptypes[j], o);
                }
            }
            if (m.reports && m.reports.length) {
                d.reports = [];
                for (var j = 0; j < m.reports.length; ++j) {
                    d.reports[j] = $root.cat.ReportMsg.toObject(m.reports[j], o);
                }
            }
            if (m.labels && m.labels.length) {
                d.labels = [];
                for (var j = 0; j < m.labels.length; ++j) {
                    d.labels[j] = $root.cat.LabelMsg.toObject(m.labels[j], o);
                }
            }
            if (m.triggers && m.triggers.length) {
                d.triggers = [];
                for (var j = 0; j < m.triggers.length; ++j) {
                    d.triggers[j] = $root.cat.TriggerMsg.toObject(m.triggers[j], o);
                }
            }
            if (m.cases && m.cases.length) {
                d.cases = [];
                for (var j = 0; j < m.cases.length; ++j) {
                    d.cases[j] = $root.cat.CaseMsg.toObject(m.cases[j], o);
                }
            }
            if (m.totalunreadmessages != null && m.hasOwnProperty("totalunreadmessages")) {
                if (typeof m.totalunreadmessages === "number")
                    d.totalunreadmessages = o.longs === String ? String(m.totalunreadmessages) : m.totalunreadmessages;
                else
                    d.totalunreadmessages = o.longs === String ? $util.Long.prototype.toString.call(m.totalunreadmessages) : o.longs === Number ? new $util.LongBits(m.totalunreadmessages.low >>> 0, m.totalunreadmessages.high >>> 0).toNumber(true) : m.totalunreadmessages;
            }
            if (m.startdate != null && m.hasOwnProperty("startdate")) {
                if (typeof m.startdate === "number")
                    d.startdate = o.longs === String ? String(m.startdate) : m.startdate;
                else
                    d.startdate = o.longs === String ? $util.Long.prototype.toString.call(m.startdate) : o.longs === Number ? new $util.LongBits(m.startdate.low >>> 0, m.startdate.high >>> 0).toNumber(true) : m.startdate;
            }
            if (m.enddate != null && m.hasOwnProperty("enddate")) {
                if (typeof m.enddate === "number")
                    d.enddate = o.longs === String ? String(m.enddate) : m.enddate;
                else
                    d.enddate = o.longs === String ? $util.Long.prototype.toString.call(m.enddate) : o.longs === Number ? new $util.LongBits(m.enddate.low >>> 0, m.enddate.high >>> 0).toNumber(true) : m.enddate;
            }
            if (m.offset != null && m.hasOwnProperty("offset")) {
                if (typeof m.offset === "number")
                    d.offset = o.longs === String ? String(m.offset) : m.offset;
                else
                    d.offset = o.longs === String ? $util.Long.prototype.toString.call(m.offset) : o.longs === Number ? new $util.LongBits(m.offset.low >>> 0, m.offset.high >>> 0).toNumber(true) : m.offset;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                if (typeof m.count === "number")
                    d.count = o.longs === String ? String(m.count) : m.count;
                else
                    d.count = o.longs === String ? $util.Long.prototype.toString.call(m.count) : o.longs === Number ? new $util.LongBits(m.count.low >>> 0, m.count.high >>> 0).toNumber(true) : m.count;
            }
            if (m.firstmessagedate != null && m.hasOwnProperty("firstmessagedate")) {
                if (typeof m.firstmessagedate === "number")
                    d.firstmessagedate = o.longs === String ? String(m.firstmessagedate) : m.firstmessagedate;
                else
                    d.firstmessagedate = o.longs === String ? $util.Long.prototype.toString.call(m.firstmessagedate) : o.longs === Number ? new $util.LongBits(m.firstmessagedate.low >>> 0, m.firstmessagedate.high >>> 0).toNumber(true) : m.firstmessagedate;
            }
            if (m.report != null && m.hasOwnProperty("report")) {
                d.report = $root.cat.ReportMsg.toObject(m.report, o);
            }
            if (m.theme != null && m.hasOwnProperty("theme")) {
                d.theme = $root.cat.ThemeMsg.toObject(m.theme, o);
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.cat.UserMsg.toObject(m.user, o);
            }
            if (m.reviewers && m.reviewers.length) {
                d.reviewers = [];
                for (var j = 0; j < m.reviewers.length; ++j) {
                    d.reviewers[j] = $root.cat.UserMsg.toObject(m.reviewers[j], o);
                }
            }
            if (m.hidden != null && m.hasOwnProperty("hidden")) {
                d.hidden = o.enums === String ? $root.cat.VisibilityType[m.hidden] === undefined ? m.hidden : $root.cat.VisibilityType[m.hidden] : m.hidden;
            }
            if (m.accounts && m.accounts.length) {
                d.accounts = [];
                for (var j = 0; j < m.accounts.length; ++j) {
                    d.accounts[j] = $root.cat.AccountMsg.toObject(m.accounts[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ProfileMsg to JSON.
         * @function toJSON
         * @memberof cat.ProfileMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProfileMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProfileMsg
         * @function getTypeUrl
         * @memberof cat.ProfileMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProfileMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ProfileMsg";
        };

        return ProfileMsg;
    })();

    /**
     * DeviceType enum.
     * @name cat.DeviceType
     * @enum {number}
     * @property {number} DEVICETYPE_UNKNOWN=0 DEVICETYPE_UNKNOWN value
     * @property {number} DEVICETYPE_VIRTUAL=1 DEVICETYPE_VIRTUAL value
     * @property {number} DEVICETYPE_PHYSICAL=2 DEVICETYPE_PHYSICAL value
     */
    cat.DeviceType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEVICETYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "DEVICETYPE_VIRTUAL"] = 1;
        values[valuesById[2] = "DEVICETYPE_PHYSICAL"] = 2;
        return values;
    })();

    /**
     * DeviceArchitecture enum.
     * @name cat.DeviceArchitecture
     * @enum {number}
     * @property {number} DEVICEARCHITECTURE_UNKNOWN=0 DEVICEARCHITECTURE_UNKNOWN value
     * @property {number} DEVICEARCHITECTURE_ARMEABI=1 DEVICEARCHITECTURE_ARMEABI value
     * @property {number} DEVICEARCHITECTURE_ARMEABI_V7A=2 DEVICEARCHITECTURE_ARMEABI_V7A value
     * @property {number} DEVICEARCHITECTURE_ARM64_V8A=3 DEVICEARCHITECTURE_ARM64_V8A value
     * @property {number} DEVICEARCHITECTURE_X86_64=4 DEVICEARCHITECTURE_X86_64 value
     * @property {number} DEVICEARCHITECTURE_X86=5 DEVICEARCHITECTURE_X86 value
     * @property {number} DEVICEARCHITECTURE_MIPS=6 DEVICEARCHITECTURE_MIPS value
     * @property {number} DEVICEARCHITECTURE_MIPS64=7 DEVICEARCHITECTURE_MIPS64 value
     * @property {number} DEVICEARCHITECTURE_RISCV64=8 DEVICEARCHITECTURE_RISCV64 value
     */
    cat.DeviceArchitecture = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEVICEARCHITECTURE_UNKNOWN"] = 0;
        values[valuesById[1] = "DEVICEARCHITECTURE_ARMEABI"] = 1;
        values[valuesById[2] = "DEVICEARCHITECTURE_ARMEABI_V7A"] = 2;
        values[valuesById[3] = "DEVICEARCHITECTURE_ARM64_V8A"] = 3;
        values[valuesById[4] = "DEVICEARCHITECTURE_X86_64"] = 4;
        values[valuesById[5] = "DEVICEARCHITECTURE_X86"] = 5;
        values[valuesById[6] = "DEVICEARCHITECTURE_MIPS"] = 6;
        values[valuesById[7] = "DEVICEARCHITECTURE_MIPS64"] = 7;
        values[valuesById[8] = "DEVICEARCHITECTURE_RISCV64"] = 8;
        return values;
    })();

    cat.DeviceMsg = (function() {

        /**
         * Properties of a DeviceMsg.
         * @memberof cat
         * @interface IDeviceMsg
         * @property {string|null} [id] DeviceMsg id
         * @property {string|null} [name] DeviceMsg name
         * @property {cat.StatusType|null} [status] DeviceMsg status
         * @property {string|null} [description] DeviceMsg description
         * @property {number|null} [created] DeviceMsg created
         * @property {number|null} [lastmodified] DeviceMsg lastmodified
         * @property {string|null} [physicalid] DeviceMsg physicalid
         * @property {cat.DeviceType|null} [type] DeviceMsg type
         * @property {number|null} [lastsync] DeviceMsg lastsync
         * @property {boolean|null} [lastsyncsuccess] DeviceMsg lastsyncsuccess
         * @property {cat.IDevicePropertiesMsg|null} [properties] DeviceMsg properties
         * @property {Array.<cat.DeviceArchitecture>|null} [architecture] DeviceMsg architecture
         * @property {string|null} [imei] DeviceMsg imei
         * @property {Array.<cat.IAppMsg>|null} [apps] DeviceMsg apps
         * @property {Array.<cat.IAccountMsg>|null} [accounts] DeviceMsg accounts
         * @property {cat.IUserMsg|null} [user] DeviceMsg user
         * @property {Array.<cat.IReceiverBufferStatusMsg>|null} [receiverbufferstatus] DeviceMsg receiverbufferstatus
         * @property {number|null} [unlinkedaccounts] DeviceMsg unlinkedaccounts
         * @property {cat.IMediaFileMsg|null} [logfile] DeviceMsg logfile
         * @property {cat.IProfileMsg|null} [profile] DeviceMsg profile
         * @property {cat.IBackUpMsg|null} [backup] DeviceMsg backup
         * @property {number|null} [backupcount] DeviceMsg backupcount
         * @property {cat.IDomainMsg|null} [domain] DeviceMsg domain
         * @property {cat.IQueryMsg|null} [query] DeviceMsg query
         */

        /**
         * Constructs a new DeviceMsg.
         * @memberof cat
         * @classdesc Represents a DeviceMsg.
         * @implements IDeviceMsg
         * @constructor
         * @param {cat.IDeviceMsg=} [p] Properties to set
         */
        function DeviceMsg(p) {
            this.architecture = [];
            this.apps = [];
            this.accounts = [];
            this.receiverbufferstatus = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DeviceMsg id.
         * @member {string} id
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.id = "";

        /**
         * DeviceMsg name.
         * @member {string} name
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.name = "";

        /**
         * DeviceMsg status.
         * @member {cat.StatusType} status
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.status = 0;

        /**
         * DeviceMsg description.
         * @member {string} description
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.description = "";

        /**
         * DeviceMsg created.
         * @member {number} created
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DeviceMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DeviceMsg physicalid.
         * @member {string} physicalid
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.physicalid = "";

        /**
         * DeviceMsg type.
         * @member {cat.DeviceType} type
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.type = 0;

        /**
         * DeviceMsg lastsync.
         * @member {number} lastsync
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.lastsync = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DeviceMsg lastsyncsuccess.
         * @member {boolean} lastsyncsuccess
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.lastsyncsuccess = false;

        /**
         * DeviceMsg properties.
         * @member {cat.IDevicePropertiesMsg|null|undefined} properties
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.properties = null;

        /**
         * DeviceMsg architecture.
         * @member {Array.<cat.DeviceArchitecture>} architecture
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.architecture = $util.emptyArray;

        /**
         * DeviceMsg imei.
         * @member {string} imei
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.imei = "";

        /**
         * DeviceMsg apps.
         * @member {Array.<cat.IAppMsg>} apps
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.apps = $util.emptyArray;

        /**
         * DeviceMsg accounts.
         * @member {Array.<cat.IAccountMsg>} accounts
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.accounts = $util.emptyArray;

        /**
         * DeviceMsg user.
         * @member {cat.IUserMsg|null|undefined} user
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.user = null;

        /**
         * DeviceMsg receiverbufferstatus.
         * @member {Array.<cat.IReceiverBufferStatusMsg>} receiverbufferstatus
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.receiverbufferstatus = $util.emptyArray;

        /**
         * DeviceMsg unlinkedaccounts.
         * @member {number} unlinkedaccounts
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.unlinkedaccounts = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DeviceMsg logfile.
         * @member {cat.IMediaFileMsg|null|undefined} logfile
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.logfile = null;

        /**
         * DeviceMsg profile.
         * @member {cat.IProfileMsg|null|undefined} profile
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.profile = null;

        /**
         * DeviceMsg backup.
         * @member {cat.IBackUpMsg|null|undefined} backup
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.backup = null;

        /**
         * DeviceMsg backupcount.
         * @member {number|null|undefined} backupcount
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.backupcount = null;

        /**
         * DeviceMsg domain.
         * @member {cat.IDomainMsg|null|undefined} domain
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.domain = null;

        /**
         * DeviceMsg query.
         * @member {cat.IQueryMsg|null|undefined} query
         * @memberof cat.DeviceMsg
         * @instance
         */
        DeviceMsg.prototype.query = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(DeviceMsg.prototype, "_profile", {
            get: $util.oneOfGetter($oneOfFields = ["profile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(DeviceMsg.prototype, "_backup", {
            get: $util.oneOfGetter($oneOfFields = ["backup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(DeviceMsg.prototype, "_backupcount", {
            get: $util.oneOfGetter($oneOfFields = ["backupcount"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(DeviceMsg.prototype, "_query", {
            get: $util.oneOfGetter($oneOfFields = ["query"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DeviceMsg instance using the specified properties.
         * @function create
         * @memberof cat.DeviceMsg
         * @static
         * @param {cat.IDeviceMsg=} [properties] Properties to set
         * @returns {cat.DeviceMsg} DeviceMsg instance
         */
        DeviceMsg.create = function create(properties) {
            return new DeviceMsg(properties);
        };

        /**
         * Encodes the specified DeviceMsg message. Does not implicitly {@link cat.DeviceMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.DeviceMsg
         * @static
         * @param {cat.IDeviceMsg} m DeviceMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeviceMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(24).int32(m.status);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(40).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(48).uint64(m.lastmodified);
            if (m.physicalid != null && Object.hasOwnProperty.call(m, "physicalid"))
                w.uint32(66).string(m.physicalid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(72).int32(m.type);
            if (m.lastsync != null && Object.hasOwnProperty.call(m, "lastsync"))
                w.uint32(80).uint64(m.lastsync);
            if (m.lastsyncsuccess != null && Object.hasOwnProperty.call(m, "lastsyncsuccess"))
                w.uint32(88).bool(m.lastsyncsuccess);
            if (m.properties != null && Object.hasOwnProperty.call(m, "properties"))
                $root.cat.DevicePropertiesMsg.encode(m.properties, w.uint32(98).fork()).ldelim();
            if (m.architecture != null && m.architecture.length) {
                w.uint32(106).fork();
                for (var i = 0; i < m.architecture.length; ++i)
                    w.int32(m.architecture[i]);
                w.ldelim();
            }
            if (m.imei != null && Object.hasOwnProperty.call(m, "imei"))
                w.uint32(114).string(m.imei);
            if (m.apps != null && m.apps.length) {
                for (var i = 0; i < m.apps.length; ++i)
                    $root.cat.AppMsg.encode(m.apps[i], w.uint32(834).fork()).ldelim();
            }
            if (m.accounts != null && m.accounts.length) {
                for (var i = 0; i < m.accounts.length; ++i)
                    $root.cat.AccountMsg.encode(m.accounts[i], w.uint32(850).fork()).ldelim();
            }
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.cat.UserMsg.encode(m.user, w.uint32(858).fork()).ldelim();
            if (m.receiverbufferstatus != null && m.receiverbufferstatus.length) {
                for (var i = 0; i < m.receiverbufferstatus.length; ++i)
                    $root.cat.ReceiverBufferStatusMsg.encode(m.receiverbufferstatus[i], w.uint32(866).fork()).ldelim();
            }
            if (m.unlinkedaccounts != null && Object.hasOwnProperty.call(m, "unlinkedaccounts"))
                w.uint32(872).uint64(m.unlinkedaccounts);
            if (m.logfile != null && Object.hasOwnProperty.call(m, "logfile"))
                $root.cat.MediaFileMsg.encode(m.logfile, w.uint32(882).fork()).ldelim();
            if (m.profile != null && Object.hasOwnProperty.call(m, "profile"))
                $root.cat.ProfileMsg.encode(m.profile, w.uint32(890).fork()).ldelim();
            if (m.backup != null && Object.hasOwnProperty.call(m, "backup"))
                $root.cat.BackUpMsg.encode(m.backup, w.uint32(898).fork()).ldelim();
            if (m.backupcount != null && Object.hasOwnProperty.call(m, "backupcount"))
                w.uint32(904).uint64(m.backupcount);
            if (m.domain != null && Object.hasOwnProperty.call(m, "domain"))
                $root.cat.DomainMsg.encode(m.domain, w.uint32(914).fork()).ldelim();
            if (m.query != null && Object.hasOwnProperty.call(m, "query"))
                $root.cat.QueryMsg.encode(m.query, w.uint32(962).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a DeviceMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.DeviceMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.DeviceMsg} DeviceMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeviceMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.DeviceMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.status = r.int32();
                        break;
                    }
                case 4: {
                        m.description = r.string();
                        break;
                    }
                case 5: {
                        m.created = r.uint64();
                        break;
                    }
                case 6: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 8: {
                        m.physicalid = r.string();
                        break;
                    }
                case 9: {
                        m.type = r.int32();
                        break;
                    }
                case 10: {
                        m.lastsync = r.uint64();
                        break;
                    }
                case 11: {
                        m.lastsyncsuccess = r.bool();
                        break;
                    }
                case 12: {
                        m.properties = $root.cat.DevicePropertiesMsg.decode(r, r.uint32());
                        break;
                    }
                case 13: {
                        if (!(m.architecture && m.architecture.length))
                            m.architecture = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.architecture.push(r.int32());
                        } else
                            m.architecture.push(r.int32());
                        break;
                    }
                case 14: {
                        m.imei = r.string();
                        break;
                    }
                case 104: {
                        if (!(m.apps && m.apps.length))
                            m.apps = [];
                        m.apps.push($root.cat.AppMsg.decode(r, r.uint32()));
                        break;
                    }
                case 106: {
                        if (!(m.accounts && m.accounts.length))
                            m.accounts = [];
                        m.accounts.push($root.cat.AccountMsg.decode(r, r.uint32()));
                        break;
                    }
                case 107: {
                        m.user = $root.cat.UserMsg.decode(r, r.uint32());
                        break;
                    }
                case 108: {
                        if (!(m.receiverbufferstatus && m.receiverbufferstatus.length))
                            m.receiverbufferstatus = [];
                        m.receiverbufferstatus.push($root.cat.ReceiverBufferStatusMsg.decode(r, r.uint32()));
                        break;
                    }
                case 109: {
                        m.unlinkedaccounts = r.uint64();
                        break;
                    }
                case 110: {
                        m.logfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 111: {
                        m.profile = $root.cat.ProfileMsg.decode(r, r.uint32());
                        break;
                    }
                case 112: {
                        m.backup = $root.cat.BackUpMsg.decode(r, r.uint32());
                        break;
                    }
                case 113: {
                        m.backupcount = r.uint64();
                        break;
                    }
                case 114: {
                        m.domain = $root.cat.DomainMsg.decode(r, r.uint32());
                        break;
                    }
                case 120: {
                        m.query = $root.cat.QueryMsg.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DeviceMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.DeviceMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.DeviceMsg} DeviceMsg
         */
        DeviceMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.DeviceMsg)
                return d;
            var m = new $root.cat.DeviceMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ENABLED":
            case 1:
                m.status = 1;
                break;
            case "DISABLED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.physicalid != null) {
                m.physicalid = String(d.physicalid);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "DEVICETYPE_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "DEVICETYPE_VIRTUAL":
            case 1:
                m.type = 1;
                break;
            case "DEVICETYPE_PHYSICAL":
            case 2:
                m.type = 2;
                break;
            }
            if (d.lastsync != null) {
                if ($util.Long)
                    (m.lastsync = $util.Long.fromValue(d.lastsync)).unsigned = true;
                else if (typeof d.lastsync === "string")
                    m.lastsync = parseInt(d.lastsync, 10);
                else if (typeof d.lastsync === "number")
                    m.lastsync = d.lastsync;
                else if (typeof d.lastsync === "object")
                    m.lastsync = new $util.LongBits(d.lastsync.low >>> 0, d.lastsync.high >>> 0).toNumber(true);
            }
            if (d.lastsyncsuccess != null) {
                m.lastsyncsuccess = Boolean(d.lastsyncsuccess);
            }
            if (d.properties != null) {
                if (typeof d.properties !== "object")
                    throw TypeError(".cat.DeviceMsg.properties: object expected");
                m.properties = $root.cat.DevicePropertiesMsg.fromObject(d.properties);
            }
            if (d.architecture) {
                if (!Array.isArray(d.architecture))
                    throw TypeError(".cat.DeviceMsg.architecture: array expected");
                m.architecture = [];
                for (var i = 0; i < d.architecture.length; ++i) {
                    switch (d.architecture[i]) {
                    default:
                        if (typeof d.architecture[i] === "number") {
                            m.architecture[i] = d.architecture[i];
                            break;
                        }
                    case "DEVICEARCHITECTURE_UNKNOWN":
                    case 0:
                        m.architecture[i] = 0;
                        break;
                    case "DEVICEARCHITECTURE_ARMEABI":
                    case 1:
                        m.architecture[i] = 1;
                        break;
                    case "DEVICEARCHITECTURE_ARMEABI_V7A":
                    case 2:
                        m.architecture[i] = 2;
                        break;
                    case "DEVICEARCHITECTURE_ARM64_V8A":
                    case 3:
                        m.architecture[i] = 3;
                        break;
                    case "DEVICEARCHITECTURE_X86_64":
                    case 4:
                        m.architecture[i] = 4;
                        break;
                    case "DEVICEARCHITECTURE_X86":
                    case 5:
                        m.architecture[i] = 5;
                        break;
                    case "DEVICEARCHITECTURE_MIPS":
                    case 6:
                        m.architecture[i] = 6;
                        break;
                    case "DEVICEARCHITECTURE_MIPS64":
                    case 7:
                        m.architecture[i] = 7;
                        break;
                    case "DEVICEARCHITECTURE_RISCV64":
                    case 8:
                        m.architecture[i] = 8;
                        break;
                    }
                }
            }
            if (d.imei != null) {
                m.imei = String(d.imei);
            }
            if (d.apps) {
                if (!Array.isArray(d.apps))
                    throw TypeError(".cat.DeviceMsg.apps: array expected");
                m.apps = [];
                for (var i = 0; i < d.apps.length; ++i) {
                    if (typeof d.apps[i] !== "object")
                        throw TypeError(".cat.DeviceMsg.apps: object expected");
                    m.apps[i] = $root.cat.AppMsg.fromObject(d.apps[i]);
                }
            }
            if (d.accounts) {
                if (!Array.isArray(d.accounts))
                    throw TypeError(".cat.DeviceMsg.accounts: array expected");
                m.accounts = [];
                for (var i = 0; i < d.accounts.length; ++i) {
                    if (typeof d.accounts[i] !== "object")
                        throw TypeError(".cat.DeviceMsg.accounts: object expected");
                    m.accounts[i] = $root.cat.AccountMsg.fromObject(d.accounts[i]);
                }
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".cat.DeviceMsg.user: object expected");
                m.user = $root.cat.UserMsg.fromObject(d.user);
            }
            if (d.receiverbufferstatus) {
                if (!Array.isArray(d.receiverbufferstatus))
                    throw TypeError(".cat.DeviceMsg.receiverbufferstatus: array expected");
                m.receiverbufferstatus = [];
                for (var i = 0; i < d.receiverbufferstatus.length; ++i) {
                    if (typeof d.receiverbufferstatus[i] !== "object")
                        throw TypeError(".cat.DeviceMsg.receiverbufferstatus: object expected");
                    m.receiverbufferstatus[i] = $root.cat.ReceiverBufferStatusMsg.fromObject(d.receiverbufferstatus[i]);
                }
            }
            if (d.unlinkedaccounts != null) {
                if ($util.Long)
                    (m.unlinkedaccounts = $util.Long.fromValue(d.unlinkedaccounts)).unsigned = true;
                else if (typeof d.unlinkedaccounts === "string")
                    m.unlinkedaccounts = parseInt(d.unlinkedaccounts, 10);
                else if (typeof d.unlinkedaccounts === "number")
                    m.unlinkedaccounts = d.unlinkedaccounts;
                else if (typeof d.unlinkedaccounts === "object")
                    m.unlinkedaccounts = new $util.LongBits(d.unlinkedaccounts.low >>> 0, d.unlinkedaccounts.high >>> 0).toNumber(true);
            }
            if (d.logfile != null) {
                if (typeof d.logfile !== "object")
                    throw TypeError(".cat.DeviceMsg.logfile: object expected");
                m.logfile = $root.cat.MediaFileMsg.fromObject(d.logfile);
            }
            if (d.profile != null) {
                if (typeof d.profile !== "object")
                    throw TypeError(".cat.DeviceMsg.profile: object expected");
                m.profile = $root.cat.ProfileMsg.fromObject(d.profile);
            }
            if (d.backup != null) {
                if (typeof d.backup !== "object")
                    throw TypeError(".cat.DeviceMsg.backup: object expected");
                m.backup = $root.cat.BackUpMsg.fromObject(d.backup);
            }
            if (d.backupcount != null) {
                if ($util.Long)
                    (m.backupcount = $util.Long.fromValue(d.backupcount)).unsigned = true;
                else if (typeof d.backupcount === "string")
                    m.backupcount = parseInt(d.backupcount, 10);
                else if (typeof d.backupcount === "number")
                    m.backupcount = d.backupcount;
                else if (typeof d.backupcount === "object")
                    m.backupcount = new $util.LongBits(d.backupcount.low >>> 0, d.backupcount.high >>> 0).toNumber(true);
            }
            if (d.domain != null) {
                if (typeof d.domain !== "object")
                    throw TypeError(".cat.DeviceMsg.domain: object expected");
                m.domain = $root.cat.DomainMsg.fromObject(d.domain);
            }
            if (d.query != null) {
                if (typeof d.query !== "object")
                    throw TypeError(".cat.DeviceMsg.query: object expected");
                m.query = $root.cat.QueryMsg.fromObject(d.query);
            }
            return m;
        };

        /**
         * Creates a plain object from a DeviceMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.DeviceMsg
         * @static
         * @param {cat.DeviceMsg} m DeviceMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeviceMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.architecture = [];
                d.apps = [];
                d.accounts = [];
                d.receiverbufferstatus = [];
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.status = o.enums === String ? "UNKNOWN" : 0;
                d.description = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.physicalid = "";
                d.type = o.enums === String ? "DEVICETYPE_UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastsync = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastsync = o.longs === String ? "0" : 0;
                d.lastsyncsuccess = false;
                d.properties = null;
                d.imei = "";
                d.user = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.unlinkedaccounts = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.unlinkedaccounts = o.longs === String ? "0" : 0;
                d.logfile = null;
                d.domain = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.StatusType[m.status] === undefined ? m.status : $root.cat.StatusType[m.status] : m.status;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.physicalid != null && m.hasOwnProperty("physicalid")) {
                d.physicalid = m.physicalid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.DeviceType[m.type] === undefined ? m.type : $root.cat.DeviceType[m.type] : m.type;
            }
            if (m.lastsync != null && m.hasOwnProperty("lastsync")) {
                if (typeof m.lastsync === "number")
                    d.lastsync = o.longs === String ? String(m.lastsync) : m.lastsync;
                else
                    d.lastsync = o.longs === String ? $util.Long.prototype.toString.call(m.lastsync) : o.longs === Number ? new $util.LongBits(m.lastsync.low >>> 0, m.lastsync.high >>> 0).toNumber(true) : m.lastsync;
            }
            if (m.lastsyncsuccess != null && m.hasOwnProperty("lastsyncsuccess")) {
                d.lastsyncsuccess = m.lastsyncsuccess;
            }
            if (m.properties != null && m.hasOwnProperty("properties")) {
                d.properties = $root.cat.DevicePropertiesMsg.toObject(m.properties, o);
            }
            if (m.architecture && m.architecture.length) {
                d.architecture = [];
                for (var j = 0; j < m.architecture.length; ++j) {
                    d.architecture[j] = o.enums === String ? $root.cat.DeviceArchitecture[m.architecture[j]] === undefined ? m.architecture[j] : $root.cat.DeviceArchitecture[m.architecture[j]] : m.architecture[j];
                }
            }
            if (m.imei != null && m.hasOwnProperty("imei")) {
                d.imei = m.imei;
            }
            if (m.apps && m.apps.length) {
                d.apps = [];
                for (var j = 0; j < m.apps.length; ++j) {
                    d.apps[j] = $root.cat.AppMsg.toObject(m.apps[j], o);
                }
            }
            if (m.accounts && m.accounts.length) {
                d.accounts = [];
                for (var j = 0; j < m.accounts.length; ++j) {
                    d.accounts[j] = $root.cat.AccountMsg.toObject(m.accounts[j], o);
                }
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.cat.UserMsg.toObject(m.user, o);
            }
            if (m.receiverbufferstatus && m.receiverbufferstatus.length) {
                d.receiverbufferstatus = [];
                for (var j = 0; j < m.receiverbufferstatus.length; ++j) {
                    d.receiverbufferstatus[j] = $root.cat.ReceiverBufferStatusMsg.toObject(m.receiverbufferstatus[j], o);
                }
            }
            if (m.unlinkedaccounts != null && m.hasOwnProperty("unlinkedaccounts")) {
                if (typeof m.unlinkedaccounts === "number")
                    d.unlinkedaccounts = o.longs === String ? String(m.unlinkedaccounts) : m.unlinkedaccounts;
                else
                    d.unlinkedaccounts = o.longs === String ? $util.Long.prototype.toString.call(m.unlinkedaccounts) : o.longs === Number ? new $util.LongBits(m.unlinkedaccounts.low >>> 0, m.unlinkedaccounts.high >>> 0).toNumber(true) : m.unlinkedaccounts;
            }
            if (m.logfile != null && m.hasOwnProperty("logfile")) {
                d.logfile = $root.cat.MediaFileMsg.toObject(m.logfile, o);
            }
            if (m.profile != null && m.hasOwnProperty("profile")) {
                d.profile = $root.cat.ProfileMsg.toObject(m.profile, o);
                if (o.oneofs)
                    d._profile = "profile";
            }
            if (m.backup != null && m.hasOwnProperty("backup")) {
                d.backup = $root.cat.BackUpMsg.toObject(m.backup, o);
                if (o.oneofs)
                    d._backup = "backup";
            }
            if (m.backupcount != null && m.hasOwnProperty("backupcount")) {
                if (typeof m.backupcount === "number")
                    d.backupcount = o.longs === String ? String(m.backupcount) : m.backupcount;
                else
                    d.backupcount = o.longs === String ? $util.Long.prototype.toString.call(m.backupcount) : o.longs === Number ? new $util.LongBits(m.backupcount.low >>> 0, m.backupcount.high >>> 0).toNumber(true) : m.backupcount;
                if (o.oneofs)
                    d._backupcount = "backupcount";
            }
            if (m.domain != null && m.hasOwnProperty("domain")) {
                d.domain = $root.cat.DomainMsg.toObject(m.domain, o);
            }
            if (m.query != null && m.hasOwnProperty("query")) {
                d.query = $root.cat.QueryMsg.toObject(m.query, o);
                if (o.oneofs)
                    d._query = "query";
            }
            return d;
        };

        /**
         * Converts this DeviceMsg to JSON.
         * @function toJSON
         * @memberof cat.DeviceMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeviceMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeviceMsg
         * @function getTypeUrl
         * @memberof cat.DeviceMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeviceMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.DeviceMsg";
        };

        return DeviceMsg;
    })();

    /**
     * AppType enum.
     * @name cat.AppType
     * @enum {number}
     * @property {number} APP_UNKNOWN=0 APP_UNKNOWN value
     * @property {number} APP_SYSTEM=1 APP_SYSTEM value
     * @property {number} APP_DEVICE=2 APP_DEVICE value
     */
    cat.AppType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "APP_UNKNOWN"] = 0;
        values[valuesById[1] = "APP_SYSTEM"] = 1;
        values[valuesById[2] = "APP_DEVICE"] = 2;
        return values;
    })();

    /**
     * AppFeatures enum.
     * @name cat.AppFeatures
     * @enum {number}
     * @property {number} APPFEATURE_UNKNOWN=0 APPFEATURE_UNKNOWN value
     * @property {number} APPFEATURE_AVATARS=1 APPFEATURE_AVATARS value
     * @property {number} APPFEATURE_SERVERS=2 APPFEATURE_SERVERS value
     * @property {number} APPFEATURE_TOPICS=3 APPFEATURE_TOPICS value
     * @property {number} APPFEATURE_MULTIPLE_ATTACHMENTS=4 APPFEATURE_MULTIPLE_ATTACHMENTS value
     */
    cat.AppFeatures = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "APPFEATURE_UNKNOWN"] = 0;
        values[valuesById[1] = "APPFEATURE_AVATARS"] = 1;
        values[valuesById[2] = "APPFEATURE_SERVERS"] = 2;
        values[valuesById[3] = "APPFEATURE_TOPICS"] = 3;
        values[valuesById[4] = "APPFEATURE_MULTIPLE_ATTACHMENTS"] = 4;
        return values;
    })();

    cat.AppTypeMsg = (function() {

        /**
         * Properties of an AppTypeMsg.
         * @memberof cat
         * @interface IAppTypeMsg
         * @property {string|null} [id] AppTypeMsg id
         * @property {string|null} [name] AppTypeMsg name
         * @property {string|null} [description] AppTypeMsg description
         * @property {cat.AppType|null} [type] AppTypeMsg type
         * @property {cat.StatusType|null} [status] AppTypeMsg status
         * @property {number|null} [created] AppTypeMsg created
         * @property {number|null} [lastmodified] AppTypeMsg lastmodified
         * @property {number|null} [obsolete] AppTypeMsg obsolete
         * @property {Array.<cat.AppFeatures>|null} [features] AppTypeMsg features
         * @property {string|null} [iconfileid] AppTypeMsg iconfileid
         * @property {string|null} [iconmimetype] AppTypeMsg iconmimetype
         * @property {Object.<string,string>|null} [properties] AppTypeMsg properties
         * @property {string|null} [packagename] AppTypeMsg packagename
         * @property {cat.IPackageVersionMsg|null} [latest] AppTypeMsg latest
         * @property {cat.IPackageVersionMsg|null} [oldest] AppTypeMsg oldest
         * @property {number|null} [unreadmessages] AppTypeMsg unreadmessages
         * @property {number|null} [conversationcount] AppTypeMsg conversationcount
         * @property {cat.IMediaMsg|null} [iconmedia] AppTypeMsg iconmedia
         * @property {number|null} [versioncode] AppTypeMsg versioncode
         * @property {string|null} [versionname] AppTypeMsg versionname
         */

        /**
         * Constructs a new AppTypeMsg.
         * @memberof cat
         * @classdesc Represents an AppTypeMsg.
         * @implements IAppTypeMsg
         * @constructor
         * @param {cat.IAppTypeMsg=} [p] Properties to set
         */
        function AppTypeMsg(p) {
            this.features = [];
            this.properties = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AppTypeMsg id.
         * @member {string} id
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.id = "";

        /**
         * AppTypeMsg name.
         * @member {string} name
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.name = "";

        /**
         * AppTypeMsg description.
         * @member {string} description
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.description = "";

        /**
         * AppTypeMsg type.
         * @member {cat.AppType} type
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.type = 0;

        /**
         * AppTypeMsg status.
         * @member {cat.StatusType} status
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.status = 0;

        /**
         * AppTypeMsg created.
         * @member {number} created
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AppTypeMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AppTypeMsg obsolete.
         * @member {number} obsolete
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.obsolete = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AppTypeMsg features.
         * @member {Array.<cat.AppFeatures>} features
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.features = $util.emptyArray;

        /**
         * AppTypeMsg iconfileid.
         * @member {string} iconfileid
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.iconfileid = "";

        /**
         * AppTypeMsg iconmimetype.
         * @member {string} iconmimetype
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.iconmimetype = "";

        /**
         * AppTypeMsg properties.
         * @member {Object.<string,string>} properties
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.properties = $util.emptyObject;

        /**
         * AppTypeMsg packagename.
         * @member {string} packagename
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.packagename = "";

        /**
         * AppTypeMsg latest.
         * @member {cat.IPackageVersionMsg|null|undefined} latest
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.latest = null;

        /**
         * AppTypeMsg oldest.
         * @member {cat.IPackageVersionMsg|null|undefined} oldest
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.oldest = null;

        /**
         * AppTypeMsg unreadmessages.
         * @member {number} unreadmessages
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.unreadmessages = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AppTypeMsg conversationcount.
         * @member {number} conversationcount
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.conversationcount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AppTypeMsg iconmedia.
         * @member {cat.IMediaMsg|null|undefined} iconmedia
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.iconmedia = null;

        /**
         * AppTypeMsg versioncode.
         * @member {number} versioncode
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.versioncode = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AppTypeMsg versionname.
         * @member {string} versionname
         * @memberof cat.AppTypeMsg
         * @instance
         */
        AppTypeMsg.prototype.versionname = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AppTypeMsg.prototype, "_latest", {
            get: $util.oneOfGetter($oneOfFields = ["latest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AppTypeMsg.prototype, "_oldest", {
            get: $util.oneOfGetter($oneOfFields = ["oldest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AppTypeMsg instance using the specified properties.
         * @function create
         * @memberof cat.AppTypeMsg
         * @static
         * @param {cat.IAppTypeMsg=} [properties] Properties to set
         * @returns {cat.AppTypeMsg} AppTypeMsg instance
         */
        AppTypeMsg.create = function create(properties) {
            return new AppTypeMsg(properties);
        };

        /**
         * Encodes the specified AppTypeMsg message. Does not implicitly {@link cat.AppTypeMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.AppTypeMsg
         * @static
         * @param {cat.IAppTypeMsg} m AppTypeMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppTypeMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(26).string(m.description);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(32).int32(m.type);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(40).int32(m.status);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(48).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(56).uint64(m.lastmodified);
            if (m.obsolete != null && Object.hasOwnProperty.call(m, "obsolete"))
                w.uint32(64).uint64(m.obsolete);
            if (m.features != null && m.features.length) {
                w.uint32(74).fork();
                for (var i = 0; i < m.features.length; ++i)
                    w.int32(m.features[i]);
                w.ldelim();
            }
            if (m.iconfileid != null && Object.hasOwnProperty.call(m, "iconfileid"))
                w.uint32(82).string(m.iconfileid);
            if (m.iconmimetype != null && Object.hasOwnProperty.call(m, "iconmimetype"))
                w.uint32(90).string(m.iconmimetype);
            if (m.properties != null && Object.hasOwnProperty.call(m, "properties")) {
                for (var ks = Object.keys(m.properties), i = 0; i < ks.length; ++i) {
                    w.uint32(194).fork().uint32(10).string(ks[i]).uint32(18).string(m.properties[ks[i]]).ldelim();
                }
            }
            if (m.packagename != null && Object.hasOwnProperty.call(m, "packagename"))
                w.uint32(202).string(m.packagename);
            if (m.latest != null && Object.hasOwnProperty.call(m, "latest"))
                $root.cat.PackageVersionMsg.encode(m.latest, w.uint32(210).fork()).ldelim();
            if (m.oldest != null && Object.hasOwnProperty.call(m, "oldest"))
                $root.cat.PackageVersionMsg.encode(m.oldest, w.uint32(218).fork()).ldelim();
            if (m.unreadmessages != null && Object.hasOwnProperty.call(m, "unreadmessages"))
                w.uint32(808).uint64(m.unreadmessages);
            if (m.conversationcount != null && Object.hasOwnProperty.call(m, "conversationcount"))
                w.uint32(816).uint64(m.conversationcount);
            if (m.iconmedia != null && Object.hasOwnProperty.call(m, "iconmedia"))
                $root.cat.MediaMsg.encode(m.iconmedia, w.uint32(858).fork()).ldelim();
            if (m.versioncode != null && Object.hasOwnProperty.call(m, "versioncode"))
                w.uint32(864).uint64(m.versioncode);
            if (m.versionname != null && Object.hasOwnProperty.call(m, "versionname"))
                w.uint32(874).string(m.versionname);
            return w;
        };

        /**
         * Decodes an AppTypeMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.AppTypeMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.AppTypeMsg} AppTypeMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppTypeMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.AppTypeMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.description = r.string();
                        break;
                    }
                case 4: {
                        m.type = r.int32();
                        break;
                    }
                case 5: {
                        m.status = r.int32();
                        break;
                    }
                case 6: {
                        m.created = r.uint64();
                        break;
                    }
                case 7: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 8: {
                        m.obsolete = r.uint64();
                        break;
                    }
                case 9: {
                        if (!(m.features && m.features.length))
                            m.features = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.features.push(r.int32());
                        } else
                            m.features.push(r.int32());
                        break;
                    }
                case 10: {
                        m.iconfileid = r.string();
                        break;
                    }
                case 11: {
                        m.iconmimetype = r.string();
                        break;
                    }
                case 24: {
                        if (m.properties === $util.emptyObject)
                            m.properties = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.properties[k] = value;
                        break;
                    }
                case 25: {
                        m.packagename = r.string();
                        break;
                    }
                case 26: {
                        m.latest = $root.cat.PackageVersionMsg.decode(r, r.uint32());
                        break;
                    }
                case 27: {
                        m.oldest = $root.cat.PackageVersionMsg.decode(r, r.uint32());
                        break;
                    }
                case 101: {
                        m.unreadmessages = r.uint64();
                        break;
                    }
                case 102: {
                        m.conversationcount = r.uint64();
                        break;
                    }
                case 107: {
                        m.iconmedia = $root.cat.MediaMsg.decode(r, r.uint32());
                        break;
                    }
                case 108: {
                        m.versioncode = r.uint64();
                        break;
                    }
                case 109: {
                        m.versionname = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AppTypeMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.AppTypeMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.AppTypeMsg} AppTypeMsg
         */
        AppTypeMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.AppTypeMsg)
                return d;
            var m = new $root.cat.AppTypeMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "APP_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "APP_SYSTEM":
            case 1:
                m.type = 1;
                break;
            case "APP_DEVICE":
            case 2:
                m.type = 2;
                break;
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ENABLED":
            case 1:
                m.status = 1;
                break;
            case "DISABLED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.obsolete != null) {
                if ($util.Long)
                    (m.obsolete = $util.Long.fromValue(d.obsolete)).unsigned = true;
                else if (typeof d.obsolete === "string")
                    m.obsolete = parseInt(d.obsolete, 10);
                else if (typeof d.obsolete === "number")
                    m.obsolete = d.obsolete;
                else if (typeof d.obsolete === "object")
                    m.obsolete = new $util.LongBits(d.obsolete.low >>> 0, d.obsolete.high >>> 0).toNumber(true);
            }
            if (d.features) {
                if (!Array.isArray(d.features))
                    throw TypeError(".cat.AppTypeMsg.features: array expected");
                m.features = [];
                for (var i = 0; i < d.features.length; ++i) {
                    switch (d.features[i]) {
                    default:
                        if (typeof d.features[i] === "number") {
                            m.features[i] = d.features[i];
                            break;
                        }
                    case "APPFEATURE_UNKNOWN":
                    case 0:
                        m.features[i] = 0;
                        break;
                    case "APPFEATURE_AVATARS":
                    case 1:
                        m.features[i] = 1;
                        break;
                    case "APPFEATURE_SERVERS":
                    case 2:
                        m.features[i] = 2;
                        break;
                    case "APPFEATURE_TOPICS":
                    case 3:
                        m.features[i] = 3;
                        break;
                    case "APPFEATURE_MULTIPLE_ATTACHMENTS":
                    case 4:
                        m.features[i] = 4;
                        break;
                    }
                }
            }
            if (d.iconfileid != null) {
                m.iconfileid = String(d.iconfileid);
            }
            if (d.iconmimetype != null) {
                m.iconmimetype = String(d.iconmimetype);
            }
            if (d.properties) {
                if (typeof d.properties !== "object")
                    throw TypeError(".cat.AppTypeMsg.properties: object expected");
                m.properties = {};
                for (var ks = Object.keys(d.properties), i = 0; i < ks.length; ++i) {
                    m.properties[ks[i]] = String(d.properties[ks[i]]);
                }
            }
            if (d.packagename != null) {
                m.packagename = String(d.packagename);
            }
            if (d.latest != null) {
                if (typeof d.latest !== "object")
                    throw TypeError(".cat.AppTypeMsg.latest: object expected");
                m.latest = $root.cat.PackageVersionMsg.fromObject(d.latest);
            }
            if (d.oldest != null) {
                if (typeof d.oldest !== "object")
                    throw TypeError(".cat.AppTypeMsg.oldest: object expected");
                m.oldest = $root.cat.PackageVersionMsg.fromObject(d.oldest);
            }
            if (d.unreadmessages != null) {
                if ($util.Long)
                    (m.unreadmessages = $util.Long.fromValue(d.unreadmessages)).unsigned = true;
                else if (typeof d.unreadmessages === "string")
                    m.unreadmessages = parseInt(d.unreadmessages, 10);
                else if (typeof d.unreadmessages === "number")
                    m.unreadmessages = d.unreadmessages;
                else if (typeof d.unreadmessages === "object")
                    m.unreadmessages = new $util.LongBits(d.unreadmessages.low >>> 0, d.unreadmessages.high >>> 0).toNumber(true);
            }
            if (d.conversationcount != null) {
                if ($util.Long)
                    (m.conversationcount = $util.Long.fromValue(d.conversationcount)).unsigned = true;
                else if (typeof d.conversationcount === "string")
                    m.conversationcount = parseInt(d.conversationcount, 10);
                else if (typeof d.conversationcount === "number")
                    m.conversationcount = d.conversationcount;
                else if (typeof d.conversationcount === "object")
                    m.conversationcount = new $util.LongBits(d.conversationcount.low >>> 0, d.conversationcount.high >>> 0).toNumber(true);
            }
            if (d.iconmedia != null) {
                if (typeof d.iconmedia !== "object")
                    throw TypeError(".cat.AppTypeMsg.iconmedia: object expected");
                m.iconmedia = $root.cat.MediaMsg.fromObject(d.iconmedia);
            }
            if (d.versioncode != null) {
                if ($util.Long)
                    (m.versioncode = $util.Long.fromValue(d.versioncode)).unsigned = true;
                else if (typeof d.versioncode === "string")
                    m.versioncode = parseInt(d.versioncode, 10);
                else if (typeof d.versioncode === "number")
                    m.versioncode = d.versioncode;
                else if (typeof d.versioncode === "object")
                    m.versioncode = new $util.LongBits(d.versioncode.low >>> 0, d.versioncode.high >>> 0).toNumber(true);
            }
            if (d.versionname != null) {
                m.versionname = String(d.versionname);
            }
            return m;
        };

        /**
         * Creates a plain object from an AppTypeMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.AppTypeMsg
         * @static
         * @param {cat.AppTypeMsg} m AppTypeMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppTypeMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.features = [];
            }
            if (o.objects || o.defaults) {
                d.properties = {};
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.description = "";
                d.type = o.enums === String ? "APP_UNKNOWN" : 0;
                d.status = o.enums === String ? "UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.obsolete = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.obsolete = o.longs === String ? "0" : 0;
                d.iconfileid = "";
                d.iconmimetype = "";
                d.packagename = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.unreadmessages = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.unreadmessages = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.conversationcount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.conversationcount = o.longs === String ? "0" : 0;
                d.iconmedia = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.versioncode = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.versioncode = o.longs === String ? "0" : 0;
                d.versionname = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.AppType[m.type] === undefined ? m.type : $root.cat.AppType[m.type] : m.type;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.StatusType[m.status] === undefined ? m.status : $root.cat.StatusType[m.status] : m.status;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.obsolete != null && m.hasOwnProperty("obsolete")) {
                if (typeof m.obsolete === "number")
                    d.obsolete = o.longs === String ? String(m.obsolete) : m.obsolete;
                else
                    d.obsolete = o.longs === String ? $util.Long.prototype.toString.call(m.obsolete) : o.longs === Number ? new $util.LongBits(m.obsolete.low >>> 0, m.obsolete.high >>> 0).toNumber(true) : m.obsolete;
            }
            if (m.features && m.features.length) {
                d.features = [];
                for (var j = 0; j < m.features.length; ++j) {
                    d.features[j] = o.enums === String ? $root.cat.AppFeatures[m.features[j]] === undefined ? m.features[j] : $root.cat.AppFeatures[m.features[j]] : m.features[j];
                }
            }
            if (m.iconfileid != null && m.hasOwnProperty("iconfileid")) {
                d.iconfileid = m.iconfileid;
            }
            if (m.iconmimetype != null && m.hasOwnProperty("iconmimetype")) {
                d.iconmimetype = m.iconmimetype;
            }
            var ks2;
            if (m.properties && (ks2 = Object.keys(m.properties)).length) {
                d.properties = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.properties[ks2[j]] = m.properties[ks2[j]];
                }
            }
            if (m.packagename != null && m.hasOwnProperty("packagename")) {
                d.packagename = m.packagename;
            }
            if (m.latest != null && m.hasOwnProperty("latest")) {
                d.latest = $root.cat.PackageVersionMsg.toObject(m.latest, o);
                if (o.oneofs)
                    d._latest = "latest";
            }
            if (m.oldest != null && m.hasOwnProperty("oldest")) {
                d.oldest = $root.cat.PackageVersionMsg.toObject(m.oldest, o);
                if (o.oneofs)
                    d._oldest = "oldest";
            }
            if (m.unreadmessages != null && m.hasOwnProperty("unreadmessages")) {
                if (typeof m.unreadmessages === "number")
                    d.unreadmessages = o.longs === String ? String(m.unreadmessages) : m.unreadmessages;
                else
                    d.unreadmessages = o.longs === String ? $util.Long.prototype.toString.call(m.unreadmessages) : o.longs === Number ? new $util.LongBits(m.unreadmessages.low >>> 0, m.unreadmessages.high >>> 0).toNumber(true) : m.unreadmessages;
            }
            if (m.conversationcount != null && m.hasOwnProperty("conversationcount")) {
                if (typeof m.conversationcount === "number")
                    d.conversationcount = o.longs === String ? String(m.conversationcount) : m.conversationcount;
                else
                    d.conversationcount = o.longs === String ? $util.Long.prototype.toString.call(m.conversationcount) : o.longs === Number ? new $util.LongBits(m.conversationcount.low >>> 0, m.conversationcount.high >>> 0).toNumber(true) : m.conversationcount;
            }
            if (m.iconmedia != null && m.hasOwnProperty("iconmedia")) {
                d.iconmedia = $root.cat.MediaMsg.toObject(m.iconmedia, o);
            }
            if (m.versioncode != null && m.hasOwnProperty("versioncode")) {
                if (typeof m.versioncode === "number")
                    d.versioncode = o.longs === String ? String(m.versioncode) : m.versioncode;
                else
                    d.versioncode = o.longs === String ? $util.Long.prototype.toString.call(m.versioncode) : o.longs === Number ? new $util.LongBits(m.versioncode.low >>> 0, m.versioncode.high >>> 0).toNumber(true) : m.versioncode;
            }
            if (m.versionname != null && m.hasOwnProperty("versionname")) {
                d.versionname = m.versionname;
            }
            return d;
        };

        /**
         * Converts this AppTypeMsg to JSON.
         * @function toJSON
         * @memberof cat.AppTypeMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppTypeMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AppTypeMsg
         * @function getTypeUrl
         * @memberof cat.AppTypeMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AppTypeMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.AppTypeMsg";
        };

        return AppTypeMsg;
    })();

    cat.DateMsg = (function() {

        /**
         * Properties of a DateMsg.
         * @memberof cat
         * @interface IDateMsg
         * @property {number|null} [day] DateMsg day
         * @property {number|null} [month] DateMsg month
         * @property {number|null} [year] DateMsg year
         */

        /**
         * Constructs a new DateMsg.
         * @memberof cat
         * @classdesc Represents a DateMsg.
         * @implements IDateMsg
         * @constructor
         * @param {cat.IDateMsg=} [p] Properties to set
         */
        function DateMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DateMsg day.
         * @member {number} day
         * @memberof cat.DateMsg
         * @instance
         */
        DateMsg.prototype.day = 0;

        /**
         * DateMsg month.
         * @member {number} month
         * @memberof cat.DateMsg
         * @instance
         */
        DateMsg.prototype.month = 0;

        /**
         * DateMsg year.
         * @member {number} year
         * @memberof cat.DateMsg
         * @instance
         */
        DateMsg.prototype.year = 0;

        /**
         * Creates a new DateMsg instance using the specified properties.
         * @function create
         * @memberof cat.DateMsg
         * @static
         * @param {cat.IDateMsg=} [properties] Properties to set
         * @returns {cat.DateMsg} DateMsg instance
         */
        DateMsg.create = function create(properties) {
            return new DateMsg(properties);
        };

        /**
         * Encodes the specified DateMsg message. Does not implicitly {@link cat.DateMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.DateMsg
         * @static
         * @param {cat.IDateMsg} m DateMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.day != null && Object.hasOwnProperty.call(m, "day"))
                w.uint32(8).uint32(m.day);
            if (m.month != null && Object.hasOwnProperty.call(m, "month"))
                w.uint32(16).uint32(m.month);
            if (m.year != null && Object.hasOwnProperty.call(m, "year"))
                w.uint32(24).uint32(m.year);
            return w;
        };

        /**
         * Decodes a DateMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.DateMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.DateMsg} DateMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.DateMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.day = r.uint32();
                        break;
                    }
                case 2: {
                        m.month = r.uint32();
                        break;
                    }
                case 3: {
                        m.year = r.uint32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DateMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.DateMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.DateMsg} DateMsg
         */
        DateMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.DateMsg)
                return d;
            var m = new $root.cat.DateMsg();
            if (d.day != null) {
                m.day = d.day >>> 0;
            }
            if (d.month != null) {
                m.month = d.month >>> 0;
            }
            if (d.year != null) {
                m.year = d.year >>> 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a DateMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.DateMsg
         * @static
         * @param {cat.DateMsg} m DateMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DateMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.day = 0;
                d.month = 0;
                d.year = 0;
            }
            if (m.day != null && m.hasOwnProperty("day")) {
                d.day = m.day;
            }
            if (m.month != null && m.hasOwnProperty("month")) {
                d.month = m.month;
            }
            if (m.year != null && m.hasOwnProperty("year")) {
                d.year = m.year;
            }
            return d;
        };

        /**
         * Converts this DateMsg to JSON.
         * @function toJSON
         * @memberof cat.DateMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DateMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DateMsg
         * @function getTypeUrl
         * @memberof cat.DateMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DateMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.DateMsg";
        };

        return DateMsg;
    })();

    /**
     * AccountType enum.
     * @name cat.AccountType
     * @enum {number}
     * @property {number} ACCOUNT_UNKNOWN=0 ACCOUNT_UNKNOWN value
     * @property {number} ACCOUNT_PROFILE=1 ACCOUNT_PROFILE value
     * @property {number} ACCOUNT_CONTACT=2 ACCOUNT_CONTACT value
     * @property {number} ACCOUNT_BOT=3 ACCOUNT_BOT value
     * @property {number} ACCOUNT_FORWARD=4 ACCOUNT_FORWARD value
     */
    cat.AccountType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ACCOUNT_UNKNOWN"] = 0;
        values[valuesById[1] = "ACCOUNT_PROFILE"] = 1;
        values[valuesById[2] = "ACCOUNT_CONTACT"] = 2;
        values[valuesById[3] = "ACCOUNT_BOT"] = 3;
        values[valuesById[4] = "ACCOUNT_FORWARD"] = 4;
        return values;
    })();

    cat.MemberCountMsg = (function() {

        /**
         * Properties of a MemberCountMsg.
         * @memberof cat
         * @interface IMemberCountMsg
         * @property {number|null} [timestamp] MemberCountMsg timestamp
         * @property {number|null} [count] MemberCountMsg count
         */

        /**
         * Constructs a new MemberCountMsg.
         * @memberof cat
         * @classdesc Represents a MemberCountMsg.
         * @implements IMemberCountMsg
         * @constructor
         * @param {cat.IMemberCountMsg=} [p] Properties to set
         */
        function MemberCountMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * MemberCountMsg timestamp.
         * @member {number} timestamp
         * @memberof cat.MemberCountMsg
         * @instance
         */
        MemberCountMsg.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MemberCountMsg count.
         * @member {number} count
         * @memberof cat.MemberCountMsg
         * @instance
         */
        MemberCountMsg.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new MemberCountMsg instance using the specified properties.
         * @function create
         * @memberof cat.MemberCountMsg
         * @static
         * @param {cat.IMemberCountMsg=} [properties] Properties to set
         * @returns {cat.MemberCountMsg} MemberCountMsg instance
         */
        MemberCountMsg.create = function create(properties) {
            return new MemberCountMsg(properties);
        };

        /**
         * Encodes the specified MemberCountMsg message. Does not implicitly {@link cat.MemberCountMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.MemberCountMsg
         * @static
         * @param {cat.IMemberCountMsg} m MemberCountMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberCountMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                w.uint32(8).uint64(m.timestamp);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(16).uint64(m.count);
            return w;
        };

        /**
         * Decodes a MemberCountMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.MemberCountMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.MemberCountMsg} MemberCountMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberCountMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.MemberCountMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.timestamp = r.uint64();
                        break;
                    }
                case 2: {
                        m.count = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a MemberCountMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.MemberCountMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.MemberCountMsg} MemberCountMsg
         */
        MemberCountMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.MemberCountMsg)
                return d;
            var m = new $root.cat.MemberCountMsg();
            if (d.timestamp != null) {
                if ($util.Long)
                    (m.timestamp = $util.Long.fromValue(d.timestamp)).unsigned = true;
                else if (typeof d.timestamp === "string")
                    m.timestamp = parseInt(d.timestamp, 10);
                else if (typeof d.timestamp === "number")
                    m.timestamp = d.timestamp;
                else if (typeof d.timestamp === "object")
                    m.timestamp = new $util.LongBits(d.timestamp.low >>> 0, d.timestamp.high >>> 0).toNumber(true);
            }
            if (d.count != null) {
                if ($util.Long)
                    (m.count = $util.Long.fromValue(d.count)).unsigned = true;
                else if (typeof d.count === "string")
                    m.count = parseInt(d.count, 10);
                else if (typeof d.count === "number")
                    m.count = d.count;
                else if (typeof d.count === "object")
                    m.count = new $util.LongBits(d.count.low >>> 0, d.count.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a MemberCountMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.MemberCountMsg
         * @static
         * @param {cat.MemberCountMsg} m MemberCountMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberCountMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timestamp = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.count = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.count = o.longs === String ? "0" : 0;
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                if (typeof m.timestamp === "number")
                    d.timestamp = o.longs === String ? String(m.timestamp) : m.timestamp;
                else
                    d.timestamp = o.longs === String ? $util.Long.prototype.toString.call(m.timestamp) : o.longs === Number ? new $util.LongBits(m.timestamp.low >>> 0, m.timestamp.high >>> 0).toNumber(true) : m.timestamp;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                if (typeof m.count === "number")
                    d.count = o.longs === String ? String(m.count) : m.count;
                else
                    d.count = o.longs === String ? $util.Long.prototype.toString.call(m.count) : o.longs === Number ? new $util.LongBits(m.count.low >>> 0, m.count.high >>> 0).toNumber(true) : m.count;
            }
            return d;
        };

        /**
         * Converts this MemberCountMsg to JSON.
         * @function toJSON
         * @memberof cat.MemberCountMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberCountMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MemberCountMsg
         * @function getTypeUrl
         * @memberof cat.MemberCountMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MemberCountMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.MemberCountMsg";
        };

        return MemberCountMsg;
    })();

    /**
     * ServerState enum.
     * @name cat.ServerState
     * @enum {number}
     * @property {number} SERVERSTATE_UNKNOWN=0 SERVERSTATE_UNKNOWN value
     * @property {number} SERVERSTATE_READY=1 SERVERSTATE_READY value
     * @property {number} SERVERSTATE_CLOSED=2 SERVERSTATE_CLOSED value
     */
    cat.ServerState = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SERVERSTATE_UNKNOWN"] = 0;
        values[valuesById[1] = "SERVERSTATE_READY"] = 1;
        values[valuesById[2] = "SERVERSTATE_CLOSED"] = 2;
        return values;
    })();

    /**
     * ServerType enum.
     * @name cat.ServerType
     * @enum {number}
     * @property {number} SERVER_UNKNOWN=0 SERVER_UNKNOWN value
     * @property {number} SERVER_PUBLIC=1 SERVER_PUBLIC value
     * @property {number} SERVER_DM=2 SERVER_DM value
     */
    cat.ServerType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SERVER_UNKNOWN"] = 0;
        values[valuesById[1] = "SERVER_PUBLIC"] = 1;
        values[valuesById[2] = "SERVER_DM"] = 2;
        return values;
    })();

    /**
     * ConversationType enum.
     * @name cat.ConversationType
     * @enum {number}
     * @property {number} CONVERSATION_UNKNOWN=0 CONVERSATION_UNKNOWN value
     * @property {number} CONVERSATION_PRIVATE=1 CONVERSATION_PRIVATE value
     * @property {number} CONVERSATION_SECRET=2 CONVERSATION_SECRET value
     * @property {number} CONVERSATION_GROUP=3 CONVERSATION_GROUP value
     * @property {number} CONVERSATION_PUBLIC=4 CONVERSATION_PUBLIC value
     * @property {number} CONVERSATION_FEED=6 CONVERSATION_FEED value
     * @property {number} CONVERSATION_CHANNEL=10 CONVERSATION_CHANNEL value
     * @property {number} CONVERSATION_SUPERGROUP=11 CONVERSATION_SUPERGROUP value
     * @property {number} CONVERSATION_STORY=23 CONVERSATION_STORY value
     * @property {number} CONVERSATION_SELF=30 CONVERSATION_SELF value
     * @property {number} CONVERSATION_VOICE=40 CONVERSATION_VOICE value
     * @property {number} CONVERSATION_THREAD=41 CONVERSATION_THREAD value
     */
    cat.ConversationType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CONVERSATION_UNKNOWN"] = 0;
        values[valuesById[1] = "CONVERSATION_PRIVATE"] = 1;
        values[valuesById[2] = "CONVERSATION_SECRET"] = 2;
        values[valuesById[3] = "CONVERSATION_GROUP"] = 3;
        values[valuesById[4] = "CONVERSATION_PUBLIC"] = 4;
        values[valuesById[6] = "CONVERSATION_FEED"] = 6;
        values[valuesById[10] = "CONVERSATION_CHANNEL"] = 10;
        values[valuesById[11] = "CONVERSATION_SUPERGROUP"] = 11;
        values[valuesById[23] = "CONVERSATION_STORY"] = 23;
        values[valuesById[30] = "CONVERSATION_SELF"] = 30;
        values[valuesById[40] = "CONVERSATION_VOICE"] = 40;
        values[valuesById[41] = "CONVERSATION_THREAD"] = 41;
        return values;
    })();

    /**
     * ConversationState enum.
     * @name cat.ConversationState
     * @enum {number}
     * @property {number} CONVERSATIONSTATE_UNKNOWN=0 CONVERSATIONSTATE_UNKNOWN value
     * @property {number} CONVERSATIONSTATE_PENDING=1 CONVERSATIONSTATE_PENDING value
     * @property {number} CONVERSATIONSTATE_READY=2 CONVERSATIONSTATE_READY value
     * @property {number} CONVERSATIONSTATE_CLOSED=4 CONVERSATIONSTATE_CLOSED value
     * @property {number} CONVERSATIONSTATE_LEFT=7 CONVERSATIONSTATE_LEFT value
     */
    cat.ConversationState = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CONVERSATIONSTATE_UNKNOWN"] = 0;
        values[valuesById[1] = "CONVERSATIONSTATE_PENDING"] = 1;
        values[valuesById[2] = "CONVERSATIONSTATE_READY"] = 2;
        values[valuesById[4] = "CONVERSATIONSTATE_CLOSED"] = 4;
        values[valuesById[7] = "CONVERSATIONSTATE_LEFT"] = 7;
        return values;
    })();

    /**
     * TopicState enum.
     * @name cat.TopicState
     * @enum {number}
     * @property {number} TOPICSTATE_UNKNOWN=0 TOPICSTATE_UNKNOWN value
     * @property {number} TOPICSTATE_READY=1 TOPICSTATE_READY value
     * @property {number} TOPICSTATE_CLOSED=2 TOPICSTATE_CLOSED value
     * @property {number} TOPICSTATE_LOCKED=3 TOPICSTATE_LOCKED value
     */
    cat.TopicState = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TOPICSTATE_UNKNOWN"] = 0;
        values[valuesById[1] = "TOPICSTATE_READY"] = 1;
        values[valuesById[2] = "TOPICSTATE_CLOSED"] = 2;
        values[valuesById[3] = "TOPICSTATE_LOCKED"] = 3;
        return values;
    })();

    /**
     * DirectionType enum.
     * @name cat.DirectionType
     * @enum {number}
     * @property {number} DIRECTION_UNKNOWN=0 DIRECTION_UNKNOWN value
     * @property {number} DIRECTION_INCOMING=1 DIRECTION_INCOMING value
     * @property {number} DIRECTION_OUTGOING=2 DIRECTION_OUTGOING value
     */
    cat.DirectionType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DIRECTION_UNKNOWN"] = 0;
        values[valuesById[1] = "DIRECTION_INCOMING"] = 1;
        values[valuesById[2] = "DIRECTION_OUTGOING"] = 2;
        return values;
    })();

    /**
     * MessageStatus enum.
     * @name cat.MessageStatus
     * @enum {number}
     * @property {number} MESSAGE_UNKNOWN=0 MESSAGE_UNKNOWN value
     * @property {number} MESSAGE_DRAFT=1 MESSAGE_DRAFT value
     * @property {number} MESSAGE_PENDING=2 MESSAGE_PENDING value
     * @property {number} MESSAGE_SENT=3 MESSAGE_SENT value
     * @property {number} MESSAGE_RECEIVED=4 MESSAGE_RECEIVED value
     * @property {number} MESSAGE_READ=5 MESSAGE_READ value
     * @property {number} MESSAGE_FAILED=6 MESSAGE_FAILED value
     * @property {number} MESSAGE_BOUNCED=7 MESSAGE_BOUNCED value
     */
    cat.MessageStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MESSAGE_UNKNOWN"] = 0;
        values[valuesById[1] = "MESSAGE_DRAFT"] = 1;
        values[valuesById[2] = "MESSAGE_PENDING"] = 2;
        values[valuesById[3] = "MESSAGE_SENT"] = 3;
        values[valuesById[4] = "MESSAGE_RECEIVED"] = 4;
        values[valuesById[5] = "MESSAGE_READ"] = 5;
        values[valuesById[6] = "MESSAGE_FAILED"] = 6;
        values[valuesById[7] = "MESSAGE_BOUNCED"] = 7;
        return values;
    })();

    /**
     * MessageActorType enum.
     * @name cat.MessageActorType
     * @enum {number}
     * @property {number} MESSAGEACTOR_UNKNOWN=0 MESSAGEACTOR_UNKNOWN value
     * @property {number} MESSAGEACTOR_SENDER=1 MESSAGEACTOR_SENDER value
     * @property {number} MESSAGEACTOR_RECIPIENT=2 MESSAGEACTOR_RECIPIENT value
     * @property {number} MESSAGEACTOR_FORWARD=3 MESSAGEACTOR_FORWARD value
     * @property {number} MESSAGEACTOR_OWNER=4 MESSAGEACTOR_OWNER value
     * @property {number} MESSAGEACTOR_CC=5 MESSAGEACTOR_CC value
     * @property {number} MESSAGEACTOR_BCC=6 MESSAGEACTOR_BCC value
     */
    cat.MessageActorType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MESSAGEACTOR_UNKNOWN"] = 0;
        values[valuesById[1] = "MESSAGEACTOR_SENDER"] = 1;
        values[valuesById[2] = "MESSAGEACTOR_RECIPIENT"] = 2;
        values[valuesById[3] = "MESSAGEACTOR_FORWARD"] = 3;
        values[valuesById[4] = "MESSAGEACTOR_OWNER"] = 4;
        values[valuesById[5] = "MESSAGEACTOR_CC"] = 5;
        values[valuesById[6] = "MESSAGEACTOR_BCC"] = 6;
        return values;
    })();

    /**
     * MessageType enum.
     * @name cat.MessageType
     * @enum {number}
     * @property {number} MESSAGE_UNSPECIFIED=0 MESSAGE_UNSPECIFIED value
     * @property {number} MESSAGE_MESSAGE=1 MESSAGE_MESSAGE value
     * @property {number} MESSAGE_GROUPCREATE=2 MESSAGE_GROUPCREATE value
     * @property {number} MESSAGE_SCREENSHOT=3 MESSAGE_SCREENSHOT value
     * @property {number} MESSAGE_CHANGETITLE=4 MESSAGE_CHANGETITLE value
     * @property {number} MESSAGE_CHANGEIMAGE=5 MESSAGE_CHANGEIMAGE value
     * @property {number} MESSAGE_ADDMEMBERS=6 MESSAGE_ADDMEMBERS value
     * @property {number} MESSAGE_DELETEMEMBER=7 MESSAGE_DELETEMEMBER value
     * @property {number} MESSAGE_JOINBYLINK=8 MESSAGE_JOINBYLINK value
     * @property {number} MESSAGE_CALL=9 MESSAGE_CALL value
     * @property {number} MESSAGE_CALL_ENDED=10 MESSAGE_CALL_ENDED value
     * @property {number} MESSAGE_REQUEST_ACCEPTED=11 MESSAGE_REQUEST_ACCEPTED value
     * @property {number} MESSAGE_TOPIC=12 MESSAGE_TOPIC value
     * @property {number} MESSAGE_TTL=13 MESSAGE_TTL value
     * @property {number} MESSAGE_POLL=14 MESSAGE_POLL value
     * @property {number} MESSAGE_HISTORYCLEAR=15 MESSAGE_HISTORYCLEAR value
     * @property {number} MESSAGE_CHATCREATE=16 MESSAGE_CHATCREATE value
     * @property {number} MESSAGE_PIN=17 MESSAGE_PIN value
     * @property {number} MESSAGE_STORY_SHARE=18 MESSAGE_STORY_SHARE value
     * @property {number} MESSAGE_POST_SHARE=19 MESSAGE_POST_SHARE value
     * @property {number} MESSAGE_MISSED_CALL=20 MESSAGE_MISSED_CALL value
     * @property {number} MESSAGE_SERVICE_MESSAGE=21 MESSAGE_SERVICE_MESSAGE value
     * @property {number} MESSAGE_CHANGEDESCRIPTION=22 MESSAGE_CHANGEDESCRIPTION value
     * @property {number} MESSAGE_REMOVEDMEMBER=23 MESSAGE_REMOVEDMEMBER value
     * @property {number} MESSAGE_CHAT_MIGRATED=24 MESSAGE_CHAT_MIGRATED value
     * @property {number} MESSAGE_LOCATION_REQUEST=25 MESSAGE_LOCATION_REQUEST value
     * @property {number} MESSAGE_VIDEO_CALL=26 MESSAGE_VIDEO_CALL value
     * @property {number} MESSAGE_REMOVEIMAGE=27 MESSAGE_REMOVEIMAGE value
     * @property {number} MESSAGE_CALL_SCHEDULED=28 MESSAGE_CALL_SCHEDULED value
     * @property {number} MESSAGE_CALL_JOINED=29 MESSAGE_CALL_JOINED value
     * @property {number} MESSAGE_CALL_LEFT=30 MESSAGE_CALL_LEFT value
     * @property {number} MESSAGE_CHANGETHEME=31 MESSAGE_CHANGETHEME value
     * @property {number} MESSAGE_CREATE_INVITELINK=32 MESSAGE_CREATE_INVITELINK value
     * @property {number} MESSAGE_CANCEL_INVITELINK=33 MESSAGE_CANCEL_INVITELINK value
     * @property {number} MESSAGE_ENDTOEND_ENCRYPTED=34 MESSAGE_ENDTOEND_ENCRYPTED value
     * @property {number} MESSAGE_SECURITYCODE_CHANGED=35 MESSAGE_SECURITYCODE_CHANGED value
     * @property {number} MESSAGE_GROUPINFO_ADMIN=36 MESSAGE_GROUPINFO_ADMIN value
     * @property {number} MESSAGE_GROUPINFO_ALL=37 MESSAGE_GROUPINFO_ALL value
     * @property {number} MESSAGE_GROUPSEND_ADMIN=38 MESSAGE_GROUPSEND_ADMIN value
     * @property {number} MESSAGE_GROUPSEND_ALL=39 MESSAGE_GROUPSEND_ALL value
     * @property {number} MESSAGE_CHANGEPHONENUMBER=40 MESSAGE_CHANGEPHONENUMBER value
     * @property {number} MESSAGE_ISADMIN=41 MESSAGE_ISADMIN value
     * @property {number} MESSAGE_NOTADMIN=42 MESSAGE_NOTADMIN value
     * @property {number} MESSAGE_BLOCKEDCONTACT=43 MESSAGE_BLOCKEDCONTACT value
     * @property {number} MESSAGE_UNBLOCKEDCONTACT=44 MESSAGE_UNBLOCKEDCONTACT value
     * @property {number} MESSAGE_CALL_STARTED=45 MESSAGE_CALL_STARTED value
     * @property {number} MESSAGE_CHANGENICKNAME=46 MESSAGE_CHANGENICKNAME value
     * @property {number} MESSAGE_CHANGENICKNAME_SELF=47 MESSAGE_CHANGENICKNAME_SELF value
     * @property {number} MESSAGE_REMOVENICKNAME=48 MESSAGE_REMOVENICKNAME value
     * @property {number} MESSAGE_REMOVENICKNAME_SELF=49 MESSAGE_REMOVENICKNAME_SELF value
     * @property {number} MESSAGE_CHANGEEMOJI=50 MESSAGE_CHANGEEMOJI value
     * @property {number} MESSAGE_TOPIC_CREATE=51 MESSAGE_TOPIC_CREATE value
     * @property {number} MESSAGE_TOPIC_EDIT=52 MESSAGE_TOPIC_EDIT value
     * @property {number} MESSAGE_THREAD_CREATE=53 MESSAGE_THREAD_CREATE value
     * @property {number} MESSAGE_WALLPAPER_CHAT=54 MESSAGE_WALLPAPER_CHAT value
     * @property {number} MESSAGE_VIDEO_CALL_STARTED=55 MESSAGE_VIDEO_CALL_STARTED value
     * @property {number} MESSAGE_VIDEO_CALL_ENDED=56 MESSAGE_VIDEO_CALL_ENDED value
     * @property {number} MESSAGE_MISSED_VIDEO_CALL=57 MESSAGE_MISSED_VIDEO_CALL value
     */
    cat.MessageType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MESSAGE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "MESSAGE_MESSAGE"] = 1;
        values[valuesById[2] = "MESSAGE_GROUPCREATE"] = 2;
        values[valuesById[3] = "MESSAGE_SCREENSHOT"] = 3;
        values[valuesById[4] = "MESSAGE_CHANGETITLE"] = 4;
        values[valuesById[5] = "MESSAGE_CHANGEIMAGE"] = 5;
        values[valuesById[6] = "MESSAGE_ADDMEMBERS"] = 6;
        values[valuesById[7] = "MESSAGE_DELETEMEMBER"] = 7;
        values[valuesById[8] = "MESSAGE_JOINBYLINK"] = 8;
        values[valuesById[9] = "MESSAGE_CALL"] = 9;
        values[valuesById[10] = "MESSAGE_CALL_ENDED"] = 10;
        values[valuesById[11] = "MESSAGE_REQUEST_ACCEPTED"] = 11;
        values[valuesById[12] = "MESSAGE_TOPIC"] = 12;
        values[valuesById[13] = "MESSAGE_TTL"] = 13;
        values[valuesById[14] = "MESSAGE_POLL"] = 14;
        values[valuesById[15] = "MESSAGE_HISTORYCLEAR"] = 15;
        values[valuesById[16] = "MESSAGE_CHATCREATE"] = 16;
        values[valuesById[17] = "MESSAGE_PIN"] = 17;
        values[valuesById[18] = "MESSAGE_STORY_SHARE"] = 18;
        values[valuesById[19] = "MESSAGE_POST_SHARE"] = 19;
        values[valuesById[20] = "MESSAGE_MISSED_CALL"] = 20;
        values[valuesById[21] = "MESSAGE_SERVICE_MESSAGE"] = 21;
        values[valuesById[22] = "MESSAGE_CHANGEDESCRIPTION"] = 22;
        values[valuesById[23] = "MESSAGE_REMOVEDMEMBER"] = 23;
        values[valuesById[24] = "MESSAGE_CHAT_MIGRATED"] = 24;
        values[valuesById[25] = "MESSAGE_LOCATION_REQUEST"] = 25;
        values[valuesById[26] = "MESSAGE_VIDEO_CALL"] = 26;
        values[valuesById[27] = "MESSAGE_REMOVEIMAGE"] = 27;
        values[valuesById[28] = "MESSAGE_CALL_SCHEDULED"] = 28;
        values[valuesById[29] = "MESSAGE_CALL_JOINED"] = 29;
        values[valuesById[30] = "MESSAGE_CALL_LEFT"] = 30;
        values[valuesById[31] = "MESSAGE_CHANGETHEME"] = 31;
        values[valuesById[32] = "MESSAGE_CREATE_INVITELINK"] = 32;
        values[valuesById[33] = "MESSAGE_CANCEL_INVITELINK"] = 33;
        values[valuesById[34] = "MESSAGE_ENDTOEND_ENCRYPTED"] = 34;
        values[valuesById[35] = "MESSAGE_SECURITYCODE_CHANGED"] = 35;
        values[valuesById[36] = "MESSAGE_GROUPINFO_ADMIN"] = 36;
        values[valuesById[37] = "MESSAGE_GROUPINFO_ALL"] = 37;
        values[valuesById[38] = "MESSAGE_GROUPSEND_ADMIN"] = 38;
        values[valuesById[39] = "MESSAGE_GROUPSEND_ALL"] = 39;
        values[valuesById[40] = "MESSAGE_CHANGEPHONENUMBER"] = 40;
        values[valuesById[41] = "MESSAGE_ISADMIN"] = 41;
        values[valuesById[42] = "MESSAGE_NOTADMIN"] = 42;
        values[valuesById[43] = "MESSAGE_BLOCKEDCONTACT"] = 43;
        values[valuesById[44] = "MESSAGE_UNBLOCKEDCONTACT"] = 44;
        values[valuesById[45] = "MESSAGE_CALL_STARTED"] = 45;
        values[valuesById[46] = "MESSAGE_CHANGENICKNAME"] = 46;
        values[valuesById[47] = "MESSAGE_CHANGENICKNAME_SELF"] = 47;
        values[valuesById[48] = "MESSAGE_REMOVENICKNAME"] = 48;
        values[valuesById[49] = "MESSAGE_REMOVENICKNAME_SELF"] = 49;
        values[valuesById[50] = "MESSAGE_CHANGEEMOJI"] = 50;
        values[valuesById[51] = "MESSAGE_TOPIC_CREATE"] = 51;
        values[valuesById[52] = "MESSAGE_TOPIC_EDIT"] = 52;
        values[valuesById[53] = "MESSAGE_THREAD_CREATE"] = 53;
        values[valuesById[54] = "MESSAGE_WALLPAPER_CHAT"] = 54;
        values[valuesById[55] = "MESSAGE_VIDEO_CALL_STARTED"] = 55;
        values[valuesById[56] = "MESSAGE_VIDEO_CALL_ENDED"] = 56;
        values[valuesById[57] = "MESSAGE_MISSED_VIDEO_CALL"] = 57;
        return values;
    })();

    /**
     * MessageLinkType enum.
     * @name cat.MessageLinkType
     * @enum {number}
     * @property {number} MESSAGELINK_UNKNOWN=0 MESSAGELINK_UNKNOWN value
     * @property {number} MESSAGELINK_REPLY=1 MESSAGELINK_REPLY value
     * @property {number} MESSAGELINK_MESSAGE=2 MESSAGELINK_MESSAGE value
     */
    cat.MessageLinkType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MESSAGELINK_UNKNOWN"] = 0;
        values[valuesById[1] = "MESSAGELINK_REPLY"] = 1;
        values[valuesById[2] = "MESSAGELINK_MESSAGE"] = 2;
        return values;
    })();

    /**
     * AttachmentType enum.
     * @name cat.AttachmentType
     * @enum {number}
     * @property {number} ATTACHMENT_UNKNOWN=0 ATTACHMENT_UNKNOWN value
     * @property {number} ATTACHMENT_PHOTO=1 ATTACHMENT_PHOTO value
     * @property {number} ATTACHMENT_VIDEO=2 ATTACHMENT_VIDEO value
     * @property {number} ATTACHMENT_AUDIO=3 ATTACHMENT_AUDIO value
     * @property {number} ATTACHMENT_ANIMATION=4 ATTACHMENT_ANIMATION value
     * @property {number} ATTACHMENT_DOCUMENT=5 ATTACHMENT_DOCUMENT value
     * @property {number} ATTACHMENT_LOCATION=6 ATTACHMENT_LOCATION value
     * @property {number} ATTACHMENT_CONTACT=7 ATTACHMENT_CONTACT value
     * @property {number} ATTACHMENT_STICKER=8 ATTACHMENT_STICKER value
     * @property {number} ATTACHMENT_ARCHIVE=9 ATTACHMENT_ARCHIVE value
     * @property {number} ATTACHMENT_TGS=10 ATTACHMENT_TGS value
     * @property {number} ATTACHMENT_EMOJI=11 ATTACHMENT_EMOJI value
     * @property {number} ATTACHMENT_EMBED=12 ATTACHMENT_EMBED value
     * @property {number} ATTACHMENT_INVITE=13 ATTACHMENT_INVITE value
     */
    cat.AttachmentType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ATTACHMENT_UNKNOWN"] = 0;
        values[valuesById[1] = "ATTACHMENT_PHOTO"] = 1;
        values[valuesById[2] = "ATTACHMENT_VIDEO"] = 2;
        values[valuesById[3] = "ATTACHMENT_AUDIO"] = 3;
        values[valuesById[4] = "ATTACHMENT_ANIMATION"] = 4;
        values[valuesById[5] = "ATTACHMENT_DOCUMENT"] = 5;
        values[valuesById[6] = "ATTACHMENT_LOCATION"] = 6;
        values[valuesById[7] = "ATTACHMENT_CONTACT"] = 7;
        values[valuesById[8] = "ATTACHMENT_STICKER"] = 8;
        values[valuesById[9] = "ATTACHMENT_ARCHIVE"] = 9;
        values[valuesById[10] = "ATTACHMENT_TGS"] = 10;
        values[valuesById[11] = "ATTACHMENT_EMOJI"] = 11;
        values[valuesById[12] = "ATTACHMENT_EMBED"] = 12;
        values[valuesById[13] = "ATTACHMENT_INVITE"] = 13;
        return values;
    })();

    /**
     * AttachmentStatus enum.
     * @name cat.AttachmentStatus
     * @enum {number}
     * @property {number} ATTACHMENTSTATUS_UNKNOWN=0 ATTACHMENTSTATUS_UNKNOWN value
     * @property {number} ATTACHMENTSTATUS_PENDING=1 ATTACHMENTSTATUS_PENDING value
     * @property {number} ATTACHMENTSTATUS_COMPLETE=2 ATTACHMENTSTATUS_COMPLETE value
     * @property {number} ATTACHMENTSTATUS_FAILED=3 ATTACHMENTSTATUS_FAILED value
     * @property {number} ATTACHMENTSTATUS_EXPIRED=4 ATTACHMENTSTATUS_EXPIRED value
     */
    cat.AttachmentStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ATTACHMENTSTATUS_UNKNOWN"] = 0;
        values[valuesById[1] = "ATTACHMENTSTATUS_PENDING"] = 1;
        values[valuesById[2] = "ATTACHMENTSTATUS_COMPLETE"] = 2;
        values[valuesById[3] = "ATTACHMENTSTATUS_FAILED"] = 3;
        values[valuesById[4] = "ATTACHMENTSTATUS_EXPIRED"] = 4;
        return values;
    })();

    cat.RawMsg = (function() {

        /**
         * Properties of a RawMsg.
         * @memberof cat
         * @interface IRawMsg
         * @property {number|null} [version] RawMsg version
         * @property {string|null} [raw] RawMsg raw
         * @property {number|null} [versioncode] RawMsg versioncode
         * @property {string|null} [context] RawMsg context
         * @property {cat.ReceiverItemType|null} [type] RawMsg type
         * @property {string|null} [hash] RawMsg hash
         * @property {number|null} [timestamp] RawMsg timestamp
         */

        /**
         * Constructs a new RawMsg.
         * @memberof cat
         * @classdesc Represents a RawMsg.
         * @implements IRawMsg
         * @constructor
         * @param {cat.IRawMsg=} [p] Properties to set
         */
        function RawMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * RawMsg version.
         * @member {number} version
         * @memberof cat.RawMsg
         * @instance
         */
        RawMsg.prototype.version = 0;

        /**
         * RawMsg raw.
         * @member {string} raw
         * @memberof cat.RawMsg
         * @instance
         */
        RawMsg.prototype.raw = "";

        /**
         * RawMsg versioncode.
         * @member {number} versioncode
         * @memberof cat.RawMsg
         * @instance
         */
        RawMsg.prototype.versioncode = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * RawMsg context.
         * @member {string} context
         * @memberof cat.RawMsg
         * @instance
         */
        RawMsg.prototype.context = "";

        /**
         * RawMsg type.
         * @member {cat.ReceiverItemType} type
         * @memberof cat.RawMsg
         * @instance
         */
        RawMsg.prototype.type = 0;

        /**
         * RawMsg hash.
         * @member {string} hash
         * @memberof cat.RawMsg
         * @instance
         */
        RawMsg.prototype.hash = "";

        /**
         * RawMsg timestamp.
         * @member {number} timestamp
         * @memberof cat.RawMsg
         * @instance
         */
        RawMsg.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new RawMsg instance using the specified properties.
         * @function create
         * @memberof cat.RawMsg
         * @static
         * @param {cat.IRawMsg=} [properties] Properties to set
         * @returns {cat.RawMsg} RawMsg instance
         */
        RawMsg.create = function create(properties) {
            return new RawMsg(properties);
        };

        /**
         * Encodes the specified RawMsg message. Does not implicitly {@link cat.RawMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.RawMsg
         * @static
         * @param {cat.IRawMsg} m RawMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RawMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(8).uint32(m.version);
            if (m.raw != null && Object.hasOwnProperty.call(m, "raw"))
                w.uint32(18).string(m.raw);
            if (m.versioncode != null && Object.hasOwnProperty.call(m, "versioncode"))
                w.uint32(24).uint64(m.versioncode);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                w.uint32(34).string(m.context);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(40).int32(m.type);
            if (m.hash != null && Object.hasOwnProperty.call(m, "hash"))
                w.uint32(50).string(m.hash);
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                w.uint32(56).uint64(m.timestamp);
            return w;
        };

        /**
         * Decodes a RawMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.RawMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.RawMsg} RawMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RawMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.RawMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.version = r.uint32();
                        break;
                    }
                case 2: {
                        m.raw = r.string();
                        break;
                    }
                case 3: {
                        m.versioncode = r.uint64();
                        break;
                    }
                case 4: {
                        m.context = r.string();
                        break;
                    }
                case 5: {
                        m.type = r.int32();
                        break;
                    }
                case 6: {
                        m.hash = r.string();
                        break;
                    }
                case 7: {
                        m.timestamp = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a RawMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.RawMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.RawMsg} RawMsg
         */
        RawMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.RawMsg)
                return d;
            var m = new $root.cat.RawMsg();
            if (d.version != null) {
                m.version = d.version >>> 0;
            }
            if (d.raw != null) {
                m.raw = String(d.raw);
            }
            if (d.versioncode != null) {
                if ($util.Long)
                    (m.versioncode = $util.Long.fromValue(d.versioncode)).unsigned = true;
                else if (typeof d.versioncode === "string")
                    m.versioncode = parseInt(d.versioncode, 10);
                else if (typeof d.versioncode === "number")
                    m.versioncode = d.versioncode;
                else if (typeof d.versioncode === "object")
                    m.versioncode = new $util.LongBits(d.versioncode.low >>> 0, d.versioncode.high >>> 0).toNumber(true);
            }
            if (d.context != null) {
                m.context = String(d.context);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "ITEMTYPE_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "ITEMTYPE_INSERT":
            case 1:
                m.type = 1;
                break;
            case "ITEMTYPE_UPDATE":
            case 2:
                m.type = 2;
                break;
            case "ITEMTYPE_DELETE":
            case 3:
                m.type = 3;
                break;
            case "ITEMTYPE_DOWNLOAD":
            case 4:
                m.type = 4;
                break;
            case "ITEMTYPE_FILE":
            case 5:
                m.type = 5;
                break;
            case "ITEMTYPE_MEMORY":
            case 6:
                m.type = 6;
                break;
            }
            if (d.hash != null) {
                m.hash = String(d.hash);
            }
            if (d.timestamp != null) {
                if ($util.Long)
                    (m.timestamp = $util.Long.fromValue(d.timestamp)).unsigned = true;
                else if (typeof d.timestamp === "string")
                    m.timestamp = parseInt(d.timestamp, 10);
                else if (typeof d.timestamp === "number")
                    m.timestamp = d.timestamp;
                else if (typeof d.timestamp === "object")
                    m.timestamp = new $util.LongBits(d.timestamp.low >>> 0, d.timestamp.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a RawMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.RawMsg
         * @static
         * @param {cat.RawMsg} m RawMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RawMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.version = 0;
                d.raw = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.versioncode = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.versioncode = o.longs === String ? "0" : 0;
                d.context = "";
                d.type = o.enums === String ? "ITEMTYPE_UNKNOWN" : 0;
                d.hash = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timestamp = o.longs === String ? "0" : 0;
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                d.version = m.version;
            }
            if (m.raw != null && m.hasOwnProperty("raw")) {
                d.raw = m.raw;
            }
            if (m.versioncode != null && m.hasOwnProperty("versioncode")) {
                if (typeof m.versioncode === "number")
                    d.versioncode = o.longs === String ? String(m.versioncode) : m.versioncode;
                else
                    d.versioncode = o.longs === String ? $util.Long.prototype.toString.call(m.versioncode) : o.longs === Number ? new $util.LongBits(m.versioncode.low >>> 0, m.versioncode.high >>> 0).toNumber(true) : m.versioncode;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = m.context;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.ReceiverItemType[m.type] === undefined ? m.type : $root.cat.ReceiverItemType[m.type] : m.type;
            }
            if (m.hash != null && m.hasOwnProperty("hash")) {
                d.hash = m.hash;
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                if (typeof m.timestamp === "number")
                    d.timestamp = o.longs === String ? String(m.timestamp) : m.timestamp;
                else
                    d.timestamp = o.longs === String ? $util.Long.prototype.toString.call(m.timestamp) : o.longs === Number ? new $util.LongBits(m.timestamp.low >>> 0, m.timestamp.high >>> 0).toNumber(true) : m.timestamp;
            }
            return d;
        };

        /**
         * Converts this RawMsg to JSON.
         * @function toJSON
         * @memberof cat.RawMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RawMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RawMsg
         * @function getTypeUrl
         * @memberof cat.RawMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RawMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.RawMsg";
        };

        return RawMsg;
    })();

    cat.ReactionMsg = (function() {

        /**
         * Properties of a ReactionMsg.
         * @memberof cat
         * @interface IReactionMsg
         * @property {string|null} [id] ReactionMsg id
         * @property {number|null} [posted] ReactionMsg posted
         * @property {number|null} [deleted] ReactionMsg deleted
         * @property {string|null} [emoji] ReactionMsg emoji
         * @property {number|null} [created] ReactionMsg created
         * @property {number|null} [lastmodified] ReactionMsg lastmodified
         * @property {string|null} [externid] ReactionMsg externid
         * @property {cat.IRawMsg|null} [raw] ReactionMsg raw
         * @property {number|null} [tsatimestamp] ReactionMsg tsatimestamp
         * @property {string|null} [tsasignature] ReactionMsg tsasignature
         * @property {number|null} [count] ReactionMsg count
         * @property {cat.IAccountMsg|null} [sender] ReactionMsg sender
         * @property {cat.IAttachmentMsg|null} [customemoji] ReactionMsg customemoji
         * @property {Array.<cat.IReactionMsg>|null} [history] ReactionMsg history
         */

        /**
         * Constructs a new ReactionMsg.
         * @memberof cat
         * @classdesc Represents a ReactionMsg.
         * @implements IReactionMsg
         * @constructor
         * @param {cat.IReactionMsg=} [p] Properties to set
         */
        function ReactionMsg(p) {
            this.history = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReactionMsg id.
         * @member {string} id
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.id = "";

        /**
         * ReactionMsg posted.
         * @member {number} posted
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.posted = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReactionMsg deleted.
         * @member {number} deleted
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.deleted = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReactionMsg emoji.
         * @member {string} emoji
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.emoji = "";

        /**
         * ReactionMsg created.
         * @member {number} created
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReactionMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReactionMsg externid.
         * @member {string} externid
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.externid = "";

        /**
         * ReactionMsg raw.
         * @member {cat.IRawMsg|null|undefined} raw
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.raw = null;

        /**
         * ReactionMsg tsatimestamp.
         * @member {number} tsatimestamp
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.tsatimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReactionMsg tsasignature.
         * @member {string} tsasignature
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.tsasignature = "";

        /**
         * ReactionMsg count.
         * @member {number|null|undefined} count
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.count = null;

        /**
         * ReactionMsg sender.
         * @member {cat.IAccountMsg|null|undefined} sender
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.sender = null;

        /**
         * ReactionMsg customemoji.
         * @member {cat.IAttachmentMsg|null|undefined} customemoji
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.customemoji = null;

        /**
         * ReactionMsg history.
         * @member {Array.<cat.IReactionMsg>} history
         * @memberof cat.ReactionMsg
         * @instance
         */
        ReactionMsg.prototype.history = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReactionMsg.prototype, "_count", {
            get: $util.oneOfGetter($oneOfFields = ["count"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ReactionMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReactionMsg
         * @static
         * @param {cat.IReactionMsg=} [properties] Properties to set
         * @returns {cat.ReactionMsg} ReactionMsg instance
         */
        ReactionMsg.create = function create(properties) {
            return new ReactionMsg(properties);
        };

        /**
         * Encodes the specified ReactionMsg message. Does not implicitly {@link cat.ReactionMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReactionMsg
         * @static
         * @param {cat.IReactionMsg} m ReactionMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReactionMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.posted != null && Object.hasOwnProperty.call(m, "posted"))
                w.uint32(16).uint64(m.posted);
            if (m.deleted != null && Object.hasOwnProperty.call(m, "deleted"))
                w.uint32(24).uint64(m.deleted);
            if (m.emoji != null && Object.hasOwnProperty.call(m, "emoji"))
                w.uint32(34).string(m.emoji);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(40).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(48).uint64(m.lastmodified);
            if (m.externid != null && Object.hasOwnProperty.call(m, "externid"))
                w.uint32(58).string(m.externid);
            if (m.raw != null && Object.hasOwnProperty.call(m, "raw"))
                $root.cat.RawMsg.encode(m.raw, w.uint32(66).fork()).ldelim();
            if (m.tsatimestamp != null && Object.hasOwnProperty.call(m, "tsatimestamp"))
                w.uint32(72).uint64(m.tsatimestamp);
            if (m.tsasignature != null && Object.hasOwnProperty.call(m, "tsasignature"))
                w.uint32(82).string(m.tsasignature);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(88).uint32(m.count);
            if (m.sender != null && Object.hasOwnProperty.call(m, "sender"))
                $root.cat.AccountMsg.encode(m.sender, w.uint32(810).fork()).ldelim();
            if (m.customemoji != null && Object.hasOwnProperty.call(m, "customemoji"))
                $root.cat.AttachmentMsg.encode(m.customemoji, w.uint32(818).fork()).ldelim();
            if (m.history != null && m.history.length) {
                for (var i = 0; i < m.history.length; ++i)
                    $root.cat.ReactionMsg.encode(m.history[i], w.uint32(826).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ReactionMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReactionMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReactionMsg} ReactionMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReactionMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReactionMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.posted = r.uint64();
                        break;
                    }
                case 3: {
                        m.deleted = r.uint64();
                        break;
                    }
                case 4: {
                        m.emoji = r.string();
                        break;
                    }
                case 5: {
                        m.created = r.uint64();
                        break;
                    }
                case 6: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 7: {
                        m.externid = r.string();
                        break;
                    }
                case 8: {
                        m.raw = $root.cat.RawMsg.decode(r, r.uint32());
                        break;
                    }
                case 9: {
                        m.tsatimestamp = r.uint64();
                        break;
                    }
                case 10: {
                        m.tsasignature = r.string();
                        break;
                    }
                case 11: {
                        m.count = r.uint32();
                        break;
                    }
                case 101: {
                        m.sender = $root.cat.AccountMsg.decode(r, r.uint32());
                        break;
                    }
                case 102: {
                        m.customemoji = $root.cat.AttachmentMsg.decode(r, r.uint32());
                        break;
                    }
                case 103: {
                        if (!(m.history && m.history.length))
                            m.history = [];
                        m.history.push($root.cat.ReactionMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReactionMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReactionMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReactionMsg} ReactionMsg
         */
        ReactionMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReactionMsg)
                return d;
            var m = new $root.cat.ReactionMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.posted != null) {
                if ($util.Long)
                    (m.posted = $util.Long.fromValue(d.posted)).unsigned = true;
                else if (typeof d.posted === "string")
                    m.posted = parseInt(d.posted, 10);
                else if (typeof d.posted === "number")
                    m.posted = d.posted;
                else if (typeof d.posted === "object")
                    m.posted = new $util.LongBits(d.posted.low >>> 0, d.posted.high >>> 0).toNumber(true);
            }
            if (d.deleted != null) {
                if ($util.Long)
                    (m.deleted = $util.Long.fromValue(d.deleted)).unsigned = true;
                else if (typeof d.deleted === "string")
                    m.deleted = parseInt(d.deleted, 10);
                else if (typeof d.deleted === "number")
                    m.deleted = d.deleted;
                else if (typeof d.deleted === "object")
                    m.deleted = new $util.LongBits(d.deleted.low >>> 0, d.deleted.high >>> 0).toNumber(true);
            }
            if (d.emoji != null) {
                m.emoji = String(d.emoji);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.externid != null) {
                m.externid = String(d.externid);
            }
            if (d.raw != null) {
                if (typeof d.raw !== "object")
                    throw TypeError(".cat.ReactionMsg.raw: object expected");
                m.raw = $root.cat.RawMsg.fromObject(d.raw);
            }
            if (d.tsatimestamp != null) {
                if ($util.Long)
                    (m.tsatimestamp = $util.Long.fromValue(d.tsatimestamp)).unsigned = true;
                else if (typeof d.tsatimestamp === "string")
                    m.tsatimestamp = parseInt(d.tsatimestamp, 10);
                else if (typeof d.tsatimestamp === "number")
                    m.tsatimestamp = d.tsatimestamp;
                else if (typeof d.tsatimestamp === "object")
                    m.tsatimestamp = new $util.LongBits(d.tsatimestamp.low >>> 0, d.tsatimestamp.high >>> 0).toNumber(true);
            }
            if (d.tsasignature != null) {
                m.tsasignature = String(d.tsasignature);
            }
            if (d.count != null) {
                m.count = d.count >>> 0;
            }
            if (d.sender != null) {
                if (typeof d.sender !== "object")
                    throw TypeError(".cat.ReactionMsg.sender: object expected");
                m.sender = $root.cat.AccountMsg.fromObject(d.sender);
            }
            if (d.customemoji != null) {
                if (typeof d.customemoji !== "object")
                    throw TypeError(".cat.ReactionMsg.customemoji: object expected");
                m.customemoji = $root.cat.AttachmentMsg.fromObject(d.customemoji);
            }
            if (d.history) {
                if (!Array.isArray(d.history))
                    throw TypeError(".cat.ReactionMsg.history: array expected");
                m.history = [];
                for (var i = 0; i < d.history.length; ++i) {
                    if (typeof d.history[i] !== "object")
                        throw TypeError(".cat.ReactionMsg.history: object expected");
                    m.history[i] = $root.cat.ReactionMsg.fromObject(d.history[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ReactionMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReactionMsg
         * @static
         * @param {cat.ReactionMsg} m ReactionMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReactionMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.history = [];
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.posted = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.posted = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.deleted = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.deleted = o.longs === String ? "0" : 0;
                d.emoji = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.externid = "";
                d.raw = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.tsatimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.tsatimestamp = o.longs === String ? "0" : 0;
                d.tsasignature = "";
                d.sender = null;
                d.customemoji = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.posted != null && m.hasOwnProperty("posted")) {
                if (typeof m.posted === "number")
                    d.posted = o.longs === String ? String(m.posted) : m.posted;
                else
                    d.posted = o.longs === String ? $util.Long.prototype.toString.call(m.posted) : o.longs === Number ? new $util.LongBits(m.posted.low >>> 0, m.posted.high >>> 0).toNumber(true) : m.posted;
            }
            if (m.deleted != null && m.hasOwnProperty("deleted")) {
                if (typeof m.deleted === "number")
                    d.deleted = o.longs === String ? String(m.deleted) : m.deleted;
                else
                    d.deleted = o.longs === String ? $util.Long.prototype.toString.call(m.deleted) : o.longs === Number ? new $util.LongBits(m.deleted.low >>> 0, m.deleted.high >>> 0).toNumber(true) : m.deleted;
            }
            if (m.emoji != null && m.hasOwnProperty("emoji")) {
                d.emoji = m.emoji;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.externid != null && m.hasOwnProperty("externid")) {
                d.externid = m.externid;
            }
            if (m.raw != null && m.hasOwnProperty("raw")) {
                d.raw = $root.cat.RawMsg.toObject(m.raw, o);
            }
            if (m.tsatimestamp != null && m.hasOwnProperty("tsatimestamp")) {
                if (typeof m.tsatimestamp === "number")
                    d.tsatimestamp = o.longs === String ? String(m.tsatimestamp) : m.tsatimestamp;
                else
                    d.tsatimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.tsatimestamp) : o.longs === Number ? new $util.LongBits(m.tsatimestamp.low >>> 0, m.tsatimestamp.high >>> 0).toNumber(true) : m.tsatimestamp;
            }
            if (m.tsasignature != null && m.hasOwnProperty("tsasignature")) {
                d.tsasignature = m.tsasignature;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                d.count = m.count;
                if (o.oneofs)
                    d._count = "count";
            }
            if (m.sender != null && m.hasOwnProperty("sender")) {
                d.sender = $root.cat.AccountMsg.toObject(m.sender, o);
            }
            if (m.customemoji != null && m.hasOwnProperty("customemoji")) {
                d.customemoji = $root.cat.AttachmentMsg.toObject(m.customemoji, o);
            }
            if (m.history && m.history.length) {
                d.history = [];
                for (var j = 0; j < m.history.length; ++j) {
                    d.history[j] = $root.cat.ReactionMsg.toObject(m.history[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ReactionMsg to JSON.
         * @function toJSON
         * @memberof cat.ReactionMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReactionMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReactionMsg
         * @function getTypeUrl
         * @memberof cat.ReactionMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReactionMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReactionMsg";
        };

        return ReactionMsg;
    })();

    /**
     * LabelType enum.
     * @name cat.LabelType
     * @enum {number}
     * @property {number} LABELTYPE_UNKNOWN=0 LABELTYPE_UNKNOWN value
     * @property {number} LABELTYPE_GLOBAL=1 LABELTYPE_GLOBAL value
     * @property {number} LABELTYPE_PROFILE=2 LABELTYPE_PROFILE value
     * @property {number} LABELTYPE_CASE=3 LABELTYPE_CASE value
     */
    cat.LabelType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LABELTYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "LABELTYPE_GLOBAL"] = 1;
        values[valuesById[2] = "LABELTYPE_PROFILE"] = 2;
        values[valuesById[3] = "LABELTYPE_CASE"] = 3;
        return values;
    })();

    cat.LabelMsg = (function() {

        /**
         * Properties of a LabelMsg.
         * @memberof cat
         * @interface ILabelMsg
         * @property {string|null} [id] LabelMsg id
         * @property {string|null} [name] LabelMsg name
         * @property {string|null} [description] LabelMsg description
         * @property {number|null} [created] LabelMsg created
         * @property {number|null} [lastmodified] LabelMsg lastmodified
         * @property {string|null} [color] LabelMsg color
         * @property {number|null} [count] LabelMsg count
         * @property {cat.LabelType|null} [type] LabelMsg type
         */

        /**
         * Constructs a new LabelMsg.
         * @memberof cat
         * @classdesc Represents a LabelMsg.
         * @implements ILabelMsg
         * @constructor
         * @param {cat.ILabelMsg=} [p] Properties to set
         */
        function LabelMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LabelMsg id.
         * @member {string} id
         * @memberof cat.LabelMsg
         * @instance
         */
        LabelMsg.prototype.id = "";

        /**
         * LabelMsg name.
         * @member {string} name
         * @memberof cat.LabelMsg
         * @instance
         */
        LabelMsg.prototype.name = "";

        /**
         * LabelMsg description.
         * @member {string} description
         * @memberof cat.LabelMsg
         * @instance
         */
        LabelMsg.prototype.description = "";

        /**
         * LabelMsg created.
         * @member {number} created
         * @memberof cat.LabelMsg
         * @instance
         */
        LabelMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * LabelMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.LabelMsg
         * @instance
         */
        LabelMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * LabelMsg color.
         * @member {string} color
         * @memberof cat.LabelMsg
         * @instance
         */
        LabelMsg.prototype.color = "";

        /**
         * LabelMsg count.
         * @member {number} count
         * @memberof cat.LabelMsg
         * @instance
         */
        LabelMsg.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * LabelMsg type.
         * @member {cat.LabelType} type
         * @memberof cat.LabelMsg
         * @instance
         */
        LabelMsg.prototype.type = 0;

        /**
         * Creates a new LabelMsg instance using the specified properties.
         * @function create
         * @memberof cat.LabelMsg
         * @static
         * @param {cat.ILabelMsg=} [properties] Properties to set
         * @returns {cat.LabelMsg} LabelMsg instance
         */
        LabelMsg.create = function create(properties) {
            return new LabelMsg(properties);
        };

        /**
         * Encodes the specified LabelMsg message. Does not implicitly {@link cat.LabelMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.LabelMsg
         * @static
         * @param {cat.ILabelMsg} m LabelMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LabelMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(26).string(m.description);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(32).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(40).uint64(m.lastmodified);
            if (m.color != null && Object.hasOwnProperty.call(m, "color"))
                w.uint32(50).string(m.color);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(808).uint64(m.count);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(816).int32(m.type);
            return w;
        };

        /**
         * Decodes a LabelMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.LabelMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.LabelMsg} LabelMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LabelMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.LabelMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.description = r.string();
                        break;
                    }
                case 4: {
                        m.created = r.uint64();
                        break;
                    }
                case 5: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 6: {
                        m.color = r.string();
                        break;
                    }
                case 101: {
                        m.count = r.uint64();
                        break;
                    }
                case 102: {
                        m.type = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a LabelMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.LabelMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.LabelMsg} LabelMsg
         */
        LabelMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.LabelMsg)
                return d;
            var m = new $root.cat.LabelMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.color != null) {
                m.color = String(d.color);
            }
            if (d.count != null) {
                if ($util.Long)
                    (m.count = $util.Long.fromValue(d.count)).unsigned = true;
                else if (typeof d.count === "string")
                    m.count = parseInt(d.count, 10);
                else if (typeof d.count === "number")
                    m.count = d.count;
                else if (typeof d.count === "object")
                    m.count = new $util.LongBits(d.count.low >>> 0, d.count.high >>> 0).toNumber(true);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "LABELTYPE_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "LABELTYPE_GLOBAL":
            case 1:
                m.type = 1;
                break;
            case "LABELTYPE_PROFILE":
            case 2:
                m.type = 2;
                break;
            case "LABELTYPE_CASE":
            case 3:
                m.type = 3;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a LabelMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.LabelMsg
         * @static
         * @param {cat.LabelMsg} m LabelMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LabelMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.description = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.color = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.count = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.count = o.longs === String ? "0" : 0;
                d.type = o.enums === String ? "LABELTYPE_UNKNOWN" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.color != null && m.hasOwnProperty("color")) {
                d.color = m.color;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                if (typeof m.count === "number")
                    d.count = o.longs === String ? String(m.count) : m.count;
                else
                    d.count = o.longs === String ? $util.Long.prototype.toString.call(m.count) : o.longs === Number ? new $util.LongBits(m.count.low >>> 0, m.count.high >>> 0).toNumber(true) : m.count;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.LabelType[m.type] === undefined ? m.type : $root.cat.LabelType[m.type] : m.type;
            }
            return d;
        };

        /**
         * Converts this LabelMsg to JSON.
         * @function toJSON
         * @memberof cat.LabelMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LabelMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LabelMsg
         * @function getTypeUrl
         * @memberof cat.LabelMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LabelMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.LabelMsg";
        };

        return LabelMsg;
    })();

    /**
     * TriggerActionType enum.
     * @name cat.TriggerActionType
     * @enum {number}
     * @property {number} TRIGGERACTION_UNKNOWN=0 TRIGGERACTION_UNKNOWN value
     * @property {number} TRIGGERACTION_LABEL=1 TRIGGERACTION_LABEL value
     */
    cat.TriggerActionType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TRIGGERACTION_UNKNOWN"] = 0;
        values[valuesById[1] = "TRIGGERACTION_LABEL"] = 1;
        return values;
    })();

    cat.TriggerActionMsg = (function() {

        /**
         * Properties of a TriggerActionMsg.
         * @memberof cat
         * @interface ITriggerActionMsg
         * @property {string|null} [id] TriggerActionMsg id
         * @property {cat.TriggerActionType|null} [type] TriggerActionMsg type
         * @property {number|null} [created] TriggerActionMsg created
         * @property {number|null} [lastmodified] TriggerActionMsg lastmodified
         * @property {cat.ILabelMsg|null} [label] TriggerActionMsg label
         */

        /**
         * Constructs a new TriggerActionMsg.
         * @memberof cat
         * @classdesc Represents a TriggerActionMsg.
         * @implements ITriggerActionMsg
         * @constructor
         * @param {cat.ITriggerActionMsg=} [p] Properties to set
         */
        function TriggerActionMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TriggerActionMsg id.
         * @member {string} id
         * @memberof cat.TriggerActionMsg
         * @instance
         */
        TriggerActionMsg.prototype.id = "";

        /**
         * TriggerActionMsg type.
         * @member {cat.TriggerActionType} type
         * @memberof cat.TriggerActionMsg
         * @instance
         */
        TriggerActionMsg.prototype.type = 0;

        /**
         * TriggerActionMsg created.
         * @member {number} created
         * @memberof cat.TriggerActionMsg
         * @instance
         */
        TriggerActionMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TriggerActionMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.TriggerActionMsg
         * @instance
         */
        TriggerActionMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TriggerActionMsg label.
         * @member {cat.ILabelMsg|null|undefined} label
         * @memberof cat.TriggerActionMsg
         * @instance
         */
        TriggerActionMsg.prototype.label = null;

        /**
         * Creates a new TriggerActionMsg instance using the specified properties.
         * @function create
         * @memberof cat.TriggerActionMsg
         * @static
         * @param {cat.ITriggerActionMsg=} [properties] Properties to set
         * @returns {cat.TriggerActionMsg} TriggerActionMsg instance
         */
        TriggerActionMsg.create = function create(properties) {
            return new TriggerActionMsg(properties);
        };

        /**
         * Encodes the specified TriggerActionMsg message. Does not implicitly {@link cat.TriggerActionMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.TriggerActionMsg
         * @static
         * @param {cat.ITriggerActionMsg} m TriggerActionMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerActionMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(24).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(32).uint64(m.lastmodified);
            if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                $root.cat.LabelMsg.encode(m.label, w.uint32(810).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a TriggerActionMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.TriggerActionMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.TriggerActionMsg} TriggerActionMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerActionMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.TriggerActionMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.type = r.int32();
                        break;
                    }
                case 3: {
                        m.created = r.uint64();
                        break;
                    }
                case 4: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 101: {
                        m.label = $root.cat.LabelMsg.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TriggerActionMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.TriggerActionMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.TriggerActionMsg} TriggerActionMsg
         */
        TriggerActionMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.TriggerActionMsg)
                return d;
            var m = new $root.cat.TriggerActionMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "TRIGGERACTION_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "TRIGGERACTION_LABEL":
            case 1:
                m.type = 1;
                break;
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.label != null) {
                if (typeof d.label !== "object")
                    throw TypeError(".cat.TriggerActionMsg.label: object expected");
                m.label = $root.cat.LabelMsg.fromObject(d.label);
            }
            return m;
        };

        /**
         * Creates a plain object from a TriggerActionMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.TriggerActionMsg
         * @static
         * @param {cat.TriggerActionMsg} m TriggerActionMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TriggerActionMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.type = o.enums === String ? "TRIGGERACTION_UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.label = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.TriggerActionType[m.type] === undefined ? m.type : $root.cat.TriggerActionType[m.type] : m.type;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.label != null && m.hasOwnProperty("label")) {
                d.label = $root.cat.LabelMsg.toObject(m.label, o);
            }
            return d;
        };

        /**
         * Converts this TriggerActionMsg to JSON.
         * @function toJSON
         * @memberof cat.TriggerActionMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TriggerActionMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TriggerActionMsg
         * @function getTypeUrl
         * @memberof cat.TriggerActionMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TriggerActionMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.TriggerActionMsg";
        };

        return TriggerActionMsg;
    })();

    /**
     * TriggerType enum.
     * @name cat.TriggerType
     * @enum {number}
     * @property {number} TRIGGER_UNKNOWN=0 TRIGGER_UNKNOWN value
     * @property {number} TRIGGER_HASH=1 TRIGGER_HASH value
     * @property {number} TRIGGER_KEYWORD=2 TRIGGER_KEYWORD value
     */
    cat.TriggerType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TRIGGER_UNKNOWN"] = 0;
        values[valuesById[1] = "TRIGGER_HASH"] = 1;
        values[valuesById[2] = "TRIGGER_KEYWORD"] = 2;
        return values;
    })();

    cat.TriggerMsg = (function() {

        /**
         * Properties of a TriggerMsg.
         * @memberof cat
         * @interface ITriggerMsg
         * @property {string|null} [id] TriggerMsg id
         * @property {string|null} [name] TriggerMsg name
         * @property {cat.TriggerType|null} [type] TriggerMsg type
         * @property {string|null} [value] TriggerMsg value
         * @property {number|null} [created] TriggerMsg created
         * @property {number|null} [lastmodified] TriggerMsg lastmodified
         * @property {string|null} [description] TriggerMsg description
         * @property {cat.StatusType|null} [status] TriggerMsg status
         * @property {Array.<cat.ITriggerActionMsg>|null} [actions] TriggerMsg actions
         */

        /**
         * Constructs a new TriggerMsg.
         * @memberof cat
         * @classdesc Represents a TriggerMsg.
         * @implements ITriggerMsg
         * @constructor
         * @param {cat.ITriggerMsg=} [p] Properties to set
         */
        function TriggerMsg(p) {
            this.actions = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TriggerMsg id.
         * @member {string} id
         * @memberof cat.TriggerMsg
         * @instance
         */
        TriggerMsg.prototype.id = "";

        /**
         * TriggerMsg name.
         * @member {string} name
         * @memberof cat.TriggerMsg
         * @instance
         */
        TriggerMsg.prototype.name = "";

        /**
         * TriggerMsg type.
         * @member {cat.TriggerType} type
         * @memberof cat.TriggerMsg
         * @instance
         */
        TriggerMsg.prototype.type = 0;

        /**
         * TriggerMsg value.
         * @member {string} value
         * @memberof cat.TriggerMsg
         * @instance
         */
        TriggerMsg.prototype.value = "";

        /**
         * TriggerMsg created.
         * @member {number} created
         * @memberof cat.TriggerMsg
         * @instance
         */
        TriggerMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TriggerMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.TriggerMsg
         * @instance
         */
        TriggerMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TriggerMsg description.
         * @member {string} description
         * @memberof cat.TriggerMsg
         * @instance
         */
        TriggerMsg.prototype.description = "";

        /**
         * TriggerMsg status.
         * @member {cat.StatusType} status
         * @memberof cat.TriggerMsg
         * @instance
         */
        TriggerMsg.prototype.status = 0;

        /**
         * TriggerMsg actions.
         * @member {Array.<cat.ITriggerActionMsg>} actions
         * @memberof cat.TriggerMsg
         * @instance
         */
        TriggerMsg.prototype.actions = $util.emptyArray;

        /**
         * Creates a new TriggerMsg instance using the specified properties.
         * @function create
         * @memberof cat.TriggerMsg
         * @static
         * @param {cat.ITriggerMsg=} [properties] Properties to set
         * @returns {cat.TriggerMsg} TriggerMsg instance
         */
        TriggerMsg.create = function create(properties) {
            return new TriggerMsg(properties);
        };

        /**
         * Encodes the specified TriggerMsg message. Does not implicitly {@link cat.TriggerMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.TriggerMsg
         * @static
         * @param {cat.ITriggerMsg} m TriggerMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(34).string(m.value);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(40).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(48).uint64(m.lastmodified);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(58).string(m.description);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.actions != null && m.actions.length) {
                for (var i = 0; i < m.actions.length; ++i)
                    $root.cat.TriggerActionMsg.encode(m.actions[i], w.uint32(810).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TriggerMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.TriggerMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.TriggerMsg} TriggerMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.TriggerMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.type = r.int32();
                        break;
                    }
                case 4: {
                        m.value = r.string();
                        break;
                    }
                case 5: {
                        m.created = r.uint64();
                        break;
                    }
                case 6: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 7: {
                        m.description = r.string();
                        break;
                    }
                case 8: {
                        m.status = r.int32();
                        break;
                    }
                case 101: {
                        if (!(m.actions && m.actions.length))
                            m.actions = [];
                        m.actions.push($root.cat.TriggerActionMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TriggerMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.TriggerMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.TriggerMsg} TriggerMsg
         */
        TriggerMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.TriggerMsg)
                return d;
            var m = new $root.cat.TriggerMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "TRIGGER_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "TRIGGER_HASH":
            case 1:
                m.type = 1;
                break;
            case "TRIGGER_KEYWORD":
            case 2:
                m.type = 2;
                break;
            }
            if (d.value != null) {
                m.value = String(d.value);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ENABLED":
            case 1:
                m.status = 1;
                break;
            case "DISABLED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.actions) {
                if (!Array.isArray(d.actions))
                    throw TypeError(".cat.TriggerMsg.actions: array expected");
                m.actions = [];
                for (var i = 0; i < d.actions.length; ++i) {
                    if (typeof d.actions[i] !== "object")
                        throw TypeError(".cat.TriggerMsg.actions: object expected");
                    m.actions[i] = $root.cat.TriggerActionMsg.fromObject(d.actions[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TriggerMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.TriggerMsg
         * @static
         * @param {cat.TriggerMsg} m TriggerMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TriggerMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.actions = [];
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.type = o.enums === String ? "TRIGGER_UNKNOWN" : 0;
                d.value = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.description = "";
                d.status = o.enums === String ? "UNKNOWN" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.TriggerType[m.type] === undefined ? m.type : $root.cat.TriggerType[m.type] : m.type;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.StatusType[m.status] === undefined ? m.status : $root.cat.StatusType[m.status] : m.status;
            }
            if (m.actions && m.actions.length) {
                d.actions = [];
                for (var j = 0; j < m.actions.length; ++j) {
                    d.actions[j] = $root.cat.TriggerActionMsg.toObject(m.actions[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TriggerMsg to JSON.
         * @function toJSON
         * @memberof cat.TriggerMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TriggerMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TriggerMsg
         * @function getTypeUrl
         * @memberof cat.TriggerMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TriggerMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.TriggerMsg";
        };

        return TriggerMsg;
    })();

    cat.AnnotationMsg = (function() {

        /**
         * Properties of an AnnotationMsg.
         * @memberof cat
         * @interface IAnnotationMsg
         * @property {string|null} [id] AnnotationMsg id
         * @property {string|null} [title] AnnotationMsg title
         * @property {string|null} [annotation] AnnotationMsg annotation
         * @property {number|null} [created] AnnotationMsg created
         * @property {number|null} [lastmodified] AnnotationMsg lastmodified
         * @property {cat.IUserMsg|null} [user] AnnotationMsg user
         * @property {cat.IAttachmentMsg|null} [attachment] AnnotationMsg attachment
         */

        /**
         * Constructs a new AnnotationMsg.
         * @memberof cat
         * @classdesc Represents an AnnotationMsg.
         * @implements IAnnotationMsg
         * @constructor
         * @param {cat.IAnnotationMsg=} [p] Properties to set
         */
        function AnnotationMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AnnotationMsg id.
         * @member {string} id
         * @memberof cat.AnnotationMsg
         * @instance
         */
        AnnotationMsg.prototype.id = "";

        /**
         * AnnotationMsg title.
         * @member {string} title
         * @memberof cat.AnnotationMsg
         * @instance
         */
        AnnotationMsg.prototype.title = "";

        /**
         * AnnotationMsg annotation.
         * @member {string} annotation
         * @memberof cat.AnnotationMsg
         * @instance
         */
        AnnotationMsg.prototype.annotation = "";

        /**
         * AnnotationMsg created.
         * @member {number} created
         * @memberof cat.AnnotationMsg
         * @instance
         */
        AnnotationMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AnnotationMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.AnnotationMsg
         * @instance
         */
        AnnotationMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AnnotationMsg user.
         * @member {cat.IUserMsg|null|undefined} user
         * @memberof cat.AnnotationMsg
         * @instance
         */
        AnnotationMsg.prototype.user = null;

        /**
         * AnnotationMsg attachment.
         * @member {cat.IAttachmentMsg|null|undefined} attachment
         * @memberof cat.AnnotationMsg
         * @instance
         */
        AnnotationMsg.prototype.attachment = null;

        /**
         * Creates a new AnnotationMsg instance using the specified properties.
         * @function create
         * @memberof cat.AnnotationMsg
         * @static
         * @param {cat.IAnnotationMsg=} [properties] Properties to set
         * @returns {cat.AnnotationMsg} AnnotationMsg instance
         */
        AnnotationMsg.create = function create(properties) {
            return new AnnotationMsg(properties);
        };

        /**
         * Encodes the specified AnnotationMsg message. Does not implicitly {@link cat.AnnotationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.AnnotationMsg
         * @static
         * @param {cat.IAnnotationMsg} m AnnotationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AnnotationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(18).string(m.title);
            if (m.annotation != null && Object.hasOwnProperty.call(m, "annotation"))
                w.uint32(26).string(m.annotation);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(32).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(40).uint64(m.lastmodified);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.cat.UserMsg.encode(m.user, w.uint32(810).fork()).ldelim();
            if (m.attachment != null && Object.hasOwnProperty.call(m, "attachment"))
                $root.cat.AttachmentMsg.encode(m.attachment, w.uint32(818).fork()).ldelim();
            return w;
        };

        /**
         * Decodes an AnnotationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.AnnotationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.AnnotationMsg} AnnotationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AnnotationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.AnnotationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.title = r.string();
                        break;
                    }
                case 3: {
                        m.annotation = r.string();
                        break;
                    }
                case 4: {
                        m.created = r.uint64();
                        break;
                    }
                case 5: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 101: {
                        m.user = $root.cat.UserMsg.decode(r, r.uint32());
                        break;
                    }
                case 102: {
                        m.attachment = $root.cat.AttachmentMsg.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AnnotationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.AnnotationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.AnnotationMsg} AnnotationMsg
         */
        AnnotationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.AnnotationMsg)
                return d;
            var m = new $root.cat.AnnotationMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.annotation != null) {
                m.annotation = String(d.annotation);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".cat.AnnotationMsg.user: object expected");
                m.user = $root.cat.UserMsg.fromObject(d.user);
            }
            if (d.attachment != null) {
                if (typeof d.attachment !== "object")
                    throw TypeError(".cat.AnnotationMsg.attachment: object expected");
                m.attachment = $root.cat.AttachmentMsg.fromObject(d.attachment);
            }
            return m;
        };

        /**
         * Creates a plain object from an AnnotationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.AnnotationMsg
         * @static
         * @param {cat.AnnotationMsg} m AnnotationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AnnotationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.title = "";
                d.annotation = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.user = null;
                d.attachment = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.annotation != null && m.hasOwnProperty("annotation")) {
                d.annotation = m.annotation;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.cat.UserMsg.toObject(m.user, o);
            }
            if (m.attachment != null && m.hasOwnProperty("attachment")) {
                d.attachment = $root.cat.AttachmentMsg.toObject(m.attachment, o);
            }
            return d;
        };

        /**
         * Converts this AnnotationMsg to JSON.
         * @function toJSON
         * @memberof cat.AnnotationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AnnotationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AnnotationMsg
         * @function getTypeUrl
         * @memberof cat.AnnotationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AnnotationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.AnnotationMsg";
        };

        return AnnotationMsg;
    })();

    /**
     * MediaType enum.
     * @name cat.MediaType
     * @enum {number}
     * @property {number} MEDIA_UNKNOWN=0 MEDIA_UNKNOWN value
     * @property {number} MEDIA_QR=1 MEDIA_QR value
     * @property {number} MEDIA_ICONS=2 MEDIA_ICONS value
     * @property {number} MEDIA_LIBRARIES=3 MEDIA_LIBRARIES value
     * @property {number} MEDIA_PROFILEAVATARS=4 MEDIA_PROFILEAVATARS value
     * @property {number} MEDIA_CONVERSATIONS=5 MEDIA_CONVERSATIONS value
     * @property {number} MEDIA_CAPTURES=6 MEDIA_CAPTURES value
     * @property {number} MEDIA_ACCOUNTAVATARS=7 MEDIA_ACCOUNTAVATARS value
     * @property {number} MEDIA_PROFILEREPORTS=8 MEDIA_PROFILEREPORTS value
     * @property {number} MEDIA_REPORTTEMPLATES=9 MEDIA_REPORTTEMPLATES value
     * @property {number} MEDIA_EMOJIS=10 MEDIA_EMOJIS value
     * @property {number} MEDIA_ANNOTATIONS=11 MEDIA_ANNOTATIONS value
     * @property {number} MEDIA_DOCS=12 MEDIA_DOCS value
     * @property {number} MEDIA_REPORTPREVIEW=13 MEDIA_REPORTPREVIEW value
     * @property {number} MEDIA_RECEIVER=14 MEDIA_RECEIVER value
     * @property {number} MEDIA_LOG=15 MEDIA_LOG value
     * @property {number} MEDIA_BACKUP=16 MEDIA_BACKUP value
     * @property {number} MEDIA_APK=17 MEDIA_APK value
     * @property {number} MEDIA_EXPORT=18 MEDIA_EXPORT value
     * @property {number} MEDIA_TASKS=19 MEDIA_TASKS value
     */
    cat.MediaType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MEDIA_UNKNOWN"] = 0;
        values[valuesById[1] = "MEDIA_QR"] = 1;
        values[valuesById[2] = "MEDIA_ICONS"] = 2;
        values[valuesById[3] = "MEDIA_LIBRARIES"] = 3;
        values[valuesById[4] = "MEDIA_PROFILEAVATARS"] = 4;
        values[valuesById[5] = "MEDIA_CONVERSATIONS"] = 5;
        values[valuesById[6] = "MEDIA_CAPTURES"] = 6;
        values[valuesById[7] = "MEDIA_ACCOUNTAVATARS"] = 7;
        values[valuesById[8] = "MEDIA_PROFILEREPORTS"] = 8;
        values[valuesById[9] = "MEDIA_REPORTTEMPLATES"] = 9;
        values[valuesById[10] = "MEDIA_EMOJIS"] = 10;
        values[valuesById[11] = "MEDIA_ANNOTATIONS"] = 11;
        values[valuesById[12] = "MEDIA_DOCS"] = 12;
        values[valuesById[13] = "MEDIA_REPORTPREVIEW"] = 13;
        values[valuesById[14] = "MEDIA_RECEIVER"] = 14;
        values[valuesById[15] = "MEDIA_LOG"] = 15;
        values[valuesById[16] = "MEDIA_BACKUP"] = 16;
        values[valuesById[17] = "MEDIA_APK"] = 17;
        values[valuesById[18] = "MEDIA_EXPORT"] = 18;
        values[valuesById[19] = "MEDIA_TASKS"] = 19;
        return values;
    })();

    cat.MediaMsg = (function() {

        /**
         * Properties of a MediaMsg.
         * @memberof cat
         * @interface IMediaMsg
         * @property {cat.MediaType|null} [type] MediaMsg type
         * @property {string|null} [refid] MediaMsg refid
         * @property {string|null} [fileid] MediaMsg fileid
         * @property {boolean|null} [thumbnail] MediaMsg thumbnail
         * @property {string|null} [key] MediaMsg key
         * @property {Uint8Array|null} [data] MediaMsg data
         */

        /**
         * Constructs a new MediaMsg.
         * @memberof cat
         * @classdesc Represents a MediaMsg.
         * @implements IMediaMsg
         * @constructor
         * @param {cat.IMediaMsg=} [p] Properties to set
         */
        function MediaMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * MediaMsg type.
         * @member {cat.MediaType} type
         * @memberof cat.MediaMsg
         * @instance
         */
        MediaMsg.prototype.type = 0;

        /**
         * MediaMsg refid.
         * @member {string} refid
         * @memberof cat.MediaMsg
         * @instance
         */
        MediaMsg.prototype.refid = "";

        /**
         * MediaMsg fileid.
         * @member {string} fileid
         * @memberof cat.MediaMsg
         * @instance
         */
        MediaMsg.prototype.fileid = "";

        /**
         * MediaMsg thumbnail.
         * @member {boolean} thumbnail
         * @memberof cat.MediaMsg
         * @instance
         */
        MediaMsg.prototype.thumbnail = false;

        /**
         * MediaMsg key.
         * @member {string|null|undefined} key
         * @memberof cat.MediaMsg
         * @instance
         */
        MediaMsg.prototype.key = null;

        /**
         * MediaMsg data.
         * @member {Uint8Array} data
         * @memberof cat.MediaMsg
         * @instance
         */
        MediaMsg.prototype.data = $util.newBuffer([]);

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaMsg.prototype, "_key", {
            get: $util.oneOfGetter($oneOfFields = ["key"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MediaMsg instance using the specified properties.
         * @function create
         * @memberof cat.MediaMsg
         * @static
         * @param {cat.IMediaMsg=} [properties] Properties to set
         * @returns {cat.MediaMsg} MediaMsg instance
         */
        MediaMsg.create = function create(properties) {
            return new MediaMsg(properties);
        };

        /**
         * Encodes the specified MediaMsg message. Does not implicitly {@link cat.MediaMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.MediaMsg
         * @static
         * @param {cat.IMediaMsg} m MediaMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MediaMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.refid != null && Object.hasOwnProperty.call(m, "refid"))
                w.uint32(18).string(m.refid);
            if (m.fileid != null && Object.hasOwnProperty.call(m, "fileid"))
                w.uint32(26).string(m.fileid);
            if (m.thumbnail != null && Object.hasOwnProperty.call(m, "thumbnail"))
                w.uint32(32).bool(m.thumbnail);
            if (m.key != null && Object.hasOwnProperty.call(m, "key"))
                w.uint32(42).string(m.key);
            if (m.data != null && Object.hasOwnProperty.call(m, "data"))
                w.uint32(50).bytes(m.data);
            return w;
        };

        /**
         * Decodes a MediaMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.MediaMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.MediaMsg} MediaMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MediaMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.MediaMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.type = r.int32();
                        break;
                    }
                case 2: {
                        m.refid = r.string();
                        break;
                    }
                case 3: {
                        m.fileid = r.string();
                        break;
                    }
                case 4: {
                        m.thumbnail = r.bool();
                        break;
                    }
                case 5: {
                        m.key = r.string();
                        break;
                    }
                case 6: {
                        m.data = r.bytes();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a MediaMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.MediaMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.MediaMsg} MediaMsg
         */
        MediaMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.MediaMsg)
                return d;
            var m = new $root.cat.MediaMsg();
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "MEDIA_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "MEDIA_QR":
            case 1:
                m.type = 1;
                break;
            case "MEDIA_ICONS":
            case 2:
                m.type = 2;
                break;
            case "MEDIA_LIBRARIES":
            case 3:
                m.type = 3;
                break;
            case "MEDIA_PROFILEAVATARS":
            case 4:
                m.type = 4;
                break;
            case "MEDIA_CONVERSATIONS":
            case 5:
                m.type = 5;
                break;
            case "MEDIA_CAPTURES":
            case 6:
                m.type = 6;
                break;
            case "MEDIA_ACCOUNTAVATARS":
            case 7:
                m.type = 7;
                break;
            case "MEDIA_PROFILEREPORTS":
            case 8:
                m.type = 8;
                break;
            case "MEDIA_REPORTTEMPLATES":
            case 9:
                m.type = 9;
                break;
            case "MEDIA_EMOJIS":
            case 10:
                m.type = 10;
                break;
            case "MEDIA_ANNOTATIONS":
            case 11:
                m.type = 11;
                break;
            case "MEDIA_DOCS":
            case 12:
                m.type = 12;
                break;
            case "MEDIA_REPORTPREVIEW":
            case 13:
                m.type = 13;
                break;
            case "MEDIA_RECEIVER":
            case 14:
                m.type = 14;
                break;
            case "MEDIA_LOG":
            case 15:
                m.type = 15;
                break;
            case "MEDIA_BACKUP":
            case 16:
                m.type = 16;
                break;
            case "MEDIA_APK":
            case 17:
                m.type = 17;
                break;
            case "MEDIA_EXPORT":
            case 18:
                m.type = 18;
                break;
            case "MEDIA_TASKS":
            case 19:
                m.type = 19;
                break;
            }
            if (d.refid != null) {
                m.refid = String(d.refid);
            }
            if (d.fileid != null) {
                m.fileid = String(d.fileid);
            }
            if (d.thumbnail != null) {
                m.thumbnail = Boolean(d.thumbnail);
            }
            if (d.key != null) {
                m.key = String(d.key);
            }
            if (d.data != null) {
                if (typeof d.data === "string")
                    $util.base64.decode(d.data, m.data = $util.newBuffer($util.base64.length(d.data)), 0);
                else if (d.data.length >= 0)
                    m.data = d.data;
            }
            return m;
        };

        /**
         * Creates a plain object from a MediaMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.MediaMsg
         * @static
         * @param {cat.MediaMsg} m MediaMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MediaMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = o.enums === String ? "MEDIA_UNKNOWN" : 0;
                d.refid = "";
                d.fileid = "";
                d.thumbnail = false;
                if (o.bytes === String)
                    d.data = "";
                else {
                    d.data = [];
                    if (o.bytes !== Array)
                        d.data = $util.newBuffer(d.data);
                }
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.MediaType[m.type] === undefined ? m.type : $root.cat.MediaType[m.type] : m.type;
            }
            if (m.refid != null && m.hasOwnProperty("refid")) {
                d.refid = m.refid;
            }
            if (m.fileid != null && m.hasOwnProperty("fileid")) {
                d.fileid = m.fileid;
            }
            if (m.thumbnail != null && m.hasOwnProperty("thumbnail")) {
                d.thumbnail = m.thumbnail;
            }
            if (m.key != null && m.hasOwnProperty("key")) {
                d.key = m.key;
                if (o.oneofs)
                    d._key = "key";
            }
            if (m.data != null && m.hasOwnProperty("data")) {
                d.data = o.bytes === String ? $util.base64.encode(m.data, 0, m.data.length) : o.bytes === Array ? Array.prototype.slice.call(m.data) : m.data;
            }
            return d;
        };

        /**
         * Converts this MediaMsg to JSON.
         * @function toJSON
         * @memberof cat.MediaMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MediaMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MediaMsg
         * @function getTypeUrl
         * @memberof cat.MediaMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MediaMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.MediaMsg";
        };

        return MediaMsg;
    })();

    /**
     * AuditLogType enum.
     * @name cat.AuditLogType
     * @enum {number}
     * @property {number} AUDITLOG_UNKNOWN=0 AUDITLOG_UNKNOWN value
     * @property {number} AUDITLOG_SYSTEM=1 AUDITLOG_SYSTEM value
     * @property {number} AUDITLOG_USER=2 AUDITLOG_USER value
     */
    cat.AuditLogType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AUDITLOG_UNKNOWN"] = 0;
        values[valuesById[1] = "AUDITLOG_SYSTEM"] = 1;
        values[valuesById[2] = "AUDITLOG_USER"] = 2;
        return values;
    })();

    /**
     * AuditLogSourceType enum.
     * @name cat.AuditLogSourceType
     * @enum {number}
     * @property {number} AUDITLOGSOURCETYPE_UNKNOWN=0 AUDITLOGSOURCETYPE_UNKNOWN value
     * @property {number} AUDITLOGSOURCETYPE_SERVER=1 AUDITLOGSOURCETYPE_SERVER value
     * @property {number} AUDITLOGSOURCETYPE_BROWSER=2 AUDITLOGSOURCETYPE_BROWSER value
     * @property {number} AUDITLOGSOURCETYPE_CAPTURE=3 AUDITLOGSOURCETYPE_CAPTURE value
     */
    cat.AuditLogSourceType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AUDITLOGSOURCETYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "AUDITLOGSOURCETYPE_SERVER"] = 1;
        values[valuesById[2] = "AUDITLOGSOURCETYPE_BROWSER"] = 2;
        values[valuesById[3] = "AUDITLOGSOURCETYPE_CAPTURE"] = 3;
        return values;
    })();

    /**
     * AuditLogResult enum.
     * @name cat.AuditLogResult
     * @enum {number}
     * @property {number} AUDITLOGRESULT_UNKNOWN=0 AUDITLOGRESULT_UNKNOWN value
     * @property {number} AUDITLOGRESULT_SUCCESS=1 AUDITLOGRESULT_SUCCESS value
     * @property {number} AUDITLOGRESULT_FAILED=2 AUDITLOGRESULT_FAILED value
     * @property {number} AUDITLOGRESULT_UNAUTHENTICATED=3 AUDITLOGRESULT_UNAUTHENTICATED value
     * @property {number} AUDITLOGRESULT_INVALIDARGUMENT=5 AUDITLOGRESULT_INVALIDARGUMENT value
     * @property {number} AUDITLOGRESULT_INVALIDSIGNIN=6 AUDITLOGRESULT_INVALIDSIGNIN value
     * @property {number} AUDITLOGRESULT_PERMISSIONDENIED=7 AUDITLOGRESULT_PERMISSIONDENIED value
     */
    cat.AuditLogResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AUDITLOGRESULT_UNKNOWN"] = 0;
        values[valuesById[1] = "AUDITLOGRESULT_SUCCESS"] = 1;
        values[valuesById[2] = "AUDITLOGRESULT_FAILED"] = 2;
        values[valuesById[3] = "AUDITLOGRESULT_UNAUTHENTICATED"] = 3;
        values[valuesById[5] = "AUDITLOGRESULT_INVALIDARGUMENT"] = 5;
        values[valuesById[6] = "AUDITLOGRESULT_INVALIDSIGNIN"] = 6;
        values[valuesById[7] = "AUDITLOGRESULT_PERMISSIONDENIED"] = 7;
        return values;
    })();

    /**
     * AuditContextType enum.
     * @name cat.AuditContextType
     * @enum {number}
     * @property {number} AUDITCONTEXTTYPE_UNKNOWN=0 AUDITCONTEXTTYPE_UNKNOWN value
     * @property {number} AUDITCONTEXTTYPE_REQUEST=1 AUDITCONTEXTTYPE_REQUEST value
     * @property {number} AUDITCONTEXTTYPE_RESPONSE=2 AUDITCONTEXTTYPE_RESPONSE value
     */
    cat.AuditContextType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AUDITCONTEXTTYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "AUDITCONTEXTTYPE_REQUEST"] = 1;
        values[valuesById[2] = "AUDITCONTEXTTYPE_RESPONSE"] = 2;
        return values;
    })();

    /**
     * AuditValueType enum.
     * @name cat.AuditValueType
     * @enum {number}
     * @property {number} AUDITVALUETYPE_UNKNOWN=0 AUDITVALUETYPE_UNKNOWN value
     * @property {number} AUDITVALUETYPE_ACCOUNT=1 AUDITVALUETYPE_ACCOUNT value
     * @property {number} AUDITVALUETYPE_ANNOTATION=2 AUDITVALUETYPE_ANNOTATION value
     * @property {number} AUDITVALUETYPE_ATTACHMENT=3 AUDITVALUETYPE_ATTACHMENT value
     * @property {number} AUDITVALUETYPE_CASE=4 AUDITVALUETYPE_CASE value
     * @property {number} AUDITVALUETYPE_CONTACT=5 AUDITVALUETYPE_CONTACT value
     * @property {number} AUDITVALUETYPE_CONVERSATION=6 AUDITVALUETYPE_CONVERSATION value
     * @property {number} AUDITVALUETYPE_FILE=7 AUDITVALUETYPE_FILE value
     * @property {number} AUDITVALUETYPE_LABEL=8 AUDITVALUETYPE_LABEL value
     * @property {number} AUDITVALUETYPE_MESSAGE=9 AUDITVALUETYPE_MESSAGE value
     * @property {number} AUDITVALUETYPE_PROFILE=10 AUDITVALUETYPE_PROFILE value
     * @property {number} AUDITVALUETYPE_REPORT=11 AUDITVALUETYPE_REPORT value
     * @property {number} AUDITVALUETYPE_REPORTTEMPLATE=12 AUDITVALUETYPE_REPORTTEMPLATE value
     * @property {number} AUDITVALUETYPE_SERVER=13 AUDITVALUETYPE_SERVER value
     * @property {number} AUDITVALUETYPE_TOPIC=14 AUDITVALUETYPE_TOPIC value
     * @property {number} AUDITVALUETYPE_USER=15 AUDITVALUETYPE_USER value
     * @property {number} AUDITVALUETYPE_DEVICE=16 AUDITVALUETYPE_DEVICE value
     * @property {number} AUDITVALUETYPE_ROLE=17 AUDITVALUETYPE_ROLE value
     * @property {number} AUDITVALUETYPE_PERMISSION=18 AUDITVALUETYPE_PERMISSION value
     * @property {number} AUDITVALUETYPE_THEME=19 AUDITVALUETYPE_THEME value
     * @property {number} AUDITVALUETYPE_APK=21 AUDITVALUETYPE_APK value
     * @property {number} AUDITVALUETYPE_STATUS=22 AUDITVALUETYPE_STATUS value
     * @property {number} AUDITVALUETYPE_LICENSE=23 AUDITVALUETYPE_LICENSE value
     * @property {number} AUDITVALUETYPE_CAPTURING=24 AUDITVALUETYPE_CAPTURING value
     * @property {number} AUDITVALUETYPE_AUDIT=25 AUDITVALUETYPE_AUDIT value
     * @property {number} AUDITVALUETYPE_LANGUAGE=26 AUDITVALUETYPE_LANGUAGE value
     * @property {number} AUDITVALUETYPE_COUNT=27 AUDITVALUETYPE_COUNT value
     * @property {number} AUDITVALUETYPE_TERMINOLOGY=28 AUDITVALUETYPE_TERMINOLOGY value
     * @property {number} AUDITVALUETYPE_SERVICE=29 AUDITVALUETYPE_SERVICE value
     * @property {number} AUDITVALUETYPE_LOGLEVEL=30 AUDITVALUETYPE_LOGLEVEL value
     * @property {number} AUDITVALUETYPE_USERSESSION=31 AUDITVALUETYPE_USERSESSION value
     * @property {number} AUDITVALUETYPE_APPTYPE=32 AUDITVALUETYPE_APPTYPE value
     * @property {number} AUDITVALUETYPE_APP=33 AUDITVALUETYPE_APP value
     * @property {number} AUDITVALUETYPE_REACTION=34 AUDITVALUETYPE_REACTION value
     * @property {number} AUDITVALUETYPE_DATE=35 AUDITVALUETYPE_DATE value
     * @property {number} AUDITVALUETYPE_SYSTEMEVENT=36 AUDITVALUETYPE_SYSTEMEVENT value
     * @property {number} AUDITVALUETYPE_DOMAIN=37 AUDITVALUETYPE_DOMAIN value
     * @property {number} AUDITVALUETYPE_TRIGGER=38 AUDITVALUETYPE_TRIGGER value
     * @property {number} AUDITVALUETYPE_SYSTEMSETTING=39 AUDITVALUETYPE_SYSTEMSETTING value
     * @property {number} AUDITVALUETYPE_SERVICESTATUS=40 AUDITVALUETYPE_SERVICESTATUS value
     * @property {number} AUDITVALUETYPE_EULA=41 AUDITVALUETYPE_EULA value
     * @property {number} AUDITVALUETYPE_BACKUP=42 AUDITVALUETYPE_BACKUP value
     * @property {number} AUDITVALUETYPE_TRIGGERACTION=43 AUDITVALUETYPE_TRIGGERACTION value
     * @property {number} AUDITVALUETYPE_PASSWORDPOLICY=44 AUDITVALUETYPE_PASSWORDPOLICY value
     */
    cat.AuditValueType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AUDITVALUETYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "AUDITVALUETYPE_ACCOUNT"] = 1;
        values[valuesById[2] = "AUDITVALUETYPE_ANNOTATION"] = 2;
        values[valuesById[3] = "AUDITVALUETYPE_ATTACHMENT"] = 3;
        values[valuesById[4] = "AUDITVALUETYPE_CASE"] = 4;
        values[valuesById[5] = "AUDITVALUETYPE_CONTACT"] = 5;
        values[valuesById[6] = "AUDITVALUETYPE_CONVERSATION"] = 6;
        values[valuesById[7] = "AUDITVALUETYPE_FILE"] = 7;
        values[valuesById[8] = "AUDITVALUETYPE_LABEL"] = 8;
        values[valuesById[9] = "AUDITVALUETYPE_MESSAGE"] = 9;
        values[valuesById[10] = "AUDITVALUETYPE_PROFILE"] = 10;
        values[valuesById[11] = "AUDITVALUETYPE_REPORT"] = 11;
        values[valuesById[12] = "AUDITVALUETYPE_REPORTTEMPLATE"] = 12;
        values[valuesById[13] = "AUDITVALUETYPE_SERVER"] = 13;
        values[valuesById[14] = "AUDITVALUETYPE_TOPIC"] = 14;
        values[valuesById[15] = "AUDITVALUETYPE_USER"] = 15;
        values[valuesById[16] = "AUDITVALUETYPE_DEVICE"] = 16;
        values[valuesById[17] = "AUDITVALUETYPE_ROLE"] = 17;
        values[valuesById[18] = "AUDITVALUETYPE_PERMISSION"] = 18;
        values[valuesById[19] = "AUDITVALUETYPE_THEME"] = 19;
        values[valuesById[21] = "AUDITVALUETYPE_APK"] = 21;
        values[valuesById[22] = "AUDITVALUETYPE_STATUS"] = 22;
        values[valuesById[23] = "AUDITVALUETYPE_LICENSE"] = 23;
        values[valuesById[24] = "AUDITVALUETYPE_CAPTURING"] = 24;
        values[valuesById[25] = "AUDITVALUETYPE_AUDIT"] = 25;
        values[valuesById[26] = "AUDITVALUETYPE_LANGUAGE"] = 26;
        values[valuesById[27] = "AUDITVALUETYPE_COUNT"] = 27;
        values[valuesById[28] = "AUDITVALUETYPE_TERMINOLOGY"] = 28;
        values[valuesById[29] = "AUDITVALUETYPE_SERVICE"] = 29;
        values[valuesById[30] = "AUDITVALUETYPE_LOGLEVEL"] = 30;
        values[valuesById[31] = "AUDITVALUETYPE_USERSESSION"] = 31;
        values[valuesById[32] = "AUDITVALUETYPE_APPTYPE"] = 32;
        values[valuesById[33] = "AUDITVALUETYPE_APP"] = 33;
        values[valuesById[34] = "AUDITVALUETYPE_REACTION"] = 34;
        values[valuesById[35] = "AUDITVALUETYPE_DATE"] = 35;
        values[valuesById[36] = "AUDITVALUETYPE_SYSTEMEVENT"] = 36;
        values[valuesById[37] = "AUDITVALUETYPE_DOMAIN"] = 37;
        values[valuesById[38] = "AUDITVALUETYPE_TRIGGER"] = 38;
        values[valuesById[39] = "AUDITVALUETYPE_SYSTEMSETTING"] = 39;
        values[valuesById[40] = "AUDITVALUETYPE_SERVICESTATUS"] = 40;
        values[valuesById[41] = "AUDITVALUETYPE_EULA"] = 41;
        values[valuesById[42] = "AUDITVALUETYPE_BACKUP"] = 42;
        values[valuesById[43] = "AUDITVALUETYPE_TRIGGERACTION"] = 43;
        values[valuesById[44] = "AUDITVALUETYPE_PASSWORDPOLICY"] = 44;
        return values;
    })();

    cat.AuditLogMsg = (function() {

        /**
         * Properties of an AuditLogMsg.
         * @memberof cat
         * @interface IAuditLogMsg
         * @property {string|null} [id] AuditLogMsg id
         * @property {cat.AuditLogType|null} [type] AuditLogMsg type
         * @property {number|null} [timestamp] AuditLogMsg timestamp
         * @property {string|null} [isotimestamp] AuditLogMsg isotimestamp
         * @property {string|null} [userid] AuditLogMsg userid
         * @property {string|null} [username] AuditLogMsg username
         * @property {string|null} [fullname] AuditLogMsg fullname
         * @property {string|null} [callname] AuditLogMsg callname
         * @property {Array.<string>|null} [request] AuditLogMsg request
         * @property {Array.<string>|null} [requestcontext] AuditLogMsg requestcontext
         * @property {cat.IQueryMsg|null} [query] AuditLogMsg query
         * @property {Array.<string>|null} [dataset] AuditLogMsg dataset
         * @property {cat.AuditLogResult|null} [result] AuditLogMsg result
         * @property {Array.<string>|null} [responsecontext] AuditLogMsg responsecontext
         * @property {cat.AuditLogSourceType|null} [sourcetype] AuditLogMsg sourcetype
         * @property {string|null} [sourcehost] AuditLogMsg sourcehost
         * @property {string|null} [description] AuditLogMsg description
         * @property {string|null} [error] AuditLogMsg error
         */

        /**
         * Constructs a new AuditLogMsg.
         * @memberof cat
         * @classdesc Represents an AuditLogMsg.
         * @implements IAuditLogMsg
         * @constructor
         * @param {cat.IAuditLogMsg=} [p] Properties to set
         */
        function AuditLogMsg(p) {
            this.request = [];
            this.requestcontext = [];
            this.dataset = [];
            this.responsecontext = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AuditLogMsg id.
         * @member {string} id
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.id = "";

        /**
         * AuditLogMsg type.
         * @member {cat.AuditLogType} type
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.type = 0;

        /**
         * AuditLogMsg timestamp.
         * @member {number} timestamp
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AuditLogMsg isotimestamp.
         * @member {string} isotimestamp
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.isotimestamp = "";

        /**
         * AuditLogMsg userid.
         * @member {string} userid
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.userid = "";

        /**
         * AuditLogMsg username.
         * @member {string} username
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.username = "";

        /**
         * AuditLogMsg fullname.
         * @member {string} fullname
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.fullname = "";

        /**
         * AuditLogMsg callname.
         * @member {string} callname
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.callname = "";

        /**
         * AuditLogMsg request.
         * @member {Array.<string>} request
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.request = $util.emptyArray;

        /**
         * AuditLogMsg requestcontext.
         * @member {Array.<string>} requestcontext
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.requestcontext = $util.emptyArray;

        /**
         * AuditLogMsg query.
         * @member {cat.IQueryMsg|null|undefined} query
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.query = null;

        /**
         * AuditLogMsg dataset.
         * @member {Array.<string>} dataset
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.dataset = $util.emptyArray;

        /**
         * AuditLogMsg result.
         * @member {cat.AuditLogResult} result
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.result = 0;

        /**
         * AuditLogMsg responsecontext.
         * @member {Array.<string>} responsecontext
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.responsecontext = $util.emptyArray;

        /**
         * AuditLogMsg sourcetype.
         * @member {cat.AuditLogSourceType} sourcetype
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.sourcetype = 0;

        /**
         * AuditLogMsg sourcehost.
         * @member {string} sourcehost
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.sourcehost = "";

        /**
         * AuditLogMsg description.
         * @member {string} description
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.description = "";

        /**
         * AuditLogMsg error.
         * @member {string} error
         * @memberof cat.AuditLogMsg
         * @instance
         */
        AuditLogMsg.prototype.error = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AuditLogMsg.prototype, "_query", {
            get: $util.oneOfGetter($oneOfFields = ["query"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AuditLogMsg instance using the specified properties.
         * @function create
         * @memberof cat.AuditLogMsg
         * @static
         * @param {cat.IAuditLogMsg=} [properties] Properties to set
         * @returns {cat.AuditLogMsg} AuditLogMsg instance
         */
        AuditLogMsg.create = function create(properties) {
            return new AuditLogMsg(properties);
        };

        /**
         * Encodes the specified AuditLogMsg message. Does not implicitly {@link cat.AuditLogMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.AuditLogMsg
         * @static
         * @param {cat.IAuditLogMsg} m AuditLogMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuditLogMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                w.uint32(24).uint64(m.timestamp);
            if (m.isotimestamp != null && Object.hasOwnProperty.call(m, "isotimestamp"))
                w.uint32(34).string(m.isotimestamp);
            if (m.userid != null && Object.hasOwnProperty.call(m, "userid"))
                w.uint32(42).string(m.userid);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(50).string(m.username);
            if (m.fullname != null && Object.hasOwnProperty.call(m, "fullname"))
                w.uint32(58).string(m.fullname);
            if (m.callname != null && Object.hasOwnProperty.call(m, "callname"))
                w.uint32(66).string(m.callname);
            if (m.request != null && m.request.length) {
                for (var i = 0; i < m.request.length; ++i)
                    w.uint32(74).string(m.request[i]);
            }
            if (m.requestcontext != null && m.requestcontext.length) {
                for (var i = 0; i < m.requestcontext.length; ++i)
                    w.uint32(82).string(m.requestcontext[i]);
            }
            if (m.query != null && Object.hasOwnProperty.call(m, "query"))
                $root.cat.QueryMsg.encode(m.query, w.uint32(90).fork()).ldelim();
            if (m.dataset != null && m.dataset.length) {
                for (var i = 0; i < m.dataset.length; ++i)
                    w.uint32(98).string(m.dataset[i]);
            }
            if (m.result != null && Object.hasOwnProperty.call(m, "result"))
                w.uint32(104).int32(m.result);
            if (m.responsecontext != null && m.responsecontext.length) {
                for (var i = 0; i < m.responsecontext.length; ++i)
                    w.uint32(114).string(m.responsecontext[i]);
            }
            if (m.sourcetype != null && Object.hasOwnProperty.call(m, "sourcetype"))
                w.uint32(120).int32(m.sourcetype);
            if (m.sourcehost != null && Object.hasOwnProperty.call(m, "sourcehost"))
                w.uint32(130).string(m.sourcehost);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(138).string(m.description);
            if (m.error != null && Object.hasOwnProperty.call(m, "error"))
                w.uint32(146).string(m.error);
            return w;
        };

        /**
         * Decodes an AuditLogMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.AuditLogMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.AuditLogMsg} AuditLogMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuditLogMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.AuditLogMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.type = r.int32();
                        break;
                    }
                case 3: {
                        m.timestamp = r.uint64();
                        break;
                    }
                case 4: {
                        m.isotimestamp = r.string();
                        break;
                    }
                case 5: {
                        m.userid = r.string();
                        break;
                    }
                case 6: {
                        m.username = r.string();
                        break;
                    }
                case 7: {
                        m.fullname = r.string();
                        break;
                    }
                case 8: {
                        m.callname = r.string();
                        break;
                    }
                case 9: {
                        if (!(m.request && m.request.length))
                            m.request = [];
                        m.request.push(r.string());
                        break;
                    }
                case 10: {
                        if (!(m.requestcontext && m.requestcontext.length))
                            m.requestcontext = [];
                        m.requestcontext.push(r.string());
                        break;
                    }
                case 11: {
                        m.query = $root.cat.QueryMsg.decode(r, r.uint32());
                        break;
                    }
                case 12: {
                        if (!(m.dataset && m.dataset.length))
                            m.dataset = [];
                        m.dataset.push(r.string());
                        break;
                    }
                case 13: {
                        m.result = r.int32();
                        break;
                    }
                case 14: {
                        if (!(m.responsecontext && m.responsecontext.length))
                            m.responsecontext = [];
                        m.responsecontext.push(r.string());
                        break;
                    }
                case 15: {
                        m.sourcetype = r.int32();
                        break;
                    }
                case 16: {
                        m.sourcehost = r.string();
                        break;
                    }
                case 17: {
                        m.description = r.string();
                        break;
                    }
                case 18: {
                        m.error = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AuditLogMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.AuditLogMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.AuditLogMsg} AuditLogMsg
         */
        AuditLogMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.AuditLogMsg)
                return d;
            var m = new $root.cat.AuditLogMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "AUDITLOG_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "AUDITLOG_SYSTEM":
            case 1:
                m.type = 1;
                break;
            case "AUDITLOG_USER":
            case 2:
                m.type = 2;
                break;
            }
            if (d.timestamp != null) {
                if ($util.Long)
                    (m.timestamp = $util.Long.fromValue(d.timestamp)).unsigned = true;
                else if (typeof d.timestamp === "string")
                    m.timestamp = parseInt(d.timestamp, 10);
                else if (typeof d.timestamp === "number")
                    m.timestamp = d.timestamp;
                else if (typeof d.timestamp === "object")
                    m.timestamp = new $util.LongBits(d.timestamp.low >>> 0, d.timestamp.high >>> 0).toNumber(true);
            }
            if (d.isotimestamp != null) {
                m.isotimestamp = String(d.isotimestamp);
            }
            if (d.userid != null) {
                m.userid = String(d.userid);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.fullname != null) {
                m.fullname = String(d.fullname);
            }
            if (d.callname != null) {
                m.callname = String(d.callname);
            }
            if (d.request) {
                if (!Array.isArray(d.request))
                    throw TypeError(".cat.AuditLogMsg.request: array expected");
                m.request = [];
                for (var i = 0; i < d.request.length; ++i) {
                    m.request[i] = String(d.request[i]);
                }
            }
            if (d.requestcontext) {
                if (!Array.isArray(d.requestcontext))
                    throw TypeError(".cat.AuditLogMsg.requestcontext: array expected");
                m.requestcontext = [];
                for (var i = 0; i < d.requestcontext.length; ++i) {
                    m.requestcontext[i] = String(d.requestcontext[i]);
                }
            }
            if (d.query != null) {
                if (typeof d.query !== "object")
                    throw TypeError(".cat.AuditLogMsg.query: object expected");
                m.query = $root.cat.QueryMsg.fromObject(d.query);
            }
            if (d.dataset) {
                if (!Array.isArray(d.dataset))
                    throw TypeError(".cat.AuditLogMsg.dataset: array expected");
                m.dataset = [];
                for (var i = 0; i < d.dataset.length; ++i) {
                    m.dataset[i] = String(d.dataset[i]);
                }
            }
            switch (d.result) {
            default:
                if (typeof d.result === "number") {
                    m.result = d.result;
                    break;
                }
                break;
            case "AUDITLOGRESULT_UNKNOWN":
            case 0:
                m.result = 0;
                break;
            case "AUDITLOGRESULT_SUCCESS":
            case 1:
                m.result = 1;
                break;
            case "AUDITLOGRESULT_FAILED":
            case 2:
                m.result = 2;
                break;
            case "AUDITLOGRESULT_UNAUTHENTICATED":
            case 3:
                m.result = 3;
                break;
            case "AUDITLOGRESULT_INVALIDARGUMENT":
            case 5:
                m.result = 5;
                break;
            case "AUDITLOGRESULT_INVALIDSIGNIN":
            case 6:
                m.result = 6;
                break;
            case "AUDITLOGRESULT_PERMISSIONDENIED":
            case 7:
                m.result = 7;
                break;
            }
            if (d.responsecontext) {
                if (!Array.isArray(d.responsecontext))
                    throw TypeError(".cat.AuditLogMsg.responsecontext: array expected");
                m.responsecontext = [];
                for (var i = 0; i < d.responsecontext.length; ++i) {
                    m.responsecontext[i] = String(d.responsecontext[i]);
                }
            }
            switch (d.sourcetype) {
            default:
                if (typeof d.sourcetype === "number") {
                    m.sourcetype = d.sourcetype;
                    break;
                }
                break;
            case "AUDITLOGSOURCETYPE_UNKNOWN":
            case 0:
                m.sourcetype = 0;
                break;
            case "AUDITLOGSOURCETYPE_SERVER":
            case 1:
                m.sourcetype = 1;
                break;
            case "AUDITLOGSOURCETYPE_BROWSER":
            case 2:
                m.sourcetype = 2;
                break;
            case "AUDITLOGSOURCETYPE_CAPTURE":
            case 3:
                m.sourcetype = 3;
                break;
            }
            if (d.sourcehost != null) {
                m.sourcehost = String(d.sourcehost);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.error != null) {
                m.error = String(d.error);
            }
            return m;
        };

        /**
         * Creates a plain object from an AuditLogMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.AuditLogMsg
         * @static
         * @param {cat.AuditLogMsg} m AuditLogMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuditLogMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.request = [];
                d.requestcontext = [];
                d.dataset = [];
                d.responsecontext = [];
            }
            if (o.defaults) {
                d.id = "";
                d.type = o.enums === String ? "AUDITLOG_UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timestamp = o.longs === String ? "0" : 0;
                d.isotimestamp = "";
                d.userid = "";
                d.username = "";
                d.fullname = "";
                d.callname = "";
                d.result = o.enums === String ? "AUDITLOGRESULT_UNKNOWN" : 0;
                d.sourcetype = o.enums === String ? "AUDITLOGSOURCETYPE_UNKNOWN" : 0;
                d.sourcehost = "";
                d.description = "";
                d.error = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.AuditLogType[m.type] === undefined ? m.type : $root.cat.AuditLogType[m.type] : m.type;
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                if (typeof m.timestamp === "number")
                    d.timestamp = o.longs === String ? String(m.timestamp) : m.timestamp;
                else
                    d.timestamp = o.longs === String ? $util.Long.prototype.toString.call(m.timestamp) : o.longs === Number ? new $util.LongBits(m.timestamp.low >>> 0, m.timestamp.high >>> 0).toNumber(true) : m.timestamp;
            }
            if (m.isotimestamp != null && m.hasOwnProperty("isotimestamp")) {
                d.isotimestamp = m.isotimestamp;
            }
            if (m.userid != null && m.hasOwnProperty("userid")) {
                d.userid = m.userid;
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
            }
            if (m.fullname != null && m.hasOwnProperty("fullname")) {
                d.fullname = m.fullname;
            }
            if (m.callname != null && m.hasOwnProperty("callname")) {
                d.callname = m.callname;
            }
            if (m.request && m.request.length) {
                d.request = [];
                for (var j = 0; j < m.request.length; ++j) {
                    d.request[j] = m.request[j];
                }
            }
            if (m.requestcontext && m.requestcontext.length) {
                d.requestcontext = [];
                for (var j = 0; j < m.requestcontext.length; ++j) {
                    d.requestcontext[j] = m.requestcontext[j];
                }
            }
            if (m.query != null && m.hasOwnProperty("query")) {
                d.query = $root.cat.QueryMsg.toObject(m.query, o);
                if (o.oneofs)
                    d._query = "query";
            }
            if (m.dataset && m.dataset.length) {
                d.dataset = [];
                for (var j = 0; j < m.dataset.length; ++j) {
                    d.dataset[j] = m.dataset[j];
                }
            }
            if (m.result != null && m.hasOwnProperty("result")) {
                d.result = o.enums === String ? $root.cat.AuditLogResult[m.result] === undefined ? m.result : $root.cat.AuditLogResult[m.result] : m.result;
            }
            if (m.responsecontext && m.responsecontext.length) {
                d.responsecontext = [];
                for (var j = 0; j < m.responsecontext.length; ++j) {
                    d.responsecontext[j] = m.responsecontext[j];
                }
            }
            if (m.sourcetype != null && m.hasOwnProperty("sourcetype")) {
                d.sourcetype = o.enums === String ? $root.cat.AuditLogSourceType[m.sourcetype] === undefined ? m.sourcetype : $root.cat.AuditLogSourceType[m.sourcetype] : m.sourcetype;
            }
            if (m.sourcehost != null && m.hasOwnProperty("sourcehost")) {
                d.sourcehost = m.sourcehost;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.error != null && m.hasOwnProperty("error")) {
                d.error = m.error;
            }
            return d;
        };

        /**
         * Converts this AuditLogMsg to JSON.
         * @function toJSON
         * @memberof cat.AuditLogMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuditLogMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuditLogMsg
         * @function getTypeUrl
         * @memberof cat.AuditLogMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuditLogMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.AuditLogMsg";
        };

        return AuditLogMsg;
    })();

    cat.AuditLogCallMsg = (function() {

        /**
         * Properties of an AuditLogCallMsg.
         * @memberof cat
         * @interface IAuditLogCallMsg
         * @property {string|null} [id] AuditLogCallMsg id
         * @property {string|null} [callname] AuditLogCallMsg callname
         * @property {Array.<string>|null} [dataset] AuditLogCallMsg dataset
         * @property {string|null} [description] AuditLogCallMsg description
         */

        /**
         * Constructs a new AuditLogCallMsg.
         * @memberof cat
         * @classdesc Represents an AuditLogCallMsg.
         * @implements IAuditLogCallMsg
         * @constructor
         * @param {cat.IAuditLogCallMsg=} [p] Properties to set
         */
        function AuditLogCallMsg(p) {
            this.dataset = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AuditLogCallMsg id.
         * @member {string} id
         * @memberof cat.AuditLogCallMsg
         * @instance
         */
        AuditLogCallMsg.prototype.id = "";

        /**
         * AuditLogCallMsg callname.
         * @member {string} callname
         * @memberof cat.AuditLogCallMsg
         * @instance
         */
        AuditLogCallMsg.prototype.callname = "";

        /**
         * AuditLogCallMsg dataset.
         * @member {Array.<string>} dataset
         * @memberof cat.AuditLogCallMsg
         * @instance
         */
        AuditLogCallMsg.prototype.dataset = $util.emptyArray;

        /**
         * AuditLogCallMsg description.
         * @member {string} description
         * @memberof cat.AuditLogCallMsg
         * @instance
         */
        AuditLogCallMsg.prototype.description = "";

        /**
         * Creates a new AuditLogCallMsg instance using the specified properties.
         * @function create
         * @memberof cat.AuditLogCallMsg
         * @static
         * @param {cat.IAuditLogCallMsg=} [properties] Properties to set
         * @returns {cat.AuditLogCallMsg} AuditLogCallMsg instance
         */
        AuditLogCallMsg.create = function create(properties) {
            return new AuditLogCallMsg(properties);
        };

        /**
         * Encodes the specified AuditLogCallMsg message. Does not implicitly {@link cat.AuditLogCallMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.AuditLogCallMsg
         * @static
         * @param {cat.IAuditLogCallMsg} m AuditLogCallMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuditLogCallMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.callname != null && Object.hasOwnProperty.call(m, "callname"))
                w.uint32(18).string(m.callname);
            if (m.dataset != null && m.dataset.length) {
                for (var i = 0; i < m.dataset.length; ++i)
                    w.uint32(26).string(m.dataset[i]);
            }
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            return w;
        };

        /**
         * Decodes an AuditLogCallMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.AuditLogCallMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.AuditLogCallMsg} AuditLogCallMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuditLogCallMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.AuditLogCallMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.callname = r.string();
                        break;
                    }
                case 3: {
                        if (!(m.dataset && m.dataset.length))
                            m.dataset = [];
                        m.dataset.push(r.string());
                        break;
                    }
                case 4: {
                        m.description = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AuditLogCallMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.AuditLogCallMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.AuditLogCallMsg} AuditLogCallMsg
         */
        AuditLogCallMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.AuditLogCallMsg)
                return d;
            var m = new $root.cat.AuditLogCallMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.callname != null) {
                m.callname = String(d.callname);
            }
            if (d.dataset) {
                if (!Array.isArray(d.dataset))
                    throw TypeError(".cat.AuditLogCallMsg.dataset: array expected");
                m.dataset = [];
                for (var i = 0; i < d.dataset.length; ++i) {
                    m.dataset[i] = String(d.dataset[i]);
                }
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            return m;
        };

        /**
         * Creates a plain object from an AuditLogCallMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.AuditLogCallMsg
         * @static
         * @param {cat.AuditLogCallMsg} m AuditLogCallMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuditLogCallMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.dataset = [];
            }
            if (o.defaults) {
                d.id = "";
                d.callname = "";
                d.description = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.callname != null && m.hasOwnProperty("callname")) {
                d.callname = m.callname;
            }
            if (m.dataset && m.dataset.length) {
                d.dataset = [];
                for (var j = 0; j < m.dataset.length; ++j) {
                    d.dataset[j] = m.dataset[j];
                }
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            return d;
        };

        /**
         * Converts this AuditLogCallMsg to JSON.
         * @function toJSON
         * @memberof cat.AuditLogCallMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuditLogCallMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AuditLogCallMsg
         * @function getTypeUrl
         * @memberof cat.AuditLogCallMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AuditLogCallMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.AuditLogCallMsg";
        };

        return AuditLogCallMsg;
    })();

    /**
     * ReferenceType enum.
     * @name cat.ReferenceType
     * @enum {number}
     * @property {number} REFERENCE_CUSTOM=0 REFERENCE_CUSTOM value
     * @property {number} REFERENCE_USER_ID=1 REFERENCE_USER_ID value
     * @property {number} REFERENCE_PROFILE_ID=2 REFERENCE_PROFILE_ID value
     * @property {number} REFERENCE_DEVICE_ID=3 REFERENCE_DEVICE_ID value
     * @property {number} REFERENCE_APP_ID=4 REFERENCE_APP_ID value
     * @property {number} REFERENCE_ACCOUNT_ID=5 REFERENCE_ACCOUNT_ID value
     * @property {number} REFERENCE_CONVERSATION_ID=6 REFERENCE_CONVERSATION_ID value
     * @property {number} REFERENCE_MESSAGE_ID=7 REFERENCE_MESSAGE_ID value
     * @property {number} REFERENCE_ATTACHMENT_ID=8 REFERENCE_ATTACHMENT_ID value
     * @property {number} REFERENCE_TAG_ID=9 REFERENCE_TAG_ID value
     * @property {number} REFERENCE_MESSAGE_LABEL_ID=10 REFERENCE_MESSAGE_LABEL_ID value
     * @property {number} REFERENCE_MESSAGE_ANNOTATION_ID=11 REFERENCE_MESSAGE_ANNOTATION_ID value
     * @property {number} REFERENCE_CONTACT_ID=12 REFERENCE_CONTACT_ID value
     * @property {number} REFERENCE_TRIGGER_ID=13 REFERENCE_TRIGGER_ID value
     * @property {number} REFERENCE_TRIGGERACTION_ID=14 REFERENCE_TRIGGERACTION_ID value
     * @property {number} REFERENCE_CASE_ID=15 REFERENCE_CASE_ID value
     * @property {number} REFERENCE_REPORT_ID=16 REFERENCE_REPORT_ID value
     * @property {number} REFERENCE_THEME_ID=17 REFERENCE_THEME_ID value
     * @property {number} REFERENCE_APPTYPE_ID=18 REFERENCE_APPTYPE_ID value
     * @property {number} REFERENCE_CONVERSATION_LABEL_ID=20 REFERENCE_CONVERSATION_LABEL_ID value
     * @property {number} REFERENCE_CONVERSATION_ANNOTATION_ID=21 REFERENCE_CONVERSATION_ANNOTATION_ID value
     * @property {number} REFERENCE_CONVERSATION_EXTERN_ID=22 REFERENCE_CONVERSATION_EXTERN_ID value
     * @property {number} REFERENCE_CONTACT_LABEL_ID=23 REFERENCE_CONTACT_LABEL_ID value
     * @property {number} REFERENCE_CONTACT_ANNOTATION_ID=24 REFERENCE_CONTACT_ANNOTATION_ID value
     * @property {number} REFERENCE_REACTION_ID=27 REFERENCE_REACTION_ID value
     * @property {number} REFERENCE_ROLE_ID=28 REFERENCE_ROLE_ID value
     * @property {number} REFERENCE_MEDIA_ID=29 REFERENCE_MEDIA_ID value
     * @property {number} REFERENCE_LICENSE_ID=30 REFERENCE_LICENSE_ID value
     * @property {number} REFERENCE_REPORT_TEMPLATE_ID=32 REFERENCE_REPORT_TEMPLATE_ID value
     * @property {number} REFERENCE_REMOTE_USER_ID=33 REFERENCE_REMOTE_USER_ID value
     * @property {number} REFERENCE_TOPIC_ID=34 REFERENCE_TOPIC_ID value
     * @property {number} REFERENCE_TOPIC_LABEL_ID=35 REFERENCE_TOPIC_LABEL_ID value
     * @property {number} REFERENCE_TOPIC_ANNOTATION_ID=36 REFERENCE_TOPIC_ANNOTATION_ID value
     * @property {number} REFERENCE_SERVER_ID=37 REFERENCE_SERVER_ID value
     * @property {number} REFERENCE_SERVER_LABEL_ID=38 REFERENCE_SERVER_LABEL_ID value
     * @property {number} REFERENCE_SERVER_ANNOTATION_ID=39 REFERENCE_SERVER_ANNOTATION_ID value
     * @property {number} REFERENCE_EMOJI_ID=40 REFERENCE_EMOJI_ID value
     * @property {number} REFERENCE_LABEL_ID=41 REFERENCE_LABEL_ID value
     * @property {number} REFERENCE_ANNOTATION_ID=42 REFERENCE_ANNOTATION_ID value
     * @property {number} REFERENCE_FILE_ID=43 REFERENCE_FILE_ID value
     * @property {number} REFERENCE_AUDIT_ID=44 REFERENCE_AUDIT_ID value
     * @property {number} REFERENCE_DOMAIN_ID=45 REFERENCE_DOMAIN_ID value
     * @property {number} REFERENCE_APK_ID=46 REFERENCE_APK_ID value
     */
    cat.ReferenceType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REFERENCE_CUSTOM"] = 0;
        values[valuesById[1] = "REFERENCE_USER_ID"] = 1;
        values[valuesById[2] = "REFERENCE_PROFILE_ID"] = 2;
        values[valuesById[3] = "REFERENCE_DEVICE_ID"] = 3;
        values[valuesById[4] = "REFERENCE_APP_ID"] = 4;
        values[valuesById[5] = "REFERENCE_ACCOUNT_ID"] = 5;
        values[valuesById[6] = "REFERENCE_CONVERSATION_ID"] = 6;
        values[valuesById[7] = "REFERENCE_MESSAGE_ID"] = 7;
        values[valuesById[8] = "REFERENCE_ATTACHMENT_ID"] = 8;
        values[valuesById[9] = "REFERENCE_TAG_ID"] = 9;
        values[valuesById[10] = "REFERENCE_MESSAGE_LABEL_ID"] = 10;
        values[valuesById[11] = "REFERENCE_MESSAGE_ANNOTATION_ID"] = 11;
        values[valuesById[12] = "REFERENCE_CONTACT_ID"] = 12;
        values[valuesById[13] = "REFERENCE_TRIGGER_ID"] = 13;
        values[valuesById[14] = "REFERENCE_TRIGGERACTION_ID"] = 14;
        values[valuesById[15] = "REFERENCE_CASE_ID"] = 15;
        values[valuesById[16] = "REFERENCE_REPORT_ID"] = 16;
        values[valuesById[17] = "REFERENCE_THEME_ID"] = 17;
        values[valuesById[18] = "REFERENCE_APPTYPE_ID"] = 18;
        values[valuesById[20] = "REFERENCE_CONVERSATION_LABEL_ID"] = 20;
        values[valuesById[21] = "REFERENCE_CONVERSATION_ANNOTATION_ID"] = 21;
        values[valuesById[22] = "REFERENCE_CONVERSATION_EXTERN_ID"] = 22;
        values[valuesById[23] = "REFERENCE_CONTACT_LABEL_ID"] = 23;
        values[valuesById[24] = "REFERENCE_CONTACT_ANNOTATION_ID"] = 24;
        values[valuesById[27] = "REFERENCE_REACTION_ID"] = 27;
        values[valuesById[28] = "REFERENCE_ROLE_ID"] = 28;
        values[valuesById[29] = "REFERENCE_MEDIA_ID"] = 29;
        values[valuesById[30] = "REFERENCE_LICENSE_ID"] = 30;
        values[valuesById[32] = "REFERENCE_REPORT_TEMPLATE_ID"] = 32;
        values[valuesById[33] = "REFERENCE_REMOTE_USER_ID"] = 33;
        values[valuesById[34] = "REFERENCE_TOPIC_ID"] = 34;
        values[valuesById[35] = "REFERENCE_TOPIC_LABEL_ID"] = 35;
        values[valuesById[36] = "REFERENCE_TOPIC_ANNOTATION_ID"] = 36;
        values[valuesById[37] = "REFERENCE_SERVER_ID"] = 37;
        values[valuesById[38] = "REFERENCE_SERVER_LABEL_ID"] = 38;
        values[valuesById[39] = "REFERENCE_SERVER_ANNOTATION_ID"] = 39;
        values[valuesById[40] = "REFERENCE_EMOJI_ID"] = 40;
        values[valuesById[41] = "REFERENCE_LABEL_ID"] = 41;
        values[valuesById[42] = "REFERENCE_ANNOTATION_ID"] = 42;
        values[valuesById[43] = "REFERENCE_FILE_ID"] = 43;
        values[valuesById[44] = "REFERENCE_AUDIT_ID"] = 44;
        values[valuesById[45] = "REFERENCE_DOMAIN_ID"] = 45;
        values[valuesById[46] = "REFERENCE_APK_ID"] = 46;
        return values;
    })();

    /**
     * NotificationType enum.
     * @name cat.NotificationType
     * @enum {number}
     * @property {number} NOTIFICATION_UNKNOWN=0 NOTIFICATION_UNKNOWN value
     * @property {number} NOTIFICATION_WELCOME=1 NOTIFICATION_WELCOME value
     * @property {number} NOTIFICATION_TRIGGER_ALERT=2 NOTIFICATION_TRIGGER_ALERT value
     * @property {number} NOTIFICATION_MESSAGE=3 NOTIFICATION_MESSAGE value
     * @property {number} NOTIFICATION_CONVERSATION=4 NOTIFICATION_CONVERSATION value
     * @property {number} NOTIFICATION_SERVER=5 NOTIFICATION_SERVER value
     * @property {number} NOTIFICATION_TOPIC=6 NOTIFICATION_TOPIC value
     * @property {number} NOTIFICATION_ACCOUNT=7 NOTIFICATION_ACCOUNT value
     * @property {number} NOTIFICATION_CONTACT=8 NOTIFICATION_CONTACT value
     * @property {number} NOTIFICATION_PROFILE=9 NOTIFICATION_PROFILE value
     * @property {number} NOTIFICATION_DEVICE=10 NOTIFICATION_DEVICE value
     * @property {number} NOTIFICATION_CASE=11 NOTIFICATION_CASE value
     * @property {number} NOTIFICATION_APP_STATUS=21 NOTIFICATION_APP_STATUS value
     * @property {number} NOTIFICATION_ACCOUNT_STATUS=22 NOTIFICATION_ACCOUNT_STATUS value
     * @property {number} NOTIFICATION_REPORT_STATUS=23 NOTIFICATION_REPORT_STATUS value
     * @property {number} NOTIFICATION_DEVICE_STATUS=24 NOTIFICATION_DEVICE_STATUS value
     * @property {number} NOTIFICATION_APK_STATUS=25 NOTIFICATION_APK_STATUS value
     * @property {number} NOTIFICATION_USERSESSION_PERMISSIONS=31 NOTIFICATION_USERSESSION_PERMISSIONS value
     * @property {number} NOTIFICATION_USERSESSION_EXPIRES=32 NOTIFICATION_USERSESSION_EXPIRES value
     * @property {number} NOTIFICATION_USERSESSION_EXPIRED=33 NOTIFICATION_USERSESSION_EXPIRED value
     * @property {number} NOTIFICATION_USERSESSION_DISABLED=34 NOTIFICATION_USERSESSION_DISABLED value
     * @property {number} NOTIFICATION_USERSESSION_LOCK=35 NOTIFICATION_USERSESSION_LOCK value
     * @property {number} NOTIFICATION_USERSESSION_UNLOCK=36 NOTIFICATION_USERSESSION_UNLOCK value
     * @property {number} NOTIFICATION_DATA_CLEANING=40 NOTIFICATION_DATA_CLEANING value
     * @property {number} NOTIFICATION_CAPTURE_APP=41 NOTIFICATION_CAPTURE_APP value
     * @property {number} NOTIFICATION_FILE=42 NOTIFICATION_FILE value
     */
    cat.NotificationType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NOTIFICATION_UNKNOWN"] = 0;
        values[valuesById[1] = "NOTIFICATION_WELCOME"] = 1;
        values[valuesById[2] = "NOTIFICATION_TRIGGER_ALERT"] = 2;
        values[valuesById[3] = "NOTIFICATION_MESSAGE"] = 3;
        values[valuesById[4] = "NOTIFICATION_CONVERSATION"] = 4;
        values[valuesById[5] = "NOTIFICATION_SERVER"] = 5;
        values[valuesById[6] = "NOTIFICATION_TOPIC"] = 6;
        values[valuesById[7] = "NOTIFICATION_ACCOUNT"] = 7;
        values[valuesById[8] = "NOTIFICATION_CONTACT"] = 8;
        values[valuesById[9] = "NOTIFICATION_PROFILE"] = 9;
        values[valuesById[10] = "NOTIFICATION_DEVICE"] = 10;
        values[valuesById[11] = "NOTIFICATION_CASE"] = 11;
        values[valuesById[21] = "NOTIFICATION_APP_STATUS"] = 21;
        values[valuesById[22] = "NOTIFICATION_ACCOUNT_STATUS"] = 22;
        values[valuesById[23] = "NOTIFICATION_REPORT_STATUS"] = 23;
        values[valuesById[24] = "NOTIFICATION_DEVICE_STATUS"] = 24;
        values[valuesById[25] = "NOTIFICATION_APK_STATUS"] = 25;
        values[valuesById[31] = "NOTIFICATION_USERSESSION_PERMISSIONS"] = 31;
        values[valuesById[32] = "NOTIFICATION_USERSESSION_EXPIRES"] = 32;
        values[valuesById[33] = "NOTIFICATION_USERSESSION_EXPIRED"] = 33;
        values[valuesById[34] = "NOTIFICATION_USERSESSION_DISABLED"] = 34;
        values[valuesById[35] = "NOTIFICATION_USERSESSION_LOCK"] = 35;
        values[valuesById[36] = "NOTIFICATION_USERSESSION_UNLOCK"] = 36;
        values[valuesById[40] = "NOTIFICATION_DATA_CLEANING"] = 40;
        values[valuesById[41] = "NOTIFICATION_CAPTURE_APP"] = 41;
        values[valuesById[42] = "NOTIFICATION_FILE"] = 42;
        return values;
    })();

    /**
     * NotificationPriority enum.
     * @name cat.NotificationPriority
     * @enum {number}
     * @property {number} NOTIFICATIONPRIORITY_NORMAL=0 NOTIFICATIONPRIORITY_NORMAL value
     * @property {number} NOTIFICATIONPRIORITY_HIGH=1 NOTIFICATIONPRIORITY_HIGH value
     */
    cat.NotificationPriority = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NOTIFICATIONPRIORITY_NORMAL"] = 0;
        values[valuesById[1] = "NOTIFICATIONPRIORITY_HIGH"] = 1;
        return values;
    })();

    cat.NotificationMsg = (function() {

        /**
         * Properties of a NotificationMsg.
         * @memberof cat
         * @interface INotificationMsg
         * @property {string|null} [id] NotificationMsg id
         * @property {cat.NotificationPriority|null} [priority] NotificationMsg priority
         * @property {cat.NotificationType|null} [type] NotificationMsg type
         * @property {Object.<string,string>|null} [references] NotificationMsg references
         * @property {string|null} [value] NotificationMsg value
         * @property {number|null} [created] NotificationMsg created
         * @property {number|null} [lastmodified] NotificationMsg lastmodified
         * @property {number|null} [read] NotificationMsg read
         * @property {cat.IUserMsg|null} [user] NotificationMsg user
         * @property {cat.IReportStatusMsg|null} [reportstatus] NotificationMsg reportstatus
         * @property {Array.<cat.IReceiverBufferStatusMsg>|null} [receiverbufferstatus] NotificationMsg receiverbufferstatus
         */

        /**
         * Constructs a new NotificationMsg.
         * @memberof cat
         * @classdesc Represents a NotificationMsg.
         * @implements INotificationMsg
         * @constructor
         * @param {cat.INotificationMsg=} [p] Properties to set
         */
        function NotificationMsg(p) {
            this.references = {};
            this.receiverbufferstatus = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NotificationMsg id.
         * @member {string} id
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.id = "";

        /**
         * NotificationMsg priority.
         * @member {cat.NotificationPriority} priority
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.priority = 0;

        /**
         * NotificationMsg type.
         * @member {cat.NotificationType} type
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.type = 0;

        /**
         * NotificationMsg references.
         * @member {Object.<string,string>} references
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.references = $util.emptyObject;

        /**
         * NotificationMsg value.
         * @member {string} value
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.value = "";

        /**
         * NotificationMsg created.
         * @member {number} created
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * NotificationMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * NotificationMsg read.
         * @member {number} read
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.read = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * NotificationMsg user.
         * @member {cat.IUserMsg|null|undefined} user
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.user = null;

        /**
         * NotificationMsg reportstatus.
         * @member {cat.IReportStatusMsg|null|undefined} reportstatus
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.reportstatus = null;

        /**
         * NotificationMsg receiverbufferstatus.
         * @member {Array.<cat.IReceiverBufferStatusMsg>} receiverbufferstatus
         * @memberof cat.NotificationMsg
         * @instance
         */
        NotificationMsg.prototype.receiverbufferstatus = $util.emptyArray;

        /**
         * Creates a new NotificationMsg instance using the specified properties.
         * @function create
         * @memberof cat.NotificationMsg
         * @static
         * @param {cat.INotificationMsg=} [properties] Properties to set
         * @returns {cat.NotificationMsg} NotificationMsg instance
         */
        NotificationMsg.create = function create(properties) {
            return new NotificationMsg(properties);
        };

        /**
         * Encodes the specified NotificationMsg message. Does not implicitly {@link cat.NotificationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.NotificationMsg
         * @static
         * @param {cat.INotificationMsg} m NotificationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.priority != null && Object.hasOwnProperty.call(m, "priority"))
                w.uint32(24).int32(m.priority);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(32).int32(m.type);
            if (m.references != null && Object.hasOwnProperty.call(m, "references")) {
                for (var ks = Object.keys(m.references), i = 0; i < ks.length; ++i) {
                    w.uint32(42).fork().uint32(8).uint32(ks[i]).uint32(18).string(m.references[ks[i]]).ldelim();
                }
            }
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(50).string(m.value);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(56).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(64).uint64(m.lastmodified);
            if (m.read != null && Object.hasOwnProperty.call(m, "read"))
                w.uint32(72).uint64(m.read);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.cat.UserMsg.encode(m.user, w.uint32(810).fork()).ldelim();
            if (m.reportstatus != null && Object.hasOwnProperty.call(m, "reportstatus"))
                $root.cat.ReportStatusMsg.encode(m.reportstatus, w.uint32(818).fork()).ldelim();
            if (m.receiverbufferstatus != null && m.receiverbufferstatus.length) {
                for (var i = 0; i < m.receiverbufferstatus.length; ++i)
                    $root.cat.ReceiverBufferStatusMsg.encode(m.receiverbufferstatus[i], w.uint32(826).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a NotificationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.NotificationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.NotificationMsg} NotificationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.NotificationMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 3: {
                        m.priority = r.int32();
                        break;
                    }
                case 4: {
                        m.type = r.int32();
                        break;
                    }
                case 5: {
                        if (m.references === $util.emptyObject)
                            m.references = {};
                        var c2 = r.uint32() + r.pos;
                        k = 0;
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.uint32();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.references[k] = value;
                        break;
                    }
                case 6: {
                        m.value = r.string();
                        break;
                    }
                case 7: {
                        m.created = r.uint64();
                        break;
                    }
                case 8: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 9: {
                        m.read = r.uint64();
                        break;
                    }
                case 101: {
                        m.user = $root.cat.UserMsg.decode(r, r.uint32());
                        break;
                    }
                case 102: {
                        m.reportstatus = $root.cat.ReportStatusMsg.decode(r, r.uint32());
                        break;
                    }
                case 103: {
                        if (!(m.receiverbufferstatus && m.receiverbufferstatus.length))
                            m.receiverbufferstatus = [];
                        m.receiverbufferstatus.push($root.cat.ReceiverBufferStatusMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a NotificationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.NotificationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.NotificationMsg} NotificationMsg
         */
        NotificationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.NotificationMsg)
                return d;
            var m = new $root.cat.NotificationMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.priority) {
            default:
                if (typeof d.priority === "number") {
                    m.priority = d.priority;
                    break;
                }
                break;
            case "NOTIFICATIONPRIORITY_NORMAL":
            case 0:
                m.priority = 0;
                break;
            case "NOTIFICATIONPRIORITY_HIGH":
            case 1:
                m.priority = 1;
                break;
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "NOTIFICATION_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "NOTIFICATION_WELCOME":
            case 1:
                m.type = 1;
                break;
            case "NOTIFICATION_TRIGGER_ALERT":
            case 2:
                m.type = 2;
                break;
            case "NOTIFICATION_MESSAGE":
            case 3:
                m.type = 3;
                break;
            case "NOTIFICATION_CONVERSATION":
            case 4:
                m.type = 4;
                break;
            case "NOTIFICATION_SERVER":
            case 5:
                m.type = 5;
                break;
            case "NOTIFICATION_TOPIC":
            case 6:
                m.type = 6;
                break;
            case "NOTIFICATION_ACCOUNT":
            case 7:
                m.type = 7;
                break;
            case "NOTIFICATION_CONTACT":
            case 8:
                m.type = 8;
                break;
            case "NOTIFICATION_PROFILE":
            case 9:
                m.type = 9;
                break;
            case "NOTIFICATION_DEVICE":
            case 10:
                m.type = 10;
                break;
            case "NOTIFICATION_CASE":
            case 11:
                m.type = 11;
                break;
            case "NOTIFICATION_APP_STATUS":
            case 21:
                m.type = 21;
                break;
            case "NOTIFICATION_ACCOUNT_STATUS":
            case 22:
                m.type = 22;
                break;
            case "NOTIFICATION_REPORT_STATUS":
            case 23:
                m.type = 23;
                break;
            case "NOTIFICATION_DEVICE_STATUS":
            case 24:
                m.type = 24;
                break;
            case "NOTIFICATION_APK_STATUS":
            case 25:
                m.type = 25;
                break;
            case "NOTIFICATION_USERSESSION_PERMISSIONS":
            case 31:
                m.type = 31;
                break;
            case "NOTIFICATION_USERSESSION_EXPIRES":
            case 32:
                m.type = 32;
                break;
            case "NOTIFICATION_USERSESSION_EXPIRED":
            case 33:
                m.type = 33;
                break;
            case "NOTIFICATION_USERSESSION_DISABLED":
            case 34:
                m.type = 34;
                break;
            case "NOTIFICATION_USERSESSION_LOCK":
            case 35:
                m.type = 35;
                break;
            case "NOTIFICATION_USERSESSION_UNLOCK":
            case 36:
                m.type = 36;
                break;
            case "NOTIFICATION_DATA_CLEANING":
            case 40:
                m.type = 40;
                break;
            case "NOTIFICATION_CAPTURE_APP":
            case 41:
                m.type = 41;
                break;
            case "NOTIFICATION_FILE":
            case 42:
                m.type = 42;
                break;
            }
            if (d.references) {
                if (typeof d.references !== "object")
                    throw TypeError(".cat.NotificationMsg.references: object expected");
                m.references = {};
                for (var ks = Object.keys(d.references), i = 0; i < ks.length; ++i) {
                    m.references[ks[i]] = String(d.references[ks[i]]);
                }
            }
            if (d.value != null) {
                m.value = String(d.value);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.read != null) {
                if ($util.Long)
                    (m.read = $util.Long.fromValue(d.read)).unsigned = true;
                else if (typeof d.read === "string")
                    m.read = parseInt(d.read, 10);
                else if (typeof d.read === "number")
                    m.read = d.read;
                else if (typeof d.read === "object")
                    m.read = new $util.LongBits(d.read.low >>> 0, d.read.high >>> 0).toNumber(true);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".cat.NotificationMsg.user: object expected");
                m.user = $root.cat.UserMsg.fromObject(d.user);
            }
            if (d.reportstatus != null) {
                if (typeof d.reportstatus !== "object")
                    throw TypeError(".cat.NotificationMsg.reportstatus: object expected");
                m.reportstatus = $root.cat.ReportStatusMsg.fromObject(d.reportstatus);
            }
            if (d.receiverbufferstatus) {
                if (!Array.isArray(d.receiverbufferstatus))
                    throw TypeError(".cat.NotificationMsg.receiverbufferstatus: array expected");
                m.receiverbufferstatus = [];
                for (var i = 0; i < d.receiverbufferstatus.length; ++i) {
                    if (typeof d.receiverbufferstatus[i] !== "object")
                        throw TypeError(".cat.NotificationMsg.receiverbufferstatus: object expected");
                    m.receiverbufferstatus[i] = $root.cat.ReceiverBufferStatusMsg.fromObject(d.receiverbufferstatus[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a NotificationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.NotificationMsg
         * @static
         * @param {cat.NotificationMsg} m NotificationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.receiverbufferstatus = [];
            }
            if (o.objects || o.defaults) {
                d.references = {};
            }
            if (o.defaults) {
                d.id = "";
                d.priority = o.enums === String ? "NOTIFICATIONPRIORITY_NORMAL" : 0;
                d.type = o.enums === String ? "NOTIFICATION_UNKNOWN" : 0;
                d.value = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.read = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.read = o.longs === String ? "0" : 0;
                d.user = null;
                d.reportstatus = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.priority != null && m.hasOwnProperty("priority")) {
                d.priority = o.enums === String ? $root.cat.NotificationPriority[m.priority] === undefined ? m.priority : $root.cat.NotificationPriority[m.priority] : m.priority;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.NotificationType[m.type] === undefined ? m.type : $root.cat.NotificationType[m.type] : m.type;
            }
            var ks2;
            if (m.references && (ks2 = Object.keys(m.references)).length) {
                d.references = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.references[ks2[j]] = m.references[ks2[j]];
                }
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.read != null && m.hasOwnProperty("read")) {
                if (typeof m.read === "number")
                    d.read = o.longs === String ? String(m.read) : m.read;
                else
                    d.read = o.longs === String ? $util.Long.prototype.toString.call(m.read) : o.longs === Number ? new $util.LongBits(m.read.low >>> 0, m.read.high >>> 0).toNumber(true) : m.read;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.cat.UserMsg.toObject(m.user, o);
            }
            if (m.reportstatus != null && m.hasOwnProperty("reportstatus")) {
                d.reportstatus = $root.cat.ReportStatusMsg.toObject(m.reportstatus, o);
            }
            if (m.receiverbufferstatus && m.receiverbufferstatus.length) {
                d.receiverbufferstatus = [];
                for (var j = 0; j < m.receiverbufferstatus.length; ++j) {
                    d.receiverbufferstatus[j] = $root.cat.ReceiverBufferStatusMsg.toObject(m.receiverbufferstatus[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this NotificationMsg to JSON.
         * @function toJSON
         * @memberof cat.NotificationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationMsg
         * @function getTypeUrl
         * @memberof cat.NotificationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.NotificationMsg";
        };

        return NotificationMsg;
    })();

    cat.NotificationBundleMsg = (function() {

        /**
         * Properties of a NotificationBundleMsg.
         * @memberof cat
         * @interface INotificationBundleMsg
         * @property {cat.IUserMsg|null} [user] NotificationBundleMsg user
         * @property {cat.IAccountMsg|null} [account] NotificationBundleMsg account
         * @property {Array.<cat.INotificationMsg>|null} [server] NotificationBundleMsg server
         * @property {Array.<cat.INotificationMsg>|null} [conversation] NotificationBundleMsg conversation
         * @property {Array.<cat.INotificationMsg>|null} [topic] NotificationBundleMsg topic
         * @property {Array.<cat.INotificationMsg>|null} [message] NotificationBundleMsg message
         * @property {Array.<cat.INotificationMsg>|null} [contact] NotificationBundleMsg contact
         */

        /**
         * Constructs a new NotificationBundleMsg.
         * @memberof cat
         * @classdesc Represents a NotificationBundleMsg.
         * @implements INotificationBundleMsg
         * @constructor
         * @param {cat.INotificationBundleMsg=} [p] Properties to set
         */
        function NotificationBundleMsg(p) {
            this.server = [];
            this.conversation = [];
            this.topic = [];
            this.message = [];
            this.contact = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NotificationBundleMsg user.
         * @member {cat.IUserMsg|null|undefined} user
         * @memberof cat.NotificationBundleMsg
         * @instance
         */
        NotificationBundleMsg.prototype.user = null;

        /**
         * NotificationBundleMsg account.
         * @member {cat.IAccountMsg|null|undefined} account
         * @memberof cat.NotificationBundleMsg
         * @instance
         */
        NotificationBundleMsg.prototype.account = null;

        /**
         * NotificationBundleMsg server.
         * @member {Array.<cat.INotificationMsg>} server
         * @memberof cat.NotificationBundleMsg
         * @instance
         */
        NotificationBundleMsg.prototype.server = $util.emptyArray;

        /**
         * NotificationBundleMsg conversation.
         * @member {Array.<cat.INotificationMsg>} conversation
         * @memberof cat.NotificationBundleMsg
         * @instance
         */
        NotificationBundleMsg.prototype.conversation = $util.emptyArray;

        /**
         * NotificationBundleMsg topic.
         * @member {Array.<cat.INotificationMsg>} topic
         * @memberof cat.NotificationBundleMsg
         * @instance
         */
        NotificationBundleMsg.prototype.topic = $util.emptyArray;

        /**
         * NotificationBundleMsg message.
         * @member {Array.<cat.INotificationMsg>} message
         * @memberof cat.NotificationBundleMsg
         * @instance
         */
        NotificationBundleMsg.prototype.message = $util.emptyArray;

        /**
         * NotificationBundleMsg contact.
         * @member {Array.<cat.INotificationMsg>} contact
         * @memberof cat.NotificationBundleMsg
         * @instance
         */
        NotificationBundleMsg.prototype.contact = $util.emptyArray;

        /**
         * Creates a new NotificationBundleMsg instance using the specified properties.
         * @function create
         * @memberof cat.NotificationBundleMsg
         * @static
         * @param {cat.INotificationBundleMsg=} [properties] Properties to set
         * @returns {cat.NotificationBundleMsg} NotificationBundleMsg instance
         */
        NotificationBundleMsg.create = function create(properties) {
            return new NotificationBundleMsg(properties);
        };

        /**
         * Encodes the specified NotificationBundleMsg message. Does not implicitly {@link cat.NotificationBundleMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.NotificationBundleMsg
         * @static
         * @param {cat.INotificationBundleMsg} m NotificationBundleMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotificationBundleMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.cat.UserMsg.encode(m.user, w.uint32(10).fork()).ldelim();
            if (m.account != null && Object.hasOwnProperty.call(m, "account"))
                $root.cat.AccountMsg.encode(m.account, w.uint32(18).fork()).ldelim();
            if (m.server != null && m.server.length) {
                for (var i = 0; i < m.server.length; ++i)
                    $root.cat.NotificationMsg.encode(m.server[i], w.uint32(26).fork()).ldelim();
            }
            if (m.conversation != null && m.conversation.length) {
                for (var i = 0; i < m.conversation.length; ++i)
                    $root.cat.NotificationMsg.encode(m.conversation[i], w.uint32(34).fork()).ldelim();
            }
            if (m.topic != null && m.topic.length) {
                for (var i = 0; i < m.topic.length; ++i)
                    $root.cat.NotificationMsg.encode(m.topic[i], w.uint32(42).fork()).ldelim();
            }
            if (m.message != null && m.message.length) {
                for (var i = 0; i < m.message.length; ++i)
                    $root.cat.NotificationMsg.encode(m.message[i], w.uint32(50).fork()).ldelim();
            }
            if (m.contact != null && m.contact.length) {
                for (var i = 0; i < m.contact.length; ++i)
                    $root.cat.NotificationMsg.encode(m.contact[i], w.uint32(58).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a NotificationBundleMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.NotificationBundleMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.NotificationBundleMsg} NotificationBundleMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotificationBundleMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.NotificationBundleMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.user = $root.cat.UserMsg.decode(r, r.uint32());
                        break;
                    }
                case 2: {
                        m.account = $root.cat.AccountMsg.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        if (!(m.server && m.server.length))
                            m.server = [];
                        m.server.push($root.cat.NotificationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 4: {
                        if (!(m.conversation && m.conversation.length))
                            m.conversation = [];
                        m.conversation.push($root.cat.NotificationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 5: {
                        if (!(m.topic && m.topic.length))
                            m.topic = [];
                        m.topic.push($root.cat.NotificationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 6: {
                        if (!(m.message && m.message.length))
                            m.message = [];
                        m.message.push($root.cat.NotificationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 7: {
                        if (!(m.contact && m.contact.length))
                            m.contact = [];
                        m.contact.push($root.cat.NotificationMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a NotificationBundleMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.NotificationBundleMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.NotificationBundleMsg} NotificationBundleMsg
         */
        NotificationBundleMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.NotificationBundleMsg)
                return d;
            var m = new $root.cat.NotificationBundleMsg();
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".cat.NotificationBundleMsg.user: object expected");
                m.user = $root.cat.UserMsg.fromObject(d.user);
            }
            if (d.account != null) {
                if (typeof d.account !== "object")
                    throw TypeError(".cat.NotificationBundleMsg.account: object expected");
                m.account = $root.cat.AccountMsg.fromObject(d.account);
            }
            if (d.server) {
                if (!Array.isArray(d.server))
                    throw TypeError(".cat.NotificationBundleMsg.server: array expected");
                m.server = [];
                for (var i = 0; i < d.server.length; ++i) {
                    if (typeof d.server[i] !== "object")
                        throw TypeError(".cat.NotificationBundleMsg.server: object expected");
                    m.server[i] = $root.cat.NotificationMsg.fromObject(d.server[i]);
                }
            }
            if (d.conversation) {
                if (!Array.isArray(d.conversation))
                    throw TypeError(".cat.NotificationBundleMsg.conversation: array expected");
                m.conversation = [];
                for (var i = 0; i < d.conversation.length; ++i) {
                    if (typeof d.conversation[i] !== "object")
                        throw TypeError(".cat.NotificationBundleMsg.conversation: object expected");
                    m.conversation[i] = $root.cat.NotificationMsg.fromObject(d.conversation[i]);
                }
            }
            if (d.topic) {
                if (!Array.isArray(d.topic))
                    throw TypeError(".cat.NotificationBundleMsg.topic: array expected");
                m.topic = [];
                for (var i = 0; i < d.topic.length; ++i) {
                    if (typeof d.topic[i] !== "object")
                        throw TypeError(".cat.NotificationBundleMsg.topic: object expected");
                    m.topic[i] = $root.cat.NotificationMsg.fromObject(d.topic[i]);
                }
            }
            if (d.message) {
                if (!Array.isArray(d.message))
                    throw TypeError(".cat.NotificationBundleMsg.message: array expected");
                m.message = [];
                for (var i = 0; i < d.message.length; ++i) {
                    if (typeof d.message[i] !== "object")
                        throw TypeError(".cat.NotificationBundleMsg.message: object expected");
                    m.message[i] = $root.cat.NotificationMsg.fromObject(d.message[i]);
                }
            }
            if (d.contact) {
                if (!Array.isArray(d.contact))
                    throw TypeError(".cat.NotificationBundleMsg.contact: array expected");
                m.contact = [];
                for (var i = 0; i < d.contact.length; ++i) {
                    if (typeof d.contact[i] !== "object")
                        throw TypeError(".cat.NotificationBundleMsg.contact: object expected");
                    m.contact[i] = $root.cat.NotificationMsg.fromObject(d.contact[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a NotificationBundleMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.NotificationBundleMsg
         * @static
         * @param {cat.NotificationBundleMsg} m NotificationBundleMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotificationBundleMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.server = [];
                d.conversation = [];
                d.topic = [];
                d.message = [];
                d.contact = [];
            }
            if (o.defaults) {
                d.user = null;
                d.account = null;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.cat.UserMsg.toObject(m.user, o);
            }
            if (m.account != null && m.hasOwnProperty("account")) {
                d.account = $root.cat.AccountMsg.toObject(m.account, o);
            }
            if (m.server && m.server.length) {
                d.server = [];
                for (var j = 0; j < m.server.length; ++j) {
                    d.server[j] = $root.cat.NotificationMsg.toObject(m.server[j], o);
                }
            }
            if (m.conversation && m.conversation.length) {
                d.conversation = [];
                for (var j = 0; j < m.conversation.length; ++j) {
                    d.conversation[j] = $root.cat.NotificationMsg.toObject(m.conversation[j], o);
                }
            }
            if (m.topic && m.topic.length) {
                d.topic = [];
                for (var j = 0; j < m.topic.length; ++j) {
                    d.topic[j] = $root.cat.NotificationMsg.toObject(m.topic[j], o);
                }
            }
            if (m.message && m.message.length) {
                d.message = [];
                for (var j = 0; j < m.message.length; ++j) {
                    d.message[j] = $root.cat.NotificationMsg.toObject(m.message[j], o);
                }
            }
            if (m.contact && m.contact.length) {
                d.contact = [];
                for (var j = 0; j < m.contact.length; ++j) {
                    d.contact[j] = $root.cat.NotificationMsg.toObject(m.contact[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this NotificationBundleMsg to JSON.
         * @function toJSON
         * @memberof cat.NotificationBundleMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotificationBundleMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotificationBundleMsg
         * @function getTypeUrl
         * @memberof cat.NotificationBundleMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotificationBundleMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.NotificationBundleMsg";
        };

        return NotificationBundleMsg;
    })();

    /**
     * AccountStatus enum.
     * @name cat.AccountStatus
     * @enum {number}
     * @property {number} ACCOUNTSTATUS_UNKNOWN=0 ACCOUNTSTATUS_UNKNOWN value
     * @property {number} ACCOUNTSTATUS_REGISTRATION=1 ACCOUNTSTATUS_REGISTRATION value
     * @property {number} ACCOUNTSTATUS_AUTHENTICATION=2 ACCOUNTSTATUS_AUTHENTICATION value
     * @property {number} ACCOUNTSTATUS_DISCONNECTED=3 ACCOUNTSTATUS_DISCONNECTED value
     * @property {number} ACCOUNTSTATUS_CONNECTED=4 ACCOUNTSTATUS_CONNECTED value
     * @property {number} ACCOUNTSTATUS_FAILED=5 ACCOUNTSTATUS_FAILED value
     * @property {number} ACCOUNTSTATUS_ONLINE=6 ACCOUNTSTATUS_ONLINE value
     * @property {number} ACCOUNTSTATUS_OFFLINE=7 ACCOUNTSTATUS_OFFLINE value
     * @property {number} ACCOUNTSTATUS_DELETED=8 ACCOUNTSTATUS_DELETED value
     * @property {number} ACCOUNTSTATUS_BANNED=9 ACCOUNTSTATUS_BANNED value
     * @property {number} ACCOUNTSTATUS_TYPING=10 ACCOUNTSTATUS_TYPING value
     * @property {number} ACCOUNTSTATUS_CANCEL=11 ACCOUNTSTATUS_CANCEL value
     * @property {number} ACCOUNTSTATUS_DELETING=12 ACCOUNTSTATUS_DELETING value
     * @property {number} ACCOUNTSTATUS_RECENTLY=13 ACCOUNTSTATUS_RECENTLY value
     * @property {number} ACCOUNTSTATUS_LASTWEEK=14 ACCOUNTSTATUS_LASTWEEK value
     * @property {number} ACCOUNTSTATUS_LASTMONTH=15 ACCOUNTSTATUS_LASTMONTH value
     * @property {number} ACCOUNTSTATUS_NEVERCHANGED=16 ACCOUNTSTATUS_NEVERCHANGED value
     * @property {number} ACCOUNTSTATUS_AWAY=17 ACCOUNTSTATUS_AWAY value
     * @property {number} ACCOUNTSTATUS_BUSY=18 ACCOUNTSTATUS_BUSY value
     * @property {number} ACCOUNTSTATUS_INVALIDCREDENTIALS=19 ACCOUNTSTATUS_INVALIDCREDENTIALS value
     * @property {number} ACCOUNTSTATUS_CHALLENGEREQUIRED=20 ACCOUNTSTATUS_CHALLENGEREQUIRED value
     * @property {number} ACCOUNTSTATUS_IPBLOCKED=21 ACCOUNTSTATUS_IPBLOCKED value
     * @property {number} ACCOUNTSTATUS_REGISTRATIONFAILED=22 ACCOUNTSTATUS_REGISTRATIONFAILED value
     * @property {number} ACCOUNTSTATUS_DELETIONFAILED=23 ACCOUNTSTATUS_DELETIONFAILED value
     */
    cat.AccountStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ACCOUNTSTATUS_UNKNOWN"] = 0;
        values[valuesById[1] = "ACCOUNTSTATUS_REGISTRATION"] = 1;
        values[valuesById[2] = "ACCOUNTSTATUS_AUTHENTICATION"] = 2;
        values[valuesById[3] = "ACCOUNTSTATUS_DISCONNECTED"] = 3;
        values[valuesById[4] = "ACCOUNTSTATUS_CONNECTED"] = 4;
        values[valuesById[5] = "ACCOUNTSTATUS_FAILED"] = 5;
        values[valuesById[6] = "ACCOUNTSTATUS_ONLINE"] = 6;
        values[valuesById[7] = "ACCOUNTSTATUS_OFFLINE"] = 7;
        values[valuesById[8] = "ACCOUNTSTATUS_DELETED"] = 8;
        values[valuesById[9] = "ACCOUNTSTATUS_BANNED"] = 9;
        values[valuesById[10] = "ACCOUNTSTATUS_TYPING"] = 10;
        values[valuesById[11] = "ACCOUNTSTATUS_CANCEL"] = 11;
        values[valuesById[12] = "ACCOUNTSTATUS_DELETING"] = 12;
        values[valuesById[13] = "ACCOUNTSTATUS_RECENTLY"] = 13;
        values[valuesById[14] = "ACCOUNTSTATUS_LASTWEEK"] = 14;
        values[valuesById[15] = "ACCOUNTSTATUS_LASTMONTH"] = 15;
        values[valuesById[16] = "ACCOUNTSTATUS_NEVERCHANGED"] = 16;
        values[valuesById[17] = "ACCOUNTSTATUS_AWAY"] = 17;
        values[valuesById[18] = "ACCOUNTSTATUS_BUSY"] = 18;
        values[valuesById[19] = "ACCOUNTSTATUS_INVALIDCREDENTIALS"] = 19;
        values[valuesById[20] = "ACCOUNTSTATUS_CHALLENGEREQUIRED"] = 20;
        values[valuesById[21] = "ACCOUNTSTATUS_IPBLOCKED"] = 21;
        values[valuesById[22] = "ACCOUNTSTATUS_REGISTRATIONFAILED"] = 22;
        values[valuesById[23] = "ACCOUNTSTATUS_DELETIONFAILED"] = 23;
        return values;
    })();

    /**
     * MemberStatus enum.
     * @name cat.MemberStatus
     * @enum {number}
     * @property {number} MEMBERSTATUS_UNKNOWN=0 MEMBERSTATUS_UNKNOWN value
     * @property {number} MEMBERSTATUS_CREATOR=1 MEMBERSTATUS_CREATOR value
     * @property {number} MEMBERSTATUS_ADMINISTRATOR=2 MEMBERSTATUS_ADMINISTRATOR value
     * @property {number} MEMBERSTATUS_MEMBER=3 MEMBERSTATUS_MEMBER value
     * @property {number} MEMBERSTATUS_BANNED=4 MEMBERSTATUS_BANNED value
     * @property {number} MEMBERSTATUS_RESTRICTED=5 MEMBERSTATUS_RESTRICTED value
     * @property {number} MEMBERSTATUS_LEFT=6 MEMBERSTATUS_LEFT value
     * @property {number} MEMBERSTATUS_FOLLOW=7 MEMBERSTATUS_FOLLOW value
     * @property {number} MEMBERSTATUS_UNFOLLOW=8 MEMBERSTATUS_UNFOLLOW value
     * @property {number} MEMBERSTATUS_REQUEST=9 MEMBERSTATUS_REQUEST value
     * @property {number} MEMBERSTATUS_MODERATOR=10 MEMBERSTATUS_MODERATOR value
     * @property {number} MEMBERSTATUS_VIP=11 MEMBERSTATUS_VIP value
     * @property {number} MEMBERSTATUS_OWNER=12 MEMBERSTATUS_OWNER value
     * @property {number} MEMBERSTATUS_GUEST=13 MEMBERSTATUS_GUEST value
     * @property {number} MEMBERSTATUS_BOT=14 MEMBERSTATUS_BOT value
     * @property {number} MEMBERSTATUS_TRACK=15 MEMBERSTATUS_TRACK value
     */
    cat.MemberStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MEMBERSTATUS_UNKNOWN"] = 0;
        values[valuesById[1] = "MEMBERSTATUS_CREATOR"] = 1;
        values[valuesById[2] = "MEMBERSTATUS_ADMINISTRATOR"] = 2;
        values[valuesById[3] = "MEMBERSTATUS_MEMBER"] = 3;
        values[valuesById[4] = "MEMBERSTATUS_BANNED"] = 4;
        values[valuesById[5] = "MEMBERSTATUS_RESTRICTED"] = 5;
        values[valuesById[6] = "MEMBERSTATUS_LEFT"] = 6;
        values[valuesById[7] = "MEMBERSTATUS_FOLLOW"] = 7;
        values[valuesById[8] = "MEMBERSTATUS_UNFOLLOW"] = 8;
        values[valuesById[9] = "MEMBERSTATUS_REQUEST"] = 9;
        values[valuesById[10] = "MEMBERSTATUS_MODERATOR"] = 10;
        values[valuesById[11] = "MEMBERSTATUS_VIP"] = 11;
        values[valuesById[12] = "MEMBERSTATUS_OWNER"] = 12;
        values[valuesById[13] = "MEMBERSTATUS_GUEST"] = 13;
        values[valuesById[14] = "MEMBERSTATUS_BOT"] = 14;
        values[valuesById[15] = "MEMBERSTATUS_TRACK"] = 15;
        return values;
    })();

    cat.MemberStatusMsg = (function() {

        /**
         * Properties of a MemberStatusMsg.
         * @memberof cat
         * @interface IMemberStatusMsg
         * @property {string|null} [id] MemberStatusMsg id
         * @property {cat.MemberStatus|null} [status] MemberStatusMsg status
         * @property {number|null} [timestamp] MemberStatusMsg timestamp
         * @property {number|null} [joined] MemberStatusMsg joined
         */

        /**
         * Constructs a new MemberStatusMsg.
         * @memberof cat
         * @classdesc Represents a MemberStatusMsg.
         * @implements IMemberStatusMsg
         * @constructor
         * @param {cat.IMemberStatusMsg=} [p] Properties to set
         */
        function MemberStatusMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * MemberStatusMsg id.
         * @member {string} id
         * @memberof cat.MemberStatusMsg
         * @instance
         */
        MemberStatusMsg.prototype.id = "";

        /**
         * MemberStatusMsg status.
         * @member {cat.MemberStatus} status
         * @memberof cat.MemberStatusMsg
         * @instance
         */
        MemberStatusMsg.prototype.status = 0;

        /**
         * MemberStatusMsg timestamp.
         * @member {number} timestamp
         * @memberof cat.MemberStatusMsg
         * @instance
         */
        MemberStatusMsg.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MemberStatusMsg joined.
         * @member {number} joined
         * @memberof cat.MemberStatusMsg
         * @instance
         */
        MemberStatusMsg.prototype.joined = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new MemberStatusMsg instance using the specified properties.
         * @function create
         * @memberof cat.MemberStatusMsg
         * @static
         * @param {cat.IMemberStatusMsg=} [properties] Properties to set
         * @returns {cat.MemberStatusMsg} MemberStatusMsg instance
         */
        MemberStatusMsg.create = function create(properties) {
            return new MemberStatusMsg(properties);
        };

        /**
         * Encodes the specified MemberStatusMsg message. Does not implicitly {@link cat.MemberStatusMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.MemberStatusMsg
         * @static
         * @param {cat.IMemberStatusMsg} m MemberStatusMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberStatusMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(16).int32(m.status);
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                w.uint32(24).uint64(m.timestamp);
            if (m.joined != null && Object.hasOwnProperty.call(m, "joined"))
                w.uint32(32).uint64(m.joined);
            return w;
        };

        /**
         * Decodes a MemberStatusMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.MemberStatusMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.MemberStatusMsg} MemberStatusMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberStatusMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.MemberStatusMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.status = r.int32();
                        break;
                    }
                case 3: {
                        m.timestamp = r.uint64();
                        break;
                    }
                case 4: {
                        m.joined = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a MemberStatusMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.MemberStatusMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.MemberStatusMsg} MemberStatusMsg
         */
        MemberStatusMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.MemberStatusMsg)
                return d;
            var m = new $root.cat.MemberStatusMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "MEMBERSTATUS_UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "MEMBERSTATUS_CREATOR":
            case 1:
                m.status = 1;
                break;
            case "MEMBERSTATUS_ADMINISTRATOR":
            case 2:
                m.status = 2;
                break;
            case "MEMBERSTATUS_MEMBER":
            case 3:
                m.status = 3;
                break;
            case "MEMBERSTATUS_BANNED":
            case 4:
                m.status = 4;
                break;
            case "MEMBERSTATUS_RESTRICTED":
            case 5:
                m.status = 5;
                break;
            case "MEMBERSTATUS_LEFT":
            case 6:
                m.status = 6;
                break;
            case "MEMBERSTATUS_FOLLOW":
            case 7:
                m.status = 7;
                break;
            case "MEMBERSTATUS_UNFOLLOW":
            case 8:
                m.status = 8;
                break;
            case "MEMBERSTATUS_REQUEST":
            case 9:
                m.status = 9;
                break;
            case "MEMBERSTATUS_MODERATOR":
            case 10:
                m.status = 10;
                break;
            case "MEMBERSTATUS_VIP":
            case 11:
                m.status = 11;
                break;
            case "MEMBERSTATUS_OWNER":
            case 12:
                m.status = 12;
                break;
            case "MEMBERSTATUS_GUEST":
            case 13:
                m.status = 13;
                break;
            case "MEMBERSTATUS_BOT":
            case 14:
                m.status = 14;
                break;
            case "MEMBERSTATUS_TRACK":
            case 15:
                m.status = 15;
                break;
            }
            if (d.timestamp != null) {
                if ($util.Long)
                    (m.timestamp = $util.Long.fromValue(d.timestamp)).unsigned = true;
                else if (typeof d.timestamp === "string")
                    m.timestamp = parseInt(d.timestamp, 10);
                else if (typeof d.timestamp === "number")
                    m.timestamp = d.timestamp;
                else if (typeof d.timestamp === "object")
                    m.timestamp = new $util.LongBits(d.timestamp.low >>> 0, d.timestamp.high >>> 0).toNumber(true);
            }
            if (d.joined != null) {
                if ($util.Long)
                    (m.joined = $util.Long.fromValue(d.joined)).unsigned = true;
                else if (typeof d.joined === "string")
                    m.joined = parseInt(d.joined, 10);
                else if (typeof d.joined === "number")
                    m.joined = d.joined;
                else if (typeof d.joined === "object")
                    m.joined = new $util.LongBits(d.joined.low >>> 0, d.joined.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a MemberStatusMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.MemberStatusMsg
         * @static
         * @param {cat.MemberStatusMsg} m MemberStatusMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberStatusMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.status = o.enums === String ? "MEMBERSTATUS_UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timestamp = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.joined = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.joined = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.MemberStatus[m.status] === undefined ? m.status : $root.cat.MemberStatus[m.status] : m.status;
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                if (typeof m.timestamp === "number")
                    d.timestamp = o.longs === String ? String(m.timestamp) : m.timestamp;
                else
                    d.timestamp = o.longs === String ? $util.Long.prototype.toString.call(m.timestamp) : o.longs === Number ? new $util.LongBits(m.timestamp.low >>> 0, m.timestamp.high >>> 0).toNumber(true) : m.timestamp;
            }
            if (m.joined != null && m.hasOwnProperty("joined")) {
                if (typeof m.joined === "number")
                    d.joined = o.longs === String ? String(m.joined) : m.joined;
                else
                    d.joined = o.longs === String ? $util.Long.prototype.toString.call(m.joined) : o.longs === Number ? new $util.LongBits(m.joined.low >>> 0, m.joined.high >>> 0).toNumber(true) : m.joined;
            }
            return d;
        };

        /**
         * Converts this MemberStatusMsg to JSON.
         * @function toJSON
         * @memberof cat.MemberStatusMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberStatusMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MemberStatusMsg
         * @function getTypeUrl
         * @memberof cat.MemberStatusMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MemberStatusMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.MemberStatusMsg";
        };

        return MemberStatusMsg;
    })();

    /**
     * ReportType enum.
     * @name cat.ReportType
     * @enum {number}
     * @property {number} REPORT_UNKNOWN=0 REPORT_UNKNOWN value
     * @property {number} REPORT_PROFILE=1 REPORT_PROFILE value
     * @property {number} REPORT_PROFILE_EXAMPLE=2 REPORT_PROFILE_EXAMPLE value
     */
    cat.ReportType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REPORT_UNKNOWN"] = 0;
        values[valuesById[1] = "REPORT_PROFILE"] = 1;
        values[valuesById[2] = "REPORT_PROFILE_EXAMPLE"] = 2;
        return values;
    })();

    /**
     * TemplateFlag enum.
     * @name cat.TemplateFlag
     * @enum {number}
     * @property {number} TEMPLATEFLAG_UNKNOWN=0 TEMPLATEFLAG_UNKNOWN value
     * @property {number} TEMPLATEFLAG_ANNOTATIONS=1 TEMPLATEFLAG_ANNOTATIONS value
     * @property {number} TEMPLATEFLAG_LABELS=2 TEMPLATEFLAG_LABELS value
     * @property {number} TEMPLATEFLAG_TAGS=3 TEMPLATEFLAG_TAGS value
     * @property {number} TEMPLATEFLAG_HASHTAGS=4 TEMPLATEFLAG_HASHTAGS value
     * @property {number} TEMPLATEFLAG_URLS=5 TEMPLATEFLAG_URLS value
     * @property {number} TEMPLATEFLAG_MENTIONS=6 TEMPLATEFLAG_MENTIONS value
     * @property {number} TEMPLATEFLAG_MEMBERS=7 TEMPLATEFLAG_MEMBERS value
     * @property {number} TEMPLATEFLAG_REACTIONS=8 TEMPLATEFLAG_REACTIONS value
     * @property {number} TEMPLATEFLAG_FILEHASHES=9 TEMPLATEFLAG_FILEHASHES value
     * @property {number} TEMPLATEFLAG_HIDEEXCLUDEDMESSAGES=10 TEMPLATEFLAG_HIDEEXCLUDEDMESSAGES value
     * @property {number} TEMPLATEFLAG_ALIAS=11 TEMPLATEFLAG_ALIAS value
     */
    cat.TemplateFlag = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TEMPLATEFLAG_UNKNOWN"] = 0;
        values[valuesById[1] = "TEMPLATEFLAG_ANNOTATIONS"] = 1;
        values[valuesById[2] = "TEMPLATEFLAG_LABELS"] = 2;
        values[valuesById[3] = "TEMPLATEFLAG_TAGS"] = 3;
        values[valuesById[4] = "TEMPLATEFLAG_HASHTAGS"] = 4;
        values[valuesById[5] = "TEMPLATEFLAG_URLS"] = 5;
        values[valuesById[6] = "TEMPLATEFLAG_MENTIONS"] = 6;
        values[valuesById[7] = "TEMPLATEFLAG_MEMBERS"] = 7;
        values[valuesById[8] = "TEMPLATEFLAG_REACTIONS"] = 8;
        values[valuesById[9] = "TEMPLATEFLAG_FILEHASHES"] = 9;
        values[valuesById[10] = "TEMPLATEFLAG_HIDEEXCLUDEDMESSAGES"] = 10;
        values[valuesById[11] = "TEMPLATEFLAG_ALIAS"] = 11;
        return values;
    })();

    /**
     * TemplateError enum.
     * @name cat.TemplateError
     * @enum {number}
     * @property {number} TEMPLATEERROR_UNKOWN=0 TEMPLATEERROR_UNKOWN value
     * @property {number} TEMPLATEERROR_JSON=1 TEMPLATEERROR_JSON value
     * @property {number} TEMPLATEERROR_CSS=2 TEMPLATEERROR_CSS value
     */
    cat.TemplateError = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TEMPLATEERROR_UNKOWN"] = 0;
        values[valuesById[1] = "TEMPLATEERROR_JSON"] = 1;
        values[valuesById[2] = "TEMPLATEERROR_CSS"] = 2;
        return values;
    })();

    cat.ReportTemplateMsg = (function() {

        /**
         * Properties of a ReportTemplateMsg.
         * @memberof cat
         * @interface IReportTemplateMsg
         * @property {string|null} [id] ReportTemplateMsg id
         * @property {string|null} [name] ReportTemplateMsg name
         * @property {cat.ReportType|null} [type] ReportTemplateMsg type
         * @property {cat.StatusType|null} [status] ReportTemplateMsg status
         * @property {number|null} [created] ReportTemplateMsg created
         * @property {number|null} [lastmodified] ReportTemplateMsg lastmodified
         * @property {Array.<cat.TemplateFlag>|null} [flags] ReportTemplateMsg flags
         * @property {Array.<cat.IReportTextFieldMsg>|null} [inputfields] ReportTemplateMsg inputfields
         * @property {string|null} [description] ReportTemplateMsg description
         * @property {Array.<cat.TemplateError>|null} [errors] ReportTemplateMsg errors
         * @property {cat.SupportedLanguages|null} [language] ReportTemplateMsg language
         * @property {boolean|null} [sampledata] ReportTemplateMsg sampledata
         * @property {cat.IMediaFileMsg|null} [jsonfile] ReportTemplateMsg jsonfile
         * @property {cat.IMediaFileMsg|null} [cssfile] ReportTemplateMsg cssfile
         * @property {cat.IMediaFileMsg|null} [logofile] ReportTemplateMsg logofile
         */

        /**
         * Constructs a new ReportTemplateMsg.
         * @memberof cat
         * @classdesc Represents a ReportTemplateMsg.
         * @implements IReportTemplateMsg
         * @constructor
         * @param {cat.IReportTemplateMsg=} [p] Properties to set
         */
        function ReportTemplateMsg(p) {
            this.flags = [];
            this.inputfields = [];
            this.errors = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReportTemplateMsg id.
         * @member {string} id
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.id = "";

        /**
         * ReportTemplateMsg name.
         * @member {string} name
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.name = "";

        /**
         * ReportTemplateMsg type.
         * @member {cat.ReportType} type
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.type = 0;

        /**
         * ReportTemplateMsg status.
         * @member {cat.StatusType} status
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.status = 0;

        /**
         * ReportTemplateMsg created.
         * @member {number} created
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportTemplateMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportTemplateMsg flags.
         * @member {Array.<cat.TemplateFlag>} flags
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.flags = $util.emptyArray;

        /**
         * ReportTemplateMsg inputfields.
         * @member {Array.<cat.IReportTextFieldMsg>} inputfields
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.inputfields = $util.emptyArray;

        /**
         * ReportTemplateMsg description.
         * @member {string} description
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.description = "";

        /**
         * ReportTemplateMsg errors.
         * @member {Array.<cat.TemplateError>} errors
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.errors = $util.emptyArray;

        /**
         * ReportTemplateMsg language.
         * @member {cat.SupportedLanguages} language
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.language = 0;

        /**
         * ReportTemplateMsg sampledata.
         * @member {boolean|null|undefined} sampledata
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.sampledata = null;

        /**
         * ReportTemplateMsg jsonfile.
         * @member {cat.IMediaFileMsg|null|undefined} jsonfile
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.jsonfile = null;

        /**
         * ReportTemplateMsg cssfile.
         * @member {cat.IMediaFileMsg|null|undefined} cssfile
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.cssfile = null;

        /**
         * ReportTemplateMsg logofile.
         * @member {cat.IMediaFileMsg|null|undefined} logofile
         * @memberof cat.ReportTemplateMsg
         * @instance
         */
        ReportTemplateMsg.prototype.logofile = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReportTemplateMsg.prototype, "_sampledata", {
            get: $util.oneOfGetter($oneOfFields = ["sampledata"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ReportTemplateMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReportTemplateMsg
         * @static
         * @param {cat.IReportTemplateMsg=} [properties] Properties to set
         * @returns {cat.ReportTemplateMsg} ReportTemplateMsg instance
         */
        ReportTemplateMsg.create = function create(properties) {
            return new ReportTemplateMsg(properties);
        };

        /**
         * Encodes the specified ReportTemplateMsg message. Does not implicitly {@link cat.ReportTemplateMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReportTemplateMsg
         * @static
         * @param {cat.IReportTemplateMsg} m ReportTemplateMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportTemplateMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(32).int32(m.status);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(40).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(48).uint64(m.lastmodified);
            if (m.flags != null && m.flags.length) {
                w.uint32(90).fork();
                for (var i = 0; i < m.flags.length; ++i)
                    w.int32(m.flags[i]);
                w.ldelim();
            }
            if (m.inputfields != null && m.inputfields.length) {
                for (var i = 0; i < m.inputfields.length; ++i)
                    $root.cat.ReportTextFieldMsg.encode(m.inputfields[i], w.uint32(98).fork()).ldelim();
            }
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(106).string(m.description);
            if (m.errors != null && m.errors.length) {
                w.uint32(114).fork();
                for (var i = 0; i < m.errors.length; ++i)
                    w.int32(m.errors[i]);
                w.ldelim();
            }
            if (m.language != null && Object.hasOwnProperty.call(m, "language"))
                w.uint32(120).int32(m.language);
            if (m.sampledata != null && Object.hasOwnProperty.call(m, "sampledata"))
                w.uint32(808).bool(m.sampledata);
            if (m.jsonfile != null && Object.hasOwnProperty.call(m, "jsonfile"))
                $root.cat.MediaFileMsg.encode(m.jsonfile, w.uint32(818).fork()).ldelim();
            if (m.cssfile != null && Object.hasOwnProperty.call(m, "cssfile"))
                $root.cat.MediaFileMsg.encode(m.cssfile, w.uint32(826).fork()).ldelim();
            if (m.logofile != null && Object.hasOwnProperty.call(m, "logofile"))
                $root.cat.MediaFileMsg.encode(m.logofile, w.uint32(834).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ReportTemplateMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReportTemplateMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReportTemplateMsg} ReportTemplateMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportTemplateMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReportTemplateMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.type = r.int32();
                        break;
                    }
                case 4: {
                        m.status = r.int32();
                        break;
                    }
                case 5: {
                        m.created = r.uint64();
                        break;
                    }
                case 6: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 11: {
                        if (!(m.flags && m.flags.length))
                            m.flags = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.flags.push(r.int32());
                        } else
                            m.flags.push(r.int32());
                        break;
                    }
                case 12: {
                        if (!(m.inputfields && m.inputfields.length))
                            m.inputfields = [];
                        m.inputfields.push($root.cat.ReportTextFieldMsg.decode(r, r.uint32()));
                        break;
                    }
                case 13: {
                        m.description = r.string();
                        break;
                    }
                case 14: {
                        if (!(m.errors && m.errors.length))
                            m.errors = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.errors.push(r.int32());
                        } else
                            m.errors.push(r.int32());
                        break;
                    }
                case 15: {
                        m.language = r.int32();
                        break;
                    }
                case 101: {
                        m.sampledata = r.bool();
                        break;
                    }
                case 102: {
                        m.jsonfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 103: {
                        m.cssfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 104: {
                        m.logofile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReportTemplateMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReportTemplateMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReportTemplateMsg} ReportTemplateMsg
         */
        ReportTemplateMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReportTemplateMsg)
                return d;
            var m = new $root.cat.ReportTemplateMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "REPORT_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "REPORT_PROFILE":
            case 1:
                m.type = 1;
                break;
            case "REPORT_PROFILE_EXAMPLE":
            case 2:
                m.type = 2;
                break;
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ENABLED":
            case 1:
                m.status = 1;
                break;
            case "DISABLED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.flags) {
                if (!Array.isArray(d.flags))
                    throw TypeError(".cat.ReportTemplateMsg.flags: array expected");
                m.flags = [];
                for (var i = 0; i < d.flags.length; ++i) {
                    switch (d.flags[i]) {
                    default:
                        if (typeof d.flags[i] === "number") {
                            m.flags[i] = d.flags[i];
                            break;
                        }
                    case "TEMPLATEFLAG_UNKNOWN":
                    case 0:
                        m.flags[i] = 0;
                        break;
                    case "TEMPLATEFLAG_ANNOTATIONS":
                    case 1:
                        m.flags[i] = 1;
                        break;
                    case "TEMPLATEFLAG_LABELS":
                    case 2:
                        m.flags[i] = 2;
                        break;
                    case "TEMPLATEFLAG_TAGS":
                    case 3:
                        m.flags[i] = 3;
                        break;
                    case "TEMPLATEFLAG_HASHTAGS":
                    case 4:
                        m.flags[i] = 4;
                        break;
                    case "TEMPLATEFLAG_URLS":
                    case 5:
                        m.flags[i] = 5;
                        break;
                    case "TEMPLATEFLAG_MENTIONS":
                    case 6:
                        m.flags[i] = 6;
                        break;
                    case "TEMPLATEFLAG_MEMBERS":
                    case 7:
                        m.flags[i] = 7;
                        break;
                    case "TEMPLATEFLAG_REACTIONS":
                    case 8:
                        m.flags[i] = 8;
                        break;
                    case "TEMPLATEFLAG_FILEHASHES":
                    case 9:
                        m.flags[i] = 9;
                        break;
                    case "TEMPLATEFLAG_HIDEEXCLUDEDMESSAGES":
                    case 10:
                        m.flags[i] = 10;
                        break;
                    case "TEMPLATEFLAG_ALIAS":
                    case 11:
                        m.flags[i] = 11;
                        break;
                    }
                }
            }
            if (d.inputfields) {
                if (!Array.isArray(d.inputfields))
                    throw TypeError(".cat.ReportTemplateMsg.inputfields: array expected");
                m.inputfields = [];
                for (var i = 0; i < d.inputfields.length; ++i) {
                    if (typeof d.inputfields[i] !== "object")
                        throw TypeError(".cat.ReportTemplateMsg.inputfields: object expected");
                    m.inputfields[i] = $root.cat.ReportTextFieldMsg.fromObject(d.inputfields[i]);
                }
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.errors) {
                if (!Array.isArray(d.errors))
                    throw TypeError(".cat.ReportTemplateMsg.errors: array expected");
                m.errors = [];
                for (var i = 0; i < d.errors.length; ++i) {
                    switch (d.errors[i]) {
                    default:
                        if (typeof d.errors[i] === "number") {
                            m.errors[i] = d.errors[i];
                            break;
                        }
                    case "TEMPLATEERROR_UNKOWN":
                    case 0:
                        m.errors[i] = 0;
                        break;
                    case "TEMPLATEERROR_JSON":
                    case 1:
                        m.errors[i] = 1;
                        break;
                    case "TEMPLATEERROR_CSS":
                    case 2:
                        m.errors[i] = 2;
                        break;
                    }
                }
            }
            switch (d.language) {
            default:
                if (typeof d.language === "number") {
                    m.language = d.language;
                    break;
                }
                break;
            case "LANGUAGE_UNKNOWN":
            case 0:
                m.language = 0;
                break;
            case "LANGUAGE_ENGLISH":
            case 1:
                m.language = 1;
                break;
            case "LANGUAGE_DUTCH":
            case 2:
                m.language = 2;
                break;
            case "LANGUAGE_GERMAN":
            case 3:
                m.language = 3;
                break;
            case "LANGUAGE_FRENCH":
            case 4:
                m.language = 4;
                break;
            case "LANGUAGE_SPANISH":
            case 5:
                m.language = 5;
                break;
            }
            if (d.sampledata != null) {
                m.sampledata = Boolean(d.sampledata);
            }
            if (d.jsonfile != null) {
                if (typeof d.jsonfile !== "object")
                    throw TypeError(".cat.ReportTemplateMsg.jsonfile: object expected");
                m.jsonfile = $root.cat.MediaFileMsg.fromObject(d.jsonfile);
            }
            if (d.cssfile != null) {
                if (typeof d.cssfile !== "object")
                    throw TypeError(".cat.ReportTemplateMsg.cssfile: object expected");
                m.cssfile = $root.cat.MediaFileMsg.fromObject(d.cssfile);
            }
            if (d.logofile != null) {
                if (typeof d.logofile !== "object")
                    throw TypeError(".cat.ReportTemplateMsg.logofile: object expected");
                m.logofile = $root.cat.MediaFileMsg.fromObject(d.logofile);
            }
            return m;
        };

        /**
         * Creates a plain object from a ReportTemplateMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReportTemplateMsg
         * @static
         * @param {cat.ReportTemplateMsg} m ReportTemplateMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportTemplateMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.flags = [];
                d.inputfields = [];
                d.errors = [];
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.type = o.enums === String ? "REPORT_UNKNOWN" : 0;
                d.status = o.enums === String ? "UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.description = "";
                d.language = o.enums === String ? "LANGUAGE_UNKNOWN" : 0;
                d.jsonfile = null;
                d.cssfile = null;
                d.logofile = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.ReportType[m.type] === undefined ? m.type : $root.cat.ReportType[m.type] : m.type;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.StatusType[m.status] === undefined ? m.status : $root.cat.StatusType[m.status] : m.status;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.flags && m.flags.length) {
                d.flags = [];
                for (var j = 0; j < m.flags.length; ++j) {
                    d.flags[j] = o.enums === String ? $root.cat.TemplateFlag[m.flags[j]] === undefined ? m.flags[j] : $root.cat.TemplateFlag[m.flags[j]] : m.flags[j];
                }
            }
            if (m.inputfields && m.inputfields.length) {
                d.inputfields = [];
                for (var j = 0; j < m.inputfields.length; ++j) {
                    d.inputfields[j] = $root.cat.ReportTextFieldMsg.toObject(m.inputfields[j], o);
                }
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.errors && m.errors.length) {
                d.errors = [];
                for (var j = 0; j < m.errors.length; ++j) {
                    d.errors[j] = o.enums === String ? $root.cat.TemplateError[m.errors[j]] === undefined ? m.errors[j] : $root.cat.TemplateError[m.errors[j]] : m.errors[j];
                }
            }
            if (m.language != null && m.hasOwnProperty("language")) {
                d.language = o.enums === String ? $root.cat.SupportedLanguages[m.language] === undefined ? m.language : $root.cat.SupportedLanguages[m.language] : m.language;
            }
            if (m.sampledata != null && m.hasOwnProperty("sampledata")) {
                d.sampledata = m.sampledata;
                if (o.oneofs)
                    d._sampledata = "sampledata";
            }
            if (m.jsonfile != null && m.hasOwnProperty("jsonfile")) {
                d.jsonfile = $root.cat.MediaFileMsg.toObject(m.jsonfile, o);
            }
            if (m.cssfile != null && m.hasOwnProperty("cssfile")) {
                d.cssfile = $root.cat.MediaFileMsg.toObject(m.cssfile, o);
            }
            if (m.logofile != null && m.hasOwnProperty("logofile")) {
                d.logofile = $root.cat.MediaFileMsg.toObject(m.logofile, o);
            }
            return d;
        };

        /**
         * Converts this ReportTemplateMsg to JSON.
         * @function toJSON
         * @memberof cat.ReportTemplateMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportTemplateMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportTemplateMsg
         * @function getTypeUrl
         * @memberof cat.ReportTemplateMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportTemplateMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReportTemplateMsg";
        };

        return ReportTemplateMsg;
    })();

    /**
     * TemplateFileType enum.
     * @name cat.TemplateFileType
     * @enum {number}
     * @property {number} TEMPLATEFILE_UNKNOWN=0 TEMPLATEFILE_UNKNOWN value
     * @property {number} TEMPLATEFILE_JSON=1 TEMPLATEFILE_JSON value
     * @property {number} TEMPLATEFILE_CSS=2 TEMPLATEFILE_CSS value
     * @property {number} TEMPLATEFILE_LOGO=3 TEMPLATEFILE_LOGO value
     */
    cat.TemplateFileType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TEMPLATEFILE_UNKNOWN"] = 0;
        values[valuesById[1] = "TEMPLATEFILE_JSON"] = 1;
        values[valuesById[2] = "TEMPLATEFILE_CSS"] = 2;
        values[valuesById[3] = "TEMPLATEFILE_LOGO"] = 3;
        return values;
    })();

    /**
     * ReportStatusType enum.
     * @name cat.ReportStatusType
     * @enum {number}
     * @property {number} REPORTSTATUS_UNKNOWN=0 REPORTSTATUS_UNKNOWN value
     * @property {number} REPORTSTATUS_NEW=1 REPORTSTATUS_NEW value
     * @property {number} REPORTSTATUS_WAITING=2 REPORTSTATUS_WAITING value
     * @property {number} REPORTSTATUS_RUNNING=3 REPORTSTATUS_RUNNING value
     * @property {number} REPORTSTATUS_COMPLETED=4 REPORTSTATUS_COMPLETED value
     * @property {number} REPORTSTATUS_CANCELLED=5 REPORTSTATUS_CANCELLED value
     * @property {number} REPORTSTATUS_FAILED=6 REPORTSTATUS_FAILED value
     * @property {number} REPORTSTATUS_FAILEDRUNNING=7 REPORTSTATUS_FAILEDRUNNING value
     */
    cat.ReportStatusType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REPORTSTATUS_UNKNOWN"] = 0;
        values[valuesById[1] = "REPORTSTATUS_NEW"] = 1;
        values[valuesById[2] = "REPORTSTATUS_WAITING"] = 2;
        values[valuesById[3] = "REPORTSTATUS_RUNNING"] = 3;
        values[valuesById[4] = "REPORTSTATUS_COMPLETED"] = 4;
        values[valuesById[5] = "REPORTSTATUS_CANCELLED"] = 5;
        values[valuesById[6] = "REPORTSTATUS_FAILED"] = 6;
        values[valuesById[7] = "REPORTSTATUS_FAILEDRUNNING"] = 7;
        return values;
    })();

    cat.ReportCountsMsg = (function() {

        /**
         * Properties of a ReportCountsMsg.
         * @memberof cat
         * @interface IReportCountsMsg
         * @property {number|null} [labels] ReportCountsMsg labels
         * @property {number|null} [conversations] ReportCountsMsg conversations
         * @property {number|null} [members] ReportCountsMsg members
         * @property {number|null} [messages] ReportCountsMsg messages
         * @property {number|null} [excludedmessages] ReportCountsMsg excludedmessages
         * @property {number|null} [mediafilebytes] ReportCountsMsg mediafilebytes
         * @property {number|null} [topics] ReportCountsMsg topics
         */

        /**
         * Constructs a new ReportCountsMsg.
         * @memberof cat
         * @classdesc Represents a ReportCountsMsg.
         * @implements IReportCountsMsg
         * @constructor
         * @param {cat.IReportCountsMsg=} [p] Properties to set
         */
        function ReportCountsMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReportCountsMsg labels.
         * @member {number} labels
         * @memberof cat.ReportCountsMsg
         * @instance
         */
        ReportCountsMsg.prototype.labels = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportCountsMsg conversations.
         * @member {number} conversations
         * @memberof cat.ReportCountsMsg
         * @instance
         */
        ReportCountsMsg.prototype.conversations = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportCountsMsg members.
         * @member {number} members
         * @memberof cat.ReportCountsMsg
         * @instance
         */
        ReportCountsMsg.prototype.members = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportCountsMsg messages.
         * @member {number} messages
         * @memberof cat.ReportCountsMsg
         * @instance
         */
        ReportCountsMsg.prototype.messages = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportCountsMsg excludedmessages.
         * @member {number} excludedmessages
         * @memberof cat.ReportCountsMsg
         * @instance
         */
        ReportCountsMsg.prototype.excludedmessages = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportCountsMsg mediafilebytes.
         * @member {number} mediafilebytes
         * @memberof cat.ReportCountsMsg
         * @instance
         */
        ReportCountsMsg.prototype.mediafilebytes = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportCountsMsg topics.
         * @member {number} topics
         * @memberof cat.ReportCountsMsg
         * @instance
         */
        ReportCountsMsg.prototype.topics = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ReportCountsMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReportCountsMsg
         * @static
         * @param {cat.IReportCountsMsg=} [properties] Properties to set
         * @returns {cat.ReportCountsMsg} ReportCountsMsg instance
         */
        ReportCountsMsg.create = function create(properties) {
            return new ReportCountsMsg(properties);
        };

        /**
         * Encodes the specified ReportCountsMsg message. Does not implicitly {@link cat.ReportCountsMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReportCountsMsg
         * @static
         * @param {cat.IReportCountsMsg} m ReportCountsMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportCountsMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.labels != null && Object.hasOwnProperty.call(m, "labels"))
                w.uint32(8).uint64(m.labels);
            if (m.conversations != null && Object.hasOwnProperty.call(m, "conversations"))
                w.uint32(16).uint64(m.conversations);
            if (m.members != null && Object.hasOwnProperty.call(m, "members"))
                w.uint32(24).uint64(m.members);
            if (m.messages != null && Object.hasOwnProperty.call(m, "messages"))
                w.uint32(32).uint64(m.messages);
            if (m.excludedmessages != null && Object.hasOwnProperty.call(m, "excludedmessages"))
                w.uint32(40).uint64(m.excludedmessages);
            if (m.mediafilebytes != null && Object.hasOwnProperty.call(m, "mediafilebytes"))
                w.uint32(48).uint64(m.mediafilebytes);
            if (m.topics != null && Object.hasOwnProperty.call(m, "topics"))
                w.uint32(56).uint64(m.topics);
            return w;
        };

        /**
         * Decodes a ReportCountsMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReportCountsMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReportCountsMsg} ReportCountsMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportCountsMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReportCountsMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.labels = r.uint64();
                        break;
                    }
                case 2: {
                        m.conversations = r.uint64();
                        break;
                    }
                case 3: {
                        m.members = r.uint64();
                        break;
                    }
                case 4: {
                        m.messages = r.uint64();
                        break;
                    }
                case 5: {
                        m.excludedmessages = r.uint64();
                        break;
                    }
                case 6: {
                        m.mediafilebytes = r.uint64();
                        break;
                    }
                case 7: {
                        m.topics = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReportCountsMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReportCountsMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReportCountsMsg} ReportCountsMsg
         */
        ReportCountsMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReportCountsMsg)
                return d;
            var m = new $root.cat.ReportCountsMsg();
            if (d.labels != null) {
                if ($util.Long)
                    (m.labels = $util.Long.fromValue(d.labels)).unsigned = true;
                else if (typeof d.labels === "string")
                    m.labels = parseInt(d.labels, 10);
                else if (typeof d.labels === "number")
                    m.labels = d.labels;
                else if (typeof d.labels === "object")
                    m.labels = new $util.LongBits(d.labels.low >>> 0, d.labels.high >>> 0).toNumber(true);
            }
            if (d.conversations != null) {
                if ($util.Long)
                    (m.conversations = $util.Long.fromValue(d.conversations)).unsigned = true;
                else if (typeof d.conversations === "string")
                    m.conversations = parseInt(d.conversations, 10);
                else if (typeof d.conversations === "number")
                    m.conversations = d.conversations;
                else if (typeof d.conversations === "object")
                    m.conversations = new $util.LongBits(d.conversations.low >>> 0, d.conversations.high >>> 0).toNumber(true);
            }
            if (d.members != null) {
                if ($util.Long)
                    (m.members = $util.Long.fromValue(d.members)).unsigned = true;
                else if (typeof d.members === "string")
                    m.members = parseInt(d.members, 10);
                else if (typeof d.members === "number")
                    m.members = d.members;
                else if (typeof d.members === "object")
                    m.members = new $util.LongBits(d.members.low >>> 0, d.members.high >>> 0).toNumber(true);
            }
            if (d.messages != null) {
                if ($util.Long)
                    (m.messages = $util.Long.fromValue(d.messages)).unsigned = true;
                else if (typeof d.messages === "string")
                    m.messages = parseInt(d.messages, 10);
                else if (typeof d.messages === "number")
                    m.messages = d.messages;
                else if (typeof d.messages === "object")
                    m.messages = new $util.LongBits(d.messages.low >>> 0, d.messages.high >>> 0).toNumber(true);
            }
            if (d.excludedmessages != null) {
                if ($util.Long)
                    (m.excludedmessages = $util.Long.fromValue(d.excludedmessages)).unsigned = true;
                else if (typeof d.excludedmessages === "string")
                    m.excludedmessages = parseInt(d.excludedmessages, 10);
                else if (typeof d.excludedmessages === "number")
                    m.excludedmessages = d.excludedmessages;
                else if (typeof d.excludedmessages === "object")
                    m.excludedmessages = new $util.LongBits(d.excludedmessages.low >>> 0, d.excludedmessages.high >>> 0).toNumber(true);
            }
            if (d.mediafilebytes != null) {
                if ($util.Long)
                    (m.mediafilebytes = $util.Long.fromValue(d.mediafilebytes)).unsigned = true;
                else if (typeof d.mediafilebytes === "string")
                    m.mediafilebytes = parseInt(d.mediafilebytes, 10);
                else if (typeof d.mediafilebytes === "number")
                    m.mediafilebytes = d.mediafilebytes;
                else if (typeof d.mediafilebytes === "object")
                    m.mediafilebytes = new $util.LongBits(d.mediafilebytes.low >>> 0, d.mediafilebytes.high >>> 0).toNumber(true);
            }
            if (d.topics != null) {
                if ($util.Long)
                    (m.topics = $util.Long.fromValue(d.topics)).unsigned = true;
                else if (typeof d.topics === "string")
                    m.topics = parseInt(d.topics, 10);
                else if (typeof d.topics === "number")
                    m.topics = d.topics;
                else if (typeof d.topics === "object")
                    m.topics = new $util.LongBits(d.topics.low >>> 0, d.topics.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a ReportCountsMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReportCountsMsg
         * @static
         * @param {cat.ReportCountsMsg} m ReportCountsMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportCountsMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.labels = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.labels = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.conversations = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.conversations = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.members = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.members = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.messages = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.messages = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.excludedmessages = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.excludedmessages = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.mediafilebytes = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.mediafilebytes = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.topics = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.topics = o.longs === String ? "0" : 0;
            }
            if (m.labels != null && m.hasOwnProperty("labels")) {
                if (typeof m.labels === "number")
                    d.labels = o.longs === String ? String(m.labels) : m.labels;
                else
                    d.labels = o.longs === String ? $util.Long.prototype.toString.call(m.labels) : o.longs === Number ? new $util.LongBits(m.labels.low >>> 0, m.labels.high >>> 0).toNumber(true) : m.labels;
            }
            if (m.conversations != null && m.hasOwnProperty("conversations")) {
                if (typeof m.conversations === "number")
                    d.conversations = o.longs === String ? String(m.conversations) : m.conversations;
                else
                    d.conversations = o.longs === String ? $util.Long.prototype.toString.call(m.conversations) : o.longs === Number ? new $util.LongBits(m.conversations.low >>> 0, m.conversations.high >>> 0).toNumber(true) : m.conversations;
            }
            if (m.members != null && m.hasOwnProperty("members")) {
                if (typeof m.members === "number")
                    d.members = o.longs === String ? String(m.members) : m.members;
                else
                    d.members = o.longs === String ? $util.Long.prototype.toString.call(m.members) : o.longs === Number ? new $util.LongBits(m.members.low >>> 0, m.members.high >>> 0).toNumber(true) : m.members;
            }
            if (m.messages != null && m.hasOwnProperty("messages")) {
                if (typeof m.messages === "number")
                    d.messages = o.longs === String ? String(m.messages) : m.messages;
                else
                    d.messages = o.longs === String ? $util.Long.prototype.toString.call(m.messages) : o.longs === Number ? new $util.LongBits(m.messages.low >>> 0, m.messages.high >>> 0).toNumber(true) : m.messages;
            }
            if (m.excludedmessages != null && m.hasOwnProperty("excludedmessages")) {
                if (typeof m.excludedmessages === "number")
                    d.excludedmessages = o.longs === String ? String(m.excludedmessages) : m.excludedmessages;
                else
                    d.excludedmessages = o.longs === String ? $util.Long.prototype.toString.call(m.excludedmessages) : o.longs === Number ? new $util.LongBits(m.excludedmessages.low >>> 0, m.excludedmessages.high >>> 0).toNumber(true) : m.excludedmessages;
            }
            if (m.mediafilebytes != null && m.hasOwnProperty("mediafilebytes")) {
                if (typeof m.mediafilebytes === "number")
                    d.mediafilebytes = o.longs === String ? String(m.mediafilebytes) : m.mediafilebytes;
                else
                    d.mediafilebytes = o.longs === String ? $util.Long.prototype.toString.call(m.mediafilebytes) : o.longs === Number ? new $util.LongBits(m.mediafilebytes.low >>> 0, m.mediafilebytes.high >>> 0).toNumber(true) : m.mediafilebytes;
            }
            if (m.topics != null && m.hasOwnProperty("topics")) {
                if (typeof m.topics === "number")
                    d.topics = o.longs === String ? String(m.topics) : m.topics;
                else
                    d.topics = o.longs === String ? $util.Long.prototype.toString.call(m.topics) : o.longs === Number ? new $util.LongBits(m.topics.low >>> 0, m.topics.high >>> 0).toNumber(true) : m.topics;
            }
            return d;
        };

        /**
         * Converts this ReportCountsMsg to JSON.
         * @function toJSON
         * @memberof cat.ReportCountsMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportCountsMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportCountsMsg
         * @function getTypeUrl
         * @memberof cat.ReportCountsMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportCountsMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReportCountsMsg";
        };

        return ReportCountsMsg;
    })();

    /**
     * ReportFileType enum.
     * @name cat.ReportFileType
     * @enum {number}
     * @property {number} REPORTFILE_UNKNOWN=0 REPORTFILE_UNKNOWN value
     * @property {number} REPORTFILE_PDF=1 REPORTFILE_PDF value
     * @property {number} REPORTFILE_JSON=2 REPORTFILE_JSON value
     * @property {number} REPORTFILE_HTML=3 REPORTFILE_HTML value
     * @property {number} REPORTFILE_ZIP=4 REPORTFILE_ZIP value
     * @property {number} REPORTFILE_LOG=5 REPORTFILE_LOG value
     */
    cat.ReportFileType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REPORTFILE_UNKNOWN"] = 0;
        values[valuesById[1] = "REPORTFILE_PDF"] = 1;
        values[valuesById[2] = "REPORTFILE_JSON"] = 2;
        values[valuesById[3] = "REPORTFILE_HTML"] = 3;
        values[valuesById[4] = "REPORTFILE_ZIP"] = 4;
        values[valuesById[5] = "REPORTFILE_LOG"] = 5;
        return values;
    })();

    cat.ReportMsg = (function() {

        /**
         * Properties of a ReportMsg.
         * @memberof cat
         * @interface IReportMsg
         * @property {string|null} [id] ReportMsg id
         * @property {number|null} [created] ReportMsg created
         * @property {number|null} [lastmodified] ReportMsg lastmodified
         * @property {number|null} [started] ReportMsg started
         * @property {number|null} [ended] ReportMsg ended
         * @property {cat.ReportStatusType|null} [status] ReportMsg status
         * @property {string|null} [statusdescription] ReportMsg statusdescription
         * @property {string|null} [name] ReportMsg name
         * @property {Array.<cat.ReportFileType>|null} [types] ReportMsg types
         * @property {Array.<cat.TemplateFlag>|null} [flags] ReportMsg flags
         * @property {Array.<cat.IReportTextFieldMsg>|null} [textfields] ReportMsg textfields
         * @property {boolean|null} [exportfiles] ReportMsg exportfiles
         * @property {boolean|null} [viewableexport] ReportMsg viewableexport
         * @property {number|null} [start] ReportMsg start
         * @property {number|null} [end] ReportMsg end
         * @property {cat.IReportCountsMsg|null} [counts] ReportMsg counts
         * @property {string|null} [tsapublickey] ReportMsg tsapublickey
         * @property {cat.IUserMsg|null} [user] ReportMsg user
         * @property {cat.IProfileMsg|null} [profile] ReportMsg profile
         * @property {cat.ICaseMsg|null} ["case"] ReportMsg case
         * @property {cat.IReportTemplateMsg|null} [template] ReportMsg template
         * @property {Array.<cat.IConversationMsg>|null} [conversations] ReportMsg conversations
         * @property {Array.<cat.ILabelMsg>|null} [labels] ReportMsg labels
         * @property {cat.IReportStatusMsg|null} [progresstatus] ReportMsg progresstatus
         * @property {cat.IMediaFileMsg|null} [htmlfile] ReportMsg htmlfile
         * @property {cat.IMediaFileMsg|null} [pdffile] ReportMsg pdffile
         * @property {cat.IMediaFileMsg|null} [zipfile] ReportMsg zipfile
         * @property {cat.IMediaFileMsg|null} [logfile] ReportMsg logfile
         * @property {cat.IMediaFileMsg|null} [jsonfile] ReportMsg jsonfile
         */

        /**
         * Constructs a new ReportMsg.
         * @memberof cat
         * @classdesc Represents a ReportMsg.
         * @implements IReportMsg
         * @constructor
         * @param {cat.IReportMsg=} [p] Properties to set
         */
        function ReportMsg(p) {
            this.types = [];
            this.flags = [];
            this.textfields = [];
            this.conversations = [];
            this.labels = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReportMsg id.
         * @member {string} id
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.id = "";

        /**
         * ReportMsg created.
         * @member {number} created
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportMsg started.
         * @member {number} started
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.started = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportMsg ended.
         * @member {number} ended
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.ended = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportMsg status.
         * @member {cat.ReportStatusType} status
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.status = 0;

        /**
         * ReportMsg statusdescription.
         * @member {string} statusdescription
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.statusdescription = "";

        /**
         * ReportMsg name.
         * @member {string} name
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.name = "";

        /**
         * ReportMsg types.
         * @member {Array.<cat.ReportFileType>} types
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.types = $util.emptyArray;

        /**
         * ReportMsg flags.
         * @member {Array.<cat.TemplateFlag>} flags
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.flags = $util.emptyArray;

        /**
         * ReportMsg textfields.
         * @member {Array.<cat.IReportTextFieldMsg>} textfields
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.textfields = $util.emptyArray;

        /**
         * ReportMsg exportfiles.
         * @member {boolean} exportfiles
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.exportfiles = false;

        /**
         * ReportMsg viewableexport.
         * @member {boolean} viewableexport
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.viewableexport = false;

        /**
         * ReportMsg start.
         * @member {number} start
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.start = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportMsg end.
         * @member {number} end
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.end = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReportMsg counts.
         * @member {cat.IReportCountsMsg|null|undefined} counts
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.counts = null;

        /**
         * ReportMsg tsapublickey.
         * @member {string} tsapublickey
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.tsapublickey = "";

        /**
         * ReportMsg user.
         * @member {cat.IUserMsg|null|undefined} user
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.user = null;

        /**
         * ReportMsg profile.
         * @member {cat.IProfileMsg|null|undefined} profile
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.profile = null;

        /**
         * ReportMsg case.
         * @member {cat.ICaseMsg|null|undefined} case
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype["case"] = null;

        /**
         * ReportMsg template.
         * @member {cat.IReportTemplateMsg|null|undefined} template
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.template = null;

        /**
         * ReportMsg conversations.
         * @member {Array.<cat.IConversationMsg>} conversations
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.conversations = $util.emptyArray;

        /**
         * ReportMsg labels.
         * @member {Array.<cat.ILabelMsg>} labels
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.labels = $util.emptyArray;

        /**
         * ReportMsg progresstatus.
         * @member {cat.IReportStatusMsg|null|undefined} progresstatus
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.progresstatus = null;

        /**
         * ReportMsg htmlfile.
         * @member {cat.IMediaFileMsg|null|undefined} htmlfile
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.htmlfile = null;

        /**
         * ReportMsg pdffile.
         * @member {cat.IMediaFileMsg|null|undefined} pdffile
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.pdffile = null;

        /**
         * ReportMsg zipfile.
         * @member {cat.IMediaFileMsg|null|undefined} zipfile
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.zipfile = null;

        /**
         * ReportMsg logfile.
         * @member {cat.IMediaFileMsg|null|undefined} logfile
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.logfile = null;

        /**
         * ReportMsg jsonfile.
         * @member {cat.IMediaFileMsg|null|undefined} jsonfile
         * @memberof cat.ReportMsg
         * @instance
         */
        ReportMsg.prototype.jsonfile = null;

        /**
         * Creates a new ReportMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReportMsg
         * @static
         * @param {cat.IReportMsg=} [properties] Properties to set
         * @returns {cat.ReportMsg} ReportMsg instance
         */
        ReportMsg.create = function create(properties) {
            return new ReportMsg(properties);
        };

        /**
         * Encodes the specified ReportMsg message. Does not implicitly {@link cat.ReportMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReportMsg
         * @static
         * @param {cat.IReportMsg} m ReportMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(16).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(24).uint64(m.lastmodified);
            if (m.started != null && Object.hasOwnProperty.call(m, "started"))
                w.uint32(32).uint64(m.started);
            if (m.ended != null && Object.hasOwnProperty.call(m, "ended"))
                w.uint32(40).uint64(m.ended);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(48).int32(m.status);
            if (m.statusdescription != null && Object.hasOwnProperty.call(m, "statusdescription"))
                w.uint32(58).string(m.statusdescription);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(66).string(m.name);
            if (m.types != null && m.types.length) {
                w.uint32(74).fork();
                for (var i = 0; i < m.types.length; ++i)
                    w.int32(m.types[i]);
                w.ldelim();
            }
            if (m.flags != null && m.flags.length) {
                w.uint32(82).fork();
                for (var i = 0; i < m.flags.length; ++i)
                    w.int32(m.flags[i]);
                w.ldelim();
            }
            if (m.textfields != null && m.textfields.length) {
                for (var i = 0; i < m.textfields.length; ++i)
                    $root.cat.ReportTextFieldMsg.encode(m.textfields[i], w.uint32(90).fork()).ldelim();
            }
            if (m.exportfiles != null && Object.hasOwnProperty.call(m, "exportfiles"))
                w.uint32(96).bool(m.exportfiles);
            if (m.viewableexport != null && Object.hasOwnProperty.call(m, "viewableexport"))
                w.uint32(104).bool(m.viewableexport);
            if (m.start != null && Object.hasOwnProperty.call(m, "start"))
                w.uint32(112).uint64(m.start);
            if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                w.uint32(120).uint64(m.end);
            if (m.counts != null && Object.hasOwnProperty.call(m, "counts"))
                $root.cat.ReportCountsMsg.encode(m.counts, w.uint32(138).fork()).ldelim();
            if (m.tsapublickey != null && Object.hasOwnProperty.call(m, "tsapublickey"))
                w.uint32(146).string(m.tsapublickey);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.cat.UserMsg.encode(m.user, w.uint32(810).fork()).ldelim();
            if (m.profile != null && Object.hasOwnProperty.call(m, "profile"))
                $root.cat.ProfileMsg.encode(m.profile, w.uint32(818).fork()).ldelim();
            if (m["case"] != null && Object.hasOwnProperty.call(m, "case"))
                $root.cat.CaseMsg.encode(m["case"], w.uint32(826).fork()).ldelim();
            if (m.template != null && Object.hasOwnProperty.call(m, "template"))
                $root.cat.ReportTemplateMsg.encode(m.template, w.uint32(834).fork()).ldelim();
            if (m.conversations != null && m.conversations.length) {
                for (var i = 0; i < m.conversations.length; ++i)
                    $root.cat.ConversationMsg.encode(m.conversations[i], w.uint32(850).fork()).ldelim();
            }
            if (m.labels != null && m.labels.length) {
                for (var i = 0; i < m.labels.length; ++i)
                    $root.cat.LabelMsg.encode(m.labels[i], w.uint32(858).fork()).ldelim();
            }
            if (m.progresstatus != null && Object.hasOwnProperty.call(m, "progresstatus"))
                $root.cat.ReportStatusMsg.encode(m.progresstatus, w.uint32(866).fork()).ldelim();
            if (m.htmlfile != null && Object.hasOwnProperty.call(m, "htmlfile"))
                $root.cat.MediaFileMsg.encode(m.htmlfile, w.uint32(874).fork()).ldelim();
            if (m.pdffile != null && Object.hasOwnProperty.call(m, "pdffile"))
                $root.cat.MediaFileMsg.encode(m.pdffile, w.uint32(882).fork()).ldelim();
            if (m.zipfile != null && Object.hasOwnProperty.call(m, "zipfile"))
                $root.cat.MediaFileMsg.encode(m.zipfile, w.uint32(890).fork()).ldelim();
            if (m.logfile != null && Object.hasOwnProperty.call(m, "logfile"))
                $root.cat.MediaFileMsg.encode(m.logfile, w.uint32(898).fork()).ldelim();
            if (m.jsonfile != null && Object.hasOwnProperty.call(m, "jsonfile"))
                $root.cat.MediaFileMsg.encode(m.jsonfile, w.uint32(906).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ReportMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReportMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReportMsg} ReportMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReportMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.created = r.uint64();
                        break;
                    }
                case 3: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 4: {
                        m.started = r.uint64();
                        break;
                    }
                case 5: {
                        m.ended = r.uint64();
                        break;
                    }
                case 6: {
                        m.status = r.int32();
                        break;
                    }
                case 7: {
                        m.statusdescription = r.string();
                        break;
                    }
                case 8: {
                        m.name = r.string();
                        break;
                    }
                case 9: {
                        if (!(m.types && m.types.length))
                            m.types = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.types.push(r.int32());
                        } else
                            m.types.push(r.int32());
                        break;
                    }
                case 10: {
                        if (!(m.flags && m.flags.length))
                            m.flags = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.flags.push(r.int32());
                        } else
                            m.flags.push(r.int32());
                        break;
                    }
                case 11: {
                        if (!(m.textfields && m.textfields.length))
                            m.textfields = [];
                        m.textfields.push($root.cat.ReportTextFieldMsg.decode(r, r.uint32()));
                        break;
                    }
                case 12: {
                        m.exportfiles = r.bool();
                        break;
                    }
                case 13: {
                        m.viewableexport = r.bool();
                        break;
                    }
                case 14: {
                        m.start = r.uint64();
                        break;
                    }
                case 15: {
                        m.end = r.uint64();
                        break;
                    }
                case 17: {
                        m.counts = $root.cat.ReportCountsMsg.decode(r, r.uint32());
                        break;
                    }
                case 18: {
                        m.tsapublickey = r.string();
                        break;
                    }
                case 101: {
                        m.user = $root.cat.UserMsg.decode(r, r.uint32());
                        break;
                    }
                case 102: {
                        m.profile = $root.cat.ProfileMsg.decode(r, r.uint32());
                        break;
                    }
                case 103: {
                        m["case"] = $root.cat.CaseMsg.decode(r, r.uint32());
                        break;
                    }
                case 104: {
                        m.template = $root.cat.ReportTemplateMsg.decode(r, r.uint32());
                        break;
                    }
                case 106: {
                        if (!(m.conversations && m.conversations.length))
                            m.conversations = [];
                        m.conversations.push($root.cat.ConversationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 107: {
                        if (!(m.labels && m.labels.length))
                            m.labels = [];
                        m.labels.push($root.cat.LabelMsg.decode(r, r.uint32()));
                        break;
                    }
                case 108: {
                        m.progresstatus = $root.cat.ReportStatusMsg.decode(r, r.uint32());
                        break;
                    }
                case 109: {
                        m.htmlfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 110: {
                        m.pdffile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 111: {
                        m.zipfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 112: {
                        m.logfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 113: {
                        m.jsonfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReportMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReportMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReportMsg} ReportMsg
         */
        ReportMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReportMsg)
                return d;
            var m = new $root.cat.ReportMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.started != null) {
                if ($util.Long)
                    (m.started = $util.Long.fromValue(d.started)).unsigned = true;
                else if (typeof d.started === "string")
                    m.started = parseInt(d.started, 10);
                else if (typeof d.started === "number")
                    m.started = d.started;
                else if (typeof d.started === "object")
                    m.started = new $util.LongBits(d.started.low >>> 0, d.started.high >>> 0).toNumber(true);
            }
            if (d.ended != null) {
                if ($util.Long)
                    (m.ended = $util.Long.fromValue(d.ended)).unsigned = true;
                else if (typeof d.ended === "string")
                    m.ended = parseInt(d.ended, 10);
                else if (typeof d.ended === "number")
                    m.ended = d.ended;
                else if (typeof d.ended === "object")
                    m.ended = new $util.LongBits(d.ended.low >>> 0, d.ended.high >>> 0).toNumber(true);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "REPORTSTATUS_UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "REPORTSTATUS_NEW":
            case 1:
                m.status = 1;
                break;
            case "REPORTSTATUS_WAITING":
            case 2:
                m.status = 2;
                break;
            case "REPORTSTATUS_RUNNING":
            case 3:
                m.status = 3;
                break;
            case "REPORTSTATUS_COMPLETED":
            case 4:
                m.status = 4;
                break;
            case "REPORTSTATUS_CANCELLED":
            case 5:
                m.status = 5;
                break;
            case "REPORTSTATUS_FAILED":
            case 6:
                m.status = 6;
                break;
            case "REPORTSTATUS_FAILEDRUNNING":
            case 7:
                m.status = 7;
                break;
            }
            if (d.statusdescription != null) {
                m.statusdescription = String(d.statusdescription);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.types) {
                if (!Array.isArray(d.types))
                    throw TypeError(".cat.ReportMsg.types: array expected");
                m.types = [];
                for (var i = 0; i < d.types.length; ++i) {
                    switch (d.types[i]) {
                    default:
                        if (typeof d.types[i] === "number") {
                            m.types[i] = d.types[i];
                            break;
                        }
                    case "REPORTFILE_UNKNOWN":
                    case 0:
                        m.types[i] = 0;
                        break;
                    case "REPORTFILE_PDF":
                    case 1:
                        m.types[i] = 1;
                        break;
                    case "REPORTFILE_JSON":
                    case 2:
                        m.types[i] = 2;
                        break;
                    case "REPORTFILE_HTML":
                    case 3:
                        m.types[i] = 3;
                        break;
                    case "REPORTFILE_ZIP":
                    case 4:
                        m.types[i] = 4;
                        break;
                    case "REPORTFILE_LOG":
                    case 5:
                        m.types[i] = 5;
                        break;
                    }
                }
            }
            if (d.flags) {
                if (!Array.isArray(d.flags))
                    throw TypeError(".cat.ReportMsg.flags: array expected");
                m.flags = [];
                for (var i = 0; i < d.flags.length; ++i) {
                    switch (d.flags[i]) {
                    default:
                        if (typeof d.flags[i] === "number") {
                            m.flags[i] = d.flags[i];
                            break;
                        }
                    case "TEMPLATEFLAG_UNKNOWN":
                    case 0:
                        m.flags[i] = 0;
                        break;
                    case "TEMPLATEFLAG_ANNOTATIONS":
                    case 1:
                        m.flags[i] = 1;
                        break;
                    case "TEMPLATEFLAG_LABELS":
                    case 2:
                        m.flags[i] = 2;
                        break;
                    case "TEMPLATEFLAG_TAGS":
                    case 3:
                        m.flags[i] = 3;
                        break;
                    case "TEMPLATEFLAG_HASHTAGS":
                    case 4:
                        m.flags[i] = 4;
                        break;
                    case "TEMPLATEFLAG_URLS":
                    case 5:
                        m.flags[i] = 5;
                        break;
                    case "TEMPLATEFLAG_MENTIONS":
                    case 6:
                        m.flags[i] = 6;
                        break;
                    case "TEMPLATEFLAG_MEMBERS":
                    case 7:
                        m.flags[i] = 7;
                        break;
                    case "TEMPLATEFLAG_REACTIONS":
                    case 8:
                        m.flags[i] = 8;
                        break;
                    case "TEMPLATEFLAG_FILEHASHES":
                    case 9:
                        m.flags[i] = 9;
                        break;
                    case "TEMPLATEFLAG_HIDEEXCLUDEDMESSAGES":
                    case 10:
                        m.flags[i] = 10;
                        break;
                    case "TEMPLATEFLAG_ALIAS":
                    case 11:
                        m.flags[i] = 11;
                        break;
                    }
                }
            }
            if (d.textfields) {
                if (!Array.isArray(d.textfields))
                    throw TypeError(".cat.ReportMsg.textfields: array expected");
                m.textfields = [];
                for (var i = 0; i < d.textfields.length; ++i) {
                    if (typeof d.textfields[i] !== "object")
                        throw TypeError(".cat.ReportMsg.textfields: object expected");
                    m.textfields[i] = $root.cat.ReportTextFieldMsg.fromObject(d.textfields[i]);
                }
            }
            if (d.exportfiles != null) {
                m.exportfiles = Boolean(d.exportfiles);
            }
            if (d.viewableexport != null) {
                m.viewableexport = Boolean(d.viewableexport);
            }
            if (d.start != null) {
                if ($util.Long)
                    (m.start = $util.Long.fromValue(d.start)).unsigned = true;
                else if (typeof d.start === "string")
                    m.start = parseInt(d.start, 10);
                else if (typeof d.start === "number")
                    m.start = d.start;
                else if (typeof d.start === "object")
                    m.start = new $util.LongBits(d.start.low >>> 0, d.start.high >>> 0).toNumber(true);
            }
            if (d.end != null) {
                if ($util.Long)
                    (m.end = $util.Long.fromValue(d.end)).unsigned = true;
                else if (typeof d.end === "string")
                    m.end = parseInt(d.end, 10);
                else if (typeof d.end === "number")
                    m.end = d.end;
                else if (typeof d.end === "object")
                    m.end = new $util.LongBits(d.end.low >>> 0, d.end.high >>> 0).toNumber(true);
            }
            if (d.counts != null) {
                if (typeof d.counts !== "object")
                    throw TypeError(".cat.ReportMsg.counts: object expected");
                m.counts = $root.cat.ReportCountsMsg.fromObject(d.counts);
            }
            if (d.tsapublickey != null) {
                m.tsapublickey = String(d.tsapublickey);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".cat.ReportMsg.user: object expected");
                m.user = $root.cat.UserMsg.fromObject(d.user);
            }
            if (d.profile != null) {
                if (typeof d.profile !== "object")
                    throw TypeError(".cat.ReportMsg.profile: object expected");
                m.profile = $root.cat.ProfileMsg.fromObject(d.profile);
            }
            if (d["case"] != null) {
                if (typeof d["case"] !== "object")
                    throw TypeError(".cat.ReportMsg.case: object expected");
                m["case"] = $root.cat.CaseMsg.fromObject(d["case"]);
            }
            if (d.template != null) {
                if (typeof d.template !== "object")
                    throw TypeError(".cat.ReportMsg.template: object expected");
                m.template = $root.cat.ReportTemplateMsg.fromObject(d.template);
            }
            if (d.conversations) {
                if (!Array.isArray(d.conversations))
                    throw TypeError(".cat.ReportMsg.conversations: array expected");
                m.conversations = [];
                for (var i = 0; i < d.conversations.length; ++i) {
                    if (typeof d.conversations[i] !== "object")
                        throw TypeError(".cat.ReportMsg.conversations: object expected");
                    m.conversations[i] = $root.cat.ConversationMsg.fromObject(d.conversations[i]);
                }
            }
            if (d.labels) {
                if (!Array.isArray(d.labels))
                    throw TypeError(".cat.ReportMsg.labels: array expected");
                m.labels = [];
                for (var i = 0; i < d.labels.length; ++i) {
                    if (typeof d.labels[i] !== "object")
                        throw TypeError(".cat.ReportMsg.labels: object expected");
                    m.labels[i] = $root.cat.LabelMsg.fromObject(d.labels[i]);
                }
            }
            if (d.progresstatus != null) {
                if (typeof d.progresstatus !== "object")
                    throw TypeError(".cat.ReportMsg.progresstatus: object expected");
                m.progresstatus = $root.cat.ReportStatusMsg.fromObject(d.progresstatus);
            }
            if (d.htmlfile != null) {
                if (typeof d.htmlfile !== "object")
                    throw TypeError(".cat.ReportMsg.htmlfile: object expected");
                m.htmlfile = $root.cat.MediaFileMsg.fromObject(d.htmlfile);
            }
            if (d.pdffile != null) {
                if (typeof d.pdffile !== "object")
                    throw TypeError(".cat.ReportMsg.pdffile: object expected");
                m.pdffile = $root.cat.MediaFileMsg.fromObject(d.pdffile);
            }
            if (d.zipfile != null) {
                if (typeof d.zipfile !== "object")
                    throw TypeError(".cat.ReportMsg.zipfile: object expected");
                m.zipfile = $root.cat.MediaFileMsg.fromObject(d.zipfile);
            }
            if (d.logfile != null) {
                if (typeof d.logfile !== "object")
                    throw TypeError(".cat.ReportMsg.logfile: object expected");
                m.logfile = $root.cat.MediaFileMsg.fromObject(d.logfile);
            }
            if (d.jsonfile != null) {
                if (typeof d.jsonfile !== "object")
                    throw TypeError(".cat.ReportMsg.jsonfile: object expected");
                m.jsonfile = $root.cat.MediaFileMsg.fromObject(d.jsonfile);
            }
            return m;
        };

        /**
         * Creates a plain object from a ReportMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReportMsg
         * @static
         * @param {cat.ReportMsg} m ReportMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.types = [];
                d.flags = [];
                d.textfields = [];
                d.conversations = [];
                d.labels = [];
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.started = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.started = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.ended = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ended = o.longs === String ? "0" : 0;
                d.status = o.enums === String ? "REPORTSTATUS_UNKNOWN" : 0;
                d.statusdescription = "";
                d.name = "";
                d.exportfiles = false;
                d.viewableexport = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.start = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.start = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.end = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.end = o.longs === String ? "0" : 0;
                d.counts = null;
                d.tsapublickey = "";
                d.user = null;
                d.profile = null;
                d["case"] = null;
                d.template = null;
                d.progresstatus = null;
                d.htmlfile = null;
                d.pdffile = null;
                d.zipfile = null;
                d.logfile = null;
                d.jsonfile = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.started != null && m.hasOwnProperty("started")) {
                if (typeof m.started === "number")
                    d.started = o.longs === String ? String(m.started) : m.started;
                else
                    d.started = o.longs === String ? $util.Long.prototype.toString.call(m.started) : o.longs === Number ? new $util.LongBits(m.started.low >>> 0, m.started.high >>> 0).toNumber(true) : m.started;
            }
            if (m.ended != null && m.hasOwnProperty("ended")) {
                if (typeof m.ended === "number")
                    d.ended = o.longs === String ? String(m.ended) : m.ended;
                else
                    d.ended = o.longs === String ? $util.Long.prototype.toString.call(m.ended) : o.longs === Number ? new $util.LongBits(m.ended.low >>> 0, m.ended.high >>> 0).toNumber(true) : m.ended;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.ReportStatusType[m.status] === undefined ? m.status : $root.cat.ReportStatusType[m.status] : m.status;
            }
            if (m.statusdescription != null && m.hasOwnProperty("statusdescription")) {
                d.statusdescription = m.statusdescription;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.types && m.types.length) {
                d.types = [];
                for (var j = 0; j < m.types.length; ++j) {
                    d.types[j] = o.enums === String ? $root.cat.ReportFileType[m.types[j]] === undefined ? m.types[j] : $root.cat.ReportFileType[m.types[j]] : m.types[j];
                }
            }
            if (m.flags && m.flags.length) {
                d.flags = [];
                for (var j = 0; j < m.flags.length; ++j) {
                    d.flags[j] = o.enums === String ? $root.cat.TemplateFlag[m.flags[j]] === undefined ? m.flags[j] : $root.cat.TemplateFlag[m.flags[j]] : m.flags[j];
                }
            }
            if (m.textfields && m.textfields.length) {
                d.textfields = [];
                for (var j = 0; j < m.textfields.length; ++j) {
                    d.textfields[j] = $root.cat.ReportTextFieldMsg.toObject(m.textfields[j], o);
                }
            }
            if (m.exportfiles != null && m.hasOwnProperty("exportfiles")) {
                d.exportfiles = m.exportfiles;
            }
            if (m.viewableexport != null && m.hasOwnProperty("viewableexport")) {
                d.viewableexport = m.viewableexport;
            }
            if (m.start != null && m.hasOwnProperty("start")) {
                if (typeof m.start === "number")
                    d.start = o.longs === String ? String(m.start) : m.start;
                else
                    d.start = o.longs === String ? $util.Long.prototype.toString.call(m.start) : o.longs === Number ? new $util.LongBits(m.start.low >>> 0, m.start.high >>> 0).toNumber(true) : m.start;
            }
            if (m.end != null && m.hasOwnProperty("end")) {
                if (typeof m.end === "number")
                    d.end = o.longs === String ? String(m.end) : m.end;
                else
                    d.end = o.longs === String ? $util.Long.prototype.toString.call(m.end) : o.longs === Number ? new $util.LongBits(m.end.low >>> 0, m.end.high >>> 0).toNumber(true) : m.end;
            }
            if (m.counts != null && m.hasOwnProperty("counts")) {
                d.counts = $root.cat.ReportCountsMsg.toObject(m.counts, o);
            }
            if (m.tsapublickey != null && m.hasOwnProperty("tsapublickey")) {
                d.tsapublickey = m.tsapublickey;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.cat.UserMsg.toObject(m.user, o);
            }
            if (m.profile != null && m.hasOwnProperty("profile")) {
                d.profile = $root.cat.ProfileMsg.toObject(m.profile, o);
            }
            if (m["case"] != null && m.hasOwnProperty("case")) {
                d["case"] = $root.cat.CaseMsg.toObject(m["case"], o);
            }
            if (m.template != null && m.hasOwnProperty("template")) {
                d.template = $root.cat.ReportTemplateMsg.toObject(m.template, o);
            }
            if (m.conversations && m.conversations.length) {
                d.conversations = [];
                for (var j = 0; j < m.conversations.length; ++j) {
                    d.conversations[j] = $root.cat.ConversationMsg.toObject(m.conversations[j], o);
                }
            }
            if (m.labels && m.labels.length) {
                d.labels = [];
                for (var j = 0; j < m.labels.length; ++j) {
                    d.labels[j] = $root.cat.LabelMsg.toObject(m.labels[j], o);
                }
            }
            if (m.progresstatus != null && m.hasOwnProperty("progresstatus")) {
                d.progresstatus = $root.cat.ReportStatusMsg.toObject(m.progresstatus, o);
            }
            if (m.htmlfile != null && m.hasOwnProperty("htmlfile")) {
                d.htmlfile = $root.cat.MediaFileMsg.toObject(m.htmlfile, o);
            }
            if (m.pdffile != null && m.hasOwnProperty("pdffile")) {
                d.pdffile = $root.cat.MediaFileMsg.toObject(m.pdffile, o);
            }
            if (m.zipfile != null && m.hasOwnProperty("zipfile")) {
                d.zipfile = $root.cat.MediaFileMsg.toObject(m.zipfile, o);
            }
            if (m.logfile != null && m.hasOwnProperty("logfile")) {
                d.logfile = $root.cat.MediaFileMsg.toObject(m.logfile, o);
            }
            if (m.jsonfile != null && m.hasOwnProperty("jsonfile")) {
                d.jsonfile = $root.cat.MediaFileMsg.toObject(m.jsonfile, o);
            }
            return d;
        };

        /**
         * Converts this ReportMsg to JSON.
         * @function toJSON
         * @memberof cat.ReportMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportMsg
         * @function getTypeUrl
         * @memberof cat.ReportMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReportMsg";
        };

        return ReportMsg;
    })();

    cat.ReportStatusMsg = (function() {

        /**
         * Properties of a ReportStatusMsg.
         * @memberof cat
         * @interface IReportStatusMsg
         * @property {cat.ReportStatusType|null} [status] ReportStatusMsg status
         * @property {number|null} [progress] ReportStatusMsg progress
         * @property {number|null} [currentstage] ReportStatusMsg currentstage
         * @property {number|null} [totalstages] ReportStatusMsg totalstages
         */

        /**
         * Constructs a new ReportStatusMsg.
         * @memberof cat
         * @classdesc Represents a ReportStatusMsg.
         * @implements IReportStatusMsg
         * @constructor
         * @param {cat.IReportStatusMsg=} [p] Properties to set
         */
        function ReportStatusMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReportStatusMsg status.
         * @member {cat.ReportStatusType} status
         * @memberof cat.ReportStatusMsg
         * @instance
         */
        ReportStatusMsg.prototype.status = 0;

        /**
         * ReportStatusMsg progress.
         * @member {number} progress
         * @memberof cat.ReportStatusMsg
         * @instance
         */
        ReportStatusMsg.prototype.progress = 0;

        /**
         * ReportStatusMsg currentstage.
         * @member {number} currentstage
         * @memberof cat.ReportStatusMsg
         * @instance
         */
        ReportStatusMsg.prototype.currentstage = 0;

        /**
         * ReportStatusMsg totalstages.
         * @member {number} totalstages
         * @memberof cat.ReportStatusMsg
         * @instance
         */
        ReportStatusMsg.prototype.totalstages = 0;

        /**
         * Creates a new ReportStatusMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReportStatusMsg
         * @static
         * @param {cat.IReportStatusMsg=} [properties] Properties to set
         * @returns {cat.ReportStatusMsg} ReportStatusMsg instance
         */
        ReportStatusMsg.create = function create(properties) {
            return new ReportStatusMsg(properties);
        };

        /**
         * Encodes the specified ReportStatusMsg message. Does not implicitly {@link cat.ReportStatusMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReportStatusMsg
         * @static
         * @param {cat.IReportStatusMsg} m ReportStatusMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportStatusMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(8).int32(m.status);
            if (m.progress != null && Object.hasOwnProperty.call(m, "progress"))
                w.uint32(16).uint32(m.progress);
            if (m.currentstage != null && Object.hasOwnProperty.call(m, "currentstage"))
                w.uint32(24).uint32(m.currentstage);
            if (m.totalstages != null && Object.hasOwnProperty.call(m, "totalstages"))
                w.uint32(32).uint32(m.totalstages);
            return w;
        };

        /**
         * Decodes a ReportStatusMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReportStatusMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReportStatusMsg} ReportStatusMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportStatusMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReportStatusMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.status = r.int32();
                        break;
                    }
                case 2: {
                        m.progress = r.uint32();
                        break;
                    }
                case 3: {
                        m.currentstage = r.uint32();
                        break;
                    }
                case 4: {
                        m.totalstages = r.uint32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReportStatusMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReportStatusMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReportStatusMsg} ReportStatusMsg
         */
        ReportStatusMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReportStatusMsg)
                return d;
            var m = new $root.cat.ReportStatusMsg();
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "REPORTSTATUS_UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "REPORTSTATUS_NEW":
            case 1:
                m.status = 1;
                break;
            case "REPORTSTATUS_WAITING":
            case 2:
                m.status = 2;
                break;
            case "REPORTSTATUS_RUNNING":
            case 3:
                m.status = 3;
                break;
            case "REPORTSTATUS_COMPLETED":
            case 4:
                m.status = 4;
                break;
            case "REPORTSTATUS_CANCELLED":
            case 5:
                m.status = 5;
                break;
            case "REPORTSTATUS_FAILED":
            case 6:
                m.status = 6;
                break;
            case "REPORTSTATUS_FAILEDRUNNING":
            case 7:
                m.status = 7;
                break;
            }
            if (d.progress != null) {
                m.progress = d.progress >>> 0;
            }
            if (d.currentstage != null) {
                m.currentstage = d.currentstage >>> 0;
            }
            if (d.totalstages != null) {
                m.totalstages = d.totalstages >>> 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ReportStatusMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReportStatusMsg
         * @static
         * @param {cat.ReportStatusMsg} m ReportStatusMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportStatusMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.status = o.enums === String ? "REPORTSTATUS_UNKNOWN" : 0;
                d.progress = 0;
                d.currentstage = 0;
                d.totalstages = 0;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.ReportStatusType[m.status] === undefined ? m.status : $root.cat.ReportStatusType[m.status] : m.status;
            }
            if (m.progress != null && m.hasOwnProperty("progress")) {
                d.progress = m.progress;
            }
            if (m.currentstage != null && m.hasOwnProperty("currentstage")) {
                d.currentstage = m.currentstage;
            }
            if (m.totalstages != null && m.hasOwnProperty("totalstages")) {
                d.totalstages = m.totalstages;
            }
            return d;
        };

        /**
         * Converts this ReportStatusMsg to JSON.
         * @function toJSON
         * @memberof cat.ReportStatusMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportStatusMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportStatusMsg
         * @function getTypeUrl
         * @memberof cat.ReportStatusMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportStatusMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReportStatusMsg";
        };

        return ReportStatusMsg;
    })();

    /**
     * TextFieldType enum.
     * @name cat.TextFieldType
     * @enum {number}
     * @property {number} TEXTFIELD_UNKNOWN=0 TEXTFIELD_UNKNOWN value
     * @property {number} TEXTFIELD_INPUT=1 TEXTFIELD_INPUT value
     * @property {number} TEXTFIELD_MULTILINE_INPUT=2 TEXTFIELD_MULTILINE_INPUT value
     * @property {number} TEXTFIELD_REPORT_TITLE_INPUT=3 TEXTFIELD_REPORT_TITLE_INPUT value
     * @property {number} TEXTFIELD_HEADING_INPUT=4 TEXTFIELD_HEADING_INPUT value
     * @property {number} TEXTFIELD_REPORT_SUBTITLE_INPUT=5 TEXTFIELD_REPORT_SUBTITLE_INPUT value
     */
    cat.TextFieldType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TEXTFIELD_UNKNOWN"] = 0;
        values[valuesById[1] = "TEXTFIELD_INPUT"] = 1;
        values[valuesById[2] = "TEXTFIELD_MULTILINE_INPUT"] = 2;
        values[valuesById[3] = "TEXTFIELD_REPORT_TITLE_INPUT"] = 3;
        values[valuesById[4] = "TEXTFIELD_HEADING_INPUT"] = 4;
        values[valuesById[5] = "TEXTFIELD_REPORT_SUBTITLE_INPUT"] = 5;
        return values;
    })();

    cat.ReportTextFieldMsg = (function() {

        /**
         * Properties of a ReportTextFieldMsg.
         * @memberof cat
         * @interface IReportTextFieldMsg
         * @property {string|null} [name] ReportTextFieldMsg name
         * @property {string|null} [label] ReportTextFieldMsg label
         * @property {cat.TextFieldType|null} [type] ReportTextFieldMsg type
         * @property {string|null} [content] ReportTextFieldMsg content
         * @property {string|null} [component] ReportTextFieldMsg component
         * @property {boolean|null} [required] ReportTextFieldMsg required
         */

        /**
         * Constructs a new ReportTextFieldMsg.
         * @memberof cat
         * @classdesc Represents a ReportTextFieldMsg.
         * @implements IReportTextFieldMsg
         * @constructor
         * @param {cat.IReportTextFieldMsg=} [p] Properties to set
         */
        function ReportTextFieldMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReportTextFieldMsg name.
         * @member {string} name
         * @memberof cat.ReportTextFieldMsg
         * @instance
         */
        ReportTextFieldMsg.prototype.name = "";

        /**
         * ReportTextFieldMsg label.
         * @member {string|null|undefined} label
         * @memberof cat.ReportTextFieldMsg
         * @instance
         */
        ReportTextFieldMsg.prototype.label = null;

        /**
         * ReportTextFieldMsg type.
         * @member {cat.TextFieldType} type
         * @memberof cat.ReportTextFieldMsg
         * @instance
         */
        ReportTextFieldMsg.prototype.type = 0;

        /**
         * ReportTextFieldMsg content.
         * @member {string} content
         * @memberof cat.ReportTextFieldMsg
         * @instance
         */
        ReportTextFieldMsg.prototype.content = "";

        /**
         * ReportTextFieldMsg component.
         * @member {string} component
         * @memberof cat.ReportTextFieldMsg
         * @instance
         */
        ReportTextFieldMsg.prototype.component = "";

        /**
         * ReportTextFieldMsg required.
         * @member {boolean} required
         * @memberof cat.ReportTextFieldMsg
         * @instance
         */
        ReportTextFieldMsg.prototype.required = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReportTextFieldMsg.prototype, "_label", {
            get: $util.oneOfGetter($oneOfFields = ["label"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ReportTextFieldMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReportTextFieldMsg
         * @static
         * @param {cat.IReportTextFieldMsg=} [properties] Properties to set
         * @returns {cat.ReportTextFieldMsg} ReportTextFieldMsg instance
         */
        ReportTextFieldMsg.create = function create(properties) {
            return new ReportTextFieldMsg(properties);
        };

        /**
         * Encodes the specified ReportTextFieldMsg message. Does not implicitly {@link cat.ReportTextFieldMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReportTextFieldMsg
         * @static
         * @param {cat.IReportTextFieldMsg} m ReportTextFieldMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReportTextFieldMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                w.uint32(18).string(m.label);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                w.uint32(34).string(m.content);
            if (m.component != null && Object.hasOwnProperty.call(m, "component"))
                w.uint32(42).string(m.component);
            if (m.required != null && Object.hasOwnProperty.call(m, "required"))
                w.uint32(48).bool(m.required);
            return w;
        };

        /**
         * Decodes a ReportTextFieldMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReportTextFieldMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReportTextFieldMsg} ReportTextFieldMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReportTextFieldMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReportTextFieldMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 2: {
                        m.label = r.string();
                        break;
                    }
                case 3: {
                        m.type = r.int32();
                        break;
                    }
                case 4: {
                        m.content = r.string();
                        break;
                    }
                case 5: {
                        m.component = r.string();
                        break;
                    }
                case 6: {
                        m.required = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReportTextFieldMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReportTextFieldMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReportTextFieldMsg} ReportTextFieldMsg
         */
        ReportTextFieldMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReportTextFieldMsg)
                return d;
            var m = new $root.cat.ReportTextFieldMsg();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.label != null) {
                m.label = String(d.label);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "TEXTFIELD_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "TEXTFIELD_INPUT":
            case 1:
                m.type = 1;
                break;
            case "TEXTFIELD_MULTILINE_INPUT":
            case 2:
                m.type = 2;
                break;
            case "TEXTFIELD_REPORT_TITLE_INPUT":
            case 3:
                m.type = 3;
                break;
            case "TEXTFIELD_HEADING_INPUT":
            case 4:
                m.type = 4;
                break;
            case "TEXTFIELD_REPORT_SUBTITLE_INPUT":
            case 5:
                m.type = 5;
                break;
            }
            if (d.content != null) {
                m.content = String(d.content);
            }
            if (d.component != null) {
                m.component = String(d.component);
            }
            if (d.required != null) {
                m.required = Boolean(d.required);
            }
            return m;
        };

        /**
         * Creates a plain object from a ReportTextFieldMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReportTextFieldMsg
         * @static
         * @param {cat.ReportTextFieldMsg} m ReportTextFieldMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReportTextFieldMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.name = "";
                d.type = o.enums === String ? "TEXTFIELD_UNKNOWN" : 0;
                d.content = "";
                d.component = "";
                d.required = false;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.label != null && m.hasOwnProperty("label")) {
                d.label = m.label;
                if (o.oneofs)
                    d._label = "label";
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.TextFieldType[m.type] === undefined ? m.type : $root.cat.TextFieldType[m.type] : m.type;
            }
            if (m.content != null && m.hasOwnProperty("content")) {
                d.content = m.content;
            }
            if (m.component != null && m.hasOwnProperty("component")) {
                d.component = m.component;
            }
            if (m.required != null && m.hasOwnProperty("required")) {
                d.required = m.required;
            }
            return d;
        };

        /**
         * Converts this ReportTextFieldMsg to JSON.
         * @function toJSON
         * @memberof cat.ReportTextFieldMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReportTextFieldMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReportTextFieldMsg
         * @function getTypeUrl
         * @memberof cat.ReportTextFieldMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportTextFieldMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReportTextFieldMsg";
        };

        return ReportTextFieldMsg;
    })();

    cat.LicenseMsg = (function() {

        /**
         * Properties of a LicenseMsg.
         * @memberof cat
         * @interface ILicenseMsg
         * @property {string|null} [id] LicenseMsg id
         * @property {number|null} [version] LicenseMsg version
         * @property {cat.ReceiverMode|null} [mode] LicenseMsg mode
         * @property {number|null} [startdate] LicenseMsg startdate
         * @property {number|null} [enddate] LicenseMsg enddate
         * @property {string|null} [systemid] LicenseMsg systemid
         * @property {string|null} [signature] LicenseMsg signature
         * @property {boolean|null} [valid] LicenseMsg valid
         */

        /**
         * Constructs a new LicenseMsg.
         * @memberof cat
         * @classdesc Represents a LicenseMsg.
         * @implements ILicenseMsg
         * @constructor
         * @param {cat.ILicenseMsg=} [p] Properties to set
         */
        function LicenseMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LicenseMsg id.
         * @member {string} id
         * @memberof cat.LicenseMsg
         * @instance
         */
        LicenseMsg.prototype.id = "";

        /**
         * LicenseMsg version.
         * @member {number} version
         * @memberof cat.LicenseMsg
         * @instance
         */
        LicenseMsg.prototype.version = 0;

        /**
         * LicenseMsg mode.
         * @member {cat.ReceiverMode} mode
         * @memberof cat.LicenseMsg
         * @instance
         */
        LicenseMsg.prototype.mode = 0;

        /**
         * LicenseMsg startdate.
         * @member {number} startdate
         * @memberof cat.LicenseMsg
         * @instance
         */
        LicenseMsg.prototype.startdate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * LicenseMsg enddate.
         * @member {number} enddate
         * @memberof cat.LicenseMsg
         * @instance
         */
        LicenseMsg.prototype.enddate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * LicenseMsg systemid.
         * @member {string} systemid
         * @memberof cat.LicenseMsg
         * @instance
         */
        LicenseMsg.prototype.systemid = "";

        /**
         * LicenseMsg signature.
         * @member {string} signature
         * @memberof cat.LicenseMsg
         * @instance
         */
        LicenseMsg.prototype.signature = "";

        /**
         * LicenseMsg valid.
         * @member {boolean} valid
         * @memberof cat.LicenseMsg
         * @instance
         */
        LicenseMsg.prototype.valid = false;

        /**
         * Creates a new LicenseMsg instance using the specified properties.
         * @function create
         * @memberof cat.LicenseMsg
         * @static
         * @param {cat.ILicenseMsg=} [properties] Properties to set
         * @returns {cat.LicenseMsg} LicenseMsg instance
         */
        LicenseMsg.create = function create(properties) {
            return new LicenseMsg(properties);
        };

        /**
         * Encodes the specified LicenseMsg message. Does not implicitly {@link cat.LicenseMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.LicenseMsg
         * @static
         * @param {cat.ILicenseMsg} m LicenseMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LicenseMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(16).uint32(m.version);
            if (m.mode != null && Object.hasOwnProperty.call(m, "mode"))
                w.uint32(24).int32(m.mode);
            if (m.startdate != null && Object.hasOwnProperty.call(m, "startdate"))
                w.uint32(32).uint64(m.startdate);
            if (m.enddate != null && Object.hasOwnProperty.call(m, "enddate"))
                w.uint32(40).uint64(m.enddate);
            if (m.systemid != null && Object.hasOwnProperty.call(m, "systemid"))
                w.uint32(50).string(m.systemid);
            if (m.signature != null && Object.hasOwnProperty.call(m, "signature"))
                w.uint32(58).string(m.signature);
            if (m.valid != null && Object.hasOwnProperty.call(m, "valid"))
                w.uint32(808).bool(m.valid);
            return w;
        };

        /**
         * Decodes a LicenseMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.LicenseMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.LicenseMsg} LicenseMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LicenseMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.LicenseMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.version = r.uint32();
                        break;
                    }
                case 3: {
                        m.mode = r.int32();
                        break;
                    }
                case 4: {
                        m.startdate = r.uint64();
                        break;
                    }
                case 5: {
                        m.enddate = r.uint64();
                        break;
                    }
                case 6: {
                        m.systemid = r.string();
                        break;
                    }
                case 7: {
                        m.signature = r.string();
                        break;
                    }
                case 101: {
                        m.valid = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a LicenseMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.LicenseMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.LicenseMsg} LicenseMsg
         */
        LicenseMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.LicenseMsg)
                return d;
            var m = new $root.cat.LicenseMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.version != null) {
                m.version = d.version >>> 0;
            }
            switch (d.mode) {
            default:
                if (typeof d.mode === "number") {
                    m.mode = d.mode;
                    break;
                }
                break;
            case "RECEIVER_UNKNOWN":
            case 0:
                m.mode = 0;
                break;
            case "RECEIVER_FULL":
            case 1:
                m.mode = 1;
                break;
            case "RECEIVER_RECEIVE":
            case 2:
                m.mode = 2;
                break;
            case "RECEIVER_PROCESS":
            case 3:
                m.mode = 3;
                break;
            case "RECEIVER_STANDALONE":
            case 4:
                m.mode = 4;
                break;
            }
            if (d.startdate != null) {
                if ($util.Long)
                    (m.startdate = $util.Long.fromValue(d.startdate)).unsigned = true;
                else if (typeof d.startdate === "string")
                    m.startdate = parseInt(d.startdate, 10);
                else if (typeof d.startdate === "number")
                    m.startdate = d.startdate;
                else if (typeof d.startdate === "object")
                    m.startdate = new $util.LongBits(d.startdate.low >>> 0, d.startdate.high >>> 0).toNumber(true);
            }
            if (d.enddate != null) {
                if ($util.Long)
                    (m.enddate = $util.Long.fromValue(d.enddate)).unsigned = true;
                else if (typeof d.enddate === "string")
                    m.enddate = parseInt(d.enddate, 10);
                else if (typeof d.enddate === "number")
                    m.enddate = d.enddate;
                else if (typeof d.enddate === "object")
                    m.enddate = new $util.LongBits(d.enddate.low >>> 0, d.enddate.high >>> 0).toNumber(true);
            }
            if (d.systemid != null) {
                m.systemid = String(d.systemid);
            }
            if (d.signature != null) {
                m.signature = String(d.signature);
            }
            if (d.valid != null) {
                m.valid = Boolean(d.valid);
            }
            return m;
        };

        /**
         * Creates a plain object from a LicenseMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.LicenseMsg
         * @static
         * @param {cat.LicenseMsg} m LicenseMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LicenseMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.version = 0;
                d.mode = o.enums === String ? "RECEIVER_UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.startdate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startdate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.enddate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.enddate = o.longs === String ? "0" : 0;
                d.systemid = "";
                d.signature = "";
                d.valid = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                d.version = m.version;
            }
            if (m.mode != null && m.hasOwnProperty("mode")) {
                d.mode = o.enums === String ? $root.cat.ReceiverMode[m.mode] === undefined ? m.mode : $root.cat.ReceiverMode[m.mode] : m.mode;
            }
            if (m.startdate != null && m.hasOwnProperty("startdate")) {
                if (typeof m.startdate === "number")
                    d.startdate = o.longs === String ? String(m.startdate) : m.startdate;
                else
                    d.startdate = o.longs === String ? $util.Long.prototype.toString.call(m.startdate) : o.longs === Number ? new $util.LongBits(m.startdate.low >>> 0, m.startdate.high >>> 0).toNumber(true) : m.startdate;
            }
            if (m.enddate != null && m.hasOwnProperty("enddate")) {
                if (typeof m.enddate === "number")
                    d.enddate = o.longs === String ? String(m.enddate) : m.enddate;
                else
                    d.enddate = o.longs === String ? $util.Long.prototype.toString.call(m.enddate) : o.longs === Number ? new $util.LongBits(m.enddate.low >>> 0, m.enddate.high >>> 0).toNumber(true) : m.enddate;
            }
            if (m.systemid != null && m.hasOwnProperty("systemid")) {
                d.systemid = m.systemid;
            }
            if (m.signature != null && m.hasOwnProperty("signature")) {
                d.signature = m.signature;
            }
            if (m.valid != null && m.hasOwnProperty("valid")) {
                d.valid = m.valid;
            }
            return d;
        };

        /**
         * Converts this LicenseMsg to JSON.
         * @function toJSON
         * @memberof cat.LicenseMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LicenseMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LicenseMsg
         * @function getTypeUrl
         * @memberof cat.LicenseMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LicenseMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.LicenseMsg";
        };

        return LicenseMsg;
    })();

    cat.SearchMsg = (function() {

        /**
         * Properties of a SearchMsg.
         * @memberof cat
         * @interface ISearchMsg
         * @property {string|null} [query] SearchMsg query
         * @property {string|null} [userid] SearchMsg userid
         * @property {Array.<string>|null} [collections] SearchMsg collections
         * @property {Array.<string>|null} [results] SearchMsg results
         */

        /**
         * Constructs a new SearchMsg.
         * @memberof cat
         * @classdesc Represents a SearchMsg.
         * @implements ISearchMsg
         * @constructor
         * @param {cat.ISearchMsg=} [p] Properties to set
         */
        function SearchMsg(p) {
            this.collections = [];
            this.results = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SearchMsg query.
         * @member {string} query
         * @memberof cat.SearchMsg
         * @instance
         */
        SearchMsg.prototype.query = "";

        /**
         * SearchMsg userid.
         * @member {string} userid
         * @memberof cat.SearchMsg
         * @instance
         */
        SearchMsg.prototype.userid = "";

        /**
         * SearchMsg collections.
         * @member {Array.<string>} collections
         * @memberof cat.SearchMsg
         * @instance
         */
        SearchMsg.prototype.collections = $util.emptyArray;

        /**
         * SearchMsg results.
         * @member {Array.<string>} results
         * @memberof cat.SearchMsg
         * @instance
         */
        SearchMsg.prototype.results = $util.emptyArray;

        /**
         * Creates a new SearchMsg instance using the specified properties.
         * @function create
         * @memberof cat.SearchMsg
         * @static
         * @param {cat.ISearchMsg=} [properties] Properties to set
         * @returns {cat.SearchMsg} SearchMsg instance
         */
        SearchMsg.create = function create(properties) {
            return new SearchMsg(properties);
        };

        /**
         * Encodes the specified SearchMsg message. Does not implicitly {@link cat.SearchMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.SearchMsg
         * @static
         * @param {cat.ISearchMsg} m SearchMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.query != null && Object.hasOwnProperty.call(m, "query"))
                w.uint32(10).string(m.query);
            if (m.userid != null && Object.hasOwnProperty.call(m, "userid"))
                w.uint32(18).string(m.userid);
            if (m.collections != null && m.collections.length) {
                for (var i = 0; i < m.collections.length; ++i)
                    w.uint32(26).string(m.collections[i]);
            }
            if (m.results != null && m.results.length) {
                for (var i = 0; i < m.results.length; ++i)
                    w.uint32(34).string(m.results[i]);
            }
            return w;
        };

        /**
         * Decodes a SearchMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.SearchMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.SearchMsg} SearchMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.SearchMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.query = r.string();
                        break;
                    }
                case 2: {
                        m.userid = r.string();
                        break;
                    }
                case 3: {
                        if (!(m.collections && m.collections.length))
                            m.collections = [];
                        m.collections.push(r.string());
                        break;
                    }
                case 4: {
                        if (!(m.results && m.results.length))
                            m.results = [];
                        m.results.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a SearchMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.SearchMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.SearchMsg} SearchMsg
         */
        SearchMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.SearchMsg)
                return d;
            var m = new $root.cat.SearchMsg();
            if (d.query != null) {
                m.query = String(d.query);
            }
            if (d.userid != null) {
                m.userid = String(d.userid);
            }
            if (d.collections) {
                if (!Array.isArray(d.collections))
                    throw TypeError(".cat.SearchMsg.collections: array expected");
                m.collections = [];
                for (var i = 0; i < d.collections.length; ++i) {
                    m.collections[i] = String(d.collections[i]);
                }
            }
            if (d.results) {
                if (!Array.isArray(d.results))
                    throw TypeError(".cat.SearchMsg.results: array expected");
                m.results = [];
                for (var i = 0; i < d.results.length; ++i) {
                    m.results[i] = String(d.results[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a SearchMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.SearchMsg
         * @static
         * @param {cat.SearchMsg} m SearchMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.collections = [];
                d.results = [];
            }
            if (o.defaults) {
                d.query = "";
                d.userid = "";
            }
            if (m.query != null && m.hasOwnProperty("query")) {
                d.query = m.query;
            }
            if (m.userid != null && m.hasOwnProperty("userid")) {
                d.userid = m.userid;
            }
            if (m.collections && m.collections.length) {
                d.collections = [];
                for (var j = 0; j < m.collections.length; ++j) {
                    d.collections[j] = m.collections[j];
                }
            }
            if (m.results && m.results.length) {
                d.results = [];
                for (var j = 0; j < m.results.length; ++j) {
                    d.results[j] = m.results[j];
                }
            }
            return d;
        };

        /**
         * Converts this SearchMsg to JSON.
         * @function toJSON
         * @memberof cat.SearchMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SearchMsg
         * @function getTypeUrl
         * @memberof cat.SearchMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.SearchMsg";
        };

        return SearchMsg;
    })();

    cat.TsaMsg = (function() {

        /**
         * Properties of a TsaMsg.
         * @memberof cat
         * @interface ITsaMsg
         * @property {number|null} [timestamp] TsaMsg timestamp
         * @property {string|null} [data] TsaMsg data
         * @property {string|null} [signature] TsaMsg signature
         */

        /**
         * Constructs a new TsaMsg.
         * @memberof cat
         * @classdesc Represents a TsaMsg.
         * @implements ITsaMsg
         * @constructor
         * @param {cat.ITsaMsg=} [p] Properties to set
         */
        function TsaMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TsaMsg timestamp.
         * @member {number} timestamp
         * @memberof cat.TsaMsg
         * @instance
         */
        TsaMsg.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TsaMsg data.
         * @member {string} data
         * @memberof cat.TsaMsg
         * @instance
         */
        TsaMsg.prototype.data = "";

        /**
         * TsaMsg signature.
         * @member {string} signature
         * @memberof cat.TsaMsg
         * @instance
         */
        TsaMsg.prototype.signature = "";

        /**
         * Creates a new TsaMsg instance using the specified properties.
         * @function create
         * @memberof cat.TsaMsg
         * @static
         * @param {cat.ITsaMsg=} [properties] Properties to set
         * @returns {cat.TsaMsg} TsaMsg instance
         */
        TsaMsg.create = function create(properties) {
            return new TsaMsg(properties);
        };

        /**
         * Encodes the specified TsaMsg message. Does not implicitly {@link cat.TsaMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.TsaMsg
         * @static
         * @param {cat.ITsaMsg} m TsaMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TsaMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                w.uint32(8).uint64(m.timestamp);
            if (m.data != null && Object.hasOwnProperty.call(m, "data"))
                w.uint32(18).string(m.data);
            if (m.signature != null && Object.hasOwnProperty.call(m, "signature"))
                w.uint32(26).string(m.signature);
            return w;
        };

        /**
         * Decodes a TsaMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.TsaMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.TsaMsg} TsaMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TsaMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.TsaMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.timestamp = r.uint64();
                        break;
                    }
                case 2: {
                        m.data = r.string();
                        break;
                    }
                case 3: {
                        m.signature = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TsaMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.TsaMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.TsaMsg} TsaMsg
         */
        TsaMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.TsaMsg)
                return d;
            var m = new $root.cat.TsaMsg();
            if (d.timestamp != null) {
                if ($util.Long)
                    (m.timestamp = $util.Long.fromValue(d.timestamp)).unsigned = true;
                else if (typeof d.timestamp === "string")
                    m.timestamp = parseInt(d.timestamp, 10);
                else if (typeof d.timestamp === "number")
                    m.timestamp = d.timestamp;
                else if (typeof d.timestamp === "object")
                    m.timestamp = new $util.LongBits(d.timestamp.low >>> 0, d.timestamp.high >>> 0).toNumber(true);
            }
            if (d.data != null) {
                m.data = String(d.data);
            }
            if (d.signature != null) {
                m.signature = String(d.signature);
            }
            return m;
        };

        /**
         * Creates a plain object from a TsaMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.TsaMsg
         * @static
         * @param {cat.TsaMsg} m TsaMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TsaMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timestamp = o.longs === String ? "0" : 0;
                d.data = "";
                d.signature = "";
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                if (typeof m.timestamp === "number")
                    d.timestamp = o.longs === String ? String(m.timestamp) : m.timestamp;
                else
                    d.timestamp = o.longs === String ? $util.Long.prototype.toString.call(m.timestamp) : o.longs === Number ? new $util.LongBits(m.timestamp.low >>> 0, m.timestamp.high >>> 0).toNumber(true) : m.timestamp;
            }
            if (m.data != null && m.hasOwnProperty("data")) {
                d.data = m.data;
            }
            if (m.signature != null && m.hasOwnProperty("signature")) {
                d.signature = m.signature;
            }
            return d;
        };

        /**
         * Converts this TsaMsg to JSON.
         * @function toJSON
         * @memberof cat.TsaMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TsaMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TsaMsg
         * @function getTypeUrl
         * @memberof cat.TsaMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TsaMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.TsaMsg";
        };

        return TsaMsg;
    })();

    cat.AppMsg = (function() {

        /**
         * Properties of an AppMsg.
         * @memberof cat
         * @interface IAppMsg
         * @property {string|null} [id] AppMsg id
         * @property {cat.StatusType|null} [status] AppMsg status
         * @property {number|null} [created] AppMsg created
         * @property {number|null} [lastmodified] AppMsg lastmodified
         * @property {Object.<string,string>|null} [properties] AppMsg properties
         * @property {string|null} [packagename] AppMsg packagename
         * @property {number|null} [versioncode] AppMsg versioncode
         * @property {string|null} [versionname] AppMsg versionname
         * @property {cat.IAppTypeMsg|null} [apptype] AppMsg apptype
         * @property {number|null} [unreadmessages] AppMsg unreadmessages
         * @property {number|null} [conversationcount] AppMsg conversationcount
         * @property {string|null} [deviceid] AppMsg deviceid
         * @property {Array.<cat.IAccountMsg>|null} [accounts] AppMsg accounts
         * @property {cat.IMediaFileMsg|null} [receivermedia] AppMsg receivermedia
         * @property {number|null} [receivermediacount] AppMsg receivermediacount
         */

        /**
         * Constructs a new AppMsg.
         * @memberof cat
         * @classdesc Represents an AppMsg.
         * @implements IAppMsg
         * @constructor
         * @param {cat.IAppMsg=} [p] Properties to set
         */
        function AppMsg(p) {
            this.properties = {};
            this.accounts = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AppMsg id.
         * @member {string} id
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.id = "";

        /**
         * AppMsg status.
         * @member {cat.StatusType|null|undefined} status
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.status = null;

        /**
         * AppMsg created.
         * @member {number} created
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AppMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AppMsg properties.
         * @member {Object.<string,string>} properties
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.properties = $util.emptyObject;

        /**
         * AppMsg packagename.
         * @member {string} packagename
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.packagename = "";

        /**
         * AppMsg versioncode.
         * @member {number|null|undefined} versioncode
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.versioncode = null;

        /**
         * AppMsg versionname.
         * @member {string|null|undefined} versionname
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.versionname = null;

        /**
         * AppMsg apptype.
         * @member {cat.IAppTypeMsg|null|undefined} apptype
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.apptype = null;

        /**
         * AppMsg unreadmessages.
         * @member {number} unreadmessages
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.unreadmessages = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AppMsg conversationcount.
         * @member {number} conversationcount
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.conversationcount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AppMsg deviceid.
         * @member {string} deviceid
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.deviceid = "";

        /**
         * AppMsg accounts.
         * @member {Array.<cat.IAccountMsg>} accounts
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.accounts = $util.emptyArray;

        /**
         * AppMsg receivermedia.
         * @member {cat.IMediaFileMsg|null|undefined} receivermedia
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.receivermedia = null;

        /**
         * AppMsg receivermediacount.
         * @member {number} receivermediacount
         * @memberof cat.AppMsg
         * @instance
         */
        AppMsg.prototype.receivermediacount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AppMsg.prototype, "_status", {
            get: $util.oneOfGetter($oneOfFields = ["status"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AppMsg.prototype, "_versioncode", {
            get: $util.oneOfGetter($oneOfFields = ["versioncode"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AppMsg.prototype, "_versionname", {
            get: $util.oneOfGetter($oneOfFields = ["versionname"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AppMsg instance using the specified properties.
         * @function create
         * @memberof cat.AppMsg
         * @static
         * @param {cat.IAppMsg=} [properties] Properties to set
         * @returns {cat.AppMsg} AppMsg instance
         */
        AppMsg.create = function create(properties) {
            return new AppMsg(properties);
        };

        /**
         * Encodes the specified AppMsg message. Does not implicitly {@link cat.AppMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.AppMsg
         * @static
         * @param {cat.IAppMsg} m AppMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(16).int32(m.status);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(24).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(32).uint64(m.lastmodified);
            if (m.properties != null && Object.hasOwnProperty.call(m, "properties")) {
                for (var ks = Object.keys(m.properties), i = 0; i < ks.length; ++i) {
                    w.uint32(42).fork().uint32(10).string(ks[i]).uint32(18).string(m.properties[ks[i]]).ldelim();
                }
            }
            if (m.packagename != null && Object.hasOwnProperty.call(m, "packagename"))
                w.uint32(50).string(m.packagename);
            if (m.versioncode != null && Object.hasOwnProperty.call(m, "versioncode"))
                w.uint32(56).uint64(m.versioncode);
            if (m.versionname != null && Object.hasOwnProperty.call(m, "versionname"))
                w.uint32(66).string(m.versionname);
            if (m.apptype != null && Object.hasOwnProperty.call(m, "apptype"))
                $root.cat.AppTypeMsg.encode(m.apptype, w.uint32(810).fork()).ldelim();
            if (m.unreadmessages != null && Object.hasOwnProperty.call(m, "unreadmessages"))
                w.uint32(816).uint64(m.unreadmessages);
            if (m.conversationcount != null && Object.hasOwnProperty.call(m, "conversationcount"))
                w.uint32(824).uint64(m.conversationcount);
            if (m.deviceid != null && Object.hasOwnProperty.call(m, "deviceid"))
                w.uint32(834).string(m.deviceid);
            if (m.accounts != null && m.accounts.length) {
                for (var i = 0; i < m.accounts.length; ++i)
                    $root.cat.AccountMsg.encode(m.accounts[i], w.uint32(842).fork()).ldelim();
            }
            if (m.receivermedia != null && Object.hasOwnProperty.call(m, "receivermedia"))
                $root.cat.MediaFileMsg.encode(m.receivermedia, w.uint32(850).fork()).ldelim();
            if (m.receivermediacount != null && Object.hasOwnProperty.call(m, "receivermediacount"))
                w.uint32(856).uint64(m.receivermediacount);
            return w;
        };

        /**
         * Decodes an AppMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.AppMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.AppMsg} AppMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.AppMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.status = r.int32();
                        break;
                    }
                case 3: {
                        m.created = r.uint64();
                        break;
                    }
                case 4: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 5: {
                        if (m.properties === $util.emptyObject)
                            m.properties = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.properties[k] = value;
                        break;
                    }
                case 6: {
                        m.packagename = r.string();
                        break;
                    }
                case 7: {
                        m.versioncode = r.uint64();
                        break;
                    }
                case 8: {
                        m.versionname = r.string();
                        break;
                    }
                case 101: {
                        m.apptype = $root.cat.AppTypeMsg.decode(r, r.uint32());
                        break;
                    }
                case 102: {
                        m.unreadmessages = r.uint64();
                        break;
                    }
                case 103: {
                        m.conversationcount = r.uint64();
                        break;
                    }
                case 104: {
                        m.deviceid = r.string();
                        break;
                    }
                case 105: {
                        if (!(m.accounts && m.accounts.length))
                            m.accounts = [];
                        m.accounts.push($root.cat.AccountMsg.decode(r, r.uint32()));
                        break;
                    }
                case 106: {
                        m.receivermedia = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 107: {
                        m.receivermediacount = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AppMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.AppMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.AppMsg} AppMsg
         */
        AppMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.AppMsg)
                return d;
            var m = new $root.cat.AppMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ENABLED":
            case 1:
                m.status = 1;
                break;
            case "DISABLED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.properties) {
                if (typeof d.properties !== "object")
                    throw TypeError(".cat.AppMsg.properties: object expected");
                m.properties = {};
                for (var ks = Object.keys(d.properties), i = 0; i < ks.length; ++i) {
                    m.properties[ks[i]] = String(d.properties[ks[i]]);
                }
            }
            if (d.packagename != null) {
                m.packagename = String(d.packagename);
            }
            if (d.versioncode != null) {
                if ($util.Long)
                    (m.versioncode = $util.Long.fromValue(d.versioncode)).unsigned = true;
                else if (typeof d.versioncode === "string")
                    m.versioncode = parseInt(d.versioncode, 10);
                else if (typeof d.versioncode === "number")
                    m.versioncode = d.versioncode;
                else if (typeof d.versioncode === "object")
                    m.versioncode = new $util.LongBits(d.versioncode.low >>> 0, d.versioncode.high >>> 0).toNumber(true);
            }
            if (d.versionname != null) {
                m.versionname = String(d.versionname);
            }
            if (d.apptype != null) {
                if (typeof d.apptype !== "object")
                    throw TypeError(".cat.AppMsg.apptype: object expected");
                m.apptype = $root.cat.AppTypeMsg.fromObject(d.apptype);
            }
            if (d.unreadmessages != null) {
                if ($util.Long)
                    (m.unreadmessages = $util.Long.fromValue(d.unreadmessages)).unsigned = true;
                else if (typeof d.unreadmessages === "string")
                    m.unreadmessages = parseInt(d.unreadmessages, 10);
                else if (typeof d.unreadmessages === "number")
                    m.unreadmessages = d.unreadmessages;
                else if (typeof d.unreadmessages === "object")
                    m.unreadmessages = new $util.LongBits(d.unreadmessages.low >>> 0, d.unreadmessages.high >>> 0).toNumber(true);
            }
            if (d.conversationcount != null) {
                if ($util.Long)
                    (m.conversationcount = $util.Long.fromValue(d.conversationcount)).unsigned = true;
                else if (typeof d.conversationcount === "string")
                    m.conversationcount = parseInt(d.conversationcount, 10);
                else if (typeof d.conversationcount === "number")
                    m.conversationcount = d.conversationcount;
                else if (typeof d.conversationcount === "object")
                    m.conversationcount = new $util.LongBits(d.conversationcount.low >>> 0, d.conversationcount.high >>> 0).toNumber(true);
            }
            if (d.deviceid != null) {
                m.deviceid = String(d.deviceid);
            }
            if (d.accounts) {
                if (!Array.isArray(d.accounts))
                    throw TypeError(".cat.AppMsg.accounts: array expected");
                m.accounts = [];
                for (var i = 0; i < d.accounts.length; ++i) {
                    if (typeof d.accounts[i] !== "object")
                        throw TypeError(".cat.AppMsg.accounts: object expected");
                    m.accounts[i] = $root.cat.AccountMsg.fromObject(d.accounts[i]);
                }
            }
            if (d.receivermedia != null) {
                if (typeof d.receivermedia !== "object")
                    throw TypeError(".cat.AppMsg.receivermedia: object expected");
                m.receivermedia = $root.cat.MediaFileMsg.fromObject(d.receivermedia);
            }
            if (d.receivermediacount != null) {
                if ($util.Long)
                    (m.receivermediacount = $util.Long.fromValue(d.receivermediacount)).unsigned = true;
                else if (typeof d.receivermediacount === "string")
                    m.receivermediacount = parseInt(d.receivermediacount, 10);
                else if (typeof d.receivermediacount === "number")
                    m.receivermediacount = d.receivermediacount;
                else if (typeof d.receivermediacount === "object")
                    m.receivermediacount = new $util.LongBits(d.receivermediacount.low >>> 0, d.receivermediacount.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from an AppMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.AppMsg
         * @static
         * @param {cat.AppMsg} m AppMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.accounts = [];
            }
            if (o.objects || o.defaults) {
                d.properties = {};
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.packagename = "";
                d.apptype = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.unreadmessages = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.unreadmessages = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.conversationcount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.conversationcount = o.longs === String ? "0" : 0;
                d.deviceid = "";
                d.receivermedia = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.receivermediacount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.receivermediacount = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.StatusType[m.status] === undefined ? m.status : $root.cat.StatusType[m.status] : m.status;
                if (o.oneofs)
                    d._status = "status";
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            var ks2;
            if (m.properties && (ks2 = Object.keys(m.properties)).length) {
                d.properties = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.properties[ks2[j]] = m.properties[ks2[j]];
                }
            }
            if (m.packagename != null && m.hasOwnProperty("packagename")) {
                d.packagename = m.packagename;
            }
            if (m.versioncode != null && m.hasOwnProperty("versioncode")) {
                if (typeof m.versioncode === "number")
                    d.versioncode = o.longs === String ? String(m.versioncode) : m.versioncode;
                else
                    d.versioncode = o.longs === String ? $util.Long.prototype.toString.call(m.versioncode) : o.longs === Number ? new $util.LongBits(m.versioncode.low >>> 0, m.versioncode.high >>> 0).toNumber(true) : m.versioncode;
                if (o.oneofs)
                    d._versioncode = "versioncode";
            }
            if (m.versionname != null && m.hasOwnProperty("versionname")) {
                d.versionname = m.versionname;
                if (o.oneofs)
                    d._versionname = "versionname";
            }
            if (m.apptype != null && m.hasOwnProperty("apptype")) {
                d.apptype = $root.cat.AppTypeMsg.toObject(m.apptype, o);
            }
            if (m.unreadmessages != null && m.hasOwnProperty("unreadmessages")) {
                if (typeof m.unreadmessages === "number")
                    d.unreadmessages = o.longs === String ? String(m.unreadmessages) : m.unreadmessages;
                else
                    d.unreadmessages = o.longs === String ? $util.Long.prototype.toString.call(m.unreadmessages) : o.longs === Number ? new $util.LongBits(m.unreadmessages.low >>> 0, m.unreadmessages.high >>> 0).toNumber(true) : m.unreadmessages;
            }
            if (m.conversationcount != null && m.hasOwnProperty("conversationcount")) {
                if (typeof m.conversationcount === "number")
                    d.conversationcount = o.longs === String ? String(m.conversationcount) : m.conversationcount;
                else
                    d.conversationcount = o.longs === String ? $util.Long.prototype.toString.call(m.conversationcount) : o.longs === Number ? new $util.LongBits(m.conversationcount.low >>> 0, m.conversationcount.high >>> 0).toNumber(true) : m.conversationcount;
            }
            if (m.deviceid != null && m.hasOwnProperty("deviceid")) {
                d.deviceid = m.deviceid;
            }
            if (m.accounts && m.accounts.length) {
                d.accounts = [];
                for (var j = 0; j < m.accounts.length; ++j) {
                    d.accounts[j] = $root.cat.AccountMsg.toObject(m.accounts[j], o);
                }
            }
            if (m.receivermedia != null && m.hasOwnProperty("receivermedia")) {
                d.receivermedia = $root.cat.MediaFileMsg.toObject(m.receivermedia, o);
            }
            if (m.receivermediacount != null && m.hasOwnProperty("receivermediacount")) {
                if (typeof m.receivermediacount === "number")
                    d.receivermediacount = o.longs === String ? String(m.receivermediacount) : m.receivermediacount;
                else
                    d.receivermediacount = o.longs === String ? $util.Long.prototype.toString.call(m.receivermediacount) : o.longs === Number ? new $util.LongBits(m.receivermediacount.low >>> 0, m.receivermediacount.high >>> 0).toNumber(true) : m.receivermediacount;
            }
            return d;
        };

        /**
         * Converts this AppMsg to JSON.
         * @function toJSON
         * @memberof cat.AppMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AppMsg
         * @function getTypeUrl
         * @memberof cat.AppMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AppMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.AppMsg";
        };

        return AppMsg;
    })();

    /**
     * AccountPropertyType enum.
     * @name cat.AccountPropertyType
     * @enum {number}
     * @property {number} ACCOUNTPROPERTY_UNKNOWN=0 ACCOUNTPROPERTY_UNKNOWN value
     * @property {number} ACCOUNTPROPERTY_USERNAME=1 ACCOUNTPROPERTY_USERNAME value
     * @property {number} ACCOUNTPROPERTY_DATABASEKEY=2 ACCOUNTPROPERTY_DATABASEKEY value
     * @property {number} ACCOUNTPROPERTY_ATTACHMENTKEY=3 ACCOUNTPROPERTY_ATTACHMENTKEY value
     */
    cat.AccountPropertyType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ACCOUNTPROPERTY_UNKNOWN"] = 0;
        values[valuesById[1] = "ACCOUNTPROPERTY_USERNAME"] = 1;
        values[valuesById[2] = "ACCOUNTPROPERTY_DATABASEKEY"] = 2;
        values[valuesById[3] = "ACCOUNTPROPERTY_ATTACHMENTKEY"] = 3;
        return values;
    })();

    cat.AccountPropertyMsg = (function() {

        /**
         * Properties of an AccountPropertyMsg.
         * @memberof cat
         * @interface IAccountPropertyMsg
         * @property {cat.AccountPropertyType|null} [type] AccountPropertyMsg type
         * @property {string|null} [value] AccountPropertyMsg value
         */

        /**
         * Constructs a new AccountPropertyMsg.
         * @memberof cat
         * @classdesc Represents an AccountPropertyMsg.
         * @implements IAccountPropertyMsg
         * @constructor
         * @param {cat.IAccountPropertyMsg=} [p] Properties to set
         */
        function AccountPropertyMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AccountPropertyMsg type.
         * @member {cat.AccountPropertyType} type
         * @memberof cat.AccountPropertyMsg
         * @instance
         */
        AccountPropertyMsg.prototype.type = 0;

        /**
         * AccountPropertyMsg value.
         * @member {string} value
         * @memberof cat.AccountPropertyMsg
         * @instance
         */
        AccountPropertyMsg.prototype.value = "";

        /**
         * Creates a new AccountPropertyMsg instance using the specified properties.
         * @function create
         * @memberof cat.AccountPropertyMsg
         * @static
         * @param {cat.IAccountPropertyMsg=} [properties] Properties to set
         * @returns {cat.AccountPropertyMsg} AccountPropertyMsg instance
         */
        AccountPropertyMsg.create = function create(properties) {
            return new AccountPropertyMsg(properties);
        };

        /**
         * Encodes the specified AccountPropertyMsg message. Does not implicitly {@link cat.AccountPropertyMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.AccountPropertyMsg
         * @static
         * @param {cat.IAccountPropertyMsg} m AccountPropertyMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AccountPropertyMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(18).string(m.value);
            return w;
        };

        /**
         * Decodes an AccountPropertyMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.AccountPropertyMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.AccountPropertyMsg} AccountPropertyMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AccountPropertyMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.AccountPropertyMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.type = r.int32();
                        break;
                    }
                case 2: {
                        m.value = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AccountPropertyMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.AccountPropertyMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.AccountPropertyMsg} AccountPropertyMsg
         */
        AccountPropertyMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.AccountPropertyMsg)
                return d;
            var m = new $root.cat.AccountPropertyMsg();
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "ACCOUNTPROPERTY_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "ACCOUNTPROPERTY_USERNAME":
            case 1:
                m.type = 1;
                break;
            case "ACCOUNTPROPERTY_DATABASEKEY":
            case 2:
                m.type = 2;
                break;
            case "ACCOUNTPROPERTY_ATTACHMENTKEY":
            case 3:
                m.type = 3;
                break;
            }
            if (d.value != null) {
                m.value = String(d.value);
            }
            return m;
        };

        /**
         * Creates a plain object from an AccountPropertyMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.AccountPropertyMsg
         * @static
         * @param {cat.AccountPropertyMsg} m AccountPropertyMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AccountPropertyMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = o.enums === String ? "ACCOUNTPROPERTY_UNKNOWN" : 0;
                d.value = "";
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.AccountPropertyType[m.type] === undefined ? m.type : $root.cat.AccountPropertyType[m.type] : m.type;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            return d;
        };

        /**
         * Converts this AccountPropertyMsg to JSON.
         * @function toJSON
         * @memberof cat.AccountPropertyMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AccountPropertyMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AccountPropertyMsg
         * @function getTypeUrl
         * @memberof cat.AccountPropertyMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AccountPropertyMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.AccountPropertyMsg";
        };

        return AccountPropertyMsg;
    })();

    cat.AccountMsg = (function() {

        /**
         * Properties of an AccountMsg.
         * @memberof cat
         * @interface IAccountMsg
         * @property {string|null} [id] AccountMsg id
         * @property {cat.StatusType|null} [status] AccountMsg status
         * @property {string|null} [phonenumber] AccountMsg phonenumber
         * @property {string|null} [username] AccountMsg username
         * @property {string|null} [password] AccountMsg password
         * @property {string|null} [firstname] AccountMsg firstname
         * @property {string|null} [lastname] AccountMsg lastname
         * @property {string|null} [fullname] AccountMsg fullname
         * @property {string|null} [email] AccountMsg email
         * @property {cat.IDateMsg|null} [birthday] AccountMsg birthday
         * @property {string|null} [bio] AccountMsg bio
         * @property {string|null} [website] AccountMsg website
         * @property {string|null} [location] AccountMsg location
         * @property {string|null} [avatarexternid] AccountMsg avatarexternid
         * @property {number|null} [created] AccountMsg created
         * @property {number|null} [lastmodified] AccountMsg lastmodified
         * @property {cat.IRawMsg|null} [raw] AccountMsg raw
         * @property {string|null} [externid] AccountMsg externid
         * @property {boolean|null} [isdirect] AccountMsg isdirect
         * @property {Array.<cat.IAccountPropertyMsg>|null} [properties] AccountMsg properties
         * @property {number|null} [tsatimestamp] AccountMsg tsatimestamp
         * @property {string|null} [tsasignature] AccountMsg tsasignature
         * @property {string|null} [alias] AccountMsg alias
         * @property {string|null} [paidsubscription] AccountMsg paidsubscription
         * @property {cat.AccountType|null} [type] AccountMsg type
         * @property {cat.IAttachmentMsg|null} [avatar] AccountMsg avatar
         * @property {cat.IAppTypeMsg|null} [apptype] AccountMsg apptype
         * @property {Array.<cat.IServerMsg>|null} [servers] AccountMsg servers
         * @property {Array.<cat.IConversationMsg>|null} [conversations] AccountMsg conversations
         * @property {cat.IMemberStatusMsg|null} [memberstatus] AccountMsg memberstatus
         * @property {Array.<cat.IAccountMsg>|null} [contacts] AccountMsg contacts
         * @property {Array.<cat.ILabelMsg>|null} [labels] AccountMsg labels
         * @property {Array.<cat.IAnnotationMsg>|null} [annotations] AccountMsg annotations
         * @property {string|null} [accountrefid] AccountMsg accountrefid
         * @property {string|null} [apprefid] AccountMsg apprefid
         * @property {number|null} [count] AccountMsg count
         * @property {number|null} [lastactivity] AccountMsg lastactivity
         * @property {cat.IProfileMsg|null} [profile] AccountMsg profile
         * @property {Array.<string>|null} [servernicknames] AccountMsg servernicknames
         * @property {Array.<cat.IAccountMsg>|null} [history] AccountMsg history
         * @property {Array.<cat.HistoryMarker>|null} [historymarkers] AccountMsg historymarkers
         * @property {number|null} [historytimestamp] AccountMsg historytimestamp
         */

        /**
         * Constructs a new AccountMsg.
         * @memberof cat
         * @classdesc Represents an AccountMsg.
         * @implements IAccountMsg
         * @constructor
         * @param {cat.IAccountMsg=} [p] Properties to set
         */
        function AccountMsg(p) {
            this.properties = [];
            this.servers = [];
            this.conversations = [];
            this.contacts = [];
            this.labels = [];
            this.annotations = [];
            this.servernicknames = [];
            this.history = [];
            this.historymarkers = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AccountMsg id.
         * @member {string} id
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.id = "";

        /**
         * AccountMsg status.
         * @member {cat.StatusType|null|undefined} status
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.status = null;

        /**
         * AccountMsg phonenumber.
         * @member {string|null|undefined} phonenumber
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.phonenumber = null;

        /**
         * AccountMsg username.
         * @member {string|null|undefined} username
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.username = null;

        /**
         * AccountMsg password.
         * @member {string|null|undefined} password
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.password = null;

        /**
         * AccountMsg firstname.
         * @member {string|null|undefined} firstname
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.firstname = null;

        /**
         * AccountMsg lastname.
         * @member {string|null|undefined} lastname
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.lastname = null;

        /**
         * AccountMsg fullname.
         * @member {string|null|undefined} fullname
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.fullname = null;

        /**
         * AccountMsg email.
         * @member {string|null|undefined} email
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.email = null;

        /**
         * AccountMsg birthday.
         * @member {cat.IDateMsg|null|undefined} birthday
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.birthday = null;

        /**
         * AccountMsg bio.
         * @member {string|null|undefined} bio
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.bio = null;

        /**
         * AccountMsg website.
         * @member {string|null|undefined} website
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.website = null;

        /**
         * AccountMsg location.
         * @member {string|null|undefined} location
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.location = null;

        /**
         * AccountMsg avatarexternid.
         * @member {string|null|undefined} avatarexternid
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.avatarexternid = null;

        /**
         * AccountMsg created.
         * @member {number} created
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AccountMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AccountMsg raw.
         * @member {cat.IRawMsg|null|undefined} raw
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.raw = null;

        /**
         * AccountMsg externid.
         * @member {string} externid
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.externid = "";

        /**
         * AccountMsg isdirect.
         * @member {boolean|null|undefined} isdirect
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.isdirect = null;

        /**
         * AccountMsg properties.
         * @member {Array.<cat.IAccountPropertyMsg>} properties
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.properties = $util.emptyArray;

        /**
         * AccountMsg tsatimestamp.
         * @member {number} tsatimestamp
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.tsatimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AccountMsg tsasignature.
         * @member {string} tsasignature
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.tsasignature = "";

        /**
         * AccountMsg alias.
         * @member {string|null|undefined} alias
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.alias = null;

        /**
         * AccountMsg paidsubscription.
         * @member {string|null|undefined} paidsubscription
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.paidsubscription = null;

        /**
         * AccountMsg type.
         * @member {cat.AccountType} type
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.type = 0;

        /**
         * AccountMsg avatar.
         * @member {cat.IAttachmentMsg|null|undefined} avatar
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.avatar = null;

        /**
         * AccountMsg apptype.
         * @member {cat.IAppTypeMsg|null|undefined} apptype
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.apptype = null;

        /**
         * AccountMsg servers.
         * @member {Array.<cat.IServerMsg>} servers
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.servers = $util.emptyArray;

        /**
         * AccountMsg conversations.
         * @member {Array.<cat.IConversationMsg>} conversations
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.conversations = $util.emptyArray;

        /**
         * AccountMsg memberstatus.
         * @member {cat.IMemberStatusMsg|null|undefined} memberstatus
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.memberstatus = null;

        /**
         * AccountMsg contacts.
         * @member {Array.<cat.IAccountMsg>} contacts
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.contacts = $util.emptyArray;

        /**
         * AccountMsg labels.
         * @member {Array.<cat.ILabelMsg>} labels
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.labels = $util.emptyArray;

        /**
         * AccountMsg annotations.
         * @member {Array.<cat.IAnnotationMsg>} annotations
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.annotations = $util.emptyArray;

        /**
         * AccountMsg accountrefid.
         * @member {string} accountrefid
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.accountrefid = "";

        /**
         * AccountMsg apprefid.
         * @member {string} apprefid
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.apprefid = "";

        /**
         * AccountMsg count.
         * @member {number} count
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AccountMsg lastactivity.
         * @member {number} lastactivity
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.lastactivity = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AccountMsg profile.
         * @member {cat.IProfileMsg|null|undefined} profile
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.profile = null;

        /**
         * AccountMsg servernicknames.
         * @member {Array.<string>} servernicknames
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.servernicknames = $util.emptyArray;

        /**
         * AccountMsg history.
         * @member {Array.<cat.IAccountMsg>} history
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.history = $util.emptyArray;

        /**
         * AccountMsg historymarkers.
         * @member {Array.<cat.HistoryMarker>} historymarkers
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.historymarkers = $util.emptyArray;

        /**
         * AccountMsg historytimestamp.
         * @member {number} historytimestamp
         * @memberof cat.AccountMsg
         * @instance
         */
        AccountMsg.prototype.historytimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_status", {
            get: $util.oneOfGetter($oneOfFields = ["status"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_phonenumber", {
            get: $util.oneOfGetter($oneOfFields = ["phonenumber"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_username", {
            get: $util.oneOfGetter($oneOfFields = ["username"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_password", {
            get: $util.oneOfGetter($oneOfFields = ["password"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_firstname", {
            get: $util.oneOfGetter($oneOfFields = ["firstname"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_lastname", {
            get: $util.oneOfGetter($oneOfFields = ["lastname"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_fullname", {
            get: $util.oneOfGetter($oneOfFields = ["fullname"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_email", {
            get: $util.oneOfGetter($oneOfFields = ["email"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_birthday", {
            get: $util.oneOfGetter($oneOfFields = ["birthday"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_bio", {
            get: $util.oneOfGetter($oneOfFields = ["bio"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_website", {
            get: $util.oneOfGetter($oneOfFields = ["website"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_location", {
            get: $util.oneOfGetter($oneOfFields = ["location"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_avatarexternid", {
            get: $util.oneOfGetter($oneOfFields = ["avatarexternid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_isdirect", {
            get: $util.oneOfGetter($oneOfFields = ["isdirect"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_alias", {
            get: $util.oneOfGetter($oneOfFields = ["alias"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_paidsubscription", {
            get: $util.oneOfGetter($oneOfFields = ["paidsubscription"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AccountMsg.prototype, "_profile", {
            get: $util.oneOfGetter($oneOfFields = ["profile"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AccountMsg instance using the specified properties.
         * @function create
         * @memberof cat.AccountMsg
         * @static
         * @param {cat.IAccountMsg=} [properties] Properties to set
         * @returns {cat.AccountMsg} AccountMsg instance
         */
        AccountMsg.create = function create(properties) {
            return new AccountMsg(properties);
        };

        /**
         * Encodes the specified AccountMsg message. Does not implicitly {@link cat.AccountMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.AccountMsg
         * @static
         * @param {cat.IAccountMsg} m AccountMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AccountMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(16).int32(m.status);
            if (m.phonenumber != null && Object.hasOwnProperty.call(m, "phonenumber"))
                w.uint32(26).string(m.phonenumber);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(34).string(m.username);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(42).string(m.password);
            if (m.firstname != null && Object.hasOwnProperty.call(m, "firstname"))
                w.uint32(50).string(m.firstname);
            if (m.lastname != null && Object.hasOwnProperty.call(m, "lastname"))
                w.uint32(58).string(m.lastname);
            if (m.fullname != null && Object.hasOwnProperty.call(m, "fullname"))
                w.uint32(66).string(m.fullname);
            if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                w.uint32(74).string(m.email);
            if (m.birthday != null && Object.hasOwnProperty.call(m, "birthday"))
                $root.cat.DateMsg.encode(m.birthday, w.uint32(90).fork()).ldelim();
            if (m.bio != null && Object.hasOwnProperty.call(m, "bio"))
                w.uint32(98).string(m.bio);
            if (m.website != null && Object.hasOwnProperty.call(m, "website"))
                w.uint32(106).string(m.website);
            if (m.location != null && Object.hasOwnProperty.call(m, "location"))
                w.uint32(114).string(m.location);
            if (m.avatarexternid != null && Object.hasOwnProperty.call(m, "avatarexternid"))
                w.uint32(122).string(m.avatarexternid);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(128).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(136).uint64(m.lastmodified);
            if (m.raw != null && Object.hasOwnProperty.call(m, "raw"))
                $root.cat.RawMsg.encode(m.raw, w.uint32(146).fork()).ldelim();
            if (m.externid != null && Object.hasOwnProperty.call(m, "externid"))
                w.uint32(154).string(m.externid);
            if (m.isdirect != null && Object.hasOwnProperty.call(m, "isdirect"))
                w.uint32(160).bool(m.isdirect);
            if (m.properties != null && m.properties.length) {
                for (var i = 0; i < m.properties.length; ++i)
                    $root.cat.AccountPropertyMsg.encode(m.properties[i], w.uint32(170).fork()).ldelim();
            }
            if (m.tsatimestamp != null && Object.hasOwnProperty.call(m, "tsatimestamp"))
                w.uint32(176).uint64(m.tsatimestamp);
            if (m.tsasignature != null && Object.hasOwnProperty.call(m, "tsasignature"))
                w.uint32(186).string(m.tsasignature);
            if (m.alias != null && Object.hasOwnProperty.call(m, "alias"))
                w.uint32(194).string(m.alias);
            if (m.paidsubscription != null && Object.hasOwnProperty.call(m, "paidsubscription"))
                w.uint32(202).string(m.paidsubscription);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(808).int32(m.type);
            if (m.avatar != null && Object.hasOwnProperty.call(m, "avatar"))
                $root.cat.AttachmentMsg.encode(m.avatar, w.uint32(818).fork()).ldelim();
            if (m.apptype != null && Object.hasOwnProperty.call(m, "apptype"))
                $root.cat.AppTypeMsg.encode(m.apptype, w.uint32(826).fork()).ldelim();
            if (m.servers != null && m.servers.length) {
                for (var i = 0; i < m.servers.length; ++i)
                    $root.cat.ServerMsg.encode(m.servers[i], w.uint32(834).fork()).ldelim();
            }
            if (m.conversations != null && m.conversations.length) {
                for (var i = 0; i < m.conversations.length; ++i)
                    $root.cat.ConversationMsg.encode(m.conversations[i], w.uint32(842).fork()).ldelim();
            }
            if (m.memberstatus != null && Object.hasOwnProperty.call(m, "memberstatus"))
                $root.cat.MemberStatusMsg.encode(m.memberstatus, w.uint32(850).fork()).ldelim();
            if (m.contacts != null && m.contacts.length) {
                for (var i = 0; i < m.contacts.length; ++i)
                    $root.cat.AccountMsg.encode(m.contacts[i], w.uint32(858).fork()).ldelim();
            }
            if (m.labels != null && m.labels.length) {
                for (var i = 0; i < m.labels.length; ++i)
                    $root.cat.LabelMsg.encode(m.labels[i], w.uint32(866).fork()).ldelim();
            }
            if (m.annotations != null && m.annotations.length) {
                for (var i = 0; i < m.annotations.length; ++i)
                    $root.cat.AnnotationMsg.encode(m.annotations[i], w.uint32(874).fork()).ldelim();
            }
            if (m.accountrefid != null && Object.hasOwnProperty.call(m, "accountrefid"))
                w.uint32(882).string(m.accountrefid);
            if (m.apprefid != null && Object.hasOwnProperty.call(m, "apprefid"))
                w.uint32(890).string(m.apprefid);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(896).uint64(m.count);
            if (m.lastactivity != null && Object.hasOwnProperty.call(m, "lastactivity"))
                w.uint32(904).uint64(m.lastactivity);
            if (m.profile != null && Object.hasOwnProperty.call(m, "profile"))
                $root.cat.ProfileMsg.encode(m.profile, w.uint32(914).fork()).ldelim();
            if (m.servernicknames != null && m.servernicknames.length) {
                for (var i = 0; i < m.servernicknames.length; ++i)
                    w.uint32(922).string(m.servernicknames[i]);
            }
            if (m.history != null && m.history.length) {
                for (var i = 0; i < m.history.length; ++i)
                    $root.cat.AccountMsg.encode(m.history[i], w.uint32(930).fork()).ldelim();
            }
            if (m.historymarkers != null && m.historymarkers.length) {
                w.uint32(938).fork();
                for (var i = 0; i < m.historymarkers.length; ++i)
                    w.int32(m.historymarkers[i]);
                w.ldelim();
            }
            if (m.historytimestamp != null && Object.hasOwnProperty.call(m, "historytimestamp"))
                w.uint32(944).uint64(m.historytimestamp);
            return w;
        };

        /**
         * Decodes an AccountMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.AccountMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.AccountMsg} AccountMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AccountMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.AccountMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.status = r.int32();
                        break;
                    }
                case 3: {
                        m.phonenumber = r.string();
                        break;
                    }
                case 4: {
                        m.username = r.string();
                        break;
                    }
                case 5: {
                        m.password = r.string();
                        break;
                    }
                case 6: {
                        m.firstname = r.string();
                        break;
                    }
                case 7: {
                        m.lastname = r.string();
                        break;
                    }
                case 8: {
                        m.fullname = r.string();
                        break;
                    }
                case 9: {
                        m.email = r.string();
                        break;
                    }
                case 11: {
                        m.birthday = $root.cat.DateMsg.decode(r, r.uint32());
                        break;
                    }
                case 12: {
                        m.bio = r.string();
                        break;
                    }
                case 13: {
                        m.website = r.string();
                        break;
                    }
                case 14: {
                        m.location = r.string();
                        break;
                    }
                case 15: {
                        m.avatarexternid = r.string();
                        break;
                    }
                case 16: {
                        m.created = r.uint64();
                        break;
                    }
                case 17: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 18: {
                        m.raw = $root.cat.RawMsg.decode(r, r.uint32());
                        break;
                    }
                case 19: {
                        m.externid = r.string();
                        break;
                    }
                case 20: {
                        m.isdirect = r.bool();
                        break;
                    }
                case 21: {
                        if (!(m.properties && m.properties.length))
                            m.properties = [];
                        m.properties.push($root.cat.AccountPropertyMsg.decode(r, r.uint32()));
                        break;
                    }
                case 22: {
                        m.tsatimestamp = r.uint64();
                        break;
                    }
                case 23: {
                        m.tsasignature = r.string();
                        break;
                    }
                case 24: {
                        m.alias = r.string();
                        break;
                    }
                case 25: {
                        m.paidsubscription = r.string();
                        break;
                    }
                case 101: {
                        m.type = r.int32();
                        break;
                    }
                case 102: {
                        m.avatar = $root.cat.AttachmentMsg.decode(r, r.uint32());
                        break;
                    }
                case 103: {
                        m.apptype = $root.cat.AppTypeMsg.decode(r, r.uint32());
                        break;
                    }
                case 104: {
                        if (!(m.servers && m.servers.length))
                            m.servers = [];
                        m.servers.push($root.cat.ServerMsg.decode(r, r.uint32()));
                        break;
                    }
                case 105: {
                        if (!(m.conversations && m.conversations.length))
                            m.conversations = [];
                        m.conversations.push($root.cat.ConversationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 106: {
                        m.memberstatus = $root.cat.MemberStatusMsg.decode(r, r.uint32());
                        break;
                    }
                case 107: {
                        if (!(m.contacts && m.contacts.length))
                            m.contacts = [];
                        m.contacts.push($root.cat.AccountMsg.decode(r, r.uint32()));
                        break;
                    }
                case 108: {
                        if (!(m.labels && m.labels.length))
                            m.labels = [];
                        m.labels.push($root.cat.LabelMsg.decode(r, r.uint32()));
                        break;
                    }
                case 109: {
                        if (!(m.annotations && m.annotations.length))
                            m.annotations = [];
                        m.annotations.push($root.cat.AnnotationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 110: {
                        m.accountrefid = r.string();
                        break;
                    }
                case 111: {
                        m.apprefid = r.string();
                        break;
                    }
                case 112: {
                        m.count = r.uint64();
                        break;
                    }
                case 113: {
                        m.lastactivity = r.uint64();
                        break;
                    }
                case 114: {
                        m.profile = $root.cat.ProfileMsg.decode(r, r.uint32());
                        break;
                    }
                case 115: {
                        if (!(m.servernicknames && m.servernicknames.length))
                            m.servernicknames = [];
                        m.servernicknames.push(r.string());
                        break;
                    }
                case 116: {
                        if (!(m.history && m.history.length))
                            m.history = [];
                        m.history.push($root.cat.AccountMsg.decode(r, r.uint32()));
                        break;
                    }
                case 117: {
                        if (!(m.historymarkers && m.historymarkers.length))
                            m.historymarkers = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.historymarkers.push(r.int32());
                        } else
                            m.historymarkers.push(r.int32());
                        break;
                    }
                case 118: {
                        m.historytimestamp = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AccountMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.AccountMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.AccountMsg} AccountMsg
         */
        AccountMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.AccountMsg)
                return d;
            var m = new $root.cat.AccountMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ENABLED":
            case 1:
                m.status = 1;
                break;
            case "DISABLED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.phonenumber != null) {
                m.phonenumber = String(d.phonenumber);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            if (d.firstname != null) {
                m.firstname = String(d.firstname);
            }
            if (d.lastname != null) {
                m.lastname = String(d.lastname);
            }
            if (d.fullname != null) {
                m.fullname = String(d.fullname);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.birthday != null) {
                if (typeof d.birthday !== "object")
                    throw TypeError(".cat.AccountMsg.birthday: object expected");
                m.birthday = $root.cat.DateMsg.fromObject(d.birthday);
            }
            if (d.bio != null) {
                m.bio = String(d.bio);
            }
            if (d.website != null) {
                m.website = String(d.website);
            }
            if (d.location != null) {
                m.location = String(d.location);
            }
            if (d.avatarexternid != null) {
                m.avatarexternid = String(d.avatarexternid);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.raw != null) {
                if (typeof d.raw !== "object")
                    throw TypeError(".cat.AccountMsg.raw: object expected");
                m.raw = $root.cat.RawMsg.fromObject(d.raw);
            }
            if (d.externid != null) {
                m.externid = String(d.externid);
            }
            if (d.isdirect != null) {
                m.isdirect = Boolean(d.isdirect);
            }
            if (d.properties) {
                if (!Array.isArray(d.properties))
                    throw TypeError(".cat.AccountMsg.properties: array expected");
                m.properties = [];
                for (var i = 0; i < d.properties.length; ++i) {
                    if (typeof d.properties[i] !== "object")
                        throw TypeError(".cat.AccountMsg.properties: object expected");
                    m.properties[i] = $root.cat.AccountPropertyMsg.fromObject(d.properties[i]);
                }
            }
            if (d.tsatimestamp != null) {
                if ($util.Long)
                    (m.tsatimestamp = $util.Long.fromValue(d.tsatimestamp)).unsigned = true;
                else if (typeof d.tsatimestamp === "string")
                    m.tsatimestamp = parseInt(d.tsatimestamp, 10);
                else if (typeof d.tsatimestamp === "number")
                    m.tsatimestamp = d.tsatimestamp;
                else if (typeof d.tsatimestamp === "object")
                    m.tsatimestamp = new $util.LongBits(d.tsatimestamp.low >>> 0, d.tsatimestamp.high >>> 0).toNumber(true);
            }
            if (d.tsasignature != null) {
                m.tsasignature = String(d.tsasignature);
            }
            if (d.alias != null) {
                m.alias = String(d.alias);
            }
            if (d.paidsubscription != null) {
                m.paidsubscription = String(d.paidsubscription);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "ACCOUNT_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "ACCOUNT_PROFILE":
            case 1:
                m.type = 1;
                break;
            case "ACCOUNT_CONTACT":
            case 2:
                m.type = 2;
                break;
            case "ACCOUNT_BOT":
            case 3:
                m.type = 3;
                break;
            case "ACCOUNT_FORWARD":
            case 4:
                m.type = 4;
                break;
            }
            if (d.avatar != null) {
                if (typeof d.avatar !== "object")
                    throw TypeError(".cat.AccountMsg.avatar: object expected");
                m.avatar = $root.cat.AttachmentMsg.fromObject(d.avatar);
            }
            if (d.apptype != null) {
                if (typeof d.apptype !== "object")
                    throw TypeError(".cat.AccountMsg.apptype: object expected");
                m.apptype = $root.cat.AppTypeMsg.fromObject(d.apptype);
            }
            if (d.servers) {
                if (!Array.isArray(d.servers))
                    throw TypeError(".cat.AccountMsg.servers: array expected");
                m.servers = [];
                for (var i = 0; i < d.servers.length; ++i) {
                    if (typeof d.servers[i] !== "object")
                        throw TypeError(".cat.AccountMsg.servers: object expected");
                    m.servers[i] = $root.cat.ServerMsg.fromObject(d.servers[i]);
                }
            }
            if (d.conversations) {
                if (!Array.isArray(d.conversations))
                    throw TypeError(".cat.AccountMsg.conversations: array expected");
                m.conversations = [];
                for (var i = 0; i < d.conversations.length; ++i) {
                    if (typeof d.conversations[i] !== "object")
                        throw TypeError(".cat.AccountMsg.conversations: object expected");
                    m.conversations[i] = $root.cat.ConversationMsg.fromObject(d.conversations[i]);
                }
            }
            if (d.memberstatus != null) {
                if (typeof d.memberstatus !== "object")
                    throw TypeError(".cat.AccountMsg.memberstatus: object expected");
                m.memberstatus = $root.cat.MemberStatusMsg.fromObject(d.memberstatus);
            }
            if (d.contacts) {
                if (!Array.isArray(d.contacts))
                    throw TypeError(".cat.AccountMsg.contacts: array expected");
                m.contacts = [];
                for (var i = 0; i < d.contacts.length; ++i) {
                    if (typeof d.contacts[i] !== "object")
                        throw TypeError(".cat.AccountMsg.contacts: object expected");
                    m.contacts[i] = $root.cat.AccountMsg.fromObject(d.contacts[i]);
                }
            }
            if (d.labels) {
                if (!Array.isArray(d.labels))
                    throw TypeError(".cat.AccountMsg.labels: array expected");
                m.labels = [];
                for (var i = 0; i < d.labels.length; ++i) {
                    if (typeof d.labels[i] !== "object")
                        throw TypeError(".cat.AccountMsg.labels: object expected");
                    m.labels[i] = $root.cat.LabelMsg.fromObject(d.labels[i]);
                }
            }
            if (d.annotations) {
                if (!Array.isArray(d.annotations))
                    throw TypeError(".cat.AccountMsg.annotations: array expected");
                m.annotations = [];
                for (var i = 0; i < d.annotations.length; ++i) {
                    if (typeof d.annotations[i] !== "object")
                        throw TypeError(".cat.AccountMsg.annotations: object expected");
                    m.annotations[i] = $root.cat.AnnotationMsg.fromObject(d.annotations[i]);
                }
            }
            if (d.accountrefid != null) {
                m.accountrefid = String(d.accountrefid);
            }
            if (d.apprefid != null) {
                m.apprefid = String(d.apprefid);
            }
            if (d.count != null) {
                if ($util.Long)
                    (m.count = $util.Long.fromValue(d.count)).unsigned = true;
                else if (typeof d.count === "string")
                    m.count = parseInt(d.count, 10);
                else if (typeof d.count === "number")
                    m.count = d.count;
                else if (typeof d.count === "object")
                    m.count = new $util.LongBits(d.count.low >>> 0, d.count.high >>> 0).toNumber(true);
            }
            if (d.lastactivity != null) {
                if ($util.Long)
                    (m.lastactivity = $util.Long.fromValue(d.lastactivity)).unsigned = true;
                else if (typeof d.lastactivity === "string")
                    m.lastactivity = parseInt(d.lastactivity, 10);
                else if (typeof d.lastactivity === "number")
                    m.lastactivity = d.lastactivity;
                else if (typeof d.lastactivity === "object")
                    m.lastactivity = new $util.LongBits(d.lastactivity.low >>> 0, d.lastactivity.high >>> 0).toNumber(true);
            }
            if (d.profile != null) {
                if (typeof d.profile !== "object")
                    throw TypeError(".cat.AccountMsg.profile: object expected");
                m.profile = $root.cat.ProfileMsg.fromObject(d.profile);
            }
            if (d.servernicknames) {
                if (!Array.isArray(d.servernicknames))
                    throw TypeError(".cat.AccountMsg.servernicknames: array expected");
                m.servernicknames = [];
                for (var i = 0; i < d.servernicknames.length; ++i) {
                    m.servernicknames[i] = String(d.servernicknames[i]);
                }
            }
            if (d.history) {
                if (!Array.isArray(d.history))
                    throw TypeError(".cat.AccountMsg.history: array expected");
                m.history = [];
                for (var i = 0; i < d.history.length; ++i) {
                    if (typeof d.history[i] !== "object")
                        throw TypeError(".cat.AccountMsg.history: object expected");
                    m.history[i] = $root.cat.AccountMsg.fromObject(d.history[i]);
                }
            }
            if (d.historymarkers) {
                if (!Array.isArray(d.historymarkers))
                    throw TypeError(".cat.AccountMsg.historymarkers: array expected");
                m.historymarkers = [];
                for (var i = 0; i < d.historymarkers.length; ++i) {
                    switch (d.historymarkers[i]) {
                    default:
                        if (typeof d.historymarkers[i] === "number") {
                            m.historymarkers[i] = d.historymarkers[i];
                            break;
                        }
                    case "HISTORYMARKER_UNKNOWN":
                    case 0:
                        m.historymarkers[i] = 0;
                        break;
                    case "HISTORYMARKER_NAME":
                    case 1:
                        m.historymarkers[i] = 1;
                        break;
                    case "HISTORYMARKER_FIRSTNAME":
                    case 2:
                        m.historymarkers[i] = 2;
                        break;
                    case "HISTORYMARKER_LASTNAME":
                    case 3:
                        m.historymarkers[i] = 3;
                        break;
                    case "HISTORYMARKER_FULLNAME":
                    case 4:
                        m.historymarkers[i] = 4;
                        break;
                    case "HISTORYMARKER_BIO":
                    case 5:
                        m.historymarkers[i] = 5;
                        break;
                    case "HISTORYMARKER_EDITED":
                    case 6:
                        m.historymarkers[i] = 6;
                        break;
                    case "HISTORYMARKER_DELETED":
                    case 7:
                        m.historymarkers[i] = 7;
                        break;
                    case "HISTORYMARKER_DESCRIPTION":
                    case 8:
                        m.historymarkers[i] = 8;
                        break;
                    case "HISTORYMARKER_ALTNAMES":
                    case 9:
                        m.historymarkers[i] = 9;
                        break;
                    case "HISTORYMARKER_PLAINNAMES":
                    case 10:
                        m.historymarkers[i] = 10;
                        break;
                    case "HISTORYMARKER_LINK":
                    case 11:
                        m.historymarkers[i] = 11;
                        break;
                    case "HISTORYMARKER_BODY":
                    case 12:
                        m.historymarkers[i] = 12;
                        break;
                    case "HISTORYMARKER_OPTIONS":
                    case 13:
                        m.historymarkers[i] = 13;
                        break;
                    case "HISTORYMARKER_AVATAR":
                    case 14:
                        m.historymarkers[i] = 14;
                        break;
                    case "HISTORYMARKER_ATTACHMENT":
                    case 15:
                        m.historymarkers[i] = 15;
                        break;
                    case "HISTORYMARKER_GROUP":
                    case 16:
                        m.historymarkers[i] = 16;
                        break;
                    case "HISTORYMARKER_TYPE":
                    case 17:
                        m.historymarkers[i] = 17;
                        break;
                    }
                }
            }
            if (d.historytimestamp != null) {
                if ($util.Long)
                    (m.historytimestamp = $util.Long.fromValue(d.historytimestamp)).unsigned = true;
                else if (typeof d.historytimestamp === "string")
                    m.historytimestamp = parseInt(d.historytimestamp, 10);
                else if (typeof d.historytimestamp === "number")
                    m.historytimestamp = d.historytimestamp;
                else if (typeof d.historytimestamp === "object")
                    m.historytimestamp = new $util.LongBits(d.historytimestamp.low >>> 0, d.historytimestamp.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from an AccountMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.AccountMsg
         * @static
         * @param {cat.AccountMsg} m AccountMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AccountMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.properties = [];
                d.servers = [];
                d.conversations = [];
                d.contacts = [];
                d.labels = [];
                d.annotations = [];
                d.servernicknames = [];
                d.history = [];
                d.historymarkers = [];
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.raw = null;
                d.externid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.tsatimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.tsatimestamp = o.longs === String ? "0" : 0;
                d.tsasignature = "";
                d.type = o.enums === String ? "ACCOUNT_UNKNOWN" : 0;
                d.avatar = null;
                d.apptype = null;
                d.memberstatus = null;
                d.accountrefid = "";
                d.apprefid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.count = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.count = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastactivity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastactivity = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.historytimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.historytimestamp = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.StatusType[m.status] === undefined ? m.status : $root.cat.StatusType[m.status] : m.status;
                if (o.oneofs)
                    d._status = "status";
            }
            if (m.phonenumber != null && m.hasOwnProperty("phonenumber")) {
                d.phonenumber = m.phonenumber;
                if (o.oneofs)
                    d._phonenumber = "phonenumber";
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
                if (o.oneofs)
                    d._username = "username";
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
                if (o.oneofs)
                    d._password = "password";
            }
            if (m.firstname != null && m.hasOwnProperty("firstname")) {
                d.firstname = m.firstname;
                if (o.oneofs)
                    d._firstname = "firstname";
            }
            if (m.lastname != null && m.hasOwnProperty("lastname")) {
                d.lastname = m.lastname;
                if (o.oneofs)
                    d._lastname = "lastname";
            }
            if (m.fullname != null && m.hasOwnProperty("fullname")) {
                d.fullname = m.fullname;
                if (o.oneofs)
                    d._fullname = "fullname";
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
                if (o.oneofs)
                    d._email = "email";
            }
            if (m.birthday != null && m.hasOwnProperty("birthday")) {
                d.birthday = $root.cat.DateMsg.toObject(m.birthday, o);
                if (o.oneofs)
                    d._birthday = "birthday";
            }
            if (m.bio != null && m.hasOwnProperty("bio")) {
                d.bio = m.bio;
                if (o.oneofs)
                    d._bio = "bio";
            }
            if (m.website != null && m.hasOwnProperty("website")) {
                d.website = m.website;
                if (o.oneofs)
                    d._website = "website";
            }
            if (m.location != null && m.hasOwnProperty("location")) {
                d.location = m.location;
                if (o.oneofs)
                    d._location = "location";
            }
            if (m.avatarexternid != null && m.hasOwnProperty("avatarexternid")) {
                d.avatarexternid = m.avatarexternid;
                if (o.oneofs)
                    d._avatarexternid = "avatarexternid";
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.raw != null && m.hasOwnProperty("raw")) {
                d.raw = $root.cat.RawMsg.toObject(m.raw, o);
            }
            if (m.externid != null && m.hasOwnProperty("externid")) {
                d.externid = m.externid;
            }
            if (m.isdirect != null && m.hasOwnProperty("isdirect")) {
                d.isdirect = m.isdirect;
                if (o.oneofs)
                    d._isdirect = "isdirect";
            }
            if (m.properties && m.properties.length) {
                d.properties = [];
                for (var j = 0; j < m.properties.length; ++j) {
                    d.properties[j] = $root.cat.AccountPropertyMsg.toObject(m.properties[j], o);
                }
            }
            if (m.tsatimestamp != null && m.hasOwnProperty("tsatimestamp")) {
                if (typeof m.tsatimestamp === "number")
                    d.tsatimestamp = o.longs === String ? String(m.tsatimestamp) : m.tsatimestamp;
                else
                    d.tsatimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.tsatimestamp) : o.longs === Number ? new $util.LongBits(m.tsatimestamp.low >>> 0, m.tsatimestamp.high >>> 0).toNumber(true) : m.tsatimestamp;
            }
            if (m.tsasignature != null && m.hasOwnProperty("tsasignature")) {
                d.tsasignature = m.tsasignature;
            }
            if (m.alias != null && m.hasOwnProperty("alias")) {
                d.alias = m.alias;
                if (o.oneofs)
                    d._alias = "alias";
            }
            if (m.paidsubscription != null && m.hasOwnProperty("paidsubscription")) {
                d.paidsubscription = m.paidsubscription;
                if (o.oneofs)
                    d._paidsubscription = "paidsubscription";
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.AccountType[m.type] === undefined ? m.type : $root.cat.AccountType[m.type] : m.type;
            }
            if (m.avatar != null && m.hasOwnProperty("avatar")) {
                d.avatar = $root.cat.AttachmentMsg.toObject(m.avatar, o);
            }
            if (m.apptype != null && m.hasOwnProperty("apptype")) {
                d.apptype = $root.cat.AppTypeMsg.toObject(m.apptype, o);
            }
            if (m.servers && m.servers.length) {
                d.servers = [];
                for (var j = 0; j < m.servers.length; ++j) {
                    d.servers[j] = $root.cat.ServerMsg.toObject(m.servers[j], o);
                }
            }
            if (m.conversations && m.conversations.length) {
                d.conversations = [];
                for (var j = 0; j < m.conversations.length; ++j) {
                    d.conversations[j] = $root.cat.ConversationMsg.toObject(m.conversations[j], o);
                }
            }
            if (m.memberstatus != null && m.hasOwnProperty("memberstatus")) {
                d.memberstatus = $root.cat.MemberStatusMsg.toObject(m.memberstatus, o);
            }
            if (m.contacts && m.contacts.length) {
                d.contacts = [];
                for (var j = 0; j < m.contacts.length; ++j) {
                    d.contacts[j] = $root.cat.AccountMsg.toObject(m.contacts[j], o);
                }
            }
            if (m.labels && m.labels.length) {
                d.labels = [];
                for (var j = 0; j < m.labels.length; ++j) {
                    d.labels[j] = $root.cat.LabelMsg.toObject(m.labels[j], o);
                }
            }
            if (m.annotations && m.annotations.length) {
                d.annotations = [];
                for (var j = 0; j < m.annotations.length; ++j) {
                    d.annotations[j] = $root.cat.AnnotationMsg.toObject(m.annotations[j], o);
                }
            }
            if (m.accountrefid != null && m.hasOwnProperty("accountrefid")) {
                d.accountrefid = m.accountrefid;
            }
            if (m.apprefid != null && m.hasOwnProperty("apprefid")) {
                d.apprefid = m.apprefid;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                if (typeof m.count === "number")
                    d.count = o.longs === String ? String(m.count) : m.count;
                else
                    d.count = o.longs === String ? $util.Long.prototype.toString.call(m.count) : o.longs === Number ? new $util.LongBits(m.count.low >>> 0, m.count.high >>> 0).toNumber(true) : m.count;
            }
            if (m.lastactivity != null && m.hasOwnProperty("lastactivity")) {
                if (typeof m.lastactivity === "number")
                    d.lastactivity = o.longs === String ? String(m.lastactivity) : m.lastactivity;
                else
                    d.lastactivity = o.longs === String ? $util.Long.prototype.toString.call(m.lastactivity) : o.longs === Number ? new $util.LongBits(m.lastactivity.low >>> 0, m.lastactivity.high >>> 0).toNumber(true) : m.lastactivity;
            }
            if (m.profile != null && m.hasOwnProperty("profile")) {
                d.profile = $root.cat.ProfileMsg.toObject(m.profile, o);
                if (o.oneofs)
                    d._profile = "profile";
            }
            if (m.servernicknames && m.servernicknames.length) {
                d.servernicknames = [];
                for (var j = 0; j < m.servernicknames.length; ++j) {
                    d.servernicknames[j] = m.servernicknames[j];
                }
            }
            if (m.history && m.history.length) {
                d.history = [];
                for (var j = 0; j < m.history.length; ++j) {
                    d.history[j] = $root.cat.AccountMsg.toObject(m.history[j], o);
                }
            }
            if (m.historymarkers && m.historymarkers.length) {
                d.historymarkers = [];
                for (var j = 0; j < m.historymarkers.length; ++j) {
                    d.historymarkers[j] = o.enums === String ? $root.cat.HistoryMarker[m.historymarkers[j]] === undefined ? m.historymarkers[j] : $root.cat.HistoryMarker[m.historymarkers[j]] : m.historymarkers[j];
                }
            }
            if (m.historytimestamp != null && m.hasOwnProperty("historytimestamp")) {
                if (typeof m.historytimestamp === "number")
                    d.historytimestamp = o.longs === String ? String(m.historytimestamp) : m.historytimestamp;
                else
                    d.historytimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.historytimestamp) : o.longs === Number ? new $util.LongBits(m.historytimestamp.low >>> 0, m.historytimestamp.high >>> 0).toNumber(true) : m.historytimestamp;
            }
            return d;
        };

        /**
         * Converts this AccountMsg to JSON.
         * @function toJSON
         * @memberof cat.AccountMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AccountMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AccountMsg
         * @function getTypeUrl
         * @memberof cat.AccountMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AccountMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.AccountMsg";
        };

        return AccountMsg;
    })();

    cat.ServerMsg = (function() {

        /**
         * Properties of a ServerMsg.
         * @memberof cat
         * @interface IServerMsg
         * @property {string|null} [id] ServerMsg id
         * @property {string|null} [name] ServerMsg name
         * @property {string|null} [description] ServerMsg description
         * @property {number|null} [created] ServerMsg created
         * @property {number|null} [lastmodified] ServerMsg lastmodified
         * @property {string|null} [externid] ServerMsg externid
         * @property {cat.ServerState|null} [state] ServerMsg state
         * @property {cat.ServerType|null} [type] ServerMsg type
         * @property {string|null} [avatarexternid] ServerMsg avatarexternid
         * @property {cat.IRawMsg|null} [raw] ServerMsg raw
         * @property {number|null} [tsatimestamp] ServerMsg tsatimestamp
         * @property {string|null} [tsasignature] ServerMsg tsasignature
         * @property {cat.VisibilityType|null} [visibility] ServerMsg visibility
         * @property {cat.IAttachmentMsg|null} [avatar] ServerMsg avatar
         * @property {Array.<cat.IConversationMsg>|null} [conversations] ServerMsg conversations
         * @property {Array.<cat.ILabelMsg>|null} [labels] ServerMsg labels
         * @property {Array.<cat.IAnnotationMsg>|null} [annotations] ServerMsg annotations
         * @property {Array.<cat.IAttachmentMsg>|null} [emojis] ServerMsg emojis
         * @property {cat.IAccountMsg|null} [account] ServerMsg account
         * @property {Array.<string>|null} [usernicknames] ServerMsg usernicknames
         * @property {number|null} [conversationcount] ServerMsg conversationcount
         * @property {string|null} [apprefid] ServerMsg apprefid
         * @property {Array.<cat.IServerMsg>|null} [history] ServerMsg history
         * @property {Array.<cat.HistoryMarker>|null} [historymarkers] ServerMsg historymarkers
         * @property {number|null} [historytimestamp] ServerMsg historytimestamp
         */

        /**
         * Constructs a new ServerMsg.
         * @memberof cat
         * @classdesc Represents a ServerMsg.
         * @implements IServerMsg
         * @constructor
         * @param {cat.IServerMsg=} [p] Properties to set
         */
        function ServerMsg(p) {
            this.conversations = [];
            this.labels = [];
            this.annotations = [];
            this.emojis = [];
            this.usernicknames = [];
            this.history = [];
            this.historymarkers = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ServerMsg id.
         * @member {string} id
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.id = "";

        /**
         * ServerMsg name.
         * @member {string|null|undefined} name
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.name = null;

        /**
         * ServerMsg description.
         * @member {string|null|undefined} description
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.description = null;

        /**
         * ServerMsg created.
         * @member {number} created
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ServerMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ServerMsg externid.
         * @member {string} externid
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.externid = "";

        /**
         * ServerMsg state.
         * @member {cat.ServerState|null|undefined} state
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.state = null;

        /**
         * ServerMsg type.
         * @member {cat.ServerType|null|undefined} type
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.type = null;

        /**
         * ServerMsg avatarexternid.
         * @member {string|null|undefined} avatarexternid
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.avatarexternid = null;

        /**
         * ServerMsg raw.
         * @member {cat.IRawMsg|null|undefined} raw
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.raw = null;

        /**
         * ServerMsg tsatimestamp.
         * @member {number} tsatimestamp
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.tsatimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ServerMsg tsasignature.
         * @member {string} tsasignature
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.tsasignature = "";

        /**
         * ServerMsg visibility.
         * @member {cat.VisibilityType} visibility
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.visibility = 0;

        /**
         * ServerMsg avatar.
         * @member {cat.IAttachmentMsg|null|undefined} avatar
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.avatar = null;

        /**
         * ServerMsg conversations.
         * @member {Array.<cat.IConversationMsg>} conversations
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.conversations = $util.emptyArray;

        /**
         * ServerMsg labels.
         * @member {Array.<cat.ILabelMsg>} labels
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.labels = $util.emptyArray;

        /**
         * ServerMsg annotations.
         * @member {Array.<cat.IAnnotationMsg>} annotations
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.annotations = $util.emptyArray;

        /**
         * ServerMsg emojis.
         * @member {Array.<cat.IAttachmentMsg>} emojis
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.emojis = $util.emptyArray;

        /**
         * ServerMsg account.
         * @member {cat.IAccountMsg|null|undefined} account
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.account = null;

        /**
         * ServerMsg usernicknames.
         * @member {Array.<string>} usernicknames
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.usernicknames = $util.emptyArray;

        /**
         * ServerMsg conversationcount.
         * @member {number} conversationcount
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.conversationcount = 0;

        /**
         * ServerMsg apprefid.
         * @member {string} apprefid
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.apprefid = "";

        /**
         * ServerMsg history.
         * @member {Array.<cat.IServerMsg>} history
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.history = $util.emptyArray;

        /**
         * ServerMsg historymarkers.
         * @member {Array.<cat.HistoryMarker>} historymarkers
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.historymarkers = $util.emptyArray;

        /**
         * ServerMsg historytimestamp.
         * @member {number} historytimestamp
         * @memberof cat.ServerMsg
         * @instance
         */
        ServerMsg.prototype.historytimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ServerMsg.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ServerMsg.prototype, "_description", {
            get: $util.oneOfGetter($oneOfFields = ["description"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ServerMsg.prototype, "_state", {
            get: $util.oneOfGetter($oneOfFields = ["state"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ServerMsg.prototype, "_type", {
            get: $util.oneOfGetter($oneOfFields = ["type"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ServerMsg.prototype, "_avatarexternid", {
            get: $util.oneOfGetter($oneOfFields = ["avatarexternid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ServerMsg instance using the specified properties.
         * @function create
         * @memberof cat.ServerMsg
         * @static
         * @param {cat.IServerMsg=} [properties] Properties to set
         * @returns {cat.ServerMsg} ServerMsg instance
         */
        ServerMsg.create = function create(properties) {
            return new ServerMsg(properties);
        };

        /**
         * Encodes the specified ServerMsg message. Does not implicitly {@link cat.ServerMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ServerMsg
         * @static
         * @param {cat.IServerMsg} m ServerMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(26).string(m.description);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(32).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(40).uint64(m.lastmodified);
            if (m.externid != null && Object.hasOwnProperty.call(m, "externid"))
                w.uint32(50).string(m.externid);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(56).int32(m.state);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(64).int32(m.type);
            if (m.avatarexternid != null && Object.hasOwnProperty.call(m, "avatarexternid"))
                w.uint32(74).string(m.avatarexternid);
            if (m.raw != null && Object.hasOwnProperty.call(m, "raw"))
                $root.cat.RawMsg.encode(m.raw, w.uint32(82).fork()).ldelim();
            if (m.tsatimestamp != null && Object.hasOwnProperty.call(m, "tsatimestamp"))
                w.uint32(88).uint64(m.tsatimestamp);
            if (m.tsasignature != null && Object.hasOwnProperty.call(m, "tsasignature"))
                w.uint32(98).string(m.tsasignature);
            if (m.visibility != null && Object.hasOwnProperty.call(m, "visibility"))
                w.uint32(104).int32(m.visibility);
            if (m.avatar != null && Object.hasOwnProperty.call(m, "avatar"))
                $root.cat.AttachmentMsg.encode(m.avatar, w.uint32(810).fork()).ldelim();
            if (m.conversations != null && m.conversations.length) {
                for (var i = 0; i < m.conversations.length; ++i)
                    $root.cat.ConversationMsg.encode(m.conversations[i], w.uint32(818).fork()).ldelim();
            }
            if (m.labels != null && m.labels.length) {
                for (var i = 0; i < m.labels.length; ++i)
                    $root.cat.LabelMsg.encode(m.labels[i], w.uint32(826).fork()).ldelim();
            }
            if (m.annotations != null && m.annotations.length) {
                for (var i = 0; i < m.annotations.length; ++i)
                    $root.cat.AnnotationMsg.encode(m.annotations[i], w.uint32(834).fork()).ldelim();
            }
            if (m.emojis != null && m.emojis.length) {
                for (var i = 0; i < m.emojis.length; ++i)
                    $root.cat.AttachmentMsg.encode(m.emojis[i], w.uint32(842).fork()).ldelim();
            }
            if (m.account != null && Object.hasOwnProperty.call(m, "account"))
                $root.cat.AccountMsg.encode(m.account, w.uint32(850).fork()).ldelim();
            if (m.usernicknames != null && m.usernicknames.length) {
                for (var i = 0; i < m.usernicknames.length; ++i)
                    w.uint32(858).string(m.usernicknames[i]);
            }
            if (m.conversationcount != null && Object.hasOwnProperty.call(m, "conversationcount"))
                w.uint32(864).uint32(m.conversationcount);
            if (m.apprefid != null && Object.hasOwnProperty.call(m, "apprefid"))
                w.uint32(882).string(m.apprefid);
            if (m.history != null && m.history.length) {
                for (var i = 0; i < m.history.length; ++i)
                    $root.cat.ServerMsg.encode(m.history[i], w.uint32(890).fork()).ldelim();
            }
            if (m.historymarkers != null && m.historymarkers.length) {
                w.uint32(898).fork();
                for (var i = 0; i < m.historymarkers.length; ++i)
                    w.int32(m.historymarkers[i]);
                w.ldelim();
            }
            if (m.historytimestamp != null && Object.hasOwnProperty.call(m, "historytimestamp"))
                w.uint32(904).uint64(m.historytimestamp);
            return w;
        };

        /**
         * Decodes a ServerMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ServerMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ServerMsg} ServerMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ServerMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.description = r.string();
                        break;
                    }
                case 4: {
                        m.created = r.uint64();
                        break;
                    }
                case 5: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 6: {
                        m.externid = r.string();
                        break;
                    }
                case 7: {
                        m.state = r.int32();
                        break;
                    }
                case 8: {
                        m.type = r.int32();
                        break;
                    }
                case 9: {
                        m.avatarexternid = r.string();
                        break;
                    }
                case 10: {
                        m.raw = $root.cat.RawMsg.decode(r, r.uint32());
                        break;
                    }
                case 11: {
                        m.tsatimestamp = r.uint64();
                        break;
                    }
                case 12: {
                        m.tsasignature = r.string();
                        break;
                    }
                case 13: {
                        m.visibility = r.int32();
                        break;
                    }
                case 101: {
                        m.avatar = $root.cat.AttachmentMsg.decode(r, r.uint32());
                        break;
                    }
                case 102: {
                        if (!(m.conversations && m.conversations.length))
                            m.conversations = [];
                        m.conversations.push($root.cat.ConversationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 103: {
                        if (!(m.labels && m.labels.length))
                            m.labels = [];
                        m.labels.push($root.cat.LabelMsg.decode(r, r.uint32()));
                        break;
                    }
                case 104: {
                        if (!(m.annotations && m.annotations.length))
                            m.annotations = [];
                        m.annotations.push($root.cat.AnnotationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 105: {
                        if (!(m.emojis && m.emojis.length))
                            m.emojis = [];
                        m.emojis.push($root.cat.AttachmentMsg.decode(r, r.uint32()));
                        break;
                    }
                case 106: {
                        m.account = $root.cat.AccountMsg.decode(r, r.uint32());
                        break;
                    }
                case 107: {
                        if (!(m.usernicknames && m.usernicknames.length))
                            m.usernicknames = [];
                        m.usernicknames.push(r.string());
                        break;
                    }
                case 108: {
                        m.conversationcount = r.uint32();
                        break;
                    }
                case 110: {
                        m.apprefid = r.string();
                        break;
                    }
                case 111: {
                        if (!(m.history && m.history.length))
                            m.history = [];
                        m.history.push($root.cat.ServerMsg.decode(r, r.uint32()));
                        break;
                    }
                case 112: {
                        if (!(m.historymarkers && m.historymarkers.length))
                            m.historymarkers = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.historymarkers.push(r.int32());
                        } else
                            m.historymarkers.push(r.int32());
                        break;
                    }
                case 113: {
                        m.historytimestamp = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ServerMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ServerMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ServerMsg} ServerMsg
         */
        ServerMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ServerMsg)
                return d;
            var m = new $root.cat.ServerMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.externid != null) {
                m.externid = String(d.externid);
            }
            switch (d.state) {
            default:
                if (typeof d.state === "number") {
                    m.state = d.state;
                    break;
                }
                break;
            case "SERVERSTATE_UNKNOWN":
            case 0:
                m.state = 0;
                break;
            case "SERVERSTATE_READY":
            case 1:
                m.state = 1;
                break;
            case "SERVERSTATE_CLOSED":
            case 2:
                m.state = 2;
                break;
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "SERVER_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "SERVER_PUBLIC":
            case 1:
                m.type = 1;
                break;
            case "SERVER_DM":
            case 2:
                m.type = 2;
                break;
            }
            if (d.avatarexternid != null) {
                m.avatarexternid = String(d.avatarexternid);
            }
            if (d.raw != null) {
                if (typeof d.raw !== "object")
                    throw TypeError(".cat.ServerMsg.raw: object expected");
                m.raw = $root.cat.RawMsg.fromObject(d.raw);
            }
            if (d.tsatimestamp != null) {
                if ($util.Long)
                    (m.tsatimestamp = $util.Long.fromValue(d.tsatimestamp)).unsigned = true;
                else if (typeof d.tsatimestamp === "string")
                    m.tsatimestamp = parseInt(d.tsatimestamp, 10);
                else if (typeof d.tsatimestamp === "number")
                    m.tsatimestamp = d.tsatimestamp;
                else if (typeof d.tsatimestamp === "object")
                    m.tsatimestamp = new $util.LongBits(d.tsatimestamp.low >>> 0, d.tsatimestamp.high >>> 0).toNumber(true);
            }
            if (d.tsasignature != null) {
                m.tsasignature = String(d.tsasignature);
            }
            switch (d.visibility) {
            default:
                if (typeof d.visibility === "number") {
                    m.visibility = d.visibility;
                    break;
                }
                break;
            case "VISIBILITY_UNKNOWN":
            case 0:
                m.visibility = 0;
                break;
            case "VISIBILITY_VISIBLE":
            case 1:
                m.visibility = 1;
                break;
            case "VISIBILITY_HIDDEN":
            case 2:
                m.visibility = 2;
                break;
            }
            if (d.avatar != null) {
                if (typeof d.avatar !== "object")
                    throw TypeError(".cat.ServerMsg.avatar: object expected");
                m.avatar = $root.cat.AttachmentMsg.fromObject(d.avatar);
            }
            if (d.conversations) {
                if (!Array.isArray(d.conversations))
                    throw TypeError(".cat.ServerMsg.conversations: array expected");
                m.conversations = [];
                for (var i = 0; i < d.conversations.length; ++i) {
                    if (typeof d.conversations[i] !== "object")
                        throw TypeError(".cat.ServerMsg.conversations: object expected");
                    m.conversations[i] = $root.cat.ConversationMsg.fromObject(d.conversations[i]);
                }
            }
            if (d.labels) {
                if (!Array.isArray(d.labels))
                    throw TypeError(".cat.ServerMsg.labels: array expected");
                m.labels = [];
                for (var i = 0; i < d.labels.length; ++i) {
                    if (typeof d.labels[i] !== "object")
                        throw TypeError(".cat.ServerMsg.labels: object expected");
                    m.labels[i] = $root.cat.LabelMsg.fromObject(d.labels[i]);
                }
            }
            if (d.annotations) {
                if (!Array.isArray(d.annotations))
                    throw TypeError(".cat.ServerMsg.annotations: array expected");
                m.annotations = [];
                for (var i = 0; i < d.annotations.length; ++i) {
                    if (typeof d.annotations[i] !== "object")
                        throw TypeError(".cat.ServerMsg.annotations: object expected");
                    m.annotations[i] = $root.cat.AnnotationMsg.fromObject(d.annotations[i]);
                }
            }
            if (d.emojis) {
                if (!Array.isArray(d.emojis))
                    throw TypeError(".cat.ServerMsg.emojis: array expected");
                m.emojis = [];
                for (var i = 0; i < d.emojis.length; ++i) {
                    if (typeof d.emojis[i] !== "object")
                        throw TypeError(".cat.ServerMsg.emojis: object expected");
                    m.emojis[i] = $root.cat.AttachmentMsg.fromObject(d.emojis[i]);
                }
            }
            if (d.account != null) {
                if (typeof d.account !== "object")
                    throw TypeError(".cat.ServerMsg.account: object expected");
                m.account = $root.cat.AccountMsg.fromObject(d.account);
            }
            if (d.usernicknames) {
                if (!Array.isArray(d.usernicknames))
                    throw TypeError(".cat.ServerMsg.usernicknames: array expected");
                m.usernicknames = [];
                for (var i = 0; i < d.usernicknames.length; ++i) {
                    m.usernicknames[i] = String(d.usernicknames[i]);
                }
            }
            if (d.conversationcount != null) {
                m.conversationcount = d.conversationcount >>> 0;
            }
            if (d.apprefid != null) {
                m.apprefid = String(d.apprefid);
            }
            if (d.history) {
                if (!Array.isArray(d.history))
                    throw TypeError(".cat.ServerMsg.history: array expected");
                m.history = [];
                for (var i = 0; i < d.history.length; ++i) {
                    if (typeof d.history[i] !== "object")
                        throw TypeError(".cat.ServerMsg.history: object expected");
                    m.history[i] = $root.cat.ServerMsg.fromObject(d.history[i]);
                }
            }
            if (d.historymarkers) {
                if (!Array.isArray(d.historymarkers))
                    throw TypeError(".cat.ServerMsg.historymarkers: array expected");
                m.historymarkers = [];
                for (var i = 0; i < d.historymarkers.length; ++i) {
                    switch (d.historymarkers[i]) {
                    default:
                        if (typeof d.historymarkers[i] === "number") {
                            m.historymarkers[i] = d.historymarkers[i];
                            break;
                        }
                    case "HISTORYMARKER_UNKNOWN":
                    case 0:
                        m.historymarkers[i] = 0;
                        break;
                    case "HISTORYMARKER_NAME":
                    case 1:
                        m.historymarkers[i] = 1;
                        break;
                    case "HISTORYMARKER_FIRSTNAME":
                    case 2:
                        m.historymarkers[i] = 2;
                        break;
                    case "HISTORYMARKER_LASTNAME":
                    case 3:
                        m.historymarkers[i] = 3;
                        break;
                    case "HISTORYMARKER_FULLNAME":
                    case 4:
                        m.historymarkers[i] = 4;
                        break;
                    case "HISTORYMARKER_BIO":
                    case 5:
                        m.historymarkers[i] = 5;
                        break;
                    case "HISTORYMARKER_EDITED":
                    case 6:
                        m.historymarkers[i] = 6;
                        break;
                    case "HISTORYMARKER_DELETED":
                    case 7:
                        m.historymarkers[i] = 7;
                        break;
                    case "HISTORYMARKER_DESCRIPTION":
                    case 8:
                        m.historymarkers[i] = 8;
                        break;
                    case "HISTORYMARKER_ALTNAMES":
                    case 9:
                        m.historymarkers[i] = 9;
                        break;
                    case "HISTORYMARKER_PLAINNAMES":
                    case 10:
                        m.historymarkers[i] = 10;
                        break;
                    case "HISTORYMARKER_LINK":
                    case 11:
                        m.historymarkers[i] = 11;
                        break;
                    case "HISTORYMARKER_BODY":
                    case 12:
                        m.historymarkers[i] = 12;
                        break;
                    case "HISTORYMARKER_OPTIONS":
                    case 13:
                        m.historymarkers[i] = 13;
                        break;
                    case "HISTORYMARKER_AVATAR":
                    case 14:
                        m.historymarkers[i] = 14;
                        break;
                    case "HISTORYMARKER_ATTACHMENT":
                    case 15:
                        m.historymarkers[i] = 15;
                        break;
                    case "HISTORYMARKER_GROUP":
                    case 16:
                        m.historymarkers[i] = 16;
                        break;
                    case "HISTORYMARKER_TYPE":
                    case 17:
                        m.historymarkers[i] = 17;
                        break;
                    }
                }
            }
            if (d.historytimestamp != null) {
                if ($util.Long)
                    (m.historytimestamp = $util.Long.fromValue(d.historytimestamp)).unsigned = true;
                else if (typeof d.historytimestamp === "string")
                    m.historytimestamp = parseInt(d.historytimestamp, 10);
                else if (typeof d.historytimestamp === "number")
                    m.historytimestamp = d.historytimestamp;
                else if (typeof d.historytimestamp === "object")
                    m.historytimestamp = new $util.LongBits(d.historytimestamp.low >>> 0, d.historytimestamp.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a ServerMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ServerMsg
         * @static
         * @param {cat.ServerMsg} m ServerMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServerMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.conversations = [];
                d.labels = [];
                d.annotations = [];
                d.emojis = [];
                d.usernicknames = [];
                d.history = [];
                d.historymarkers = [];
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.externid = "";
                d.raw = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.tsatimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.tsatimestamp = o.longs === String ? "0" : 0;
                d.tsasignature = "";
                d.visibility = o.enums === String ? "VISIBILITY_UNKNOWN" : 0;
                d.avatar = null;
                d.account = null;
                d.conversationcount = 0;
                d.apprefid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.historytimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.historytimestamp = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
                if (o.oneofs)
                    d._name = "name";
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
                if (o.oneofs)
                    d._description = "description";
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.externid != null && m.hasOwnProperty("externid")) {
                d.externid = m.externid;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = o.enums === String ? $root.cat.ServerState[m.state] === undefined ? m.state : $root.cat.ServerState[m.state] : m.state;
                if (o.oneofs)
                    d._state = "state";
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.ServerType[m.type] === undefined ? m.type : $root.cat.ServerType[m.type] : m.type;
                if (o.oneofs)
                    d._type = "type";
            }
            if (m.avatarexternid != null && m.hasOwnProperty("avatarexternid")) {
                d.avatarexternid = m.avatarexternid;
                if (o.oneofs)
                    d._avatarexternid = "avatarexternid";
            }
            if (m.raw != null && m.hasOwnProperty("raw")) {
                d.raw = $root.cat.RawMsg.toObject(m.raw, o);
            }
            if (m.tsatimestamp != null && m.hasOwnProperty("tsatimestamp")) {
                if (typeof m.tsatimestamp === "number")
                    d.tsatimestamp = o.longs === String ? String(m.tsatimestamp) : m.tsatimestamp;
                else
                    d.tsatimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.tsatimestamp) : o.longs === Number ? new $util.LongBits(m.tsatimestamp.low >>> 0, m.tsatimestamp.high >>> 0).toNumber(true) : m.tsatimestamp;
            }
            if (m.tsasignature != null && m.hasOwnProperty("tsasignature")) {
                d.tsasignature = m.tsasignature;
            }
            if (m.visibility != null && m.hasOwnProperty("visibility")) {
                d.visibility = o.enums === String ? $root.cat.VisibilityType[m.visibility] === undefined ? m.visibility : $root.cat.VisibilityType[m.visibility] : m.visibility;
            }
            if (m.avatar != null && m.hasOwnProperty("avatar")) {
                d.avatar = $root.cat.AttachmentMsg.toObject(m.avatar, o);
            }
            if (m.conversations && m.conversations.length) {
                d.conversations = [];
                for (var j = 0; j < m.conversations.length; ++j) {
                    d.conversations[j] = $root.cat.ConversationMsg.toObject(m.conversations[j], o);
                }
            }
            if (m.labels && m.labels.length) {
                d.labels = [];
                for (var j = 0; j < m.labels.length; ++j) {
                    d.labels[j] = $root.cat.LabelMsg.toObject(m.labels[j], o);
                }
            }
            if (m.annotations && m.annotations.length) {
                d.annotations = [];
                for (var j = 0; j < m.annotations.length; ++j) {
                    d.annotations[j] = $root.cat.AnnotationMsg.toObject(m.annotations[j], o);
                }
            }
            if (m.emojis && m.emojis.length) {
                d.emojis = [];
                for (var j = 0; j < m.emojis.length; ++j) {
                    d.emojis[j] = $root.cat.AttachmentMsg.toObject(m.emojis[j], o);
                }
            }
            if (m.account != null && m.hasOwnProperty("account")) {
                d.account = $root.cat.AccountMsg.toObject(m.account, o);
            }
            if (m.usernicknames && m.usernicknames.length) {
                d.usernicknames = [];
                for (var j = 0; j < m.usernicknames.length; ++j) {
                    d.usernicknames[j] = m.usernicknames[j];
                }
            }
            if (m.conversationcount != null && m.hasOwnProperty("conversationcount")) {
                d.conversationcount = m.conversationcount;
            }
            if (m.apprefid != null && m.hasOwnProperty("apprefid")) {
                d.apprefid = m.apprefid;
            }
            if (m.history && m.history.length) {
                d.history = [];
                for (var j = 0; j < m.history.length; ++j) {
                    d.history[j] = $root.cat.ServerMsg.toObject(m.history[j], o);
                }
            }
            if (m.historymarkers && m.historymarkers.length) {
                d.historymarkers = [];
                for (var j = 0; j < m.historymarkers.length; ++j) {
                    d.historymarkers[j] = o.enums === String ? $root.cat.HistoryMarker[m.historymarkers[j]] === undefined ? m.historymarkers[j] : $root.cat.HistoryMarker[m.historymarkers[j]] : m.historymarkers[j];
                }
            }
            if (m.historytimestamp != null && m.hasOwnProperty("historytimestamp")) {
                if (typeof m.historytimestamp === "number")
                    d.historytimestamp = o.longs === String ? String(m.historytimestamp) : m.historytimestamp;
                else
                    d.historytimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.historytimestamp) : o.longs === Number ? new $util.LongBits(m.historytimestamp.low >>> 0, m.historytimestamp.high >>> 0).toNumber(true) : m.historytimestamp;
            }
            return d;
        };

        /**
         * Converts this ServerMsg to JSON.
         * @function toJSON
         * @memberof cat.ServerMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServerMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ServerMsg
         * @function getTypeUrl
         * @memberof cat.ServerMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ServerMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ServerMsg";
        };

        return ServerMsg;
    })();

    cat.ConversationMsg = (function() {

        /**
         * Properties of a ConversationMsg.
         * @memberof cat
         * @interface IConversationMsg
         * @property {string|null} [id] ConversationMsg id
         * @property {string|null} [name] ConversationMsg name
         * @property {string|null} [description] ConversationMsg description
         * @property {boolean|null} [ispublic] ConversationMsg ispublic
         * @property {cat.ConversationType|null} [type] ConversationMsg type
         * @property {cat.ConversationState|null} [state] ConversationMsg state
         * @property {number|null} [created] ConversationMsg created
         * @property {number|null} [lastmodified] ConversationMsg lastmodified
         * @property {string|null} [externid] ConversationMsg externid
         * @property {Array.<string>|null} [altnames] ConversationMsg altnames
         * @property {Array.<string>|null} [plainnames] ConversationMsg plainnames
         * @property {string|null} [link] ConversationMsg link
         * @property {string|null} [avatarexternid] ConversationMsg avatarexternid
         * @property {cat.IRawMsg|null} [raw] ConversationMsg raw
         * @property {number|null} [unreadcount] ConversationMsg unreadcount
         * @property {string|null} [lastmessageid] ConversationMsg lastmessageid
         * @property {string|null} [firstmessageid] ConversationMsg firstmessageid
         * @property {number|null} [tsatimestamp] ConversationMsg tsatimestamp
         * @property {string|null} [tsasignature] ConversationMsg tsasignature
         * @property {cat.VisibilityType|null} [visibility] ConversationMsg visibility
         * @property {cat.IAttachmentMsg|null} [avatar] ConversationMsg avatar
         * @property {Array.<cat.ITopicMsg>|null} [topics] ConversationMsg topics
         * @property {Array.<cat.IMessageMsg>|null} [messages] ConversationMsg messages
         * @property {Array.<cat.IAccountMsg>|null} [members] ConversationMsg members
         * @property {Array.<cat.ILabelMsg>|null} [labels] ConversationMsg labels
         * @property {Array.<cat.IAnnotationMsg>|null} [annotations] ConversationMsg annotations
         * @property {number|null} [lastmessagereceived] ConversationMsg lastmessagereceived
         * @property {cat.IAccountMsg|null} [account] ConversationMsg account
         * @property {cat.IAppTypeMsg|null} [apptype] ConversationMsg apptype
         * @property {cat.IServerMsg|null} [server] ConversationMsg server
         * @property {cat.IMemberCountMsg|null} [membercount] ConversationMsg membercount
         * @property {Array.<cat.IConversationMsg>|null} [history] ConversationMsg history
         * @property {number|null} [topiccount] ConversationMsg topiccount
         * @property {cat.IUserMsg|null} [user] ConversationMsg user
         * @property {Array.<cat.HistoryMarker>|null} [historymarkers] ConversationMsg historymarkers
         * @property {number|null} [historytimestamp] ConversationMsg historytimestamp
         * @property {number|null} [start] ConversationMsg start
         * @property {number|null} [end] ConversationMsg end
         * @property {number|null} [knownmembercount] ConversationMsg knownmembercount
         * @property {boolean|null} [allmembersselected] ConversationMsg allmembersselected
         * @property {Array.<string>|null} [excludedmessages] ConversationMsg excludedmessages
         * @property {number|null} [totalmessagescount] ConversationMsg totalmessagescount
         * @property {boolean|null} [active] ConversationMsg active
         * @property {string|null} [apprefid] ConversationMsg apprefid
         * @property {number|null} [offset] ConversationMsg offset
         * @property {number|null} [count] ConversationMsg count
         */

        /**
         * Constructs a new ConversationMsg.
         * @memberof cat
         * @classdesc Represents a ConversationMsg.
         * @implements IConversationMsg
         * @constructor
         * @param {cat.IConversationMsg=} [p] Properties to set
         */
        function ConversationMsg(p) {
            this.altnames = [];
            this.plainnames = [];
            this.topics = [];
            this.messages = [];
            this.members = [];
            this.labels = [];
            this.annotations = [];
            this.history = [];
            this.historymarkers = [];
            this.excludedmessages = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ConversationMsg id.
         * @member {string} id
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.id = "";

        /**
         * ConversationMsg name.
         * @member {string|null|undefined} name
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.name = null;

        /**
         * ConversationMsg description.
         * @member {string|null|undefined} description
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.description = null;

        /**
         * ConversationMsg ispublic.
         * @member {boolean|null|undefined} ispublic
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.ispublic = null;

        /**
         * ConversationMsg type.
         * @member {cat.ConversationType|null|undefined} type
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.type = null;

        /**
         * ConversationMsg state.
         * @member {cat.ConversationState|null|undefined} state
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.state = null;

        /**
         * ConversationMsg created.
         * @member {number} created
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg externid.
         * @member {string} externid
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.externid = "";

        /**
         * ConversationMsg altnames.
         * @member {Array.<string>} altnames
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.altnames = $util.emptyArray;

        /**
         * ConversationMsg plainnames.
         * @member {Array.<string>} plainnames
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.plainnames = $util.emptyArray;

        /**
         * ConversationMsg link.
         * @member {string|null|undefined} link
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.link = null;

        /**
         * ConversationMsg avatarexternid.
         * @member {string|null|undefined} avatarexternid
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.avatarexternid = null;

        /**
         * ConversationMsg raw.
         * @member {cat.IRawMsg|null|undefined} raw
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.raw = null;

        /**
         * ConversationMsg unreadcount.
         * @member {number} unreadcount
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.unreadcount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg lastmessageid.
         * @member {string} lastmessageid
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.lastmessageid = "";

        /**
         * ConversationMsg firstmessageid.
         * @member {string} firstmessageid
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.firstmessageid = "";

        /**
         * ConversationMsg tsatimestamp.
         * @member {number} tsatimestamp
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.tsatimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg tsasignature.
         * @member {string} tsasignature
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.tsasignature = "";

        /**
         * ConversationMsg visibility.
         * @member {cat.VisibilityType} visibility
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.visibility = 0;

        /**
         * ConversationMsg avatar.
         * @member {cat.IAttachmentMsg|null|undefined} avatar
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.avatar = null;

        /**
         * ConversationMsg topics.
         * @member {Array.<cat.ITopicMsg>} topics
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.topics = $util.emptyArray;

        /**
         * ConversationMsg messages.
         * @member {Array.<cat.IMessageMsg>} messages
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.messages = $util.emptyArray;

        /**
         * ConversationMsg members.
         * @member {Array.<cat.IAccountMsg>} members
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.members = $util.emptyArray;

        /**
         * ConversationMsg labels.
         * @member {Array.<cat.ILabelMsg>} labels
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.labels = $util.emptyArray;

        /**
         * ConversationMsg annotations.
         * @member {Array.<cat.IAnnotationMsg>} annotations
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.annotations = $util.emptyArray;

        /**
         * ConversationMsg lastmessagereceived.
         * @member {number} lastmessagereceived
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.lastmessagereceived = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg account.
         * @member {cat.IAccountMsg|null|undefined} account
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.account = null;

        /**
         * ConversationMsg apptype.
         * @member {cat.IAppTypeMsg|null|undefined} apptype
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.apptype = null;

        /**
         * ConversationMsg server.
         * @member {cat.IServerMsg|null|undefined} server
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.server = null;

        /**
         * ConversationMsg membercount.
         * @member {cat.IMemberCountMsg|null|undefined} membercount
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.membercount = null;

        /**
         * ConversationMsg history.
         * @member {Array.<cat.IConversationMsg>} history
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.history = $util.emptyArray;

        /**
         * ConversationMsg topiccount.
         * @member {number} topiccount
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.topiccount = 0;

        /**
         * ConversationMsg user.
         * @member {cat.IUserMsg|null|undefined} user
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.user = null;

        /**
         * ConversationMsg historymarkers.
         * @member {Array.<cat.HistoryMarker>} historymarkers
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.historymarkers = $util.emptyArray;

        /**
         * ConversationMsg historytimestamp.
         * @member {number} historytimestamp
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.historytimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg start.
         * @member {number} start
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.start = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg end.
         * @member {number} end
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.end = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg knownmembercount.
         * @member {number} knownmembercount
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.knownmembercount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg allmembersselected.
         * @member {boolean|null|undefined} allmembersselected
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.allmembersselected = null;

        /**
         * ConversationMsg excludedmessages.
         * @member {Array.<string>} excludedmessages
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.excludedmessages = $util.emptyArray;

        /**
         * ConversationMsg totalmessagescount.
         * @member {number} totalmessagescount
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.totalmessagescount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg active.
         * @member {boolean} active
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.active = false;

        /**
         * ConversationMsg apprefid.
         * @member {string} apprefid
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.apprefid = "";

        /**
         * ConversationMsg offset.
         * @member {number} offset
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ConversationMsg count.
         * @member {number} count
         * @memberof cat.ConversationMsg
         * @instance
         */
        ConversationMsg.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConversationMsg.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConversationMsg.prototype, "_description", {
            get: $util.oneOfGetter($oneOfFields = ["description"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConversationMsg.prototype, "_ispublic", {
            get: $util.oneOfGetter($oneOfFields = ["ispublic"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConversationMsg.prototype, "_type", {
            get: $util.oneOfGetter($oneOfFields = ["type"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConversationMsg.prototype, "_state", {
            get: $util.oneOfGetter($oneOfFields = ["state"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConversationMsg.prototype, "_link", {
            get: $util.oneOfGetter($oneOfFields = ["link"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConversationMsg.prototype, "_avatarexternid", {
            get: $util.oneOfGetter($oneOfFields = ["avatarexternid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ConversationMsg.prototype, "_allmembersselected", {
            get: $util.oneOfGetter($oneOfFields = ["allmembersselected"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ConversationMsg instance using the specified properties.
         * @function create
         * @memberof cat.ConversationMsg
         * @static
         * @param {cat.IConversationMsg=} [properties] Properties to set
         * @returns {cat.ConversationMsg} ConversationMsg instance
         */
        ConversationMsg.create = function create(properties) {
            return new ConversationMsg(properties);
        };

        /**
         * Encodes the specified ConversationMsg message. Does not implicitly {@link cat.ConversationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ConversationMsg
         * @static
         * @param {cat.IConversationMsg} m ConversationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConversationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(26).string(m.description);
            if (m.ispublic != null && Object.hasOwnProperty.call(m, "ispublic"))
                w.uint32(32).bool(m.ispublic);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(48).int32(m.type);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(56).int32(m.state);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(64).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(72).uint64(m.lastmodified);
            if (m.externid != null && Object.hasOwnProperty.call(m, "externid"))
                w.uint32(82).string(m.externid);
            if (m.altnames != null && m.altnames.length) {
                for (var i = 0; i < m.altnames.length; ++i)
                    w.uint32(98).string(m.altnames[i]);
            }
            if (m.plainnames != null && m.plainnames.length) {
                for (var i = 0; i < m.plainnames.length; ++i)
                    w.uint32(106).string(m.plainnames[i]);
            }
            if (m.link != null && Object.hasOwnProperty.call(m, "link"))
                w.uint32(114).string(m.link);
            if (m.avatarexternid != null && Object.hasOwnProperty.call(m, "avatarexternid"))
                w.uint32(122).string(m.avatarexternid);
            if (m.raw != null && Object.hasOwnProperty.call(m, "raw"))
                $root.cat.RawMsg.encode(m.raw, w.uint32(130).fork()).ldelim();
            if (m.unreadcount != null && Object.hasOwnProperty.call(m, "unreadcount"))
                w.uint32(136).uint64(m.unreadcount);
            if (m.lastmessageid != null && Object.hasOwnProperty.call(m, "lastmessageid"))
                w.uint32(146).string(m.lastmessageid);
            if (m.firstmessageid != null && Object.hasOwnProperty.call(m, "firstmessageid"))
                w.uint32(154).string(m.firstmessageid);
            if (m.tsatimestamp != null && Object.hasOwnProperty.call(m, "tsatimestamp"))
                w.uint32(160).uint64(m.tsatimestamp);
            if (m.tsasignature != null && Object.hasOwnProperty.call(m, "tsasignature"))
                w.uint32(170).string(m.tsasignature);
            if (m.visibility != null && Object.hasOwnProperty.call(m, "visibility"))
                w.uint32(184).int32(m.visibility);
            if (m.avatar != null && Object.hasOwnProperty.call(m, "avatar"))
                $root.cat.AttachmentMsg.encode(m.avatar, w.uint32(810).fork()).ldelim();
            if (m.topics != null && m.topics.length) {
                for (var i = 0; i < m.topics.length; ++i)
                    $root.cat.TopicMsg.encode(m.topics[i], w.uint32(818).fork()).ldelim();
            }
            if (m.messages != null && m.messages.length) {
                for (var i = 0; i < m.messages.length; ++i)
                    $root.cat.MessageMsg.encode(m.messages[i], w.uint32(826).fork()).ldelim();
            }
            if (m.members != null && m.members.length) {
                for (var i = 0; i < m.members.length; ++i)
                    $root.cat.AccountMsg.encode(m.members[i], w.uint32(834).fork()).ldelim();
            }
            if (m.labels != null && m.labels.length) {
                for (var i = 0; i < m.labels.length; ++i)
                    $root.cat.LabelMsg.encode(m.labels[i], w.uint32(842).fork()).ldelim();
            }
            if (m.annotations != null && m.annotations.length) {
                for (var i = 0; i < m.annotations.length; ++i)
                    $root.cat.AnnotationMsg.encode(m.annotations[i], w.uint32(850).fork()).ldelim();
            }
            if (m.lastmessagereceived != null && Object.hasOwnProperty.call(m, "lastmessagereceived"))
                w.uint32(856).uint64(m.lastmessagereceived);
            if (m.account != null && Object.hasOwnProperty.call(m, "account"))
                $root.cat.AccountMsg.encode(m.account, w.uint32(866).fork()).ldelim();
            if (m.apptype != null && Object.hasOwnProperty.call(m, "apptype"))
                $root.cat.AppTypeMsg.encode(m.apptype, w.uint32(874).fork()).ldelim();
            if (m.server != null && Object.hasOwnProperty.call(m, "server"))
                $root.cat.ServerMsg.encode(m.server, w.uint32(882).fork()).ldelim();
            if (m.membercount != null && Object.hasOwnProperty.call(m, "membercount"))
                $root.cat.MemberCountMsg.encode(m.membercount, w.uint32(890).fork()).ldelim();
            if (m.history != null && m.history.length) {
                for (var i = 0; i < m.history.length; ++i)
                    $root.cat.ConversationMsg.encode(m.history[i], w.uint32(898).fork()).ldelim();
            }
            if (m.topiccount != null && Object.hasOwnProperty.call(m, "topiccount"))
                w.uint32(904).uint32(m.topiccount);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.cat.UserMsg.encode(m.user, w.uint32(914).fork()).ldelim();
            if (m.historymarkers != null && m.historymarkers.length) {
                w.uint32(922).fork();
                for (var i = 0; i < m.historymarkers.length; ++i)
                    w.int32(m.historymarkers[i]);
                w.ldelim();
            }
            if (m.historytimestamp != null && Object.hasOwnProperty.call(m, "historytimestamp"))
                w.uint32(928).uint64(m.historytimestamp);
            if (m.start != null && Object.hasOwnProperty.call(m, "start"))
                w.uint32(968).uint64(m.start);
            if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                w.uint32(976).uint64(m.end);
            if (m.knownmembercount != null && Object.hasOwnProperty.call(m, "knownmembercount"))
                w.uint32(984).uint64(m.knownmembercount);
            if (m.allmembersselected != null && Object.hasOwnProperty.call(m, "allmembersselected"))
                w.uint32(992).bool(m.allmembersselected);
            if (m.excludedmessages != null && m.excludedmessages.length) {
                for (var i = 0; i < m.excludedmessages.length; ++i)
                    w.uint32(1002).string(m.excludedmessages[i]);
            }
            if (m.totalmessagescount != null && Object.hasOwnProperty.call(m, "totalmessagescount"))
                w.uint32(1040).uint64(m.totalmessagescount);
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(1048).bool(m.active);
            if (m.apprefid != null && Object.hasOwnProperty.call(m, "apprefid"))
                w.uint32(1058).string(m.apprefid);
            if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
                w.uint32(8008).uint64(m.offset);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(8016).uint64(m.count);
            return w;
        };

        /**
         * Decodes a ConversationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ConversationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ConversationMsg} ConversationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConversationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ConversationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.description = r.string();
                        break;
                    }
                case 4: {
                        m.ispublic = r.bool();
                        break;
                    }
                case 6: {
                        m.type = r.int32();
                        break;
                    }
                case 7: {
                        m.state = r.int32();
                        break;
                    }
                case 8: {
                        m.created = r.uint64();
                        break;
                    }
                case 9: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 10: {
                        m.externid = r.string();
                        break;
                    }
                case 12: {
                        if (!(m.altnames && m.altnames.length))
                            m.altnames = [];
                        m.altnames.push(r.string());
                        break;
                    }
                case 13: {
                        if (!(m.plainnames && m.plainnames.length))
                            m.plainnames = [];
                        m.plainnames.push(r.string());
                        break;
                    }
                case 14: {
                        m.link = r.string();
                        break;
                    }
                case 15: {
                        m.avatarexternid = r.string();
                        break;
                    }
                case 16: {
                        m.raw = $root.cat.RawMsg.decode(r, r.uint32());
                        break;
                    }
                case 17: {
                        m.unreadcount = r.uint64();
                        break;
                    }
                case 18: {
                        m.lastmessageid = r.string();
                        break;
                    }
                case 19: {
                        m.firstmessageid = r.string();
                        break;
                    }
                case 20: {
                        m.tsatimestamp = r.uint64();
                        break;
                    }
                case 21: {
                        m.tsasignature = r.string();
                        break;
                    }
                case 23: {
                        m.visibility = r.int32();
                        break;
                    }
                case 101: {
                        m.avatar = $root.cat.AttachmentMsg.decode(r, r.uint32());
                        break;
                    }
                case 102: {
                        if (!(m.topics && m.topics.length))
                            m.topics = [];
                        m.topics.push($root.cat.TopicMsg.decode(r, r.uint32()));
                        break;
                    }
                case 103: {
                        if (!(m.messages && m.messages.length))
                            m.messages = [];
                        m.messages.push($root.cat.MessageMsg.decode(r, r.uint32()));
                        break;
                    }
                case 104: {
                        if (!(m.members && m.members.length))
                            m.members = [];
                        m.members.push($root.cat.AccountMsg.decode(r, r.uint32()));
                        break;
                    }
                case 105: {
                        if (!(m.labels && m.labels.length))
                            m.labels = [];
                        m.labels.push($root.cat.LabelMsg.decode(r, r.uint32()));
                        break;
                    }
                case 106: {
                        if (!(m.annotations && m.annotations.length))
                            m.annotations = [];
                        m.annotations.push($root.cat.AnnotationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 107: {
                        m.lastmessagereceived = r.uint64();
                        break;
                    }
                case 108: {
                        m.account = $root.cat.AccountMsg.decode(r, r.uint32());
                        break;
                    }
                case 109: {
                        m.apptype = $root.cat.AppTypeMsg.decode(r, r.uint32());
                        break;
                    }
                case 110: {
                        m.server = $root.cat.ServerMsg.decode(r, r.uint32());
                        break;
                    }
                case 111: {
                        m.membercount = $root.cat.MemberCountMsg.decode(r, r.uint32());
                        break;
                    }
                case 112: {
                        if (!(m.history && m.history.length))
                            m.history = [];
                        m.history.push($root.cat.ConversationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 113: {
                        m.topiccount = r.uint32();
                        break;
                    }
                case 114: {
                        m.user = $root.cat.UserMsg.decode(r, r.uint32());
                        break;
                    }
                case 115: {
                        if (!(m.historymarkers && m.historymarkers.length))
                            m.historymarkers = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.historymarkers.push(r.int32());
                        } else
                            m.historymarkers.push(r.int32());
                        break;
                    }
                case 116: {
                        m.historytimestamp = r.uint64();
                        break;
                    }
                case 121: {
                        m.start = r.uint64();
                        break;
                    }
                case 122: {
                        m.end = r.uint64();
                        break;
                    }
                case 123: {
                        m.knownmembercount = r.uint64();
                        break;
                    }
                case 124: {
                        m.allmembersselected = r.bool();
                        break;
                    }
                case 125: {
                        if (!(m.excludedmessages && m.excludedmessages.length))
                            m.excludedmessages = [];
                        m.excludedmessages.push(r.string());
                        break;
                    }
                case 130: {
                        m.totalmessagescount = r.uint64();
                        break;
                    }
                case 131: {
                        m.active = r.bool();
                        break;
                    }
                case 132: {
                        m.apprefid = r.string();
                        break;
                    }
                case 1001: {
                        m.offset = r.uint64();
                        break;
                    }
                case 1002: {
                        m.count = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ConversationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ConversationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ConversationMsg} ConversationMsg
         */
        ConversationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ConversationMsg)
                return d;
            var m = new $root.cat.ConversationMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.ispublic != null) {
                m.ispublic = Boolean(d.ispublic);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "CONVERSATION_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "CONVERSATION_PRIVATE":
            case 1:
                m.type = 1;
                break;
            case "CONVERSATION_SECRET":
            case 2:
                m.type = 2;
                break;
            case "CONVERSATION_GROUP":
            case 3:
                m.type = 3;
                break;
            case "CONVERSATION_PUBLIC":
            case 4:
                m.type = 4;
                break;
            case "CONVERSATION_FEED":
            case 6:
                m.type = 6;
                break;
            case "CONVERSATION_CHANNEL":
            case 10:
                m.type = 10;
                break;
            case "CONVERSATION_SUPERGROUP":
            case 11:
                m.type = 11;
                break;
            case "CONVERSATION_STORY":
            case 23:
                m.type = 23;
                break;
            case "CONVERSATION_SELF":
            case 30:
                m.type = 30;
                break;
            case "CONVERSATION_VOICE":
            case 40:
                m.type = 40;
                break;
            case "CONVERSATION_THREAD":
            case 41:
                m.type = 41;
                break;
            }
            switch (d.state) {
            default:
                if (typeof d.state === "number") {
                    m.state = d.state;
                    break;
                }
                break;
            case "CONVERSATIONSTATE_UNKNOWN":
            case 0:
                m.state = 0;
                break;
            case "CONVERSATIONSTATE_PENDING":
            case 1:
                m.state = 1;
                break;
            case "CONVERSATIONSTATE_READY":
            case 2:
                m.state = 2;
                break;
            case "CONVERSATIONSTATE_CLOSED":
            case 4:
                m.state = 4;
                break;
            case "CONVERSATIONSTATE_LEFT":
            case 7:
                m.state = 7;
                break;
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.externid != null) {
                m.externid = String(d.externid);
            }
            if (d.altnames) {
                if (!Array.isArray(d.altnames))
                    throw TypeError(".cat.ConversationMsg.altnames: array expected");
                m.altnames = [];
                for (var i = 0; i < d.altnames.length; ++i) {
                    m.altnames[i] = String(d.altnames[i]);
                }
            }
            if (d.plainnames) {
                if (!Array.isArray(d.plainnames))
                    throw TypeError(".cat.ConversationMsg.plainnames: array expected");
                m.plainnames = [];
                for (var i = 0; i < d.plainnames.length; ++i) {
                    m.plainnames[i] = String(d.plainnames[i]);
                }
            }
            if (d.link != null) {
                m.link = String(d.link);
            }
            if (d.avatarexternid != null) {
                m.avatarexternid = String(d.avatarexternid);
            }
            if (d.raw != null) {
                if (typeof d.raw !== "object")
                    throw TypeError(".cat.ConversationMsg.raw: object expected");
                m.raw = $root.cat.RawMsg.fromObject(d.raw);
            }
            if (d.unreadcount != null) {
                if ($util.Long)
                    (m.unreadcount = $util.Long.fromValue(d.unreadcount)).unsigned = true;
                else if (typeof d.unreadcount === "string")
                    m.unreadcount = parseInt(d.unreadcount, 10);
                else if (typeof d.unreadcount === "number")
                    m.unreadcount = d.unreadcount;
                else if (typeof d.unreadcount === "object")
                    m.unreadcount = new $util.LongBits(d.unreadcount.low >>> 0, d.unreadcount.high >>> 0).toNumber(true);
            }
            if (d.lastmessageid != null) {
                m.lastmessageid = String(d.lastmessageid);
            }
            if (d.firstmessageid != null) {
                m.firstmessageid = String(d.firstmessageid);
            }
            if (d.tsatimestamp != null) {
                if ($util.Long)
                    (m.tsatimestamp = $util.Long.fromValue(d.tsatimestamp)).unsigned = true;
                else if (typeof d.tsatimestamp === "string")
                    m.tsatimestamp = parseInt(d.tsatimestamp, 10);
                else if (typeof d.tsatimestamp === "number")
                    m.tsatimestamp = d.tsatimestamp;
                else if (typeof d.tsatimestamp === "object")
                    m.tsatimestamp = new $util.LongBits(d.tsatimestamp.low >>> 0, d.tsatimestamp.high >>> 0).toNumber(true);
            }
            if (d.tsasignature != null) {
                m.tsasignature = String(d.tsasignature);
            }
            switch (d.visibility) {
            default:
                if (typeof d.visibility === "number") {
                    m.visibility = d.visibility;
                    break;
                }
                break;
            case "VISIBILITY_UNKNOWN":
            case 0:
                m.visibility = 0;
                break;
            case "VISIBILITY_VISIBLE":
            case 1:
                m.visibility = 1;
                break;
            case "VISIBILITY_HIDDEN":
            case 2:
                m.visibility = 2;
                break;
            }
            if (d.avatar != null) {
                if (typeof d.avatar !== "object")
                    throw TypeError(".cat.ConversationMsg.avatar: object expected");
                m.avatar = $root.cat.AttachmentMsg.fromObject(d.avatar);
            }
            if (d.topics) {
                if (!Array.isArray(d.topics))
                    throw TypeError(".cat.ConversationMsg.topics: array expected");
                m.topics = [];
                for (var i = 0; i < d.topics.length; ++i) {
                    if (typeof d.topics[i] !== "object")
                        throw TypeError(".cat.ConversationMsg.topics: object expected");
                    m.topics[i] = $root.cat.TopicMsg.fromObject(d.topics[i]);
                }
            }
            if (d.messages) {
                if (!Array.isArray(d.messages))
                    throw TypeError(".cat.ConversationMsg.messages: array expected");
                m.messages = [];
                for (var i = 0; i < d.messages.length; ++i) {
                    if (typeof d.messages[i] !== "object")
                        throw TypeError(".cat.ConversationMsg.messages: object expected");
                    m.messages[i] = $root.cat.MessageMsg.fromObject(d.messages[i]);
                }
            }
            if (d.members) {
                if (!Array.isArray(d.members))
                    throw TypeError(".cat.ConversationMsg.members: array expected");
                m.members = [];
                for (var i = 0; i < d.members.length; ++i) {
                    if (typeof d.members[i] !== "object")
                        throw TypeError(".cat.ConversationMsg.members: object expected");
                    m.members[i] = $root.cat.AccountMsg.fromObject(d.members[i]);
                }
            }
            if (d.labels) {
                if (!Array.isArray(d.labels))
                    throw TypeError(".cat.ConversationMsg.labels: array expected");
                m.labels = [];
                for (var i = 0; i < d.labels.length; ++i) {
                    if (typeof d.labels[i] !== "object")
                        throw TypeError(".cat.ConversationMsg.labels: object expected");
                    m.labels[i] = $root.cat.LabelMsg.fromObject(d.labels[i]);
                }
            }
            if (d.annotations) {
                if (!Array.isArray(d.annotations))
                    throw TypeError(".cat.ConversationMsg.annotations: array expected");
                m.annotations = [];
                for (var i = 0; i < d.annotations.length; ++i) {
                    if (typeof d.annotations[i] !== "object")
                        throw TypeError(".cat.ConversationMsg.annotations: object expected");
                    m.annotations[i] = $root.cat.AnnotationMsg.fromObject(d.annotations[i]);
                }
            }
            if (d.lastmessagereceived != null) {
                if ($util.Long)
                    (m.lastmessagereceived = $util.Long.fromValue(d.lastmessagereceived)).unsigned = true;
                else if (typeof d.lastmessagereceived === "string")
                    m.lastmessagereceived = parseInt(d.lastmessagereceived, 10);
                else if (typeof d.lastmessagereceived === "number")
                    m.lastmessagereceived = d.lastmessagereceived;
                else if (typeof d.lastmessagereceived === "object")
                    m.lastmessagereceived = new $util.LongBits(d.lastmessagereceived.low >>> 0, d.lastmessagereceived.high >>> 0).toNumber(true);
            }
            if (d.account != null) {
                if (typeof d.account !== "object")
                    throw TypeError(".cat.ConversationMsg.account: object expected");
                m.account = $root.cat.AccountMsg.fromObject(d.account);
            }
            if (d.apptype != null) {
                if (typeof d.apptype !== "object")
                    throw TypeError(".cat.ConversationMsg.apptype: object expected");
                m.apptype = $root.cat.AppTypeMsg.fromObject(d.apptype);
            }
            if (d.server != null) {
                if (typeof d.server !== "object")
                    throw TypeError(".cat.ConversationMsg.server: object expected");
                m.server = $root.cat.ServerMsg.fromObject(d.server);
            }
            if (d.membercount != null) {
                if (typeof d.membercount !== "object")
                    throw TypeError(".cat.ConversationMsg.membercount: object expected");
                m.membercount = $root.cat.MemberCountMsg.fromObject(d.membercount);
            }
            if (d.history) {
                if (!Array.isArray(d.history))
                    throw TypeError(".cat.ConversationMsg.history: array expected");
                m.history = [];
                for (var i = 0; i < d.history.length; ++i) {
                    if (typeof d.history[i] !== "object")
                        throw TypeError(".cat.ConversationMsg.history: object expected");
                    m.history[i] = $root.cat.ConversationMsg.fromObject(d.history[i]);
                }
            }
            if (d.topiccount != null) {
                m.topiccount = d.topiccount >>> 0;
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".cat.ConversationMsg.user: object expected");
                m.user = $root.cat.UserMsg.fromObject(d.user);
            }
            if (d.historymarkers) {
                if (!Array.isArray(d.historymarkers))
                    throw TypeError(".cat.ConversationMsg.historymarkers: array expected");
                m.historymarkers = [];
                for (var i = 0; i < d.historymarkers.length; ++i) {
                    switch (d.historymarkers[i]) {
                    default:
                        if (typeof d.historymarkers[i] === "number") {
                            m.historymarkers[i] = d.historymarkers[i];
                            break;
                        }
                    case "HISTORYMARKER_UNKNOWN":
                    case 0:
                        m.historymarkers[i] = 0;
                        break;
                    case "HISTORYMARKER_NAME":
                    case 1:
                        m.historymarkers[i] = 1;
                        break;
                    case "HISTORYMARKER_FIRSTNAME":
                    case 2:
                        m.historymarkers[i] = 2;
                        break;
                    case "HISTORYMARKER_LASTNAME":
                    case 3:
                        m.historymarkers[i] = 3;
                        break;
                    case "HISTORYMARKER_FULLNAME":
                    case 4:
                        m.historymarkers[i] = 4;
                        break;
                    case "HISTORYMARKER_BIO":
                    case 5:
                        m.historymarkers[i] = 5;
                        break;
                    case "HISTORYMARKER_EDITED":
                    case 6:
                        m.historymarkers[i] = 6;
                        break;
                    case "HISTORYMARKER_DELETED":
                    case 7:
                        m.historymarkers[i] = 7;
                        break;
                    case "HISTORYMARKER_DESCRIPTION":
                    case 8:
                        m.historymarkers[i] = 8;
                        break;
                    case "HISTORYMARKER_ALTNAMES":
                    case 9:
                        m.historymarkers[i] = 9;
                        break;
                    case "HISTORYMARKER_PLAINNAMES":
                    case 10:
                        m.historymarkers[i] = 10;
                        break;
                    case "HISTORYMARKER_LINK":
                    case 11:
                        m.historymarkers[i] = 11;
                        break;
                    case "HISTORYMARKER_BODY":
                    case 12:
                        m.historymarkers[i] = 12;
                        break;
                    case "HISTORYMARKER_OPTIONS":
                    case 13:
                        m.historymarkers[i] = 13;
                        break;
                    case "HISTORYMARKER_AVATAR":
                    case 14:
                        m.historymarkers[i] = 14;
                        break;
                    case "HISTORYMARKER_ATTACHMENT":
                    case 15:
                        m.historymarkers[i] = 15;
                        break;
                    case "HISTORYMARKER_GROUP":
                    case 16:
                        m.historymarkers[i] = 16;
                        break;
                    case "HISTORYMARKER_TYPE":
                    case 17:
                        m.historymarkers[i] = 17;
                        break;
                    }
                }
            }
            if (d.historytimestamp != null) {
                if ($util.Long)
                    (m.historytimestamp = $util.Long.fromValue(d.historytimestamp)).unsigned = true;
                else if (typeof d.historytimestamp === "string")
                    m.historytimestamp = parseInt(d.historytimestamp, 10);
                else if (typeof d.historytimestamp === "number")
                    m.historytimestamp = d.historytimestamp;
                else if (typeof d.historytimestamp === "object")
                    m.historytimestamp = new $util.LongBits(d.historytimestamp.low >>> 0, d.historytimestamp.high >>> 0).toNumber(true);
            }
            if (d.start != null) {
                if ($util.Long)
                    (m.start = $util.Long.fromValue(d.start)).unsigned = true;
                else if (typeof d.start === "string")
                    m.start = parseInt(d.start, 10);
                else if (typeof d.start === "number")
                    m.start = d.start;
                else if (typeof d.start === "object")
                    m.start = new $util.LongBits(d.start.low >>> 0, d.start.high >>> 0).toNumber(true);
            }
            if (d.end != null) {
                if ($util.Long)
                    (m.end = $util.Long.fromValue(d.end)).unsigned = true;
                else if (typeof d.end === "string")
                    m.end = parseInt(d.end, 10);
                else if (typeof d.end === "number")
                    m.end = d.end;
                else if (typeof d.end === "object")
                    m.end = new $util.LongBits(d.end.low >>> 0, d.end.high >>> 0).toNumber(true);
            }
            if (d.knownmembercount != null) {
                if ($util.Long)
                    (m.knownmembercount = $util.Long.fromValue(d.knownmembercount)).unsigned = true;
                else if (typeof d.knownmembercount === "string")
                    m.knownmembercount = parseInt(d.knownmembercount, 10);
                else if (typeof d.knownmembercount === "number")
                    m.knownmembercount = d.knownmembercount;
                else if (typeof d.knownmembercount === "object")
                    m.knownmembercount = new $util.LongBits(d.knownmembercount.low >>> 0, d.knownmembercount.high >>> 0).toNumber(true);
            }
            if (d.allmembersselected != null) {
                m.allmembersselected = Boolean(d.allmembersselected);
            }
            if (d.excludedmessages) {
                if (!Array.isArray(d.excludedmessages))
                    throw TypeError(".cat.ConversationMsg.excludedmessages: array expected");
                m.excludedmessages = [];
                for (var i = 0; i < d.excludedmessages.length; ++i) {
                    m.excludedmessages[i] = String(d.excludedmessages[i]);
                }
            }
            if (d.totalmessagescount != null) {
                if ($util.Long)
                    (m.totalmessagescount = $util.Long.fromValue(d.totalmessagescount)).unsigned = true;
                else if (typeof d.totalmessagescount === "string")
                    m.totalmessagescount = parseInt(d.totalmessagescount, 10);
                else if (typeof d.totalmessagescount === "number")
                    m.totalmessagescount = d.totalmessagescount;
                else if (typeof d.totalmessagescount === "object")
                    m.totalmessagescount = new $util.LongBits(d.totalmessagescount.low >>> 0, d.totalmessagescount.high >>> 0).toNumber(true);
            }
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            if (d.apprefid != null) {
                m.apprefid = String(d.apprefid);
            }
            if (d.offset != null) {
                if ($util.Long)
                    (m.offset = $util.Long.fromValue(d.offset)).unsigned = true;
                else if (typeof d.offset === "string")
                    m.offset = parseInt(d.offset, 10);
                else if (typeof d.offset === "number")
                    m.offset = d.offset;
                else if (typeof d.offset === "object")
                    m.offset = new $util.LongBits(d.offset.low >>> 0, d.offset.high >>> 0).toNumber(true);
            }
            if (d.count != null) {
                if ($util.Long)
                    (m.count = $util.Long.fromValue(d.count)).unsigned = true;
                else if (typeof d.count === "string")
                    m.count = parseInt(d.count, 10);
                else if (typeof d.count === "number")
                    m.count = d.count;
                else if (typeof d.count === "object")
                    m.count = new $util.LongBits(d.count.low >>> 0, d.count.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a ConversationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ConversationMsg
         * @static
         * @param {cat.ConversationMsg} m ConversationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConversationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.altnames = [];
                d.plainnames = [];
                d.topics = [];
                d.messages = [];
                d.members = [];
                d.labels = [];
                d.annotations = [];
                d.history = [];
                d.historymarkers = [];
                d.excludedmessages = [];
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.externid = "";
                d.raw = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.unreadcount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.unreadcount = o.longs === String ? "0" : 0;
                d.lastmessageid = "";
                d.firstmessageid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.tsatimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.tsatimestamp = o.longs === String ? "0" : 0;
                d.tsasignature = "";
                d.visibility = o.enums === String ? "VISIBILITY_UNKNOWN" : 0;
                d.avatar = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmessagereceived = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmessagereceived = o.longs === String ? "0" : 0;
                d.account = null;
                d.apptype = null;
                d.server = null;
                d.membercount = null;
                d.topiccount = 0;
                d.user = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.historytimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.historytimestamp = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.start = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.start = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.end = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.end = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.knownmembercount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.knownmembercount = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.totalmessagescount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.totalmessagescount = o.longs === String ? "0" : 0;
                d.active = false;
                d.apprefid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.offset = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.offset = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.count = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.count = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
                if (o.oneofs)
                    d._name = "name";
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
                if (o.oneofs)
                    d._description = "description";
            }
            if (m.ispublic != null && m.hasOwnProperty("ispublic")) {
                d.ispublic = m.ispublic;
                if (o.oneofs)
                    d._ispublic = "ispublic";
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.ConversationType[m.type] === undefined ? m.type : $root.cat.ConversationType[m.type] : m.type;
                if (o.oneofs)
                    d._type = "type";
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = o.enums === String ? $root.cat.ConversationState[m.state] === undefined ? m.state : $root.cat.ConversationState[m.state] : m.state;
                if (o.oneofs)
                    d._state = "state";
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.externid != null && m.hasOwnProperty("externid")) {
                d.externid = m.externid;
            }
            if (m.altnames && m.altnames.length) {
                d.altnames = [];
                for (var j = 0; j < m.altnames.length; ++j) {
                    d.altnames[j] = m.altnames[j];
                }
            }
            if (m.plainnames && m.plainnames.length) {
                d.plainnames = [];
                for (var j = 0; j < m.plainnames.length; ++j) {
                    d.plainnames[j] = m.plainnames[j];
                }
            }
            if (m.link != null && m.hasOwnProperty("link")) {
                d.link = m.link;
                if (o.oneofs)
                    d._link = "link";
            }
            if (m.avatarexternid != null && m.hasOwnProperty("avatarexternid")) {
                d.avatarexternid = m.avatarexternid;
                if (o.oneofs)
                    d._avatarexternid = "avatarexternid";
            }
            if (m.raw != null && m.hasOwnProperty("raw")) {
                d.raw = $root.cat.RawMsg.toObject(m.raw, o);
            }
            if (m.unreadcount != null && m.hasOwnProperty("unreadcount")) {
                if (typeof m.unreadcount === "number")
                    d.unreadcount = o.longs === String ? String(m.unreadcount) : m.unreadcount;
                else
                    d.unreadcount = o.longs === String ? $util.Long.prototype.toString.call(m.unreadcount) : o.longs === Number ? new $util.LongBits(m.unreadcount.low >>> 0, m.unreadcount.high >>> 0).toNumber(true) : m.unreadcount;
            }
            if (m.lastmessageid != null && m.hasOwnProperty("lastmessageid")) {
                d.lastmessageid = m.lastmessageid;
            }
            if (m.firstmessageid != null && m.hasOwnProperty("firstmessageid")) {
                d.firstmessageid = m.firstmessageid;
            }
            if (m.tsatimestamp != null && m.hasOwnProperty("tsatimestamp")) {
                if (typeof m.tsatimestamp === "number")
                    d.tsatimestamp = o.longs === String ? String(m.tsatimestamp) : m.tsatimestamp;
                else
                    d.tsatimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.tsatimestamp) : o.longs === Number ? new $util.LongBits(m.tsatimestamp.low >>> 0, m.tsatimestamp.high >>> 0).toNumber(true) : m.tsatimestamp;
            }
            if (m.tsasignature != null && m.hasOwnProperty("tsasignature")) {
                d.tsasignature = m.tsasignature;
            }
            if (m.visibility != null && m.hasOwnProperty("visibility")) {
                d.visibility = o.enums === String ? $root.cat.VisibilityType[m.visibility] === undefined ? m.visibility : $root.cat.VisibilityType[m.visibility] : m.visibility;
            }
            if (m.avatar != null && m.hasOwnProperty("avatar")) {
                d.avatar = $root.cat.AttachmentMsg.toObject(m.avatar, o);
            }
            if (m.topics && m.topics.length) {
                d.topics = [];
                for (var j = 0; j < m.topics.length; ++j) {
                    d.topics[j] = $root.cat.TopicMsg.toObject(m.topics[j], o);
                }
            }
            if (m.messages && m.messages.length) {
                d.messages = [];
                for (var j = 0; j < m.messages.length; ++j) {
                    d.messages[j] = $root.cat.MessageMsg.toObject(m.messages[j], o);
                }
            }
            if (m.members && m.members.length) {
                d.members = [];
                for (var j = 0; j < m.members.length; ++j) {
                    d.members[j] = $root.cat.AccountMsg.toObject(m.members[j], o);
                }
            }
            if (m.labels && m.labels.length) {
                d.labels = [];
                for (var j = 0; j < m.labels.length; ++j) {
                    d.labels[j] = $root.cat.LabelMsg.toObject(m.labels[j], o);
                }
            }
            if (m.annotations && m.annotations.length) {
                d.annotations = [];
                for (var j = 0; j < m.annotations.length; ++j) {
                    d.annotations[j] = $root.cat.AnnotationMsg.toObject(m.annotations[j], o);
                }
            }
            if (m.lastmessagereceived != null && m.hasOwnProperty("lastmessagereceived")) {
                if (typeof m.lastmessagereceived === "number")
                    d.lastmessagereceived = o.longs === String ? String(m.lastmessagereceived) : m.lastmessagereceived;
                else
                    d.lastmessagereceived = o.longs === String ? $util.Long.prototype.toString.call(m.lastmessagereceived) : o.longs === Number ? new $util.LongBits(m.lastmessagereceived.low >>> 0, m.lastmessagereceived.high >>> 0).toNumber(true) : m.lastmessagereceived;
            }
            if (m.account != null && m.hasOwnProperty("account")) {
                d.account = $root.cat.AccountMsg.toObject(m.account, o);
            }
            if (m.apptype != null && m.hasOwnProperty("apptype")) {
                d.apptype = $root.cat.AppTypeMsg.toObject(m.apptype, o);
            }
            if (m.server != null && m.hasOwnProperty("server")) {
                d.server = $root.cat.ServerMsg.toObject(m.server, o);
            }
            if (m.membercount != null && m.hasOwnProperty("membercount")) {
                d.membercount = $root.cat.MemberCountMsg.toObject(m.membercount, o);
            }
            if (m.history && m.history.length) {
                d.history = [];
                for (var j = 0; j < m.history.length; ++j) {
                    d.history[j] = $root.cat.ConversationMsg.toObject(m.history[j], o);
                }
            }
            if (m.topiccount != null && m.hasOwnProperty("topiccount")) {
                d.topiccount = m.topiccount;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.cat.UserMsg.toObject(m.user, o);
            }
            if (m.historymarkers && m.historymarkers.length) {
                d.historymarkers = [];
                for (var j = 0; j < m.historymarkers.length; ++j) {
                    d.historymarkers[j] = o.enums === String ? $root.cat.HistoryMarker[m.historymarkers[j]] === undefined ? m.historymarkers[j] : $root.cat.HistoryMarker[m.historymarkers[j]] : m.historymarkers[j];
                }
            }
            if (m.historytimestamp != null && m.hasOwnProperty("historytimestamp")) {
                if (typeof m.historytimestamp === "number")
                    d.historytimestamp = o.longs === String ? String(m.historytimestamp) : m.historytimestamp;
                else
                    d.historytimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.historytimestamp) : o.longs === Number ? new $util.LongBits(m.historytimestamp.low >>> 0, m.historytimestamp.high >>> 0).toNumber(true) : m.historytimestamp;
            }
            if (m.start != null && m.hasOwnProperty("start")) {
                if (typeof m.start === "number")
                    d.start = o.longs === String ? String(m.start) : m.start;
                else
                    d.start = o.longs === String ? $util.Long.prototype.toString.call(m.start) : o.longs === Number ? new $util.LongBits(m.start.low >>> 0, m.start.high >>> 0).toNumber(true) : m.start;
            }
            if (m.end != null && m.hasOwnProperty("end")) {
                if (typeof m.end === "number")
                    d.end = o.longs === String ? String(m.end) : m.end;
                else
                    d.end = o.longs === String ? $util.Long.prototype.toString.call(m.end) : o.longs === Number ? new $util.LongBits(m.end.low >>> 0, m.end.high >>> 0).toNumber(true) : m.end;
            }
            if (m.knownmembercount != null && m.hasOwnProperty("knownmembercount")) {
                if (typeof m.knownmembercount === "number")
                    d.knownmembercount = o.longs === String ? String(m.knownmembercount) : m.knownmembercount;
                else
                    d.knownmembercount = o.longs === String ? $util.Long.prototype.toString.call(m.knownmembercount) : o.longs === Number ? new $util.LongBits(m.knownmembercount.low >>> 0, m.knownmembercount.high >>> 0).toNumber(true) : m.knownmembercount;
            }
            if (m.allmembersselected != null && m.hasOwnProperty("allmembersselected")) {
                d.allmembersselected = m.allmembersselected;
                if (o.oneofs)
                    d._allmembersselected = "allmembersselected";
            }
            if (m.excludedmessages && m.excludedmessages.length) {
                d.excludedmessages = [];
                for (var j = 0; j < m.excludedmessages.length; ++j) {
                    d.excludedmessages[j] = m.excludedmessages[j];
                }
            }
            if (m.totalmessagescount != null && m.hasOwnProperty("totalmessagescount")) {
                if (typeof m.totalmessagescount === "number")
                    d.totalmessagescount = o.longs === String ? String(m.totalmessagescount) : m.totalmessagescount;
                else
                    d.totalmessagescount = o.longs === String ? $util.Long.prototype.toString.call(m.totalmessagescount) : o.longs === Number ? new $util.LongBits(m.totalmessagescount.low >>> 0, m.totalmessagescount.high >>> 0).toNumber(true) : m.totalmessagescount;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            if (m.apprefid != null && m.hasOwnProperty("apprefid")) {
                d.apprefid = m.apprefid;
            }
            if (m.offset != null && m.hasOwnProperty("offset")) {
                if (typeof m.offset === "number")
                    d.offset = o.longs === String ? String(m.offset) : m.offset;
                else
                    d.offset = o.longs === String ? $util.Long.prototype.toString.call(m.offset) : o.longs === Number ? new $util.LongBits(m.offset.low >>> 0, m.offset.high >>> 0).toNumber(true) : m.offset;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                if (typeof m.count === "number")
                    d.count = o.longs === String ? String(m.count) : m.count;
                else
                    d.count = o.longs === String ? $util.Long.prototype.toString.call(m.count) : o.longs === Number ? new $util.LongBits(m.count.low >>> 0, m.count.high >>> 0).toNumber(true) : m.count;
            }
            return d;
        };

        /**
         * Converts this ConversationMsg to JSON.
         * @function toJSON
         * @memberof cat.ConversationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConversationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConversationMsg
         * @function getTypeUrl
         * @memberof cat.ConversationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConversationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ConversationMsg";
        };

        return ConversationMsg;
    })();

    cat.TopicMsg = (function() {

        /**
         * Properties of a TopicMsg.
         * @memberof cat
         * @interface ITopicMsg
         * @property {string|null} [id] TopicMsg id
         * @property {string|null} [name] TopicMsg name
         * @property {string|null} [description] TopicMsg description
         * @property {number|null} [created] TopicMsg created
         * @property {number|null} [lastmodified] TopicMsg lastmodified
         * @property {string|null} [externid] TopicMsg externid
         * @property {string|null} [lastmessageid] TopicMsg lastmessageid
         * @property {string|null} [firstmessageid] TopicMsg firstmessageid
         * @property {cat.TopicState|null} [state] TopicMsg state
         * @property {string|null} [avatarexternid] TopicMsg avatarexternid
         * @property {cat.IRawMsg|null} [raw] TopicMsg raw
         * @property {number|null} [tsatimestamp] TopicMsg tsatimestamp
         * @property {string|null} [tsasignature] TopicMsg tsasignature
         * @property {cat.IAttachmentMsg|null} [avatar] TopicMsg avatar
         * @property {Array.<cat.IMessageMsg>|null} [messages] TopicMsg messages
         * @property {Array.<cat.ILabelMsg>|null} [labels] TopicMsg labels
         * @property {Array.<cat.IAnnotationMsg>|null} [annotations] TopicMsg annotations
         * @property {cat.IConversationMsg|null} [conversation] TopicMsg conversation
         * @property {string|null} [apprefid] TopicMsg apprefid
         * @property {Array.<cat.ITopicMsg>|null} [history] TopicMsg history
         * @property {Array.<cat.HistoryMarker>|null} [historymarkers] TopicMsg historymarkers
         * @property {number|null} [historytimestamp] TopicMsg historytimestamp
         * @property {number|null} [start] TopicMsg start
         * @property {number|null} [end] TopicMsg end
         */

        /**
         * Constructs a new TopicMsg.
         * @memberof cat
         * @classdesc Represents a TopicMsg.
         * @implements ITopicMsg
         * @constructor
         * @param {cat.ITopicMsg=} [p] Properties to set
         */
        function TopicMsg(p) {
            this.messages = [];
            this.labels = [];
            this.annotations = [];
            this.history = [];
            this.historymarkers = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TopicMsg id.
         * @member {string} id
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.id = "";

        /**
         * TopicMsg name.
         * @member {string|null|undefined} name
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.name = null;

        /**
         * TopicMsg description.
         * @member {string|null|undefined} description
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.description = null;

        /**
         * TopicMsg created.
         * @member {number} created
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TopicMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TopicMsg externid.
         * @member {string} externid
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.externid = "";

        /**
         * TopicMsg lastmessageid.
         * @member {string} lastmessageid
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.lastmessageid = "";

        /**
         * TopicMsg firstmessageid.
         * @member {string} firstmessageid
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.firstmessageid = "";

        /**
         * TopicMsg state.
         * @member {cat.TopicState|null|undefined} state
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.state = null;

        /**
         * TopicMsg avatarexternid.
         * @member {string|null|undefined} avatarexternid
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.avatarexternid = null;

        /**
         * TopicMsg raw.
         * @member {cat.IRawMsg|null|undefined} raw
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.raw = null;

        /**
         * TopicMsg tsatimestamp.
         * @member {number} tsatimestamp
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.tsatimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TopicMsg tsasignature.
         * @member {string} tsasignature
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.tsasignature = "";

        /**
         * TopicMsg avatar.
         * @member {cat.IAttachmentMsg|null|undefined} avatar
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.avatar = null;

        /**
         * TopicMsg messages.
         * @member {Array.<cat.IMessageMsg>} messages
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.messages = $util.emptyArray;

        /**
         * TopicMsg labels.
         * @member {Array.<cat.ILabelMsg>} labels
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.labels = $util.emptyArray;

        /**
         * TopicMsg annotations.
         * @member {Array.<cat.IAnnotationMsg>} annotations
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.annotations = $util.emptyArray;

        /**
         * TopicMsg conversation.
         * @member {cat.IConversationMsg|null|undefined} conversation
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.conversation = null;

        /**
         * TopicMsg apprefid.
         * @member {string} apprefid
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.apprefid = "";

        /**
         * TopicMsg history.
         * @member {Array.<cat.ITopicMsg>} history
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.history = $util.emptyArray;

        /**
         * TopicMsg historymarkers.
         * @member {Array.<cat.HistoryMarker>} historymarkers
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.historymarkers = $util.emptyArray;

        /**
         * TopicMsg historytimestamp.
         * @member {number} historytimestamp
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.historytimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TopicMsg start.
         * @member {number} start
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.start = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TopicMsg end.
         * @member {number} end
         * @memberof cat.TopicMsg
         * @instance
         */
        TopicMsg.prototype.end = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(TopicMsg.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(TopicMsg.prototype, "_description", {
            get: $util.oneOfGetter($oneOfFields = ["description"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(TopicMsg.prototype, "_state", {
            get: $util.oneOfGetter($oneOfFields = ["state"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(TopicMsg.prototype, "_avatarexternid", {
            get: $util.oneOfGetter($oneOfFields = ["avatarexternid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new TopicMsg instance using the specified properties.
         * @function create
         * @memberof cat.TopicMsg
         * @static
         * @param {cat.ITopicMsg=} [properties] Properties to set
         * @returns {cat.TopicMsg} TopicMsg instance
         */
        TopicMsg.create = function create(properties) {
            return new TopicMsg(properties);
        };

        /**
         * Encodes the specified TopicMsg message. Does not implicitly {@link cat.TopicMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.TopicMsg
         * @static
         * @param {cat.ITopicMsg} m TopicMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(26).string(m.description);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(32).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(40).uint64(m.lastmodified);
            if (m.externid != null && Object.hasOwnProperty.call(m, "externid"))
                w.uint32(50).string(m.externid);
            if (m.lastmessageid != null && Object.hasOwnProperty.call(m, "lastmessageid"))
                w.uint32(58).string(m.lastmessageid);
            if (m.firstmessageid != null && Object.hasOwnProperty.call(m, "firstmessageid"))
                w.uint32(66).string(m.firstmessageid);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(72).int32(m.state);
            if (m.avatarexternid != null && Object.hasOwnProperty.call(m, "avatarexternid"))
                w.uint32(82).string(m.avatarexternid);
            if (m.raw != null && Object.hasOwnProperty.call(m, "raw"))
                $root.cat.RawMsg.encode(m.raw, w.uint32(90).fork()).ldelim();
            if (m.tsatimestamp != null && Object.hasOwnProperty.call(m, "tsatimestamp"))
                w.uint32(96).uint64(m.tsatimestamp);
            if (m.tsasignature != null && Object.hasOwnProperty.call(m, "tsasignature"))
                w.uint32(106).string(m.tsasignature);
            if (m.avatar != null && Object.hasOwnProperty.call(m, "avatar"))
                $root.cat.AttachmentMsg.encode(m.avatar, w.uint32(810).fork()).ldelim();
            if (m.messages != null && m.messages.length) {
                for (var i = 0; i < m.messages.length; ++i)
                    $root.cat.MessageMsg.encode(m.messages[i], w.uint32(818).fork()).ldelim();
            }
            if (m.labels != null && m.labels.length) {
                for (var i = 0; i < m.labels.length; ++i)
                    $root.cat.LabelMsg.encode(m.labels[i], w.uint32(826).fork()).ldelim();
            }
            if (m.annotations != null && m.annotations.length) {
                for (var i = 0; i < m.annotations.length; ++i)
                    $root.cat.AnnotationMsg.encode(m.annotations[i], w.uint32(834).fork()).ldelim();
            }
            if (m.conversation != null && Object.hasOwnProperty.call(m, "conversation"))
                $root.cat.ConversationMsg.encode(m.conversation, w.uint32(842).fork()).ldelim();
            if (m.apprefid != null && Object.hasOwnProperty.call(m, "apprefid"))
                w.uint32(882).string(m.apprefid);
            if (m.history != null && m.history.length) {
                for (var i = 0; i < m.history.length; ++i)
                    $root.cat.TopicMsg.encode(m.history[i], w.uint32(890).fork()).ldelim();
            }
            if (m.historymarkers != null && m.historymarkers.length) {
                w.uint32(898).fork();
                for (var i = 0; i < m.historymarkers.length; ++i)
                    w.int32(m.historymarkers[i]);
                w.ldelim();
            }
            if (m.historytimestamp != null && Object.hasOwnProperty.call(m, "historytimestamp"))
                w.uint32(904).uint64(m.historytimestamp);
            if (m.start != null && Object.hasOwnProperty.call(m, "start"))
                w.uint32(968).uint64(m.start);
            if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                w.uint32(976).uint64(m.end);
            return w;
        };

        /**
         * Decodes a TopicMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.TopicMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.TopicMsg} TopicMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.TopicMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.description = r.string();
                        break;
                    }
                case 4: {
                        m.created = r.uint64();
                        break;
                    }
                case 5: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 6: {
                        m.externid = r.string();
                        break;
                    }
                case 7: {
                        m.lastmessageid = r.string();
                        break;
                    }
                case 8: {
                        m.firstmessageid = r.string();
                        break;
                    }
                case 9: {
                        m.state = r.int32();
                        break;
                    }
                case 10: {
                        m.avatarexternid = r.string();
                        break;
                    }
                case 11: {
                        m.raw = $root.cat.RawMsg.decode(r, r.uint32());
                        break;
                    }
                case 12: {
                        m.tsatimestamp = r.uint64();
                        break;
                    }
                case 13: {
                        m.tsasignature = r.string();
                        break;
                    }
                case 101: {
                        m.avatar = $root.cat.AttachmentMsg.decode(r, r.uint32());
                        break;
                    }
                case 102: {
                        if (!(m.messages && m.messages.length))
                            m.messages = [];
                        m.messages.push($root.cat.MessageMsg.decode(r, r.uint32()));
                        break;
                    }
                case 103: {
                        if (!(m.labels && m.labels.length))
                            m.labels = [];
                        m.labels.push($root.cat.LabelMsg.decode(r, r.uint32()));
                        break;
                    }
                case 104: {
                        if (!(m.annotations && m.annotations.length))
                            m.annotations = [];
                        m.annotations.push($root.cat.AnnotationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 105: {
                        m.conversation = $root.cat.ConversationMsg.decode(r, r.uint32());
                        break;
                    }
                case 110: {
                        m.apprefid = r.string();
                        break;
                    }
                case 111: {
                        if (!(m.history && m.history.length))
                            m.history = [];
                        m.history.push($root.cat.TopicMsg.decode(r, r.uint32()));
                        break;
                    }
                case 112: {
                        if (!(m.historymarkers && m.historymarkers.length))
                            m.historymarkers = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.historymarkers.push(r.int32());
                        } else
                            m.historymarkers.push(r.int32());
                        break;
                    }
                case 113: {
                        m.historytimestamp = r.uint64();
                        break;
                    }
                case 121: {
                        m.start = r.uint64();
                        break;
                    }
                case 122: {
                        m.end = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TopicMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.TopicMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.TopicMsg} TopicMsg
         */
        TopicMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.TopicMsg)
                return d;
            var m = new $root.cat.TopicMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.externid != null) {
                m.externid = String(d.externid);
            }
            if (d.lastmessageid != null) {
                m.lastmessageid = String(d.lastmessageid);
            }
            if (d.firstmessageid != null) {
                m.firstmessageid = String(d.firstmessageid);
            }
            switch (d.state) {
            default:
                if (typeof d.state === "number") {
                    m.state = d.state;
                    break;
                }
                break;
            case "TOPICSTATE_UNKNOWN":
            case 0:
                m.state = 0;
                break;
            case "TOPICSTATE_READY":
            case 1:
                m.state = 1;
                break;
            case "TOPICSTATE_CLOSED":
            case 2:
                m.state = 2;
                break;
            case "TOPICSTATE_LOCKED":
            case 3:
                m.state = 3;
                break;
            }
            if (d.avatarexternid != null) {
                m.avatarexternid = String(d.avatarexternid);
            }
            if (d.raw != null) {
                if (typeof d.raw !== "object")
                    throw TypeError(".cat.TopicMsg.raw: object expected");
                m.raw = $root.cat.RawMsg.fromObject(d.raw);
            }
            if (d.tsatimestamp != null) {
                if ($util.Long)
                    (m.tsatimestamp = $util.Long.fromValue(d.tsatimestamp)).unsigned = true;
                else if (typeof d.tsatimestamp === "string")
                    m.tsatimestamp = parseInt(d.tsatimestamp, 10);
                else if (typeof d.tsatimestamp === "number")
                    m.tsatimestamp = d.tsatimestamp;
                else if (typeof d.tsatimestamp === "object")
                    m.tsatimestamp = new $util.LongBits(d.tsatimestamp.low >>> 0, d.tsatimestamp.high >>> 0).toNumber(true);
            }
            if (d.tsasignature != null) {
                m.tsasignature = String(d.tsasignature);
            }
            if (d.avatar != null) {
                if (typeof d.avatar !== "object")
                    throw TypeError(".cat.TopicMsg.avatar: object expected");
                m.avatar = $root.cat.AttachmentMsg.fromObject(d.avatar);
            }
            if (d.messages) {
                if (!Array.isArray(d.messages))
                    throw TypeError(".cat.TopicMsg.messages: array expected");
                m.messages = [];
                for (var i = 0; i < d.messages.length; ++i) {
                    if (typeof d.messages[i] !== "object")
                        throw TypeError(".cat.TopicMsg.messages: object expected");
                    m.messages[i] = $root.cat.MessageMsg.fromObject(d.messages[i]);
                }
            }
            if (d.labels) {
                if (!Array.isArray(d.labels))
                    throw TypeError(".cat.TopicMsg.labels: array expected");
                m.labels = [];
                for (var i = 0; i < d.labels.length; ++i) {
                    if (typeof d.labels[i] !== "object")
                        throw TypeError(".cat.TopicMsg.labels: object expected");
                    m.labels[i] = $root.cat.LabelMsg.fromObject(d.labels[i]);
                }
            }
            if (d.annotations) {
                if (!Array.isArray(d.annotations))
                    throw TypeError(".cat.TopicMsg.annotations: array expected");
                m.annotations = [];
                for (var i = 0; i < d.annotations.length; ++i) {
                    if (typeof d.annotations[i] !== "object")
                        throw TypeError(".cat.TopicMsg.annotations: object expected");
                    m.annotations[i] = $root.cat.AnnotationMsg.fromObject(d.annotations[i]);
                }
            }
            if (d.conversation != null) {
                if (typeof d.conversation !== "object")
                    throw TypeError(".cat.TopicMsg.conversation: object expected");
                m.conversation = $root.cat.ConversationMsg.fromObject(d.conversation);
            }
            if (d.apprefid != null) {
                m.apprefid = String(d.apprefid);
            }
            if (d.history) {
                if (!Array.isArray(d.history))
                    throw TypeError(".cat.TopicMsg.history: array expected");
                m.history = [];
                for (var i = 0; i < d.history.length; ++i) {
                    if (typeof d.history[i] !== "object")
                        throw TypeError(".cat.TopicMsg.history: object expected");
                    m.history[i] = $root.cat.TopicMsg.fromObject(d.history[i]);
                }
            }
            if (d.historymarkers) {
                if (!Array.isArray(d.historymarkers))
                    throw TypeError(".cat.TopicMsg.historymarkers: array expected");
                m.historymarkers = [];
                for (var i = 0; i < d.historymarkers.length; ++i) {
                    switch (d.historymarkers[i]) {
                    default:
                        if (typeof d.historymarkers[i] === "number") {
                            m.historymarkers[i] = d.historymarkers[i];
                            break;
                        }
                    case "HISTORYMARKER_UNKNOWN":
                    case 0:
                        m.historymarkers[i] = 0;
                        break;
                    case "HISTORYMARKER_NAME":
                    case 1:
                        m.historymarkers[i] = 1;
                        break;
                    case "HISTORYMARKER_FIRSTNAME":
                    case 2:
                        m.historymarkers[i] = 2;
                        break;
                    case "HISTORYMARKER_LASTNAME":
                    case 3:
                        m.historymarkers[i] = 3;
                        break;
                    case "HISTORYMARKER_FULLNAME":
                    case 4:
                        m.historymarkers[i] = 4;
                        break;
                    case "HISTORYMARKER_BIO":
                    case 5:
                        m.historymarkers[i] = 5;
                        break;
                    case "HISTORYMARKER_EDITED":
                    case 6:
                        m.historymarkers[i] = 6;
                        break;
                    case "HISTORYMARKER_DELETED":
                    case 7:
                        m.historymarkers[i] = 7;
                        break;
                    case "HISTORYMARKER_DESCRIPTION":
                    case 8:
                        m.historymarkers[i] = 8;
                        break;
                    case "HISTORYMARKER_ALTNAMES":
                    case 9:
                        m.historymarkers[i] = 9;
                        break;
                    case "HISTORYMARKER_PLAINNAMES":
                    case 10:
                        m.historymarkers[i] = 10;
                        break;
                    case "HISTORYMARKER_LINK":
                    case 11:
                        m.historymarkers[i] = 11;
                        break;
                    case "HISTORYMARKER_BODY":
                    case 12:
                        m.historymarkers[i] = 12;
                        break;
                    case "HISTORYMARKER_OPTIONS":
                    case 13:
                        m.historymarkers[i] = 13;
                        break;
                    case "HISTORYMARKER_AVATAR":
                    case 14:
                        m.historymarkers[i] = 14;
                        break;
                    case "HISTORYMARKER_ATTACHMENT":
                    case 15:
                        m.historymarkers[i] = 15;
                        break;
                    case "HISTORYMARKER_GROUP":
                    case 16:
                        m.historymarkers[i] = 16;
                        break;
                    case "HISTORYMARKER_TYPE":
                    case 17:
                        m.historymarkers[i] = 17;
                        break;
                    }
                }
            }
            if (d.historytimestamp != null) {
                if ($util.Long)
                    (m.historytimestamp = $util.Long.fromValue(d.historytimestamp)).unsigned = true;
                else if (typeof d.historytimestamp === "string")
                    m.historytimestamp = parseInt(d.historytimestamp, 10);
                else if (typeof d.historytimestamp === "number")
                    m.historytimestamp = d.historytimestamp;
                else if (typeof d.historytimestamp === "object")
                    m.historytimestamp = new $util.LongBits(d.historytimestamp.low >>> 0, d.historytimestamp.high >>> 0).toNumber(true);
            }
            if (d.start != null) {
                if ($util.Long)
                    (m.start = $util.Long.fromValue(d.start)).unsigned = true;
                else if (typeof d.start === "string")
                    m.start = parseInt(d.start, 10);
                else if (typeof d.start === "number")
                    m.start = d.start;
                else if (typeof d.start === "object")
                    m.start = new $util.LongBits(d.start.low >>> 0, d.start.high >>> 0).toNumber(true);
            }
            if (d.end != null) {
                if ($util.Long)
                    (m.end = $util.Long.fromValue(d.end)).unsigned = true;
                else if (typeof d.end === "string")
                    m.end = parseInt(d.end, 10);
                else if (typeof d.end === "number")
                    m.end = d.end;
                else if (typeof d.end === "object")
                    m.end = new $util.LongBits(d.end.low >>> 0, d.end.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a TopicMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.TopicMsg
         * @static
         * @param {cat.TopicMsg} m TopicMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TopicMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.messages = [];
                d.labels = [];
                d.annotations = [];
                d.history = [];
                d.historymarkers = [];
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.externid = "";
                d.lastmessageid = "";
                d.firstmessageid = "";
                d.raw = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.tsatimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.tsatimestamp = o.longs === String ? "0" : 0;
                d.tsasignature = "";
                d.avatar = null;
                d.conversation = null;
                d.apprefid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.historytimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.historytimestamp = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.start = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.start = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.end = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.end = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
                if (o.oneofs)
                    d._name = "name";
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
                if (o.oneofs)
                    d._description = "description";
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.externid != null && m.hasOwnProperty("externid")) {
                d.externid = m.externid;
            }
            if (m.lastmessageid != null && m.hasOwnProperty("lastmessageid")) {
                d.lastmessageid = m.lastmessageid;
            }
            if (m.firstmessageid != null && m.hasOwnProperty("firstmessageid")) {
                d.firstmessageid = m.firstmessageid;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = o.enums === String ? $root.cat.TopicState[m.state] === undefined ? m.state : $root.cat.TopicState[m.state] : m.state;
                if (o.oneofs)
                    d._state = "state";
            }
            if (m.avatarexternid != null && m.hasOwnProperty("avatarexternid")) {
                d.avatarexternid = m.avatarexternid;
                if (o.oneofs)
                    d._avatarexternid = "avatarexternid";
            }
            if (m.raw != null && m.hasOwnProperty("raw")) {
                d.raw = $root.cat.RawMsg.toObject(m.raw, o);
            }
            if (m.tsatimestamp != null && m.hasOwnProperty("tsatimestamp")) {
                if (typeof m.tsatimestamp === "number")
                    d.tsatimestamp = o.longs === String ? String(m.tsatimestamp) : m.tsatimestamp;
                else
                    d.tsatimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.tsatimestamp) : o.longs === Number ? new $util.LongBits(m.tsatimestamp.low >>> 0, m.tsatimestamp.high >>> 0).toNumber(true) : m.tsatimestamp;
            }
            if (m.tsasignature != null && m.hasOwnProperty("tsasignature")) {
                d.tsasignature = m.tsasignature;
            }
            if (m.avatar != null && m.hasOwnProperty("avatar")) {
                d.avatar = $root.cat.AttachmentMsg.toObject(m.avatar, o);
            }
            if (m.messages && m.messages.length) {
                d.messages = [];
                for (var j = 0; j < m.messages.length; ++j) {
                    d.messages[j] = $root.cat.MessageMsg.toObject(m.messages[j], o);
                }
            }
            if (m.labels && m.labels.length) {
                d.labels = [];
                for (var j = 0; j < m.labels.length; ++j) {
                    d.labels[j] = $root.cat.LabelMsg.toObject(m.labels[j], o);
                }
            }
            if (m.annotations && m.annotations.length) {
                d.annotations = [];
                for (var j = 0; j < m.annotations.length; ++j) {
                    d.annotations[j] = $root.cat.AnnotationMsg.toObject(m.annotations[j], o);
                }
            }
            if (m.conversation != null && m.hasOwnProperty("conversation")) {
                d.conversation = $root.cat.ConversationMsg.toObject(m.conversation, o);
            }
            if (m.apprefid != null && m.hasOwnProperty("apprefid")) {
                d.apprefid = m.apprefid;
            }
            if (m.history && m.history.length) {
                d.history = [];
                for (var j = 0; j < m.history.length; ++j) {
                    d.history[j] = $root.cat.TopicMsg.toObject(m.history[j], o);
                }
            }
            if (m.historymarkers && m.historymarkers.length) {
                d.historymarkers = [];
                for (var j = 0; j < m.historymarkers.length; ++j) {
                    d.historymarkers[j] = o.enums === String ? $root.cat.HistoryMarker[m.historymarkers[j]] === undefined ? m.historymarkers[j] : $root.cat.HistoryMarker[m.historymarkers[j]] : m.historymarkers[j];
                }
            }
            if (m.historytimestamp != null && m.hasOwnProperty("historytimestamp")) {
                if (typeof m.historytimestamp === "number")
                    d.historytimestamp = o.longs === String ? String(m.historytimestamp) : m.historytimestamp;
                else
                    d.historytimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.historytimestamp) : o.longs === Number ? new $util.LongBits(m.historytimestamp.low >>> 0, m.historytimestamp.high >>> 0).toNumber(true) : m.historytimestamp;
            }
            if (m.start != null && m.hasOwnProperty("start")) {
                if (typeof m.start === "number")
                    d.start = o.longs === String ? String(m.start) : m.start;
                else
                    d.start = o.longs === String ? $util.Long.prototype.toString.call(m.start) : o.longs === Number ? new $util.LongBits(m.start.low >>> 0, m.start.high >>> 0).toNumber(true) : m.start;
            }
            if (m.end != null && m.hasOwnProperty("end")) {
                if (typeof m.end === "number")
                    d.end = o.longs === String ? String(m.end) : m.end;
                else
                    d.end = o.longs === String ? $util.Long.prototype.toString.call(m.end) : o.longs === Number ? new $util.LongBits(m.end.low >>> 0, m.end.high >>> 0).toNumber(true) : m.end;
            }
            return d;
        };

        /**
         * Converts this TopicMsg to JSON.
         * @function toJSON
         * @memberof cat.TopicMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TopicMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TopicMsg
         * @function getTypeUrl
         * @memberof cat.TopicMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TopicMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.TopicMsg";
        };

        return TopicMsg;
    })();

    cat.MessageMsg = (function() {

        /**
         * Properties of a MessageMsg.
         * @memberof cat
         * @interface IMessageMsg
         * @property {string|null} [id] MessageMsg id
         * @property {cat.MessageType|null} [type] MessageMsg type
         * @property {cat.MessageStatus|null} [status] MessageMsg status
         * @property {cat.DirectionType|null} [direction] MessageMsg direction
         * @property {number|null} [sent] MessageMsg sent
         * @property {number|null} [received] MessageMsg received
         * @property {number|null} [lastedited] MessageMsg lastedited
         * @property {number|null} [deleted] MessageMsg deleted
         * @property {number|null} [forwarded] MessageMsg forwarded
         * @property {number|null} [expires] MessageMsg expires
         * @property {number|null} [opened] MessageMsg opened
         * @property {number|null} [created] MessageMsg created
         * @property {number|null} [lastmodified] MessageMsg lastmodified
         * @property {string|null} [subject] MessageMsg subject
         * @property {string|null} [body] MessageMsg body
         * @property {Array.<string>|null} [attributes] MessageMsg attributes
         * @property {cat.IRawMsg|null} [raw] MessageMsg raw
         * @property {string|null} [externid] MessageMsg externid
         * @property {string|null} [group] MessageMsg group
         * @property {Array.<string>|null} [urls] MessageMsg urls
         * @property {Array.<string>|null} [mentions] MessageMsg mentions
         * @property {Array.<string>|null} [hashtags] MessageMsg hashtags
         * @property {Object.<string,string>|null} [options] MessageMsg options
         * @property {number|null} [tsatimestamp] MessageMsg tsatimestamp
         * @property {string|null} [tsasignature] MessageMsg tsasignature
         * @property {string|null} [tmpid] MessageMsg tmpid
         * @property {string|null} [randomid] MessageMsg randomid
         * @property {Array.<cat.IContentMsg>|null} [content] MessageMsg content
         * @property {Array.<string>|null} [oldexternids] MessageMsg oldexternids
         * @property {Array.<cat.IMessageMsg>|null} [groupmessages] MessageMsg groupmessages
         * @property {cat.IAccountMsg|null} [sender] MessageMsg sender
         * @property {cat.IAccountMsg|null} [forward] MessageMsg forward
         * @property {cat.IAccountMsg|null} [owner] MessageMsg owner
         * @property {Array.<cat.IAccountMsg>|null} [recipients] MessageMsg recipients
         * @property {cat.ITopicMsg|null} [topic] MessageMsg topic
         * @property {Array.<cat.IAttachmentMsg>|null} [attachments] MessageMsg attachments
         * @property {Array.<cat.IMessageMsg>|null} [comments] MessageMsg comments
         * @property {Array.<cat.IReactionMsg>|null} [reactions] MessageMsg reactions
         * @property {cat.IMessageMsg|null} [replyto] MessageMsg replyto
         * @property {Array.<cat.ILabelMsg>|null} [labels] MessageMsg labels
         * @property {Array.<cat.IAnnotationMsg>|null} [annotations] MessageMsg annotations
         * @property {cat.MessageStatus|null} [statusupdate] MessageMsg statusupdate
         * @property {string|null} [apprefid] MessageMsg apprefid
         * @property {string|null} [randomreplyid] MessageMsg randomreplyid
         * @property {cat.IConversationMsg|null} [channel] MessageMsg channel
         * @property {Array.<cat.IMessageMsg>|null} [history] MessageMsg history
         * @property {Array.<cat.IAttachmentMsg>|null} [emojis] MessageMsg emojis
         * @property {Array.<cat.HistoryMarker>|null} [historymarkers] MessageMsg historymarkers
         * @property {number|null} [historytimestamp] MessageMsg historytimestamp
         */

        /**
         * Constructs a new MessageMsg.
         * @memberof cat
         * @classdesc Represents a MessageMsg.
         * @implements IMessageMsg
         * @constructor
         * @param {cat.IMessageMsg=} [p] Properties to set
         */
        function MessageMsg(p) {
            this.attributes = [];
            this.urls = [];
            this.mentions = [];
            this.hashtags = [];
            this.options = {};
            this.content = [];
            this.oldexternids = [];
            this.groupmessages = [];
            this.recipients = [];
            this.attachments = [];
            this.comments = [];
            this.reactions = [];
            this.labels = [];
            this.annotations = [];
            this.history = [];
            this.emojis = [];
            this.historymarkers = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * MessageMsg id.
         * @member {string} id
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.id = "";

        /**
         * MessageMsg type.
         * @member {cat.MessageType|null|undefined} type
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.type = null;

        /**
         * MessageMsg status.
         * @member {cat.MessageStatus|null|undefined} status
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.status = null;

        /**
         * MessageMsg direction.
         * @member {cat.DirectionType|null|undefined} direction
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.direction = null;

        /**
         * MessageMsg sent.
         * @member {number|null|undefined} sent
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.sent = null;

        /**
         * MessageMsg received.
         * @member {number|null|undefined} received
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.received = null;

        /**
         * MessageMsg lastedited.
         * @member {number|null|undefined} lastedited
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.lastedited = null;

        /**
         * MessageMsg deleted.
         * @member {number|null|undefined} deleted
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.deleted = null;

        /**
         * MessageMsg forwarded.
         * @member {number|null|undefined} forwarded
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.forwarded = null;

        /**
         * MessageMsg expires.
         * @member {number|null|undefined} expires
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.expires = null;

        /**
         * MessageMsg opened.
         * @member {number|null|undefined} opened
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.opened = null;

        /**
         * MessageMsg created.
         * @member {number} created
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MessageMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MessageMsg subject.
         * @member {string|null|undefined} subject
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.subject = null;

        /**
         * MessageMsg body.
         * @member {string|null|undefined} body
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.body = null;

        /**
         * MessageMsg attributes.
         * @member {Array.<string>} attributes
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.attributes = $util.emptyArray;

        /**
         * MessageMsg raw.
         * @member {cat.IRawMsg|null|undefined} raw
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.raw = null;

        /**
         * MessageMsg externid.
         * @member {string} externid
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.externid = "";

        /**
         * MessageMsg group.
         * @member {string|null|undefined} group
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.group = null;

        /**
         * MessageMsg urls.
         * @member {Array.<string>} urls
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.urls = $util.emptyArray;

        /**
         * MessageMsg mentions.
         * @member {Array.<string>} mentions
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.mentions = $util.emptyArray;

        /**
         * MessageMsg hashtags.
         * @member {Array.<string>} hashtags
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.hashtags = $util.emptyArray;

        /**
         * MessageMsg options.
         * @member {Object.<string,string>} options
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.options = $util.emptyObject;

        /**
         * MessageMsg tsatimestamp.
         * @member {number} tsatimestamp
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.tsatimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MessageMsg tsasignature.
         * @member {string} tsasignature
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.tsasignature = "";

        /**
         * MessageMsg tmpid.
         * @member {string|null|undefined} tmpid
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.tmpid = null;

        /**
         * MessageMsg randomid.
         * @member {string|null|undefined} randomid
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.randomid = null;

        /**
         * MessageMsg content.
         * @member {Array.<cat.IContentMsg>} content
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.content = $util.emptyArray;

        /**
         * MessageMsg oldexternids.
         * @member {Array.<string>} oldexternids
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.oldexternids = $util.emptyArray;

        /**
         * MessageMsg groupmessages.
         * @member {Array.<cat.IMessageMsg>} groupmessages
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.groupmessages = $util.emptyArray;

        /**
         * MessageMsg sender.
         * @member {cat.IAccountMsg|null|undefined} sender
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.sender = null;

        /**
         * MessageMsg forward.
         * @member {cat.IAccountMsg|null|undefined} forward
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.forward = null;

        /**
         * MessageMsg owner.
         * @member {cat.IAccountMsg|null|undefined} owner
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.owner = null;

        /**
         * MessageMsg recipients.
         * @member {Array.<cat.IAccountMsg>} recipients
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.recipients = $util.emptyArray;

        /**
         * MessageMsg topic.
         * @member {cat.ITopicMsg|null|undefined} topic
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.topic = null;

        /**
         * MessageMsg attachments.
         * @member {Array.<cat.IAttachmentMsg>} attachments
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.attachments = $util.emptyArray;

        /**
         * MessageMsg comments.
         * @member {Array.<cat.IMessageMsg>} comments
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.comments = $util.emptyArray;

        /**
         * MessageMsg reactions.
         * @member {Array.<cat.IReactionMsg>} reactions
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.reactions = $util.emptyArray;

        /**
         * MessageMsg replyto.
         * @member {cat.IMessageMsg|null|undefined} replyto
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.replyto = null;

        /**
         * MessageMsg labels.
         * @member {Array.<cat.ILabelMsg>} labels
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.labels = $util.emptyArray;

        /**
         * MessageMsg annotations.
         * @member {Array.<cat.IAnnotationMsg>} annotations
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.annotations = $util.emptyArray;

        /**
         * MessageMsg statusupdate.
         * @member {cat.MessageStatus} statusupdate
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.statusupdate = 0;

        /**
         * MessageMsg apprefid.
         * @member {string} apprefid
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.apprefid = "";

        /**
         * MessageMsg randomreplyid.
         * @member {string} randomreplyid
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.randomreplyid = "";

        /**
         * MessageMsg channel.
         * @member {cat.IConversationMsg|null|undefined} channel
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.channel = null;

        /**
         * MessageMsg history.
         * @member {Array.<cat.IMessageMsg>} history
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.history = $util.emptyArray;

        /**
         * MessageMsg emojis.
         * @member {Array.<cat.IAttachmentMsg>} emojis
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.emojis = $util.emptyArray;

        /**
         * MessageMsg historymarkers.
         * @member {Array.<cat.HistoryMarker>} historymarkers
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.historymarkers = $util.emptyArray;

        /**
         * MessageMsg historytimestamp.
         * @member {number} historytimestamp
         * @memberof cat.MessageMsg
         * @instance
         */
        MessageMsg.prototype.historytimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_type", {
            get: $util.oneOfGetter($oneOfFields = ["type"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_status", {
            get: $util.oneOfGetter($oneOfFields = ["status"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_direction", {
            get: $util.oneOfGetter($oneOfFields = ["direction"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_sent", {
            get: $util.oneOfGetter($oneOfFields = ["sent"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_received", {
            get: $util.oneOfGetter($oneOfFields = ["received"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_lastedited", {
            get: $util.oneOfGetter($oneOfFields = ["lastedited"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_deleted", {
            get: $util.oneOfGetter($oneOfFields = ["deleted"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_forwarded", {
            get: $util.oneOfGetter($oneOfFields = ["forwarded"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_expires", {
            get: $util.oneOfGetter($oneOfFields = ["expires"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_opened", {
            get: $util.oneOfGetter($oneOfFields = ["opened"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_subject", {
            get: $util.oneOfGetter($oneOfFields = ["subject"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_body", {
            get: $util.oneOfGetter($oneOfFields = ["body"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_group", {
            get: $util.oneOfGetter($oneOfFields = ["group"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_tmpid", {
            get: $util.oneOfGetter($oneOfFields = ["tmpid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MessageMsg.prototype, "_randomid", {
            get: $util.oneOfGetter($oneOfFields = ["randomid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MessageMsg instance using the specified properties.
         * @function create
         * @memberof cat.MessageMsg
         * @static
         * @param {cat.IMessageMsg=} [properties] Properties to set
         * @returns {cat.MessageMsg} MessageMsg instance
         */
        MessageMsg.create = function create(properties) {
            return new MessageMsg(properties);
        };

        /**
         * Encodes the specified MessageMsg message. Does not implicitly {@link cat.MessageMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.MessageMsg
         * @static
         * @param {cat.IMessageMsg} m MessageMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MessageMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(24).int32(m.status);
            if (m.direction != null && Object.hasOwnProperty.call(m, "direction"))
                w.uint32(32).int32(m.direction);
            if (m.sent != null && Object.hasOwnProperty.call(m, "sent"))
                w.uint32(64).uint64(m.sent);
            if (m.received != null && Object.hasOwnProperty.call(m, "received"))
                w.uint32(72).uint64(m.received);
            if (m.lastedited != null && Object.hasOwnProperty.call(m, "lastedited"))
                w.uint32(80).uint64(m.lastedited);
            if (m.deleted != null && Object.hasOwnProperty.call(m, "deleted"))
                w.uint32(88).uint64(m.deleted);
            if (m.forwarded != null && Object.hasOwnProperty.call(m, "forwarded"))
                w.uint32(96).uint64(m.forwarded);
            if (m.expires != null && Object.hasOwnProperty.call(m, "expires"))
                w.uint32(104).uint64(m.expires);
            if (m.opened != null && Object.hasOwnProperty.call(m, "opened"))
                w.uint32(112).uint64(m.opened);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(120).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(128).uint64(m.lastmodified);
            if (m.subject != null && Object.hasOwnProperty.call(m, "subject"))
                w.uint32(138).string(m.subject);
            if (m.body != null && Object.hasOwnProperty.call(m, "body"))
                w.uint32(146).string(m.body);
            if (m.attributes != null && m.attributes.length) {
                for (var i = 0; i < m.attributes.length; ++i)
                    w.uint32(154).string(m.attributes[i]);
            }
            if (m.raw != null && Object.hasOwnProperty.call(m, "raw"))
                $root.cat.RawMsg.encode(m.raw, w.uint32(162).fork()).ldelim();
            if (m.externid != null && Object.hasOwnProperty.call(m, "externid"))
                w.uint32(170).string(m.externid);
            if (m.group != null && Object.hasOwnProperty.call(m, "group"))
                w.uint32(178).string(m.group);
            if (m.urls != null && m.urls.length) {
                for (var i = 0; i < m.urls.length; ++i)
                    w.uint32(186).string(m.urls[i]);
            }
            if (m.mentions != null && m.mentions.length) {
                for (var i = 0; i < m.mentions.length; ++i)
                    w.uint32(194).string(m.mentions[i]);
            }
            if (m.hashtags != null && m.hashtags.length) {
                for (var i = 0; i < m.hashtags.length; ++i)
                    w.uint32(202).string(m.hashtags[i]);
            }
            if (m.options != null && Object.hasOwnProperty.call(m, "options")) {
                for (var ks = Object.keys(m.options), i = 0; i < ks.length; ++i) {
                    w.uint32(210).fork().uint32(10).string(ks[i]).uint32(18).string(m.options[ks[i]]).ldelim();
                }
            }
            if (m.tsatimestamp != null && Object.hasOwnProperty.call(m, "tsatimestamp"))
                w.uint32(216).uint64(m.tsatimestamp);
            if (m.tsasignature != null && Object.hasOwnProperty.call(m, "tsasignature"))
                w.uint32(226).string(m.tsasignature);
            if (m.tmpid != null && Object.hasOwnProperty.call(m, "tmpid"))
                w.uint32(242).string(m.tmpid);
            if (m.randomid != null && Object.hasOwnProperty.call(m, "randomid"))
                w.uint32(250).string(m.randomid);
            if (m.content != null && m.content.length) {
                for (var i = 0; i < m.content.length; ++i)
                    $root.cat.ContentMsg.encode(m.content[i], w.uint32(258).fork()).ldelim();
            }
            if (m.oldexternids != null && m.oldexternids.length) {
                for (var i = 0; i < m.oldexternids.length; ++i)
                    w.uint32(266).string(m.oldexternids[i]);
            }
            if (m.groupmessages != null && m.groupmessages.length) {
                for (var i = 0; i < m.groupmessages.length; ++i)
                    $root.cat.MessageMsg.encode(m.groupmessages[i], w.uint32(810).fork()).ldelim();
            }
            if (m.sender != null && Object.hasOwnProperty.call(m, "sender"))
                $root.cat.AccountMsg.encode(m.sender, w.uint32(818).fork()).ldelim();
            if (m.forward != null && Object.hasOwnProperty.call(m, "forward"))
                $root.cat.AccountMsg.encode(m.forward, w.uint32(826).fork()).ldelim();
            if (m.owner != null && Object.hasOwnProperty.call(m, "owner"))
                $root.cat.AccountMsg.encode(m.owner, w.uint32(834).fork()).ldelim();
            if (m.recipients != null && m.recipients.length) {
                for (var i = 0; i < m.recipients.length; ++i)
                    $root.cat.AccountMsg.encode(m.recipients[i], w.uint32(842).fork()).ldelim();
            }
            if (m.topic != null && Object.hasOwnProperty.call(m, "topic"))
                $root.cat.TopicMsg.encode(m.topic, w.uint32(850).fork()).ldelim();
            if (m.attachments != null && m.attachments.length) {
                for (var i = 0; i < m.attachments.length; ++i)
                    $root.cat.AttachmentMsg.encode(m.attachments[i], w.uint32(858).fork()).ldelim();
            }
            if (m.comments != null && m.comments.length) {
                for (var i = 0; i < m.comments.length; ++i)
                    $root.cat.MessageMsg.encode(m.comments[i], w.uint32(866).fork()).ldelim();
            }
            if (m.reactions != null && m.reactions.length) {
                for (var i = 0; i < m.reactions.length; ++i)
                    $root.cat.ReactionMsg.encode(m.reactions[i], w.uint32(874).fork()).ldelim();
            }
            if (m.replyto != null && Object.hasOwnProperty.call(m, "replyto"))
                $root.cat.MessageMsg.encode(m.replyto, w.uint32(882).fork()).ldelim();
            if (m.labels != null && m.labels.length) {
                for (var i = 0; i < m.labels.length; ++i)
                    $root.cat.LabelMsg.encode(m.labels[i], w.uint32(890).fork()).ldelim();
            }
            if (m.annotations != null && m.annotations.length) {
                for (var i = 0; i < m.annotations.length; ++i)
                    $root.cat.AnnotationMsg.encode(m.annotations[i], w.uint32(898).fork()).ldelim();
            }
            if (m.statusupdate != null && Object.hasOwnProperty.call(m, "statusupdate"))
                w.uint32(904).int32(m.statusupdate);
            if (m.apprefid != null && Object.hasOwnProperty.call(m, "apprefid"))
                w.uint32(914).string(m.apprefid);
            if (m.randomreplyid != null && Object.hasOwnProperty.call(m, "randomreplyid"))
                w.uint32(922).string(m.randomreplyid);
            if (m.channel != null && Object.hasOwnProperty.call(m, "channel"))
                $root.cat.ConversationMsg.encode(m.channel, w.uint32(930).fork()).ldelim();
            if (m.history != null && m.history.length) {
                for (var i = 0; i < m.history.length; ++i)
                    $root.cat.MessageMsg.encode(m.history[i], w.uint32(938).fork()).ldelim();
            }
            if (m.emojis != null && m.emojis.length) {
                for (var i = 0; i < m.emojis.length; ++i)
                    $root.cat.AttachmentMsg.encode(m.emojis[i], w.uint32(946).fork()).ldelim();
            }
            if (m.historymarkers != null && m.historymarkers.length) {
                w.uint32(954).fork();
                for (var i = 0; i < m.historymarkers.length; ++i)
                    w.int32(m.historymarkers[i]);
                w.ldelim();
            }
            if (m.historytimestamp != null && Object.hasOwnProperty.call(m, "historytimestamp"))
                w.uint32(960).uint64(m.historytimestamp);
            return w;
        };

        /**
         * Decodes a MessageMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.MessageMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.MessageMsg} MessageMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MessageMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.MessageMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.type = r.int32();
                        break;
                    }
                case 3: {
                        m.status = r.int32();
                        break;
                    }
                case 4: {
                        m.direction = r.int32();
                        break;
                    }
                case 8: {
                        m.sent = r.uint64();
                        break;
                    }
                case 9: {
                        m.received = r.uint64();
                        break;
                    }
                case 10: {
                        m.lastedited = r.uint64();
                        break;
                    }
                case 11: {
                        m.deleted = r.uint64();
                        break;
                    }
                case 12: {
                        m.forwarded = r.uint64();
                        break;
                    }
                case 13: {
                        m.expires = r.uint64();
                        break;
                    }
                case 14: {
                        m.opened = r.uint64();
                        break;
                    }
                case 15: {
                        m.created = r.uint64();
                        break;
                    }
                case 16: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 17: {
                        m.subject = r.string();
                        break;
                    }
                case 18: {
                        m.body = r.string();
                        break;
                    }
                case 19: {
                        if (!(m.attributes && m.attributes.length))
                            m.attributes = [];
                        m.attributes.push(r.string());
                        break;
                    }
                case 20: {
                        m.raw = $root.cat.RawMsg.decode(r, r.uint32());
                        break;
                    }
                case 21: {
                        m.externid = r.string();
                        break;
                    }
                case 22: {
                        m.group = r.string();
                        break;
                    }
                case 23: {
                        if (!(m.urls && m.urls.length))
                            m.urls = [];
                        m.urls.push(r.string());
                        break;
                    }
                case 24: {
                        if (!(m.mentions && m.mentions.length))
                            m.mentions = [];
                        m.mentions.push(r.string());
                        break;
                    }
                case 25: {
                        if (!(m.hashtags && m.hashtags.length))
                            m.hashtags = [];
                        m.hashtags.push(r.string());
                        break;
                    }
                case 26: {
                        if (m.options === $util.emptyObject)
                            m.options = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.options[k] = value;
                        break;
                    }
                case 27: {
                        m.tsatimestamp = r.uint64();
                        break;
                    }
                case 28: {
                        m.tsasignature = r.string();
                        break;
                    }
                case 30: {
                        m.tmpid = r.string();
                        break;
                    }
                case 31: {
                        m.randomid = r.string();
                        break;
                    }
                case 32: {
                        if (!(m.content && m.content.length))
                            m.content = [];
                        m.content.push($root.cat.ContentMsg.decode(r, r.uint32()));
                        break;
                    }
                case 33: {
                        if (!(m.oldexternids && m.oldexternids.length))
                            m.oldexternids = [];
                        m.oldexternids.push(r.string());
                        break;
                    }
                case 101: {
                        if (!(m.groupmessages && m.groupmessages.length))
                            m.groupmessages = [];
                        m.groupmessages.push($root.cat.MessageMsg.decode(r, r.uint32()));
                        break;
                    }
                case 102: {
                        m.sender = $root.cat.AccountMsg.decode(r, r.uint32());
                        break;
                    }
                case 103: {
                        m.forward = $root.cat.AccountMsg.decode(r, r.uint32());
                        break;
                    }
                case 104: {
                        m.owner = $root.cat.AccountMsg.decode(r, r.uint32());
                        break;
                    }
                case 105: {
                        if (!(m.recipients && m.recipients.length))
                            m.recipients = [];
                        m.recipients.push($root.cat.AccountMsg.decode(r, r.uint32()));
                        break;
                    }
                case 106: {
                        m.topic = $root.cat.TopicMsg.decode(r, r.uint32());
                        break;
                    }
                case 107: {
                        if (!(m.attachments && m.attachments.length))
                            m.attachments = [];
                        m.attachments.push($root.cat.AttachmentMsg.decode(r, r.uint32()));
                        break;
                    }
                case 108: {
                        if (!(m.comments && m.comments.length))
                            m.comments = [];
                        m.comments.push($root.cat.MessageMsg.decode(r, r.uint32()));
                        break;
                    }
                case 109: {
                        if (!(m.reactions && m.reactions.length))
                            m.reactions = [];
                        m.reactions.push($root.cat.ReactionMsg.decode(r, r.uint32()));
                        break;
                    }
                case 110: {
                        m.replyto = $root.cat.MessageMsg.decode(r, r.uint32());
                        break;
                    }
                case 111: {
                        if (!(m.labels && m.labels.length))
                            m.labels = [];
                        m.labels.push($root.cat.LabelMsg.decode(r, r.uint32()));
                        break;
                    }
                case 112: {
                        if (!(m.annotations && m.annotations.length))
                            m.annotations = [];
                        m.annotations.push($root.cat.AnnotationMsg.decode(r, r.uint32()));
                        break;
                    }
                case 113: {
                        m.statusupdate = r.int32();
                        break;
                    }
                case 114: {
                        m.apprefid = r.string();
                        break;
                    }
                case 115: {
                        m.randomreplyid = r.string();
                        break;
                    }
                case 116: {
                        m.channel = $root.cat.ConversationMsg.decode(r, r.uint32());
                        break;
                    }
                case 117: {
                        if (!(m.history && m.history.length))
                            m.history = [];
                        m.history.push($root.cat.MessageMsg.decode(r, r.uint32()));
                        break;
                    }
                case 118: {
                        if (!(m.emojis && m.emojis.length))
                            m.emojis = [];
                        m.emojis.push($root.cat.AttachmentMsg.decode(r, r.uint32()));
                        break;
                    }
                case 119: {
                        if (!(m.historymarkers && m.historymarkers.length))
                            m.historymarkers = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.historymarkers.push(r.int32());
                        } else
                            m.historymarkers.push(r.int32());
                        break;
                    }
                case 120: {
                        m.historytimestamp = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a MessageMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.MessageMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.MessageMsg} MessageMsg
         */
        MessageMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.MessageMsg)
                return d;
            var m = new $root.cat.MessageMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "MESSAGE_UNSPECIFIED":
            case 0:
                m.type = 0;
                break;
            case "MESSAGE_MESSAGE":
            case 1:
                m.type = 1;
                break;
            case "MESSAGE_GROUPCREATE":
            case 2:
                m.type = 2;
                break;
            case "MESSAGE_SCREENSHOT":
            case 3:
                m.type = 3;
                break;
            case "MESSAGE_CHANGETITLE":
            case 4:
                m.type = 4;
                break;
            case "MESSAGE_CHANGEIMAGE":
            case 5:
                m.type = 5;
                break;
            case "MESSAGE_ADDMEMBERS":
            case 6:
                m.type = 6;
                break;
            case "MESSAGE_DELETEMEMBER":
            case 7:
                m.type = 7;
                break;
            case "MESSAGE_JOINBYLINK":
            case 8:
                m.type = 8;
                break;
            case "MESSAGE_CALL":
            case 9:
                m.type = 9;
                break;
            case "MESSAGE_CALL_ENDED":
            case 10:
                m.type = 10;
                break;
            case "MESSAGE_REQUEST_ACCEPTED":
            case 11:
                m.type = 11;
                break;
            case "MESSAGE_TOPIC":
            case 12:
                m.type = 12;
                break;
            case "MESSAGE_TTL":
            case 13:
                m.type = 13;
                break;
            case "MESSAGE_POLL":
            case 14:
                m.type = 14;
                break;
            case "MESSAGE_HISTORYCLEAR":
            case 15:
                m.type = 15;
                break;
            case "MESSAGE_CHATCREATE":
            case 16:
                m.type = 16;
                break;
            case "MESSAGE_PIN":
            case 17:
                m.type = 17;
                break;
            case "MESSAGE_STORY_SHARE":
            case 18:
                m.type = 18;
                break;
            case "MESSAGE_POST_SHARE":
            case 19:
                m.type = 19;
                break;
            case "MESSAGE_MISSED_CALL":
            case 20:
                m.type = 20;
                break;
            case "MESSAGE_SERVICE_MESSAGE":
            case 21:
                m.type = 21;
                break;
            case "MESSAGE_CHANGEDESCRIPTION":
            case 22:
                m.type = 22;
                break;
            case "MESSAGE_REMOVEDMEMBER":
            case 23:
                m.type = 23;
                break;
            case "MESSAGE_CHAT_MIGRATED":
            case 24:
                m.type = 24;
                break;
            case "MESSAGE_LOCATION_REQUEST":
            case 25:
                m.type = 25;
                break;
            case "MESSAGE_VIDEO_CALL":
            case 26:
                m.type = 26;
                break;
            case "MESSAGE_REMOVEIMAGE":
            case 27:
                m.type = 27;
                break;
            case "MESSAGE_CALL_SCHEDULED":
            case 28:
                m.type = 28;
                break;
            case "MESSAGE_CALL_JOINED":
            case 29:
                m.type = 29;
                break;
            case "MESSAGE_CALL_LEFT":
            case 30:
                m.type = 30;
                break;
            case "MESSAGE_CHANGETHEME":
            case 31:
                m.type = 31;
                break;
            case "MESSAGE_CREATE_INVITELINK":
            case 32:
                m.type = 32;
                break;
            case "MESSAGE_CANCEL_INVITELINK":
            case 33:
                m.type = 33;
                break;
            case "MESSAGE_ENDTOEND_ENCRYPTED":
            case 34:
                m.type = 34;
                break;
            case "MESSAGE_SECURITYCODE_CHANGED":
            case 35:
                m.type = 35;
                break;
            case "MESSAGE_GROUPINFO_ADMIN":
            case 36:
                m.type = 36;
                break;
            case "MESSAGE_GROUPINFO_ALL":
            case 37:
                m.type = 37;
                break;
            case "MESSAGE_GROUPSEND_ADMIN":
            case 38:
                m.type = 38;
                break;
            case "MESSAGE_GROUPSEND_ALL":
            case 39:
                m.type = 39;
                break;
            case "MESSAGE_CHANGEPHONENUMBER":
            case 40:
                m.type = 40;
                break;
            case "MESSAGE_ISADMIN":
            case 41:
                m.type = 41;
                break;
            case "MESSAGE_NOTADMIN":
            case 42:
                m.type = 42;
                break;
            case "MESSAGE_BLOCKEDCONTACT":
            case 43:
                m.type = 43;
                break;
            case "MESSAGE_UNBLOCKEDCONTACT":
            case 44:
                m.type = 44;
                break;
            case "MESSAGE_CALL_STARTED":
            case 45:
                m.type = 45;
                break;
            case "MESSAGE_CHANGENICKNAME":
            case 46:
                m.type = 46;
                break;
            case "MESSAGE_CHANGENICKNAME_SELF":
            case 47:
                m.type = 47;
                break;
            case "MESSAGE_REMOVENICKNAME":
            case 48:
                m.type = 48;
                break;
            case "MESSAGE_REMOVENICKNAME_SELF":
            case 49:
                m.type = 49;
                break;
            case "MESSAGE_CHANGEEMOJI":
            case 50:
                m.type = 50;
                break;
            case "MESSAGE_TOPIC_CREATE":
            case 51:
                m.type = 51;
                break;
            case "MESSAGE_TOPIC_EDIT":
            case 52:
                m.type = 52;
                break;
            case "MESSAGE_THREAD_CREATE":
            case 53:
                m.type = 53;
                break;
            case "MESSAGE_WALLPAPER_CHAT":
            case 54:
                m.type = 54;
                break;
            case "MESSAGE_VIDEO_CALL_STARTED":
            case 55:
                m.type = 55;
                break;
            case "MESSAGE_VIDEO_CALL_ENDED":
            case 56:
                m.type = 56;
                break;
            case "MESSAGE_MISSED_VIDEO_CALL":
            case 57:
                m.type = 57;
                break;
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "MESSAGE_UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "MESSAGE_DRAFT":
            case 1:
                m.status = 1;
                break;
            case "MESSAGE_PENDING":
            case 2:
                m.status = 2;
                break;
            case "MESSAGE_SENT":
            case 3:
                m.status = 3;
                break;
            case "MESSAGE_RECEIVED":
            case 4:
                m.status = 4;
                break;
            case "MESSAGE_READ":
            case 5:
                m.status = 5;
                break;
            case "MESSAGE_FAILED":
            case 6:
                m.status = 6;
                break;
            case "MESSAGE_BOUNCED":
            case 7:
                m.status = 7;
                break;
            }
            switch (d.direction) {
            default:
                if (typeof d.direction === "number") {
                    m.direction = d.direction;
                    break;
                }
                break;
            case "DIRECTION_UNKNOWN":
            case 0:
                m.direction = 0;
                break;
            case "DIRECTION_INCOMING":
            case 1:
                m.direction = 1;
                break;
            case "DIRECTION_OUTGOING":
            case 2:
                m.direction = 2;
                break;
            }
            if (d.sent != null) {
                if ($util.Long)
                    (m.sent = $util.Long.fromValue(d.sent)).unsigned = true;
                else if (typeof d.sent === "string")
                    m.sent = parseInt(d.sent, 10);
                else if (typeof d.sent === "number")
                    m.sent = d.sent;
                else if (typeof d.sent === "object")
                    m.sent = new $util.LongBits(d.sent.low >>> 0, d.sent.high >>> 0).toNumber(true);
            }
            if (d.received != null) {
                if ($util.Long)
                    (m.received = $util.Long.fromValue(d.received)).unsigned = true;
                else if (typeof d.received === "string")
                    m.received = parseInt(d.received, 10);
                else if (typeof d.received === "number")
                    m.received = d.received;
                else if (typeof d.received === "object")
                    m.received = new $util.LongBits(d.received.low >>> 0, d.received.high >>> 0).toNumber(true);
            }
            if (d.lastedited != null) {
                if ($util.Long)
                    (m.lastedited = $util.Long.fromValue(d.lastedited)).unsigned = true;
                else if (typeof d.lastedited === "string")
                    m.lastedited = parseInt(d.lastedited, 10);
                else if (typeof d.lastedited === "number")
                    m.lastedited = d.lastedited;
                else if (typeof d.lastedited === "object")
                    m.lastedited = new $util.LongBits(d.lastedited.low >>> 0, d.lastedited.high >>> 0).toNumber(true);
            }
            if (d.deleted != null) {
                if ($util.Long)
                    (m.deleted = $util.Long.fromValue(d.deleted)).unsigned = true;
                else if (typeof d.deleted === "string")
                    m.deleted = parseInt(d.deleted, 10);
                else if (typeof d.deleted === "number")
                    m.deleted = d.deleted;
                else if (typeof d.deleted === "object")
                    m.deleted = new $util.LongBits(d.deleted.low >>> 0, d.deleted.high >>> 0).toNumber(true);
            }
            if (d.forwarded != null) {
                if ($util.Long)
                    (m.forwarded = $util.Long.fromValue(d.forwarded)).unsigned = true;
                else if (typeof d.forwarded === "string")
                    m.forwarded = parseInt(d.forwarded, 10);
                else if (typeof d.forwarded === "number")
                    m.forwarded = d.forwarded;
                else if (typeof d.forwarded === "object")
                    m.forwarded = new $util.LongBits(d.forwarded.low >>> 0, d.forwarded.high >>> 0).toNumber(true);
            }
            if (d.expires != null) {
                if ($util.Long)
                    (m.expires = $util.Long.fromValue(d.expires)).unsigned = true;
                else if (typeof d.expires === "string")
                    m.expires = parseInt(d.expires, 10);
                else if (typeof d.expires === "number")
                    m.expires = d.expires;
                else if (typeof d.expires === "object")
                    m.expires = new $util.LongBits(d.expires.low >>> 0, d.expires.high >>> 0).toNumber(true);
            }
            if (d.opened != null) {
                if ($util.Long)
                    (m.opened = $util.Long.fromValue(d.opened)).unsigned = true;
                else if (typeof d.opened === "string")
                    m.opened = parseInt(d.opened, 10);
                else if (typeof d.opened === "number")
                    m.opened = d.opened;
                else if (typeof d.opened === "object")
                    m.opened = new $util.LongBits(d.opened.low >>> 0, d.opened.high >>> 0).toNumber(true);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.subject != null) {
                m.subject = String(d.subject);
            }
            if (d.body != null) {
                m.body = String(d.body);
            }
            if (d.attributes) {
                if (!Array.isArray(d.attributes))
                    throw TypeError(".cat.MessageMsg.attributes: array expected");
                m.attributes = [];
                for (var i = 0; i < d.attributes.length; ++i) {
                    m.attributes[i] = String(d.attributes[i]);
                }
            }
            if (d.raw != null) {
                if (typeof d.raw !== "object")
                    throw TypeError(".cat.MessageMsg.raw: object expected");
                m.raw = $root.cat.RawMsg.fromObject(d.raw);
            }
            if (d.externid != null) {
                m.externid = String(d.externid);
            }
            if (d.group != null) {
                m.group = String(d.group);
            }
            if (d.urls) {
                if (!Array.isArray(d.urls))
                    throw TypeError(".cat.MessageMsg.urls: array expected");
                m.urls = [];
                for (var i = 0; i < d.urls.length; ++i) {
                    m.urls[i] = String(d.urls[i]);
                }
            }
            if (d.mentions) {
                if (!Array.isArray(d.mentions))
                    throw TypeError(".cat.MessageMsg.mentions: array expected");
                m.mentions = [];
                for (var i = 0; i < d.mentions.length; ++i) {
                    m.mentions[i] = String(d.mentions[i]);
                }
            }
            if (d.hashtags) {
                if (!Array.isArray(d.hashtags))
                    throw TypeError(".cat.MessageMsg.hashtags: array expected");
                m.hashtags = [];
                for (var i = 0; i < d.hashtags.length; ++i) {
                    m.hashtags[i] = String(d.hashtags[i]);
                }
            }
            if (d.options) {
                if (typeof d.options !== "object")
                    throw TypeError(".cat.MessageMsg.options: object expected");
                m.options = {};
                for (var ks = Object.keys(d.options), i = 0; i < ks.length; ++i) {
                    m.options[ks[i]] = String(d.options[ks[i]]);
                }
            }
            if (d.tsatimestamp != null) {
                if ($util.Long)
                    (m.tsatimestamp = $util.Long.fromValue(d.tsatimestamp)).unsigned = true;
                else if (typeof d.tsatimestamp === "string")
                    m.tsatimestamp = parseInt(d.tsatimestamp, 10);
                else if (typeof d.tsatimestamp === "number")
                    m.tsatimestamp = d.tsatimestamp;
                else if (typeof d.tsatimestamp === "object")
                    m.tsatimestamp = new $util.LongBits(d.tsatimestamp.low >>> 0, d.tsatimestamp.high >>> 0).toNumber(true);
            }
            if (d.tsasignature != null) {
                m.tsasignature = String(d.tsasignature);
            }
            if (d.tmpid != null) {
                m.tmpid = String(d.tmpid);
            }
            if (d.randomid != null) {
                m.randomid = String(d.randomid);
            }
            if (d.content) {
                if (!Array.isArray(d.content))
                    throw TypeError(".cat.MessageMsg.content: array expected");
                m.content = [];
                for (var i = 0; i < d.content.length; ++i) {
                    if (typeof d.content[i] !== "object")
                        throw TypeError(".cat.MessageMsg.content: object expected");
                    m.content[i] = $root.cat.ContentMsg.fromObject(d.content[i]);
                }
            }
            if (d.oldexternids) {
                if (!Array.isArray(d.oldexternids))
                    throw TypeError(".cat.MessageMsg.oldexternids: array expected");
                m.oldexternids = [];
                for (var i = 0; i < d.oldexternids.length; ++i) {
                    m.oldexternids[i] = String(d.oldexternids[i]);
                }
            }
            if (d.groupmessages) {
                if (!Array.isArray(d.groupmessages))
                    throw TypeError(".cat.MessageMsg.groupmessages: array expected");
                m.groupmessages = [];
                for (var i = 0; i < d.groupmessages.length; ++i) {
                    if (typeof d.groupmessages[i] !== "object")
                        throw TypeError(".cat.MessageMsg.groupmessages: object expected");
                    m.groupmessages[i] = $root.cat.MessageMsg.fromObject(d.groupmessages[i]);
                }
            }
            if (d.sender != null) {
                if (typeof d.sender !== "object")
                    throw TypeError(".cat.MessageMsg.sender: object expected");
                m.sender = $root.cat.AccountMsg.fromObject(d.sender);
            }
            if (d.forward != null) {
                if (typeof d.forward !== "object")
                    throw TypeError(".cat.MessageMsg.forward: object expected");
                m.forward = $root.cat.AccountMsg.fromObject(d.forward);
            }
            if (d.owner != null) {
                if (typeof d.owner !== "object")
                    throw TypeError(".cat.MessageMsg.owner: object expected");
                m.owner = $root.cat.AccountMsg.fromObject(d.owner);
            }
            if (d.recipients) {
                if (!Array.isArray(d.recipients))
                    throw TypeError(".cat.MessageMsg.recipients: array expected");
                m.recipients = [];
                for (var i = 0; i < d.recipients.length; ++i) {
                    if (typeof d.recipients[i] !== "object")
                        throw TypeError(".cat.MessageMsg.recipients: object expected");
                    m.recipients[i] = $root.cat.AccountMsg.fromObject(d.recipients[i]);
                }
            }
            if (d.topic != null) {
                if (typeof d.topic !== "object")
                    throw TypeError(".cat.MessageMsg.topic: object expected");
                m.topic = $root.cat.TopicMsg.fromObject(d.topic);
            }
            if (d.attachments) {
                if (!Array.isArray(d.attachments))
                    throw TypeError(".cat.MessageMsg.attachments: array expected");
                m.attachments = [];
                for (var i = 0; i < d.attachments.length; ++i) {
                    if (typeof d.attachments[i] !== "object")
                        throw TypeError(".cat.MessageMsg.attachments: object expected");
                    m.attachments[i] = $root.cat.AttachmentMsg.fromObject(d.attachments[i]);
                }
            }
            if (d.comments) {
                if (!Array.isArray(d.comments))
                    throw TypeError(".cat.MessageMsg.comments: array expected");
                m.comments = [];
                for (var i = 0; i < d.comments.length; ++i) {
                    if (typeof d.comments[i] !== "object")
                        throw TypeError(".cat.MessageMsg.comments: object expected");
                    m.comments[i] = $root.cat.MessageMsg.fromObject(d.comments[i]);
                }
            }
            if (d.reactions) {
                if (!Array.isArray(d.reactions))
                    throw TypeError(".cat.MessageMsg.reactions: array expected");
                m.reactions = [];
                for (var i = 0; i < d.reactions.length; ++i) {
                    if (typeof d.reactions[i] !== "object")
                        throw TypeError(".cat.MessageMsg.reactions: object expected");
                    m.reactions[i] = $root.cat.ReactionMsg.fromObject(d.reactions[i]);
                }
            }
            if (d.replyto != null) {
                if (typeof d.replyto !== "object")
                    throw TypeError(".cat.MessageMsg.replyto: object expected");
                m.replyto = $root.cat.MessageMsg.fromObject(d.replyto);
            }
            if (d.labels) {
                if (!Array.isArray(d.labels))
                    throw TypeError(".cat.MessageMsg.labels: array expected");
                m.labels = [];
                for (var i = 0; i < d.labels.length; ++i) {
                    if (typeof d.labels[i] !== "object")
                        throw TypeError(".cat.MessageMsg.labels: object expected");
                    m.labels[i] = $root.cat.LabelMsg.fromObject(d.labels[i]);
                }
            }
            if (d.annotations) {
                if (!Array.isArray(d.annotations))
                    throw TypeError(".cat.MessageMsg.annotations: array expected");
                m.annotations = [];
                for (var i = 0; i < d.annotations.length; ++i) {
                    if (typeof d.annotations[i] !== "object")
                        throw TypeError(".cat.MessageMsg.annotations: object expected");
                    m.annotations[i] = $root.cat.AnnotationMsg.fromObject(d.annotations[i]);
                }
            }
            switch (d.statusupdate) {
            default:
                if (typeof d.statusupdate === "number") {
                    m.statusupdate = d.statusupdate;
                    break;
                }
                break;
            case "MESSAGE_UNKNOWN":
            case 0:
                m.statusupdate = 0;
                break;
            case "MESSAGE_DRAFT":
            case 1:
                m.statusupdate = 1;
                break;
            case "MESSAGE_PENDING":
            case 2:
                m.statusupdate = 2;
                break;
            case "MESSAGE_SENT":
            case 3:
                m.statusupdate = 3;
                break;
            case "MESSAGE_RECEIVED":
            case 4:
                m.statusupdate = 4;
                break;
            case "MESSAGE_READ":
            case 5:
                m.statusupdate = 5;
                break;
            case "MESSAGE_FAILED":
            case 6:
                m.statusupdate = 6;
                break;
            case "MESSAGE_BOUNCED":
            case 7:
                m.statusupdate = 7;
                break;
            }
            if (d.apprefid != null) {
                m.apprefid = String(d.apprefid);
            }
            if (d.randomreplyid != null) {
                m.randomreplyid = String(d.randomreplyid);
            }
            if (d.channel != null) {
                if (typeof d.channel !== "object")
                    throw TypeError(".cat.MessageMsg.channel: object expected");
                m.channel = $root.cat.ConversationMsg.fromObject(d.channel);
            }
            if (d.history) {
                if (!Array.isArray(d.history))
                    throw TypeError(".cat.MessageMsg.history: array expected");
                m.history = [];
                for (var i = 0; i < d.history.length; ++i) {
                    if (typeof d.history[i] !== "object")
                        throw TypeError(".cat.MessageMsg.history: object expected");
                    m.history[i] = $root.cat.MessageMsg.fromObject(d.history[i]);
                }
            }
            if (d.emojis) {
                if (!Array.isArray(d.emojis))
                    throw TypeError(".cat.MessageMsg.emojis: array expected");
                m.emojis = [];
                for (var i = 0; i < d.emojis.length; ++i) {
                    if (typeof d.emojis[i] !== "object")
                        throw TypeError(".cat.MessageMsg.emojis: object expected");
                    m.emojis[i] = $root.cat.AttachmentMsg.fromObject(d.emojis[i]);
                }
            }
            if (d.historymarkers) {
                if (!Array.isArray(d.historymarkers))
                    throw TypeError(".cat.MessageMsg.historymarkers: array expected");
                m.historymarkers = [];
                for (var i = 0; i < d.historymarkers.length; ++i) {
                    switch (d.historymarkers[i]) {
                    default:
                        if (typeof d.historymarkers[i] === "number") {
                            m.historymarkers[i] = d.historymarkers[i];
                            break;
                        }
                    case "HISTORYMARKER_UNKNOWN":
                    case 0:
                        m.historymarkers[i] = 0;
                        break;
                    case "HISTORYMARKER_NAME":
                    case 1:
                        m.historymarkers[i] = 1;
                        break;
                    case "HISTORYMARKER_FIRSTNAME":
                    case 2:
                        m.historymarkers[i] = 2;
                        break;
                    case "HISTORYMARKER_LASTNAME":
                    case 3:
                        m.historymarkers[i] = 3;
                        break;
                    case "HISTORYMARKER_FULLNAME":
                    case 4:
                        m.historymarkers[i] = 4;
                        break;
                    case "HISTORYMARKER_BIO":
                    case 5:
                        m.historymarkers[i] = 5;
                        break;
                    case "HISTORYMARKER_EDITED":
                    case 6:
                        m.historymarkers[i] = 6;
                        break;
                    case "HISTORYMARKER_DELETED":
                    case 7:
                        m.historymarkers[i] = 7;
                        break;
                    case "HISTORYMARKER_DESCRIPTION":
                    case 8:
                        m.historymarkers[i] = 8;
                        break;
                    case "HISTORYMARKER_ALTNAMES":
                    case 9:
                        m.historymarkers[i] = 9;
                        break;
                    case "HISTORYMARKER_PLAINNAMES":
                    case 10:
                        m.historymarkers[i] = 10;
                        break;
                    case "HISTORYMARKER_LINK":
                    case 11:
                        m.historymarkers[i] = 11;
                        break;
                    case "HISTORYMARKER_BODY":
                    case 12:
                        m.historymarkers[i] = 12;
                        break;
                    case "HISTORYMARKER_OPTIONS":
                    case 13:
                        m.historymarkers[i] = 13;
                        break;
                    case "HISTORYMARKER_AVATAR":
                    case 14:
                        m.historymarkers[i] = 14;
                        break;
                    case "HISTORYMARKER_ATTACHMENT":
                    case 15:
                        m.historymarkers[i] = 15;
                        break;
                    case "HISTORYMARKER_GROUP":
                    case 16:
                        m.historymarkers[i] = 16;
                        break;
                    case "HISTORYMARKER_TYPE":
                    case 17:
                        m.historymarkers[i] = 17;
                        break;
                    }
                }
            }
            if (d.historytimestamp != null) {
                if ($util.Long)
                    (m.historytimestamp = $util.Long.fromValue(d.historytimestamp)).unsigned = true;
                else if (typeof d.historytimestamp === "string")
                    m.historytimestamp = parseInt(d.historytimestamp, 10);
                else if (typeof d.historytimestamp === "number")
                    m.historytimestamp = d.historytimestamp;
                else if (typeof d.historytimestamp === "object")
                    m.historytimestamp = new $util.LongBits(d.historytimestamp.low >>> 0, d.historytimestamp.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a MessageMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.MessageMsg
         * @static
         * @param {cat.MessageMsg} m MessageMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MessageMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.attributes = [];
                d.urls = [];
                d.mentions = [];
                d.hashtags = [];
                d.content = [];
                d.oldexternids = [];
                d.groupmessages = [];
                d.recipients = [];
                d.attachments = [];
                d.comments = [];
                d.reactions = [];
                d.labels = [];
                d.annotations = [];
                d.history = [];
                d.emojis = [];
                d.historymarkers = [];
            }
            if (o.objects || o.defaults) {
                d.options = {};
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.raw = null;
                d.externid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.tsatimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.tsatimestamp = o.longs === String ? "0" : 0;
                d.tsasignature = "";
                d.sender = null;
                d.forward = null;
                d.owner = null;
                d.topic = null;
                d.replyto = null;
                d.statusupdate = o.enums === String ? "MESSAGE_UNKNOWN" : 0;
                d.apprefid = "";
                d.randomreplyid = "";
                d.channel = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.historytimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.historytimestamp = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.MessageType[m.type] === undefined ? m.type : $root.cat.MessageType[m.type] : m.type;
                if (o.oneofs)
                    d._type = "type";
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.MessageStatus[m.status] === undefined ? m.status : $root.cat.MessageStatus[m.status] : m.status;
                if (o.oneofs)
                    d._status = "status";
            }
            if (m.direction != null && m.hasOwnProperty("direction")) {
                d.direction = o.enums === String ? $root.cat.DirectionType[m.direction] === undefined ? m.direction : $root.cat.DirectionType[m.direction] : m.direction;
                if (o.oneofs)
                    d._direction = "direction";
            }
            if (m.sent != null && m.hasOwnProperty("sent")) {
                if (typeof m.sent === "number")
                    d.sent = o.longs === String ? String(m.sent) : m.sent;
                else
                    d.sent = o.longs === String ? $util.Long.prototype.toString.call(m.sent) : o.longs === Number ? new $util.LongBits(m.sent.low >>> 0, m.sent.high >>> 0).toNumber(true) : m.sent;
                if (o.oneofs)
                    d._sent = "sent";
            }
            if (m.received != null && m.hasOwnProperty("received")) {
                if (typeof m.received === "number")
                    d.received = o.longs === String ? String(m.received) : m.received;
                else
                    d.received = o.longs === String ? $util.Long.prototype.toString.call(m.received) : o.longs === Number ? new $util.LongBits(m.received.low >>> 0, m.received.high >>> 0).toNumber(true) : m.received;
                if (o.oneofs)
                    d._received = "received";
            }
            if (m.lastedited != null && m.hasOwnProperty("lastedited")) {
                if (typeof m.lastedited === "number")
                    d.lastedited = o.longs === String ? String(m.lastedited) : m.lastedited;
                else
                    d.lastedited = o.longs === String ? $util.Long.prototype.toString.call(m.lastedited) : o.longs === Number ? new $util.LongBits(m.lastedited.low >>> 0, m.lastedited.high >>> 0).toNumber(true) : m.lastedited;
                if (o.oneofs)
                    d._lastedited = "lastedited";
            }
            if (m.deleted != null && m.hasOwnProperty("deleted")) {
                if (typeof m.deleted === "number")
                    d.deleted = o.longs === String ? String(m.deleted) : m.deleted;
                else
                    d.deleted = o.longs === String ? $util.Long.prototype.toString.call(m.deleted) : o.longs === Number ? new $util.LongBits(m.deleted.low >>> 0, m.deleted.high >>> 0).toNumber(true) : m.deleted;
                if (o.oneofs)
                    d._deleted = "deleted";
            }
            if (m.forwarded != null && m.hasOwnProperty("forwarded")) {
                if (typeof m.forwarded === "number")
                    d.forwarded = o.longs === String ? String(m.forwarded) : m.forwarded;
                else
                    d.forwarded = o.longs === String ? $util.Long.prototype.toString.call(m.forwarded) : o.longs === Number ? new $util.LongBits(m.forwarded.low >>> 0, m.forwarded.high >>> 0).toNumber(true) : m.forwarded;
                if (o.oneofs)
                    d._forwarded = "forwarded";
            }
            if (m.expires != null && m.hasOwnProperty("expires")) {
                if (typeof m.expires === "number")
                    d.expires = o.longs === String ? String(m.expires) : m.expires;
                else
                    d.expires = o.longs === String ? $util.Long.prototype.toString.call(m.expires) : o.longs === Number ? new $util.LongBits(m.expires.low >>> 0, m.expires.high >>> 0).toNumber(true) : m.expires;
                if (o.oneofs)
                    d._expires = "expires";
            }
            if (m.opened != null && m.hasOwnProperty("opened")) {
                if (typeof m.opened === "number")
                    d.opened = o.longs === String ? String(m.opened) : m.opened;
                else
                    d.opened = o.longs === String ? $util.Long.prototype.toString.call(m.opened) : o.longs === Number ? new $util.LongBits(m.opened.low >>> 0, m.opened.high >>> 0).toNumber(true) : m.opened;
                if (o.oneofs)
                    d._opened = "opened";
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.subject != null && m.hasOwnProperty("subject")) {
                d.subject = m.subject;
                if (o.oneofs)
                    d._subject = "subject";
            }
            if (m.body != null && m.hasOwnProperty("body")) {
                d.body = m.body;
                if (o.oneofs)
                    d._body = "body";
            }
            if (m.attributes && m.attributes.length) {
                d.attributes = [];
                for (var j = 0; j < m.attributes.length; ++j) {
                    d.attributes[j] = m.attributes[j];
                }
            }
            if (m.raw != null && m.hasOwnProperty("raw")) {
                d.raw = $root.cat.RawMsg.toObject(m.raw, o);
            }
            if (m.externid != null && m.hasOwnProperty("externid")) {
                d.externid = m.externid;
            }
            if (m.group != null && m.hasOwnProperty("group")) {
                d.group = m.group;
                if (o.oneofs)
                    d._group = "group";
            }
            if (m.urls && m.urls.length) {
                d.urls = [];
                for (var j = 0; j < m.urls.length; ++j) {
                    d.urls[j] = m.urls[j];
                }
            }
            if (m.mentions && m.mentions.length) {
                d.mentions = [];
                for (var j = 0; j < m.mentions.length; ++j) {
                    d.mentions[j] = m.mentions[j];
                }
            }
            if (m.hashtags && m.hashtags.length) {
                d.hashtags = [];
                for (var j = 0; j < m.hashtags.length; ++j) {
                    d.hashtags[j] = m.hashtags[j];
                }
            }
            var ks2;
            if (m.options && (ks2 = Object.keys(m.options)).length) {
                d.options = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.options[ks2[j]] = m.options[ks2[j]];
                }
            }
            if (m.tsatimestamp != null && m.hasOwnProperty("tsatimestamp")) {
                if (typeof m.tsatimestamp === "number")
                    d.tsatimestamp = o.longs === String ? String(m.tsatimestamp) : m.tsatimestamp;
                else
                    d.tsatimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.tsatimestamp) : o.longs === Number ? new $util.LongBits(m.tsatimestamp.low >>> 0, m.tsatimestamp.high >>> 0).toNumber(true) : m.tsatimestamp;
            }
            if (m.tsasignature != null && m.hasOwnProperty("tsasignature")) {
                d.tsasignature = m.tsasignature;
            }
            if (m.tmpid != null && m.hasOwnProperty("tmpid")) {
                d.tmpid = m.tmpid;
                if (o.oneofs)
                    d._tmpid = "tmpid";
            }
            if (m.randomid != null && m.hasOwnProperty("randomid")) {
                d.randomid = m.randomid;
                if (o.oneofs)
                    d._randomid = "randomid";
            }
            if (m.content && m.content.length) {
                d.content = [];
                for (var j = 0; j < m.content.length; ++j) {
                    d.content[j] = $root.cat.ContentMsg.toObject(m.content[j], o);
                }
            }
            if (m.oldexternids && m.oldexternids.length) {
                d.oldexternids = [];
                for (var j = 0; j < m.oldexternids.length; ++j) {
                    d.oldexternids[j] = m.oldexternids[j];
                }
            }
            if (m.groupmessages && m.groupmessages.length) {
                d.groupmessages = [];
                for (var j = 0; j < m.groupmessages.length; ++j) {
                    d.groupmessages[j] = $root.cat.MessageMsg.toObject(m.groupmessages[j], o);
                }
            }
            if (m.sender != null && m.hasOwnProperty("sender")) {
                d.sender = $root.cat.AccountMsg.toObject(m.sender, o);
            }
            if (m.forward != null && m.hasOwnProperty("forward")) {
                d.forward = $root.cat.AccountMsg.toObject(m.forward, o);
            }
            if (m.owner != null && m.hasOwnProperty("owner")) {
                d.owner = $root.cat.AccountMsg.toObject(m.owner, o);
            }
            if (m.recipients && m.recipients.length) {
                d.recipients = [];
                for (var j = 0; j < m.recipients.length; ++j) {
                    d.recipients[j] = $root.cat.AccountMsg.toObject(m.recipients[j], o);
                }
            }
            if (m.topic != null && m.hasOwnProperty("topic")) {
                d.topic = $root.cat.TopicMsg.toObject(m.topic, o);
            }
            if (m.attachments && m.attachments.length) {
                d.attachments = [];
                for (var j = 0; j < m.attachments.length; ++j) {
                    d.attachments[j] = $root.cat.AttachmentMsg.toObject(m.attachments[j], o);
                }
            }
            if (m.comments && m.comments.length) {
                d.comments = [];
                for (var j = 0; j < m.comments.length; ++j) {
                    d.comments[j] = $root.cat.MessageMsg.toObject(m.comments[j], o);
                }
            }
            if (m.reactions && m.reactions.length) {
                d.reactions = [];
                for (var j = 0; j < m.reactions.length; ++j) {
                    d.reactions[j] = $root.cat.ReactionMsg.toObject(m.reactions[j], o);
                }
            }
            if (m.replyto != null && m.hasOwnProperty("replyto")) {
                d.replyto = $root.cat.MessageMsg.toObject(m.replyto, o);
            }
            if (m.labels && m.labels.length) {
                d.labels = [];
                for (var j = 0; j < m.labels.length; ++j) {
                    d.labels[j] = $root.cat.LabelMsg.toObject(m.labels[j], o);
                }
            }
            if (m.annotations && m.annotations.length) {
                d.annotations = [];
                for (var j = 0; j < m.annotations.length; ++j) {
                    d.annotations[j] = $root.cat.AnnotationMsg.toObject(m.annotations[j], o);
                }
            }
            if (m.statusupdate != null && m.hasOwnProperty("statusupdate")) {
                d.statusupdate = o.enums === String ? $root.cat.MessageStatus[m.statusupdate] === undefined ? m.statusupdate : $root.cat.MessageStatus[m.statusupdate] : m.statusupdate;
            }
            if (m.apprefid != null && m.hasOwnProperty("apprefid")) {
                d.apprefid = m.apprefid;
            }
            if (m.randomreplyid != null && m.hasOwnProperty("randomreplyid")) {
                d.randomreplyid = m.randomreplyid;
            }
            if (m.channel != null && m.hasOwnProperty("channel")) {
                d.channel = $root.cat.ConversationMsg.toObject(m.channel, o);
            }
            if (m.history && m.history.length) {
                d.history = [];
                for (var j = 0; j < m.history.length; ++j) {
                    d.history[j] = $root.cat.MessageMsg.toObject(m.history[j], o);
                }
            }
            if (m.emojis && m.emojis.length) {
                d.emojis = [];
                for (var j = 0; j < m.emojis.length; ++j) {
                    d.emojis[j] = $root.cat.AttachmentMsg.toObject(m.emojis[j], o);
                }
            }
            if (m.historymarkers && m.historymarkers.length) {
                d.historymarkers = [];
                for (var j = 0; j < m.historymarkers.length; ++j) {
                    d.historymarkers[j] = o.enums === String ? $root.cat.HistoryMarker[m.historymarkers[j]] === undefined ? m.historymarkers[j] : $root.cat.HistoryMarker[m.historymarkers[j]] : m.historymarkers[j];
                }
            }
            if (m.historytimestamp != null && m.hasOwnProperty("historytimestamp")) {
                if (typeof m.historytimestamp === "number")
                    d.historytimestamp = o.longs === String ? String(m.historytimestamp) : m.historytimestamp;
                else
                    d.historytimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.historytimestamp) : o.longs === Number ? new $util.LongBits(m.historytimestamp.low >>> 0, m.historytimestamp.high >>> 0).toNumber(true) : m.historytimestamp;
            }
            return d;
        };

        /**
         * Converts this MessageMsg to JSON.
         * @function toJSON
         * @memberof cat.MessageMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MessageMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MessageMsg
         * @function getTypeUrl
         * @memberof cat.MessageMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessageMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.MessageMsg";
        };

        return MessageMsg;
    })();

    /**
     * HistoryMarker enum.
     * @name cat.HistoryMarker
     * @enum {number}
     * @property {number} HISTORYMARKER_UNKNOWN=0 HISTORYMARKER_UNKNOWN value
     * @property {number} HISTORYMARKER_NAME=1 HISTORYMARKER_NAME value
     * @property {number} HISTORYMARKER_FIRSTNAME=2 HISTORYMARKER_FIRSTNAME value
     * @property {number} HISTORYMARKER_LASTNAME=3 HISTORYMARKER_LASTNAME value
     * @property {number} HISTORYMARKER_FULLNAME=4 HISTORYMARKER_FULLNAME value
     * @property {number} HISTORYMARKER_BIO=5 HISTORYMARKER_BIO value
     * @property {number} HISTORYMARKER_EDITED=6 HISTORYMARKER_EDITED value
     * @property {number} HISTORYMARKER_DELETED=7 HISTORYMARKER_DELETED value
     * @property {number} HISTORYMARKER_DESCRIPTION=8 HISTORYMARKER_DESCRIPTION value
     * @property {number} HISTORYMARKER_ALTNAMES=9 HISTORYMARKER_ALTNAMES value
     * @property {number} HISTORYMARKER_PLAINNAMES=10 HISTORYMARKER_PLAINNAMES value
     * @property {number} HISTORYMARKER_LINK=11 HISTORYMARKER_LINK value
     * @property {number} HISTORYMARKER_BODY=12 HISTORYMARKER_BODY value
     * @property {number} HISTORYMARKER_OPTIONS=13 HISTORYMARKER_OPTIONS value
     * @property {number} HISTORYMARKER_AVATAR=14 HISTORYMARKER_AVATAR value
     * @property {number} HISTORYMARKER_ATTACHMENT=15 HISTORYMARKER_ATTACHMENT value
     * @property {number} HISTORYMARKER_GROUP=16 HISTORYMARKER_GROUP value
     * @property {number} HISTORYMARKER_TYPE=17 HISTORYMARKER_TYPE value
     */
    cat.HistoryMarker = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "HISTORYMARKER_UNKNOWN"] = 0;
        values[valuesById[1] = "HISTORYMARKER_NAME"] = 1;
        values[valuesById[2] = "HISTORYMARKER_FIRSTNAME"] = 2;
        values[valuesById[3] = "HISTORYMARKER_LASTNAME"] = 3;
        values[valuesById[4] = "HISTORYMARKER_FULLNAME"] = 4;
        values[valuesById[5] = "HISTORYMARKER_BIO"] = 5;
        values[valuesById[6] = "HISTORYMARKER_EDITED"] = 6;
        values[valuesById[7] = "HISTORYMARKER_DELETED"] = 7;
        values[valuesById[8] = "HISTORYMARKER_DESCRIPTION"] = 8;
        values[valuesById[9] = "HISTORYMARKER_ALTNAMES"] = 9;
        values[valuesById[10] = "HISTORYMARKER_PLAINNAMES"] = 10;
        values[valuesById[11] = "HISTORYMARKER_LINK"] = 11;
        values[valuesById[12] = "HISTORYMARKER_BODY"] = 12;
        values[valuesById[13] = "HISTORYMARKER_OPTIONS"] = 13;
        values[valuesById[14] = "HISTORYMARKER_AVATAR"] = 14;
        values[valuesById[15] = "HISTORYMARKER_ATTACHMENT"] = 15;
        values[valuesById[16] = "HISTORYMARKER_GROUP"] = 16;
        values[valuesById[17] = "HISTORYMARKER_TYPE"] = 17;
        return values;
    })();

    /**
     * ContentType enum.
     * @name cat.ContentType
     * @enum {number}
     * @property {number} CONTENTTYPE_UNKNOWN=0 CONTENTTYPE_UNKNOWN value
     * @property {number} CONTENTTYPE_TEXT=1 CONTENTTYPE_TEXT value
     * @property {number} CONTENTTYPE_CUSTOMEMOJI=2 CONTENTTYPE_CUSTOMEMOJI value
     */
    cat.ContentType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CONTENTTYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "CONTENTTYPE_TEXT"] = 1;
        values[valuesById[2] = "CONTENTTYPE_CUSTOMEMOJI"] = 2;
        return values;
    })();

    cat.ContentMsg = (function() {

        /**
         * Properties of a ContentMsg.
         * @memberof cat
         * @interface IContentMsg
         * @property {cat.ContentType|null} [type] ContentMsg type
         * @property {string|null} [value] ContentMsg value
         */

        /**
         * Constructs a new ContentMsg.
         * @memberof cat
         * @classdesc Represents a ContentMsg.
         * @implements IContentMsg
         * @constructor
         * @param {cat.IContentMsg=} [p] Properties to set
         */
        function ContentMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentMsg type.
         * @member {cat.ContentType} type
         * @memberof cat.ContentMsg
         * @instance
         */
        ContentMsg.prototype.type = 0;

        /**
         * ContentMsg value.
         * @member {string} value
         * @memberof cat.ContentMsg
         * @instance
         */
        ContentMsg.prototype.value = "";

        /**
         * Creates a new ContentMsg instance using the specified properties.
         * @function create
         * @memberof cat.ContentMsg
         * @static
         * @param {cat.IContentMsg=} [properties] Properties to set
         * @returns {cat.ContentMsg} ContentMsg instance
         */
        ContentMsg.create = function create(properties) {
            return new ContentMsg(properties);
        };

        /**
         * Encodes the specified ContentMsg message. Does not implicitly {@link cat.ContentMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ContentMsg
         * @static
         * @param {cat.IContentMsg} m ContentMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(18).string(m.value);
            return w;
        };

        /**
         * Decodes a ContentMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ContentMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ContentMsg} ContentMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ContentMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.type = r.int32();
                        break;
                    }
                case 2: {
                        m.value = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ContentMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ContentMsg} ContentMsg
         */
        ContentMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ContentMsg)
                return d;
            var m = new $root.cat.ContentMsg();
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "CONTENTTYPE_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "CONTENTTYPE_TEXT":
            case 1:
                m.type = 1;
                break;
            case "CONTENTTYPE_CUSTOMEMOJI":
            case 2:
                m.type = 2;
                break;
            }
            if (d.value != null) {
                m.value = String(d.value);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ContentMsg
         * @static
         * @param {cat.ContentMsg} m ContentMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = o.enums === String ? "CONTENTTYPE_UNKNOWN" : 0;
                d.value = "";
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.ContentType[m.type] === undefined ? m.type : $root.cat.ContentType[m.type] : m.type;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            return d;
        };

        /**
         * Converts this ContentMsg to JSON.
         * @function toJSON
         * @memberof cat.ContentMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ContentMsg
         * @function getTypeUrl
         * @memberof cat.ContentMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ContentMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ContentMsg";
        };

        return ContentMsg;
    })();

    cat.AttachmentMsg = (function() {

        /**
         * Properties of an AttachmentMsg.
         * @memberof cat
         * @interface IAttachmentMsg
         * @property {string|null} [id] AttachmentMsg id
         * @property {cat.AttachmentType|null} [type] AttachmentMsg type
         * @property {number|null} [created] AttachmentMsg created
         * @property {number|null} [lastmodified] AttachmentMsg lastmodified
         * @property {Object.<string,string>|null} [content] AttachmentMsg content
         * @property {cat.IRawMsg|null} [raw] AttachmentMsg raw
         * @property {string|null} [externid] AttachmentMsg externid
         * @property {string|null} [name] AttachmentMsg name
         * @property {cat.AttachmentStatus|null} [status] AttachmentMsg status
         * @property {cat.IMediaMsg|null} [media] AttachmentMsg media
         * @property {Array.<cat.IMediaFileMsg>|null} [files] AttachmentMsg files
         * @property {string|null} [accountrefid] AttachmentMsg accountrefid
         */

        /**
         * Constructs a new AttachmentMsg.
         * @memberof cat
         * @classdesc Represents an AttachmentMsg.
         * @implements IAttachmentMsg
         * @constructor
         * @param {cat.IAttachmentMsg=} [p] Properties to set
         */
        function AttachmentMsg(p) {
            this.content = {};
            this.files = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AttachmentMsg id.
         * @member {string} id
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.id = "";

        /**
         * AttachmentMsg type.
         * @member {cat.AttachmentType|null|undefined} type
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.type = null;

        /**
         * AttachmentMsg created.
         * @member {number} created
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AttachmentMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AttachmentMsg content.
         * @member {Object.<string,string>} content
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.content = $util.emptyObject;

        /**
         * AttachmentMsg raw.
         * @member {cat.IRawMsg|null|undefined} raw
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.raw = null;

        /**
         * AttachmentMsg externid.
         * @member {string|null|undefined} externid
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.externid = null;

        /**
         * AttachmentMsg name.
         * @member {string|null|undefined} name
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.name = null;

        /**
         * AttachmentMsg status.
         * @member {cat.AttachmentStatus} status
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.status = 0;

        /**
         * AttachmentMsg media.
         * @member {cat.IMediaMsg|null|undefined} media
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.media = null;

        /**
         * AttachmentMsg files.
         * @member {Array.<cat.IMediaFileMsg>} files
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.files = $util.emptyArray;

        /**
         * AttachmentMsg accountrefid.
         * @member {string} accountrefid
         * @memberof cat.AttachmentMsg
         * @instance
         */
        AttachmentMsg.prototype.accountrefid = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AttachmentMsg.prototype, "_type", {
            get: $util.oneOfGetter($oneOfFields = ["type"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AttachmentMsg.prototype, "_raw", {
            get: $util.oneOfGetter($oneOfFields = ["raw"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AttachmentMsg.prototype, "_externid", {
            get: $util.oneOfGetter($oneOfFields = ["externid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AttachmentMsg.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AttachmentMsg instance using the specified properties.
         * @function create
         * @memberof cat.AttachmentMsg
         * @static
         * @param {cat.IAttachmentMsg=} [properties] Properties to set
         * @returns {cat.AttachmentMsg} AttachmentMsg instance
         */
        AttachmentMsg.create = function create(properties) {
            return new AttachmentMsg(properties);
        };

        /**
         * Encodes the specified AttachmentMsg message. Does not implicitly {@link cat.AttachmentMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.AttachmentMsg
         * @static
         * @param {cat.IAttachmentMsg} m AttachmentMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttachmentMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(24).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(32).uint64(m.lastmodified);
            if (m.content != null && Object.hasOwnProperty.call(m, "content")) {
                for (var ks = Object.keys(m.content), i = 0; i < ks.length; ++i) {
                    w.uint32(42).fork().uint32(10).string(ks[i]).uint32(18).string(m.content[ks[i]]).ldelim();
                }
            }
            if (m.raw != null && Object.hasOwnProperty.call(m, "raw"))
                $root.cat.RawMsg.encode(m.raw, w.uint32(58).fork()).ldelim();
            if (m.externid != null && Object.hasOwnProperty.call(m, "externid"))
                w.uint32(66).string(m.externid);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(74).string(m.name);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(808).int32(m.status);
            if (m.media != null && Object.hasOwnProperty.call(m, "media"))
                $root.cat.MediaMsg.encode(m.media, w.uint32(818).fork()).ldelim();
            if (m.files != null && m.files.length) {
                for (var i = 0; i < m.files.length; ++i)
                    $root.cat.MediaFileMsg.encode(m.files[i], w.uint32(826).fork()).ldelim();
            }
            if (m.accountrefid != null && Object.hasOwnProperty.call(m, "accountrefid"))
                w.uint32(834).string(m.accountrefid);
            return w;
        };

        /**
         * Decodes an AttachmentMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.AttachmentMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.AttachmentMsg} AttachmentMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttachmentMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.AttachmentMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.type = r.int32();
                        break;
                    }
                case 3: {
                        m.created = r.uint64();
                        break;
                    }
                case 4: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 5: {
                        if (m.content === $util.emptyObject)
                            m.content = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.content[k] = value;
                        break;
                    }
                case 7: {
                        m.raw = $root.cat.RawMsg.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        m.externid = r.string();
                        break;
                    }
                case 9: {
                        m.name = r.string();
                        break;
                    }
                case 101: {
                        m.status = r.int32();
                        break;
                    }
                case 102: {
                        m.media = $root.cat.MediaMsg.decode(r, r.uint32());
                        break;
                    }
                case 103: {
                        if (!(m.files && m.files.length))
                            m.files = [];
                        m.files.push($root.cat.MediaFileMsg.decode(r, r.uint32()));
                        break;
                    }
                case 104: {
                        m.accountrefid = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AttachmentMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.AttachmentMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.AttachmentMsg} AttachmentMsg
         */
        AttachmentMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.AttachmentMsg)
                return d;
            var m = new $root.cat.AttachmentMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "ATTACHMENT_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "ATTACHMENT_PHOTO":
            case 1:
                m.type = 1;
                break;
            case "ATTACHMENT_VIDEO":
            case 2:
                m.type = 2;
                break;
            case "ATTACHMENT_AUDIO":
            case 3:
                m.type = 3;
                break;
            case "ATTACHMENT_ANIMATION":
            case 4:
                m.type = 4;
                break;
            case "ATTACHMENT_DOCUMENT":
            case 5:
                m.type = 5;
                break;
            case "ATTACHMENT_LOCATION":
            case 6:
                m.type = 6;
                break;
            case "ATTACHMENT_CONTACT":
            case 7:
                m.type = 7;
                break;
            case "ATTACHMENT_STICKER":
            case 8:
                m.type = 8;
                break;
            case "ATTACHMENT_ARCHIVE":
            case 9:
                m.type = 9;
                break;
            case "ATTACHMENT_TGS":
            case 10:
                m.type = 10;
                break;
            case "ATTACHMENT_EMOJI":
            case 11:
                m.type = 11;
                break;
            case "ATTACHMENT_EMBED":
            case 12:
                m.type = 12;
                break;
            case "ATTACHMENT_INVITE":
            case 13:
                m.type = 13;
                break;
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.content) {
                if (typeof d.content !== "object")
                    throw TypeError(".cat.AttachmentMsg.content: object expected");
                m.content = {};
                for (var ks = Object.keys(d.content), i = 0; i < ks.length; ++i) {
                    m.content[ks[i]] = String(d.content[ks[i]]);
                }
            }
            if (d.raw != null) {
                if (typeof d.raw !== "object")
                    throw TypeError(".cat.AttachmentMsg.raw: object expected");
                m.raw = $root.cat.RawMsg.fromObject(d.raw);
            }
            if (d.externid != null) {
                m.externid = String(d.externid);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "ATTACHMENTSTATUS_UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ATTACHMENTSTATUS_PENDING":
            case 1:
                m.status = 1;
                break;
            case "ATTACHMENTSTATUS_COMPLETE":
            case 2:
                m.status = 2;
                break;
            case "ATTACHMENTSTATUS_FAILED":
            case 3:
                m.status = 3;
                break;
            case "ATTACHMENTSTATUS_EXPIRED":
            case 4:
                m.status = 4;
                break;
            }
            if (d.media != null) {
                if (typeof d.media !== "object")
                    throw TypeError(".cat.AttachmentMsg.media: object expected");
                m.media = $root.cat.MediaMsg.fromObject(d.media);
            }
            if (d.files) {
                if (!Array.isArray(d.files))
                    throw TypeError(".cat.AttachmentMsg.files: array expected");
                m.files = [];
                for (var i = 0; i < d.files.length; ++i) {
                    if (typeof d.files[i] !== "object")
                        throw TypeError(".cat.AttachmentMsg.files: object expected");
                    m.files[i] = $root.cat.MediaFileMsg.fromObject(d.files[i]);
                }
            }
            if (d.accountrefid != null) {
                m.accountrefid = String(d.accountrefid);
            }
            return m;
        };

        /**
         * Creates a plain object from an AttachmentMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.AttachmentMsg
         * @static
         * @param {cat.AttachmentMsg} m AttachmentMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AttachmentMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.files = [];
            }
            if (o.objects || o.defaults) {
                d.content = {};
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.status = o.enums === String ? "ATTACHMENTSTATUS_UNKNOWN" : 0;
                d.media = null;
                d.accountrefid = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.AttachmentType[m.type] === undefined ? m.type : $root.cat.AttachmentType[m.type] : m.type;
                if (o.oneofs)
                    d._type = "type";
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            var ks2;
            if (m.content && (ks2 = Object.keys(m.content)).length) {
                d.content = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.content[ks2[j]] = m.content[ks2[j]];
                }
            }
            if (m.raw != null && m.hasOwnProperty("raw")) {
                d.raw = $root.cat.RawMsg.toObject(m.raw, o);
                if (o.oneofs)
                    d._raw = "raw";
            }
            if (m.externid != null && m.hasOwnProperty("externid")) {
                d.externid = m.externid;
                if (o.oneofs)
                    d._externid = "externid";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
                if (o.oneofs)
                    d._name = "name";
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.AttachmentStatus[m.status] === undefined ? m.status : $root.cat.AttachmentStatus[m.status] : m.status;
            }
            if (m.media != null && m.hasOwnProperty("media")) {
                d.media = $root.cat.MediaMsg.toObject(m.media, o);
            }
            if (m.files && m.files.length) {
                d.files = [];
                for (var j = 0; j < m.files.length; ++j) {
                    d.files[j] = $root.cat.MediaFileMsg.toObject(m.files[j], o);
                }
            }
            if (m.accountrefid != null && m.hasOwnProperty("accountrefid")) {
                d.accountrefid = m.accountrefid;
            }
            return d;
        };

        /**
         * Converts this AttachmentMsg to JSON.
         * @function toJSON
         * @memberof cat.AttachmentMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AttachmentMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AttachmentMsg
         * @function getTypeUrl
         * @memberof cat.AttachmentMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AttachmentMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.AttachmentMsg";
        };

        return AttachmentMsg;
    })();

    /**
     * ThumbnailStatus enum.
     * @name cat.ThumbnailStatus
     * @enum {number}
     * @property {number} THUMBNAILSTATUS_UNKNOWN=0 THUMBNAILSTATUS_UNKNOWN value
     * @property {number} THUMBNAILSTATUS_PENDING=1 THUMBNAILSTATUS_PENDING value
     * @property {number} THUMBNAILSTATUS_COMPLETE=2 THUMBNAILSTATUS_COMPLETE value
     * @property {number} THUMBNAILSTATUS_SKIPPED=3 THUMBNAILSTATUS_SKIPPED value
     * @property {number} THUMBNAILSTATUS_FAILED=4 THUMBNAILSTATUS_FAILED value
     */
    cat.ThumbnailStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "THUMBNAILSTATUS_UNKNOWN"] = 0;
        values[valuesById[1] = "THUMBNAILSTATUS_PENDING"] = 1;
        values[valuesById[2] = "THUMBNAILSTATUS_COMPLETE"] = 2;
        values[valuesById[3] = "THUMBNAILSTATUS_SKIPPED"] = 3;
        values[valuesById[4] = "THUMBNAILSTATUS_FAILED"] = 4;
        return values;
    })();

    cat.MediaFileMsg = (function() {

        /**
         * Properties of a MediaFileMsg.
         * @memberof cat
         * @interface IMediaFileMsg
         * @property {string|null} [id] MediaFileMsg id
         * @property {number|null} [created] MediaFileMsg created
         * @property {number|null} [lastmodified] MediaFileMsg lastmodified
         * @property {string|null} [externid] MediaFileMsg externid
         * @property {string|null} [fileid] MediaFileMsg fileid
         * @property {number|null} [filesize] MediaFileMsg filesize
         * @property {string|null} [filename] MediaFileMsg filename
         * @property {string|null} [mimetype] MediaFileMsg mimetype
         * @property {Object.<string,string>|null} [filehashes] MediaFileMsg filehashes
         * @property {string|null} [filekey] MediaFileMsg filekey
         * @property {string|null} [thumbfileid] MediaFileMsg thumbfileid
         * @property {string|null} [thumbmimetype] MediaFileMsg thumbmimetype
         * @property {cat.ThumbnailStatus|null} [thumbnailstatus] MediaFileMsg thumbnailstatus
         * @property {string|null} [thumbfilekey] MediaFileMsg thumbfilekey
         * @property {string|null} [info] MediaFileMsg info
         * @property {string|null} [timestamputc] MediaFileMsg timestamputc
         * @property {string|null} [origin] MediaFileMsg origin
         * @property {number|null} [tsatimestamp] MediaFileMsg tsatimestamp
         * @property {string|null} [tsasignature] MediaFileMsg tsasignature
         * @property {cat.ReceiverMediaType|null} [receivermediatype] MediaFileMsg receivermediatype
         * @property {cat.ReceiverMediaLinkStatus|null} [linkstatus] MediaFileMsg linkstatus
         * @property {cat.IMediaMsg|null} [media] MediaFileMsg media
         * @property {string|null} [apprefid] MediaFileMsg apprefid
         * @property {string|null} [accountrefid] MediaFileMsg accountrefid
         * @property {string|null} [reportrefid] MediaFileMsg reportrefid
         * @property {string|null} [messagerefid] MediaFileMsg messagerefid
         */

        /**
         * Constructs a new MediaFileMsg.
         * @memberof cat
         * @classdesc Represents a MediaFileMsg.
         * @implements IMediaFileMsg
         * @constructor
         * @param {cat.IMediaFileMsg=} [p] Properties to set
         */
        function MediaFileMsg(p) {
            this.filehashes = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * MediaFileMsg id.
         * @member {string} id
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.id = "";

        /**
         * MediaFileMsg created.
         * @member {number} created
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MediaFileMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MediaFileMsg externid.
         * @member {string} externid
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.externid = "";

        /**
         * MediaFileMsg fileid.
         * @member {string|null|undefined} fileid
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.fileid = null;

        /**
         * MediaFileMsg filesize.
         * @member {number|null|undefined} filesize
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.filesize = null;

        /**
         * MediaFileMsg filename.
         * @member {string|null|undefined} filename
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.filename = null;

        /**
         * MediaFileMsg mimetype.
         * @member {string|null|undefined} mimetype
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.mimetype = null;

        /**
         * MediaFileMsg filehashes.
         * @member {Object.<string,string>} filehashes
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.filehashes = $util.emptyObject;

        /**
         * MediaFileMsg filekey.
         * @member {string|null|undefined} filekey
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.filekey = null;

        /**
         * MediaFileMsg thumbfileid.
         * @member {string|null|undefined} thumbfileid
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.thumbfileid = null;

        /**
         * MediaFileMsg thumbmimetype.
         * @member {string|null|undefined} thumbmimetype
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.thumbmimetype = null;

        /**
         * MediaFileMsg thumbnailstatus.
         * @member {cat.ThumbnailStatus} thumbnailstatus
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.thumbnailstatus = 0;

        /**
         * MediaFileMsg thumbfilekey.
         * @member {string|null|undefined} thumbfilekey
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.thumbfilekey = null;

        /**
         * MediaFileMsg info.
         * @member {string|null|undefined} info
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.info = null;

        /**
         * MediaFileMsg timestamputc.
         * @member {string|null|undefined} timestamputc
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.timestamputc = null;

        /**
         * MediaFileMsg origin.
         * @member {string|null|undefined} origin
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.origin = null;

        /**
         * MediaFileMsg tsatimestamp.
         * @member {number} tsatimestamp
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.tsatimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MediaFileMsg tsasignature.
         * @member {string} tsasignature
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.tsasignature = "";

        /**
         * MediaFileMsg receivermediatype.
         * @member {cat.ReceiverMediaType} receivermediatype
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.receivermediatype = 0;

        /**
         * MediaFileMsg linkstatus.
         * @member {cat.ReceiverMediaLinkStatus} linkstatus
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.linkstatus = 0;

        /**
         * MediaFileMsg media.
         * @member {cat.IMediaMsg|null|undefined} media
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.media = null;

        /**
         * MediaFileMsg apprefid.
         * @member {string} apprefid
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.apprefid = "";

        /**
         * MediaFileMsg accountrefid.
         * @member {string} accountrefid
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.accountrefid = "";

        /**
         * MediaFileMsg reportrefid.
         * @member {string} reportrefid
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.reportrefid = "";

        /**
         * MediaFileMsg messagerefid.
         * @member {string} messagerefid
         * @memberof cat.MediaFileMsg
         * @instance
         */
        MediaFileMsg.prototype.messagerefid = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_fileid", {
            get: $util.oneOfGetter($oneOfFields = ["fileid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_filesize", {
            get: $util.oneOfGetter($oneOfFields = ["filesize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_filename", {
            get: $util.oneOfGetter($oneOfFields = ["filename"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_mimetype", {
            get: $util.oneOfGetter($oneOfFields = ["mimetype"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_filekey", {
            get: $util.oneOfGetter($oneOfFields = ["filekey"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_thumbfileid", {
            get: $util.oneOfGetter($oneOfFields = ["thumbfileid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_thumbmimetype", {
            get: $util.oneOfGetter($oneOfFields = ["thumbmimetype"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_thumbfilekey", {
            get: $util.oneOfGetter($oneOfFields = ["thumbfilekey"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_info", {
            get: $util.oneOfGetter($oneOfFields = ["info"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_timestamputc", {
            get: $util.oneOfGetter($oneOfFields = ["timestamputc"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaFileMsg.prototype, "_origin", {
            get: $util.oneOfGetter($oneOfFields = ["origin"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MediaFileMsg instance using the specified properties.
         * @function create
         * @memberof cat.MediaFileMsg
         * @static
         * @param {cat.IMediaFileMsg=} [properties] Properties to set
         * @returns {cat.MediaFileMsg} MediaFileMsg instance
         */
        MediaFileMsg.create = function create(properties) {
            return new MediaFileMsg(properties);
        };

        /**
         * Encodes the specified MediaFileMsg message. Does not implicitly {@link cat.MediaFileMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.MediaFileMsg
         * @static
         * @param {cat.IMediaFileMsg} m MediaFileMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MediaFileMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(16).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(24).uint64(m.lastmodified);
            if (m.externid != null && Object.hasOwnProperty.call(m, "externid"))
                w.uint32(34).string(m.externid);
            if (m.fileid != null && Object.hasOwnProperty.call(m, "fileid"))
                w.uint32(42).string(m.fileid);
            if (m.filesize != null && Object.hasOwnProperty.call(m, "filesize"))
                w.uint32(48).uint64(m.filesize);
            if (m.filename != null && Object.hasOwnProperty.call(m, "filename"))
                w.uint32(58).string(m.filename);
            if (m.mimetype != null && Object.hasOwnProperty.call(m, "mimetype"))
                w.uint32(66).string(m.mimetype);
            if (m.filehashes != null && Object.hasOwnProperty.call(m, "filehashes")) {
                for (var ks = Object.keys(m.filehashes), i = 0; i < ks.length; ++i) {
                    w.uint32(74).fork().uint32(10).string(ks[i]).uint32(18).string(m.filehashes[ks[i]]).ldelim();
                }
            }
            if (m.filekey != null && Object.hasOwnProperty.call(m, "filekey"))
                w.uint32(82).string(m.filekey);
            if (m.thumbfileid != null && Object.hasOwnProperty.call(m, "thumbfileid"))
                w.uint32(90).string(m.thumbfileid);
            if (m.thumbmimetype != null && Object.hasOwnProperty.call(m, "thumbmimetype"))
                w.uint32(98).string(m.thumbmimetype);
            if (m.thumbnailstatus != null && Object.hasOwnProperty.call(m, "thumbnailstatus"))
                w.uint32(104).int32(m.thumbnailstatus);
            if (m.thumbfilekey != null && Object.hasOwnProperty.call(m, "thumbfilekey"))
                w.uint32(114).string(m.thumbfilekey);
            if (m.info != null && Object.hasOwnProperty.call(m, "info"))
                w.uint32(122).string(m.info);
            if (m.timestamputc != null && Object.hasOwnProperty.call(m, "timestamputc"))
                w.uint32(138).string(m.timestamputc);
            if (m.origin != null && Object.hasOwnProperty.call(m, "origin"))
                w.uint32(146).string(m.origin);
            if (m.tsatimestamp != null && Object.hasOwnProperty.call(m, "tsatimestamp"))
                w.uint32(152).uint64(m.tsatimestamp);
            if (m.tsasignature != null && Object.hasOwnProperty.call(m, "tsasignature"))
                w.uint32(162).string(m.tsasignature);
            if (m.receivermediatype != null && Object.hasOwnProperty.call(m, "receivermediatype"))
                w.uint32(808).int32(m.receivermediatype);
            if (m.linkstatus != null && Object.hasOwnProperty.call(m, "linkstatus"))
                w.uint32(816).int32(m.linkstatus);
            if (m.media != null && Object.hasOwnProperty.call(m, "media"))
                $root.cat.MediaMsg.encode(m.media, w.uint32(826).fork()).ldelim();
            if (m.apprefid != null && Object.hasOwnProperty.call(m, "apprefid"))
                w.uint32(834).string(m.apprefid);
            if (m.accountrefid != null && Object.hasOwnProperty.call(m, "accountrefid"))
                w.uint32(842).string(m.accountrefid);
            if (m.reportrefid != null && Object.hasOwnProperty.call(m, "reportrefid"))
                w.uint32(850).string(m.reportrefid);
            if (m.messagerefid != null && Object.hasOwnProperty.call(m, "messagerefid"))
                w.uint32(858).string(m.messagerefid);
            return w;
        };

        /**
         * Decodes a MediaFileMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.MediaFileMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.MediaFileMsg} MediaFileMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MediaFileMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.MediaFileMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.created = r.uint64();
                        break;
                    }
                case 3: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 4: {
                        m.externid = r.string();
                        break;
                    }
                case 5: {
                        m.fileid = r.string();
                        break;
                    }
                case 6: {
                        m.filesize = r.uint64();
                        break;
                    }
                case 7: {
                        m.filename = r.string();
                        break;
                    }
                case 8: {
                        m.mimetype = r.string();
                        break;
                    }
                case 9: {
                        if (m.filehashes === $util.emptyObject)
                            m.filehashes = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.filehashes[k] = value;
                        break;
                    }
                case 10: {
                        m.filekey = r.string();
                        break;
                    }
                case 11: {
                        m.thumbfileid = r.string();
                        break;
                    }
                case 12: {
                        m.thumbmimetype = r.string();
                        break;
                    }
                case 13: {
                        m.thumbnailstatus = r.int32();
                        break;
                    }
                case 14: {
                        m.thumbfilekey = r.string();
                        break;
                    }
                case 15: {
                        m.info = r.string();
                        break;
                    }
                case 17: {
                        m.timestamputc = r.string();
                        break;
                    }
                case 18: {
                        m.origin = r.string();
                        break;
                    }
                case 19: {
                        m.tsatimestamp = r.uint64();
                        break;
                    }
                case 20: {
                        m.tsasignature = r.string();
                        break;
                    }
                case 101: {
                        m.receivermediatype = r.int32();
                        break;
                    }
                case 102: {
                        m.linkstatus = r.int32();
                        break;
                    }
                case 103: {
                        m.media = $root.cat.MediaMsg.decode(r, r.uint32());
                        break;
                    }
                case 104: {
                        m.apprefid = r.string();
                        break;
                    }
                case 105: {
                        m.accountrefid = r.string();
                        break;
                    }
                case 106: {
                        m.reportrefid = r.string();
                        break;
                    }
                case 107: {
                        m.messagerefid = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a MediaFileMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.MediaFileMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.MediaFileMsg} MediaFileMsg
         */
        MediaFileMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.MediaFileMsg)
                return d;
            var m = new $root.cat.MediaFileMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.externid != null) {
                m.externid = String(d.externid);
            }
            if (d.fileid != null) {
                m.fileid = String(d.fileid);
            }
            if (d.filesize != null) {
                if ($util.Long)
                    (m.filesize = $util.Long.fromValue(d.filesize)).unsigned = true;
                else if (typeof d.filesize === "string")
                    m.filesize = parseInt(d.filesize, 10);
                else if (typeof d.filesize === "number")
                    m.filesize = d.filesize;
                else if (typeof d.filesize === "object")
                    m.filesize = new $util.LongBits(d.filesize.low >>> 0, d.filesize.high >>> 0).toNumber(true);
            }
            if (d.filename != null) {
                m.filename = String(d.filename);
            }
            if (d.mimetype != null) {
                m.mimetype = String(d.mimetype);
            }
            if (d.filehashes) {
                if (typeof d.filehashes !== "object")
                    throw TypeError(".cat.MediaFileMsg.filehashes: object expected");
                m.filehashes = {};
                for (var ks = Object.keys(d.filehashes), i = 0; i < ks.length; ++i) {
                    m.filehashes[ks[i]] = String(d.filehashes[ks[i]]);
                }
            }
            if (d.filekey != null) {
                m.filekey = String(d.filekey);
            }
            if (d.thumbfileid != null) {
                m.thumbfileid = String(d.thumbfileid);
            }
            if (d.thumbmimetype != null) {
                m.thumbmimetype = String(d.thumbmimetype);
            }
            switch (d.thumbnailstatus) {
            default:
                if (typeof d.thumbnailstatus === "number") {
                    m.thumbnailstatus = d.thumbnailstatus;
                    break;
                }
                break;
            case "THUMBNAILSTATUS_UNKNOWN":
            case 0:
                m.thumbnailstatus = 0;
                break;
            case "THUMBNAILSTATUS_PENDING":
            case 1:
                m.thumbnailstatus = 1;
                break;
            case "THUMBNAILSTATUS_COMPLETE":
            case 2:
                m.thumbnailstatus = 2;
                break;
            case "THUMBNAILSTATUS_SKIPPED":
            case 3:
                m.thumbnailstatus = 3;
                break;
            case "THUMBNAILSTATUS_FAILED":
            case 4:
                m.thumbnailstatus = 4;
                break;
            }
            if (d.thumbfilekey != null) {
                m.thumbfilekey = String(d.thumbfilekey);
            }
            if (d.info != null) {
                m.info = String(d.info);
            }
            if (d.timestamputc != null) {
                m.timestamputc = String(d.timestamputc);
            }
            if (d.origin != null) {
                m.origin = String(d.origin);
            }
            if (d.tsatimestamp != null) {
                if ($util.Long)
                    (m.tsatimestamp = $util.Long.fromValue(d.tsatimestamp)).unsigned = true;
                else if (typeof d.tsatimestamp === "string")
                    m.tsatimestamp = parseInt(d.tsatimestamp, 10);
                else if (typeof d.tsatimestamp === "number")
                    m.tsatimestamp = d.tsatimestamp;
                else if (typeof d.tsatimestamp === "object")
                    m.tsatimestamp = new $util.LongBits(d.tsatimestamp.low >>> 0, d.tsatimestamp.high >>> 0).toNumber(true);
            }
            if (d.tsasignature != null) {
                m.tsasignature = String(d.tsasignature);
            }
            switch (d.receivermediatype) {
            default:
                if (typeof d.receivermediatype === "number") {
                    m.receivermediatype = d.receivermediatype;
                    break;
                }
                break;
            case "RECEIVERMEDIA_UNKNOWN":
            case 0:
                m.receivermediatype = 0;
                break;
            case "RECEIVERMEDIA_LINKABLE":
            case 1:
                m.receivermediatype = 1;
                break;
            case "RECEIVERMEDIA_SOURCE":
            case 2:
                m.receivermediatype = 2;
                break;
            case "RECEIVERMEDIA_FILTERED":
            case 3:
                m.receivermediatype = 3;
                break;
            }
            switch (d.linkstatus) {
            default:
                if (typeof d.linkstatus === "number") {
                    m.linkstatus = d.linkstatus;
                    break;
                }
                break;
            case "RECEIVERMEDIALINKSTATUS_UNKNOWN":
            case 0:
                m.linkstatus = 0;
                break;
            case "RECEIVERMEDIALINKSTATUS_LINKED":
            case 1:
                m.linkstatus = 1;
                break;
            case "RECEIVERMEDIALINKSTATUS_UNLINKED":
            case 2:
                m.linkstatus = 2;
                break;
            }
            if (d.media != null) {
                if (typeof d.media !== "object")
                    throw TypeError(".cat.MediaFileMsg.media: object expected");
                m.media = $root.cat.MediaMsg.fromObject(d.media);
            }
            if (d.apprefid != null) {
                m.apprefid = String(d.apprefid);
            }
            if (d.accountrefid != null) {
                m.accountrefid = String(d.accountrefid);
            }
            if (d.reportrefid != null) {
                m.reportrefid = String(d.reportrefid);
            }
            if (d.messagerefid != null) {
                m.messagerefid = String(d.messagerefid);
            }
            return m;
        };

        /**
         * Creates a plain object from a MediaFileMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.MediaFileMsg
         * @static
         * @param {cat.MediaFileMsg} m MediaFileMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MediaFileMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.objects || o.defaults) {
                d.filehashes = {};
            }
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.externid = "";
                d.thumbnailstatus = o.enums === String ? "THUMBNAILSTATUS_UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.tsatimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.tsatimestamp = o.longs === String ? "0" : 0;
                d.tsasignature = "";
                d.receivermediatype = o.enums === String ? "RECEIVERMEDIA_UNKNOWN" : 0;
                d.linkstatus = o.enums === String ? "RECEIVERMEDIALINKSTATUS_UNKNOWN" : 0;
                d.media = null;
                d.apprefid = "";
                d.accountrefid = "";
                d.reportrefid = "";
                d.messagerefid = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.externid != null && m.hasOwnProperty("externid")) {
                d.externid = m.externid;
            }
            if (m.fileid != null && m.hasOwnProperty("fileid")) {
                d.fileid = m.fileid;
                if (o.oneofs)
                    d._fileid = "fileid";
            }
            if (m.filesize != null && m.hasOwnProperty("filesize")) {
                if (typeof m.filesize === "number")
                    d.filesize = o.longs === String ? String(m.filesize) : m.filesize;
                else
                    d.filesize = o.longs === String ? $util.Long.prototype.toString.call(m.filesize) : o.longs === Number ? new $util.LongBits(m.filesize.low >>> 0, m.filesize.high >>> 0).toNumber(true) : m.filesize;
                if (o.oneofs)
                    d._filesize = "filesize";
            }
            if (m.filename != null && m.hasOwnProperty("filename")) {
                d.filename = m.filename;
                if (o.oneofs)
                    d._filename = "filename";
            }
            if (m.mimetype != null && m.hasOwnProperty("mimetype")) {
                d.mimetype = m.mimetype;
                if (o.oneofs)
                    d._mimetype = "mimetype";
            }
            var ks2;
            if (m.filehashes && (ks2 = Object.keys(m.filehashes)).length) {
                d.filehashes = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.filehashes[ks2[j]] = m.filehashes[ks2[j]];
                }
            }
            if (m.filekey != null && m.hasOwnProperty("filekey")) {
                d.filekey = m.filekey;
                if (o.oneofs)
                    d._filekey = "filekey";
            }
            if (m.thumbfileid != null && m.hasOwnProperty("thumbfileid")) {
                d.thumbfileid = m.thumbfileid;
                if (o.oneofs)
                    d._thumbfileid = "thumbfileid";
            }
            if (m.thumbmimetype != null && m.hasOwnProperty("thumbmimetype")) {
                d.thumbmimetype = m.thumbmimetype;
                if (o.oneofs)
                    d._thumbmimetype = "thumbmimetype";
            }
            if (m.thumbnailstatus != null && m.hasOwnProperty("thumbnailstatus")) {
                d.thumbnailstatus = o.enums === String ? $root.cat.ThumbnailStatus[m.thumbnailstatus] === undefined ? m.thumbnailstatus : $root.cat.ThumbnailStatus[m.thumbnailstatus] : m.thumbnailstatus;
            }
            if (m.thumbfilekey != null && m.hasOwnProperty("thumbfilekey")) {
                d.thumbfilekey = m.thumbfilekey;
                if (o.oneofs)
                    d._thumbfilekey = "thumbfilekey";
            }
            if (m.info != null && m.hasOwnProperty("info")) {
                d.info = m.info;
                if (o.oneofs)
                    d._info = "info";
            }
            if (m.timestamputc != null && m.hasOwnProperty("timestamputc")) {
                d.timestamputc = m.timestamputc;
                if (o.oneofs)
                    d._timestamputc = "timestamputc";
            }
            if (m.origin != null && m.hasOwnProperty("origin")) {
                d.origin = m.origin;
                if (o.oneofs)
                    d._origin = "origin";
            }
            if (m.tsatimestamp != null && m.hasOwnProperty("tsatimestamp")) {
                if (typeof m.tsatimestamp === "number")
                    d.tsatimestamp = o.longs === String ? String(m.tsatimestamp) : m.tsatimestamp;
                else
                    d.tsatimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.tsatimestamp) : o.longs === Number ? new $util.LongBits(m.tsatimestamp.low >>> 0, m.tsatimestamp.high >>> 0).toNumber(true) : m.tsatimestamp;
            }
            if (m.tsasignature != null && m.hasOwnProperty("tsasignature")) {
                d.tsasignature = m.tsasignature;
            }
            if (m.receivermediatype != null && m.hasOwnProperty("receivermediatype")) {
                d.receivermediatype = o.enums === String ? $root.cat.ReceiverMediaType[m.receivermediatype] === undefined ? m.receivermediatype : $root.cat.ReceiverMediaType[m.receivermediatype] : m.receivermediatype;
            }
            if (m.linkstatus != null && m.hasOwnProperty("linkstatus")) {
                d.linkstatus = o.enums === String ? $root.cat.ReceiverMediaLinkStatus[m.linkstatus] === undefined ? m.linkstatus : $root.cat.ReceiverMediaLinkStatus[m.linkstatus] : m.linkstatus;
            }
            if (m.media != null && m.hasOwnProperty("media")) {
                d.media = $root.cat.MediaMsg.toObject(m.media, o);
            }
            if (m.apprefid != null && m.hasOwnProperty("apprefid")) {
                d.apprefid = m.apprefid;
            }
            if (m.accountrefid != null && m.hasOwnProperty("accountrefid")) {
                d.accountrefid = m.accountrefid;
            }
            if (m.reportrefid != null && m.hasOwnProperty("reportrefid")) {
                d.reportrefid = m.reportrefid;
            }
            if (m.messagerefid != null && m.hasOwnProperty("messagerefid")) {
                d.messagerefid = m.messagerefid;
            }
            return d;
        };

        /**
         * Converts this MediaFileMsg to JSON.
         * @function toJSON
         * @memberof cat.MediaFileMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MediaFileMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MediaFileMsg
         * @function getTypeUrl
         * @memberof cat.MediaFileMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MediaFileMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.MediaFileMsg";
        };

        return MediaFileMsg;
    })();

    cat.ReceiverBufferStatusMsg = (function() {

        /**
         * Properties of a ReceiverBufferStatusMsg.
         * @memberof cat
         * @interface IReceiverBufferStatusMsg
         * @property {number|null} [items] ReceiverBufferStatusMsg items
         * @property {number|null} [files] ReceiverBufferStatusMsg files
         * @property {string|null} [packagename] ReceiverBufferStatusMsg packagename
         */

        /**
         * Constructs a new ReceiverBufferStatusMsg.
         * @memberof cat
         * @classdesc Represents a ReceiverBufferStatusMsg.
         * @implements IReceiverBufferStatusMsg
         * @constructor
         * @param {cat.IReceiverBufferStatusMsg=} [p] Properties to set
         */
        function ReceiverBufferStatusMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReceiverBufferStatusMsg items.
         * @member {number} items
         * @memberof cat.ReceiverBufferStatusMsg
         * @instance
         */
        ReceiverBufferStatusMsg.prototype.items = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReceiverBufferStatusMsg files.
         * @member {number} files
         * @memberof cat.ReceiverBufferStatusMsg
         * @instance
         */
        ReceiverBufferStatusMsg.prototype.files = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReceiverBufferStatusMsg packagename.
         * @member {string} packagename
         * @memberof cat.ReceiverBufferStatusMsg
         * @instance
         */
        ReceiverBufferStatusMsg.prototype.packagename = "";

        /**
         * Creates a new ReceiverBufferStatusMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReceiverBufferStatusMsg
         * @static
         * @param {cat.IReceiverBufferStatusMsg=} [properties] Properties to set
         * @returns {cat.ReceiverBufferStatusMsg} ReceiverBufferStatusMsg instance
         */
        ReceiverBufferStatusMsg.create = function create(properties) {
            return new ReceiverBufferStatusMsg(properties);
        };

        /**
         * Encodes the specified ReceiverBufferStatusMsg message. Does not implicitly {@link cat.ReceiverBufferStatusMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReceiverBufferStatusMsg
         * @static
         * @param {cat.IReceiverBufferStatusMsg} m ReceiverBufferStatusMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiverBufferStatusMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.items != null && Object.hasOwnProperty.call(m, "items"))
                w.uint32(8).uint64(m.items);
            if (m.files != null && Object.hasOwnProperty.call(m, "files"))
                w.uint32(16).uint64(m.files);
            if (m.packagename != null && Object.hasOwnProperty.call(m, "packagename"))
                w.uint32(26).string(m.packagename);
            return w;
        };

        /**
         * Decodes a ReceiverBufferStatusMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReceiverBufferStatusMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReceiverBufferStatusMsg} ReceiverBufferStatusMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiverBufferStatusMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReceiverBufferStatusMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.items = r.uint64();
                        break;
                    }
                case 2: {
                        m.files = r.uint64();
                        break;
                    }
                case 3: {
                        m.packagename = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReceiverBufferStatusMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReceiverBufferStatusMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReceiverBufferStatusMsg} ReceiverBufferStatusMsg
         */
        ReceiverBufferStatusMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReceiverBufferStatusMsg)
                return d;
            var m = new $root.cat.ReceiverBufferStatusMsg();
            if (d.items != null) {
                if ($util.Long)
                    (m.items = $util.Long.fromValue(d.items)).unsigned = true;
                else if (typeof d.items === "string")
                    m.items = parseInt(d.items, 10);
                else if (typeof d.items === "number")
                    m.items = d.items;
                else if (typeof d.items === "object")
                    m.items = new $util.LongBits(d.items.low >>> 0, d.items.high >>> 0).toNumber(true);
            }
            if (d.files != null) {
                if ($util.Long)
                    (m.files = $util.Long.fromValue(d.files)).unsigned = true;
                else if (typeof d.files === "string")
                    m.files = parseInt(d.files, 10);
                else if (typeof d.files === "number")
                    m.files = d.files;
                else if (typeof d.files === "object")
                    m.files = new $util.LongBits(d.files.low >>> 0, d.files.high >>> 0).toNumber(true);
            }
            if (d.packagename != null) {
                m.packagename = String(d.packagename);
            }
            return m;
        };

        /**
         * Creates a plain object from a ReceiverBufferStatusMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReceiverBufferStatusMsg
         * @static
         * @param {cat.ReceiverBufferStatusMsg} m ReceiverBufferStatusMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiverBufferStatusMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.items = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.items = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.files = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.files = o.longs === String ? "0" : 0;
                d.packagename = "";
            }
            if (m.items != null && m.hasOwnProperty("items")) {
                if (typeof m.items === "number")
                    d.items = o.longs === String ? String(m.items) : m.items;
                else
                    d.items = o.longs === String ? $util.Long.prototype.toString.call(m.items) : o.longs === Number ? new $util.LongBits(m.items.low >>> 0, m.items.high >>> 0).toNumber(true) : m.items;
            }
            if (m.files != null && m.hasOwnProperty("files")) {
                if (typeof m.files === "number")
                    d.files = o.longs === String ? String(m.files) : m.files;
                else
                    d.files = o.longs === String ? $util.Long.prototype.toString.call(m.files) : o.longs === Number ? new $util.LongBits(m.files.low >>> 0, m.files.high >>> 0).toNumber(true) : m.files;
            }
            if (m.packagename != null && m.hasOwnProperty("packagename")) {
                d.packagename = m.packagename;
            }
            return d;
        };

        /**
         * Converts this ReceiverBufferStatusMsg to JSON.
         * @function toJSON
         * @memberof cat.ReceiverBufferStatusMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiverBufferStatusMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiverBufferStatusMsg
         * @function getTypeUrl
         * @memberof cat.ReceiverBufferStatusMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiverBufferStatusMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReceiverBufferStatusMsg";
        };

        return ReceiverBufferStatusMsg;
    })();

    /**
     * ReceiverMediaType enum.
     * @name cat.ReceiverMediaType
     * @enum {number}
     * @property {number} RECEIVERMEDIA_UNKNOWN=0 RECEIVERMEDIA_UNKNOWN value
     * @property {number} RECEIVERMEDIA_LINKABLE=1 RECEIVERMEDIA_LINKABLE value
     * @property {number} RECEIVERMEDIA_SOURCE=2 RECEIVERMEDIA_SOURCE value
     * @property {number} RECEIVERMEDIA_FILTERED=3 RECEIVERMEDIA_FILTERED value
     */
    cat.ReceiverMediaType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RECEIVERMEDIA_UNKNOWN"] = 0;
        values[valuesById[1] = "RECEIVERMEDIA_LINKABLE"] = 1;
        values[valuesById[2] = "RECEIVERMEDIA_SOURCE"] = 2;
        values[valuesById[3] = "RECEIVERMEDIA_FILTERED"] = 3;
        return values;
    })();

    /**
     * ReceiverMediaLinkStatus enum.
     * @name cat.ReceiverMediaLinkStatus
     * @enum {number}
     * @property {number} RECEIVERMEDIALINKSTATUS_UNKNOWN=0 RECEIVERMEDIALINKSTATUS_UNKNOWN value
     * @property {number} RECEIVERMEDIALINKSTATUS_LINKED=1 RECEIVERMEDIALINKSTATUS_LINKED value
     * @property {number} RECEIVERMEDIALINKSTATUS_UNLINKED=2 RECEIVERMEDIALINKSTATUS_UNLINKED value
     */
    cat.ReceiverMediaLinkStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RECEIVERMEDIALINKSTATUS_UNKNOWN"] = 0;
        values[valuesById[1] = "RECEIVERMEDIALINKSTATUS_LINKED"] = 1;
        values[valuesById[2] = "RECEIVERMEDIALINKSTATUS_UNLINKED"] = 2;
        return values;
    })();

    /**
     * MimeType enum.
     * @name cat.MimeType
     * @enum {number}
     * @property {number} MIMETYPE_UNKNOWN=0 MIMETYPE_UNKNOWN value
     * @property {number} MIMETYPE_IMAGE=1 MIMETYPE_IMAGE value
     * @property {number} MIMETYPE_VIDEO=2 MIMETYPE_VIDEO value
     * @property {number} MIMETYPE_AUDIO=3 MIMETYPE_AUDIO value
     * @property {number} MIMETYPE_TGS=4 MIMETYPE_TGS value
     * @property {number} MIMETYPE_OTHER=5 MIMETYPE_OTHER value
     */
    cat.MimeType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MIMETYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "MIMETYPE_IMAGE"] = 1;
        values[valuesById[2] = "MIMETYPE_VIDEO"] = 2;
        values[valuesById[3] = "MIMETYPE_AUDIO"] = 3;
        values[valuesById[4] = "MIMETYPE_TGS"] = 4;
        values[valuesById[5] = "MIMETYPE_OTHER"] = 5;
        return values;
    })();

    /**
     * ReceiverCallStatus enum.
     * @name cat.ReceiverCallStatus
     * @enum {number}
     * @property {number} CALLSTATUS_PENDING=0 CALLSTATUS_PENDING value
     * @property {number} CALLSTATUS_SUCCESS=1 CALLSTATUS_SUCCESS value
     * @property {number} CALLSTATUS_FAILED=2 CALLSTATUS_FAILED value
     */
    cat.ReceiverCallStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CALLSTATUS_PENDING"] = 0;
        values[valuesById[1] = "CALLSTATUS_SUCCESS"] = 1;
        values[valuesById[2] = "CALLSTATUS_FAILED"] = 2;
        return values;
    })();

    /**
     * ReceiverCallName enum.
     * @name cat.ReceiverCallName
     * @enum {number}
     * @property {number} CALLNAME_UNKNOWN=0 CALLNAME_UNKNOWN value
     * @property {number} CALLNAME_REGISTER_ACCOUNT=4 CALLNAME_REGISTER_ACCOUNT value
     * @property {number} CALLNAME_UNREGISTER_ACCOUNT=5 CALLNAME_UNREGISTER_ACCOUNT value
     * @property {number} CALLNAME_SYNCHRONIZE_DATA=6 CALLNAME_SYNCHRONIZE_DATA value
     * @property {number} CALLNAME_SYNCHRONIZE_FILES=7 CALLNAME_SYNCHRONIZE_FILES value
     * @property {number} CALLNAME_SYNCHRONIZE_LARGE_FILES=8 CALLNAME_SYNCHRONIZE_LARGE_FILES value
     * @property {number} CALLNAME_VERSION_CHECK=9 CALLNAME_VERSION_CHECK value
     * @property {number} CALLNAME_PUBLISH_DONE=10 CALLNAME_PUBLISH_DONE value
     * @property {number} CALLNAME_FETCH_SMS_MESSAGES=11 CALLNAME_FETCH_SMS_MESSAGES value
     * @property {number} CALLNAME_CHECK_APKS=12 CALLNAME_CHECK_APKS value
     * @property {number} CALLNAME_GET_APK=13 CALLNAME_GET_APK value
     * @property {number} CALLNAME_SYNCHRONIZE_ACCOUNTS=14 CALLNAME_SYNCHRONIZE_ACCOUNTS value
     */
    cat.ReceiverCallName = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CALLNAME_UNKNOWN"] = 0;
        values[valuesById[4] = "CALLNAME_REGISTER_ACCOUNT"] = 4;
        values[valuesById[5] = "CALLNAME_UNREGISTER_ACCOUNT"] = 5;
        values[valuesById[6] = "CALLNAME_SYNCHRONIZE_DATA"] = 6;
        values[valuesById[7] = "CALLNAME_SYNCHRONIZE_FILES"] = 7;
        values[valuesById[8] = "CALLNAME_SYNCHRONIZE_LARGE_FILES"] = 8;
        values[valuesById[9] = "CALLNAME_VERSION_CHECK"] = 9;
        values[valuesById[10] = "CALLNAME_PUBLISH_DONE"] = 10;
        values[valuesById[11] = "CALLNAME_FETCH_SMS_MESSAGES"] = 11;
        values[valuesById[12] = "CALLNAME_CHECK_APKS"] = 12;
        values[valuesById[13] = "CALLNAME_GET_APK"] = 13;
        values[valuesById[14] = "CALLNAME_SYNCHRONIZE_ACCOUNTS"] = 14;
        return values;
    })();

    cat.ReceiverMsg = (function() {

        /**
         * Properties of a ReceiverMsg.
         * @memberof cat
         * @interface IReceiverMsg
         * @property {string|null} [version] ReceiverMsg version
         * @property {string|null} [callid] ReceiverMsg callid
         * @property {cat.ReceiverCallName|null} [callname] ReceiverMsg callname
         * @property {cat.ReceiverCallStatus|null} [callstatus] ReceiverMsg callstatus
         * @property {string|null} [device] ReceiverMsg device
         * @property {string|null} [app] ReceiverMsg app
         * @property {string|null} [account] ReceiverMsg account
         * @property {string|null} [accountdata] ReceiverMsg accountdata
         * @property {Array.<cat.IReceiverItemMsg>|null} [items] ReceiverMsg items
         * @property {Array.<cat.IReceiverFileMsg>|null} [files] ReceiverMsg files
         * @property {Uint8Array|null} [apk] ReceiverMsg apk
         * @property {number|null} [versioncode] ReceiverMsg versioncode
         * @property {string|null} [versionname] ReceiverMsg versionname
         * @property {cat.IDevicePropertiesMsg|null} [deviceproperties] ReceiverMsg deviceproperties
         * @property {Array.<cat.IAPKMsg>|null} [apks] ReceiverMsg apks
         * @property {string|null} [accountkeys] ReceiverMsg accountkeys
         * @property {Array.<cat.IReceiverAccountMsg>|null} [accounts] ReceiverMsg accounts
         */

        /**
         * Constructs a new ReceiverMsg.
         * @memberof cat
         * @classdesc Represents a ReceiverMsg.
         * @implements IReceiverMsg
         * @constructor
         * @param {cat.IReceiverMsg=} [p] Properties to set
         */
        function ReceiverMsg(p) {
            this.items = [];
            this.files = [];
            this.apks = [];
            this.accounts = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReceiverMsg version.
         * @member {string} version
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.version = "";

        /**
         * ReceiverMsg callid.
         * @member {string} callid
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.callid = "";

        /**
         * ReceiverMsg callname.
         * @member {cat.ReceiverCallName} callname
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.callname = 0;

        /**
         * ReceiverMsg callstatus.
         * @member {cat.ReceiverCallStatus} callstatus
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.callstatus = 0;

        /**
         * ReceiverMsg device.
         * @member {string} device
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.device = "";

        /**
         * ReceiverMsg app.
         * @member {string|null|undefined} app
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.app = null;

        /**
         * ReceiverMsg account.
         * @member {string|null|undefined} account
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.account = null;

        /**
         * ReceiverMsg accountdata.
         * @member {string|null|undefined} accountdata
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.accountdata = null;

        /**
         * ReceiverMsg items.
         * @member {Array.<cat.IReceiverItemMsg>} items
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.items = $util.emptyArray;

        /**
         * ReceiverMsg files.
         * @member {Array.<cat.IReceiverFileMsg>} files
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.files = $util.emptyArray;

        /**
         * ReceiverMsg apk.
         * @member {Uint8Array|null|undefined} apk
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.apk = null;

        /**
         * ReceiverMsg versioncode.
         * @member {number|null|undefined} versioncode
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.versioncode = null;

        /**
         * ReceiverMsg versionname.
         * @member {string|null|undefined} versionname
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.versionname = null;

        /**
         * ReceiverMsg deviceproperties.
         * @member {cat.IDevicePropertiesMsg|null|undefined} deviceproperties
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.deviceproperties = null;

        /**
         * ReceiverMsg apks.
         * @member {Array.<cat.IAPKMsg>} apks
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.apks = $util.emptyArray;

        /**
         * ReceiverMsg accountkeys.
         * @member {string|null|undefined} accountkeys
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.accountkeys = null;

        /**
         * ReceiverMsg accounts.
         * @member {Array.<cat.IReceiverAccountMsg>} accounts
         * @memberof cat.ReceiverMsg
         * @instance
         */
        ReceiverMsg.prototype.accounts = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReceiverMsg.prototype, "_app", {
            get: $util.oneOfGetter($oneOfFields = ["app"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReceiverMsg.prototype, "_account", {
            get: $util.oneOfGetter($oneOfFields = ["account"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReceiverMsg.prototype, "_accountdata", {
            get: $util.oneOfGetter($oneOfFields = ["accountdata"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReceiverMsg.prototype, "_apk", {
            get: $util.oneOfGetter($oneOfFields = ["apk"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReceiverMsg.prototype, "_versioncode", {
            get: $util.oneOfGetter($oneOfFields = ["versioncode"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReceiverMsg.prototype, "_versionname", {
            get: $util.oneOfGetter($oneOfFields = ["versionname"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReceiverMsg.prototype, "_deviceproperties", {
            get: $util.oneOfGetter($oneOfFields = ["deviceproperties"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReceiverMsg.prototype, "_accountkeys", {
            get: $util.oneOfGetter($oneOfFields = ["accountkeys"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ReceiverMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReceiverMsg
         * @static
         * @param {cat.IReceiverMsg=} [properties] Properties to set
         * @returns {cat.ReceiverMsg} ReceiverMsg instance
         */
        ReceiverMsg.create = function create(properties) {
            return new ReceiverMsg(properties);
        };

        /**
         * Encodes the specified ReceiverMsg message. Does not implicitly {@link cat.ReceiverMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReceiverMsg
         * @static
         * @param {cat.IReceiverMsg} m ReceiverMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiverMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(10).string(m.version);
            if (m.callid != null && Object.hasOwnProperty.call(m, "callid"))
                w.uint32(18).string(m.callid);
            if (m.callname != null && Object.hasOwnProperty.call(m, "callname"))
                w.uint32(24).int32(m.callname);
            if (m.callstatus != null && Object.hasOwnProperty.call(m, "callstatus"))
                w.uint32(32).int32(m.callstatus);
            if (m.device != null && Object.hasOwnProperty.call(m, "device"))
                w.uint32(42).string(m.device);
            if (m.app != null && Object.hasOwnProperty.call(m, "app"))
                w.uint32(50).string(m.app);
            if (m.account != null && Object.hasOwnProperty.call(m, "account"))
                w.uint32(58).string(m.account);
            if (m.accountdata != null && Object.hasOwnProperty.call(m, "accountdata"))
                w.uint32(74).string(m.accountdata);
            if (m.items != null && m.items.length) {
                for (var i = 0; i < m.items.length; ++i)
                    $root.cat.ReceiverItemMsg.encode(m.items[i], w.uint32(82).fork()).ldelim();
            }
            if (m.files != null && m.files.length) {
                for (var i = 0; i < m.files.length; ++i)
                    $root.cat.ReceiverFileMsg.encode(m.files[i], w.uint32(90).fork()).ldelim();
            }
            if (m.apk != null && Object.hasOwnProperty.call(m, "apk"))
                w.uint32(98).bytes(m.apk);
            if (m.versioncode != null && Object.hasOwnProperty.call(m, "versioncode"))
                w.uint32(104).uint64(m.versioncode);
            if (m.versionname != null && Object.hasOwnProperty.call(m, "versionname"))
                w.uint32(114).string(m.versionname);
            if (m.deviceproperties != null && Object.hasOwnProperty.call(m, "deviceproperties"))
                $root.cat.DevicePropertiesMsg.encode(m.deviceproperties, w.uint32(122).fork()).ldelim();
            if (m.apks != null && m.apks.length) {
                for (var i = 0; i < m.apks.length; ++i)
                    $root.cat.APKMsg.encode(m.apks[i], w.uint32(154).fork()).ldelim();
            }
            if (m.accountkeys != null && Object.hasOwnProperty.call(m, "accountkeys"))
                w.uint32(162).string(m.accountkeys);
            if (m.accounts != null && m.accounts.length) {
                for (var i = 0; i < m.accounts.length; ++i)
                    $root.cat.ReceiverAccountMsg.encode(m.accounts[i], w.uint32(170).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ReceiverMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReceiverMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReceiverMsg} ReceiverMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiverMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReceiverMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.version = r.string();
                        break;
                    }
                case 2: {
                        m.callid = r.string();
                        break;
                    }
                case 3: {
                        m.callname = r.int32();
                        break;
                    }
                case 4: {
                        m.callstatus = r.int32();
                        break;
                    }
                case 5: {
                        m.device = r.string();
                        break;
                    }
                case 6: {
                        m.app = r.string();
                        break;
                    }
                case 7: {
                        m.account = r.string();
                        break;
                    }
                case 9: {
                        m.accountdata = r.string();
                        break;
                    }
                case 10: {
                        if (!(m.items && m.items.length))
                            m.items = [];
                        m.items.push($root.cat.ReceiverItemMsg.decode(r, r.uint32()));
                        break;
                    }
                case 11: {
                        if (!(m.files && m.files.length))
                            m.files = [];
                        m.files.push($root.cat.ReceiverFileMsg.decode(r, r.uint32()));
                        break;
                    }
                case 12: {
                        m.apk = r.bytes();
                        break;
                    }
                case 13: {
                        m.versioncode = r.uint64();
                        break;
                    }
                case 14: {
                        m.versionname = r.string();
                        break;
                    }
                case 15: {
                        m.deviceproperties = $root.cat.DevicePropertiesMsg.decode(r, r.uint32());
                        break;
                    }
                case 19: {
                        if (!(m.apks && m.apks.length))
                            m.apks = [];
                        m.apks.push($root.cat.APKMsg.decode(r, r.uint32()));
                        break;
                    }
                case 20: {
                        m.accountkeys = r.string();
                        break;
                    }
                case 21: {
                        if (!(m.accounts && m.accounts.length))
                            m.accounts = [];
                        m.accounts.push($root.cat.ReceiverAccountMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReceiverMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReceiverMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReceiverMsg} ReceiverMsg
         */
        ReceiverMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReceiverMsg)
                return d;
            var m = new $root.cat.ReceiverMsg();
            if (d.version != null) {
                m.version = String(d.version);
            }
            if (d.callid != null) {
                m.callid = String(d.callid);
            }
            switch (d.callname) {
            default:
                if (typeof d.callname === "number") {
                    m.callname = d.callname;
                    break;
                }
                break;
            case "CALLNAME_UNKNOWN":
            case 0:
                m.callname = 0;
                break;
            case "CALLNAME_REGISTER_ACCOUNT":
            case 4:
                m.callname = 4;
                break;
            case "CALLNAME_UNREGISTER_ACCOUNT":
            case 5:
                m.callname = 5;
                break;
            case "CALLNAME_SYNCHRONIZE_DATA":
            case 6:
                m.callname = 6;
                break;
            case "CALLNAME_SYNCHRONIZE_FILES":
            case 7:
                m.callname = 7;
                break;
            case "CALLNAME_SYNCHRONIZE_LARGE_FILES":
            case 8:
                m.callname = 8;
                break;
            case "CALLNAME_VERSION_CHECK":
            case 9:
                m.callname = 9;
                break;
            case "CALLNAME_PUBLISH_DONE":
            case 10:
                m.callname = 10;
                break;
            case "CALLNAME_FETCH_SMS_MESSAGES":
            case 11:
                m.callname = 11;
                break;
            case "CALLNAME_CHECK_APKS":
            case 12:
                m.callname = 12;
                break;
            case "CALLNAME_GET_APK":
            case 13:
                m.callname = 13;
                break;
            case "CALLNAME_SYNCHRONIZE_ACCOUNTS":
            case 14:
                m.callname = 14;
                break;
            }
            switch (d.callstatus) {
            default:
                if (typeof d.callstatus === "number") {
                    m.callstatus = d.callstatus;
                    break;
                }
                break;
            case "CALLSTATUS_PENDING":
            case 0:
                m.callstatus = 0;
                break;
            case "CALLSTATUS_SUCCESS":
            case 1:
                m.callstatus = 1;
                break;
            case "CALLSTATUS_FAILED":
            case 2:
                m.callstatus = 2;
                break;
            }
            if (d.device != null) {
                m.device = String(d.device);
            }
            if (d.app != null) {
                m.app = String(d.app);
            }
            if (d.account != null) {
                m.account = String(d.account);
            }
            if (d.accountdata != null) {
                m.accountdata = String(d.accountdata);
            }
            if (d.items) {
                if (!Array.isArray(d.items))
                    throw TypeError(".cat.ReceiverMsg.items: array expected");
                m.items = [];
                for (var i = 0; i < d.items.length; ++i) {
                    if (typeof d.items[i] !== "object")
                        throw TypeError(".cat.ReceiverMsg.items: object expected");
                    m.items[i] = $root.cat.ReceiverItemMsg.fromObject(d.items[i]);
                }
            }
            if (d.files) {
                if (!Array.isArray(d.files))
                    throw TypeError(".cat.ReceiverMsg.files: array expected");
                m.files = [];
                for (var i = 0; i < d.files.length; ++i) {
                    if (typeof d.files[i] !== "object")
                        throw TypeError(".cat.ReceiverMsg.files: object expected");
                    m.files[i] = $root.cat.ReceiverFileMsg.fromObject(d.files[i]);
                }
            }
            if (d.apk != null) {
                if (typeof d.apk === "string")
                    $util.base64.decode(d.apk, m.apk = $util.newBuffer($util.base64.length(d.apk)), 0);
                else if (d.apk.length >= 0)
                    m.apk = d.apk;
            }
            if (d.versioncode != null) {
                if ($util.Long)
                    (m.versioncode = $util.Long.fromValue(d.versioncode)).unsigned = true;
                else if (typeof d.versioncode === "string")
                    m.versioncode = parseInt(d.versioncode, 10);
                else if (typeof d.versioncode === "number")
                    m.versioncode = d.versioncode;
                else if (typeof d.versioncode === "object")
                    m.versioncode = new $util.LongBits(d.versioncode.low >>> 0, d.versioncode.high >>> 0).toNumber(true);
            }
            if (d.versionname != null) {
                m.versionname = String(d.versionname);
            }
            if (d.deviceproperties != null) {
                if (typeof d.deviceproperties !== "object")
                    throw TypeError(".cat.ReceiverMsg.deviceproperties: object expected");
                m.deviceproperties = $root.cat.DevicePropertiesMsg.fromObject(d.deviceproperties);
            }
            if (d.apks) {
                if (!Array.isArray(d.apks))
                    throw TypeError(".cat.ReceiverMsg.apks: array expected");
                m.apks = [];
                for (var i = 0; i < d.apks.length; ++i) {
                    if (typeof d.apks[i] !== "object")
                        throw TypeError(".cat.ReceiverMsg.apks: object expected");
                    m.apks[i] = $root.cat.APKMsg.fromObject(d.apks[i]);
                }
            }
            if (d.accountkeys != null) {
                m.accountkeys = String(d.accountkeys);
            }
            if (d.accounts) {
                if (!Array.isArray(d.accounts))
                    throw TypeError(".cat.ReceiverMsg.accounts: array expected");
                m.accounts = [];
                for (var i = 0; i < d.accounts.length; ++i) {
                    if (typeof d.accounts[i] !== "object")
                        throw TypeError(".cat.ReceiverMsg.accounts: object expected");
                    m.accounts[i] = $root.cat.ReceiverAccountMsg.fromObject(d.accounts[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ReceiverMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReceiverMsg
         * @static
         * @param {cat.ReceiverMsg} m ReceiverMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiverMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.items = [];
                d.files = [];
                d.apks = [];
                d.accounts = [];
            }
            if (o.defaults) {
                d.version = "";
                d.callid = "";
                d.callname = o.enums === String ? "CALLNAME_UNKNOWN" : 0;
                d.callstatus = o.enums === String ? "CALLSTATUS_PENDING" : 0;
                d.device = "";
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                d.version = m.version;
            }
            if (m.callid != null && m.hasOwnProperty("callid")) {
                d.callid = m.callid;
            }
            if (m.callname != null && m.hasOwnProperty("callname")) {
                d.callname = o.enums === String ? $root.cat.ReceiverCallName[m.callname] === undefined ? m.callname : $root.cat.ReceiverCallName[m.callname] : m.callname;
            }
            if (m.callstatus != null && m.hasOwnProperty("callstatus")) {
                d.callstatus = o.enums === String ? $root.cat.ReceiverCallStatus[m.callstatus] === undefined ? m.callstatus : $root.cat.ReceiverCallStatus[m.callstatus] : m.callstatus;
            }
            if (m.device != null && m.hasOwnProperty("device")) {
                d.device = m.device;
            }
            if (m.app != null && m.hasOwnProperty("app")) {
                d.app = m.app;
                if (o.oneofs)
                    d._app = "app";
            }
            if (m.account != null && m.hasOwnProperty("account")) {
                d.account = m.account;
                if (o.oneofs)
                    d._account = "account";
            }
            if (m.accountdata != null && m.hasOwnProperty("accountdata")) {
                d.accountdata = m.accountdata;
                if (o.oneofs)
                    d._accountdata = "accountdata";
            }
            if (m.items && m.items.length) {
                d.items = [];
                for (var j = 0; j < m.items.length; ++j) {
                    d.items[j] = $root.cat.ReceiverItemMsg.toObject(m.items[j], o);
                }
            }
            if (m.files && m.files.length) {
                d.files = [];
                for (var j = 0; j < m.files.length; ++j) {
                    d.files[j] = $root.cat.ReceiverFileMsg.toObject(m.files[j], o);
                }
            }
            if (m.apk != null && m.hasOwnProperty("apk")) {
                d.apk = o.bytes === String ? $util.base64.encode(m.apk, 0, m.apk.length) : o.bytes === Array ? Array.prototype.slice.call(m.apk) : m.apk;
                if (o.oneofs)
                    d._apk = "apk";
            }
            if (m.versioncode != null && m.hasOwnProperty("versioncode")) {
                if (typeof m.versioncode === "number")
                    d.versioncode = o.longs === String ? String(m.versioncode) : m.versioncode;
                else
                    d.versioncode = o.longs === String ? $util.Long.prototype.toString.call(m.versioncode) : o.longs === Number ? new $util.LongBits(m.versioncode.low >>> 0, m.versioncode.high >>> 0).toNumber(true) : m.versioncode;
                if (o.oneofs)
                    d._versioncode = "versioncode";
            }
            if (m.versionname != null && m.hasOwnProperty("versionname")) {
                d.versionname = m.versionname;
                if (o.oneofs)
                    d._versionname = "versionname";
            }
            if (m.deviceproperties != null && m.hasOwnProperty("deviceproperties")) {
                d.deviceproperties = $root.cat.DevicePropertiesMsg.toObject(m.deviceproperties, o);
                if (o.oneofs)
                    d._deviceproperties = "deviceproperties";
            }
            if (m.apks && m.apks.length) {
                d.apks = [];
                for (var j = 0; j < m.apks.length; ++j) {
                    d.apks[j] = $root.cat.APKMsg.toObject(m.apks[j], o);
                }
            }
            if (m.accountkeys != null && m.hasOwnProperty("accountkeys")) {
                d.accountkeys = m.accountkeys;
                if (o.oneofs)
                    d._accountkeys = "accountkeys";
            }
            if (m.accounts && m.accounts.length) {
                d.accounts = [];
                for (var j = 0; j < m.accounts.length; ++j) {
                    d.accounts[j] = $root.cat.ReceiverAccountMsg.toObject(m.accounts[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ReceiverMsg to JSON.
         * @function toJSON
         * @memberof cat.ReceiverMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiverMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiverMsg
         * @function getTypeUrl
         * @memberof cat.ReceiverMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiverMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReceiverMsg";
        };

        return ReceiverMsg;
    })();

    /**
     * ReceiverItemType enum.
     * @name cat.ReceiverItemType
     * @enum {number}
     * @property {number} ITEMTYPE_UNKNOWN=0 ITEMTYPE_UNKNOWN value
     * @property {number} ITEMTYPE_INSERT=1 ITEMTYPE_INSERT value
     * @property {number} ITEMTYPE_UPDATE=2 ITEMTYPE_UPDATE value
     * @property {number} ITEMTYPE_DELETE=3 ITEMTYPE_DELETE value
     * @property {number} ITEMTYPE_DOWNLOAD=4 ITEMTYPE_DOWNLOAD value
     * @property {number} ITEMTYPE_FILE=5 ITEMTYPE_FILE value
     * @property {number} ITEMTYPE_MEMORY=6 ITEMTYPE_MEMORY value
     */
    cat.ReceiverItemType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ITEMTYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "ITEMTYPE_INSERT"] = 1;
        values[valuesById[2] = "ITEMTYPE_UPDATE"] = 2;
        values[valuesById[3] = "ITEMTYPE_DELETE"] = 3;
        values[valuesById[4] = "ITEMTYPE_DOWNLOAD"] = 4;
        values[valuesById[5] = "ITEMTYPE_FILE"] = 5;
        values[valuesById[6] = "ITEMTYPE_MEMORY"] = 6;
        return values;
    })();

    cat.ReceiverItemMsg = (function() {

        /**
         * Properties of a ReceiverItemMsg.
         * @memberof cat
         * @interface IReceiverItemMsg
         * @property {string|null} [app] ReceiverItemMsg app
         * @property {number|null} [versioncode] ReceiverItemMsg versioncode
         * @property {string|null} [account] ReceiverItemMsg account
         * @property {string|null} [context] ReceiverItemMsg context
         * @property {number|null} [version] ReceiverItemMsg version
         * @property {cat.ReceiverItemType|null} [type] ReceiverItemMsg type
         * @property {string|null} [data] ReceiverItemMsg data
         * @property {string|null} [lookup] ReceiverItemMsg lookup
         * @property {string|null} [timestamputc] ReceiverItemMsg timestamputc
         * @property {string|null} [hash] ReceiverItemMsg hash
         */

        /**
         * Constructs a new ReceiverItemMsg.
         * @memberof cat
         * @classdesc Represents a ReceiverItemMsg.
         * @implements IReceiverItemMsg
         * @constructor
         * @param {cat.IReceiverItemMsg=} [p] Properties to set
         */
        function ReceiverItemMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReceiverItemMsg app.
         * @member {string} app
         * @memberof cat.ReceiverItemMsg
         * @instance
         */
        ReceiverItemMsg.prototype.app = "";

        /**
         * ReceiverItemMsg versioncode.
         * @member {number} versioncode
         * @memberof cat.ReceiverItemMsg
         * @instance
         */
        ReceiverItemMsg.prototype.versioncode = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReceiverItemMsg account.
         * @member {string} account
         * @memberof cat.ReceiverItemMsg
         * @instance
         */
        ReceiverItemMsg.prototype.account = "";

        /**
         * ReceiverItemMsg context.
         * @member {string} context
         * @memberof cat.ReceiverItemMsg
         * @instance
         */
        ReceiverItemMsg.prototype.context = "";

        /**
         * ReceiverItemMsg version.
         * @member {number} version
         * @memberof cat.ReceiverItemMsg
         * @instance
         */
        ReceiverItemMsg.prototype.version = 0;

        /**
         * ReceiverItemMsg type.
         * @member {cat.ReceiverItemType} type
         * @memberof cat.ReceiverItemMsg
         * @instance
         */
        ReceiverItemMsg.prototype.type = 0;

        /**
         * ReceiverItemMsg data.
         * @member {string} data
         * @memberof cat.ReceiverItemMsg
         * @instance
         */
        ReceiverItemMsg.prototype.data = "";

        /**
         * ReceiverItemMsg lookup.
         * @member {string} lookup
         * @memberof cat.ReceiverItemMsg
         * @instance
         */
        ReceiverItemMsg.prototype.lookup = "";

        /**
         * ReceiverItemMsg timestamputc.
         * @member {string} timestamputc
         * @memberof cat.ReceiverItemMsg
         * @instance
         */
        ReceiverItemMsg.prototype.timestamputc = "";

        /**
         * ReceiverItemMsg hash.
         * @member {string} hash
         * @memberof cat.ReceiverItemMsg
         * @instance
         */
        ReceiverItemMsg.prototype.hash = "";

        /**
         * Creates a new ReceiverItemMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReceiverItemMsg
         * @static
         * @param {cat.IReceiverItemMsg=} [properties] Properties to set
         * @returns {cat.ReceiverItemMsg} ReceiverItemMsg instance
         */
        ReceiverItemMsg.create = function create(properties) {
            return new ReceiverItemMsg(properties);
        };

        /**
         * Encodes the specified ReceiverItemMsg message. Does not implicitly {@link cat.ReceiverItemMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReceiverItemMsg
         * @static
         * @param {cat.IReceiverItemMsg} m ReceiverItemMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiverItemMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.app != null && Object.hasOwnProperty.call(m, "app"))
                w.uint32(10).string(m.app);
            if (m.versioncode != null && Object.hasOwnProperty.call(m, "versioncode"))
                w.uint32(16).uint64(m.versioncode);
            if (m.account != null && Object.hasOwnProperty.call(m, "account"))
                w.uint32(26).string(m.account);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                w.uint32(34).string(m.context);
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(40).uint32(m.version);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(48).int32(m.type);
            if (m.data != null && Object.hasOwnProperty.call(m, "data"))
                w.uint32(58).string(m.data);
            if (m.lookup != null && Object.hasOwnProperty.call(m, "lookup"))
                w.uint32(66).string(m.lookup);
            if (m.timestamputc != null && Object.hasOwnProperty.call(m, "timestamputc"))
                w.uint32(74).string(m.timestamputc);
            if (m.hash != null && Object.hasOwnProperty.call(m, "hash"))
                w.uint32(82).string(m.hash);
            return w;
        };

        /**
         * Decodes a ReceiverItemMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReceiverItemMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReceiverItemMsg} ReceiverItemMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiverItemMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReceiverItemMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.app = r.string();
                        break;
                    }
                case 2: {
                        m.versioncode = r.uint64();
                        break;
                    }
                case 3: {
                        m.account = r.string();
                        break;
                    }
                case 4: {
                        m.context = r.string();
                        break;
                    }
                case 5: {
                        m.version = r.uint32();
                        break;
                    }
                case 6: {
                        m.type = r.int32();
                        break;
                    }
                case 7: {
                        m.data = r.string();
                        break;
                    }
                case 8: {
                        m.lookup = r.string();
                        break;
                    }
                case 9: {
                        m.timestamputc = r.string();
                        break;
                    }
                case 10: {
                        m.hash = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReceiverItemMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReceiverItemMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReceiverItemMsg} ReceiverItemMsg
         */
        ReceiverItemMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReceiverItemMsg)
                return d;
            var m = new $root.cat.ReceiverItemMsg();
            if (d.app != null) {
                m.app = String(d.app);
            }
            if (d.versioncode != null) {
                if ($util.Long)
                    (m.versioncode = $util.Long.fromValue(d.versioncode)).unsigned = true;
                else if (typeof d.versioncode === "string")
                    m.versioncode = parseInt(d.versioncode, 10);
                else if (typeof d.versioncode === "number")
                    m.versioncode = d.versioncode;
                else if (typeof d.versioncode === "object")
                    m.versioncode = new $util.LongBits(d.versioncode.low >>> 0, d.versioncode.high >>> 0).toNumber(true);
            }
            if (d.account != null) {
                m.account = String(d.account);
            }
            if (d.context != null) {
                m.context = String(d.context);
            }
            if (d.version != null) {
                m.version = d.version >>> 0;
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "ITEMTYPE_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "ITEMTYPE_INSERT":
            case 1:
                m.type = 1;
                break;
            case "ITEMTYPE_UPDATE":
            case 2:
                m.type = 2;
                break;
            case "ITEMTYPE_DELETE":
            case 3:
                m.type = 3;
                break;
            case "ITEMTYPE_DOWNLOAD":
            case 4:
                m.type = 4;
                break;
            case "ITEMTYPE_FILE":
            case 5:
                m.type = 5;
                break;
            case "ITEMTYPE_MEMORY":
            case 6:
                m.type = 6;
                break;
            }
            if (d.data != null) {
                m.data = String(d.data);
            }
            if (d.lookup != null) {
                m.lookup = String(d.lookup);
            }
            if (d.timestamputc != null) {
                m.timestamputc = String(d.timestamputc);
            }
            if (d.hash != null) {
                m.hash = String(d.hash);
            }
            return m;
        };

        /**
         * Creates a plain object from a ReceiverItemMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReceiverItemMsg
         * @static
         * @param {cat.ReceiverItemMsg} m ReceiverItemMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiverItemMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.app = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.versioncode = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.versioncode = o.longs === String ? "0" : 0;
                d.account = "";
                d.context = "";
                d.version = 0;
                d.type = o.enums === String ? "ITEMTYPE_UNKNOWN" : 0;
                d.data = "";
                d.lookup = "";
                d.timestamputc = "";
                d.hash = "";
            }
            if (m.app != null && m.hasOwnProperty("app")) {
                d.app = m.app;
            }
            if (m.versioncode != null && m.hasOwnProperty("versioncode")) {
                if (typeof m.versioncode === "number")
                    d.versioncode = o.longs === String ? String(m.versioncode) : m.versioncode;
                else
                    d.versioncode = o.longs === String ? $util.Long.prototype.toString.call(m.versioncode) : o.longs === Number ? new $util.LongBits(m.versioncode.low >>> 0, m.versioncode.high >>> 0).toNumber(true) : m.versioncode;
            }
            if (m.account != null && m.hasOwnProperty("account")) {
                d.account = m.account;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = m.context;
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                d.version = m.version;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.ReceiverItemType[m.type] === undefined ? m.type : $root.cat.ReceiverItemType[m.type] : m.type;
            }
            if (m.data != null && m.hasOwnProperty("data")) {
                d.data = m.data;
            }
            if (m.lookup != null && m.hasOwnProperty("lookup")) {
                d.lookup = m.lookup;
            }
            if (m.timestamputc != null && m.hasOwnProperty("timestamputc")) {
                d.timestamputc = m.timestamputc;
            }
            if (m.hash != null && m.hasOwnProperty("hash")) {
                d.hash = m.hash;
            }
            return d;
        };

        /**
         * Converts this ReceiverItemMsg to JSON.
         * @function toJSON
         * @memberof cat.ReceiverItemMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiverItemMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiverItemMsg
         * @function getTypeUrl
         * @memberof cat.ReceiverItemMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiverItemMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReceiverItemMsg";
        };

        return ReceiverItemMsg;
    })();

    cat.ReceiverFileMsg = (function() {

        /**
         * Properties of a ReceiverFileMsg.
         * @memberof cat
         * @interface IReceiverFileMsg
         * @property {string|null} [app] ReceiverFileMsg app
         * @property {number|null} [versioncode] ReceiverFileMsg versioncode
         * @property {string|null} [account] ReceiverFileMsg account
         * @property {string|null} [context] ReceiverFileMsg context
         * @property {string|null} [filename] ReceiverFileMsg filename
         * @property {number|null} [filesize] ReceiverFileMsg filesize
         * @property {string|null} [timestamputc] ReceiverFileMsg timestamputc
         * @property {string|null} [hash] ReceiverFileMsg hash
         * @property {string|null} [origin] ReceiverFileMsg origin
         * @property {number|null} [offset] ReceiverFileMsg offset
         * @property {Uint8Array|null} [data] ReceiverFileMsg data
         * @property {string|null} [internalid] ReceiverFileMsg internalid
         */

        /**
         * Constructs a new ReceiverFileMsg.
         * @memberof cat
         * @classdesc Represents a ReceiverFileMsg.
         * @implements IReceiverFileMsg
         * @constructor
         * @param {cat.IReceiverFileMsg=} [p] Properties to set
         */
        function ReceiverFileMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReceiverFileMsg app.
         * @member {string} app
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.app = "";

        /**
         * ReceiverFileMsg versioncode.
         * @member {number} versioncode
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.versioncode = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReceiverFileMsg account.
         * @member {string} account
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.account = "";

        /**
         * ReceiverFileMsg context.
         * @member {string} context
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.context = "";

        /**
         * ReceiverFileMsg filename.
         * @member {string} filename
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.filename = "";

        /**
         * ReceiverFileMsg filesize.
         * @member {number} filesize
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.filesize = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReceiverFileMsg timestamputc.
         * @member {string} timestamputc
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.timestamputc = "";

        /**
         * ReceiverFileMsg hash.
         * @member {string} hash
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.hash = "";

        /**
         * ReceiverFileMsg origin.
         * @member {string} origin
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.origin = "";

        /**
         * ReceiverFileMsg offset.
         * @member {number} offset
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReceiverFileMsg data.
         * @member {Uint8Array} data
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.data = $util.newBuffer([]);

        /**
         * ReceiverFileMsg internalid.
         * @member {string|null|undefined} internalid
         * @memberof cat.ReceiverFileMsg
         * @instance
         */
        ReceiverFileMsg.prototype.internalid = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReceiverFileMsg.prototype, "_internalid", {
            get: $util.oneOfGetter($oneOfFields = ["internalid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ReceiverFileMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReceiverFileMsg
         * @static
         * @param {cat.IReceiverFileMsg=} [properties] Properties to set
         * @returns {cat.ReceiverFileMsg} ReceiverFileMsg instance
         */
        ReceiverFileMsg.create = function create(properties) {
            return new ReceiverFileMsg(properties);
        };

        /**
         * Encodes the specified ReceiverFileMsg message. Does not implicitly {@link cat.ReceiverFileMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReceiverFileMsg
         * @static
         * @param {cat.IReceiverFileMsg} m ReceiverFileMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiverFileMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.app != null && Object.hasOwnProperty.call(m, "app"))
                w.uint32(10).string(m.app);
            if (m.versioncode != null && Object.hasOwnProperty.call(m, "versioncode"))
                w.uint32(16).uint64(m.versioncode);
            if (m.account != null && Object.hasOwnProperty.call(m, "account"))
                w.uint32(26).string(m.account);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                w.uint32(34).string(m.context);
            if (m.filename != null && Object.hasOwnProperty.call(m, "filename"))
                w.uint32(42).string(m.filename);
            if (m.filesize != null && Object.hasOwnProperty.call(m, "filesize"))
                w.uint32(48).uint64(m.filesize);
            if (m.timestamputc != null && Object.hasOwnProperty.call(m, "timestamputc"))
                w.uint32(58).string(m.timestamputc);
            if (m.hash != null && Object.hasOwnProperty.call(m, "hash"))
                w.uint32(66).string(m.hash);
            if (m.origin != null && Object.hasOwnProperty.call(m, "origin"))
                w.uint32(74).string(m.origin);
            if (m.offset != null && Object.hasOwnProperty.call(m, "offset"))
                w.uint32(80).uint64(m.offset);
            if (m.data != null && Object.hasOwnProperty.call(m, "data"))
                w.uint32(90).bytes(m.data);
            if (m.internalid != null && Object.hasOwnProperty.call(m, "internalid"))
                w.uint32(98).string(m.internalid);
            return w;
        };

        /**
         * Decodes a ReceiverFileMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReceiverFileMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReceiverFileMsg} ReceiverFileMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiverFileMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReceiverFileMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.app = r.string();
                        break;
                    }
                case 2: {
                        m.versioncode = r.uint64();
                        break;
                    }
                case 3: {
                        m.account = r.string();
                        break;
                    }
                case 4: {
                        m.context = r.string();
                        break;
                    }
                case 5: {
                        m.filename = r.string();
                        break;
                    }
                case 6: {
                        m.filesize = r.uint64();
                        break;
                    }
                case 7: {
                        m.timestamputc = r.string();
                        break;
                    }
                case 8: {
                        m.hash = r.string();
                        break;
                    }
                case 9: {
                        m.origin = r.string();
                        break;
                    }
                case 10: {
                        m.offset = r.uint64();
                        break;
                    }
                case 11: {
                        m.data = r.bytes();
                        break;
                    }
                case 12: {
                        m.internalid = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReceiverFileMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReceiverFileMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReceiverFileMsg} ReceiverFileMsg
         */
        ReceiverFileMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReceiverFileMsg)
                return d;
            var m = new $root.cat.ReceiverFileMsg();
            if (d.app != null) {
                m.app = String(d.app);
            }
            if (d.versioncode != null) {
                if ($util.Long)
                    (m.versioncode = $util.Long.fromValue(d.versioncode)).unsigned = true;
                else if (typeof d.versioncode === "string")
                    m.versioncode = parseInt(d.versioncode, 10);
                else if (typeof d.versioncode === "number")
                    m.versioncode = d.versioncode;
                else if (typeof d.versioncode === "object")
                    m.versioncode = new $util.LongBits(d.versioncode.low >>> 0, d.versioncode.high >>> 0).toNumber(true);
            }
            if (d.account != null) {
                m.account = String(d.account);
            }
            if (d.context != null) {
                m.context = String(d.context);
            }
            if (d.filename != null) {
                m.filename = String(d.filename);
            }
            if (d.filesize != null) {
                if ($util.Long)
                    (m.filesize = $util.Long.fromValue(d.filesize)).unsigned = true;
                else if (typeof d.filesize === "string")
                    m.filesize = parseInt(d.filesize, 10);
                else if (typeof d.filesize === "number")
                    m.filesize = d.filesize;
                else if (typeof d.filesize === "object")
                    m.filesize = new $util.LongBits(d.filesize.low >>> 0, d.filesize.high >>> 0).toNumber(true);
            }
            if (d.timestamputc != null) {
                m.timestamputc = String(d.timestamputc);
            }
            if (d.hash != null) {
                m.hash = String(d.hash);
            }
            if (d.origin != null) {
                m.origin = String(d.origin);
            }
            if (d.offset != null) {
                if ($util.Long)
                    (m.offset = $util.Long.fromValue(d.offset)).unsigned = true;
                else if (typeof d.offset === "string")
                    m.offset = parseInt(d.offset, 10);
                else if (typeof d.offset === "number")
                    m.offset = d.offset;
                else if (typeof d.offset === "object")
                    m.offset = new $util.LongBits(d.offset.low >>> 0, d.offset.high >>> 0).toNumber(true);
            }
            if (d.data != null) {
                if (typeof d.data === "string")
                    $util.base64.decode(d.data, m.data = $util.newBuffer($util.base64.length(d.data)), 0);
                else if (d.data.length >= 0)
                    m.data = d.data;
            }
            if (d.internalid != null) {
                m.internalid = String(d.internalid);
            }
            return m;
        };

        /**
         * Creates a plain object from a ReceiverFileMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReceiverFileMsg
         * @static
         * @param {cat.ReceiverFileMsg} m ReceiverFileMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiverFileMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.app = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.versioncode = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.versioncode = o.longs === String ? "0" : 0;
                d.account = "";
                d.context = "";
                d.filename = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.filesize = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.filesize = o.longs === String ? "0" : 0;
                d.timestamputc = "";
                d.hash = "";
                d.origin = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.offset = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.offset = o.longs === String ? "0" : 0;
                if (o.bytes === String)
                    d.data = "";
                else {
                    d.data = [];
                    if (o.bytes !== Array)
                        d.data = $util.newBuffer(d.data);
                }
            }
            if (m.app != null && m.hasOwnProperty("app")) {
                d.app = m.app;
            }
            if (m.versioncode != null && m.hasOwnProperty("versioncode")) {
                if (typeof m.versioncode === "number")
                    d.versioncode = o.longs === String ? String(m.versioncode) : m.versioncode;
                else
                    d.versioncode = o.longs === String ? $util.Long.prototype.toString.call(m.versioncode) : o.longs === Number ? new $util.LongBits(m.versioncode.low >>> 0, m.versioncode.high >>> 0).toNumber(true) : m.versioncode;
            }
            if (m.account != null && m.hasOwnProperty("account")) {
                d.account = m.account;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = m.context;
            }
            if (m.filename != null && m.hasOwnProperty("filename")) {
                d.filename = m.filename;
            }
            if (m.filesize != null && m.hasOwnProperty("filesize")) {
                if (typeof m.filesize === "number")
                    d.filesize = o.longs === String ? String(m.filesize) : m.filesize;
                else
                    d.filesize = o.longs === String ? $util.Long.prototype.toString.call(m.filesize) : o.longs === Number ? new $util.LongBits(m.filesize.low >>> 0, m.filesize.high >>> 0).toNumber(true) : m.filesize;
            }
            if (m.timestamputc != null && m.hasOwnProperty("timestamputc")) {
                d.timestamputc = m.timestamputc;
            }
            if (m.hash != null && m.hasOwnProperty("hash")) {
                d.hash = m.hash;
            }
            if (m.origin != null && m.hasOwnProperty("origin")) {
                d.origin = m.origin;
            }
            if (m.offset != null && m.hasOwnProperty("offset")) {
                if (typeof m.offset === "number")
                    d.offset = o.longs === String ? String(m.offset) : m.offset;
                else
                    d.offset = o.longs === String ? $util.Long.prototype.toString.call(m.offset) : o.longs === Number ? new $util.LongBits(m.offset.low >>> 0, m.offset.high >>> 0).toNumber(true) : m.offset;
            }
            if (m.data != null && m.hasOwnProperty("data")) {
                d.data = o.bytes === String ? $util.base64.encode(m.data, 0, m.data.length) : o.bytes === Array ? Array.prototype.slice.call(m.data) : m.data;
            }
            if (m.internalid != null && m.hasOwnProperty("internalid")) {
                d.internalid = m.internalid;
                if (o.oneofs)
                    d._internalid = "internalid";
            }
            return d;
        };

        /**
         * Converts this ReceiverFileMsg to JSON.
         * @function toJSON
         * @memberof cat.ReceiverFileMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiverFileMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiverFileMsg
         * @function getTypeUrl
         * @memberof cat.ReceiverFileMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiverFileMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReceiverFileMsg";
        };

        return ReceiverFileMsg;
    })();

    cat.ReceiverAccountMsg = (function() {

        /**
         * Properties of a ReceiverAccountMsg.
         * @memberof cat
         * @interface IReceiverAccountMsg
         * @property {string|null} [app] ReceiverAccountMsg app
         * @property {string|null} [version] ReceiverAccountMsg version
         * @property {number|null} [versioncode] ReceiverAccountMsg versioncode
         * @property {string|null} [externid] ReceiverAccountMsg externid
         * @property {string|null} [name] ReceiverAccountMsg name
         * @property {boolean|null} [capturing] ReceiverAccountMsg capturing
         * @property {string|null} [keys] ReceiverAccountMsg keys
         */

        /**
         * Constructs a new ReceiverAccountMsg.
         * @memberof cat
         * @classdesc Represents a ReceiverAccountMsg.
         * @implements IReceiverAccountMsg
         * @constructor
         * @param {cat.IReceiverAccountMsg=} [p] Properties to set
         */
        function ReceiverAccountMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReceiverAccountMsg app.
         * @member {string} app
         * @memberof cat.ReceiverAccountMsg
         * @instance
         */
        ReceiverAccountMsg.prototype.app = "";

        /**
         * ReceiverAccountMsg version.
         * @member {string} version
         * @memberof cat.ReceiverAccountMsg
         * @instance
         */
        ReceiverAccountMsg.prototype.version = "";

        /**
         * ReceiverAccountMsg versioncode.
         * @member {number} versioncode
         * @memberof cat.ReceiverAccountMsg
         * @instance
         */
        ReceiverAccountMsg.prototype.versioncode = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ReceiverAccountMsg externid.
         * @member {string} externid
         * @memberof cat.ReceiverAccountMsg
         * @instance
         */
        ReceiverAccountMsg.prototype.externid = "";

        /**
         * ReceiverAccountMsg name.
         * @member {string} name
         * @memberof cat.ReceiverAccountMsg
         * @instance
         */
        ReceiverAccountMsg.prototype.name = "";

        /**
         * ReceiverAccountMsg capturing.
         * @member {boolean} capturing
         * @memberof cat.ReceiverAccountMsg
         * @instance
         */
        ReceiverAccountMsg.prototype.capturing = false;

        /**
         * ReceiverAccountMsg keys.
         * @member {string|null|undefined} keys
         * @memberof cat.ReceiverAccountMsg
         * @instance
         */
        ReceiverAccountMsg.prototype.keys = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(ReceiverAccountMsg.prototype, "_keys", {
            get: $util.oneOfGetter($oneOfFields = ["keys"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ReceiverAccountMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReceiverAccountMsg
         * @static
         * @param {cat.IReceiverAccountMsg=} [properties] Properties to set
         * @returns {cat.ReceiverAccountMsg} ReceiverAccountMsg instance
         */
        ReceiverAccountMsg.create = function create(properties) {
            return new ReceiverAccountMsg(properties);
        };

        /**
         * Encodes the specified ReceiverAccountMsg message. Does not implicitly {@link cat.ReceiverAccountMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReceiverAccountMsg
         * @static
         * @param {cat.IReceiverAccountMsg} m ReceiverAccountMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiverAccountMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.app != null && Object.hasOwnProperty.call(m, "app"))
                w.uint32(10).string(m.app);
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(18).string(m.version);
            if (m.versioncode != null && Object.hasOwnProperty.call(m, "versioncode"))
                w.uint32(24).uint64(m.versioncode);
            if (m.externid != null && Object.hasOwnProperty.call(m, "externid"))
                w.uint32(34).string(m.externid);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(42).string(m.name);
            if (m.capturing != null && Object.hasOwnProperty.call(m, "capturing"))
                w.uint32(48).bool(m.capturing);
            if (m.keys != null && Object.hasOwnProperty.call(m, "keys"))
                w.uint32(58).string(m.keys);
            return w;
        };

        /**
         * Decodes a ReceiverAccountMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReceiverAccountMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReceiverAccountMsg} ReceiverAccountMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiverAccountMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReceiverAccountMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.app = r.string();
                        break;
                    }
                case 2: {
                        m.version = r.string();
                        break;
                    }
                case 3: {
                        m.versioncode = r.uint64();
                        break;
                    }
                case 4: {
                        m.externid = r.string();
                        break;
                    }
                case 5: {
                        m.name = r.string();
                        break;
                    }
                case 6: {
                        m.capturing = r.bool();
                        break;
                    }
                case 7: {
                        m.keys = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReceiverAccountMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReceiverAccountMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReceiverAccountMsg} ReceiverAccountMsg
         */
        ReceiverAccountMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReceiverAccountMsg)
                return d;
            var m = new $root.cat.ReceiverAccountMsg();
            if (d.app != null) {
                m.app = String(d.app);
            }
            if (d.version != null) {
                m.version = String(d.version);
            }
            if (d.versioncode != null) {
                if ($util.Long)
                    (m.versioncode = $util.Long.fromValue(d.versioncode)).unsigned = true;
                else if (typeof d.versioncode === "string")
                    m.versioncode = parseInt(d.versioncode, 10);
                else if (typeof d.versioncode === "number")
                    m.versioncode = d.versioncode;
                else if (typeof d.versioncode === "object")
                    m.versioncode = new $util.LongBits(d.versioncode.low >>> 0, d.versioncode.high >>> 0).toNumber(true);
            }
            if (d.externid != null) {
                m.externid = String(d.externid);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.capturing != null) {
                m.capturing = Boolean(d.capturing);
            }
            if (d.keys != null) {
                m.keys = String(d.keys);
            }
            return m;
        };

        /**
         * Creates a plain object from a ReceiverAccountMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReceiverAccountMsg
         * @static
         * @param {cat.ReceiverAccountMsg} m ReceiverAccountMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiverAccountMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.app = "";
                d.version = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.versioncode = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.versioncode = o.longs === String ? "0" : 0;
                d.externid = "";
                d.name = "";
                d.capturing = false;
            }
            if (m.app != null && m.hasOwnProperty("app")) {
                d.app = m.app;
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                d.version = m.version;
            }
            if (m.versioncode != null && m.hasOwnProperty("versioncode")) {
                if (typeof m.versioncode === "number")
                    d.versioncode = o.longs === String ? String(m.versioncode) : m.versioncode;
                else
                    d.versioncode = o.longs === String ? $util.Long.prototype.toString.call(m.versioncode) : o.longs === Number ? new $util.LongBits(m.versioncode.low >>> 0, m.versioncode.high >>> 0).toNumber(true) : m.versioncode;
            }
            if (m.externid != null && m.hasOwnProperty("externid")) {
                d.externid = m.externid;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.capturing != null && m.hasOwnProperty("capturing")) {
                d.capturing = m.capturing;
            }
            if (m.keys != null && m.hasOwnProperty("keys")) {
                d.keys = m.keys;
                if (o.oneofs)
                    d._keys = "keys";
            }
            return d;
        };

        /**
         * Converts this ReceiverAccountMsg to JSON.
         * @function toJSON
         * @memberof cat.ReceiverAccountMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiverAccountMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiverAccountMsg
         * @function getTypeUrl
         * @memberof cat.ReceiverAccountMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiverAccountMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReceiverAccountMsg";
        };

        return ReceiverAccountMsg;
    })();

    cat.ReceiverAccountListMsg = (function() {

        /**
         * Properties of a ReceiverAccountListMsg.
         * @memberof cat
         * @interface IReceiverAccountListMsg
         * @property {Array.<cat.IReceiverAccountMsg>|null} [accounts] ReceiverAccountListMsg accounts
         */

        /**
         * Constructs a new ReceiverAccountListMsg.
         * @memberof cat
         * @classdesc Represents a ReceiverAccountListMsg.
         * @implements IReceiverAccountListMsg
         * @constructor
         * @param {cat.IReceiverAccountListMsg=} [p] Properties to set
         */
        function ReceiverAccountListMsg(p) {
            this.accounts = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReceiverAccountListMsg accounts.
         * @member {Array.<cat.IReceiverAccountMsg>} accounts
         * @memberof cat.ReceiverAccountListMsg
         * @instance
         */
        ReceiverAccountListMsg.prototype.accounts = $util.emptyArray;

        /**
         * Creates a new ReceiverAccountListMsg instance using the specified properties.
         * @function create
         * @memberof cat.ReceiverAccountListMsg
         * @static
         * @param {cat.IReceiverAccountListMsg=} [properties] Properties to set
         * @returns {cat.ReceiverAccountListMsg} ReceiverAccountListMsg instance
         */
        ReceiverAccountListMsg.create = function create(properties) {
            return new ReceiverAccountListMsg(properties);
        };

        /**
         * Encodes the specified ReceiverAccountListMsg message. Does not implicitly {@link cat.ReceiverAccountListMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ReceiverAccountListMsg
         * @static
         * @param {cat.IReceiverAccountListMsg} m ReceiverAccountListMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiverAccountListMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.accounts != null && m.accounts.length) {
                for (var i = 0; i < m.accounts.length; ++i)
                    $root.cat.ReceiverAccountMsg.encode(m.accounts[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ReceiverAccountListMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ReceiverAccountListMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ReceiverAccountListMsg} ReceiverAccountListMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiverAccountListMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ReceiverAccountListMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (!(m.accounts && m.accounts.length))
                            m.accounts = [];
                        m.accounts.push($root.cat.ReceiverAccountMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReceiverAccountListMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ReceiverAccountListMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ReceiverAccountListMsg} ReceiverAccountListMsg
         */
        ReceiverAccountListMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ReceiverAccountListMsg)
                return d;
            var m = new $root.cat.ReceiverAccountListMsg();
            if (d.accounts) {
                if (!Array.isArray(d.accounts))
                    throw TypeError(".cat.ReceiverAccountListMsg.accounts: array expected");
                m.accounts = [];
                for (var i = 0; i < d.accounts.length; ++i) {
                    if (typeof d.accounts[i] !== "object")
                        throw TypeError(".cat.ReceiverAccountListMsg.accounts: object expected");
                    m.accounts[i] = $root.cat.ReceiverAccountMsg.fromObject(d.accounts[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ReceiverAccountListMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ReceiverAccountListMsg
         * @static
         * @param {cat.ReceiverAccountListMsg} m ReceiverAccountListMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiverAccountListMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.accounts = [];
            }
            if (m.accounts && m.accounts.length) {
                d.accounts = [];
                for (var j = 0; j < m.accounts.length; ++j) {
                    d.accounts[j] = $root.cat.ReceiverAccountMsg.toObject(m.accounts[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ReceiverAccountListMsg to JSON.
         * @function toJSON
         * @memberof cat.ReceiverAccountListMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiverAccountListMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiverAccountListMsg
         * @function getTypeUrl
         * @memberof cat.ReceiverAccountListMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiverAccountListMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ReceiverAccountListMsg";
        };

        return ReceiverAccountListMsg;
    })();

    cat.DevicePropertiesMsg = (function() {

        /**
         * Properties of a DevicePropertiesMsg.
         * @memberof cat
         * @interface IDevicePropertiesMsg
         * @property {number|null} [timestamp] DevicePropertiesMsg timestamp
         * @property {string|null} [imei] DevicePropertiesMsg imei
         * @property {string|null} [brand] DevicePropertiesMsg brand
         * @property {string|null} [name] DevicePropertiesMsg name
         * @property {string|null} [model] DevicePropertiesMsg model
         * @property {string|null} [abis] DevicePropertiesMsg abis
         * @property {number|null} [storage] DevicePropertiesMsg storage
         * @property {number|null} [usage] DevicePropertiesMsg usage
         * @property {number|null} [freespace] DevicePropertiesMsg freespace
         * @property {boolean|null} [batterycharging] DevicePropertiesMsg batterycharging
         * @property {number|null} [batterylevel] DevicePropertiesMsg batterylevel
         * @property {string|null} [osversion] DevicePropertiesMsg osversion
         */

        /**
         * Constructs a new DevicePropertiesMsg.
         * @memberof cat
         * @classdesc Represents a DevicePropertiesMsg.
         * @implements IDevicePropertiesMsg
         * @constructor
         * @param {cat.IDevicePropertiesMsg=} [p] Properties to set
         */
        function DevicePropertiesMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DevicePropertiesMsg timestamp.
         * @member {number} timestamp
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DevicePropertiesMsg imei.
         * @member {string} imei
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.imei = "";

        /**
         * DevicePropertiesMsg brand.
         * @member {string} brand
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.brand = "";

        /**
         * DevicePropertiesMsg name.
         * @member {string} name
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.name = "";

        /**
         * DevicePropertiesMsg model.
         * @member {string} model
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.model = "";

        /**
         * DevicePropertiesMsg abis.
         * @member {string} abis
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.abis = "";

        /**
         * DevicePropertiesMsg storage.
         * @member {number} storage
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.storage = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DevicePropertiesMsg usage.
         * @member {number} usage
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.usage = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DevicePropertiesMsg freespace.
         * @member {number} freespace
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.freespace = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DevicePropertiesMsg batterycharging.
         * @member {boolean} batterycharging
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.batterycharging = false;

        /**
         * DevicePropertiesMsg batterylevel.
         * @member {number} batterylevel
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.batterylevel = 0;

        /**
         * DevicePropertiesMsg osversion.
         * @member {string} osversion
         * @memberof cat.DevicePropertiesMsg
         * @instance
         */
        DevicePropertiesMsg.prototype.osversion = "";

        /**
         * Creates a new DevicePropertiesMsg instance using the specified properties.
         * @function create
         * @memberof cat.DevicePropertiesMsg
         * @static
         * @param {cat.IDevicePropertiesMsg=} [properties] Properties to set
         * @returns {cat.DevicePropertiesMsg} DevicePropertiesMsg instance
         */
        DevicePropertiesMsg.create = function create(properties) {
            return new DevicePropertiesMsg(properties);
        };

        /**
         * Encodes the specified DevicePropertiesMsg message. Does not implicitly {@link cat.DevicePropertiesMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.DevicePropertiesMsg
         * @static
         * @param {cat.IDevicePropertiesMsg} m DevicePropertiesMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DevicePropertiesMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                w.uint32(8).uint64(m.timestamp);
            if (m.imei != null && Object.hasOwnProperty.call(m, "imei"))
                w.uint32(18).string(m.imei);
            if (m.brand != null && Object.hasOwnProperty.call(m, "brand"))
                w.uint32(26).string(m.brand);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.model != null && Object.hasOwnProperty.call(m, "model"))
                w.uint32(42).string(m.model);
            if (m.abis != null && Object.hasOwnProperty.call(m, "abis"))
                w.uint32(50).string(m.abis);
            if (m.storage != null && Object.hasOwnProperty.call(m, "storage"))
                w.uint32(80).uint64(m.storage);
            if (m.usage != null && Object.hasOwnProperty.call(m, "usage"))
                w.uint32(88).uint64(m.usage);
            if (m.freespace != null && Object.hasOwnProperty.call(m, "freespace"))
                w.uint32(96).uint64(m.freespace);
            if (m.batterycharging != null && Object.hasOwnProperty.call(m, "batterycharging"))
                w.uint32(120).bool(m.batterycharging);
            if (m.batterylevel != null && Object.hasOwnProperty.call(m, "batterylevel"))
                w.uint32(128).uint32(m.batterylevel);
            if (m.osversion != null && Object.hasOwnProperty.call(m, "osversion"))
                w.uint32(162).string(m.osversion);
            return w;
        };

        /**
         * Decodes a DevicePropertiesMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.DevicePropertiesMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.DevicePropertiesMsg} DevicePropertiesMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DevicePropertiesMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.DevicePropertiesMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.timestamp = r.uint64();
                        break;
                    }
                case 2: {
                        m.imei = r.string();
                        break;
                    }
                case 3: {
                        m.brand = r.string();
                        break;
                    }
                case 4: {
                        m.name = r.string();
                        break;
                    }
                case 5: {
                        m.model = r.string();
                        break;
                    }
                case 6: {
                        m.abis = r.string();
                        break;
                    }
                case 10: {
                        m.storage = r.uint64();
                        break;
                    }
                case 11: {
                        m.usage = r.uint64();
                        break;
                    }
                case 12: {
                        m.freespace = r.uint64();
                        break;
                    }
                case 15: {
                        m.batterycharging = r.bool();
                        break;
                    }
                case 16: {
                        m.batterylevel = r.uint32();
                        break;
                    }
                case 20: {
                        m.osversion = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DevicePropertiesMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.DevicePropertiesMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.DevicePropertiesMsg} DevicePropertiesMsg
         */
        DevicePropertiesMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.DevicePropertiesMsg)
                return d;
            var m = new $root.cat.DevicePropertiesMsg();
            if (d.timestamp != null) {
                if ($util.Long)
                    (m.timestamp = $util.Long.fromValue(d.timestamp)).unsigned = true;
                else if (typeof d.timestamp === "string")
                    m.timestamp = parseInt(d.timestamp, 10);
                else if (typeof d.timestamp === "number")
                    m.timestamp = d.timestamp;
                else if (typeof d.timestamp === "object")
                    m.timestamp = new $util.LongBits(d.timestamp.low >>> 0, d.timestamp.high >>> 0).toNumber(true);
            }
            if (d.imei != null) {
                m.imei = String(d.imei);
            }
            if (d.brand != null) {
                m.brand = String(d.brand);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.model != null) {
                m.model = String(d.model);
            }
            if (d.abis != null) {
                m.abis = String(d.abis);
            }
            if (d.storage != null) {
                if ($util.Long)
                    (m.storage = $util.Long.fromValue(d.storage)).unsigned = true;
                else if (typeof d.storage === "string")
                    m.storage = parseInt(d.storage, 10);
                else if (typeof d.storage === "number")
                    m.storage = d.storage;
                else if (typeof d.storage === "object")
                    m.storage = new $util.LongBits(d.storage.low >>> 0, d.storage.high >>> 0).toNumber(true);
            }
            if (d.usage != null) {
                if ($util.Long)
                    (m.usage = $util.Long.fromValue(d.usage)).unsigned = true;
                else if (typeof d.usage === "string")
                    m.usage = parseInt(d.usage, 10);
                else if (typeof d.usage === "number")
                    m.usage = d.usage;
                else if (typeof d.usage === "object")
                    m.usage = new $util.LongBits(d.usage.low >>> 0, d.usage.high >>> 0).toNumber(true);
            }
            if (d.freespace != null) {
                if ($util.Long)
                    (m.freespace = $util.Long.fromValue(d.freespace)).unsigned = true;
                else if (typeof d.freespace === "string")
                    m.freespace = parseInt(d.freespace, 10);
                else if (typeof d.freespace === "number")
                    m.freespace = d.freespace;
                else if (typeof d.freespace === "object")
                    m.freespace = new $util.LongBits(d.freespace.low >>> 0, d.freespace.high >>> 0).toNumber(true);
            }
            if (d.batterycharging != null) {
                m.batterycharging = Boolean(d.batterycharging);
            }
            if (d.batterylevel != null) {
                m.batterylevel = d.batterylevel >>> 0;
            }
            if (d.osversion != null) {
                m.osversion = String(d.osversion);
            }
            return m;
        };

        /**
         * Creates a plain object from a DevicePropertiesMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.DevicePropertiesMsg
         * @static
         * @param {cat.DevicePropertiesMsg} m DevicePropertiesMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DevicePropertiesMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timestamp = o.longs === String ? "0" : 0;
                d.imei = "";
                d.brand = "";
                d.name = "";
                d.model = "";
                d.abis = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.storage = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.storage = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.usage = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.usage = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.freespace = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.freespace = o.longs === String ? "0" : 0;
                d.batterycharging = false;
                d.batterylevel = 0;
                d.osversion = "";
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                if (typeof m.timestamp === "number")
                    d.timestamp = o.longs === String ? String(m.timestamp) : m.timestamp;
                else
                    d.timestamp = o.longs === String ? $util.Long.prototype.toString.call(m.timestamp) : o.longs === Number ? new $util.LongBits(m.timestamp.low >>> 0, m.timestamp.high >>> 0).toNumber(true) : m.timestamp;
            }
            if (m.imei != null && m.hasOwnProperty("imei")) {
                d.imei = m.imei;
            }
            if (m.brand != null && m.hasOwnProperty("brand")) {
                d.brand = m.brand;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.model != null && m.hasOwnProperty("model")) {
                d.model = m.model;
            }
            if (m.abis != null && m.hasOwnProperty("abis")) {
                d.abis = m.abis;
            }
            if (m.storage != null && m.hasOwnProperty("storage")) {
                if (typeof m.storage === "number")
                    d.storage = o.longs === String ? String(m.storage) : m.storage;
                else
                    d.storage = o.longs === String ? $util.Long.prototype.toString.call(m.storage) : o.longs === Number ? new $util.LongBits(m.storage.low >>> 0, m.storage.high >>> 0).toNumber(true) : m.storage;
            }
            if (m.usage != null && m.hasOwnProperty("usage")) {
                if (typeof m.usage === "number")
                    d.usage = o.longs === String ? String(m.usage) : m.usage;
                else
                    d.usage = o.longs === String ? $util.Long.prototype.toString.call(m.usage) : o.longs === Number ? new $util.LongBits(m.usage.low >>> 0, m.usage.high >>> 0).toNumber(true) : m.usage;
            }
            if (m.freespace != null && m.hasOwnProperty("freespace")) {
                if (typeof m.freespace === "number")
                    d.freespace = o.longs === String ? String(m.freespace) : m.freespace;
                else
                    d.freespace = o.longs === String ? $util.Long.prototype.toString.call(m.freespace) : o.longs === Number ? new $util.LongBits(m.freespace.low >>> 0, m.freespace.high >>> 0).toNumber(true) : m.freespace;
            }
            if (m.batterycharging != null && m.hasOwnProperty("batterycharging")) {
                d.batterycharging = m.batterycharging;
            }
            if (m.batterylevel != null && m.hasOwnProperty("batterylevel")) {
                d.batterylevel = m.batterylevel;
            }
            if (m.osversion != null && m.hasOwnProperty("osversion")) {
                d.osversion = m.osversion;
            }
            return d;
        };

        /**
         * Converts this DevicePropertiesMsg to JSON.
         * @function toJSON
         * @memberof cat.DevicePropertiesMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DevicePropertiesMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DevicePropertiesMsg
         * @function getTypeUrl
         * @memberof cat.DevicePropertiesMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DevicePropertiesMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.DevicePropertiesMsg";
        };

        return DevicePropertiesMsg;
    })();

    cat.DeviceNotificationMsg = (function() {

        /**
         * Properties of a DeviceNotificationMsg.
         * @memberof cat
         * @interface IDeviceNotificationMsg
         * @property {number|null} [timestamp] DeviceNotificationMsg timestamp
         * @property {string|null} [text] DeviceNotificationMsg text
         */

        /**
         * Constructs a new DeviceNotificationMsg.
         * @memberof cat
         * @classdesc Represents a DeviceNotificationMsg.
         * @implements IDeviceNotificationMsg
         * @constructor
         * @param {cat.IDeviceNotificationMsg=} [p] Properties to set
         */
        function DeviceNotificationMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DeviceNotificationMsg timestamp.
         * @member {number} timestamp
         * @memberof cat.DeviceNotificationMsg
         * @instance
         */
        DeviceNotificationMsg.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DeviceNotificationMsg text.
         * @member {string} text
         * @memberof cat.DeviceNotificationMsg
         * @instance
         */
        DeviceNotificationMsg.prototype.text = "";

        /**
         * Creates a new DeviceNotificationMsg instance using the specified properties.
         * @function create
         * @memberof cat.DeviceNotificationMsg
         * @static
         * @param {cat.IDeviceNotificationMsg=} [properties] Properties to set
         * @returns {cat.DeviceNotificationMsg} DeviceNotificationMsg instance
         */
        DeviceNotificationMsg.create = function create(properties) {
            return new DeviceNotificationMsg(properties);
        };

        /**
         * Encodes the specified DeviceNotificationMsg message. Does not implicitly {@link cat.DeviceNotificationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.DeviceNotificationMsg
         * @static
         * @param {cat.IDeviceNotificationMsg} m DeviceNotificationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeviceNotificationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                w.uint32(8).uint64(m.timestamp);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(18).string(m.text);
            return w;
        };

        /**
         * Decodes a DeviceNotificationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.DeviceNotificationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.DeviceNotificationMsg} DeviceNotificationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeviceNotificationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.DeviceNotificationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.timestamp = r.uint64();
                        break;
                    }
                case 2: {
                        m.text = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DeviceNotificationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.DeviceNotificationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.DeviceNotificationMsg} DeviceNotificationMsg
         */
        DeviceNotificationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.DeviceNotificationMsg)
                return d;
            var m = new $root.cat.DeviceNotificationMsg();
            if (d.timestamp != null) {
                if ($util.Long)
                    (m.timestamp = $util.Long.fromValue(d.timestamp)).unsigned = true;
                else if (typeof d.timestamp === "string")
                    m.timestamp = parseInt(d.timestamp, 10);
                else if (typeof d.timestamp === "number")
                    m.timestamp = d.timestamp;
                else if (typeof d.timestamp === "object")
                    m.timestamp = new $util.LongBits(d.timestamp.low >>> 0, d.timestamp.high >>> 0).toNumber(true);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            return m;
        };

        /**
         * Creates a plain object from a DeviceNotificationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.DeviceNotificationMsg
         * @static
         * @param {cat.DeviceNotificationMsg} m DeviceNotificationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeviceNotificationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.timestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timestamp = o.longs === String ? "0" : 0;
                d.text = "";
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                if (typeof m.timestamp === "number")
                    d.timestamp = o.longs === String ? String(m.timestamp) : m.timestamp;
                else
                    d.timestamp = o.longs === String ? $util.Long.prototype.toString.call(m.timestamp) : o.longs === Number ? new $util.LongBits(m.timestamp.low >>> 0, m.timestamp.high >>> 0).toNumber(true) : m.timestamp;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            return d;
        };

        /**
         * Converts this DeviceNotificationMsg to JSON.
         * @function toJSON
         * @memberof cat.DeviceNotificationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeviceNotificationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeviceNotificationMsg
         * @function getTypeUrl
         * @memberof cat.DeviceNotificationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeviceNotificationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.DeviceNotificationMsg";
        };

        return DeviceNotificationMsg;
    })();

    cat.MediaLinkMsg = (function() {

        /**
         * Properties of a MediaLinkMsg.
         * @memberof cat
         * @interface IMediaLinkMsg
         * @property {string|null} [appid] MediaLinkMsg appid
         * @property {string|null} [refid] MediaLinkMsg refid
         * @property {number|null} [created] MediaLinkMsg created
         * @property {number|null} [lastmodified] MediaLinkMsg lastmodified
         * @property {string|null} [start] MediaLinkMsg start
         * @property {string|null} [end] MediaLinkMsg end
         * @property {cat.IRawMsg|null} [raw] MediaLinkMsg raw
         * @property {cat.MediaLinkType|null} [type] MediaLinkMsg type
         */

        /**
         * Constructs a new MediaLinkMsg.
         * @memberof cat
         * @classdesc Represents a MediaLinkMsg.
         * @implements IMediaLinkMsg
         * @constructor
         * @param {cat.IMediaLinkMsg=} [p] Properties to set
         */
        function MediaLinkMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * MediaLinkMsg appid.
         * @member {string} appid
         * @memberof cat.MediaLinkMsg
         * @instance
         */
        MediaLinkMsg.prototype.appid = "";

        /**
         * MediaLinkMsg refid.
         * @member {string} refid
         * @memberof cat.MediaLinkMsg
         * @instance
         */
        MediaLinkMsg.prototype.refid = "";

        /**
         * MediaLinkMsg created.
         * @member {number} created
         * @memberof cat.MediaLinkMsg
         * @instance
         */
        MediaLinkMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MediaLinkMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.MediaLinkMsg
         * @instance
         */
        MediaLinkMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MediaLinkMsg start.
         * @member {string|null|undefined} start
         * @memberof cat.MediaLinkMsg
         * @instance
         */
        MediaLinkMsg.prototype.start = null;

        /**
         * MediaLinkMsg end.
         * @member {string|null|undefined} end
         * @memberof cat.MediaLinkMsg
         * @instance
         */
        MediaLinkMsg.prototype.end = null;

        /**
         * MediaLinkMsg raw.
         * @member {cat.IRawMsg|null|undefined} raw
         * @memberof cat.MediaLinkMsg
         * @instance
         */
        MediaLinkMsg.prototype.raw = null;

        /**
         * MediaLinkMsg type.
         * @member {cat.MediaLinkType|null|undefined} type
         * @memberof cat.MediaLinkMsg
         * @instance
         */
        MediaLinkMsg.prototype.type = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaLinkMsg.prototype, "_start", {
            get: $util.oneOfGetter($oneOfFields = ["start"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaLinkMsg.prototype, "_end", {
            get: $util.oneOfGetter($oneOfFields = ["end"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(MediaLinkMsg.prototype, "_type", {
            get: $util.oneOfGetter($oneOfFields = ["type"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MediaLinkMsg instance using the specified properties.
         * @function create
         * @memberof cat.MediaLinkMsg
         * @static
         * @param {cat.IMediaLinkMsg=} [properties] Properties to set
         * @returns {cat.MediaLinkMsg} MediaLinkMsg instance
         */
        MediaLinkMsg.create = function create(properties) {
            return new MediaLinkMsg(properties);
        };

        /**
         * Encodes the specified MediaLinkMsg message. Does not implicitly {@link cat.MediaLinkMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.MediaLinkMsg
         * @static
         * @param {cat.IMediaLinkMsg} m MediaLinkMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MediaLinkMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.appid != null && Object.hasOwnProperty.call(m, "appid"))
                w.uint32(10).string(m.appid);
            if (m.refid != null && Object.hasOwnProperty.call(m, "refid"))
                w.uint32(18).string(m.refid);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(32).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(40).uint64(m.lastmodified);
            if (m.start != null && Object.hasOwnProperty.call(m, "start"))
                w.uint32(50).string(m.start);
            if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                w.uint32(58).string(m.end);
            if (m.raw != null && Object.hasOwnProperty.call(m, "raw"))
                $root.cat.RawMsg.encode(m.raw, w.uint32(66).fork()).ldelim();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(72).int32(m.type);
            return w;
        };

        /**
         * Decodes a MediaLinkMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.MediaLinkMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.MediaLinkMsg} MediaLinkMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MediaLinkMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.MediaLinkMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.appid = r.string();
                        break;
                    }
                case 2: {
                        m.refid = r.string();
                        break;
                    }
                case 4: {
                        m.created = r.uint64();
                        break;
                    }
                case 5: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 6: {
                        m.start = r.string();
                        break;
                    }
                case 7: {
                        m.end = r.string();
                        break;
                    }
                case 8: {
                        m.raw = $root.cat.RawMsg.decode(r, r.uint32());
                        break;
                    }
                case 9: {
                        m.type = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a MediaLinkMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.MediaLinkMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.MediaLinkMsg} MediaLinkMsg
         */
        MediaLinkMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.MediaLinkMsg)
                return d;
            var m = new $root.cat.MediaLinkMsg();
            if (d.appid != null) {
                m.appid = String(d.appid);
            }
            if (d.refid != null) {
                m.refid = String(d.refid);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.start != null) {
                m.start = String(d.start);
            }
            if (d.end != null) {
                m.end = String(d.end);
            }
            if (d.raw != null) {
                if (typeof d.raw !== "object")
                    throw TypeError(".cat.MediaLinkMsg.raw: object expected");
                m.raw = $root.cat.RawMsg.fromObject(d.raw);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "MEDIALINK_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "MEDIALINK_AVATAR_ACCOUNT":
            case 1:
                m.type = 1;
                break;
            case "MEDIALINK_AVATAR_CONVERSATION":
            case 2:
                m.type = 2;
                break;
            case "MEDIALINK_AVATAR_CONTACT":
            case 3:
                m.type = 3;
                break;
            case "MEDIALINK_ATTACHMENT":
            case 4:
                m.type = 4;
                break;
            case "MEDIALINK_AVATAR_TOPIC":
            case 5:
                m.type = 5;
                break;
            case "MEDIALINK_AVATAR_SERVER":
            case 6:
                m.type = 6;
                break;
            case "MEDIALINK_EMOJI":
            case 7:
                m.type = 7;
                break;
            }
            return m;
        };

        /**
         * Creates a plain object from a MediaLinkMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.MediaLinkMsg
         * @static
         * @param {cat.MediaLinkMsg} m MediaLinkMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MediaLinkMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.appid = "";
                d.refid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.raw = null;
            }
            if (m.appid != null && m.hasOwnProperty("appid")) {
                d.appid = m.appid;
            }
            if (m.refid != null && m.hasOwnProperty("refid")) {
                d.refid = m.refid;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.start != null && m.hasOwnProperty("start")) {
                d.start = m.start;
                if (o.oneofs)
                    d._start = "start";
            }
            if (m.end != null && m.hasOwnProperty("end")) {
                d.end = m.end;
                if (o.oneofs)
                    d._end = "end";
            }
            if (m.raw != null && m.hasOwnProperty("raw")) {
                d.raw = $root.cat.RawMsg.toObject(m.raw, o);
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.MediaLinkType[m.type] === undefined ? m.type : $root.cat.MediaLinkType[m.type] : m.type;
                if (o.oneofs)
                    d._type = "type";
            }
            return d;
        };

        /**
         * Converts this MediaLinkMsg to JSON.
         * @function toJSON
         * @memberof cat.MediaLinkMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MediaLinkMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MediaLinkMsg
         * @function getTypeUrl
         * @memberof cat.MediaLinkMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MediaLinkMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.MediaLinkMsg";
        };

        return MediaLinkMsg;
    })();

    /**
     * MediaLinkType enum.
     * @name cat.MediaLinkType
     * @enum {number}
     * @property {number} MEDIALINK_UNKNOWN=0 MEDIALINK_UNKNOWN value
     * @property {number} MEDIALINK_AVATAR_ACCOUNT=1 MEDIALINK_AVATAR_ACCOUNT value
     * @property {number} MEDIALINK_AVATAR_CONVERSATION=2 MEDIALINK_AVATAR_CONVERSATION value
     * @property {number} MEDIALINK_AVATAR_CONTACT=3 MEDIALINK_AVATAR_CONTACT value
     * @property {number} MEDIALINK_ATTACHMENT=4 MEDIALINK_ATTACHMENT value
     * @property {number} MEDIALINK_AVATAR_TOPIC=5 MEDIALINK_AVATAR_TOPIC value
     * @property {number} MEDIALINK_AVATAR_SERVER=6 MEDIALINK_AVATAR_SERVER value
     * @property {number} MEDIALINK_EMOJI=7 MEDIALINK_EMOJI value
     */
    cat.MediaLinkType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MEDIALINK_UNKNOWN"] = 0;
        values[valuesById[1] = "MEDIALINK_AVATAR_ACCOUNT"] = 1;
        values[valuesById[2] = "MEDIALINK_AVATAR_CONVERSATION"] = 2;
        values[valuesById[3] = "MEDIALINK_AVATAR_CONTACT"] = 3;
        values[valuesById[4] = "MEDIALINK_ATTACHMENT"] = 4;
        values[valuesById[5] = "MEDIALINK_AVATAR_TOPIC"] = 5;
        values[valuesById[6] = "MEDIALINK_AVATAR_SERVER"] = 6;
        values[valuesById[7] = "MEDIALINK_EMOJI"] = 7;
        return values;
    })();

    cat.PackageMsg = (function() {

        /**
         * Properties of a PackageMsg.
         * @memberof cat
         * @interface IPackageMsg
         * @property {string|null} [name] PackageMsg name
         * @property {boolean|null} [concurrentaccounts] PackageMsg concurrentaccounts
         * @property {Array.<cat.IPackageVersionMsg>|null} [versions] PackageMsg versions
         * @property {cat.IPackageShadowMsg|null} [shadow] PackageMsg shadow
         * @property {Array.<cat.IPackageDatabaseMsg>|null} [databases] PackageMsg databases
         * @property {Array.<cat.IPackageWatchMsg>|null} [watches] PackageMsg watches
         * @property {cat.IPackageDownloadMsg|null} [download] PackageMsg download
         * @property {boolean|null} [backfill] PackageMsg backfill
         * @property {Array.<cat.IPackageMemoryMsg>|null} [memory] PackageMsg memory
         * @property {Array.<cat.IPackageHookMsg>|null} [hooks] PackageMsg hooks
         * @property {Array.<cat.IPackagePatchMsg>|null} [patches] PackageMsg patches
         */

        /**
         * Constructs a new PackageMsg.
         * @memberof cat
         * @classdesc Represents a PackageMsg.
         * @implements IPackageMsg
         * @constructor
         * @param {cat.IPackageMsg=} [p] Properties to set
         */
        function PackageMsg(p) {
            this.versions = [];
            this.databases = [];
            this.watches = [];
            this.memory = [];
            this.hooks = [];
            this.patches = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PackageMsg name.
         * @member {string} name
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.name = "";

        /**
         * PackageMsg concurrentaccounts.
         * @member {boolean} concurrentaccounts
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.concurrentaccounts = false;

        /**
         * PackageMsg versions.
         * @member {Array.<cat.IPackageVersionMsg>} versions
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.versions = $util.emptyArray;

        /**
         * PackageMsg shadow.
         * @member {cat.IPackageShadowMsg|null|undefined} shadow
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.shadow = null;

        /**
         * PackageMsg databases.
         * @member {Array.<cat.IPackageDatabaseMsg>} databases
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.databases = $util.emptyArray;

        /**
         * PackageMsg watches.
         * @member {Array.<cat.IPackageWatchMsg>} watches
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.watches = $util.emptyArray;

        /**
         * PackageMsg download.
         * @member {cat.IPackageDownloadMsg|null|undefined} download
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.download = null;

        /**
         * PackageMsg backfill.
         * @member {boolean} backfill
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.backfill = false;

        /**
         * PackageMsg memory.
         * @member {Array.<cat.IPackageMemoryMsg>} memory
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.memory = $util.emptyArray;

        /**
         * PackageMsg hooks.
         * @member {Array.<cat.IPackageHookMsg>} hooks
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.hooks = $util.emptyArray;

        /**
         * PackageMsg patches.
         * @member {Array.<cat.IPackagePatchMsg>} patches
         * @memberof cat.PackageMsg
         * @instance
         */
        PackageMsg.prototype.patches = $util.emptyArray;

        /**
         * Creates a new PackageMsg instance using the specified properties.
         * @function create
         * @memberof cat.PackageMsg
         * @static
         * @param {cat.IPackageMsg=} [properties] Properties to set
         * @returns {cat.PackageMsg} PackageMsg instance
         */
        PackageMsg.create = function create(properties) {
            return new PackageMsg(properties);
        };

        /**
         * Encodes the specified PackageMsg message. Does not implicitly {@link cat.PackageMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PackageMsg
         * @static
         * @param {cat.IPackageMsg} m PackageMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.concurrentaccounts != null && Object.hasOwnProperty.call(m, "concurrentaccounts"))
                w.uint32(16).bool(m.concurrentaccounts);
            if (m.versions != null && m.versions.length) {
                for (var i = 0; i < m.versions.length; ++i)
                    $root.cat.PackageVersionMsg.encode(m.versions[i], w.uint32(26).fork()).ldelim();
            }
            if (m.shadow != null && Object.hasOwnProperty.call(m, "shadow"))
                $root.cat.PackageShadowMsg.encode(m.shadow, w.uint32(34).fork()).ldelim();
            if (m.databases != null && m.databases.length) {
                for (var i = 0; i < m.databases.length; ++i)
                    $root.cat.PackageDatabaseMsg.encode(m.databases[i], w.uint32(42).fork()).ldelim();
            }
            if (m.watches != null && m.watches.length) {
                for (var i = 0; i < m.watches.length; ++i)
                    $root.cat.PackageWatchMsg.encode(m.watches[i], w.uint32(50).fork()).ldelim();
            }
            if (m.download != null && Object.hasOwnProperty.call(m, "download"))
                $root.cat.PackageDownloadMsg.encode(m.download, w.uint32(58).fork()).ldelim();
            if (m.backfill != null && Object.hasOwnProperty.call(m, "backfill"))
                w.uint32(64).bool(m.backfill);
            if (m.memory != null && m.memory.length) {
                for (var i = 0; i < m.memory.length; ++i)
                    $root.cat.PackageMemoryMsg.encode(m.memory[i], w.uint32(74).fork()).ldelim();
            }
            if (m.hooks != null && m.hooks.length) {
                for (var i = 0; i < m.hooks.length; ++i)
                    $root.cat.PackageHookMsg.encode(m.hooks[i], w.uint32(82).fork()).ldelim();
            }
            if (m.patches != null && m.patches.length) {
                for (var i = 0; i < m.patches.length; ++i)
                    $root.cat.PackagePatchMsg.encode(m.patches[i], w.uint32(90).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a PackageMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PackageMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PackageMsg} PackageMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PackageMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 2: {
                        m.concurrentaccounts = r.bool();
                        break;
                    }
                case 3: {
                        if (!(m.versions && m.versions.length))
                            m.versions = [];
                        m.versions.push($root.cat.PackageVersionMsg.decode(r, r.uint32()));
                        break;
                    }
                case 4: {
                        m.shadow = $root.cat.PackageShadowMsg.decode(r, r.uint32());
                        break;
                    }
                case 5: {
                        if (!(m.databases && m.databases.length))
                            m.databases = [];
                        m.databases.push($root.cat.PackageDatabaseMsg.decode(r, r.uint32()));
                        break;
                    }
                case 6: {
                        if (!(m.watches && m.watches.length))
                            m.watches = [];
                        m.watches.push($root.cat.PackageWatchMsg.decode(r, r.uint32()));
                        break;
                    }
                case 7: {
                        m.download = $root.cat.PackageDownloadMsg.decode(r, r.uint32());
                        break;
                    }
                case 8: {
                        m.backfill = r.bool();
                        break;
                    }
                case 9: {
                        if (!(m.memory && m.memory.length))
                            m.memory = [];
                        m.memory.push($root.cat.PackageMemoryMsg.decode(r, r.uint32()));
                        break;
                    }
                case 10: {
                        if (!(m.hooks && m.hooks.length))
                            m.hooks = [];
                        m.hooks.push($root.cat.PackageHookMsg.decode(r, r.uint32()));
                        break;
                    }
                case 11: {
                        if (!(m.patches && m.patches.length))
                            m.patches = [];
                        m.patches.push($root.cat.PackagePatchMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PackageMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PackageMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PackageMsg} PackageMsg
         */
        PackageMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PackageMsg)
                return d;
            var m = new $root.cat.PackageMsg();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.concurrentaccounts != null) {
                m.concurrentaccounts = Boolean(d.concurrentaccounts);
            }
            if (d.versions) {
                if (!Array.isArray(d.versions))
                    throw TypeError(".cat.PackageMsg.versions: array expected");
                m.versions = [];
                for (var i = 0; i < d.versions.length; ++i) {
                    if (typeof d.versions[i] !== "object")
                        throw TypeError(".cat.PackageMsg.versions: object expected");
                    m.versions[i] = $root.cat.PackageVersionMsg.fromObject(d.versions[i]);
                }
            }
            if (d.shadow != null) {
                if (typeof d.shadow !== "object")
                    throw TypeError(".cat.PackageMsg.shadow: object expected");
                m.shadow = $root.cat.PackageShadowMsg.fromObject(d.shadow);
            }
            if (d.databases) {
                if (!Array.isArray(d.databases))
                    throw TypeError(".cat.PackageMsg.databases: array expected");
                m.databases = [];
                for (var i = 0; i < d.databases.length; ++i) {
                    if (typeof d.databases[i] !== "object")
                        throw TypeError(".cat.PackageMsg.databases: object expected");
                    m.databases[i] = $root.cat.PackageDatabaseMsg.fromObject(d.databases[i]);
                }
            }
            if (d.watches) {
                if (!Array.isArray(d.watches))
                    throw TypeError(".cat.PackageMsg.watches: array expected");
                m.watches = [];
                for (var i = 0; i < d.watches.length; ++i) {
                    if (typeof d.watches[i] !== "object")
                        throw TypeError(".cat.PackageMsg.watches: object expected");
                    m.watches[i] = $root.cat.PackageWatchMsg.fromObject(d.watches[i]);
                }
            }
            if (d.download != null) {
                if (typeof d.download !== "object")
                    throw TypeError(".cat.PackageMsg.download: object expected");
                m.download = $root.cat.PackageDownloadMsg.fromObject(d.download);
            }
            if (d.backfill != null) {
                m.backfill = Boolean(d.backfill);
            }
            if (d.memory) {
                if (!Array.isArray(d.memory))
                    throw TypeError(".cat.PackageMsg.memory: array expected");
                m.memory = [];
                for (var i = 0; i < d.memory.length; ++i) {
                    if (typeof d.memory[i] !== "object")
                        throw TypeError(".cat.PackageMsg.memory: object expected");
                    m.memory[i] = $root.cat.PackageMemoryMsg.fromObject(d.memory[i]);
                }
            }
            if (d.hooks) {
                if (!Array.isArray(d.hooks))
                    throw TypeError(".cat.PackageMsg.hooks: array expected");
                m.hooks = [];
                for (var i = 0; i < d.hooks.length; ++i) {
                    if (typeof d.hooks[i] !== "object")
                        throw TypeError(".cat.PackageMsg.hooks: object expected");
                    m.hooks[i] = $root.cat.PackageHookMsg.fromObject(d.hooks[i]);
                }
            }
            if (d.patches) {
                if (!Array.isArray(d.patches))
                    throw TypeError(".cat.PackageMsg.patches: array expected");
                m.patches = [];
                for (var i = 0; i < d.patches.length; ++i) {
                    if (typeof d.patches[i] !== "object")
                        throw TypeError(".cat.PackageMsg.patches: object expected");
                    m.patches[i] = $root.cat.PackagePatchMsg.fromObject(d.patches[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PackageMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PackageMsg
         * @static
         * @param {cat.PackageMsg} m PackageMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.versions = [];
                d.databases = [];
                d.watches = [];
                d.memory = [];
                d.hooks = [];
                d.patches = [];
            }
            if (o.defaults) {
                d.name = "";
                d.concurrentaccounts = false;
                d.shadow = null;
                d.download = null;
                d.backfill = false;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.concurrentaccounts != null && m.hasOwnProperty("concurrentaccounts")) {
                d.concurrentaccounts = m.concurrentaccounts;
            }
            if (m.versions && m.versions.length) {
                d.versions = [];
                for (var j = 0; j < m.versions.length; ++j) {
                    d.versions[j] = $root.cat.PackageVersionMsg.toObject(m.versions[j], o);
                }
            }
            if (m.shadow != null && m.hasOwnProperty("shadow")) {
                d.shadow = $root.cat.PackageShadowMsg.toObject(m.shadow, o);
            }
            if (m.databases && m.databases.length) {
                d.databases = [];
                for (var j = 0; j < m.databases.length; ++j) {
                    d.databases[j] = $root.cat.PackageDatabaseMsg.toObject(m.databases[j], o);
                }
            }
            if (m.watches && m.watches.length) {
                d.watches = [];
                for (var j = 0; j < m.watches.length; ++j) {
                    d.watches[j] = $root.cat.PackageWatchMsg.toObject(m.watches[j], o);
                }
            }
            if (m.download != null && m.hasOwnProperty("download")) {
                d.download = $root.cat.PackageDownloadMsg.toObject(m.download, o);
            }
            if (m.backfill != null && m.hasOwnProperty("backfill")) {
                d.backfill = m.backfill;
            }
            if (m.memory && m.memory.length) {
                d.memory = [];
                for (var j = 0; j < m.memory.length; ++j) {
                    d.memory[j] = $root.cat.PackageMemoryMsg.toObject(m.memory[j], o);
                }
            }
            if (m.hooks && m.hooks.length) {
                d.hooks = [];
                for (var j = 0; j < m.hooks.length; ++j) {
                    d.hooks[j] = $root.cat.PackageHookMsg.toObject(m.hooks[j], o);
                }
            }
            if (m.patches && m.patches.length) {
                d.patches = [];
                for (var j = 0; j < m.patches.length; ++j) {
                    d.patches[j] = $root.cat.PackagePatchMsg.toObject(m.patches[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this PackageMsg to JSON.
         * @function toJSON
         * @memberof cat.PackageMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageMsg
         * @function getTypeUrl
         * @memberof cat.PackageMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PackageMsg";
        };

        return PackageMsg;
    })();

    cat.PackageVersionMsg = (function() {

        /**
         * Properties of a PackageVersionMsg.
         * @memberof cat
         * @interface IPackageVersionMsg
         * @property {string|null} [name] PackageVersionMsg name
         * @property {Array.<number>|null} [codes] PackageVersionMsg codes
         * @property {string|null} [releasedate] PackageVersionMsg releasedate
         * @property {string|null} [description] PackageVersionMsg description
         */

        /**
         * Constructs a new PackageVersionMsg.
         * @memberof cat
         * @classdesc Represents a PackageVersionMsg.
         * @implements IPackageVersionMsg
         * @constructor
         * @param {cat.IPackageVersionMsg=} [p] Properties to set
         */
        function PackageVersionMsg(p) {
            this.codes = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PackageVersionMsg name.
         * @member {string} name
         * @memberof cat.PackageVersionMsg
         * @instance
         */
        PackageVersionMsg.prototype.name = "";

        /**
         * PackageVersionMsg codes.
         * @member {Array.<number>} codes
         * @memberof cat.PackageVersionMsg
         * @instance
         */
        PackageVersionMsg.prototype.codes = $util.emptyArray;

        /**
         * PackageVersionMsg releasedate.
         * @member {string} releasedate
         * @memberof cat.PackageVersionMsg
         * @instance
         */
        PackageVersionMsg.prototype.releasedate = "";

        /**
         * PackageVersionMsg description.
         * @member {string} description
         * @memberof cat.PackageVersionMsg
         * @instance
         */
        PackageVersionMsg.prototype.description = "";

        /**
         * Creates a new PackageVersionMsg instance using the specified properties.
         * @function create
         * @memberof cat.PackageVersionMsg
         * @static
         * @param {cat.IPackageVersionMsg=} [properties] Properties to set
         * @returns {cat.PackageVersionMsg} PackageVersionMsg instance
         */
        PackageVersionMsg.create = function create(properties) {
            return new PackageVersionMsg(properties);
        };

        /**
         * Encodes the specified PackageVersionMsg message. Does not implicitly {@link cat.PackageVersionMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PackageVersionMsg
         * @static
         * @param {cat.IPackageVersionMsg} m PackageVersionMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageVersionMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.codes != null && m.codes.length) {
                w.uint32(18).fork();
                for (var i = 0; i < m.codes.length; ++i)
                    w.uint32(m.codes[i]);
                w.ldelim();
            }
            if (m.releasedate != null && Object.hasOwnProperty.call(m, "releasedate"))
                w.uint32(26).string(m.releasedate);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            return w;
        };

        /**
         * Decodes a PackageVersionMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PackageVersionMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PackageVersionMsg} PackageVersionMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageVersionMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PackageVersionMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 2: {
                        if (!(m.codes && m.codes.length))
                            m.codes = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.codes.push(r.uint32());
                        } else
                            m.codes.push(r.uint32());
                        break;
                    }
                case 3: {
                        m.releasedate = r.string();
                        break;
                    }
                case 4: {
                        m.description = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PackageVersionMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PackageVersionMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PackageVersionMsg} PackageVersionMsg
         */
        PackageVersionMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PackageVersionMsg)
                return d;
            var m = new $root.cat.PackageVersionMsg();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.codes) {
                if (!Array.isArray(d.codes))
                    throw TypeError(".cat.PackageVersionMsg.codes: array expected");
                m.codes = [];
                for (var i = 0; i < d.codes.length; ++i) {
                    m.codes[i] = d.codes[i] >>> 0;
                }
            }
            if (d.releasedate != null) {
                m.releasedate = String(d.releasedate);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            return m;
        };

        /**
         * Creates a plain object from a PackageVersionMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PackageVersionMsg
         * @static
         * @param {cat.PackageVersionMsg} m PackageVersionMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageVersionMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.codes = [];
            }
            if (o.defaults) {
                d.name = "";
                d.releasedate = "";
                d.description = "";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.codes && m.codes.length) {
                d.codes = [];
                for (var j = 0; j < m.codes.length; ++j) {
                    d.codes[j] = m.codes[j];
                }
            }
            if (m.releasedate != null && m.hasOwnProperty("releasedate")) {
                d.releasedate = m.releasedate;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            return d;
        };

        /**
         * Converts this PackageVersionMsg to JSON.
         * @function toJSON
         * @memberof cat.PackageVersionMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageVersionMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageVersionMsg
         * @function getTypeUrl
         * @memberof cat.PackageVersionMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageVersionMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PackageVersionMsg";
        };

        return PackageVersionMsg;
    })();

    cat.PackageShadowMsg = (function() {

        /**
         * Properties of a PackageShadowMsg.
         * @memberof cat
         * @interface IPackageShadowMsg
         * @property {string|null} [name] PackageShadowMsg name
         * @property {Array.<string>|null} [create] PackageShadowMsg create
         * @property {Array.<string>|null} [drop] PackageShadowMsg drop
         * @property {Array.<string>|null} ["export"] PackageShadowMsg export
         */

        /**
         * Constructs a new PackageShadowMsg.
         * @memberof cat
         * @classdesc Represents a PackageShadowMsg.
         * @implements IPackageShadowMsg
         * @constructor
         * @param {cat.IPackageShadowMsg=} [p] Properties to set
         */
        function PackageShadowMsg(p) {
            this.create = [];
            this.drop = [];
            this["export"] = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PackageShadowMsg name.
         * @member {string} name
         * @memberof cat.PackageShadowMsg
         * @instance
         */
        PackageShadowMsg.prototype.name = "";

        /**
         * PackageShadowMsg create.
         * @member {Array.<string>} create
         * @memberof cat.PackageShadowMsg
         * @instance
         */
        PackageShadowMsg.prototype.create = $util.emptyArray;

        /**
         * PackageShadowMsg drop.
         * @member {Array.<string>} drop
         * @memberof cat.PackageShadowMsg
         * @instance
         */
        PackageShadowMsg.prototype.drop = $util.emptyArray;

        /**
         * PackageShadowMsg export.
         * @member {Array.<string>} export
         * @memberof cat.PackageShadowMsg
         * @instance
         */
        PackageShadowMsg.prototype["export"] = $util.emptyArray;

        /**
         * Creates a new PackageShadowMsg instance using the specified properties.
         * @function create
         * @memberof cat.PackageShadowMsg
         * @static
         * @param {cat.IPackageShadowMsg=} [properties] Properties to set
         * @returns {cat.PackageShadowMsg} PackageShadowMsg instance
         */
        PackageShadowMsg.create = function create(properties) {
            return new PackageShadowMsg(properties);
        };

        /**
         * Encodes the specified PackageShadowMsg message. Does not implicitly {@link cat.PackageShadowMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PackageShadowMsg
         * @static
         * @param {cat.IPackageShadowMsg} m PackageShadowMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageShadowMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.create != null && m.create.length) {
                for (var i = 0; i < m.create.length; ++i)
                    w.uint32(26).string(m.create[i]);
            }
            if (m.drop != null && m.drop.length) {
                for (var i = 0; i < m.drop.length; ++i)
                    w.uint32(34).string(m.drop[i]);
            }
            if (m["export"] != null && m["export"].length) {
                for (var i = 0; i < m["export"].length; ++i)
                    w.uint32(42).string(m["export"][i]);
            }
            return w;
        };

        /**
         * Decodes a PackageShadowMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PackageShadowMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PackageShadowMsg} PackageShadowMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageShadowMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PackageShadowMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        if (!(m.create && m.create.length))
                            m.create = [];
                        m.create.push(r.string());
                        break;
                    }
                case 4: {
                        if (!(m.drop && m.drop.length))
                            m.drop = [];
                        m.drop.push(r.string());
                        break;
                    }
                case 5: {
                        if (!(m["export"] && m["export"].length))
                            m["export"] = [];
                        m["export"].push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PackageShadowMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PackageShadowMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PackageShadowMsg} PackageShadowMsg
         */
        PackageShadowMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PackageShadowMsg)
                return d;
            var m = new $root.cat.PackageShadowMsg();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.create) {
                if (!Array.isArray(d.create))
                    throw TypeError(".cat.PackageShadowMsg.create: array expected");
                m.create = [];
                for (var i = 0; i < d.create.length; ++i) {
                    m.create[i] = String(d.create[i]);
                }
            }
            if (d.drop) {
                if (!Array.isArray(d.drop))
                    throw TypeError(".cat.PackageShadowMsg.drop: array expected");
                m.drop = [];
                for (var i = 0; i < d.drop.length; ++i) {
                    m.drop[i] = String(d.drop[i]);
                }
            }
            if (d["export"]) {
                if (!Array.isArray(d["export"]))
                    throw TypeError(".cat.PackageShadowMsg.export: array expected");
                m["export"] = [];
                for (var i = 0; i < d["export"].length; ++i) {
                    m["export"][i] = String(d["export"][i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PackageShadowMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PackageShadowMsg
         * @static
         * @param {cat.PackageShadowMsg} m PackageShadowMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageShadowMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.create = [];
                d.drop = [];
                d["export"] = [];
            }
            if (o.defaults) {
                d.name = "";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.create && m.create.length) {
                d.create = [];
                for (var j = 0; j < m.create.length; ++j) {
                    d.create[j] = m.create[j];
                }
            }
            if (m.drop && m.drop.length) {
                d.drop = [];
                for (var j = 0; j < m.drop.length; ++j) {
                    d.drop[j] = m.drop[j];
                }
            }
            if (m["export"] && m["export"].length) {
                d["export"] = [];
                for (var j = 0; j < m["export"].length; ++j) {
                    d["export"][j] = m["export"][j];
                }
            }
            return d;
        };

        /**
         * Converts this PackageShadowMsg to JSON.
         * @function toJSON
         * @memberof cat.PackageShadowMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageShadowMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageShadowMsg
         * @function getTypeUrl
         * @memberof cat.PackageShadowMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageShadowMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PackageShadowMsg";
        };

        return PackageShadowMsg;
    })();

    cat.PackageDatabaseMsg = (function() {

        /**
         * Properties of a PackageDatabaseMsg.
         * @memberof cat
         * @interface IPackageDatabaseMsg
         * @property {string|null} [name] PackageDatabaseMsg name
         * @property {Array.<string>|null} [paths] PackageDatabaseMsg paths
         * @property {Array.<number>|null} [versions] PackageDatabaseMsg versions
         * @property {boolean|null} [required] PackageDatabaseMsg required
         * @property {number|null} [contextversion] PackageDatabaseMsg contextversion
         * @property {Array.<string>|null} [pragmas] PackageDatabaseMsg pragmas
         * @property {Array.<cat.IPackageTriggerMsg>|null} [triggers] PackageDatabaseMsg triggers
         */

        /**
         * Constructs a new PackageDatabaseMsg.
         * @memberof cat
         * @classdesc Represents a PackageDatabaseMsg.
         * @implements IPackageDatabaseMsg
         * @constructor
         * @param {cat.IPackageDatabaseMsg=} [p] Properties to set
         */
        function PackageDatabaseMsg(p) {
            this.paths = [];
            this.versions = [];
            this.pragmas = [];
            this.triggers = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PackageDatabaseMsg name.
         * @member {string} name
         * @memberof cat.PackageDatabaseMsg
         * @instance
         */
        PackageDatabaseMsg.prototype.name = "";

        /**
         * PackageDatabaseMsg paths.
         * @member {Array.<string>} paths
         * @memberof cat.PackageDatabaseMsg
         * @instance
         */
        PackageDatabaseMsg.prototype.paths = $util.emptyArray;

        /**
         * PackageDatabaseMsg versions.
         * @member {Array.<number>} versions
         * @memberof cat.PackageDatabaseMsg
         * @instance
         */
        PackageDatabaseMsg.prototype.versions = $util.emptyArray;

        /**
         * PackageDatabaseMsg required.
         * @member {boolean} required
         * @memberof cat.PackageDatabaseMsg
         * @instance
         */
        PackageDatabaseMsg.prototype.required = false;

        /**
         * PackageDatabaseMsg contextversion.
         * @member {number} contextversion
         * @memberof cat.PackageDatabaseMsg
         * @instance
         */
        PackageDatabaseMsg.prototype.contextversion = 0;

        /**
         * PackageDatabaseMsg pragmas.
         * @member {Array.<string>} pragmas
         * @memberof cat.PackageDatabaseMsg
         * @instance
         */
        PackageDatabaseMsg.prototype.pragmas = $util.emptyArray;

        /**
         * PackageDatabaseMsg triggers.
         * @member {Array.<cat.IPackageTriggerMsg>} triggers
         * @memberof cat.PackageDatabaseMsg
         * @instance
         */
        PackageDatabaseMsg.prototype.triggers = $util.emptyArray;

        /**
         * Creates a new PackageDatabaseMsg instance using the specified properties.
         * @function create
         * @memberof cat.PackageDatabaseMsg
         * @static
         * @param {cat.IPackageDatabaseMsg=} [properties] Properties to set
         * @returns {cat.PackageDatabaseMsg} PackageDatabaseMsg instance
         */
        PackageDatabaseMsg.create = function create(properties) {
            return new PackageDatabaseMsg(properties);
        };

        /**
         * Encodes the specified PackageDatabaseMsg message. Does not implicitly {@link cat.PackageDatabaseMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PackageDatabaseMsg
         * @static
         * @param {cat.IPackageDatabaseMsg} m PackageDatabaseMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageDatabaseMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.paths != null && m.paths.length) {
                for (var i = 0; i < m.paths.length; ++i)
                    w.uint32(18).string(m.paths[i]);
            }
            if (m.versions != null && m.versions.length) {
                w.uint32(26).fork();
                for (var i = 0; i < m.versions.length; ++i)
                    w.uint64(m.versions[i]);
                w.ldelim();
            }
            if (m.required != null && Object.hasOwnProperty.call(m, "required"))
                w.uint32(32).bool(m.required);
            if (m.contextversion != null && Object.hasOwnProperty.call(m, "contextversion"))
                w.uint32(40).uint32(m.contextversion);
            if (m.pragmas != null && m.pragmas.length) {
                for (var i = 0; i < m.pragmas.length; ++i)
                    w.uint32(50).string(m.pragmas[i]);
            }
            if (m.triggers != null && m.triggers.length) {
                for (var i = 0; i < m.triggers.length; ++i)
                    $root.cat.PackageTriggerMsg.encode(m.triggers[i], w.uint32(58).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a PackageDatabaseMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PackageDatabaseMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PackageDatabaseMsg} PackageDatabaseMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageDatabaseMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PackageDatabaseMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 2: {
                        if (!(m.paths && m.paths.length))
                            m.paths = [];
                        m.paths.push(r.string());
                        break;
                    }
                case 3: {
                        if (!(m.versions && m.versions.length))
                            m.versions = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.versions.push(r.uint64());
                        } else
                            m.versions.push(r.uint64());
                        break;
                    }
                case 4: {
                        m.required = r.bool();
                        break;
                    }
                case 5: {
                        m.contextversion = r.uint32();
                        break;
                    }
                case 6: {
                        if (!(m.pragmas && m.pragmas.length))
                            m.pragmas = [];
                        m.pragmas.push(r.string());
                        break;
                    }
                case 7: {
                        if (!(m.triggers && m.triggers.length))
                            m.triggers = [];
                        m.triggers.push($root.cat.PackageTriggerMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PackageDatabaseMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PackageDatabaseMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PackageDatabaseMsg} PackageDatabaseMsg
         */
        PackageDatabaseMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PackageDatabaseMsg)
                return d;
            var m = new $root.cat.PackageDatabaseMsg();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.paths) {
                if (!Array.isArray(d.paths))
                    throw TypeError(".cat.PackageDatabaseMsg.paths: array expected");
                m.paths = [];
                for (var i = 0; i < d.paths.length; ++i) {
                    m.paths[i] = String(d.paths[i]);
                }
            }
            if (d.versions) {
                if (!Array.isArray(d.versions))
                    throw TypeError(".cat.PackageDatabaseMsg.versions: array expected");
                m.versions = [];
                for (var i = 0; i < d.versions.length; ++i) {
                    if ($util.Long)
                        (m.versions[i] = $util.Long.fromValue(d.versions[i])).unsigned = true;
                    else if (typeof d.versions[i] === "string")
                        m.versions[i] = parseInt(d.versions[i], 10);
                    else if (typeof d.versions[i] === "number")
                        m.versions[i] = d.versions[i];
                    else if (typeof d.versions[i] === "object")
                        m.versions[i] = new $util.LongBits(d.versions[i].low >>> 0, d.versions[i].high >>> 0).toNumber(true);
                }
            }
            if (d.required != null) {
                m.required = Boolean(d.required);
            }
            if (d.contextversion != null) {
                m.contextversion = d.contextversion >>> 0;
            }
            if (d.pragmas) {
                if (!Array.isArray(d.pragmas))
                    throw TypeError(".cat.PackageDatabaseMsg.pragmas: array expected");
                m.pragmas = [];
                for (var i = 0; i < d.pragmas.length; ++i) {
                    m.pragmas[i] = String(d.pragmas[i]);
                }
            }
            if (d.triggers) {
                if (!Array.isArray(d.triggers))
                    throw TypeError(".cat.PackageDatabaseMsg.triggers: array expected");
                m.triggers = [];
                for (var i = 0; i < d.triggers.length; ++i) {
                    if (typeof d.triggers[i] !== "object")
                        throw TypeError(".cat.PackageDatabaseMsg.triggers: object expected");
                    m.triggers[i] = $root.cat.PackageTriggerMsg.fromObject(d.triggers[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PackageDatabaseMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PackageDatabaseMsg
         * @static
         * @param {cat.PackageDatabaseMsg} m PackageDatabaseMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageDatabaseMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.paths = [];
                d.versions = [];
                d.pragmas = [];
                d.triggers = [];
            }
            if (o.defaults) {
                d.name = "";
                d.required = false;
                d.contextversion = 0;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.paths && m.paths.length) {
                d.paths = [];
                for (var j = 0; j < m.paths.length; ++j) {
                    d.paths[j] = m.paths[j];
                }
            }
            if (m.versions && m.versions.length) {
                d.versions = [];
                for (var j = 0; j < m.versions.length; ++j) {
                    if (typeof m.versions[j] === "number")
                        d.versions[j] = o.longs === String ? String(m.versions[j]) : m.versions[j];
                    else
                        d.versions[j] = o.longs === String ? $util.Long.prototype.toString.call(m.versions[j]) : o.longs === Number ? new $util.LongBits(m.versions[j].low >>> 0, m.versions[j].high >>> 0).toNumber(true) : m.versions[j];
                }
            }
            if (m.required != null && m.hasOwnProperty("required")) {
                d.required = m.required;
            }
            if (m.contextversion != null && m.hasOwnProperty("contextversion")) {
                d.contextversion = m.contextversion;
            }
            if (m.pragmas && m.pragmas.length) {
                d.pragmas = [];
                for (var j = 0; j < m.pragmas.length; ++j) {
                    d.pragmas[j] = m.pragmas[j];
                }
            }
            if (m.triggers && m.triggers.length) {
                d.triggers = [];
                for (var j = 0; j < m.triggers.length; ++j) {
                    d.triggers[j] = $root.cat.PackageTriggerMsg.toObject(m.triggers[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this PackageDatabaseMsg to JSON.
         * @function toJSON
         * @memberof cat.PackageDatabaseMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageDatabaseMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageDatabaseMsg
         * @function getTypeUrl
         * @memberof cat.PackageDatabaseMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageDatabaseMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PackageDatabaseMsg";
        };

        return PackageDatabaseMsg;
    })();

    cat.PackageTriggerMsg = (function() {

        /**
         * Properties of a PackageTriggerMsg.
         * @memberof cat
         * @interface IPackageTriggerMsg
         * @property {string|null} [context] PackageTriggerMsg context
         * @property {Array.<number>|null} [versions] PackageTriggerMsg versions
         * @property {string|null} [description] PackageTriggerMsg description
         * @property {Array.<string>|null} [tables] PackageTriggerMsg tables
         * @property {Array.<string>|null} [init] PackageTriggerMsg init
         * @property {Array.<string>|null} [insert] PackageTriggerMsg insert
         * @property {Array.<string>|null} [update] PackageTriggerMsg update
         * @property {Array.<string>|null} ["delete"] PackageTriggerMsg delete
         * @property {Array.<string>|null} [copy] PackageTriggerMsg copy
         * @property {Array.<string>|null} [drop] PackageTriggerMsg drop
         * @property {Array.<string>|null} [lookup] PackageTriggerMsg lookup
         */

        /**
         * Constructs a new PackageTriggerMsg.
         * @memberof cat
         * @classdesc Represents a PackageTriggerMsg.
         * @implements IPackageTriggerMsg
         * @constructor
         * @param {cat.IPackageTriggerMsg=} [p] Properties to set
         */
        function PackageTriggerMsg(p) {
            this.versions = [];
            this.tables = [];
            this.init = [];
            this.insert = [];
            this.update = [];
            this["delete"] = [];
            this.copy = [];
            this.drop = [];
            this.lookup = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PackageTriggerMsg context.
         * @member {string} context
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype.context = "";

        /**
         * PackageTriggerMsg versions.
         * @member {Array.<number>} versions
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype.versions = $util.emptyArray;

        /**
         * PackageTriggerMsg description.
         * @member {string} description
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype.description = "";

        /**
         * PackageTriggerMsg tables.
         * @member {Array.<string>} tables
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype.tables = $util.emptyArray;

        /**
         * PackageTriggerMsg init.
         * @member {Array.<string>} init
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype.init = $util.emptyArray;

        /**
         * PackageTriggerMsg insert.
         * @member {Array.<string>} insert
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype.insert = $util.emptyArray;

        /**
         * PackageTriggerMsg update.
         * @member {Array.<string>} update
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype.update = $util.emptyArray;

        /**
         * PackageTriggerMsg delete.
         * @member {Array.<string>} delete
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype["delete"] = $util.emptyArray;

        /**
         * PackageTriggerMsg copy.
         * @member {Array.<string>} copy
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype.copy = $util.emptyArray;

        /**
         * PackageTriggerMsg drop.
         * @member {Array.<string>} drop
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype.drop = $util.emptyArray;

        /**
         * PackageTriggerMsg lookup.
         * @member {Array.<string>} lookup
         * @memberof cat.PackageTriggerMsg
         * @instance
         */
        PackageTriggerMsg.prototype.lookup = $util.emptyArray;

        /**
         * Creates a new PackageTriggerMsg instance using the specified properties.
         * @function create
         * @memberof cat.PackageTriggerMsg
         * @static
         * @param {cat.IPackageTriggerMsg=} [properties] Properties to set
         * @returns {cat.PackageTriggerMsg} PackageTriggerMsg instance
         */
        PackageTriggerMsg.create = function create(properties) {
            return new PackageTriggerMsg(properties);
        };

        /**
         * Encodes the specified PackageTriggerMsg message. Does not implicitly {@link cat.PackageTriggerMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PackageTriggerMsg
         * @static
         * @param {cat.IPackageTriggerMsg} m PackageTriggerMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageTriggerMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                w.uint32(10).string(m.context);
            if (m.versions != null && m.versions.length) {
                w.uint32(18).fork();
                for (var i = 0; i < m.versions.length; ++i)
                    w.uint64(m.versions[i]);
                w.ldelim();
            }
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(26).string(m.description);
            if (m.tables != null && m.tables.length) {
                for (var i = 0; i < m.tables.length; ++i)
                    w.uint32(34).string(m.tables[i]);
            }
            if (m.init != null && m.init.length) {
                for (var i = 0; i < m.init.length; ++i)
                    w.uint32(42).string(m.init[i]);
            }
            if (m.insert != null && m.insert.length) {
                for (var i = 0; i < m.insert.length; ++i)
                    w.uint32(50).string(m.insert[i]);
            }
            if (m.update != null && m.update.length) {
                for (var i = 0; i < m.update.length; ++i)
                    w.uint32(58).string(m.update[i]);
            }
            if (m["delete"] != null && m["delete"].length) {
                for (var i = 0; i < m["delete"].length; ++i)
                    w.uint32(66).string(m["delete"][i]);
            }
            if (m.copy != null && m.copy.length) {
                for (var i = 0; i < m.copy.length; ++i)
                    w.uint32(74).string(m.copy[i]);
            }
            if (m.drop != null && m.drop.length) {
                for (var i = 0; i < m.drop.length; ++i)
                    w.uint32(82).string(m.drop[i]);
            }
            if (m.lookup != null && m.lookup.length) {
                for (var i = 0; i < m.lookup.length; ++i)
                    w.uint32(90).string(m.lookup[i]);
            }
            return w;
        };

        /**
         * Decodes a PackageTriggerMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PackageTriggerMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PackageTriggerMsg} PackageTriggerMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageTriggerMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PackageTriggerMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.context = r.string();
                        break;
                    }
                case 2: {
                        if (!(m.versions && m.versions.length))
                            m.versions = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.versions.push(r.uint64());
                        } else
                            m.versions.push(r.uint64());
                        break;
                    }
                case 3: {
                        m.description = r.string();
                        break;
                    }
                case 4: {
                        if (!(m.tables && m.tables.length))
                            m.tables = [];
                        m.tables.push(r.string());
                        break;
                    }
                case 5: {
                        if (!(m.init && m.init.length))
                            m.init = [];
                        m.init.push(r.string());
                        break;
                    }
                case 6: {
                        if (!(m.insert && m.insert.length))
                            m.insert = [];
                        m.insert.push(r.string());
                        break;
                    }
                case 7: {
                        if (!(m.update && m.update.length))
                            m.update = [];
                        m.update.push(r.string());
                        break;
                    }
                case 8: {
                        if (!(m["delete"] && m["delete"].length))
                            m["delete"] = [];
                        m["delete"].push(r.string());
                        break;
                    }
                case 9: {
                        if (!(m.copy && m.copy.length))
                            m.copy = [];
                        m.copy.push(r.string());
                        break;
                    }
                case 10: {
                        if (!(m.drop && m.drop.length))
                            m.drop = [];
                        m.drop.push(r.string());
                        break;
                    }
                case 11: {
                        if (!(m.lookup && m.lookup.length))
                            m.lookup = [];
                        m.lookup.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PackageTriggerMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PackageTriggerMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PackageTriggerMsg} PackageTriggerMsg
         */
        PackageTriggerMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PackageTriggerMsg)
                return d;
            var m = new $root.cat.PackageTriggerMsg();
            if (d.context != null) {
                m.context = String(d.context);
            }
            if (d.versions) {
                if (!Array.isArray(d.versions))
                    throw TypeError(".cat.PackageTriggerMsg.versions: array expected");
                m.versions = [];
                for (var i = 0; i < d.versions.length; ++i) {
                    if ($util.Long)
                        (m.versions[i] = $util.Long.fromValue(d.versions[i])).unsigned = true;
                    else if (typeof d.versions[i] === "string")
                        m.versions[i] = parseInt(d.versions[i], 10);
                    else if (typeof d.versions[i] === "number")
                        m.versions[i] = d.versions[i];
                    else if (typeof d.versions[i] === "object")
                        m.versions[i] = new $util.LongBits(d.versions[i].low >>> 0, d.versions[i].high >>> 0).toNumber(true);
                }
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.tables) {
                if (!Array.isArray(d.tables))
                    throw TypeError(".cat.PackageTriggerMsg.tables: array expected");
                m.tables = [];
                for (var i = 0; i < d.tables.length; ++i) {
                    m.tables[i] = String(d.tables[i]);
                }
            }
            if (d.init) {
                if (!Array.isArray(d.init))
                    throw TypeError(".cat.PackageTriggerMsg.init: array expected");
                m.init = [];
                for (var i = 0; i < d.init.length; ++i) {
                    m.init[i] = String(d.init[i]);
                }
            }
            if (d.insert) {
                if (!Array.isArray(d.insert))
                    throw TypeError(".cat.PackageTriggerMsg.insert: array expected");
                m.insert = [];
                for (var i = 0; i < d.insert.length; ++i) {
                    m.insert[i] = String(d.insert[i]);
                }
            }
            if (d.update) {
                if (!Array.isArray(d.update))
                    throw TypeError(".cat.PackageTriggerMsg.update: array expected");
                m.update = [];
                for (var i = 0; i < d.update.length; ++i) {
                    m.update[i] = String(d.update[i]);
                }
            }
            if (d["delete"]) {
                if (!Array.isArray(d["delete"]))
                    throw TypeError(".cat.PackageTriggerMsg.delete: array expected");
                m["delete"] = [];
                for (var i = 0; i < d["delete"].length; ++i) {
                    m["delete"][i] = String(d["delete"][i]);
                }
            }
            if (d.copy) {
                if (!Array.isArray(d.copy))
                    throw TypeError(".cat.PackageTriggerMsg.copy: array expected");
                m.copy = [];
                for (var i = 0; i < d.copy.length; ++i) {
                    m.copy[i] = String(d.copy[i]);
                }
            }
            if (d.drop) {
                if (!Array.isArray(d.drop))
                    throw TypeError(".cat.PackageTriggerMsg.drop: array expected");
                m.drop = [];
                for (var i = 0; i < d.drop.length; ++i) {
                    m.drop[i] = String(d.drop[i]);
                }
            }
            if (d.lookup) {
                if (!Array.isArray(d.lookup))
                    throw TypeError(".cat.PackageTriggerMsg.lookup: array expected");
                m.lookup = [];
                for (var i = 0; i < d.lookup.length; ++i) {
                    m.lookup[i] = String(d.lookup[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PackageTriggerMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PackageTriggerMsg
         * @static
         * @param {cat.PackageTriggerMsg} m PackageTriggerMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageTriggerMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.versions = [];
                d.tables = [];
                d.init = [];
                d.insert = [];
                d.update = [];
                d["delete"] = [];
                d.copy = [];
                d.drop = [];
                d.lookup = [];
            }
            if (o.defaults) {
                d.context = "";
                d.description = "";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = m.context;
            }
            if (m.versions && m.versions.length) {
                d.versions = [];
                for (var j = 0; j < m.versions.length; ++j) {
                    if (typeof m.versions[j] === "number")
                        d.versions[j] = o.longs === String ? String(m.versions[j]) : m.versions[j];
                    else
                        d.versions[j] = o.longs === String ? $util.Long.prototype.toString.call(m.versions[j]) : o.longs === Number ? new $util.LongBits(m.versions[j].low >>> 0, m.versions[j].high >>> 0).toNumber(true) : m.versions[j];
                }
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.tables && m.tables.length) {
                d.tables = [];
                for (var j = 0; j < m.tables.length; ++j) {
                    d.tables[j] = m.tables[j];
                }
            }
            if (m.init && m.init.length) {
                d.init = [];
                for (var j = 0; j < m.init.length; ++j) {
                    d.init[j] = m.init[j];
                }
            }
            if (m.insert && m.insert.length) {
                d.insert = [];
                for (var j = 0; j < m.insert.length; ++j) {
                    d.insert[j] = m.insert[j];
                }
            }
            if (m.update && m.update.length) {
                d.update = [];
                for (var j = 0; j < m.update.length; ++j) {
                    d.update[j] = m.update[j];
                }
            }
            if (m["delete"] && m["delete"].length) {
                d["delete"] = [];
                for (var j = 0; j < m["delete"].length; ++j) {
                    d["delete"][j] = m["delete"][j];
                }
            }
            if (m.copy && m.copy.length) {
                d.copy = [];
                for (var j = 0; j < m.copy.length; ++j) {
                    d.copy[j] = m.copy[j];
                }
            }
            if (m.drop && m.drop.length) {
                d.drop = [];
                for (var j = 0; j < m.drop.length; ++j) {
                    d.drop[j] = m.drop[j];
                }
            }
            if (m.lookup && m.lookup.length) {
                d.lookup = [];
                for (var j = 0; j < m.lookup.length; ++j) {
                    d.lookup[j] = m.lookup[j];
                }
            }
            return d;
        };

        /**
         * Converts this PackageTriggerMsg to JSON.
         * @function toJSON
         * @memberof cat.PackageTriggerMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageTriggerMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageTriggerMsg
         * @function getTypeUrl
         * @memberof cat.PackageTriggerMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageTriggerMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PackageTriggerMsg";
        };

        return PackageTriggerMsg;
    })();

    cat.PackageWatchMsg = (function() {

        /**
         * Properties of a PackageWatchMsg.
         * @memberof cat
         * @interface IPackageWatchMsg
         * @property {string|null} [context] PackageWatchMsg context
         * @property {Array.<number>|null} [versions] PackageWatchMsg versions
         * @property {string|null} [description] PackageWatchMsg description
         * @property {string|null} [events] PackageWatchMsg events
         * @property {string|null} [include] PackageWatchMsg include
         * @property {string|null} [exclude] PackageWatchMsg exclude
         * @property {boolean|null} [subdirs] PackageWatchMsg subdirs
         * @property {string|null} [source] PackageWatchMsg source
         * @property {string|null} [destination] PackageWatchMsg destination
         * @property {boolean|null} [isunique] PackageWatchMsg isunique
         * @property {boolean|null} [compress] PackageWatchMsg compress
         * @property {boolean|null} [remove] PackageWatchMsg remove
         * @property {Array.<string>|null} [packagenames] PackageWatchMsg packagenames
         * @property {Array.<number>|null} [wds] PackageWatchMsg wds
         * @property {boolean|null} [parent] PackageWatchMsg parent
         */

        /**
         * Constructs a new PackageWatchMsg.
         * @memberof cat
         * @classdesc Represents a PackageWatchMsg.
         * @implements IPackageWatchMsg
         * @constructor
         * @param {cat.IPackageWatchMsg=} [p] Properties to set
         */
        function PackageWatchMsg(p) {
            this.versions = [];
            this.packagenames = [];
            this.wds = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PackageWatchMsg context.
         * @member {string} context
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.context = "";

        /**
         * PackageWatchMsg versions.
         * @member {Array.<number>} versions
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.versions = $util.emptyArray;

        /**
         * PackageWatchMsg description.
         * @member {string} description
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.description = "";

        /**
         * PackageWatchMsg events.
         * @member {string} events
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.events = "";

        /**
         * PackageWatchMsg include.
         * @member {string} include
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.include = "";

        /**
         * PackageWatchMsg exclude.
         * @member {string} exclude
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.exclude = "";

        /**
         * PackageWatchMsg subdirs.
         * @member {boolean} subdirs
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.subdirs = false;

        /**
         * PackageWatchMsg source.
         * @member {string} source
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.source = "";

        /**
         * PackageWatchMsg destination.
         * @member {string} destination
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.destination = "";

        /**
         * PackageWatchMsg isunique.
         * @member {boolean} isunique
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.isunique = false;

        /**
         * PackageWatchMsg compress.
         * @member {boolean} compress
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.compress = false;

        /**
         * PackageWatchMsg remove.
         * @member {boolean} remove
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.remove = false;

        /**
         * PackageWatchMsg packagenames.
         * @member {Array.<string>} packagenames
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.packagenames = $util.emptyArray;

        /**
         * PackageWatchMsg wds.
         * @member {Array.<number>} wds
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.wds = $util.emptyArray;

        /**
         * PackageWatchMsg parent.
         * @member {boolean} parent
         * @memberof cat.PackageWatchMsg
         * @instance
         */
        PackageWatchMsg.prototype.parent = false;

        /**
         * Creates a new PackageWatchMsg instance using the specified properties.
         * @function create
         * @memberof cat.PackageWatchMsg
         * @static
         * @param {cat.IPackageWatchMsg=} [properties] Properties to set
         * @returns {cat.PackageWatchMsg} PackageWatchMsg instance
         */
        PackageWatchMsg.create = function create(properties) {
            return new PackageWatchMsg(properties);
        };

        /**
         * Encodes the specified PackageWatchMsg message. Does not implicitly {@link cat.PackageWatchMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PackageWatchMsg
         * @static
         * @param {cat.IPackageWatchMsg} m PackageWatchMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageWatchMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                w.uint32(10).string(m.context);
            if (m.versions != null && m.versions.length) {
                w.uint32(18).fork();
                for (var i = 0; i < m.versions.length; ++i)
                    w.uint64(m.versions[i]);
                w.ldelim();
            }
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(26).string(m.description);
            if (m.events != null && Object.hasOwnProperty.call(m, "events"))
                w.uint32(34).string(m.events);
            if (m.include != null && Object.hasOwnProperty.call(m, "include"))
                w.uint32(42).string(m.include);
            if (m.exclude != null && Object.hasOwnProperty.call(m, "exclude"))
                w.uint32(50).string(m.exclude);
            if (m.subdirs != null && Object.hasOwnProperty.call(m, "subdirs"))
                w.uint32(56).bool(m.subdirs);
            if (m.source != null && Object.hasOwnProperty.call(m, "source"))
                w.uint32(66).string(m.source);
            if (m.destination != null && Object.hasOwnProperty.call(m, "destination"))
                w.uint32(74).string(m.destination);
            if (m.isunique != null && Object.hasOwnProperty.call(m, "isunique"))
                w.uint32(80).bool(m.isunique);
            if (m.compress != null && Object.hasOwnProperty.call(m, "compress"))
                w.uint32(88).bool(m.compress);
            if (m.remove != null && Object.hasOwnProperty.call(m, "remove"))
                w.uint32(96).bool(m.remove);
            if (m.packagenames != null && m.packagenames.length) {
                for (var i = 0; i < m.packagenames.length; ++i)
                    w.uint32(810).string(m.packagenames[i]);
            }
            if (m.wds != null && m.wds.length) {
                w.uint32(818).fork();
                for (var i = 0; i < m.wds.length; ++i)
                    w.uint32(m.wds[i]);
                w.ldelim();
            }
            if (m.parent != null && Object.hasOwnProperty.call(m, "parent"))
                w.uint32(824).bool(m.parent);
            return w;
        };

        /**
         * Decodes a PackageWatchMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PackageWatchMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PackageWatchMsg} PackageWatchMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageWatchMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PackageWatchMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.context = r.string();
                        break;
                    }
                case 2: {
                        if (!(m.versions && m.versions.length))
                            m.versions = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.versions.push(r.uint64());
                        } else
                            m.versions.push(r.uint64());
                        break;
                    }
                case 3: {
                        m.description = r.string();
                        break;
                    }
                case 4: {
                        m.events = r.string();
                        break;
                    }
                case 5: {
                        m.include = r.string();
                        break;
                    }
                case 6: {
                        m.exclude = r.string();
                        break;
                    }
                case 7: {
                        m.subdirs = r.bool();
                        break;
                    }
                case 8: {
                        m.source = r.string();
                        break;
                    }
                case 9: {
                        m.destination = r.string();
                        break;
                    }
                case 10: {
                        m.isunique = r.bool();
                        break;
                    }
                case 11: {
                        m.compress = r.bool();
                        break;
                    }
                case 12: {
                        m.remove = r.bool();
                        break;
                    }
                case 101: {
                        if (!(m.packagenames && m.packagenames.length))
                            m.packagenames = [];
                        m.packagenames.push(r.string());
                        break;
                    }
                case 102: {
                        if (!(m.wds && m.wds.length))
                            m.wds = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.wds.push(r.uint32());
                        } else
                            m.wds.push(r.uint32());
                        break;
                    }
                case 103: {
                        m.parent = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PackageWatchMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PackageWatchMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PackageWatchMsg} PackageWatchMsg
         */
        PackageWatchMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PackageWatchMsg)
                return d;
            var m = new $root.cat.PackageWatchMsg();
            if (d.context != null) {
                m.context = String(d.context);
            }
            if (d.versions) {
                if (!Array.isArray(d.versions))
                    throw TypeError(".cat.PackageWatchMsg.versions: array expected");
                m.versions = [];
                for (var i = 0; i < d.versions.length; ++i) {
                    if ($util.Long)
                        (m.versions[i] = $util.Long.fromValue(d.versions[i])).unsigned = true;
                    else if (typeof d.versions[i] === "string")
                        m.versions[i] = parseInt(d.versions[i], 10);
                    else if (typeof d.versions[i] === "number")
                        m.versions[i] = d.versions[i];
                    else if (typeof d.versions[i] === "object")
                        m.versions[i] = new $util.LongBits(d.versions[i].low >>> 0, d.versions[i].high >>> 0).toNumber(true);
                }
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.events != null) {
                m.events = String(d.events);
            }
            if (d.include != null) {
                m.include = String(d.include);
            }
            if (d.exclude != null) {
                m.exclude = String(d.exclude);
            }
            if (d.subdirs != null) {
                m.subdirs = Boolean(d.subdirs);
            }
            if (d.source != null) {
                m.source = String(d.source);
            }
            if (d.destination != null) {
                m.destination = String(d.destination);
            }
            if (d.isunique != null) {
                m.isunique = Boolean(d.isunique);
            }
            if (d.compress != null) {
                m.compress = Boolean(d.compress);
            }
            if (d.remove != null) {
                m.remove = Boolean(d.remove);
            }
            if (d.packagenames) {
                if (!Array.isArray(d.packagenames))
                    throw TypeError(".cat.PackageWatchMsg.packagenames: array expected");
                m.packagenames = [];
                for (var i = 0; i < d.packagenames.length; ++i) {
                    m.packagenames[i] = String(d.packagenames[i]);
                }
            }
            if (d.wds) {
                if (!Array.isArray(d.wds))
                    throw TypeError(".cat.PackageWatchMsg.wds: array expected");
                m.wds = [];
                for (var i = 0; i < d.wds.length; ++i) {
                    m.wds[i] = d.wds[i] >>> 0;
                }
            }
            if (d.parent != null) {
                m.parent = Boolean(d.parent);
            }
            return m;
        };

        /**
         * Creates a plain object from a PackageWatchMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PackageWatchMsg
         * @static
         * @param {cat.PackageWatchMsg} m PackageWatchMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageWatchMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.versions = [];
                d.packagenames = [];
                d.wds = [];
            }
            if (o.defaults) {
                d.context = "";
                d.description = "";
                d.events = "";
                d.include = "";
                d.exclude = "";
                d.subdirs = false;
                d.source = "";
                d.destination = "";
                d.isunique = false;
                d.compress = false;
                d.remove = false;
                d.parent = false;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = m.context;
            }
            if (m.versions && m.versions.length) {
                d.versions = [];
                for (var j = 0; j < m.versions.length; ++j) {
                    if (typeof m.versions[j] === "number")
                        d.versions[j] = o.longs === String ? String(m.versions[j]) : m.versions[j];
                    else
                        d.versions[j] = o.longs === String ? $util.Long.prototype.toString.call(m.versions[j]) : o.longs === Number ? new $util.LongBits(m.versions[j].low >>> 0, m.versions[j].high >>> 0).toNumber(true) : m.versions[j];
                }
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.events != null && m.hasOwnProperty("events")) {
                d.events = m.events;
            }
            if (m.include != null && m.hasOwnProperty("include")) {
                d.include = m.include;
            }
            if (m.exclude != null && m.hasOwnProperty("exclude")) {
                d.exclude = m.exclude;
            }
            if (m.subdirs != null && m.hasOwnProperty("subdirs")) {
                d.subdirs = m.subdirs;
            }
            if (m.source != null && m.hasOwnProperty("source")) {
                d.source = m.source;
            }
            if (m.destination != null && m.hasOwnProperty("destination")) {
                d.destination = m.destination;
            }
            if (m.isunique != null && m.hasOwnProperty("isunique")) {
                d.isunique = m.isunique;
            }
            if (m.compress != null && m.hasOwnProperty("compress")) {
                d.compress = m.compress;
            }
            if (m.remove != null && m.hasOwnProperty("remove")) {
                d.remove = m.remove;
            }
            if (m.packagenames && m.packagenames.length) {
                d.packagenames = [];
                for (var j = 0; j < m.packagenames.length; ++j) {
                    d.packagenames[j] = m.packagenames[j];
                }
            }
            if (m.wds && m.wds.length) {
                d.wds = [];
                for (var j = 0; j < m.wds.length; ++j) {
                    d.wds[j] = m.wds[j];
                }
            }
            if (m.parent != null && m.hasOwnProperty("parent")) {
                d.parent = m.parent;
            }
            return d;
        };

        /**
         * Converts this PackageWatchMsg to JSON.
         * @function toJSON
         * @memberof cat.PackageWatchMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageWatchMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageWatchMsg
         * @function getTypeUrl
         * @memberof cat.PackageWatchMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageWatchMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PackageWatchMsg";
        };

        return PackageWatchMsg;
    })();

    cat.PackageDownloadMsg = (function() {

        /**
         * Properties of a PackageDownloadMsg.
         * @memberof cat
         * @interface IPackageDownloadMsg
         * @property {string|null} [useragent] PackageDownloadMsg useragent
         */

        /**
         * Constructs a new PackageDownloadMsg.
         * @memberof cat
         * @classdesc Represents a PackageDownloadMsg.
         * @implements IPackageDownloadMsg
         * @constructor
         * @param {cat.IPackageDownloadMsg=} [p] Properties to set
         */
        function PackageDownloadMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PackageDownloadMsg useragent.
         * @member {string} useragent
         * @memberof cat.PackageDownloadMsg
         * @instance
         */
        PackageDownloadMsg.prototype.useragent = "";

        /**
         * Creates a new PackageDownloadMsg instance using the specified properties.
         * @function create
         * @memberof cat.PackageDownloadMsg
         * @static
         * @param {cat.IPackageDownloadMsg=} [properties] Properties to set
         * @returns {cat.PackageDownloadMsg} PackageDownloadMsg instance
         */
        PackageDownloadMsg.create = function create(properties) {
            return new PackageDownloadMsg(properties);
        };

        /**
         * Encodes the specified PackageDownloadMsg message. Does not implicitly {@link cat.PackageDownloadMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PackageDownloadMsg
         * @static
         * @param {cat.IPackageDownloadMsg} m PackageDownloadMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageDownloadMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.useragent != null && Object.hasOwnProperty.call(m, "useragent"))
                w.uint32(10).string(m.useragent);
            return w;
        };

        /**
         * Decodes a PackageDownloadMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PackageDownloadMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PackageDownloadMsg} PackageDownloadMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageDownloadMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PackageDownloadMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.useragent = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PackageDownloadMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PackageDownloadMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PackageDownloadMsg} PackageDownloadMsg
         */
        PackageDownloadMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PackageDownloadMsg)
                return d;
            var m = new $root.cat.PackageDownloadMsg();
            if (d.useragent != null) {
                m.useragent = String(d.useragent);
            }
            return m;
        };

        /**
         * Creates a plain object from a PackageDownloadMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PackageDownloadMsg
         * @static
         * @param {cat.PackageDownloadMsg} m PackageDownloadMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageDownloadMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.useragent = "";
            }
            if (m.useragent != null && m.hasOwnProperty("useragent")) {
                d.useragent = m.useragent;
            }
            return d;
        };

        /**
         * Converts this PackageDownloadMsg to JSON.
         * @function toJSON
         * @memberof cat.PackageDownloadMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageDownloadMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageDownloadMsg
         * @function getTypeUrl
         * @memberof cat.PackageDownloadMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageDownloadMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PackageDownloadMsg";
        };

        return PackageDownloadMsg;
    })();

    cat.PackageMemoryMsg = (function() {

        /**
         * Properties of a PackageMemoryMsg.
         * @memberof cat
         * @interface IPackageMemoryMsg
         * @property {string|null} [context] PackageMemoryMsg context
         * @property {Array.<number>|null} [versions] PackageMemoryMsg versions
         * @property {Object.<string,string>|null} [properties] PackageMemoryMsg properties
         */

        /**
         * Constructs a new PackageMemoryMsg.
         * @memberof cat
         * @classdesc Represents a PackageMemoryMsg.
         * @implements IPackageMemoryMsg
         * @constructor
         * @param {cat.IPackageMemoryMsg=} [p] Properties to set
         */
        function PackageMemoryMsg(p) {
            this.versions = [];
            this.properties = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PackageMemoryMsg context.
         * @member {string} context
         * @memberof cat.PackageMemoryMsg
         * @instance
         */
        PackageMemoryMsg.prototype.context = "";

        /**
         * PackageMemoryMsg versions.
         * @member {Array.<number>} versions
         * @memberof cat.PackageMemoryMsg
         * @instance
         */
        PackageMemoryMsg.prototype.versions = $util.emptyArray;

        /**
         * PackageMemoryMsg properties.
         * @member {Object.<string,string>} properties
         * @memberof cat.PackageMemoryMsg
         * @instance
         */
        PackageMemoryMsg.prototype.properties = $util.emptyObject;

        /**
         * Creates a new PackageMemoryMsg instance using the specified properties.
         * @function create
         * @memberof cat.PackageMemoryMsg
         * @static
         * @param {cat.IPackageMemoryMsg=} [properties] Properties to set
         * @returns {cat.PackageMemoryMsg} PackageMemoryMsg instance
         */
        PackageMemoryMsg.create = function create(properties) {
            return new PackageMemoryMsg(properties);
        };

        /**
         * Encodes the specified PackageMemoryMsg message. Does not implicitly {@link cat.PackageMemoryMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PackageMemoryMsg
         * @static
         * @param {cat.IPackageMemoryMsg} m PackageMemoryMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageMemoryMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                w.uint32(10).string(m.context);
            if (m.versions != null && m.versions.length) {
                w.uint32(18).fork();
                for (var i = 0; i < m.versions.length; ++i)
                    w.uint64(m.versions[i]);
                w.ldelim();
            }
            if (m.properties != null && Object.hasOwnProperty.call(m, "properties")) {
                for (var ks = Object.keys(m.properties), i = 0; i < ks.length; ++i) {
                    w.uint32(26).fork().uint32(10).string(ks[i]).uint32(18).string(m.properties[ks[i]]).ldelim();
                }
            }
            return w;
        };

        /**
         * Decodes a PackageMemoryMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PackageMemoryMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PackageMemoryMsg} PackageMemoryMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageMemoryMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PackageMemoryMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.context = r.string();
                        break;
                    }
                case 2: {
                        if (!(m.versions && m.versions.length))
                            m.versions = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.versions.push(r.uint64());
                        } else
                            m.versions.push(r.uint64());
                        break;
                    }
                case 3: {
                        if (m.properties === $util.emptyObject)
                            m.properties = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.properties[k] = value;
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PackageMemoryMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PackageMemoryMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PackageMemoryMsg} PackageMemoryMsg
         */
        PackageMemoryMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PackageMemoryMsg)
                return d;
            var m = new $root.cat.PackageMemoryMsg();
            if (d.context != null) {
                m.context = String(d.context);
            }
            if (d.versions) {
                if (!Array.isArray(d.versions))
                    throw TypeError(".cat.PackageMemoryMsg.versions: array expected");
                m.versions = [];
                for (var i = 0; i < d.versions.length; ++i) {
                    if ($util.Long)
                        (m.versions[i] = $util.Long.fromValue(d.versions[i])).unsigned = true;
                    else if (typeof d.versions[i] === "string")
                        m.versions[i] = parseInt(d.versions[i], 10);
                    else if (typeof d.versions[i] === "number")
                        m.versions[i] = d.versions[i];
                    else if (typeof d.versions[i] === "object")
                        m.versions[i] = new $util.LongBits(d.versions[i].low >>> 0, d.versions[i].high >>> 0).toNumber(true);
                }
            }
            if (d.properties) {
                if (typeof d.properties !== "object")
                    throw TypeError(".cat.PackageMemoryMsg.properties: object expected");
                m.properties = {};
                for (var ks = Object.keys(d.properties), i = 0; i < ks.length; ++i) {
                    m.properties[ks[i]] = String(d.properties[ks[i]]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PackageMemoryMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PackageMemoryMsg
         * @static
         * @param {cat.PackageMemoryMsg} m PackageMemoryMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageMemoryMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.versions = [];
            }
            if (o.objects || o.defaults) {
                d.properties = {};
            }
            if (o.defaults) {
                d.context = "";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = m.context;
            }
            if (m.versions && m.versions.length) {
                d.versions = [];
                for (var j = 0; j < m.versions.length; ++j) {
                    if (typeof m.versions[j] === "number")
                        d.versions[j] = o.longs === String ? String(m.versions[j]) : m.versions[j];
                    else
                        d.versions[j] = o.longs === String ? $util.Long.prototype.toString.call(m.versions[j]) : o.longs === Number ? new $util.LongBits(m.versions[j].low >>> 0, m.versions[j].high >>> 0).toNumber(true) : m.versions[j];
                }
            }
            var ks2;
            if (m.properties && (ks2 = Object.keys(m.properties)).length) {
                d.properties = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.properties[ks2[j]] = m.properties[ks2[j]];
                }
            }
            return d;
        };

        /**
         * Converts this PackageMemoryMsg to JSON.
         * @function toJSON
         * @memberof cat.PackageMemoryMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageMemoryMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageMemoryMsg
         * @function getTypeUrl
         * @memberof cat.PackageMemoryMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageMemoryMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PackageMemoryMsg";
        };

        return PackageMemoryMsg;
    })();

    cat.PackageHookMsg = (function() {

        /**
         * Properties of a PackageHookMsg.
         * @memberof cat
         * @interface IPackageHookMsg
         * @property {string|null} [context] PackageHookMsg context
         * @property {Array.<number>|null} [versions] PackageHookMsg versions
         */

        /**
         * Constructs a new PackageHookMsg.
         * @memberof cat
         * @classdesc Represents a PackageHookMsg.
         * @implements IPackageHookMsg
         * @constructor
         * @param {cat.IPackageHookMsg=} [p] Properties to set
         */
        function PackageHookMsg(p) {
            this.versions = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PackageHookMsg context.
         * @member {string} context
         * @memberof cat.PackageHookMsg
         * @instance
         */
        PackageHookMsg.prototype.context = "";

        /**
         * PackageHookMsg versions.
         * @member {Array.<number>} versions
         * @memberof cat.PackageHookMsg
         * @instance
         */
        PackageHookMsg.prototype.versions = $util.emptyArray;

        /**
         * Creates a new PackageHookMsg instance using the specified properties.
         * @function create
         * @memberof cat.PackageHookMsg
         * @static
         * @param {cat.IPackageHookMsg=} [properties] Properties to set
         * @returns {cat.PackageHookMsg} PackageHookMsg instance
         */
        PackageHookMsg.create = function create(properties) {
            return new PackageHookMsg(properties);
        };

        /**
         * Encodes the specified PackageHookMsg message. Does not implicitly {@link cat.PackageHookMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PackageHookMsg
         * @static
         * @param {cat.IPackageHookMsg} m PackageHookMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageHookMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                w.uint32(10).string(m.context);
            if (m.versions != null && m.versions.length) {
                w.uint32(18).fork();
                for (var i = 0; i < m.versions.length; ++i)
                    w.uint64(m.versions[i]);
                w.ldelim();
            }
            return w;
        };

        /**
         * Decodes a PackageHookMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PackageHookMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PackageHookMsg} PackageHookMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageHookMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PackageHookMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.context = r.string();
                        break;
                    }
                case 2: {
                        if (!(m.versions && m.versions.length))
                            m.versions = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.versions.push(r.uint64());
                        } else
                            m.versions.push(r.uint64());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PackageHookMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PackageHookMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PackageHookMsg} PackageHookMsg
         */
        PackageHookMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PackageHookMsg)
                return d;
            var m = new $root.cat.PackageHookMsg();
            if (d.context != null) {
                m.context = String(d.context);
            }
            if (d.versions) {
                if (!Array.isArray(d.versions))
                    throw TypeError(".cat.PackageHookMsg.versions: array expected");
                m.versions = [];
                for (var i = 0; i < d.versions.length; ++i) {
                    if ($util.Long)
                        (m.versions[i] = $util.Long.fromValue(d.versions[i])).unsigned = true;
                    else if (typeof d.versions[i] === "string")
                        m.versions[i] = parseInt(d.versions[i], 10);
                    else if (typeof d.versions[i] === "number")
                        m.versions[i] = d.versions[i];
                    else if (typeof d.versions[i] === "object")
                        m.versions[i] = new $util.LongBits(d.versions[i].low >>> 0, d.versions[i].high >>> 0).toNumber(true);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PackageHookMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PackageHookMsg
         * @static
         * @param {cat.PackageHookMsg} m PackageHookMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageHookMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.versions = [];
            }
            if (o.defaults) {
                d.context = "";
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = m.context;
            }
            if (m.versions && m.versions.length) {
                d.versions = [];
                for (var j = 0; j < m.versions.length; ++j) {
                    if (typeof m.versions[j] === "number")
                        d.versions[j] = o.longs === String ? String(m.versions[j]) : m.versions[j];
                    else
                        d.versions[j] = o.longs === String ? $util.Long.prototype.toString.call(m.versions[j]) : o.longs === Number ? new $util.LongBits(m.versions[j].low >>> 0, m.versions[j].high >>> 0).toNumber(true) : m.versions[j];
                }
            }
            return d;
        };

        /**
         * Converts this PackageHookMsg to JSON.
         * @function toJSON
         * @memberof cat.PackageHookMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageHookMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageHookMsg
         * @function getTypeUrl
         * @memberof cat.PackageHookMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageHookMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PackageHookMsg";
        };

        return PackageHookMsg;
    })();

    cat.PackagePatchMsg = (function() {

        /**
         * Properties of a PackagePatchMsg.
         * @memberof cat
         * @interface IPackagePatchMsg
         * @property {Array.<number>|null} [versions] PackagePatchMsg versions
         * @property {string|null} [description] PackagePatchMsg description
         * @property {Object.<string,string>|null} [permissions] PackagePatchMsg permissions
         * @property {Object.<string,string>|null} [activity] PackagePatchMsg activity
         * @property {boolean|null} [libcat] PackagePatchMsg libcat
         */

        /**
         * Constructs a new PackagePatchMsg.
         * @memberof cat
         * @classdesc Represents a PackagePatchMsg.
         * @implements IPackagePatchMsg
         * @constructor
         * @param {cat.IPackagePatchMsg=} [p] Properties to set
         */
        function PackagePatchMsg(p) {
            this.versions = [];
            this.permissions = {};
            this.activity = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PackagePatchMsg versions.
         * @member {Array.<number>} versions
         * @memberof cat.PackagePatchMsg
         * @instance
         */
        PackagePatchMsg.prototype.versions = $util.emptyArray;

        /**
         * PackagePatchMsg description.
         * @member {string|null|undefined} description
         * @memberof cat.PackagePatchMsg
         * @instance
         */
        PackagePatchMsg.prototype.description = null;

        /**
         * PackagePatchMsg permissions.
         * @member {Object.<string,string>} permissions
         * @memberof cat.PackagePatchMsg
         * @instance
         */
        PackagePatchMsg.prototype.permissions = $util.emptyObject;

        /**
         * PackagePatchMsg activity.
         * @member {Object.<string,string>} activity
         * @memberof cat.PackagePatchMsg
         * @instance
         */
        PackagePatchMsg.prototype.activity = $util.emptyObject;

        /**
         * PackagePatchMsg libcat.
         * @member {boolean} libcat
         * @memberof cat.PackagePatchMsg
         * @instance
         */
        PackagePatchMsg.prototype.libcat = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(PackagePatchMsg.prototype, "_description", {
            get: $util.oneOfGetter($oneOfFields = ["description"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PackagePatchMsg instance using the specified properties.
         * @function create
         * @memberof cat.PackagePatchMsg
         * @static
         * @param {cat.IPackagePatchMsg=} [properties] Properties to set
         * @returns {cat.PackagePatchMsg} PackagePatchMsg instance
         */
        PackagePatchMsg.create = function create(properties) {
            return new PackagePatchMsg(properties);
        };

        /**
         * Encodes the specified PackagePatchMsg message. Does not implicitly {@link cat.PackagePatchMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PackagePatchMsg
         * @static
         * @param {cat.IPackagePatchMsg} m PackagePatchMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackagePatchMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.versions != null && m.versions.length) {
                w.uint32(10).fork();
                for (var i = 0; i < m.versions.length; ++i)
                    w.uint64(m.versions[i]);
                w.ldelim();
            }
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(18).string(m.description);
            if (m.permissions != null && Object.hasOwnProperty.call(m, "permissions")) {
                for (var ks = Object.keys(m.permissions), i = 0; i < ks.length; ++i) {
                    w.uint32(26).fork().uint32(10).string(ks[i]).uint32(18).string(m.permissions[ks[i]]).ldelim();
                }
            }
            if (m.activity != null && Object.hasOwnProperty.call(m, "activity")) {
                for (var ks = Object.keys(m.activity), i = 0; i < ks.length; ++i) {
                    w.uint32(34).fork().uint32(10).string(ks[i]).uint32(18).string(m.activity[ks[i]]).ldelim();
                }
            }
            if (m.libcat != null && Object.hasOwnProperty.call(m, "libcat"))
                w.uint32(40).bool(m.libcat);
            return w;
        };

        /**
         * Decodes a PackagePatchMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PackagePatchMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PackagePatchMsg} PackagePatchMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackagePatchMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PackagePatchMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (!(m.versions && m.versions.length))
                            m.versions = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.versions.push(r.uint64());
                        } else
                            m.versions.push(r.uint64());
                        break;
                    }
                case 2: {
                        m.description = r.string();
                        break;
                    }
                case 3: {
                        if (m.permissions === $util.emptyObject)
                            m.permissions = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.permissions[k] = value;
                        break;
                    }
                case 4: {
                        if (m.activity === $util.emptyObject)
                            m.activity = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.activity[k] = value;
                        break;
                    }
                case 5: {
                        m.libcat = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PackagePatchMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PackagePatchMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PackagePatchMsg} PackagePatchMsg
         */
        PackagePatchMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PackagePatchMsg)
                return d;
            var m = new $root.cat.PackagePatchMsg();
            if (d.versions) {
                if (!Array.isArray(d.versions))
                    throw TypeError(".cat.PackagePatchMsg.versions: array expected");
                m.versions = [];
                for (var i = 0; i < d.versions.length; ++i) {
                    if ($util.Long)
                        (m.versions[i] = $util.Long.fromValue(d.versions[i])).unsigned = true;
                    else if (typeof d.versions[i] === "string")
                        m.versions[i] = parseInt(d.versions[i], 10);
                    else if (typeof d.versions[i] === "number")
                        m.versions[i] = d.versions[i];
                    else if (typeof d.versions[i] === "object")
                        m.versions[i] = new $util.LongBits(d.versions[i].low >>> 0, d.versions[i].high >>> 0).toNumber(true);
                }
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.permissions) {
                if (typeof d.permissions !== "object")
                    throw TypeError(".cat.PackagePatchMsg.permissions: object expected");
                m.permissions = {};
                for (var ks = Object.keys(d.permissions), i = 0; i < ks.length; ++i) {
                    m.permissions[ks[i]] = String(d.permissions[ks[i]]);
                }
            }
            if (d.activity) {
                if (typeof d.activity !== "object")
                    throw TypeError(".cat.PackagePatchMsg.activity: object expected");
                m.activity = {};
                for (var ks = Object.keys(d.activity), i = 0; i < ks.length; ++i) {
                    m.activity[ks[i]] = String(d.activity[ks[i]]);
                }
            }
            if (d.libcat != null) {
                m.libcat = Boolean(d.libcat);
            }
            return m;
        };

        /**
         * Creates a plain object from a PackagePatchMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PackagePatchMsg
         * @static
         * @param {cat.PackagePatchMsg} m PackagePatchMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackagePatchMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.versions = [];
            }
            if (o.objects || o.defaults) {
                d.permissions = {};
                d.activity = {};
            }
            if (o.defaults) {
                d.libcat = false;
            }
            if (m.versions && m.versions.length) {
                d.versions = [];
                for (var j = 0; j < m.versions.length; ++j) {
                    if (typeof m.versions[j] === "number")
                        d.versions[j] = o.longs === String ? String(m.versions[j]) : m.versions[j];
                    else
                        d.versions[j] = o.longs === String ? $util.Long.prototype.toString.call(m.versions[j]) : o.longs === Number ? new $util.LongBits(m.versions[j].low >>> 0, m.versions[j].high >>> 0).toNumber(true) : m.versions[j];
                }
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
                if (o.oneofs)
                    d._description = "description";
            }
            var ks2;
            if (m.permissions && (ks2 = Object.keys(m.permissions)).length) {
                d.permissions = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.permissions[ks2[j]] = m.permissions[ks2[j]];
                }
            }
            if (m.activity && (ks2 = Object.keys(m.activity)).length) {
                d.activity = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.activity[ks2[j]] = m.activity[ks2[j]];
                }
            }
            if (m.libcat != null && m.hasOwnProperty("libcat")) {
                d.libcat = m.libcat;
            }
            return d;
        };

        /**
         * Converts this PackagePatchMsg to JSON.
         * @function toJSON
         * @memberof cat.PackagePatchMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackagePatchMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackagePatchMsg
         * @function getTypeUrl
         * @memberof cat.PackagePatchMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackagePatchMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PackagePatchMsg";
        };

        return PackagePatchMsg;
    })();

    /**
     * Terminology enum.
     * @name cat.Terminology
     * @enum {number}
     * @property {number} TERMINOLOGY_UNKNOWN=0 TERMINOLOGY_UNKNOWN value
     * @property {number} TERMINOLOGY_PROFILE=1 TERMINOLOGY_PROFILE value
     * @property {number} TERMINOLOGY_ACCOUNT=2 TERMINOLOGY_ACCOUNT value
     * @property {number} TERMINOLOGY_THEME=3 TERMINOLOGY_THEME value
     * @property {number} TERMINOLOGY_CASE=4 TERMINOLOGY_CASE value
     */
    cat.Terminology = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TERMINOLOGY_UNKNOWN"] = 0;
        values[valuesById[1] = "TERMINOLOGY_PROFILE"] = 1;
        values[valuesById[2] = "TERMINOLOGY_ACCOUNT"] = 2;
        values[valuesById[3] = "TERMINOLOGY_THEME"] = 3;
        values[valuesById[4] = "TERMINOLOGY_CASE"] = 4;
        return values;
    })();

    cat.TerminologyMsg = (function() {

        /**
         * Properties of a TerminologyMsg.
         * @memberof cat
         * @interface ITerminologyMsg
         * @property {cat.Terminology|null} [id] TerminologyMsg id
         * @property {Object.<string,string>|null} [singular] TerminologyMsg singular
         * @property {Object.<string,string>|null} [plural] TerminologyMsg plural
         */

        /**
         * Constructs a new TerminologyMsg.
         * @memberof cat
         * @classdesc Represents a TerminologyMsg.
         * @implements ITerminologyMsg
         * @constructor
         * @param {cat.ITerminologyMsg=} [p] Properties to set
         */
        function TerminologyMsg(p) {
            this.singular = {};
            this.plural = {};
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TerminologyMsg id.
         * @member {cat.Terminology} id
         * @memberof cat.TerminologyMsg
         * @instance
         */
        TerminologyMsg.prototype.id = 0;

        /**
         * TerminologyMsg singular.
         * @member {Object.<string,string>} singular
         * @memberof cat.TerminologyMsg
         * @instance
         */
        TerminologyMsg.prototype.singular = $util.emptyObject;

        /**
         * TerminologyMsg plural.
         * @member {Object.<string,string>} plural
         * @memberof cat.TerminologyMsg
         * @instance
         */
        TerminologyMsg.prototype.plural = $util.emptyObject;

        /**
         * Creates a new TerminologyMsg instance using the specified properties.
         * @function create
         * @memberof cat.TerminologyMsg
         * @static
         * @param {cat.ITerminologyMsg=} [properties] Properties to set
         * @returns {cat.TerminologyMsg} TerminologyMsg instance
         */
        TerminologyMsg.create = function create(properties) {
            return new TerminologyMsg(properties);
        };

        /**
         * Encodes the specified TerminologyMsg message. Does not implicitly {@link cat.TerminologyMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.TerminologyMsg
         * @static
         * @param {cat.ITerminologyMsg} m TerminologyMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TerminologyMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(8).int32(m.id);
            if (m.singular != null && Object.hasOwnProperty.call(m, "singular")) {
                for (var ks = Object.keys(m.singular), i = 0; i < ks.length; ++i) {
                    w.uint32(18).fork().uint32(10).string(ks[i]).uint32(18).string(m.singular[ks[i]]).ldelim();
                }
            }
            if (m.plural != null && Object.hasOwnProperty.call(m, "plural")) {
                for (var ks = Object.keys(m.plural), i = 0; i < ks.length; ++i) {
                    w.uint32(26).fork().uint32(10).string(ks[i]).uint32(18).string(m.plural[ks[i]]).ldelim();
                }
            }
            return w;
        };

        /**
         * Decodes a TerminologyMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.TerminologyMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.TerminologyMsg} TerminologyMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TerminologyMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.TerminologyMsg(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.int32();
                        break;
                    }
                case 2: {
                        if (m.singular === $util.emptyObject)
                            m.singular = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.singular[k] = value;
                        break;
                    }
                case 3: {
                        if (m.plural === $util.emptyObject)
                            m.plural = {};
                        var c2 = r.uint32() + r.pos;
                        k = "";
                        value = "";
                        while (r.pos < c2) {
                            var tag2 = r.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                k = r.string();
                                break;
                            case 2:
                                value = r.string();
                                break;
                            default:
                                r.skipType(tag2 & 7);
                                break;
                            }
                        }
                        m.plural[k] = value;
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TerminologyMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.TerminologyMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.TerminologyMsg} TerminologyMsg
         */
        TerminologyMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.TerminologyMsg)
                return d;
            var m = new $root.cat.TerminologyMsg();
            switch (d.id) {
            default:
                if (typeof d.id === "number") {
                    m.id = d.id;
                    break;
                }
                break;
            case "TERMINOLOGY_UNKNOWN":
            case 0:
                m.id = 0;
                break;
            case "TERMINOLOGY_PROFILE":
            case 1:
                m.id = 1;
                break;
            case "TERMINOLOGY_ACCOUNT":
            case 2:
                m.id = 2;
                break;
            case "TERMINOLOGY_THEME":
            case 3:
                m.id = 3;
                break;
            case "TERMINOLOGY_CASE":
            case 4:
                m.id = 4;
                break;
            }
            if (d.singular) {
                if (typeof d.singular !== "object")
                    throw TypeError(".cat.TerminologyMsg.singular: object expected");
                m.singular = {};
                for (var ks = Object.keys(d.singular), i = 0; i < ks.length; ++i) {
                    m.singular[ks[i]] = String(d.singular[ks[i]]);
                }
            }
            if (d.plural) {
                if (typeof d.plural !== "object")
                    throw TypeError(".cat.TerminologyMsg.plural: object expected");
                m.plural = {};
                for (var ks = Object.keys(d.plural), i = 0; i < ks.length; ++i) {
                    m.plural[ks[i]] = String(d.plural[ks[i]]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TerminologyMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.TerminologyMsg
         * @static
         * @param {cat.TerminologyMsg} m TerminologyMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TerminologyMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.objects || o.defaults) {
                d.singular = {};
                d.plural = {};
            }
            if (o.defaults) {
                d.id = o.enums === String ? "TERMINOLOGY_UNKNOWN" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = o.enums === String ? $root.cat.Terminology[m.id] === undefined ? m.id : $root.cat.Terminology[m.id] : m.id;
            }
            var ks2;
            if (m.singular && (ks2 = Object.keys(m.singular)).length) {
                d.singular = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.singular[ks2[j]] = m.singular[ks2[j]];
                }
            }
            if (m.plural && (ks2 = Object.keys(m.plural)).length) {
                d.plural = {};
                for (var j = 0; j < ks2.length; ++j) {
                    d.plural[ks2[j]] = m.plural[ks2[j]];
                }
            }
            return d;
        };

        /**
         * Converts this TerminologyMsg to JSON.
         * @function toJSON
         * @memberof cat.TerminologyMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TerminologyMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TerminologyMsg
         * @function getTypeUrl
         * @memberof cat.TerminologyMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TerminologyMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.TerminologyMsg";
        };

        return TerminologyMsg;
    })();

    /**
     * SupportedLanguages enum.
     * @name cat.SupportedLanguages
     * @enum {number}
     * @property {number} LANGUAGE_UNKNOWN=0 LANGUAGE_UNKNOWN value
     * @property {number} LANGUAGE_ENGLISH=1 LANGUAGE_ENGLISH value
     * @property {number} LANGUAGE_DUTCH=2 LANGUAGE_DUTCH value
     * @property {number} LANGUAGE_GERMAN=3 LANGUAGE_GERMAN value
     * @property {number} LANGUAGE_FRENCH=4 LANGUAGE_FRENCH value
     * @property {number} LANGUAGE_SPANISH=5 LANGUAGE_SPANISH value
     */
    cat.SupportedLanguages = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LANGUAGE_UNKNOWN"] = 0;
        values[valuesById[1] = "LANGUAGE_ENGLISH"] = 1;
        values[valuesById[2] = "LANGUAGE_DUTCH"] = 2;
        values[valuesById[3] = "LANGUAGE_GERMAN"] = 3;
        values[valuesById[4] = "LANGUAGE_FRENCH"] = 4;
        values[valuesById[5] = "LANGUAGE_SPANISH"] = 5;
        return values;
    })();

    cat.ProcessorStatisticsMsg = (function() {

        /**
         * Properties of a ProcessorStatisticsMsg.
         * @memberof cat
         * @interface IProcessorStatisticsMsg
         * @property {string|null} [deviceid] ProcessorStatisticsMsg deviceid
         * @property {string|null} [app] ProcessorStatisticsMsg app
         * @property {string|null} [context] ProcessorStatisticsMsg context
         * @property {number|null} [average] ProcessorStatisticsMsg average
         * @property {number|null} [max] ProcessorStatisticsMsg max
         * @property {number|null} [count] ProcessorStatisticsMsg count
         * @property {boolean|null} [success] ProcessorStatisticsMsg success
         * @property {number|null} [retrycount] ProcessorStatisticsMsg retrycount
         */

        /**
         * Constructs a new ProcessorStatisticsMsg.
         * @memberof cat
         * @classdesc Represents a ProcessorStatisticsMsg.
         * @implements IProcessorStatisticsMsg
         * @constructor
         * @param {cat.IProcessorStatisticsMsg=} [p] Properties to set
         */
        function ProcessorStatisticsMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ProcessorStatisticsMsg deviceid.
         * @member {string} deviceid
         * @memberof cat.ProcessorStatisticsMsg
         * @instance
         */
        ProcessorStatisticsMsg.prototype.deviceid = "";

        /**
         * ProcessorStatisticsMsg app.
         * @member {string} app
         * @memberof cat.ProcessorStatisticsMsg
         * @instance
         */
        ProcessorStatisticsMsg.prototype.app = "";

        /**
         * ProcessorStatisticsMsg context.
         * @member {string} context
         * @memberof cat.ProcessorStatisticsMsg
         * @instance
         */
        ProcessorStatisticsMsg.prototype.context = "";

        /**
         * ProcessorStatisticsMsg average.
         * @member {number} average
         * @memberof cat.ProcessorStatisticsMsg
         * @instance
         */
        ProcessorStatisticsMsg.prototype.average = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProcessorStatisticsMsg max.
         * @member {number} max
         * @memberof cat.ProcessorStatisticsMsg
         * @instance
         */
        ProcessorStatisticsMsg.prototype.max = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProcessorStatisticsMsg count.
         * @member {number} count
         * @memberof cat.ProcessorStatisticsMsg
         * @instance
         */
        ProcessorStatisticsMsg.prototype.count = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProcessorStatisticsMsg success.
         * @member {boolean} success
         * @memberof cat.ProcessorStatisticsMsg
         * @instance
         */
        ProcessorStatisticsMsg.prototype.success = false;

        /**
         * ProcessorStatisticsMsg retrycount.
         * @member {number} retrycount
         * @memberof cat.ProcessorStatisticsMsg
         * @instance
         */
        ProcessorStatisticsMsg.prototype.retrycount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ProcessorStatisticsMsg instance using the specified properties.
         * @function create
         * @memberof cat.ProcessorStatisticsMsg
         * @static
         * @param {cat.IProcessorStatisticsMsg=} [properties] Properties to set
         * @returns {cat.ProcessorStatisticsMsg} ProcessorStatisticsMsg instance
         */
        ProcessorStatisticsMsg.create = function create(properties) {
            return new ProcessorStatisticsMsg(properties);
        };

        /**
         * Encodes the specified ProcessorStatisticsMsg message. Does not implicitly {@link cat.ProcessorStatisticsMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ProcessorStatisticsMsg
         * @static
         * @param {cat.IProcessorStatisticsMsg} m ProcessorStatisticsMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProcessorStatisticsMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.deviceid != null && Object.hasOwnProperty.call(m, "deviceid"))
                w.uint32(10).string(m.deviceid);
            if (m.app != null && Object.hasOwnProperty.call(m, "app"))
                w.uint32(18).string(m.app);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                w.uint32(26).string(m.context);
            if (m.average != null && Object.hasOwnProperty.call(m, "average"))
                w.uint32(32).uint64(m.average);
            if (m.max != null && Object.hasOwnProperty.call(m, "max"))
                w.uint32(40).uint64(m.max);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(48).uint64(m.count);
            if (m.success != null && Object.hasOwnProperty.call(m, "success"))
                w.uint32(56).bool(m.success);
            if (m.retrycount != null && Object.hasOwnProperty.call(m, "retrycount"))
                w.uint32(64).uint64(m.retrycount);
            return w;
        };

        /**
         * Decodes a ProcessorStatisticsMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ProcessorStatisticsMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ProcessorStatisticsMsg} ProcessorStatisticsMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProcessorStatisticsMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ProcessorStatisticsMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.deviceid = r.string();
                        break;
                    }
                case 2: {
                        m.app = r.string();
                        break;
                    }
                case 3: {
                        m.context = r.string();
                        break;
                    }
                case 4: {
                        m.average = r.uint64();
                        break;
                    }
                case 5: {
                        m.max = r.uint64();
                        break;
                    }
                case 6: {
                        m.count = r.uint64();
                        break;
                    }
                case 7: {
                        m.success = r.bool();
                        break;
                    }
                case 8: {
                        m.retrycount = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ProcessorStatisticsMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ProcessorStatisticsMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ProcessorStatisticsMsg} ProcessorStatisticsMsg
         */
        ProcessorStatisticsMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ProcessorStatisticsMsg)
                return d;
            var m = new $root.cat.ProcessorStatisticsMsg();
            if (d.deviceid != null) {
                m.deviceid = String(d.deviceid);
            }
            if (d.app != null) {
                m.app = String(d.app);
            }
            if (d.context != null) {
                m.context = String(d.context);
            }
            if (d.average != null) {
                if ($util.Long)
                    (m.average = $util.Long.fromValue(d.average)).unsigned = true;
                else if (typeof d.average === "string")
                    m.average = parseInt(d.average, 10);
                else if (typeof d.average === "number")
                    m.average = d.average;
                else if (typeof d.average === "object")
                    m.average = new $util.LongBits(d.average.low >>> 0, d.average.high >>> 0).toNumber(true);
            }
            if (d.max != null) {
                if ($util.Long)
                    (m.max = $util.Long.fromValue(d.max)).unsigned = true;
                else if (typeof d.max === "string")
                    m.max = parseInt(d.max, 10);
                else if (typeof d.max === "number")
                    m.max = d.max;
                else if (typeof d.max === "object")
                    m.max = new $util.LongBits(d.max.low >>> 0, d.max.high >>> 0).toNumber(true);
            }
            if (d.count != null) {
                if ($util.Long)
                    (m.count = $util.Long.fromValue(d.count)).unsigned = true;
                else if (typeof d.count === "string")
                    m.count = parseInt(d.count, 10);
                else if (typeof d.count === "number")
                    m.count = d.count;
                else if (typeof d.count === "object")
                    m.count = new $util.LongBits(d.count.low >>> 0, d.count.high >>> 0).toNumber(true);
            }
            if (d.success != null) {
                m.success = Boolean(d.success);
            }
            if (d.retrycount != null) {
                if ($util.Long)
                    (m.retrycount = $util.Long.fromValue(d.retrycount)).unsigned = true;
                else if (typeof d.retrycount === "string")
                    m.retrycount = parseInt(d.retrycount, 10);
                else if (typeof d.retrycount === "number")
                    m.retrycount = d.retrycount;
                else if (typeof d.retrycount === "object")
                    m.retrycount = new $util.LongBits(d.retrycount.low >>> 0, d.retrycount.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a ProcessorStatisticsMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ProcessorStatisticsMsg
         * @static
         * @param {cat.ProcessorStatisticsMsg} m ProcessorStatisticsMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProcessorStatisticsMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.deviceid = "";
                d.app = "";
                d.context = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.average = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.average = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.max = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.max = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.count = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.count = o.longs === String ? "0" : 0;
                d.success = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.retrycount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.retrycount = o.longs === String ? "0" : 0;
            }
            if (m.deviceid != null && m.hasOwnProperty("deviceid")) {
                d.deviceid = m.deviceid;
            }
            if (m.app != null && m.hasOwnProperty("app")) {
                d.app = m.app;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = m.context;
            }
            if (m.average != null && m.hasOwnProperty("average")) {
                if (typeof m.average === "number")
                    d.average = o.longs === String ? String(m.average) : m.average;
                else
                    d.average = o.longs === String ? $util.Long.prototype.toString.call(m.average) : o.longs === Number ? new $util.LongBits(m.average.low >>> 0, m.average.high >>> 0).toNumber(true) : m.average;
            }
            if (m.max != null && m.hasOwnProperty("max")) {
                if (typeof m.max === "number")
                    d.max = o.longs === String ? String(m.max) : m.max;
                else
                    d.max = o.longs === String ? $util.Long.prototype.toString.call(m.max) : o.longs === Number ? new $util.LongBits(m.max.low >>> 0, m.max.high >>> 0).toNumber(true) : m.max;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                if (typeof m.count === "number")
                    d.count = o.longs === String ? String(m.count) : m.count;
                else
                    d.count = o.longs === String ? $util.Long.prototype.toString.call(m.count) : o.longs === Number ? new $util.LongBits(m.count.low >>> 0, m.count.high >>> 0).toNumber(true) : m.count;
            }
            if (m.success != null && m.hasOwnProperty("success")) {
                d.success = m.success;
            }
            if (m.retrycount != null && m.hasOwnProperty("retrycount")) {
                if (typeof m.retrycount === "number")
                    d.retrycount = o.longs === String ? String(m.retrycount) : m.retrycount;
                else
                    d.retrycount = o.longs === String ? $util.Long.prototype.toString.call(m.retrycount) : o.longs === Number ? new $util.LongBits(m.retrycount.low >>> 0, m.retrycount.high >>> 0).toNumber(true) : m.retrycount;
            }
            return d;
        };

        /**
         * Converts this ProcessorStatisticsMsg to JSON.
         * @function toJSON
         * @memberof cat.ProcessorStatisticsMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProcessorStatisticsMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProcessorStatisticsMsg
         * @function getTypeUrl
         * @memberof cat.ProcessorStatisticsMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProcessorStatisticsMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ProcessorStatisticsMsg";
        };

        return ProcessorStatisticsMsg;
    })();

    cat.ProcessorStatRawMsg = (function() {

        /**
         * Properties of a ProcessorStatRawMsg.
         * @memberof cat
         * @interface IProcessorStatRawMsg
         * @property {string|null} [deviceid] ProcessorStatRawMsg deviceid
         * @property {string|null} [app] ProcessorStatRawMsg app
         * @property {string|null} [context] ProcessorStatRawMsg context
         * @property {number|null} [time] ProcessorStatRawMsg time
         * @property {boolean|null} [success] ProcessorStatRawMsg success
         * @property {number|null} [created] ProcessorStatRawMsg created
         * @property {number|null} [retrycount] ProcessorStatRawMsg retrycount
         */

        /**
         * Constructs a new ProcessorStatRawMsg.
         * @memberof cat
         * @classdesc Represents a ProcessorStatRawMsg.
         * @implements IProcessorStatRawMsg
         * @constructor
         * @param {cat.IProcessorStatRawMsg=} [p] Properties to set
         */
        function ProcessorStatRawMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ProcessorStatRawMsg deviceid.
         * @member {string} deviceid
         * @memberof cat.ProcessorStatRawMsg
         * @instance
         */
        ProcessorStatRawMsg.prototype.deviceid = "";

        /**
         * ProcessorStatRawMsg app.
         * @member {string} app
         * @memberof cat.ProcessorStatRawMsg
         * @instance
         */
        ProcessorStatRawMsg.prototype.app = "";

        /**
         * ProcessorStatRawMsg context.
         * @member {string} context
         * @memberof cat.ProcessorStatRawMsg
         * @instance
         */
        ProcessorStatRawMsg.prototype.context = "";

        /**
         * ProcessorStatRawMsg time.
         * @member {number} time
         * @memberof cat.ProcessorStatRawMsg
         * @instance
         */
        ProcessorStatRawMsg.prototype.time = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProcessorStatRawMsg success.
         * @member {boolean} success
         * @memberof cat.ProcessorStatRawMsg
         * @instance
         */
        ProcessorStatRawMsg.prototype.success = false;

        /**
         * ProcessorStatRawMsg created.
         * @member {number} created
         * @memberof cat.ProcessorStatRawMsg
         * @instance
         */
        ProcessorStatRawMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ProcessorStatRawMsg retrycount.
         * @member {number} retrycount
         * @memberof cat.ProcessorStatRawMsg
         * @instance
         */
        ProcessorStatRawMsg.prototype.retrycount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ProcessorStatRawMsg instance using the specified properties.
         * @function create
         * @memberof cat.ProcessorStatRawMsg
         * @static
         * @param {cat.IProcessorStatRawMsg=} [properties] Properties to set
         * @returns {cat.ProcessorStatRawMsg} ProcessorStatRawMsg instance
         */
        ProcessorStatRawMsg.create = function create(properties) {
            return new ProcessorStatRawMsg(properties);
        };

        /**
         * Encodes the specified ProcessorStatRawMsg message. Does not implicitly {@link cat.ProcessorStatRawMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.ProcessorStatRawMsg
         * @static
         * @param {cat.IProcessorStatRawMsg} m ProcessorStatRawMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProcessorStatRawMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.deviceid != null && Object.hasOwnProperty.call(m, "deviceid"))
                w.uint32(10).string(m.deviceid);
            if (m.app != null && Object.hasOwnProperty.call(m, "app"))
                w.uint32(18).string(m.app);
            if (m.context != null && Object.hasOwnProperty.call(m, "context"))
                w.uint32(26).string(m.context);
            if (m.time != null && Object.hasOwnProperty.call(m, "time"))
                w.uint32(32).uint64(m.time);
            if (m.success != null && Object.hasOwnProperty.call(m, "success"))
                w.uint32(40).bool(m.success);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(48).uint64(m.created);
            if (m.retrycount != null && Object.hasOwnProperty.call(m, "retrycount"))
                w.uint32(56).uint64(m.retrycount);
            return w;
        };

        /**
         * Decodes a ProcessorStatRawMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.ProcessorStatRawMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.ProcessorStatRawMsg} ProcessorStatRawMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProcessorStatRawMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.ProcessorStatRawMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.deviceid = r.string();
                        break;
                    }
                case 2: {
                        m.app = r.string();
                        break;
                    }
                case 3: {
                        m.context = r.string();
                        break;
                    }
                case 4: {
                        m.time = r.uint64();
                        break;
                    }
                case 5: {
                        m.success = r.bool();
                        break;
                    }
                case 6: {
                        m.created = r.uint64();
                        break;
                    }
                case 7: {
                        m.retrycount = r.uint64();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ProcessorStatRawMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.ProcessorStatRawMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.ProcessorStatRawMsg} ProcessorStatRawMsg
         */
        ProcessorStatRawMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.ProcessorStatRawMsg)
                return d;
            var m = new $root.cat.ProcessorStatRawMsg();
            if (d.deviceid != null) {
                m.deviceid = String(d.deviceid);
            }
            if (d.app != null) {
                m.app = String(d.app);
            }
            if (d.context != null) {
                m.context = String(d.context);
            }
            if (d.time != null) {
                if ($util.Long)
                    (m.time = $util.Long.fromValue(d.time)).unsigned = true;
                else if (typeof d.time === "string")
                    m.time = parseInt(d.time, 10);
                else if (typeof d.time === "number")
                    m.time = d.time;
                else if (typeof d.time === "object")
                    m.time = new $util.LongBits(d.time.low >>> 0, d.time.high >>> 0).toNumber(true);
            }
            if (d.success != null) {
                m.success = Boolean(d.success);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.retrycount != null) {
                if ($util.Long)
                    (m.retrycount = $util.Long.fromValue(d.retrycount)).unsigned = true;
                else if (typeof d.retrycount === "string")
                    m.retrycount = parseInt(d.retrycount, 10);
                else if (typeof d.retrycount === "number")
                    m.retrycount = d.retrycount;
                else if (typeof d.retrycount === "object")
                    m.retrycount = new $util.LongBits(d.retrycount.low >>> 0, d.retrycount.high >>> 0).toNumber(true);
            }
            return m;
        };

        /**
         * Creates a plain object from a ProcessorStatRawMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.ProcessorStatRawMsg
         * @static
         * @param {cat.ProcessorStatRawMsg} m ProcessorStatRawMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProcessorStatRawMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.deviceid = "";
                d.app = "";
                d.context = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.time = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.time = o.longs === String ? "0" : 0;
                d.success = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.retrycount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.retrycount = o.longs === String ? "0" : 0;
            }
            if (m.deviceid != null && m.hasOwnProperty("deviceid")) {
                d.deviceid = m.deviceid;
            }
            if (m.app != null && m.hasOwnProperty("app")) {
                d.app = m.app;
            }
            if (m.context != null && m.hasOwnProperty("context")) {
                d.context = m.context;
            }
            if (m.time != null && m.hasOwnProperty("time")) {
                if (typeof m.time === "number")
                    d.time = o.longs === String ? String(m.time) : m.time;
                else
                    d.time = o.longs === String ? $util.Long.prototype.toString.call(m.time) : o.longs === Number ? new $util.LongBits(m.time.low >>> 0, m.time.high >>> 0).toNumber(true) : m.time;
            }
            if (m.success != null && m.hasOwnProperty("success")) {
                d.success = m.success;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.retrycount != null && m.hasOwnProperty("retrycount")) {
                if (typeof m.retrycount === "number")
                    d.retrycount = o.longs === String ? String(m.retrycount) : m.retrycount;
                else
                    d.retrycount = o.longs === String ? $util.Long.prototype.toString.call(m.retrycount) : o.longs === Number ? new $util.LongBits(m.retrycount.low >>> 0, m.retrycount.high >>> 0).toNumber(true) : m.retrycount;
            }
            return d;
        };

        /**
         * Converts this ProcessorStatRawMsg to JSON.
         * @function toJSON
         * @memberof cat.ProcessorStatRawMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProcessorStatRawMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProcessorStatRawMsg
         * @function getTypeUrl
         * @memberof cat.ProcessorStatRawMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProcessorStatRawMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.ProcessorStatRawMsg";
        };

        return ProcessorStatRawMsg;
    })();

    cat.SeriesMsg = (function() {

        /**
         * Properties of a SeriesMsg.
         * @memberof cat
         * @interface ISeriesMsg
         * @property {string|null} [name] SeriesMsg name
         * @property {Array.<string>|null} [data] SeriesMsg data
         */

        /**
         * Constructs a new SeriesMsg.
         * @memberof cat
         * @classdesc Represents a SeriesMsg.
         * @implements ISeriesMsg
         * @constructor
         * @param {cat.ISeriesMsg=} [p] Properties to set
         */
        function SeriesMsg(p) {
            this.data = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SeriesMsg name.
         * @member {string} name
         * @memberof cat.SeriesMsg
         * @instance
         */
        SeriesMsg.prototype.name = "";

        /**
         * SeriesMsg data.
         * @member {Array.<string>} data
         * @memberof cat.SeriesMsg
         * @instance
         */
        SeriesMsg.prototype.data = $util.emptyArray;

        /**
         * Creates a new SeriesMsg instance using the specified properties.
         * @function create
         * @memberof cat.SeriesMsg
         * @static
         * @param {cat.ISeriesMsg=} [properties] Properties to set
         * @returns {cat.SeriesMsg} SeriesMsg instance
         */
        SeriesMsg.create = function create(properties) {
            return new SeriesMsg(properties);
        };

        /**
         * Encodes the specified SeriesMsg message. Does not implicitly {@link cat.SeriesMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.SeriesMsg
         * @static
         * @param {cat.ISeriesMsg} m SeriesMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeriesMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.data != null && m.data.length) {
                for (var i = 0; i < m.data.length; ++i)
                    w.uint32(18).string(m.data[i]);
            }
            return w;
        };

        /**
         * Decodes a SeriesMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.SeriesMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.SeriesMsg} SeriesMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeriesMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.SeriesMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.name = r.string();
                        break;
                    }
                case 2: {
                        if (!(m.data && m.data.length))
                            m.data = [];
                        m.data.push(r.string());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a SeriesMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.SeriesMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.SeriesMsg} SeriesMsg
         */
        SeriesMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.SeriesMsg)
                return d;
            var m = new $root.cat.SeriesMsg();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.data) {
                if (!Array.isArray(d.data))
                    throw TypeError(".cat.SeriesMsg.data: array expected");
                m.data = [];
                for (var i = 0; i < d.data.length; ++i) {
                    m.data[i] = String(d.data[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a SeriesMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.SeriesMsg
         * @static
         * @param {cat.SeriesMsg} m SeriesMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeriesMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.data = [];
            }
            if (o.defaults) {
                d.name = "";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.data && m.data.length) {
                d.data = [];
                for (var j = 0; j < m.data.length; ++j) {
                    d.data[j] = m.data[j];
                }
            }
            return d;
        };

        /**
         * Converts this SeriesMsg to JSON.
         * @function toJSON
         * @memberof cat.SeriesMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeriesMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SeriesMsg
         * @function getTypeUrl
         * @memberof cat.SeriesMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SeriesMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.SeriesMsg";
        };

        return SeriesMsg;
    })();

    cat.StatisticsSeriesMsg = (function() {

        /**
         * Properties of a StatisticsSeriesMsg.
         * @memberof cat
         * @interface IStatisticsSeriesMsg
         * @property {Array.<string>|null} [labels] StatisticsSeriesMsg labels
         * @property {Array.<cat.ISeriesMsg>|null} [series] StatisticsSeriesMsg series
         */

        /**
         * Constructs a new StatisticsSeriesMsg.
         * @memberof cat
         * @classdesc Represents a StatisticsSeriesMsg.
         * @implements IStatisticsSeriesMsg
         * @constructor
         * @param {cat.IStatisticsSeriesMsg=} [p] Properties to set
         */
        function StatisticsSeriesMsg(p) {
            this.labels = [];
            this.series = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StatisticsSeriesMsg labels.
         * @member {Array.<string>} labels
         * @memberof cat.StatisticsSeriesMsg
         * @instance
         */
        StatisticsSeriesMsg.prototype.labels = $util.emptyArray;

        /**
         * StatisticsSeriesMsg series.
         * @member {Array.<cat.ISeriesMsg>} series
         * @memberof cat.StatisticsSeriesMsg
         * @instance
         */
        StatisticsSeriesMsg.prototype.series = $util.emptyArray;

        /**
         * Creates a new StatisticsSeriesMsg instance using the specified properties.
         * @function create
         * @memberof cat.StatisticsSeriesMsg
         * @static
         * @param {cat.IStatisticsSeriesMsg=} [properties] Properties to set
         * @returns {cat.StatisticsSeriesMsg} StatisticsSeriesMsg instance
         */
        StatisticsSeriesMsg.create = function create(properties) {
            return new StatisticsSeriesMsg(properties);
        };

        /**
         * Encodes the specified StatisticsSeriesMsg message. Does not implicitly {@link cat.StatisticsSeriesMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.StatisticsSeriesMsg
         * @static
         * @param {cat.IStatisticsSeriesMsg} m StatisticsSeriesMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatisticsSeriesMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.labels != null && m.labels.length) {
                for (var i = 0; i < m.labels.length; ++i)
                    w.uint32(10).string(m.labels[i]);
            }
            if (m.series != null && m.series.length) {
                for (var i = 0; i < m.series.length; ++i)
                    $root.cat.SeriesMsg.encode(m.series[i], w.uint32(18).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a StatisticsSeriesMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.StatisticsSeriesMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.StatisticsSeriesMsg} StatisticsSeriesMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatisticsSeriesMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.StatisticsSeriesMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        if (!(m.labels && m.labels.length))
                            m.labels = [];
                        m.labels.push(r.string());
                        break;
                    }
                case 2: {
                        if (!(m.series && m.series.length))
                            m.series = [];
                        m.series.push($root.cat.SeriesMsg.decode(r, r.uint32()));
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a StatisticsSeriesMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.StatisticsSeriesMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.StatisticsSeriesMsg} StatisticsSeriesMsg
         */
        StatisticsSeriesMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.StatisticsSeriesMsg)
                return d;
            var m = new $root.cat.StatisticsSeriesMsg();
            if (d.labels) {
                if (!Array.isArray(d.labels))
                    throw TypeError(".cat.StatisticsSeriesMsg.labels: array expected");
                m.labels = [];
                for (var i = 0; i < d.labels.length; ++i) {
                    m.labels[i] = String(d.labels[i]);
                }
            }
            if (d.series) {
                if (!Array.isArray(d.series))
                    throw TypeError(".cat.StatisticsSeriesMsg.series: array expected");
                m.series = [];
                for (var i = 0; i < d.series.length; ++i) {
                    if (typeof d.series[i] !== "object")
                        throw TypeError(".cat.StatisticsSeriesMsg.series: object expected");
                    m.series[i] = $root.cat.SeriesMsg.fromObject(d.series[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a StatisticsSeriesMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.StatisticsSeriesMsg
         * @static
         * @param {cat.StatisticsSeriesMsg} m StatisticsSeriesMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatisticsSeriesMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.labels = [];
                d.series = [];
            }
            if (m.labels && m.labels.length) {
                d.labels = [];
                for (var j = 0; j < m.labels.length; ++j) {
                    d.labels[j] = m.labels[j];
                }
            }
            if (m.series && m.series.length) {
                d.series = [];
                for (var j = 0; j < m.series.length; ++j) {
                    d.series[j] = $root.cat.SeriesMsg.toObject(m.series[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this StatisticsSeriesMsg to JSON.
         * @function toJSON
         * @memberof cat.StatisticsSeriesMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatisticsSeriesMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StatisticsSeriesMsg
         * @function getTypeUrl
         * @memberof cat.StatisticsSeriesMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StatisticsSeriesMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.StatisticsSeriesMsg";
        };

        return StatisticsSeriesMsg;
    })();

    cat.FailureMsg = (function() {

        /**
         * Properties of a FailureMsg.
         * @memberof cat
         * @interface IFailureMsg
         * @property {string|null} [reason] FailureMsg reason
         * @property {cat.IReceiverItemMsg|null} [item] FailureMsg item
         * @property {cat.IReceiverFileMsg|null} [file] FailureMsg file
         */

        /**
         * Constructs a new FailureMsg.
         * @memberof cat
         * @classdesc Represents a FailureMsg.
         * @implements IFailureMsg
         * @constructor
         * @param {cat.IFailureMsg=} [p] Properties to set
         */
        function FailureMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FailureMsg reason.
         * @member {string} reason
         * @memberof cat.FailureMsg
         * @instance
         */
        FailureMsg.prototype.reason = "";

        /**
         * FailureMsg item.
         * @member {cat.IReceiverItemMsg|null|undefined} item
         * @memberof cat.FailureMsg
         * @instance
         */
        FailureMsg.prototype.item = null;

        /**
         * FailureMsg file.
         * @member {cat.IReceiverFileMsg|null|undefined} file
         * @memberof cat.FailureMsg
         * @instance
         */
        FailureMsg.prototype.file = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(FailureMsg.prototype, "_item", {
            get: $util.oneOfGetter($oneOfFields = ["item"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(FailureMsg.prototype, "_file", {
            get: $util.oneOfGetter($oneOfFields = ["file"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FailureMsg instance using the specified properties.
         * @function create
         * @memberof cat.FailureMsg
         * @static
         * @param {cat.IFailureMsg=} [properties] Properties to set
         * @returns {cat.FailureMsg} FailureMsg instance
         */
        FailureMsg.create = function create(properties) {
            return new FailureMsg(properties);
        };

        /**
         * Encodes the specified FailureMsg message. Does not implicitly {@link cat.FailureMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.FailureMsg
         * @static
         * @param {cat.IFailureMsg} m FailureMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FailureMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.reason != null && Object.hasOwnProperty.call(m, "reason"))
                w.uint32(10).string(m.reason);
            if (m.item != null && Object.hasOwnProperty.call(m, "item"))
                $root.cat.ReceiverItemMsg.encode(m.item, w.uint32(18).fork()).ldelim();
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.cat.ReceiverFileMsg.encode(m.file, w.uint32(26).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a FailureMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.FailureMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.FailureMsg} FailureMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FailureMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.FailureMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.reason = r.string();
                        break;
                    }
                case 2: {
                        m.item = $root.cat.ReceiverItemMsg.decode(r, r.uint32());
                        break;
                    }
                case 3: {
                        m.file = $root.cat.ReceiverFileMsg.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a FailureMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.FailureMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.FailureMsg} FailureMsg
         */
        FailureMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.FailureMsg)
                return d;
            var m = new $root.cat.FailureMsg();
            if (d.reason != null) {
                m.reason = String(d.reason);
            }
            if (d.item != null) {
                if (typeof d.item !== "object")
                    throw TypeError(".cat.FailureMsg.item: object expected");
                m.item = $root.cat.ReceiverItemMsg.fromObject(d.item);
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".cat.FailureMsg.file: object expected");
                m.file = $root.cat.ReceiverFileMsg.fromObject(d.file);
            }
            return m;
        };

        /**
         * Creates a plain object from a FailureMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.FailureMsg
         * @static
         * @param {cat.FailureMsg} m FailureMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FailureMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.reason = "";
            }
            if (m.reason != null && m.hasOwnProperty("reason")) {
                d.reason = m.reason;
            }
            if (m.item != null && m.hasOwnProperty("item")) {
                d.item = $root.cat.ReceiverItemMsg.toObject(m.item, o);
                if (o.oneofs)
                    d._item = "item";
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.cat.ReceiverFileMsg.toObject(m.file, o);
                if (o.oneofs)
                    d._file = "file";
            }
            return d;
        };

        /**
         * Converts this FailureMsg to JSON.
         * @function toJSON
         * @memberof cat.FailureMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FailureMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FailureMsg
         * @function getTypeUrl
         * @memberof cat.FailureMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FailureMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.FailureMsg";
        };

        return FailureMsg;
    })();

    cat.BackUpMsg = (function() {

        /**
         * Properties of a BackUpMsg.
         * @memberof cat
         * @interface IBackUpMsg
         * @property {string|null} [id] BackUpMsg id
         * @property {string|null} [appname] BackUpMsg appname
         * @property {string|null} [appversionname] BackUpMsg appversionname
         * @property {string|null} [appversioncode] BackUpMsg appversioncode
         * @property {number|null} [created] BackUpMsg created
         * @property {number|null} [backuptimestamp] BackUpMsg backuptimestamp
         * @property {cat.IMediaFileMsg|null} [zipfile] BackUpMsg zipfile
         */

        /**
         * Constructs a new BackUpMsg.
         * @memberof cat
         * @classdesc Represents a BackUpMsg.
         * @implements IBackUpMsg
         * @constructor
         * @param {cat.IBackUpMsg=} [p] Properties to set
         */
        function BackUpMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * BackUpMsg id.
         * @member {string} id
         * @memberof cat.BackUpMsg
         * @instance
         */
        BackUpMsg.prototype.id = "";

        /**
         * BackUpMsg appname.
         * @member {string} appname
         * @memberof cat.BackUpMsg
         * @instance
         */
        BackUpMsg.prototype.appname = "";

        /**
         * BackUpMsg appversionname.
         * @member {string} appversionname
         * @memberof cat.BackUpMsg
         * @instance
         */
        BackUpMsg.prototype.appversionname = "";

        /**
         * BackUpMsg appversioncode.
         * @member {string} appversioncode
         * @memberof cat.BackUpMsg
         * @instance
         */
        BackUpMsg.prototype.appversioncode = "";

        /**
         * BackUpMsg created.
         * @member {number} created
         * @memberof cat.BackUpMsg
         * @instance
         */
        BackUpMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BackUpMsg backuptimestamp.
         * @member {number} backuptimestamp
         * @memberof cat.BackUpMsg
         * @instance
         */
        BackUpMsg.prototype.backuptimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BackUpMsg zipfile.
         * @member {cat.IMediaFileMsg|null|undefined} zipfile
         * @memberof cat.BackUpMsg
         * @instance
         */
        BackUpMsg.prototype.zipfile = null;

        /**
         * Creates a new BackUpMsg instance using the specified properties.
         * @function create
         * @memberof cat.BackUpMsg
         * @static
         * @param {cat.IBackUpMsg=} [properties] Properties to set
         * @returns {cat.BackUpMsg} BackUpMsg instance
         */
        BackUpMsg.create = function create(properties) {
            return new BackUpMsg(properties);
        };

        /**
         * Encodes the specified BackUpMsg message. Does not implicitly {@link cat.BackUpMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.BackUpMsg
         * @static
         * @param {cat.IBackUpMsg} m BackUpMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BackUpMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.appname != null && Object.hasOwnProperty.call(m, "appname"))
                w.uint32(18).string(m.appname);
            if (m.appversionname != null && Object.hasOwnProperty.call(m, "appversionname"))
                w.uint32(26).string(m.appversionname);
            if (m.appversioncode != null && Object.hasOwnProperty.call(m, "appversioncode"))
                w.uint32(34).string(m.appversioncode);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(40).uint64(m.created);
            if (m.backuptimestamp != null && Object.hasOwnProperty.call(m, "backuptimestamp"))
                w.uint32(48).uint64(m.backuptimestamp);
            if (m.zipfile != null && Object.hasOwnProperty.call(m, "zipfile"))
                $root.cat.MediaFileMsg.encode(m.zipfile, w.uint32(810).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a BackUpMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.BackUpMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.BackUpMsg} BackUpMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BackUpMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.BackUpMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.appname = r.string();
                        break;
                    }
                case 3: {
                        m.appversionname = r.string();
                        break;
                    }
                case 4: {
                        m.appversioncode = r.string();
                        break;
                    }
                case 5: {
                        m.created = r.uint64();
                        break;
                    }
                case 6: {
                        m.backuptimestamp = r.uint64();
                        break;
                    }
                case 101: {
                        m.zipfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a BackUpMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.BackUpMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.BackUpMsg} BackUpMsg
         */
        BackUpMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.BackUpMsg)
                return d;
            var m = new $root.cat.BackUpMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.appname != null) {
                m.appname = String(d.appname);
            }
            if (d.appversionname != null) {
                m.appversionname = String(d.appversionname);
            }
            if (d.appversioncode != null) {
                m.appversioncode = String(d.appversioncode);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.backuptimestamp != null) {
                if ($util.Long)
                    (m.backuptimestamp = $util.Long.fromValue(d.backuptimestamp)).unsigned = true;
                else if (typeof d.backuptimestamp === "string")
                    m.backuptimestamp = parseInt(d.backuptimestamp, 10);
                else if (typeof d.backuptimestamp === "number")
                    m.backuptimestamp = d.backuptimestamp;
                else if (typeof d.backuptimestamp === "object")
                    m.backuptimestamp = new $util.LongBits(d.backuptimestamp.low >>> 0, d.backuptimestamp.high >>> 0).toNumber(true);
            }
            if (d.zipfile != null) {
                if (typeof d.zipfile !== "object")
                    throw TypeError(".cat.BackUpMsg.zipfile: object expected");
                m.zipfile = $root.cat.MediaFileMsg.fromObject(d.zipfile);
            }
            return m;
        };

        /**
         * Creates a plain object from a BackUpMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.BackUpMsg
         * @static
         * @param {cat.BackUpMsg} m BackUpMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BackUpMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.appname = "";
                d.appversionname = "";
                d.appversioncode = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.backuptimestamp = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.backuptimestamp = o.longs === String ? "0" : 0;
                d.zipfile = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.appname != null && m.hasOwnProperty("appname")) {
                d.appname = m.appname;
            }
            if (m.appversionname != null && m.hasOwnProperty("appversionname")) {
                d.appversionname = m.appversionname;
            }
            if (m.appversioncode != null && m.hasOwnProperty("appversioncode")) {
                d.appversioncode = m.appversioncode;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.backuptimestamp != null && m.hasOwnProperty("backuptimestamp")) {
                if (typeof m.backuptimestamp === "number")
                    d.backuptimestamp = o.longs === String ? String(m.backuptimestamp) : m.backuptimestamp;
                else
                    d.backuptimestamp = o.longs === String ? $util.Long.prototype.toString.call(m.backuptimestamp) : o.longs === Number ? new $util.LongBits(m.backuptimestamp.low >>> 0, m.backuptimestamp.high >>> 0).toNumber(true) : m.backuptimestamp;
            }
            if (m.zipfile != null && m.hasOwnProperty("zipfile")) {
                d.zipfile = $root.cat.MediaFileMsg.toObject(m.zipfile, o);
            }
            return d;
        };

        /**
         * Converts this BackUpMsg to JSON.
         * @function toJSON
         * @memberof cat.BackUpMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BackUpMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BackUpMsg
         * @function getTypeUrl
         * @memberof cat.BackUpMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BackUpMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.BackUpMsg";
        };

        return BackUpMsg;
    })();

    /**
     * APKType enum.
     * @name cat.APKType
     * @enum {number}
     * @property {number} APK_UNKNOWN=0 APK_UNKNOWN value
     * @property {number} APK_APK=1 APK_APK value
     * @property {number} APK_XAPK=2 APK_XAPK value
     * @property {number} APK_APKM=3 APK_APKM value
     */
    cat.APKType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "APK_UNKNOWN"] = 0;
        values[valuesById[1] = "APK_APK"] = 1;
        values[valuesById[2] = "APK_XAPK"] = 2;
        values[valuesById[3] = "APK_APKM"] = 3;
        return values;
    })();

    /**
     * APKFileType enum.
     * @name cat.APKFileType
     * @enum {number}
     * @property {number} APK_FILE_UNKNOWN=0 APK_FILE_UNKNOWN value
     * @property {number} APK_FILE_ORIGINAL=1 APK_FILE_ORIGINAL value
     * @property {number} APK_FILE_PATCH=2 APK_FILE_PATCH value
     * @property {number} APK_FILE_PATCHLOG=3 APK_FILE_PATCHLOG value
     */
    cat.APKFileType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "APK_FILE_UNKNOWN"] = 0;
        values[valuesById[1] = "APK_FILE_ORIGINAL"] = 1;
        values[valuesById[2] = "APK_FILE_PATCH"] = 2;
        values[valuesById[3] = "APK_FILE_PATCHLOG"] = 3;
        return values;
    })();

    /**
     * APKPatchStatus enum.
     * @name cat.APKPatchStatus
     * @enum {number}
     * @property {number} APK_PATCHSTATUS_UNKNOWN=0 APK_PATCHSTATUS_UNKNOWN value
     * @property {number} APK_PATCHSTATUS_PENDING=1 APK_PATCHSTATUS_PENDING value
     * @property {number} APK_PATCHSTATUS_COMPLETE=2 APK_PATCHSTATUS_COMPLETE value
     * @property {number} APK_PATCHSTATUS_FAILED=3 APK_PATCHSTATUS_FAILED value
     */
    cat.APKPatchStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "APK_PATCHSTATUS_UNKNOWN"] = 0;
        values[valuesById[1] = "APK_PATCHSTATUS_PENDING"] = 1;
        values[valuesById[2] = "APK_PATCHSTATUS_COMPLETE"] = 2;
        values[valuesById[3] = "APK_PATCHSTATUS_FAILED"] = 3;
        return values;
    })();

    cat.APKMsg = (function() {

        /**
         * Properties of a APKMsg.
         * @memberof cat
         * @interface IAPKMsg
         * @property {string|null} [id] APKMsg id
         * @property {string|null} [packagename] APKMsg packagename
         * @property {number|null} [versioncode] APKMsg versioncode
         * @property {string|null} [versionname] APKMsg versionname
         * @property {cat.APKType|null} [type] APKMsg type
         * @property {Array.<cat.DeviceArchitecture>|null} [architecture] APKMsg architecture
         * @property {number|null} [created] APKMsg created
         * @property {number|null} [lastmodified] APKMsg lastmodified
         * @property {cat.APKPatchStatus|null} [patchstatus] APKMsg patchstatus
         * @property {cat.IMediaFileMsg|null} [apkfile] APKMsg apkfile
         * @property {boolean|null} [supported] APKMsg supported
         * @property {boolean|null} [patchable] APKMsg patchable
         * @property {cat.IMediaFileMsg|null} [patchedfile] APKMsg patchedfile
         * @property {cat.IMediaFileMsg|null} [logfile] APKMsg logfile
         * @property {string|null} [deviceid] APKMsg deviceid
         */

        /**
         * Constructs a new APKMsg.
         * @memberof cat
         * @classdesc Represents a APKMsg.
         * @implements IAPKMsg
         * @constructor
         * @param {cat.IAPKMsg=} [p] Properties to set
         */
        function APKMsg(p) {
            this.architecture = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * APKMsg id.
         * @member {string} id
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.id = "";

        /**
         * APKMsg packagename.
         * @member {string} packagename
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.packagename = "";

        /**
         * APKMsg versioncode.
         * @member {number} versioncode
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.versioncode = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * APKMsg versionname.
         * @member {string} versionname
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.versionname = "";

        /**
         * APKMsg type.
         * @member {cat.APKType} type
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.type = 0;

        /**
         * APKMsg architecture.
         * @member {Array.<cat.DeviceArchitecture>} architecture
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.architecture = $util.emptyArray;

        /**
         * APKMsg created.
         * @member {number} created
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * APKMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * APKMsg patchstatus.
         * @member {cat.APKPatchStatus|null|undefined} patchstatus
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.patchstatus = null;

        /**
         * APKMsg apkfile.
         * @member {cat.IMediaFileMsg|null|undefined} apkfile
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.apkfile = null;

        /**
         * APKMsg supported.
         * @member {boolean} supported
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.supported = false;

        /**
         * APKMsg patchable.
         * @member {boolean} patchable
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.patchable = false;

        /**
         * APKMsg patchedfile.
         * @member {cat.IMediaFileMsg|null|undefined} patchedfile
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.patchedfile = null;

        /**
         * APKMsg logfile.
         * @member {cat.IMediaFileMsg|null|undefined} logfile
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.logfile = null;

        /**
         * APKMsg deviceid.
         * @member {string} deviceid
         * @memberof cat.APKMsg
         * @instance
         */
        APKMsg.prototype.deviceid = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(APKMsg.prototype, "_patchstatus", {
            get: $util.oneOfGetter($oneOfFields = ["patchstatus"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new APKMsg instance using the specified properties.
         * @function create
         * @memberof cat.APKMsg
         * @static
         * @param {cat.IAPKMsg=} [properties] Properties to set
         * @returns {cat.APKMsg} APKMsg instance
         */
        APKMsg.create = function create(properties) {
            return new APKMsg(properties);
        };

        /**
         * Encodes the specified APKMsg message. Does not implicitly {@link cat.APKMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.APKMsg
         * @static
         * @param {cat.IAPKMsg} m APKMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APKMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.packagename != null && Object.hasOwnProperty.call(m, "packagename"))
                w.uint32(18).string(m.packagename);
            if (m.versioncode != null && Object.hasOwnProperty.call(m, "versioncode"))
                w.uint32(24).uint64(m.versioncode);
            if (m.versionname != null && Object.hasOwnProperty.call(m, "versionname"))
                w.uint32(34).string(m.versionname);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(40).int32(m.type);
            if (m.architecture != null && m.architecture.length) {
                w.uint32(50).fork();
                for (var i = 0; i < m.architecture.length; ++i)
                    w.int32(m.architecture[i]);
                w.ldelim();
            }
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(56).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(64).uint64(m.lastmodified);
            if (m.patchstatus != null && Object.hasOwnProperty.call(m, "patchstatus"))
                w.uint32(72).int32(m.patchstatus);
            if (m.apkfile != null && Object.hasOwnProperty.call(m, "apkfile"))
                $root.cat.MediaFileMsg.encode(m.apkfile, w.uint32(810).fork()).ldelim();
            if (m.supported != null && Object.hasOwnProperty.call(m, "supported"))
                w.uint32(816).bool(m.supported);
            if (m.patchable != null && Object.hasOwnProperty.call(m, "patchable"))
                w.uint32(824).bool(m.patchable);
            if (m.patchedfile != null && Object.hasOwnProperty.call(m, "patchedfile"))
                $root.cat.MediaFileMsg.encode(m.patchedfile, w.uint32(834).fork()).ldelim();
            if (m.logfile != null && Object.hasOwnProperty.call(m, "logfile"))
                $root.cat.MediaFileMsg.encode(m.logfile, w.uint32(842).fork()).ldelim();
            if (m.deviceid != null && Object.hasOwnProperty.call(m, "deviceid"))
                w.uint32(850).string(m.deviceid);
            return w;
        };

        /**
         * Decodes a APKMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.APKMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.APKMsg} APKMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APKMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.APKMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.packagename = r.string();
                        break;
                    }
                case 3: {
                        m.versioncode = r.uint64();
                        break;
                    }
                case 4: {
                        m.versionname = r.string();
                        break;
                    }
                case 5: {
                        m.type = r.int32();
                        break;
                    }
                case 6: {
                        if (!(m.architecture && m.architecture.length))
                            m.architecture = [];
                        if ((t & 7) === 2) {
                            var c2 = r.uint32() + r.pos;
                            while (r.pos < c2)
                                m.architecture.push(r.int32());
                        } else
                            m.architecture.push(r.int32());
                        break;
                    }
                case 7: {
                        m.created = r.uint64();
                        break;
                    }
                case 8: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 9: {
                        m.patchstatus = r.int32();
                        break;
                    }
                case 101: {
                        m.apkfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 102: {
                        m.supported = r.bool();
                        break;
                    }
                case 103: {
                        m.patchable = r.bool();
                        break;
                    }
                case 104: {
                        m.patchedfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 105: {
                        m.logfile = $root.cat.MediaFileMsg.decode(r, r.uint32());
                        break;
                    }
                case 106: {
                        m.deviceid = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a APKMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.APKMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.APKMsg} APKMsg
         */
        APKMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.APKMsg)
                return d;
            var m = new $root.cat.APKMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.packagename != null) {
                m.packagename = String(d.packagename);
            }
            if (d.versioncode != null) {
                if ($util.Long)
                    (m.versioncode = $util.Long.fromValue(d.versioncode)).unsigned = true;
                else if (typeof d.versioncode === "string")
                    m.versioncode = parseInt(d.versioncode, 10);
                else if (typeof d.versioncode === "number")
                    m.versioncode = d.versioncode;
                else if (typeof d.versioncode === "object")
                    m.versioncode = new $util.LongBits(d.versioncode.low >>> 0, d.versioncode.high >>> 0).toNumber(true);
            }
            if (d.versionname != null) {
                m.versionname = String(d.versionname);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "APK_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "APK_APK":
            case 1:
                m.type = 1;
                break;
            case "APK_XAPK":
            case 2:
                m.type = 2;
                break;
            case "APK_APKM":
            case 3:
                m.type = 3;
                break;
            }
            if (d.architecture) {
                if (!Array.isArray(d.architecture))
                    throw TypeError(".cat.APKMsg.architecture: array expected");
                m.architecture = [];
                for (var i = 0; i < d.architecture.length; ++i) {
                    switch (d.architecture[i]) {
                    default:
                        if (typeof d.architecture[i] === "number") {
                            m.architecture[i] = d.architecture[i];
                            break;
                        }
                    case "DEVICEARCHITECTURE_UNKNOWN":
                    case 0:
                        m.architecture[i] = 0;
                        break;
                    case "DEVICEARCHITECTURE_ARMEABI":
                    case 1:
                        m.architecture[i] = 1;
                        break;
                    case "DEVICEARCHITECTURE_ARMEABI_V7A":
                    case 2:
                        m.architecture[i] = 2;
                        break;
                    case "DEVICEARCHITECTURE_ARM64_V8A":
                    case 3:
                        m.architecture[i] = 3;
                        break;
                    case "DEVICEARCHITECTURE_X86_64":
                    case 4:
                        m.architecture[i] = 4;
                        break;
                    case "DEVICEARCHITECTURE_X86":
                    case 5:
                        m.architecture[i] = 5;
                        break;
                    case "DEVICEARCHITECTURE_MIPS":
                    case 6:
                        m.architecture[i] = 6;
                        break;
                    case "DEVICEARCHITECTURE_MIPS64":
                    case 7:
                        m.architecture[i] = 7;
                        break;
                    case "DEVICEARCHITECTURE_RISCV64":
                    case 8:
                        m.architecture[i] = 8;
                        break;
                    }
                }
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            switch (d.patchstatus) {
            default:
                if (typeof d.patchstatus === "number") {
                    m.patchstatus = d.patchstatus;
                    break;
                }
                break;
            case "APK_PATCHSTATUS_UNKNOWN":
            case 0:
                m.patchstatus = 0;
                break;
            case "APK_PATCHSTATUS_PENDING":
            case 1:
                m.patchstatus = 1;
                break;
            case "APK_PATCHSTATUS_COMPLETE":
            case 2:
                m.patchstatus = 2;
                break;
            case "APK_PATCHSTATUS_FAILED":
            case 3:
                m.patchstatus = 3;
                break;
            }
            if (d.apkfile != null) {
                if (typeof d.apkfile !== "object")
                    throw TypeError(".cat.APKMsg.apkfile: object expected");
                m.apkfile = $root.cat.MediaFileMsg.fromObject(d.apkfile);
            }
            if (d.supported != null) {
                m.supported = Boolean(d.supported);
            }
            if (d.patchable != null) {
                m.patchable = Boolean(d.patchable);
            }
            if (d.patchedfile != null) {
                if (typeof d.patchedfile !== "object")
                    throw TypeError(".cat.APKMsg.patchedfile: object expected");
                m.patchedfile = $root.cat.MediaFileMsg.fromObject(d.patchedfile);
            }
            if (d.logfile != null) {
                if (typeof d.logfile !== "object")
                    throw TypeError(".cat.APKMsg.logfile: object expected");
                m.logfile = $root.cat.MediaFileMsg.fromObject(d.logfile);
            }
            if (d.deviceid != null) {
                m.deviceid = String(d.deviceid);
            }
            return m;
        };

        /**
         * Creates a plain object from a APKMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.APKMsg
         * @static
         * @param {cat.APKMsg} m APKMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APKMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.architecture = [];
            }
            if (o.defaults) {
                d.id = "";
                d.packagename = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.versioncode = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.versioncode = o.longs === String ? "0" : 0;
                d.versionname = "";
                d.type = o.enums === String ? "APK_UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.apkfile = null;
                d.supported = false;
                d.patchable = false;
                d.patchedfile = null;
                d.logfile = null;
                d.deviceid = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.packagename != null && m.hasOwnProperty("packagename")) {
                d.packagename = m.packagename;
            }
            if (m.versioncode != null && m.hasOwnProperty("versioncode")) {
                if (typeof m.versioncode === "number")
                    d.versioncode = o.longs === String ? String(m.versioncode) : m.versioncode;
                else
                    d.versioncode = o.longs === String ? $util.Long.prototype.toString.call(m.versioncode) : o.longs === Number ? new $util.LongBits(m.versioncode.low >>> 0, m.versioncode.high >>> 0).toNumber(true) : m.versioncode;
            }
            if (m.versionname != null && m.hasOwnProperty("versionname")) {
                d.versionname = m.versionname;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.APKType[m.type] === undefined ? m.type : $root.cat.APKType[m.type] : m.type;
            }
            if (m.architecture && m.architecture.length) {
                d.architecture = [];
                for (var j = 0; j < m.architecture.length; ++j) {
                    d.architecture[j] = o.enums === String ? $root.cat.DeviceArchitecture[m.architecture[j]] === undefined ? m.architecture[j] : $root.cat.DeviceArchitecture[m.architecture[j]] : m.architecture[j];
                }
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.patchstatus != null && m.hasOwnProperty("patchstatus")) {
                d.patchstatus = o.enums === String ? $root.cat.APKPatchStatus[m.patchstatus] === undefined ? m.patchstatus : $root.cat.APKPatchStatus[m.patchstatus] : m.patchstatus;
                if (o.oneofs)
                    d._patchstatus = "patchstatus";
            }
            if (m.apkfile != null && m.hasOwnProperty("apkfile")) {
                d.apkfile = $root.cat.MediaFileMsg.toObject(m.apkfile, o);
            }
            if (m.supported != null && m.hasOwnProperty("supported")) {
                d.supported = m.supported;
            }
            if (m.patchable != null && m.hasOwnProperty("patchable")) {
                d.patchable = m.patchable;
            }
            if (m.patchedfile != null && m.hasOwnProperty("patchedfile")) {
                d.patchedfile = $root.cat.MediaFileMsg.toObject(m.patchedfile, o);
            }
            if (m.logfile != null && m.hasOwnProperty("logfile")) {
                d.logfile = $root.cat.MediaFileMsg.toObject(m.logfile, o);
            }
            if (m.deviceid != null && m.hasOwnProperty("deviceid")) {
                d.deviceid = m.deviceid;
            }
            return d;
        };

        /**
         * Converts this APKMsg to JSON.
         * @function toJSON
         * @memberof cat.APKMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APKMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for APKMsg
         * @function getTypeUrl
         * @memberof cat.APKMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        APKMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.APKMsg";
        };

        return APKMsg;
    })();

    /**
     * SystemEventType enum.
     * @name cat.SystemEventType
     * @enum {number}
     * @property {number} SYSTEMEVENT_UNKNOWN=0 SYSTEMEVENT_UNKNOWN value
     * @property {number} SYSTEMEVENT_START=1 SYSTEMEVENT_START value
     * @property {number} SYSTEMEVENT_STOP=2 SYSTEMEVENT_STOP value
     * @property {number} SYSTEMEVENT_UPDATE=3 SYSTEMEVENT_UPDATE value
     * @property {number} SYSTEMEVENT_ERROR=4 SYSTEMEVENT_ERROR value
     * @property {number} SYSTEMEVENT_RESTART=5 SYSTEMEVENT_RESTART value
     * @property {number} SYSTEMEVENT_DATACLEANING=6 SYSTEMEVENT_DATACLEANING value
     */
    cat.SystemEventType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SYSTEMEVENT_UNKNOWN"] = 0;
        values[valuesById[1] = "SYSTEMEVENT_START"] = 1;
        values[valuesById[2] = "SYSTEMEVENT_STOP"] = 2;
        values[valuesById[3] = "SYSTEMEVENT_UPDATE"] = 3;
        values[valuesById[4] = "SYSTEMEVENT_ERROR"] = 4;
        values[valuesById[5] = "SYSTEMEVENT_RESTART"] = 5;
        values[valuesById[6] = "SYSTEMEVENT_DATACLEANING"] = 6;
        return values;
    })();

    cat.SystemEventMsg = (function() {

        /**
         * Properties of a SystemEventMsg.
         * @memberof cat
         * @interface ISystemEventMsg
         * @property {string|null} [id] SystemEventMsg id
         * @property {string|null} [component] SystemEventMsg component
         * @property {number|null} [created] SystemEventMsg created
         * @property {cat.SystemEventType|null} [type] SystemEventMsg type
         * @property {string|null} [info] SystemEventMsg info
         */

        /**
         * Constructs a new SystemEventMsg.
         * @memberof cat
         * @classdesc Represents a SystemEventMsg.
         * @implements ISystemEventMsg
         * @constructor
         * @param {cat.ISystemEventMsg=} [p] Properties to set
         */
        function SystemEventMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SystemEventMsg id.
         * @member {string} id
         * @memberof cat.SystemEventMsg
         * @instance
         */
        SystemEventMsg.prototype.id = "";

        /**
         * SystemEventMsg component.
         * @member {string} component
         * @memberof cat.SystemEventMsg
         * @instance
         */
        SystemEventMsg.prototype.component = "";

        /**
         * SystemEventMsg created.
         * @member {number} created
         * @memberof cat.SystemEventMsg
         * @instance
         */
        SystemEventMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * SystemEventMsg type.
         * @member {cat.SystemEventType} type
         * @memberof cat.SystemEventMsg
         * @instance
         */
        SystemEventMsg.prototype.type = 0;

        /**
         * SystemEventMsg info.
         * @member {string} info
         * @memberof cat.SystemEventMsg
         * @instance
         */
        SystemEventMsg.prototype.info = "";

        /**
         * Creates a new SystemEventMsg instance using the specified properties.
         * @function create
         * @memberof cat.SystemEventMsg
         * @static
         * @param {cat.ISystemEventMsg=} [properties] Properties to set
         * @returns {cat.SystemEventMsg} SystemEventMsg instance
         */
        SystemEventMsg.create = function create(properties) {
            return new SystemEventMsg(properties);
        };

        /**
         * Encodes the specified SystemEventMsg message. Does not implicitly {@link cat.SystemEventMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.SystemEventMsg
         * @static
         * @param {cat.ISystemEventMsg} m SystemEventMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SystemEventMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.component != null && Object.hasOwnProperty.call(m, "component"))
                w.uint32(18).string(m.component);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(24).uint64(m.created);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(32).int32(m.type);
            if (m.info != null && Object.hasOwnProperty.call(m, "info"))
                w.uint32(42).string(m.info);
            return w;
        };

        /**
         * Decodes a SystemEventMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.SystemEventMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.SystemEventMsg} SystemEventMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SystemEventMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.SystemEventMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.component = r.string();
                        break;
                    }
                case 3: {
                        m.created = r.uint64();
                        break;
                    }
                case 4: {
                        m.type = r.int32();
                        break;
                    }
                case 5: {
                        m.info = r.string();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a SystemEventMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.SystemEventMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.SystemEventMsg} SystemEventMsg
         */
        SystemEventMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.SystemEventMsg)
                return d;
            var m = new $root.cat.SystemEventMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.component != null) {
                m.component = String(d.component);
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            switch (d.type) {
            default:
                if (typeof d.type === "number") {
                    m.type = d.type;
                    break;
                }
                break;
            case "SYSTEMEVENT_UNKNOWN":
            case 0:
                m.type = 0;
                break;
            case "SYSTEMEVENT_START":
            case 1:
                m.type = 1;
                break;
            case "SYSTEMEVENT_STOP":
            case 2:
                m.type = 2;
                break;
            case "SYSTEMEVENT_UPDATE":
            case 3:
                m.type = 3;
                break;
            case "SYSTEMEVENT_ERROR":
            case 4:
                m.type = 4;
                break;
            case "SYSTEMEVENT_RESTART":
            case 5:
                m.type = 5;
                break;
            case "SYSTEMEVENT_DATACLEANING":
            case 6:
                m.type = 6;
                break;
            }
            if (d.info != null) {
                m.info = String(d.info);
            }
            return m;
        };

        /**
         * Creates a plain object from a SystemEventMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.SystemEventMsg
         * @static
         * @param {cat.SystemEventMsg} m SystemEventMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SystemEventMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.component = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                d.type = o.enums === String ? "SYSTEMEVENT_UNKNOWN" : 0;
                d.info = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.component != null && m.hasOwnProperty("component")) {
                d.component = m.component;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = o.enums === String ? $root.cat.SystemEventType[m.type] === undefined ? m.type : $root.cat.SystemEventType[m.type] : m.type;
            }
            if (m.info != null && m.hasOwnProperty("info")) {
                d.info = m.info;
            }
            return d;
        };

        /**
         * Converts this SystemEventMsg to JSON.
         * @function toJSON
         * @memberof cat.SystemEventMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SystemEventMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SystemEventMsg
         * @function getTypeUrl
         * @memberof cat.SystemEventMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SystemEventMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.SystemEventMsg";
        };

        return SystemEventMsg;
    })();

    cat.DomainMsg = (function() {

        /**
         * Properties of a DomainMsg.
         * @memberof cat
         * @interface IDomainMsg
         * @property {string|null} [id] DomainMsg id
         * @property {string|null} [name] DomainMsg name
         * @property {string|null} [url] DomainMsg url
         * @property {string|null} [username] DomainMsg username
         * @property {string|null} [password] DomainMsg password
         * @property {cat.StatusType|null} [status] DomainMsg status
         * @property {number|null} [created] DomainMsg created
         * @property {number|null} [lastmodified] DomainMsg lastmodified
         * @property {number|null} [devicecount] DomainMsg devicecount
         */

        /**
         * Constructs a new DomainMsg.
         * @memberof cat
         * @classdesc Represents a DomainMsg.
         * @implements IDomainMsg
         * @constructor
         * @param {cat.IDomainMsg=} [p] Properties to set
         */
        function DomainMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DomainMsg id.
         * @member {string} id
         * @memberof cat.DomainMsg
         * @instance
         */
        DomainMsg.prototype.id = "";

        /**
         * DomainMsg name.
         * @member {string} name
         * @memberof cat.DomainMsg
         * @instance
         */
        DomainMsg.prototype.name = "";

        /**
         * DomainMsg url.
         * @member {string} url
         * @memberof cat.DomainMsg
         * @instance
         */
        DomainMsg.prototype.url = "";

        /**
         * DomainMsg username.
         * @member {string} username
         * @memberof cat.DomainMsg
         * @instance
         */
        DomainMsg.prototype.username = "";

        /**
         * DomainMsg password.
         * @member {string} password
         * @memberof cat.DomainMsg
         * @instance
         */
        DomainMsg.prototype.password = "";

        /**
         * DomainMsg status.
         * @member {cat.StatusType} status
         * @memberof cat.DomainMsg
         * @instance
         */
        DomainMsg.prototype.status = 0;

        /**
         * DomainMsg created.
         * @member {number} created
         * @memberof cat.DomainMsg
         * @instance
         */
        DomainMsg.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DomainMsg lastmodified.
         * @member {number} lastmodified
         * @memberof cat.DomainMsg
         * @instance
         */
        DomainMsg.prototype.lastmodified = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * DomainMsg devicecount.
         * @member {number} devicecount
         * @memberof cat.DomainMsg
         * @instance
         */
        DomainMsg.prototype.devicecount = 0;

        /**
         * Creates a new DomainMsg instance using the specified properties.
         * @function create
         * @memberof cat.DomainMsg
         * @static
         * @param {cat.IDomainMsg=} [properties] Properties to set
         * @returns {cat.DomainMsg} DomainMsg instance
         */
        DomainMsg.create = function create(properties) {
            return new DomainMsg(properties);
        };

        /**
         * Encodes the specified DomainMsg message. Does not implicitly {@link cat.DomainMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.DomainMsg
         * @static
         * @param {cat.IDomainMsg} m DomainMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DomainMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                w.uint32(26).string(m.url);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(34).string(m.username);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(42).string(m.password);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(48).int32(m.status);
            if (m.created != null && Object.hasOwnProperty.call(m, "created"))
                w.uint32(56).uint64(m.created);
            if (m.lastmodified != null && Object.hasOwnProperty.call(m, "lastmodified"))
                w.uint32(64).uint64(m.lastmodified);
            if (m.devicecount != null && Object.hasOwnProperty.call(m, "devicecount"))
                w.uint32(72).uint32(m.devicecount);
            return w;
        };

        /**
         * Decodes a DomainMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.DomainMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.DomainMsg} DomainMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DomainMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.DomainMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.name = r.string();
                        break;
                    }
                case 3: {
                        m.url = r.string();
                        break;
                    }
                case 4: {
                        m.username = r.string();
                        break;
                    }
                case 5: {
                        m.password = r.string();
                        break;
                    }
                case 6: {
                        m.status = r.int32();
                        break;
                    }
                case 7: {
                        m.created = r.uint64();
                        break;
                    }
                case 8: {
                        m.lastmodified = r.uint64();
                        break;
                    }
                case 9: {
                        m.devicecount = r.uint32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DomainMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.DomainMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.DomainMsg} DomainMsg
         */
        DomainMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.DomainMsg)
                return d;
            var m = new $root.cat.DomainMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.url != null) {
                m.url = String(d.url);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            switch (d.status) {
            default:
                if (typeof d.status === "number") {
                    m.status = d.status;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                m.status = 0;
                break;
            case "ENABLED":
            case 1:
                m.status = 1;
                break;
            case "DISABLED":
            case 2:
                m.status = 2;
                break;
            }
            if (d.created != null) {
                if ($util.Long)
                    (m.created = $util.Long.fromValue(d.created)).unsigned = true;
                else if (typeof d.created === "string")
                    m.created = parseInt(d.created, 10);
                else if (typeof d.created === "number")
                    m.created = d.created;
                else if (typeof d.created === "object")
                    m.created = new $util.LongBits(d.created.low >>> 0, d.created.high >>> 0).toNumber(true);
            }
            if (d.lastmodified != null) {
                if ($util.Long)
                    (m.lastmodified = $util.Long.fromValue(d.lastmodified)).unsigned = true;
                else if (typeof d.lastmodified === "string")
                    m.lastmodified = parseInt(d.lastmodified, 10);
                else if (typeof d.lastmodified === "number")
                    m.lastmodified = d.lastmodified;
                else if (typeof d.lastmodified === "object")
                    m.lastmodified = new $util.LongBits(d.lastmodified.low >>> 0, d.lastmodified.high >>> 0).toNumber(true);
            }
            if (d.devicecount != null) {
                m.devicecount = d.devicecount >>> 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a DomainMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.DomainMsg
         * @static
         * @param {cat.DomainMsg} m DomainMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DomainMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.url = "";
                d.username = "";
                d.password = "";
                d.status = o.enums === String ? "UNKNOWN" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.created = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.created = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.lastmodified = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastmodified = o.longs === String ? "0" : 0;
                d.devicecount = 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = m.url;
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = o.enums === String ? $root.cat.StatusType[m.status] === undefined ? m.status : $root.cat.StatusType[m.status] : m.status;
            }
            if (m.created != null && m.hasOwnProperty("created")) {
                if (typeof m.created === "number")
                    d.created = o.longs === String ? String(m.created) : m.created;
                else
                    d.created = o.longs === String ? $util.Long.prototype.toString.call(m.created) : o.longs === Number ? new $util.LongBits(m.created.low >>> 0, m.created.high >>> 0).toNumber(true) : m.created;
            }
            if (m.lastmodified != null && m.hasOwnProperty("lastmodified")) {
                if (typeof m.lastmodified === "number")
                    d.lastmodified = o.longs === String ? String(m.lastmodified) : m.lastmodified;
                else
                    d.lastmodified = o.longs === String ? $util.Long.prototype.toString.call(m.lastmodified) : o.longs === Number ? new $util.LongBits(m.lastmodified.low >>> 0, m.lastmodified.high >>> 0).toNumber(true) : m.lastmodified;
            }
            if (m.devicecount != null && m.hasOwnProperty("devicecount")) {
                d.devicecount = m.devicecount;
            }
            return d;
        };

        /**
         * Converts this DomainMsg to JSON.
         * @function toJSON
         * @memberof cat.DomainMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DomainMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DomainMsg
         * @function getTypeUrl
         * @memberof cat.DomainMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DomainMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.DomainMsg";
        };

        return DomainMsg;
    })();

    cat.PatchClientConfigurationMsg = (function() {

        /**
         * Properties of a PatchClientConfigurationMsg.
         * @memberof cat
         * @interface IPatchClientConfigurationMsg
         * @property {string|null} [id] PatchClientConfigurationMsg id
         * @property {string|null} [url] PatchClientConfigurationMsg url
         * @property {string|null} [username] PatchClientConfigurationMsg username
         * @property {string|null} [password] PatchClientConfigurationMsg password
         * @property {number|null} [interval] PatchClientConfigurationMsg interval
         * @property {boolean|null} [enabled] PatchClientConfigurationMsg enabled
         */

        /**
         * Constructs a new PatchClientConfigurationMsg.
         * @memberof cat
         * @classdesc Represents a PatchClientConfigurationMsg.
         * @implements IPatchClientConfigurationMsg
         * @constructor
         * @param {cat.IPatchClientConfigurationMsg=} [p] Properties to set
         */
        function PatchClientConfigurationMsg(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PatchClientConfigurationMsg id.
         * @member {string} id
         * @memberof cat.PatchClientConfigurationMsg
         * @instance
         */
        PatchClientConfigurationMsg.prototype.id = "";

        /**
         * PatchClientConfigurationMsg url.
         * @member {string} url
         * @memberof cat.PatchClientConfigurationMsg
         * @instance
         */
        PatchClientConfigurationMsg.prototype.url = "";

        /**
         * PatchClientConfigurationMsg username.
         * @member {string} username
         * @memberof cat.PatchClientConfigurationMsg
         * @instance
         */
        PatchClientConfigurationMsg.prototype.username = "";

        /**
         * PatchClientConfigurationMsg password.
         * @member {string} password
         * @memberof cat.PatchClientConfigurationMsg
         * @instance
         */
        PatchClientConfigurationMsg.prototype.password = "";

        /**
         * PatchClientConfigurationMsg interval.
         * @member {number} interval
         * @memberof cat.PatchClientConfigurationMsg
         * @instance
         */
        PatchClientConfigurationMsg.prototype.interval = 0;

        /**
         * PatchClientConfigurationMsg enabled.
         * @member {boolean} enabled
         * @memberof cat.PatchClientConfigurationMsg
         * @instance
         */
        PatchClientConfigurationMsg.prototype.enabled = false;

        /**
         * Creates a new PatchClientConfigurationMsg instance using the specified properties.
         * @function create
         * @memberof cat.PatchClientConfigurationMsg
         * @static
         * @param {cat.IPatchClientConfigurationMsg=} [properties] Properties to set
         * @returns {cat.PatchClientConfigurationMsg} PatchClientConfigurationMsg instance
         */
        PatchClientConfigurationMsg.create = function create(properties) {
            return new PatchClientConfigurationMsg(properties);
        };

        /**
         * Encodes the specified PatchClientConfigurationMsg message. Does not implicitly {@link cat.PatchClientConfigurationMsg.verify|verify} messages.
         * @function encode
         * @memberof cat.PatchClientConfigurationMsg
         * @static
         * @param {cat.IPatchClientConfigurationMsg} m PatchClientConfigurationMsg message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatchClientConfigurationMsg.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                w.uint32(18).string(m.url);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(26).string(m.username);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(34).string(m.password);
            if (m.interval != null && Object.hasOwnProperty.call(m, "interval"))
                w.uint32(40).uint32(m.interval);
            if (m.enabled != null && Object.hasOwnProperty.call(m, "enabled"))
                w.uint32(48).bool(m.enabled);
            return w;
        };

        /**
         * Decodes a PatchClientConfigurationMsg message from the specified reader or buffer.
         * @function decode
         * @memberof cat.PatchClientConfigurationMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {cat.PatchClientConfigurationMsg} PatchClientConfigurationMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatchClientConfigurationMsg.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.cat.PatchClientConfigurationMsg();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.id = r.string();
                        break;
                    }
                case 2: {
                        m.url = r.string();
                        break;
                    }
                case 3: {
                        m.username = r.string();
                        break;
                    }
                case 4: {
                        m.password = r.string();
                        break;
                    }
                case 5: {
                        m.interval = r.uint32();
                        break;
                    }
                case 6: {
                        m.enabled = r.bool();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PatchClientConfigurationMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cat.PatchClientConfigurationMsg
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {cat.PatchClientConfigurationMsg} PatchClientConfigurationMsg
         */
        PatchClientConfigurationMsg.fromObject = function fromObject(d) {
            if (d instanceof $root.cat.PatchClientConfigurationMsg)
                return d;
            var m = new $root.cat.PatchClientConfigurationMsg();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.url != null) {
                m.url = String(d.url);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            if (d.interval != null) {
                m.interval = d.interval >>> 0;
            }
            if (d.enabled != null) {
                m.enabled = Boolean(d.enabled);
            }
            return m;
        };

        /**
         * Creates a plain object from a PatchClientConfigurationMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cat.PatchClientConfigurationMsg
         * @static
         * @param {cat.PatchClientConfigurationMsg} m PatchClientConfigurationMsg
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PatchClientConfigurationMsg.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.url = "";
                d.username = "";
                d.password = "";
                d.interval = 0;
                d.enabled = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = m.url;
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            if (m.interval != null && m.hasOwnProperty("interval")) {
                d.interval = m.interval;
            }
            if (m.enabled != null && m.hasOwnProperty("enabled")) {
                d.enabled = m.enabled;
            }
            return d;
        };

        /**
         * Converts this PatchClientConfigurationMsg to JSON.
         * @function toJSON
         * @memberof cat.PatchClientConfigurationMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PatchClientConfigurationMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PatchClientConfigurationMsg
         * @function getTypeUrl
         * @memberof cat.PatchClientConfigurationMsg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PatchClientConfigurationMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cat.PatchClientConfigurationMsg";
        };

        return PatchClientConfigurationMsg;
    })();

    /**
     * Permission enum.
     * @name cat.Permission
     * @enum {number}
     * @property {number} PERMISSION_ESSENTIAL=0 PERMISSION_ESSENTIAL value
     * @property {number} PERMISSION_MANAGE_USERS=100 PERMISSION_MANAGE_USERS value
     * @property {number} PERMISSION_MANAGE_ROLES=101 PERMISSION_MANAGE_ROLES value
     * @property {number} PERMISSION_MANAGE_LICENSES=102 PERMISSION_MANAGE_LICENSES value
     * @property {number} PERMISSION_MANAGE_TERMINOLOGY=103 PERMISSION_MANAGE_TERMINOLOGY value
     * @property {number} PERMISSION_MANAGE_CASES=104 PERMISSION_MANAGE_CASES value
     * @property {number} PERMISSION_MANAGE_PROFILES=105 PERMISSION_MANAGE_PROFILES value
     * @property {number} PERMISSION_MANAGE_THEMES=106 PERMISSION_MANAGE_THEMES value
     * @property {number} PERMISSION_MANAGE_GLOBAL_LABELS=107 PERMISSION_MANAGE_GLOBAL_LABELS value
     * @property {number} PERMISSION_MANAGE_LOGS=108 PERMISSION_MANAGE_LOGS value
     * @property {number} PERMISSION_MANAGE_DEVICES=112 PERMISSION_MANAGE_DEVICES value
     * @property {number} PERMISSION_MANAGE_REPORT_TEMPLATES=113 PERMISSION_MANAGE_REPORT_TEMPLATES value
     * @property {number} PERMISSION_MANAGE_USER_SUPERVISION=114 PERMISSION_MANAGE_USER_SUPERVISION value
     * @property {number} PERMISSION_MANAGE_SYSTEM_SETTINGS=115 PERMISSION_MANAGE_SYSTEM_SETTINGS value
     * @property {number} PERMISSION_MANAGE_SYSTEM_ENABLE_KEYCLOAK=118 PERMISSION_MANAGE_SYSTEM_ENABLE_KEYCLOAK value
     * @property {number} PERMISSION_MANAGE_SYSTEM_ENABLE_ENCRYPTION=119 PERMISSION_MANAGE_SYSTEM_ENABLE_ENCRYPTION value
     * @property {number} PERMISSION_MANAGE_SYSTEM_ENABLE_APK_PATCH=120 PERMISSION_MANAGE_SYSTEM_ENABLE_APK_PATCH value
     * @property {number} PERMISSION_MANAGE_APKS=130 PERMISSION_MANAGE_APKS value
     * @property {number} PERMISSION_VIEW_SYSTEM_EVENTS=131 PERMISSION_VIEW_SYSTEM_EVENTS value
     * @property {number} PERMISSION_MANAGE_DOMAINS=132 PERMISSION_MANAGE_DOMAINS value
     * @property {number} PERMISSION_MANAGE_ASSIGNED_DEVICES=200 PERMISSION_MANAGE_ASSIGNED_DEVICES value
     * @property {number} PERMISSION_VIEW_ASSIGNED_DEVICES=201 PERMISSION_VIEW_ASSIGNED_DEVICES value
     * @property {number} PERMISSION_VIEW_DEVICES=202 PERMISSION_VIEW_DEVICES value
     * @property {number} PERMISSION_LINK_DEVICE_ACCOUNT_TO_PROFILE=203 PERMISSION_LINK_DEVICE_ACCOUNT_TO_PROFILE value
     * @property {number} PERMISSION_LINK_DEVICE_TO_PROFILE=204 PERMISSION_LINK_DEVICE_TO_PROFILE value
     * @property {number} PERMISSION_DEVICE_AS_PROFILE_APP=205 PERMISSION_DEVICE_AS_PROFILE_APP value
     * @property {number} PERMISSION_DOWNLOAD_CAPTURE_APP=206 PERMISSION_DOWNLOAD_CAPTURE_APP value
     * @property {number} PERMISSION_VIEW_DEVICE_BACKUPS=207 PERMISSION_VIEW_DEVICE_BACKUPS value
     * @property {number} PERMISSION_DOWNLOAD_DEVICE_BACKUPS=208 PERMISSION_DOWNLOAD_DEVICE_BACKUPS value
     * @property {number} PERMISSION_MANAGE_ASSIGNED_PROFILES=300 PERMISSION_MANAGE_ASSIGNED_PROFILES value
     * @property {number} PERMISSION_VIEW_ASSIGNED_PROFILES=301 PERMISSION_VIEW_ASSIGNED_PROFILES value
     * @property {number} PERMISSION_MANAGE_MY_PROFILES=302 PERMISSION_MANAGE_MY_PROFILES value
     * @property {number} PERMISSION_CREATE_PROFILE_REPORTS=303 PERMISSION_CREATE_PROFILE_REPORTS value
     * @property {number} PERMISSION_VIEW_PROFILE_REPORTS=304 PERMISSION_VIEW_PROFILE_REPORTS value
     * @property {number} PERMISSION_DOWNLOAD_PROFILE_REPORTS=305 PERMISSION_DOWNLOAD_PROFILE_REPORTS value
     * @property {number} PERMISSION_VIEW_PROFILE_CONTACTS=307 PERMISSION_VIEW_PROFILE_CONTACTS value
     * @property {number} PERMISSION_MANAGE_PROFILE_LABELS=308 PERMISSION_MANAGE_PROFILE_LABELS value
     * @property {number} PERMISSION_SET_LABELS=309 PERMISSION_SET_LABELS value
     * @property {number} PERMISSION_VIEW_LABELS=310 PERMISSION_VIEW_LABELS value
     * @property {number} PERMISSION_SET_ANNOTATIONS=318 PERMISSION_SET_ANNOTATIONS value
     * @property {number} PERMISSION_VIEW_ANNOTATIONS=319 PERMISSION_VIEW_ANNOTATIONS value
     * @property {number} PERMISSION_REMOVE_DATA=324 PERMISSION_REMOVE_DATA value
     * @property {number} PERMISSION_EXPORT_PROFILES=325 PERMISSION_EXPORT_PROFILES value
     * @property {number} PERMISSION_USER_LINKABLE_TO_DEVICE=401 PERMISSION_USER_LINKABLE_TO_DEVICE value
     * @property {number} PERMISSION_USER_SUPERVISION_LINKABLE_FROM=402 PERMISSION_USER_SUPERVISION_LINKABLE_FROM value
     * @property {number} PERMISSION_USER_SUPERVISION_LINKABLE_TO=403 PERMISSION_USER_SUPERVISION_LINKABLE_TO value
     * @property {number} PERMISSION_SEARCH_ACCOUNTS_SYSTEM_WIDE=500 PERMISSION_SEARCH_ACCOUNTS_SYSTEM_WIDE value
     * @property {number} PERMISSION_SEARCH_THEMES_SYSTEM_WIDE=501 PERMISSION_SEARCH_THEMES_SYSTEM_WIDE value
     * @property {number} PERMISSION_VIEW_ASSIGNED_SUPERVISORS=502 PERMISSION_VIEW_ASSIGNED_SUPERVISORS value
     * @property {number} PERMISSION_VIEW_CURRENT_LICENSE=505 PERMISSION_VIEW_CURRENT_LICENSE value
     * @property {number} PERMISSION_VIEW_USER_SESSIONS=506 PERMISSION_VIEW_USER_SESSIONS value
     * @property {number} PERMISSION_DATA_CLEANING=507 PERMISSION_DATA_CLEANING value
     * @property {number} PERMISSION_MY_CASES=602 PERMISSION_MY_CASES value
     * @property {number} PERMISSION_MANAGE_CASE_LABELS=603 PERMISSION_MANAGE_CASE_LABELS value
     * @property {number} PERMISSION_VIEW_AUDIT_TRAIL=604 PERMISSION_VIEW_AUDIT_TRAIL value
     * @property {number} PERMISSION_VIEW_GLOBAL_STATISTICS=606 PERMISSION_VIEW_GLOBAL_STATISTICS value
     */
    cat.Permission = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PERMISSION_ESSENTIAL"] = 0;
        values[valuesById[100] = "PERMISSION_MANAGE_USERS"] = 100;
        values[valuesById[101] = "PERMISSION_MANAGE_ROLES"] = 101;
        values[valuesById[102] = "PERMISSION_MANAGE_LICENSES"] = 102;
        values[valuesById[103] = "PERMISSION_MANAGE_TERMINOLOGY"] = 103;
        values[valuesById[104] = "PERMISSION_MANAGE_CASES"] = 104;
        values[valuesById[105] = "PERMISSION_MANAGE_PROFILES"] = 105;
        values[valuesById[106] = "PERMISSION_MANAGE_THEMES"] = 106;
        values[valuesById[107] = "PERMISSION_MANAGE_GLOBAL_LABELS"] = 107;
        values[valuesById[108] = "PERMISSION_MANAGE_LOGS"] = 108;
        values[valuesById[112] = "PERMISSION_MANAGE_DEVICES"] = 112;
        values[valuesById[113] = "PERMISSION_MANAGE_REPORT_TEMPLATES"] = 113;
        values[valuesById[114] = "PERMISSION_MANAGE_USER_SUPERVISION"] = 114;
        values[valuesById[115] = "PERMISSION_MANAGE_SYSTEM_SETTINGS"] = 115;
        values[valuesById[118] = "PERMISSION_MANAGE_SYSTEM_ENABLE_KEYCLOAK"] = 118;
        values[valuesById[119] = "PERMISSION_MANAGE_SYSTEM_ENABLE_ENCRYPTION"] = 119;
        values[valuesById[120] = "PERMISSION_MANAGE_SYSTEM_ENABLE_APK_PATCH"] = 120;
        values[valuesById[130] = "PERMISSION_MANAGE_APKS"] = 130;
        values[valuesById[131] = "PERMISSION_VIEW_SYSTEM_EVENTS"] = 131;
        values[valuesById[132] = "PERMISSION_MANAGE_DOMAINS"] = 132;
        values[valuesById[200] = "PERMISSION_MANAGE_ASSIGNED_DEVICES"] = 200;
        values[valuesById[201] = "PERMISSION_VIEW_ASSIGNED_DEVICES"] = 201;
        values[valuesById[202] = "PERMISSION_VIEW_DEVICES"] = 202;
        values[valuesById[203] = "PERMISSION_LINK_DEVICE_ACCOUNT_TO_PROFILE"] = 203;
        values[valuesById[204] = "PERMISSION_LINK_DEVICE_TO_PROFILE"] = 204;
        values[valuesById[205] = "PERMISSION_DEVICE_AS_PROFILE_APP"] = 205;
        values[valuesById[206] = "PERMISSION_DOWNLOAD_CAPTURE_APP"] = 206;
        values[valuesById[207] = "PERMISSION_VIEW_DEVICE_BACKUPS"] = 207;
        values[valuesById[208] = "PERMISSION_DOWNLOAD_DEVICE_BACKUPS"] = 208;
        values[valuesById[300] = "PERMISSION_MANAGE_ASSIGNED_PROFILES"] = 300;
        values[valuesById[301] = "PERMISSION_VIEW_ASSIGNED_PROFILES"] = 301;
        values[valuesById[302] = "PERMISSION_MANAGE_MY_PROFILES"] = 302;
        values[valuesById[303] = "PERMISSION_CREATE_PROFILE_REPORTS"] = 303;
        values[valuesById[304] = "PERMISSION_VIEW_PROFILE_REPORTS"] = 304;
        values[valuesById[305] = "PERMISSION_DOWNLOAD_PROFILE_REPORTS"] = 305;
        values[valuesById[307] = "PERMISSION_VIEW_PROFILE_CONTACTS"] = 307;
        values[valuesById[308] = "PERMISSION_MANAGE_PROFILE_LABELS"] = 308;
        values[valuesById[309] = "PERMISSION_SET_LABELS"] = 309;
        values[valuesById[310] = "PERMISSION_VIEW_LABELS"] = 310;
        values[valuesById[318] = "PERMISSION_SET_ANNOTATIONS"] = 318;
        values[valuesById[319] = "PERMISSION_VIEW_ANNOTATIONS"] = 319;
        values[valuesById[324] = "PERMISSION_REMOVE_DATA"] = 324;
        values[valuesById[325] = "PERMISSION_EXPORT_PROFILES"] = 325;
        values[valuesById[401] = "PERMISSION_USER_LINKABLE_TO_DEVICE"] = 401;
        values[valuesById[402] = "PERMISSION_USER_SUPERVISION_LINKABLE_FROM"] = 402;
        values[valuesById[403] = "PERMISSION_USER_SUPERVISION_LINKABLE_TO"] = 403;
        values[valuesById[500] = "PERMISSION_SEARCH_ACCOUNTS_SYSTEM_WIDE"] = 500;
        values[valuesById[501] = "PERMISSION_SEARCH_THEMES_SYSTEM_WIDE"] = 501;
        values[valuesById[502] = "PERMISSION_VIEW_ASSIGNED_SUPERVISORS"] = 502;
        values[valuesById[505] = "PERMISSION_VIEW_CURRENT_LICENSE"] = 505;
        values[valuesById[506] = "PERMISSION_VIEW_USER_SESSIONS"] = 506;
        values[valuesById[507] = "PERMISSION_DATA_CLEANING"] = 507;
        values[valuesById[602] = "PERMISSION_MY_CASES"] = 602;
        values[valuesById[603] = "PERMISSION_MANAGE_CASE_LABELS"] = 603;
        values[valuesById[604] = "PERMISSION_VIEW_AUDIT_TRAIL"] = 604;
        values[valuesById[606] = "PERMISSION_VIEW_GLOBAL_STATISTICS"] = 606;
        return values;
    })();

    return cat;
})();

export { $root as default };
