import { Pipe, PipeTransform } from "@angular/core";

/**
 * Check if the current message was received on a new day
 *
 * @export
 * @class IsNewDayPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: "isNewDay",
  pure: true
})

export class IsNewDayPipe implements PipeTransform {

  transform(received: number, previousReceived?: number): boolean {
    const curReceived: number = received;
    const prevReceived: number = previousReceived || 0;

    // Construct a comparable previous date
    const prevDate = new Date(prevReceived);
    prevDate.setHours(0, 0, 0, 0); // Rounddate to make an epoch per day

    // Construct a comparable current date
    const curDate = new Date(curReceived);
    curDate.setHours(0, 0, 0, 0); // Rounddate to make an epoch per day

    // Return true if the days are different
    return prevDate.getTime() !== curDate.getTime();
  }
}
