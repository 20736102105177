// Copyright 2018-2024, Volto Labs BV
// All rights reserved.
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PipesModule } from "@pipes/pipes.module";
import { TranslateModule } from "@ngx-translate/core";
import { ComponentsModule } from "@components/components.module";
import { RegisterCaptureModal } from "./register-capture/register-capture.modal";
import { MatButtonModule } from '@angular/material/button';
import { DevicePropertiesModal } from "./device-properties/device-properties.modal";
import { FormDialog } from "./form-dialog/form-dialog.modal";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatListModule } from "@angular/material/list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { DeviceStats } from "./device-stats/device-stats.modal";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { DirectivesModule } from "@directives/directives.module";
import { MatTableModule } from "@angular/material/table";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialogModule } from "@angular/material/dialog";
import { ChangePasswordDialog } from "./change-password/change-password.modal";
import { ReactionDialog } from "./reaction-dialog/reaction-dialog.modal";
import { WizardTextareaDialog } from "./wizard-textarea/wizard-textarea.modal";
import { DocumentationDialog } from "./documentation-dialog/documentation-dialog.modal"
import { ReportPreviewModal } from "./report-preview/report-preview.modal";
import { AdminResetPasswordDialog } from "./admin-reset-password/admin-reset-password.modal";
import { ConversationDetailsDialog } from "./detail-pages/conversation-details/conversation-details.dialog";
import { MessageDetailsDialog } from "./detail-pages/message-details/message-details.dialog";
import { MediaDetailsDialog } from "./detail-pages/media-details/media-details.dialog";
import { ContactDetailsDialog } from "./detail-pages/contact-details/contact-details.dialog";
import { AccountDetailsDialog } from "./detail-pages/account-details/account-details.dialog";
import { ServerDetailsDialog } from "./detail-pages/server-details/server-details.dialog";
import { TopicDetailsDialog } from "./detail-pages/topic-details/topic-details.dialog";
import { LinkDeviceDomainsModal } from "./link-device-domain/link-device-domain.modal";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        TranslateModule,
        ReactiveFormsModule,
        ComponentsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatGridListModule,
        MatListModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatIconModule,
        DirectivesModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDialogModule
    ],
    declarations: [
        RegisterCaptureModal,
        DeviceStats,
        DevicePropertiesModal,
        FormDialog,
        ChangePasswordDialog,
        ReactionDialog,
        WizardTextareaDialog,
        ReportPreviewModal,
        DocumentationDialog,
        AdminResetPasswordDialog,
        ConversationDetailsDialog,
        MessageDetailsDialog,
        MediaDetailsDialog,
        ContactDetailsDialog,
        AccountDetailsDialog,
        ServerDetailsDialog,
        TopicDetailsDialog,
        LinkDeviceDomainsModal
    ],
    exports: [
        RegisterCaptureModal,
        DeviceStats,
        DevicePropertiesModal,
        FormDialog,
        ChangePasswordDialog,
        ReactionDialog,
        WizardTextareaDialog,
        ReportPreviewModal,
        DocumentationDialog,
        AdminResetPasswordDialog,
        ConversationDetailsDialog,
        MessageDetailsDialog,
        MediaDetailsDialog,
        ContactDetailsDialog,
        AccountDetailsDialog,
        ServerDetailsDialog,
        TopicDetailsDialog,
        LinkDeviceDomainsModal
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ModalModule { }
