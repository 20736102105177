import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";

/**
 * Highlight search words
 */
@Pipe({
    name: "highlight",
    pure: true
})
export class HighlightPipe implements PipeTransform {

    constructor(private _logger: LoggerService) {}

    transform(value: string, searchterm: string): any {
        if (searchterm) {
            const lowValue = value.toLowerCase();
            const re = new RegExp(searchterm?.toLowerCase(), "g");
            const match = lowValue.match(re);
            if (match) {
                return value.replace(re, `<span class='cat-link'>${searchterm}</span>`);
            } else {
                return value;
            }
        } else {
            this._logger.warn("[HighlightPipe] No search term provided");
        }
    }
}
