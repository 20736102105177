import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";

/**
 * Get the name of a forwarded conversation
 *
 * @export
 * @class GetForwardConversationName
 * @implements {PipeTransform}
 */
@Pipe({
  name: "getForwardConversationName",
  pure: true
})


export class GetForwardConversationName implements PipeTransform {
    transform(msg: cat.MessageMsg): string {
        let result = "";
        if (msg && msg.forward && msg.forward.conversations && msg.forward.conversations.length) {
            result = msg.forward.conversations[0].name;
        }
        return result;
    }
}
