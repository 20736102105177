import { Pipe, PipeTransform } from "@angular/core";
import { protosui } from "@definitions/definitions";
import { TranslateService } from "@ngx-translate/core";
import { LoggerService } from "@services/logger/logger.service";

/**
 * Human readable mimetypes.
 */
@Pipe({
  name: "humanizeMimetype",
  pure: true
})
export class HumanizeMimetypePipe implements PipeTransform {

    private _uitext = protosui.messages.uitext;

    constructor(private _logger: LoggerService, private _translate: TranslateService) {}

    transform(mimetype: string): string {

        let image = ` ${this._translate.instant(protosui.messages.uitext.image)}`;
        let document = ` ${this._translate.instant(protosui.messages.uitext.document)}`;
        let audio = ` ${this._translate.instant(protosui.messages.uitext.audio)}`;
        let video = ` ${this._translate.instant(protosui.messages.uitext.video)}`;
        let file = ` ${this._translate.instant(protosui.messages.uitext.file)}`;

        const mimeTypesMap: { [key: string]: string } = {
            "image/jpeg": "JPEG" + image,
            "image/png": "PNG" + image,
            "image/gif": "GIF" + image,
            "image/webp": "WebP" + image,
            "image/svg+xml": "SVG" + image,
            "application/pdf": "PDF" + document,
            "application/json": "JSON" + file,
            "text/html": "HTML" + image,
            "text/plain": "Plain Text",
            "audio/mpeg": "MP3" + audio,
            "audio/ogg": "OGG" + audio,
            "video/mp4": "MP4" + video,
            "video/ogg": "OGG" + video,
            "application/zip": "ZIP Archive"
        };

        const parentTypes: { [key: string]: string } = {
            "image": "Image" + file,
            "audio": "Audio" + file,
            "video": "Video" + file,
            "application": "Application" + file,
            "text": "Text" + document
        };

        // Match from specific map.
        if (mimeTypesMap[mimetype]) {
            return mimeTypesMap[mimetype];
        }

        // Try to match parent otherwise.
        const parentType = mimetype.split("/")[0];
        if (parentTypes[parentType]) {
            return parentTypes[parentType];
        }

        // Return 'other'.
        return this._uitext.other;
    }
}

