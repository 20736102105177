import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";

@Pipe({
  name: "deviceArchitectures",
  pure: true
})
/**
* Get the contact handle, depending on the provided information
* @param {cat.AccountMsg} sender The account of the sender.
* @returns {string} The best available contact handle
*/
export class DeviceArchitecturesPipe implements PipeTransform {
    transform(architectures: cat.DeviceArchitecture[]): string {
        return DeviceArchitectures(architectures);
    }
}

export const DeviceArchitectures = (architectures: cat.DeviceArchitecture[]) => {
    const result: string[] = [];
    for (const arch of architectures) {
        result.push(protosui.def.DeviceArchitecture[cat.DeviceArchitecture[arch]].label);
    }
    return result.join(",");
}