<h4 mat-dialog-title class="cat-no-margin" color="dark">
    <b>{{ "Register your CAT Capture device" | translate }}</b>
</h4>

<div mat-dialog-content>

    <div *ngIf="device?.domain?.url else noDomain" class="cat-margin-bottom-md">
        <img [src]="{ 'fileid': device.id, 'thumbfileid': '' } | catsrc : 'media' : mediaType.MEDIA_QR : 'device'">
    </div>

    <ng-template #noDomain>
        <div class="cat-margin-bottom-lg cat-padding-md">
            <p color="danger">
                {{ uitext.nodomain | translate }}
            </p>
        </div>
    </ng-template>

    <div class="cat-padding-left-md">

        <ng-container *ngIf="device?.domain?.url">
            <h6 class="cat-no-margin cat-padding-bottom-md"><b>{{ "Scan QR" | translate }}</b></h6>

            <span *ngIf="device.name" class="cat-block cat-margin-bottom-sm">
                <b>{{ "1" }}</b>: {{ "Open CAT Capture on your device" | translate }}
            </span>
            <span *ngIf="device.name" class="cat-block cat-margin-bottom-sm">
                <b>{{ "2" }}</b>: {{ "Go to Settings and click on 'Scan QR code' to link" | translate }}
            </span>
        </ng-container>

        <div class="cat-margin-top-xl">

            <h6 class="cat-no-margin cat-padding-bottom-md"><b>{{ "Manually" | translate }}</b></h6>

            <button mat-flat-button color="primary" (click)="showManual = !showManual" class="cat-margin-bottom-lg">
                <mat-icon matSuffix color="light">
                    {{ showManual ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <ng-container *ngIf="!showManual else showClose">
                    {{ uitext.show }}
                </ng-container>
                <ng-template #showClose>
                    {{ uitext.hide }}
                </ng-template>
            </button>

            <ng-container *ngIf="showManual">
                <span *ngIf="device.name" class="cat-block cat-margin-bottom-sm">
                    <b>{{ "Device name" | translate }}</b>: {{ device.name }}
                </span>
                <span class="cat-block cat-margin-bottom-sm">
                    <b>{{ "Device ID" | translate }}</b>: {{ device.physicalid }}
                </span>
                <span class="cat-block cat-margin-bottom-sm">
                    <b>{{ "URL" | translate }}</b>: {{ device.domain?.url | emptyValue }}
                </span>
                <span *ngIf="device.domain?.username" class="cat-block cat-margin-bottom-sm">
                    <b>{{ uitext.username | translate }}</b>: {{ device.domain.username }}
                </span>
                <span *ngIf="device.domain?.password" class="cat-block cat-margin-bottom-sm">
                    <b>{{ "Password" | translate }}</b>: {{ device.domain.password }}
                </span>
            </ng-container>


        </div>

    </div>
</div>

<div mat-dialog-actions align="end" class="cat-padding-bottom-lg cat-padding-x-lg">
    <button mat-stroked-button mat-dialog-close cdkFocusInitial class="cat-padding-lg" (click)="closeDialog()">
        {{ uitext.close | translate }}
    </button>
</div>
