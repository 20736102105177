import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";
import { Store } from "@ngxs/store";
import { IState, PermissionType } from "@shared/app-models";
import { cat } from "src/assets/proto/msgs";
@Pipe({
  name: "hasPermission",
  pure: true
})
/**
* Get the contact external id, depending on the provided information
* @param {cat.AccountMsg} sender The account of the sender.
* @returns {string} The extern id
*/
export class HasPermissionPipe implements PipeTransform {

    constructor(
        private _logger: LoggerService,
        private _store: Store
    ) {}

    transform(permissionIds: Array<number | cat.PermissionMsg> | number, permissionType?: PermissionType, itemId?: string): boolean {

        if (typeof permissionIds === "number") {
            permissionIds = [permissionIds]
        }

        if (permissionIds?.length && Array.isArray(permissionIds)) {
            let permissions: Map<string, cat.PermissionMsg>;
            switch (permissionType) {
                case PermissionType.ManagePermissions: {
                    // Check if the permission is a part of the role's permissions
                    return permissionIds.some((perm: cat.PermissionMsg) => perm.id === itemId);
                }
                case PermissionType.User:
                default: {
                    // Fetch all permission ids, e.g.: MANAGE_CASES from userGetCurrentUserPermissions in the store
                    permissions = this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserPermissions).list;
                    break;
                }
            }
            return permissionIds.map((id: number) => permissions.has(cat.Permission[id])).some(result => result);
        } else {
            return false;
        }
    }
}
