import { Pipe, PipeTransform } from "@angular/core";

/**
 * Check if two things are the same.
 */
@Pipe({
    name: "isEqual",
    pure: true
})
export class IsEqualPipe implements PipeTransform {

    transform(item1: any, item2: any): boolean {

        if (item1 === null || item1 === undefined || item2 === null || item2 === undefined) {
            return item1 === item2;
        }

        if (typeof item1 !== typeof item2) {
            return false;
        }

        if (typeof item1 !== "object") {
            return item1 === item2;
        }

        if (Array.isArray(item1)) {
            if (!Array.isArray(item2) || item1.length !== item2.length) {
                return false;
            }
            for (let i = 0; i < item1.length; i++) {
                if (!this.transform(item1[i], item2[i])) {
                    return false;
                }
            }
            return true;
        }

        const keys1 = Object.keys(item1);
        const keys2 = Object.keys(item2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            if (!this.transform(item1[key], item2[key])) {
                return false;
            }
        }

        return true;
    }
}

