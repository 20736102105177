<div class="cat-flex-container cat-flex-column">

    <modal-header
        (emitAction)="headerAction($event)"
        [isLoading]="(exportLoading$|async)"
        [conversationId]="conversationId"
        [accountId]="account?.id"
        [keepOverlays]="true"
        [buttons]="buttons"
        [title]="uitext.conversationdetails"
        class="cat-margin-bottom-lg">
    </modal-header>

    <div class="cat-flex-grow cat-nooverflow-ellipsis">

        @if (conversation$|async; as conversation) {
            <div class="cat-flex-container cat-flex-column">

                <div class="cat-flex-grow cat-nooverflow-ellipsis">

                    <mat-tab-group
                        class="cat-full-height cat-overflow-y"
                        preserveContent
                        #tabGroup
                        animationDuration="0"
                        [selectedIndex]="selectedTabIndex"
                        (selectedTabChange)="onTabChanged($event)">

                        <mat-tab label="{{ uitext.info | translate }}" [aria-label]="uitext.info">
                            <ng-template matTabContent>

                                <div class="cat-padding-xl cat-margin-bottom-xl">

                                    <div class="grid-container">

                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.id | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ conversation?.id | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.externid | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ conversation?.externid | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.type | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ (messageDefinitions.ConversationType | getEnumLabel : conversation?.type) | emptyValue | translate }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.state | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ (messageDefinitions.ConversationState | getEnumLabel : conversation?.state) | emptyValue | translate }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.knownmembercount | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ conversation?.knownmembercount | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.totalmessagescount | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ conversation?.totalmessagescount | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text cat-full-height">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.description | translate }}
                                                </div>
                                                <div matListItemLine class="cat-wrap cat-break-word">
                                                    {{ conversation?.description | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.name | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ conversation?.name | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.altnames | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ conversation?.altnames | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.link | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ conversation?.link | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.created | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ conversation?.created | date: 'd MMMM y, HH:mm' : undefined : tl.locale }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.lastmodified | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ conversation?.lastmodified | date: 'd MMMM y, HH:mm' : undefined : tl.locale }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                    </div>

                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="{{ uitext.gallery | translate | capitalizeFirstChar }}" [aria-label]="uitext.gallery" class="cat-full-height">
                            <ng-template matTabContent>
                                <mat-tab-body class="cat-full-height cat-nooverflow-ellipsis">
                                    <app-conversation-media
                                        class="cat-full-height cat-flex-column"
                                        [account]="account"
                                        [conversation]="conversation">
                                    </app-conversation-media>
                                </mat-tab-body>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="{{ uitext.members | translate | capitalizeFirstChar }}" [aria-label]="uitext.members" class="cat-full-height">
                            <ng-template matTabContent>
                                <mat-tab-body class="cat-full-height cat-nooverflow-ellipsis">
                                    <app-conversation-members
                                        class="cat-full-height cat-flex-column"
                                        [profile]="profile"
                                        [conversation]="conversation"
                                        [account]="account"
                                        [apptype]="apptype">
                                    </app-conversation-members>
                                </mat-tab-body>
                            </ng-template>
                        </mat-tab>

                        @if (([permissionEnum.PERMISSION_VIEW_ANNOTATIONS, permissionEnum.PERMISSION_SET_ANNOTATIONS] | hasPermission)) {
                            <mat-tab [aria-label]="uitext.annotations" label="{{ uitext.annotations | translate }}">
                                <ng-template matTabContent>
                                    <div class="cat-padding-xl">
                                        <app-annotations
                                            *ngIf="([permissionEnum.PERMISSION_VIEW_ANNOTATIONS, permissionEnum.PERMISSION_SET_ANNOTATIONS] | hasPermission)"
                                            [_accountId]="account.id"
                                            [_refId]="conversation.id"
                                            [_get]="getAnnotations"
                                            [_remove]="removeAnnotations"
                                            [_add]="addAnnotations">
                                        </app-annotations>
                                    </div>
                                </ng-template>
                            </mat-tab>
                        }

                        @if ([permissionEnum.PERMISSION_VIEW_LABELS, permissionEnum.PERMISSION_SET_LABELS] | hasPermission) {
                            <mat-tab [aria-label]="uitext.labels" label="{{ uitext.labels | translate }}">
                                <ng-template matTabContent>
                                    <div class="cat-padding-xl">
                                        <labels
                                            *ngIf="([permissionEnum.PERMISSION_VIEW_LABELS, permissionEnum.PERMISSION_SET_LABELS] | hasPermission)"
                                            [_refId]="conversation.id"
                                            [asProfile]="true"
                                            [routeId]="profile.id"
                                            [_profile]="profile"
                                            [_get]="getLabels"
                                            [_add]="setLabel"
                                            [_remove]="unsetLabel">
                                        </labels>
                                    </div>
                                </ng-template>
                            </mat-tab>
                        }

                        <mat-tab [aria-label]="uitext.history" label="{{ uitext.history | translate }}" class="cat-full-height cat-nooverflow-ellipsis">
                            <ng-template matTabContent class="cat-nooverflow-ellipsis">
                                <mat-tab-body class="cat-nooverflow-ellipsis cat-background-white">

                                    <history-timeline
                                        [conversation]="conversation"
                                        [profile]="profile"
                                        [account]="account"
                                        [mode]="'chat'"
                                        [refresh]="refreshTimeline">
                                    </history-timeline>

                                </mat-tab-body>
                            </ng-template>
                        </mat-tab>

                    </mat-tab-group>
                </div>

            </div>
        }
    </div>

</div>
