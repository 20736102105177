import { Pipe, PipeTransform } from "@angular/core";

/**
 * Check if a object exist or if not a single value is set
 *
 * @export
 * @class isObjectEmpty
 * @implements {PipeTransform}
 */
@Pipe({
    name: "isObjectEmpty",
    pure: true
})

export class IsObjectEmptyPipe implements PipeTransform {
    transform(obj: { [k: string]: any }): boolean {
        let result = true;
        if (obj && Object.values(obj)?.length) {
            for (const value of Object.values(obj)) {
                if (value?.length) {
                    result = false;
                    break;
                }
            }
        }
        return result;
    }
}
