import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, Input, Output, ViewChild, ElementRef, EventEmitter, inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { MessageMode, MessageAction, IReactionsData, IState, IJumpToDateData, IDialogButton } from "@shared/app-models";
import { TranslateService } from "@ngx-translate/core";
import { HasPermissionPipe } from "@pipes/haspermission/haspermission.pipe";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";
import { Observable } from "rxjs";
import { JumpToDatePopoverComponent } from "@components/dialog-jump-to-date/popover-jump-to-date.component";
import { ReactionDialog } from "@modals/reaction-dialog/reaction-dialog.modal";
import { MatDialog } from "@angular/material/dialog";
import { ReportWizardService } from "@services/report-wizard/report-wizard.service";

@Component({
    selector: "cat-msg",
    templateUrl: "./msg.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MsgComponent {

    // Inputs
    @Input() set setmsg(msg: cat.MessageMsg) {
        if (msg) {
           this.msg = msg;
        }
    }
    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    @Input() selectedMsg: cat.MessageMsg = cat.MessageMsg.create();
    @Input() chatId: string = "";
    @Input() endTimestamp = 0;
    @Input() hideComments = false;
    @Input() isFirst = false;
    @Input() isGroup = false;
    @Input() isHighlighted = false;
    @Input() isLast = false;
    @Input() isNewDay = false;
    @Input() isReadOnly = false;
    @Input() isSelected = false;
    @Input() mode: MessageMode;
    @Input() showJumpDate = true;
    @Input() showMenu = true;
    @Input() showQuickSelect = true;
    @Input() startTimestamp = 0;

    // Outputs
    @Output() emitOpenMessageInfo: any = new EventEmitter();
    @Output() emitSelectMessage: any = new EventEmitter();
    @Output() emitOpenContact: any = new EventEmitter();
    @Output() emitOpenComments: EventEmitter<{ msg: cat.MessageMsg }> = new EventEmitter<{ msg: cat.MessageMsg }>();
    @Output() emitJumpToDate: EventEmitter<{ unixTime: number, msg: cat.MessageMsg }> = new EventEmitter<{ unixTime: number, msg: cat.MessageMsg }>();
    @Output() emitJumpToMsg: EventEmitter<{ msg: cat.MessageMsg }> = new EventEmitter<{ msg: cat.MessageMsg }>();
    @Output() emitGetMedia: any = new EventEmitter();

    // Elements
    @ViewChild("audio", {static: false}) audio: ElementRef;

    // Observables
    excludedMessages$: Observable<string[]> = inject(Store).select((state: IState) => state.cat.ExcludeReportMessages);

    public hasExternid = (value: string, emoji: cat.AttachmentMsg) => value.includes(emoji.externid);
    public getEmojiname = (value: string) => {
        // Example string <:kaas:123>, 123
        const externid = value.substring(
            value.lastIndexOf(":") + 1,
            value.lastIndexOf(">")
        );
        return value
            .replace("<", "")
            .replace(">", "")
            .replace(externid, "");
    }

    // Properties
    public msg: cat.MessageMsg = cat.MessageMsg.create();
    public msgType = cat.MessageType;
    public msgMode = MessageMode;
    public messageAction = MessageAction;
    public mediaType = cat.MediaType;
    public conversationType = cat.ConversationType;
    public attachmentType = cat.AttachmentType;
    public topicState = cat.TopicState;
    public permissionEnum = cat.Permission;
    public contentTypeEnum = cat.ContentType;
    public uitext = protosui.messages.uitext;
    public tooltipEvent: "hover";
    public showArrow = true;
    public duration = 300;
    public expandHistory = new Map<string, boolean>();

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _translate: TranslateService,
        private _reportService: ReportWizardService,
        private _dialogRef: MatDialog,
        private _store: Store,
        private _logger: LoggerService
    ) {}

    get reportStatus$() {
        return this._reportService.report$;
    }

    /**
    * Emit the getting of the media
    * @param {cat.AttachmentMsg} attachment Attachment with filesize and fileid.
    * @param {cat.MessageMsg} message Message details of the attachment.
    */
    async userGetMedia(attachment: cat.AttachmentMsg, message: cat.MessageMsg) {
        try {
            // Reset audio from popup image detail
            if (this.audio) {
                this.audio.nativeElement.pause();
                this.audio.nativeElement.currentTime = 0;
            }

            this._logger.debug(`Emit get media of attachment: ${attachment.id} and message: ${message.id}`);
            this.emitGetMedia.emit({ attachment, message });
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Emit open contact info to a parent page
     *
     * @param {cat.AccountMsg} contact The contact to open
     * @memberof MsgComponent
     */
    async openContact(contact: cat.AccountMsg) {
        if (new HasPermissionPipe(this._logger, this._store).transform(cat.Permission.PERMISSION_VIEW_PROFILE_CONTACTS)) {
            this._logger.debug(`Emit open contact: ${contact.id}`, contact.externid);
            if (contact.externid !== this.account.externid) {
                this.emitOpenContact.emit({ contact });
            } else {
                await this.common.createSnackbar(protosui.messages.uitext.ownaccount);
            }
        } else {
            await this.common.createSnackbar(protosui.messages.uitext.nopermission);
        }
    }

    /**
     * Emit open message info to a parent page
     *
     * @param {cat.MessageMsg} message The message to open
     * @param {MessageAction} action The action to pre-select
     * @memberof MsgComponent
     */
    openMessageInfo(message: cat.MessageMsg, action: MessageAction) {
        this._logger.debug(`Emit open message info of message: ${message.id} with action: ${MessageAction[action]}`);
        this.emitOpenMessageInfo.emit({ message, action });
    }

    /**
     * Emit exclude specific message id.
     *
     * @param {cat.MessageMsg} message
     * @memberof MsgComponent
     */
    public selectMessage(message: cat.MessageMsg) {
        if (this.mode === MessageMode.Report && message.id) {
            this.emitSelectMessage.emit(message.id);
        }
    }

    openComments() {
        try {
            this._logger.debug("Open the message comments", this.msg);
            this.emitOpenComments.emit({ msg: this.msg });
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
    * Show the original service message content
    * @param {cat.MessageMsg} msg The selected message
    */
    public async showOriginalServiceMessage(msg: cat.MessageMsg) {
        // Create dialog with warning before deletion.
        const warningDialog = this.common.createDialogReference({
            title: this._translate.instant(protosui.messages.uitext.originalservicemessage),
            content: msg.body,
            buttons: []
        });
        warningDialog.afterClosed().subscribe(async (result: IDialogButton["action"]) => {
            this._logger.debug("Dialog closed", result);
        });
    }

    async openJump(event: MouseEvent) {
        try {

            if (!this.showJumpDate) {
                return;
            }

            const popupData: IJumpToDateData = {
                startTimestamp: this.startTimestamp,
                endTimestamp: this.endTimestamp
            };

            const dialogRef = this._dialogRef.open(JumpToDatePopoverComponent, {
                width: "400px",
                height: "400px",
                data: popupData
            });
            dialogRef.afterClosed().subscribe(result => {
                this._logger.debug(`Dialog result: ${result}`);
                if (result) {
                    this.emitJumpToDate.emit({ unixTime: result, msg: this.msg });
                }
            });

        } catch (error) {
            this._logger.error(error);
        }
    }

    async jumpToMsg(msg: cat.MessageMsg) {
        try {
            if (this.mode === this.msgMode.MsgLink) {
                await this.common.createSnackbar(protosui.messages.uitext.scrollingnotavailable);
            } else {
                if (msg.id && (msg.body || msg.attachments.length || msg.type !== cat.MessageType.MESSAGE_MESSAGE)) {
                    this.emitJumpToMsg.emit({ msg: msg });
                } else {
                    await this.common.createSnackbar(protosui.messages.uitext.messagenotloaded)
                }
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Show reaction detail popover
     *
     * @param {MouseEvent} event Location of the click
     * @param {cat.ReactionMsg} reaction The reaction of the message
     * @returns
     * @memberof MsgComponent
     */
    public async openReaction(event: MouseEvent, reaction: cat.ReactionMsg) {

        try {
            const data: IReactionsData = {
                account: this.account,
                reaction: reaction,
                message: this.msg
            };
            const dialogRef = this._dialogRef.open(ReactionDialog, {
                data: data
            });
            dialogRef.afterClosed().subscribe((result: any) => {
                this._logger.debug(`Dialog result: `, result);
            });
        } catch (error) {
            this._logger.error(error);
        }
    }
}
