import { Store } from "@ngxs/store";
import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "src/assets/proto/msgs";
import { protosui } from "src/definitions/definitions";
import { IDefData, IState } from "@shared/app-models";

/**
 * Replace terminology, preferrably after the translate pipe
 * If you added a term alias as "TREE" you want the user to see "TREE"
 * and not a translated version of that specified word (in the correct language)
 */
@Pipe({
    name: "replaceTerm",
    pure: true
})
export class ReplaceTermPipe implements PipeTransform {

    // Determine if the first character of a string is a capital.
    static startsWithCaptital(testString: string): boolean {
        let result = false;
        // Determine the case of the second character, the first character is a bracket {
        if (testString.charAt(1) === testString.charAt(1).toUpperCase()) {
            result = true
        }
        return result;
    }

    // Determine if the string starts with adjoining capitals, return amount of start capitals.
    static startCapitals(str: string): number {
        let result = 0;
        for (let i = 0; i < str.length; ++i) {
            // Return if lowercase character is found
            if (str.charAt(i) !== str.charAt(i).toUpperCase()) {
                result = i - 1;
                break;
            }
            result = i;
        }
        return result;
    }

    constructor(
        private store: Store
    ) {}

    transform(value: string): string {

        let newValue: string = value;

        // Prerequisites
        const language = this.store.selectSnapshot((state: IState) => state.cat.Me)?.msg.language;
        const terminology: cat.ITerminologyMsg[] = this.store.selectSnapshot((state: IState) => state.cat.Me)?.msg.terminology;

        // Collect all defined singular/plural values in one array and the put them in a
        // case insensitive expression with singular/plural strings to test if we know the terms
        const singularMatch = new RegExp(Object.values(protosui.def.Terminology).filter(def => def.singular?.length).map(def => def.singular).join( "|" ), "i");
        const pluralMatch = new RegExp(Object.values(protosui.def.Terminology).filter(def => def.plural?.length).map(def => def.plural).join( "|" ), "i");

        // A regex to find all words encapsulated by single brackets
        const regex = /\{(.*?)\}/gi;
        let regexResult: RegExpExecArray;

        // Loop over all strings encapsulated with brackets (matches)
        while ((regexResult = regex.exec(value)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (regexResult.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            regexResult.forEach((match: string) => {

                // Only change a word if it's known to us as a singular/plural 'term'
                if (singularMatch.test(match)) {

                    // Get the definition based on the singular term, e.g. {profile}
                    const defKey = Object.keys(protosui.def.Terminology).find(key => protosui.def.Terminology[key]?.singular?.toUpperCase() === match.toUpperCase());
                    const definition: IDefData = protosui.def.Terminology[defKey];

                    if (definition) {

                        const userTerm = terminology.find((termMessage) => termMessage.id === cat.Terminology[defKey]);

                        if (userTerm?.singular) {
                            const replaceValue = userTerm.singular[cat.SupportedLanguages[language]];

                            // Only replace if we have a replacement value
                            if (replaceValue) {

                                // Return a (capitalized) result
                                const startReplace = ReplaceTermPipe.startCapitals(replaceValue);
                                ReplaceTermPipe.startsWithCaptital(match) || startReplace
                                    ? newValue = newValue.replace(match, (replaceValue.slice(0, startReplace+1).toUpperCase() + replaceValue.slice(startReplace+1).toLowerCase()))
                                    : newValue = newValue.replace(match, replaceValue.toLowerCase());

                            } else {
                                console.warn("User defined term not found, use default.", definition.original_singular);
                                newValue = newValue.replace(match, definition.original_singular);
                            }

                        } else {
                            console.warn("User defined term not found, use default?", match);
                        }
                    } else {
                        console.warn("No definition found for this singular term", match);
                    }

                } else if (pluralMatch.test(match)) {

                    // Get the definition based on the singular term, e.g. {profile}
                    const defKey = Object.keys(protosui.def.Terminology).find(key => protosui.def.Terminology[key]?.plural?.toUpperCase() === match.toUpperCase());
                    const definition: IDefData = protosui.def.Terminology[defKey];

                    if (definition) {
                        const userTerm = terminology.find((termMessage) => termMessage.id === cat.Terminology[defKey]);

                        if (userTerm?.plural) {
                            const replaceValue = userTerm.plural[cat.SupportedLanguages[language]];

                            // Only replace if we have a replacement value
                            if (replaceValue) {

                                // Return a (capitalized) result
                                const startReplace = ReplaceTermPipe.startCapitals(replaceValue);
                                ReplaceTermPipe.startsWithCaptital(match) || startReplace
                                    ? newValue = newValue.replace(match, (replaceValue.slice(0, startReplace+1).toUpperCase() + replaceValue.slice(startReplace+1).toLowerCase()))
                                    : newValue = newValue.replace(match, replaceValue.toLowerCase());

                            } else {

                                console.debug("User defined term not found, use default original_plural: ", definition.original_plural);

                                // Return a (capitalized) result
                                ReplaceTermPipe.startsWithCaptital(match)
                                    ? newValue = newValue.replace(match, (definition.original_plural.charAt(0).toUpperCase() + definition.original_plural.slice(1).toLowerCase()))
                                    : newValue = newValue.replace(match, definition.original_plural.toLowerCase());
                            }
                        } else {
                            console.debug("User defined plural term not found, use default?", match);
                        }
                    } else {
                        console.debug("No definition found for this plural term", match);
                    }
                }
            });
        }
        return newValue;
    }
}