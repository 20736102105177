<div class="cat-flex-container cat-flex-column">

    <modal-header
        [accountId]="data?.account?.id"
        [conversationId]="data?.conversationId"
        [messageId]="data?.message?.id"
        [title]="uitext.messagedetails"
        class="cat-margin-bottom-lg">
    </modal-header>

    <div class="cat-flex-grow cat-nooverflow-ellipsis">

        <ng-container *ngIf="(message$|async) as message">

            <div class="cat-flex-container cat-flex-column">

                <div class="cat-flex-grow cat-nooverflow-ellipsis">

                    <mat-tab-group
                        #tabGroup
                        preserveContent
                        class="cat-full-height cat-overflow-y" animationDuration="0"
                        [selectedIndex]="selectedTabIndex"
                        (selectedTabChange)="onTabChanged($event)">

                        <mat-tab label="{{ 'Message view' | translate }}" [aria-label]="uitext.message">
                            <ng-template matTabContent>
                                <div class="cat-padding-xl cat-background-white">
                                    <div class="cat-flex cat-align-center cat-justify-center">
                                        <cat-msg
                                            *ngIf="!(msgLoading$ | async)"
                                            [setmsg]="message"
                                            [showQuickSelect]="false"
                                            [showJumpDate]="false"
                                            [showMenu]="false"
                                            [account]="data?.account"
                                            (emitGetMedia)="userGetMedia($event)">
                                        </cat-msg>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="{{ 'Info' | translate }}" [aria-label]="uitext.info">
                            <ng-template matTabContent>

                                <div class="cat-padding-xl cat-margin-bottom-xl">

                                    <div class="grid-container">

                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.id | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ message?.id | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text cat-full-height">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.externid | translate }}
                                                </div>
                                                <div matListItemLine class="cat-wrap cat-break-word">
                                                    {{ message?.externid | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.sender | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ (message?.sender | accountHandle) | emptyValue }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.type | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ (messageDefinitions.MessageType | getEnumLabel : message?.type) | emptyValue | translate }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.direction | translate }}
                                                </div>
                                                <div matListItemLine>
                                                    {{ (messageDefinitions.DirectionType | getEnumLabel : message?.direction) | emptyValue | translate }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text cat-full-height">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.created | translate }}
                                                </div>
                                                <div matListItemLine class="cat-wrap cat-break-word">
                                                    {{ message?.created | date: 'd MMMM y, HH:mm:ss' : undefined : tl.locale }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                            <mat-list-item class="cat-cursor-text cat-full-height">
                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                <div matListItemTitle>
                                                    {{ uitext.received | translate }}
                                                </div>
                                                <div matListItemLine class="cat-wrap cat-break-word">
                                                    {{ message?.received | date: 'd MMMM y, HH:mm:ss' : undefined : tl.locale }}
                                                </div>
                                            </mat-list-item>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="{{ 'Annotations' | translate }}" [aria-label]="uitext.annotations">
                            <ng-template matTabContent>
                                <div class="cat-padding-xl">

                                    <app-annotations
                                        *ngIf="([permissionEnum.PERMISSION_VIEW_ANNOTATIONS, permissionEnum.PERMISSION_SET_ANNOTATIONS] | hasPermission)"
                                        [_accountId]="data?.account.id"
                                        [_refId]="data?.message?.id"
                                        [_get]="getAnnotations"
                                        [_remove]="removeAnnotations"
                                        [_add]="addAnnotations">
                                    </app-annotations>

                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="{{ 'Labels' | translate }}" [aria-label]="uitext.labels">
                            <ng-template matTabContent>
                                <div class="cat-padding-xl">

                                    <labels
                                        *ngIf="([permissionEnum.PERMISSION_VIEW_LABELS, permissionEnum.PERMISSION_SET_LABELS] | hasPermission)"
                                        [_add]="userSetMessageLabel"
                                        [asProfile]="true"
                                        [_get]="userGetMessageLabels"
                                        [_remove]="userUnsetMessageLabel"
                                        [_refId]="data?.message?.id"
                                        [routeId]="data?.profile.id"
                                        [_profile]="data?.profile">
                                    </labels>

                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="{{ uitext.attachments | translate }}" [aria-label]="uitext.attachments">
                            <ng-template matTabContent>
                                <ng-container *ngIf="(attachments$ | async) as attachments">
                                    <div class="cat-padding-xl">

                                        <ng-container *ngFor="let attachment of attachments trackBy: common.trackById">

                                            <div *ngIf="attachment.status === attachmentStatus.ATTACHMENTSTATUS_COMPLETE" class="cat-no-background">

                                                <div class="grid-container">

                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <header class="header">
                                                            <h5 class="cat-margin-md cat-padding-sm">
                                                                <b>{{ uitext.attachment | translate }}</b>
                                                            </h5>
                                                        </header>
                                                        <mat-divider></mat-divider>
                                                    </div>

                                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                        <mat-list-item class="cat-cursor-text cat-full-height">
                                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                            <div matListItemTitle>
                                                                {{ uitext.externid | translate }}
                                                            </div>
                                                            <div matListItemLine class="cat-wrap cat-break-word">
                                                                {{ attachment?.externid | emptyValue }}
                                                            </div>
                                                        </mat-list-item>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                        <mat-list-item class="cat-cursor-text">
                                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                            <div matListItemTitle>
                                                                {{ uitext.type | translate }}
                                                            </div>
                                                            <div matListItemLine>
                                                                {{ (messageDefinitions.AttachmentType | getEnumLabel : attachment?.type) | emptyValue | translate }}
                                                            </div>
                                                        </mat-list-item>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                        <mat-list-item class="cat-cursor-text">
                                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                            <div matListItemTitle>
                                                                {{ uitext.status | translate }}
                                                            </div>
                                                            <div matListItemLine>
                                                                {{ (messageDefinitions.AttachmentStatus | getEnumLabel : attachment?.status) | emptyValue | translate }}
                                                            </div>
                                                        </mat-list-item>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                        <mat-list-item class="cat-cursor-text">
                                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                            <div matListItemTitle>
                                                                {{ uitext.created | translate }}
                                                            </div>
                                                            <div matListItemLine>
                                                                {{ attachment?.created | date: 'd MMMM y, HH:mm' : undefined : tl.locale }}
                                                            </div>
                                                        </mat-list-item>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                        <mat-list-item class="cat-cursor-text">
                                                            <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                            <div matListItemTitle>
                                                                {{ uitext.lastmodified | translate }}
                                                            </div>
                                                            <div matListItemLine>
                                                                {{ attachment?.lastmodified | date: 'd MMMM y, HH:mm' : undefined : tl.locale }}
                                                            </div>
                                                        </mat-list-item>
                                                    </div>
                                                </div>

                                                <ng-container *ngIf="attachment?.files[0] as file">
                                                    <div class="grid-container">

                                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <header class="header cat-margin-top-xl">
                                                                <h5 class="cat-margin-md cat-padding-sm">
                                                                    <b>{{ uitext.file | translate }}</b>
                                                                </h5>
                                                            </header>
                                                            <mat-divider></mat-divider>
                                                        </div>

                                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                            <mat-list-item class="cat-cursor-text cat-full-height">
                                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                                <div matListItemTitle>
                                                                    {{ uitext.filename | translate }}
                                                                </div>
                                                                <div matListItemLine class="cat-wrap cat-break-word">
                                                                    {{ file?.filename | emptyValue }}
                                                                </div>
                                                            </mat-list-item>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                            <mat-list-item class="cat-cursor-text">
                                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                                <div matListItemTitle>
                                                                    {{ uitext.mimetype | translate }}
                                                                </div>
                                                                <div matListItemLine>
                                                                    {{ file?.mimetype | emptyValue }}
                                                                </div>
                                                            </mat-list-item>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                            <mat-list-item class="cat-cursor-text cat-full-height">
                                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                                <div matListItemTitle>
                                                                    {{ uitext.externid | translate }}
                                                                </div>
                                                                <div matListItemLine class="cat-wrap cat-break-word">
                                                                    {{ file?.externid | emptyValue }}
                                                                </div>
                                                            </mat-list-item>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                            <mat-list-item class="cat-cursor-text">
                                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                                <div matListItemTitle>
                                                                    {{ uitext.filesize | translate }}
                                                                </div>
                                                                <div matListItemLine>
                                                                    {{ (file?.filesize | filesize) | emptyValue }}
                                                                </div>
                                                            </mat-list-item>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                            <mat-list-item class="cat-cursor-text">
                                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                                <div matListItemTitle>
                                                                    {{ uitext.created | translate }}
                                                                </div>
                                                                <div matListItemLine>
                                                                    {{ file?.created | date: 'd MMMM y, HH:mm' : undefined : tl.locale }}
                                                                </div>
                                                            </mat-list-item>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                            <mat-list-item class="cat-cursor-text">
                                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                                <div matListItemTitle>
                                                                    {{ uitext.lastmodified | translate }}
                                                                </div>
                                                                <div matListItemLine>
                                                                    {{ file?.lastmodified | date: 'd MMMM y, HH:mm' : undefined : tl.locale }}
                                                                </div>
                                                            </mat-list-item>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                            <mat-list-item class="cat-cursor-text cat-full-height">
                                                                <mat-icon matListItemIcon color="dark">info</mat-icon>
                                                                <div matListItemTitle>
                                                                    {{ uitext.filehashes | translate }}
                                                                </div>
                                                                <div matListItemLine class="cat-wrap cat-break-word">
                                                                    <ng-container *ngFor="let hash of file?.filehashes | keys">
                                                                        <b>{{ hash.key }}</b> <br>
                                                                        {{ hash.value }}
                                                                        <br><br>
                                                                    </ng-container>
                                                                </div>
                                                            </mat-list-item>
                                                        </div>

                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div *ngIf="attachment.files?.length && attachment.status !== attachmentStatus.ATTACHMENTSTATUS_COMPLETE">
                                                {{ "Attachments are incomplete, awaiting media files." | translate }}
                                                <h4 class="cat-margin-top-xl"><b>{{ "Pending media files" | translate }} ({{ attachment.files.length }})</b></h4>

                                                <mat-table [dataSource]="attachment.files" class="cat-margin-top-md">

                                                    <ng-container matColumnDef="externid">
                                                        <mat-header-cell *matHeaderCellDef>
                                                            {{ uitext.externid | translate }}
                                                        </mat-header-cell>
                                                        <mat-cell *matCellDef="let mediafile">
                                                            <div class="cat-break-word cat-padding-y-sm">
                                                                {{ mediafile?.externid | emptyValue }}
                                                            </div>
                                                        </mat-cell>
                                                    </ng-container>

                                                    <ng-container matColumnDef="filename">
                                                        <mat-header-cell *matHeaderCellDef>
                                                            {{ "Filename" | translate }}
                                                        </mat-header-cell>
                                                        <mat-cell *matCellDef="let mediafile">
                                                            <div class="cat-break-word cat-padding-y-sm">
                                                                {{ mediafile?.filename | emptyValue }}
                                                            </div>
                                                        </mat-cell>
                                                    </ng-container>

                                                    <ng-container matColumnDef="filesize">
                                                        <mat-header-cell *matHeaderCellDef>
                                                            {{ "Filesize (bytes)" | translate }}
                                                        </mat-header-cell>
                                                        <mat-cell *matCellDef="let mediafile">
                                                            <ng-container *ngIf="mediafile.filesize">
                                                                {{ mediafile?.filesize | emptyValue }}
                                                            </ng-container>
                                                        </mat-cell>
                                                    </ng-container>

                                                    <ng-container matColumnDef="mimetype">
                                                        <mat-header-cell *matHeaderCellDef>
                                                            {{ "Mimetype" | translate }}
                                                        </mat-header-cell>
                                                        <mat-cell *matCellDef="let mediafile">
                                                            {{ mediafile?.mimetype | emptyValue }}
                                                        </mat-cell>
                                                    </ng-container>

                                                    <ng-container matColumnDef="lastmodified">
                                                        <mat-header-cell *matHeaderCellDef>
                                                            {{ uitext.lastmodified | translate }}
                                                        </mat-header-cell>
                                                        <mat-cell *matCellDef="let mediafile">
                                                            {{ mediafile.lastmodified | date: 'd MMM y, HH:mm:ss' : undefined : tl.locale }}
                                                        </mat-cell>
                                                    </ng-container>

                                                    <mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></mat-header-row>

                                                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                                                </mat-table>
                                            </div>
                                        </ng-container>
                                        <div *ngIf="!attachments?.length" class="cat-item cat-no-background">
                                            {{ uitext.notfound | translate }}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="{{ uitext.links | translate }}" [aria-label]="uitext.links">
                            <ng-template matTabContent>
                                <div class="cat-padding-xl">

                                    <div class="cat-margin-bottom-lg">
                                        <h6 class="cat-no-padding cat-no-margin">
                                            <b>{{ "Mentions" | translate }}</b>
                                        </h6>
                                        <div *ngFor="let mention of message?.mentions">
                                            {{ mention }}
                                        </div>
                                        <div *ngIf="!message?.mentions?.length">
                                            {{ "" | emptyValue }}
                                        </div>
                                    </div>

                                    <div class="cat-margin-bottom-lg">
                                        <h6 class="cat-no-padding cat-no-margin">
                                            <b>{{ "Hashtags" | translate }}</b>
                                        </h6>
                                        <div *ngFor="let hashtag of message?.hashtags">
                                            {{ hashtag }}
                                        </div>
                                        <div *ngIf="!message?.hashtags?.length">
                                            {{ "" | emptyValue }}
                                        </div>
                                    </div>

                                    <div class="cat-margin-bottom-lg">
                                        <h6 class="cat-no-padding cat-no-margin">
                                            <b>{{ "URLs" | translate }}</b>
                                        </h6>
                                        <div *ngFor="let url of message?.urls">
                                            {{ url }}
                                        </div>
                                        <div *ngIf="!message?.urls?.length">
                                            {{ "" | emptyValue }}
                                        </div>
                                    </div>

                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="{{ uitext.reactions | translate }}" [aria-label]="uitext.reactions">
                            <ng-template matTabContent>
                                <div class="cat-padding-xl">

                                    <ng-container *ngIf="(reactions$|async) as reactions">
                                        <div *ngIf="!(msgLoading$|async)" class="cat-no-background cat-list cat-overflow-y cat-padding-right-lg">
                                            <ng-container *ngFor="let reaction of reactions let last = last trackBy: common.trackById">
                                                <reaction
                                                    class="cat-block"
                                                    [class.cat-margin-bottom-md]="!last"
                                                    [account]="data?.account"
                                                    [reaction]="reaction"
                                                    [loading]="(msgLoading$|async)">
                                                </reaction>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="!reactions?.length">
                                            {{ uitext.notfound | translate }}
                                        </div>
                                    </ng-container>

                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="{{ uitext.history | translate }}" [aria-label]="uitext.history" class="cat-full-height cat-nooverflow-ellipsis">
                            <ng-template matTabContent class="cat-nooverflow-ellipsis">
                                <mat-tab-body class="cat-nooverflow-ellipsis cat-background-white">

                                    <history-timeline
                                        [message]="message"
                                        [account]="data?.account"
                                        [refresh]="refreshTimeline"
                                        [mode]="'msg'">
                                    </history-timeline>

                                </mat-tab-body>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>

                </div>

            </div>
        </ng-container>
    </div>
</div>
