<modal-header
    (emitAction)="headerAction($event)"
    [isLoading]="(isLoading$|async) || (isContactMergesLoading$|async) || (annotationLoading$|async)"
    [buttons]="buttons"
    [accountId]="accountId"
    [contactId]="contactId"
    [title]="uitext.contactdetails"
    class="cat-margin-bottom-lg">
</modal-header>

<main class="cat-page-container">
    <ng-container *ngIf="(contact$|async) as contact">
        <ng-container *ngIf="contact?.id">
            <mat-tab-group preserveContent animationDuration="0" (selectedTabChange)="tabChange($event)" class="cat-full-height cat-overflow-y">

                <mat-tab label="{{ uitext.info | translate }}" [aria-label]="uitext.info">
                    <ng-template matTabContent>

                        <div class="cat-padding-xl cat-margin-bottom-xl">

                            <div class="grid-container">

                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item (click)="openFormDialog('alias', contact); $event.stopPropagation()" class="cat-cursor-pointer cat-hover-white">
                                        <mat-icon matListItemIcon color="primary">edit</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.alias | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.alias | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.id | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.id | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.externid | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.externid | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.fullname | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.fullname | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.phonenumber | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.phonenumber | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.firstname | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.firstname | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.lastname | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.lastname | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.birthday | translate }}
                                        </div>
                                        <div matListItemLine>
                                            <ng-container *ngIf="contact?.birthday?.day else noBirthday">
                                                {{ common.objectToDate(contact.birthday) | date : 'd MMMM y' : undefined : tl.locale }}
                                            </ng-container>
                                            <ng-template #noBirthday>
                                                {{ "" | emptyValue }}
                                            </ng-template>
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text cat-full-height">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.bio | translate }}
                                        </div>
                                        <div matListItemLine class="cat-wrap cat-break-word">
                                            {{ contact?.bio | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.website | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.website | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.email | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.email | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.location | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.location | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.username | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.username | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text cat-full-height">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.servernicknames | translate }}
                                        </div>
                                        <div matListItemLine class="cat-wrap cat-break-word">
                                            {{ contact?.servernicknames | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text cat-full-height">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.paidsubscription | translate }}
                                        </div>
                                        <div matListItemLine class="cat-wrap cat-break-word">
                                            {{ contact?.paidsubscription | emptyValue }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.created | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.created | date: 'd MMMM y, HH:mm' : undefined : tl.locale }}
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                    <mat-list-item class="cat-cursor-text">
                                        <mat-icon matListItemIcon color="dark">info</mat-icon>
                                        <div matListItemTitle>
                                            {{ uitext.lastmodified | translate }}
                                        </div>
                                        <div matListItemLine>
                                            {{ contact?.lastmodified | date: 'd MMMM y, HH:mm' : undefined : tl.locale }}
                                        </div>
                                    </mat-list-item>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab
                    class="cat-content"
                    [aria-label]="uitext.mergedcontacts"
                    label="{{ uitext.mergedcontacts | translate }}">
                    <ng-template matTabContent>
                        <div class="cat-margin-lg cat-nooverflow-ellipsis cat-padding-xl">

                            <section class="cat-max-height-xl cat-overflow-y cat-background-light-shade" tabindex="0">

                                <ng-container *ngIf="(mergedcontacts$|async) as contacts">
                                    <mat-table [dataSource]="contacts">

                                        <mat-header-row *matHeaderRowDef="contactColumns; sticky: true"></mat-header-row>

                                        <mat-row *matRowDef="let row; columns: contactColumns" (click)="gotoMergedContact(row); $event.stopPropagation()" class="cat-cursor-pointer"></mat-row>

                                        <ng-container matColumnDef="avatar">
                                            <mat-header-cell *matHeaderCellDef class="cat-width-100"></mat-header-cell>
                                            <mat-cell *matCellDef="let contact" class="cat-width-100">
                                                <ng-container>
                                                    <div class="cat-avatar cat-sm cat-no-margin">
                                                        <img *ngIf="(contact?.avatar | hasThumb)"
                                                            [src]="contact.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : contact.accountrefid">
                                                    </div>
                                                </ng-container>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="app">
                                            <mat-header-cell *matHeaderCellDef class="cat-width-100">
                                                {{ uitext.app | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let contact" class="cat-width-100">
                                                <ng-container *ngIf="(apptypes$|async) as apptypes">
                                                    <div
                                                        *ngIf="contact.apptype"
                                                        matTooltip="{{ contact.apptype.name | capitalizeFirstChar }}"
                                                        matTooltipPosition="right"
                                                        class="cat-avatar cat-xs cat-no-margin cat-no-border-radius cat-no-background">
                                                        <img
                                                            *ngIf="contact.apptype && contact.apptype.id"
                                                            [src]="apptypes.get(contact.apptype.id).iconfileid | iconmedia">
                                                    </div>
                                                </ng-container>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="externid">
                                            <mat-header-cell *matHeaderCellDef>
                                                {{ uitext.externid | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let contact">
                                                {{ contact.externid | emptyValue }}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="username">
                                            <mat-header-cell *matHeaderCellDef>
                                                {{ uitext.username | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let contact">
                                                {{ contact.username | emptyValue }}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="name">
                                            <mat-header-cell *matHeaderCellDef>
                                                {{ uitext.name | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let contact">
                                                {{ contact | accountName | emptyValue }}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="alias">
                                            <mat-header-cell *matHeaderCellDef>
                                                {{ uitext.alias | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let contact">
                                                {{ contact.alias | emptyValue }}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="servernicknames">
                                            <mat-header-cell *matHeaderCellDef>
                                                {{ uitext.nicknames | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let contact">
                                                {{ contact.servernicknames | emptyValue }}
                                            </mat-cell>
                                        </ng-container>

                                    </mat-table>

                                    <div *ngIf="contacts.length === 0" class="cat-no-records">
                                        {{ uitext.notfound | translate }}
                                    </div>

                                </ng-container>
                            </section>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab
                    class="cat-content"
                    [aria-label]="uitext.conversations"
                    label="{{ uitext.conversations | translate }}">
                    <ng-template matTabContent>
                        <div class="cat-margin-lg cat-nooverflow-ellipsis cat-padding-xl">

                            <section class="cat-max-height-xl cat-overflow-y cat-background-light-shade" tabindex="0">

                                <ng-container *ngIf="(conversations$|async) as conversations">
                                    <mat-table [dataSource]="conversations">

                                        <mat-header-row *matHeaderRowDef="conversationColumns; sticky: true"></mat-header-row>

                                        <mat-row *matRowDef="let row; columns: conversationColumns" (click)="gotoConversation(row); $event.stopPropagation()" class="cat-cursor-pointer"></mat-row>

                                        <ng-container matColumnDef="apptype">
                                            <mat-header-cell *matHeaderCellDef class="cat-width-100">
                                                {{ uitext.app | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let conversation" class="cat-width-100">
                                                <ng-container *ngIf="(apptypes$|async) as apptypes">
                                                    <div
                                                        *ngIf="conversation.apptype"
                                                        matTooltip="{{ conversation.apptype.name | capitalizeFirstChar }}"
                                                        matTooltipPosition="right"
                                                        class="cat-avatar cat-xs cat-no-margin cat-no-border-radius cat-no-background">
                                                        <img
                                                            *ngIf="conversation.apptype && conversation.apptype.id"
                                                            [src]="apptypes.get(conversation.apptype.id).iconfileid | iconmedia">
                                                    </div>
                                                </ng-container>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="name">
                                            <mat-header-cell *matHeaderCellDef>
                                                {{ uitext.name | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let conversation">
                                                {{ conversation.name | emptyValue }}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="type">
                                            <mat-header-cell *matHeaderCellDef>
                                                {{ uitext.type | translate }}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let conversation">
                                                {{ conversationTypeEnum[conversationType[conversation.type]].label | translate }}
                                            </mat-cell>
                                        </ng-container>

                                    </mat-table>

                                    <div *ngIf="conversations.length === 0" class="cat-no-records">
                                        {{ uitext.notfound | translate }}
                                    </div>

                                </ng-container>
                            </section>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab
                    *ngIf="([permissionEnum.PERMISSION_SET_ANNOTATIONS, permissionEnum.PERMISSION_VIEW_ANNOTATIONS] | hasPermission)"
                    [aria-label]="uitext.annotations"
                    label="{{ uitext.annotations | translate }}">
                    <ng-template matTabContent>
                        <div class="cat-padding-xl">
                            <app-annotations
                                [_accountId]="contact.accountrefid"
                                [_refId]="contact.id"
                                [_get]="getAnnotations"
                                [_remove]="removeAnnotations"
                                [_add]="addAnnotations">
                            </app-annotations>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab
                    *ngIf="([permissionEnum.PERMISSION_SET_LABELS, permissionEnum.PERMISSION_VIEW_LABELS] | hasPermission)"
                    [aria-label]="uitext.labels"
                    label="{{ uitext.labels | translate }}">
                    <ng-template matTabContent>
                        <div class="cat-padding-xl">
                            <labels
                                [_refId]="contact.id"
                                [asProfile]="true"
                                [routeId]="profileId"
                                [_get]="getLabels"
                                [_add]="setLabel"
                                [_remove]="unsetLabel">
                            </labels>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab
                    label="{{ uitext.history | translate }}"
                    [aria-label]="uitext.history"
                    class="cat-full-height cat-nooverflow-ellipsis">
                    <ng-template matTabContent class="cat-nooverflow-ellipsis">
                        <mat-tab-body class="cat-nooverflow-ellipsis cat-background-white">
                            <history-timeline
                                [account]="{ id: accountId }"
                                [contact]="contact"
                                [mode]="'contact'"
                                [refresh]="refreshTimeline">
                            </history-timeline>

                        </mat-tab-body>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>

        </ng-container>
    </ng-container>
</main>