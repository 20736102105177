import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";

/**
 * Check if a chat is private
 *
 * @export
 * @class IsOneOnOneChat
 * @implements {PipeTransform}
 */
@Pipe({
  name: "isOneOnOneChat",
  pure: true
})

export class IsOneOnOneChat implements PipeTransform {
    transform(type: cat.ConversationType): boolean {

      let result = false;

      switch (type) {
        case cat.ConversationType.CONVERSATION_SECRET:
        case cat.ConversationType.CONVERSATION_PRIVATE:
        case cat.ConversationType.CONVERSATION_SELF: {
          result = true;
          break;
        }
        default: {
          break;
        }
      }

      return result;
    }
}
