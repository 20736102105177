import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { CommonService } from "@services/common/common.service";
import { TlService } from "@services/tl/tl.service";

/** Decorator that marks a class as an Angular component */
@Component({
    selector: "app-msg-search-result",
    templateUrl: "./msg-search-result.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MsgSearchResultComponent {

    @Input() term = "";
    @Input() msg: cat.MessageMsg = cat.MessageMsg.create();

    public topicState = cat.TopicState;

    constructor(
        public common: CommonService,
        public tl: TlService
    ) {
    }
}
