import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class SubscriptionManager implements OnDestroy {

    private subscriptions: Subscription[] = [];

    protected addSubscription(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    protected addSubscriptions(subscriptions: Subscription[]) {
        this.subscriptions.push(...subscriptions);
    }

    protected unsubscribeAll() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    ngOnDestroy() {
        this.unsubscribeAll();
    }
}