// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { Injectable } from "@angular/core";
import { environment } from "@app/env";

/**
* The possible log levels.
*/
export enum LogLevel {
    Error,
    Warning,
    Debug
}

@Injectable({
    providedIn: "root"
})

export class LoggerService {

    /**
    * Logs messages or objects with the debug level.
    * Works the same as this.logger.debug().
    */
    public debug(...objects: any[]) {
        this.log(console.log, LogLevel.Debug, objects); // tslint:disable-line
    }

    /**
    * Logs messages or objects with the warning level.
    * Works the same as console.warn().
    */
    public warn(...objects: any[]) {
        this.log(console.warn, LogLevel.Warning, objects);
    }

    /**
    * Logs messages or objects with the error level.
    * Works the same as this.logger.error().
    */
    public error(...objects: any[]) {
        this.log(console.error, LogLevel.Error, objects);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private log(func: Function, level: LogLevel, objects: any[]) {
        if (!environment.production || (environment.production && level === LogLevel.Error)) {
            func.apply(console, objects);
        }
    }
}
