// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input } from "@angular/core";
import { MatGridList } from "@angular/material/grid-list";
import { CommonService } from "@services/common/common.service";

export interface GridColumns {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
}

@Directive({
    selector: "[matGridResize]",
    host: {
        '(window:resize)': 'onResize()',
    }
})
export class MatGridResizeDirective implements AfterViewInit {

    @Input() set resizeNow(resize: boolean) {
        if (resize) {
            this.onResize();
        }
    }

    private _defaultCols = 3;

    public constructor(public grid: MatGridList, private el: ElementRef, private _cdr: ChangeDetectorRef, private _common: CommonService) {}

    /**
     * Get the current grid and determine the columns.
     *
     * @private
     * @memberof MatGridResizeDirective
     */
    private onResize() {
        // Use a timeout to get the actual current width;
        setTimeout(() => {
            const width = this.el.nativeElement.offsetWidth;
            this.grid.cols = this.getCols(width);
            this._common.detectChange(this._cdr);
        });
    }

    public ngAfterViewInit(): void {
        console.debug("[MatGridResizeDirective]: After view init.");
        this.onResize();
    }

    /**
     * Return the amount of columns appropriate for the width.
     *
     * @private
     * @param {number} width
     * @returns
     * @memberof MatGridResizeDirective
     */
    private getCols(width: number) {
        let columns: number = this._defaultCols;
        if (width <= 600) {
            columns = 1;
        } else if (width <= 800) {
            columns = 2;
        } else if (width <= 1000) {
            columns = 3;
        } else if (width <= 1200) {
            columns = 4;
        } else if (width <= 1400) {
            columns = 5;
        } else if (width <= 1600) {
            columns = 5;
        } else if (width <= 1800) {
            columns = 6;
        } else if (width <= 2000) {
            columns = 7;
        } else if (width <= 2200) {
            columns = 8;
        } else {
            10;
        }
        return columns;
    }
}

