import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { appRouteNames } from "@app/app.routes.names";
import { cat } from "@assets/proto/msgs";
import { userGetConversationReport, userSetConversationVisibility } from "@assets/proto/services";
import { protosui } from "@definitions/definitions";
import { ConversationDetailsDialog } from "@modals/detail-pages/conversation-details/conversation-details.dialog";
import { ConversationAction, IConversationDetailData, IState } from "@shared/app-models";
import { Store } from "@ngxs/store";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { ReportWizardService } from "@services/report-wizard/report-wizard.service";
import { WsService } from "@services/ws/ws.service";
import { AuthService } from "@services/auth/auth.service";
@Component({
    selector: "chat-item-menu",
    templateUrl: "./chat-item-menu.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatItemMenuComponent {

    @Input() chat: cat.ConversationMsg = cat.ConversationMsg.create();
    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    @Input() profileId: string = "";
    @Output() emitRefreshChats: EventEmitter<boolean> = new EventEmitter();

    public reportIcon = protosui.msgIcons.ReportMsg;
    public visibilityType = cat.VisibilityType;
    public permissionEnum = cat.Permission;
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        private _auth: AuthService,
        private _reportService: ReportWizardService,
        private _logger: LoggerService,
        private _dialog: MatDialog,
        private _router: Router,
        private _store: Store,
        private _ws: WsService
    ) {}

    /**
    * Open the conversation detail modal
    * @param {cat.ConversationMsg} conversation The conversation
    */
    public async openDetails() {
        try {
            const dialogData: IConversationDetailData = {
                conversationAction: ConversationAction.View,
                conversation: this.chat,
                profile: cat.ProfileMsg.create({ id: this.profileId }),
                account: this.account
            };
            this._logger.debug("Dialog Data: ", dialogData);

            this._dialog.open(ConversationDetailsDialog, {
                width: "95vw",
                height: "95vh",
                data: dialogData,
                panelClass: "cat-dialog"
            });
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Create a report from a conversation perspective
     *
     * @memberof ChatItemMenuComponent
     */
     public async createReport() {
        try {
            const payload: cat.ConversationMsg = cat.ConversationMsg.create({
                id: this.chat.id
            });
            await this._ws.sendRequest(userGetConversationReport, payload);
            const report = this._store.selectSnapshot((state: IState) => state.cat.userGetConversationReport)?.msg;
            this._reportService.startDuplicateReport(report);

            // Make sure the reporter navigates back here.
            this._auth.setBackPath(this._router.url);

            await this._router.navigate([`${appRouteNames.DASHBOARD}/profile/${this.profileId}/${appRouteNames.MANAGE_PROFILE_RERORTS}/${appRouteNames.REPORT_TEMPLATE}`]);
            this.common.dismissAllOverlays();
        } catch (error) {
            this._logger.error(error);
            await this.common.createSnackbar(error);
        }
    }


    /**
     * Set the visibility of a single conversation.
     *
     * @memberof ChatItemMenuComponent
     */
    public async hideConversation() {
        try {
            // Determine visibility.
            const visibility = this.chat.visibility === cat.VisibilityType.VISIBILITY_HIDDEN
                ? cat.VisibilityType.VISIBILITY_VISIBLE
                : cat.VisibilityType.VISIBILITY_HIDDEN;

            const payload: cat.ConversationMsg = cat.ConversationMsg.create({
                id: this.chat.id,
                visibility: visibility
            });
            await this._ws.sendRequest(userSetConversationVisibility, payload);

            // Inform the chat page that visibility has updated for a chat.
            this.emitRefreshChats.emit();

            this.common.dismissAllOverlays();
        } catch (error) {
            this._logger.error(error);
            await this.common.createSnackbar(error);
        }
    }
}
