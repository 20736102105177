import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";

/** Decorator that marks a class as an Angular component */
@Component({
    selector: "app-msg-attachment",
    templateUrl: "./msg-attachment.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MsgAttachmentsComponent {

    @ViewChild("audioPlayer", {static: false}) audioPlayer: ElementRef;

    // Component inputs
    @Input() attachment: cat.AttachmentMsg = cat.AttachmentMsg.create();
    @Input() msg: cat.MessageMsg = cat.MessageMsg.create();
    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    // Component outputs
    @Output() emitGetMedia: any = new EventEmitter();

    // Enums
    public attachmentType = cat.AttachmentType;
    public attachmentTypeDef = protosui.def.AttachmentType;
    public attachmentStatusDef = protosui.def.AttachmentStatus;
    public thumbStatusDef = protosui.def.ThumbnailStatus;
    public conversationType = cat.ConversationType;
    public attachmentStatus = cat.AttachmentStatus;
    public thumbStatus = cat.ThumbnailStatus;
    public mediaType = cat.MediaType;
    public uitext = protosui.messages.uitext;
    public mediaHeaders = [
        cat.AttachmentType.ATTACHMENT_EMBED,
        cat.AttachmentType.ATTACHMENT_INVITE,
        cat.AttachmentType.ATTACHMENT_CONTACT,
        cat.AttachmentType.ATTACHMENT_LOCATION,
        cat.AttachmentType.ATTACHMENT_DOCUMENT,
        cat.AttachmentType.ATTACHMENT_AUDIO,
        cat.AttachmentType.ATTACHMENT_ARCHIVE,
        cat.AttachmentType.ATTACHMENT_EMOJI
    ];

    constructor(public common: CommonService) {}


    /**
     * Open the media details in the message component.
     *
     * @param {cat.AttachmentMsg} attachment The attachment.
     * @param {cat.MessageMsg} msg The message.
     * @memberof MsgAttachmentsComponent
     */
    emitMedia(attachment: cat.AttachmentMsg, msg: cat.MessageMsg): void {
        // First stop the audio before opening details
        const audio: HTMLAudioElement = this.audioPlayer?.nativeElement;

        if (audio) {
            audio.pause();
            audio.currentTime = 0;
        }

        // Then emit to open the media in the message component.
        this.emitGetMedia.emit(attachment, msg);
    }
}
