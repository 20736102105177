import { Store } from "@ngxs/store";
import { Location } from "@angular/common";
import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy, inject } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { cat } from "@assets/proto/msgs";
import { IDialogButton, IDialogDeviceStats, IIconDefinition, IState } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { getList, getListItemById } from "@store/store";
import { Observable } from "rxjs";
import { AuthService } from "@services/auth/auth.service";
import { appRouteNames } from "@app/app.routes.names";
import { DeviceStats } from "@modals/device-stats/device-stats.modal";
import { MatDialog } from "@angular/material/dialog";
import { IsBufferingPipe } from "@pipes/isbuffering/isbuffering.pipe";
import { protosui } from "@definitions/definitions";
import { WsService } from "@services/ws/ws.service";
import { userGetCurrentUserProfiles } from "@assets/proto/services";
import { SubscriptionManager } from "@shared/subscription-manager";

@Component({
    selector: "app-header",
    templateUrl: "./app-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

/** Header component class */
export class HeaderComponent extends SubscriptionManager implements OnInit, OnDestroy {

    @Input() title: string;
    @Input() loading = false;
    @Input() hideNotifications = false;
    @Input() hideUserDetails = false;
    @Input() hideProgressBar = false;
    @Input() back = false;
    @Input() backWarning = false;
    @Input() dismiss = false;
    @Input() keepOverlays = false;
    @Input() profileMode = false;
    @Input() profileId = "";
    @Input() apptype: cat.AppTypeMsg = cat.AppTypeMsg.create();
    @Input() icon: IIconDefinition = { color: "primary", matname: "" };
    @Input() usm: cat.UserSessionMsg = cat.UserSessionMsg.create();

    me$: Observable<cat.UserMsg> = inject(Store).select((state: IState) => state.cat.Me?.msg);
    notifications$: Observable<cat.NotificationMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetCurrentUserNotifications));
    devices$: Observable<cat.DeviceMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetProfileDevices));

    // Profile mode properties.
    public mediaType = cat.MediaType;
    public account: cat.AccountMsg = cat.AccountMsg.create({ avatar: cat.AttachmentMsg.create() });
    public profile: cat.ProfileMsg = cat.ProfileMsg.create();
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        private _auth: AuthService,
        private _store: Store,
        private _ws: WsService,
        private _router: Router,
        private _location: Location,
        private _dialogRef: MatDialog,
        private _logger: LoggerService,
        private _activatedRoute: ActivatedRoute
    ) {
        super();
    }

    async ngOnInit() {
        // Fetch the usersession to show roles.
        this.usm = this._auth.getUserSession();

        // Subscribe to route param changes
        this.addSubscription(
            // When a user changes the id, fetch new data.
            this._activatedRoute.params.subscribe(async (params: Params) => {

                // Determine ID's from the route.
                const accountId = params.accountid;
                params.profileid
                    ? this.profile.id = params.profileid
                    : this.profile.id = this.profileId;

                if (this.profileMode) {
                    if (!this.profile.id) {
                        throw new Error("No profile id found.");
                    }
                    // Attach the stored app to the properties.
                    const accounts = this._store.selectSnapshot((state: IState) => state.cat.userGetProfileAccounts).list;
                    if (accounts.has(accountId)) {
                        this.account = accounts.get(accountId);
                        if (this.account?.apptype?.id && this._store.selectSnapshot((state: IState) => state.cat.userGetAppTypes).list.has(this.account.apptype.id)) {
                            this.apptype = this._store.selectSnapshot((state: IState) => state.cat.userGetAppTypes).list.get(this.account.apptype.id);
                        }
                    }
                    // Fetch the complete profile from the store.
                    if (this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserProfiles).list.has(this.profile.id)) {
                        this.profile = cat.ProfileMsg.create({ ...this.profile, ...this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserProfiles).list.get(this.profile.id) });
                    } else {
                        const payload = cat.ProfileMsg.create({ id: this.profile.id });
                        await this._ws.sendRequest(userGetCurrentUserProfiles, payload);
                    }
                    // Profile may be stored as request item.
                    if (!this.profile.created) {
                        // Preload the current profile (if already exists).
                        if (this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserProfiles).list.has(this.profile.id)) {
                            this.profile = this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserProfiles).list.get(this.profile.id);
                        }
                    }
                }
            })
        );
    }

    ngOnDestroy() {
        this.usm = cat.UserSessionMsg.create();
        this.unsubscribeAll();
    }

    get profile$(): Observable<cat.ProfileMsg> {
        return this._store.select((state: IState) => getListItemById(state.cat, userGetCurrentUserProfiles.methodName, this.profile.id) as cat.ProfileMsg);
    }


    /**
     * Change the role.
     *
     * @memberof HeaderComponent
     */
    public async changeRole() {
        await this._router.navigate([`/${appRouteNames.PREREQUISITES}`], {
            replaceUrl: true,
            skipLocationChange: false,
            queryParams: { changeRole: true }
        });
    }

    /**
     * Navigate to the user profile page
     *
     * @memberof HeaderComponent
     */
    public openUserDetails() {
        const user: cat.UserMsg = this._store.selectSnapshot((state: IState) => state.cat.Me.msg);
        this._router.navigate([`${appRouteNames.USER_SETTINGS}/${user.id}`]);
    }

    /**
    * Present a notifications modal
    */
    async toNotifications() {
        try {
            await this._router.navigate([`${appRouteNames.NOTIFICATIONS}`]);
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Navigate back in history
     *
     * @memberof HeaderComponent
     */
    async goBack() {

        if (this.backWarning) {

            // Create dialog
            const warningDialog = this.common.createDialogReference({
                title: protosui.messages.uitext.areyousure,
                content: protosui.messages.uitext.loseprogress,
                buttons: [
                    {
                        title: protosui.messages.uitext.ok,
                        color: "warn",
                        action: "proceed"
                    },
                    {
                        title: protosui.messages.uitext.cancel,
                        color: "primary",
                        action: "cancel"
                    }
                ]
            });

            warningDialog.afterClosed().subscribe(async (result: IDialogButton["action"]) => {
                if (result === "proceed") {
                    this.navigateBack();
                }
            });

        } else {
            this.navigateBack();
        }
    }

    /**
     * Navigate back to the correct path.
     *
     * @private
     * @memberof HeaderComponent
     */
    private async navigateBack() {
        // Get the backpath right before navigating.
        const backpath = this._auth.getBackPath();

        if (this._activatedRoute?.snapshot?.queryParams["from"]) {
            this._logger.debug("Query param", this._activatedRoute.snapshot.queryParams["from"]);
            await this._router.navigate([this._activatedRoute.snapshot.queryParams["from"]]);
        } else if (backpath) {
            await this._router.navigate([backpath]);
            this._auth.clearBackPath();
        } else {
            this._location.back();
        }
    }

    /**
    * Go back one step, by dismissing the modal.
    */
    async dismissPage() {
        try {
            if (this.keepOverlays) {
                const topDialog = this._dialogRef.openDialogs[this._dialogRef.openDialogs.length - 1];
                if (topDialog) {
                    topDialog.close();
                }
            } else {
                await this.common.dismissAllOverlays();
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Open the stat dialog.
     *
     * @param {string} [packageName] Optional packagename.
     * @memberof HeaderComponent
     */
    openStatDialog(packageName?: string) {

        const devices = this._store.selectSnapshot((state: IState) => getList(state.cat.userGetProfileDevices) as cat.DeviceMsg[]);
        const isBuffering = new IsBufferingPipe(this._logger).transform(devices, this.apptype);
        this._logger.debug("Devices", devices);
        this._logger.debug("isBuffering", isBuffering);

        if (isBuffering) {
            const data: IDialogDeviceStats = {
                profileId: this.profile.id,
                packageName: packageName
            }
            const dialogRef = this._dialogRef.open(DeviceStats, {
                data: data
            });
            dialogRef.afterClosed().subscribe(result => {
                this._logger.debug(`Dialog result: ${result}`);
            });
        }
    }
}
