import { Store } from "@ngxs/store";
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, inject, Inject } from "@angular/core";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { WsService } from "@services/ws/ws.service";
import { Observable } from "rxjs";
import { messageDefinitions } from "@assets/proto/message-definitions";
import { clearStoredMessage } from "@store/actions";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { MediaDetailsDialog } from "../media-details/media-details.dialog";
import { TlService } from "@services/tl/tl.service";
import * as calls from "@assets/proto/services";
import * as models from "@shared/app-models";
import { SubscriptionManager } from "@shared/subscription-manager";

@Component({
    selector: "topic-details",
    templateUrl: "topic-details.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicDetailsDialog extends SubscriptionManager implements OnInit, OnDestroy {

    topic$: Observable<cat.TopicMsg> = inject(Store).select((state: models.IState) => state.cat.userGetDetailedTopic.msg);

    public selectedTabIndex: number = 0;

    public attachmentType = cat.AttachmentType;
    public conversationAction: number = models.ConversationAction.View;
    public conversationActionEnum = models.ConversationAction;
    public mediaType: cat.MediaType = cat.MediaType.MEDIA_ACCOUNTAVATARS;
    public permissionEnum = cat.Permission;
    public profile: cat.ProfileMsg = cat.ProfileMsg.create();
    public messageDefinitions = messageDefinitions;
    public uitext = protosui.messages.uitext;

    public getAnnotations: calls.IServiceDescriptor = calls.userGetTopicAnnotations;
    public addAnnotations: calls.IServiceDescriptor = calls.userAddTopicAnnotation;
    public removeAnnotations: calls.IServiceDescriptor = calls.userRemoveTopicAnnotation;
    public getLabels: calls.IServiceDescriptor = calls.userGetTopicLabels;
    public setLabel: calls.IServiceDescriptor = calls.userSetTopicLabel;
    public unsetLabel: calls.IServiceDescriptor = calls.userUnsetTopicLabel;

    public definition: models.IMessageDefinitions = this.common.getMessageDefinitions(messageDefinitions.TopicMsg);
    public account: cat.AccountMsg = cat.AccountMsg.create();

    // History
    public refreshTimeline: number = 0;

    private _topicId = "";

    constructor(
        public tl: TlService,
        public common: CommonService,
        private _dialog: MatDialog,
        private _store: Store,
        private _logger: LoggerService,
        private _ws: WsService,
        @Inject(MAT_DIALOG_DATA) public data: models.ITopicDetailData
    ) {
        // SubscriptionManager.
        super();

        this.account = data.account;
        this.profile = data.profile;
        this._topicId = data.topic.id;

        if (!this.account.id || !this.profile.id || !this._topicId) {
            throw new Error("Missing profile, account or topic id.");
        }
    }

    public async ngOnInit() {
        // Clear details.
        this._store.dispatch(new clearStoredMessage(calls.userGetDetailedTopic.methodName, messageDefinitions.TopicMsg));
        // Get the topic details.
        await this.userGetDetailedTopic();
    }


    public ngOnDestroy() {
        try {
            // Clear store values.
            this._store.dispatch(new clearStoredMessage(calls.userGetDetailedTopic.methodName, messageDefinitions.TopicMsg));
            // Ensure all subscriptions are removed (synchronous and not using callbacks)
            this.unsubscribeAll();
        } catch (error) {
            this._logger.error(error);
        }
    }

    public async onTabChanged(event: MatTabChangeEvent) {

        if (!event || !event.tab?.ariaLabel) {
            this._logger.error("No label text / event found.");
        }

        const label = event.tab.ariaLabel.toLowerCase();

        if (label === this.uitext.info.toLowerCase()) {
            await this.userGetDetailedTopic();
        }
        if (label === this.uitext.history.toLowerCase()) {
            await this.userGetDetailedTopic();
        }
    }

    /**
     * Get the full detailed topic.
     *
     * @private
     * @memberof TopicDetailsDialog
     */
    private async userGetDetailedTopic() {
        try {
            const payload = cat.TopicMsg.create({id: this._topicId });
            await this._ws.sendRequest(calls.userGetDetailedTopic, payload);
            this.refreshTimeline = Date.now();
        } catch (error) {
            this._logger.error(error);
            this.common.createSnackbar(error);
        }
    }

    /**
    * Enlarge the topic avatar
    * @param {cat.TopicMsg} topic The current topic, with avatar
    */
    async openMedia(topic: cat.TopicMsg) {
        try {

            if (!topic || !this.account.id || !topic.avatar) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            // Close this dialog.
            this.common.dismissAllOverlays();

            const attachment: cat.AttachmentMsg = cat.AttachmentMsg.create(topic.avatar);

            const dialogData: models.IImageDetailPageData = {
                attachment: attachment,
                refId: this.account.id,
                accountId: this.account.id,
                mediatype: cat.MediaType.MEDIA_ACCOUNTAVATARS,
                asProfile: true,
                profileId: this.profile.id
            };
            this._logger.debug("Dialog Data: ", dialogData);

            this._dialog.open(MediaDetailsDialog, {
                width: "95vw",
                height: "95vh",
                data: dialogData
            });

        } catch (error) {
            this._logger.error(error);
        }
    }
}
