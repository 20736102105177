<div class="cat-flex-inner-container cat-font-weight-light">

    <div class="cat-padding-top-lg cat-padding-x-lg cat-end">
        <mat-icon (click)="showInfo()" matSuffix matTooltip="help" matTooltipPosition="right" color="primary" class="cat-cursor-pointer">
            help
        </mat-icon>
    </div>

    <div class="cat-height-60">
        <div class="cat-flex-row cat-center">
            <div class="cat-flex-1 cat-flex-grid-container cat-center">
                <div *ngIf="previousItem?.id === originId" class="cat-chip cat-padding-x-sm">
                    <div class="cat-chip-content cat-padding-sm cat-text-xs">
                        {{ uitext.start | translate }}
                    </div>
                </div>
                <div *ngIf="previousItem?.historytimestamp" class="cat-full-width cat-margin-top-sm">
                    <b>{{ previousItem.historytimestamp | date: 'd MMMM y, HH:mm:ss' : undefined : tl.locale }}</b>
                </div>
            </div>
            <div *ngIf="previousItem?.id" class="cat-flex-1 cat-flex-grid-container cat-max-width-xl"></div>
            <div class="cat-flex-1 cat-flex-grid-container cat-center">
                <div *ngIf="(selectedItem?.id === originId) && (currentId !== originId)" class="cat-chip cat-padding-x-sm">
                    <div class="cat-chip-content cat-padding-sm cat-text-xs">
                        {{ uitext.start | translate }}
                    </div>
                </div>
                <div *ngIf="selectedItem?.id === currentId" class="cat-chip cat-padding-x-sm">
                    <div class="cat-chip-content cat-padding-sm cat-text-xs">
                        {{ uitext.current | translate }}
                    </div>
                </div>
                <div *ngIf="selectedItem?.historytimestamp" class="cat-full-width cat-margin-top-sm">
                    <b>{{ selectedItem.historytimestamp | date: 'd MMMM y, HH:mm:ss' : undefined : tl.locale }}</b>
                </div>
            </div>
        </div>
    </div>


    <div class="cat-overflow-y cat-padding-x-md">

        <div class="cat-flex-row cat-center">

            <div class="cat-flex-1 cat-flex-grid-container cat-center">
                <ng-container *ngIf="previousItem?.id">
                    <ng-container *ngTemplateOutlet="historyCard; context: { item: previousItem }"></ng-container>
                </ng-container>
                <ng-container *ngIf="!previousItem?.id">
                    <div>{{ "No previous item" | translate }}</div>
                </ng-container>
            </div>

            <div *ngIf="previousItem?.id" class="cat-flex-1 cat-flex-grid-container cat-center cat-max-width-xl">
                <ng-container *ngIf="previousItem?.historymarkers?.length">
                    <div *ngIf="previousItem as item" class="cat-flex-grid-container cat-align-center cat-text-sm cat-margin-top-sm">
                        <span class="cat-block cat-padding-right-md" color="warn"><b><</b></span>
                        <mat-chip-set class="cat-center mat-mdc-chip-set-stacked">
                            <mat-chip *ngFor="let item of item.historymarkers" color="warn">
                                {{ markerType[historyMarker[item]]?.label | emptyValue | translate }}
                            </mat-chip>
                        </mat-chip-set>
                        <span class="cat-block cat-padding-left-md" color="warn"><b>></b></span>
                    </div>
                </ng-container>
            </div>

            <div class="cat-flex-1 cat-flex-grid-container cat-center">
                <ng-container *ngIf="!selectedItem?.id">
                    <div>{{ "No history" | translate }}</div>
                </ng-container>
                <ng-container *ngIf="selectedItem?.id">
                    <ng-container *ngTemplateOutlet="historyCard; context: { item: selectedItem }"></ng-container>
                </ng-container>
            </div>
        </div>

    </div>

    <div class="cat-flex-full-height cat-background-white cat-height-auto"></div>

    <div (wheel)="onScroll($event)" class="cat-padding-x-lg">
        <div #chartContainer></div>
    </div>
    <div (wheel)="onScroll($event)" class="cat-padding-x-lg">
        <div class="cat-button-row">
            <div class="cat-button-row-item">
                <button
                    [disabled]="selectedItem.id === timelineItems?.minKey"
                    (click)="goMin()"
                    mat-icon-button color="primary">
                    <mat-icon>skip_previous</mat-icon>
                </button>
            </div>
            <div class="cat-button-row-item">
                <button
                    [disabled]="selectedItem.id === timelineItems?.minKey"
                    (click)="goBack()"
                    mat-icon-button color="primary">
                    <mat-icon>arrow_left</mat-icon>
                </button>
            </div>
            <div class="cat-button-row-item cat-push-right">
                <button
                    [disabled]="selectedItem.id === timelineItems?.maxKey"
                    (click)="goNext()"
                    mat-icon-button color="primary">
                    <mat-icon>arrow_right</mat-icon>
                </button>
            </div>
            <div class="cat-button-row-item">
                <button
                    [disabled]="selectedItem.id === timelineItems?.maxKey"
                    (click)="goMax()"
                    mat-icon-button color="primary">
                    <mat-icon>skip_next</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>


<ng-template #historyCard let-item="item">

    <cat-msg
        *ngIf="mode === 'msg'"
        [account]="account"
        [setmsg]="item"
        [showQuickSelect]="false"
        [showMenu]="false"
        [isFirst]="true">
    </cat-msg>

    <ng-container *ngIf="mode === 'chat'">
        <mat-card *ngIf="item as chat" class="cat-max-width-400" color="light-shade">
            <mat-card-header class="cat-start">
                <div (click)="openMedia(chat)" mat-card-avatar class="cat-cursor-pointer cat-avatar" [class.cat-cursor-pointer]="(chat.avatar | hasThumb)">
                    @if (chat.avatar | hasThumb) {
                        <img [src]="chat.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
                    } @else {
                        <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                    }
                </div>
                <mat-card-title>
                    {{ uitext.name | translate }}: {{ chat.name }}
                </mat-card-title>
                <mat-card-subtitle *ngIf="chat.description">
                    {{ uitext.description | translate }}: {{ chat.description }}
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="cat-start">
                <p *ngIf="chat.link">{{ uitext.link | translate }}: {{ chat.link }}</p>
                <p *ngIf="chat.plainnames?.length">{{ uitext.plainnames | translate }}: {{ chat.plainnames.join(", ") }}</p>
                <p *ngIf="chat.altnames?.length">{{ uitext.altnames | translate }}: {{ chat.altnames.join(", ") }}</p>
            </mat-card-content>
        </mat-card>
    </ng-container>

    <ng-container *ngIf="mode === 'server'">
        <mat-card *ngIf="item as server" class="cat-max-width-400" color="light-shade">
            <mat-card-header class="cat-start">
                <div (click)="openMedia(server)" mat-card-avatar class="cat-cursor-pointer cat-avatar" [class.cat-cursor-pointer]="(server.avatar | hasThumb)">
                    @if (server.avatar | hasThumb) {
                        <img [src]="server.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
                    } @else {
                        <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                    }
                </div>
                <mat-card-title *ngIf="server.name">
                    {{ uitext.name | translate }}: {{ server.name }}
                </mat-card-title>
                <mat-card-subtitle *ngIf="server.description">
                    {{ uitext.description | translate }}: {{ server.description }}
                </mat-card-subtitle>
            </mat-card-header>
        </mat-card>
    </ng-container>

    <ng-container *ngIf="mode === 'topic'">
        <mat-card *ngIf="item as topic" class="cat-max-width-400" color="light-shade">
            <mat-card-header class="cat-start">
                <div (click)="openMedia(topic)" mat-card-avatar class="cat-cursor-pointer cat-avatar" [class.cat-cursor-pointer]="(topic.avatar | hasThumb)">
                    @if (topic.avatar | hasThumb) {
                        <img [src]="topic.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
                    } @else {
                        <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                    }
                </div>
                <mat-card-title *ngIf="topic.name">
                    {{ uitext.name | translate }}: {{ topic.name }}
                </mat-card-title>
                <mat-card-subtitle *ngIf="topic.description">
                    {{ uitext.description | translate }}: {{ topic.description }}
                </mat-card-subtitle>
            </mat-card-header>
        </mat-card>
    </ng-container>

    <ng-container *ngIf="mode === 'contact' || mode === 'account'">
        <mat-card *ngIf="item as contact" class="cat-max-width-400" color="light-shade">
            <mat-card-header class="cat-start">
                <div (click)="openMedia(contact)" mat-card-avatar class="cat-cursor-pointer cat-avatar" [class.cat-cursor-pointer]="(contact.avatar | hasThumb)">
                    @if (contact.avatar | hasThumb) {
                        <img [src]="contact.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
                    } @else {
                        <ng-container *ngTemplateOutlet="noAvatar"></ng-container>
                    }
                </div>
                <mat-card-title>
                    {{ uitext.username | translate }}: {{ contact.username }}
                </mat-card-title>
                <mat-card-subtitle *ngIf="contact.bio">
                    {{ uitext.bio | translate }}: {{ contact.bio }}
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="cat-start">
                <p *ngIf="contact.firstname">{{ uitext.firstname | translate }}: {{ contact.firstname }}</p>
                <p *ngIf="contact.lastname">{{ uitext.lastname | translate }}: {{ contact.lastname }}</p>
                <p *ngIf="contact.fullname">{{ uitext.fullname | translate }}: {{ contact.fullname }}</p>
            </mat-card-content>
        </mat-card>
    </ng-container>

</ng-template>



<ng-template #noAvatar>
    <img matTooltip="{{ uitext.noavatarfound | translate }}" matTooltipPosition="left" src="assets/img/cat-avatar.svg">
</ng-template>