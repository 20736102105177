<mat-form-field [color]="color" appearance="fill" class="cat-full-width cat-margin-bottom-lg">

    <mat-label>
        {{ data.label | translate | replaceTerm }}
    </mat-label>

    <ng-container *ngIf="focus else noFocus">
        <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="control" autocomplete="on" matInputAutofocus>
    </ng-container>
    <ng-template #noFocus>
        <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="control" autocomplete="on">
    </ng-template>

    <mat-error *ngIf="control.invalid && submitted">
        {{ control.errors | getValidatorMsg : data.validatorList }}
    </mat-error>

    <mat-hint *ngIf="!submitted && control.touched" class="cat-opacity-4">
        {{ control.errors | getValidatorMsg : data.validatorList }}
    </mat-hint>

    <mat-hint *ngIf="submitted" align="end" class="cat-opacity-2">
        {{ control?.value?.length }}
    </mat-hint>

    <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="cat-cursor-pointer cat-padding-sm" color="grey-dark">
        {{ hidePassword ? 'visibility_off' : 'visibility'}}
    </mat-icon>

    <mat-icon (click)="common.showDescription(data)" matSuffix matTooltip="help" matTooltipPosition="right" color="primary" class="cat-cursor-pointer">
        help
    </mat-icon>

</mat-form-field>