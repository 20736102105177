<h4 mat-dialog-title class="cat-no-margin" color="dark">
    <b>{{ "Change password" | translate | replaceTerm }}</b>
</h4>

<div mat-dialog-content class="cat-padding-x-xl">

    @if (changePasswordForm) {
        <form [formGroup]="changePasswordForm" class="cat-width-350 cat-margin-top-lg" ngNativeValidate>

            <password-field
                [data]="fieldData?.oldpassword"
                [submitted]="submitted"
                [control]="changePasswordForm.get('oldpassword')">
            </password-field>

            <password-field
                [data]="fieldData?.newpassword"
                [submitted]="submitted"
                [control]="changePasswordForm.get('newpassword')">
            </password-field>

            <password-field
                [data]="fieldData?.confirmnewpassword"
                [submitted]="submitted"
                [control]="changePasswordForm.get('confirmnewpassword')">
            </password-field>

        </form>
    }

</div>

<mat-dialog-actions align="end" class="cat-padding-bottom-lg cat-padding-x-lg">

    <button mat-stroked-button mat-dialog-close (click)="closeDialog()">
        {{ uitext.cancel | translate }}
    </button>

    <button
        type="submit"
        (click)="changePassword()"
        [disabled]="(isLoading$|async)"
        mat-flat-button color="primary" cdkFocusInitial>
        <mat-icon>lock</mat-icon>
        <span *ngIf="!(isLoading$|async)">
            {{ "Change password" | translate | replaceTerm }}
        </span>
        <mat-progress-bar *ngIf="(isLoading$|async)" mode="indeterminate" color="primary" class="cat-min-width-100"></mat-progress-bar>
    </button>

</mat-dialog-actions>