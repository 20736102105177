import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";
import { orderBy } from "lodash-es";

@Pipe({
    name: "sortBy",
    pure: true
})
/**
 * Sort array items, taking numeric strings into account
 *
 * @export
 * @class SortByPipe
 * @implements {PipeTransform}
 */
export class SortByPipe implements PipeTransform {

    constructor(
        private _logger: LoggerService
    ) {}

    transform(value: any[], order: "asc" | "desc" = "asc", column: any = ""): any[] {

        // Return if no sorting options are found
        if (!value  || value.length <= 1 || !order) {
            return value;
        }
        if (!column || column === "") {
            if (order === "asc") {
                return value.sort();
            } else {
                return value.sort().reverse();
            }
        }

        // Exception for numeric strings
        const identity = (column === "id")
            ? (item: any) => new Number(item[column])
            : [column];
        return orderBy(value, identity, [order]);
    }
}
