<h4 mat-dialog-title class="cat-no-margin" color="dark">
    <b>{{ "Reset password" | translate | replaceTerm }}</b>
</h4>

<div mat-dialog-content class="cat-padding-x-xl">

    <p>
        {{ uitext.resetpasswordexpiration | translate | replaceTerm }}
    </p>

    @if (resetPasswordForm) {
        <form [formGroup]="resetPasswordForm" class="cat-width-350 cat-no-margin" ngNativeValidate>
            <password-field
                [data]="fieldData?.temppassword"
                [submitted]="submitted"
                [focus]="true"
                [control]="resetPasswordForm.get('temppassword')">
            </password-field>
        </form>
    }

</div>

<mat-dialog-actions align="end" class="cat-padding-bottom-lg cat-padding-x-lg">

    <button mat-stroked-button mat-dialog-close (click)="closeDialog()" cdkFocusInitial>
        {{ uitext.cancel | translate }}
    </button>

    <button
        type="submit"
        (click)="resetPassword()"
        [disabled]="(isLoading$|async)"
        mat-flat-button color="primary">
        <mat-icon>lock</mat-icon>
        <span *ngIf="!(isLoading$|async)">
            {{ "Reset password" | translate | replaceTerm }}
        </span>
        <mat-progress-bar *ngIf="(isLoading$|async)" mode="indeterminate" color="primary" class="cat-min-width-100"></mat-progress-bar>
    </button>

</mat-dialog-actions>