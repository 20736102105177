import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { cat } from "@assets/proto/msgs";
import { SubscriptionManager } from "@shared/subscription-manager";


@Component({
    selector: "report-form",
    templateUrl: "./report-form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportFormComponent extends SubscriptionManager {

    // Inputs
    @Input() profile = cat.ProfileMsg.create();
    @Input() set formName(name: string) {
        if (name) {
            this._formName = name;
        }
    }
    @Input() set setTemplate(template: cat.ReportTemplateMsg) {
        if (template) {

            // Different template selected, clear storage
            if (this.template.id != template.id) {
                this._logger.debug("Different template detected, clear storage");
                localStorage.removeItem(this._formName);
            }

            this.unsubscribeAll();
            this.templateForm = new FormGroup({});

            template.inputfields.map((field) => {
                this._logger.debug("Field", field);
                if (field.required) {
                    this.templateForm.addControl(field.name, new FormControl('', Validators.required));
                } else {
                    this.templateForm.addControl(field.name, new FormControl(''));
                }
            })
            this.template = template;

            if (this.profile) {
                // Check if form already has value
                const storedValues = JSON.parse(localStorage.getItem(`profile-${this.profile.id}-textFields`)) || {};

                if (Object.keys(storedValues).length) {
                    this.templateForm.setValue(storedValues);
                }

                this.addSubscription(this.templateForm.valueChanges.subscribe(() => {
                    this.emitValidity.emit(this.templateForm.valid);
                    this.emitChange.emit(this.templateForm);
                }));
            }
            if (!template.inputfields.length) {
                this.emitValidity.emit(true);
            } else {
                this.emitValidity.emit(this.templateForm.valid);
            }
        }
    }

    @Output() emitChange: any = new EventEmitter<FormGroup>();
    @Output() emitValidity: any = new EventEmitter<boolean>();


    public template = cat.ReportTemplateMsg.create();
    public templateForm = new FormGroup({});
    public textType = cat.TextFieldType;

    private _formName = "";

    constructor(
        public common: CommonService,
        private _logger: LoggerService
    ) {
        super();
    }
}
