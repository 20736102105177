import { Store } from "@ngxs/store";
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, Input, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { cat } from "@assets/proto/msgs";
import { userGetCurrentUserDevices } from "@assets/proto/services";
import { DeviceAccountsPopoverComponent } from "@components/dialog-device-accounts/popover-device-accounts.component";
import { protosui } from "@definitions/definitions";
import { IState } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";
import { WsService } from "@services/ws/ws.service";
import { clearList, updateDeviceSyncProgress } from "@store/actions";
import { getList } from "@store/store";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { RegisterCaptureModal } from "@modals/register-capture/register-capture.modal";
import { SubscriptionManager } from "@shared/subscription-manager";
import { appRouteNames } from "@app/app.routes.names";
@Component({
    selector: "widget-device",
    templateUrl: "./widget-device.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetDeviceComponent extends SubscriptionManager implements OnInit {

    // Force component to destroy and clean up subsciptions
    @HostListener("unloaded")
    ngOnDestroy() {
        // Ensure all subscriptions are removed (synchronous and not using callbacks)
        this.unsubscribeAll();
    }

    dashboadAngle$: Observable<"profile"|"device"|"stats"> = inject(Store).select((state: IState) => state.cat.DashboardAngle);
    devices$: Observable<cat.DeviceMsg[]> = inject(Store).select((state: IState) => getList(state.cat.userGetCurrentUserDevices));

    @Input() set reload(reload: string) {
        if (reload) {
            this.userGetCurrentUserDevices();
        }
    }

    // Public properties
    public permissionEnum = cat.Permission;
    public columns: number = 1;
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        public tl: TlService,
        private _store: Store,
        private _activatedRoute: ActivatedRoute,
        private _cdr: ChangeDetectorRef,
        private _dialogRef: MatDialog,
        private _router: Router,
        private _logger: LoggerService,
        private _ws: WsService
    ) {
        super();
    }

    /**
     * Create form data and fetch the annotations.
     */
    public async ngOnInit() {
        this.addSubscriptions([
            this._ws.notifications
                .get(cat.NotificationType.NOTIFICATION_DEVICE_STATUS)
                .subscribe((notification: cat.NotificationMsg) => this.handleDeviceStatusUpdate(notification)),

            this._ws.notifications
                .get(cat.NotificationType.NOTIFICATION_DEVICE)
                .subscribe((notification: cat.NotificationMsg) => this.handleDeviceUpdate(notification))
        ]);
        await this.userGetCurrentUserDevices();
    }

    /**
     * Get the devices belonging to a user.
     *
     * @memberof WidgetDeviceComponent
     */
    public async userGetCurrentUserDevices() {
        try {
            await this._ws.sendRequest(userGetCurrentUserDevices);
        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(new Error(error));
        }
    }

    /**
     * Device status update, show progress.
     *
     * @param {cat.NotificationMsg} notification
     * @memberof WidgetDeviceComponent
     */
    async handleDeviceStatusUpdate(notification: cat.NotificationMsg) {
        const deviceId = notification.references[cat.ReferenceType.REFERENCE_DEVICE_ID];
        const device: cat.DeviceMsg = cat.DeviceMsg.create({
            id: deviceId,
            receiverbufferstatus: notification.receiverbufferstatus
        });
        this._store.dispatch(new updateDeviceSyncProgress(device, userGetCurrentUserDevices.methodName));
    }

    /**
     * New device found, fetch the devices.
     *
     * @param {cat.NotificationMsg} notification
     * @memberof WidgetDeviceComponent
     */
    async handleDeviceUpdate(notification: cat.NotificationMsg) {
        const deviceIds = Array.from(this._store.selectSnapshot((state: IState) => state.cat.userGetCurrentUserDevices).list.keys());
        const newDeviceId = notification.references[cat.ReferenceType.REFERENCE_DEVICE_ID];

        if (!deviceIds.includes(newDeviceId)) {
            this.userGetCurrentUserDevices();
        }
    }

     /**
     * Navigate to a device dashboard.
     *
     * @param {cat.DeviceMsg} device The device to go to.
     * @memberof WidgetDeviceComponent
     */
      async goToDevice(device: cat.DeviceMsg) {
        try {

            if (device && device.id) {
                this._router.navigate([`${this._router.url}/device/${device.id}`]);
            } else {
                this.common.createSnackbar(protosui.messages.uitext.nodevice);
            }

        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(new Error(error));
        }
    }

    /**
     * Show the accounts, found on the device.
     *
     * @param {cat.AccountMsg[]} accounts The accounts.
     * @returns
     * @memberof WidgetDeviceComponent
     */
     public async showAccounts(accounts: cat.AccountMsg[]) {
        try {

            if (accounts?.length) {

                const dialogRef = this._dialogRef.open(DeviceAccountsPopoverComponent, {
                    width: "400px",
                    height: "400px",
                    data: accounts
                });
                dialogRef.afterClosed().subscribe(result => {
                    this._logger.debug(`Dialog result: ${result}`);
                });

            } else {
                await this.common.createSnackbar(protosui.messages.uitext.nodeviceaccounts);
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Clear QR code from store.
     */
    public async clearQrApk() {
        this._store.dispatch(new clearList('CaptureQRApk'));
    }

    /**
     * Register a new device.
     *
     * @memberof WidgetDeviceComponent
     */
    public async userRegisterDevice() {
        try {
            await this.clearQrApk();
            await this._router.navigate([`${appRouteNames.REGISTER_DEVICE}`], { relativeTo: this._activatedRoute });
        } catch (error) {
            this.common.createSnackbar(error);
            this._logger.error(error);
        } finally {
            this.common.detectChange(this._cdr);
        }
    }

    /**
     * Open the dialog with the register details.
     *
     * @param {cat.DeviceMsg} device The new device.
     * @memberof WidgetDeviceComponent
     */
     openRegisterDialog(device: cat.DeviceMsg) {
        const dialogRef = this._dialogRef.open(RegisterCaptureModal, {
            width: "600px",
            data: {
                device: device
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this._logger.debug(`Dialog result: ${result}`);
        });
    }
}
