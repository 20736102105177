import { Pipe, PipeTransform } from "@angular/core";
import { cat } from "@assets/proto/msgs";

@Pipe({
  name: "accountHandle",
  pure: true
})
/**
* Get the contact handle, depending on the provided information
* @param {cat.AccountMsg} sender The account of the sender.
* @returns {string} The best available contact handle
*/
export class AccountHandlePipe implements PipeTransform {
    transform(account: cat.AccountMsg, singleWord?: boolean): string {
        return accountHandle(account, singleWord);
    }
}

export const accountHandle = (account: cat.IAccountMsg, singleWord?: boolean) => {
    let result = "";
    if (account) {
        if (account.alias) {
            result = account.alias;
        }
        if (!result.length) {
            if (account.fullname && account.fullname.length) {
                result = account.fullname;
            }
        }
        if (!result.length) {
            if (account.firstname && account.firstname.length) {
                result = account.firstname;
            }
            if (!singleWord) {
                if (account.lastname && account.lastname.length) {
                    if (!result.length) {
                        result = account.lastname;
                    } else {
                        result += ` ${account.lastname}`;
                    }
                }
            }
        }
        if (!result.length) {
            if (account.username && account.username.length) {
                result = account.username;
                // Append server nicknames if applicable.
                if (account.servernicknames.length) {
                    result += ` (${account.servernicknames.join()})`;
                }
            }
        }
        if (!result.length) {
            if (account.phonenumber && account.phonenumber.length) {
                result = account.phonenumber;
            }
        }
        if (!result.length) {
            if (account.email && account.email.length) {
                result = account.email;
            }
        }
        if (!result.length) {
            if (account.externid && account.externid.length) {
                result = account.externid;
            }
        }
    }
    return result.trimStart();
}