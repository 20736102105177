<h4 mat-dialog-title class="cat-no-margin" color="dark">
    <b>{{ data.field.label || data.field.name }}</b>
</h4>

<div mat-dialog-content class="cat-background-light">
    <div class="cat-margin-top-lg">
        <textarea-field
            [data]="getData()"
            [focus]="true"
            [maxRows]="20"
            [control]="data.control">
        </textarea-field>
    </div>
</div>

<mat-dialog-actions align="end" class="cat-padding-y-lg cat-padding-right-lg">
    <button mat-stroked-button mat-dialog-close cdkFocusInitial color="primary">{{ uitext.close | translate }}</button>
</mat-dialog-actions>