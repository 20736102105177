// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TlService } from "src/services/tl/tl.service";
import { cat } from "@assets/proto/msgs";
import { protosui } from "src/definitions/definitions";
import { TranslateService } from "@ngx-translate/core";
import { accountHandle } from "../accounthandle/account-handle.pipe";
import { humanizeSeconds } from "../humanizeseconds/humanizeseconds.pipe";

/**
 * Get the service message body
 *
 * @export
 * @class GetMessageServiceBodyPipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: "getMsgServiceBody",
    pure: true
})

export class GetMessageServiceBodyPipe implements PipeTransform {

    constructor(
        private _tl: TlService,
        private _translate: TranslateService,
    ) {}

    transform(msg: cat.IMessageMsg): string {
        const result: string[] = [];

        if (msg && msg.type) {
            switch (msg.type) {
                // Service message with format "val[0]"
                case cat.MessageType.MESSAGE_CALL_ENDED:
                case cat.MessageType.MESSAGE_VIDEO_CALL_ENDED:
                case cat.MessageType.MESSAGE_REQUEST_ACCEPTED:
                case cat.MessageType.MESSAGE_CHATCREATE:
                case cat.MessageType.MESSAGE_CHAT_MIGRATED:
                case cat.MessageType.MESSAGE_GROUPCREATE:
                case cat.MessageType.MESSAGE_HISTORYCLEAR:
                case cat.MessageType.MESSAGE_TOPIC:
                case cat.MessageType.MESSAGE_ENDTOEND_ENCRYPTED:
                case cat.MessageType.MESSAGE_SECURITYCODE_CHANGED: {
                    result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                    break;
                }
                // Service message with format "sender val[0]"
                case cat.MessageType.MESSAGE_CALL_JOINED:
                case cat.MessageType.MESSAGE_CALL_LEFT:
                case cat.MessageType.MESSAGE_CALL_STARTED:
                case cat.MessageType.MESSAGE_VIDEO_CALL_STARTED:
                case cat.MessageType.MESSAGE_MISSED_VIDEO_CALL:
                case cat.MessageType.MESSAGE_CHANGEDESCRIPTION:
                case cat.MessageType.MESSAGE_MISSED_CALL:
                case cat.MessageType.MESSAGE_REMOVEIMAGE:
                case cat.MessageType.MESSAGE_SCREENSHOT:
                case cat.MessageType.MESSAGE_LOCATION_REQUEST:
                case cat.MessageType.MESSAGE_JOINBYLINK:
                case cat.MessageType.MESSAGE_GROUPINFO_ADMIN:
                case cat.MessageType.MESSAGE_GROUPINFO_ALL:
                case cat.MessageType.MESSAGE_GROUPSEND_ADMIN:
                case cat.MessageType.MESSAGE_GROUPSEND_ALL:
                case cat.MessageType.MESSAGE_CHANGEPHONENUMBER:
                case cat.MessageType.MESSAGE_BLOCKEDCONTACT:
                case cat.MessageType.MESSAGE_UNBLOCKEDCONTACT:
                case cat.MessageType.MESSAGE_REMOVENICKNAME_SELF: {
                    result.push(accountHandle(msg.sender));
                    result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                    break;
                }
                // Service message with format "sender (attributes && attributes[0] != '0' ? val[0] humanizeSeconds(attributes[0]) : val[1])"
                case cat.MessageType.MESSAGE_TTL: {
                    result.push(accountHandle(msg.sender));
                    if (msg.attributes?.length && msg.attributes[0] !== "0") {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                        result.push(humanizeSeconds(Number(msg.attributes[0]), this._translate));
                    } else if (msg.attributes?.length && msg.attributes[0] === "0") {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[1]));
                    } else {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[2]));
                    }
                    break;
                }
                case cat.MessageType.MESSAGE_ISADMIN:
                case cat.MessageType.MESSAGE_NOTADMIN: {
                    if (msg.attributes?.length) {
                        // E.g. Messenger
                        result.push(msg.attributes[0]);
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                    } else if (msg.sender) {
                        // E.g. WhatsApp
                        result.push(accountHandle(msg.sender));
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                    } else {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                    }
                    break;
                }
                // Service message with format "sender val[0] (attributes ? val[1] attributes[0] : )"
                case cat.MessageType.MESSAGE_CHANGEIMAGE:
                case cat.MessageType.MESSAGE_CHANGETHEME:
                case cat.MessageType.MESSAGE_CHANGEEMOJI:
                case cat.MessageType.MESSAGE_CHANGETITLE:
                case cat.MessageType.MESSAGE_PIN: {
                    if (msg.sender) {
                        result.push(accountHandle(msg.sender));
                    }
                    result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                    if (msg.attributes?.length) {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[1]));
                        result.push(msg.attributes[0]);
                    }
                    break;
                }
                // Service message with format "val[0] (attributes ? datetimeFromTimestamp(attributes[0]) : )"
                case cat.MessageType.MESSAGE_CALL_SCHEDULED: {
                    result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                    if (msg.attributes?.length) {
                        result.push(new DatePipe(this._tl.locale).transform(msg.attributes[0], "d MMMM y, HH:mm"));
                    }
                    break;
                }
                // Service message with format "sender val[0] (attributes ? val[1] humanizeSeconds(attributes[0]) : )"
                case cat.MessageType.MESSAGE_CALL:
                case cat.MessageType.MESSAGE_VIDEO_CALL: {
                    result.push(accountHandle(msg.sender));
                    result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                    if (msg.attributes?.length) {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[1]));
                        result.push(humanizeSeconds(Number(msg.attributes[0]), this._translate));
                    }
                    break;
                }
                // Service message with format "sender (attributes ? val[0] attributes : val[1].replace(sender.externid)"
                case cat.MessageType.MESSAGE_ADDMEMBERS:
                case cat.MessageType.MESSAGE_DELETEMEMBER: {
                    result.push(accountHandle(msg.sender));
                    if (msg.attributes?.length) {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                        for (const attr of msg.attributes) {
                            result.push(attr);
                        }
                    } else if (msg.sender) {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[1].replace("%s", msg.sender.externid)));
                    }
                    break;
                }
                case cat.MessageType.MESSAGE_REMOVENICKNAME: {
                    if (msg.sender && !msg.attributes?.length) {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0].replace("%s", msg.sender.externid)));
                    } else if (msg.attributes?.length) {
                        result.push(accountHandle(msg.sender));
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[1]));
                        for (const attr of msg.attributes) {
                            result.push(attr);
                        }
                    }
                    break;
                }
                // Service message with format "sender val[0].replace(sender.externid)"
                case cat.MessageType.MESSAGE_REMOVEDMEMBER: {
                    result.push(accountHandle(msg.sender));
                    if (msg.attributes?.length) {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                        for (const attr of msg.attributes) {
                            result.push(attr);
                        }
                    } else if (msg.sender) {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[1].replace("%s", msg.sender.externid)));
                    }
                    break;
                }
                // Service message with format "sender val[0] (attributes ? attributes[0] : )"
                case cat.MessageType.MESSAGE_CANCEL_INVITELINK:
                case cat.MessageType.MESSAGE_CREATE_INVITELINK:
                case cat.MessageType.MESSAGE_CHANGENICKNAME_SELF:
                case cat.MessageType.MESSAGE_TOPIC_CREATE:
                case cat.MessageType.MESSAGE_TOPIC_EDIT:
                case cat.MessageType.MESSAGE_THREAD_CREATE: {
                    result.push(accountHandle(msg.sender));
                    result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                    if (msg.attributes?.length) {
                        result.push(msg.attributes[0]);
                    }
                    break;
                }
                // Service message with format "sender (attributes.length == 1 ? val[0] attributes[0] : val[1].replace(attributes[0].replace[attributes[1])"
                case cat.MessageType.MESSAGE_CHANGENICKNAME: {
                    result.push(accountHandle(msg.sender));
                    if (msg.attributes?.length) {
                        if (msg.attributes.length === 1) {
                            result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                            result.push(msg.attributes[0]);
                        } else if (msg.attributes.length === 2) {
                            result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[1])
                                .replace("%s1", msg.attributes[0])
                                .replace("%s2", msg.attributes[1])
                            );
                        }
                    }
                    break;
                }
                case cat.MessageType.MESSAGE_WALLPAPER_CHAT: {
                    result.push(accountHandle(msg.sender));
                    if (msg.attributes.length) {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[1])
                            .replace("%s", msg.attributes[0]));
                    } else {
                        result.push(this._translate.instant(protosui.serviceMessageMap.get(msg.type)[0]));
                    }
                    break;
                }
                // Service message with format "body"
                case cat.MessageType.MESSAGE_SERVICE_MESSAGE: {
                    result.push(msg.body);
                    break;
                }
            }
        }
        return result.join(" ");
    }
}