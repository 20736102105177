// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

// NOTE: THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT

export const messageDefinitions = {
    WebsocketMsg: "WebsocketMsg",
    UserSessionMsg: "UserSessionMsg",
    VoidMsg: "VoidMsg",
    FilterMsg: "FilterMsg",
    QueryMsg: "QueryMsg",
    FilterCountMsg: "FilterCountMsg",
    EulaMsg: "EulaMsg",
    ServiceStatusMsg: "ServiceStatusMsg",
    ServiceMsg: "ServiceMsg",
    ServiceUpdateMsg: "ServiceUpdateMsg",
    ServiceStatisticsMsg: "ServiceStatisticsMsg",
    ServiceConfigurationMsg: "ServiceConfigurationMsg",
    LogConfigurationMsg: "LogConfigurationMsg",
    DatabaseConfigurationMsg: "DatabaseConfigurationMsg",
    FileServerConfigurationMsg: "FileServerConfigurationMsg",
    WebServiceConfigurationMsg: "WebServiceConfigurationMsg",
    DockerConfigurationMsg: "DockerConfigurationMsg",
    RetentionConfigurationMsg: "RetentionConfigurationMsg",
    ReceiverConfigurationMsg: "ReceiverConfigurationMsg",
    ConfigurationMsg: "ConfigurationMsg",
    SettingMsg: "SettingMsg",
    PasswordPolicyMsg: "PasswordPolicyMsg",
    CertificateMsg: "CertificateMsg",
    UserMsg: "UserMsg",
    RoleMsg: "RoleMsg",
    PermissionMsg: "PermissionMsg",
    AuthCallMsg: "AuthCallMsg",
    AuthCallListMsg: "AuthCallListMsg",
    ThemeMsg: "ThemeMsg",
    CaseMsg: "CaseMsg",
    ProfileMsg: "ProfileMsg",
    DeviceMsg: "DeviceMsg",
    AppTypeMsg: "AppTypeMsg",
    DateMsg: "DateMsg",
    MemberCountMsg: "MemberCountMsg",
    RawMsg: "RawMsg",
    ReactionMsg: "ReactionMsg",
    LabelMsg: "LabelMsg",
    TriggerActionMsg: "TriggerActionMsg",
    TriggerMsg: "TriggerMsg",
    AnnotationMsg: "AnnotationMsg",
    MediaMsg: "MediaMsg",
    AuditLogMsg: "AuditLogMsg",
    AuditLogCallMsg: "AuditLogCallMsg",
    NotificationMsg: "NotificationMsg",
    NotificationBundleMsg: "NotificationBundleMsg",
    MemberStatusMsg: "MemberStatusMsg",
    ReportTemplateMsg: "ReportTemplateMsg",
    ReportCountsMsg: "ReportCountsMsg",
    ReportMsg: "ReportMsg",
    ReportStatusMsg: "ReportStatusMsg",
    ReportTextFieldMsg: "ReportTextFieldMsg",
    LicenseMsg: "LicenseMsg",
    SearchMsg: "SearchMsg",
    TsaMsg: "TsaMsg",
    AppMsg: "AppMsg",
    AccountPropertyMsg: "AccountPropertyMsg",
    AccountMsg: "AccountMsg",
    ServerMsg: "ServerMsg",
    ConversationMsg: "ConversationMsg",
    TopicMsg: "TopicMsg",
    MessageMsg: "MessageMsg",
    ContentMsg: "ContentMsg",
    AttachmentMsg: "AttachmentMsg",
    MediaFileMsg: "MediaFileMsg",
    ReceiverBufferStatusMsg: "ReceiverBufferStatusMsg",
    ReceiverMsg: "ReceiverMsg",
    ReceiverItemMsg: "ReceiverItemMsg",
    ReceiverFileMsg: "ReceiverFileMsg",
    ReceiverAccountMsg: "ReceiverAccountMsg",
    ReceiverAccountListMsg: "ReceiverAccountListMsg",
    DevicePropertiesMsg: "DevicePropertiesMsg",
    DeviceNotificationMsg: "DeviceNotificationMsg",
    MediaLinkMsg: "MediaLinkMsg",
    PackageMsg: "PackageMsg",
    PackageVersionMsg: "PackageVersionMsg",
    PackageShadowMsg: "PackageShadowMsg",
    PackageDatabaseMsg: "PackageDatabaseMsg",
    PackageTriggerMsg: "PackageTriggerMsg",
    PackageWatchMsg: "PackageWatchMsg",
    PackageDownloadMsg: "PackageDownloadMsg",
    PackageMemoryMsg: "PackageMemoryMsg",
    PackageHookMsg: "PackageHookMsg",
    PackagePatchMsg: "PackagePatchMsg",
    TerminologyMsg: "TerminologyMsg",
    ProcessorStatisticsMsg: "ProcessorStatisticsMsg",
    ProcessorStatRawMsg: "ProcessorStatRawMsg",
    SeriesMsg: "SeriesMsg",
    StatisticsSeriesMsg: "StatisticsSeriesMsg",
    FailureMsg: "FailureMsg",
    BackUpMsg: "BackUpMsg",
    APKMsg: "APKMsg",
    SystemEventMsg: "SystemEventMsg",
    DomainMsg: "DomainMsg",
    PatchClientConfigurationMsg: "PatchClientConfigurationMsg",
    CallStatus: "CallStatus",
    ErrorType: "ErrorType",
    SortDirection: "SortDirection",
    FilterOperator: "FilterOperator",
    ServiceType: "ServiceType",
    LogLevel: "LogLevel",
    ServiceUpdateType: "ServiceUpdateType",
    StatusType: "StatusType",
    ConfigurationType: "ConfigurationType",
    ReceiverMode: "ReceiverMode",
    Settings: "Settings",
    CertificateType: "CertificateType",
    AppearanceMode: "AppearanceMode",
    Popover: "Popover",
    ProfileFilterType: "ProfileFilterType",
    VisibilityType: "VisibilityType",
    DeviceType: "DeviceType",
    DeviceArchitecture: "DeviceArchitecture",
    AppType: "AppType",
    AppFeatures: "AppFeatures",
    AccountType: "AccountType",
    ServerState: "ServerState",
    ServerType: "ServerType",
    ConversationType: "ConversationType",
    ConversationState: "ConversationState",
    TopicState: "TopicState",
    DirectionType: "DirectionType",
    MessageStatus: "MessageStatus",
    MessageActorType: "MessageActorType",
    MessageType: "MessageType",
    MessageLinkType: "MessageLinkType",
    AttachmentType: "AttachmentType",
    AttachmentStatus: "AttachmentStatus",
    LabelType: "LabelType",
    TriggerActionType: "TriggerActionType",
    TriggerType: "TriggerType",
    MediaType: "MediaType",
    AuditLogType: "AuditLogType",
    AuditLogSourceType: "AuditLogSourceType",
    AuditLogResult: "AuditLogResult",
    AuditContextType: "AuditContextType",
    AuditValueType: "AuditValueType",
    ReferenceType: "ReferenceType",
    NotificationType: "NotificationType",
    NotificationPriority: "NotificationPriority",
    AccountStatus: "AccountStatus",
    MemberStatus: "MemberStatus",
    ReportType: "ReportType",
    TemplateFlag: "TemplateFlag",
    TemplateError: "TemplateError",
    TemplateFileType: "TemplateFileType",
    ReportStatusType: "ReportStatusType",
    ReportFileType: "ReportFileType",
    TextFieldType: "TextFieldType",
    AccountPropertyType: "AccountPropertyType",
    HistoryMarker: "HistoryMarker",
    ContentType: "ContentType",
    ThumbnailStatus: "ThumbnailStatus",
    ReceiverMediaType: "ReceiverMediaType",
    ReceiverMediaLinkStatus: "ReceiverMediaLinkStatus",
    MimeType: "MimeType",
    ReceiverCallStatus: "ReceiverCallStatus",
    ReceiverCallName: "ReceiverCallName",
    ReceiverItemType: "ReceiverItemType",
    MediaLinkType: "MediaLinkType",
    Terminology: "Terminology",
    SupportedLanguages: "SupportedLanguages",
    APKType: "APKType",
    APKFileType: "APKFileType",
    APKPatchStatus: "APKPatchStatus",
    SystemEventType: "SystemEventType"
};
