import { Component, OnInit, ChangeDetectionStrategy, Inject } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { protosui } from "@definitions/definitions";
import { IJumpToDateData } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import dayjs from "dayjs/esm";
@Component({
    selector: "jump-to-date-popover",
    templateUrl: "./popover-jump-to-date.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JumpToDatePopoverComponent implements OnInit {

    public startTimestamp = 0;
    public endTimestamp = 0;
    public maxDate = "";
    public minDate = "";

    constructor(
        public common: CommonService,
        private _logger: LoggerService,
        private _dialogRef: MatDialogRef<JumpToDatePopoverComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IJumpToDateData
    ) {
        if (!this.data.startTimestamp || !this.data.endTimestamp) {
            this.common.createSnackbar(protosui.messages.uitext.prerequisites);
            throw new Error(protosui.messages.uitext.prerequisites);
        }
    }

    /**
    * Fetch the params everytime the popup opens
    */
    ngOnInit() {
        this._logger.debug("Data", this.data);
        this.startTimestamp = this.data.startTimestamp;
        this.endTimestamp = this.data.endTimestamp;
        this.minDate = dayjs(Number(this.startTimestamp)).toISOString();
        this.maxDate = dayjs(Number(this.endTimestamp)).toISOString();
    }

    public jumpTo(day: 'today') {

        let startofDay;

        switch (day) {
            case "today": {
                startofDay = dayjs(Number(this.endTimestamp)).startOf("day").valueOf();
                break;
            }
        }

        this.dismiss(startofDay);
    }


    public dateChange(event: MatDatepickerInputEvent<Date>) {
        const unixTime = dayjs(event.value).startOf("day").valueOf();
        this.dismiss(unixTime);
    }

    /**
     * Dismiss the dialog.
     *
     * @memberof PopoverFilterMenuComponent
     */
    public dismiss(jumpTo: number) {
        this._dialogRef.close(jumpTo);
    }
}
