<div class="cat-chat-item cat-padding-x-lg cat-cursor-pointer" [class.cat-selected]="selected" [class.cat-disabled]="disabled">

    <div class="cat-avatar cat-md" [class.cat-story]="(chat.type === conversationType.CONVERSATION_STORY)">
        <img
            *ngIf="(chat.avatar | hasThumb) else noAvatar"
            [src]="chat.avatar.files[0] | catsrc : 'thumb' : mediaType.MEDIA_ACCOUNTAVATARS : account.id">
        <ng-template #noAvatar>
            <img matTooltip="{{ uitext.noavatarfound | translate }}" matTooltipPosition="left" src="assets/img/cat-avatar.svg">
        </ng-template>
    </div>

    <div class="cat-flex-fill-remaining cat-margin-x-lg cat-nowrap cat-nooverflow-ellipsis">

        <span class="cat-no-margin cat-nooverflow-ellipsis" color="grey-dark">
            <b [title]="chat.name">{{ chat.name | emptyValue }}</b>
        </span>

        <p class="cat-no-margin cat-margin-top-md cat-nooverflow-ellipsis" color="dark">
            <ng-container *ngIf="chat.messages && chat.messages.length else noMessage">

                @switch (chat.messages[0]?.type) {
                    @case (messageType.MESSAGE_MESSAGE) {
                        <ng-container *ngIf="!(chat.type | isOneOnOneChat) && chat.messages[0].sender">
                            {{ (chat.messages[0].direction | isIncoming) ? (chat.messages[0].sender | accountHandle : true) : uitext.you | translate }}:
                        </ng-container>
                        {{ chat.messages[0].body }}
                        <ng-container *ngIf="(!chat.messages[0]?.body?.length || chat.messages[0]?.attachments?.length)">
                            {{ (chat.messages[0] | getMsgAttachmentType) | translate }}
                            {{ uitext.message | translate }}
                        </ng-container>
                        <ng-container *ngIf="(!chat.messages[0]?.body?.length && !chat.messages[0]?.attachments?.length) && chat.description?.length">
                            {{ chat.description }}
                        </ng-container>
                    }
                    @case (messageType.MESSAGE_POLL) {
                        {{ uitext.poll | translate }}
                        {{ chat.messages[0].body | emptyValue }}
                    }
                    @case (messageType.MESSAGE_STORY_SHARE) {
                        {{ (chat.messages[0].direction | isIncoming) ? chat.name : uitext.you | translate }}
                        {{ uitext.storyshare | translate }}
                    }
                    @case (messageType.MESSAGE_POST_SHARE) {
                        {{ (chat.messages[0].direction | isIncoming) ? chat.name : uitext.you | translate }}
                        {{ uitext.postshare | translate }}
                    }
                    @default {
                        {{ (chat.messages[0] | getMsgServiceBody | translate | replaceTerm) | emptyValue }}
                    }
                }

            </ng-container>
            <ng-template #noMessage>{{ "" | emptyValue }}</ng-template>
        </p>
    </div>


    <div class="cat-nowrap cat-nooverflow-ellipsis cat-end cat-max-width-md cat-flex-column">
        <div class="cat-no-margin cat-nooverflow-ellipsis cat-button-wrapper">

            <mat-icon
                *ngIf="chat.state === conversationState.CONVERSATIONSTATE_CLOSED"
                matTooltip="{{ 'Closed / ended' | translate }}" matTooltipPosition="above" matTooltipShowDelay="500"
                class="cat-font-lg cat-margin-left-sm"
                color="warn">
                close
            </mat-icon>

            <mat-icon
                *ngIf="chat.state === conversationState.CONVERSATIONSTATE_PENDING"
                matTooltip="{{ 'Pending' | translate }}" matTooltipPosition="above" matTooltipShowDelay="500"
                class="cat-font-lg cat-margin-left-sm"
                color="warn">
                hourglass_empty
            </mat-icon>

            <mat-icon
                *ngIf="chat.state === conversationState.CONVERSATIONSTATE_LEFT"
                matTooltip="{{ 'Conversation you left, or deleted' | translate }}" matTooltipPosition="above" matTooltipShowDelay="500"
                class="cat-font-lg cat-margin-left-sm"
                color="warn">
                exit_to_app
            </mat-icon>

            <mat-icon
                *ngIf="chat.visibility === conversationVisibility.VISIBILITY_HIDDEN"
                matTooltip="{{ 'Hidden' | translate }}" matTooltipPosition="above" matTooltipShowDelay="500"
                class="cat-font-lg cat-margin-left-sm"
                color="warn">
                visibility_off
            </mat-icon>

            <div class="cat-margin-left-sm"></div>

            <ng-container *ngIf="chat.topiccount else notExpandable">
                <span matTooltip="{{ 'Expand' | translate }} {{ chat.topiccount }} {{ 'topic(s)' | translate }}" matTooltipPosition="above" class="cat-button-wrapper">
                    <span color="primary" class="cat-margin-right-sm">
                        <b>{{ chat.topiccount }}</b>
                    </span>
                    <mat-icon color="primary">
                        {{ conversationTypeEnum[conversationType[chat.type]]?.icon?.matname }}
                    </mat-icon>
                </span>
            </ng-container>

            <ng-template #notExpandable>

                <mat-icon *ngIf="!selected || unreadCount < 1" color="dark" matTooltip="{{ conversationTypeEnum[conversationType[chat.type]]?.label | translate }}" matTooltipPosition="above">
                    {{ conversationTypeEnum[conversationType[chat.type]]?.icon?.matname }}
                </mat-icon>
                <div
                    *ngIf="selected && unreadCount > 0"
                    class="cat-badge-container"
                    matBadge="{{ unreadCount }}">
                </div>
            </ng-template>

        </div>
        <p class="cat-no-margin cat-nooverflow-ellipsis cat-margin-top-sm" color="dark">
            <ng-container *ngIf="chat.messages && chat.messages.length && (chat.messages[0].received > 0); else noMessage">
                {{ chat.messages[0].received | humanizetime }}
            </ng-container>
            <ng-template #noMessage>
                {{ (chat.lastmodified | humanizetime) | emptyValue }}
            </ng-template>
        </p>
    </div>

    <button
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="chatMenu"
        mat-mini-fab color="transparent" class="cat-no-shadow cat-margin-left-md">
        <mat-icon color="primary" class="cat-on-top">more_vert</mat-icon>
    </button>
</div>

<mat-menu #chatMenu="matMenu">
    <chat-item-menu [chat]="chat" [profileId]="profileId" [account]="account" (emitRefreshChats)="emitConversationVisibility.emit()"></chat-item-menu>
</mat-menu>