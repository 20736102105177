// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { Injectable, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngxs/store";
import { LoggerService } from "@services/logger/logger.service";
import { cat } from "@assets/proto/msgs";
import { FormGroup, FormControl, UntypedFormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { protosui } from "@definitions/definitions";
import { userGetProfileDevices, IServiceDescriptor } from "@assets/proto/services";
import { FileStreamerService } from "@services/filestreamer/filestreamer.service";
import { ViewRef } from "@angular/core";
import { CapitalizeFirstCharPipe } from "@pipes/capitalizefirstchar/capitalize-first-char.pipe";
import { addMe, appendToInfiteArray, prependToInfiteArray, removeDateFromInfiniteArray, updateDeviceSyncProgress, updateMessage } from "@store/actions";
import { ReplaceTermPipe } from "@pipes/replaceterm/replaceterm.pipe";
import { IsNewDayPipe } from "@pipes/isNewDay/is-new-day.pipe";
import { GenericDialog } from "@components/dialog-generic/generic-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { cloneDeep } from "lodash-es";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import * as model from "@shared/app-models";

@Injectable({
    providedIn: "root"
})
export class CommonService {

    public appColors: Map<string, string> = new Map([
        ["telegram", "#229ED9"],
        ["telegram web", "#0088CC"],
        ["plus messenger", "#009988"],
        ["instagram", "#FD1D1D"],
        ["snapchat", "#FFFC00"],
        ["messenger", "#006AFF"],
        ["whatsapp", "#25d366"],
        ["titanium backup", "#FABC13"],
        ["discord", "#5662F6"],
        ["signal", "#3A76F0"],
        ["teleguard", "#B4336B"],
        ["tiktok", "#63666A" ]
    ]);

    constructor(
        private _dialogRef: MatDialog,
        private _router: Router,
        private _logger: LoggerService,
        private _formBuilder: UntypedFormBuilder,
        private _translate: TranslateService,
        private _snackBar: MatSnackBar,
        private _store: Store) {}

    /**
    * @example
    * createSnackbar("New toast", "bottom", 3000)
    * Create a toast error alert and present it
    * @param {string} msg The message for the toast
    * @param {string} vposition The vertical position of the toast on the screen
    * @param {number} duration How many milliseconds to wait before hiding the toast
    * @param {string} cssclas Additional css class to style the toast
    */
    public async createSnackbar(msg: string, vposition?: "bottom" | "top", duration?: number, cssclass?: string, formfields?: string[]) {
        try {

            if (!msg || typeof msg !== "string") {
                this._logger.error("Invalid toast msg");
                return;
            }

            const message = (formfields?.length)
                ?   new ReplaceTermPipe(this._store).transform(this._translate.instant(msg)) + `: ${formfields.join(", ")}`
                :   new ReplaceTermPipe(this._store).transform(this._translate.instant(msg));

            this._snackBar.open(message, undefined, {
                horizontalPosition: "center",
                verticalPosition: vposition || "bottom",
                duration: duration || 3000,
                panelClass: cssclass || "cat-background-primary"

            });

        } catch (error) {
            this._logger.error(error);
            throw new Error(error);
        }
    }

    /**
    * Get message definitions based on the store selector.
    * @param {IServiceDescriptor} call The current value as a string.
    * @param {Map<string, any>} storeList The current value as a string.
    * @param {any} storeItem The current value as a string.
    * @returns {IMessageDefinitions} Message definitions.
    */
    public getMessageDefinitions(msg: string): model.IMessageDefinitions {
        try {

            // Always return a message definition
            const result: model.IMessageDefinitions = {};

            // Add the details.
            if (msg) {

                // Attach the message definition
                if (protosui.def[msg]) {
                    result.msg = protosui.def[msg];
                } else {
                    this._logger.error(`Definitions not found for: ${msg}`);
                }

                if (protosui.msgIcons[msg]) {
                    result.icon = protosui.msgIcons[msg];
                } else {
                    this._logger.error(`Icon not found for: ${msg}`);
                }
            }

            return result;
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Dismiss all overlays, modals and popovers
     *
     * @memberof CommonService
     */
    async dismissAllOverlays() {
        try {

            this._logger.debug("Dismiss all overlays");

            // All dialogs
            this._dialogRef.closeAll();

        } catch (error) {
            this._logger.error(error);
        }
    }

    public updatePopoverState(removedPopover: cat.Popover) {
        try {
            // Also update local state
            const storedUser: cat.UserMsg = cloneDeep(this._store.selectSnapshot((state: model.IState) => state.cat.Me.msg));
            const popovers: number[] = Object.values(storedUser.popovers).filter((popover: cat.Popover) => popover !== removedPopover) as number[];
            storedUser.popovers = popovers;
            this._store.dispatch(new addMe(storedUser));
        } catch (error) {
            throw new Error(error);
        }
    }

    /**
     * Create the password field validator list based on the provided password policy.
     * @param policy The password policy.
     * @returns List of validator items.
     */
    public getPasswordValidators(policy: cat.PasswordPolicyMsg): model.IValidatorListItem[] {
        const validators = []
        validators.push(
            {
                type: model.AngularValidators.Required,
                validator: Validators.required
            }
        )
        if (policy.minlength) {
            validators.push(
                {
                    type: model.AngularValidators.MinLength,
                    validator: Validators.minLength(policy.minlength),
                    value: policy.minlength
                }
            )
        }
        if (policy.maxlength) {
            validators.push(
                {
                    type: model.AngularValidators.MaxLength,
                    validator: Validators.maxLength(policy.maxlength),
                    value: policy.maxlength
                }
            )
        }
        if (policy.lowercase) {
            validators.push(
                {
                    type: model.AngularValidators.patternLowerCase,
                    validator: Validators.pattern(new RegExp(`(?=(?:[^a-z]*[a-z]){${policy.lowercase}})`)),
                    value: policy.lowercase
                }
            )
        }
        if (policy.uppercase) {
            validators.push(
                {
                    type: model.AngularValidators.patternUpperCase,
                    validator: Validators.pattern(new RegExp(`(?=(?:[^A-Z]*[A-Z]){${policy.uppercase}})`)),
                    value: policy.uppercase
                }
            )
        }
        if (policy.digits) {
            validators.push(
                {
                    type: model.AngularValidators.patternDigits,
                    validator: Validators.pattern(new RegExp(`(?=(?:[^0-9]*[0-9]){${policy.digits}})`)),
                    value: policy.digits
                }
            )
        }
        if (policy.symbols) {
            validators.push(
                {
                    type: model.AngularValidators.patternSymbols,
                    validator: Validators.pattern(new RegExp(`(?=(?:[a-z0-9A-Z]*[^a-z0-9A-Z]){${policy.symbols}})`)),
                    value: policy.symbols
                }
            )
        }

        return validators;
    }

    /**
     * Show form field descriptions.
     *
     * @param {*} fielddata Fielddata.
     * @memberof CommonService
     */
    showDescription(fielddata: model.IFormFieldData) {

        if (fielddata.label && fielddata.description) {

            let validators = "";

            // Add validators for field description 'requirements'.
            if (fielddata.validatorList?.length) {
                fielddata.validatorList.map((validatorConfig: model.IValidatorListItem) => {
                    const definition = protosui.messages.validatorTexts[validatorConfig.type];
                    if (definition) {
                        const value = validatorConfig.value ? ` (${validatorConfig.value})` : "";
                        validators += `<li>${this._translate.instant(definition)}${value}</li>`;
                    }
                });
            }

            if (validators.length) {
                validators = `<b>${new ReplaceTermPipe(this._store).transform(this._translate.instant(protosui.messages.uitext.requirements))}</b><br><ul class="validators">${validators}</ul>`;
            }

            const data: model.IDialogData = {
                title: new ReplaceTermPipe(this._store).transform(this._translate.instant(fielddata.label)),
                content: `${new ReplaceTermPipe(this._store).transform(this._translate.instant(fielddata.description))} <br><br>\n \n ${validators}`
            };

            const dialogRef = this._dialogRef.open(GenericDialog, {
                data: data
            });
            dialogRef.afterClosed().subscribe(result => {
                this._logger.debug(`Dialog result: ${result}`);
            });
        } else {
            this.createSnackbar(protosui.messages.uitext.nodescription);
        }
    }

    /**
     * Function to detect changes in a safe way
     * @param {ChangeDetectorRef} cdr The change detection reference.
    */
    detectChange(cdr: ChangeDetectorRef) {
        if (cdr !== null && cdr !== undefined && !(cdr as ViewRef).destroyed) {
            cdr.detectChanges();
        }
    }

    /**
     * Create a generic dialog, show instantly.
     *
     * @param {model.IDialogData} data
     * @memberof CommonService
     */
    public createDialog(data: model.IDialogData, minWidth?: string) {
        try {
            this._logger.debug(`Open Dialog: ${JSON.stringify(data)}`);

            if (data) {
                if (data.buttons?.length) {
                    for (const button of data.buttons) {
                        button.title = new ReplaceTermPipe(this._store).transform(this._translate.instant(button.title));
                    }
                }

                if (data.content) {
                    data.content = new ReplaceTermPipe(this._store).transform(this._translate.instant(data.content));
                }

                if (data.title) {
                    data.title = new ReplaceTermPipe(this._store).transform(this._translate.instant(data.title));
                }

                const dialogRef = this._dialogRef.open(GenericDialog, {
                    minWidth: minWidth || undefined,
                    data: data
                });
                dialogRef.afterClosed().subscribe(result => {
                    this._logger.debug(`Dialog result: ${result}`);
                });
            } else {
                this._logger.error("No data provided to open dialog.");
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Create a generic dialog ref, to listen to close events etc.
     *
     * @param {model.IDialogData} data
     * @returns {MatDialogRef<GenericDialog, any>}
     * @memberof CommonService
     */
    public createDialogReference(data: model.IDialogData): MatDialogRef<GenericDialog, any> {
        try {

            if (data.buttons.length) {
                for (const button of data.buttons) {
                    button.title = new ReplaceTermPipe(this._store).transform(this._translate.instant(button.title));
                }
            }

            if (data.content) {
                data.content = new ReplaceTermPipe(this._store).transform(this._translate.instant(data.content));
            }

            if (data.title) {
                data.title = new ReplaceTermPipe(this._store).transform(this._translate.instant(data.title));
            }

            this._logger.debug(`Open dialog (reference): ${JSON.stringify(data)}`);
            return this._dialogRef.open(GenericDialog, {
                data: data
            });
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Convert a date object to date.
     * @param {any} object The date object
     * @returns {Date} A Javascript date object
     */
    public objectToDate(object: any): Date {

        let date: Date = undefined;

        if (object && object.day && object.month && object.year) {
            date = new Date(object.year, (object.month - 1), object.day);
        }
        return date;
    }

    /**
     * Construct a proto message from routedata and activated route
     *
     * @param {model.RouteData} routeData The routeData of the page
     * @param {ActivatedRoute} activatedRoute The activated route of the page
     * @returns {*}
     * @memberof CommonService
     */
    public dataToProtoMsg(routeData: model.RouteData, action: model.CallAction, routeId?: string, item?: any): any {
        try {

            if (!routeData || !action) {
                this._logger.error(protosui.messages.uitext.prerequisites);
            }

            // Add a payload to the request
            const payload: any = {};

            this._logger.debug(`Construct a ${action} call...`, routeData);

            if (routeData[action].payload) {

                Object.entries(routeData[action].payload).map((entry: any) => {
                    const [key, value] = entry;

                    // Exception for IDs
                    if (key === "id") {
                        payload.id = routeId || value;
                    // Add other key/value pairs from the data or from the item
                    } else if (!value && item && (key in item)) {
                        payload[key] = item[key];
                    } else if (value) {
                        payload[key] = value;
                    }
                });

                // payload = routeData[action].payload;
            // Otherwise just add the id from the item
            } else if (item && item.id) {
                payload.id = item.id;
            }

            // Get the current proto message and append new value
            const message: any = cat[routeData[action].call.requestType].create(payload);

            // Create child message if found in route data
            if (routeData.select.child) {

                // Possible payload for child element
                const childPayload: any = (item && item.id) ? { id: item.id } : {};

                if (routeData.select.child.repeated) {
                    message[routeData.select.child.selector] = [ cat[routeData.select.child.msg].create(childPayload)];
                } else {
                    this._logger.debug("TODO: child message without repeated");
                    message[routeData.select.child.selector] = cat[routeData.select.child.msg].create(childPayload);
                }
            }

            return message;

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
    * Get the definition for an enum
    * @param {DefData} definition The definition
    * @returns {Array<{ id: number, name: string }>} An object with human labels
    */
    public humanifyEnum(definition: model.DefData, isLanguage = false): Array<{ id: number, name: string }> {

        try {

            const enumerator = definition.enumerator; // e.g. MessageType
            const enumlist = cat[enumerator];
            let label: string;

            if (enumlist) {

                // Filter out enums with _UNKNOWN in it
                return Object.keys(enumlist)
                    .filter(key => !key.match(/UNKNOWN/g))
                    .filter(key => isLanguage ? this.getActiveLanguages().includes(key) : key)
                    .map(key => {
                        if (protosui.def[enumerator]) {
                            // e.g.: protosui.def.MessageType[cat.MessageType[1]].label
                            // means =>: protosui.def.MessageType.MESSAGE_MESSAGE.label
                            label = protosui.def[enumerator][cat[enumerator][enumlist[key]]].label;

                        } else {

                            // e.g.: Unknown
                            label = new CapitalizeFirstCharPipe().transform(key);
                        }

                        return { id: Number(enumlist[key]), name: label };

                });
            } else {
                throw Error("No enumlist");
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
    * Safely get a nested property without breaking the code
    * @param {Array} object The object where the property is in
    * @param {Array} path The path to the property
    * @returns A property or undefined
    */
    public getProp(object: any, path: Array<string>) {
        return path.reduce((obj, key) =>
            (obj && obj[key] !== "undefined") ? obj[key] : undefined, object);
    }

    /**
    * Default track function to increase performance on for loops
    */
    public trackById = ((index: number, item: any) => (item.id) ? item.id : index );

    /**
    * Default track function to increase performance on for loops
    */
    public trackByKey = ((index: number, item: any) => (item.key) ? item.key : index);

    /**
     * Super simple form builder with validators.
     *
     * @param {model.IFormFields} formInfo Form field info.
     * @returns {FormGroup}
     * @memberof CommonService
     */
    public createFormGroup(formInfo: model.IFormFields): FormGroup  {
        try {

            if (!formInfo) {
                throw new Error("No form fields provided.");
            }

            // Create a form group to return.
            const formGroup: FormGroup = this._formBuilder.group({}) as FormGroup;

            // Loop the form fields.
            const fields = Object.keys(formInfo);
            for (const field of fields) {
                // Add control.
                formGroup.addControl(field, new FormControl());
                // Add validators.
                formInfo[field]?.validatorList?.map(config => formGroup.get(field).addValidators(config.validator));
            }

            formGroup.setErrors({ 'invalid': true });
            return formGroup;

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Verify if a route exists based on a item id.
     *
     * @param {ActivatedRoute} route
     * @param {IServiceDescriptor} service
     * @param {string} itemId
     * @memberof CommonService
     */
    public async verifyRoute(route: ActivatedRoute, service: IServiceDescriptor, itemId: string) {
        try {
            if (!this._store.selectSnapshot((state: model.IState) => state.cat?.[service.methodName]?.list.has(itemId))) {
                this._logger.warn("[verifyRoute] no item with id found.", itemId);
                await this._router.navigate(['../../'], { relativeTo: route });
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
     * Return a string of form errors.
     *
     * @param {FormGroup} form
     * @param {model.IFormField[]} [fields]
     * @returns {string[]}
     * @memberof CommonService
     */
    public getFormErrors(form: FormGroup): string[] {
        try {
            const invalid = [];
            const controls = form.controls;
            for (const name in controls) {
                if (controls[name].invalid) {
                    invalid.push(name.toLowerCase());
                }
            }
            return invalid;
        } catch (error) {
            this._logger.error(new Error(error));
        }
    }

    /**
     * Detect Safari browser, for specific mimetype input issues (CAT-3406)
     *
     * @returns {boolean} Yes or no.
     * @memberof CommonService
     */
    public isSafari(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    /**
     * Handle upload files from the users client
     * @param {cat.AppMsg} app The app details.
     */
    public async handleFileInput(files: FileList, mimetypes: Array<string>, handler: model.TFileHandlerCallback) {
        try {
            if (!files || !files.length) {
                this.createSnackbar(protosui.messages.uitext.nofiles);
            }

            // Desktop platform
            for (const file of Array.from(files)) {

                this._logger.debug("Mimetypes: ", mimetypes);
                this._logger.debug("File type: ", file?.type);

                if (mimetypes?.length && !mimetypes.includes(file.type)) {
                    this.createSnackbar(protosui.messages.uitext.invalidmimetype);
                    throw new Error(protosui.messages.uitext.invalidmimetype);
                }

                this._logger.debug("File size: ", file?.size);

                if (!file?.size) {
                    this.createSnackbar(protosui.messages.uitext.emptyfile);
                    throw new Error(protosui.messages.uitext.emptyfile);
                }

                this._logger.debug("Streaming file with size:", file?.size);

                const fileStreamer = new FileStreamerService(file);
                while (!fileStreamer.isEndOfFile()) {
                    const data = await fileStreamer.readAsArrayBuffer(9 * 1024 * 1024);
                    await handler({ data: new Uint8Array(data), isEnd: false, filename: file.name, mimetype: file.type });
                }
                await handler({ data: null, isEnd: true, filename: file.name, mimetype: file.type });
            }
        } catch (error) {
            this._logger.error(error);
            this.createSnackbar(error);
        }
    }

    /**
     * Validate the call arguments based on input validation options.
     *
     * @protected
     * @param {[string, any][]} options The input validation options with a description and value.
     * @memberof CommonService
     */
    public validateCallArguments(options: [string, any][]) {
        for (const [description, value] of options) {
            if (value) {
                switch (typeof value) {
                    case "number": {
                        if (!value) {
                            throw new Error(`${protosui.messages.uitext.prerequisites}: ${description}`);
                        }
                        break;
                    }
                    case "string": {
                        if (!value.length) {
                            throw new Error(`${protosui.messages.uitext.prerequisites}: ${description}`);
                        }
                        break;
                    }
                    case "object": {
                        if (Array.isArray && Array.isArray(value) && !value.length) {
                            throw new Error(`${protosui.messages.uitext.prerequisites}: ${description}`);
                        }
                        break;
                    }
                    case "boolean": {
                        if (!value) {
                            throw new Error(`${protosui.messages.uitext.prerequisites}: ${description}`);
                        }
                        break;
                    }
                    default: {
                        throw new Error(`${protosui.messages.uitext.prerequisites}: ${description}`);
                    }
                }
            } else {
                throw new Error(`${protosui.messages.uitext.prerequisites}: ${description}`);
            }
        }
    }

    /**
     * Asynchroneous timeout function that returns after a number of milliseconds.
     *
     * @param {number} ms The timeout value in milliseconds.
     */
    public async timeout(ms: number) {
        if (ms >= 0) {
            if (ms >= 100) {
                while (ms > 0) {
                    await new Promise((res) => setTimeout(res, 100));
                    ms -= 100;
                }
            } else {
                await new Promise((res) => setTimeout(res, ms));
            }
        } else {
            throw new Error("Invalid timeout value.");
        }
    }

    /**
    * Get the active languages, a subset from the supported languages.
    *
    * @returns {string[]} List of active languages.
    */
    public getActiveLanguages(): string[] {
        let result: string[] = [];
        try {
            if (this._store.selectSnapshot((state: model.IState) => state.cat.Me)?.msg?.settings?.find((setting: cat.ISettingMsg) => setting.id === cat.Settings.SETTING_LANGUAGES)) {
                result = this._store.selectSnapshot((state: model.IState) => state.cat.Me.msg).settings.find((setting: cat.ISettingMsg) => setting.id === cat.Settings.SETTING_LANGUAGES)?.value;
            }
        } catch (error) {
            this._logger.error(error);
        }
        return result;
    }

    /**
     * Generic handling of new messages, including append / prepend flag and adding of date messages.
     *
     * @param {IServiceDescriptor} cursor The store cursor.
     * @param {string} parentId Conversation / topic id.
     * @param {string} firstMsgId Id of the first message in the parent.
     * @param {boolean} append To append or prepend.
     * @param {boolean} [list=false] For SIM message purposes.
     * @param {string} [firstChannelMsgId] Optional channel first msg id.
     * @memberof CommonService
     */
    public async handleNewMessages(cursor: IServiceDescriptor, parentId: string, firstMsgId: string, append: boolean, list = false, firstChannelMsgId?: string) {
        try {

            if (!cursor || !parentId || !firstMsgId) {
                throw new Error(protosui.messages.uitext.prerequisites);
            }

            if (this._store.selectSnapshot((state: model.IState) => state?.cat?.[cursor.methodName])?.list?.size) {

                let newMessages: Map<string, cat.MessageMsg> = this._store.selectSnapshot((state: model.IState) => state?.cat?.[cursor.methodName])?.list;

                // Message Id, Message
                if (list) {
                    newMessages = this._store.selectSnapshot((state: model.IState) => state?.cat?.[cursor.methodName]).list;
                }

                // Detect when all messages are loaded, do it before removing duplicates to prevent a false positive
                if (!newMessages.size || newMessages.size < 1) {
                    this._logger.debug("No new messages received, do nothing...", append);
                } else {

                    // List reference for new day message insertion, lookup every cycle
                    const infiniteList = this._store.selectSnapshot((state: model.IState) => state?.cat?.[cursor.methodName]).infiniteArray;
                    const prependMessages: any[] = [];
                    const appendMessages: any[] = [];
                    let existingNewDay: model.INewDayMsg;
                    let iteration = newMessages.size; // e.g. 30

                    for (const newMessage of newMessages.values()) {

                        // Reduce iteration to determine end (0).
                        iteration--;

                        // List reference for new day message insertion, lookup every cycle.
                        // Prepend message added before infinite list so prepend.concat().
                        // Append messages should be at the end, so (infinitelist, appendMessages).
                        const allMessages = prependMessages.concat(infiniteList, appendMessages);

                        // Determine the previous message for adding a potential 'new day' message.
                        const previousMsg = (append)
                            ? allMessages[allMessages.length - 1]
                            : allMessages[0];
                        const newday = new IsNewDayPipe().transform(Number(newMessage.received), Number(previousMsg?.received));
                        const newdayMessage: model.INewDayMsg = {
                            received: Number(newMessage.received),
                            isNewDay: true,
                            id: newMessage.id
                        };

                        // Check if we have this message already locally
                        const knownMessage = allMessages.find((message: cat.MessageMsg | model.INewDayMsg) => message.id === newMessage.id);

                        // Add the message, if not already found.
                        if (knownMessage === undefined) {

                            // Add the message to the end (newer messages).
                            if (append) {

                                // Insert new day message, before adding the message.
                                if (previousMsg && !(previousMsg as model.INewDayMsg).isNewDay && (newday || (iteration < 1))) {

                                    // Find any existing new day messages, compared with the newly added (newMessage).
                                    const knownNewdayMessage = allMessages.find((msg: any) => msg.isNewDay &&
                                        (new Date(Number(msg.received)).setHours(0, 0, 0, 0) === new Date(Number(newMessage.received)).setHours(0, 0, 0, 0)));

                                    // For append, only add newday message if not already added.
                                    if (!knownNewdayMessage) {
                                        // Add new day message.
                                        appendMessages.push(newdayMessage);
                                    }
                                }

                                // After adding a new day, add the message itself.
                                appendMessages.push(newMessage);

                            // Prepend the message, at to the top (older messages).
                            } else {

                                // Insert new day message, before adding the message.
                                if (previousMsg && !(previousMsg as model.INewDayMsg).isNewDay && (newday || (iteration < 1))) {

                                    // Find any existing new day messages, compared with the previously added (previousMsg).
                                    const existingNewDay = allMessages.find((msg: any) => msg.isNewDay &&
                                        (new Date(Number(msg.received)).setHours(0, 0, 0, 0) === new Date(Number(previousMsg.received)).setHours(0, 0, 0, 0)));

                                    // Remove (previously) added new day messages, always add new day below to have the 'earliest' new day
                                    if (existingNewDay) {
                                        this._store.dispatch(new removeDateFromInfiniteArray(existingNewDay, cursor.methodName));
                                    }

                                    // Add new day message.
                                    newdayMessage.received = previousMsg.received as number;
                                    prependMessages.unshift(newdayMessage);
                                }

                                // After adding a new day, add the message itself.
                                prependMessages.unshift(newMessage);
                            }

                        } else {
                            this._logger.debug("Message already added, please update", newMessage);
                            this._store.dispatch(new updateMessage(newMessage, cursor.methodName));
                        }

                        // Insert new day on top of chat, when first message (of chat) is found (and not a new day already)
                        if ((newMessage.id === firstMsgId) || (newMessage.id === firstChannelMsgId)) {

                            if (!previousMsg || (previousMsg && !(previousMsg as model.INewDayMsg).isNewDay)) {

                                // Construct a new day message.
                                const firstMessage = {
                                    received: newMessage.received,
                                    isNewDay: true
                                };

                                // Find existing new day message (before adding the new one) in messages (same as first message).
                                // Only take prepend messages (top messages) and the infinite list into account.
                                const existingDay = prependMessages.concat(infiniteList).find((msg: any) => msg.isNewDay &&
                                    (new Date(Number(msg.received)).setHours(0, 0, 0, 0) === new Date(Number(firstMessage.received)).setHours(0, 0, 0, 0)));

                                // Remove (previously) added new day message (same as first new day message).
                                if (existingDay) {
                                    existingNewDay = existingDay;
                                }

                                // Always prepend (if there is not already a date on top).
                                prependMessages.unshift(firstMessage);
                            }
                        }
                    }
                    // Add all prepended messages at once.
                    if (prependMessages?.length) {
                        this._logger.debug("Prepend messages: ", prependMessages);
                        this._store.dispatch(new prependToInfiteArray(prependMessages, cursor.methodName));
                    }
                    // Add all appended messages at once.
                    if (appendMessages?.length) {
                        this._logger.debug("Append messages: ", appendMessages);
                        this._store.dispatch(new appendToInfiteArray(appendMessages, cursor.methodName));
                    }
                    // Remove existing new day after prependToInfiteArray.
                    if (existingNewDay) {
                        this._store.dispatch(new removeDateFromInfiniteArray(existingNewDay, cursor.methodName));
                    }
                }

            } else {
                this._logger.debug("Conversation ID not found in store, do nothing...");
            }

        } catch (error) {
            this._logger.error(error);
        }
    }

    /**
      * Device status update, show progress (if the profile has the device attached).
      *
      * @param {cat.NotificationMsg} notification
      * @memberof CommonService
    */
    async handleDeviceStatusUpdate(notification: cat.NotificationMsg) {
        const deviceId = notification.references[cat.ReferenceType.REFERENCE_DEVICE_ID];
        const device: cat.DeviceMsg = cat.DeviceMsg.create({
            id: deviceId,
            receiverbufferstatus: notification.receiverbufferstatus
        });
        if (this._store.selectSnapshot((state: model.IState) => state.cat.userGetProfileDevices).list.has(deviceId)) {
            this._store.dispatch(new updateDeviceSyncProgress(device, userGetProfileDevices.methodName));
        }
    }

    /**
      * Store the table columns for a specific page (based on route name) with a prefix
      * to prevent the selection to be deleted upon logout.
      *
      * @param {ActivatedRoute} route The route name to make storage key unique.
      * @param {string[]} columns Column names.
      * @memberof CommonService
    */
    storeTableColumns(route: ActivatedRoute, columns: string[]) {
        if (!route || !columns?.length) {
            throw new Error("No route / columns provided");
        }
        localStorage.setItem(`tableColumns_${route.snapshot.component.name}`, JSON.stringify(columns));
    }

    /**
      * Retrieve the table columns for a specific page (based on route name) with a prefix.
      *
      * @param {ActivatedRoute} route The route name to make storage key unique.
      * @returns {string}
      * @memberof CommonService
    */
    getTableColumns(route: ActivatedRoute): string {
        if (!route) {
            throw new Error("No route / columns provided");
        }
        return localStorage.getItem(`tableColumns_${route.snapshot.component.name}`);
    }

    /**
     * Add the selected columns in the correct order, based on the provided ordered list.
     *
     * @param {string[]} columnOrder The order in which the columns should appear.
     * @param {string[]} selectedColumns The desired columns, including the newly added one.
     * @returns {string[]}
     * @memberof CommonService
     */
    addTableColumn(columnOrder: string[], selectedColumns: string[]): string[] {
        if (!columnOrder?.length || !selectedColumns?.length) {
            throw new Error("No columns provided");
        }

        // New ordered column list.
        const orderedColumns = [];

        // If we need to get the absolute correct order we need to iterate all columns
        // and push in the same order as the provided ordered columnOrder list.
        columnOrder.map(allcolumn => {
            if (selectedColumns.includes(allcolumn)) {
                orderedColumns.push(allcolumn);
            }
        });
        return orderedColumns;
    }
}