// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { Injectable } from "@angular/core";
import { cat } from "@assets/proto/msgs";

/**
* Theme interface with name and style
*/
interface Theme {
    name: number;
    styles: ThemeStyle[];
}

/**
* ThemeStyle interface with variable and value
*/
interface ThemeStyle {
    themeVariable: string;
    value: string;
}

@Injectable({
    providedIn: "root"
})
export class ThemeService {

    private _themes: Theme[] = [];

    constructor() {

        this._themes = [
            {
                name: cat.AppearanceMode.APPEARANCE_LIGHT,
                styles: [

                ]
            },
            {
                name: cat.AppearanceMode.APPEARANCE_DARK,
                styles: [

                ]
            }
        ];
    }

    /**
     * Set a new theme
     * @param {number} name The name of the theme to be set
     */
    setTheme(name: cat.AppearanceMode) {
        let curTheme: Theme = this._themes.find((theme: Theme) => theme.name === name);

        if (!curTheme) {
            curTheme = this._themes.find((theme: Theme) => theme.name === cat.AppearanceMode.APPEARANCE_LIGHT);
        }

        curTheme?.styles?.forEach(style => {
            document.documentElement.style.setProperty(style.themeVariable, style.value);
        });
    }
}
