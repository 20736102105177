import { Pipe, PipeTransform } from "@angular/core";
import { LoggerService } from "@services/logger/logger.service";
import { cat } from "@assets/proto/msgs";

/**
 * Determine if a message is incoming or outgoing
 *
 * @export
 * @class IsIncomingPipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: "isIncoming",
    pure: true
})

export class IsIncomingPipe implements PipeTransform {

    private _direction = cat.DirectionType;

    constructor(
        private _logger: LoggerService
    ) {}

    transform(direction: number): boolean {

        let result = false;

        if (direction && (direction === this._direction.DIRECTION_INCOMING)) {
            result = true;
        }
        return result;
    }
}