// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

import { NgModule } from "@angular/core";
import { GridResizeDirective } from "./grid-resize/grid-resize.directive";
import { MatAutofocusDirective } from "./mat-input-autofocus/autofocus.directive";
import { ReactiveFormsModule } from "@angular/forms";
import { MatGridResizeDirective } from "./mat-grid-resize/mat-grid-resize.directive";

@NgModule({
    imports: [ReactiveFormsModule],
    declarations: [
        GridResizeDirective,
        MatAutofocusDirective,
        MatGridResizeDirective
    ],
    exports: [
        GridResizeDirective,
        MatAutofocusDirective,
        MatGridResizeDirective
    ]
})
export class DirectivesModule { }
