<div class="cat-flex-column cat-full-width-height">

    <app-header
        [profileMode]="false"
        [dismiss]="true"
        [hideNotifications]="true"
        [hideUserDetails]="true"
        [title]="data.title">
    </app-header>

    <div mat-dialog-content class="cat-background-light cat-nooverflow-ellipsis cat-no-padding cat-flex-column">

        <div class="cat-background-white cat-flex-1">
            <div class="cat-full-width-height cat-flex-column">
                <div class="cat-padding-lg cat-flex-1">
                    <iframe
                        #doc
                        loading="lazy"
                        class="cat-full-width-height"
                        [src]="data.doc | docmedia | safehtml">
                        title="Document">
                        {{ "This is not supported by your browser" | translate }}
                    </iframe>
                </div>
            </div>
        </div>
    </div>

    <mat-dialog-actions align="end" class="cat-padding-y-lg cat-padding-right-lg">
        <button mat-stroked-button mat-dialog-close (click)="closeDialog('cancel')" cdkFocusInitial>
            {{ uitext.close | translate }}
        </button>
    </mat-dialog-actions>

</div>