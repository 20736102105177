
<button (click)="copyMsg()" mat-menu-item extended color="primary">
    <mat-icon color="primary">content_copy</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <span color="primary"><b>{{ uitext.copy | translate }}</b></span>
        <br>
        <span color="medium">{{ "Text to clipboard" | translate | replaceTerm }}</span>
    </div>
</button>

<button (click)="emitOpenDetails.emit(messageAction.Sender)" mat-menu-item extended color="primary">
    <mat-icon color="primary">info</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <span color="primary"><b>{{ uitext.info | translate }}</b></span>
        <br>
        <span color="medium">{{ "Message details" | translate | replaceTerm }}</span>
    </div>
</button>

<button (click)="emitOpenDetails.emit(messageAction.Annotate)" mat-menu-item extended color="primary">
    <mat-icon color="primary">chat</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <span color="primary">
            <b>
                {{ uitext.annotations | translate }}
                <span color="medium" class="cat-margin-left-sm">({{ msg?.annotations?.length }})</span>
            </b>
        </span>
    </div>
</button>

<button (click)="emitOpenDetails.emit(messageAction.Label)" mat-menu-item extended color="primary">
    <mat-icon color="primary">bookmark</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <span color="primary">
            <b>
                {{ uitext.labels | translate }}
                <span color="medium" class="cat-margin-left-sm">({{ msg?.labels?.length }})</span>
            </b>
        </span>
    </div>
</button>

<button (click)="emitOpenDetails.emit(messageAction.Attachments)" mat-menu-item extended color="primary">
    <mat-icon color="primary">attachment</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <span color="primary">
            <b>
                {{ uitext.attachments | translate }}
                <span color="medium" class="cat-margin-left-sm">({{ msg?.attachments?.length }})</span>
            </b>
        </span>
    </div>
</button>

<button (click)="emitOpenDetails.emit(messageAction.Links)" mat-menu-item extended color="primary">
    <mat-icon color="primary">link</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <span color="primary">
            <b>
                {{ uitext.links | translate }}
                <span color="medium" class="cat-margin-left-sm">({{ msg.urls?.length + msg.mentions?.length + msg.hashtags?.length }})</span>
            </b>
        </span>
    </div>
</button>

<button (click)="emitOpenDetails.emit(messageAction.Reactions)" mat-menu-item extended color="primary">
    <mat-icon color="primary">favorite</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <span color="primary">
            <b>
                {{ uitext.reactions | translate }}
                <span color="medium" class="cat-margin-left-sm">({{ msg?.reactions?.length }})</span>
            </b>
        </span>
    </div>
</button>

<button (click)="emitOpenDetails.emit(messageAction.History)" mat-menu-item extended color="primary">
    <mat-icon color="primary">history</mat-icon>
    <div class="cat-float-start cat-margin-left-md cat-margin-right-md cat-lineheight-md">
        <span color="primary">
            <b>
                {{ uitext.history | translate }}
                <span color="medium" class="cat-margin-left-sm">({{ msg?.history?.length }})</span>
            </b>
        </span>
    </div>
</button>



