import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "dayjs/esm";

/**
 * Human readable dates
 */
@Pipe({
  name: "isotime",
  pure: true
})
export class ISOTimePipe implements PipeTransform {

    transform(value: number): string {

        let result: any;

        if (value) {
            if (dayjs.isDayjs(value)) {
                result = value.format("YYYY-MM-DDTHH:mm");
            } else if (typeof value === "number") {
                result = dayjs(value).format("YYYY-MM-DDTHH:mm");
            }
        }
        return result;
    }
}

