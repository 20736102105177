import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

/**
 * Bypass security and trust the given value to be a safe resource URL
 */
@Pipe({
  name: "safehtml",
  pure: true
})
export class SafehtmlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    /**
     * Bypass security and trust the given value to be a safe resource URL, i.e. a
     * location that may be used to load executable code from, like <script src>, or <iframe src>.
     * @param {any} html The html
     * @returns {SafeResourceUrl}
     */
    transform(html: any): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    }
}
