import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, NgZone, Pipe, PipeTransform } from "@angular/core";
import { protosui } from "@definitions/definitions";
import { TranslateService } from "@ngx-translate/core";
import { LoggerService } from "@services/logger/logger.service";
import { TlService } from "@services/tl/tl.service";
import dayjs from "dayjs/esm";

/**
 * Human readable dates, updating
 */
@Pipe({
  name: "humanizetime",
  pure: false
})
export class HumanizeTimePipe implements PipeTransform {

    private timer: number;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _translate: TranslateService,
        private _tl: TlService,
        private _ngZone: NgZone,
        private _logger: LoggerService
    ) {}

    transform(receivedTime: number) {
        this.removeTimer();
        const d = new Date(receivedTime);
        const now = new Date();
        const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
        const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
        this.timer = this._ngZone.runOutsideAngular(() => {
            if (typeof window !== "undefined") {
                return window.setTimeout(() => {
                    this._ngZone.run(() => this._changeDetectorRef.markForCheck());
                }, timeToUpdate);
            }
            return null;
        });

        const todayMidnight = dayjs().startOf('day');
        const yesterdayMidnight = dayjs().subtract(1, 'day').startOf('day');

        if (Number.isNaN(seconds)) {
            return "";
        } else if (seconds <= 90) {
            return this._translate.instant(protosui.messages.uitext.now);
        } else if (dayjs(receivedTime).isAfter(todayMidnight)) {
            return new DatePipe(this._tl.locale).transform(receivedTime, "HH:mm");
        } else if (dayjs(receivedTime).isBefore(todayMidnight) && dayjs(receivedTime).isAfter(yesterdayMidnight)) {
            return this._translate.instant(protosui.messages.uitext.yesterday);
        } else {
            const time = new DatePipe(this._tl.locale).transform(receivedTime, "dd/MM/y");
            return time;
        }
    }

    ngOnDestroy() {
        this.removeTimer();
    }

    private removeTimer() {
        if (this.timer) {
            window.clearTimeout(this.timer);
            this.timer = null;
        }
    }

    private getSecondsUntilUpdate(seconds: number) {
        const min = 60;
        const hr = min * 60;
        const day = hr * 24;
        // Update every 2 seconds
        if (seconds < min) {
            return 2;
        // Update every 30 seconds
        } else if (seconds < hr) {
            return 30;
        // Update every 5 minutes
        } else if (seconds < day) {
            return 300;
        // Update every hour
        } else {
            return 3600;
        }
    }
}

