import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IReportPreviewData } from "@shared/app-models";
import { LoggerService } from "@services/logger/logger.service";
import { CommonService } from "@services/common/common.service";
import { cat } from "@assets/proto/msgs";
import { protosui } from "@definitions/definitions";

@Component({
  selector: "report-preview",
  templateUrl: "./report-preview.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportPreviewModal implements OnInit {

    @ViewChild("reportpreview", {static: false}) reportPreview: ElementRef;
    @ViewChild("reportfile", {static: false}) reportFile: ElementRef;

    public mediaType = cat.MediaType;
    public uitext = protosui.messages.uitext;

    constructor(
        public dialogRef: MatDialogRef<ReportPreviewModal>,
        public common: CommonService,
        private _logger: LoggerService,
        @Inject(MAT_DIALOG_DATA) public data: IReportPreviewData) {}

    ngOnInit() {
        this._logger.debug("Data", this.data);
    }

    /**
     * Close the dialog.
     *
     * @param {("cancel" | "delete")} event
     * @memberof FormDialog
     */
    closeDialog(event: "cancel" | "delete") {
        this.dialogRef.close(event);
    }
}