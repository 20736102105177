import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { cat } from "@assets/proto/msgs";
import { TopicDetailsDialog } from "@modals/detail-pages/topic-details/topic-details.dialog";
import { ITopicDetailData } from "@shared/app-models";
import { CommonService } from "@services/common/common.service";
import { LoggerService } from "@services/logger/logger.service";
import { protosui } from "@definitions/definitions";

@Component({
    selector: "topic-item",
    templateUrl: "./topic-item.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicItemComponent {

    // I/O
    @Input() topic: cat.TopicMsg = cat.TopicMsg.create();
    @Input() profile: cat.ProfileMsg = cat.ProfileMsg.create();
    @Input() account: cat.AccountMsg = cat.AccountMsg.create();
    @Input() unreadCount = 0;
    @Input() selected = false;
    @Input() disabled = false;

    // Properties
    public mediaType = cat.MediaType;
    public messageType = cat.MessageType;
    public topicState = cat.TopicState;
    public attachmentType = cat.AttachmentType;
    public uitext = protosui.messages.uitext;

    constructor(
        public common: CommonService,
        private _dialog: MatDialog,
        private _logger: LoggerService) {
    }

    public async selectTopic() {
        this._logger.warn("Select topic", this.topic);
    }

    /**
     * Open all topic details, annotations etcetera.
     *
     * @memberof TopicItemComponent
     */
    public async openTopicDetails() {
        try {
            const dialogData: ITopicDetailData = {
                topic: this.topic,
                profile: this.profile,
                account: this.account
            };
            this._logger.debug("Dialog Data: ", dialogData);

            this._dialog.open(TopicDetailsDialog, {
                width: "95vw",
                height: "95vh",
                data: dialogData,
                panelClass: "cat-dialog"
            });
        } catch (error) {
            this._logger.error(error);
        }
    }
}
