import { Pipe, PipeTransform } from "@angular/core";
import { QueryService } from "@services/query/query.service";

/**
 * Check for call filters from queryService
 */
@Pipe({
    name: "hasCallFilters",
    pure: true
})
export class HasCallFiltersPipe implements PipeTransform {

    constructor(
        private queryService: QueryService
    ) {}

    transform(path: string): boolean {
        let result = false;
        if (this.queryService.availableFilters?.get(path)?.length) {
            result = true;
        }
        return result;
    }
}
