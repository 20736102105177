// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

/** Software title */
export const title = "Cyber Agent Technology (Dev)";

/** Software version */
export const version = "1.12.5346-develop";

/** Software docker build */
export const docker = true;

