// Copyright 2018-2024, Volto Labs BV
// All rights reserved.

// NOTE: THIS FILE IS AUTOMATICALLY GENERATED, DO NOT EDIT

import { cat } from "../../assets/proto/msgs";
import { IdDictionary, IAppVersion, IKeycloakSettings } from "../../shared/app-models";

/**
* Generated interface for AppState
*/
export interface IAppState {
    // Primitive
    /** Generated WsRetryConnection store interface */
    WsRetryConnection: number;
    /** Generated WsConnection store interface */
    WsConnection: boolean;
    /** Generated Lock store interface */
    SessionLocked: boolean;
    /** Generated WsVersion store interface */
    WsVersion: IAppVersion;
    /** Generated AuthUser store interface */
    AuthUser: boolean;
    /** Generated KeycloakMode store interface */
    KeycloakMode: boolean;
    /** Generated QRCode store interface */
    QRCode: string;
    /** Generated DashboardAngle store interface */
    DashboardAngle: "device" | "profile" | "stats";
    /** Generated DeviceStats store interface */
    DeviceStats: { name: string, series: { name: string, value: number }[] }[];
    /** Generated GlobalSuccessStats store interface */
    GlobalSuccessStats: { name: string, series: { name: string, value: number }[] }[];
    /** Generated GlobalFailedStats store interface */
    GlobalFailedStats: { name: string, series: { name: string, value: number }[] }[];
    /** Generated ExcludeReportMessages store interface */
    ExcludeReportMessages: string[];
    /** Generated RoleColumns store interface */
    RoleColumns: string[];
    /** Generated DatepickerMonth store interface */
    DatepickerMonth: string;
    /** Generated SelectedMessage store interface */
    SelectedMessage: cat.MessageMsg;
    /** Generated CaptureQRApk store interface */
    CaptureQRApk: IMedia;
    /** Generated CaptureQR store interface */
    CaptureQR: IMedia;
    /** Generated Report Preview URL interface */
    ReportPreviewURL: string;

    // Protobuf service calls
    /** Generated userUnlock store interface */
    userUnlock: IuserUnlock;
    /** Generated userSignOut store interface */
    userSignOut: IuserSignOut;
    /** Generated userChangeSessionRole store interface */
    userChangeSessionRole: IuserChangeSessionRole;
    /** Generated userGetUserSessions store interface */
    userGetUserSessions: IuserGetUserSessions;
    /** Generated userGetEula store interface */
    userGetEula: IuserGetEula;
    /** Generated userGetPasswordPolicy store interface */
    userGetPasswordPolicy: IuserGetPasswordPolicy;
    /** Generated userSetTerminology store interface */
    userSetTerminology: IuserSetTerminology;
    /** Generated userGetSystemId store interface */
    userGetSystemId: IuserGetSystemId;
    /** Generated userGetCurrentLicense store interface */
    userGetCurrentLicense: IuserGetCurrentLicense;
    /** Generated userGetLicenseCount store interface */
    userGetLicenseCount: IuserGetLicenseCount;
    /** Generated userRemoveLicense store interface */
    userRemoveLicense: IuserRemoveLicense;
    /** Generated userImportLicense store interface */
    userImportLicense: IuserImportLicense;
    /** Generated userGetAvailableLogFileDates store interface */
    userGetAvailableLogFileDates: IuserGetAvailableLogFileDates;
    /** Generated userSetLogLevel store interface */
    userSetLogLevel: IuserSetLogLevel;
    /** Generated userSetSystemSetting store interface */
    userSetSystemSetting: IuserSetSystemSetting;
    /** Generated userGetSystemEventsFileId store interface */
    userGetSystemEventsFileId: IuserGetSystemEventsFileId;
    /** Generated userGetLogFileId store interface */
    userGetLogFileId: IuserGetLogFileId;
    /** Generated userGetSystemEventCount store interface */
    userGetSystemEventCount: IuserGetSystemEventCount;
    /** Generated userGetServiceStatuses store interface */
    userGetServiceStatuses: IuserGetServiceStatuses;
    /** Generated userGetTerminology store interface */
    userGetTerminology: IuserGetTerminology;
    /** Generated userGetLicenses store interface */
    userGetLicenses: IuserGetLicenses;
    /** Generated userGetSystemSettings store interface */
    userGetSystemSettings: IuserGetSystemSettings;
    /** Generated userGetSystemEvents store interface */
    userGetSystemEvents: IuserGetSystemEvents;
    /** Generated userGetAuditLogCount store interface */
    userGetAuditLogCount: IuserGetAuditLogCount;
    /** Generated userGetAuditLogs store interface */
    userGetAuditLogs: IuserGetAuditLogs;
    /** Generated userGetAuditLogUsers store interface */
    userGetAuditLogUsers: IuserGetAuditLogUsers;
    /** Generated userGetAuditLogCalls store interface */
    userGetAuditLogCalls: IuserGetAuditLogCalls;
    /** Generated userGetReportTemplateCount store interface */
    userGetReportTemplateCount: IuserGetReportTemplateCount;
    /** Generated userSignIn store interface */
    userSignIn: IuserSignIn;
    /** Generated userSignInWithSession store interface */
    userSignInWithSession: IuserSignInWithSession;
    /** Generated userSignInWithKeycloak store interface */
    userSignInWithKeycloak: IuserSignInWithKeycloak;
    /** Generated userAcceptEula store interface */
    userAcceptEula: IuserAcceptEula;
    /** Generated userSetCurrentUserPassword store interface */
    userSetCurrentUserPassword: IuserSetCurrentUserPassword;
    /** Generated userGetCurrentUserProfileCount store interface */
    userGetCurrentUserProfileCount: IuserGetCurrentUserProfileCount;
    /** Generated userGetCurrentUserCaseCount store interface */
    userGetCurrentUserCaseCount: IuserGetCurrentUserCaseCount;
    /** Generated userGetCurrentUserProfileReportCount store interface */
    userGetCurrentUserProfileReportCount: IuserGetCurrentUserProfileReportCount;
    /** Generated userRemoveCurrentUserNotifications store interface */
    userRemoveCurrentUserNotifications: IuserRemoveCurrentUserNotifications;
    /** Generated userRemoveProfileAvatar store interface */
    userRemoveProfileAvatar: IuserRemoveProfileAvatar;
    /** Generated userSetCurrentUser store interface */
    userSetCurrentUser: IuserSetCurrentUser;
    /** Generated userSetCurrentUserLastPopover store interface */
    userSetCurrentUserLastPopover: IuserSetCurrentUserLastPopover;
    /** Generated userRestoreCurrentUserPopovers store interface */
    userRestoreCurrentUserPopovers: IuserRestoreCurrentUserPopovers;
    /** Generated userSetCurrentUserLastProfile store interface */
    userSetCurrentUserLastProfile: IuserSetCurrentUserLastProfile;
    /** Generated userSetCurrentUserLastConversation store interface */
    userSetCurrentUserLastConversation: IuserSetCurrentUserLastConversation;
    /** Generated userResetUserPassword store interface */
    userResetUserPassword: IuserResetUserPassword;
    /** Generated userResetCurrentUserPassword store interface */
    userResetCurrentUserPassword: IuserResetCurrentUserPassword;
    /** Generated userUnmergeContact store interface */
    userUnmergeContact: IuserUnmergeContact;
    /** Generated userSetContactAlias store interface */
    userSetContactAlias: IuserSetContactAlias;
    /** Generated userSetProfileHidden store interface */
    userSetProfileHidden: IuserSetProfileHidden;
    /** Generated userGetDetailedAccount store interface */
    userGetDetailedAccount: IuserGetDetailedAccount;
    /** Generated userLinkDeviceToProfile store interface */
    userLinkDeviceToProfile: IuserLinkDeviceToProfile;
    /** Generated userUnlinkDeviceFromProfile store interface */
    userUnlinkDeviceFromProfile: IuserUnlinkDeviceFromProfile;
    /** Generated userSetRolePermission store interface */
    userSetRolePermission: IuserSetRolePermission;
    /** Generated userUnsetRolePermission store interface */
    userUnsetRolePermission: IuserUnsetRolePermission;
    /** Generated userSetConversationVisibility store interface */
    userSetConversationVisibility: IuserSetConversationVisibility;
    /** Generated userSetServerVisibility store interface */
    userSetServerVisibility: IuserSetServerVisibility;
    /** Generated userGetDeviceAppStatistics store interface */
    userGetDeviceAppStatistics: IuserGetDeviceAppStatistics;
    /** Generated userGetGlobalAppStatistics store interface */
    userGetGlobalAppStatistics: IuserGetGlobalAppStatistics;
    /** Generated userGetGlobalFailedItemsStatistics store interface */
    userGetGlobalFailedItemsStatistics: IuserGetGlobalFailedItemsStatistics;
    /** Generated userAddDomain store interface */
    userAddDomain: IuserAddDomain;
    /** Generated userModifyDomain store interface */
    userModifyDomain: IuserModifyDomain;
    /** Generated userRemoveDomain store interface */
    userRemoveDomain: IuserRemoveDomain;
    /** Generated userLinkDeviceToDomain store interface */
    userLinkDeviceToDomain: IuserLinkDeviceToDomain;
    /** Generated userUnlinkDeviceFromDomain store interface */
    userUnlinkDeviceFromDomain: IuserUnlinkDeviceFromDomain;
    /** Generated userGetProfileFirstMessageTimestamp store interface */
    userGetProfileFirstMessageTimestamp: IuserGetProfileFirstMessageTimestamp;
    /** Generated userGetConversationReport store interface */
    userGetConversationReport: IuserGetConversationReport;
    /** Generated userGetContactReport store interface */
    userGetContactReport: IuserGetContactReport;
    /** Generated userGetExistingReport store interface */
    userGetExistingReport: IuserGetExistingReport;
    /** Generated userGetReportingCounts store interface */
    userGetReportingCounts: IuserGetReportingCounts;
    /** Generated userRetryProfileReport store interface */
    userRetryProfileReport: IuserRetryProfileReport;
    /** Generated userGetProfileConversationCount store interface */
    userGetProfileConversationCount: IuserGetProfileConversationCount;
    /** Generated userGetProfileConversationCounts store interface */
    userGetProfileConversationCounts: IuserGetProfileConversationCounts;
    /** Generated userGetAccountConversationCount store interface */
    userGetAccountConversationCount: IuserGetAccountConversationCount;
    /** Generated userGetExcludedTopicsMessages store interface */
    userGetExcludedTopicsMessages: IuserGetExcludedTopicsMessages;
    /** Generated userGetUserCount store interface */
    userGetUserCount: IuserGetUserCount;
    /** Generated userAddUser store interface */
    userAddUser: IuserAddUser;
    /** Generated userModifyUser store interface */
    userModifyUser: IuserModifyUser;
    /** Generated userRemoveUser store interface */
    userRemoveUser: IuserRemoveUser;
    /** Generated userAddRole store interface */
    userAddRole: IuserAddRole;
    /** Generated userModifyRole store interface */
    userModifyRole: IuserModifyRole;
    /** Generated userRemoveRole store interface */
    userRemoveRole: IuserRemoveRole;
    /** Generated userGetThemeCount store interface */
    userGetThemeCount: IuserGetThemeCount;
    /** Generated userAddTheme store interface */
    userAddTheme: IuserAddTheme;
    /** Generated userModifyTheme store interface */
    userModifyTheme: IuserModifyTheme;
    /** Generated userRemoveTheme store interface */
    userRemoveTheme: IuserRemoveTheme;
    /** Generated userGetCaseCount store interface */
    userGetCaseCount: IuserGetCaseCount;
    /** Generated userAddCase store interface */
    userAddCase: IuserAddCase;
    /** Generated userModifyCase store interface */
    userModifyCase: IuserModifyCase;
    /** Generated userRemoveCase store interface */
    userRemoveCase: IuserRemoveCase;
    /** Generated userAddReportTemplate store interface */
    userAddReportTemplate: IuserAddReportTemplate;
    /** Generated userModifyReportTemplate store interface */
    userModifyReportTemplate: IuserModifyReportTemplate;
    /** Generated userRemoveReportTemplate store interface */
    userRemoveReportTemplate: IuserRemoveReportTemplate;
    /** Generated userGetDeviceCount store interface */
    userGetDeviceCount: IuserGetDeviceCount;
    /** Generated userRegisterDevice store interface */
    userRegisterDevice: IuserRegisterDevice;
    /** Generated userAddDevice store interface */
    userAddDevice: IuserAddDevice;
    /** Generated userModifyDevice store interface */
    userModifyDevice: IuserModifyDevice;
    /** Generated userRemoveDevice store interface */
    userRemoveDevice: IuserRemoveDevice;
    /** Generated userGetProfileCount store interface */
    userGetProfileCount: IuserGetProfileCount;
    /** Generated userAddProfile store interface */
    userAddProfile: IuserAddProfile;
    /** Generated userAddProfileToCurrentUser store interface */
    userAddProfileToCurrentUser: IuserAddProfileToCurrentUser;
    /** Generated userModifyProfile store interface */
    userModifyProfile: IuserModifyProfile;
    /** Generated userRemoveProfile store interface */
    userRemoveProfile: IuserRemoveProfile;
    /** Generated userRemoveCurrentUserProfile store interface */
    userRemoveCurrentUserProfile: IuserRemoveCurrentUserProfile;
    /** Generated userRemoveProfileMedia store interface */
    userRemoveProfileMedia: IuserRemoveProfileMedia;
    /** Generated userGetDetailedConversation store interface */
    userGetDetailedConversation: IuserGetDetailedConversation;
    /** Generated userGetDetailedMessage store interface */
    userGetDetailedMessage: IuserGetDetailedMessage;
    /** Generated userLinkProfileToCase store interface */
    userLinkProfileToCase: IuserLinkProfileToCase;
    /** Generated userUnlinkProfileFromCase store interface */
    userUnlinkProfileFromCase: IuserUnlinkProfileFromCase;
    /** Generated userLinkSupervisorToCase store interface */
    userLinkSupervisorToCase: IuserLinkSupervisorToCase;
    /** Generated userUnlinkSupervisorFromCase store interface */
    userUnlinkSupervisorFromCase: IuserUnlinkSupervisorFromCase;
    /** Generated userAddCaseLabel store interface */
    userAddCaseLabel: IuserAddCaseLabel;
    /** Generated userRemoveCaseLabel store interface */
    userRemoveCaseLabel: IuserRemoveCaseLabel;
    /** Generated userAddProfileReport store interface */
    userAddProfileReport: IuserAddProfileReport;
    /** Generated userRemoveProfileReport store interface */
    userRemoveProfileReport: IuserRemoveProfileReport;
    /** Generated userAddProfileLabel store interface */
    userAddProfileLabel: IuserAddProfileLabel;
    /** Generated userRemoveProfileLabel store interface */
    userRemoveProfileLabel: IuserRemoveProfileLabel;
    /** Generated userGetProfileContact store interface */
    userGetProfileContact: IuserGetProfileContact;
    /** Generated userGetProfileContactCount store interface */
    userGetProfileContactCount: IuserGetProfileContactCount;
    /** Generated userSetProfileAccount store interface */
    userSetProfileAccount: IuserSetProfileAccount;
    /** Generated userModifyAppAccount store interface */
    userModifyAppAccount: IuserModifyAppAccount;
    /** Generated userGetDetailedTopic store interface */
    userGetDetailedTopic: IuserGetDetailedTopic;
    /** Generated userAddGlobalLabel store interface */
    userAddGlobalLabel: IuserAddGlobalLabel;
    /** Generated userRemoveGlobalLabel store interface */
    userRemoveGlobalLabel: IuserRemoveGlobalLabel;
    /** Generated userSetMessageLabel store interface */
    userSetMessageLabel: IuserSetMessageLabel;
    /** Generated userUnsetMessageLabel store interface */
    userUnsetMessageLabel: IuserUnsetMessageLabel;
    /** Generated userAddMessageAnnotation store interface */
    userAddMessageAnnotation: IuserAddMessageAnnotation;
    /** Generated userRemoveMessageAnnotation store interface */
    userRemoveMessageAnnotation: IuserRemoveMessageAnnotation;
    /** Generated userAddConversationAnnotation store interface */
    userAddConversationAnnotation: IuserAddConversationAnnotation;
    /** Generated userRemoveConversationAnnotation store interface */
    userRemoveConversationAnnotation: IuserRemoveConversationAnnotation;
    /** Generated userSetTopicLabel store interface */
    userSetTopicLabel: IuserSetTopicLabel;
    /** Generated userUnsetTopicLabel store interface */
    userUnsetTopicLabel: IuserUnsetTopicLabel;
    /** Generated userAddTopicAnnotation store interface */
    userAddTopicAnnotation: IuserAddTopicAnnotation;
    /** Generated userRemoveTopicAnnotation store interface */
    userRemoveTopicAnnotation: IuserRemoveTopicAnnotation;
    /** Generated userSetServerLabel store interface */
    userSetServerLabel: IuserSetServerLabel;
    /** Generated userUnsetServerLabel store interface */
    userUnsetServerLabel: IuserUnsetServerLabel;
    /** Generated userAddServerAnnotation store interface */
    userAddServerAnnotation: IuserAddServerAnnotation;
    /** Generated userRemoveServerAnnotation store interface */
    userRemoveServerAnnotation: IuserRemoveServerAnnotation;
    /** Generated userSetConversationLabel store interface */
    userSetConversationLabel: IuserSetConversationLabel;
    /** Generated userUnsetConversationLabel store interface */
    userUnsetConversationLabel: IuserUnsetConversationLabel;
    /** Generated userGetDetailedServer store interface */
    userGetDetailedServer: IuserGetDetailedServer;
    /** Generated userGetConversationMediaCount store interface */
    userGetConversationMediaCount: IuserGetConversationMediaCount;
    /** Generated userGetConversationMemberCount store interface */
    userGetConversationMemberCount: IuserGetConversationMemberCount;
    /** Generated userSetContactLabel store interface */
    userSetContactLabel: IuserSetContactLabel;
    /** Generated userUnsetContactLabel store interface */
    userUnsetContactLabel: IuserUnsetContactLabel;
    /** Generated userAddContactAnnotation store interface */
    userAddContactAnnotation: IuserAddContactAnnotation;
    /** Generated userRemoveContactAnnotation store interface */
    userRemoveContactAnnotation: IuserRemoveContactAnnotation;
    /** Generated userGetAccountConversationCounts store interface */
    userGetAccountConversationCounts: IuserGetAccountConversationCounts;
    /** Generated userGetAccountServerCounts store interface */
    userGetAccountServerCounts: IuserGetAccountServerCounts;
    /** Generated userGetServerConversationCounts store interface */
    userGetServerConversationCounts: IuserGetServerConversationCounts;
    /** Generated userGetConversationTopicCounts store interface */
    userGetConversationTopicCounts: IuserGetConversationTopicCounts;
    /** Generated userSetUserProfileOwner store interface */
    userSetUserProfileOwner: IuserSetUserProfileOwner;
    /** Generated userRemoveUserProfileOwner store interface */
    userRemoveUserProfileOwner: IuserRemoveUserProfileOwner;
    /** Generated userLinkSupervisorUserToUser store interface */
    userLinkSupervisorUserToUser: IuserLinkSupervisorUserToUser;
    /** Generated userUnlinkSupervisorUserFromUser store interface */
    userUnlinkSupervisorUserFromUser: IuserUnlinkSupervisorUserFromUser;
    /** Generated userGetDeviceBackupCount store interface */
    userGetDeviceBackupCount: IuserGetDeviceBackupCount;
    /** Generated userRemoveDeviceBackup store interface */
    userRemoveDeviceBackup: IuserRemoveDeviceBackup;
    /** Generated userGetAPKCount store interface */
    userGetAPKCount: IuserGetAPKCount;
    /** Generated userRemoveAPK store interface */
    userRemoveAPK: IuserRemoveAPK;
    /** Generated userPatchAPK store interface */
    userPatchAPK: IuserPatchAPK;
    /** Generated userGetDeviceLogFileId store interface */
    userGetDeviceLogFileId: IuserGetDeviceLogFileId;
    /** Generated userGetAvailableDeviceLogFiles store interface */
    userGetAvailableDeviceLogFiles: IuserGetAvailableDeviceLogFiles;
    /** Generated userRemoveAccount store interface */
    userRemoveAccount: IuserRemoveAccount;
    /** Generated userStartDataCleaning store interface */
    userStartDataCleaning: IuserStartDataCleaning;
    /** Generated userGetDataCleaningStatus store interface */
    userGetDataCleaningStatus: IuserGetDataCleaningStatus;
    /** Generated userGetCurrentUserProfilesExportFileId store interface */
    userGetCurrentUserProfilesExportFileId: IuserGetCurrentUserProfilesExportFileId;
    /** Generated userGetConversationMemberExportFileId store interface */
    userGetConversationMemberExportFileId: IuserGetConversationMemberExportFileId;
    /** Generated userGetDevicesExportFileId store interface */
    userGetDevicesExportFileId: IuserGetDevicesExportFileId;
    /** Generated userGetReportTemplates store interface */
    userGetReportTemplates: IuserGetReportTemplates;
    /** Generated userGetCurrentUserPermissions store interface */
    userGetCurrentUserPermissions: IuserGetCurrentUserPermissions;
    /** Generated userGetCurrentUserProfiles store interface */
    userGetCurrentUserProfiles: IuserGetCurrentUserProfiles;
    /** Generated userGetUserProfiles store interface */
    userGetUserProfiles: IuserGetUserProfiles;
    /** Generated userGetCurrentUserCases store interface */
    userGetCurrentUserCases: IuserGetCurrentUserCases;
    /** Generated userGetAvailableCaseProfiles store interface */
    userGetAvailableCaseProfiles: IuserGetAvailableCaseProfiles;
    /** Generated userGetCurrentUserProfileReports store interface */
    userGetCurrentUserProfileReports: IuserGetCurrentUserProfileReports;
    /** Generated userGetCurrentUserProfileMedia store interface */
    userGetCurrentUserProfileMedia: IuserGetCurrentUserProfileMedia;
    /** Generated userGetCurrentUserDevices store interface */
    userGetCurrentUserDevices: IuserGetCurrentUserDevices;
    /** Generated userGetCurrentUserNotifications store interface */
    userGetCurrentUserNotifications: IuserGetCurrentUserNotifications;
    /** Generated userGetContactMerges store interface */
    userGetContactMerges: IuserGetContactMerges;
    /** Generated userGetCurrentUserThemes store interface */
    userGetCurrentUserThemes: IuserGetCurrentUserThemes;
    /** Generated userGetAccountMedia store interface */
    userGetAccountMedia: IuserGetAccountMedia;
    /** Generated userGetProfileAccounts store interface */
    userGetProfileAccounts: IuserGetProfileAccounts;
    /** Generated userGetProfileDevices store interface */
    userGetProfileDevices: IuserGetProfileDevices;
    /** Generated userGetProfileAccountsMediaCount store interface */
    userGetProfileAccountsMediaCount: IuserGetProfileAccountsMediaCount;
    /** Generated userGetRolesWithPermissions store interface */
    userGetRolesWithPermissions: IuserGetRolesWithPermissions;
    /** Generated userGetUsersAssignableToProfile store interface */
    userGetUsersAssignableToProfile: IuserGetUsersAssignableToProfile;
    /** Generated userGetDomains store interface */
    userGetDomains: IuserGetDomains;
    /** Generated userGetAvailableReportTemplates store interface */
    userGetAvailableReportTemplates: IuserGetAvailableReportTemplates;
    /** Generated userGetReportingLabels store interface */
    userGetReportingLabels: IuserGetReportingLabels;
    /** Generated userGetReportingAccounts store interface */
    userGetReportingAccounts: IuserGetReportingAccounts;
    /** Generated userGetProfileConversations store interface */
    userGetProfileConversations: IuserGetProfileConversations;
    /** Generated userGetUsers store interface */
    userGetUsers: IuserGetUsers;
    /** Generated userGetRoles store interface */
    userGetRoles: IuserGetRoles;
    /** Generated userGetThemes store interface */
    userGetThemes: IuserGetThemes;
    /** Generated userGetThemeUsers store interface */
    userGetThemeUsers: IuserGetThemeUsers;
    /** Generated userGetThemeUserProfileAccounts store interface */
    userGetThemeUserProfileAccounts: IuserGetThemeUserProfileAccounts;
    /** Generated userGetAppTypes store interface */
    userGetAppTypes: IuserGetAppTypes;
    /** Generated userGetCases store interface */
    userGetCases: IuserGetCases;
    /** Generated userGetDevices store interface */
    userGetDevices: IuserGetDevices;
    /** Generated userGetDeviceUsers store interface */
    userGetDeviceUsers: IuserGetDeviceUsers;
    /** Generated userGetProfiles store interface */
    userGetProfiles: IuserGetProfiles;
    /** Generated userGetProfileUsers store interface */
    userGetProfileUsers: IuserGetProfileUsers;
    /** Generated userGetCaseProfiles store interface */
    userGetCaseProfiles: IuserGetCaseProfiles;
    /** Generated userGetCaseLabels store interface */
    userGetCaseLabels: IuserGetCaseLabels;
    /** Generated userGetProfileLabels store interface */
    userGetProfileLabels: IuserGetProfileLabels;
    /** Generated userGetProfileContacts store interface */
    userGetProfileContacts: IuserGetProfileContacts;
    /** Generated userGetContactConversations store interface */
    userGetContactConversations: IuserGetContactConversations;
    /** Generated userGetDeviceAccounts store interface */
    userGetDeviceAccounts: IuserGetDeviceAccounts;
    /** Generated userGetAccountContacts store interface */
    userGetAccountContacts: IuserGetAccountContacts;
    /** Generated userGetConversationMessages store interface */
    userGetConversationMessages: IuserGetConversationMessages;
    /** Generated userGetMessageMessages store interface */
    userGetMessageMessages: IuserGetMessageMessages;
    /** Generated userSearchConversationMessages store interface */
    userSearchConversationMessages: IuserSearchConversationMessages;
    /** Generated userSearchTopicMessages store interface */
    userSearchTopicMessages: IuserSearchTopicMessages;
    /** Generated userGetTopicMessages store interface */
    userGetTopicMessages: IuserGetTopicMessages;
    /** Generated userGetMessageAttachments store interface */
    userGetMessageAttachments: IuserGetMessageAttachments;
    /** Generated userGetMessageReactions store interface */
    userGetMessageReactions: IuserGetMessageReactions;
    /** Generated userGetGlobalLabels store interface */
    userGetGlobalLabels: IuserGetGlobalLabels;
    /** Generated userGetMessageLabels store interface */
    userGetMessageLabels: IuserGetMessageLabels;
    /** Generated userGetMessageAnnotations store interface */
    userGetMessageAnnotations: IuserGetMessageAnnotations;
    /** Generated userGetConversationAnnotations store interface */
    userGetConversationAnnotations: IuserGetConversationAnnotations;
    /** Generated userGetTopicLabels store interface */
    userGetTopicLabels: IuserGetTopicLabels;
    /** Generated userGetTopicAnnotations store interface */
    userGetTopicAnnotations: IuserGetTopicAnnotations;
    /** Generated userGetServerLabels store interface */
    userGetServerLabels: IuserGetServerLabels;
    /** Generated userGetServerAnnotations store interface */
    userGetServerAnnotations: IuserGetServerAnnotations;
    /** Generated userGetConversationLabels store interface */
    userGetConversationLabels: IuserGetConversationLabels;
    /** Generated userGetAccountServers store interface */
    userGetAccountServers: IuserGetAccountServers;
    /** Generated userGetAccountConversations store interface */
    userGetAccountConversations: IuserGetAccountConversations;
    /** Generated userGetServerConversations store interface */
    userGetServerConversations: IuserGetServerConversations;
    /** Generated userGetConversationTopics store interface */
    userGetConversationTopics: IuserGetConversationTopics;
    /** Generated userGetConversationMembers store interface */
    userGetConversationMembers: IuserGetConversationMembers;
    /** Generated userGetConversationMedia store interface */
    userGetConversationMedia: IuserGetConversationMedia;
    /** Generated userGetContactLabels store interface */
    userGetContactLabels: IuserGetContactLabels;
    /** Generated userGetContactAnnotations store interface */
    userGetContactAnnotations: IuserGetContactAnnotations;
    /** Generated userSearchAccountsAndContactsSystemWide store interface */
    userSearchAccountsAndContactsSystemWide: IuserSearchAccountsAndContactsSystemWide;
    /** Generated userSearchConversationsSystemWide store interface */
    userSearchConversationsSystemWide: IuserSearchConversationsSystemWide;
    /** Generated userGetSupervisorUsers store interface */
    userGetSupervisorUsers: IuserGetSupervisorUsers;
    /** Generated userGetLinkableToSupervisorUsers store interface */
    userGetLinkableToSupervisorUsers: IuserGetLinkableToSupervisorUsers;
    /** Generated userGetLinkableToDeviceUsers store interface */
    userGetLinkableToDeviceUsers: IuserGetLinkableToDeviceUsers;
    /** Generated userGetDeviceBackups store interface */
    userGetDeviceBackups: IuserGetDeviceBackups;
    /** Generated userGetAPKS store interface */
    userGetAPKS: IuserGetAPKS;
    /** Generated userGetAppReceiverMedia store interface */
    userGetAppReceiverMedia: IuserGetAppReceiverMedia;
    /** Generated userGetDeviceReceiverMediaCount store interface */
    userGetDeviceReceiverMediaCount: IuserGetDeviceReceiverMediaCount;
    /** Generated userSetCurrentUserProfileMediaStream store interface */
    userSetCurrentUserProfileMediaStream: IuserSetCurrentUserProfileMediaStream;
    /** Generated userSetCurrentUserNotificationsRead store interface */
    userSetCurrentUserNotificationsRead: IuserSetCurrentUserNotificationsRead;
    /** Generated userMergeContacts store interface */
    userMergeContacts: IuserMergeContacts;
    /** Generated userAddReportTemplateFiles store interface */
    userAddReportTemplateFiles: IuserAddReportTemplateFiles;
    /** Generated userAddReportTemplateFile store interface */
    userAddReportTemplateFile: IuserAddReportTemplateFile;
    /** Generated userAddAnnotationAttachment store interface */
    userAddAnnotationAttachment: IuserAddAnnotationAttachment;
    /** Generated userAddAPK store interface */
    userAddAPK: IuserAddAPK;
    /** Generated userCancelReport store interface */
    userCancelReport: IuserCancelReport;
    /** Generated userGetReportPreviewFileId store interface */
    userGetReportPreviewFileId: IuserGetReportPreviewFileId;
    /** Generated userGetReportTemplatePreviewFileId store interface */
    userGetReportTemplatePreviewFileId: IuserGetReportTemplatePreviewFileId;
    // Other
    /** Generated SelectedConversation store interface */
    SelectedConversation: cat.ConversationMsg;
    /** Generated SelectedConversations store interface */
    SelectedConversations: cat.ConversationMsg[];
    /** Generated SelectedContacts store interface */
    SelectedContacts: cat.AccountMsg[];
    /** Generated ReplyToMsg store interface */
    ReplyToMsg: cat.MessageMsg;
    /** Generated CaseLabels store interface */
    CaseLabels: IdDictionary;
    /** Generated FileStreams store interface */
    FileStreams: any;
    /** Generated Me store interface */
    Me: IMe;
    /** Generated Terminology store interface */
    Terminology: ITerminology;
    /** Generated UserSessions store interface */
    UserSessions: cat.UserSessionMsg[];
}

/**
* Generated interface IMedia for store item
*/
export interface IMedia {
    /**
    * Media message
    */
    readonly msg?: cat.MediaMsg;
    /**
    * A media list map
    */
    readonly list?: Map<string, cat.MediaMsg>;
    /**
    * The infinite list
    */
    readonly infinite?: Map<string, cat.MediaMsg>;
}

/**
* Generated partial Media, with default values.
*/
export function createMedia(partial: Partial<IMedia>): IMedia {
    return {
        msg: partial.msg || undefined,
        list: partial.list || new Map<string, cat.MediaMsg>(),
        infinite: partial.infinite || new Map<string, cat.MediaMsg>()
    };
}

/**
* Generated interface IMe for store item
*/
export interface IMe {
    /**
    * The user message
    */
    readonly msg?: cat.UserMsg;
    /**
    * The user list message
    */
    readonly list?: Map<string, cat.UserMsg>;
    /**
    * The infinite list
    */
    readonly infinite?: Map<string, cat.UserMsg>;
}

/**
* Generated partial Me, with default values.
*/
export function createMe(partial: Partial<IMe>): IMe {
    return {
        msg: partial.msg || undefined,
        list: partial.list || new Map<string, cat.UserMsg>(),
        infinite: partial.infinite || new Map<string, cat.UserMsg>()
    };
}

/**
* Generated interface ITerminology for store item
*/
export interface ITerminology {
    readonly list?: Map<cat.Terminology, { [k: string]: string; }>;
}


    /**
    * Generated interface IuserUnlock for store item
    */
    export interface IuserUnlock {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnlock, with default values.
    */
    export function createuserUnlock(partial: Partial<IuserUnlock>): IuserUnlock {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSignOut for store item
    */
    export interface IuserSignOut {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSignOut, with default values.
    */
    export function createuserSignOut(partial: Partial<IuserSignOut>): IuserSignOut {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserChangeSessionRole for store item
    */
    export interface IuserChangeSessionRole {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userChangeSessionRole, with default values.
    */
    export function createuserChangeSessionRole(partial: Partial<IuserChangeSessionRole>): IuserChangeSessionRole {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetUserSessions for store item
    */
    export interface IuserGetUserSessions {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserSessionMsg message
        */
        readonly msg?: cat.UserSessionMsg;
        /**
        * The UserSessionMsg list map
        */
        readonly list?: Map<string, cat.UserSessionMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserSessionMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserSessionMsg[];
    }

    /**
    * Generated partial userGetUserSessions, with default values.
    */
    export function createuserGetUserSessions(partial: Partial<IuserGetUserSessions>): IuserGetUserSessions {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserSessionMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserSessionMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetEula for store item
    */
    export interface IuserGetEula {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The EulaMsg message
        */
        readonly msg?: cat.EulaMsg;
        /**
        * The EulaMsg list map
        */
        readonly list?: Map<string, cat.EulaMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.EulaMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.EulaMsg[];
    }

    /**
    * Generated partial userGetEula, with default values.
    */
    export function createuserGetEula(partial: Partial<IuserGetEula>): IuserGetEula {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.EulaMsg>(),
            infinite: partial.infinite || new Map<string, cat.EulaMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetPasswordPolicy for store item
    */
    export interface IuserGetPasswordPolicy {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The PasswordPolicyMsg message
        */
        readonly msg?: cat.PasswordPolicyMsg;
        /**
        * The PasswordPolicyMsg list map
        */
        readonly list?: Map<string, cat.PasswordPolicyMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.PasswordPolicyMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.PasswordPolicyMsg[];
    }

    /**
    * Generated partial userGetPasswordPolicy, with default values.
    */
    export function createuserGetPasswordPolicy(partial: Partial<IuserGetPasswordPolicy>): IuserGetPasswordPolicy {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.PasswordPolicyMsg>(),
            infinite: partial.infinite || new Map<string, cat.PasswordPolicyMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetTerminology for store item
    */
    export interface IuserSetTerminology {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetTerminology, with default values.
    */
    export function createuserSetTerminology(partial: Partial<IuserSetTerminology>): IuserSetTerminology {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetSystemId for store item
    */
    export interface IuserGetSystemId {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LicenseMsg message
        */
        readonly msg?: cat.LicenseMsg;
        /**
        * The LicenseMsg list map
        */
        readonly list?: Map<string, cat.LicenseMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LicenseMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LicenseMsg[];
    }

    /**
    * Generated partial userGetSystemId, with default values.
    */
    export function createuserGetSystemId(partial: Partial<IuserGetSystemId>): IuserGetSystemId {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LicenseMsg>(),
            infinite: partial.infinite || new Map<string, cat.LicenseMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentLicense for store item
    */
    export interface IuserGetCurrentLicense {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LicenseMsg message
        */
        readonly msg?: cat.LicenseMsg;
        /**
        * The LicenseMsg list map
        */
        readonly list?: Map<string, cat.LicenseMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LicenseMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LicenseMsg[];
    }

    /**
    * Generated partial userGetCurrentLicense, with default values.
    */
    export function createuserGetCurrentLicense(partial: Partial<IuserGetCurrentLicense>): IuserGetCurrentLicense {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LicenseMsg>(),
            infinite: partial.infinite || new Map<string, cat.LicenseMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetLicenseCount for store item
    */
    export interface IuserGetLicenseCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetLicenseCount, with default values.
    */
    export function createuserGetLicenseCount(partial: Partial<IuserGetLicenseCount>): IuserGetLicenseCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveLicense for store item
    */
    export interface IuserRemoveLicense {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveLicense, with default values.
    */
    export function createuserRemoveLicense(partial: Partial<IuserRemoveLicense>): IuserRemoveLicense {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserImportLicense for store item
    */
    export interface IuserImportLicense {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LicenseMsg message
        */
        readonly msg?: cat.LicenseMsg;
        /**
        * The LicenseMsg list map
        */
        readonly list?: Map<string, cat.LicenseMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LicenseMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LicenseMsg[];
    }

    /**
    * Generated partial userImportLicense, with default values.
    */
    export function createuserImportLicense(partial: Partial<IuserImportLicense>): IuserImportLicense {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LicenseMsg>(),
            infinite: partial.infinite || new Map<string, cat.LicenseMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAvailableLogFileDates for store item
    */
    export interface IuserGetAvailableLogFileDates {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The FilterMsg message
        */
        readonly msg?: cat.FilterMsg;
        /**
        * The FilterMsg list map
        */
        readonly list?: Map<string, cat.FilterMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.FilterMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.FilterMsg[];
    }

    /**
    * Generated partial userGetAvailableLogFileDates, with default values.
    */
    export function createuserGetAvailableLogFileDates(partial: Partial<IuserGetAvailableLogFileDates>): IuserGetAvailableLogFileDates {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.FilterMsg>(),
            infinite: partial.infinite || new Map<string, cat.FilterMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetLogLevel for store item
    */
    export interface IuserSetLogLevel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetLogLevel, with default values.
    */
    export function createuserSetLogLevel(partial: Partial<IuserSetLogLevel>): IuserSetLogLevel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetSystemSetting for store item
    */
    export interface IuserSetSystemSetting {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetSystemSetting, with default values.
    */
    export function createuserSetSystemSetting(partial: Partial<IuserSetSystemSetting>): IuserSetSystemSetting {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetSystemEventsFileId for store item
    */
    export interface IuserGetSystemEventsFileId {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaMsg message
        */
        readonly msg?: cat.MediaMsg;
        /**
        * The MediaMsg list map
        */
        readonly list?: Map<string, cat.MediaMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaMsg[];
    }

    /**
    * Generated partial userGetSystemEventsFileId, with default values.
    */
    export function createuserGetSystemEventsFileId(partial: Partial<IuserGetSystemEventsFileId>): IuserGetSystemEventsFileId {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetLogFileId for store item
    */
    export interface IuserGetLogFileId {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaMsg message
        */
        readonly msg?: cat.MediaMsg;
        /**
        * The MediaMsg list map
        */
        readonly list?: Map<string, cat.MediaMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaMsg[];
    }

    /**
    * Generated partial userGetLogFileId, with default values.
    */
    export function createuserGetLogFileId(partial: Partial<IuserGetLogFileId>): IuserGetLogFileId {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetSystemEventCount for store item
    */
    export interface IuserGetSystemEventCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetSystemEventCount, with default values.
    */
    export function createuserGetSystemEventCount(partial: Partial<IuserGetSystemEventCount>): IuserGetSystemEventCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetServiceStatuses for store item
    */
    export interface IuserGetServiceStatuses {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ServiceStatusMsg message
        */
        readonly msg?: cat.ServiceStatusMsg;
        /**
        * The ServiceStatusMsg list map
        */
        readonly list?: Map<string, cat.ServiceStatusMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ServiceStatusMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ServiceStatusMsg[];
    }

    /**
    * Generated partial userGetServiceStatuses, with default values.
    */
    export function createuserGetServiceStatuses(partial: Partial<IuserGetServiceStatuses>): IuserGetServiceStatuses {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ServiceStatusMsg>(),
            infinite: partial.infinite || new Map<string, cat.ServiceStatusMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetTerminology for store item
    */
    export interface IuserGetTerminology {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The TerminologyMsg message
        */
        readonly msg?: cat.TerminologyMsg;
        /**
        * The TerminologyMsg list map
        */
        readonly list?: Map<string, cat.TerminologyMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.TerminologyMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.TerminologyMsg[];
    }

    /**
    * Generated partial userGetTerminology, with default values.
    */
    export function createuserGetTerminology(partial: Partial<IuserGetTerminology>): IuserGetTerminology {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.TerminologyMsg>(),
            infinite: partial.infinite || new Map<string, cat.TerminologyMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetLicenses for store item
    */
    export interface IuserGetLicenses {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LicenseMsg message
        */
        readonly msg?: cat.LicenseMsg;
        /**
        * The LicenseMsg list map
        */
        readonly list?: Map<string, cat.LicenseMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LicenseMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LicenseMsg[];
    }

    /**
    * Generated partial userGetLicenses, with default values.
    */
    export function createuserGetLicenses(partial: Partial<IuserGetLicenses>): IuserGetLicenses {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LicenseMsg>(),
            infinite: partial.infinite || new Map<string, cat.LicenseMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetSystemSettings for store item
    */
    export interface IuserGetSystemSettings {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The SettingMsg message
        */
        readonly msg?: cat.SettingMsg;
        /**
        * The SettingMsg list map
        */
        readonly list?: Map<string, cat.SettingMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.SettingMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.SettingMsg[];
    }

    /**
    * Generated partial userGetSystemSettings, with default values.
    */
    export function createuserGetSystemSettings(partial: Partial<IuserGetSystemSettings>): IuserGetSystemSettings {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.SettingMsg>(),
            infinite: partial.infinite || new Map<string, cat.SettingMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetSystemEvents for store item
    */
    export interface IuserGetSystemEvents {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The SystemEventMsg message
        */
        readonly msg?: cat.SystemEventMsg;
        /**
        * The SystemEventMsg list map
        */
        readonly list?: Map<string, cat.SystemEventMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.SystemEventMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.SystemEventMsg[];
    }

    /**
    * Generated partial userGetSystemEvents, with default values.
    */
    export function createuserGetSystemEvents(partial: Partial<IuserGetSystemEvents>): IuserGetSystemEvents {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.SystemEventMsg>(),
            infinite: partial.infinite || new Map<string, cat.SystemEventMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAuditLogCount for store item
    */
    export interface IuserGetAuditLogCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetAuditLogCount, with default values.
    */
    export function createuserGetAuditLogCount(partial: Partial<IuserGetAuditLogCount>): IuserGetAuditLogCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAuditLogs for store item
    */
    export interface IuserGetAuditLogs {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AuditLogMsg message
        */
        readonly msg?: cat.AuditLogMsg;
        /**
        * The AuditLogMsg list map
        */
        readonly list?: Map<string, cat.AuditLogMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AuditLogMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AuditLogMsg[];
    }

    /**
    * Generated partial userGetAuditLogs, with default values.
    */
    export function createuserGetAuditLogs(partial: Partial<IuserGetAuditLogs>): IuserGetAuditLogs {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AuditLogMsg>(),
            infinite: partial.infinite || new Map<string, cat.AuditLogMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAuditLogUsers for store item
    */
    export interface IuserGetAuditLogUsers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userGetAuditLogUsers, with default values.
    */
    export function createuserGetAuditLogUsers(partial: Partial<IuserGetAuditLogUsers>): IuserGetAuditLogUsers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAuditLogCalls for store item
    */
    export interface IuserGetAuditLogCalls {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AuditLogCallMsg message
        */
        readonly msg?: cat.AuditLogCallMsg;
        /**
        * The AuditLogCallMsg list map
        */
        readonly list?: Map<string, cat.AuditLogCallMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AuditLogCallMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AuditLogCallMsg[];
    }

    /**
    * Generated partial userGetAuditLogCalls, with default values.
    */
    export function createuserGetAuditLogCalls(partial: Partial<IuserGetAuditLogCalls>): IuserGetAuditLogCalls {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AuditLogCallMsg>(),
            infinite: partial.infinite || new Map<string, cat.AuditLogCallMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetReportTemplateCount for store item
    */
    export interface IuserGetReportTemplateCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetReportTemplateCount, with default values.
    */
    export function createuserGetReportTemplateCount(partial: Partial<IuserGetReportTemplateCount>): IuserGetReportTemplateCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSignIn for store item
    */
    export interface IuserSignIn {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userSignIn, with default values.
    */
    export function createuserSignIn(partial: Partial<IuserSignIn>): IuserSignIn {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSignInWithSession for store item
    */
    export interface IuserSignInWithSession {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userSignInWithSession, with default values.
    */
    export function createuserSignInWithSession(partial: Partial<IuserSignInWithSession>): IuserSignInWithSession {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSignInWithKeycloak for store item
    */
    export interface IuserSignInWithKeycloak {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userSignInWithKeycloak, with default values.
    */
    export function createuserSignInWithKeycloak(partial: Partial<IuserSignInWithKeycloak>): IuserSignInWithKeycloak {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAcceptEula for store item
    */
    export interface IuserAcceptEula {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userAcceptEula, with default values.
    */
    export function createuserAcceptEula(partial: Partial<IuserAcceptEula>): IuserAcceptEula {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetCurrentUserPassword for store item
    */
    export interface IuserSetCurrentUserPassword {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetCurrentUserPassword, with default values.
    */
    export function createuserSetCurrentUserPassword(partial: Partial<IuserSetCurrentUserPassword>): IuserSetCurrentUserPassword {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserProfileCount for store item
    */
    export interface IuserGetCurrentUserProfileCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetCurrentUserProfileCount, with default values.
    */
    export function createuserGetCurrentUserProfileCount(partial: Partial<IuserGetCurrentUserProfileCount>): IuserGetCurrentUserProfileCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserCaseCount for store item
    */
    export interface IuserGetCurrentUserCaseCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetCurrentUserCaseCount, with default values.
    */
    export function createuserGetCurrentUserCaseCount(partial: Partial<IuserGetCurrentUserCaseCount>): IuserGetCurrentUserCaseCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserProfileReportCount for store item
    */
    export interface IuserGetCurrentUserProfileReportCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetCurrentUserProfileReportCount, with default values.
    */
    export function createuserGetCurrentUserProfileReportCount(partial: Partial<IuserGetCurrentUserProfileReportCount>): IuserGetCurrentUserProfileReportCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveCurrentUserNotifications for store item
    */
    export interface IuserRemoveCurrentUserNotifications {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveCurrentUserNotifications, with default values.
    */
    export function createuserRemoveCurrentUserNotifications(partial: Partial<IuserRemoveCurrentUserNotifications>): IuserRemoveCurrentUserNotifications {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveProfileAvatar for store item
    */
    export interface IuserRemoveProfileAvatar {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveProfileAvatar, with default values.
    */
    export function createuserRemoveProfileAvatar(partial: Partial<IuserRemoveProfileAvatar>): IuserRemoveProfileAvatar {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetCurrentUser for store item
    */
    export interface IuserSetCurrentUser {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userSetCurrentUser, with default values.
    */
    export function createuserSetCurrentUser(partial: Partial<IuserSetCurrentUser>): IuserSetCurrentUser {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetCurrentUserLastPopover for store item
    */
    export interface IuserSetCurrentUserLastPopover {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetCurrentUserLastPopover, with default values.
    */
    export function createuserSetCurrentUserLastPopover(partial: Partial<IuserSetCurrentUserLastPopover>): IuserSetCurrentUserLastPopover {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRestoreCurrentUserPopovers for store item
    */
    export interface IuserRestoreCurrentUserPopovers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRestoreCurrentUserPopovers, with default values.
    */
    export function createuserRestoreCurrentUserPopovers(partial: Partial<IuserRestoreCurrentUserPopovers>): IuserRestoreCurrentUserPopovers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetCurrentUserLastProfile for store item
    */
    export interface IuserSetCurrentUserLastProfile {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetCurrentUserLastProfile, with default values.
    */
    export function createuserSetCurrentUserLastProfile(partial: Partial<IuserSetCurrentUserLastProfile>): IuserSetCurrentUserLastProfile {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetCurrentUserLastConversation for store item
    */
    export interface IuserSetCurrentUserLastConversation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetCurrentUserLastConversation, with default values.
    */
    export function createuserSetCurrentUserLastConversation(partial: Partial<IuserSetCurrentUserLastConversation>): IuserSetCurrentUserLastConversation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserResetUserPassword for store item
    */
    export interface IuserResetUserPassword {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userResetUserPassword, with default values.
    */
    export function createuserResetUserPassword(partial: Partial<IuserResetUserPassword>): IuserResetUserPassword {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserResetCurrentUserPassword for store item
    */
    export interface IuserResetCurrentUserPassword {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userResetCurrentUserPassword, with default values.
    */
    export function createuserResetCurrentUserPassword(partial: Partial<IuserResetCurrentUserPassword>): IuserResetCurrentUserPassword {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnmergeContact for store item
    */
    export interface IuserUnmergeContact {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnmergeContact, with default values.
    */
    export function createuserUnmergeContact(partial: Partial<IuserUnmergeContact>): IuserUnmergeContact {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetContactAlias for store item
    */
    export interface IuserSetContactAlias {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetContactAlias, with default values.
    */
    export function createuserSetContactAlias(partial: Partial<IuserSetContactAlias>): IuserSetContactAlias {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetProfileHidden for store item
    */
    export interface IuserSetProfileHidden {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetProfileHidden, with default values.
    */
    export function createuserSetProfileHidden(partial: Partial<IuserSetProfileHidden>): IuserSetProfileHidden {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDetailedAccount for store item
    */
    export interface IuserGetDetailedAccount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userGetDetailedAccount, with default values.
    */
    export function createuserGetDetailedAccount(partial: Partial<IuserGetDetailedAccount>): IuserGetDetailedAccount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserLinkDeviceToProfile for store item
    */
    export interface IuserLinkDeviceToProfile {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userLinkDeviceToProfile, with default values.
    */
    export function createuserLinkDeviceToProfile(partial: Partial<IuserLinkDeviceToProfile>): IuserLinkDeviceToProfile {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnlinkDeviceFromProfile for store item
    */
    export interface IuserUnlinkDeviceFromProfile {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnlinkDeviceFromProfile, with default values.
    */
    export function createuserUnlinkDeviceFromProfile(partial: Partial<IuserUnlinkDeviceFromProfile>): IuserUnlinkDeviceFromProfile {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetRolePermission for store item
    */
    export interface IuserSetRolePermission {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetRolePermission, with default values.
    */
    export function createuserSetRolePermission(partial: Partial<IuserSetRolePermission>): IuserSetRolePermission {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnsetRolePermission for store item
    */
    export interface IuserUnsetRolePermission {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnsetRolePermission, with default values.
    */
    export function createuserUnsetRolePermission(partial: Partial<IuserUnsetRolePermission>): IuserUnsetRolePermission {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetConversationVisibility for store item
    */
    export interface IuserSetConversationVisibility {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetConversationVisibility, with default values.
    */
    export function createuserSetConversationVisibility(partial: Partial<IuserSetConversationVisibility>): IuserSetConversationVisibility {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetServerVisibility for store item
    */
    export interface IuserSetServerVisibility {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetServerVisibility, with default values.
    */
    export function createuserSetServerVisibility(partial: Partial<IuserSetServerVisibility>): IuserSetServerVisibility {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDeviceAppStatistics for store item
    */
    export interface IuserGetDeviceAppStatistics {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The StatisticsSeriesMsg message
        */
        readonly msg?: cat.StatisticsSeriesMsg;
        /**
        * The StatisticsSeriesMsg list map
        */
        readonly list?: Map<string, cat.StatisticsSeriesMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.StatisticsSeriesMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.StatisticsSeriesMsg[];
    }

    /**
    * Generated partial userGetDeviceAppStatistics, with default values.
    */
    export function createuserGetDeviceAppStatistics(partial: Partial<IuserGetDeviceAppStatistics>): IuserGetDeviceAppStatistics {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.StatisticsSeriesMsg>(),
            infinite: partial.infinite || new Map<string, cat.StatisticsSeriesMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetGlobalAppStatistics for store item
    */
    export interface IuserGetGlobalAppStatistics {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The StatisticsSeriesMsg message
        */
        readonly msg?: cat.StatisticsSeriesMsg;
        /**
        * The StatisticsSeriesMsg list map
        */
        readonly list?: Map<string, cat.StatisticsSeriesMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.StatisticsSeriesMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.StatisticsSeriesMsg[];
    }

    /**
    * Generated partial userGetGlobalAppStatistics, with default values.
    */
    export function createuserGetGlobalAppStatistics(partial: Partial<IuserGetGlobalAppStatistics>): IuserGetGlobalAppStatistics {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.StatisticsSeriesMsg>(),
            infinite: partial.infinite || new Map<string, cat.StatisticsSeriesMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetGlobalFailedItemsStatistics for store item
    */
    export interface IuserGetGlobalFailedItemsStatistics {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaMsg message
        */
        readonly msg?: cat.MediaMsg;
        /**
        * The MediaMsg list map
        */
        readonly list?: Map<string, cat.MediaMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaMsg[];
    }

    /**
    * Generated partial userGetGlobalFailedItemsStatistics, with default values.
    */
    export function createuserGetGlobalFailedItemsStatistics(partial: Partial<IuserGetGlobalFailedItemsStatistics>): IuserGetGlobalFailedItemsStatistics {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddDomain for store item
    */
    export interface IuserAddDomain {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The DomainMsg message
        */
        readonly msg?: cat.DomainMsg;
        /**
        * The DomainMsg list map
        */
        readonly list?: Map<string, cat.DomainMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.DomainMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.DomainMsg[];
    }

    /**
    * Generated partial userAddDomain, with default values.
    */
    export function createuserAddDomain(partial: Partial<IuserAddDomain>): IuserAddDomain {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.DomainMsg>(),
            infinite: partial.infinite || new Map<string, cat.DomainMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserModifyDomain for store item
    */
    export interface IuserModifyDomain {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The DomainMsg message
        */
        readonly msg?: cat.DomainMsg;
        /**
        * The DomainMsg list map
        */
        readonly list?: Map<string, cat.DomainMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.DomainMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.DomainMsg[];
    }

    /**
    * Generated partial userModifyDomain, with default values.
    */
    export function createuserModifyDomain(partial: Partial<IuserModifyDomain>): IuserModifyDomain {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.DomainMsg>(),
            infinite: partial.infinite || new Map<string, cat.DomainMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveDomain for store item
    */
    export interface IuserRemoveDomain {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveDomain, with default values.
    */
    export function createuserRemoveDomain(partial: Partial<IuserRemoveDomain>): IuserRemoveDomain {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserLinkDeviceToDomain for store item
    */
    export interface IuserLinkDeviceToDomain {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userLinkDeviceToDomain, with default values.
    */
    export function createuserLinkDeviceToDomain(partial: Partial<IuserLinkDeviceToDomain>): IuserLinkDeviceToDomain {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnlinkDeviceFromDomain for store item
    */
    export interface IuserUnlinkDeviceFromDomain {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnlinkDeviceFromDomain, with default values.
    */
    export function createuserUnlinkDeviceFromDomain(partial: Partial<IuserUnlinkDeviceFromDomain>): IuserUnlinkDeviceFromDomain {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileFirstMessageTimestamp for store item
    */
    export interface IuserGetProfileFirstMessageTimestamp {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ProfileMsg message
        */
        readonly msg?: cat.ProfileMsg;
        /**
        * The ProfileMsg list map
        */
        readonly list?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ProfileMsg[];
    }

    /**
    * Generated partial userGetProfileFirstMessageTimestamp, with default values.
    */
    export function createuserGetProfileFirstMessageTimestamp(partial: Partial<IuserGetProfileFirstMessageTimestamp>): IuserGetProfileFirstMessageTimestamp {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ProfileMsg>(),
            infinite: partial.infinite || new Map<string, cat.ProfileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationReport for store item
    */
    export interface IuserGetConversationReport {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportMsg message
        */
        readonly msg?: cat.ReportMsg;
        /**
        * The ReportMsg list map
        */
        readonly list?: Map<string, cat.ReportMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportMsg[];
    }

    /**
    * Generated partial userGetConversationReport, with default values.
    */
    export function createuserGetConversationReport(partial: Partial<IuserGetConversationReport>): IuserGetConversationReport {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetContactReport for store item
    */
    export interface IuserGetContactReport {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportMsg message
        */
        readonly msg?: cat.ReportMsg;
        /**
        * The ReportMsg list map
        */
        readonly list?: Map<string, cat.ReportMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportMsg[];
    }

    /**
    * Generated partial userGetContactReport, with default values.
    */
    export function createuserGetContactReport(partial: Partial<IuserGetContactReport>): IuserGetContactReport {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetExistingReport for store item
    */
    export interface IuserGetExistingReport {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportMsg message
        */
        readonly msg?: cat.ReportMsg;
        /**
        * The ReportMsg list map
        */
        readonly list?: Map<string, cat.ReportMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportMsg[];
    }

    /**
    * Generated partial userGetExistingReport, with default values.
    */
    export function createuserGetExistingReport(partial: Partial<IuserGetExistingReport>): IuserGetExistingReport {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetReportingCounts for store item
    */
    export interface IuserGetReportingCounts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportMsg message
        */
        readonly msg?: cat.ReportMsg;
        /**
        * The ReportMsg list map
        */
        readonly list?: Map<string, cat.ReportMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportMsg[];
    }

    /**
    * Generated partial userGetReportingCounts, with default values.
    */
    export function createuserGetReportingCounts(partial: Partial<IuserGetReportingCounts>): IuserGetReportingCounts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRetryProfileReport for store item
    */
    export interface IuserRetryProfileReport {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportMsg message
        */
        readonly msg?: cat.ReportMsg;
        /**
        * The ReportMsg list map
        */
        readonly list?: Map<string, cat.ReportMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportMsg[];
    }

    /**
    * Generated partial userRetryProfileReport, with default values.
    */
    export function createuserRetryProfileReport(partial: Partial<IuserRetryProfileReport>): IuserRetryProfileReport {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileConversationCount for store item
    */
    export interface IuserGetProfileConversationCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetProfileConversationCount, with default values.
    */
    export function createuserGetProfileConversationCount(partial: Partial<IuserGetProfileConversationCount>): IuserGetProfileConversationCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileConversationCounts for store item
    */
    export interface IuserGetProfileConversationCounts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The FilterCountMsg message
        */
        readonly msg?: cat.FilterCountMsg;
        /**
        * The FilterCountMsg list map
        */
        readonly list?: Map<string, cat.FilterCountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.FilterCountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.FilterCountMsg[];
    }

    /**
    * Generated partial userGetProfileConversationCounts, with default values.
    */
    export function createuserGetProfileConversationCounts(partial: Partial<IuserGetProfileConversationCounts>): IuserGetProfileConversationCounts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.FilterCountMsg>(),
            infinite: partial.infinite || new Map<string, cat.FilterCountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAccountConversationCount for store item
    */
    export interface IuserGetAccountConversationCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetAccountConversationCount, with default values.
    */
    export function createuserGetAccountConversationCount(partial: Partial<IuserGetAccountConversationCount>): IuserGetAccountConversationCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetExcludedTopicsMessages for store item
    */
    export interface IuserGetExcludedTopicsMessages {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ConversationMsg message
        */
        readonly msg?: cat.ConversationMsg;
        /**
        * The ConversationMsg list map
        */
        readonly list?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ConversationMsg[];
    }

    /**
    * Generated partial userGetExcludedTopicsMessages, with default values.
    */
    export function createuserGetExcludedTopicsMessages(partial: Partial<IuserGetExcludedTopicsMessages>): IuserGetExcludedTopicsMessages {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ConversationMsg>(),
            infinite: partial.infinite || new Map<string, cat.ConversationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetUserCount for store item
    */
    export interface IuserGetUserCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetUserCount, with default values.
    */
    export function createuserGetUserCount(partial: Partial<IuserGetUserCount>): IuserGetUserCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddUser for store item
    */
    export interface IuserAddUser {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userAddUser, with default values.
    */
    export function createuserAddUser(partial: Partial<IuserAddUser>): IuserAddUser {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserModifyUser for store item
    */
    export interface IuserModifyUser {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userModifyUser, with default values.
    */
    export function createuserModifyUser(partial: Partial<IuserModifyUser>): IuserModifyUser {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveUser for store item
    */
    export interface IuserRemoveUser {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveUser, with default values.
    */
    export function createuserRemoveUser(partial: Partial<IuserRemoveUser>): IuserRemoveUser {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddRole for store item
    */
    export interface IuserAddRole {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The RoleMsg message
        */
        readonly msg?: cat.RoleMsg;
        /**
        * The RoleMsg list map
        */
        readonly list?: Map<string, cat.RoleMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.RoleMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.RoleMsg[];
    }

    /**
    * Generated partial userAddRole, with default values.
    */
    export function createuserAddRole(partial: Partial<IuserAddRole>): IuserAddRole {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.RoleMsg>(),
            infinite: partial.infinite || new Map<string, cat.RoleMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserModifyRole for store item
    */
    export interface IuserModifyRole {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The RoleMsg message
        */
        readonly msg?: cat.RoleMsg;
        /**
        * The RoleMsg list map
        */
        readonly list?: Map<string, cat.RoleMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.RoleMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.RoleMsg[];
    }

    /**
    * Generated partial userModifyRole, with default values.
    */
    export function createuserModifyRole(partial: Partial<IuserModifyRole>): IuserModifyRole {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.RoleMsg>(),
            infinite: partial.infinite || new Map<string, cat.RoleMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveRole for store item
    */
    export interface IuserRemoveRole {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveRole, with default values.
    */
    export function createuserRemoveRole(partial: Partial<IuserRemoveRole>): IuserRemoveRole {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetThemeCount for store item
    */
    export interface IuserGetThemeCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetThemeCount, with default values.
    */
    export function createuserGetThemeCount(partial: Partial<IuserGetThemeCount>): IuserGetThemeCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddTheme for store item
    */
    export interface IuserAddTheme {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ThemeMsg message
        */
        readonly msg?: cat.ThemeMsg;
        /**
        * The ThemeMsg list map
        */
        readonly list?: Map<string, cat.ThemeMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ThemeMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ThemeMsg[];
    }

    /**
    * Generated partial userAddTheme, with default values.
    */
    export function createuserAddTheme(partial: Partial<IuserAddTheme>): IuserAddTheme {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ThemeMsg>(),
            infinite: partial.infinite || new Map<string, cat.ThemeMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserModifyTheme for store item
    */
    export interface IuserModifyTheme {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ThemeMsg message
        */
        readonly msg?: cat.ThemeMsg;
        /**
        * The ThemeMsg list map
        */
        readonly list?: Map<string, cat.ThemeMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ThemeMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ThemeMsg[];
    }

    /**
    * Generated partial userModifyTheme, with default values.
    */
    export function createuserModifyTheme(partial: Partial<IuserModifyTheme>): IuserModifyTheme {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ThemeMsg>(),
            infinite: partial.infinite || new Map<string, cat.ThemeMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveTheme for store item
    */
    export interface IuserRemoveTheme {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveTheme, with default values.
    */
    export function createuserRemoveTheme(partial: Partial<IuserRemoveTheme>): IuserRemoveTheme {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCaseCount for store item
    */
    export interface IuserGetCaseCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetCaseCount, with default values.
    */
    export function createuserGetCaseCount(partial: Partial<IuserGetCaseCount>): IuserGetCaseCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddCase for store item
    */
    export interface IuserAddCase {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The CaseMsg message
        */
        readonly msg?: cat.CaseMsg;
        /**
        * The CaseMsg list map
        */
        readonly list?: Map<string, cat.CaseMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.CaseMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.CaseMsg[];
    }

    /**
    * Generated partial userAddCase, with default values.
    */
    export function createuserAddCase(partial: Partial<IuserAddCase>): IuserAddCase {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.CaseMsg>(),
            infinite: partial.infinite || new Map<string, cat.CaseMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserModifyCase for store item
    */
    export interface IuserModifyCase {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The CaseMsg message
        */
        readonly msg?: cat.CaseMsg;
        /**
        * The CaseMsg list map
        */
        readonly list?: Map<string, cat.CaseMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.CaseMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.CaseMsg[];
    }

    /**
    * Generated partial userModifyCase, with default values.
    */
    export function createuserModifyCase(partial: Partial<IuserModifyCase>): IuserModifyCase {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.CaseMsg>(),
            infinite: partial.infinite || new Map<string, cat.CaseMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveCase for store item
    */
    export interface IuserRemoveCase {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveCase, with default values.
    */
    export function createuserRemoveCase(partial: Partial<IuserRemoveCase>): IuserRemoveCase {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddReportTemplate for store item
    */
    export interface IuserAddReportTemplate {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportTemplateMsg message
        */
        readonly msg?: cat.ReportTemplateMsg;
        /**
        * The ReportTemplateMsg list map
        */
        readonly list?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportTemplateMsg[];
    }

    /**
    * Generated partial userAddReportTemplate, with default values.
    */
    export function createuserAddReportTemplate(partial: Partial<IuserAddReportTemplate>): IuserAddReportTemplate {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportTemplateMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportTemplateMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserModifyReportTemplate for store item
    */
    export interface IuserModifyReportTemplate {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportTemplateMsg message
        */
        readonly msg?: cat.ReportTemplateMsg;
        /**
        * The ReportTemplateMsg list map
        */
        readonly list?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportTemplateMsg[];
    }

    /**
    * Generated partial userModifyReportTemplate, with default values.
    */
    export function createuserModifyReportTemplate(partial: Partial<IuserModifyReportTemplate>): IuserModifyReportTemplate {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportTemplateMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportTemplateMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveReportTemplate for store item
    */
    export interface IuserRemoveReportTemplate {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveReportTemplate, with default values.
    */
    export function createuserRemoveReportTemplate(partial: Partial<IuserRemoveReportTemplate>): IuserRemoveReportTemplate {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDeviceCount for store item
    */
    export interface IuserGetDeviceCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetDeviceCount, with default values.
    */
    export function createuserGetDeviceCount(partial: Partial<IuserGetDeviceCount>): IuserGetDeviceCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRegisterDevice for store item
    */
    export interface IuserRegisterDevice {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The DeviceMsg message
        */
        readonly msg?: cat.DeviceMsg;
        /**
        * The DeviceMsg list map
        */
        readonly list?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.DeviceMsg[];
    }

    /**
    * Generated partial userRegisterDevice, with default values.
    */
    export function createuserRegisterDevice(partial: Partial<IuserRegisterDevice>): IuserRegisterDevice {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.DeviceMsg>(),
            infinite: partial.infinite || new Map<string, cat.DeviceMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddDevice for store item
    */
    export interface IuserAddDevice {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The DeviceMsg message
        */
        readonly msg?: cat.DeviceMsg;
        /**
        * The DeviceMsg list map
        */
        readonly list?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.DeviceMsg[];
    }

    /**
    * Generated partial userAddDevice, with default values.
    */
    export function createuserAddDevice(partial: Partial<IuserAddDevice>): IuserAddDevice {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.DeviceMsg>(),
            infinite: partial.infinite || new Map<string, cat.DeviceMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserModifyDevice for store item
    */
    export interface IuserModifyDevice {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The DeviceMsg message
        */
        readonly msg?: cat.DeviceMsg;
        /**
        * The DeviceMsg list map
        */
        readonly list?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.DeviceMsg[];
    }

    /**
    * Generated partial userModifyDevice, with default values.
    */
    export function createuserModifyDevice(partial: Partial<IuserModifyDevice>): IuserModifyDevice {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.DeviceMsg>(),
            infinite: partial.infinite || new Map<string, cat.DeviceMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveDevice for store item
    */
    export interface IuserRemoveDevice {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveDevice, with default values.
    */
    export function createuserRemoveDevice(partial: Partial<IuserRemoveDevice>): IuserRemoveDevice {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileCount for store item
    */
    export interface IuserGetProfileCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetProfileCount, with default values.
    */
    export function createuserGetProfileCount(partial: Partial<IuserGetProfileCount>): IuserGetProfileCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddProfile for store item
    */
    export interface IuserAddProfile {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ProfileMsg message
        */
        readonly msg?: cat.ProfileMsg;
        /**
        * The ProfileMsg list map
        */
        readonly list?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ProfileMsg[];
    }

    /**
    * Generated partial userAddProfile, with default values.
    */
    export function createuserAddProfile(partial: Partial<IuserAddProfile>): IuserAddProfile {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ProfileMsg>(),
            infinite: partial.infinite || new Map<string, cat.ProfileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddProfileToCurrentUser for store item
    */
    export interface IuserAddProfileToCurrentUser {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ProfileMsg message
        */
        readonly msg?: cat.ProfileMsg;
        /**
        * The ProfileMsg list map
        */
        readonly list?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ProfileMsg[];
    }

    /**
    * Generated partial userAddProfileToCurrentUser, with default values.
    */
    export function createuserAddProfileToCurrentUser(partial: Partial<IuserAddProfileToCurrentUser>): IuserAddProfileToCurrentUser {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ProfileMsg>(),
            infinite: partial.infinite || new Map<string, cat.ProfileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserModifyProfile for store item
    */
    export interface IuserModifyProfile {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ProfileMsg message
        */
        readonly msg?: cat.ProfileMsg;
        /**
        * The ProfileMsg list map
        */
        readonly list?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ProfileMsg[];
    }

    /**
    * Generated partial userModifyProfile, with default values.
    */
    export function createuserModifyProfile(partial: Partial<IuserModifyProfile>): IuserModifyProfile {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ProfileMsg>(),
            infinite: partial.infinite || new Map<string, cat.ProfileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveProfile for store item
    */
    export interface IuserRemoveProfile {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveProfile, with default values.
    */
    export function createuserRemoveProfile(partial: Partial<IuserRemoveProfile>): IuserRemoveProfile {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveCurrentUserProfile for store item
    */
    export interface IuserRemoveCurrentUserProfile {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveCurrentUserProfile, with default values.
    */
    export function createuserRemoveCurrentUserProfile(partial: Partial<IuserRemoveCurrentUserProfile>): IuserRemoveCurrentUserProfile {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveProfileMedia for store item
    */
    export interface IuserRemoveProfileMedia {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveProfileMedia, with default values.
    */
    export function createuserRemoveProfileMedia(partial: Partial<IuserRemoveProfileMedia>): IuserRemoveProfileMedia {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDetailedConversation for store item
    */
    export interface IuserGetDetailedConversation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ConversationMsg message
        */
        readonly msg?: cat.ConversationMsg;
        /**
        * The ConversationMsg list map
        */
        readonly list?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ConversationMsg[];
    }

    /**
    * Generated partial userGetDetailedConversation, with default values.
    */
    export function createuserGetDetailedConversation(partial: Partial<IuserGetDetailedConversation>): IuserGetDetailedConversation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ConversationMsg>(),
            infinite: partial.infinite || new Map<string, cat.ConversationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDetailedMessage for store item
    */
    export interface IuserGetDetailedMessage {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MessageMsg message
        */
        readonly msg?: cat.MessageMsg;
        /**
        * The MessageMsg list map
        */
        readonly list?: Map<string, cat.MessageMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MessageMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MessageMsg[];
    }

    /**
    * Generated partial userGetDetailedMessage, with default values.
    */
    export function createuserGetDetailedMessage(partial: Partial<IuserGetDetailedMessage>): IuserGetDetailedMessage {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MessageMsg>(),
            infinite: partial.infinite || new Map<string, cat.MessageMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserLinkProfileToCase for store item
    */
    export interface IuserLinkProfileToCase {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userLinkProfileToCase, with default values.
    */
    export function createuserLinkProfileToCase(partial: Partial<IuserLinkProfileToCase>): IuserLinkProfileToCase {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnlinkProfileFromCase for store item
    */
    export interface IuserUnlinkProfileFromCase {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnlinkProfileFromCase, with default values.
    */
    export function createuserUnlinkProfileFromCase(partial: Partial<IuserUnlinkProfileFromCase>): IuserUnlinkProfileFromCase {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserLinkSupervisorToCase for store item
    */
    export interface IuserLinkSupervisorToCase {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userLinkSupervisorToCase, with default values.
    */
    export function createuserLinkSupervisorToCase(partial: Partial<IuserLinkSupervisorToCase>): IuserLinkSupervisorToCase {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnlinkSupervisorFromCase for store item
    */
    export interface IuserUnlinkSupervisorFromCase {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnlinkSupervisorFromCase, with default values.
    */
    export function createuserUnlinkSupervisorFromCase(partial: Partial<IuserUnlinkSupervisorFromCase>): IuserUnlinkSupervisorFromCase {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddCaseLabel for store item
    */
    export interface IuserAddCaseLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userAddCaseLabel, with default values.
    */
    export function createuserAddCaseLabel(partial: Partial<IuserAddCaseLabel>): IuserAddCaseLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveCaseLabel for store item
    */
    export interface IuserRemoveCaseLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveCaseLabel, with default values.
    */
    export function createuserRemoveCaseLabel(partial: Partial<IuserRemoveCaseLabel>): IuserRemoveCaseLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddProfileReport for store item
    */
    export interface IuserAddProfileReport {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userAddProfileReport, with default values.
    */
    export function createuserAddProfileReport(partial: Partial<IuserAddProfileReport>): IuserAddProfileReport {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveProfileReport for store item
    */
    export interface IuserRemoveProfileReport {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveProfileReport, with default values.
    */
    export function createuserRemoveProfileReport(partial: Partial<IuserRemoveProfileReport>): IuserRemoveProfileReport {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddProfileLabel for store item
    */
    export interface IuserAddProfileLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userAddProfileLabel, with default values.
    */
    export function createuserAddProfileLabel(partial: Partial<IuserAddProfileLabel>): IuserAddProfileLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveProfileLabel for store item
    */
    export interface IuserRemoveProfileLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveProfileLabel, with default values.
    */
    export function createuserRemoveProfileLabel(partial: Partial<IuserRemoveProfileLabel>): IuserRemoveProfileLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileContact for store item
    */
    export interface IuserGetProfileContact {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userGetProfileContact, with default values.
    */
    export function createuserGetProfileContact(partial: Partial<IuserGetProfileContact>): IuserGetProfileContact {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileContactCount for store item
    */
    export interface IuserGetProfileContactCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetProfileContactCount, with default values.
    */
    export function createuserGetProfileContactCount(partial: Partial<IuserGetProfileContactCount>): IuserGetProfileContactCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetProfileAccount for store item
    */
    export interface IuserSetProfileAccount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetProfileAccount, with default values.
    */
    export function createuserSetProfileAccount(partial: Partial<IuserSetProfileAccount>): IuserSetProfileAccount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserModifyAppAccount for store item
    */
    export interface IuserModifyAppAccount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userModifyAppAccount, with default values.
    */
    export function createuserModifyAppAccount(partial: Partial<IuserModifyAppAccount>): IuserModifyAppAccount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDetailedTopic for store item
    */
    export interface IuserGetDetailedTopic {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The TopicMsg message
        */
        readonly msg?: cat.TopicMsg;
        /**
        * The TopicMsg list map
        */
        readonly list?: Map<string, cat.TopicMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.TopicMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.TopicMsg[];
    }

    /**
    * Generated partial userGetDetailedTopic, with default values.
    */
    export function createuserGetDetailedTopic(partial: Partial<IuserGetDetailedTopic>): IuserGetDetailedTopic {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.TopicMsg>(),
            infinite: partial.infinite || new Map<string, cat.TopicMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddGlobalLabel for store item
    */
    export interface IuserAddGlobalLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userAddGlobalLabel, with default values.
    */
    export function createuserAddGlobalLabel(partial: Partial<IuserAddGlobalLabel>): IuserAddGlobalLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveGlobalLabel for store item
    */
    export interface IuserRemoveGlobalLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveGlobalLabel, with default values.
    */
    export function createuserRemoveGlobalLabel(partial: Partial<IuserRemoveGlobalLabel>): IuserRemoveGlobalLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetMessageLabel for store item
    */
    export interface IuserSetMessageLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetMessageLabel, with default values.
    */
    export function createuserSetMessageLabel(partial: Partial<IuserSetMessageLabel>): IuserSetMessageLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnsetMessageLabel for store item
    */
    export interface IuserUnsetMessageLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnsetMessageLabel, with default values.
    */
    export function createuserUnsetMessageLabel(partial: Partial<IuserUnsetMessageLabel>): IuserUnsetMessageLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddMessageAnnotation for store item
    */
    export interface IuserAddMessageAnnotation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AnnotationMsg message
        */
        readonly msg?: cat.AnnotationMsg;
        /**
        * The AnnotationMsg list map
        */
        readonly list?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AnnotationMsg[];
    }

    /**
    * Generated partial userAddMessageAnnotation, with default values.
    */
    export function createuserAddMessageAnnotation(partial: Partial<IuserAddMessageAnnotation>): IuserAddMessageAnnotation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AnnotationMsg>(),
            infinite: partial.infinite || new Map<string, cat.AnnotationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveMessageAnnotation for store item
    */
    export interface IuserRemoveMessageAnnotation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveMessageAnnotation, with default values.
    */
    export function createuserRemoveMessageAnnotation(partial: Partial<IuserRemoveMessageAnnotation>): IuserRemoveMessageAnnotation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddConversationAnnotation for store item
    */
    export interface IuserAddConversationAnnotation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AnnotationMsg message
        */
        readonly msg?: cat.AnnotationMsg;
        /**
        * The AnnotationMsg list map
        */
        readonly list?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AnnotationMsg[];
    }

    /**
    * Generated partial userAddConversationAnnotation, with default values.
    */
    export function createuserAddConversationAnnotation(partial: Partial<IuserAddConversationAnnotation>): IuserAddConversationAnnotation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AnnotationMsg>(),
            infinite: partial.infinite || new Map<string, cat.AnnotationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveConversationAnnotation for store item
    */
    export interface IuserRemoveConversationAnnotation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveConversationAnnotation, with default values.
    */
    export function createuserRemoveConversationAnnotation(partial: Partial<IuserRemoveConversationAnnotation>): IuserRemoveConversationAnnotation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetTopicLabel for store item
    */
    export interface IuserSetTopicLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetTopicLabel, with default values.
    */
    export function createuserSetTopicLabel(partial: Partial<IuserSetTopicLabel>): IuserSetTopicLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnsetTopicLabel for store item
    */
    export interface IuserUnsetTopicLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnsetTopicLabel, with default values.
    */
    export function createuserUnsetTopicLabel(partial: Partial<IuserUnsetTopicLabel>): IuserUnsetTopicLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddTopicAnnotation for store item
    */
    export interface IuserAddTopicAnnotation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AnnotationMsg message
        */
        readonly msg?: cat.AnnotationMsg;
        /**
        * The AnnotationMsg list map
        */
        readonly list?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AnnotationMsg[];
    }

    /**
    * Generated partial userAddTopicAnnotation, with default values.
    */
    export function createuserAddTopicAnnotation(partial: Partial<IuserAddTopicAnnotation>): IuserAddTopicAnnotation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AnnotationMsg>(),
            infinite: partial.infinite || new Map<string, cat.AnnotationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveTopicAnnotation for store item
    */
    export interface IuserRemoveTopicAnnotation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveTopicAnnotation, with default values.
    */
    export function createuserRemoveTopicAnnotation(partial: Partial<IuserRemoveTopicAnnotation>): IuserRemoveTopicAnnotation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetServerLabel for store item
    */
    export interface IuserSetServerLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetServerLabel, with default values.
    */
    export function createuserSetServerLabel(partial: Partial<IuserSetServerLabel>): IuserSetServerLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnsetServerLabel for store item
    */
    export interface IuserUnsetServerLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnsetServerLabel, with default values.
    */
    export function createuserUnsetServerLabel(partial: Partial<IuserUnsetServerLabel>): IuserUnsetServerLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddServerAnnotation for store item
    */
    export interface IuserAddServerAnnotation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AnnotationMsg message
        */
        readonly msg?: cat.AnnotationMsg;
        /**
        * The AnnotationMsg list map
        */
        readonly list?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AnnotationMsg[];
    }

    /**
    * Generated partial userAddServerAnnotation, with default values.
    */
    export function createuserAddServerAnnotation(partial: Partial<IuserAddServerAnnotation>): IuserAddServerAnnotation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AnnotationMsg>(),
            infinite: partial.infinite || new Map<string, cat.AnnotationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveServerAnnotation for store item
    */
    export interface IuserRemoveServerAnnotation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveServerAnnotation, with default values.
    */
    export function createuserRemoveServerAnnotation(partial: Partial<IuserRemoveServerAnnotation>): IuserRemoveServerAnnotation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetConversationLabel for store item
    */
    export interface IuserSetConversationLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetConversationLabel, with default values.
    */
    export function createuserSetConversationLabel(partial: Partial<IuserSetConversationLabel>): IuserSetConversationLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnsetConversationLabel for store item
    */
    export interface IuserUnsetConversationLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnsetConversationLabel, with default values.
    */
    export function createuserUnsetConversationLabel(partial: Partial<IuserUnsetConversationLabel>): IuserUnsetConversationLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDetailedServer for store item
    */
    export interface IuserGetDetailedServer {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ServerMsg message
        */
        readonly msg?: cat.ServerMsg;
        /**
        * The ServerMsg list map
        */
        readonly list?: Map<string, cat.ServerMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ServerMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ServerMsg[];
    }

    /**
    * Generated partial userGetDetailedServer, with default values.
    */
    export function createuserGetDetailedServer(partial: Partial<IuserGetDetailedServer>): IuserGetDetailedServer {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ServerMsg>(),
            infinite: partial.infinite || new Map<string, cat.ServerMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationMediaCount for store item
    */
    export interface IuserGetConversationMediaCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetConversationMediaCount, with default values.
    */
    export function createuserGetConversationMediaCount(partial: Partial<IuserGetConversationMediaCount>): IuserGetConversationMediaCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationMemberCount for store item
    */
    export interface IuserGetConversationMemberCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetConversationMemberCount, with default values.
    */
    export function createuserGetConversationMemberCount(partial: Partial<IuserGetConversationMemberCount>): IuserGetConversationMemberCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetContactLabel for store item
    */
    export interface IuserSetContactLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetContactLabel, with default values.
    */
    export function createuserSetContactLabel(partial: Partial<IuserSetContactLabel>): IuserSetContactLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnsetContactLabel for store item
    */
    export interface IuserUnsetContactLabel {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnsetContactLabel, with default values.
    */
    export function createuserUnsetContactLabel(partial: Partial<IuserUnsetContactLabel>): IuserUnsetContactLabel {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddContactAnnotation for store item
    */
    export interface IuserAddContactAnnotation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AnnotationMsg message
        */
        readonly msg?: cat.AnnotationMsg;
        /**
        * The AnnotationMsg list map
        */
        readonly list?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AnnotationMsg[];
    }

    /**
    * Generated partial userAddContactAnnotation, with default values.
    */
    export function createuserAddContactAnnotation(partial: Partial<IuserAddContactAnnotation>): IuserAddContactAnnotation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AnnotationMsg>(),
            infinite: partial.infinite || new Map<string, cat.AnnotationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveContactAnnotation for store item
    */
    export interface IuserRemoveContactAnnotation {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveContactAnnotation, with default values.
    */
    export function createuserRemoveContactAnnotation(partial: Partial<IuserRemoveContactAnnotation>): IuserRemoveContactAnnotation {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAccountConversationCounts for store item
    */
    export interface IuserGetAccountConversationCounts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The FilterCountMsg message
        */
        readonly msg?: cat.FilterCountMsg;
        /**
        * The FilterCountMsg list map
        */
        readonly list?: Map<string, cat.FilterCountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.FilterCountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.FilterCountMsg[];
    }

    /**
    * Generated partial userGetAccountConversationCounts, with default values.
    */
    export function createuserGetAccountConversationCounts(partial: Partial<IuserGetAccountConversationCounts>): IuserGetAccountConversationCounts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.FilterCountMsg>(),
            infinite: partial.infinite || new Map<string, cat.FilterCountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAccountServerCounts for store item
    */
    export interface IuserGetAccountServerCounts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The FilterCountMsg message
        */
        readonly msg?: cat.FilterCountMsg;
        /**
        * The FilterCountMsg list map
        */
        readonly list?: Map<string, cat.FilterCountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.FilterCountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.FilterCountMsg[];
    }

    /**
    * Generated partial userGetAccountServerCounts, with default values.
    */
    export function createuserGetAccountServerCounts(partial: Partial<IuserGetAccountServerCounts>): IuserGetAccountServerCounts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.FilterCountMsg>(),
            infinite: partial.infinite || new Map<string, cat.FilterCountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetServerConversationCounts for store item
    */
    export interface IuserGetServerConversationCounts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The FilterCountMsg message
        */
        readonly msg?: cat.FilterCountMsg;
        /**
        * The FilterCountMsg list map
        */
        readonly list?: Map<string, cat.FilterCountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.FilterCountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.FilterCountMsg[];
    }

    /**
    * Generated partial userGetServerConversationCounts, with default values.
    */
    export function createuserGetServerConversationCounts(partial: Partial<IuserGetServerConversationCounts>): IuserGetServerConversationCounts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.FilterCountMsg>(),
            infinite: partial.infinite || new Map<string, cat.FilterCountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationTopicCounts for store item
    */
    export interface IuserGetConversationTopicCounts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The FilterCountMsg message
        */
        readonly msg?: cat.FilterCountMsg;
        /**
        * The FilterCountMsg list map
        */
        readonly list?: Map<string, cat.FilterCountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.FilterCountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.FilterCountMsg[];
    }

    /**
    * Generated partial userGetConversationTopicCounts, with default values.
    */
    export function createuserGetConversationTopicCounts(partial: Partial<IuserGetConversationTopicCounts>): IuserGetConversationTopicCounts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.FilterCountMsg>(),
            infinite: partial.infinite || new Map<string, cat.FilterCountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetUserProfileOwner for store item
    */
    export interface IuserSetUserProfileOwner {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetUserProfileOwner, with default values.
    */
    export function createuserSetUserProfileOwner(partial: Partial<IuserSetUserProfileOwner>): IuserSetUserProfileOwner {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveUserProfileOwner for store item
    */
    export interface IuserRemoveUserProfileOwner {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveUserProfileOwner, with default values.
    */
    export function createuserRemoveUserProfileOwner(partial: Partial<IuserRemoveUserProfileOwner>): IuserRemoveUserProfileOwner {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserLinkSupervisorUserToUser for store item
    */
    export interface IuserLinkSupervisorUserToUser {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userLinkSupervisorUserToUser, with default values.
    */
    export function createuserLinkSupervisorUserToUser(partial: Partial<IuserLinkSupervisorUserToUser>): IuserLinkSupervisorUserToUser {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserUnlinkSupervisorUserFromUser for store item
    */
    export interface IuserUnlinkSupervisorUserFromUser {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userUnlinkSupervisorUserFromUser, with default values.
    */
    export function createuserUnlinkSupervisorUserFromUser(partial: Partial<IuserUnlinkSupervisorUserFromUser>): IuserUnlinkSupervisorUserFromUser {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDeviceBackupCount for store item
    */
    export interface IuserGetDeviceBackupCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetDeviceBackupCount, with default values.
    */
    export function createuserGetDeviceBackupCount(partial: Partial<IuserGetDeviceBackupCount>): IuserGetDeviceBackupCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveDeviceBackup for store item
    */
    export interface IuserRemoveDeviceBackup {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveDeviceBackup, with default values.
    */
    export function createuserRemoveDeviceBackup(partial: Partial<IuserRemoveDeviceBackup>): IuserRemoveDeviceBackup {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAPKCount for store item
    */
    export interface IuserGetAPKCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The QueryMsg message
        */
        readonly msg?: cat.QueryMsg;
        /**
        * The QueryMsg list map
        */
        readonly list?: Map<string, cat.QueryMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.QueryMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.QueryMsg[];
    }

    /**
    * Generated partial userGetAPKCount, with default values.
    */
    export function createuserGetAPKCount(partial: Partial<IuserGetAPKCount>): IuserGetAPKCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.QueryMsg>(),
            infinite: partial.infinite || new Map<string, cat.QueryMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveAPK for store item
    */
    export interface IuserRemoveAPK {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveAPK, with default values.
    */
    export function createuserRemoveAPK(partial: Partial<IuserRemoveAPK>): IuserRemoveAPK {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserPatchAPK for store item
    */
    export interface IuserPatchAPK {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userPatchAPK, with default values.
    */
    export function createuserPatchAPK(partial: Partial<IuserPatchAPK>): IuserPatchAPK {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDeviceLogFileId for store item
    */
    export interface IuserGetDeviceLogFileId {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaMsg message
        */
        readonly msg?: cat.MediaMsg;
        /**
        * The MediaMsg list map
        */
        readonly list?: Map<string, cat.MediaMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaMsg[];
    }

    /**
    * Generated partial userGetDeviceLogFileId, with default values.
    */
    export function createuserGetDeviceLogFileId(partial: Partial<IuserGetDeviceLogFileId>): IuserGetDeviceLogFileId {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAvailableDeviceLogFiles for store item
    */
    export interface IuserGetAvailableDeviceLogFiles {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The FilterMsg message
        */
        readonly msg?: cat.FilterMsg;
        /**
        * The FilterMsg list map
        */
        readonly list?: Map<string, cat.FilterMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.FilterMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.FilterMsg[];
    }

    /**
    * Generated partial userGetAvailableDeviceLogFiles, with default values.
    */
    export function createuserGetAvailableDeviceLogFiles(partial: Partial<IuserGetAvailableDeviceLogFiles>): IuserGetAvailableDeviceLogFiles {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.FilterMsg>(),
            infinite: partial.infinite || new Map<string, cat.FilterMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserRemoveAccount for store item
    */
    export interface IuserRemoveAccount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userRemoveAccount, with default values.
    */
    export function createuserRemoveAccount(partial: Partial<IuserRemoveAccount>): IuserRemoveAccount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserStartDataCleaning for store item
    */
    export interface IuserStartDataCleaning {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userStartDataCleaning, with default values.
    */
    export function createuserStartDataCleaning(partial: Partial<IuserStartDataCleaning>): IuserStartDataCleaning {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDataCleaningStatus for store item
    */
    export interface IuserGetDataCleaningStatus {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userGetDataCleaningStatus, with default values.
    */
    export function createuserGetDataCleaningStatus(partial: Partial<IuserGetDataCleaningStatus>): IuserGetDataCleaningStatus {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserProfilesExportFileId for store item
    */
    export interface IuserGetCurrentUserProfilesExportFileId {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaMsg message
        */
        readonly msg?: cat.MediaMsg;
        /**
        * The MediaMsg list map
        */
        readonly list?: Map<string, cat.MediaMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaMsg[];
    }

    /**
    * Generated partial userGetCurrentUserProfilesExportFileId, with default values.
    */
    export function createuserGetCurrentUserProfilesExportFileId(partial: Partial<IuserGetCurrentUserProfilesExportFileId>): IuserGetCurrentUserProfilesExportFileId {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationMemberExportFileId for store item
    */
    export interface IuserGetConversationMemberExportFileId {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaMsg message
        */
        readonly msg?: cat.MediaMsg;
        /**
        * The MediaMsg list map
        */
        readonly list?: Map<string, cat.MediaMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaMsg[];
    }

    /**
    * Generated partial userGetConversationMemberExportFileId, with default values.
    */
    export function createuserGetConversationMemberExportFileId(partial: Partial<IuserGetConversationMemberExportFileId>): IuserGetConversationMemberExportFileId {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDevicesExportFileId for store item
    */
    export interface IuserGetDevicesExportFileId {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaMsg message
        */
        readonly msg?: cat.MediaMsg;
        /**
        * The MediaMsg list map
        */
        readonly list?: Map<string, cat.MediaMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaMsg[];
    }

    /**
    * Generated partial userGetDevicesExportFileId, with default values.
    */
    export function createuserGetDevicesExportFileId(partial: Partial<IuserGetDevicesExportFileId>): IuserGetDevicesExportFileId {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetReportTemplates for store item
    */
    export interface IuserGetReportTemplates {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportTemplateMsg message
        */
        readonly msg?: cat.ReportTemplateMsg;
        /**
        * The ReportTemplateMsg list map
        */
        readonly list?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportTemplateMsg[];
    }

    /**
    * Generated partial userGetReportTemplates, with default values.
    */
    export function createuserGetReportTemplates(partial: Partial<IuserGetReportTemplates>): IuserGetReportTemplates {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportTemplateMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportTemplateMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserPermissions for store item
    */
    export interface IuserGetCurrentUserPermissions {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The PermissionMsg message
        */
        readonly msg?: cat.PermissionMsg;
        /**
        * The PermissionMsg list map
        */
        readonly list?: Map<string, cat.PermissionMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.PermissionMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.PermissionMsg[];
    }

    /**
    * Generated partial userGetCurrentUserPermissions, with default values.
    */
    export function createuserGetCurrentUserPermissions(partial: Partial<IuserGetCurrentUserPermissions>): IuserGetCurrentUserPermissions {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.PermissionMsg>(),
            infinite: partial.infinite || new Map<string, cat.PermissionMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserProfiles for store item
    */
    export interface IuserGetCurrentUserProfiles {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ProfileMsg message
        */
        readonly msg?: cat.ProfileMsg;
        /**
        * The ProfileMsg list map
        */
        readonly list?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ProfileMsg[];
    }

    /**
    * Generated partial userGetCurrentUserProfiles, with default values.
    */
    export function createuserGetCurrentUserProfiles(partial: Partial<IuserGetCurrentUserProfiles>): IuserGetCurrentUserProfiles {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ProfileMsg>(),
            infinite: partial.infinite || new Map<string, cat.ProfileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetUserProfiles for store item
    */
    export interface IuserGetUserProfiles {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ProfileMsg message
        */
        readonly msg?: cat.ProfileMsg;
        /**
        * The ProfileMsg list map
        */
        readonly list?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ProfileMsg[];
    }

    /**
    * Generated partial userGetUserProfiles, with default values.
    */
    export function createuserGetUserProfiles(partial: Partial<IuserGetUserProfiles>): IuserGetUserProfiles {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ProfileMsg>(),
            infinite: partial.infinite || new Map<string, cat.ProfileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserCases for store item
    */
    export interface IuserGetCurrentUserCases {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The CaseMsg message
        */
        readonly msg?: cat.CaseMsg;
        /**
        * The CaseMsg list map
        */
        readonly list?: Map<string, cat.CaseMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.CaseMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.CaseMsg[];
    }

    /**
    * Generated partial userGetCurrentUserCases, with default values.
    */
    export function createuserGetCurrentUserCases(partial: Partial<IuserGetCurrentUserCases>): IuserGetCurrentUserCases {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.CaseMsg>(),
            infinite: partial.infinite || new Map<string, cat.CaseMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAvailableCaseProfiles for store item
    */
    export interface IuserGetAvailableCaseProfiles {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ProfileMsg message
        */
        readonly msg?: cat.ProfileMsg;
        /**
        * The ProfileMsg list map
        */
        readonly list?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ProfileMsg[];
    }

    /**
    * Generated partial userGetAvailableCaseProfiles, with default values.
    */
    export function createuserGetAvailableCaseProfiles(partial: Partial<IuserGetAvailableCaseProfiles>): IuserGetAvailableCaseProfiles {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ProfileMsg>(),
            infinite: partial.infinite || new Map<string, cat.ProfileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserProfileReports for store item
    */
    export interface IuserGetCurrentUserProfileReports {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportMsg message
        */
        readonly msg?: cat.ReportMsg;
        /**
        * The ReportMsg list map
        */
        readonly list?: Map<string, cat.ReportMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportMsg[];
    }

    /**
    * Generated partial userGetCurrentUserProfileReports, with default values.
    */
    export function createuserGetCurrentUserProfileReports(partial: Partial<IuserGetCurrentUserProfileReports>): IuserGetCurrentUserProfileReports {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserProfileMedia for store item
    */
    export interface IuserGetCurrentUserProfileMedia {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AttachmentMsg message
        */
        readonly msg?: cat.AttachmentMsg;
        /**
        * The AttachmentMsg list map
        */
        readonly list?: Map<string, cat.AttachmentMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AttachmentMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AttachmentMsg[];
    }

    /**
    * Generated partial userGetCurrentUserProfileMedia, with default values.
    */
    export function createuserGetCurrentUserProfileMedia(partial: Partial<IuserGetCurrentUserProfileMedia>): IuserGetCurrentUserProfileMedia {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AttachmentMsg>(),
            infinite: partial.infinite || new Map<string, cat.AttachmentMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserDevices for store item
    */
    export interface IuserGetCurrentUserDevices {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The DeviceMsg message
        */
        readonly msg?: cat.DeviceMsg;
        /**
        * The DeviceMsg list map
        */
        readonly list?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.DeviceMsg[];
    }

    /**
    * Generated partial userGetCurrentUserDevices, with default values.
    */
    export function createuserGetCurrentUserDevices(partial: Partial<IuserGetCurrentUserDevices>): IuserGetCurrentUserDevices {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.DeviceMsg>(),
            infinite: partial.infinite || new Map<string, cat.DeviceMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserNotifications for store item
    */
    export interface IuserGetCurrentUserNotifications {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The NotificationMsg message
        */
        readonly msg?: cat.NotificationMsg;
        /**
        * The NotificationMsg list map
        */
        readonly list?: Map<string, cat.NotificationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.NotificationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.NotificationMsg[];
    }

    /**
    * Generated partial userGetCurrentUserNotifications, with default values.
    */
    export function createuserGetCurrentUserNotifications(partial: Partial<IuserGetCurrentUserNotifications>): IuserGetCurrentUserNotifications {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.NotificationMsg>(),
            infinite: partial.infinite || new Map<string, cat.NotificationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetContactMerges for store item
    */
    export interface IuserGetContactMerges {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userGetContactMerges, with default values.
    */
    export function createuserGetContactMerges(partial: Partial<IuserGetContactMerges>): IuserGetContactMerges {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCurrentUserThemes for store item
    */
    export interface IuserGetCurrentUserThemes {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ThemeMsg message
        */
        readonly msg?: cat.ThemeMsg;
        /**
        * The ThemeMsg list map
        */
        readonly list?: Map<string, cat.ThemeMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ThemeMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ThemeMsg[];
    }

    /**
    * Generated partial userGetCurrentUserThemes, with default values.
    */
    export function createuserGetCurrentUserThemes(partial: Partial<IuserGetCurrentUserThemes>): IuserGetCurrentUserThemes {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ThemeMsg>(),
            infinite: partial.infinite || new Map<string, cat.ThemeMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAccountMedia for store item
    */
    export interface IuserGetAccountMedia {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaFileMsg message
        */
        readonly msg?: cat.MediaFileMsg;
        /**
        * The MediaFileMsg list map
        */
        readonly list?: Map<string, cat.MediaFileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaFileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaFileMsg[];
    }

    /**
    * Generated partial userGetAccountMedia, with default values.
    */
    export function createuserGetAccountMedia(partial: Partial<IuserGetAccountMedia>): IuserGetAccountMedia {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaFileMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaFileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileAccounts for store item
    */
    export interface IuserGetProfileAccounts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userGetProfileAccounts, with default values.
    */
    export function createuserGetProfileAccounts(partial: Partial<IuserGetProfileAccounts>): IuserGetProfileAccounts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileDevices for store item
    */
    export interface IuserGetProfileDevices {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The DeviceMsg message
        */
        readonly msg?: cat.DeviceMsg;
        /**
        * The DeviceMsg list map
        */
        readonly list?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.DeviceMsg[];
    }

    /**
    * Generated partial userGetProfileDevices, with default values.
    */
    export function createuserGetProfileDevices(partial: Partial<IuserGetProfileDevices>): IuserGetProfileDevices {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.DeviceMsg>(),
            infinite: partial.infinite || new Map<string, cat.DeviceMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileAccountsMediaCount for store item
    */
    export interface IuserGetProfileAccountsMediaCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userGetProfileAccountsMediaCount, with default values.
    */
    export function createuserGetProfileAccountsMediaCount(partial: Partial<IuserGetProfileAccountsMediaCount>): IuserGetProfileAccountsMediaCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetRolesWithPermissions for store item
    */
    export interface IuserGetRolesWithPermissions {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The RoleMsg message
        */
        readonly msg?: cat.RoleMsg;
        /**
        * The RoleMsg list map
        */
        readonly list?: Map<string, cat.RoleMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.RoleMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.RoleMsg[];
    }

    /**
    * Generated partial userGetRolesWithPermissions, with default values.
    */
    export function createuserGetRolesWithPermissions(partial: Partial<IuserGetRolesWithPermissions>): IuserGetRolesWithPermissions {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.RoleMsg>(),
            infinite: partial.infinite || new Map<string, cat.RoleMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetUsersAssignableToProfile for store item
    */
    export interface IuserGetUsersAssignableToProfile {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userGetUsersAssignableToProfile, with default values.
    */
    export function createuserGetUsersAssignableToProfile(partial: Partial<IuserGetUsersAssignableToProfile>): IuserGetUsersAssignableToProfile {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDomains for store item
    */
    export interface IuserGetDomains {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The DomainMsg message
        */
        readonly msg?: cat.DomainMsg;
        /**
        * The DomainMsg list map
        */
        readonly list?: Map<string, cat.DomainMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.DomainMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.DomainMsg[];
    }

    /**
    * Generated partial userGetDomains, with default values.
    */
    export function createuserGetDomains(partial: Partial<IuserGetDomains>): IuserGetDomains {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.DomainMsg>(),
            infinite: partial.infinite || new Map<string, cat.DomainMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAvailableReportTemplates for store item
    */
    export interface IuserGetAvailableReportTemplates {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportTemplateMsg message
        */
        readonly msg?: cat.ReportTemplateMsg;
        /**
        * The ReportTemplateMsg list map
        */
        readonly list?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportTemplateMsg[];
    }

    /**
    * Generated partial userGetAvailableReportTemplates, with default values.
    */
    export function createuserGetAvailableReportTemplates(partial: Partial<IuserGetAvailableReportTemplates>): IuserGetAvailableReportTemplates {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportTemplateMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportTemplateMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetReportingLabels for store item
    */
    export interface IuserGetReportingLabels {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userGetReportingLabels, with default values.
    */
    export function createuserGetReportingLabels(partial: Partial<IuserGetReportingLabels>): IuserGetReportingLabels {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetReportingAccounts for store item
    */
    export interface IuserGetReportingAccounts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userGetReportingAccounts, with default values.
    */
    export function createuserGetReportingAccounts(partial: Partial<IuserGetReportingAccounts>): IuserGetReportingAccounts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileConversations for store item
    */
    export interface IuserGetProfileConversations {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ConversationMsg message
        */
        readonly msg?: cat.ConversationMsg;
        /**
        * The ConversationMsg list map
        */
        readonly list?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ConversationMsg[];
    }

    /**
    * Generated partial userGetProfileConversations, with default values.
    */
    export function createuserGetProfileConversations(partial: Partial<IuserGetProfileConversations>): IuserGetProfileConversations {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ConversationMsg>(),
            infinite: partial.infinite || new Map<string, cat.ConversationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetUsers for store item
    */
    export interface IuserGetUsers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userGetUsers, with default values.
    */
    export function createuserGetUsers(partial: Partial<IuserGetUsers>): IuserGetUsers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetRoles for store item
    */
    export interface IuserGetRoles {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The RoleMsg message
        */
        readonly msg?: cat.RoleMsg;
        /**
        * The RoleMsg list map
        */
        readonly list?: Map<string, cat.RoleMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.RoleMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.RoleMsg[];
    }

    /**
    * Generated partial userGetRoles, with default values.
    */
    export function createuserGetRoles(partial: Partial<IuserGetRoles>): IuserGetRoles {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.RoleMsg>(),
            infinite: partial.infinite || new Map<string, cat.RoleMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetThemes for store item
    */
    export interface IuserGetThemes {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ThemeMsg message
        */
        readonly msg?: cat.ThemeMsg;
        /**
        * The ThemeMsg list map
        */
        readonly list?: Map<string, cat.ThemeMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ThemeMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ThemeMsg[];
    }

    /**
    * Generated partial userGetThemes, with default values.
    */
    export function createuserGetThemes(partial: Partial<IuserGetThemes>): IuserGetThemes {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ThemeMsg>(),
            infinite: partial.infinite || new Map<string, cat.ThemeMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetThemeUsers for store item
    */
    export interface IuserGetThemeUsers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userGetThemeUsers, with default values.
    */
    export function createuserGetThemeUsers(partial: Partial<IuserGetThemeUsers>): IuserGetThemeUsers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetThemeUserProfileAccounts for store item
    */
    export interface IuserGetThemeUserProfileAccounts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ProfileMsg message
        */
        readonly msg?: cat.ProfileMsg;
        /**
        * The ProfileMsg list map
        */
        readonly list?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ProfileMsg[];
    }

    /**
    * Generated partial userGetThemeUserProfileAccounts, with default values.
    */
    export function createuserGetThemeUserProfileAccounts(partial: Partial<IuserGetThemeUserProfileAccounts>): IuserGetThemeUserProfileAccounts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ProfileMsg>(),
            infinite: partial.infinite || new Map<string, cat.ProfileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAppTypes for store item
    */
    export interface IuserGetAppTypes {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AppTypeMsg message
        */
        readonly msg?: cat.AppTypeMsg;
        /**
        * The AppTypeMsg list map
        */
        readonly list?: Map<string, cat.AppTypeMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AppTypeMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AppTypeMsg[];
    }

    /**
    * Generated partial userGetAppTypes, with default values.
    */
    export function createuserGetAppTypes(partial: Partial<IuserGetAppTypes>): IuserGetAppTypes {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AppTypeMsg>(),
            infinite: partial.infinite || new Map<string, cat.AppTypeMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCases for store item
    */
    export interface IuserGetCases {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The CaseMsg message
        */
        readonly msg?: cat.CaseMsg;
        /**
        * The CaseMsg list map
        */
        readonly list?: Map<string, cat.CaseMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.CaseMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.CaseMsg[];
    }

    /**
    * Generated partial userGetCases, with default values.
    */
    export function createuserGetCases(partial: Partial<IuserGetCases>): IuserGetCases {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.CaseMsg>(),
            infinite: partial.infinite || new Map<string, cat.CaseMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDevices for store item
    */
    export interface IuserGetDevices {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The DeviceMsg message
        */
        readonly msg?: cat.DeviceMsg;
        /**
        * The DeviceMsg list map
        */
        readonly list?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.DeviceMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.DeviceMsg[];
    }

    /**
    * Generated partial userGetDevices, with default values.
    */
    export function createuserGetDevices(partial: Partial<IuserGetDevices>): IuserGetDevices {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.DeviceMsg>(),
            infinite: partial.infinite || new Map<string, cat.DeviceMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDeviceUsers for store item
    */
    export interface IuserGetDeviceUsers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userGetDeviceUsers, with default values.
    */
    export function createuserGetDeviceUsers(partial: Partial<IuserGetDeviceUsers>): IuserGetDeviceUsers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfiles for store item
    */
    export interface IuserGetProfiles {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ProfileMsg message
        */
        readonly msg?: cat.ProfileMsg;
        /**
        * The ProfileMsg list map
        */
        readonly list?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ProfileMsg[];
    }

    /**
    * Generated partial userGetProfiles, with default values.
    */
    export function createuserGetProfiles(partial: Partial<IuserGetProfiles>): IuserGetProfiles {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ProfileMsg>(),
            infinite: partial.infinite || new Map<string, cat.ProfileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileUsers for store item
    */
    export interface IuserGetProfileUsers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userGetProfileUsers, with default values.
    */
    export function createuserGetProfileUsers(partial: Partial<IuserGetProfileUsers>): IuserGetProfileUsers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCaseProfiles for store item
    */
    export interface IuserGetCaseProfiles {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ProfileMsg message
        */
        readonly msg?: cat.ProfileMsg;
        /**
        * The ProfileMsg list map
        */
        readonly list?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ProfileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ProfileMsg[];
    }

    /**
    * Generated partial userGetCaseProfiles, with default values.
    */
    export function createuserGetCaseProfiles(partial: Partial<IuserGetCaseProfiles>): IuserGetCaseProfiles {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ProfileMsg>(),
            infinite: partial.infinite || new Map<string, cat.ProfileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetCaseLabels for store item
    */
    export interface IuserGetCaseLabels {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userGetCaseLabels, with default values.
    */
    export function createuserGetCaseLabels(partial: Partial<IuserGetCaseLabels>): IuserGetCaseLabels {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileLabels for store item
    */
    export interface IuserGetProfileLabels {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userGetProfileLabels, with default values.
    */
    export function createuserGetProfileLabels(partial: Partial<IuserGetProfileLabels>): IuserGetProfileLabels {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetProfileContacts for store item
    */
    export interface IuserGetProfileContacts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userGetProfileContacts, with default values.
    */
    export function createuserGetProfileContacts(partial: Partial<IuserGetProfileContacts>): IuserGetProfileContacts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetContactConversations for store item
    */
    export interface IuserGetContactConversations {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ConversationMsg message
        */
        readonly msg?: cat.ConversationMsg;
        /**
        * The ConversationMsg list map
        */
        readonly list?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ConversationMsg[];
    }

    /**
    * Generated partial userGetContactConversations, with default values.
    */
    export function createuserGetContactConversations(partial: Partial<IuserGetContactConversations>): IuserGetContactConversations {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ConversationMsg>(),
            infinite: partial.infinite || new Map<string, cat.ConversationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDeviceAccounts for store item
    */
    export interface IuserGetDeviceAccounts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userGetDeviceAccounts, with default values.
    */
    export function createuserGetDeviceAccounts(partial: Partial<IuserGetDeviceAccounts>): IuserGetDeviceAccounts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAccountContacts for store item
    */
    export interface IuserGetAccountContacts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userGetAccountContacts, with default values.
    */
    export function createuserGetAccountContacts(partial: Partial<IuserGetAccountContacts>): IuserGetAccountContacts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationMessages for store item
    */
    export interface IuserGetConversationMessages {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MessageMsg message
        */
        readonly msg?: cat.MessageMsg;
        /**
        * The MessageMsg list map
        */
        readonly list?: Map<string, cat.MessageMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MessageMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MessageMsg[];
    }

    /**
    * Generated partial userGetConversationMessages, with default values.
    */
    export function createuserGetConversationMessages(partial: Partial<IuserGetConversationMessages>): IuserGetConversationMessages {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MessageMsg>(),
            infinite: partial.infinite || new Map<string, cat.MessageMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetMessageMessages for store item
    */
    export interface IuserGetMessageMessages {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MessageMsg message
        */
        readonly msg?: cat.MessageMsg;
        /**
        * The MessageMsg list map
        */
        readonly list?: Map<string, cat.MessageMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MessageMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MessageMsg[];
    }

    /**
    * Generated partial userGetMessageMessages, with default values.
    */
    export function createuserGetMessageMessages(partial: Partial<IuserGetMessageMessages>): IuserGetMessageMessages {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MessageMsg>(),
            infinite: partial.infinite || new Map<string, cat.MessageMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSearchConversationMessages for store item
    */
    export interface IuserSearchConversationMessages {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MessageMsg message
        */
        readonly msg?: cat.MessageMsg;
        /**
        * The MessageMsg list map
        */
        readonly list?: Map<string, cat.MessageMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MessageMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MessageMsg[];
    }

    /**
    * Generated partial userSearchConversationMessages, with default values.
    */
    export function createuserSearchConversationMessages(partial: Partial<IuserSearchConversationMessages>): IuserSearchConversationMessages {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MessageMsg>(),
            infinite: partial.infinite || new Map<string, cat.MessageMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSearchTopicMessages for store item
    */
    export interface IuserSearchTopicMessages {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MessageMsg message
        */
        readonly msg?: cat.MessageMsg;
        /**
        * The MessageMsg list map
        */
        readonly list?: Map<string, cat.MessageMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MessageMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MessageMsg[];
    }

    /**
    * Generated partial userSearchTopicMessages, with default values.
    */
    export function createuserSearchTopicMessages(partial: Partial<IuserSearchTopicMessages>): IuserSearchTopicMessages {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MessageMsg>(),
            infinite: partial.infinite || new Map<string, cat.MessageMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetTopicMessages for store item
    */
    export interface IuserGetTopicMessages {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MessageMsg message
        */
        readonly msg?: cat.MessageMsg;
        /**
        * The MessageMsg list map
        */
        readonly list?: Map<string, cat.MessageMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MessageMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MessageMsg[];
    }

    /**
    * Generated partial userGetTopicMessages, with default values.
    */
    export function createuserGetTopicMessages(partial: Partial<IuserGetTopicMessages>): IuserGetTopicMessages {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MessageMsg>(),
            infinite: partial.infinite || new Map<string, cat.MessageMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetMessageAttachments for store item
    */
    export interface IuserGetMessageAttachments {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AttachmentMsg message
        */
        readonly msg?: cat.AttachmentMsg;
        /**
        * The AttachmentMsg list map
        */
        readonly list?: Map<string, cat.AttachmentMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AttachmentMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AttachmentMsg[];
    }

    /**
    * Generated partial userGetMessageAttachments, with default values.
    */
    export function createuserGetMessageAttachments(partial: Partial<IuserGetMessageAttachments>): IuserGetMessageAttachments {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AttachmentMsg>(),
            infinite: partial.infinite || new Map<string, cat.AttachmentMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetMessageReactions for store item
    */
    export interface IuserGetMessageReactions {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReactionMsg message
        */
        readonly msg?: cat.ReactionMsg;
        /**
        * The ReactionMsg list map
        */
        readonly list?: Map<string, cat.ReactionMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReactionMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReactionMsg[];
    }

    /**
    * Generated partial userGetMessageReactions, with default values.
    */
    export function createuserGetMessageReactions(partial: Partial<IuserGetMessageReactions>): IuserGetMessageReactions {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReactionMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReactionMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetGlobalLabels for store item
    */
    export interface IuserGetGlobalLabels {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userGetGlobalLabels, with default values.
    */
    export function createuserGetGlobalLabels(partial: Partial<IuserGetGlobalLabels>): IuserGetGlobalLabels {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetMessageLabels for store item
    */
    export interface IuserGetMessageLabels {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userGetMessageLabels, with default values.
    */
    export function createuserGetMessageLabels(partial: Partial<IuserGetMessageLabels>): IuserGetMessageLabels {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetMessageAnnotations for store item
    */
    export interface IuserGetMessageAnnotations {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AnnotationMsg message
        */
        readonly msg?: cat.AnnotationMsg;
        /**
        * The AnnotationMsg list map
        */
        readonly list?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AnnotationMsg[];
    }

    /**
    * Generated partial userGetMessageAnnotations, with default values.
    */
    export function createuserGetMessageAnnotations(partial: Partial<IuserGetMessageAnnotations>): IuserGetMessageAnnotations {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AnnotationMsg>(),
            infinite: partial.infinite || new Map<string, cat.AnnotationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationAnnotations for store item
    */
    export interface IuserGetConversationAnnotations {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AnnotationMsg message
        */
        readonly msg?: cat.AnnotationMsg;
        /**
        * The AnnotationMsg list map
        */
        readonly list?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AnnotationMsg[];
    }

    /**
    * Generated partial userGetConversationAnnotations, with default values.
    */
    export function createuserGetConversationAnnotations(partial: Partial<IuserGetConversationAnnotations>): IuserGetConversationAnnotations {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AnnotationMsg>(),
            infinite: partial.infinite || new Map<string, cat.AnnotationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetTopicLabels for store item
    */
    export interface IuserGetTopicLabels {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userGetTopicLabels, with default values.
    */
    export function createuserGetTopicLabels(partial: Partial<IuserGetTopicLabels>): IuserGetTopicLabels {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetTopicAnnotations for store item
    */
    export interface IuserGetTopicAnnotations {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AnnotationMsg message
        */
        readonly msg?: cat.AnnotationMsg;
        /**
        * The AnnotationMsg list map
        */
        readonly list?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AnnotationMsg[];
    }

    /**
    * Generated partial userGetTopicAnnotations, with default values.
    */
    export function createuserGetTopicAnnotations(partial: Partial<IuserGetTopicAnnotations>): IuserGetTopicAnnotations {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AnnotationMsg>(),
            infinite: partial.infinite || new Map<string, cat.AnnotationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetServerLabels for store item
    */
    export interface IuserGetServerLabels {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userGetServerLabels, with default values.
    */
    export function createuserGetServerLabels(partial: Partial<IuserGetServerLabels>): IuserGetServerLabels {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetServerAnnotations for store item
    */
    export interface IuserGetServerAnnotations {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AnnotationMsg message
        */
        readonly msg?: cat.AnnotationMsg;
        /**
        * The AnnotationMsg list map
        */
        readonly list?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AnnotationMsg[];
    }

    /**
    * Generated partial userGetServerAnnotations, with default values.
    */
    export function createuserGetServerAnnotations(partial: Partial<IuserGetServerAnnotations>): IuserGetServerAnnotations {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AnnotationMsg>(),
            infinite: partial.infinite || new Map<string, cat.AnnotationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationLabels for store item
    */
    export interface IuserGetConversationLabels {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userGetConversationLabels, with default values.
    */
    export function createuserGetConversationLabels(partial: Partial<IuserGetConversationLabels>): IuserGetConversationLabels {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAccountServers for store item
    */
    export interface IuserGetAccountServers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ServerMsg message
        */
        readonly msg?: cat.ServerMsg;
        /**
        * The ServerMsg list map
        */
        readonly list?: Map<string, cat.ServerMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ServerMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ServerMsg[];
    }

    /**
    * Generated partial userGetAccountServers, with default values.
    */
    export function createuserGetAccountServers(partial: Partial<IuserGetAccountServers>): IuserGetAccountServers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ServerMsg>(),
            infinite: partial.infinite || new Map<string, cat.ServerMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAccountConversations for store item
    */
    export interface IuserGetAccountConversations {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ConversationMsg message
        */
        readonly msg?: cat.ConversationMsg;
        /**
        * The ConversationMsg list map
        */
        readonly list?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ConversationMsg[];
    }

    /**
    * Generated partial userGetAccountConversations, with default values.
    */
    export function createuserGetAccountConversations(partial: Partial<IuserGetAccountConversations>): IuserGetAccountConversations {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ConversationMsg>(),
            infinite: partial.infinite || new Map<string, cat.ConversationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetServerConversations for store item
    */
    export interface IuserGetServerConversations {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ConversationMsg message
        */
        readonly msg?: cat.ConversationMsg;
        /**
        * The ConversationMsg list map
        */
        readonly list?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ConversationMsg[];
    }

    /**
    * Generated partial userGetServerConversations, with default values.
    */
    export function createuserGetServerConversations(partial: Partial<IuserGetServerConversations>): IuserGetServerConversations {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ConversationMsg>(),
            infinite: partial.infinite || new Map<string, cat.ConversationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationTopics for store item
    */
    export interface IuserGetConversationTopics {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The TopicMsg message
        */
        readonly msg?: cat.TopicMsg;
        /**
        * The TopicMsg list map
        */
        readonly list?: Map<string, cat.TopicMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.TopicMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.TopicMsg[];
    }

    /**
    * Generated partial userGetConversationTopics, with default values.
    */
    export function createuserGetConversationTopics(partial: Partial<IuserGetConversationTopics>): IuserGetConversationTopics {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.TopicMsg>(),
            infinite: partial.infinite || new Map<string, cat.TopicMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationMembers for store item
    */
    export interface IuserGetConversationMembers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AccountMsg message
        */
        readonly msg?: cat.AccountMsg;
        /**
        * The AccountMsg list map
        */
        readonly list?: Map<string, cat.AccountMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AccountMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AccountMsg[];
    }

    /**
    * Generated partial userGetConversationMembers, with default values.
    */
    export function createuserGetConversationMembers(partial: Partial<IuserGetConversationMembers>): IuserGetConversationMembers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AccountMsg>(),
            infinite: partial.infinite || new Map<string, cat.AccountMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetConversationMedia for store item
    */
    export interface IuserGetConversationMedia {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaFileMsg message
        */
        readonly msg?: cat.MediaFileMsg;
        /**
        * The MediaFileMsg list map
        */
        readonly list?: Map<string, cat.MediaFileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaFileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaFileMsg[];
    }

    /**
    * Generated partial userGetConversationMedia, with default values.
    */
    export function createuserGetConversationMedia(partial: Partial<IuserGetConversationMedia>): IuserGetConversationMedia {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaFileMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaFileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetContactLabels for store item
    */
    export interface IuserGetContactLabels {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The LabelMsg message
        */
        readonly msg?: cat.LabelMsg;
        /**
        * The LabelMsg list map
        */
        readonly list?: Map<string, cat.LabelMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.LabelMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.LabelMsg[];
    }

    /**
    * Generated partial userGetContactLabels, with default values.
    */
    export function createuserGetContactLabels(partial: Partial<IuserGetContactLabels>): IuserGetContactLabels {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.LabelMsg>(),
            infinite: partial.infinite || new Map<string, cat.LabelMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetContactAnnotations for store item
    */
    export interface IuserGetContactAnnotations {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AnnotationMsg message
        */
        readonly msg?: cat.AnnotationMsg;
        /**
        * The AnnotationMsg list map
        */
        readonly list?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AnnotationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AnnotationMsg[];
    }

    /**
    * Generated partial userGetContactAnnotations, with default values.
    */
    export function createuserGetContactAnnotations(partial: Partial<IuserGetContactAnnotations>): IuserGetContactAnnotations {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AnnotationMsg>(),
            infinite: partial.infinite || new Map<string, cat.AnnotationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSearchAccountsAndContactsSystemWide for store item
    */
    export interface IuserSearchAccountsAndContactsSystemWide {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userSearchAccountsAndContactsSystemWide, with default values.
    */
    export function createuserSearchAccountsAndContactsSystemWide(partial: Partial<IuserSearchAccountsAndContactsSystemWide>): IuserSearchAccountsAndContactsSystemWide {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSearchConversationsSystemWide for store item
    */
    export interface IuserSearchConversationsSystemWide {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ConversationMsg message
        */
        readonly msg?: cat.ConversationMsg;
        /**
        * The ConversationMsg list map
        */
        readonly list?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ConversationMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ConversationMsg[];
    }

    /**
    * Generated partial userSearchConversationsSystemWide, with default values.
    */
    export function createuserSearchConversationsSystemWide(partial: Partial<IuserSearchConversationsSystemWide>): IuserSearchConversationsSystemWide {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ConversationMsg>(),
            infinite: partial.infinite || new Map<string, cat.ConversationMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetSupervisorUsers for store item
    */
    export interface IuserGetSupervisorUsers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userGetSupervisorUsers, with default values.
    */
    export function createuserGetSupervisorUsers(partial: Partial<IuserGetSupervisorUsers>): IuserGetSupervisorUsers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetLinkableToSupervisorUsers for store item
    */
    export interface IuserGetLinkableToSupervisorUsers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userGetLinkableToSupervisorUsers, with default values.
    */
    export function createuserGetLinkableToSupervisorUsers(partial: Partial<IuserGetLinkableToSupervisorUsers>): IuserGetLinkableToSupervisorUsers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetLinkableToDeviceUsers for store item
    */
    export interface IuserGetLinkableToDeviceUsers {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The UserMsg message
        */
        readonly msg?: cat.UserMsg;
        /**
        * The UserMsg list map
        */
        readonly list?: Map<string, cat.UserMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.UserMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.UserMsg[];
    }

    /**
    * Generated partial userGetLinkableToDeviceUsers, with default values.
    */
    export function createuserGetLinkableToDeviceUsers(partial: Partial<IuserGetLinkableToDeviceUsers>): IuserGetLinkableToDeviceUsers {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.UserMsg>(),
            infinite: partial.infinite || new Map<string, cat.UserMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDeviceBackups for store item
    */
    export interface IuserGetDeviceBackups {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The BackUpMsg message
        */
        readonly msg?: cat.BackUpMsg;
        /**
        * The BackUpMsg list map
        */
        readonly list?: Map<string, cat.BackUpMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.BackUpMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.BackUpMsg[];
    }

    /**
    * Generated partial userGetDeviceBackups, with default values.
    */
    export function createuserGetDeviceBackups(partial: Partial<IuserGetDeviceBackups>): IuserGetDeviceBackups {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.BackUpMsg>(),
            infinite: partial.infinite || new Map<string, cat.BackUpMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAPKS for store item
    */
    export interface IuserGetAPKS {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The APKMsg message
        */
        readonly msg?: cat.APKMsg;
        /**
        * The APKMsg list map
        */
        readonly list?: Map<string, cat.APKMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.APKMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.APKMsg[];
    }

    /**
    * Generated partial userGetAPKS, with default values.
    */
    export function createuserGetAPKS(partial: Partial<IuserGetAPKS>): IuserGetAPKS {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.APKMsg>(),
            infinite: partial.infinite || new Map<string, cat.APKMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetAppReceiverMedia for store item
    */
    export interface IuserGetAppReceiverMedia {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaFileMsg message
        */
        readonly msg?: cat.MediaFileMsg;
        /**
        * The MediaFileMsg list map
        */
        readonly list?: Map<string, cat.MediaFileMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaFileMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaFileMsg[];
    }

    /**
    * Generated partial userGetAppReceiverMedia, with default values.
    */
    export function createuserGetAppReceiverMedia(partial: Partial<IuserGetAppReceiverMedia>): IuserGetAppReceiverMedia {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaFileMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaFileMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetDeviceReceiverMediaCount for store item
    */
    export interface IuserGetDeviceReceiverMediaCount {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AppMsg message
        */
        readonly msg?: cat.AppMsg;
        /**
        * The AppMsg list map
        */
        readonly list?: Map<string, cat.AppMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AppMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AppMsg[];
    }

    /**
    * Generated partial userGetDeviceReceiverMediaCount, with default values.
    */
    export function createuserGetDeviceReceiverMediaCount(partial: Partial<IuserGetDeviceReceiverMediaCount>): IuserGetDeviceReceiverMediaCount {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AppMsg>(),
            infinite: partial.infinite || new Map<string, cat.AppMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetCurrentUserProfileMediaStream for store item
    */
    export interface IuserSetCurrentUserProfileMediaStream {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetCurrentUserProfileMediaStream, with default values.
    */
    export function createuserSetCurrentUserProfileMediaStream(partial: Partial<IuserSetCurrentUserProfileMediaStream>): IuserSetCurrentUserProfileMediaStream {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserSetCurrentUserNotificationsRead for store item
    */
    export interface IuserSetCurrentUserNotificationsRead {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userSetCurrentUserNotificationsRead, with default values.
    */
    export function createuserSetCurrentUserNotificationsRead(partial: Partial<IuserSetCurrentUserNotificationsRead>): IuserSetCurrentUserNotificationsRead {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserMergeContacts for store item
    */
    export interface IuserMergeContacts {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userMergeContacts, with default values.
    */
    export function createuserMergeContacts(partial: Partial<IuserMergeContacts>): IuserMergeContacts {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddReportTemplateFiles for store item
    */
    export interface IuserAddReportTemplateFiles {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportTemplateMsg message
        */
        readonly msg?: cat.ReportTemplateMsg;
        /**
        * The ReportTemplateMsg list map
        */
        readonly list?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportTemplateMsg[];
    }

    /**
    * Generated partial userAddReportTemplateFiles, with default values.
    */
    export function createuserAddReportTemplateFiles(partial: Partial<IuserAddReportTemplateFiles>): IuserAddReportTemplateFiles {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportTemplateMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportTemplateMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddReportTemplateFile for store item
    */
    export interface IuserAddReportTemplateFile {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The ReportTemplateMsg message
        */
        readonly msg?: cat.ReportTemplateMsg;
        /**
        * The ReportTemplateMsg list map
        */
        readonly list?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.ReportTemplateMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.ReportTemplateMsg[];
    }

    /**
    * Generated partial userAddReportTemplateFile, with default values.
    */
    export function createuserAddReportTemplateFile(partial: Partial<IuserAddReportTemplateFile>): IuserAddReportTemplateFile {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.ReportTemplateMsg>(),
            infinite: partial.infinite || new Map<string, cat.ReportTemplateMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddAnnotationAttachment for store item
    */
    export interface IuserAddAnnotationAttachment {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The AttachmentMsg message
        */
        readonly msg?: cat.AttachmentMsg;
        /**
        * The AttachmentMsg list map
        */
        readonly list?: Map<string, cat.AttachmentMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.AttachmentMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.AttachmentMsg[];
    }

    /**
    * Generated partial userAddAnnotationAttachment, with default values.
    */
    export function createuserAddAnnotationAttachment(partial: Partial<IuserAddAnnotationAttachment>): IuserAddAnnotationAttachment {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.AttachmentMsg>(),
            infinite: partial.infinite || new Map<string, cat.AttachmentMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserAddAPK for store item
    */
    export interface IuserAddAPK {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The APKMsg message
        */
        readonly msg?: cat.APKMsg;
        /**
        * The APKMsg list map
        */
        readonly list?: Map<string, cat.APKMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.APKMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.APKMsg[];
    }

    /**
    * Generated partial userAddAPK, with default values.
    */
    export function createuserAddAPK(partial: Partial<IuserAddAPK>): IuserAddAPK {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.APKMsg>(),
            infinite: partial.infinite || new Map<string, cat.APKMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserCancelReport for store item
    */
    export interface IuserCancelReport {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The VoidMsg message
        */
        readonly msg?: cat.VoidMsg;
        /**
        * The VoidMsg list map
        */
        readonly list?: Map<string, cat.VoidMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.VoidMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.VoidMsg[];
    }

    /**
    * Generated partial userCancelReport, with default values.
    */
    export function createuserCancelReport(partial: Partial<IuserCancelReport>): IuserCancelReport {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.VoidMsg>(),
            infinite: partial.infinite || new Map<string, cat.VoidMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetReportPreviewFileId for store item
    */
    export interface IuserGetReportPreviewFileId {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaMsg message
        */
        readonly msg?: cat.MediaMsg;
        /**
        * The MediaMsg list map
        */
        readonly list?: Map<string, cat.MediaMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaMsg[];
    }

    /**
    * Generated partial userGetReportPreviewFileId, with default values.
    */
    export function createuserGetReportPreviewFileId(partial: Partial<IuserGetReportPreviewFileId>): IuserGetReportPreviewFileId {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


    /**
    * Generated interface IuserGetReportTemplatePreviewFileId for store item
    */
    export interface IuserGetReportTemplatePreviewFileId {
        /**
        * Loading indicator
        */
        readonly isLoading?: boolean;
        /**
        * All items loaded indicator (offset)
        */
        readonly allitemsloaded?: boolean;
        /**
        * The MediaMsg message
        */
        readonly msg?: cat.MediaMsg;
        /**
        * The MediaMsg list map
        */
        readonly list?: Map<string, cat.MediaMsg>;
        /**
        * The infinite list
        */
        readonly infinite?: Map<string, cat.MediaMsg>;
        /**
        * The infinite array
        */
        readonly infiniteArray?: cat.MediaMsg[];
    }

    /**
    * Generated partial userGetReportTemplatePreviewFileId, with default values.
    */
    export function createuserGetReportTemplatePreviewFileId(partial: Partial<IuserGetReportTemplatePreviewFileId>): IuserGetReportTemplatePreviewFileId {
        return {
            isLoading: partial.isLoading || false,
            allitemsloaded: partial.allitemsloaded || false,
            msg: partial.msg || undefined,
            list: partial.list || new Map<string, cat.MediaMsg>(),
            infinite: partial.infinite || new Map<string, cat.MediaMsg>(),
            infiniteArray: partial.infiniteArray || []
        };
    }


/**
* Generated interface IStoreItem for store item
*/
export interface IStoreItem {
    /**
    * Loading indicator
    */
    readonly isLoading?: boolean;
    /**
    * All items loaded indicator (offset)
    */
    readonly allitemsloaded?: boolean;
    /**
    * The general message
    */
    readonly msg?: any;
    /**
    * The message list map
    */
    readonly list?: Map<string, any>;
    /**
    * The infinite list
    */
    readonly infinite?: Map<string, any>;
    /**
    * The infinite array
    */
    readonly infiniteArray?: any[];
}

/**
* Generated partial StoreItem, with default values.
*/
export function createStoreItem(partial: Partial<IStoreItem>): IStoreItem {
    return {
        isLoading: partial.isLoading || false,
        allitemsloaded: partial.allitemsloaded || false,
        msg: partial.msg || undefined,
        list: partial.list || new Map<string, any>(),
        infinite: partial.infinite || new Map<string, any>(),
        infiniteArray: partial.infiniteArray || []
    };
}



