<div class="cat-list cat-padding-xl cat-overflow-y cat-no-background cat-full-height">

    <h4 class="cat-no-padding cat-margin-bottom-xl cat-nooverflow-ellipsis" color="dark">
        <b>{{ data.title | translate | replaceTerm }}</b>
    </h4>

    <ng-container *ngIf="(counts$|async) as counts">
        <ng-container *ngIf="(data.path | hasCallFilters); else noFilters">
                <ng-container *ngFor="let filter of availableFilters">

                        <ng-container *ngIf="(filter.field === 'lastmessageid'); else enumFilter">
                            <mat-slide-toggle
                                class="cat-full-width cat-no-margin"
                                (change)="toggleFilter($event, filter)"
                                color="primary"
                                [checked]="!currentFilters[filter.field]">
                                <div class="cat-margin-left-md cat-cursor-pointer">
                                    {{ uitext.empty | translate }}
                                    <ng-container *ngIf="counts.emptycount">
                                        (<b>{{ counts.emptycount }}</b>)
                                    </ng-container>
                                </div>
                            </mat-slide-toggle>
                        </ng-container>

                        <ng-template #enumFilter>

                            <div [ngSwitch]="data.mode">

                                <div *ngSwitchCase="'chat'">

                                    <ng-container *ngIf="filter.field === 'state'">
                                        <h5 class="cat-margin-top-xl cat-margin-bottom-sm">
                                            {{ uitext.state | translate }}
                                        </h5>
                                        <hr>
                                        <div class="grid-container cat-no-padding cat-no-margin">
                                            <ng-container *ngFor="let option of conversationStateDef | keys">
                                                <ng-template
                                                    [ngTemplateOutlet]="toggle"
                                                    [ngTemplateOutletContext]="{ filter: filter, option: option, counts: counts.statecount, countindex: conversationState[option.key] }">
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="filter.field === 'type'">
                                        <h5 class="cat-margin-top-xl cat-margin-bottom-sm">
                                            {{ uitext.type | translate }}
                                        </h5>
                                        <hr>
                                        <div class="grid-container cat-no-padding cat-no-margin">
                                            <ng-container *ngFor="let option of conversationTypeDef | keys">
                                                <ng-template
                                                    [ngTemplateOutlet]="toggle"
                                                    [ngTemplateOutletContext]="{ filter: filter, option: option, counts: counts.typecount, countindex: conversationType[option.key] }">
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="filter.field === 'visibility'">
                                        <h5 class="cat-margin-top-xl cat-margin-bottom-sm">
                                            {{ uitext.visibility | translate }}
                                        </h5>
                                        <hr>
                                        <div class="grid-container cat-no-padding cat-no-margin">
                                            <ng-container *ngFor="let option of visibilityTypeDef | keys">
                                                <ng-template
                                                    [ngTemplateOutlet]="toggle"
                                                    [ngTemplateOutletContext]="{ filter: filter, option: option, counts: counts.visibilitycount, countindex: visibilityType[option.key] }">
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>

                                <div *ngSwitchCase="'topic'">
                                    <ng-container *ngIf="filter.field === 'state'">
                                        <div class="grid-container cat-no-padding cat-no-margin">
                                            <ng-container *ngFor="let option of topicStateDef | keys">
                                                <ng-template
                                                    [ngTemplateOutlet]="toggle"
                                                    [ngTemplateOutletContext]="{ filter: filter, option: option, counts: counts.statecount, countindex: topicState[option.key]  }">
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>

                                <div *ngSwitchCase="'server'">
                                    <ng-container *ngIf="filter.field === 'state'">
                                        <div class="grid-container cat-no-padding cat-no-margin">
                                            <ng-container *ngFor="let option of serverStateDef | keys">
                                                <ng-template
                                                    [ngTemplateOutlet]="toggle"
                                                    [ngTemplateOutletContext]="{ filter: filter, option: option, counts: counts.statecount, countindex: serverState[option.key] }">
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="filter.field === 'visibility'">
                                        <div class="cat-divider cat-margin-y-sm"></div>
                                        <div class="grid-container cat-no-padding cat-no-margin">
                                            <ng-container *ngFor="let option of visibilityTypeDef | keys">
                                                <ng-template
                                                    [ngTemplateOutlet]="toggle"
                                                    [ngTemplateOutletContext]="{ filter: filter, option: option, counts: counts.visibilitycount, countindex: visibilityType[option.key] }">
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>

                                <div *ngSwitchCase="'serverchat'">
                                    <ng-container *ngIf="filter.field === 'state'">
                                        <h5 class="cat-margin-top-xl cat-margin-bottom-sm">
                                            {{ uitext.state | translate }}
                                        </h5>
                                        <hr>
                                        <div class="grid-container cat-no-padding cat-no-margin">
                                            <ng-container *ngFor="let option of conversationStateDef | keys">
                                                <ng-template
                                                    [ngTemplateOutlet]="toggle"
                                                    [ngTemplateOutletContext]="{ filter: filter, option: option, counts: counts.statecount, countindex: conversationState[option.key] }">
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="filter.field === 'type'">
                                        <h5 class="cat-margin-top-xl cat-margin-bottom-sm">
                                            {{ uitext.type | translate }}
                                        </h5>
                                        <hr>
                                        <div class="grid-container cat-no-padding cat-no-margin">
                                            <ng-container *ngFor="let option of conversationTypeDef | keys">
                                                <ng-template
                                                    [ngTemplateOutlet]="toggle"
                                                    [ngTemplateOutletContext]="{ filter: filter, option: option, counts: counts.typecount, countindex: conversationType[option.key] }">
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="filter.field === 'visibility'">
                                        <h5 class="cat-margin-top-xl cat-margin-bottom-sm">
                                            {{ uitext.visibility | translate }}
                                        </h5>
                                        <hr>                                        <div class="grid-container cat-no-padding cat-no-margin">
                                            <ng-container *ngFor="let option of visibilityTypeDef | keys">
                                                <ng-template
                                                    [ngTemplateOutlet]="toggle"
                                                    [ngTemplateOutletContext]="{ filter: filter, option: option, counts: counts.visibilitycount, countindex: visibilityType[option.key] }">
                                                </ng-template>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                        </ng-template>


            </ng-container>
        </ng-container>
    </ng-container>

    <ng-template #noFilters>
        <div class="cat-item">
            <div class="cat-label">{{ uitext.notfound | translate }}</div>
        </div>
    </ng-template>

    <div *ngIf="data.removeEnabled" (click)="removeQuery()" class="cat-item cat-no-padding cat-margin-top-md" button>
        <button mat-flat-button fill="solid" color="warn" size="small" class="cat-round-btn cat-sm cat-no-margin">
            <mat-icon  class="cat-animation-fade-up cat-align-self-center cat-font-md">delete</mat-icon>
        </button>
        <div color="danger" class="cat-label cat-margin-left-md cat-text-bold">
            {{ uitext.clear | translate }}
        </div>
    </div>

    <button mat-flat-button (click)="dismiss()" expand="full"  fill="solid" color="primary" class="cat-border-radius cat-sm cat-margin-top-xl">
        <div color="light" class="cat-label cat-margin-left-md cat-text-bold">
            {{ uitext.close | translate }}
        </div>
    </button>

    <div class="cat-height-100"></div>

</div>

<ng-template #toggle let-filter="filter" let-option="option" let-counts="counts" let-countindex="countindex">
    <ng-container *ngIf="filter.values?.includes(option?.key)">
        <div class="col-xs-4 cat-no-padding cat-no-margin">
            <mat-slide-toggle
                class="cat-full-width cat-no-margin"
                (change)="selectFilter(filter, option.key)"
                color="primary"
                [checked]="(filter.operator === filterOperatorEnum.FILTER_INCLUDE)
                    ? (currentFilters[filter.field]?.indexOf(option.key) > -1)
                    : !(currentFilters[filter.field]?.indexOf(option.key) > -1)">

                <div class="cat-margin-left-md cat-cursor-pointer">
                    <span *ngIf="option.value">{{ option.value.label | translate | replaceTerm }}</span>
                    <ng-container *ngIf="counts && countindex">
                        (<b>{{ counts[countindex] }}</b>)
                    </ng-container>
                </div>
            </mat-slide-toggle>
        </div>
    </ng-container>
</ng-template>
